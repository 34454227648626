import { IFranchiseeStateParams } from "Controllers/Rms/FranchiseeManagement/fmRouter";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import * as _ from "underscore"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { ContractTerritoryTypeParams } from "../../../Directives/Common";

export class fmContractsController implements ng.IController {
    contractsGrid: kendo.ui.Grid;
    contractsGridOptions: kendo.ui.GridOptions;

    contractWindow: kendo.ui.Window;
    contractWindowOptions: kendo.ui.WindowOptions;

    fddDropDown: kendo.ui.DropDownList;
    fddDropDownOptions: kendo.ui.DropDownListOptions;
    selectedFdd: RpmEntities.DisclosureDocumentViewModel;

    ratePlanDropDown: kendo.ui.DropDownList;
    ratePlanDropDownOptions: kendo.ui.DropDownListOptions;
    selectedRatePlan: RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel;

    contractSourceDropDown: kendo.ui.DropDownList;
    contractSourceDropDownOptions: kendo.ui.DropDownListOptions;
    selectedContractSource: RpmEntities.RoyaltyContractSourceViewModel;

    franchisor: CoreEntities.Franchisor;
    contractStatuses: RpmEntities.RoyaltyContractStatusViewModel[];
    ratePlanTypes: RpmEntities.RoyaltyRatePlanTypeViewModel[];
    contract: RpmEntities.usp_RoyaltyContracts_CreateFromDisclosureDocument_Input | RpmEntities.usp_RoyaltyContracts_CreateFromDisclosureDocument_Dynamic_Input;

    currentLoginEmail: string;
    readOnly: boolean;
    isLoading: boolean;

    dontCopyRatePlanItems: boolean;
    dontCopyRatePlanMinimums: boolean;

    refreshContractTerritoryType: boolean;
    isDynamicFees: boolean;

    static $inject = [
        "$scope",
        "$state",
        "$stateParams",
        "coreApiResources",
        "ngDialog",
        "rpmUiApiResources",
        "identityManager",
        "$timeout",
        "$q"
    ];

    constructor(
        private $scope: ng.IScope,
        private $state: ng.ui.IState,
        private $stateParams: IFranchiseeStateParams,
        private coreApiResources: CoreApiResources,
        private ngDialog: any,
        private rpmUiApiResources: RpmUiApiResources,
        private identityManager: IdentityManager,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService
    ) {
    }

    $onInit() {
        this.dontCopyRatePlanItems = false;
        this.dontCopyRatePlanMinimums = false;
        this.refreshContractTerritoryType = false;
        this.readOnly = true;
        this.isLoading = true;
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabled').val() === 'True';

        this.InitGrid();
        this.InitWindow();
        this.InitFddDropDown();
        this.InitRatePlanDropDown();
        this.InitContractSourceDropDown();

        this.$q.all([
            this.identityManager.GetLoggedInUserInfo().then(successResponse => {
                this.currentLoginEmail = successResponse.data.Email;
            }),

            this.identityManager.HasRole(["FranchiseeManagementAdmin", "Admin"]).then(isAdmin => {
                this.readOnly = !isAdmin;
            }),

            this.rpmUiApiResources.GetAllRoyaltyContractStatuses().then((statusResponse) => {
                this.contractStatuses = statusResponse.data;
            }),

            this.coreApiResources.FranchiseApi.get({ id: this.$stateParams.franchiseeID, $expand: "Franchisor", $select: "FranchiseId,Franchisor" }).$promise
                .then((franchise) => {
                    this.franchisor = franchise.Franchisor;
                }),

            this.rpmUiApiResources.GetAllRoyaltyRatePlanTypes().then((typesResponse) => {
                this.ratePlanTypes = typesResponse.data
            })

        ])
            .then(() => {
                return this.contractsGrid.dataSource.read().then(() => {
                    this.contractsGrid.refresh();
                })
            })
            .finally(() => { this.isLoading = false; });
    }

    InitGrid() {
        let contractsGridColumns: kendo.ui.GridColumn[] = [
            {
                field: nameof<RpmEntities.RoyaltyContractViewModel>(o => o.RoyaltyContractId),
                title: "Contract ID"
            }, {
                field: nameof<RpmEntities.RoyaltyContractViewModel>(o => o.FddYear),
                title: "Fdd Year"
            }, {
                field: nameof<RpmEntities.RoyaltyContractViewModel>(o => o.ContractBeginsDate),
                title: "Contract Begin",
                template: "#=kendo.toString(kendo.parseDate(ContractBeginsDate),'d')#",
            }, {
                field: nameof<RpmEntities.RoyaltyContractViewModel>(o => o.ContractEndsDate),
                title: "Contract End",
                template: "#=kendo.toString(kendo.parseDate(ContractEndsDate),'d')#",
            }, {
                title: "Status",
                field: "Status"
            }, {
                template: `<a class='pure-button' href='/ContractManagement/Edit/\\#?tab=general&contractId=#= RoyaltyContractId #'>View / Edit &gt;</button>`,
                width: 150
            }
        ];

        let contractsDataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    this.isLoading = true;
                    this.rpmUiApiResources.GetRoyaltyContractsByFranchise(parseInt(this.$stateParams.franchiseeID))
                        .then((contractsResponse) => {
                            for (let contract of contractsResponse.data) {
                                let status = this.GetContractStatusById(contract.RoyaltyContractStatusID);
                                (<any>contract).Status = status && status.Name;
                            }

                            options.success(contractsResponse.data);
                        },
                            (err) => { options.error(err); })
                        .finally(() => { this.isLoading = false; });
                }
            },
            schema: {
                model: {
                    id: "RoyaltyContractId",
                    fields: {
                        RoyaltyContractId: {
                            type: "number",
                            defaultValue: 0,
                            editable: false
                        }
                    }
                }
            },
            sort: {
                field: "Status",
                dir: "asc"
            }
        });

        this.contractsGridOptions = {
            columns: contractsGridColumns,
            dataSource: contractsDataSource,
            autoBind: false,
            navigatable: true,
            scrollable: false,
            editable: false,
            filterable: true,
            sortable: true
        };
    }

    InitWindow() {
        this.contractWindowOptions = {
            modal: true
        }
    }

    InitFddDropDown() {
        this.fddDropDownOptions = {
            autoBind: false,
            optionLabel: "Select Fdd...",
            optionLabelTemplate: "Select Fdd...",
            dataTextField: nameof<RpmEntities.DisclosureDocumentViewModel>(d => d.Description),
            dataValueField: nameof<RpmEntities.DisclosureDocumentViewModel>(d => d.DisclosureDocumentId),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        this.rpmUiApiResources.GetDisclosureDocumentsByFranchisorId(this.franchisor.FranchisorId)
                            .then((docsResponse) => {
                                if (docsResponse.data && Array.isArray(docsResponse.data) && docsResponse.data.length > 0) {
                                    let fddDropDownData = docsResponse.data;
                                    fddDropDownData.forEach(x => {
                                        x.Description = x.Year + " " + x.Description;
                                    })
                                    options.success(_.filter(fddDropDownData, d => d.Selectable));
                                }
                                else {
                                    options.success(_.filter([], d => d.Selectable));
                                }
                            }, (err) => { options.error(err); })
                    },
                },
                sort: [
                    { field: nameof<RpmEntities.DisclosureDocumentViewModel>(d => d.Year), dir: "desc" }
                ]
            }),
            select: (e) => {
                this.$timeout(() => {
                    this.ratePlanDropDown.dataSource.read();
                });
            }
        };
    }

    InitRatePlanDropDown() {
        let template = "<span>#= DisclosureDocumentRatePlanId #</span> - " +
            "<span ng-bind='$ctrl.GetRatePlanTypesById(#= RoyaltyRatePlanTypeId #).Name'></span>";

        this.ratePlanDropDownOptions = {
            autoBind: false,
            optionLabel: "Select Rate Plan...",
            optionLabelTemplate: "Select Rate Plan...",
            dataTextField: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel>(d => d.DisclosureDocumentRatePlanId),
            dataValueField: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel>(d => d.DisclosureDocumentRatePlanId),
            template: template,
            valueTemplate: template,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        if (!this.selectedFdd.DisclosureDocumentId) {
                            options.success([]);
                            return;
                        }

                        this.rpmUiApiResources.GetRoyaltyDisclosureDocumentRatePlansByDisclosureDocument(this.selectedFdd.DisclosureDocumentId)
                            .then((ratePlansResponse) => {
                                options.success(_.filter(ratePlansResponse.data, r => !r.DeletedDateTime));
                            }, (err) => { options.error(err); })
                    }
                },
                sort: [
                    { field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel>(d => d.DisclosureDocumentRatePlanId), dir: "desc" }
                ]
            })
        };
    }

    InitContractSourceDropDown() {
        this.contractSourceDropDownOptions = {
            autoBind: false,
            optionLabel: "Select Contract Source...",
            optionLabelTemplate: "Select Contract Source...",
            dataTextField: nameof<RpmEntities.RoyaltyContractSourceViewModel>(d => d.Name),
            dataValueField: nameof<RpmEntities.RoyaltyContractSourceViewModel>(d => d.RoyaltyContractSourceID),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        this.rpmUiApiResources.GetAllRoyaltyContractSources()
                            .then((sourcesResponse) => {
                                options.success(sourcesResponse.data);
                            }, (err) => { options.error(err); })
                    },
                },
                sort: [
                    { field: nameof<RpmEntities.DisclosureDocumentViewModel>(d => d.Year), dir: "desc" }
                ]
            })
        };
    }

    OnContractTerritoryTypeSelect(contractTerritoryType: ContractTerritoryTypeParams["contractTerritoryType"]) {
        this.contract.ContractTerritoryTypedId = contractTerritoryType.contractTerritoryTypeId;
    }

    AddNewContractPopup() {
        let contract: Partial<RpmEntities.RoyaltyContractViewModel> = {
            FranchiseId: parseInt(this.$stateParams.franchiseeID)
        };
        this.contract = <any>contract;

        return this.$q.all([
            this.$q.when(this.fddDropDown.dataSource.read()).then(() => {
                this.fddDropDown.refresh();
            }),
            this.coreApiResources.GetFranchiseOwnedPopulation(this.contract.FranchiseId).then((ownedPopulation) => {
                this.contract.OwnedTerritoryPopulation = ownedPopulation;
            }),
            this.coreApiResources.GetFranchiseTotalPopulation(this.contract.FranchiseId).then((totalPopulation) => {
                this.contract.TotalTerritoryPopulation = totalPopulation;
            })
        ])
            .then(() => {
                this.$timeout(() => {
                    this.contractWindow.center();
                }, 0);
                this.dontCopyRatePlanItems = false;
                this.dontCopyRatePlanMinimums = false;
                this.selectedFdd = null;
                this.selectedRatePlan = null;
                this.selectedContractSource = null;
                this.refreshContractTerritoryType = !this.refreshContractTerritoryType;
                this.contractWindow.open();
            });
    }

    CreateContract() {
        this.contract.DisclosureDocumentId = this.selectedFdd.DisclosureDocumentId;
        this.contract.DisclosureDocumentRatePlanId = this.selectedRatePlan.DisclosureDocumentRatePlanId;
        this.contract.ContractSourceId = this.selectedContractSource.RoyaltyContractSourceID;
        this.contract.CopyRatePlanItems = !this.dontCopyRatePlanItems;
        this.contract.CopyRatePlanMinimums = !this.dontCopyRatePlanMinimums;
        this.contract.ContractBeginDate = this.formatLocalDate(this.contract.ContractBeginDate);
        this.contract.ContractEndDate = this.formatLocalDate(this.contract.ContractEndDate);
        this.contract.ContractSignedDate = this.formatLocalDate(this.contract.ContractSignedDate);
        this.contract.RoyaltyBeginDate = this.formatLocalDate(this.contract.RoyaltyBeginDate);
        this.contract.RoyaltyEndDate = this.formatLocalDate(this.contract.RoyaltyEndDate);

        if (this.isDynamicFees)
            return this.rpmUiApiResources.CreateRoyaltyContractByDisclosureDocumentDynamic(this.contract.DisclosureDocumentId, this.contract).then(() => {
                this.contractWindow.close();
                return this.contractsGrid.dataSource.read().then(() => {
                    this.contractsGrid.refresh();
                })
            });

        return this.rpmUiApiResources.CreateRoyaltyContractByDisclosureDocument(this.contract.DisclosureDocumentId, this.contract).then(() => {
            this.contractWindow.close();
            return this.contractsGrid.dataSource.read().then(() => {
                this.contractsGrid.refresh();
            })
        });
    }
    formatLocalDate(inputDate) {
        return new Date(new Date(inputDate).getTime() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .split('T')[0];
    }

    CancelContract() {
        this.contractWindow.close();
    }

    GetContractStatusById(id: number) {
        return _.find(this.contractStatuses, s => s.RoyaltyContractStatusID === id);
    }

    GetRatePlanTypesById(id: number) {
        return _.find(this.ratePlanTypes, s => s.RoyaltyRatePlanTypeId === id);
    }
}
