import { KbsBaseComponentController } from "./KbsBaseComponent";

export class CategoriesComponentController extends KbsBaseComponentController<KnowledgeResources.ICategory> {
    GetGridColumns(): kendo.ui.GridColumn[] {
        return [
            {
                field: "Description",
                title: "Description",
            },
            {
                template: "<button class='pure-button button-secondary' ng-click='$ctrl.OpenEditEntityWindow(dataItem)'>Edit</button>",
                width: 100,
                title: "&nbsp;"
            },
            {
                template: "<button class='pure-button button-error' ng-click='$ctrl.DeleteEntity(dataItem)'>X</button>",
                width: 70,
                title: "&nbsp;"
            }
        ];
    }

    GetDataSourceSchemaModel(): any {
        return { id: "CategoryId" };
    }

    TransportReadOperationHandler(options: kendo.data.DataSourceTransportReadOptions) {
        this.knowledgeApiResources.CategoriesApi.query({})
            .$promise.then(
                (categories) => {
                    options.success(categories);
                },
                (err) => {
                    options.error(err);
                });
    }

    CreateNewEntity(): KnowledgeResources.ICategory {
        let category = new this.knowledgeApiResources.CategoriesApi();
        category.CategoryId = 0;
        category.Description = "";

        return category;
    }

    GetSavePromise(category: KnowledgeResources.ICategory) {
        let categoryPromise: ng.IPromise<KnowledgeResources.ICategory>;

        if (category.CategoryId) {
            // Update
            categoryPromise = this.knowledgeApiResources.CategoriesApi.update({ id: category.CategoryId }, category).$promise;
        }
        else {
            // Create
            categoryPromise = category.$save();
        }

        return categoryPromise;
    }

    DeleteEntity(category: KnowledgeResources.ICategory) {
        if (confirm(`Are you sure you want to delete '${category.Description}'?`)) {
            return this.knowledgeApiResources.CategoriesApi.delete({ id: category.CategoryId })
                .$promise.then(() => {
                    return this.RefreshGrid();
                });
        }
    }

    static BindComponent(app: ng.IModule) {
        app.component("kbsCategories", {
            bindings: {
            },
            controller: CategoriesComponentController,
            templateUrl: "/Templates/KBS/Categories.html",
        });
    }
}