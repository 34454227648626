import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { BaseStateHandler } from "StateHandlers/BaseStateHandler";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyContractStateHandler extends BaseStateHandler<RpmEntities.RoyaltyContractViewModel, number>  {

    constructor(
        private rpmUiApiResources: RpmUiApiResources
    )
    {
        super();
    }

    Get(royaltyContractId: number, isDynamicFees?: boolean): angular.IPromise<RpmEntities.RoyaltyContractViewModel>
    {
        if (isDynamicFees) {
            return this.rpmUiApiResources.GetRoyaltyContractDynamic(royaltyContractId)
                .then((response) => { return response.data; })
        }
        else {
            return this.rpmUiApiResources.GetRoyaltyContract(royaltyContractId)
                .then((response) => { return response.data; })
        }
    }
}