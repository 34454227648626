import { IFRMStateParams } from "Controllers/Rms/FranchisorRoyaltyManagement/frmRouter";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmEntities } from "../../../Interfaces/FranForce/Rpm/RpmResources";
import { RpmUiApiResources } from "../../../Services/Resources/RpmUiApiResources";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { createRoyaltyFranchisorMinimumFeeIncreasesAndCapsModel } from "../../../Models/Royalty/FranchisorRoyaltyManagement/CreateRoyaltyFranchisorMinimumFeeIncreasesAndCapsModel";

interface IFRMAdFeeCapScope extends ng.IScope {
    // Kendo
    adFeeGridOptions: kendo.ui.GridOptions;
    adFeeDataSource: kendo.data.DataSource;
    facGridOptions: kendo.ui.GridOptions;
    facDataSource: kendo.data.DataSource;
    dialog: kendo.ui.Dialog;
    franchiseAdFeeCapInfoGridOptions: kendo.ui.GridOptions;
    franchiseAdFeeCapInfoDataSource: kendo.data.DataSource;

    //yearly ad cap fee data
    yearlyCapFeeData: any;

    //franchise Ad Fee Cap Info Data
    franchiseAdFeeCapInfoData: any;

    //year field
    addYearlyCapFeeYear: number;

    //ad cap fee field
    addYearlyCapFee: number;

    //year field validation
    isInValidYear: boolean;
    isInValidYearErrorMessage: string;
    invalidTextFieldYearCSSClass: string

    //Ad fee cap field validations
    isInvalidAdCapFee: boolean;
    isInvalidAdCapFeeMessage: string;
    invalidTextFieldAdCapFeeCSSClass: string;

    //newly added yearly ad fee caps
    newlyAddedYearlyAdFeeCap: {
        newlyAddedYear: number,
        newlyAddedCapFee: number,
    }[];
}

export class FRMAdFeeCapController implements ng.IController {

    static $inject = [
        '$scope',
        '$stateParams',
        'rpmApiResources',
        'rpmUiApiResources',
        'identityManager',
        "royaltyApiResources"
    ];

    constructor(
        private $scope: IFRMAdFeeCapScope,
        private $stateParams: IFRMStateParams,
        private rpmApiResources: RpmApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private identityManager: IdentityManager,
        private royaltyApiResources: RoyaltyApiResources
    ) {

        $scope.newlyAddedYearlyAdFeeCap = []

        //set validation show to false by default
        this.clearAllValidations();

        //set current year as default year field
        var date = new Date();
        this.$scope.addYearlyCapFeeYear = date.getFullYear();

        //populate year-ad cap fee grid with latest year by default
        this.$scope.yearlyCapFeeData = {
            data: [],
            page: 1,
            pageSize: 5
        }

        this.royaltyApiResources.RoyaltyFranchisorMinimumFeeIncreasesAndCapsByFranchisor(this.$stateParams.franchisorId as unknown as number)
            .then((response) => {
                response.data.forEach((item, index) => {
                    let year = item.yearIncreased;
                    let fee = this.numberWithCommas(item.adFeeCap);
                    this.$scope.yearlyCapFeeData.data.push({
                        Year: year,
                        AdFeeCap: fee
                    })
                });

                //get latest year in ad cap fee grid
                let latestYear = this.getLatestYear();

                this.$scope.yearlyCapFeeData.data.sort(function (a, b) {
                    return a.Year - b.Year
                }).reverse();

                //add fee yearly grid columns
                let adfeeColumns: Array<kendo.ui.GridColumn> = [
                    {
                        field: "Year",
                        title: "Year"
                    },
                    {
                        field: "AdFeeCap",
                        title: "Ad Fee Cap"
                    }];

                //add data to adCapFee data grid
                $scope.adFeeDataSource = new kendo.data.DataSource($scope.yearlyCapFeeData);

                $scope.adFeeGridOptions = {
                    columns: adfeeColumns,
                    dataSource: $scope.adFeeDataSource,
                    pageable: {
                        alwaysVisible: true,
                        pageSizes: [5, 10, 15, 20]
                    },
                    dataBound: function (e) {
                        // set latest year row to selected
                        let grid = e.sender;
                        $.each(grid.tbody.find('tr'), function () {
                            var model = grid.dataItem(this);
                            if (latestYear) {
                                if (model.get("Year").toString() === latestYear.toString()) {
                                    grid.select(this);
                                }
                            }
                        });
                    },
                    selectable: true,
                    sortable: true,
                    scrollable: true,
                    height: 200,
                }

                //franchise ad fee cap info grid columns
                let franchiseAdFeeCapInfoColumns: Array<kendo.ui.GridColumn> = [
                    {
                        field: "Exception",
                        width: 120,
                        template: '<span style="font-weight:bold; font-size:20px" ng-show="$ctrl.isExcludeChecked(dataItem)">&\\#10004;</span>',
                        attributes: {
                            style: "text-align: center"
                        },
                        "filterable": false
                    }, {
                        field: "LicenseNumber",
                        title: "License Number",
                        width: 200,
                        filterable: {
                            multi: true,
                            search: true
                        }
                    },
                    {
                        field: "ZeeName",
                        title: "Zee Name",
                        filterable: {
                            multi: true,
                            search: true
                        }
                    },
                    {
                        field: "AdFeeCap",
                        title: "Ad Fee Cap",
                        attributes: {
                            style: "text-align: center"
                        },
                        width: 200,
                        filterable: {
                            multi: true,
                            search: true
                        }
                    }];

                //franchise ad fee cap info grid data source
                $scope.franchiseAdFeeCapInfoDataSource = new kendo.data.DataSource($scope.franchiseAdFeeCapInfoData);

                //franchise ad fee cap info grid column options
                $scope.franchiseAdFeeCapInfoGridOptions = {
                    columns: franchiseAdFeeCapInfoColumns,
                    dataSource: $scope.franchiseAdFeeCapInfoDataSource,
                    selectable: false,
                    sortable: true,
                    scrollable: true,
                    height: 400,
                    filterable: true,
                }
            })
            .catch((err) => {
                console.log("response yearly ad cap fee data error", err);
            });
    }

    $onInit() {

    }

    toggleErrorValidationForYear(isInValidYear: boolean, invalidTextFieldYearCSSClass: string, isInValidYearErrorMessage: string) {
        this.$scope.isInValidYear = isInValidYear;
        this.$scope.invalidTextFieldYearCSSClass = invalidTextFieldYearCSSClass;
        this.$scope.isInValidYearErrorMessage = isInValidYearErrorMessage;
    }

    //validation to check if valid year
    checkIfYearIsValid(year) {
        if (year || year.toString().trim() !== "") {
            if (year.toString().length === 4) {
                this.toggleErrorValidationForYear(false, "", "");
                return true;
            }
            else {
                this.toggleErrorValidationForYear(true, "invalidTextFieldYearCSSClass", "Please enter a valid year");
                return false;
            }
        }
    }

    //validation for empty year
    checkIfYearFieldIsEmpty(year) {
        if (!year || year.toString().trim() === "") {
            this.toggleErrorValidationForYear(true, "invalidTextFieldYearCSSClass", "Please enter year");
            return true;
        }
        else {
            this.toggleErrorValidationForYear(false, "", "");
            return false;
        }
    }

    //validation for empty cap fee
    checkIfAdCapFeeFieldIsEmpty(capFee) {
        if (!capFee || capFee.toString().trim() === "") {
            this.$scope.isInvalidAdCapFee = true;
            this.$scope.invalidTextFieldAdCapFeeCSSClass = "invalidTextFieldAdCapFeeCSSClass";
            this.$scope.isInvalidAdCapFeeMessage = "Please enter ad cap fee"
            return true;
        }
        else {
            this.$scope.isInvalidAdCapFee = false;
            this.$scope.invalidTextFieldAdCapFeeCSSClass = "";
            this.$scope.isInvalidAdCapFeeMessage = ""
            return false;
        }
    }

    //validate if year is greater than existing year
    checkIfYearIsGreaterThanExistingYear(year) {
        let isAddedYearLessThanExisting = false;
        this.$scope.yearlyCapFeeData.data.forEach((item, index) => {
            if (year <= item.Year) {
                isAddedYearLessThanExisting = true;
                return;
            }
        })
        if (isAddedYearLessThanExisting && (this.$scope.addYearlyCapFeeYear || this.$scope.addYearlyCapFeeYear.toString().trim() !== "")) {
            this.toggleErrorValidationForYear(true, "invalidTextFieldYearCSSClass", "Year should be greater than existing year");
            return true;
        }
        else {
            this.toggleErrorValidationForYear(false, "", "");
            return false;
        }
    }

    //validate if year is greater than next year
    checkIfYearIsGreaterThanCurrentYear(year) {
        if (year > new Date().getFullYear() + 1) {
            this.toggleErrorValidationForYear(true, "invalidTextFieldYearCSSClass", "Year should not be greater than next year");
            return true;
        }
        else {
            this.toggleErrorValidationForYear(false, "", "");
            return false;
        }
    }

    //validation to check if yearly cap fee exists
    checkIfYearCapFeeExists(year) {
        let yearlyCapFeeExists = false;
        this.$scope.yearlyCapFeeData.data.forEach((item, index) => {
            if (item.Year.toString().trim() === year.toString().trim()) {
                yearlyCapFeeExists = true;
                return;
            }
        })
        if (yearlyCapFeeExists) {
            this.toggleErrorValidationForYear(true, "invalidTextFieldYearCSSClass", "Ad cap fee for the year exists");
            return true;
        }
        else {
            this.toggleErrorValidationForYear(false, "", "");
            return false;
        }
    }


    //onclick func to add yearly ad cap fee for franchisor
    addYearCapFee() {

        //set year & capFee variables and scope variables for newly added data
        let year = this.$scope.addYearlyCapFeeYear;
        let capFee = this.$scope.addYearlyCapFee;

        //validation checks
        if (this.checkIfYearFieldIsEmpty(year)) {
            return;
        }
        if (this.checkIfAdCapFeeFieldIsEmpty(capFee)) {
            return;
        }
        if (!this.checkIfYearIsValid(year)) {
            return;
        }
        if (this.checkIfYearCapFeeExists(year)) {
            return;
        }
        if (this.checkIfYearIsGreaterThanExistingYear(year)) {
            return;
        }
        if (this.checkIfYearIsGreaterThanCurrentYear(year)) {
            return;
        }

        this.$scope.newlyAddedYearlyAdFeeCap.push({
            newlyAddedCapFee: capFee,
            newlyAddedYear: year
        })

        //clear the fields year & capFee modal
        this.$scope.addYearlyCapFeeYear = null;
        this.$scope.addYearlyCapFee = null;

        // add yearly ad cap fee record to grid
        this.$scope.yearlyCapFeeData = {
            data: [
                ...this.$scope.yearlyCapFeeData.data,
                {
                    Year: year,
                    AdFeeCap: this.numberWithCommas(capFee)
                }
            ]
        }

        //sort data in desc order by year
        this.$scope.yearlyCapFeeData.data.sort((a, b) => {
            return a.Year - b.Year
        }).reverse();

        //add year & capFee to yearly ad Cap Fee Grid
        this.$scope.adFeeDataSource.data([
            ...this.$scope.yearlyCapFeeData.data,
        ]);

        //clode dialog
        this.$scope.dialog.close();

        //set year field to current year
        this.$scope.addYearlyCapFeeYear = new Date().getFullYear();

        //select the first row after adding new year
        var grid = $("#adCapFeeGrid").data("kendoGrid");
        grid.content.find(".k-scrollbar-vertical").scrollTop(0);
        grid.one("dataBound", function (e) {
            setTimeout(function () {
                grid.select(e.sender.tbody.find("tr:first"));
            }, 50)
        });

        //change the page to 1 when server operations are used will refresh the data
        grid.dataSource.page(1);
    }

    //get latest year
    getLatestYear() {
        let arrayYears = [];
        if (this.$scope.yearlyCapFeeData && this.$scope.yearlyCapFeeData.data) {
            this.$scope.yearlyCapFeeData.data.forEach((item, index) => {
                arrayYears.push(parseInt(item.Year.toString()))
            });
            let latestYearBeforeAdding = arrayYears.sort((a, b) => a - b).reverse()[0];
            return latestYearBeforeAdding;
        }
    }

    //on change of year field
    onYearChange() {
        //set year variable
        let year = this.$scope.addYearlyCapFeeYear;

        if (this.checkIfYearFieldIsEmpty(year)) {
            return;
        }
        if (this.checkIfYearCapFeeExists(year)) {
            return;
        }
        if (this.checkIfYearIsGreaterThanExistingYear(year)) {
            return;
        }
        if (this.checkIfYearIsGreaterThanCurrentYear(year)) {
            return;
        }
    }

    //ad fee dialog cancel click
    cancelAddFeeDialog() {
        //clear all validations
        this.clearAllValidations();

        //clear the fields year & capFee modal
        this.$scope.addYearlyCapFeeYear = new Date().getFullYear();
        this.$scope.addYearlyCapFee = null;

        //close the dialog
        this.$scope.dialog.close();
    }

    //on change of ad cap fee field
    onAdCapFeeChange() {
        //set year variable
        let capFee = this.$scope.addYearlyCapFee;
        if (this.checkIfAdCapFeeFieldIsEmpty(capFee)) {
            return;
        }
    }

    //clear all validations
    clearAllValidations() {
        this.$scope.isInValidYear = false;
        this.$scope.isInValidYearErrorMessage = "";
        this.$scope.invalidTextFieldYearCSSClass = ""
        this.$scope.isInvalidAdCapFee = false;
        this.$scope.isInvalidAdCapFeeMessage = "";
        this.$scope.invalidTextFieldAdCapFeeCSSClass = "";
    }

    //on select of row from yearly ad Fee Grid
    onSelection(data) {
        //if added year is clicked
        if (this.$scope.newlyAddedYearlyAdFeeCap.filter(e => e.newlyAddedYear == data.Year).length > 0) {
            this.getDataOnAddNewYearCapFee(data.Year);
        }
        else {
            this.getFranciseeAdCapFeeInfo(data.Year);
        }
    }

    // get Francisee Ad Cap Fee Info
    getFranciseeAdCapFeeInfo(year: number) {
        this.$scope.franchiseAdFeeCapInfoData = {
            data: []
        }
        this.royaltyApiResources.GetFranchiseAdFeeCapInfo(this.$stateParams.franchisorId as unknown as number, year as number)
            .then((response) => {
                response.data.forEach((item, index) => {
                    this.$scope.franchiseAdFeeCapInfoData.data.push({
                        franchiseId: item.franchiseId,
                        Exclude: item.adFeeCapException,
                        LicenseNumber: item.licenseNumber,
                        ZeeName: item.franchiseeName,
                        AdFeeCap: item.adFeeCapException ? this.numberWithCommas(item.adFeeCap) : null,
                        Year: year,
                    })
                });

                //franchisee ad fee cap info datasource
                this.$scope.franchiseAdFeeCapInfoDataSource.data([
                    ...this.$scope.franchiseAdFeeCapInfoData.data,
                ]);
            })
            .catch((err) => {
                console.log("franchise ad fee cap grid data error", err);
            });
    }

    //function to set exclude to check if exception for franchisee exists
    isExcludeChecked = function (dataItem) {
        if (dataItem.Exclude && dataItem.Exclude.toString() === "true") {
            return true;
        }
        else {
            return false;
        }
    }

    //on save click
    async onSave() {
        let promises = this.$scope.newlyAddedYearlyAdFeeCap.map(m => 
            this.royaltyApiResources.CreateRoyaltyFranchisorMinimumFeeIncreasesAndCaps(Number(this.$stateParams.franchisorId), Number(m.newlyAddedYear), Number(m.newlyAddedCapFee))
                .then(response => {
                    return response.data;
                })
                .catch(err => alert("Error in Creating Royalty Franchisor Minimum Ad Fee Increases And Caps")));
        let promiseResult = await Promise.all(promises);
        location.reload();
    }

    //on cancel click
    onCancel() {
        location.reload();
    }

    //numbers with commas
    numberWithCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
    }

    //get data for newly added year
    getDataOnAddNewYearCapFee(year) {
        this.$scope.franchiseAdFeeCapInfoData = {
            data: []
        }
        this.royaltyApiResources.GetFranchiseAdFeeCapInfoForNewYear(this.$stateParams.franchisorId as unknown as number)
            .then((response) => {
                response.data.forEach((item, index) => {
                    this.$scope.franchiseAdFeeCapInfoData.data.push({
                        franchiseId: item.franchiseId,
                        Exclude: item.adFeeCapException,
                        LicenseNumber: item.licenseNumber,
                        ZeeName: item.franchiseeName,
                        AdFeeCap: item.adFeeCapException ? this.numberWithCommas(item.adFeeCap) : null,
                        Year: year,
                    })
                });

                //franchisee ad fee cap info datasource
                this.$scope.franchiseAdFeeCapInfoDataSource.data([
                    ...this.$scope.franchiseAdFeeCapInfoData.data,
                ]);
            })
            .catch((err) => {
                console.log("error newly added year ad cap fee", err);
            });
    }
}