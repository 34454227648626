import { SyncTechnicianDataModel } from "../../Models/Common/SyncTechnicianDataModel";
import { FranchiseOrchestrationsApiResources } from "Services/Resources/FranchiseOrchestrationsApiResources";
import { StringProperties } from "../../Types/StringProperties";
import { data } from "jquery";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            technicianMultiSelector: Partial<StringProperties<TechnicianMultiSelectorComponentController>>;
        }
    }
}

export class TechnicianMultiSelectorComponentController implements ng.IController {
    selectedModel: kendo.data.ObservableObject & SyncTechnicianDataModel;
    dropdown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    syncTechnicianMultiSelectOptions: kendo.ui.MultiSelectOptions;
    technicianMultiSelect: kendo.ui.MultiSelect;

    //---- Bindings ----
    franchiseIds: number[];
    select: string[];
    displayTextTemplate: string;
    sort: (a: Partial<SyncTechnicianDataModel>, b: Partial<SyncTechnicianDataModel>) => number;
    selectedTechnicians: any[];
    enableSelectAll: boolean;
    //------------------

    static readonly DisplayProperty = "DisplayText";

    static $inject = [
        "$q",
        "$interpolate",
        "$location",
        "franchiseOrchestrationsApiResources"
    ];

    constructor(
        private $q: ng.IQService,
        private $interpolate: ng.IInterpolateService,
        private $location: ng.ILocationService,
        private franchiseOrchestrationsApiResources: FranchiseOrchestrationsApiResources
    ) {

    }

    async $onInit() {

        if (!this.select || !(this.select instanceof Array)) {
            this.select = [
                nameof<SyncTechnicianDataModel>(o => o.syncTechnicianId),
                nameof<SyncTechnicianDataModel>(o => o.name)
            ];
        }

        if (!this.displayTextTemplate)
            this.displayTextTemplate = `{{${nameof<SyncTechnicianDataModel>(o => o.name)}}}`;

        if (!this.sort)
            this.sort = (a, b) => { return a[TechnicianMultiSelectorComponentController.DisplayProperty].toLowerCase().localeCompare(b[TechnicianMultiSelectorComponentController.DisplayProperty].toLowerCase()); }

        this.SetDropDownOptions();

    }

    async SetDropDownOptions() {
        this.syncTechnicianMultiSelectOptions =
        {
            /*headerTemplate: `<label><input type="checkbox" ng-click='$ctrl.SelectAllOnClick($event)' id="ces_reports_techniciansMultiSelect"> Select All</label>`,*/
            placeholder: "Select Technicians...",
            dataTextField: nameof<SyncTechnicianDataModel>(o => o.name),
            dataValueField: nameof<SyncTechnicianDataModel>(o => o.syncTechnicianId),
            valuePrimitive: true,
            autoBind: true,
            autoClose: false,
            clearButton: this.enableSelectAll ? false : true,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options: kendo.data.DataSourceTransportReadOptions) => {

                        if (!this.franchiseIds || this.franchiseIds == undefined || (this.franchiseIds && this.franchiseIds.length <= 0) && (!this.selectedTechnicians || (this.selectedTechnicians && this.selectedTechnicians.length <=0))) {
                            options.success([]);
                        }
                        else {
                            this.franchiseOrchestrationsApiResources.GetSyncTechniciansByFranchiseIds(this.franchiseIds)
                                .then((result) => {

                                    let technicians = result.data;
                                    for (let technician of technicians) {
                                        technician[TechnicianMultiSelectorComponentController.DisplayProperty] = this.$interpolate(this.displayTextTemplate)(technician);
                                    }
                                    if (this.sort) {
                                        technicians = technicians.sort(this.sort);
                                    }

                                    options.success(technicians);
                                })
                                .catch(err => console.log('err'));
                        }
                    }
                }
            }),
            dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) => {
                this.technicianMultiSelect = e.sender;
            }
            //deselect: function (e) {
            //    $("#ces_reports_techniciansMultiSelect").prop("checked", false);
            //}
        };
    }

    //SelectAllOnClick(e) {
    //    if ($("#ces_reports_techniciansMultiSelect").is(":checked")) {
    //        let allTechnicianList: number[] = this.technicianMultiSelect.dataSource.data().map((x, i) => x[nameof<SyncTechnicianDataModel>(o => o.syncTechnicianId)]);
    //        this.technicianMultiSelect.value(allTechnicianList);
    //        this.technicianMultiSelect.trigger("change");
    //    }
    //    else {
    //        this.technicianMultiSelect.value([]);
    //        this.technicianMultiSelect.trigger("change");
    //    }
    //}

    $postLink() {

    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.franchiseIds)] && this.franchiseIds && this.franchiseIds != null && this.franchiseIds.length > 0) {
            if (!this.syncTechnicianMultiSelectOptions)
                this.SetDropDownOptions();
            else {
                this.technicianMultiSelect.dataSource.read();
            }
                
        }
        else {
            if (this.syncTechnicianMultiSelectOptions && this.syncTechnicianMultiSelectOptions.dataSource) {
                this.syncTechnicianMultiSelectOptions.dataSource.data([]);
            }         
        }
    }

    ClearAll() {
        this.technicianMultiSelect.value([]);
        this.technicianMultiSelect.trigger("change");
    }

    SelectAll() {
        let allTechnicianList: number[] = this.technicianMultiSelect.dataSource.data().map((x, i) => x[nameof<SyncTechnicianDataModel>(o => o.syncTechnicianId)]);
        this.technicianMultiSelect.value(allTechnicianList);
        this.technicianMultiSelect.trigger("change");
    }

    static BindComponent(app: ng.IModule) {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.technicianMultiSelector);

        app.component(componentName, {
            bindings: {
                [nameof<TechnicianMultiSelectorComponentController>(o => o.franchiseIds)]: "<",
                [nameof<TechnicianMultiSelectorComponentController>(o => o.select)]: "<",
                [nameof<TechnicianMultiSelectorComponentController>(o => o.displayTextTemplate)]: "<",
                [nameof<TechnicianMultiSelectorComponentController>(o => o.sort)]: "<",
                [nameof<TechnicianMultiSelectorComponentController>(o => o.selectedTechnicians)]: "=",
                [nameof<TechnicianMultiSelectorComponentController>(o => o.enableSelectAll)]: "<",

            },
            controller: TechnicianMultiSelectorComponentController,
            templateUrl: `/Templates/Common/TechnicianMultiSelector.html`
        });
    }

}