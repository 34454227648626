
export class BatchManagementController implements ng.IController
{
    searchOptions: { description: string, routeName: string }[];
    selectedOption: string;
    
    kNotifier: kendo.ui.Notification;
    kNotifierOptions: kendo.ui.NotificationOptions;

    static $inject = [
        "$scope",
        "$state",
        "$stateParams"
    ];

    constructor(
        private $scope: ng.IScope,
        private $state: ng.ui.IStateService,
        private $stateParams: ng.ui.IStateParamsService
    ) {
        
    }

    $onInit() 
    {
        this.searchOptions = [
            { description: "Franchisor", routeName: "bm.franchisor" },
            { description: "Franchise", routeName: "bm.franchise" },
            { description: "Royalty Period", routeName: "bm.period" },
            { description: "Batch Id", routeName: "bm.batchId" }
        ];
   
        if (this.$state.current.name) {
            this.selectedOption = this.$state.current.name;
        }

        this.kNotifierOptions = {
            position: {
                top: 15,
                left: $(window).width() / 2
            },
            templates: [{
                type: 'ngTemplate',
                template: $('#kNTemplate').html(),
            }]
        };

        this.$scope.$on('showNotification', (e, data) =>
        {
            this.kNotifier.show({
                kValue: data.message,
            }, "ngTemplate");
        });
    }

    OnSearchOptionSelect()
    {
        this.$state.go(this.selectedOption, { });
    }

    OnRefresh() {
        this.$scope.$broadcast('refreshBatchManagementData', { });
    }
}
