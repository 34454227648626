import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";


interface EmployeeBioSearchConcept  {
    conceptCode: string;
}
  

export class EmployeeBiosSearchResources 
{
    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig",
        "$httpParamSerializer"
    ];


  

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig,
        private $httpParamSerializer: ng.IHttpParamSerializer 
    )
    {
        this.RefreshResources()
    }

    RefreshResources()
    {
    }

    EmployeeBioSearchByConcept(conceptCode)
    {
        return `${this.apiConfig.AppSettings.FranchiseEmployeeOrchestrationsApiRootUrl}/public/concepts/${conceptCode}/employee-bios/search?apikey=${this.apiConfig.AppSettings.FranchiseEmployeeOrchestrationsApiKey}`;
    }

    
    
 
}