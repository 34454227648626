import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyContractRatePlanMinimumItemsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;
    
    GetRoyaltyContractRatePlanMinimumItemsByContractPlanItem(contractRatePlanId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContractRatePlanMinimumItems/InContractRatePlan/" + contractRatePlanId
        };

        return this.$http<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel[]>(config);
    }

    CreateRoyaltyContractRatePlanMinimumItem(model: RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyContractRatePlanMinimumItems",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyContractRatePlanMinimumItem(id: number, model: RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyContractRatePlanMinimumItems/" + id,
            data: model
        };

        return this.$http<number>(config);
    }
}
