import { CoreApiResources } from "Services/Resources/CoreApiResources";

export class FranchiseIntuitCompanyComponentController implements ng.IController {
    //---- Bindings ----
    franchiseId: number;
    //------------------
    intuitCompanyId: string;
    
    static $inject = [
        "coreApiResources",
        "$q",
        "$log"
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService
    )
    {

    }

    $onInit() {
        this.coreApiResources.GetIntuitCompanyId(this.franchiseId).then((response) => {
            this.intuitCompanyId = response.data;
        });
    }

    SaveCompanyId()
    {
        if (confirm("Are you sure you want to update the Intuit Company Id?"))
        {
            return this.coreApiResources.UpdateIntuitCompanyId(this.franchiseId, this.intuitCompanyId);
        }
        else {
            return this.$q.reject();
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("franchiseIntuitCompany", {
            bindings: {
                franchiseId: "<"
            },
            controller: FranchiseIntuitCompanyComponentController,
            templateUrl: "/Templates/Billing/IntuitCompany.html",
        });
    }
}
