import { SyncApiResources } from "Services/Resources/SyncApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { KendoUtil } from "Utility/KendoUtil";
import { ExtendedSyncTechnician } from "Interfaces/FranForce/Sync/ExtendedSyncTechnician";
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { SyncResources, SyncEntities } from "Interfaces/FranForce/Sync/SyncResources";
import { DisplayFranchise } from "Interfaces/FranForce/Common/DisplayFranchise";

export class IndexCertificationsController implements ng.IController 
{
    franchises: DisplayFranchise[];
    selectedFranchise: number;
    franchiseCertifications: CoreEntities.FranchiseCertification[];
    franchiseCertificationTypes: CoreEntities.FranchiseCertificationType[];
    technicians: ExtendedSyncTechnician[];
    selectedTechnician: number;
    technicianCertifications: SyncEntities.SyncTechnicianCertification[];
    technicianCertificationTypes: SyncEntities.SyncTechnicianCertificationType[];
    technicianBackgroundChecks: SyncEntities.SyncTechnicianBackgroundCheck[];
    franchiseCertificationGrid: kendo.ui.Grid;
    franchiseGridCertificationOptions: kendo.ui.GridOptions;
    syncTechnicianCertificationGrid: kendo.ui.Grid;
    syncTechnicianCertificationGridOptions: kendo.ui.GridOptions;
    syncTechnicianBackgroundCheckGrid: kendo.ui.Grid;
    syncTechnicianBackgroundCheckGridOptions: kendo.ui.GridOptions;
    franchiseCertificationTypeDropDownOptions: kendo.ui.DropDownListOptions;
    syncTechnicianCertificationTypeDropDownOptions: kendo.ui.DropDownListOptions;
    isLoadingFranchises: boolean;
    isLoadingTechnicians: boolean;
    isLoadingTechnicianInfo: boolean;
    franchisesLoaded: boolean;
    techniciansLoaded: boolean;
    franchiseCertificationsLoaded: boolean;
    technicianCertificationsLoaded: boolean;
    technicianBackgroundChecksLoaded: boolean;
    franchiseDropDownOptions: kendo.ui.DropDownListOptions;
    technicianDropDownOptions: kendo.ui.DropDownListOptions;
    franchiseCertTypeDropDown: kendo.ui.DropDownList;
    technicianCertTypeDropDown: kendo.ui.DropDownList;
    tenants: number[];
    errorDescription: string;
    technicianGridOptions: kendo.ui.GridOptions;
    technicianGrid: kendo.ui.Grid;
    now: Date;
    popup: kendo.ui.Window;
    popupOptions: kendo.ui.WindowOptions;

    static $inject = [
        "$scope",
        "syncApiResources",
        "coreApiResources",
        "$window",
        "$q",
        "$log"
    ];

    constructor(
        private $scope: ng.IScope,
        private syncApiResources: SyncApiResources,
        private coreApiResources: CoreApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService
    )
    {
        this.$scope = $scope;
        this.syncApiResources = syncApiResources;
        this.coreApiResources = coreApiResources;
        this.$window = $window;
        this.$q = $q;
        this.$log = $log;
    }

    $onInit() {
        this.isLoadingFranchises = false;
        this.isLoadingFranchises = false;
        this.franchisesLoaded = false;
        this.techniciansLoaded = false;
        this.franchiseCertificationsLoaded = false;
        this.technicianBackgroundChecksLoaded = false;
        this.technicianCertificationsLoaded = false;
        this.now = new Date();
        this.popupOptions = {
            draggable: false,
            height: "80%",
            width: "80%",
            modal: true,
            position: {
                left: "20%",
                top: "20%"
            },
            resizable: false,
            pinned: true,
            visible: false,
            animation: {
                open: {
                    effects: "expand:horizontal fadeIn"
                },
                close: {
                    effects: "expand:horizontal fadeIn"
                   
                }
            },
            close: (e: kendo.ui.WindowCloseEvent) => {
                this.onSelectedFranchiseChange();
            }
        };
        this.franchiseGridCertificationOptions = {
            sortable: true,
            scrollable: false,
            filterable: {
                mode: "menu",
                extra: true
            },
            resizable: true,
            columns: [
                {
                    title: "Cert Type",
                    template: `<div class="pure-u-1" style="text-align:center">
                                    <select kendo-drop-down-list
                                        class="pure-u-1"
                                        k-options="$ctrl.franchiseCertificationTypeDropDownOptions"
                                        k-rebind="$ctrl.franchiseCertificationTypeDropDownOptions"
                                        style="width:100%"
                                        ng-model=dataItem.FranchiseCertificationTypeId
                                        value=dataItem.FranchiseCertificationTypeId>
                                    </select>
                                </div>`,
                    width: 150
                },
                {
                    template: `<div style="text-align:center; width: 100%">
                                    <input type="text" class="k-textbox" ng-model="dataItem.CertificationNumber" style="width:75%" />
                               </div>`,
                    title: "Cert #",
                    width: 150
                },
                {
                    field: "EndDate",
                    title: "Expires",
                    width: 300,
                    template: `<div style="text-align:center; width: 100%">
                                    <input ng-model="dataItem.EndDate" kendo-date-picker pure-input-1-3" onkeydown="return false;" />
                               </div>`
                },
                {
                    template: `<div style="text-align:center; width: 100%">
                                    <input type="text" class="k-textbox" ng-model="dataItem.State" style="width:75%"/>
                               </div>`,
                    title: "State",
                    width: 150
                },
                {
                    template: `<div style="text-align:center" style="width: 100%">
                                    <select kendo-drop-down-list
                                        class="pure-u-1"
                                        style="width:100%"
                                        ng-model="dataItem.Country"
                                        value="dataItem.Country">
                                        <option value="United States">United States</option>
                                        <option value="Canada">Canada</option>
                                    </select>
                               </div>`,
                    title: "Country",
                    width: 150
                },
                {
                    template: `<div class='pure-u-1' style='text-align: center;'>
                    <button style='width: 100%; box-sizing: border-box; margin-top: 5px;' 
                                    class='pure-button' 
                                    promise-btn
                                    ng-click='$ctrl.SaveFranchiseCertification(dataItem, false)'>
                                    Save
                                </button>
                    </div>`,
                    width: 150
                },
                {
                    template: `<div class='pure-u-1' style='text-align: center;'>
                    <button style='width: 100%; box-sizing: border-box; margin-top: 5px;' 
                                    class='pure-button' 
                                    promise-btn
                                    ng-click='$ctrl.SaveFranchiseCertification(dataItem, true)'>
                                    Delete
                                </button>
                    </div>`,
                    width: 150
                }]
        };

        this.syncTechnicianCertificationGridOptions = {
            sortable: true,
            scrollable: false,
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true
            },
            resizable: true,
            columns: [
                {
                    title: "Cert Type",
                    template: `<div class="pure-u-1" style="text-align:center">
                                    <select kendo-drop-down-list="$ctrl.technicianCertTypeDropDown"
                                        class="pure-u-1"
                                        k-options="$ctrl.syncTechnicianCertificationTypeDropDownOptions"
                                        k-rebind="$ctrl.syncTechnicianCertificationTypeDropDownOptions"
                                        style="width:100%"
                                        ng-model=dataItem.SyncTechnicianCertificationTypeId
                                        value=dataItem.SyncTechnicianCertificationTypeId>
                                    </select>
                                </div>`,
                    width: 150
                },
                {
                    template: `<div style="text-align:center; width: 100%">
                                    <input type="text" class="k-textbox" ng-model="dataItem.CertificationNumber" style="width:75%" />
                               </div>`,
                    title: "Cert #",
                    width: 150
                },
                {
                    field: "EndDate",
                    title: "Expires",
                    width: 300,
                    template: `<div style="text-align:center; width: 100%">
                                    <input ng-model="dataItem.EndDate" kendo-date-picker pure-input-1-3" onkeydown="return false;" />
                               </div>`
                },
                {
                    template: `<div style="text-align:center; width: 100%">
                                    <input type="text" class="k-textbox" ng-model="dataItem.State" style="width:75%"/>
                               </div>`,
                    title: "State",
                    width: 150
                },
                {
                    template: `<div style="text-align:center" style="width: 100%">
                                    <select kendo-drop-down-list
                                        class="pure-u-1"
                                        style="width:100%"
                                        ng-model="dataItem.Country"
                                        value="dataItem.Country">
                                        <option value="United States">United States</option>
                                        <option value="Canada">Canada</option>
                                    </select>
                               </div>`,
                    title: "Country",
                    width: 150
                },
                {
                    template: `<div class='pure-u-1' style='text-align: center;'>
                    <button style='width: 100%; box-sizing: border-box; margin-top: 5px;' 
                                    class='pure-button' 
                                    promise-btn
                                    ng-click='$ctrl.SaveTechCertification(dataItem, false)'>
                                    Save
                                </button>
                    </div>`,
                    width: 150
                },
                {
                    template: `<div class='pure-u-1' style='text-align: center;'>
                    <button style='width: 100%; box-sizing: border-box; margin-top: 5px;' 
                                    class='pure-button' 
                                    promise-btn
                                    ng-click='$ctrl.SaveTechCertification(dataItem, true)'>
                                    Delete
                                </button>
                    </div>`,
                    width: 150
                }]
        };

        this.syncTechnicianBackgroundCheckGridOptions = {
            sortable: true,
            scrollable: false,
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true
            },
            resizable: true,
            columns: [
                {
                    field: "BackgroundCheckDate",
                    title: "Date",
                    width: 300,
                    template: `<div style="text-align:center; width: 100%">
                                    <input ng-model="dataItem.BackgroundCheckDate" kendo-date-picker pure-input-1-3" onkeydown="return false;" />
                               </div>`
                },
                {
                    field: "Passed",
                    title: "Passed",
                    template: `<div class='pure-u-1' style='text-align: center;'>
                                <input ng-model="dataItem.Passed" class="k-checkbox" id="dataItem.SyncTechnicianCertificationId" type="checkbox">
                                <label class="k-checkbox-label" for="dataItem.SyncTechnicianCertificationId"></label>
                    </div>`,
                    width: 150
                },
                {
                    template: `<div style="text-align:center; width: 100%">
                                    <input type="text" class="k-textbox" ng-model="dataItem.Comment" style="width:75%"/>
                               </div>`,
                    title: "Comment",
                    width: 600
                },
                {
                    template: `<div class='pure-u-1' style='text-align: center;'>
                    <button style='width: 100%; box-sizing: border-box; margin-top: 5px;' 
                                    class='pure-button' 
                                    promise-btn
                                    ng-click='$ctrl.SaveTechBackgroundCheck(dataItem, false)'>
                                    Save
                                </button>
                    </div>`,
                    width: 150
                },
                {
                    template: `<div class='pure-u-1' style='text-align: center;'>
                    <button style='width: 100%; box-sizing: border-box; margin-top: 5px;' 
                                    class='pure-button' 
                                    promise-btn
                                    ng-click='$ctrl.SaveTechBackgroundCheck(dataItem, true)'>
                                    Delete
                                </button>
                    </div>`,
                    width: 150
                }],
            rowTemplate: `<tr id="#: uid #" >
                            <td><div style="text-align:center; width: 100%">
                                    <input ng-model="dataItem.BackgroundCheckDate" kendo-date-picker pure-input-1-3" onkeydown="return false;" />
                               </div></td>
                                <td><div class='pure-u-1' style='text-align: center;'>
                                <input ng-model="dataItem.Passed" class="k-checkbox" id="#: uid #_ckbox" type="checkbox">
                                <label class="k-checkbox-label" for="#: uid #_ckbox"></label>
                                </div></td>
                                <td><div style="text-align:center; width: 100%">
                                    <input type="text" class="k-textbox" ng-model="dataItem.Comment" style="width:75%"/>
                               </div></td>
                                <td><div class='pure-u-1' style='text-align: center;'>
                    <button style='width: 100%; box-sizing: border-box; margin-top: 5px;' 
                                    class='pure-button' 
                                    promise-btn
                                    ng-click='$ctrl.SaveTechBackgroundCheck(dataItem, false)'>
                                    Save
                                </button>
                    </div></td>
                                <td><div class='pure-u-1' style='text-align: center;'>
                    <button style='width: 100%; box-sizing: border-box; margin-top: 5px;' 
                                    class='pure-button' 
                                    promise-btn
                                    ng-click='$ctrl.SaveTechBackgroundCheck(dataItem, true)'>
                                    Delete
                                </button>
                    </div></td>
                        </tr>`
        };

        this.technicianGridOptions = {
            scrollable: false,
            selectable: "row",
            resizable: true,
            columns: [
                {
                    field: "Name",
                    title: "Name",
                    width: 300
                },
                {
                    template: `<div ng-show="dataItem.SyncTechnicianBackgroundChecks.length > 0" style="vertical-align: middle; height:20px">
                                    <img src="/Content/css/images/icons/StatusOK.ico" style="height: 20px; width: 20px" />
                                </div>`,
                    title: "Background Check",
                    width: 150
                },
                {
                    template: `<div ng-show="dataItem.AMRTStatusTypeId == 1" style="vertical-align: middle; height:20px">
                                    <img src="/Content/css/images/icons/StatusOK.ico" style="height: 20px; width: 20px" />
                                </div>
                                <div ng-show="dataItem.AMRTStatusTypeId == 2" style="vertical-align: middle; height:20px" >
                                    <img src="/Content/css/images/icons/StatusWarning.ico"  style="height: 20px; width: 20px"/>
                                </div>`,
                    title: "AMRT",
                    width: 150
                },
                {
                    template: `<div ng-show="dataItem.ASDStatusTypeId == 1" style="vertical-align: middle; height:20px">
                                    <img src="/Content/css/images/icons/StatusOK.ico" style="height: 20px; width: 20px" />
                                </div>
                                <div ng-show="dataItem.ASDStatusTypeId == 2" style="vertical-align: middle; height:20px" >
                                    <img src="/Content/css/images/icons/StatusWarning.ico"  style="height: 20px; width: 20px"/>
                                </div>`,
                    title: "ASD",
                    width: 150
                },
                {
                    template: `<div ng-show="dataItem.FRSTStatusTypeId == 1" style="vertical-align: middle; height:20px">
                                    <img src="/Content/css/images/icons/StatusOK.ico" style="height: 20px; width: 20px" />
                                </div>
                                <div ng-show="dataItem.FRSTStatusTypeId == 2" style="vertical-align: middle; height:20px" >
                                    <img src="/Content/css/images/icons/StatusWarning.ico"  style="height: 20px; width: 20px"/>
                                </div>`,
                    title: "FRST",
                    width: 150
                },
                {
                    template: `<div ng-show="dataItem.OCTStatusTypeId == 1" style="vertical-align: middle; height:20px">
                                    <img src="/Content/css/images/icons/StatusOK.ico" style="height: 20px; width: 20px" />
                                </div>
                                <div ng-show="dataItem.OCTStatusTypeId == 2" style="vertical-align: middle; height:20px" >
                                    <img src="/Content/css/images/icons/StatusWarning.ico"  style="height: 20px; width: 20px"/>
                                </div>`,
                    title: "OCT",
                    width: 150
                },
                {
                    template: `<div ng-show="dataItem.RRPStatusTypeId == 1" style="vertical-align: middle; height:20px">
                                    <img src="/Content/css/images/icons/StatusOK.ico" style="height: 20px; width: 20px" />
                                </div>
                                <div ng-show="dataItem.RRPStatusTypeId == 2" style="vertical-align: middle; height:20px" >
                                    <img src="/Content/css/images/icons/StatusWarning.ico"  style="height: 20px; width: 20px"/>
                                </div>`,
                    title: "RRP",
                    width: 150
                },
                {
                    template: `<div ng-show="dataItem.SubroStatusTypeId  == 1" style="vertical-align: middle; height:20px">
                                    <img src="/Content/css/images/icons/StatusOK.ico" style="height: 20px; width: 20px" />
                                </div>
                                <div ng-show="dataItem.SubroStatusTypeId  == 2" style="vertical-align: middle; height:20px" >
                                    <img src="/Content/css/images/icons/StatusWarning.ico"  style="height: 20px; width: 20px"/>
                                </div>`,
                    title: "Subro",
                    width: 150
                },
                {
                    template: `<div ng-show="dataItem.WRTStatusTypeId == 1" style="vertical-align: middle; height:20px">
                                    <img src="/Content/css/images/icons/StatusOK.ico" style="height: 20px; width: 20px" />
                                </div>
                                <div ng-show="dataItem.WRTStatusTypeId == 2" style="vertical-align: middle; height:20px" >
                                    <img src="/Content/css/images/icons/StatusWarning.ico"  style="height: 20px; width: 20px"/>
                                </div>`,
                    title: "WRT",
                    width: 150
                }]
        };
        
        this.GetFranchises(11);
        this.GetCertificationTypes(11);
    }

    openTechnicianCertManagement(technician: ExtendedSyncTechnician) {
            this.selectedTechnician = technician.SyncTechnicianId;
            this.popup = <kendo.ui.Window>$("#TechnicianCertificationManager").data("kendoWindow");
            
            this.technicianCertifications = technician.SyncTechnicianCertifications;
            
            this.syncTechnicianCertificationGrid.setDataSource(new kendo.data.DataSource({
                data: this.technicianCertifications
            }));

            this.technicianBackgroundChecks = technician.SyncTechnicianBackgroundChecks;

            this.syncTechnicianBackgroundCheckGrid.setDataSource(new kendo.data.DataSource({
                data: this.technicianBackgroundChecks,
            }));

            this.popupOptions.title = "Certification Management for " + technician.Name;
            let width = this.$window.window.innerWidth * .8;
            this.popupOptions.width = width;
            this.popup.setOptions(this.popupOptions);
            this.popup.center();
            this.popup.open();
    }

    GetFranchises(conceptId: number) {
        
        this.isLoadingFranchises = true;
        this.franchisesLoaded = false;
        return this.coreApiResources.FranchiseApi.query({
            $filter: `ConceptId eq ${conceptId} and DeletedDateTime eq null and IsTest eq false`,
            $orderby: `LicenseNumber`
        }).$promise.then(
            (results: CoreResources.IFranchise[]) =>
            {
                this.franchises = results;
                for (let franchise of this.franchises)
                {
                    franchise.DisplayText = franchise.DisplayText = franchise.LicenseNumber + " " + franchise.DoingBusinessAs;
                }
                this.selectedFranchise = results[0].FranchiseId;
                this.franchiseDropDownOptions = {
                    dataSource: this.franchises,
                    dataValueField: "FranchiseId",
                    dataTextField: "DisplayText",
                    filter: "contains",
                    dataBound: (e: kendo.ui.DropDownListDataBoundEvent) => {
                        this.onSelectedFranchiseChange();
                    },
                    change: (e: kendo.ui.DropDownListChangeEvent) => {
                        this.onSelectedFranchiseChange();
                    },
                    valueTemplate: "<small>{{dataItem.DisplayText}}</small>",
                    template: "<small>{{dataItem.DisplayText}}</small>"
                };
                this.isLoadingFranchises = false;
                this.franchisesLoaded = true;
            },
            (err) => {
                console.error(err);
                this.isLoadingFranchises = false;
                alert("Could not get franchises for concept.");
            });
            
    }
   
    GetCertificationTypes(conceptId: number) {
        return this.coreApiResources.FranchiseCertificationTypeApi.query({
            $filter: `ConceptId eq ${conceptId} and IsDeleted eq false`,
            $orderby: `Name`
        }).$promise.then(
            (certTypes: CoreEntities.FranchiseCertificationType[]) => {
                this.franchiseCertificationTypes = certTypes;

                this.franchiseCertificationTypeDropDownOptions = {
                    dataSource: this.franchiseCertificationTypes,
                    dataValueField: "FranchiseCertificationTypeId",
                    dataTextField: "Name",
                    filter: "contains",
                    valueTemplate: "{{dataItem.Name}}",
                    template: "<small>{{dataItem.Name}}</small>"
                };

                let syncSourceTypeId = 0;
                if (conceptId == 11)
                {
                    syncSourceTypeId = 3;
                }
                this.syncApiResources.SyncTechnicianCertificationTypeApi.query({
                    $filter: `SyncSourceTypeId eq ${syncSourceTypeId} and IsDeleted eq false`,
                    $orderby: `Name`
                }).$promise.then(
                    (techCertTypes: SyncEntities.SyncTechnicianCertificationType[]) => {
                        this.technicianCertificationTypes = techCertTypes;
                        this.syncTechnicianCertificationTypeDropDownOptions = {
                            dataSource: this.technicianCertificationTypes,
                            dataValueField: "SyncTechnicianCertificationTypeId",
                            dataTextField: "Name",
                            filter: "contains",
                            valueTemplate: "{{dataItem.Name}}",
                            template: "<small>{{dataItem.Name}}</small>"
                        };
                    },
                    (err) => {
                        console.error(err);
                        alert("Could not get technician certification types.");
                    });
            },
            (err) => {
                console.error(err);
                alert("Could not get franchise certification types.");
            });
    }

    onSelectedFranchiseChange() {
        this.tenants = [];
        this.technicians = [];
        this.isLoadingTechnicians = true;
        this.techniciansLoaded = false;
        this.technicianCertifications = [];
        this.technicianBackgroundChecks = [];
        this.franchiseCertifications = [];
        this.technicianBackgroundChecksLoaded = false;
        this.technicianCertificationsLoaded = false;
        return this.syncApiResources.SyncBusinessUnitApi.query({
            $filter: `FranchiseId eq ${this.selectedFranchise} and IsDeleted eq false`
        }).$promise.then(
            (businessUnits: SyncResources.ISyncBusinessUnit[]) => {
                if (businessUnits == null || businessUnits.length == 0)
                {
                    this.errorDescription = "No data warehouse information for franchise.";
                    this.isLoadingTechnicians = false;
                }
                for (let businessUnit of businessUnits) {
                    let index = this.tenants.indexOf(businessUnit.SyncTenantID);
                    if (index == -1) {
                        this.tenants.push(businessUnit.SyncTenantID);
                        for (let tenant of this.tenants)
                        {
                            this.syncApiResources.SyncTechniciansApi.query({
                                $filter: `SyncTenantID eq ${tenant} and IsDeleted eq false`,
                                $orderby: `Name`,
                                $expand: `SyncTechnicianCertifications($filter=IsDeleted eq false),SyncTechnicianBackgroundChecks($filter=IsDeleted eq false)`
                            }).$promise.then(
                                (technicians: ExtendedSyncTechnician[]) => {
                                    this.technicians = [];
                                    this.refreshTechnicianGrid(technicians);
                                    for (let tech of technicians) {
                                        this.technicians.push(tech);
                                    }

                                    this.technicianGrid.setDataSource(new kendo.data.DataSource({
                                        data: this.technicians
                                    }));
                                    if (this.selectedTechnician > 0)
                                    {
                                        let data = this.technicianGrid.dataSource.data();

                                        for (let i = 0; i < data.length; i++) {
                                            if (data[i].SyncTechnicianId == this.selectedTechnician) {
                                                var select = this.technicianGrid.tbody.find('tr[data-uid="' + data[i].uid + '"]');
                                                //Have to add the class to the row instead of selecting because selecting fires off the change event.
                                                select.addClass("k-state-selected");
                                            }
                                        }
                                    }
                                    
                                    this.isLoadingTechnicians = false;
                                    this.techniciansLoaded = true;

                                },
                                (err) => {
                                    console.error(err);
                                    alert("Could not get technicians for franchise.");
                                });
                        }

                    }
                }
                this.coreApiResources.FranchiseCertificationApi.query({
                    $filter: `FranchiseId eq ${this.selectedFranchise} and IsDeleted eq false`,
                    $expand: `FranchiseCertificationType`,
                    $orderby: `EndDate desc`
                }).$promise.then(
                    (certs) => {
                        this.franchiseCertifications = certs;
                        
                        this.franchiseCertificationGrid.setDataSource(new kendo.data.DataSource({
                            data: this.franchiseCertifications
                        }));
                    },
                    (err) => {
                        console.error(err);
                        this.isLoadingTechnicians = false;
                        alert("Could not get certifications for franchise.");
                    });
                this.isLoadingTechnicians = false;
                this.techniciansLoaded = true;
                
            });
    }

    refreshTechnicianGrid(technicians: ExtendedSyncTechnician[])
    {
        for (let tech of technicians) {
            if (tech.SyncTechnicianCertifications != null) {
                for (let cert of tech.SyncTechnicianCertifications) {
                    let endDate = new Date(cert.EndDate);

                    switch (cert.SyncTechnicianCertificationTypeId) {
                        case 1:
                            if (tech.RRPStatusTypeId != 1) {
                                if (cert.EndDate && endDate > this.now) {
                                    tech.RRPStatusTypeId = 1;
                                }
                                else {
                                    tech.RRPStatusTypeId = 2;
                                }
                            }
                            break;
                        case 2:
                            tech.SubroStatusTypeId = 1;
                            break;
                        case 3:
                            if (tech.WRTStatusTypeId != 1) {
                                if (cert.EndDate && endDate > this.now) {
                                    tech.WRTStatusTypeId = 1;
                                }
                                else {
                                    tech.WRTStatusTypeId = 2;
                                }
                            }
                            break;
                        case 4:
                            if (tech.ASDStatusTypeId != 1) {
                                if (cert.EndDate && endDate > this.now) {
                                    tech.ASDStatusTypeId = 1;
                                }
                                else {
                                    tech.ASDStatusTypeId = 2;
                                }
                            }
                            break;
                        case 5:
                            if (tech.FRSTStatusTypeId != 1) {
                                if (cert.EndDate && endDate > this.now) {
                                    tech.FRSTStatusTypeId = 1;
                                }
                                else {
                                    tech.FRSTStatusTypeId = 2;
                                }
                            }
                            break;
                        case 6:
                            if (tech.OCTStatusTypeId != 1) {
                                if (cert.EndDate && endDate > this.now) {
                                    tech.OCTStatusTypeId = 1;
                                }
                                else {
                                    tech.OCTStatusTypeId = 2;
                                }
                            }
                            break;
                        case 7:
                            if (tech.AMRTStatusTypeId != 1) {
                                if (cert.EndDate && endDate > this.now) {
                                    tech.AMRTStatusTypeId = 1;
                                }
                                else {
                                    tech.AMRTStatusTypeId = 2;
                                }
                            }
                            break;
                    }
                }
            }
        }
    }

    onTechnicianChange()
    {
        this.GetTechnicianInfo(this.selectedTechnician);
    }
     
    GetTechnicianInfo(syncTechnicianId: number) {
        this.isLoadingTechnicianInfo = true;
        this.technicianCertificationsLoaded = false;
        this.technicianBackgroundChecksLoaded = false;
        return this.syncApiResources.SyncTechnicianCertificationApi.query({
            $filter: `SyncTechnicianId eq ${syncTechnicianId} and IsDeleted eq false`,
            $orderby: `EndDate desc`
        }).$promise.then(
            (certs) => {
                this.technicianCertifications = certs;
                
                this.syncTechnicianCertificationGrid.setDataSource(new kendo.data.DataSource({
                    data: this.technicianCertifications
                }));
                this.technicianCertificationsLoaded = true;
                this.syncApiResources.SyncTechnicianBackgroundCheckApi.query({
                    $filter: `SyncTechnicianId eq ${syncTechnicianId} and IsDeleted eq false`,
                    $orderby: `BackgroundCheckDate desc`
                }).$promise.then(
                    (bgChecks) => {
                        this.technicianBackgroundChecks = bgChecks;
                        
                        this.syncTechnicianBackgroundCheckGrid.setDataSource(new kendo.data.DataSource({
                            data: this.technicianBackgroundChecks,
                        }));
                        
                        this.isLoadingTechnicianInfo = false;
                        this.technicianBackgroundChecksLoaded = true;
                    });
                this.refreshTechnicianGrid(this.technicians);
            },
            (err) => {
                console.error(err);
                alert("Could not get certifications and background checks for technician.");
            });
    }
    
    SaveFranchiseCertification(franchiseCertification: CoreEntities.FranchiseCertification, isDeleted: boolean)
    {
        if (!isDeleted && !(franchiseCertification.State.length == 0 || franchiseCertification.State.length == 2))
        {
            alert("State must be 2 characters, if supplied.");
            return;
        }
        else if (isDeleted)
        {
            if (!confirm("Are you sure you want to delete the selected certification?"))
            {
                return;
            }
        }
        var cert = new this.coreApiResources.FranchiseCertificationApi();
        cert.CertificationNumber = franchiseCertification.CertificationNumber;
        cert.EndDate = franchiseCertification.EndDate;
        cert.FranchiseCertificationTypeId = franchiseCertification.FranchiseCertificationTypeId;
        cert.FranchiseCertificationId = franchiseCertification.FranchiseCertificationId;
        cert.FranchiseId = franchiseCertification.FranchiseId;
        cert.IsDeleted = isDeleted;
        cert.UpdateUser = "unknownuser";
        cert.CreateUser = franchiseCertification.CreateUser;
        cert.State = franchiseCertification.State;
        cert.Country = franchiseCertification.Country;
        return cert.$save(() => {
            this.onSelectedFranchiseChange();
        },
            (err) => {
                console.error(err);
                alert("Could not get certifications for franchise.");
            });

    }

    SaveTechCertification(techCertification: SyncEntities.SyncTechnicianCertification, isDeleted: boolean) {
        if (!isDeleted && !(techCertification.State.length == 0 || techCertification.State.length == 2)) {
            alert("State must be 2 characters, if supplied.");
            return;
        }
        else if (isDeleted) {
            if (!confirm("Are you sure you want to delete the selected certification?")) {
                return;
            }
            if (!techCertification.SyncTechnicianCertificationId) {

                this.onTechnicianChange();
                return;
            }
        }
        var cert = new this.syncApiResources.SyncTechnicianCertificationApi();
        cert.CertificationNumber = techCertification.CertificationNumber;
        cert.EndDate = techCertification.EndDate;
        cert.SyncTechnicianCertificationTypeId = techCertification.SyncTechnicianCertificationTypeId;
        cert.SyncTechnicianCertificationId = techCertification.SyncTechnicianCertificationId;
        cert.SyncTechnicianId = techCertification.SyncTechnicianId;
        cert.IsDeleted = isDeleted;
        cert.UpdateUser = "unknownuser";
        cert.CreateUser = techCertification.CreateUser;
        cert.State = techCertification.State;
        cert.Country = techCertification.Country;
        cert.SyncSourceTypeId = techCertification.SyncSourceTypeId;
        cert.ExternalId = techCertification.ExternalId;

        return cert.$save(() => {
            this.onTechnicianChange();
        },
        (err) => {
            console.error(err);
            alert("Could not save tech certification for technician.");
        });

    }

    SaveTechBackgroundCheck(techBackgroundCheck: SyncEntities.SyncTechnicianBackgroundCheck, isDeleted: boolean) {
        if (isDeleted) {
            if (!confirm("Are you sure you want to delete the selected background check?")) {
                return;
            }
            if (!techBackgroundCheck.SyncTechnicianBackgroundCheckId) {

                this.onTechnicianChange();
                return;
            }
        }
        var cert = new this.syncApiResources.SyncTechnicianBackgroundCheckApi();
        cert.SyncTechnicianId = techBackgroundCheck.SyncTechnicianId;
        cert.IsDeleted = isDeleted;
        cert.UpdateUser = "unknownuser";
        cert.CreateUser = techBackgroundCheck.CreateUser;
        cert.SyncSourceTypeId = techBackgroundCheck.SyncSourceTypeId;
        cert.ExternalId = techBackgroundCheck.ExternalId;
        cert.Passed = techBackgroundCheck.Passed;
        cert.BackgroundCheckDate = techBackgroundCheck.BackgroundCheckDate;
        cert.Comment = techBackgroundCheck.Comment;

        return cert.$save(() => {
            this.onTechnicianChange();
        },
        (err) => {
            console.error(err);
            alert("Could not save tech background check for technician.");
        });

    }

    addFranchiseCertification()
    {
        var blankCert: CoreEntities.FranchiseCertification={
            CertificationNumber: "",
            CreatedDateTime: null,
            CreateUser: "",
            EndDate : null,
            FranchiseCertificationId: 0,
            FranchiseCertificationType: null,
            FranchiseCertificationTypeId: 1,
            FranchiseId: this.selectedFranchise,
            IsDeleted: false,
            StartDate: null,
            UpdatedDateTime: null,
            UpdateUser: "",
            Country: "United States",
            State: ""
        };
        this.franchiseCertifications.push(blankCert);
        this.franchiseCertificationGrid.setDataSource(new kendo.data.DataSource({
            data: this.franchiseCertifications
        }));
        
    }

    addTechCertification() {
        var blankCert: SyncEntities.SyncTechnicianCertification = {
            CertificationNumber: "",
            CreatedDateTime: null,
            CreateUser: "",
            EndDate: null,
            SyncTechnicianCertificationId: 0,
            ExternalCreatedDateTime: null,
            SyncTechnicianId: this.selectedTechnician,
            SyncSourceTypeId: 3,
            ExternalId: "",
            ExternalUpdatedDateTime: null,
            SyncSourceType: null,
            SyncTechnician: null,
            SyncTechnicianCertificationType: null,
            SyncTechnicianCertificationTypeId: 7,
            IsDeleted: false,
            StartDate: null,
            UpdatedDateTime: null,
            UpdateUser: "",
            Country: "United States",
            State: ""
        };
        this.technicianCertifications.push(blankCert);
        this.syncTechnicianCertificationGrid.setDataSource(new kendo.data.DataSource({
            data: this.technicianCertifications
        }));

    }

    addTechBackgroundCheck() {
        var blankCert: SyncEntities.SyncTechnicianBackgroundCheck = {
            BackgroundCheckDate: null,
            Comment: "",
            CreatedDateTime: null,
            CreateUser: "",
            ExternalCreatedDateTime: null,
            ExternalId: "",
            ExternalUpdatedDateTime: null,
            IsDeleted: false,
            Passed: true,
            SyncSourceType: null,
            SyncSourceTypeId: 3,
            SyncTechnician: null,
            SyncTechnicianBackgroundCheckId: 0,
            SyncTechnicianId: this.selectedTechnician,
            UpdatedDateTime: null,
            UpdateUser: ""
        };
        this.technicianBackgroundChecks.push(blankCert);
        this.syncTechnicianBackgroundCheckGrid.setDataSource(new kendo.data.DataSource({
            data: this.technicianBackgroundChecks
        }));

    }
}