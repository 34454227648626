export interface IFranchiseeStateParams extends ng.ui.IStateParamsService {
    franchiseeID: string;
}

let fmRouterConfig = [
    "$stateProvider",
    "$urlMatcherFactoryProvider",
    ($stateProvider: ng.ui.IStateProvider, $urlMatcherFactoryProvider: ng.ui.IUrlMatcherFactory) =>
    {
        var fmIndexState = {
            name: 'fm',
            url: '/fm/:franchiseeID',
            resolve: { franchiseeID: ['$stateParams', ($stateParams) => { return $stateParams.franchiseeID; }] },
            templateUrl: ($stateParams: IFranchiseeStateParams) => { return '/Templates/FranchiseeManagement/FranchiseeManagementIndex.html' }
        };

        var fmGeneralState = {
            name: 'fm.general',
            url: '/general',
            templateUrl: ($stateParams: IFranchiseeStateParams) => { return '/Templates/FranchiseeManagement/fmGeneral.html' }
        };

        var fmEmailState = {
            name: 'fm.email',
            url: '/email',
            templateUrl: ($stateParams: IFranchiseeStateParams) => { return '/Templates/FranchiseeManagement/fmEmail.html' }
        }

        var fmContractsState = {
            name: 'fm.contracts',
            url: '/contracts',
            templateUrl: ($stateParams: IFranchiseeStateParams) => { return '/Templates/FranchiseeManagement/fmContracts.html' }
        }

        var fmPosState = {
            name: 'fm.pos',
            url: '/pos',
            templateUrl: ($stateParams: IFranchiseeStateParams) => { return '/Templates/FranchiseeManagement/fmPos.html' }
        }

        $urlMatcherFactoryProvider.strictMode(false);
        $stateProvider.state(fmIndexState);
        $stateProvider.state(fmGeneralState);
        $stateProvider.state(fmEmailState);
        $stateProvider.state(fmContractsState);
        $stateProvider.state(fmPosState);
    }
]

export { fmRouterConfig };