import { IdentityManager } from "../../../Services/Resources/IdentityManager";

export class rmsApprovalSectionsComponentController implements ng.IController {
    isLoading: boolean;
    sectionNames: string[];
    tab: string;

    hasAdminRole: boolean;
    hasRMSApprovalConfigurationRole: boolean;
    hasRMSApprovalConfigurationAdminRole: boolean;
    hasRMSApprovalQueueRole: boolean;
    hasRMSApprovalQueueAdminRole: boolean;
    hasRMSPostApprovalQueueRole: boolean;
    hasRMSPostApprovalQueueAdminRole: boolean;

    isApprovalConfigurationTabUnsaved: boolean;

    static $inject = [
        '$window',
        '$location',
        '$q',
        'identityManager',
        '$scope'
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private identityManager: IdentityManager,
        private $scope: ng.IScope
    ) {

    }

    async $onInit() {
        this.isApprovalConfigurationTabUnsaved = false;
        this.sectionNames = [];
        this.isLoading = false;

        this.hasAdminRole = false;
        this.hasRMSApprovalConfigurationRole = false;
        this.hasRMSApprovalConfigurationAdminRole = false;
        this.hasRMSApprovalQueueRole = false;
        this.hasRMSApprovalQueueAdminRole = false;
        this.hasRMSPostApprovalQueueRole = false;
        this.hasRMSPostApprovalQueueAdminRole = false;

        await this.getRequiredUserRoles();
        this.tab = this.GetSection();

        this.$scope.$on('setApprovalConfigurationTabUnsaved', (event, args) => {
            this.isApprovalConfigurationTabUnsaved = args.isApprovalConfigurationTabUnsaved;
        });

        this.$window.addEventListener("beforeunload", (event) => {
            if (this.isApprovalConfigurationTabUnsaved) {
                event.preventDefault();
                let message = "You have unsaved changes. Are you sure you want to leave?";
                (event || window.event).returnValue = message;
                return message;
            }
        })
    }

    async getRequiredUserRoles() {
        this.isLoading = true;
        await this.identityManager.GetLoggedInUserInfo().then(successResponse => {
            this.isLoading = false;
            if (successResponse && successResponse.data && successResponse.data.Roles && successResponse.data.Roles.length > 0) {

                /*check required roles*/
                successResponse.data.Roles.map(x => {

                    if (x.Name.trim().toLowerCase() == ("Admin").toLowerCase())
                        this.hasAdminRole = true;

                    if (x.Name.trim().toLowerCase() == ("RMSApprovalConfiguration").toLowerCase())
                        this.hasRMSApprovalConfigurationRole = true;

                    if (x.Name.trim().toLowerCase() == ("RMSApprovalConfigurationAdmin").toLowerCase())
                        this.hasRMSApprovalConfigurationAdminRole = true;

                    if (x.Name.trim().toLowerCase() == ("RMSApprovalQueue").toLowerCase())
                        this.hasRMSApprovalQueueRole = true;

                    if (x.Name.trim().toLowerCase() == ("RMSApprovalQueueAdmin").toLowerCase())
                        this.hasRMSApprovalQueueAdminRole = true;

                    if (x.Name.trim().toLowerCase() == ("RMSPostApprovalQueue").toLowerCase())
                        this.hasRMSPostApprovalQueueRole = true;

                    if (x.Name.trim().toLowerCase() == ("RMSPostApprovalQueueAdmin").toLowerCase())
                        this.hasRMSPostApprovalQueueAdminRole = true;

                    if (this.hasRMSApprovalQueueAdminRole || this.hasRMSApprovalQueueRole || this.hasAdminRole) {
                        if (this.sectionNames.indexOf("Pre-Approval Queue") < 0)
                            this.sectionNames.push("Pre-Approval Queue");
                    }

                    if (this.hasRMSApprovalConfigurationAdminRole || this.hasRMSApprovalConfigurationRole || this.hasAdminRole) {
                        if (this.sectionNames.indexOf("Approval Configurations") < 0)
                            this.sectionNames.push("Approval Configurations");
                    }

                    if (this.hasRMSPostApprovalQueueAdminRole || this.hasRMSPostApprovalQueueRole || this.hasAdminRole) {
                        if (this.sectionNames.indexOf("Post-Approval Queue") < 0)
                            this.sectionNames.push("Post-Approval Queue");
                    }

                    //this.sectionNames = this.sectionNames.sort().reverse();
                });

                if (this.sectionNames && this.sectionNames.length && this.sectionNames.length > 0) {
                    this.tab = this.GetSection();
                }

                this.$scope.$digest();

            }
        }).catch(ex => {
            this.isLoading = false;
            console.log("Error! Unable to get user roles.", ex);
        });
    }

    GetSection() {
        let section = this.$location.search()[nameof<rmsApprovalSectionsComponentController>(o => o.tab)];
        let isValid = this.IsValidSection(section);
        let result = isValid ? section : this.sectionNames[0]
        if (!isValid) {
            this.SetSection(result);
        }
        return result;
    }

    SetSection(sectionName: string) {

        if (this.tab == "Approval Configurations") {

            if (this.isApprovalConfigurationTabUnsaved) {
                let proceed = confirm("Are you sure you want to proceed without saving the changes?");
                if (!proceed) {
                    return;
                }
                else {
                    this.isApprovalConfigurationTabUnsaved = false;
                }
            }

            this.$scope.$broadcast('approvalQueueInit', {});
        }

        if (!this.IsValidSection(sectionName)) {
            throw "Invalid Section to Set: " + sectionName;
        }

        this.tab = sectionName;
        this.$location.search(nameof<rmsApprovalSectionsComponentController>(o => o.tab), sectionName);
    }

    IsValidSection(sectionName: string): boolean {
        return this.sectionNames.some(n => n === sectionName);
    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsApprovalSections", {
            bindings: {

            },
            templateUrl: '/Templates/RMSApproval/rmsApprovalSections.html',
            controller: rmsApprovalSectionsComponentController
        });
    }
}