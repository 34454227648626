import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { WebImageEntities } from "Interfaces/FranForce/WebImages/WebImageResources";

interface EmployeeBioRenderParameters  {
    apikey: string;
}
  

export class WebImageApiResources 
{
    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig",
        "$httpParamSerializer"
    ];


  

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig,
        private $httpParamSerializer: ng.IHttpParamSerializer 
    )
    {
        this.RefreshResources()
    }

    RefreshResources()
    {
    }

    WebImageRenderUrl(webImageGuid: string, imageRenderingParams: Partial<WebImageEntities.ImageRenderingParameters>) 
    {
        return `${this.apiConfig.AppSettings.CoreApiRootUrl}/WebImages/${webImageGuid}/Render?${this.$httpParamSerializer(imageRenderingParams)}`;
    } 

    EmployeeBioRenderUrl(webImageId: number) 
    {
        return `${this.apiConfig.AppSettings.FranchiseEmployeeOrchestrationsApiRootUrl}/public/web-images/${webImageId}?apikey=${this.apiConfig.AppSettings.FranchiseEmployeeOrchestrationsApiKey}`;
    } 

    WebImageUploadUrl(webImageParameters: Partial<WebImageEntities.WebImage>) 
    {
        return `${this.apiConfig.AppSettings.CoreApiRootUrl}/WebImages/Upload?${this.$httpParamSerializer(webImageParameters)}`;
    } 

    EmployeeBioImageUploadUrl(webImageParameters: Partial<WebImageEntities.WebImage>)
    {
        return `${this.apiConfig.AppSettings.FranchiseEmployeeOrchestrationsApiRootUrl}/protected/web-images/Upload?${this.$httpParamSerializer(webImageParameters)}`;
    }
    
    WebImageQuery(odataParameters: ODataQueryParameters)
    {
        return this.odataHelper.GetStandardCoreODataQuery<WebImageEntities.WebImageWithoutContent>("WebImages", odataParameters);
    }
    
    WebImageGet(id: number, odataParameters: ODataQueryParameters)
    {
        return this.odataHelper.GetStandardCoreODataGet<WebImageEntities.WebImageWithoutContent>("WebImages", id, odataParameters);
    }

    WebImageTypeQuery(odataParameters: ODataQueryParameters)
    {
        return this.odataHelper.GetStandardCoreODataQuery<WebImageEntities.WebImageType>("WebImageTypes", odataParameters);
    }

    WebImageTypeGet(id: number, odataParameters: ODataQueryParameters)
    {
        return this.odataHelper.GetStandardCoreODataGet<WebImageEntities.WebImageType>("WebImageTypes", id, odataParameters);
    }
}