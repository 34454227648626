import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmResources } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

interface IBMFranchiseeStateParams extends ng.ui.IStateParamsService
{
    franchiseId?: string|number;
}

export class bmFranchiseeController implements ng.IController
{
    selectedFranchiseId: number;
    periodFilters: kendo.data.DataSourceFilter;

    readOnly: boolean;
    conceptFilters: kendo.data.DataSourceFilter;
    franchisorFilters: kendo.data.DataSourceFilter;

    static $inject = [
        '$scope',
        '$stateParams',
        'coreApiResources',
        'rpmApiResources',
        'identityManager',
        '$state',
        '$q',
    ]

    constructor(
        private $scope: ng.IScope,
        private $stateParams: IBMFranchiseeStateParams,
        private coreApiResources: CoreApiResources,
        private rpmApiResources: RpmApiResources,
        private identityManager: IdentityManager,
        private $state: ng.ui.IStateService,
        private $q: ng.IQService,
    ) {
        
    }

    $onInit() 
    {
        let adminPromise = this.identityManager.HasRole(["BatchManagementAdmin", "Admin"])
            .then(isAdmin =>
            {
                this.readOnly = !isAdmin;
            });

        this.InitFilters();

        if (this.$stateParams.franchiseId)
        {
            this.selectedFranchiseId = parseInt(<string>this.$stateParams.franchiseId);
            this.periodFilters = <kendo.data.DataSourceFilterItem>{
                operator: "eq",
                field: nameof<RpmResources.IRoyaltyPeriodReport>(o => o.FranchiseId),
                value: this.selectedFranchiseId 
            };
        }

        this.$scope.$watch(() => this.selectedFranchiseId, () =>
        {
            if (this.selectedFranchiseId)
            {
                this.$stateParams.franchiseId = this.selectedFranchiseId;
                this.$state.go("bm.franchise", this.$stateParams);
            }
        });
    }


    InitFilters()
    {
        this.conceptFilters = <kendo.data.DataSourceFilterItem>{
            field: nameof<CoreEntities.Concept>(o => o.RoyaltySystemId),
            operator: "isnotnull"
        };

        this.franchisorFilters = <kendo.data.DataSourceFilterItem>{
            field: nameof<CoreEntities.Franchisor>(o => o.RoyaltySystemId),
            operator: "isnotnull"
        };
    }
}
