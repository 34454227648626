import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltySyncInvoiceDetailsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltySyncInvoiceDetailsBySyncInvoice(syncInvoiceId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltySyncInvoiceDetails/BySyncInvoice/" + syncInvoiceId
        };

        return this.$http<RpmEntities.RoyaltySyncInvoiceDetailViewModel[]>(config);
    }
}