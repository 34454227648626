import { FranchiseEntityRelationshipApplicationsDropDownOnSelect, FranchiseEntityRelationshipDetailsGridOnInit, FranchiseEntityRelationshipTypesDropDownOnSelect } from 'Directives/RPM/FranchiseEntity';
import { FranchiseEntityRelationshipDetailsData, FranchiseEntityRelationshipSelectedPrimary } from 'Directives/RPM/FranchiseEntity/FranchiseEntityRelationshipDetailsGrid';
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import * as elements from 'typed-html';
import { StringProperties } from "Types/StringProperties";
import { franchiseEntityRelationshipDeatilDataModel } from "../../../Models/Royalty/FranchiseEntities/FranchiseEntityRelationshipDetailDataModel";
import { franchiseEntityDeleteResponseDataModel } from "../../../Models/Royalty/FranchiseEntities/FranchiseEntityDeleteResponseDataModel";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            rmsFranchiseEntityRelationshipEditor: Partial<StringProperties<FranchiseEntityRelationshipEditor>>;
        }
    }
}

export type FranchiseEntityRelationshipEditorOnAction = {
    actionType: "Create" | "Update" | "Cancel" | "Delete";
    details: franchiseEntityRelationshipDeatilDataModel;
} 

export type FranchiseEntityRelationshipEditorOnInit = {
    loadEditorFunc: (franchiseEntityId: number, franchiseEntityRelationshipId: number) => Promise<any>;
} 



export class FranchiseEntityRelationshipEditor implements ng.IController 
{
    onInit: (params: FranchiseEntityRelationshipEditorOnInit) => void;
    onAction: (params: FranchiseEntityRelationshipEditorOnAction) => void;

    franchiseEntityId: number;
    franchiseEntityRelationshipId: number;
    franchiseEntityRelationshipAppId: number;
    isLoading: boolean;
    relationship: franchiseEntityRelationshipDeatilDataModel;
    detailFunctions: FranchiseEntityRelationshipDetailsGridOnInit;
    effectiveDateVal: Date;
    effectiveDatePicker: kendo.ui.DatePicker;
    expirationDateVal: Date;
    expirationDatePicker: kendo.ui.DatePicker;
    detailRows: any[];
    franchiseEntityRelationshipTypes: number[];
    primarySelectedFranchiseId: number;

    errorMessage: string;
    showErrorMessaage: boolean;

    static $inject = [
        "$timeout",
        "royaltyApiResources"
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private royaltyApiResources: RoyaltyApiResources,
    )
    {
        this.detailRows = [];
        this.showErrorMessaage = false;
    }

    $onInit()
    {
        if (this.onInit)
        {
            this.onInit({
                loadEditorFunc: async (franchiseEntityId: number, franchiseEntityRelationshipId: number) =>
                {
                    this.showErrorMessaage = false;
                    return await this.InitData(franchiseEntityId, franchiseEntityRelationshipId);
                }
            })
        }
    }

    $postLink()
    {

    }

    async InitData(franchiseEntityId: number, franchiseEntityRelationshipId: number)
    {
        this.franchiseEntityId = franchiseEntityId;
        this.franchiseEntityRelationshipId = franchiseEntityRelationshipId;

            if (this.franchiseEntityRelationshipId!=0)
            {
                this.royaltyApiResources.GetFranchiseEntityRelationshipDetailsByFranchiseEntityRelationshipId(this.franchiseEntityRelationshipId, true)
                    .then(
                        (response) => {
                            if (response && response.data) {
                                this.franchiseEntityRelationshipTypes = response.data.franchiseEntityRelationshipTypes;
                                this.relationship = {
                                    franchiseEntityRelationshipId: response.data.franchiseEntityRelationshipId,
                                    franchiseEntityId: response.data.franchiseEntityId,
                                    franchiseEntityRelationshipApplicationId: response.data.franchiseEntityRelationshipApplicationId,
                                    franchiseEntityRelationshipTypes: response.data.franchiseEntityRelationshipTypes,
                                    effectiveDate: (new Date(response.data.effectiveDate)).toISOString(),
                                    expirationDate: (new Date(response.data.expirationDate)).toISOString(),
                                    isDeleted: response.data.isDeleted,
                                    relationshipDetails: response.data.relationshipDetails
                                }                     
                            }
                        });
            }
            else
            {
                this.relationship = {
                    franchiseEntityRelationshipId: 0,
                    franchiseEntityId: this.franchiseEntityId,
                    franchiseEntityRelationshipApplicationId: 0,
                    franchiseEntityRelationshipTypes: [],
                    effectiveDate: null,
                    expirationDate: null,
                    isDeleted: false,
                    relationshipDetails: []
                }  

            };

            await this.detailFunctions.Cancel();
    }

    Create()
    {
        if (this.relationship && !this.relationship.franchiseEntityRelationshipApplicationId) {
            this.showErrorMessaage = true;
            this.errorMessage = "Please select Application Type";
            return;
        }

        if (this.relationship.effectiveDate) {
            this.relationship.effectiveDate = new Date(this.relationship.effectiveDate).toISOString();
        }
        else {
            this.showErrorMessaage = true;
            this.errorMessage = "Please select valid effective Date";
            return;
        }

        if (this.relationship.expirationDate) {
            this.relationship.expirationDate = new Date(this.relationship.expirationDate).toISOString();
        }
        else {
            this.showErrorMessaage = true;
            this.errorMessage = "Please select valid expiration Date";
            return;
        }

        if (this.relationship.effectiveDate >= this.relationship.expirationDate) {
            this.showErrorMessaage = true;
            this.errorMessage = "Expiration Date should be greater than Effective Date";
            return;
        }

        if (this.relationship.expirationDate < new Date().toISOString()) {
            this.showErrorMessaage = true;
            this.errorMessage = "Expiration Date should not be past date";
            return;
        }

        if (this.relationship && this.relationship.franchiseEntityRelationshipTypes && this.relationship.franchiseEntityRelationshipTypes.length < 1) {
            this.showErrorMessaage = true;
            this.errorMessage = "Please select Relationship Type";
            return;
        }

        if (this.detailRows && this.detailRows.length > 0) {
            this.detailRows.forEach(item => {
                let relationshipDetailObj = {
                    franchiseEntityRelationshipDetailId: 0,
                    franchiseEntityRelationshipId: 0,
                    franchiseId: item.FranchiseId,
                    isPrimary: this.primarySelectedFranchiseId == item.FranchiseId?true:false,
                    isDeleted: false
                }
                this.relationship.relationshipDetails.push(relationshipDetailObj)
            })
        }

        if (this.relationship && this.relationship.relationshipDetails && this.relationship.relationshipDetails.length < 2) {
            this.relationship.relationshipDetails = [];
            this.showErrorMessaage = true;
            this.errorMessage = "Relationship Details should have at least two franchises";
            return;
        }

        let containsPrimaryFranchise = false;
        if (this.relationship && this.relationship.relationshipDetails && this.relationship.relationshipDetails.length > 1) {
            this.relationship.relationshipDetails.forEach(x => {
                if (x.isPrimary == true) {
                    containsPrimaryFranchise = true;
                }
            })
        }

        if (!containsPrimaryFranchise) {
            this.relationship.relationshipDetails = [];
            this.showErrorMessaage = true;
            this.errorMessage = "Relationship should have one primary franchise";
            return;
        }

        this.isLoading = true;
        this.detailRows = [];
        this.primarySelectedFranchiseId = 0;
        this.royaltyApiResources.CreateFranchiseEntityRelationship(this.franchiseEntityId, this.relationship)
            .then(
                (response) => {
                    this.isLoading = false;
                    if (response && response.data) {
                        let createdFranchiseRelationshipId = response.data.franchiseEntityRelationshipId;
                        //this.detailFunctions.Sync(createdFranchiseRelationshipId);
                        this.ResetAllFields();
                        if (this.onAction)
                            this.onAction({ actionType: "Create", details: null });
                    }
                }
            )
            .catch(error => {
                this.isLoading = false;
                this.showErrorMessaage = true;

                let errorString = "";
                let errorObj = error.data.errors
                Object.keys(error.data.errors).forEach(function (key, index) {
                    errorString = errorString + "," + errorObj[key].join();
                });
                var errorListArr = errorString.replace(/^,/, '').split(',');
                if (errorListArr && errorListArr.length > 0) {
                    this.errorMessage = errorListArr[0];
                }
            });
    }

    async Update()
    {
        if (this.relationship.effectiveDate) {
            this.relationship.effectiveDate = new Date(this.relationship.effectiveDate).toISOString();
        }
        else {
            this.showErrorMessaage = true;
            this.errorMessage = "Please enter valid effective Date";
            return;
        }

        if (this.relationship.expirationDate) {
            this.relationship.expirationDate = new Date(this.relationship.expirationDate).toISOString();
        }
        else {
            this.showErrorMessaage = true;
            this.errorMessage = "Please enter valid expiration Date";
            return;
        }

        this.relationship.effectiveDate = new Date(this.relationship.effectiveDate).toISOString();
        this.relationship.expirationDate = new Date(this.relationship.expirationDate).toISOString();
        this.isLoading = true;
        this.detailRows = [];
        this.primarySelectedFranchiseId = 0;
        this.royaltyApiResources.UpdateFranchiseEntityRelationship(this.franchiseEntityRelationshipId, this.relationship)
            .then(
                (response) => {
                    this.isLoading = false;
                    if (response && response.data) {
                        this.detailFunctions.Sync(this.relationship.franchiseEntityRelationshipId);
                        this.ResetAllFields();
                        if (this.onAction)
                            this.onAction({ actionType: "Update", details: this.relationship });
                    }
                }
        ).catch(error => {
            this.isLoading = false;
            this.showErrorMessaage = true;

            let errorString = "";
            let errorObj = error.data.errors
            Object.keys(error.data.errors).forEach(function (key, index) {
                errorString = errorString + "," + errorObj[key].join();
            });
            var errorListArr = errorString.replace(/^,/, '').split(',');
            if (errorListArr && errorListArr.length > 0) {
                this.errorMessage = errorListArr[0];
            }
        });
    }

    async Delete()
    {
        let deleteResponseData: franchiseEntityDeleteResponseDataModel;
        await this.detailFunctions.Delete();
        this.isLoading = true;
        this.royaltyApiResources.DeleteByFranchiseEntityRelationshipId(this.franchiseEntityRelationshipId)
            .then(
                (response) => {
                    this.isLoading = false;
                    if (response && response.data) {
                        deleteResponseData = response.data;
                        this.ResetAllFields();
                        if (this.onAction)
                            this.onAction({ actionType: "Delete", details: deleteResponseData });
                    }
                }
        ).catch(error => {
            alert("Error: unable to delete franchise entity relationship");
            this.isLoading = false;
        });
    }

    ResetAllFields() {
        this.relationship.effectiveDate = null;
        this.relationship.expirationDate = null;
        this.relationship.franchiseEntityRelationshipTypes = [];
        this.relationship.franchiseEntityRelationshipApplicationId = 0;
    }

    async Cancel()
    {    
        await this.detailFunctions.Cancel();
        this.ResetAllFields();
        if (this.onAction)
            this.onAction({ actionType: "Cancel", details: this.relationship });
    }

    OnPrimarySelect(primarySelectedFranchiseId: FranchiseEntityRelationshipSelectedPrimary["primarySelectedFranchiseId"]) {
        this.primarySelectedFranchiseId = primarySelectedFranchiseId;
        if (this.relationship && this.relationship.relationshipDetails && this.relationship.relationshipDetails.length > 0) {
            this.relationship.relationshipDetails.forEach((item) => {
                item.isPrimary = false;
                if (item.franchiseId == this.primarySelectedFranchiseId) {
                    item.isPrimary = true;
                }
            })
        }
    }

    OnFranchiseEntitySelect(detailData: FranchiseEntityRelationshipDetailsData["detailData"]) {
        if (detailData["Selected"]) {
            this.detailRows.push(detailData);
        }
        else {
            this.detailRows = this.detailRows.filter(item => item["FranchiseId"] != detailData["FranchiseId"]);
        }
    }

    OnFranchiseEntityRelationshipApplicationSelect(entity: FranchiseEntityRelationshipApplicationsDropDownOnSelect["entity"])
    {
        this.relationship.franchiseEntityRelationshipApplicationId = entity["franchiseEntityRelationshipApplicationId"];
        this.franchiseEntityRelationshipAppId = entity["franchiseEntityRelationshipApplicationId"];
        this.relationship.franchiseEntityRelationshipTypes = [];
    }

    OnFranchiseEntityRelationshipTypeSelect(entity: FranchiseEntityRelationshipTypesDropDownOnSelect["entity"])
    {
        this.relationship.franchiseEntityRelationshipTypes.push(parseInt(entity["franchiseEntityRelationshipTypeBitId"], 10))
    }

    OnFranchiseEntityRelationshipTypeDeselect(entity: FranchiseEntityRelationshipTypesDropDownOnSelect["entity"]) {
        if (this.relationship.franchiseEntityRelationshipTypes && this.relationship.franchiseEntityRelationshipTypes.length > 0) {
            this.relationship.franchiseEntityRelationshipTypes =
                this.relationship.franchiseEntityRelationshipTypes.filter(item => item != entity["franchiseEntityRelationshipTypeBitId"]);
        }
    }

    DetailGridInit(
        Sync: FranchiseEntityRelationshipDetailsGridOnInit["Sync"],
        Cancel: FranchiseEntityRelationshipDetailsGridOnInit["Cancel"],
        Delete: FranchiseEntityRelationshipDetailsGridOnInit["Delete"]
    )
    {
        this.detailFunctions = {
            Sync: Sync,
            Cancel: Cancel,
            Delete: Delete
        };
    }

    static BindComponent(app: ng.IModule)
    {
        let loadingTemplate = (
            <loadingGearsOverlay isLoading={`$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.isLoading)}`} ></loadingGearsOverlay>
        );

        let editors = (
            <div>
                <div class="pure-g gutters">
                    <div class="pure-u-1 pure-u-md-1-3">
                        <label for="applicationType" class="">Application Type</label>
                        <br />
                        <rmsFranchiseEntityRelationshipApplicationsDropDown
                            franchiseEntityRelationshipApplicationId={`$ctrl.${nameof.full<FranchiseEntityRelationshipEditor>(o => o.relationship.franchiseEntityRelationshipApplicationId)}`}
                            onSelect={`$ctrl.${nameof.full<FranchiseEntityRelationshipEditor>(o => o.OnFranchiseEntityRelationshipApplicationSelect)}(${nameof.full<FranchiseEntityRelationshipApplicationsDropDownOnSelect>(o => o.entity)})`}>
                        </rmsFranchiseEntityRelationshipApplicationsDropDown>
                    </div>
                    <div class="pure-u-1 pure-u-md-1-3">
                        <label for="" class="">Effective Date</label>
                        <input
                            id="effectiveDatePicker"
                            style="width:100%;"
                            kendo-date-picker={`$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.effectiveDatePicker)}`}
                            k-ng-model={`$ctrl.${nameof.full<FranchiseEntityRelationshipEditor>(o => o.relationship.effectiveDate)}`} />
                    </div>
                    <div class="pure-u-1 pure-u-md-1-3">
                        <label for="" class="">Expiration Date</label>
                        <input
                            id="expirationDatePicker"
                            style="width:100%;"
                            kendo-date-picker={`$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.expirationDatePicker)}`}
                            k-ng-model={`$ctrl.${nameof.full<FranchiseEntityRelationshipEditor>(o => o.relationship.expirationDate)}`} />
                    </div>
                </div>
                <div class="pure-g gutters">
                    <div class="pure-u-1 pure-u-md-1-3" style="margin-top:20px">
                        <label for="" class="">Relationship Type</label>
                        <rmsFranchiseEntityRelationshipTypesDropDown
                            franchiseEntityRelationshipApplicationId={`$ctrl.${nameof.full<FranchiseEntityRelationshipEditor>(o => o.franchiseEntityRelationshipAppId)}`}
                            franchiseEntityRelationshipType={`$ctrl.${nameof.full<FranchiseEntityRelationshipEditor>(o => o.relationship.franchiseEntityRelationshipTypes)}`}
                            onSelect={`$ctrl.${nameof.full<FranchiseEntityRelationshipEditor>(o => o.OnFranchiseEntityRelationshipTypeSelect)}(${nameof.full<FranchiseEntityRelationshipTypesDropDownOnSelect>(o => o.entity)})`}
                            onDeselect={`$ctrl.${nameof.full<FranchiseEntityRelationshipEditor>(o => o.OnFranchiseEntityRelationshipTypeDeselect)}(${nameof.full<FranchiseEntityRelationshipTypesDropDownOnSelect>(o => o.entity)})`}>
                        </rmsFranchiseEntityRelationshipTypesDropDown>
                    </div>
                </div>
                <div style="margin-top:20px" ng-show={`$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.showErrorMessaage)}`}>
                    <span ng-bind={`$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.errorMessage)}`} style="color: red">
                    </span>
                </div>
            </div>
            
        );

        let detailsGrid = (
            <rmsFranchiseEntityRelationshipDetailsGrid
                franchiseEntityId={`$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.franchiseEntityId)}`}
                franchiseEntityRelationshipId={`$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.franchiseEntityRelationshipId)}`}
                onSelectRow={`$ctrl.${nameof.full<FranchiseEntityRelationshipEditor>(o => o.OnFranchiseEntitySelect)}(${nameof.full<FranchiseEntityRelationshipDetailsData>(o => o.detailData)})`}
                onPrimarySelect={`$ctrl.${nameof.full<FranchiseEntityRelationshipEditor>(o => o.OnPrimarySelect)}(${nameof.full<FranchiseEntityRelationshipSelectedPrimary>(o => o.primarySelectedFranchiseId)})`}
                onInit={
                    `$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.DetailGridInit)}(` +
                        `${nameof<FranchiseEntityRelationshipDetailsGridOnInit>(o => o.Sync)}, ` +
                        `${nameof<FranchiseEntityRelationshipDetailsGridOnInit>(o => o.Cancel)}, ` +
                        `${nameof<FranchiseEntityRelationshipDetailsGridOnInit>(o => o.Delete)}` +
                    `)`
                }
            >
            </rmsFranchiseEntityRelationshipDetailsGrid>
        );

        let buttonsTemplate = (
            <div style="margin-top:20px;">
                <button
                    ng-show={`$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.franchiseEntityRelationshipId)}`}
                    promise-btn
                    ng-click={`!$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.Delete)}()`}>
                    Delete
                </button>
                <button
                    promise-btn
                    ng-click={`!$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.Cancel)}()`}>
                    Cancel
                </button>
                <button
                    ng-show={`$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.franchiseEntityRelationshipId)}`}
                    promise-btn
                    ng-click={`!$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.Update)}()`}>
                    Update
                </button>
                <button
                    ng-show={`!$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.franchiseEntityRelationshipId)}`}
                    promise-btn
                    ng-click={`!$ctrl.${nameof<FranchiseEntityRelationshipEditor>(o => o.Create)}()`}>
                    Create
                </button>
            </div>
        );

        let template = `${loadingTemplate} ${editors} ${detailsGrid} ${buttonsTemplate}`;
        let componentName = nameof<JSX.IntrinsicElements>(o => o.rmsFranchiseEntityRelationshipEditor);

        app.component(componentName, {
            bindings: {
                [nameof<FranchiseEntityRelationshipEditor>(o => o.franchiseEntityId)]: "<",
                [nameof<FranchiseEntityRelationshipEditor>(o => o.franchiseEntityRelationshipId)]: "<",
                [nameof<FranchiseEntityRelationshipEditor>(o => o.onInit)]: "&?",
                [nameof<FranchiseEntityRelationshipEditor>(o => o.onAction)]: "&?",
            },
            controller: FranchiseEntityRelationshipEditor,
            template: template
        });
    }
}

