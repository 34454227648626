import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AngularUtil } from "Utility/AngularUtil";
import { KendoUtil } from "Utility/KendoUtil";
import { WebImageApiResources } from "Services/Resources/WebImageApiResources";
import { onUploadCompleteParametersEvent } from "Directives/Common/UploadImage";
import { CoreEntities, CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { WebImageEntities } from "Interfaces/FranForce/WebImages/WebImageResources";

export type onUpdateEmployeeBioEvent = { bio: CoreEntities.FranchiseProfileEmployeeBio };
export type onDeleteEmployeeBioEvent = { bio: CoreEntities.FranchiseProfileEmployeeBio };

export class FranchiseProfileEmployeeBioEditComponent implements ng.IController 
{
    franchiseProfileEmployeeBio: CoreEntities.FranchiseProfileEmployeeBio;
    linkedUser: IManagedUserViewModel;
    isFranchisee: boolean = false;
    onUpdate: (params: { event: onUpdateEmployeeBioEvent }) => void;
    onDelete: (params: { event: onDeleteEmployeeBioEvent }) => void;

    isLoading: boolean;

    bioImageUrl: string;
    saveBioImageUrl: string;
    isLinkedToPosEmployee: boolean;
    tooltip: kendo.ui.Tooltip;
    tooltipContent: string;

    static $inject = [
        'coreApiResources',
        'identityManager',
        'webImageApiResources',
        'apiConfig',
        '$timeout'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private webImageApiResources: WebImageApiResources,
        private apiConfig: ApiConfig,
        private $timeout: ng.ITimeoutService
    )
    {
    }

    $onInit()
    {
        this.identityManager.GetLoggedInUserInfo()
            .then((userResponse) =>
            {
                this.isFranchisee = userResponse.data.Roles.some(r => r.Name === this.apiConfig.FranForceConstants.RoleConstants.FranchiseeRole)
            });
    }

    $postLink()
    {

    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.franchiseProfileEmployeeBio)])
        {
            this.InitBio();
        }

        if (changes[nameof(this.linkedUser)])
        {
            this.InitUser()
        }
    }

    InitBio()
    {
        if (!this.franchiseProfileEmployeeBio)
            return;

        this.SetBioImageUrl(this.franchiseProfileEmployeeBio.WebImageId);
            
        const franchiseBioWebImageTypeId = 2;

        this.saveBioImageUrl = this.webImageApiResources.WebImageUploadUrl({
            FranchiseId: this.franchiseProfileEmployeeBio.FranchiseId,
            WebImageTypeId: franchiseBioWebImageTypeId
        });

        this.tooltipContent = `This bio is not linked to a Point of Sale employee. This can be managed 
            <a href='/Users/Edit/${this.franchiseProfileEmployeeBio.AspNetUserId}#?tab=Permissions' target='_blank'>here</a>.`;
    }

    InitUser()
    {
        if (!this.linkedUser)
            return;

        this.isLinkedToPosEmployee = this.linkedUser.Claims
            .some(c => c.Type == this.apiConfig.FranForceConstants.ClaimConstants.AssignedPosEmployeeIdClaimType);
    }

    OnUploadComplete(event: onUploadCompleteParametersEvent)
    {
        let webImageId = parseInt(event.createdId);
        let promise = this.coreApiResources.SetFranchiseProfileEmployeeBioImage(this.franchiseProfileEmployeeBio.FranchiseProfileEmployeeBioId, webImageId)
            .then(async () =>
            {
                this.franchiseProfileEmployeeBio.WebImageId = webImageId;
                await this.SetBioImageUrl(webImageId);
                this.onUpdate({ event: { bio: this.franchiseProfileEmployeeBio } });

                this.$timeout(() =>
                {
                    event.e.sender.clearAllFiles();
                }, 2000);
            });

        AngularUtil.TrackLoadingPromise(promise, this);
    }

    UpdateBio()
    {
        let promise = this.coreApiResources.FranchiseProfileEmployeeBioApi
            .patch(
                { id: this.franchiseProfileEmployeeBio.FranchiseProfileEmployeeBioId },
                {
                    FranchiseProfileEmployeeBioId: this.franchiseProfileEmployeeBio.FranchiseProfileEmployeeBioId,
                    DisplayName: this.franchiseProfileEmployeeBio.DisplayName,
                    Description: this.franchiseProfileEmployeeBio.Description
                } as Partial<CoreResources.IFranchiseProfileEmployeeBio>
            ).$promise
            .then((bio) =>
            {
                if (this.onUpdate)
                    this.onUpdate({ event: { bio: bio } });

                this.SetBioImageUrl(bio.WebImageId);
            });

        return AngularUtil.TrackLoadingPromise(promise, this);
    }

    DeleteBio()
    {
        let promise = this.coreApiResources.FranchiseProfileEmployeeBioApi
            .delete({ id: this.franchiseProfileEmployeeBio.FranchiseProfileEmployeeBioId }).$promise
            .then((bio) =>
            {
                this.onDelete({ event: { bio: bio } });
            });

        return AngularUtil.TrackLoadingPromise(promise, this);
    }

    private async SetBioImageUrl(webImageId: number)
    {
        if (!webImageId)
            return null;

        let response = await this.webImageApiResources.WebImageGet(webImageId, {
            $select: nameof<WebImageEntities.WebImageWithoutContent>(o => o.WebImageGuid)
        });

        this.bioImageUrl = (
            webImageId ?
                this.webImageApiResources.WebImageRenderUrl(response.data.WebImageGuid, { IsInline: true }) :
                null
        );
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfileEmployeeBioEdit', {
            bindings: {
                [nameof<FranchiseProfileEmployeeBioEditComponent>(o => o.franchiseProfileEmployeeBio)]: "<",
                [nameof<FranchiseProfileEmployeeBioEditComponent>(o => o.linkedUser)]: "<",
                [nameof<FranchiseProfileEmployeeBioEditComponent>(o => o.onUpdate)]: "&",
                [nameof<FranchiseProfileEmployeeBioEditComponent>(o => o.onDelete)]: "&"
            },
            controller: FranchiseProfileEmployeeBioEditComponent,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfileEmployeeBioEdit.html"
        });
    }
}


