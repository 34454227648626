export class FormErrorDisplayComponentController implements ng.IController 
{
    form: ng.IFormController
    validator: kendo.ui.Validator

    static $inject = [

    ]

    constructor(

    )
    {

    }

    $onInit()
    {
        
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        
    }

    GetErrors()
    {
        if (this.validator) {
            return this.validator.errors();
        }
        else {
            return this.form.$error
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("formErrorDisplay", {
                bindings: {
                    [nameof<FormErrorDisplayComponentController>(o => o.form)]: "<"
                },
                templateUrl: "/Templates/Common/FormErrorDisplay.html",
                controller: FormErrorDisplayComponentController
            });
    }
}

