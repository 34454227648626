import * as elements from 'typed-html';
import { StringProperties } from "Types/StringProperties";

/**
    Requires parent container to have two styles:
    - Height, (block/inline-block) or defined height
    - position: relative
 */

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            loadingGearsOverlay: Partial<StringProperties<LoadingGearsOverlayComponentController>>;
        }
    }
}


/**
    Requires parent container to have two styles:
    - Height, (block/inline-block) or defined height
    - position: relative
 */
export class LoadingGearsOverlayComponentController implements ng.IController
{
    isLoading: boolean;

    static $inject = [];
    
    constructor()
    {
    }

    $onInit()
    {
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.loadingGearsOverlay);

        app.component(componentName, {
            bindings: {
                [nameof<LoadingGearsOverlayComponentController>(o => o.isLoading)]: "<",
            },
            controller: LoadingGearsOverlayComponentController,
            template: (
                <div class="wrapper" ng-show={`$ctrl.${nameof<LoadingGearsOverlayComponentController>(o => o.isLoading)}`}>
                    <img src='/Content/css/images/icons/loading-gears.gif' /> 
                </div>
            ),
        });
    }
}

