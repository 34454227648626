import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RmsStateService } from "Services/State/RmsState";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { KendoHelperService } from "Services/Utility/KendoHelperService";

import * as _ from "underscore"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class cmRoyaltiesServiceCategoriesComponentController implements ng.IController
{

    contractRatePlanItemId: number;
    franchiseId: number;
    readOnly: boolean;
    onLoaded: (params: { self: cmRoyaltiesServiceCategoriesComponentController }) => void;

    isLoading: boolean; 
    royaltyServiceCategories: RpmEntities.RoyaltyServiceCategoryViewModel[];
    royaltyServiceCategoryTypes: RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result[];
    serviceCategoryGridOptions: kendo.ui.GridOptions;
    serviceCategoryGrid: kendo.ui.Grid;
    formController: angular.IFormController;

    addAllCategoriesWindow: kendo.ui.Window;
    addAllRateTextBoxOptions: kendo.ui.NumericTextBoxOptions;
    addAllServiceCategoryModel: Partial<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>;

    isDynamicFees: boolean;

    static $inject = [
        'rpmUiApiResources',
        'coreApiResources',
        'rpmHelper',
        'identityManager',
        'kendoHelper',
        '$q',
        '$timeout'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private coreApiResources: CoreApiResources,
        private rpmHelper: RpmHelperService,
        private identityManager: IdentityManager,
        private kendoHelper: KendoHelperService,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService)
    {

    }

    $onInit()
    {
        this.isLoading = true;
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledCm').val() === 'True';
        this.InitServiceCategoryGrid();
        if (this.franchiseId)
        {
            this.LoadServiceCategories();
        }
    }

    $postLink()
    {
        this.addAllCategoriesWindow.setOptions(<kendo.ui.WindowOptions>{
            modal: true,
            title: "Add For All Service Categories",
            pinned: true
        });

        this.addAllRateTextBoxOptions = {
            spinners: false,
            format: this.rpmHelper.StringFormats.Number.RatePercentage,
            decimals: 7,
            min: 0,
            max: 1,
        }
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes.franchiseId && changes.franchiseId.currentValue)
        {
            this.LoadServiceCategories();
        }

        if (changes.contractRatePlanItemId)
        {
            this.$timeout(() => { this.ResetChanges(); }, 0);
        }
    }

    LoadServiceCategories()
    {
        this.isLoading = true;
        
        return this.coreApiResources.FranchiseApi.get({ id: this.franchiseId, $select: nameof<CoreResources.IFranchise>(o => o.FranchisorId) }).$promise
            .then((franchise) => {
                return franchise.FranchisorId;
            })
            .then((franchisorId) => {
                return this.$q.all([
                    this.rpmUiApiResources.GetRoyaltyServiceCategoriesByFranchisor(franchisorId)
                        .then((serviceCategoriesResponse) =>
                        {
                            this.royaltyServiceCategories = serviceCategoriesResponse.data
                        }),
                    this.rpmUiApiResources.GetAllRoyaltyServiceCategoryTypes()
                        .then((categoryTypesResponse) =>
                        {
                            this.royaltyServiceCategoryTypes = categoryTypesResponse.data;
                        })
                ]);
            })
            .finally(() => {
                this.isLoading = false;
                this.onLoaded({ self: this });
            });
    }

    InitServiceCategoryGrid() {
        let scDataSource = new kendo.data.DataSource({
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {
                    if (!this.contractRatePlanItemId)
                    {
                        options.success([]);
                        return;
                    }
                    if (this.isDynamicFees) {
                        this.rpmUiApiResources.GetRoyaltyContractRatePlanItemDetailsByContractPlanItemDynamic(this.contractRatePlanItemId)
                            .then((itemDetailsResponse) => {
                                options.success(itemDetailsResponse.data);
                            }, (err) => { options.error(err); })
                    }
                    else
                    {
                        this.rpmUiApiResources.GetRoyaltyContractRatePlanItemDetailsByContractPlanItem(this.contractRatePlanItemId)
                            .then((itemDetailsResponse) => {
                                options.success(itemDetailsResponse.data);
                            }, (err) => { options.error(err); })
                    }
                },
                create: (options: kendo.data.DataSourceTransportOptions) => {

                    let createModel: RpmEntities.RoyaltyContractRatePlanItemDetailViewModel = options.data;
                    this.rpmUiApiResources.CreateRoyaltyContractRatePlanItemDetail(createModel)
                        .then((itemDetailResponse) => {
                            createModel.ContractRatePlanItemDetailId = itemDetailResponse.data
                            options.success({ ContractRatePlanItemDetailId: createModel.ContractRatePlanItemDetailId});
                        }, (err) => { options.error(err); });
                },
                update: (options: kendo.data.DataSourceTransportOptions) => {
                    let updateModel: RpmEntities.RoyaltyContractRatePlanItemDetailViewModel = options.data;
                    this.rpmUiApiResources.UpdateRoyaltyContractRatePlanItemDetail(updateModel.ContractRatePlanItemDetailId, updateModel)
                        .then((idResponse) => {
                            options.success();
                        }, (err) => { options.error(err); });
                },
                destroy: (options: kendo.data.DataSourceTransportOptions) => {
                    let deleteModel: RpmEntities.RoyaltyContractRatePlanItemDetailViewModel = options.data;
                    deleteModel.DeletedDateTime = new Date().toISOString();
                    this.rpmUiApiResources.UpdateRoyaltyContractRatePlanItemDetail(deleteModel.ContractRatePlanItemDetailId, deleteModel)
                        .then((idResponse) => {
                            options.success();
                        }, (err) => { options.error(err); });
                },
            },
            batch: false,
            schema: {
                model: {
                    id: "ContractRatePlanItemDetailId",
                    fields: {
                        ContractRatePlanItemDetailId: {
                            type: "number",
                            validation: { required: true },
                            editable: false,
                            defaultValue: 0
                        },
                        RoyaltyFeeRateResidential: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        RoyaltyFeeRateCommercial: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        AdFeeRateResidential: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        AdFeeRateCommercial: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        TAFSFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        TechnologyFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        DeletedDateTime: {
                            type: "string",
                            defaultValue: null
                        }
                    }
                }
            },
            pageSize: 20,
            filter: { field: "DeletedDateTime", operator: "isnull" }
        });

        let scColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.ContractRatePlanItemDetailId),
                title: "ID",
                hidden: true
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryId),
                title: "Service Category",
                editor: (container, options) => {
                    let input = "<input required name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description),
                        dataValueField: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.RoyaltyServiceCategoryId),
                        template: `<span class="#: 'deleted-' + (${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.DeletedDateTime)} ? 'true icon-error ' : 'false') #">#: ${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description)} # </span>`,
                        valueTemplate: `<span class="#: 'deleted-' + (${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.DeletedDateTime)} ? 'true icon-error ' : 'false') #">#: ${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description)} # </span>`,
                        dataSource: new kendo.data.DataSource({
                            data: this.royaltyServiceCategories,
                            sort: [
                                {
                                    field: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.DeletedDateTime),
                                    dir: "desc",
                                    compare: (a: RpmEntities.RoyaltyServiceCategoryViewModel, b: RpmEntities.RoyaltyServiceCategoryViewModel) =>
                                    {
                                        return (a.DeletedDateTime ? 1 : 0) - (b.DeletedDateTime ? 1 : 0)
                                    }
                                },
                                {
                                    field: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description),
                                    dir: "asc"
                                }
                            ]
                        }),
                        change: (e) => {
                            var dataItem = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.royaltyServiceCategories[0];
                            }

                            options.model.set(nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryId), dataItem.RoyaltyServiceCategoryId);
                        }
                    });
                },
                template: `<span ng-class="'deleted-' + ($ctrl.${nameof(this.GetRoyaltyServiceCategoryById)}(dataItem.${nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryId)}).${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.DeletedDateTime)} ? 'true icon-error' : 'false')" 
                                ng-bind="$ctrl.${nameof(this.GetRoyaltyServiceCategoryById)}(dataItem.${nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryId)}).${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description)}"></span>`,
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryTypeId),
                title: "Service Category Type",
                editor: (container, options) =>
                {
                    let input = "<input required name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.Name),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.RoyaltyServiceCategoryTypeId),
                        template: `<span class="#: 'deleted-' + (${nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.DeletedDateTime)} ? 'true icon-error ' : 'false') #">#: ${nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.Description)} # </span>`,
                        valueTemplate: `<span class="#: 'deleted-' + (${nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.DeletedDateTime)} ? 'true icon-error ' : 'false') #">#: ${nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.Description)} # </span>`,
                        dataSource: {
                            data: this.royaltyServiceCategoryTypes
                        },
                        change: (e) =>
                        {
                            var dataItem = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.royaltyServiceCategoryTypes[0];
                            }

                            options.model.set(nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.RoyaltyServiceCategoryTypeId), dataItem.RoyaltyServiceCategoryTypeId);
                        }
                    });
                },
                template: `<span ng-class="'deleted-' + ($ctrl.${nameof(this.GetRoyaltyServiceCategoryTypeById)}(dataItem.${nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryTypeId)}).${nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.DeletedDateTime)} ? 'true icon-error' : 'false')" 
                                ng-bind="$ctrl.${nameof(this.GetRoyaltyServiceCategoryTypeById)}(dataItem.${nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryTypeId)}).${nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.Description)}"></span>`,
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.RoyaltyFeeRateResidential),
                title: "Res Royalty Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.RoyaltyFeeRateCommercial),
                title: "Com Royalty Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.AdFeeRateResidential),
                title: "Res Ad Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.AdFeeRateCommercial),
                title: "Com Ad Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.TAFSFeeRate),
                title: "TAFS Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.TechnologyFeeRate),
                title: "Tech Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }];

        this.serviceCategoryGridOptions = {
            columns: scColumns,
            dataSource: scDataSource,
            scrollable: false,
            navigatable: true,
            editable: !this.readOnly ? { confirmation: false } : false,
            pageable: true,
            edit: (e) => {
                var input = e.container.find(".k-input");
                var value = input.val();

                input.blur((j) => {
                    let columnName = j?.target?.dataset?.bind?.split(":")[1];
                    switch (columnName) {
                        case nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.RoyaltyFeeRateResidential):
                        case nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.RoyaltyFeeRateCommercial):
                        case nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.AdFeeRateResidential):
                        case nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.AdFeeRateCommercial):
                        case nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.TAFSFeeRate):
                        case nameof<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(o => o.TechnologyFeeRate):
                            let newValue = input.val();
                            if (value != newValue && newValue != null && (newValue > 0.1)) {
                                alert("Warning! " + columnName + " value above 10%");
                            }
                            break;
                    }
                });
            },
        };
    }

    AddAllCategoriesPopup()
    {
        this.addAllServiceCategoryModel = {
            AdFeeRateCommercial: 0,
            AdFeeRateResidential: 0,
            RoyaltyFeeRateCommercial: 0,
            RoyaltyFeeRateResidential: 0,
            RollinAdFeeRate: 0,
            RollInRoyaltyFeeRate: 0,
            TAFSFeeRate: 0,
            TechnologyFeeRate: 0,
        };
        this.addAllCategoriesWindow.center();
        this.$timeout(() =>
        {
            this.addAllCategoriesWindow.open();
        })
    }

    AddAllCategoriesSave()
    {
        let data = this.kendoHelper.GetFilteredUnpagedData<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>(this.serviceCategoryGrid.dataSource)
        let validCategorySubset = this.royaltyServiceCategories.filter((category) =>
        {
            if (category.DeletedDateTime)
                return false;

            return data.every((dataItem: RpmEntities.RoyaltyContractRatePlanItemDetailViewModel) =>
            {
                return dataItem.RoyaltyServiceCategoryId != category.RoyaltyServiceCategoryId
            })
        });

        for (let category of validCategorySubset)
        {
            let newDetail = this.GetDefaultRoyaltyContractRatePlanItemDetail();
            angular.extend(newDetail, this.addAllServiceCategoryModel);
            newDetail.RoyaltyServiceCategoryId = category.RoyaltyServiceCategoryId;
            newDetail.RoyaltyServiceCategoryTypeId = category.RoyaltyServiceCategoryTypeId;
            this.serviceCategoryGrid.dataSource.add(newDetail);
        }

        this.addAllCategoriesWindow.close();
    }

    AddNewServiceCategory()
    {
        if (!this.readOnly)
        {
            let serviceCategoryId = this.royaltyServiceCategories[0] && this.royaltyServiceCategories[0].RoyaltyServiceCategoryId;
            if (!serviceCategoryId)
                serviceCategoryId = 0;

            let defaultItemDetail = this.GetDefaultRoyaltyContractRatePlanItemDetail();
            defaultItemDetail.RoyaltyServiceCategoryId = serviceCategoryId;

            this.serviceCategoryGrid.dataSource.add(defaultItemDetail);
        }
    }

    GetDefaultRoyaltyContractRatePlanItemDetail(): RpmEntities.RoyaltyContractRatePlanItemDetailViewModel
    {
        return {
            ContractRatePlanItemDetailId: 0,
            ContractRatePlanItemId: this.contractRatePlanItemId,
            RoyaltyServiceCategoryTypeId: 0,
            CreatedDateTime: kendo.toString(new Date(), 'd'),
            DeletedDateTime: null,
            RoyaltyServiceCategoryId: null,
            AnnualRollInSalesAmount: 0,
            MonthlyRollInSalesAmount: 0,
            RollinAdFeeRate: 0,
            RollInRoyaltyFeeRate: 0,
            WeeklyRollInSalesAmount: 0,
            RoyaltyFeeRateResidential: 0,
            RoyaltyFeeRateCommercial: 0,
            AdFeeRateResidential: 0,
            AdFeeRateCommercial: 0,
            TechnologyFeeRate: 0,
            TAFSFeeRate: 0,
            CreatedUser: null,
            UpdatedDateTime: null,
            UpdatedUser: null
        };
    }

    GetRoyaltyServiceCategoryById(serviceCategoryId: number)
    {
        return _.find(this.royaltyServiceCategories, (sc) => { return sc.RoyaltyServiceCategoryId === serviceCategoryId; });
    }

    GetRoyaltyServiceCategoryTypeById(serviceCategoryTypeId: number)
    {
        return _.find(this.royaltyServiceCategoryTypes, (ct) => { return ct.RoyaltyServiceCategoryTypeId === serviceCategoryTypeId; });
    }
    
    CancelChanges()
    {
        if (!this.readOnly)
        {
            this.serviceCategoryGrid.dataSource.cancelChanges();
        }
    }
    
    HasChanges()
    {
        if (!this.serviceCategoryGrid)
            return false;

        return this.serviceCategoryGrid.dataSource.hasChanges();
    }

    async ResetChanges() {
        this.CancelChanges();
    
        try {
            await this.serviceCategoryGrid.dataSource.read();
            this.serviceCategoryGrid.refresh();
    
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.formController.$setPristine();
        } catch (error) {
            console.error("Error during ResetChanges:", error);
            // Optionally, handle the error further or display a message to the user
        }
    }
    

    async SaveChanges() {
        try {
            await this.serviceCategoryGrid.dataSource.sync();
            await this.ResetChanges();
        } catch (error) {
            console.error("Error during serviceCategoryGrid SaveChanges:", error);
            throw error; // Re-throw the error to be handled by the caller
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("cmRoyaltiesServiceCategories", {
                bindings: {
                    [nameof<cmRoyaltiesServiceCategoriesComponentController>(o => o.contractRatePlanItemId)]: "<",
                    [nameof<cmRoyaltiesServiceCategoriesComponentController>(o => o.franchiseId)]: "<",
                    [nameof<cmRoyaltiesServiceCategoriesComponentController>(o => o.readOnly)]: "<",
                    [nameof<cmRoyaltiesServiceCategoriesComponentController>(o => o.onLoaded)]: "&?"
                },
                templateUrl: '/Templates/ContractManagement/cmRoyalties/ServiceCategories.html',
                controller: cmRoyaltiesServiceCategoriesComponentController
            });
    }
}
