import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyPeriodReportAdjustmentsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyPeriodReportAdjustmentsByRoyaltyPeriodReport(royaltyPeriodReportId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriodReportAdjustments/InRoyaltyPeriodReport/" + royaltyPeriodReportId
        };

        return this.$http<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult[]>(config);
    }

    GetRoyaltyPeriodReportAdjustmentsUnpostedByFranchisorIds(franchisorIds: number[])
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriodReportAdjustments/Unposted/ByFranchisors",
            params: {
                franchisorIds: franchisorIds
            }
        };

        return this.$http<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result[]>(config);
    }

    CreateRoyaltyPeriodReportAdjustment(model: RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyPeriodReportAdjustments",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyPeriodReportAdjustment(id: number, model: RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyPeriodReportAdjustments/" + id,
            data: model
        };

        return this.$http<void>(config);
    }
}
    
