import * as elements from 'typed-html';
import * as rmsClients from 'Clients/Rms/index';

import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RmsStateService } from "Services/State/RmsState";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { StringProperties } from 'Types/StringProperties';
import { ExpressionBinding } from 'Utility/AngularUtil';
import { ICmController } from 'Directives/RPM/ContractManagement/ICmController';
import { cmRoyaltyContractRatePlanMinimumsMonthsAppliedOnInit, cmRoyaltyContractRatePlanMinimumsMonthsAppliedOnSelect, cmRoyaltyContractRatePlanMinimumsMonthsApplied } from 'Directives/Rpm/ContractManagement';
import { KendoUtil, BindDropDownEditor } from 'Utility/KendoUtil';
import { NameOfFullToControllerAs } from 'Utility/Helpers';
import { AngularUtil } from "Utility/AngularUtil";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            cmRoyaltyContractRatePlanMinimums: Partial<StringProperties<cmRoyaltyContractRatePlanMinimums>>;
        }
    }
}

export type cmRoyaltyContractRatePlanMinimumsOnInit = { self: cmRoyaltyContractRatePlanMinimums };

export interface RoyaltyContractRatePlanMinimumGridItem extends RpmEntities.RoyaltyContractRatePlanMinimum
{
    MonthSettings: cmRoyaltyContractRatePlanMinimumsMonthsAppliedOnSelect["all"];
}

interface DataSourcePromise<T>
{
    dataSource: kendo.data.DataSource,
    dataPromise: Promise<T[]>;
}

export class cmRoyaltyContractRatePlanMinimums implements ng.IController, ICmController
{
    readOnly: boolean;
    onInit: ExpressionBinding<cmRoyaltyContractRatePlanMinimumsOnInit>;

    isLoading: boolean = false;

    private contractRatePlanId: number;
    public ratePlanMinimumGrid: kendo.ui.Grid;
    private ratePlanWIBEndValue: number = null;
    private feeTypes: DataSourcePromise<RpmEntities.usp_RoyaltyFeeTypeResult>;
    private royaltyPeriodFrequencies: DataSourcePromise<RpmEntities.RoyaltyPeriodFrequencyViewModel>;
    private royaltyFindScaleBasedOnTypes: DataSourcePromise<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>;

    private minimumsClient = rmsClients.royaltyContractRatePlanMinimumClient;

    static $inject = [
        'rpmUiApiResources',
        '$timeout',
        'coreApiResources',
        '$scope'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private $timeout: ng.ITimeoutService,
        private coreApiResources: CoreApiResources,
        private $scope: ng.IScope
    )
    {

    }

    $onInit()
    {

    }

    async $postLink()
    {
        this.InitLookupData();
        this.InitRatePlanMinimumItemsGrid();

        if (this.onInit)
            this.onInit({ self: this });
    }

    async LoadGrid(contractRatePlanId: number, wibEndValue?: number)
    {
        await AngularUtil.TrackLoadingPromise((async () =>
        {
            this.contractRatePlanId = contractRatePlanId;
            
            if (wibEndValue !== undefined) {
                this.ratePlanWIBEndValue = wibEndValue;
            }
            
            await this.LoadLookupData();
            await Promise.resolve(this.ratePlanMinimumGrid.dataSource.read());
        })(), this);
    }

    private InitLookupData()
    {
        this.feeTypes = {
            dataPromise: Promise.resolve(null),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                    {
                        return await this.feeTypes.dataPromise;
                    })
                }
            })
        };

        this.royaltyPeriodFrequencies = {
            dataPromise: Promise.resolve(null),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                    {
                        return await this.royaltyPeriodFrequencies.dataPromise;
                    })
                }
            })
        };

        this.royaltyFindScaleBasedOnTypes = {
            dataPromise: Promise.resolve(null),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                    {
                        return await this.royaltyFindScaleBasedOnTypes.dataPromise;
                    })
                }
            })
        };
    }

    async LoadLookupData()
    {
        this.feeTypes.dataPromise = Promise.resolve(this.rpmUiApiResources.GetAllRoyaltyFeeTypes().then(r => r.data));
        this.royaltyPeriodFrequencies.dataPromise = Promise.resolve(this.rpmUiApiResources.GetAllRoyaltyPeriodFrequencies().then(r => r.data));
        this.royaltyFindScaleBasedOnTypes.dataPromise = Promise.resolve(this.rpmUiApiResources.GetAllRoyaltyFindScaleBasedOnTypes().then(r => r.data.filter(s => s.UseForContract === true)));

        await Promise.all([
            this.feeTypes.dataSource.read(),
            this.royaltyPeriodFrequencies.dataSource.read(),
            this.royaltyFindScaleBasedOnTypes.dataSource.read()
        ]);

        this.$timeout();
    }

    private InitRatePlanMinimumItemsGrid()
    {
        let schema: kendo.data.DataSourceSchema = {
            model: {
                id: nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.RoyaltyContractRatePlanMinimumId),
                fields: {
                    [nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.RoyaltyContractRatePlanMinimumId)]: {
                        type: "number",
                        validation: { required: true },
                        editable: false,
                        defaultValue: 0
                    },
                    [nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.ContractRatePlanId)]: {
                        type: "number",
                        validation: { required: true },
                    },
                    [nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.WeeksInBusinessStart)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.WeeksInBusinessEnd)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.RoyaltyFeeTypeId)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.RoyaltyPeriodFrequencyId)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.FindScaleBasedOnId)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.FeeFixedRateAmount)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.GrossSalesMinimumAmount)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.MonthSettings)]: {
                        type: "object",
                        defaultValue: []
                    }
                } as kendo.data.DataSourceSchemaModelFields
            }
        }

        let minItemDataSourceOptions: kendo.data.DataSourceOptions = {
            //filter: { field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum>(o => o.DeletedDateTime), operator: "isnull" },
            transport: {
                read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    if (this.contractRatePlanId) {
                        let minimums = await this.minimumsClient.Query({
                            $filter: `${nameof<RpmEntities.RoyaltyContractRatePlanMinimum>(o => o.ContractRatePlanId)} eq ${this.contractRatePlanId}`
                        });
                        return minimums;
                    }
                }),
                create: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let newItem: RoyaltyContractRatePlanMinimumGridItem & kendo.data.Model = options.data;
                    newItem.ContractRatePlanId = this.contractRatePlanId;
                    //let minimumResult = await this.minimumsClient.Post(newItem);
                    let minimumResult = await this.coreApiResources.CreateRoyaltyContractRatePlanMinimums(newItem).then((response) => {
                        return response.data
                    });

                    if (newItem.MonthSettings?.length)
                    {
                        await cmRoyaltyContractRatePlanMinimumsMonthsApplied.SaveMonthsEnabled(newItem.MonthSettings, minimumResult.RoyaltyContractRatePlanMinimumId, this.coreApiResources);
                    }

                    return minimumResult;
                }),
                update: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let updateItem: RoyaltyContractRatePlanMinimumGridItem & kendo.data.Model = options.data;

                    if (updateItem.MonthSettings?.length)
                    {
                        await cmRoyaltyContractRatePlanMinimumsMonthsApplied.SaveMonthsEnabled(updateItem.MonthSettings, updateItem.RoyaltyContractRatePlanMinimumId, this.coreApiResources);
                    }

                    //return await this.minimumsClient.Put(updateItem.RoyaltyContractRatePlanMinimumId, updateItem);
                    return await this.coreApiResources.UpdateRoyaltyContractRatePlanMinimums(updateItem.RoyaltyContractRatePlanMinimumId, updateItem).then((response) => {
                        return response;
                    });
                }),
                destroy: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let deleteItem: RoyaltyContractRatePlanMinimumGridItem = options.data;
                    return await this.minimumsClient.Delete(deleteItem.RoyaltyContractRatePlanMinimumId, { allowHardDelete: false, forceHardDelete: false });
                })
            },
            pageSize: 10,
            schema: schema,
            sort: {
                field: nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.WeeksInBusinessStart),
                dir: "asc"
            }
        };

        let minItemColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.RoyaltyContractRatePlanMinimumId),
                title: "Id",
                width: 160,
                hidden: true
            }, {
                field: nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.RoyaltyFeeTypeId),
                title: "Fee Type",
                editor: (container, options) =>
                {
                    let dropDownListOptions: kendo.ui.DropDownListOptions = {
                        dataTextField: nameof<RpmEntities.usp_RoyaltyFeeTypeResult>(o => o.Description),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyFeeTypeResult>(o => o.RoyaltyFeeTypeId),
                        dataSource: this.feeTypes.dataSource,
                        change: (e) =>
                        {
                            let dataItem: RpmEntities.usp_RoyaltyFeeTypeResult = e.sender.dataItem();
                            options.model.set(nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.RoyaltyFeeTypeId), dataItem?.RoyaltyFeeTypeId);
                        },
                        open: (e) => { e.sender.dataSource.read(); }
                    }
                    let ddl = BindDropDownEditor(container, options, dropDownListOptions);
                },
                template: (dataItem: RoyaltyContractRatePlanMinimumGridItem) =>
                {
                    return this.feeTypes.dataSource.data()
                        .find((ft: RpmEntities.usp_RoyaltyFeeTypeResult) => { return ft.RoyaltyFeeTypeId === dataItem.RoyaltyFeeTypeId; })
                        ?.Description ?? "--";
                }
            }, {
                field: nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.RoyaltyPeriodFrequencyId),
                title: "Minimum Fee Frequency",
                editor: (container, options) => 
                {
                    let dropDownOptions: kendo.ui.DropDownListOptions = {
                        dataTextField: nameof<RpmEntities.RoyaltyPeriodFrequencyViewModel>(o => o.Description),
                        dataValueField: nameof<RpmEntities.RoyaltyPeriodFrequencyViewModel>(o => o.RoyaltyPeriodFrequencyId),
                        dataSource: this.royaltyPeriodFrequencies.dataSource,
                        select: (e) => 
                        {
                            let dataItem: RpmEntities.RoyaltyPeriodFrequencyViewModel = e.dataItem;

                            options.model.set(nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.RoyaltyPeriodFrequencyId), dataItem.RoyaltyPeriodFrequencyId);
                        },
                        open: (e) => { e.sender.dataSource.read(); }
                    };
                    let ddl = BindDropDownEditor(container, options, dropDownOptions);
                },
                template: (dataItem: RoyaltyContractRatePlanMinimumGridItem) =>
                {
                    return this.royaltyPeriodFrequencies.dataSource.data()
                        .find((f: RpmEntities.RoyaltyPeriodFrequencyViewModel) => { return f.RoyaltyPeriodFrequencyId === dataItem.RoyaltyPeriodFrequencyId; })
                        ?.Description ?? "--";
                }
            }, {
                field: nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.FindScaleBasedOnId),
                title: "Minimum Scale Based On",
                editor: (container, options) => 
                {
                    let dropDownOptions: kendo.ui.DropDownListOptions = {
                        dataTextField: nameof<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>(o => o.Description),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>(o => o.FindScaleBasedOnId),
                        dataSource: this.royaltyFindScaleBasedOnTypes.dataSource,
                        select: (e) => 
                        {
                            this.$timeout(() =>
                            {
                                let dataItem: RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result = e.dataItem;

                                options.model.set(nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.FindScaleBasedOnId), dataItem.FindScaleBasedOnId);
                            })
                        },
                        open: (e) => { e.sender.dataSource.read(); }
                    };
                    let ddl = BindDropDownEditor(container, options, dropDownOptions);
                    ddl.jqueryElement.prop("required", "required");
                },
                template: (dataItem: RoyaltyContractRatePlanMinimumGridItem) =>
                {
                    return this.royaltyFindScaleBasedOnTypes.dataSource.data()
                        .find((fs: RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result) => { return fs.FindScaleBasedOnId === dataItem.FindScaleBasedOnId; })
                        ?.Description ?? "--";
                }
            }, {
                field: nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.WeeksInBusinessStart),
                title: "WIB Start"
            }, {
                field: nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.WeeksInBusinessEnd),
                title: "WIB End"
            }, {
                field: nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.FeeFixedRateAmount),
                title: "Minimum Fee",
                format: "{0:c}"
            }, {
                field: nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.GrossSalesMinimumAmount),
                title: "Gross Sales Min Amount",
                format: "{0:c}"
            }, {
                field: nameof<RoyaltyContractRatePlanMinimumGridItem>(o => o.MonthSettings),
                title: "MonthSettings",
                hidden: true
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }];

        let toolbar: kendo.ui.GridToolbarItem[] = [
            {
                template: (
                    <button type="button"
                        ng-click={`${NameOfFullToControllerAs(nameof.full(this.ratePlanMinimumGrid.addRow))}()`}>
                        Add Row
                    </button>
                )
            }
        ];

        let options: kendo.ui.GridOptions = {
            autoBind: false,
            toolbar: toolbar,
            dataSource: new kendo.data.DataSource(minItemDataSourceOptions),
            columns: minItemColumns,
            selectable: "row",
            editable: !this.readOnly ? { confirmation: false } : false,
            navigatable: true,
            scrollable: false,
            sortable: true,
            pageable: KendoUtil.GetDefaultKendoGridPageable(10),
            edit: (e) =>
            {
                if (e.model.isNew())
                {
                    e.sender.expandRow(
                        e.sender.tbody.find("tr[data-uid='" + e.model.uid + "']")
                    );
                }
            }
        };

        this.ratePlanMinimumGrid.setOptions(options);
    }

    private SetHasMonthlyMinimumChanges(dataItem: kendo.data.Model & RoyaltyContractRatePlanMinimumGridItem, monthSettings: cmRoyaltyContractRatePlanMinimumsMonthsAppliedOnSelect["all"])
    {
        dataItem.MonthSettings = monthSettings;
        dataItem.dirty = true;
    }

    private SetMinimumMonthsAppliedController(dataItem: kendo.data.Model & RoyaltyContractRatePlanMinimumGridItem, controller: cmRoyaltyContractRatePlanMinimumsMonthsAppliedOnInit["self"])
    {
        this.$timeout(async () =>
        {
            if (dataItem?.MonthSettings?.length)
            {
                controller.SetMonthsData(dataItem.RoyaltyContractRatePlanMinimumId ?? 0, dataItem.MonthSettings, true)
            }
            else
            {
                await controller.LoadMonthsData(dataItem.RoyaltyContractRatePlanMinimumId ?? 0);
            }
        });
    }

    CancelChanges()
    {
        this.ratePlanMinimumGrid.cancelChanges();
    }

    HasChanges()
    {
        //Don't need to check monthly minimums because they should already show up as changes via HasMonthlyMinimumChanges
        return this.ratePlanMinimumGrid.dataSource.hasChanges();
    }

    async ResetChanges() {
        try {
            this.CancelChanges();
            if (this.contractRatePlanId) {
                await this.LoadGrid(this.contractRatePlanId);
            }
        } catch (error) {
            console.error("Error during ratePlanMinimumGrid ResetChanges:", error);
            throw error;
            // Optionally, handle the error further or display a message to the user
        }
    }

    // Add a public method to update the WIB end value
    public UpdateWIBEndValue(wibEndValue: number) {
        if (wibEndValue !== undefined && wibEndValue !== null) {
            this.ratePlanWIBEndValue = wibEndValue;
            console.log("WIB end value updated directly:", wibEndValue);
        }
    }

    showSnackbar: boolean;
    snackbarType: string;
    snackbarMessage: string;

    DisplaySnackbar(type: string, message: string) {
        this.showSnackbar = !this.showSnackbar;
        this.snackbarType = type;
        this.snackbarMessage = message;
        this.$scope.$evalAsync();
    }

    UpdateGridOptions = function (adjustmentCreationValidationErrors) {
        if (this.addRatePlanItemsValidationErrorGrid && this.addRatePlanItemsValidationErrorGrid.dataSource) {
            // Create new object if it's null
            const options = {
                dataSource: {
                    data: adjustmentCreationValidationErrors
                },
                columns: [
                    // { field: 'rowNumber', title: 'Row No.' },
                    { field: 'propertyName', title: 'Property' },
                    { field: 'errorMessage', title: 'Error Message' }
                ]
            };

            this.addRatePlanItemsValidationErrorGrid.setOptions(options);
        } else {
            // Update dataSource if it exists
            const dataSource = new kendo.data.DataSource({
                data: adjustmentCreationValidationErrors
            });

            this.addRatePlanItemsValidationErrorGrid.setDataSource(dataSource)
            
        }

        this.addRatePlanItemsValidationErrorGrid.dataSource.read(); // Read the new data from the data source
        this.addRatePlanItemsValidationErrorGrid.refresh();
    };

    validationErrorsKendoWindow: kendo.ui.Window

    addRatePlanItemsValidationErrorGrid: kendo.ui.Grid;
    addRatePlanItemsValidationErrorGridOptions: kendo.ui.GridOptions;

    ValidateRatePlanMinimumGrid() {
        const validationErrors = [];
        const ratePlanMinimumData = this.ratePlanMinimumGrid.dataSource.data().filter(x => x["DeletedDateTime"] == null);

        for (let i = 0; i < ratePlanMinimumData.length; i++) {
            const item = ratePlanMinimumData[i];
            
            // Validation for RoyaltyFeeTypeId (Required)
            if (item['RoyaltyFeeTypeId'] == null) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "Royalty Fee Type",
                    errorMessage: "Royalty Fee Type is required."
                });
            }

            // Validation for RoyaltyPeriodFrequencyId (Required)
            if (item['RoyaltyPeriodFrequencyId'] == null) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "Royalty Period Frequency",
                    errorMessage: "Royalty Period Frequency is required."
                });
            }

            // Validation for WeeksInBusinessStart (Required, must be between -500 and 10000)
            if (item['WeeksInBusinessStart'] == null || item['WeeksInBusinessStart'] === undefined || isNaN(item['WeeksInBusinessStart']) || item['WeeksInBusinessStart'] < -500 || item['WeeksInBusinessStart'] > 10000) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "WIB Start",
                    errorMessage: "WIB Start must be between -500 and 10000 for the Royalty Rate Plan Minimum."
                });
            }

            // Validation for WeeksInBusinessEnd (Required, must be between -500 and 10000)
            if (item['WeeksInBusinessEnd'] == null || item['WeeksInBusinessEnd'] === undefined || isNaN(item['WeeksInBusinessEnd']) || item['WeeksInBusinessEnd'] < -500 || item['WeeksInBusinessEnd'] > 10000) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "WIB End",
                    errorMessage: "WIB End must be between -500 and 10000 for the Royalty Rate Plan Minimum."
                });
            }

            // Validation: WIB Start should be less than or equal to WIB End
            if (item['WeeksInBusinessStart'] != null && item['WeeksInBusinessEnd'] != null && item['WeeksInBusinessStart'] > item['WeeksInBusinessEnd']) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "WIB Start and End",
                    errorMessage: "WIB Start should be less than or equal to WIB End for the Royalty Rate Plan Minimum."
                });
            }
            
            // Validation: WIB End of rate plan should not be less than WIB End in minimums
            if (this.ratePlanWIBEndValue != null && item['WeeksInBusinessEnd'] != null && this.ratePlanWIBEndValue < item['WeeksInBusinessEnd']) {
                // This is a critical validation error that should prevent saving
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "WIB End",
                    errorMessage: "WIB End in minimums should not be greater than WIB End in rate plan."
                });
            }
            // Validation for FindScaleBasedOnId (Required)
            if (item['FindScaleBasedOnId'] == null || item['FindScaleBasedOnId'] === undefined || isNaN(item['FindScaleBasedOnId']) || item['FindScaleBasedOnId'] <= 0) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "Minimum Scale Based On",
                    errorMessage: "Minimum Scale Based On is required for the Royalty Rate Plan Minimum."
                });
            }
            // Validation for FeeFixedRateAmount (Required, must be between 0 and 1,000,000)
            if (item['FeeFixedRateAmount'] == null || item['FeeFixedRateAmount'] === undefined || isNaN(item['FeeFixedRateAmount']) || item['FeeFixedRateAmount'] < 0 || item['FeeFixedRateAmount'] > 1000000) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "Minimum Fee Amount",
                    errorMessage: "Minimum Fee Amount must be between 0 and 1,000,000 for the Royalty Rate Plan Minimum."
                });
            }
            // Validation for GrossSalesMinimumAmount (Required, must be between 0 and 1,000,000)
            if (item['GrossSalesMinimumAmount'] == null || item['GrossSalesMinimumAmount'] === undefined || isNaN(item['GrossSalesMinimumAmount']) || item['GrossSalesMinimumAmount'] < 0 || item['GrossSalesMinimumAmount'] > 1000000) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "Gross Sales Minimum Amount",
                    errorMessage: "Gross Sales Minimum Amount must be between 0 and 1,000,000 for the Royalty Rate Plan Minimum."
                });
            }
        }

        return validationErrors;
    }

    async SaveChanges() {
        try {
            const validationErrors = this.ValidateRatePlanMinimumGrid();

            if (validationErrors && validationErrors.length > 0) {
                this.UpdateGridOptions(validationErrors);
                this.DisplaySnackbar("error", "There are validation errors. Please fix them and try again!");
                this.validationErrorsKendoWindow.open();
                throw new Error("There are validation errors. Please fix them and try again!");
            }
            
            if (this.ratePlanMinimumGrid && this.ratePlanMinimumGrid.dataSource) {
                await this.ratePlanMinimumGrid.dataSource.sync();
            }
            await this.ResetChanges();
        } catch (error) {
            console.error("Error during ratePlanMinimumGrid SaveChanges:", error);
            throw error;
            // Optionally, handle the error further or display a message to the user
        }
    }

    static BindComponent(app: ng.IModule)
    {
        let template = (
            <div>
                <div kendo-window="$ctrl.validationErrorsKendoWindow" k-visible="false">
                    <div class="rpm-new-adjustment-popup rpm-modal">
                        <div kendo-grid="$ctrl.addRatePlanItemsValidationErrorGrid" id="rpit-table">
                        </div>
                    </div>
                </div>
        
                <snackbar show="$ctrl.showSnackbar" type="$ctrl.snackbarType" message="$ctrl.snackbarMessage">
                </snackbar>
        
                <div kendo-grid={`$ctrl.ratePlanMinimumGrid`}>
                    <div k-detail-template>
                        <cmRoyaltiesRatePlanMinimumsMonthsApplied
                            onInit={`$ctrl.SetMinimumMonthsAppliedController(dataItem, self)`}
                            onSelect={`$ctrl.SetHasMonthlyMinimumChanges(dataItem, all)`}
                        >
                        </cmRoyaltiesRatePlanMinimumsMonthsApplied>
                    </div>
                </div>
            </div>
        );
        

        let componentName = nameof<JSX.IntrinsicElements>(o => o.cmRoyaltyContractRatePlanMinimums)
        app
            .component(componentName, {
                bindings: {
                    [nameof<cmRoyaltyContractRatePlanMinimums>(o => o.onInit)]: "&?"
                },
                template: template,
                controller: cmRoyaltyContractRatePlanMinimums
            });
    }
}
