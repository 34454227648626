import axios, { AxiosInstance } from "axios";
import { Helpers } from "Utility/Helpers";
import { ApiConfig } from "AppConfig/ApiConfig";

export type FranForceAxiosClientBuilderConfig = {
    baseUrl: string;
};

export class FranForceAxiosClientBuilder
{
    static BuildCoreBaseClient(config: Omit<FranForceAxiosClientBuilderConfig, "baseUrl"> = {}): AxiosInstance
    {
        return FranForceAxiosClientBuilder.BuildBaseClient({
            baseUrl: ApiConfig.Default.AppSettings.CoreApiRootUrl
        });
    }

    static BuildFranchiseOrchestrationClient(config: Omit<FranForceAxiosClientBuilderConfig, "baseUrl"> = {}): AxiosInstance {
        return FranForceAxiosClientBuilder.BuildBaseClient({
            baseUrl: ApiConfig.Default.AppSettings.FranchiseOrchestrationsRootUrl
        });
    }

    static BuildIdentityBaseClient(config: Omit<FranForceAxiosClientBuilderConfig, "baseUrl"> = {}): AxiosInstance
    {
        return FranForceAxiosClientBuilder.BuildBaseClient({
            baseUrl: ApiConfig.Default.AppSettings.IdentityApiRootUrl
        });
    }

    static BuildServiceManagerBaseClient(config: Omit<FranForceAxiosClientBuilderConfig, "baseUrl"> = {}): AxiosInstance {
        return FranForceAxiosClientBuilder.BuildBaseClient({
            baseUrl: ApiConfig.Default.AppSettings.ServiceManagerUrl
        });
    }

    static BuildEmployeeGroupBaseClient(config: Omit<FranForceAxiosClientBuilderConfig, "baseUrl"> = {}): AxiosInstance
    {
        return FranForceAxiosClientBuilder.BuildBaseClient({
            baseUrl: ApiConfig.Default.AppSettings.FranchiseEmployeeGroupApiRootUrl
        });
    }

    static BuildEmployeeOrchestrationsBaseClient(config: Omit<FranForceAxiosClientBuilderConfig, "baseUrl"> = {}): AxiosInstance
    {
        return FranForceAxiosClientBuilder.BuildBaseClient({
            baseUrl: ApiConfig.Default.AppSettings.FranchiseEmployeeOrchestrationsApiRootUrl
        });
    }

    static BuildFranchiseGroupBaseClient(config: Omit<FranForceAxiosClientBuilderConfig, "baseUrl"> = {}): AxiosInstance
    {
        return FranForceAxiosClientBuilder.BuildBaseClient({
            baseUrl: ApiConfig.Default.AppSettings.FranchiseGroupApiRootUrl
        });
    }

    static BuildServiceOrchestrationsBaseClient(config: Omit<FranForceAxiosClientBuilderConfig, "baseUrl"> = {}): AxiosInstance
    {
        return FranForceAxiosClientBuilder.BuildBaseClient({
            baseUrl: ApiConfig.Default.AppSettings.ServiceOrchestrationsApiRootUrl
        });
    }
    static BuildCallCenterOrchestrationsBaseClient(config: Omit<FranForceAxiosClientBuilderConfig, "baseUrl"> = {}): AxiosInstance {
        return FranForceAxiosClientBuilder.BuildBaseClient({
            baseUrl: ApiConfig.Default.AppSettings.CallCenterOrchestrationsBaseUrl
        });
    }

    static BuildRoyaltyOrchestrationsBaseClient(config: Omit<FranForceAxiosClientBuilderConfig, "baseUrl"> = {}): AxiosInstance {
        return FranForceAxiosClientBuilder.BuildBaseClient({
            baseUrl: ApiConfig.Default.AppSettings.RoyaltyRootUrl
        });
    }

    static BuildBillingOrchestrationsBaseClient(config: Omit<FranForceAxiosClientBuilderConfig, "baseUrl"> = {}): AxiosInstance {
        return FranForceAxiosClientBuilder.BuildBaseClient({
            baseUrl: ApiConfig.Default.AppSettings.BillingRootUrl
        });
    }

    static BuildSyncGroupBaseClient(config: Omit<FranForceAxiosClientBuilderConfig, "baseUrl"> = {}): AxiosInstance {
        return FranForceAxiosClientBuilder.BuildBaseClient({
            baseUrl: ApiConfig.Default.AppSettings.SyncGroupBaseUrl
        });
    }

    static BuildEngagementServicesBaseClient(config: Omit<FranForceAxiosClientBuilderConfig, "baseUrl"> = {}): AxiosInstance {
        return FranForceAxiosClientBuilder.BuildBaseClient({
            baseUrl: ApiConfig.Default.AppSettings.EngagementServicesBaseUrl
        });
    }


    static BuildBaseClient(config: FranForceAxiosClientBuilderConfig): AxiosInstance
    {
        let client = axios.create({
            baseURL: config.baseUrl,
            transformRequest: (data, headers) =>
            {
                const isCoreApi = Helpers.StartsWith(config.baseUrl.toLowerCase(), ApiConfig.Default.AppSettings.CoreApiRootUrl.toLowerCase());
                const isIdentityApi = Helpers.StartsWith(config.baseUrl.toLowerCase(), ApiConfig.Default.AppSettings.IdentityApiRootUrl.toLowerCase());
                const isEmployeeGroupApi = Helpers.StartsWith(config.baseUrl.toLowerCase(), ApiConfig.Default.AppSettings.FranchiseEmployeeGroupApiRootUrl.toLowerCase());
                const isEmployeeOrchestrationsApi = Helpers.StartsWith(config.baseUrl.toLowerCase(), ApiConfig.Default.AppSettings.FranchiseEmployeeOrchestrationsApiRootUrl.toLowerCase());
                const isFranchiseGroupApi = Helpers.StartsWith(config.baseUrl.toLowerCase(), ApiConfig.Default.AppSettings.FranchiseGroupApiRootUrl.toLowerCase());
                const isServiceOrchestrationsApi = Helpers.StartsWith(config.baseUrl.toLowerCase(), ApiConfig.Default.AppSettings.ServiceOrchestrationsApiRootUrl.toLowerCase());
                const isCallCenterOrchestrationsApi = Helpers.StartsWith(config.baseUrl.toLowerCase(), ApiConfig.Default.AppSettings.CallCenterOrchestrationsBaseUrl.toLowerCase());
                const isRoyaltyOrchestrationsApi = Helpers.StartsWith(config.baseUrl.toLowerCase(), ApiConfig.Default.AppSettings.RoyaltyRootUrl.toLowerCase());
                const isBillingOrchestrationsApi = Helpers.StartsWith(config.baseUrl.toLowerCase(), ApiConfig.Default.AppSettings.BillingRootUrl.toLowerCase());
                const isSyncGroupApi = Helpers.StartsWith(config.baseUrl.toLowerCase(), ApiConfig.Default.AppSettings.SyncGroupBaseUrl.toLowerCase());
                const isServiceManagerApi = Helpers.StartsWith(config.baseUrl.toLowerCase(), ApiConfig.Default.AppSettings.ServiceManagerUrl.toLowerCase());
                const isFranchiseOrchestrationApi = Helpers.StartsWith(config.baseUrl.toLowerCase(), ApiConfig.Default.AppSettings.FranchiseOrchestrationsRootUrl.toLowerCase());
                const isEngagementServicesApi = Helpers.StartsWith(config.baseUrl.toLowerCase(), ApiConfig.Default.AppSettings.EngagementServicesBaseUrl.toLowerCase());

                const tokenStorage = localStorage as ILocalStorage;

                if ((isCoreApi || isIdentityApi ) && tokenStorage.FranForceAuthToken)
                {
                    let tokenType = tokenStorage.FranForceAuthTokenType || 'bearer';
                    headers.common['Authorization'] = `${tokenType} ${tokenStorage.FranForceAuthToken}`;
                }

                if ((isEngagementServicesApi || isFranchiseOrchestrationApi || isServiceManagerApi || isSyncGroupApi || isEmployeeGroupApi || isEmployeeOrchestrationsApi || isFranchiseGroupApi || isServiceOrchestrationsApi || isCallCenterOrchestrationsApi || isRoyaltyOrchestrationsApi || isBillingOrchestrationsApi) && tokenStorage.FranForceAuthToken) {
                    let tokenType = tokenStorage.FranForceAuthTokenType || 'bearer';
                    headers.common['Authorization'] = `${tokenType} ${tokenStorage.FranForceAuthToken}`;
                    headers.common['x-requested-with']="xmlhttprequest";
                   
                }

                return data;
            }
        });
        
        return client;
    }
}