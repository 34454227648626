import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RmsConfirmationDialogComponentController } from "Directives/RPM/Common/rmsConfirmationDialog";
import { cmRoyaltiesFeeSettingsComponentController } from "Directives/RPM/ContractManagement/cmRoyalties/cmRoyaltiesFeeSettings";
import { cmRoyaltiesRatePlansComponentController } from "Directives/RPM/ContractManagement/cmRoyalties/cmRoyaltiesRatePlans";
import { cmRoyaltiesRatePlanItemsComponentController } from "Directives/RPM/ContractManagement/cmRoyalties/cmRoyaltiesRatePlanItems";
import { cmRoyaltiesServiceCategoriesComponentController } from "Directives/RPM/ContractManagement/cmRoyalties/cmRoyaltiesServiceCategories";
import { cmRoyaltiesRatePlanMinimumItemsComponentController } from "Directives/RPM/ContractManagement/cmRoyalties/cmRoyaltiesRatePlanMinItems";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { cmRoyaltyContractRatePlanMinimumsOnInit, cmRoyaltyContractRatePlanMinimums } from "Directives/Rpm/ContractManagement";
import { PromiseUtil } from "Utility/PromiseUtil";
import { RmsStateService } from "../../../Services/State/RmsState";
import moment = require("moment");


interface IRoyaltieScope extends ng.IScope {

    isRoyaltyFrequencyChanged: boolean;
}
export class CmRoyaltiesComponentController implements ng.IController {
    
    contractId: number;
    conceptId: number;
    franchisorId: number;
    readOnly: boolean;
    init: (params: { self: CmRoyaltiesComponentController }) => void;

    feeSettingsController: cmRoyaltiesFeeSettingsComponentController;
    ratePlansController: cmRoyaltiesRatePlansComponentController;
    ratePlanItemsController: cmRoyaltiesRatePlanItemsComponentController;
    serviceCategoriesController: cmRoyaltiesServiceCategoriesComponentController;
    ratePlanMinimumsController: cmRoyaltyContractRatePlanMinimums;

    isRoyaltyFrequencyChanged: boolean;
    newAdjustmentWindow: kendo.ui.Window;
    royaltyFrequencyChangeReason: string;
    royaltyEffectiveDate?: string;
    errorMessage: string;
    dateErrorMessage: string;
    //Legacy
    //ratePlanMinimumItemsController: cmRoyaltiesRatePlanMinimumItemsComponentController;
    

    isLoading: boolean;
    //currentLoginEmail: string;
    
    isMethodologySpecial: boolean;
    franchiseId: number;
    selectedRatePlanId: number;
    selectedRatePlanItemId: number;
    
    dialogController: RmsConfirmationDialogComponentController;
    formController: ng.IFormController;

    showMessageInfoModal: boolean;
    infoModalMessage: string;
    infoModalTitle: string;

    royaltyContract: RpmEntities.RoyaltyContractViewModel;
    isSaveClicked: boolean;

    isDynamicFees: boolean;

    static $inject = [
        nameof<CmRoyaltiesComponentController>(o => o.rpmApiResources),
        nameof<CmRoyaltiesComponentController>(o => o.rpmHelper),
        nameof<CmRoyaltiesComponentController>(o => o.identityManager),
        nameof<CmRoyaltiesComponentController>(o => o.ngDialog),
        "$timeout",
        "rmsState",
        '$scope'
    ];

    constructor(
        private rpmApiResources: RpmApiResources,
        private rpmHelper: RpmHelperService,
        private identityManager: IdentityManager,
        private ngDialog: any,
        private $timeout: ng.ITimeoutService,
        private rmsState: RmsStateService,
        private $scope: IRoyaltieScope
    ) {

    }

    async $onInit()
    {
        this.showMessageInfoModal = false;
        this.infoModalMessage = "";
        this.infoModalTitle = "";

        this.readOnly = false;
        this.isMethodologySpecial = false;
        this.isLoading = false;
        this.selectedRatePlanId = null;
        this.selectedRatePlanItemId = null;
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledCm').val() === 'True';
        this.royaltyContract = await this.rmsState.royaltyContractStateHandler.Load(this.contractId, this.isDynamicFees);

        this.$scope.$on('setRoyaltyFrequencyChanged', (event, args) => {
            this.$scope.isRoyaltyFrequencyChanged = args.isRoyaltyFrequencyChanged;
            this.royaltyFrequencyChangeReason = null;
            this.royaltyEffectiveDate = null;
        });

        if (this.init) {
            this.init({ self: this });
        }
    }

    OnFeeSettingsControllerLoaded(controller: cmRoyaltiesFeeSettingsComponentController)
    {
        this.feeSettingsController = controller;
        this.franchiseId = this.feeSettingsController.contract.FranchiseId
        this.isMethodologySpecial = (this.feeSettingsController.contract.RoyaltyMethodologyTypeId === this.rpmHelper.SpecialRoyaltyMethodologyTypeId);
    }

    OnRatePlanMinimumsInit(controller: cmRoyaltyContractRatePlanMinimumsOnInit["self"])
    {
        this.ratePlanMinimumsController = controller;
    }
    
    OnSelectRatePlan(ratePlan: RpmEntities.RoyaltyContractRatePlanViewModel, isRemoved: boolean = false)
    {
        let hasChanges = this.HasUnsavedChanges();
        let needsConfirmation = (
            hasChanges &&
            !isRemoved && 
            this.selectedRatePlanId &&
            (
                ratePlan === null ||
                ratePlan.ContractRatePlanId !== this.selectedRatePlanId
            )
        );

        if (needsConfirmation)
        {
            this.dialogController.Open({
                title: false,
                message: `There are currently changes on grids based on the current rate plan selected (Id: ${this.selectedRatePlanId}). 
                                                Do you want to save those changes?`,
                actions: [
                    {
                        name: "Yes",
                        cssClass: "pure-button btn-accept",
                        event: async (e) =>
                        {
                            await PromiseUtil.TrackLoadingPromise(this, Promise.all([
                                this.ratePlanItemsController.SaveChanges(),
                                this.serviceCategoriesController.SaveChanges(),
                                //this.ratePlanMinimumItemsController.SaveChanges(),
                                this.ratePlanMinimumsController.SaveChanges()
                            ]));

                            this.SetRatePlan(ratePlan);
                        }
                    }, {
                        name: "No",
                        cssClass: "pure-button btn-default",
                        event: (e) =>
                        {
                            this.SetRatePlan(ratePlan);
                        }
                    }, {
                        name: "Cancel",
                        cssClass: "pure-button btn-cancel",
                        event: (e) =>
                        {
                            this.ratePlansController.SelectRowById(this.selectedRatePlanId);
                        }
                    },
                ],
            });
        }
        else
        {
            this.SetRatePlan(ratePlan);
        }
    }

    SetRatePlan(ratePlan: RpmEntities.RoyaltyContractRatePlanViewModel)
    {
        this.$timeout(() => {
            this.selectedRatePlanId = ratePlan && ratePlan.ContractRatePlanId;
            this.selectedRatePlanItemId = null;
            // Pass the WIB end value directly to the minimums controller
            const wibEndValue = ratePlan ? ratePlan.WeeksInBusinessEnd : null;
            this.ratePlanMinimumsController.LoadGrid(this.selectedRatePlanId, wibEndValue);
        }, 0);
    }
    
    OnSelectRatePlanItem(ratePlanItem: RpmEntities.RoyaltyContractRatePlanItemViewModel, isRemoved: boolean = false)
    {
        let hasChanges = (
            this.isMethodologySpecial &&
            this.serviceCategoriesController?.HasChanges()
        );
        let needsConfirmation = (
            hasChanges &&
            !isRemoved &&
            this.selectedRatePlanItemId &&
            (
                ratePlanItem === null  ||
                ratePlanItem.ContractRatePlanItemId !== this.selectedRatePlanItemId
            )
        );
        
        if (needsConfirmation)
        {
            this.dialogController.Open({
                title: false,
                message: `There are currently changes to service categories based on the current rate plan item selected (Id: ${this.selectedRatePlanItemId}). 
                                                Do you want to save those changes?`,
                actions: [
                    {
                        name: "Yes",
                        cssClass: "pure-button btn-accept",
                        event: async (e) =>
                        {
                            await PromiseUtil.TrackLoadingPromise(this, Promise.resolve(this.serviceCategoriesController.SaveChanges()));
                            this.SetRatePlanItem(ratePlanItem);
                        }
                    }, {
                        name: "No",
                        cssClass: "pure-button btn-default",
                        event: (e) =>
                        {
                            this.SetRatePlanItem(ratePlanItem);
                        }
                    }, {
                        name: "Cancel",
                        cssClass: "pure-button btn-cancel",
                        event: (e) =>
                        {
                            this.ratePlanItemsController.SelectRowById(this.selectedRatePlanItemId);
                        }
                    },
                ],
            });
        }
        else
        {
            this.SetRatePlanItem(ratePlanItem);
        }
    }

    SetRatePlanItem(ratePlanItem: RpmEntities.RoyaltyContractRatePlanItemViewModel)
    {
        this.$timeout(() => {
            this.selectedRatePlanItemId = ratePlanItem?.ContractRatePlanItemId;
        }, 0);
    }

    async CancelChanges()
    {
        await Promise.all([
            this.feeSettingsController?.ResetChanges(),
            this.ratePlansController?.ResetChanges(),
            this.ratePlanItemsController?.ResetChanges(),
            this.serviceCategoriesController?.ResetChanges(),
            //this.ratePlanMinimumItemsController?.ResetChanges(),
            this.ratePlanMinimumsController?.ResetChanges()
        ]);

        this.selectedRatePlanId = null;
        this.selectedRatePlanItemId = null;
        this.ratePlansController.SelectRowById(null);
        this.ratePlanItemsController.SelectRowById(null);
    }

    HasUnsavedChanges()
    {
        return (
            this.feeSettingsController?.formController?.$dirty ||
            this.ratePlansController?.HasChanges() ||
            this.ratePlanItemsController?.HasChanges() ||
            this.serviceCategoriesController?.HasChanges() ||
            //this.ratePlanMinimumItemsController?.HasChanges() ||
            this.ratePlanMinimumsController?.HasChanges()
        );
    }

    validateionPopupMessage(message: string, title: string, show: boolean) {
        this.infoModalMessage = message;
        this.infoModalTitle = title;
        this.showMessageInfoModal = show;
    }

    PickPivotField(conceptId: number, contractSignedDate: string | Date, royaltyBeginDate: string | Date) {
        if ([1, 2, 13].every(cid => cid != conceptId)) {
            let pivotDate = moment("2016-04-01");
            let pivotField = pivotDate.isBefore(contractSignedDate, "day") ? "ContractSignedDate" : "RoyaltyBeginDate";
            return pivotField;
        }
        else if ([2, 13].some(cid => cid == conceptId)) {
            let pivotDate = moment("2018-09-25");
            let pivotField = pivotDate.isBefore(contractSignedDate, "day") ? "ContractSignedDate" : "RoyaltyBeginDate";
            return pivotField;
        }
        else if (1 == conceptId) {
            let pivotDate = moment("2020-10-07");
            let pivotField = pivotDate.isBefore(contractSignedDate, "day") ? "ContractSignedDate" : "RoyaltyBeginDate";
            return pivotField;
        }
        else {
            return nameof(contractSignedDate);
        }
    }

    CalculateWIBForContractEndDate() {
        let contractEndsDate = new Date(this.royaltyContract.ContractEndsDate);
        let calculationDate = moment({ year: contractEndsDate.getFullYear(), month: contractEndsDate.getMonth(), date: contractEndsDate.getDate() }).toDate();
        let pivotField = this.PickPivotField(this.conceptId, this.royaltyContract.ContractSignedDate, this.royaltyContract.RoyaltyBeginsDate);
        let start = moment(this.royaltyContract[pivotField]);
        let end = moment(calculationDate);
        start.utcOffset("day");
        end.startOf("day");
        start.utcOffset(0, true);
        end.utcOffset(0, true);
        let duration = moment.duration(end.diff(start));
        let totalDaysInBusiness = duration.asDays();
        let weeksInBusiness = Math.trunc(totalDaysInBusiness / 7);
        return weeksInBusiness;
    }

    showSnackbar: boolean;
    snackbarType: string;
    snackbarMessage: string;

    DisplaySnackbar(type: string, message: string) {
        this.showSnackbar = !this.showSnackbar;
        this.snackbarType = type;
        this.snackbarMessage = message;
        this.$scope.$evalAsync();
    }

    async CheckIsRoyaltyFequencyIdChanged() {
        if (this.$scope.isRoyaltyFrequencyChanged &&
            !this.IsRoyaltyFrequencyChangedReasonEntered()) {
            this.newAdjustmentWindow?.center();
            this.newAdjustmentWindow?.open();
        }
        else {
           
            
            this.Save();
        }
    }

    async SaveFrequencyChangeReasonAndFeeSettings() {
        let isValid = true;
        const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
        if (!this.royaltyEffectiveDate) {
            this.dateErrorMessage = "Effective Date is required.";
            isValid = false;
        }
        else if (this.royaltyEffectiveDate && !dateRegex.test(this.royaltyEffectiveDate)) {
            this.dateErrorMessage = "Invalid date format. Please use MM/DD/YYYY.";
            isValid = false;
        } else {
            this.dateErrorMessage = "";
        }

        if (!this.royaltyFrequencyChangeReason || this.royaltyFrequencyChangeReason?.trim().length == 0) {
            this.errorMessage = "Reason for change is required.";
            isValid = false;
        } else if (this.royaltyFrequencyChangeReason.length > 100) {
            this.errorMessage = "Reason for change is limited to 100 characters.";
            isValid = false;
        } else {
            this.errorMessage = "";
        }

        if (!isValid) {
            this.isLoading = false;
            return;
        }

        this.errorMessage = "";
        this.dateErrorMessage = "";
        this.Save();
    }
    ResetRoyaltyFrequencyId() {
        if (!this.isSaveClicked) {
            this.feeSettingsController.ResetRoyaltyFrequencyId();
            this.$scope.isRoyaltyFrequencyChanged = false;
            this.royaltyFrequencyChangeReason = "";
        }
        this.errorMessage = "";
        this.dateErrorMessage = "";
        
        
    }


    CancelNewAdjustment() {
        //this.feeSettingsController.ResetRoyaltyFrequencyId();
       // this.$scope.isRoyaltyFrequencyChanged = false;
        this.newAdjustmentWindow?.close();
    }

    IsRoyaltyFrequencyChanged() {
        return this.$scope.isRoyaltyFrequencyChanged;
    }

    IsRoyaltyFrequencyChangedReasonEntered() {
        if (!this.royaltyEffectiveDate || !this.royaltyFrequencyChangeReason || this.royaltyFrequencyChangeReason == null || this.royaltyFrequencyChangeReason?.trim().length == 0)
            return false;

        return true;
    }

    async Save() {
        this.isSaveClicked = true;
        this.newAdjustmentWindow?.close();
        this.isSaveClicked = false;
        let weeksInBusiness = this.CalculateWIBForContractEndDate();
        //console.log("weeksInBusiness", weeksInBusiness);

        //rate plan error check
        let isRatePlanError = false;
        let hasMinimumWibEndError = false;

        if (this.ratePlansController.royaltyRatePlanGrid && this.ratePlansController.royaltyRatePlanGrid.dataSource) {
            let royaltyRatePlanGridData = this.ratePlansController.royaltyRatePlanGrid.dataSource.data().filter(x => { return x["DeletedDateTime"] == null ? true : false });

            for (let i = 0; i < royaltyRatePlanGridData.length; i++) {

                if (royaltyRatePlanGridData && royaltyRatePlanGridData[i]) { 

                    //check missing WIB Start
                    if (royaltyRatePlanGridData[i]['WeeksInBusinessStart'] == null || royaltyRatePlanGridData[i]['WeeksInBusinessStart'] == undefined) {
                        this.validateionPopupMessage("Please enter WIB Start for Royalty Rate Plan", "Error!", true);
                        isRatePlanError = true;
                        break;
                    }

                    //check missing WIB End
                    if (royaltyRatePlanGridData[i]['WeeksInBusinessEnd'] == null || royaltyRatePlanGridData[i]['WeeksInBusinessEnd'] == undefined) {
                        this.validateionPopupMessage("Please enter WIB End for Royalty Rate Plan", "Error!", true);
                        isRatePlanError = true;
                        break;
                    }

                    //check if rate plan has equal WIB start and end
                    if (royaltyRatePlanGridData[i]['WeeksInBusinessStart'] == royaltyRatePlanGridData[i]['WeeksInBusinessEnd']) {
                        this.validateionPopupMessage("WIB Start and WIB End cannot be equal for Royalty Rate Plan", "Error!", true);
                        isRatePlanError = true;
                        break;
                    }

                    //check for upper and lower limit
                    if (royaltyRatePlanGridData[i]['WeeksInBusinessEnd'] < -500 || royaltyRatePlanGridData[i]['WeeksInBusinessEnd'] > 10000) {
                        this.validateionPopupMessage("WIB End should range between -500 to 10000 for Royalty Rate Plan", "Error!", true);
                        isRatePlanError = true;
                        break;
                    }

                    //check for upper and lower limit
                    if (royaltyRatePlanGridData[i]['WeeksInBusinessStart'] < -500 || royaltyRatePlanGridData[i]['WeeksInBusinessStart'] > 10000) {
                        this.validateionPopupMessage("WIB Start should range between -500 to 10000 for Royalty Rate Plan", "Error!", true);
                        isRatePlanError = true;
                        break;
                    }

                    //check if wib start is greater than wib end
                    if (royaltyRatePlanGridData[i]['WeeksInBusinessStart'] > royaltyRatePlanGridData[i]['WeeksInBusinessEnd']) {
                        this.validateionPopupMessage("WIB Start should be less than WIB End for Royalty Rate Plan", "Error!", true);
                        isRatePlanError = true;
                        break;
                    }

                    //Add all WIB range values
                    if (royaltyRatePlanGridData && royaltyRatePlanGridData.length > 1 && royaltyRatePlanGridData[i]) {

                        if (i == 0) {

                            if ((royaltyRatePlanGridData[i]['WeeksInBusinessEnd'] + 1) != royaltyRatePlanGridData[i + 1]['WeeksInBusinessStart']) {
                                this.validateionPopupMessage("WIB should not be skipped or overlapped for Royalty Rate Plan", "Error!", true);
                                isRatePlanError = true;
                                break;
                            }
                        }
                        else if (i > 0 && ((i + 1) < royaltyRatePlanGridData.length)) {

                            if ((royaltyRatePlanGridData[i]['WeeksInBusinessStart'] - 1) != royaltyRatePlanGridData[i - 1]['WeeksInBusinessEnd']) {
                                this.validateionPopupMessage("WIB should not be skipped or overlapped for Royalty Rate Plan", "Error!", true);
                                isRatePlanError = true;
                                break;
                            }

                            if ((royaltyRatePlanGridData[i]['WeeksInBusinessEnd'] + 1) != royaltyRatePlanGridData[i + 1]['WeeksInBusinessStart']) {
                                this.validateionPopupMessage("WIB should not be skipped or overlapped for Royalty Rate Plan", "Error!", true);
                                isRatePlanError = true;
                                break;
                            }
                        }
                        else if (i > 0 && ((i + 1) == royaltyRatePlanGridData.length)) {

                            if ((royaltyRatePlanGridData[i]['WeeksInBusinessStart'] - 1) != royaltyRatePlanGridData[i - 1]['WeeksInBusinessEnd']) {
                                this.validateionPopupMessage("WIB should not be skipped or overlapped for Royalty Rate Plan", "Error!", true);
                                isRatePlanError = true;
                                break;
                            }
                        }
                    }
                }
            }

            if (isRatePlanError) {
                this.isLoading = false;
                return true;
            }
        }

        if (isRatePlanError) {
            this.isLoading = false;
            return true;
        }

        // Update the WIB end value in the minimums controller with the latest value from the grid
        if (this.selectedRatePlanId && this.ratePlanMinimumsController) {
            const gridData = this.ratePlansController.royaltyRatePlanGrid.dataSource.data();
            const selectedRatePlan = gridData.find((rp: any) => 
                rp['ContractRatePlanId'] === this.selectedRatePlanId && rp['DeletedDateTime'] === null);
            if (selectedRatePlan) {
                // Update the WIB end value in the minimums controller using the public method
                this.ratePlanMinimumsController.UpdateWIBEndValue(selectedRatePlan['WeeksInBusinessEnd']);
                console.log("Updated WIB end value before validation (non-dynamic flow):", selectedRatePlan['WeeksInBusinessEnd']);
            }
        }
     
        // Check for errors in minimums controller, specifically for WIB End validation
        let hasErrors = false;
        
        // Check for minimums validation errors
        if (this.ratePlanMinimumsController) {
            try {
                const validationErrors = this.ratePlanMinimumsController.ValidateRatePlanMinimumGrid();
                if (validationErrors && validationErrors.length > 0) {
                    hasErrors = true;
                    
                    // Check specifically for WIB End validation errors
                    for (const error of validationErrors) {
                        if (error.propertyName === "WIB End" && 
                            error.errorMessage.includes("WIB End in minimums should not be greater than WIB End in rate plan")) {
                            hasMinimumWibEndError = true;
                            break;
                        }
                    }
                    
                    // Display validation errors in the grid
                    this.ratePlanMinimumsController.UpdateGridOptions(validationErrors);
                    this.ratePlanMinimumsController.validationErrorsKendoWindow.open();
                }
            } catch (error) {
                console.error("Error validating rate plan minimums:", error);
                hasErrors = true;
            }
        }

        // If any errors exist, don't proceed with saving
        if (hasErrors || hasMinimumWibEndError) {
            this.isLoading = false;
            return true;
        }

        this.isLoading = true;

        try {
            if (this.feeSettingsController) {
                this.feeSettingsController.royaltyFrequencyChangeReason = this.royaltyFrequencyChangeReason;
                if (this.royaltyEffectiveDate) {
                    const selectedDate = new Date(this.royaltyEffectiveDate);
                    this.feeSettingsController.royaltyEffectiveDate = new Date(Date.UTC(
                        selectedDate.getFullYear(),
                        selectedDate.getMonth(),
                        selectedDate.getDate()
                    )).toISOString().split('.')[0];
                } else {
                    this.feeSettingsController.royaltyEffectiveDate = null;
                }
                console.log("in fee sttings controller cm Royalties"+ this.feeSettingsController.royaltyEffectiveDate);
                await this.feeSettingsController.SaveChanges();
            }
    
            if (this.ratePlansController) {
                await this.ratePlansController.SaveChanges();
            }
    
            if (this.ratePlanItemsController) {
                await this.ratePlanItemsController.SaveChanges();
            }
    
            if (this.serviceCategoriesController) {
                await this.serviceCategoriesController.SaveChanges();
            }

            if (this.ratePlanMinimumsController) {
                await this.ratePlanMinimumsController.SaveChanges();
            }

            if (this.ratePlanMinimumsController?.addRatePlanItemsValidationErrorGrid?.dataSource) {
                this.ratePlanMinimumsController.addRatePlanItemsValidationErrorGrid.dataSource.data([]);
            }

            if (this.ratePlanMinimumsController?.validationErrorsKendoWindow) {
                this.ratePlanMinimumsController.validationErrorsKendoWindow.close();
            }

            this.$scope.isRoyaltyFrequencyChanged = false;
            this.royaltyFrequencyChangeReason = "";
            this.royaltyEffectiveDate = null;
            this.DisplaySnackbar("success", "Successfully saved the changes!");

        } catch (error) {
            console.error(error); // Log the error for debugging purposes
            this.DisplaySnackbar("error", "An error occurred while saving changes.");
        } finally {
            this.isLoading = false;
        }
    }
    
    static BindComponent(app: ng.IModule)
    {
        app
            .component("cmRoyalties", {
                bindings: {
                    [nameof<CmRoyaltiesComponentController>(o => o.contractId)]: "<",
                    [nameof<CmRoyaltiesComponentController>(o => o.franchisorId)]: "<",
                    [nameof<CmRoyaltiesComponentController>(o => o.conceptId)]: "<",
                    [nameof<CmRoyaltiesComponentController>(o => o.init)]: "&?"
                },
                templateUrl: '/Templates/ContractManagement/cmRoyalties.html',
                controller: CmRoyaltiesComponentController
            });
    }
}