import { ParsedPosEmployeeId, SyncApiResources } from "Services/Resources/SyncApiResources";
import { PosEmployeeDisplay } from "Directives/Users/EditUser/EditUserFranchisePosEmployee";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AngularUtil } from "Utility/AngularUtil";
import { EditUserStateService } from "Services/State/EditUserStateService";

export class EditUserPosEmployeeDisplayComponentController implements ng.IController 
{
    posEmployeeId: ParsedPosEmployeeId | string;
    posEmployeeDisplay: PosEmployeeDisplay;

    isLoading: boolean;
    
    static $inject = [
        '$q',
        'editUserState',
        'syncApiResources',
        'identityManager',
        'apiConfig',
    ];

    constructor(
        private $q: ng.IQService,
        private editUserState: EditUserStateService,
        private syncApiResources: SyncApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
    )
    {
    }

    $onInit()
    {
        
    }

    $postLink()
    {

    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        this.Load();
    }

    Load()
    {
        if (this.posEmployeeId)
        {
            if (typeof this.posEmployeeId === "string")
                this.posEmployeeId = this.syncApiResources.ParsePosEmployeeIdClaimValue(this.posEmployeeId);

            let promise: ng.IPromise<PosEmployeeDisplay> = null;
            let type = this.posEmployeeId.Type;

            if (this.posEmployeeId.Type == "T") {
                promise = this.syncApiResources.GetSyncTechnicianSimpleDetails(this.posEmployeeId.Id)
                    .then((detailResponse) =>
                    {
                        if (!detailResponse.data) {
                            return null;
                        }
                        return <PosEmployeeDisplay>{
                            Id: detailResponse.data.SyncTechnicianId,
                            Display: detailResponse.data.Name,
                            Type: type
                        };
                    })
            }
            else if (this.posEmployeeId.Type == "E") {
                promise = this.syncApiResources.GetSyncEmployeeSimpleDetails(this.posEmployeeId.Id)
                    .then((detailResponse) =>
                    {
                        if (!detailResponse.data) {
                            return null;
                        }
                        return <PosEmployeeDisplay>{
                            Id: detailResponse.data.SyncEmployeeId,
                            Display: detailResponse.data.Name,
                            Type: type
                        };
                    })
            }
            else {
                promise = this.$q.resolve(null);
            }

            promise = promise
                .then((result) =>
                {
                    this.posEmployeeDisplay = result;
                    return result;
                });

            AngularUtil.TrackLoadingPromise(promise, this)
                .catch(AngularUtil.GetJsonAlertCatch())
        }
        else {
            this.posEmployeeDisplay = null;
        }
    }
    
    static BindComponent(app: ng.IModule)
    {
        app.component("editUserPosEmployeeDisplay", {
            bindings: {
                [nameof<EditUserPosEmployeeDisplayComponentController>(o => o.posEmployeeId)]: "<"
            },
            controller: EditUserPosEmployeeDisplayComponentController,
            templateUrl: "/Templates/Users/EditUser/EditUserPosEmployeeDisplay.html"
        });
    }
}

