

export class StorageService
{
    localStorage: ILocalStorage;
    sessionStorage: Storage;

    static $inject = [

    ];

    constructor(

    )
    {
        this.localStorage = <ILocalStorage>localStorage;
        this.sessionStorage = sessionStorage;
    }

    $onInit()
    {

    }

    static BindService(app: ng.IModule)
    {
        app.service("storage", StorageService);
    }
}
