import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { DppEntities, DppResources } from "Interfaces/FranForce/Dpp/DppResources";

export class ProfileCompletenessService
{
    coreApiResources: CoreApiResources;

    constructor(coreApiResources: CoreApiResources)
    {
        this.coreApiResources = coreApiResources;
    }

    static Init(dppApp: ng.IModule)
    {
        dppApp.factory("ProfileCompleteness", [
            "coreApiResources",
            (coreApiResources: CoreApiResources) =>
            {
                return new ProfileCompletenessService(coreApiResources);
            }
        ]);
    }

    AssessProfile(profile: DppResources.IDisasterPrepProfile)
    {
        return new ProfileCompletenessAssessment(this, profile);
    }
    
    IsDisplayableBuilding(building: DppEntities.DisasterPrepProfileBuilding): boolean {
        return (!!building &&
            (
                !!building.BuildingName ||
                !!building.Address ||
                !!building.Address2 ||
                !!building.City ||
                !!building.StateAbbr ||
                !!building.PostalCode ||
                !!building.YearBuilt ||
                !!building.Stories ||
                !!building.SquareFeet
            )
        );
    }

    IsDisplayableContactGroup(contactGroup: DppEntities.DisasterPrepProfileBuildingContactGroup): boolean {
        return (!!contactGroup &&
            (
                !!contactGroup.AlarmDisasterPrepProfileContact ||
                !!contactGroup.BuildingOwnerDisasterPrepProfileContact ||
                !!contactGroup.FireDisasterPrepProfileContact ||
                !!contactGroup.HospitalDisasterPrepProfileContact ||
                !!contactGroup.MaintenanceDirectorDisasterPrepProfileContact ||
                !!contactGroup.PoliceDisasterPrepProfileContact ||
                !!contactGroup.PrimaryContactDisasterPrepProfileContact ||
                !!contactGroup.PropertyManagerDisasterPrepProfileContact ||
                !!contactGroup.SecurityDisasterPrepProfileContact
            )
        );
    }

    IsDisplayableInsurance(insurance: DppEntities.DisasterPrepProfileBuildingInsurance) : boolean
    {
        return (!!insurance &&
            (
                (!!insurance.Agency) ||
                (!!insurance.AgencyDisasterPrepProfileContact) ||
                (!!insurance.CarrierDisasterPrepProfileContact) ||
                (!!insurance.CarrierPolicy) ||
                (!!insurance.CarrierAfterHoursPhone)
            )
        );
    }

    IsDisplayableInformationTechnology(informationTechnology: DppEntities.DisasterPrepProfileBuildingInformationTechnology) : boolean
    {
        return (!!informationTechnology &&
            (
                (typeof informationTechnology.NumberOfServers === "number") ||
                (typeof informationTechnology.HasBatteryBackup === "boolean") ||
                (typeof informationTechnology.IsKeyRequired === "boolean") ||
                (!!informationTechnology.SystemShutOffDirections) ||
                (!!informationTechnology.SystemLocations)
            )
        );
    }

    IsDisplayableHazardInfo(hazardInfo: DppEntities.DisasterPrepProfileBuildingHazardousMaterial)
    {
        return !!hazardInfo;
    }

    IsDisplayableUtility(utility: DppEntities.DisasterPrepProfileBuildingUtility): boolean {
        return (!!utility &&
            (
                !!utility.AccountNumber ||
                typeof utility.CanBeShutOff === "boolean" ||
                !!utility.ShutOffDirections ||
                !!utility.ShutOffLocation ||
                typeof utility.HasMeter === "boolean" ||
                !!utility.MeterLocation ||
                !!utility.OtherDetails
            ));
    }

    IsDisplayableServiceProvider(serviceProvider: DppEntities.DisasterPrepProfileServiceProvider): boolean
    {
        return (!!serviceProvider && (
            (
                (serviceProvider.UseFranchise && !!serviceProvider.FranchiseId)
                ||
                (!serviceProvider.UseFranchise && !!serviceProvider.DisasterPrepProfileExternalProvider)
            )
        ));
    }
}

export class ProfileCompletenessAssessment
{
    profileCompletenessService: ProfileCompletenessService;
    dppProfile: DppResources.IDisasterPrepProfile;

    isValid: boolean;
     
    buildingAssessmentDictionary: { [buildingId: number]: BuildingAssessment; }

    
    constructor(profileCompletenessService: ProfileCompletenessService, profile: DppResources.IDisasterPrepProfile)
    {
        this.profileCompletenessService = profileCompletenessService;
        this.dppProfile = profile;
        
        this.Reassess();
    }

    /**
     * Reassesses the current profile or the one passed in. 
     * @param updatedProfile
     */
    Reassess(updatedProfile?: DppResources.IDisasterPrepProfile)
    {
        if (updatedProfile)
        {
            this.dppProfile = updatedProfile;
        }

        this.isValid = (!!this.dppProfile);
        this.buildingAssessmentDictionary = [];

        if (this.isValid)
        {
            for (let dppBuilding of this.dppProfile.DisasterPrepProfileBuildings)
            {
                this.buildingAssessmentDictionary[dppBuilding.DppBuildingId] = new BuildingAssessment(this.profileCompletenessService,
                    <DppResources.IDisasterPrepProfileBuilding>dppBuilding);
            }
        }
    }
}

class BuildingAssessment
{
    profileCompletenessService: ProfileCompletenessService;
    dppBuilding: DppResources.IDisasterPrepProfileBuilding;

    isDisplayableBuildingInfo: boolean;
    isDisplayableContacts: boolean;
    isDisplayableInsurance: boolean;
    isDisplayableInformationTechnology: boolean;
    isDisplayableHazards: boolean;
    
    isDisplayableUtilities: boolean;
    isDisplayableUtilitiesDictionary: { [dppUtilityId: number]: boolean; };
    isDisplayableServiceProviders: boolean;
    isDisplayableServiceProvidersDictionary: { [dppServiceProviderId: number]: boolean; };
    
    constructor(profileCompletenessService: ProfileCompletenessService, building: DppResources.IDisasterPrepProfileBuilding)
    {
        this.profileCompletenessService = profileCompletenessService;
        this.dppBuilding = building;

        this.Reassess();
    }

    Reassess(updatedBuilding?: DppResources.IDisasterPrepProfileBuilding)
    {
        if (updatedBuilding)
        {
            this.dppBuilding = updatedBuilding;
        }

        this.isDisplayableBuildingInfo = this.profileCompletenessService.IsDisplayableBuilding(this.dppBuilding);

        if (this.dppBuilding)
        {
            this.isDisplayableContacts = this.profileCompletenessService.IsDisplayableContactGroup(this.dppBuilding.DisasterPrepProfileBuildingContactGroup);
            this.isDisplayableInsurance = this.profileCompletenessService.IsDisplayableInsurance(this.dppBuilding.DisasterPrepProfileBuildingInsurance);
            this.isDisplayableInformationTechnology = this.profileCompletenessService.IsDisplayableInformationTechnology(this.dppBuilding.DisasterPrepProfileBuildingInformationTechnology);
            this.isDisplayableHazards = this.profileCompletenessService.IsDisplayableHazardInfo(this.dppBuilding.DisasterPrepProfileBuildingHazardousMaterial);

            this.isDisplayableUtilitiesDictionary = [];
            this.isDisplayableUtilities = false;
            if (this.dppBuilding.DisasterPrepProfileBuildingUtilities)
            {
                for (let util of this.dppBuilding.DisasterPrepProfileBuildingUtilities)
                {
                    this.isDisplayableUtilitiesDictionary[util.DppUtilityTypeId] = this.profileCompletenessService.IsDisplayableUtility(util);

                    if (this.isDisplayableUtilitiesDictionary[util.DppUtilityTypeId])
                    {
                        this.isDisplayableUtilities = true;
                    }
                }
            }

            this.isDisplayableServiceProvidersDictionary = [];
            this.isDisplayableServiceProviders = false;
            if (this.dppBuilding.DisasterPrepProfileServiceProviders)
            {
                for (let provider of this.dppBuilding.DisasterPrepProfileServiceProviders)
                {
                    this.isDisplayableServiceProvidersDictionary[provider.DppServiceProviderTypeId] = this.profileCompletenessService.IsDisplayableServiceProvider(provider);

                    if (this.isDisplayableServiceProvidersDictionary[provider.DppServiceProviderTypeId])
                    {
                        this.isDisplayableServiceProviders = true;
                    }
                }
            }

        }
        else {
            this.isDisplayableContacts = false;
            this.isDisplayableInsurance = false;
            this.isDisplayableInformationTechnology = false;
            this.isDisplayableHazards = false;
            this.isDisplayableUtilities = false;
            this.isDisplayableUtilitiesDictionary = [];
            this.isDisplayableServiceProviders = false;
            this.isDisplayableServiceProvidersDictionary = [];
        }
    }
}
