
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { RmsStateService } from "Services/State/RmsState";
import * as _ from "underscore"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { SqlDbType } from "@TypeGen/Enums";

export class rmsKeyValueGridComponentController implements ng.IController 
{
    kvpGridDataSource: kendo.data.DataSource;
    kvpDropDownDataSource: kendo.data.DataSource;
    canEdit: boolean;
    init: (params: { self: rmsKeyValueGridComponentController }) => void;
    gridInit: (params: { options: kendo.ui.GridOptions, self: rmsKeyValueGridComponentController }) => void;
    onKeySelect: (params: {
        ddlEvent: kendo.ui.DropDownListSelectEvent,
        container: JQuery,
        options: kendo.ui.GridColumnEditorOptions
    }) => void;
    
    kvGrid: kendo.ui.Grid;
    kvpDropDownPromise: ng.IPromise<any>;
    isLoading: boolean;

    dataTypes: RpmEntities.usp_RMS_GetKeyValueDataTypes_Result[]
    
    static $inject = [
        'rpmApiResources',
        'rpmUiApiResources',
        'coreApiResources',
        'identityManager',
        'rpmHelper',
        "$q",
        "rmsState"
    ]

    constructor(
        private rpmApiResources: RpmApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private rpmHelper: RpmHelperService,
        private $q: ng.IQService,
        private rmsState: RmsStateService,
    ) {
        
    }

    $onInit()
    {
        if (this.init)
            this.init({ self: this })
    }

    $postLink()
    {
        if (this.kvpGridDataSource) {
            this.InitKvpGrid();
        }
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.kvpGridDataSource)] && this.kvGrid) {
            this.InitKvpGrid()
        }

        if (changes[nameof(this.kvpDropDownDataSource)] && this.kvpDropDownDataSource) {
            this.kvpDropDownPromise = this.$q.when(this.kvpDropDownDataSource.read());
        }

        if (changes[nameof(this.canEdit)] && this.kvGrid)
        {
            this.kvGrid.setOptions({ editable: this.canEdit });
        }
    }

    InitKvpGrid()
    {
        let kvColumns: Array<kendo.ui.GridColumn> = [
            {
                field: this.kvpGridDataSource.options.schema.model.id,
                hidden: true
            }, {
                field: nameof<IRmsKeyValueRecord>(o => o.RoyaltyKeyValuePairId),
                title: "Key",
                editor: (container, options) =>
                {
                    this.KeyEditor(container, options);
                },
                template: this.KeyTemplate
            }, {
                title: "Description",
                template: this.DescriptionTemplate
            }, {
                field: nameof<IRmsKeyValueRecord>(o => o.Value),
                title: "Value",
                editor: (container, options) =>
                {
                    this.ValueEditor(container, options);
                },
                template: this.ValueTemplate
            }, {
                command: [{name: "destroy" }],
                title: "&nbsp;",
                width: "250px"
            }];


        let options: kendo.ui.GridOptions = {
            columns: kvColumns,
            dataSource: this.kvpGridDataSource,
            sortable: true,
            toolbar: [
                {
                    template: `<button type='button' class='pure-button' ng-click='$ctrl.${nameof(this.AddNewKvp)}()'>New</button>`
                }, {
                    template: `<button type='button' class='pure-button' ng-click='$ctrl.${nameof(this.CancelChanges)}()'>Cancel</button>`
                },
            ],
            editable: this.canEdit,
            navigatable: true
        }
        
        if (this.gridInit)
            this.gridInit({ options: options, self: this });

        this.$q.all([
            this.rpmUiApiResources.GetAllRoyaltyDataTypes()
                .then(typesResponse =>
                {
                    this.dataTypes = typesResponse.data;
                }),
            this.$q.when(this.kvpDropDownPromise)
        ])
            .then(() => {
                this.kvGrid.setOptions(options);
            })
    }

    KeyEditor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) =>
    {
        let input = "<input name='" + options.field + "'/>";
        let angularContainer = angular.element(container);
        angularContainer.append(input);

        angular.element(angular.element(container).children()[0]).kendoDropDownList({
            dataTextField: nameof<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result>(o => o.KeyName),
            dataValueField: nameof<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result>(o => o.RoyaltyKeyValuePairId),
            value: options.model.get(nameof<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result>(o => o.RoyaltyKeyValuePairId)),
            valuePrimitive: true,
            dataSource: this.kvpDropDownDataSource,
            select: (e) =>
            {
                options.model.set(
                    nameof<IRmsKeyValueRecord>(o => o.RoyaltyKeyValuePairId),
                    e.dataItem[nameof<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result>(o => o.RoyaltyKeyValuePairId)]
                );

                if (this.onKeySelect)
                {
                    this.onKeySelect({
                        ddlEvent: e,
                        container: container,
                        options: options
                    });
                }
            }
        });     
        angularContainer.append("<p class='icon-error error-msg-" + options.field + "'></p>");
    }

    KeyTemplate = (dataItem: kendo.data.Model) =>
    {
        let royaltyKey = _.find<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result>(this.kvpDropDownDataSource.data(), (k) =>
        {
            return k.RoyaltyKeyValuePairId === dataItem.get(nameof<IRmsKeyValueRecord>(o => o.RoyaltyKeyValuePairId))
        });

        if (!royaltyKey) {
            return "Select Key...";
        }

        let dataType = _.find(this.dataTypes, dt => dt.DataTypeId == royaltyKey.DataTypeId);

        return `${royaltyKey.KeyName} - (${dataType.Name})`;
    }
    
    ValueEditor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) =>
    {
        let royaltyKey = _.find<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result>(this.kvpDropDownDataSource.data() , (k) =>
        {
            return k.RoyaltyKeyValuePairId === options.model.get(nameof<IRmsKeyValueRecord>(o => o.RoyaltyKeyValuePairId ))
        });

        if (!royaltyKey) {
            return;
        }

        let matchingDataType = _.find(this.dataTypes, (t) => { return royaltyKey.DataTypeId == t.DataTypeId });

        if (!matchingDataType) {
            return;
        }
        
        switch (matchingDataType.SqlDbTypeEnum)
        {
            case SqlDbType.Bit: 
                {
                    let input = "<input name='" + options.field + "'/>";
                    angular.element(container).append(input);

                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: "Description",
                        dataValueField: nameof<IRmsKeyValueRecord>(o => o.Value),
                        value: options.model.get(nameof<IRmsKeyValueRecord>(o => o.Value)),
                        valuePrimitive: true,
                        dataSource: {
                            data: [{
                                Description: "null", Value: null
                            },{
                                Description: "True", Value: true
                            }, {
                                Description: "False", Value: false
                            }]
                        },
                        select: (e) =>
                        {
                            options.model.set(nameof<IRmsKeyValueRecord>(o => o.Value), e.dataItem[nameof<IRmsKeyValueRecord>(o => o.Value)]);
                        }
                    });
                }
                break;
            case SqlDbType.Date:
                {
                    let input = "<input name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDatePicker({
                        value: options.model.get(nameof<IRmsKeyValueRecord>(o => o.Value)),
                        change: (e) =>
                        {
                            options.model.set(nameof<IRmsKeyValueRecord>(o => o.Value), kendo.toString(e.sender.value(), "M/d/yyyy"));
                        }
                    });
                }
                break;
            case SqlDbType.Money:
                {
                    this.rpmHelper.CurrencyEditor(container, options);
                }
                break;
            case SqlDbType.Decimal:
                {
                    this.rpmHelper.NumericEditor(container, options, {
                        decimals: 20,
                        format: "n20"
                    });
                }
                break;
            case SqlDbType.Int:
                {
                    this.rpmHelper.NumericEditor(container, options, {
                        format: "n0",
                        decimals: 0,
                    });
                }
                break;
            case SqlDbType.Float:
                {
                    this.rpmHelper.NumericEditor(container, options, {
                        format: "n7",
                        decimals: 7,
                    });
                }
                break;
            case SqlDbType.VarChar:
            default:
                {
                    let input = "<input name='" + options.field + "'/>";
                    angular.element(container).append(input);
                }
                break;
        }
    }

    ValueTemplate = (dataItem: kendo.data.Model) =>
    {
        let matchingDataType = _.find<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result>(this.kvpDropDownDataSource.data(), (dt) =>
        {
            return dt.RoyaltyKeyValuePairId === dataItem.get(nameof<IRmsKeyValueRecord>(o => o.RoyaltyKeyValuePairId))
        });

        if (matchingDataType && matchingDataType.DataTypeId == SqlDbType.Money) {
            return kendo.toString(dataItem.get(nameof<IRmsKeyValueRecord>(o => o.Value)), 'c')
        }
        else {
            return dataItem.get(nameof<IRmsKeyValueRecord>(o => o.Value));
        }
    }

    DescriptionTemplate = (dataItem: kendo.data.Model) =>
    {
        let matchingDataType = _.find<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result>(this.kvpDropDownDataSource.data(), (dt) =>
        {
            return dt.RoyaltyKeyValuePairId === dataItem.get(nameof<IRmsKeyValueRecord>(o => o.RoyaltyKeyValuePairId))
        });

        return matchingDataType && matchingDataType.Description;
    }
    
    AddNewKvp = () =>
    {
        this.kvGrid.dataSource.add(<IRmsKeyValueRecord>{
            RoyaltyKeyValuePairId: null,
            Value: null
        });
    }

    CancelChanges = () => 
    {
        this.kvGrid.dataSource.cancelChanges();
    }

    HasChanges()
    {
        if (!this.kvGrid)
            return false;

        return this.kvGrid.dataSource.hasChanges();
    }

    ResetChanges()
    {
        if (!this.kvGrid)
            return this.$q.resolve();

        this.isLoading = true;
        this.kvGrid.dataSource.cancelChanges();
        return this.$q.when(this.kvGrid.dataSource.read())
            .then(() =>
            {
                this.kvGrid.refresh();
            })
            .finally(() =>
            {
                this.isLoading = false;
            });
    }

    SaveChanges()
    {
        this.isLoading = true;
        return this.$q.when(this.kvGrid.dataSource.sync()).then(() =>
        {
            return this.ResetChanges();
        }).finally(() => { this.isLoading = false; });
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("rmsKeyValueGrid", {
                bindings: {
                    [nameof<rmsKeyValueGridComponentController>(o => o.kvpGridDataSource)]: "<",
                    [nameof<rmsKeyValueGridComponentController>(o => o.kvpDropDownDataSource)]: "<",
                    [nameof<rmsKeyValueGridComponentController>(o => o.canEdit)]: "<",
                    [nameof<rmsKeyValueGridComponentController>(o => o.init)]: "&?",
                    [nameof<rmsKeyValueGridComponentController>(o => o.gridInit)]: "&?",
                    [nameof<rmsKeyValueGridComponentController>(o => o.onKeySelect)]: "&?",
                },
                template: `<loading-gears-overlay is-loading="$ctrl.isLoading"></loading-gears-overlay>
                <div kendo-grid="$ctrl.${nameof<rmsKeyValueGridComponentController>(o => o.kvGrid)}"
                 class="no-scrollbar"></div>`,
                controller: rmsKeyValueGridComponentController
            });
    }
}

