import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { reportsProcessModel } from "Models/Royalty/ManualSalesEntry/ReportsProcessModel";
import { RpmEntities } from "../../../Interfaces/FranForce/Rpm/RpmResources";
import { CoreEntities } from "../../../Interfaces/FranForce/Core/CoreResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";

export class ManualSalesProcessGridController implements ng.IController {

    //bindings
    franchisorId: number;

    //dialog
    processReportDialog: kendo.ui.Dialog;

    //periods to be added grid load data
    processReportGridOptions: kendo.ui.GridOptions;
    processReportGridDataSource: kendo.data.DataSource;
    processReportGrid: kendo.ui.Grid;
    isprocessReportGridShow: boolean;

    //Process Report Status
    processReportStatusCSSClass: string;
    showProcessReportStatus: boolean;
    processReportStatusMessage: string;

    isLoading: boolean;

    static $inject = [
        'rpmUiApiResources',
        'coreApiResources',
        'royaltyApiResources',
        '$timeout',
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private coreApiResources: CoreApiResources,
        private royaltyApiResources: RoyaltyApiResources,
        private $timeout: ng.ITimeoutService,
    ) {
    }

    $onInit() {
        this.voidAllValidations();
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.franchisorId)].currentValue != changes[nameof(this.franchisorId)].previousValue) {
            if (this.franchisorId) {
                this.franchisorId = changes[nameof(this.franchisorId)].currentValue;
            }
        }
    }

    onProcessReportsClick() {
        this.voidAllValidations();
        //open dialog
        var dialog = $("#processReportDialog").data("kendoDialog");
        dialog.open();
        this.GetDataForReportsProcessing();
    }

    voidAllValidations() {
        this.isprocessReportGridShow = false;

        this.showProcessReportStatus = false;
        this.processReportStatusCSSClass = "";
        this.processReportStatusMessage = "";
    }

    setProcessReportStatus(
        showProcessReportStatus: boolean,
        processReportStatusCSSClass: string,
        processReportStatusMessage: string
    ) {
        this.showProcessReportStatus = showProcessReportStatus;
        this.processReportStatusCSSClass = processReportStatusCSSClass;
        this.processReportStatusMessage = processReportStatusMessage;
    }

    Process(dataItem: RpmEntities.usp_RoyaltyManualSalesEntry_GetUnprocessedEntries_Result) {
        this.royaltyApiResources.ProcessSalesReportData({
            franchisorId: dataItem["franchisorId"],
            periodEndDate: dataItem["periodEndDate"],
            frequencyType: dataItem["royalPeriodFrequencyId"]
        }).then(
            (response) => {
                if (response.data && response.data["isValid"] == true) {
                    (<any>dataItem).RoyaltyReportBatchId = response.data["royaltyReportBatchId"];
                    return this.royaltyApiResources.GetRoyaltyReportBatchAggregates(response.data["royaltyReportBatchId"])
                        .then((batchResponse) => {
                            (<any>dataItem).BatchStatus = batchResponse.data["royaltyReportBatchStatusDescription"];
                        });
                }
                else {
                    var errorStr = 'Report data processesing failed <br /><ul>'
                    response.data["messages"].forEach(function (messageItem) {
                        errorStr += '<li>' + messageItem + '</li>';
                    });
                    errorStr += '</ul>';

                    this.setProcessReportStatus(true, "processReportFailureStatusCSSClass", errorStr);
                }
            });
    }

    //Get Data For Reports Processing
    GetDataForReportsProcessing() {
        this.isLoading = true;
        this.royaltyApiResources.GetDataForReportsProcessing(this.franchisorId)
            .then(
                (response) => {
                    this.isLoading = false;
                    if (response && response.data && response.data.length > 0) {
                        this.isprocessReportGridShow = true;
                        let reportsProcessInfo = response.data;

                        let processReportGridColumns: Array<kendo.ui.GridColumn> = [
                            {
                                field: nameof<reportsProcessModel>(o => o.franchisorDisplayName),
                                title: "Franchisor",
                            },
                            {
                                field: nameof<reportsProcessModel>(o => o.royalPeriodFrequency),
                                title: "Frequency"
                            },
                            {
                                field: nameof<reportsProcessModel>(o => o.periodEndDate),
                                title: "Period End Date",
                                template: (dataItem) => {
                                    return kendo.toString(kendo.parseDate(dataItem.periodEndDate), 'yyyy-MM-dd');
                                }
                            },
                            {
                                field: nameof<reportsProcessModel>(o => o.totalEntries),
                                title: "Entries",
                            },
                            {
                                field: nameof<reportsProcessModel>(o => o.franchisorId),
                                hidden: true
                            },
                            {
                                field: nameof<reportsProcessModel>(o => o.royalPeriodFrequencyId),
                                hidden: true
                            },
                            {
                                width: 150,
                                template: `<button promise-btn ng-show='!dataItem.RoyaltyReportBatchId' class='pure-button' style='width: 100%; box-sizing: border-box;' ng-click='$ctrl.Process(dataItem)'>Process</button>
                                    <div ng-show='dataItem.RoyaltyReportBatchId'>
                                        <a ng-href='/BatchManagement\\#/bm/batchId/{{dataItem.RoyaltyReportBatchId}}' target='_blank'>View Report Batch</a> <br>
                                        Status: <span ng-bind='dataItem.BatchStatus'></span> <img src='/Content/css/images/icons/loading.gif' ng-show='!dataItem.BatchStatus'>
                                    <div>`
                            }
                        ];

                        this.processReportGridDataSource = new kendo.data.DataSource({
                            transport: {
                                parameterMap: function (options, operation) {
                                    if (operation !== "read" && options.models) {
                                        return { models: kendo.stringify(options.models) };
                                    }
                                }
                            },
                            schema: {
                                model: {
                                    fields: {
                                        franchisorDisplayName: { type: "string" },
                                        periodEndDate: { type: "string" },
                                        totalEntries: { type: "number" },
                                        royalPeriodFrequency: { type: "string" },
                                        franchisorId: { type: "number" },
                                    }
                                }
                            }
                        });

                        this.processReportGridOptions = {
                            columns: processReportGridColumns,
                            dataSource: this.processReportGridDataSource,
                            sortable: true,
                            scrollable: true,
                            resizable: true,
                            height: 340
                        }

                        this.processReportGridDataSource.data(reportsProcessInfo);
                    }
                    else {
                        this.showProcessReportStatus = true;
                        this.processReportStatusCSSClass = "";
                        this.isprocessReportGridShow = false;
                        this.processReportStatusMessage = "No data available for processing";
                    }

                }).catch(err => {
                    this.isLoading = false;
                    alert("Error occured in fetching data for processing.")
                });
    }
 
    static BindComponent(app: ng.IModule) {
        app.component('manualSalesProcessGrid', {
            bindings: {
                [nameof<ManualSalesProcessGridController>(o => o.franchisorId)]: "<",
            },
            controller: ManualSalesProcessGridController,
            templateUrl: "/Templates/RPM/ManualSalesProcessGrid.html"
        });
    }
}


