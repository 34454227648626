import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RmsStateService } from "Services/State/RmsState";
import * as _ from "underscore";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RoyaltyPeriodReportDetailViewModel } from "Interfaces/FranForce/Rpm/PeriodReport/RatePlanItemModel";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";

export class PeriodReportSalesComponentController implements ng.IController {

    royaltyPeriodReportId: number;
    franchise: Partial<CoreEntities.Franchise>;
    init: (params: { self: PeriodReportSalesComponentController }) => void;

    isLoading: boolean;
    grid: kendo.ui.Grid;
    isDynamicFees: boolean;

    static $inject = [
        '$q',
        'rpmUiApiResources',
        'rmsState'
    ];

    constructor(
        private $q: ng.IQService,
        private rpmUiApiResources: RpmUiApiResources,
        private rmsState: RmsStateService,
    ) {
    }

    $onInit() {
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledPm').val() === 'True';
        if (this.init) {
            this.init({ self: this });
        }
    }

    $postLink() {
        this.InitGrid();

        if (this.franchise && this.royaltyPeriodReportId) {
            this.RefreshGrid();
        }

        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledPm').val() === 'True';
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (this.franchise && this.royaltyPeriodReportId)
        {
            let hasChanges = (onChanges[nameof(this.franchise)] || onChanges[nameof(this.royaltyPeriodReportId)]);
            let validBindings = this.franchise && this.royaltyPeriodReportId;
            if (hasChanges && validBindings) {
                this.RefreshGrid();
            }
        }
    }

    InitGrid()
    {
        let scColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.royaltyPeriodReportDetailId),
                hidden: true
            }, {
                field: nameof<RoyaltyPeriodReportDetailViewModel>(o => o.royaltyServiceCategoryId),
                hidden: true
            }, {
                field: `ServiceCategory.${nameof< RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description)}`,
                title: "Service Category",
                footerTemplate: "TOTALS:"
            }, {
                field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceResidentialGrossSalesAmount),
                title: "Residential Sales",
                aggregates: ["sum"],
                footerTemplate: "<div style='text-align:right'>#= kendo.toString(sum, 'c') #</div>",
                template: `<div style='text-align:right'>#=kendo.toString(${nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceResidentialGrossSalesAmount)}, 'c')#</div>`,
                headerAttributes: {
                    style: "text-align:right"
                }
            }, {
                field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceCommercialGrossSalesAmount),
                title: "Commercial Sales",
                aggregates: ["sum"],
                footerTemplate: "<div style='text-align:right'>#= kendo.toString(sum, 'c') #</div>",
                template: `<div style='text-align:right'>#=kendo.toString(${nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceCommercialGrossSalesAmount)}, 'c')#</div>`,
                headerAttributes: {
                    style: "text-align:right"
                }
            }, {
                field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceTotalGrossSalesAmount),
                title: "Total Sales",
                aggregates: ["sum"],
                footerTemplate: "<div style='text-align:right'>#= kendo.toString(sum, 'c') #</div>",
                template: `<div style='text-align:right'>#=kendo.toString(${nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceTotalGrossSalesAmount)}, 'c')#</div>`,
                headerAttributes: {
                    style: "text-align:right"
                }
            }
        ];

        let scDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.royaltyServiceCategoryId),
                    fields: {
                        [nameof< RoyaltyPeriodReportDetailViewModel>(o => o.royaltyServiceCategoryId)]: { type: "number", validation: { required: true } },
                        [nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceResidentialGrossSalesAmount)]: { type: "number", validation: { required: true } },
                        [nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceCommercialGrossSalesAmount)]: { type: "number", validation: { required: true } },
                        [nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceTotalGrossSalesAmount)]: { type: "number", validation: { required: true } },
                        ServiceCategory: { type: "object" }
                    }
                }
            },
            aggregate: [
                { field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceTotalGrossSalesAmount), aggregate: "sum" },
                { field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceResidentialGrossSalesAmount), aggregate: "sum" },
                { field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceCommercialGrossSalesAmount), aggregate: "sum" }
            ],
            transport: {
                read: (options) =>
                {
                    this.isDynamicFees = $('#hdnIsDynamicFeesEnabledPm').val() === 'True';
                    this.isLoading = true;
                    if (this.isDynamicFees) {
                        //Dynamic Flow
                        console.log("Dynamic Flow");

                        this.rpmUiApiResources.GetRoyaltyPeriodReportDetailsByPeriodReportDynamic(this.royaltyPeriodReportId)
                            .then((response) => {
                                // Map the response data to camelCase property names
                                const mappedDetails = response.data.map(detail => {
                                    // Create a new object with camelCase property names
                                    const mappedDetail: any = {};
                                    
                                    // Map each property to camelCase
                                    Object.keys(detail).forEach(key => {
                                        // Skip $id property
                                        if (key === '$id') return;
                                        
                                        // Convert PascalCase to camelCase (e.g., RoyaltyServiceCategoryId -> royaltyServiceCategoryId)
                                        const camelKey = key.charAt(0).toLowerCase() + key.slice(1);
                                        mappedDetail[camelKey] = detail[key];
                                    });
                                    
                                    return mappedDetail;
                                });

                                // Get service categories if needed for dynamic flow
                                if (this.franchise && this.franchise.FranchisorId) {
                                    this.rmsState.royaltyServiceCategoriesByFranchisorStateHandler.Load(this.franchise.FranchisorId)
                                        .then((serviceCategories) => {
                                            mappedDetails.map(detail => {
                                                (<any>detail).ServiceCategory = _.find(serviceCategories, (sc) => { return sc.RoyaltyServiceCategoryId == detail.royaltyServiceCategoryId; }) || { };
                                            });
                                            options.success(mappedDetails);
                                        })
                                        .catch((err) => {
                                            // If service categories can't be loaded, still provide a default serviceCategory
                                            for (let detail of mappedDetails) {
                                                detail.serviceCategory = {
                                                    Description: 'Unknown',
                                                    RoyaltyServiceCategoryId: detail.royaltyServiceCategoryId
                                                };
                                            }
                                            options.success(mappedDetails);
                                        }).finally(() => { this.isLoading = false; });
                                } 
                            
                            })                         
                    }
                    else {
                        //Non_Dynamic Flow
                        console.log("Non-Dynamic Flow");
                        this.rmsState.GetPeriodReportDetails(this.royaltyPeriodReportId, this.franchise.FranchisorId, this.isDynamicFees)
                            .then((periodDetails) => {

                                for (let detail of periodDetails.royaltyPeriodReportDetails) {
                                    (<any>detail).ServiceCategory = _.find(periodDetails.serviceCategories, sc => sc.RoyaltyServiceCategoryId === detail.royaltyServiceCategoryId) || {};
                                }

                                options.success(periodDetails.royaltyPeriodReportDetails);
                            })
                            .catch((err) => {
                                options.error(err);
                            })
                            .finally(() => { this.isLoading = false; });
                    }
                }
            }
        });

        this.grid.setOptions(<kendo.ui.GridOptions>{
            autoBind: false,
            columns: scColumns,
            dataSource: scDataSource,
            resizable: true
        });
    }

    RefreshGrid() {
        console.log('Before RefreshGrid, isDynamicFees:', this.isDynamicFees);
        if (this.grid) {
            return this.$q.when(this.grid.dataSource.read())
                .then(() => {
                    this.grid.refresh();
                    console.log('After grid refresh, isDynamicFees:', this.isDynamicFees);
                });
        }
    }


    static BindComponent(app: ng.IModule)
    {
        app.component('prSales', {
            bindings: {
                [nameof<PeriodReportSalesComponentController>(o => o.royaltyPeriodReportId)]: "<",
                [nameof<PeriodReportSalesComponentController>(o => o.franchise)]: "<",
                [nameof<PeriodReportSalesComponentController>(o => o.init)]: "&"
            },
            controller: PeriodReportSalesComponentController,
            templateUrl: "/Templates/PeriodReport/prSections/Sales.html"
        });
    }
}
