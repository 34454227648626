import * as _ from "underscore"

import { Helpers } from "Utility/Helpers";

import { IdentityManager } from "Services/Resources/IdentityManager";
import { Constants } from 'Constants';

export class PasswordValidationComponent implements ng.IController {
    //Props
    passwordExpirationDate: string;
    errorList: any;
    errorArray:any;
    daysToExpiry: number;
    channgePasswordModel: IChangePasswordBindingModel;;

    //Popup
    passwordValidatonPopup: kendo.ui.Window;
    passwordPopupValidationOptions: kendo.ui.WindowOptions;

    localStorage: ILocalStorage;

    static $inject = [
        "identityManager",
        "$scope",
        '$timeout',
        "$window",
        "$rootScope",
    ];

    constructor(
        private identityManager: IdentityManager,
        private $scope: ng.IScope,
        private $timeout: ng.ITimeoutService,
        private $window: ng.IWindowService,
        private $rootScope: ng.IRootScopeService,
    ) {
        this.$rootScope.$on(Constants.PopupEvents.OpenPasswordErrorValidationPopupEvent, (event,err) => {
            this.errorList=err;
            this.openPopup();
        });
    }

    async $onInit() {
        this.localStorage = <ILocalStorage>localStorage;
        this.channgePasswordModel = {
            OldPassword: '',
            NewPassword: '',
            ConfirmPassword: ''
        };

        this.passwordExpirationDate = this.localStorage.PassWordExpirationDate;

    }

    async $postLink() {

    }

    openPopup() {
        if(this.errorList){
            let error=[];
           
            if(this.errorList?.data.Message==='The request is invalid.'){
            
            if(typeof this.errorList.data.ModelState=='object'){
            
            let modelStateKeysArray=Object.keys(this.errorList.data.ModelState);
                modelStateKeysArray.forEach((obj)=>{
                   error.push(this.errorList.data.ModelState[obj][0]);
                    console.log(error);
                })
            }
            }else{
              error.push(this.errorList?.data.Message);
            }
           
            let options: kendo.ui.WindowOptions = {
                modal: true,
               position: { top: 150 },
                
    
            };
            
            this.errorArray=error
          
  
           // this.passwordValidatonPopup.title('Update password');

           this.passwordValidatonPopup.open();
           this.passwordValidatonPopup.title('Error'); 
          // this.passwordPopup.content(template)
           this.passwordValidatonPopup.center();
           this.passwordValidatonPopup.setOptions(options);



        }

    


    }
    closePopup() {
        this.passwordValidatonPopup.close()
    }



    static BindComponent(app: ng.IModule) {
        app.component('passwordValidationComponent', {
            controller: PasswordValidationComponent,
            templateUrl: "/Templates/PasswordPopup/PasswordValidationComponent.html"
        });
    }
}



