import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyFranchisorMinimumFeeIncreasesAndCapsRpmUiPartialService implements IRpmUiPartialService {

    endpointPrefix: string;
    $http: angular.IHttpService;

    RoyaltyFranchisorMinimumFeeIncreasesAndCapsByFranchisor(FranchisorId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContractMinimumFeeIncreasesAndCaps/InFranchisor/" + FranchisorId
        };
        return this.$http<RpmEntities.usp_RoyaltyFranchisorMinimumFeeIncreasesAndCaps_GetByFranchisorId_Result[]>(config);
    }

    GetFranchiseAdFeeCapInfo(FranchisorId: number, year: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContractMinimumFeeIncreasesAndCaps/InFranchisor/" + FranchisorId + "/" + year
        };
        return this.$http<RpmEntities.usp_RoyaltyFranchiseAdFeeCap_GetByFranchisorIdAndYear_Result[]>(config);
    }

    GetFranchiseAdFeeCapInfoForNewYear(FranchisorId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContractMinimumFeeIncreasesAndCaps/InFranchisor/GetFranchiseAdFeeCapInfoForNewYear/" + FranchisorId
        };
        return this.$http<RpmEntities.usp_RoyaltyFranchiseAdFeeCapForNewYear_GetByFranchisorId_Result[]>(config);
    }

    CreateRoyaltyFranchisorMinimumFeeIncreasesAndCaps(franchisorId: number, yearIncreased: number, adFeeCap: number) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyContractMinimumFeeIncreasesAndCaps/InFranchisor",
            params: {
                franchisorId: franchisorId, yearIncreased: yearIncreased, adFeeCap: adFeeCap
            }
        };
        return this.$http<number>(config);
    }

    RoyaltyFranchiseAdFeeCapForNewYear(FranchisorId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/Franchisors/newYear/" + FranchisorId
        };
        return this.$http<RpmEntities.usp_RoyaltyFranchiseAdFeeCapForNewYear_GetByFranchisorId_Result[]>(config);
    }

}