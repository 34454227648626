import { LegalEntities } from "Interfaces/FranForce/Legal/LegalResources";

let ittTitleInfoDirectiveConfig = [
    "$http", "coreApiResources", "ngDialog", "legalApiResources",
    function ($http, coreApiResources, ngDialog, legalApiResources)
{

    function link(scope, element, attrs, controller)
    {
        let legalApi = legalApiResources;
        scope.isConceptSelected = false;
        scope.isLicenseSelected = false;
        scope.isSpecificSelected = false;

        scope.newContractClick = function ()
        {
            ngDialog.open({
                template: 'NewContractModal',
                className: 'ngdialog-theme-default itt-modal',
                scope: scope,
                controller: ['$scope', ($scope) => { }]
            });
        }

        scope.newContract = function (contractTypeID)
        {
            window.location.href = "/Itt/CreateNewContract/" + this.selectedLicense.FranchiseId + "/" + contractTypeID;
        }

        scope.newSpecific = function ()
        {
            scope.isSpecificSelected = false;
            scope.$emit('selectionsChanged');
        }

        scope.specificChanged = function (arg)
        {
            scope.isSpecificSelected = true;

            var selected = arg.sender.dataItem(this.select());
            var contractID = selected.ContractID;

            $http.get('/Itt/GoToNextSection/' + scope.selectedFranchiseID + "/" + contractID).
                then(successResponse =>
                {
                    window.location.href = successResponse.data.url;
                }, errorResponse =>
                    {

                    });

            // Assign selection details to parent IttMainController scope variables 
            //scope.selectionDetails.franchiseID = scope.selectedFranchiseID;
            //scope.selectionDetails.contractID = contractID;

            // Emit Concept/License/Franchise have been selected
            // so that the section controller knows to retrieve neccessary data
            //scope.$emit('selectionsMade');

            //$http.get('/Itt/EditSectionOne/' + scope.selectedFranchiseID + '/' + contractID)
            //    .then(function (success) {
            //        debugger;
            //    }, function (error) {
            //        debugger;
            //    });
        }

        scope.comboBoxDataSource = new kendo.data.DataSource({ data: [] });
        scope.gridDataSource = new kendo.data.DataSource({ data: [] });

        scope.onConceptChange = function (concept)
        {
            if (scope.selectedConcept != "0") {
                scope.isConceptSelected = true;
                scope.isLicenseSelected = false;
                scope.isSpecificSelected = false;
                scope.selectedConceptName = concept.DisplayName;
                scope.selectedConceptID = concept.ConceptId;

                coreApiResources.FranchiseApi.query({
                    $select: "LicenseNumber, FranchiseId",
                    $filter: "ConceptId eq " + scope.selectedConceptID
                }, (franchises: IFranchiseOption[]) =>
                    {
                        scope.comboBoxDataSource.data(franchises);
                    });

            } else {
                scope.isConceptSelected = false;
            }

            // Clears out text in License combobox
            this.selectedLicense = "";
        }

        scope.onLicenseChange = function (item)
        {

            scope.isSpecificSelected = false;

            if (item == null) {
                scope.isLicenseSelected = false;
                scope.selectedLicenseNumber = null;
            }
            else {
                scope.isLicenseSelected = true;

                scope.selectedLicenseNumber = item.LicenseNumber;
                scope.selectedFranchiseID = item.FranchiseId;

                legalApi.ContractApi.query({
                    $filter: "FranchiseID eq " + scope.selectedFranchiseID,
                    $expand: "ContractType, ContractStatu",
                    $select: "DateCreated,ContractID"
                }, function successCallback(response: LegalEntities.Contract[])
                    {
                        console.log(response);
                        scope.gridDataSource.data(response);
                    }, function errorCallback(response)
                    {

                    });
            }
        }

        let specificColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "DateCreated",
                title: "Date Created",
                template: "#= kendo.toString(kendo.parseDate(DateCreated, 'yyyy-MM-dd'), 'MM/dd/yyyy') #",
                width: 128
            }, {
                field: "ContractType.Description",
                title: "Contract Type"
            }, {
                field: "ContractStatu.Description",
                title: "Status"
            }, {
                command: [{ name: "edit" }],
                title: "Edit"
            }, {
                title: "",
                template: "<div class='pure-u-1'>" +
                    "<span class='itt-col'>MR </span> |" +
                    "<span class='itt-col'>CURE </span> |" +
                    "<span class='itt-col'>CANCEL </span>" +
                    "</div>",
                width: "250px",
                sortable: false
            }
        ];

        scope.specificGridOptions = {
            columns: specificColumns,
            dataSource: scope.gridDataSource,
            selectable: true,
            change: scope.specificChanged
        };

        //coreApiResources.ConceptApi.query({ $select: "ConceptId, DisplayName" },
        //    (concepts: IConceptOption[]) => {
        //        scope.concepts = concepts;
        //    }); 

        coreApiResources.GetAllowedConcepts().then((concepts: IConceptOption) =>
        {
            scope.concepts = concepts;
        }, errorResponse =>
            {

            });
    }

    return {
        link: link,
        templateUrl: 'Templates/Itt/IttTitleInfoTemplate.html'
    };
}];

export { ittTitleInfoDirectiveConfig };