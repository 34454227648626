import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RestAxiosClient } from "Clients/RestAxiosClient";
import { IRestAxiosClient } from "Clients/IRestAxiosClient";

const royaltyDisclosureDocumentRatePlanMinimumClient = new RestAxiosClient<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum, number>(
    FranForceAxiosClientBuilder.BuildCoreBaseClient(),
    "/RoyaltyDisclosureDocumentRatePlanMinimums"
) as IRestAxiosClient<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum, number>;

export { royaltyDisclosureDocumentRatePlanMinimumClient };