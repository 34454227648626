import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
export class RoyaltyRatePlanItemTypesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetAllRoyaltyRatePlanItemTypes()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyRatePlanItemTypes/"
        };

        return this.$http<RpmEntities.RoyaltyRatePlanItemTypeViewModel[]>(config);
    }
}