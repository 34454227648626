import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { FranchiseClient, FranchiseSearchDto, FranchiseDto } from '@nbly/franchise-group-api-clients';
import { AxiosInstance } from "axios";
import { StringProperties } from "../../../Types/StringProperties";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            franchiseMultiSelect: Partial<StringProperties<FranchiseMultiSelectorComponentController>>;
        }
    }
}

export class FranchiseMultiSelectorComponentController implements ng.IController {

    //Clients
    franchiseGroupClient: AxiosInstance;
    franchiseClient: FranchiseClient;

    //DTO'S
    franchiseDTO: FranchiseDto[];
    selectedFranchise: FranchiseDto[] = [];


    dropdown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;
    franchiseMultiSelectOptions: kendo.ui.MultiSelectOptions;
    franchiseMultiSelect: kendo.ui.MultiSelect;

    //---- Bindings ----
    conceptId: number;
    select: string[];
    displayTextTemplate: string;
    sort: (a: Partial<FranchiseDto>, b: Partial<FranchiseDto>) => number;
    selectedFranchises: any[];
    enableSelectAll: boolean;
    //------------------

    static readonly DisplayProperty = "DisplayText";

    static $inject = [
        "$q",
        "$interpolate",
        "$location",
    ];

    constructor(
        private $q: ng.IQService,
        private $interpolate: ng.IInterpolateService,
        private $location: ng.ILocationService,
    ) {
        this.franchiseGroupClient = FranForceAxiosClientBuilder.BuildFranchiseGroupBaseClient();
        this.franchiseClient = new FranchiseClient("", this.franchiseGroupClient);
    }

    $onInit() {

        if (!this.select || !(this.select instanceof Array)) {
            this.select = [
                nameof<FranchiseDto>(o => o.franchiseId),
                nameof<FranchiseDto>(o => o.franchiseeName)
            ];
        }

        if (!this.displayTextTemplate)
            this.displayTextTemplate = `{{${nameof<FranchiseDto>(o => o.franchiseeName)}}}`;

        if (!this.sort)
            this.sort = (a, b) => { return a[FranchiseMultiSelectorComponentController.DisplayProperty].toLowerCase().localeCompare(b[FranchiseMultiSelectorComponentController.DisplayProperty].toLowerCase()); }

        this.SetDropDownOptions();
    }

    SetDropDownOptions() {
        this.franchiseMultiSelectOptions =
        {
            placeholder: "Select Franchises...",
            dataTextField: nameof<FranchiseDto>(o => o.franchiseeName),
            dataValueField: nameof<FranchiseDto>(o => o.franchiseId),
            valuePrimitive: true,
            autoBind: true,
            autoClose: false,
            clearButton: this.enableSelectAll ? false : true,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options: kendo.data.DataSourceTransportReadOptions) => {

                        let franchiseSearchDto: FranchiseSearchDto = {
                            conceptId: this.conceptId,
                            isActive: undefined,
                            externalId: undefined,
                            isDeleted: false,
                            isTest: undefined,
                            matchingFranchiseIds: undefined,
                            referenceId: undefined,
                            licenseNumber: undefined,
                            doingBusinessAs: undefined,
                            matchingLicenseNumbers: undefined,
                            franchisorId: undefined
                            
                        }

                        this.franchiseClient.post_protected_franchises_search('', franchiseSearchDto)
                            .then((result) => {

                                let franchises = result.result;
                                for (let franchise of franchises) {
                                    franchise[FranchiseMultiSelectorComponentController.DisplayProperty] = this.$interpolate(this.displayTextTemplate)(franchise);
                                }
                                if (this.sort) {
                                    franchises = franchises.sort(this.sort);
                                }

                                options.success(franchises);
                            })
                            .catch(err => console.log('err'));

                    }
                }
            }),
            dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) => {
                this.franchiseMultiSelect = e.sender;
            }
        };
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.conceptId)] && this.conceptId && this.conceptId != null) {

            if (!this.franchiseMultiSelectOptions)
                this.SetDropDownOptions();
            else {
                this.franchiseMultiSelect.dataSource.read();
            }

        }
        else {
            if (this.franchiseMultiSelectOptions && this.franchiseMultiSelectOptions.dataSource) {
                this.franchiseMultiSelectOptions.dataSource.data([]);
            }
        }
    }

    ClearAll() {
        this.franchiseMultiSelect.value([]);
        this.franchiseMultiSelect.trigger("change");
    }

    SelectAll() {
        let allFranchiseList: number[] = this.franchiseMultiSelect.dataSource.data().map((x, i) => x[nameof<FranchiseDto>(o => o.franchiseId)]);
        this.franchiseMultiSelect.value(allFranchiseList);
        this.franchiseMultiSelect.trigger("change");
    }

    static BindComponent(app: ng.IModule) {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.franchiseMultiSelect);

        app.component(componentName, {
            bindings: {
                [nameof<FranchiseMultiSelectorComponentController>(o => o.conceptId)]: "<",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.select)]: "<",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.displayTextTemplate)]: "<",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.sort)]: "<",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.selectedFranchises)]: "=",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.enableSelectAll)]: "<",

            },
            controller: FranchiseMultiSelectorComponentController,
            templateUrl: `/Templates/Reports/Common/FranchiseMultiSelect.html`
        });
    }
}