import { RmsDashboardMissingReportsResultVm } from '@nbly/royalty-orchestrations-clients';

export class rmsDashboardMissingReportsGridComponentController implements ng.IController {

    numberOfPeriods: number;
    missingReportsFranchisorGrid: kendo.ui.Grid;
    missingReportsFranchisorGridOptions: kendo.ui.GridOptions;
    missingReportsFranchiseeGrid: { [uid: string]: kendo.ui.Grid };

    static $inject = [
        '$window',
        '$location',
        '$q',
        '$scope',
        "$rootScope",
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private $scope: ng.IScope,
        private $rootScope: ng.IRootScopeService,
    ) {

    }

    $onInit() {
        this.missingReportsFranchiseeGrid = {};
        this.LoadGrid();
        this.$rootScope.$on('missingReportsDataChanged', (event, args) => {
            if (args && args.missingReportsData && args.numberOfPeriods) {

                let missingReportsResultData: RmsDashboardMissingReportsResultVm[] = args.missingReportsData;
                this.numberOfPeriods = args.numberOfPeriods;
                this.PopulateGrid(missingReportsResultData);
            }
        });

        this.$rootScope.$on('resetGrid', () => {
            this.missingReportsFranchisorGrid.dataSource.data([])
            this.missingReportsFranchisorGrid.refresh();
        });
    }

    PopulateGrid(data: RmsDashboardMissingReportsResultVm[]) {
        this.missingReportsFranchisorGrid.dataSource.data(data)
        this.missingReportsFranchisorGrid.refresh();
    }

    LoadGrid() {

        let missingReportsFranchisorDataSource = new kendo.data.DataSource({
            data: [],
            pageSize: 10,
            schema: {
                model: {
                    id: "franchisorId",
                    fields: {
                        franchisorId: {
                            type: "number"
                        },
                        franchisorName: {
                            type: "string"
                        },
                        reportCount: {
                            type: "number"
                        }
                    }
                }
            }
        });

        let missingReportsFranchisorColumns: Array<kendo.ui.GridColumn> =
            [
                {
                    field: "franchisorId",
                    title: "Franchisor Id"
                },
                {
                    field: "franchisorName",
                    title: "Franchisor Name"
                },
                {
                    field: "reportCount",
                    title: "Count"
                },
            ];

        this.missingReportsFranchisorGridOptions = {
            autoBind: false,
            dataSource: missingReportsFranchisorDataSource,
            columns: missingReportsFranchisorColumns,
            pageable: {
                numeric: false,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
        };
    }

    GetMissingReportsFranchiseeGridOptions(data: any): kendo.ui.GridOptions {
        let missingReportsFranchiseeGridDataSource = new kendo.data.DataSource({
            batch: true,
            pageSize: 10,
            schema: {
                model: {
                    id: "licenseNumber",
                    fields: {
                        franchisorId: {
                            type: "string"
                        },
                        licenseNumber: {
                            type: "string"
                        },
                        franchiseeName: {
                            type: "string"
                        },
                        openingDate: {
                            type: "string"
                        },
                        doingBusinessAs: {
                            type: "string"
                        },
                        periodEndDate: {
                            type: "string"
                        },
                        royaltyPeriodFrequency: {
                            type: "string"
                        },
                        royaltySystemName: {
                            type: "string"
                        },
                        posName: {
                            type: "string"
                        },
                        contractBeignsDate: {
                            type: "string"
                        },
                        contractEndsDate: {
                            type: "string"
                        }
                    }
                }
            },
            transport: {
                read: (e: kendo.data.DataSourceTransportReadOptions) => {
                    e.success(data.rmsDashboardMissingReportsInfo);
                },
            }
        });

        let missingReportsFranchiseeGridColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "franchisorId",
                title: "Franchisor Id",
                hidden: true
            }, {
                field: "licenseNumber",
                title: "License Number"
            }, {
                field: "franchiseeName",
                title: "Name"
            }, {
                field: "openingDate",
                title: "Opening Date",
                template: "#= kendo.toString(kendo.parseDate(openingDate, 'yyyy-MM-dd'), 'MM/dd/yyyy') #",
            }, {
                field: "doingBusinessAs",
                title: "DBA"
            }, {
                field: "periodEndDate",
                title: "Period End Date",
                template: "#= kendo.toString(kendo.parseDate(periodEndDate, 'yyyy-MM-dd'), 'MM/dd/yyyy') #",
            }, {
                field: "royaltyPeriodFrequency",
                title: "Royalty Period Frequency",
            }, {
                field: "royaltySystemName",
                title: "Royalty System"
            }, {
                field: "posName",
                title: "POS"
            }, {
                field: "contractBeignsDate",
                title: "Contract Start",
                template: "#= contractBeignsDate != null ? kendo.toString(kendo.parseDate(contractBeignsDate, 'yyyy-MM-dd'), 'MM/dd/yyyy') : '' #",
            }, {
                field: "contractEndsDate",
                title: "Contract End",
                template: "#= contractEndsDate != null ? kendo.toString(kendo.parseDate(contractEndsDate, 'yyyy-MM-dd'), 'MM/dd/yyyy') : '' #",
            }];

        return {
            columns: missingReportsFranchiseeGridColumns,
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            dataSource: missingReportsFranchiseeGridDataSource,
            resizable: true,
            scrollable: true,
            sortable: true,
            filterable: true,
        };
    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsDashboardMissingReportsGrid", {
            bindings: {

            },
            templateUrl: '/Templates/RMSDashboard/RmsDashboardMissingReports/rmsDashboardMissingReportsGrid.html',
            controller: rmsDashboardMissingReportsGridComponentController
        });
    }
}