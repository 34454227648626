/* import * as _ from "underscore" */
import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import { Loader } from '@googlemaps/js-api-loader';
import { ConceptClient, ConceptDto } from '@nbly/franchise-group-api-clients';
import { Helpers } from 'Utility/Helpers';
import { Constants } from 'Constants/Index';
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { FanClient } from '@nbly/engagement-services-clients'
import { KendoHelperService } from "Services/Utility/KendoHelperService";

export class FindANeighborComponent implements ng.IController {




  autoCompleteState: kendo.ui.AutoComplete;
  autoCompleteOptions: kendo.ui.AutoCompleteOptions;
  autoCompleteSelectedValue: any;
  selectedFranchiseOwnerSearch: any;
  searchType: string = 'Search by Address';

  isLoading: boolean = true;
  loader: Loader;
  mapOptions: google.maps.MapOptions;

  searchBox;
  map: google.maps.Map;


  //Clients
  franchiseGroupClient: AxiosInstance;
  engagementServicesClient: AxiosInstance;
  fanClient: FanClient;
  conceptClient: ConceptClient;

  //DTO'S
  conceptDTO: ConceptDto[];
  selectedConcept: ConceptDto[] = [];
  includeConceptIds = [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 13, 15, 16, 17, 18, 24, 25, 26, 27, 29, 30];

  modifiedMainDataArray: any[] = [];
  pinMarkerObject: any[] = [];


  positions: any
  items: any = [];

  grouptip: any;
  masterClusterOverLay: any;
  masterClusterOverlyFunc: any;
  groupHtml: string;
  altGroupHtml: string
  hideGroup: any;
  detailtip: any;
  detailHtml: string;
  hideDetail: any;
  filterOptions: any;
  polygonArray: any[] = [];
  polygonArrayObject: any = {};
  array: any[] = [];
  positionMapping: any[] = [];
  selectedPolygonId: any;
  brandsMultiSelect: kendo.ui.MultiSelect;
  brandsMultiSelectOptions: kendo.ui.MultiSelectOptions;
  mapSearchBounds: google.maps.LatLngBounds;
  cntrlIsPressed: boolean;
  marker: any;
  buttonMenu: any;
  isDropdownOpen: boolean;

  darkModeStatus: boolean = false;
  isAdminOrFranchiseDevelopment: boolean = false;
  isAdmin: boolean = false;
  isFranchiseeRole: boolean = false;

  static $inject = [
    "$scope",
    "$window",
    'identityManager',
    'apiConfig',
    'kendoHelper',
  ];

  constructor(
    private $scope: ng.IScope,
    private $window: ng.IWindowService,
    private identityManager: IdentityManager,
    private apiConfig: ApiConfig,
    private kendoHelper: KendoHelperService,
  ) {
    this.franchiseGroupClient = FranForceAxiosClientBuilder.BuildFranchiseGroupBaseClient();
    this.engagementServicesClient = FranForceAxiosClientBuilder.BuildEngagementServicesBaseClient();
    this.fanClient = new FanClient("", this.engagementServicesClient);
    this.conceptClient = new ConceptClient("", this.franchiseGroupClient);
    this.mapOptions = Constants.FANMapOptions;
    $(document).keydown((event) => {

      if (event.ctrlKey)
        this.cntrlIsPressed = true;
    });

    $(document).keyup(() => {
      this.cntrlIsPressed = false;
    });

    this.cntrlIsPressed = false;
  }

  async $onInit() {

    this.filterOptions = {
      layers: {
        pins: true,
        territory: false,
        owned: false,
        tafs: false,
      },
      filter: {
        resale: false
      }
    }
    this.loader = new Loader({
      apiKey: "AIzaSyAeuOJJEv_Zm-qUBNhODYmAKZKGAAicu-g",
      version: "weekly",
      libraries: ['drawing', 'geometry', 'places', 'visualization'],
    });


    this.groupHtml = '<li data-id="{id}" class="brand-{Brand} {TAFS} {for-sale:FranchiseAvailableForsale}">{Franchise}</li>';
    this.altGroupHtml = '<li data-id="{id}" class="brand-{Brand} {TAFS} {for-sale:FranchiseAvailableForsale}">{Franchise}</li>';
    this.detailHtml = '\
    <a href="javascript:void(\'Close\');" class="popup-close">X</a>\
    <div class="popup-logo brand-{Brand}"></div>\
    <h3 style="font-weight:100;margin-top:20px;">{Franchise}</h3>\
    <h4 style="margin:0px;font-size:16px;font-weight:100;">{ContactFirstName} {ContactLastName}</h4>\
    <br data-contact="true">\
    <h4 style="margin:0px;font-size:16px;font-weight:100;">{City}, {State}, {Zip}</h4>\
    <br>\
    <div class="ratings-main-container">\
    <span>NPS: {NetPromoterScorePercentage}%</span>\
    <div class="ratings-container">\
        <div class="ratings">\
            <div class="empty-stars"></div>\
            <div class="full-stars" style="width:{NetPromoterStarRatingPercent}%"></div>\
        </div>\
        <span>&nbsp;{NetPromoterStarRating}</span>\
    </div>\
    <div>{ReviewCount} Review(s)</div>\
    <div>{GoogleReviewCount} Google Review(s)</div>\
    <div>{FacebookReviewCount} Facebook Review(s)</div>\
    <div>{NpsReviewCount} NPS Review(s)</div>\
    </div>\
    <span class="{hide:WebsiteURL}">\
      <br>\
      <a href="{WebsiteURL}" target="_blank">View Website</a>\
    </span>\
    <br>\
    <span>{phone:Phone}</span>\
    <span data-resale="true" class="{hide:FranchiseAvailableForsale} resale-dollar" title="Available for Resale">Business For Sale</span>';


    this.identityManager.GetLoggedInUserInfo()
      .then((loggedInUser) => {
        this.isAdminOrFranchiseDevelopment = this.identityManager.UserHasRole(loggedInUser.data, [
          this.apiConfig.FranForceConstants.RoleConstants.AdminRole,
          this.apiConfig.FranForceConstants.RoleConstants.FranchiseDevelopment,
        ]);
        this.isAdmin = this.identityManager.UserHasRole(loggedInUser.data, [
          this.apiConfig.FranForceConstants.RoleConstants.AdminRole
        ]);
        this.isFranchiseeRole = this.identityManager.UserHasRole(loggedInUser.data, [
          this.apiConfig.FranForceConstants.RoleConstants.FranchiseeRole
        ]);
      });


    await this.loadAllConcepts().then(async () => {
      await this.loader.load().then((google) => {

        this.map = new google.maps.Map(document.getElementById("maps"), this.mapOptions);

        //Render Neighbors like you logo
        const neighborsLikeYouImageContainer = document.createElement("a");
        neighborsLikeYouImageContainer.classList.add("neighbors-like-you-container");

        neighborsLikeYouImageContainer.href = 'http://go.nbly.com/nly';
        neighborsLikeYouImageContainer.target = '_blank';

        const neighborsLikeYou = document.createElement("img");
        neighborsLikeYou.src = '/Content/css/images/FAN/Neighborly-NLY-Full-RGB.png'
        neighborsLikeYou.classList.add("neighbors-like-you");

        neighborsLikeYouImageContainer.appendChild(neighborsLikeYou);


        this.map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(neighborsLikeYouImageContainer);

        this.masterClusterOverlyFunc = function clusterOverlay(outsideScope) {
          this.that = outsideScope;
          this.div_ = null;
        }

        this.masterClusterOverlyFunc.prototype = new google.maps.OverlayView();

        this.masterClusterOverlyFunc.prototype.show = function () {
          this.setMap(this.map)
        }

        this.masterClusterOverlyFunc.prototype.hide = function () {
          this.setMap(null)
        }

        this.masterClusterOverlyFunc.prototype.remove = function () {

        }

        this.masterClusterOverlyFunc.prototype.stopPropagation_ = function (e) {
          if (navigator.userAgent.toLowerCase().indexOf('msie') != -1 && document.all) {
            window.event.cancelBubble = true;
            window.event.returnValue = false;
          } else {
            e.preventDefault(); // optionally prevent default actions
            e.stopPropagation();
          }
        }
        this.masterClusterOverlyFunc.prototype.onAdd = function () {
          if (!this._div) {
            this._div = document.createElement('div');
            this._div.style.display = 'block';
            this._div.style.position = 'static';
            this._div.style.margin = '0px';
            this._div.style.padding = '0px';
            this._div.style.overflow = 'visible';
            this._div.style.opacity = 1
          }
          this.getPanes().overlayMouseTarget.appendChild(this._div);

          // set this as locally scoped var so event does not get confused
          /* var me = this; */
          google.maps.event.addDomListener(this._div, 'click', (event) => {

            let dataId = event.target.getAttribute('data-id');
            this.that.renderMainPopup(dataId);
            event.stopPropagation();

          });

        }


      }).then(() => {

        this.masterClusterOverLay = new this.masterClusterOverlyFunc(this);
        this.masterClusterOverLay.draw = function () {
          if (this.that.filterOptions.layers.pins) {
            this.that.clusterDraw();
          }
        }
        this.masterClusterOverLay.setMap(this.map);
        $('#maps').on('mouseover mouseout', function (e: any) {
          let id;

          if (e.target && (id = e.target.getAttribute('data-id'))) {

            switch (e.type) {
              case 'mouseover':

                $(e.target).addClass('active');
                break;
              case 'mouseout':

                $(e.target).removeClass('active');
                break;
            }
          }
        });

        let input = $('#FANMap')[0] as HTMLInputElement;
        let options = {
          types: ['geocode'],
          componentRestrictions: { country: ["us", "ca"] }
        };
        this.searchBox = new google.maps.places.Autocomplete(input, options);

        this.searchBox.addListener("place_changed", () => {
          const places = this.searchBox.getPlace();
          if (places.length == 0) {
            return;
          }
          //If marker already exsists, remove it
          if (this.marker) {
            this.marker.setMap(null);
          }


          if (!places.geometry || !places.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
          }

          const icon = {
            url: "Content/css/images/FAN/Location-Found.png", // url
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(0, 0)
          };

          this.marker = new google.maps.Marker({
            map: this.map,
            icon,
            title: '',
            position: places.geometry.location,
          });


          this.marker.addListener("click", () => {

            let point = new google.maps.LatLng(parseFloat(places.geometry.location.lat()), parseFloat(places.geometry.location.lng()));
            let locationObject: any = {};
            locationObject.group = [];
            let poloygonArrayLen = this.polygonArray.length;
            for (let i = 0; i < poloygonArrayLen; i++) {
              try {

                if (!this.selectedConcept.find((obj) => obj.conceptId == this.polygonArray[i].Brand)) {
                  continue;
                }
                let response = google.maps.geometry.poly.containsLocation(point, this.polygonArray[i].franchisePolygon);
                if (response) {
                  locationObject.group.push(this.pinMarkerObject[this.polygonArray[i].id])
                }

              } catch (err) {
                console.log('Error occured', err)
              }
            }

            let latlng = new google.maps.LatLng(parseFloat(places.geometry.location.lat()), parseFloat(places.geometry.location.lng()))
            let pos = this.masterClusterOverLay.getProjection().fromLatLngToDivPixel(latlng);
            locationObject.x = pos.x;
            locationObject.y = pos.y;
            this.showGroup(locationObject, true);


          });

          this.mapSearchBounds = new google.maps.LatLngBounds();
          if (places.geometry.viewport) {
            this.mapSearchBounds.union(places.geometry.viewport);
          } else {
            this.mapSearchBounds.extend(places.geometry.location);
          }
          this.map.fitBounds(this.mapSearchBounds);
        });

      }).catch(e => {
        console.log('Error occured loading Google Maps', e);
      });
    })

  }


  async loadAllConcepts() {
    this.conceptClient.get_protected_concepts().then(async (res: any) => {
      this.conceptDTO = res.result;
      this.conceptDTO = this.conceptDTO.filter((concepts) => this.includeConceptIds.includes(concepts.conceptId));
      this.selectedConcept = this.conceptDTO.filter((concepts) => this.includeConceptIds.includes(concepts.conceptId));
      this.$scope.$digest();
      await this.fetchBrandJSONS();
    })

  }


  async refreshS3Json() {
    for (const conceptId of this.includeConceptIds) {
      await this.fanClient.concept(conceptId);
    }
  }

  getActiveConcept(concept: { conceptInfo: ConceptDto }) {
    if (this.selectedConcept.find((obj) => obj.conceptId == concept.conceptInfo.conceptId)) {
      return true;
    }
    return false;
  }

  selectConcept() {
    if (this.filterOptions.layers.territory) {
      this.territoryMapRender();
    }
    //Do filtering on the data array, based on selected brands
    this.clusterDraw();
  }

  selectConceptOld(concept: { conceptInfo: ConceptDto }) {

    if (this.cntrlIsPressed) {

      if (this.selectedConcept.find((obj) => obj.conceptId == concept.conceptInfo.conceptId)) {
        //Already Selected
        this.selectedConcept = this.selectedConcept.filter((obj) => obj.conceptId !== concept.conceptInfo.conceptId);

      } else {
        this.selectedConcept.push(concept.conceptInfo);
        if (this.filterOptions.layers.territory) {
          this.territoryMapRender();
        }
      }
    } else {
      if (this.selectedConcept.find((obj) => obj.conceptId == concept.conceptInfo.conceptId)) {
        //Already clicked
      }
      this.selectedConcept = [];
      this.selectedConcept.push(concept.conceptInfo);
      if (this.filterOptions.layers.territory || this.filterOptions.layers.tafs || this.filterOptions.layers.owned) {
        this.territoryMapRender();
      }
    }

    if (this.searchType == 'Search by Owner') {
      this.loadDataForOwnerSearch();

    }
    this.clusterDraw();

  }


  loadDataForOwnerSearch() {
    let dataSource = [];
    this.selectedConcept.map((concept) => {
      this.modifiedMainDataArray.map((obj) => {
        if (obj.Brand == concept.conceptId) {
          if (obj.FranchiseTerritoryTypeID == 1 || obj.onlyTafs)
            dataSource.push(obj);
        }

      });


    })
    let datasource = new kendo.data.DataSource({
      data: dataSource,

    });

    this.autoCompleteOptions = {
      dataSource: datasource,
      dataTextField: 'searchField',
      select: (e) => {
        this.selectedFranchiseOwnerSearch = e.dataItem
        this.map.setCenter(new google.maps.LatLng(e.dataItem.lat, e.dataItem.lng));
        this.map.setZoom(12)
      }
    }

    this.autoCompleteState.setOptions(this.autoCompleteOptions);

    this.autoCompleteState.setDataSource(datasource);
    this.autoCompleteState.options.filter = 'contains';

  }

  toggleSelectAll() {

    this.selectedConcept = [];
    this.conceptDTO.map((obj) => {
      this.selectedConcept.push(obj);
    });
    if (this.filterOptions.layers.territory || this.filterOptions.layers.tafs || this.filterOptions.layers.owned) {
      this.territoryMapRender();

    }

    if (this.searchType == 'Search by Owner') {
      this.loadDataForOwnerSearch();
    }
    this.clusterDraw();

  }



  async fetchBrandJSONS() {

    await Promise.all(
      this.conceptDTO.map(async (obj) => {
        let pathToFile = obj.conceptGeoJson;
        let headers = new Headers();
        headers.append('pragma', 'cache');
        headers.append('cache-control', 'public');


        let init = {
          method: 'GET',
          headers: headers,
        };

        const urlParams = new URLSearchParams(pathToFile);
        const fileExpires = urlParams.get('Expires');

        let fanExpiryTimeStamp;
        fanExpiryTimeStamp = JSON.parse(localStorage.getItem(obj.conceptCode));


        if (fanExpiryTimeStamp && fanExpiryTimeStamp.Expires > Math.floor(Date.now() / 1000)) {

          if (localStorage.getItem(obj.conceptCode)) {
            pathToFile = JSON.parse(localStorage.getItem(obj.conceptCode)).path;
          } else {
            let object = { path: pathToFile, Expires: fileExpires }
            localStorage.setItem(obj.conceptCode, JSON.stringify(object));
          }

        } else {

          let object = { path: pathToFile, Expires: fileExpires }
          localStorage.setItem(obj.conceptCode, JSON.stringify(object));
        }



        await fetch(pathToFile, init)
          .then(response => response.json())
          .then((data) => {
            if (data) {
              this.ModifyData(data, obj);
            }
          }).catch((err) => {
            console.log('Error occured', err)
          })
      })
    ).then(() => {
      /*   this.SetDropDownOptions(); */
      this.clusterDraw();
      this.isLoading = false;
      this.$scope.$digest();
    })

  }

  territoryMapRender() {
    this.polygonArray.map((obj) => {
      if (this.selectedConcept.find((concept) => concept.conceptId == obj.Brand)) {
        if (this.filterOptions.filter.resale) {
          //remove this.showResale
          if (obj.FranchiseAvailableForsale && this.isAdminOrFranchiseDevelopment) {
            this.polygonArrayObject[obj.id].franchisePolygon.setMap(this.map);
          } else if (obj.FranchiseAvailableForsale && obj.ShowResale && this.isFranchiseeRole) {
            this.polygonArrayObject[obj.id].franchisePolygon.setMap(this.map);
          }
        } else {

          if (obj.FranchiseTerritoryTypeID == 1) {
            //Check if toggle is enabled
            if (this.filterOptions.layers.owned) {
              this.polygonArrayObject[obj.id].franchisePolygon.setMap(this.map);
            } else {
              this.polygonArrayObject[obj.id].franchisePolygon.setMap(null);
            }
          }

          if (obj.FranchiseTerritoryTypeID == 2) {
            //Check if toggle is enabled
            if (this.filterOptions.layers.tafs) {
              this.polygonArrayObject[obj.id].franchisePolygon.setMap(this.map);
            } else {
              this.polygonArrayObject[obj.id].franchisePolygon.setMap(null);
            }
          }
        }


      } else {
        try {
          this.polygonArrayObject[obj.id].franchisePolygon.setMap(null);
        } catch (err) {
          console.log("Error occured setting poloygon", err)
        }
      }
    });
  }
  async SetDropDownOptions() {
    this.brandsMultiSelectOptions = {
      dataValueField: nameof.full<ConceptDto>(o => o.conceptId),
      dataTextField: nameof.full<ConceptDto>(o => o.displayName),
      dataSource: this.conceptDTO,
      autoBind: true,
      dataBound: (e) => {
        $('#Header').prop('checked', true)

        $('#Header').click(function (e) {

          if ($(this).is(':checked')) {
            $('#items_listbox').find("li").each(function () {
              if ($(this).hasClass("k-state-selected")) {

              } else {
                $(this).prop("checked", true);

                $(this).trigger("click");
                $(this).find("input").prop("checked", true);

              }

            });

          }
          else {
            $('#items_listbox').find("li").each(function () {
              if ($(this).hasClass("k-state-selected")) {
                $(this).prop("checked", false);
                $(this).trigger("click");
                $(this).find("input").prop("checked", false);
              }
            });
          }
        });

        let items = e.sender.ul.find("li");
        setTimeout(() => {
          this.checkInputs(items);
        });
      },

      itemTemplate: "<input type='checkbox'/> #:data.displayName#",
      headerTemplate: "<div><input type='checkbox' id='Header'><label> Select All</label></div>",
      autoClose: false,
      change: (e) => {
        let items = e.sender.ul.find("li");
        let that = this;
        let unselectedElementExsists: boolean = false;
        items.each(function () {
          let element = $(this);
          let input = element.children("input");
          input.prop("checked", element.hasClass("k-state-selected"));
          if (!element.hasClass("k-state-selected")) {
            unselectedElementExsists = true;
          }

        });
        that.selectConcept();
        if (unselectedElementExsists) {
          $('#Header').prop('checked', false)
        } else {
          $('#Header').prop('checked', true)
        }
      },

    }

  }

  checkInputs(elements) {
    elements.each(function () {
      let element = $(this);
      let input = element.children("input");
      input.prop("checked", element.hasClass("k-state-selected"));
    });
  };

  stateChanged(e) {
    this.clusterDraw();
    if (e.e) {
      if (e.type == 'territory') {
        this.filterOptions.layers.territory = true;
        this.filterOptions.layers.tafs = true;
        this.filterOptions.layers.owned = true;
        this.territoryMapRender();
      } else if (e.type == 'tafs' || e.type == 'owned') {
        this.territoryMapRender();
      }



      if (e.type == 'resale') {
        if (this.filterOptions.layers.territory) {
          this.polygonArray.map((obj) => {
            obj.franchisePolygon.setMap(null);
          });
          this.territoryMapRender();
        }

      }
    } else if (e.e == false) {

      if (e.type == 'resale') {
        if (this.filterOptions.layers.territory) {
          this.polygonArray.map((obj) => {
            this.polygonArrayObject[obj.id].franchisePolygon.setMap(this.map);
          });
          this.territoryMapRender();
        }
      }

      if (e.type == 'tafs') {
        this.polygonArray.map((obj) => {
          if (obj.FranchiseTerritoryTypeID == 2) {
            this.polygonArrayObject[obj.id].franchisePolygon.setMap(null);
          }
        });
      }

      if (e.type == 'owned') {
        this.polygonArray.map((obj) => {
          if (obj.FranchiseTerritoryTypeID == 1) {
            this.polygonArrayObject[obj.id].franchisePolygon.setMap(null);
          }

        });
      }
      if (e.type == 'territory') {
        this.filterOptions.layers.owned = false;
        this.filterOptions.layers.tafs = false;
        this.polygonArray.map((obj) => {

          this.polygonArrayObject[obj.id].franchisePolygon.setMap(null);


        });
      }
    }

  }

  mouseOut(obj, franchisePolygon) {
    if (obj.FranchiseTerritoryTypeID == 2) {
      //We need to un highlight highlight corresponding owned polygon
      franchisePolygon.setOptions({
        strokeOpacity: 0.5,
        fillOpacity: 0.4
      });
      if (this.polygonArrayObject[obj.Id]) {
        this.polygonArrayObject[obj.Id].franchisePolygon.setOptions({
          strokeOpacity: 0.5,
          fillOpacity: 0.4
        });
      }

    } else if (obj.FranchiseTerritoryTypeID == 1) {
      franchisePolygon.setOptions({
        strokeOpacity: 0.5,
        fillOpacity: 0.4
      });
      if (this.polygonArrayObject[obj.Id + '_TAFS']) {
        this.polygonArrayObject[obj.Id + '_TAFS'].franchisePolygon.setOptions({
          strokeOpacity: 0.5,
          fillOpacity: 0.4
        });
      }
    }

  }

  mouseOver(obj, franchisePolygon) {
    franchisePolygon.setOptions({
      strokeOpacity: 1,
      fillOpacity: 0.6
    });
    if (obj.FranchiseTerritoryTypeID == 2) {
      //We need to highlight corresponding owned polygon

      if (this.polygonArrayObject[obj.Id]) {
        this.polygonArrayObject[obj.Id].franchisePolygon.setOptions({
          strokeOpacity: 1,
          fillOpacity: 0.6
        });
      }
    } else if (obj.FranchiseTerritoryTypeID == 1) {
      if (this.polygonArrayObject[obj.Id + '_TAFS']) {
        this.polygonArrayObject[obj.Id + '_TAFS'].franchisePolygon.setOptions({
          strokeOpacity: 1,
          fillOpacity: 0.6
        });
      }
    }
  }


  ModifyData(data: any, conceptObj) {

    const mainObj = [...data];
    for (const obj of data) {
      //remove this.showresaile
      /*    if (!this.showResale && obj.FranchiseAvailableForsale)
         {
           continue;
         } */
      let pathsArray;
      let pinPosition = { lng: 0, lat: 0 };
      let onlyTafs = false;
      pathsArray = Helpers.parsePolyStrings(obj.TerritoryBoundry.WellKnownText);

      if (obj.FranchiseTerritoryTypeID === 2) {
        let a = mainObj.find((mainObj => mainObj.FranchiseTerritoryTypeID == 1 && mainObj.Id == obj.Id));
        if (!a) {
          //its unique only TAFS
          onlyTafs = true;
        }
      }

      pinPosition = this.GetLatLngFromObject(obj);
      obj.TerritoryBoundry = {};


      let franchisePolygon: google.maps.Polygon;

      if (obj.FranchiseTerritoryTypeID == 1) {
        franchisePolygon = new google.maps.Polygon({
          paths: pathsArray,
          strokeColor: '#2a6031',
          fillColor: '#0eaf5b',
          strokeOpacity: 0.5,
          fillOpacity: 0.4,
          editable: false,
        });


      } else if (obj.FranchiseTerritoryTypeID == 2) {

        franchisePolygon = new google.maps.Polygon({
          paths: pathsArray,
          strokeColor: '#696967',
          fillColor: '#696967',
          strokeOpacity: 0.5,
          fillOpacity: 0.4,
          editable: false,
        });
      }

      google.maps.event.addListener(franchisePolygon, 'mouseover', () => {
        this.mouseOver(obj, franchisePolygon)

      });

      google.maps.event.addListener(franchisePolygon, 'mouseout', () => {
        this.mouseOut(obj, franchisePolygon);
      });

      if (conceptObj.conceptId == '15') {
        if (obj.Brand == '4') {
          obj.Brand = 15;
        }
      }

      if (conceptObj.conceptId == '13') {
        if (obj.Brand == '13') {
          obj.Brand = 2;
        }
      }


      let dynamicKey = obj.Id;

      if (obj.FranchiseTerritoryTypeID == 2) {
        dynamicKey = obj.Id + '_TAFS';
      }

      this.polygonArray.push({ onlyTafs: onlyTafs, franchisePolygon, id: dynamicKey, Brand: obj.Brand, FranchiseAvailableForsale: obj.FranchiseAvailableForsale, FranchiseTerritoryTypeID: obj.FranchiseTerritoryTypeID, ShowResale: obj?.ShowResale });

      this.polygonArrayObject[dynamicKey] = ({ onlyTafs: onlyTafs, franchisePolygon, id: dynamicKey, Brand: obj.Brand, FranchiseAvailableForsale: obj.FranchiseAvailableForsale, FranchiseTerritoryTypeID: obj.FranchiseTerritoryTypeID, ShowResale: obj?.ShowResale });

      let searchField = obj.ContactFirstName + ' ' + obj.ContactLastName + ', ' + obj.Franchise;
      let minifiedObject = {
        onlyTafs: onlyTafs,
        id: dynamicKey,
        Brand: obj.Brand,
        Franchise: obj.Franchise,
        ContactFirstName: obj.ContactFirstName,
        ContactLastName: obj.ContactLastName,
        City: obj.City,
        State: obj.State,
        Zip: obj.Zip,
        WebsiteURL: obj.WebsiteURL,
        Phone: obj.Phone,
        Level: obj.Level,
        FranchiseAvailableForsale: obj.FranchiseAvailableForsale,
        Available: obj.Available,
        Address: obj.Address,
        Address2: obj.Address2,
        Email: obj.Email,
        NetPromoterScore: obj.NetPromoterScore,
        NetPromoterStarRating: obj.NetPromoterStarRating,
        NetPromoterStarRatingPercent: obj.NetPromoterStarRatingPercent,
        NetPromoterScorePercentage: obj.NetPromoterScorePercentage,
        ReviewCount: obj.ReviewCount,
        GoogleReviewCount: obj?.GoogleReviewCount | 0,
        FacebookReviewCount: obj?.FacebookReviewCount | 0,
        NpsReviewCount: obj?.NpsReviewCount | 0,
        Fake: obj.Fake,
        x: pinPosition.lat,
        y: pinPosition.lng,
        lat: pinPosition.lat,
        lng: pinPosition.lng,
        FranchiseTerritoryTypeID: obj.FranchiseTerritoryTypeID,
        searchField: searchField,
        ShowResale: obj?.ShowResale,

      }
      this.pinMarkerObject[dynamicKey] = minifiedObject;
      this.modifiedMainDataArray.push(minifiedObject);
    }
  }


  GetLatLngFromObject(object: any) {
    let pinPosition = { lat: 0, lng: 0 };
    if (object?.PushPinLatitude && object?.PushPinLongitude) {
      pinPosition = { lat: object.PushPinLatitude, lng: object.PushPinLongitude };
    } else if (object?.TerritoryBoundry?.Centroid) {
      //If the object has Centroid Object
      pinPosition = Helpers.parsePolyStrings(object?.TerritoryBoundry.Centroid)[0][0];
    }
    return pinPosition;
  }




  renderMainPopup(dataId) {
    var pos = dataId && this.positions[dataId];
    if (!pos) {
      // Didn't click on a cluster icon.
      return;
    } else if (pos.group) {
      // Display the group tooltip popup.
      this.showGroup(pos, null);
    } else {
      // Display the location detail popup.
      this.showDetail(dataId);
      // Activate the map selection.
      this.highLightTerritoryBoundry(dataId);
      if (this.filterOptions.layers.tafs) {

        if (this.polygonArrayObject[dataId + '_TAFS']) {
          this.highLightTerritoryBoundry(dataId + '_TAFS');
        }

      }



    }

  }

  highLightTerritoryBoundry(id) {

    //Need to modify this as well
    if (this.filterOptions.layers.territory || this.filterOptions.layers.tafs || this.filterOptions.layers.owned) {

      if (this.polygonArrayObject[id].FranchiseTerritoryTypeID == 2) {

        this.polygonArrayObject[id].franchisePolygon.setOptions({
          strokeColor: '#000000',
          fillColor: '#696967',
          strokeOpacity: 1,
          fillOpacity: 0.7
        });
        this.polygonArrayObject[id].franchisePolygon.setMap(this.map);
        /*    this.selectedPolygonId = id+'_TAFS'; */
      } else {

        this.polygonArrayObject[id].franchisePolygon.setOptions({
          strokeColor: '#31592e',
          fillColor: '#0eaf5b',
          strokeOpacity: 1,
          fillOpacity: 0.7
        });
        this.polygonArrayObject[id].franchisePolygon.setMap(this.map);
        /*   this.selectedPolygonId = id; */

      }



    }
  }

  searchButtonClicked() {

    var input = document.getElementById('FANMap');
    function noop() { }

    google.maps.event.trigger(input, 'focus', {});
    google.maps.event.trigger(input, 'keydown', {
      keyCode: 40, // arrow down
      stopPropagation: noop, // because these get called
      preventDefault: noop,
    });
    google.maps.event.trigger(input, 'keydown', { keyCode: 13 });  // enter
    google.maps.event.trigger(this, 'focus', {});

    if (this.searchType == 'Search by Owner') {

      //OWner Search
      if (this.selectedFranchiseOwnerSearch?.lat && this.selectedFranchiseOwnerSearch?.lng) {
        this.map.setCenter(new google.maps.LatLng(this.selectedFranchiseOwnerSearch.lat, this.selectedFranchiseOwnerSearch.lng));
        this.map.setZoom(14)
      }

    }
  }

  toggleDropdown() {
    var dropdownContent = angular.element(document.getElementById('dropdownOptions'));
    dropdownContent.css('display', (dropdownContent.css('display') === 'block' || dropdownContent.css('display') === '') ? 'none' : 'block');
  }

  updateSearchType(str) {
    this.selectedFranchiseOwnerSearch = null
    this.searchType = str;
    var dropdownContent = angular.element(document.getElementById('dropdownOptions'));
    dropdownContent.css('display', (dropdownContent.css('display') === 'block' || dropdownContent.css('display') === '') ? 'none' : 'block');

    if (str == 'Search by Owner') {
      this.loadDataForOwnerSearch();
    }




  }

  async showDetail(id) {
    var reviewsList;
    this.isLoading = true;
    let searchId = id;
    if (id?.includes('_TAFS')) {
      searchId = id.split('_')[0];
    }
    await this.fanClient.reviews(searchId).then((res: any) => {
      reviewsList = res.result;
    }).catch((err) => {
      console.log('Error occured fetching reviews', err)
    }).finally(() => {
      this.isLoading = false;
    });
    // Find the matching territory.
    var t = this.modifiedMainDataArray.find((obj => obj.id == id));
    if (!t) {
      return;
    }

    // Make sure we have a popup element.
    if (!this.detailtip) {
      this.detailtip = document.createElement('div');
      this.detailtip.className = 'map-popup';
      this.detailtip.style.display = 'none';
      document.getElementById('maps').append(this.detailtip);

    }

    // Get the detail html.
    let showResale = this.isAdminOrFranchiseDevelopment || this.isFranchiseeRole;
    let html = this.detailHtml.replace(/\{(\w+:)?(\w+)\}/g, function (m, m1, m2) {
      var v = t[m2];
      if (m1 === 'hide:') {
        if (m2 === 'FranchiseAvailableForsale') {
          /*  return v */
          return v && showResale ? '' : 'hide';
        } else {
          return v ? '' : 'hide';
        }
      } else if (m1 === 'phone:') {
        return v ? v.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '';
      } else if (m2 === 'NetPromoterScorePercentage' || m2 === 'NetPromoterStarRating'
        || m2 === 'NetPromoterStarRatingPercent' || m2 === 'NetPromoterScore' || m2 === 'ReviewCount') {
        if (v) {
          return v
        } else {
          return '0'
        }
      } else {
        return v;
      }
    });

    // Display it.


    if (reviewsList) {
      var tempElement = document.createElement('div');
      tempElement.innerHTML = html;

      var reveiwsHtml = '';
      reviewsList.forEach(element => {
        var testObj = '\
        <div class="ratings-main-container">\
        <div><b>{doingBusinessAs} </b></div>\
            <span>NPS: {netPromoterScorePercentage}%</span>\
            <div class="ratings-container">\
                <div class="ratings">\
                    <div class="empty-stars"></div>\
                    <div class="full-stars" style="width:{netPromoterStarRatingPercent}%"></div>\
                </div>\
                <span>&nbsp;{netPromoterStarRating}</span>\
            </div>\
            <div>{reviewCount} Review(s)</div>\
            <div>{googleReviewCount} Google Review(s)</div>\
            <div>{facebookReviewCount} Facebook Review(s)</div>\
            <div>{npsReviewCount} NPS Review(s)</div>\
            <br>\
            </div>';



        let rHTML = testObj.replace(/\{(\w+:)?(\w+)\}/g, function (m, m1, m2) {
          var v = element[m2];
          if (m2 === 'googleReviewCount' || m2 === 'facebookReviewCount' || m2 === 'netPromoterScorePercentage' || m2 === 'netPromoterStarRating'
            || m2 === 'netPromoterStarRatingPercent' || m2 === 'netPromoterScore' || m2 === 'reviewCount') {
            if (v) {
              return v
            } else {
              return '0'
            }
          } else {
            return v;
          }
        });

        reveiwsHtml += rHTML;

      });

      // Find the div with class "ratings-container" and empty its contents
      var ratingsContainer = tempElement.querySelector('.ratings-main-container');
      if (ratingsContainer) {
        ratingsContainer.innerHTML = ''; // Set the innerHTML to an empty string
      }

      ratingsContainer.innerHTML = reveiwsHtml;

      // Get the modified HTML string
      var modifiedHtmlString = tempElement.innerHTML;

      this.detailtip.innerHTML = modifiedHtmlString;
    } else {
      this.detailtip.innerHTML = html;
    }

    this.detailtip.style.display = 'block';
    this.detailtip.setAttribute('data-id', id);

    if (!t.contactFirstName || !t.contactFirstName.length) {
      $(this.detailtip).find("[data-contact]").remove();
    }

    if (t.FranchiseAvailableForsale) {
      $(this.detailtip).addClass("for-sale");
    } else {
      $(this.detailtip).removeClass("for-sale");
    }

    if (t.FranchiseTerritoryTypeID == 2) {
      /*    $(this.detailtip).addClass("TAFS"); */
    } else {
      $(this.detailtip).removeClass("TAFS");
    }

    // Build a proxy to maintain context.
    if (!this.hideDetail) {
      this.hideDetail = $.proxy(this.hideDetailFunc, this);
    }

    // Bind the hide detail on capture, if possible.
    if (this.$window.addEventListener) {
      this.$window.addEventListener('mousedown', this.hideDetail, true);
      this.$window.addEventListener('wheel', this.hideDetail, true);
      this.$window.addEventListener('mousewheel', this.hideDetail, true);
      this.$window.addEventListener('DOMMouseScroll', this.hideDetail, true);
    } else if (this.$window.attachEvent) {
      this.$window.attachEvent('mousedown', this.hideDetail);
    }
    // Find the matching cluster and mark it as selected.
    $(this.masterClusterOverLay._div).children("[data-id='" + id + "']").addClass('selected');
  }


  hideDetailFunc(e) {
    var id,
      p = e && e.target,
      count = 0,
      reg = /\bmap\-popup\b/;
    /* data = e && Get.LinkData(e); */
    var data: any;


    while (p && count++ < 5) {
      // If we found a matching class name, we're STILL on the tooltip.
      if (p.className == 'popup-close') {
        this.detailtip.style.display = 'none';
        return
      }
      if (p.className && reg.test(p.className)) {
        e.stopPropagation();
        // Exit.
        return;
      }
      p = p.parentNode;
    }

    // No detail tip, not sure why this was called.
    if (!this.detailtip) {
      return;
    }

    // Otherwise, hide the tooltip.
    this.detailtip.style.display = 'none';

    // Fetch the id that was used to display the details.
    id = this.detailtip.getAttribute('data-id');

    // And unbind the event.
    if (this.$window.removeEventListener) {
      this.$window.removeEventListener('mousedown', this.hideDetail, true);
    } else if (this.$window.detachEvent) {
      this.$window.detachEvent('mousedown', this.hideDetail);
    }
    // Find the matching cluster and deselect it.
    $(this.masterClusterOverLay._div).children("[data-id='" + id + "']").removeClass('selected');
  }

  showGroup(pos, isPin) {
    var sb, i, len, item, t, y, transform, m,
      /*       id = pos.id, */
      that = this;

    // Make sure we have a tooltip element.
    if (!this.grouptip) {
      this.grouptip = document.createElement('div');
      this.grouptip.className = 'map-tooltip';
      this.grouptip.style.display = 'none';
      // Add it to the cluster overlay.
      this.masterClusterOverLay._div.parentNode.appendChild(this.grouptip);

      this.grouptip.onclick = (e) => {
        var id = e.target.getAttribute('data-id');
        id && this.showDetail(id);
        if (this.filterOptions.layers.territory) {
          this.polygonArray.map((obj) => {
            obj.franchisePolygon.setMap(null);
          });
          this.highLightTerritoryBoundry(id);
        }

        e.stopPropagation();
      };
    }

    // Sort the items in the group from left to right (by default they're grouped by brands).
    pos.group.sort(function (i1, i2) {
      return i1.x - i2.x;
    });

    // Build the items for the tooltip.
    sb = [];
    i = 0;
    len = pos.group.length;
    while (i < len) {
      item = pos.group[i++];

      t = this.modifiedMainDataArray.find((obj => obj.id == item.id));
      /*    t = CMS.Dwyer.Territories[item.id];  */
      if (t) {
        // If we have a pin group and a valid website then include an anchor to the website.
        // Otherwise we just show the usual text.
        if (isPin && t.website && t.website.length) {
          sb.push(this.altGroupHtml.replace(/\{([\w-]+:)?(\w+)\}/g, function (m, m1, m2) {
            var v = t[m2];

            if (that.isAdminOrFranchiseDevelopment) {
              if (m1 === 'for-sale:') {

                return v && 'for-sale';
              } else {
                return v;
              }
            } else if (that.isFranchiseeRole) {
              if (t.showResale && t.FranchiseAvailableForsale) {
                if (m1 === 'for-sale:') {

                  return v && 'for-sale';
                } else {
                  return v;
                }
              }
            }

          }));
        } else {
          sb.push(this.groupHtml.replace(/\{([\w-]+:)?(\w+)\}/g, function (m, m1, m2) {
            var v = t[m2];
  
            if (that.isAdminOrFranchiseDevelopment) {
              if (m1 === 'for-sale:') {
                /*    return v; */
                return v && 'for-sale';
                /*    return v && permissions.canViewResale ? 'for-sale' : ''; */
              } else {
                return v;
              }
            }else if(that.isFranchiseeRole){
              if(t.showResale && t.FranchiseAvailableForsale){
                if (m1 === 'for-sale:') {
                  return v && 'for-sale';
                } else {
                  return v;
                }
              }else{
                return v;
              }
            }

          }));
        }
      }
    }

    if (pos.group && !len) {
      sb.push('<li>No services available for this location.</li>');
    }

    // Add them and display the tooltip.
    if (sb.length) {
      if (isPin) {
        if (len === 1)
          sb.unshift('<h2>Your&nbsp;<strong>' + len + '</strong> <span>Service Provider</span></h2><ul class="ui-scroll">');
        else
          sb.unshift('<h2>Your&nbsp;<strong>' + len + '</strong> <span>Service Providers</span></h2><ul class="ui-scroll">');
      } else {
        sb.unshift('<h2><strong>' + len + '</strong> <span>Locations</span></h2><ul class="ui-scroll">');
      }

      sb.push('</ul>');
      this.grouptip.innerHTML = sb.join("");
      this.grouptip.style.left = Math.round(pos.x) + 'px';
      this.grouptip.style.top = Math.round(pos.y) + 'px';
      this.grouptip.style.display = 'block';

      // Calculate how close we are to the top of the map.
      y = pos.y;
      transform = $(this.masterClusterOverLay._div).parent().parent().css('transform');
      m = /matrix\(.+?,\s*(\-?\d+)\)/i.exec(transform);

      if (m) {
        // Adjust the y position if the map was dragged off center.
        /* y += Make.Int(m[1]); */
      }
      // Reverse the popup if it's too close to the top.
      if (y < 330) {
        this.grouptip.className = 'map-tooltip reverseY';
      } else {
        this.grouptip.className = 'map-tooltip';
      }

      // Build a proxy to maintain context.
      if (!this.hideGroup) {
        this.hideGroup = $.proxy(this.hideGroupFunc, this);
        /*       this.hideGroup = $.proxy(this.hideGroup, this); */
      }

      // Bind the hide detail on capture, if possible.
      if (this.$window.addEventListener) {
        window.addEventListener('mousedown', this.hideGroup, true);
        window.addEventListener('wheel', this.hideGroup, true);
        window.addEventListener('mousewheel', this.hideGroup, true);
        window.addEventListener('DOMMouseScroll', this.hideGroup, true);
      } else if (this.$window.attachEvent) {
        this.$window.attachEvent('mousedown', this.hideGroup);
      }


    }
  }

  // Hide the group popup (unless we clicked right on it).
  hideGroupFunc(e) {
    var p = e.target,
      count = 0,
      reg = /\bmap\-tooltip\b/;

    // Take the target of the mousedown, and walk up the tree (max 5 times).
    while (p && count++ < 5) {
      // If we found a matching class name, we're STILL on the tooltip.
      if (p.className && reg.test(p.className)) {
        e.stopPropagation();
        // Exit.
        return;
      }
      p = p.parentNode;
    }

    // Otherwise, hide the tooltip.
    this.grouptip.style.display = 'none';

    // And unbind the event.
    if (this.$window.removeEventListener) {
      this.$window.removeEventListener('mousedown', this.hideGroup, true);
    } else if (this.$window.detachEvent) {
      this.$window.detachEvent('mousedown', this.hideGroup);
    }
  }

  clusterDraw() {

    var p: any, pos: any, len: any, i: any, item: any;

    this.array = [];

    this.positions = {};

    this.getActiveItems();

    this.groupItems(this.array);


    // Iterate through the currently items already on the map.
    for (p in this.items) {
      if (this.items.hasOwnProperty(p)) {
        // Check for that item in the NEW collection.
        pos = this.positions[p];

        // If it doesn't exist in the new collection, OR if it is marked as grouped.
        if (!pos || pos.grouped) {
          // Hide it.
          this.items[p].style.display = 'none';

        }
      }
    }

    // Iterate through the array of items to show.
    i = 0;
    len = this.array.length;
    var docFragment = document.createDocumentFragment();
    while (i < len) {
      // Get the map position data.
      pos = this.array[i++];
      if (pos?.grouped) {
        continue;
      }
      // Check to see if that has already been added to the map.
      item = this.items[pos.id];
      if (!item) {
        // If not, create it.     
        item = document.createElement('div');
        item.setAttribute('data-id', pos.id);
        docFragment.appendChild(item);
        this.items[pos.id] = item;
      } else {
        // Otherwise, display it.
        item.style.display = '';

      }

      // Set the position.
      item.style.left = Math.round(pos.x) + 'px';
      item.style.top = Math.round(pos.y) + 'px';

      // Set the class.
      if (pos.group) {
        // Set the group class and number.
        item.className = 'map-icon group brand-' + pos.b;
        item.innerHTML = pos.group.length;
        item.group = 1;
      } else {
        // If this item WAS a group, remove the contents.
        if (item.group) {
          item.group = 0;
          item.innerHTML = '';
        }
        // Set the brand class.
        if (pos.a) {
          item.className = 'map-icon unbrand-' + pos.b;
        } else {
          item.className = 'map-icon brand-' + pos.b;
        }
      }

      /*   if(pos.FranchiseTerritoryTypeID == 2){
           item.className += ' TAFS'; 
        }
   */
      if (pos.FranchiseAvailableForsale && this.isAdminOrFranchiseDevelopment) {
        item.className += ' for-sale';
      } else if (pos.FranchiseAvailableForsale && pos.ShowResale && !this.isAdminOrFranchiseDevelopment && this.isFranchiseeRole) {
        item.className += ' for-sale';
      }

    }
    if (this.masterClusterOverLay && this.masterClusterOverLay._div) {
      this.masterClusterOverLay._div.appendChild(docFragment);
    }
    // Save the collection of current positions visible on the map.
    /*  this.positions = positions; */
  }

  exportFunction() {

    let counter = 0;

    let currentBounds = this.map.getBounds();

    let objectsToExport = [];
    if (this.array && this.array.length) {
      for (let i = 0; i < this.array.length; i++) {
        if (counter < 50) {
          let dataObject = this.positionMapping[this.array[i].id];
          if (dataObject.id) {

            var point = new google.maps.LatLng(parseFloat(dataObject.lat), parseFloat(dataObject.lng));
            if (currentBounds.contains(point)) {
              // your location is inside your map object's viewport
              objectsToExport.push(dataObject);
              counter = counter + 1;

            }
          }
        } else {
          break;
        }
      }

    }

    let csvString: any = [
      [
        "Brand",
        "FranchiseName",
        "ContactFirstName",
        "ContactLastName",
        "Address",
        "Address2",
        "City",
        "State",
        "Zip",
        "Phone",
        "Email",
        "NetPromoterScore",
        "NetPromoterStarRating"
      ],
      ...objectsToExport.map(item => [
        this.sanitizeString(this.conceptDTO.find((obj => obj.conceptId == item.Brand)).displayName),
        this.sanitizeString(item.Franchise),
        this.sanitizeString(item.ContactFirstName),
        this.sanitizeString(item.ContactLastName),
        this.sanitizeString(item.Address),
        this.sanitizeString(item.Address2),
        this.sanitizeString(item.City),
        this.sanitizeString(item.State),
        this.sanitizeString(item.Zip),
        this.sanitizeString(item.Phone),

        /*    this.sanitizeString(item.Email.replaceAll(',', ';')), */
        item.Email?.replaceAll(',', ';'),
        item.NetPromoterScore,
        item.NetPromoterStarRating
      ])
    ].map(e => e.join(","))
      .join("\n");

    let link = document.createElement("a");
    let blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    let url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', 'NearbyFranchises.csv');
    link.click();
  }

  sanitizeString(str) {
    if (str) {
      str = str.replaceAll(/[^a-z0-9@áéíóúñü \.,_-]/gim, "");
      return this.escapeCSV(str.trim());
    }
    return ''
  }

  escapeCSV(term) {
    if (term.match && term.match(/,|"/)) {
      return `"${term.replaceAll('"', '""')}"`
    } else {
      return term
    }
  }


  getActiveItems() {
    let currentBounds = this.map.getBounds(); // get bounds of the map object's viewport
    let projection = this.masterClusterOverLay.getProjection();

    var index, t, pos, t1;
    index = this.modifiedMainDataArray && this.modifiedMainDataArray.length;
    if (this.filterOptions.layers.pins) {
      while (index--) {
        t = this.modifiedMainDataArray[index];
        if (t.FranchiseTerritoryTypeID == 2) {
          if (!t.onlyTafs) {
            continue
          }
        }
        if (this.filterOptions.filter.resale) {
          if (this.isAdminOrFranchiseDevelopment) {
            if (!t.FranchiseAvailableForsale) {
              continue;
            }
          } else if (this.isFranchiseeRole) {

            if (!t.FranchiseAvailableForsale || !t.ShowResale) {
              continue;
            }
          }

        }
        if (t && t.Brand) {
          if (!this.selectedConcept.find((obj) => obj.conceptId == t.Brand)) {
            continue;
          }
        }

        if (t) {
          if (t.lat && t.lng) {
            if (this.masterClusterOverLay) {
              try {
                pos = projection.fromLatLngToDivPixel(new google.maps.LatLng(t.lat, t.lng));
              } catch (err) {
                console.log('Error occured', err);
              }
            }
          }
        }

        if (!pos) {
          continue;
        }
        t1 = {
          id: t.id,
          b: t.Brand,
          a: !!t.available,
          x: pos.x,
          y: pos.y,
          FranchiseAvailableForsale: t.FranchiseAvailableForsale,
          FranchiseTerritoryTypeID: t.FranchiseTerritoryTypeID,
          ShowResale: t.ShowResale
          ////// Do some updates here

        };
        // Save a reference to the item position.

        var point = new google.maps.LatLng(parseFloat(t.lat), parseFloat(t.lng));

        if (currentBounds.contains(point)) {
          // your location is inside your map object's viewport
          this.positions[t.id] = t1;
          this.array.push(t1);
          this.positionMapping[t.id] = this.modifiedMainDataArray[index];
        }
      }
    } else {
      this.masterClusterOverLay._div.display = 'none'
    }

  }

  groupItems(array) {
    var t1, j, t2, x, y, d, k, x, y,
      len = array.length,
      i = 0;

    // Recursively compare items to one another.
    while (i < len) {
      // Get the item to start with.
      t1 = array[i++];
      // If this item was already grouped, skip over it.
      if (t1.grouped) {
        continue;
      }

      // Look through the next item to the end.
      j = i;
      while (j < len) {
        // Get the item to compare.
        t2 = array[j++];
        // If we already grouped this item, skip over it.
        if (t2.grouped) {
          continue;
        }


        x = t1.x - t2.x;
        y = t1.y - t2.y;

        d = Math.sqrt(x * x + y * y);

        // If we're too close.
        if (d < 45.0) {
          // Make sure we have a group array for this item.
          if (!t1.group) {
            // Clone the main item as it's own child.
            t1.group = [$.extend({}, t1)];
          }
          // Add the item to the group.
          t1.group.push(t2);

          // If we have multiple different brands, zero out the main one.
          if (t1.b != t2.b) {
            t1.b = 0;
          }

          // Re-assign the x and y coordinates to be average of it's child elements.
          k = t1.group.length;
          x = 0;
          y = 0;
          while (k--) {
            x += t1.group[k].x;
            y += t1.group[k].y;
          }
          t1.lat = x / t1.group.length;
          t1.lng = y / t1.group.length;

          if (this.isAdminOrFranchiseDevelopment) {
            if (t2.FranchiseAvailableForsale) {
              t1.FranchiseAvailableForsale = t2.FranchiseAvailableForsale;
            }
          } else if (this.isFranchiseeRole) {
            if (t2.FranchiseAvailableForsale && t2.ShowResale) {
              t1.FranchiseAvailableForsale = t2.FranchiseAvailableForsale;
              t1.ShowResale = t2.ShowResale;
            }
          }


          t2.grouped = true;
        }
      }
    }
    this.array = array;
  }

  toggleDarkMode() {

    if (this.darkModeStatus) {
      this.map.setOptions({ styles: Constants.googleMapsDarkModeStyles });
      document.body.classList.add("darkMode");
    } else {
      this.map.setOptions({ styles: Constants.googleMapsLightModeStyles })
      document.body.classList.remove("darkMode");
    }
  }

  static BindComponent(app: ng.IModule) {
    app.component('findANeighborComponent', {
      bindings: {

      },
      controller: FindANeighborComponent,
      templateUrl: "/Templates/FindANeighbor/FindANeighborComponent.html"
    });
  }

}
