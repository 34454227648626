import { IntegrationFinanceDashboardResultRm, BillingServiceClient, IntegrationFinanceClient }
    from "@nbly/billing-orchestrations-clients";

import { FinanceSyncWorkerClient } from "@nbly/service-manager-clients";
import { AxiosInstance } from "axios";
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";

export class financeIntegrationDashboardGridComponentController implements ng.IController {

    //bindings
    displaySnackbar: (params: { type: string, message: string }) => void;
    //end bindings

    selectedBatchIdsMap: Map<number, Array<number>>;
    selectedBatchIds: Set<number>;

    integrationFinanceDashboardResultRm: IntegrationFinanceDashboardResultRm;
    financeIntegrationBatchesGrid: kendo.ui.Grid;
    financeIntegrationBatchesGridOptions: kendo.ui.GridOptions;
    financeIntegrationInvoiceGrid: { [uid: string]: kendo.ui.Grid };

    billingOrchestrationsClient: AxiosInstance;
    billingServicesClient: BillingServiceClient
    integrationFinanceClient: IntegrationFinanceClient

    serviceManagerClient: AxiosInstance;
    financeSyncWorkerClient: FinanceSyncWorkerClient;

    isLoading: boolean;
    
    static $inject = [
        '$scope',
        '$rootScope',
    ];

    ResetInvoiceCounters() {
        this.selectedBatchIdsMap = new Map<number, Array<number>>();
    }

    ResetInvoiceBatchCounters() {
        this.selectedBatchIds = new Set<number>();
    }

    ResetCounters() {
        this.ResetInvoiceBatchCounters();
        this.ResetInvoiceCounters();
    }

    ResendFinanceInvoices = async function () {
        let snackBarMessage: { type: string, message: string } = null;

        if (!this.selectedBatchIdsMap || this.selectedBatchIdsMap.size <= 0) {
            snackBarMessage = { type: "warning", message: "Please select invoices for the re-trigger" };
            this.displaySnackbar(snackBarMessage);
            return;
        }

        let selectedInvoiceIds = [];

        this.selectedBatchIdsMap.forEach(function (value) {
            if(value && value.length)
                selectedInvoiceIds = [...selectedInvoiceIds, ...value];
        })

        if (selectedInvoiceIds && selectedInvoiceIds.length) {
            try {
                await this.financeSyncWorkerClient.execute6(Array.from(selectedInvoiceIds))
                snackBarMessage = { type: "success", message: "Request queued successfully" };
                this.OnRefreshGrid()
            }
            catch (error) {
                snackBarMessage = { type: "error", message: "Some error occured please try again after sometime!" };
            }
        }

        else {
            snackBarMessage = { type: "warning", message: "Please select invoices for the re-trigger" };
        }

        this.displaySnackbar(snackBarMessage);
    }

    ResendFinanceBatches = async function () {
        let snackBarMessage: { type: string, message: string } = null;

        if (this.selectedBatchIds && this.selectedBatchIds.size) {

            try {
                await this.financeSyncWorkerClient.execute5(Array.from(this.selectedBatchIds))
                snackBarMessage = { type: "success", message: "Request queued successfully" };
                this.OnRefreshGrid()
            }
            catch (error) {
                snackBarMessage = { type: "error", message: "Some error occured please try again after sometime!" };
            }
        }

        else {
            snackBarMessage = { type: "warning", message: "Please select invoice batches for the re-trigger" };
        }

        this.displaySnackbar(snackBarMessage);
    }

    constructor(
        private $scope: ng.IScope,
        private $rootScope: ng.IRootScopeService,
    ) {
        this.billingOrchestrationsClient = FranForceAxiosClientBuilder.BuildBillingOrchestrationsBaseClient();
        this.billingServicesClient = new BillingServiceClient("", this.billingOrchestrationsClient);
        this.integrationFinanceClient = new IntegrationFinanceClient("", this.billingOrchestrationsClient);

        this.serviceManagerClient = FranForceAxiosClientBuilder.BuildServiceManagerBaseClient();
        this.financeSyncWorkerClient = new FinanceSyncWorkerClient("", this.serviceManagerClient);

        this.ResetCounters();
    }

    

    $onInit() {
        this.$rootScope.$on('financeIntegrationDashboardFiltersChanged', async (event, args) => {
            if (args) {
                this.integrationFinanceDashboardResultRm = args.integrationFinanceDashboardFilters;

                console.log("this.integrationFinanceDashboardResultRm", this.integrationFinanceDashboardResultRm);

                await this.PopulateFinanceIntegrationBatchesGrid(this.integrationFinanceDashboardResultRm);
            }
        });
        this.financeIntegrationInvoiceGrid = {};
        this.LoadFinanceIntegrationBatchesGrid();
    }

    async $postLink() {

    }

    LoadFinanceIntegrationBatchesGrid() {
        const self = this;

        let financeIntegrationBatchesGridDataSource = new kendo.data.DataSource({
            data: [],
            pageSize: 25,
            schema: {
                model: {
                    id: "integrationFinanceInvoiceBatchId",
                    type:"number",
                    fields: {
                        integrationFinanceInvoiceBatchId: {
                            type: "number"
                        },
                        integrationFinanceProcessStatusTypeName: {
                            type: "string"
                        },
                        processedDateTime: {
                            type: "string"
                        },
                        createdDateTime: {
                            type: "string"
                        },
                        createdByUser: {
                            type: "string"
                        }
                    }
                }
            }
        });

        let financeIntegrationBatchesGridColumns: Array<kendo.ui.GridColumn> =
            [{
                selectable: true,
            },
            {
                field: "integrationFinanceInvoiceBatchId",
                title: "FinanceBatchId",
            },
            {
                field: "integrationFinanceProcessStatusTypeName",
                title: "Process Status",
            },
            {
                field: "processedDateTime",
                title: "Processed Date Time",
            },
            {
                field: "createdDateTime",
                title: "Created Date Time",
                template: "#= kendo.toString(kendo.parseDate(createdDateTime), 'MM/dd/yyyy hh:mm:ss tt') #",
            },
            {
                field: "createdByUser",
                title: "Created By User",
            }
            //{
            //    field: "integrationFinanceProcessStatusTypeName",
            //    title: "Actions",
            //    template: function (dataItem) {
            //        if (dataItem.integrationFinanceProcessStatusTypeName === 'Error-Import' ||
            //            dataItem.integrationFinanceProcessStatusTypeName === 'Error-Tax') {
            //            return `<button class='financeIntegrationDashboard_refreshBtnInGrid' ng-click='$ctrl.OnRefreshBatch(${dataItem.integrationFinanceInvoiceBatchId})'>Refresh</button>`;
            //        }
            //        return '';

            //    }
            //}
            ];

        this.financeIntegrationBatchesGridOptions = {
            editable: false,
            toolbar: [
                {
                    template: `<button class='financeIntegrationDashboard_refreshBtn' ng-click='$ctrl.OnRefreshGrid()'>Refresh</button>`
                },
                {
                    template: `<button ng-show="{{$ctrl.selectedBatchIds.length}}" class='financeIntegrationDashboard_refreshBtn' ng-click='$ctrl.ResendFinanceBatches()'>Resend</button>`
                }
            ],
            autoBind: false,
            change: this.OnFinanceIntegrationBatchesGridRowCheck(self),
            dataSource: financeIntegrationBatchesGridDataSource,
            columns: financeIntegrationBatchesGridColumns,
            sortable: true,
            pageable: {
                numeric: true,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
        };
    }

    OnRefreshBatch = async function (batchId: number) {

        const integrationbatchData = await this.onRefreshGetBatchData(batchId);
        console.log("Data received: ", integrationbatchData);

        const batch = integrationbatchData.integrationFinanceInvoiceBatchs.find(b => b.integrationFinanceInvoiceBatchId == batchId);
            
        if (batch) {
            const financeIntegrationBatchesGridData = this.financeIntegrationBatchesGrid.dataSource.data();
        
            const rowToUpdate = financeIntegrationBatchesGridData.find((item: any) =>
                (item as { integrationFinanceInvoiceBatchId: number }).integrationFinanceInvoiceBatchId === batch.integrationFinanceInvoiceBatchId
            );
        
            if (rowToUpdate) {
                rowToUpdate.processedDateTime = batch.processedDateTime;
                rowToUpdate.createdByUser = batch.createdByUser;
                rowToUpdate.integrationFinanceProcessStatusTypeName = batch.integrationFinanceProcessStatusTypeName;
        
                const invoices = rowToUpdate.integrationFinanceInvoices;
        
                rowToUpdate.integrationFinanceInvoices = invoices.map((invoice, index) => {
                    const updatedInvoice = batch.integrationFinanceInvoices[index];
                    return {
                        ...invoice,
                        processedDateTime: updatedInvoice.processedDateTime,
                        errorDescription: updatedInvoice.errorDescription || '',
                        invoiceDate: updatedInvoice.invoiceDate,
                        invoiceType: updatedInvoice.invoiceType,
                        invoiceNumber: updatedInvoice.invoiceNumber,
                        invoiceSource: updatedInvoice.invoiceSource,
                        invoiceSourceId: updatedInvoice.invoiceSourceId,
                        customerId: updatedInvoice.customerId,
                        overrideTaxDate: updatedInvoice.overrideTaxDate,
                        company: updatedInvoice.company,
                        financeSalesOrderNumber: updatedInvoice.financeSalesOrderNumber,
                        isTaxSynced: updatedInvoice.isTaxSynced,
                        processRetryCount: updatedInvoice.processRetryCount,
                        financeInvoiceNumber: updatedInvoice.financeInvoiceNumber
                    };
                });
        
                this.financeIntegrationBatchesGrid.refresh();
            }
        }
        
    }

    private OnFinanceIntegrationBatchesGridRowCheck(self: this): (e: kendo.ui.GridChangeEvent) => void {
        return function(gridChangeEvent: kendo.ui.GridChangeEvent) {
            const datasource = this;

            const rows = this.select();

            if (rows && rows.length) {
                rows.each(function () {
                    const selectedRow = datasource.dataItem(this);
                    const { integrationFinanceInvoiceBatchId } = selectedRow;

                    self.selectedBatchIds.add(integrationFinanceInvoiceBatchId);
                });
            }
            else
                self.ResetInvoiceBatchCounters();
        };
    }

    GetfinanceIntegrationInvoiceGridDeatilsOptions(data: any): kendo.ui.GridOptions {
        const self = this;

        let financeIntegrationInvoiceGridDataSource = new kendo.data.DataSource({
            batch: true,
            pageSize: 10,
            schema: {
                model: {
                    id: "integrationFinanceInvoiceId",
                    fields: {
                        integrationFinanceInvoiceId: {
                            type: "number"
                        },
                        processedDateTime: {
                            type: "string"
                        },
                        errorDescription: {
                            type: "string"
                        },
                        invoiceDate: {
                            type: "string"
                        },
                        invoiceType: {
                            type: "string"
                        },
                        invoiceNumber: {
                            type: "string"
                        },
                        invoiceSource: {
                            type: "string"
                        },
                        invoiceSourceId: {
                            type: "string"
                        },
                        customerId: {
                            type: "string"
                        },
                        overrideTaxDate: {
                            type: "string"
                        },
                        company: {
                            type: "string"
                        },
                        invoiceTaxAmount: {
                            type: "number"
                        }
                    }
                }
            },
            transport: {
                read: (e: kendo.data.DataSourceTransportReadOptions) => {
                    e.success(data.integrationFinanceInvoices);
                },
            }
        });

        let financeIntegrationInvoiceGridColumns: Array<kendo.ui.GridColumn> = [
            {
                selectable: true
            },
            {
                field: "integrationFinanceInvoiceId",
                title: "Finance Invoice Id",
            }, {
                field: "processedDateTime",
                title: "Processed Date Time",
                template: "#= processedDateTime != null ? kendo.toString(kendo.parseDate(processedDateTime), 'MM/dd/yyyy hh:mm:ss tt') : '' #",
            }, {
                field: "errorDescription",
                title: "Error Description"
            }, {
                field: "invoiceDate",
                title: "InvoiceDate",
                template: "#= kendo.toString(kendo.parseDate(invoiceDate), 'MM/dd/yyyy hh:mm:ss tt') #",
            }, {
                field: "invoiceType",
                title: "Invoice Type"
            }, {
                field: "invoiceNumber",
                title: "Invoice Number"
            }, {
                field: "invoiceSource",
                title: "Invoice Source",
            }, {
                field: "invoiceSourceId",
                title: "Invoice Source Id"
            }, {
                field: "customerId",
                title: "Customer Id"
            }, {
                field: "overrideTaxDate",
                title: "Override Tax Date",
                template: "#= overrideTaxDate != null ? kendo.toString(kendo.parseDate(overrideTaxDate), 'MM/dd/yyyy hh:mm:ss tt') : '' #",
            }, {
                field: "company",
                title: "Company"
            }, {
                field: "financeSalesOrderNumber",
                title: "Sales Order Number"
            }];

        return {
            columns: financeIntegrationInvoiceGridColumns,
            change: this.OnFinanceIntegrationInvoiceRowChecked(self),
            pageable: {
                numeric: true,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            dataSource: financeIntegrationInvoiceGridDataSource,
            resizable: true,
            scrollable: true,
            sortable: true
        };
    }

    private OnFinanceIntegrationInvoiceRowChecked(self: this): (e: kendo.ui.GridChangeEvent) => void {
        return function (arg) {
            const detailGridWrapper = this.wrapper;
            const parentRow = detailGridWrapper.closest("tr.k-detail-row").prev("tr");
            const parentGrid = parentRow.closest("[data-role=grid]").data("kendoGrid");
            const parentModel = parentGrid.dataItem(parentRow);

            const { integrationFinanceInvoiceBatchId } = parentModel;

            const datasource = this;

            const rows = this.select();

            if (rows && rows.length) {
                rows.each(function () {
                    const selectedRow = datasource.dataItem(this);

                    const { integrationFinanceInvoiceId } = selectedRow;

                    if (self.selectedBatchIdsMap.has(integrationFinanceInvoiceBatchId)) {
                        const batches = self.selectedBatchIdsMap.get(integrationFinanceInvoiceBatchId);
                        batches.push(integrationFinanceInvoiceId);
                    }
                    else
                        self.selectedBatchIdsMap.set(integrationFinanceInvoiceBatchId, [integrationFinanceInvoiceId]);

                });
            }
            else
                self.selectedBatchIdsMap.set(integrationFinanceInvoiceBatchId, []);
        };
    }

    async PopulateFinanceIntegrationBatchesGrid(integrationFinanceDashboardResultRm: IntegrationFinanceDashboardResultRm) {
        this.isLoading = true;
        await this.integrationFinanceClient.dashboardResult(integrationFinanceDashboardResultRm)
            .then(response => {
                if (response && response.result) {
                    this.financeIntegrationBatchesGrid.dataSource.data(response.result.integrationFinanceInvoiceBatchs)
                    this.financeIntegrationBatchesGrid.refresh();
                }

            })
            .catch(err => {
                this.displaySnackbar({ type: "error", message: "Error! Failed to load dashboard data." });
            })
            .finally(() => {
                this.isLoading = false;
                this.$scope.$digest();
            })
    }

    async onRefreshGetBatchData(batchId : number) {
        this.isLoading = true;
        try {
            const response = await this.integrationFinanceClient.batchStatus(batchId);
            if (response && response.result) {
                return response.result;
            }
        } catch (err) {
            this.displaySnackbar({ type: "error", message: "Error! Failed to load data." });
        } finally {
            this.isLoading = false;
            this.$scope.$digest();
        }
    }

    OnRefreshGrid() {
        this.$rootScope.$emit('financeIntegrationDashboardRefresh', {});
        this.ResetCounters();
    }

    static BindComponent(app: ng.IModule) {
        app.component("financeIntegrationDashboardGrid", {
            bindings: {
                [nameof<financeIntegrationDashboardGridComponentController>(o => o.displaySnackbar)]: "&",
            },
            templateUrl: '/Templates/FinanceIntegrationDashboard/financeIntegrationDashboardGrid.html',
            controller: financeIntegrationDashboardGridComponentController
        });
    }
}