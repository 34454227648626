import * as _ from "underscore"
import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";

import {
    PhotoGalleryCategoryConceptXrefClient, PhotoGalleryCategoryConceptXrefWithCategoryDescDto,
    PhotoGalleryCategoriesClient, PhotoGalleryCategoryDto,
    PhotoGalleryImageInfoClient,
    PhotoGalleryImageInfoDto
} from '@nbly/service-orchestrations-clients';

import {
    FranchiseWebLocationClient,
    FranchiseWebLocationDto
} from '@nbly/franchise-group-api-clients';

import { getNaturalImageDimensions, photoGalleryImageDimensions } from "Utility/ImageDimensionUtils";

const Compressor = require('compressorjs');
export class FranchiseProfilePhotosComponent implements ng.IController {
    franchiseId: number;
    conceptId: number;
    isLoading: boolean;
    franchiseWebLocationId: number;

    isFirstLoad: boolean;
    rawFile: kendo.ui.UploadSelectEvent;
    conceptCode: string;
    bioImageUrl: any;
    kendoUpload: kendo.ui.Upload;
    defaultKendoUploadOptions: kendo.ui.UploadOptions;
    Compressor: any;
    /*     compressorOptions: Compressor.Options; */
    allowedUploadExtensions: string[];
    compressFile: any;
    franchiseWebLocationDtoArray: FranchiseWebLocationDto[];
    franchiseWebLocationDto: FranchiseWebLocationDto;
    photoEditorWindow: kendo.ui.Window;
    photoEditorWindowOptions: kendo.ui.WindowOptions;

    //Clients
    PhotoGalleryCategoryDto: PhotoGalleryCategoryDto[];
    photoGalleryImageInfoByWebLocationDto: PhotoGalleryImageInfoDto[] = undefined;
    PhotoGalleryCategoryConceptXrefWithCategoryDescDto: PhotoGalleryCategoryConceptXrefWithCategoryDescDto[];
    serviceOrchestrationsClient: AxiosInstance;
    franchiseWebLocationClient: AxiosInstance;
    photoGalleryCategoryConceptXrefClient: PhotoGalleryCategoryConceptXrefClient;
    photoGalleryCategoriesClient: PhotoGalleryCategoriesClient;
    franchiseWebLocationListClient: FranchiseWebLocationClient;
    photoGalleryImageInfoClient: PhotoGalleryImageInfoClient;
    selectedPhoto: any;

    static $inject = [
        "$scope",
        "$document"
    ];

    constructor(
        private $scope: ng.IScope,
        private $document: ng.IDocumentService
    ) {
        this.serviceOrchestrationsClient = FranForceAxiosClientBuilder.BuildServiceOrchestrationsBaseClient();
        this.franchiseWebLocationClient = FranForceAxiosClientBuilder.BuildFranchiseGroupBaseClient();
        this.photoGalleryCategoriesClient = new PhotoGalleryCategoriesClient("", this.serviceOrchestrationsClient);
        this.photoGalleryImageInfoClient = new PhotoGalleryImageInfoClient("", this.serviceOrchestrationsClient);
        this.photoGalleryCategoryConceptXrefClient = new PhotoGalleryCategoryConceptXrefClient("", this.serviceOrchestrationsClient);
        this.franchiseWebLocationListClient = new FranchiseWebLocationClient("", this.franchiseWebLocationClient);
    }

    async $onInit() {
        this.InitUploadComponent();

    }

    async $postLink() {
        this.$document.on('franchiseWebLocationSelector-webLocationSelect', async (ev: any) => {
            this.franchiseWebLocationDto = ev.originalEvent.detail;
            this.conceptId = this.franchiseWebLocationDto.conceptId;
            this.franchiseWebLocationId = this.franchiseWebLocationDto.franchiseWebLocationId;
            this.loadFranchiseWeblocations();
            await this.loadConceptCategories();
            await this.getPhotosByWebLocationId();

        });

    }

    async getPhotosByWebLocationId() {
        this.photoGalleryImageInfoByWebLocationDto = undefined
        if (this.franchiseWebLocationDto && this.franchiseWebLocationDto.franchiseWebLocationId) {
            let webLocationId = this.franchiseWebLocationDto.franchiseWebLocationId;
            await this.photoGalleryImageInfoClient.webLocationId(webLocationId).then((res) => {
                this.photoGalleryImageInfoByWebLocationDto = res.result;
                this.$scope.$digest();
            })
        }
    }


    async loadFranchiseWeblocations() {
        if (this.franchiseId) {
            await this.franchiseWebLocationListClient.get_protected_franchiseweblocations_franchises_franchiseid(this.franchiseId)
                .then((res) => {
                    this.franchiseWebLocationDtoArray = res.result;
                }).catch((err) => {
                    console.log('Error Occured while fetching franchiseWebLocationDtos', this.franchiseWebLocationDtoArray)
                })
        }
    }


    async loadConceptCategories() {
        await this.photoGalleryCategoryConceptXrefClient.concept(this.conceptId).then((res) => {
            this.PhotoGalleryCategoryConceptXrefWithCategoryDescDto = res.result;
            this.$scope.$digest();
        }).catch((err) => {
            this.PhotoGalleryCategoryConceptXrefWithCategoryDescDto = [];
        });
    }

    async FindOptimalQuality(file) {
        let result;
        for (let i = 10; i > 0; i--) {
            result = await new Promise((resolve, reject) => {
                new Compressor(file, {
                    quality: i / 10,
                    maxWidth: 1000,
                    success: resolve,
                    error: reject,
                });
            });
            console.log('the result of finding optimal quality file is ', result.size)
            if (result.size < 500000) {
                console.log('break from the loop');
                break;
            }
        }
        return result;
    }



    async compress(file) {
        try {
            let response = await this.FindOptimalQuality(file);
            /*             const compressedFile = new File([response], file.name, { type: file.type, lastModified: Date.now() }); */
            let reader = new FileReader();
            reader.onload = (event) => {
                this.bioImageUrl = event.target.result;
                this.$scope.$digest();
            };

            reader.readAsDataURL(response);

            return response;
        } catch (err) {

        }

    }


    async setImage(e: kendo.ui.UploadSelectEvent) {

        try {
            const { width, height } = await getNaturalImageDimensions(e.files[0].rawFile);
            console.log(`Image dimensions: ${width}px x ${height}px`);
            if (width <= photoGalleryImageDimensions.width || height <= photoGalleryImageDimensions.height) {
                this.kendoUpload.clearAllFiles();
                alert('Image dimensions should be greater than 243 x 243. Please upload a new image.');
                return;
            }else{
                this.rawFile = e.files[0].rawFile;;
                console.log('the raw file', this.rawFile)
        
                this.openPhotoEditorPopup(null);
            }
        } catch (e) {
            // Could not load image from specified URL
            console.error(e);
        }


    }





    InitUploadComponent() {

        this.allowedUploadExtensions = [".png", ".webp", ".jpeg", ".jpg"];
        this.defaultKendoUploadOptions = {
            showFileList: true,
            multiple: false,
            async: {
                autoUpload: false,
            },
            validation: {
                allowedExtensions: this.allowedUploadExtensions
            },
            localization: {
                select: 'Select Photo',

            },
            select: this.onSelect
        };
    }

    onSelect = (e) => {
        if (e.files[0].validationErrors?.length) {
            console.log('error occured')
        } else {
            this.setImage(e);
        }
    }

    openPhotoEditorPopup(photo: { photo: PhotoGalleryImageInfoDto }) {

        this.selectedPhoto = null;
        if (photo) {
            this.selectedPhoto = photo.photo;
            let template =
                `<div style="width:80vw;height:80vh;">
                <photo-editor-component
                photo-By-Weblocation="$ctrl.selectedPhoto"
                concept-id="$ctrl.conceptId"
                photo-Gallery-Category-Concepts="$ctrl.PhotoGalleryCategoryConceptXrefWithCategoryDescDto"
                franchise-Web-Location-Dto="$ctrl.franchiseWebLocationDtoArray"
                franchise-web-location-id="$ctrl.franchiseWebLocationId"
                on-save="$ctrl.getPhotosByWebLocationId()"
                ></photo-editor-component>
            </div>`;

            let options: kendo.ui.WindowOptions = {
                modal: true,
                position: { top: 20 },
                close: (e) => {
                    /*   this.kendoUpload.clearAllFiles(); */
                    /*   this.photoEditorWindow.destroy(); */

                }
            };

            this.photoEditorWindow.open();
            this.photoEditorWindow.title('Photo Editor');
            this.photoEditorWindow.content(template)
            this.photoEditorWindow.center();
            this.photoEditorWindow.setOptions(options);

        } else {

            let template =
                `<div style="width:80vw;height:80vh;">
                <photo-editor-component
                photo-By-Weblocation="$ctrl.selectedPhoto"
                image-file="$ctrl.rawFile"
                concept-id="$ctrl.conceptId"
                photo-Gallery-Category-Concepts="$ctrl.PhotoGalleryCategoryConceptXrefWithCategoryDescDto"
                franchise-Web-Location-Dto="$ctrl.franchiseWebLocationDtoArray"
                franchise-web-location-id="$ctrl.franchiseWebLocationId"
                on-save="$ctrl.getPhotosByWebLocationId()"
                ></photo-editor-component>
            </div>`;

            let options: kendo.ui.WindowOptions = {
                modal: true,
                position: { top: 20 },
                close: (e) => {
                    console.log('Inside else close');

                    this.kendoUpload.clearAllFiles();
                    /*  this.photoEditorWindow.destroy(); */

                }
            };

            this.photoEditorWindow.open();
            this.photoEditorWindow.title('Photo Editor');
            this.photoEditorWindow.content(template)
            this.photoEditorWindow.center();
            this.photoEditorWindow.setOptions(options);

        }


    }


    static BindComponent(app: ng.IModule) {
        app.component('franchiseProfilePhotos', {
            bindings: {
                franchiseId: "<"
            },
            controller: FranchiseProfilePhotosComponent,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfilePhotos.html"
        });
    }
}


