import { ParsedPosEmployeeId, SyncApiResources } from "Services/Resources/SyncApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AngularUtil } from "Utility/AngularUtil";
import * as _ from "underscore"
import { userStateObj, EditUserStateService } from "Services/State/EditUserStateService";
import { SyncResources } from "Interfaces/FranForce/Sync/SyncResources";
import { CoreEntities, CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export interface PosEmployeeDisplay {
    Id: number,
    Display: string;
    Type: "T" | "E";
}

export class EditUserFranchisePosEmployeeComponentController implements ng.IController 
{
    managedUserId: string;
    refreshDate: Date;
    refresh: (params: { refreshedDate: Date }) => void;

    userState: userStateObj;
    isLoading: boolean;

    isViewable: boolean;
    syncPosEmployeeIds: ParsedPosEmployeeId[];
    franchiseId: number;

    employeesDropDown: kendo.ui.DropDownList;
    employeesDropDownOptions: kendo.ui.DropDownListOptions;

    conceptFilters: kendo.data.DataSourceFilters;
    franchiseFilters: kendo.data.DataSourceFilters;

    techParams: ODataQueryParameters;
    employeeParams: ODataQueryParameters;

    static $inject = [
        'editUserState',
        'syncApiResources',
        'identityManager',
        'apiConfig',
        '$q'
    ];

    constructor(
        private editUserState: EditUserStateService,
        private syncApiResources: SyncApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $q: ng.IQService
    )
    {
    }

    $onInit()
    {
        this.techParams = {
            $select: [
                nameof<SyncResources.ISyncTechnician>(o => o.Email),
                nameof<SyncResources.ISyncTechnician>(o => o.Name),
                nameof<SyncResources.ISyncTechnician>(o => o.SyncTechnicianId)
            ].join(",")
        };

        this.employeeParams = {
            $select: [
                nameof<SyncResources.ISyncEmployee>(o => o.Email),
                nameof<SyncResources.ISyncEmployee>(o => o.Name),
                nameof<SyncResources.ISyncEmployee>(o => o.SyncEmployeeId)
            ].join(",")
        };

        this.employeesDropDownOptions = {
            optionLabel: "Select POS Employee...",
            optionLabelTemplate: "Select POS Employee...",
            dataTextField: nameof<PosEmployeeDisplay>(o => o.Display),
            dataValueField: nameof<PosEmployeeDisplay>(o => o.Id),
            autoBind: false,
            autoWidth: true,
            valuePrimitive: false,
            filter: "contains",
            height: 450,
            dataSource: new kendo.data.DataSource({
                sort: [
                    { field: nameof<PosEmployeeDisplay>(o => o.Display), dir: "asc" }
                ],
                transport: {
                    read: (options) =>
                    {
                        if (!this.franchiseId) {
                            options.success([]);
                            return;
                        }

                        this.$q.all([
                            this.syncApiResources.GetSyncEmployeesInFranchises(this.franchiseId, this.employeeParams),
                            this.syncApiResources.GetSyncTechniciansInFranchises(this.franchiseId, this.techParams)
                        ])
                            .then((responses) =>
                            {
                                let employees = responses[0].data
                                    .map((employee) =>
                                    {
                                        return <PosEmployeeDisplay>{
                                            Display: `${employee.Name}`,
                                            Id: employee.SyncEmployeeId,
                                            Type: "E"
                                        };
                                    });
                                let technicians = responses[1].data
                                    .map((tech) =>
                                    {
                                        return <PosEmployeeDisplay>{
                                            Display: `${tech.Name}`,
                                            Id: tech.SyncTechnicianId,
                                            Type: "T"
                                        };
                                    });

                                let posEmployees = employees.concat(technicians)
                                    .filter((posEmployee) =>
                                    {
                                        return this.syncPosEmployeeIds.every(id =>
                                            id.Id != posEmployee.Id && id.Type != posEmployee.Type
                                        );
                                    });

                                options.success(posEmployees);
                            })
                            .catch((err) =>
                            {
                                options.error(err);
                            })
                    }
                }
            })
        };
    }

    $postLink()
    {

    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        this.Load();
    }

    Load()
    {
        let promise = this.editUserState.Load(this.managedUserId)
            .then((result) =>
            {
                this.userState = result;
            })
            .then(() =>
            {
                this.isViewable = (this.userState.managedUser ? true : false) &&
                    this.userState.managedUser.Roles.some(r =>
                        r.Name == this.apiConfig.FranForceConstants.RoleConstants.FranchiseeRole || 
                        r.Name == this.apiConfig.FranForceConstants.RoleConstants.FranchiseeEmployeeRole
                    );

                this.syncPosEmployeeIds = this.userState.managedUser.Claims
                    .filter(c => c.Type === this.apiConfig.FranForceConstants.ClaimConstants.AssignedPosEmployeeIdClaimType)
                    .map(c => this.syncApiResources.ParsePosEmployeeIdClaimValue(c.Value));

                this.SetFilters();

                return this.RefreshPosEmployeeDropDown();
            });


        return AngularUtil.TrackLoadingPromise(promise, this);
    }

    SetFilters()
    {
        let conceptFilterItems: Array<kendo.data.DataSourceFilterItem> = [];
        let franchiseFilterItems: Array<kendo.data.DataSourceFilterItem> = [];

        this.conceptFilters = <any>[];
        this.franchiseFilters = null;

        let conceptIds = this.userState.managedUser.Claims
            .filter(c => c.Type == this.apiConfig.FranForceConstants.ClaimConstants.ManagedConceptIdClaimType)
            .map(c => parseInt(c.Value));

        let franchiseIds = this.userState.managedUser.Claims
            .filter(c =>
                c.Type == this.apiConfig.FranForceConstants.ClaimConstants.EmployedFranchiseIdClaimType ||
                c.Type == this.apiConfig.FranForceConstants.ClaimConstants.OwnedFranchiseIdClaimType
            )
            .map(c => parseInt(c.Value));
        franchiseIds = _.uniq(franchiseIds);

        for (let conceptId of conceptIds) {
            conceptFilterItems.push(
                {
                    field: nameof<CoreEntities.Concept>(o => o.ConceptId),
                    operator: "eq",
                    value: conceptId
                }
            );

            franchiseFilterItems.push(
                {
                    field: nameof<CoreEntities.Franchise>(o => o.ConceptId),
                    operator: "eq",
                    value: conceptId
                }
            );
        }

        for (let franchiseId of franchiseIds)
        {
            franchiseFilterItems.push(
                {
                    field: nameof<CoreEntities.Franchise>(o => o.FranchiseId),
                    operator: "eq",
                    value: franchiseId
                }
            )
        }

        if (conceptFilterItems.length) {
            this.conceptFilters = {
                logic: "or",
                filters: conceptFilterItems
            }
        }

        if (franchiseFilterItems.length)
        {
            this.franchiseFilters = {
                logic: "or",
                filters: franchiseFilterItems
            }
        }
    }

    OnFranchiseSelect(franchise: Partial<CoreResources.IFranchise>)
    {
        this.franchiseId = franchise.FranchiseId;
        this.RefreshPosEmployeeDropDown();
    }

    AddPosEmployee(posEmployee: PosEmployeeDisplay)
    {
        return this.identityManager.AddAssignedPosEmployeePermission({
            Email: this.userState.managedUser.Email,
            Id: posEmployee.Id,
            Type: posEmployee.Type
        })
            .then(() =>
            {
                this.RefreshUser();
            })
            .catch(AngularUtil.GetJsonAlertCatch());
    }

    RemovePosEmployee(posEmployee: PosEmployeeDisplay)
    {
        return this.identityManager.RemoveAssignedPosEmployeePermission({
            Email: this.userState.managedUser.Email,
            Id: posEmployee.Id,
            Type: posEmployee.Type
        })
            .then(() =>
            {
                this.RefreshUser();
            })
            .catch(AngularUtil.GetJsonAlertCatch());
    }

    RefreshPosEmployeeDropDown()
    {
        if (!this.employeesDropDown)
            return;

        return this.$q.when(this.employeesDropDown.dataSource.read())
            .then(() =>
            {
                this.employeesDropDown.refresh();
            })
    }

    RefreshUser()
    {
        this.editUserState.Clear(this.userState.managedUser.Id);
        if (this.refresh) {
            this.refresh({
                refreshedDate: new Date()
            });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("editUserFranchiseTechnician", {
            bindings: {
                [nameof<EditUserFranchisePosEmployeeComponentController>(o => o.managedUserId)]: "<",
                [nameof<EditUserFranchisePosEmployeeComponentController>(o => o.refreshDate)]: "<",
                [nameof<EditUserFranchisePosEmployeeComponentController>(o => o.refresh)]: "&"
            },
            controller: EditUserFranchisePosEmployeeComponentController,
            templateUrl: "/Templates/Users/EditUser/EditUserFranchisePosEmployee.html"
        });
    }
}

