import { royaltySystemDataModel } from "../../../Models/Royalty/RoyaltySystemType/RoyaltySystemDataModel";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { KendoUtil } from "../../../Utility/KendoUtil";
import { RpmHelperService } from "../../../Services/Utility/RpmHelperService";
import { rmsSearchResultDataModel } from "../../../Models/Royalty/RmsSearch/RmsSearchResultDataModel";
import { BillingInvoiceTaxResultVmDataModel } from "../../../Models/Billing/ZorWare/BillingInvoices/BillingInvoiceTaxResultVmDataModel";
import { BillingInvoiceTaxTrackerResponseDataModel } from "../../../Models/Billing/ZorWare/BillingInvoices/BillingInvoiceTaxTrackerResponseDataModel";

export class BillingInvoiceTaxTrackerGridComponentController implements ng.IController {

    //bindings
    billingInvoiceTaxTrackerGridDataSource: kendo.data.DataSource;

    //bindings

    billingInvoiceTaxTrackerGrid: kendo.ui.Grid;

    static $inject = [
        'rpmHelper',
        '$timeout',
        '$q',
        '$window'
    ];

    constructor(
        private rpmHelper: RpmHelperService,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
        private $window: ng.IWindowService
    ) {

    }

    $onInit() {

    }

    $postLink() {
        this.InitBillingInvoiceTaxTrackerGrid();
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.billingInvoiceTaxTrackerGridDataSource)] && this.billingInvoiceTaxTrackerGridDataSource) {
            this.billingInvoiceTaxTrackerGrid.setDataSource(this.billingInvoiceTaxTrackerGridDataSource);
            this.billingInvoiceTaxTrackerGrid.resize(true);
        }
    }

    InitBillingInvoiceTaxTrackerGrid() {
        let billingInvoiceTaxTrackerGridColumns: kendo.ui.GridColumn[] = [
            {
                field: nameof<BillingInvoiceTaxTrackerResponseDataModel>(o => o.conceptId),
                title: "Concept Id",
            }, {
                field: nameof<BillingInvoiceTaxTrackerResponseDataModel>(o => o.conceptCode),
                title: "Concept Code",
            }, {
                field: nameof<BillingInvoiceTaxTrackerResponseDataModel>(o => o.conceptName),
                title: "Concept Name",
            }, {
                field: nameof<BillingInvoiceTaxTrackerResponseDataModel>(o => o.year),
                title: "Year",
            }, {
                field: nameof<BillingInvoiceTaxTrackerResponseDataModel>(o => o.month),
                title: "Month",
            }, {
                field: nameof<BillingInvoiceTaxTrackerResponseDataModel>(o => o.statusType),
                title: "Status",
            }];

        let billingInvoicesTaxGridOptions: kendo.ui.GridOptions = {
            columns: billingInvoiceTaxTrackerGridColumns,
            selectable: false,
            editable: false,
            scrollable: true,
            sortable: true,
            dataBound: function () {
                for (var i = 0; i < this.columns.length; i++) {
                    this.autoFitColumn(i);
                }
            },
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true

            },
            resizable: true,
            filterable: true
        };

        if (this.billingInvoiceTaxTrackerGrid) {
            this.billingInvoiceTaxTrackerGrid.setOptions(billingInvoicesTaxGridOptions);
            $("#billingInvoicesTaxGrid").kendoTooltip({
                filter: "th",
                position: "right",
                content: function (e) {
                    var cell = $(e.target);
                    var content = cell.text();
                    return content;
                }
            }).data("kendoTooltip");
        }
    }

    static BindComponent(app: ng.IModule) {
        app.component("billingInvoiceTaxTrackerGrid", {
            bindings: {
                [nameof<BillingInvoiceTaxTrackerGridComponentController>(o => o.billingInvoiceTaxTrackerGridDataSource)]: "<",
            },
            templateUrl: '/Templates/Billing/GenerateBillingInvoices/BillingInvoiceTaxTrackerGrid.html',
            controller: BillingInvoiceTaxTrackerGridComponentController
        });
    }
}