import { royaltySystemDataModel } from "../../../Models/Royalty/RoyaltySystemType/RoyaltySystemDataModel";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { KendoUtil } from "../../../Utility/KendoUtil";
import { RpmHelperService } from "../../../Services/Utility/RpmHelperService";
import { rmsSearchResultDataModel } from "../../../Models/Royalty/RmsSearch/RmsSearchResultDataModel";
import { BillingInvoiceTaxResultVmDataModel } from "../../../Models/Billing/ZorWare/BillingInvoices/BillingInvoiceTaxResultVmDataModel";

export class billingInvoicesTaxGridComponentController implements ng.IController {

    // -- Bindings --
    billingInvoicesTaxGridDataSource: kendo.data.DataSource;
    // --

    billingInvoicesTaxGrid: kendo.ui.Grid;

    static $inject = [
        'rpmHelper',
        '$timeout',
        '$q',
        '$window'
    ];

    constructor(
        private rpmHelper: RpmHelperService,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
        private $window: ng.IWindowService
    ) {

    }

    $onInit() {

    }

    $postLink() {
        this.InitBillingInvoicesTaxGrid();
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.billingInvoicesTaxGridDataSource)] && this.billingInvoicesTaxGridDataSource) {
            this.billingInvoicesTaxGrid.setDataSource(this.billingInvoicesTaxGridDataSource);
            this.billingInvoicesTaxGrid.resize(true);
        }
    }

    InitBillingInvoicesTaxGrid() {
        let billingInvoicesTaxGridColumns: kendo.ui.GridColumn[] = [
        {
            field: nameof<BillingInvoiceTaxResultVmDataModel>(o => o.customerNumber),
                title: "Customer Number",
        }, {
                field: nameof<BillingInvoiceTaxResultVmDataModel>(o => o.documentNumber),
                title: "Document Number",
        }, {
                field: nameof<BillingInvoiceTaxResultVmDataModel>(o => o.batchNumber),
                title: "Batch Number",
        }, {
                field: nameof<BillingInvoiceTaxResultVmDataModel>(o => o.batchSource),
                title: "Batch Source",
        }, {
                field: nameof<BillingInvoiceTaxResultVmDataModel>(o => o.documentDate),
                title: "Document Date",
        }, {
                field: nameof<BillingInvoiceTaxResultVmDataModel>(o => o.taxAmount),
                title: "Tax Amount",
        }, {
                field: nameof<BillingInvoiceTaxResultVmDataModel>(o => o.voidStatus),
                title: "Void Status",
            }, {
                field: nameof<BillingInvoiceTaxResultVmDataModel>(o => o.voidDate),
                title: "Void Date",
        }, {
                field: nameof<BillingInvoiceTaxResultVmDataModel>(o => o.trxDescription),
                title: "TRX Description",
        }];

        let billingInvoicesTaxGridOptions: kendo.ui.GridOptions = {
            columns: billingInvoicesTaxGridColumns,
            selectable: false,
            editable: false,
            scrollable: true,
            sortable: true,
            dataBound: function () {
                for (var i = 0; i < this.columns.length; i++) {
                    this.autoFitColumn(i);
                }
            },
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true

            },
            resizable: true,
            filterable: true
        };

        if (this.billingInvoicesTaxGrid) {
            this.billingInvoicesTaxGrid.setOptions(billingInvoicesTaxGridOptions);
            $("#billingInvoicesTaxGrid").kendoTooltip({
                filter: "th",
                position: "right",
                content: function (e) {
                    var cell = $(e.target);
                    var content = cell.text();
                    return content;
                }
            }).data("kendoTooltip");
        }
    }

    static BindComponent(app: ng.IModule) {
        app.component("billingInvoicesTaxGrid", {
            bindings: {
                [nameof<billingInvoicesTaxGridComponentController>(o => o.billingInvoicesTaxGridDataSource)]: "<",
            },
            templateUrl: '/Templates/Billing/GenerateBillingInvoices/BillingInvoicesTaxGrid.html',
            controller: billingInvoicesTaxGridComponentController
        });
    }
}