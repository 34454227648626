let selectOnClickDirectiveConfig = [
    '$window', '$timeout',
    ($window: ng.IWindowService, $timeout: ng.ITimeoutService) => ({
        restrict: 'A',
        link(scope: ng.IScope, element: ng.IAugmentedJQuery, attrs)
        {
            var focusedElement = null;

            element.on('focus', () =>
            {
                var self = this;
                if (focusedElement != self) {
                    focusedElement = self;
                    $timeout(() =>
                    {
                        this.select();
                    }, 10);
                }
            });

            element.on('blur', () =>
            {
                focusedElement = null;
            });
        }
    })
];

export { selectOnClickDirectiveConfig };