

export class SearchFranchisePostalCodesController implements ng.IController 
{
    conceptId: number;
    filters: kendo.data.DataSourceFilters;
    
    static $inject = [];

    constructor()
    {

    }

    $onInit()
    {
        this.filters = {
            //logic: "or",
            //filters: <kendo.data.DataSourceFilterItem[]>[
            //    {
            //        field: nameof<CoreEntities.Concept>(o => o.ConceptId),
            //        operator: "eq",
            //        value: 1
            //    }, {
            //        field: nameof<CoreEntities.Concept>(o => o.ConceptId),
            //        operator: "eq",
            //        value: 2
            //    }
            //]
        }
    }
}