import { Helpers } from "Utility/Helpers";
import { ApiConfig } from "AppConfig/ApiConfig";

let httpInterceptorConfig = [
    '$httpProvider',
    'apiConfig',
    ($httpProvider: ng.IHttpProvider, apiConfig: ApiConfig) =>
    {
        let interceptorFunc = () =>
        {
            let interceptor: ng.IHttpInterceptor = {
                "request": (config: ng.IRequestConfig) =>
                {
                    var isCoreApi = Helpers.StartsWith(config.url.toLowerCase(), apiConfig.AppSettings.CoreApiRootUrl.toLowerCase());
                    var isIdentityApi = Helpers.StartsWith(config.url.toLowerCase(), apiConfig.AppSettings.IdentityApiRootUrl.toLowerCase());
                    var isKnowledgeApi = Helpers.StartsWith(config.url.toLowerCase(), apiConfig.AppSettings.KnowledgeApiRootUrl.toLowerCase());
                    var isRoyaltyApi = Helpers.StartsWith(config.url.toLowerCase(), apiConfig.AppSettings.RoyaltyRootUrl.toLowerCase());
                    var isFranchiseConfigApi = Helpers.StartsWith(config.url.toLowerCase(), apiConfig.AppSettings.FranchiseConfigUrl.toLowerCase());
                    var isBillingApi = Helpers.StartsWith(config.url.toLowerCase(), apiConfig.AppSettings.BillingRootUrl.toLowerCase());
                    var isServiceManagerApi = Helpers.StartsWith(config.url.toLowerCase(), apiConfig.AppSettings.ServiceManagerUrl.toLowerCase());
                    var isNotificationGroupApi = Helpers.StartsWith(config.url.toLowerCase(), apiConfig.AppSettings.NotificationGroupBaseUrl.toLowerCase());
                    var isServiceOrchGroupApi  = Helpers.StartsWith(config.url.toLowerCase(), apiConfig.AppSettings.ServiceOrchestrationsApiRootUrl.toLowerCase());
                    var isFranchiseOrchestrationsApi = Helpers.StartsWith(config.url.toLowerCase(), apiConfig.AppSettings.FranchiseOrchestrationsRootUrl.toLowerCase());
                    const isCallCenterOrchestrationsApi = Helpers.StartsWith(config.url.toLowerCase(), ApiConfig.Default.AppSettings.CallCenterOrchestrationsBaseUrl.toLowerCase());

                    if ((isCoreApi || isIdentityApi || isKnowledgeApi || isRoyaltyApi || isFranchiseConfigApi || isBillingApi || isServiceManagerApi || isNotificationGroupApi || isServiceOrchGroupApi || isFranchiseOrchestrationsApi || isCallCenterOrchestrationsApi) && (<ILocalStorage>localStorage).FranForceAuthTokenType && (<ILocalStorage>localStorage).FranForceAuthToken) {
                        config.headers['Authorization'] = (<ILocalStorage>localStorage).FranForceAuthTokenType + " " + (<ILocalStorage>localStorage).FranForceAuthToken;
                    }
                    return config;
                }
            }
            return interceptor;
        };

        $httpProvider.interceptors.push(interceptorFunc);
    }
];

export { httpInterceptorConfig };
