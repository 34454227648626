import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import * as _ from "underscore"
import { RpmEntities, RpmResources } from "Interfaces/FranForce/Rpm/RpmResources";

interface RoyaltyReportBackedOutParams {
    status: RpmEntities.usp_RoyaltyPeriodReportStatus_GetBySetViaBatchManagement_Result,
    reason: string;
    selectedPeriodReports: RpmResources.IRoyaltyPeriodReport[] & { Selected?: boolean }[];
  }

export class BatchManagementResetPeriodReportsComponentController implements ng.IController 
{
    // -- Bindings --
    selectedPeriodReports: RpmResources.IRoyaltyPeriodReport[] & { Selected?: boolean }[];
    onSave: (params: {}) => void;
    isError: boolean;
    isDifferentPeriodReportStatus: boolean;
    selectedStatusForBackout?: number;
    onVoidReset: (params: {}) => void;
    setErrorDescription: (params: { errorDesc: string}) => void;
    onRoyaltyReportsStatusSelected: (params: {
        backout: RoyaltyReportBackedOutParams
    }) => void;
    // --

    backoutPeriodReportWindow: kendo.ui.Window;
    dropdown: kendo.ui.DropDownList;
    backoutStatusDropDownOptions: kendo.ui.DropDownListOptions;
    backout: {
        status: RpmEntities.usp_RoyaltyPeriodReportStatus_GetBySetViaBatchManagement_Result,
        reason: string
    };

    isLoading: boolean;

    static $inject = [
        'coreApiResources',
        '$q',
        '$log',
        '$timeout',
        'rpmUiApiResources',
        'rpmHelper',
        'odataHelper',
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private odataHelper: ODataHelperService,
    )
    {
    }

    $onInit()
    {
    }

    $postLink()
    {
        this.InitBackoutStatusDropdown()
    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
        if (this.selectedStatusForBackout) {
            if (this.dropdown) {
                this.dropdown.dataSource.read();
            }
        }
    }
    
    InitBackoutStatusDropdown()
    {
        let datasource = new kendo.data.DataSource({
            transport: {
                read: (options) =>
                {
                    this.rpmUiApiResources.GetRoyaltyPeriodReportStatusesForBatchManagement()
                        .then((statusesResponse) =>
                        {        
                            let filteredStatuses = statusesResponse.data.filter((status) =>
                            {
                                if (this.selectedStatusForBackout && this.selectedStatusForBackout == 6) {
                                    return status.RoyaltyPeriodReportStatusID == 9
                                }
                                else if (this.selectedStatusForBackout && this.selectedStatusForBackout == 3) {
                                    return status.RoyaltyPeriodReportStatusID == 4
                                }
                                else {
                                    return null
                                }
                                
                            });
                            options.success(filteredStatuses);
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        })
                }
            }
        })

        this.backoutStatusDropDownOptions = {
            autoWidth: true,
            optionLabel: "Select Status...",
            height: 450,
            dataSource: datasource,
            dataTextField: nameof<RpmEntities.usp_RoyaltyPeriodReportStatus_GetBySetViaBatchManagement_Result>(o => o.Name),
            dataValueField: nameof<RpmEntities.usp_RoyaltyPeriodReportStatus_GetBySetViaBatchManagement_Result>(o => o.RoyaltyPeriodReportStatusID),
        };
    }
    
    
    AreAllSelectedPeriodReportsSuccess()
    {
        return _.every(this.selectedPeriodReports, pr => {
            return (pr.RoyaltyPeriodReportStatusID == 3 || pr.RoyaltyPeriodReportStatusID == 6) ? true : false;
        });
    }

    BackoutPeriodReportsWindowOpen(periodReports: RpmResources.IRoyaltyPeriodReport[])
    {
        this.backout = {
            reason: "",
            status: null
        };

        this.backoutPeriodReportWindow.open();
        this.$timeout(() =>
        {
            this.backoutPeriodReportWindow.center();
        }, 0)
    }

    BackoutPeriodReports()
    {
        if (!this.backout.reason) {
            alert("Specify a reason for the backout");
            return this.$q.reject();
        }

        if (!this.backout.status || !this.backout.status.RoyaltyPeriodReportStatusID) {
            alert("Specify a status for the backout");
            return this.$q.reject();
        }

        this.onRoyaltyReportsStatusSelected(
            {backout: {...this.backout, selectedPeriodReports: this.selectedPeriodReports}});

        
            let promises: ng.IPromise<any>[] = [];

            if (this.backout.status.RoyaltyPeriodReportStatusID == 9) {
                if (this.onVoidReset) {
                    this.onVoidReset({});
                }
            } else {
                promises = this.selectedPeriodReports.map(periodReport =>
                    this.rpmUiApiResources.BackoutRoyaltyPeriodReports({
                        RoyaltyPeriodReportId: periodReport.RoyaltyPeriodReportId,
                        RoyaltyPeriodReportStatusID: this.backout.status.RoyaltyPeriodReportStatusID,
                        ErrorDescription: this.backout.reason,
                        RetryCount: null,
                        UserName: null
                    })
                );
            }
            
            return this.$q.all(promises)
                .then(() => {
                    this.setErrorDescription({ errorDesc: this.backout.reason });
                    this.backoutPeriodReportWindow.close();
                    if (this.onSave && this.backout.status.RoyaltyPeriodReportStatusID != 9) {
                        this.onSave({});
                    }
                });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('bmResetPeriodReports', {
            bindings: {
                [nameof<BatchManagementResetPeriodReportsComponentController>(o => o.selectedPeriodReports)]: "<",
                [nameof<BatchManagementResetPeriodReportsComponentController>(o => o.onSave)]: "&",
                [nameof<BatchManagementResetPeriodReportsComponentController>(o => o.isError)]: "<",
                [nameof<BatchManagementResetPeriodReportsComponentController>(o => o.isDifferentPeriodReportStatus)]: "<", 
                [nameof<BatchManagementResetPeriodReportsComponentController>(o => o.selectedStatusForBackout)]: "<",
                [nameof<BatchManagementResetPeriodReportsComponentController>(o => o.onVoidReset)]: "&",
                [nameof<BatchManagementResetPeriodReportsComponentController>(o => o.setErrorDescription)]: "&",
                [nameof<BatchManagementResetPeriodReportsComponentController>(o => o.onRoyaltyReportsStatusSelected)]: "&",
            },
            controller: BatchManagementResetPeriodReportsComponentController,
            templateUrl: "/Templates/BatchManagement/bmResetPeriodReports.html" 
        });
    }
}

