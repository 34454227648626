import { IdentityManager } from "Services/Resources/IdentityManager";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AngularUtil } from "Utility/AngularUtil";
import { userStateObj, EditUserStateService } from "Services/State/EditUserStateService";
import * as _ from "underscore"
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class EditUserDppEmployeeAccessComponentController implements ng.IController 
{
    managedUserId: string;
    refreshDate: Date;
    refresh: (params: { refreshedDate: Date }) => void;

    userState: userStateObj;
    isLoading: boolean;

    isViewable: boolean;
    isDppEmployee: boolean;

    static $inject = [
        'editUserState',
        'identityManager',
        'coreApiResources',
        'odataHelper',
        'apiConfig',
        '$timeout'
    ];

    constructor(
        private editUserState: EditUserStateService,
        private identityManager: IdentityManager,
        private coreApiResources: CoreApiResources,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig,
        private $timeout: ng.ITimeoutService,
    )
    {
    }

    $onInit()
    {

    }

    $postLink()
    {

    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        this.Load();
    }

    Load()
    {
        let promise = this.editUserState.Load(this.managedUserId)
            .then((result) =>
            {
                this.userState = result;
            })
            .then(() =>
            {
                let dppClaim = _.find(this.userState.selfUser.Claims,
                    c => c.Type === this.apiConfig.FranForceConstants.ClaimConstants.IsDppEmployeePermissionClaimType
                );

                this.isDppEmployee = dppClaim &&
                    (dppClaim.Value.toLowerCase() == "true" || dppClaim.Value == "1");

                let employedFranchiseIds = this.userState.managedUser.Claims
                    .filter(c => c.Type == this.apiConfig.FranForceConstants.ClaimConstants.EmployedFranchiseIdClaimType);

                let params: ODataQueryParameters = {
                    $select: nameof<CoreResources.IFranchise>(o => o.ConceptId),
                    $filter: this.odataHelper.CreateInFilterStatement(
                        nameof<CoreResources.IFranchise>(o => o.FranchiseId),
                        employedFranchiseIds
                    )
                }

                return this.coreApiResources.FranchiseApi.query(params).$promise
                    .then((franchiseConceptIds) =>
                    {
                        let isEmployedByRainbow = franchiseConceptIds
                            .some((fc) =>
                            {
                                //Rainbow / Rainbow Germany.
                                return fc.ConceptId == 11 || fc.ConceptId == 12
                            });

                        this.isViewable = (this.userState.managedUser ? true : false) &&
                            isEmployedByRainbow;
                    });
            });
    
        return AngularUtil.TrackLoadingPromise(promise, this);
    }

    ToggleDppEmployee()
    {
        let params = {
            Email: this.userState.managedUser.Email,
            IsDppEmployee: this.isDppEmployee
        };

        let changeFunction = this.isDppEmployee ?
            () => { return this.identityManager.RemoveDppEmployeePermission(params); } :
            () => { return this.identityManager.AddDppEmployeePermission(params); };

        return changeFunction()
            .then(() =>
            {
                this.RefreshUser();
                this.$timeout(() =>
                {
                    this.isDppEmployee = !this.isDppEmployee;
                }, 0);
            })
            .catch(AngularUtil.GetJsonAlertCatch());
    }


    RefreshUser()
    {
        this.editUserState.Clear(this.userState.managedUser.Id);
        if (this.refresh) {
            this.refresh({
                refreshedDate: new Date()
            });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("editUserDppEmployeeAccess", {
            bindings: {
                [nameof<EditUserDppEmployeeAccessComponentController>(o => o.managedUserId)]: "<",
                [nameof<EditUserDppEmployeeAccessComponentController>(o => o.refreshDate)]: "<",
                [nameof<EditUserDppEmployeeAccessComponentController>(o => o.refresh)]: "&"
            },
            controller: EditUserDppEmployeeAccessComponentController,
            templateUrl: "/Templates/Users/EditUser/EditUserDppEmployeeAccess.html"
        });
    }
}

