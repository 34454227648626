import { EditUserStateService, userStateObj } from "Services/State/EditUserStateService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { AngularUtil } from "Utility/AngularUtil";

export class EditUserChangePasswordComponentController implements ng.IController 
{
    managedUserId: string;
    refreshDate: Date;
    refresh: (params: { refreshedDate: Date }) => void;

    userState: userStateObj;
    isLoading: boolean;
    
    sendToSelf: boolean;
    setCustomPassword: boolean;
    passwordResetModel: IResetPasswordBindingModel;
   
    static $inject = [
        'editUserState',
        'identityManager'
    ];

    constructor(
        private editUserState: EditUserStateService,
        private identityManager: IdentityManager
    )
    {
    }

    $onInit()
    {
        this.passwordResetModel = {
            ConfirmPassword: "",
            NewPassword: ""
        };

        this.sendToSelf = false;
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        this.Load();
    }

    Load()
    {
        let promise = this.editUserState.Load(this.managedUserId)
            .then((result) =>
            {
                this.userState = result;
            });

        return AngularUtil.TrackLoadingPromise(promise, this);
    }

    ResetPassword()
    {
        if (confirm("Are you sure you want to reset this user's password?"))
        {
            return this.identityManager.ManagedResetPassword(
                this.managedUserId,
                !this.sendToSelf,
                (this.setCustomPassword ? this.passwordResetModel : null)
            )
                .then((data) =>
                {
                    this.passwordResetModel = {
                        NewPassword: "",
                        ConfirmPassword: ""
                    };
                    
                    this.RefreshUser();
                })
                .catch(AngularUtil.GetJsonAlertCatch());
        }
    }
    
    RefreshUser()
    {
        this.editUserState.Clear(this.userState.managedUser.Id);
        if (this.refresh) {
            this.refresh({
                refreshedDate: new Date()
            });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("editUserChangePassword", {
            bindings: {
                [nameof<EditUserChangePasswordComponentController>(o => o.managedUserId)]: "<",
                [nameof<EditUserChangePasswordComponentController>(o => o.refreshDate)]: "<",
                [nameof<EditUserChangePasswordComponentController>(o => o.refresh)]: "&"
            },
            controller: EditUserChangePasswordComponentController,
            templateUrl: "/Templates/Users/EditUser/EditUserChangePassword.html"
        });
    }
}



