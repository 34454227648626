

interface IIndexHomeScope {
    token: string;
}

export class IndexHomeController
{
    static $inject = ['$scope'];
    constructor($scope: IIndexHomeScope)  
    {
        $scope.token = (<ILocalStorage>localStorage).FranForceAuthToken;
    }
}