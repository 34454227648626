import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RestAxiosClient } from "Clients/RestAxiosClient";
import { IRestAxiosClient } from "Clients/IRestAxiosClient";

const rmsFranchiseEntityRelationshipClient = new RestAxiosClient<RpmEntities.FranchiseEntityRelationship, number>(
    FranForceAxiosClientBuilder.BuildCoreBaseClient(),
    "/RmsFranchiseEntityRelationships"
) as IRestAxiosClient<RpmEntities.FranchiseEntityRelationship, number>;

export { rmsFranchiseEntityRelationshipClient };