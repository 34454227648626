import * as _ from "underscore";
import {
    PhotoGalleryImageInfoDto,
    PhotoGalleryCategoryConceptXrefWithCategoryDescDto,
    PhotoGalleryImageInfoClient,
    CreatePhotoGalleryImageInfoDto,
    UpdatePhotoGalleryImageInfoDto
} from '@nbly/service-orchestrations-clients';
import { FranchiseWebLocationDto } from '@nbly/franchise-group-api-clients';
import * as CropperTypes from 'cropperjs';
import { AxiosInstance } from "axios";
import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { Helpers } from "Utility/Helpers"
import { getNaturalImageDimensions, photoGalleryImageDimensions } from "Utility/ImageDimensionUtils";

import Cropper from 'cropperjs/dist/cropper.esm';
const Compressor = require('compressorjs');

export class PhotoEditorComponent implements ng.IController {

    franchiseId: number;
    isLoading: boolean;
    conceptCode: string;
    conceptId: number;
    imageFile: any;
    thumbnailImageFile: any;
    photoGalleryCategoryConcepts: PhotoGalleryCategoryConceptXrefWithCategoryDescDto[];
    franchiseWebLocationDto: FranchiseWebLocationDto[];
    franchiseWebLocationId: number;
    photoByWeblocation: PhotoGalleryImageInfoDto;
    description: string;
    onSave: () => void;
    thumbnailImageUrl: string;

    imageFileName: string;
    thumbnailImageFileName: string;

    imageUrl: any;

    termsWindow: kendo.ui.Window;
    termsWindowOptions: kendo.ui.WindowOptions;
    CropperOption: CropperTypes.default.Options
    cropper: CropperTypes.default;
    thumbnailCropper: CropperTypes.default;
    thumbnailCropperOptions: CropperTypes.default.Options;

    scaleX: number = 1;
    scaleY: number = 1;
    inMainImageEditMode: boolean = false;
    inThumbnailImageEditMode: boolean = false;

    kendoUpload: kendo.ui.Upload;
    defaultKendoUploadOptions: kendo.ui.UploadOptions;
    allowedUploadExtensions: string[];
    Compressor: any;

    selectedFranchiesIds: number[];

    //Clients and DTO'S
    serviceOrchestrationsClient: AxiosInstance;
    photoGalleryImageInfoClient: PhotoGalleryImageInfoClient;
    photoGalleryInfoDto: CreatePhotoGalleryImageInfoDto;
    photoGalleryImageInfoDto: PhotoGalleryImageInfoDto;
    updatePhotoGalleryImageInfoDto: UpdatePhotoGalleryImageInfoDto;
    isFileUpload: boolean = false;

    undo_list: { imageURL: string, isNew: boolean }[] = [];
    undo_list_thumb: { thumbnailURL: string, generatedFromMain: boolean }[] = [];
    originalImage: any;
    originalThumbnailImage: any;
    isNew: boolean = false;


    static $inject = [
        '$timeout',
        "$scope",
    ];


    constructor(
        private $timeout: ng.ITimeoutService,
        private $scope: ng.IScope,

    ) {
        this.serviceOrchestrationsClient = FranForceAxiosClientBuilder.BuildServiceOrchestrationsBaseClient();
        this.photoGalleryImageInfoClient = new PhotoGalleryImageInfoClient("", this.serviceOrchestrationsClient);
        this.photoByWeblocation = {
            photoGalleryImageInfoId: 0,
            createdDateTime: null,
            updatedDateTime: null,
            deletedDateTime: null,
            categoryId: null,
            shortCaption: null,
            longCaption: null,
            altText: null,
            imageFileName: null,
            fullImageUrl: null,
            thumbNailImageUrl: null,
            legalDisclaimer: null,
            user: null,
            useForNationalWebsite: null,
            preSignedFullImageUrl: null,
            preSignedThumbNailImageUrl: null,
            imageExtension: null,
            thumbnailExtension: null,
            photoGalleryImageExtensions: null,
        }

    }

    async $onInit() {
        this.InitDummyImageInfoDTO();
        if (this.photoByWeblocation && this.photoByWeblocation.photoGalleryImageInfoId) {
            this.setExsistingImage();
            this.GenerateListOfWeblocationObject();
        } else {
            if (this.franchiseWebLocationId) {
                this.photoGalleryInfoDto.web_location_ids.push(this.franchiseWebLocationId);
            }

            this.setImage();
        }
        this.isLoading = false;

        this.InitUploadComponent();

    }

    InitDummyImageInfoDTO() {

        this.photoGalleryInfoDto = {
            category_id: 0,
            short_caption: '',
            long_caption: '',
            alt_text: '',
            legal_disclaimer: false,
            user: null,
            use_for_national_website: false,
            web_location_ids: [],
            full_image_base64: '',
            thumbnail_image_base64: '',
            image_extension: '',
            thumbnail_extension: ''
        }

        this.updatePhotoGalleryImageInfoDto = {
            photo_gallery_image_info_id: 0,
            category_id: null,
            short_caption: null,
            long_caption: null,
            alt_text: null,
            legal_disclaimer: null,
            user: null,
            use_for_national_website: null,
            web_location_ids: null,
            full_image_base64: null,
            thumbnail_image_base64: null,
            image_extension: null,
            thumbnail_extension: null,
        }
    }

    InitUploadComponent() {
        this.allowedUploadExtensions = [".png", ".webp", ".jpeg", ".jpg"];
        this.defaultKendoUploadOptions = {
            showFileList: true,
            multiple: false,
            async: {
                autoUpload: false,
            },
            validation: {
                allowedExtensions: this.allowedUploadExtensions
            },
            localization: {
                select: 'Select Photo',

            },
            select: this.onSelect
        };
    }

    onSelect = async (e) => {
        if (e.files[0].validationErrors?.length) {
            alert('Invalid File Type Selected')
        } else {

            const { width, height } = await getNaturalImageDimensions(e.files[0].rawFile);
            if (width <= photoGalleryImageDimensions.width || height <= photoGalleryImageDimensions.height) {
                this.kendoUpload.clearAllFiles();
                alert('Image dimensions should be greater than ' + photoGalleryImageDimensions.width + 'x' + photoGalleryImageDimensions.height + '. Please upload a new image.');
                return;
            } else {
                this.setUploadImage(e);
            }
        }
    }

    GenerateListOfWeblocationObject() {
        this.selectedFranchiesIds = [];
        if (this.photoByWeblocation && this.photoByWeblocation.photoGalleryImageExtensions.length) {
            this.photoByWeblocation.photoGalleryImageExtensions.map((imageExtension) => {
                this.selectedFranchiesIds.push(imageExtension.franchiseWebLocationId)
                this.photoGalleryInfoDto.web_location_ids.push(imageExtension.franchiseWebLocationId)
            });
        }

    }

    setExsistingImage() {
        this.photoGalleryInfoDto = {
            category_id: this.photoByWeblocation.categoryId,
            short_caption: this.photoByWeblocation.shortCaption,
            long_caption: this.photoByWeblocation.longCaption,
            alt_text: this.photoByWeblocation.altText,
            legal_disclaimer: this.photoByWeblocation.legalDisclaimer,
            user: this.photoByWeblocation.user,
            use_for_national_website: this.photoByWeblocation.useForNationalWebsite,
            web_location_ids: [],
            full_image_base64: this.photoByWeblocation.preSignedFullImageUrl,
            thumbnail_image_base64: this.photoByWeblocation.preSignedThumbNailImageUrl,
            image_extension: '',
            thumbnail_extension: ''
        };

        this.thumbnailImageUrl = this.photoByWeblocation.preSignedThumbNailImageUrl;
        this.imageUrl = this.photoByWeblocation.preSignedFullImageUrl;
        this.originalImage = angular.copy(this.imageUrl);
        this.originalThumbnailImage = angular.copy(this.thumbnailImageUrl);

        this.imageFileName = this.photoByWeblocation.imageFileName;
        this.thumbnailImageFileName = this.photoByWeblocation.thumbNailImageUrl;
    }

    async setImage() {
        if (this.imageFile) {
            await this.compress(this.imageFile).then(res => {
                this.imageFile = res;
                let reader = new FileReader();
                reader.onload = (event) => {
                    this.imageUrl = event.target.result;
                    [this.photoGalleryInfoDto.image_extension, this.photoGalleryInfoDto.full_image_base64] = this.GenerateBase64WithoutMetaInfo(this.imageUrl);
                    this.GenerateThumbnail(false);
                    this.$scope.$digest();
                };

                reader.readAsDataURL(this.imageFile);

                this.$scope.$digest();
            })
            /*  this.GenerateThumbnail(); */
        }
    }

    async FindOptimalQuality(file) {
        let result;
        for (let i = 10; i > 0; i--) {
            result = await new Promise((resolve, reject) => {
                new Compressor(file, {
                    quality: i / 10,
                    maxWidth: 1000,
                    convertSize: 500000,
                    success: resolve,
                    error: reject,
                });
            });

            if (result.size < 500000) {
                break;
            }
        }
        return result;
    }

    async compress(file) {
        try {
            let response = await this.FindOptimalQuality(file);
            return response;
        } catch (err) {

        }

    }


    async setUploadImage(e: kendo.ui.UploadSelectEvent) {
        const file = e.files[0].rawFile;
        this.isFileUpload = true;
        this.isNew = true;

        await this.compress(file).then(res => {
            this.imageFile = res;
            let reader = new FileReader();
            reader.onload = (event) => {
                this.undo_list_thumb = [];
                this.undo_list.push({ imageURL: this.imageUrl, isNew: true });
                this.imageUrl = event.target.result;

                [this.photoGalleryInfoDto.image_extension, this.photoGalleryInfoDto.full_image_base64] = this.GenerateBase64WithoutMetaInfo(this.imageUrl);

                if (this.inThumbnailImageEditMode) {
                    this.thumbnailCropper.destroy();
                    this.thumbnailCropper = null;
                    this.inThumbnailImageEditMode = false;
                }
                this.GenerateThumbnail(false);
                this.$scope.$digest();
            };
            reader.readAsDataURL(res);
            this.$scope.$digest();

        })

    }
    $onDestroy() {
        console.log('Inside on destroy')
    }


    openTermsPopup() {
        let template =
            `<div style="width:50vw;height:50vh;">
        <p></p>
        </div>`;

        let options: kendo.ui.WindowOptions = {
            modal: true,
            position: { top: 20 },

        };

        this.termsWindow.open();
        this.termsWindow.title('Terms and Conditions');
        this.termsWindow.content(template)
        this.termsWindow.center();
        this.termsWindow.setOptions(options);

    }



    //Move it to seprate component all togather
    RotateLeft() {
        this.cropper.rotate(-90);
        /*   this.cropper.zoomTo(0);  */


    }

    RotateRight() {
        this.cropper.rotate(90);
        /*    this.cropper.zoomTo(0);  */
    }

    flipX() {
        this.scaleX = -this.scaleX;
        this.cropper.scale(this.scaleX, this.scaleY);
    }

    flipY() {
        this.scaleY = -this.scaleY;
        this.cropper.scale(this.scaleX, this.scaleY);
    }


    async save() {
        try {
            let imageData = this.cropper.getImageData();
            const scale = imageData.naturalWidth / imageData.naturalWidth;
            const cropImageData = this.cropper.getData();
            const scaledData = {
                width: cropImageData.width / scale,
                height: cropImageData.height / scale,

            };

            if (scaledData.width <= photoGalleryImageDimensions.width || scaledData.height <= photoGalleryImageDimensions.height) {
                alert('Cropper Image dimensions should be greater than' + photoGalleryImageDimensions.width + 'x' + photoGalleryImageDimensions.height);
                return;
            }

            let imageType: string;
            if (!this.imageFile) {
                imageType = 'image/' + this.photoByWeblocation.imageExtension;

            } else {
                imageType = this.imageFile.type;
            }
            if (this.isNew) {
                this.undo_list.push({ imageURL: this.imageUrl, isNew: this.isNew });
            } else {

                this.undo_list.push({ imageURL: this.imageUrl, isNew: false });

            }

            this.cropper.getCroppedCanvas(
                { imageSmoothingEnabled: false, imageSmoothingQuality: "high" }
            ).toBlob(async (blob) => {
                this.imageFile = new File([blob], 'this.imageFile.name', { type: 'image/' + blob.type });
                this.$scope.$digest();
            }, imageType);


            this.imageUrl = this.cropper.getCroppedCanvas().toDataURL(imageType);
            this.undo_list_thumb.push({ thumbnailURL: this.thumbnailImageUrl, generatedFromMain: false });


            [this.photoGalleryInfoDto.image_extension, this.photoGalleryInfoDto.full_image_base64] = this.GenerateBase64WithoutMetaInfo(this.imageUrl);

            if (this.inThumbnailImageEditMode) {
                this.inThumbnailImageEditMode = false;
            }
            if (this.isNew) {
                this.GenerateThumbnail(false);
                this.isNew = false;
            } else {
                this.GenerateThumbnail(true);
            }

            this.cropper.destroy();
            this.inMainImageEditMode = false;

        } catch (err) {
            console.log('error occured while saving image', err);
        }

    }

    async saveThumbnail() {
        try {
            let imageType: string;
            if (!this.imageFile) {
                imageType = 'image/' + this.photoByWeblocation.thumbnailExtension;
            } else {
                imageType = this.imageFile.type;
            }

            if (this.undo_list_thumb.length) {

                let obj = this.undo_list_thumb[this.undo_list_thumb.length - 1];

                if (obj.generatedFromMain) {
                    this.undo_list_thumb.pop();

                } else {
                    this.undo_list_thumb.push({ thumbnailURL: this.thumbnailImageUrl, generatedFromMain: false });
                }
            } else {
                this.undo_list_thumb.push({ thumbnailURL: this.thumbnailImageUrl, generatedFromMain: false });
            }

            this.thumbnailCropper.getCroppedCanvas(
                { imageSmoothingEnabled: false, imageSmoothingQuality: "high" }
            ).toBlob(async (blob) => {
                this.thumbnailImageFile = new File([blob], 'this.imageFile.name', { type: 'image/' + blob.type });
                this.$scope.$digest();
            }, imageType);
            this.thumbnailImageUrl = this.thumbnailCropper.getCroppedCanvas().toDataURL(imageType);


            [this.photoGalleryInfoDto.thumbnail_extension, this.photoGalleryInfoDto.thumbnail_image_base64] = this.GenerateBase64WithoutMetaInfo(this.thumbnailImageUrl);
            if (this.isFileUpload) {
                this.isFileUpload = false;
            }
            this.thumbnailCropper.destroy();
            this.inThumbnailImageEditMode = false;


        } catch (err) {
            console.log('error occured while saving image', err);
        }
    }

    GenerateBase64WithoutMetaInfo(base64URL: string) {

        return base64URL.split(',');
    }

    GenerateThumbnail(generatedFromMainImage: boolean) {
        if (this.imageUrl) {
            if (Helpers.isUrl(this.imageUrl)) {
                this.thumbnailImageUrl = this.originalThumbnailImage;
            } else {
                this.thumbnailImageUrl = angular.copy(this.imageUrl);
                if (generatedFromMainImage) {
                    this.undo_list_thumb.push({ thumbnailURL: this.thumbnailImageUrl, generatedFromMain: generatedFromMainImage });
                }
            }

            this.$timeout(() => {
                this.InitThumbnailEditTool();
            });
        }
    }

    InitThumbnailEditTool() {

        this.inThumbnailImageEditMode = !this.inThumbnailImageEditMode;
        if (this.inThumbnailImageEditMode) {
            let contents = $('#thumbnailImageContainer')[0] as HTMLImageElement;
            this.thumbnailCropperOptions = {
                dragMode: 'move',
                scalable: true,
                rotatable: true,
                aspectRatio: 4 / 3,
                autoCropArea: 0.69,
                center: true,
                autoCrop: true,
                checkCrossOrigin: false,
                checkOrientation: false,
                viewMode: 2,
            /*     maxCanvasHeight: 500, */
                crop(event) {

                },
            }
            this.thumbnailCropper = new Cropper(contents, this.thumbnailCropperOptions);
        } else {

            if (this.undo_list_thumb.length) {

                let obj = this.undo_list_thumb[this.undo_list_thumb.length - 1];
                if (obj.generatedFromMain) {
                    this.undo_list_thumb.pop();
                    if (this.undo_list_thumb.length) {
                        this.thumbnailImageUrl = this.undo_list_thumb.pop().thumbnailURL;

                    }

                }
            }
            this.thumbnailCropper.destroy();
            this.thumbnailCropper = null;
        }


    }

    InitEditTool() {
        if (this.isNew) {
            this.isNew = false;
        }
        this.inMainImageEditMode = !this.inMainImageEditMode;
        if (this.inMainImageEditMode) {
            this.inThumbnailImageEditMode = false;
            if (this.thumbnailCropper) {
                this.inThumbnailImageEditMode = false;
                this.thumbnailCropper.destroy();
                this.thumbnailCropper = null;
            }

            let contents = $('#imageContainer')[0] as HTMLImageElement;
            this.CropperOption = {
                dragMode: 'move',
                checkCrossOrigin: false,
                checkOrientation: false,
                scalable: true,
                rotatable: true,
                center: true,
                autoCrop: true,
                autoCropArea: 0.69,
                viewMode: 2,
                /*  minCanvasWidth: 324,
                minCanvasHeight:243,
                minContainerHeight:324,
                minContainerWidth:243,
                 minCropBoxWidth     : 324,
                minCropBoxHeight    : 243,   */
                crop(event) {

                },
            }
            this.cropper = new Cropper(contents, this.CropperOption);
        } else {
            this.cropper.destroy();
            this.cropper = null;
        }
    }

    uploadNewImage() {

        $("#uploadNewImage").click();
    }
    isUrl(string) {
        var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        return regexp.test(string);
    }

    UndoThumb() {
        if (this.undo_list_thumb.length) {
            this.thumbnailImageUrl = this.undo_list_thumb.pop().thumbnailURL;
            if (!this.undo_list_thumb.length) {
                this.$timeout(() => {
                    this.InitThumbnailEditTool();
                });
            }

            if (!Helpers.isUrl(this.thumbnailImageUrl)) {
                [this.photoGalleryInfoDto.image_extension, this.photoGalleryInfoDto.thumbnail_image_base64] = this.GenerateBase64WithoutMetaInfo(this.thumbnailImageUrl);

            }
        }
    }

    Undo() {
        if (this.undo_list.length) {
            let imageUrlObject = this.undo_list.pop();
            this.imageUrl = imageUrlObject.imageURL;
            if (imageUrlObject.isNew) {
                this.isNew = true;
                this.undo_list_thumb = [];

                if (this.inThumbnailImageEditMode) {
                    this.inThumbnailImageEditMode = false;
                    this.thumbnailCropper.destroy();
                    this.thumbnailCropper = null;
                }
                this.GenerateThumbnail(false);
            } else {
                if (this.inThumbnailImageEditMode) {
                    this.inThumbnailImageEditMode = false;
                    this.thumbnailCropper.destroy();
                    this.thumbnailCropper = null;
                }
            }
            if (!Helpers.isUrl(this.imageUrl)) {
                [this.photoGalleryInfoDto.image_extension, this.photoGalleryInfoDto.full_image_base64] = this.GenerateBase64WithoutMetaInfo(this.imageUrl);

            }

        }
    }
    async masterSave() {

        this.photoGalleryInfoDto.thumbnail_extension = this.photoGalleryInfoDto.thumbnail_extension.replace('data:image/', '').replace(';base64', '');
        this.photoGalleryInfoDto.image_extension = this.photoGalleryInfoDto.image_extension.replace('data:image/', '').replace(';base64', '');
        this.photoGalleryInfoDto.category_id = parseInt(this.photoGalleryInfoDto.category_id.toString());

        //need to check if the id already exsists

        if (this.photoByWeblocation && this.photoByWeblocation.photoGalleryImageInfoId) {
            //If image has been editied, i.e it is in base 64 format and not in URL, we update the image as well
            if (!Helpers.isUrl(this.imageUrl)) {

                this.updatePhotoGalleryImageInfoDto.full_image_base64 = this.photoGalleryInfoDto.full_image_base64;
                this.updatePhotoGalleryImageInfoDto.image_extension = this.photoGalleryInfoDto.image_extension;
            }
            if (!Helpers.isUrl(this.thumbnailImageUrl)) {
                this.updatePhotoGalleryImageInfoDto.thumbnail_image_base64 = this.photoGalleryInfoDto.thumbnail_image_base64;
                this.updatePhotoGalleryImageInfoDto.thumbnail_extension = this.photoGalleryInfoDto.thumbnail_extension;
            }
            this.updatePhotoGalleryImageInfoDto.alt_text = this.photoGalleryInfoDto.alt_text;
            this.updatePhotoGalleryImageInfoDto.short_caption = this.photoGalleryInfoDto.short_caption;
            this.updatePhotoGalleryImageInfoDto.photo_gallery_image_info_id = this.photoByWeblocation.photoGalleryImageInfoId;
            this.updatePhotoGalleryImageInfoDto.web_location_ids = this.photoGalleryInfoDto.web_location_ids;
            this.updatePhotoGalleryImageInfoDto.category_id = this.photoGalleryInfoDto.category_id;
            this.updatePhotoGalleryImageInfoDto.legal_disclaimer = this.photoGalleryInfoDto.legal_disclaimer;
            this.updatePhotoGalleryImageInfoDto.long_caption = this.photoGalleryInfoDto.long_caption;
            this.updatePhotoGalleryImageInfoDto.use_for_national_website = this.photoGalleryInfoDto.use_for_national_website;

            await this.photoGalleryImageInfoClient.photoGalleryImageInfo2(this.updatePhotoGalleryImageInfoDto).then((res) => {

                this.photoGalleryImageInfoDto = res.result;
                this.imageUrl = this.photoGalleryImageInfoDto.preSignedFullImageUrl;
                this.thumbnailImageUrl = this.photoGalleryImageInfoDto.preSignedThumbNailImageUrl;

                this.imageFileName = this.photoGalleryImageInfoDto.imageFileName;
                this.thumbnailImageFileName = this.photoGalleryImageInfoDto.thumbNailImageUrl;
                this.onSave();
                this.$scope.$digest();
            }).catch((err) => {
                console.log('The error ', err)
            })

        } else {

            await this.photoGalleryImageInfoClient.photoGalleryImageInfo(this.photoGalleryInfoDto).then((res) => {
                this.photoByWeblocation = res.result;
                this.photoGalleryImageInfoDto = res.result;
                this.imageUrl = this.photoGalleryImageInfoDto.preSignedFullImageUrl;
                this.thumbnailImageUrl = this.photoGalleryImageInfoDto.preSignedThumbNailImageUrl;

                this.imageFileName = this.photoGalleryImageInfoDto.imageFileName;
                this.thumbnailImageFileName = this.photoGalleryImageInfoDto.thumbNailImageUrl;

                this.onSave();
                this.$scope.$digest();
            }).catch((Err) => {
                console.log('Error occured while saving the image', Err)
            });
        }


    }

    static BindComponent(app: ng.IModule) {
        app.component('photoEditorComponent', {
            bindings: {
                [nameof<PhotoEditorComponent>(o => o.imageFile)]: "<",
                conceptId: "<",
                photoGalleryCategoryConcepts: "<",
                onSave: "&",
                onBioCreate: "&",
                franchiseWebLocationDto: "<",
                photoByWeblocation: "=",
                franchiseWebLocationId: "<"

            },
            controller: PhotoEditorComponent,
            templateUrl: "/Templates/PhotoEditor/PhotoEditorComponent.html"
        });
    }
}


