declare var appCachebuster: string

let cacheBusterConfig = [
    "$httpProvider",
    ($httpProvider: ng.IHttpProvider) =>
    {
        let interceptorFunc = () =>
        {
            let interceptor: ng.IHttpInterceptor = {
                "request": (config: ng.IRequestConfig) =>
                {
                    let templateUrlRegex = /Templates\/(.*)\.html$/
                    if (templateUrlRegex.test(config.url))
                    {
                        config.url += "?c=" + appCachebuster;
                    }

                    return config;
                }
            }
            return interceptor;
        };

        $httpProvider.interceptors.push(interceptorFunc);
    }
];

export { cacheBusterConfig };