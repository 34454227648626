import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ZeeMailApiResources } from "Services/Resources/ZeeMailApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { ZeeMailResources, ZeeMailEntities } from "Interfaces/FranForce/ZeeMail/ZeeMailResources";

export class ZeeMailStatusChangeComponentController implements ng.IController
{
    zeeMailAccountId: number;
    zeeMailActionTypeId: number;
    onSave?: (params: {}) => void;
    onCancel?: (params: {}) => void;

    zeeMailAccount: ZeeMailResources.IZorWareZeeMailAccount;
    actionType: ZeeMailResources.IZorWareZeeMailActionType;

    title: string;
    instructionTemplateText: string;
    isLoading: boolean;

    comment: ZeeMailEntities.ZorWareZeeMailComment;

    static $inject = [
        'coreApiResources',
        'zeeMailApiResources',
        'identityManager',
        'apiConfig'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private zeeMailApiResources: ZeeMailApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig
    )
    {
    }

    $onInit()
    {
        
    }

    $postLink()
    {
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.zeeMailAccountId)] && this.zeeMailAccountId)
        {
            if (this.zeeMailAccount && this.zeeMailAccount.ZorWareZeeMailAccountID === this.zeeMailAccountId) {
                return;
            }

            this.zeeMailAccount = null;
            let accountParams = {
                id: this.zeeMailAccountId,
                $expand: `${nameof<ZeeMailResources.IZorWareZeeMailAccount>(o => o.Franchise)}($select=${nameof<ZeeMailResources.IZorWareZeeMailAccount>(o => o.Franchise.ConceptId)})`
            };

            this.isLoading = true;
            this.zeeMailApiResources.ZorWareZeeMailAccountApi.get(accountParams).$promise
                .then((account) =>
                {
                    this.zeeMailAccount = account;
                    this.comment = this.comment || <any>{};
                    this.comment.ZorWareZeeMailAccountID = this.zeeMailAccountId;
                    this.SetTemplate();
                })
                .finally(() => { this.isLoading = false; });
        }

        if (changes[nameof(this.zeeMailActionTypeId)] && this.zeeMailActionTypeId)
        {
            this.title = "";

            this.isLoading = true;
            this.zeeMailApiResources.ZorWareZeeMailActionTypeApi.get({id: this.zeeMailActionTypeId }).$promise
                .then((actionType) =>
                {
                    this.comment = this.comment || <any>{};
                    this.comment.ZorWareZeeMailActionTypeID = this.zeeMailActionTypeId;
                    this.actionType = actionType;
                    this.title = this.actionType.TypeName.replace(/([A-Z])/g, ' $1').trim();
                    this.SetTemplate();
                })
                .finally(() => { this.isLoading = false; });
        }
    }

    SetTemplate()
    {
        if (this.actionType && this.zeeMailAccount)
        {
            this.isLoading = true;
            this.zeeMailApiResources.ZorWareZeeMailTemplateApi.query({
                $filter: `${nameof<ZeeMailEntities.ZorWareZeeMailTemplate>(o => o.ConceptId)} eq ${this.zeeMailAccount.Franchise.ConceptId}`
            }).$promise
                .then((templates) =>
                {
                    if (templates.length) {
                        this.instructionTemplateText = templates[0][this.actionType.TypeName];
                    }
                    else {
                        this.instructionTemplateText = this.title;
                    }
                })
                .finally(() => { this.isLoading = false; });
        }
    }

    ResetForm()
    {
        this.comment.Comments = "";
        this.comment.EnteredName = "";
    }

    Save()
    {
        return this.zeeMailApiResources.ZeeMailAccountStatusChange(this.zeeMailAccountId, this.comment).then(() =>
        {
            if (this.onSave)
            {
                this.ResetForm();
                this.onSave({});
            }
        });
    }

    Cancel()
    {
        this.ResetForm();
        if (this.onCancel) {
            this.onCancel({});
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("zeeMailStatusChange", {
            bindings: {
                zeeMailAccountId: "<",
                zeeMailActionTypeId: "<",
                onSave: "&",
                onCancel: "&"
            },
            controller: ZeeMailStatusChangeComponentController,
            templateUrl: "/Templates/ZeeMail/ZeeMailStatusChange.html"
        });
    }
}


