import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { DppResourceApi } from "Interfaces/FranForce/Dpp/DppResourceApiInterfaces";
import { DppResources, DppEntities } from "Interfaces/FranForce/Dpp/DppResources";
import { IDppAndSummary } from "Interfaces/FranForce/Portal/IDppAndSummary";

export class DppApiResources
{
    DisasterPrepProfileApi: DppResourceApi.IDisasterPrepProfileApi;
    DisasterPrepProfileBuildingAssetApi: DppResourceApi.IDisasterPrepProfileBuildingAssetApi;
    DisasterPrepProfileBuildingAssetCategoryApi: DppResourceApi.IDisasterPrepProfileBuildingAssetCategoryApi;
    DisasterPrepProfileBuildingFloorApi: DppResourceApi.IDisasterPrepProfileBuildingFloorApi;
    DisasterPrepProfileBuildingApi: DppResourceApi.IDisasterPrepProfileBuildingApi;
    DisasterPrepProfileBuildingContactGroupApi: DppResourceApi.IDisasterPrepProfileBuildingContactGroupApi;
    DisasterPrepProfileBuildingHazardousMaterialApi: DppResourceApi.IDisasterPrepProfileBuildingHazardousMaterialApi;
    DisasterPrepProfileBuildingInsuranceApi: DppResourceApi.IDisasterPrepProfileBuildingInsuranceApi;
    DisasterPrepProfileBuildingInformationTechnologyApi: DppResourceApi.IDisasterPrepProfileBuildingInformationTechnologyApi;
    DisasterPrepProfileBuildingUtilityApi: DppResourceApi.IDisasterPrepProfileBuildingUtilityApi;
    DisasterPrepProfileBuildingUtilityAssetApi: DppResourceApi.IDisasterPrepProfileBuildingUtilityAssetApi
    DisasterPrepProfileCategorizedBuildingAssetApi: DppResourceApi.IDisasterPrepProfileCategorizedBuildingAssetApi;
    DisasterPrepProfileContactApi: DppResourceApi.IDisasterPrepProfileContactApi;
    DisasterPrepProfileServiceProviderApi: DppResourceApi.IDisasterPrepProfileServiceProviderApi;
    DisasterPrepProfileServiceProviderTypeApi: DppResourceApi.IDisasterPrepProfileServiceProviderTypeApi;
    DisasterPrepProfileExternalProviderApi: DppResourceApi.IDisasterPrepProfileExternalProviderApi;
    DisasterPrepProfileUtilityTypeApi: DppResourceApi.IDisasterPrepProfileUtilityTypeApi;

    DppAssetSelectParametersWithoutFileContent: string[];

    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {
        this.RefreshResources()
    }

    RefreshResources()
    {
        this.DisasterPrepProfileApi = this.$resource<DppResources.IDisasterPrepProfile>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfiles/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileBuildingAssetApi = this.$resource<DppResources.IDisasterPrepProfileBuildingAsset>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileBuildingAssets/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileBuildingAssetCategoryApi = this.$resource<DppResources.IDisasterPrepProfileBuildingAssetCategory>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileBuildingAssetCategories/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileBuildingFloorApi = this.$resource<DppResources.IDisasterPrepProfileBuildingFloor>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileBuildingFloors/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileBuildingApi = this.$resource<DppResources.IDisasterPrepProfileBuilding>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileBuildings/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileContactApi = this.$resource<DppResources.IDisasterPrepProfileContact>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileContacts/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileBuildingContactGroupApi = this.$resource<DppResources.IDisasterPrepProfileBuildingContactGroup>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileBuildingContactGroups/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileBuildingHazardousMaterialApi = this.$resource<DppResources.IDisasterPrepProfileBuildingHazardousMaterial>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileBuildingHazardousMaterials/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileBuildingInsuranceApi = this.$resource<DppResources.IDisasterPrepProfileBuildingInsurance>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileBuildingInsurances/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileBuildingInformationTechnologyApi = this.$resource<DppResources.IDisasterPrepProfileBuildingInformationTechnology>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileBuildingInformationTechnologies/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileBuildingUtilityApi = this.$resource<DppResources.IDisasterPrepProfileBuildingUtility>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileBuildingUtilities/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileBuildingUtilityAssetApi = this.$resource<DppResources.IDisasterPrepProfileBuildingUtilityAsset>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileBuildingUtilityAssets/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileCategorizedBuildingAssetApi = this.$resource<DppResources.IDisasterPrepProfileCategorizedBuildingAsset>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileCategorizedBuildingAssets/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileServiceProviderApi = this.$resource<DppResources.IDisasterPrepProfileServiceProvider>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileServiceProviders/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileServiceProviderTypeApi = this.$resource<DppResources.IDisasterPrepProfileServiceProviderType>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileServiceProviderTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileExternalProviderApi = this.$resource<DppResources.IDisasterPrepProfileExternalProvider>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileExternalProviders/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.DisasterPrepProfileUtilityTypeApi = this.$resource<DppResources.IDisasterPrepProfileUtilityType>(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfileUtilityTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.DppAssetSelectParametersWithoutFileContent = ["DppBuildingAssetId", "DppId", "DppBuildingId", "CreatedDateTime", "ModifiedDateTime", "FileName", "FileExtension", "FileDescription"];
    }

    GetDppPdfUrl(dppId: number): ng.IPromise<string> {
        return this.$http.get(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfiles/" + dppId + "/PdfUrl").then((response: ng.IHttpPromiseCallbackArg<string>) => {
            return response.data + "&access_token=" + (<ILocalStorage>localStorage).FranForceAuthToken;
        });
    }

    EmailPdfs(dppId: number | string, emails: string[]): ng.IHttpPromise<void> {
        let url = this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfiles/" + dppId + "/EmailPdf";
        return this.$http.post<void>(url, emails);
    }

    GetDppAndSummary(dppId: number | string): ng.IPromise<IDppAndSummary> {
        var dppAndSummary: IDppAndSummary = {
            dpp: null,
            summary: null
        }

        return this.GetFullProfileQueryObject(dppId)
            .then((queryObject: Object) => {
                return this.DisasterPrepProfileApi.get(queryObject).$promise;
            },
            (err) => { console.error(err); })
            .then((dpp: DppResources.IDisasterPrepProfile) => {
                dppAndSummary.dpp = dpp;
                return this.$http.post(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfiles/CompletenessSummary", dpp);
            },
            (err) => { console.error(err); })
            .then((response: ng.IHttpPromiseCallbackArg<DppEntities.DppCompletenessSummary>) => {
                dppAndSummary.summary = response.data;
                return dppAndSummary;
            });
    }

    GetFullProfileQueryObject(dppId: number | string): ng.IPromise<object> {
        return this.$http.get(this.apiConfig.AppSettings.CoreApiRootUrl + "/DisasterPrepProfiles/FullProfileODataExpand").then(
            (expandQuery: ng.IHttpPromiseCallbackArg<string>) => {
                return { id: dppId, $expand: expandQuery.data };
            });
    }
}