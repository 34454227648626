import * as elements from 'typed-html';
import { StringProperties } from "Types/StringProperties";
import { ExpressionBinding } from 'Utility/AngularUtil';
import * as _  from 'underscore';

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            monthsEnabled: Partial<StringProperties<MonthsEnabledController>>;
        }
    }
}

export type Month =
{
    readonly id: number,
    readonly name: string;
};

export const months: Month[] = [
    { name: "January", id: 1 },
    { name: "February", id: 2 },
    { name: "March", id: 3 },
    { name: "April", id: 4 },
    { name: "May", id: 5 },
    { name: "June", id: 6 },
    { name: "July", id: 7 },
    { name: "August", id: 8 },
    { name: "September", id: 9 },
    { name: "October", id: 10 },
    { name: "November", id: 11 },
    { name: "December", id: 12 }
];

export interface MonthEnabled
{
    month: Month;
    enabled: boolean;
}

export type monthInit = (month: Month) => boolean;
export type MonthsEnabledControllerOnInit = { self: MonthsEnabledController };
export type MonthsEnabledControllerOnSelect = { changed: MonthEnabled, all: MonthEnabled[] };

export class MonthsEnabledController implements ng.IController
{
    onInit: ExpressionBinding<MonthsEnabledControllerOnInit>;
    onSelect: ExpressionBinding<MonthsEnabledControllerOnSelect>;

    private monthsEnabled: MonthEnabled[];
    private dirty: boolean; 

    static $inject = [
        "$timeout"
    ];

    constructor(
        private $timeout: ng.ITimeoutService
    )
    {

    }

    $onInit()
    {
        this.dirty = false;
        if (this.onInit)
        {
            this.onInit({ self: this });
        }
    }

    InitAllMonths(enabled: boolean | monthInit)
    {
        this.dirty = false;
        this.$timeout(() =>
        {
            this.monthsEnabled = months.slice()
                .map(month =>
                {
                    return {
                        month: month,
                        enabled: (typeof enabled === "function" ? enabled(month) : enabled)
                    } as MonthEnabled;
                });  

            this.onSelect({
                all: this.monthsEnabled,
                changed: null
            });
        })
    }

    Init(months: MonthEnabled[], dirty: boolean = false)
    {
        this.dirty = dirty;
        this.$timeout(() =>
        {
            this.monthsEnabled = _.uniq(months, m => m.month.id);

            this.onSelect({
                all: this.monthsEnabled,
                changed: null
            });
        });
    }

    Toggle(id: number)
    {
        let month = this.monthsEnabled.find(m => m.month.id == id);
        if (!month)
            throw "month doesn't exist.";

        month.enabled = !month.enabled;
        this.dirty = true;
        if (this.onSelect)
        {
            this.onSelect({
                all: this.monthsEnabled,
                changed: month
            });
        }
    }

    GetMonths()
    {
        return this.monthsEnabled;
    }

    HasChanges = () => this.dirty;

    private ToggleMonth(month: MonthEnabled)
    {
        month.enabled = !month.enabled;
        if (this.onSelect)
        {
            this.onSelect({
                all: this.monthsEnabled,
                changed: month
            });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.monthsEnabled);

        let monthEnabled = "monthEnabled"; 

        app.component(componentName, {
            bindings: {
                [nameof<MonthsEnabledController>(o => o.onInit)]: "&",
                [nameof<MonthsEnabledController>(o => o.onSelect)]: "&",
            },
            controller: MonthsEnabledController,
            template: (
                
                <toggleCheckbox
                    ng-repeat={`${monthEnabled} in $ctrl.${nameof<MonthsEnabledController>(o => o.monthsEnabled)}`}
                    is-checked={`${monthEnabled}.${nameof<MonthEnabled>(o => o.enabled)}`}
                    on-check={`$ctrl.${nameof<MonthsEnabledController>(o => o.ToggleMonth)}(${monthEnabled})`}>
                    <toggleLabel>
                        <span ng-bind={`${monthEnabled}.${nameof.full<MonthEnabled>(o => o.month.name)}`}></span>
                    </toggleLabel>
                </toggleCheckbox>
                //<div ng-repeat={`${monthEnabled} in $ctrl.${nameof<MonthsEnabledController>(o => o.monthsEnabled)}`}>
                //    {`{{monthEnabled}}`}
                //</div>  
            ),
        });
    }
}

