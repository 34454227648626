import * as elements from 'typed-html';
import rmsContractExcludedServicesClient from 'Clients/Rms/RmsContractExcludedServicesClient';
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import { ODataHelperService } from 'Services/Utility/ODataHelperService';
import { PromiseUtil } from 'Utility/PromiseUtil';
import { StringProperties } from "Types/StringProperties";
import { KendoUtil } from 'Utility/KendoUtil';
import { RpmApiResources } from 'Services/Resources/RpmApiResources';

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            cmExcludedServices: Partial<StringProperties<CmExcludedServicesComponent>>;
        }
    }
}

export class CmExcludedServicesComponent implements ng.IController
{
    contractId: number;
    init: (params: { self: CmExcludedServicesComponent }) => void;

    isLoading: boolean = false;

    newExcludeName: string;
    excludedServices: RpmEntities.RoyaltyContractExcludedService[];
    excludedServicesDataSource: kendo.data.DataSource;

    static $inject = [
        "odataHelper",
        "$timeout",
        "$q",
        'rpmApiResources',
    ];

    constructor(
        public odataHelper: ODataHelperService,
        public $timeout: ng.ITimeoutService,
        public $q: ng.IQService,
        private rpmApiResources: RpmApiResources
    )
    {

    }

    $onInit()
    {
        if (this.init)
        {
            this.init({ self: this });
        }
    }

    $postLink()
    {

    }

    async $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.contractId)] && this.contractId)
        {
            this.InitNewExclude();
            this.InitDataSource();
            await PromiseUtil.TrackLoadingPromise(this, this.excludedServicesDataSource.read());
        }
    }

    InitDataSource()
    {
        this.excludedServicesDataSource = new kendo.data.DataSource(
            {
                schema: {
                    model: {
                        id: nameof<RpmEntities.RoyaltyContractExcludedService>(o => o.RoyaltyContractExcludedServiceId),
                        fields: {
                            [nameof<RpmEntities.RoyaltyContractExcludedService>(o => o.RoyaltyContractExcludedServiceId)]: { type: "number" },
                            [nameof<RpmEntities.RoyaltyContractExcludedService>(o => o.RoyaltyContractId)]: { type: "number" },
                            [nameof<RpmEntities.RoyaltyContractExcludedService>(o => o.Name)]: { type: "string" },
                        } as kendo.data.DataSourceSchemaModelFields
                    } as kendo.data.DataSourceSchemaModel
                } as kendo.data.DataSourceSchema,
                transport: {
                    read: KendoUtil.AsyncDataSourceTransportTryWrap(async () =>
                    {
                        let results = await rmsContractExcludedServicesClient.Query({
                            $filter: `${nameof<RpmEntities.RoyaltyContractExcludedService>(o => o.RoyaltyContractId)} eq ${this.contractId}`
                        });
                        this.$timeout(() =>
                        {
                            this.excludedServices = results;
                        });
                        return results;
                    }),
                    create: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) => {
                        let royaltyContractExcludedServicesModel = options.data as RpmEntities.RoyaltyContractExcludedService;
                        return (await this.rpmApiResources.InsertRoyaltyContractExcludedServices(royaltyContractExcludedServicesModel))
                        //return (await rmsContractExcludedServicesClient.Post(createModel));
                    }),
                    destroy: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                    {
                        return rmsContractExcludedServicesClient.Delete(options.data[nameof<RpmEntities.RoyaltyContractExcludedService>(o => o.RoyaltyContractExcludedServiceId)], {allowHardDelete: true})
                    }),
                    update: (options) => options.error("Not supported"),
                },
                change: (e) =>
                {
                    this.$timeout(() =>
                    {
                        this.excludedServices = (this.excludedServicesDataSource.data() as kendo.data.ObservableArray).slice(0);
                    });
                },
            } as kendo.data.DataSourceOptions
        );
    }

    InitNewExclude()
    {
        this.newExcludeName = "";
    }

    async AddNewExclude(name: string)
    {
        this.excludedServicesDataSource.add({
            RoyaltyContractExcludedServiceId: 0,
            RoyaltyContractId: this.contractId,
            Name: name,
            CreatedDateTime: new Date().toUTCString(),
            DeletedDateTime: null,
            RoyaltyContract: null,
        } as RpmEntities.RoyaltyContractExcludedService);
        this.InitNewExclude();
    }

    HasChanges()
    {
        if (!this.excludedServicesDataSource)
            return false;

        return this.excludedServicesDataSource.hasChanges();
    }

    SaveChanges = async () =>
    {
        return this.$timeout(() =>
            {
            return PromiseUtil.TrackLoadingPromise(this,
                    this.$q.when(this.excludedServicesDataSource.sync())
                        .then(() =>
                        {
                            return this.excludedServicesDataSource.read();
                        }))
            }, 0);
    }

    static BindComponent(app: ng.IModule)
    {
        const componentName = nameof<JSX.IntrinsicElements>(o => o.cmExcludedServices);
        const serviceVar = "service";

        const loadingTemplate =
            <loadingGearsOverlay isLoading={`$ctrl.${nameof<CmExcludedServicesComponent>(o => o.isLoading)}`}></loadingGearsOverlay>;

        const itemsTemplate = (
            <div class='bordered-item-list'>
                <div ng-repeat={`${serviceVar} in $ctrl.${nameof<CmExcludedServicesComponent>(o => o.excludedServices)}`}
                    class="item" >
                    <span ng-bind={`${serviceVar}.${nameof<RpmEntities.RoyaltyContractExcludedService>(o => o.Name)}`}></span>
                    <input
                        type="button"
                        class='pure-button button-error'
                        ng-click={`$ctrl.${nameof.full<CmExcludedServicesComponent>(o => o.excludedServicesDataSource.remove)}(${serviceVar})`}
                        value="X"
                    />
                </div>
                <div class='flex-break'></div> 
                <div class='create-item item'>
                    <input type="text"
                        ng-model={`$ctrl.${nameof.full<CmExcludedServicesComponent>(o => o.newExcludeName)}`} />
                    <button
                        type="button"
                        class='pure-button'
                        ng-click={`$ctrl.${nameof<CmExcludedServicesComponent>(o => o.AddNewExclude)}($ctrl.${nameof.full<CmExcludedServicesComponent>(o => o.newExcludeName)})`}>
                        Add
                    </button>
                </div>
            </div>
        );

        const template = `${loadingTemplate} ${itemsTemplate}`;

        app.component(componentName, {
            bindings: {
                [nameof<CmExcludedServicesComponent>(o => o.contractId)]: "<",
                [nameof<CmExcludedServicesComponent>(o => o.init)]: "&",
            },
            template: template,
            controller: CmExcludedServicesComponent
        });
    }
}
