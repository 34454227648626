import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";


interface INewFranchiseProfileScope {
    concepts: CoreResources.IConcept[];
    selectedConceptId: string | null;

    franchisesInConcept: CoreResources.IFranchise[];
    selectedFranchiseId: number;

    SetFranchisesInConcept: Function;
    CreateProfile: Function;
}

export class NewFranchiseProfileController
{
    static $inject = [
        '$scope',
        'coreApiResources',
        '$window',
    ];

    constructor(
        private $scope: INewFranchiseProfileScope,
        private coreApiResources: CoreApiResources,
        private $window: ng.IWindowService
    )
    {
        $scope.selectedConceptId = null;
        $scope.concepts = coreApiResources.ConceptApi.query({ $select: "ConceptId,ConceptCode,DisplayName" });

        $scope.SetFranchisesInConcept = () =>
        {
            $scope.franchisesInConcept = coreApiResources.FranchiseApi
                .query({ $filter: `ConceptId eq ${$scope.selectedConceptId} and FranchiseProfile eq null`, $expand: "FranchiseProfile" });
        }

        $scope.CreateProfile = () =>
        {
            var foundFranchiseProfiles = coreApiResources.FranchiseProfileApi.query(
                { $filter: `FranchiseId eq ${$scope.selectedFranchiseId}` }, () =>
                {
                    if (foundFranchiseProfiles.length > 0) {
                        $window.location.href = `/FranchiseProfiles/Edit/${foundFranchiseProfiles[0].FranchiseId}`;
                    }
                    else {
                        var newFranchiseProfile = new coreApiResources.FranchiseProfileApi();
                        newFranchiseProfile.FranchiseId = $scope.selectedFranchiseId;
                        newFranchiseProfile.$save(() =>
                        {
                            $window.location.href = `/FranchiseProfiles/Edit/${newFranchiseProfile.FranchiseId}`;
                        }, () =>
                            {
                                alert("Error occured during save.");
                            });
                    }
                });
        }
    }
}
