import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { royaltySystemDataModel } from "../../../Models/Royalty/RoyaltySystemType/RoyaltySystemDataModel";
import { StringProperties } from "../../../Types/StringProperties";
import { ExpressionBinding } from "../../../Utility/AngularUtil";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            rmsRoyaltySystemSelector: StringProperties<Partial<RmsRoyaltySystemSelectorComponentController>>;
        }
    }
}

export type RmsRoyaltySystemSelectorOnSelectParams = {
    event: kendo.ui.DropDownListSelectEvent,
    royaltySystem: royaltySystemDataModel;
} 

export class RmsRoyaltySystemSelectorComponentController implements ng.IController {

    dropDown: kendo.ui.DropDownList;
    readOnly: boolean;
    royaltySystemTypeId?: number;
    resetSelect: boolean;
    onSelect: ExpressionBinding<RmsRoyaltySystemSelectorOnSelectParams>;

    static $inject = [
        'royaltyApiResources',
    ]

    constructor(
        private royaltyApiResources: RoyaltyApiResources
    ) {

    }

    $onInit() {
        //to be removed
        this.InitDropdown();
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.setOptions({ enable: !this.readOnly })
        }

        if (this.resetSelect != null && this.dropDown) {
            this.dropDown.select(0);
        }
    }

    $postLink() {
        this.InitDropdown();
    }


    InitDropdown() {
        if (this.dropDown) {
            this.dropDown.setOptions(
                {
                    autoBind: true,
                    valuePrimitive: true,
                    dataValueField: nameof<royaltySystemDataModel>(o => o.royaltySystemId),
                    dataTextField: nameof<royaltySystemDataModel>(o => o.description),
                    optionLabel: "Select...",
                    optionLabelTemplate: "Select...",
                    autoWidth: true,
                    enable: !this.readOnly,
                    dataBound: function () {
                        var grid = this;
                        setTimeout(function () {
                            grid.resize();
                        });
                    },
                    select: (e) => {
                        if (this.onSelect) {
                            this.onSelect({
                                event: e,
                                royaltySystem: e.dataItem
                            });
                        }
                    }
                } as kendo.ui.DropDownListOptions
            );

            let dataSource = new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        this.royaltyApiResources.GetRoyaltySystemTypes(true)
                            .then((royaltySystemTypesResponse) => {
                                let nullObj: royaltySystemDataModel = {
                                    royaltySystemId: 0,
                                    name: 'NULL',
                                    description: 'NULL',
                                    externalId: '',
                                    source: '',
                                    createdDateTime: '',
                                    deletedDateTime: ''
                                }
                                var royaltySystemTypes: royaltySystemDataModel[] = royaltySystemTypesResponse.data;
                                royaltySystemTypes.push(nullObj);
                                options.success(royaltySystemTypes);
                            })
                            .catch((err) => {
                                options.error(err);
                            });
                    }
                }
            });

            this.dropDown.setDataSource(dataSource);
        }
    }

    static BindComponent(app: ng.IModule) {
        app
            .component("rmsRoyaltySystemSelector", {
                bindings: {
                    [nameof<RmsRoyaltySystemSelectorComponentController>(o => o.royaltySystemTypeId)]: "<",
                    [nameof<RmsRoyaltySystemSelectorComponentController>(o => o.readOnly)]: "<",
                    [nameof<RmsRoyaltySystemSelectorComponentController>(o => o.onSelect)]: "&?",
                    [nameof<RmsRoyaltySystemSelectorComponentController>(o => o.resetSelect)]: "<",
                },
                template: `<select kendo-drop-down-list="$ctrl.${nameof<RmsRoyaltySystemSelectorComponentController>(o => o.dropDown)}"
                                    ng-model="$ctrl.${nameof<RmsRoyaltySystemSelectorComponentController>(o => o.royaltySystemTypeId)}"
                                    ng-readonly="$ctrl.${nameof<RmsRoyaltySystemSelectorComponentController>(o => o.readOnly)}">
                        </select>`,
                controller: RmsRoyaltySystemSelectorComponentController
            });
    }
}