import * as elements from 'typed-html';
import * as rmsClients from 'Clients/Rms/index';
import { StringProperties } from "Types/StringProperties";
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import { KendoUtil } from 'Utility/KendoUtil';
import { ODataHelperService } from 'Services/Utility/ODataHelperService';
import { RpmUiApiResources } from 'Services/Resources/RpmUiApiResources';
import { ExpressionBinding, AngularUtil } from 'Utility/AngularUtil';

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            fddRoyaltiesRatePlanMinimumSelector: Partial<StringProperties<fddRoyaltiesRatePlanMinimumSelector>>;
        }
    }
}


export type fddRoyaltiesRatePlanMinimumSelectorOnInit = { self: fddRoyaltiesRatePlanMinimumSelector };
export type fddRoyaltiesRatePlanMinimumSelectorOnSelect = {
    entity: kendo.data.ObservableObject & RoyaltyDisclosureDocumentRatePlanMinimumDropDownItem;
    event: kendo.ui.DropDownListSelectEvent;
};

export interface RoyaltyDisclosureDocumentRatePlanMinimumDropDownItem {
    RoyaltyDisclosureDocumentRatePlanMinimumId: number,
    Display: string
}

export class fddRoyaltiesRatePlanMinimumSelector implements ng.IController 
{
    onInit: ExpressionBinding<fddRoyaltiesRatePlanMinimumSelectorOnInit>;
    onSelect: ExpressionBinding<fddRoyaltiesRatePlanMinimumSelectorOnSelect>;

    isLoading: boolean = false;
    private disclosureDocumentRatePlanId: number
    private disclosureDocumentRatePlanMinimumId: number;
    private dropDown: kendo.ui.DropDownList;
    private ratePlanMinClient = rmsClients.royaltyDisclosureDocumentRatePlanMinimumClient;

    static $inject = [
        "odataHelper",
        "rpmUiApiResources"
    ];

    constructor(
        private odataHelper: ODataHelperService,
        private rpmUiApiResources: RpmUiApiResources,
    )
    {

    }

    $onInit()
    {

    }

    $postLink()
    {
        this.InitDropDown();
        if (this.onInit)
        {
            this.onInit({ self: this });
        }
    }

    $onChanges(changes: ng.IOnChangesObject)
    {

    }

    private async InitDropDown()
    {
        let options: kendo.ui.DropDownListOptions = {
            autoBind: false,
            valuePrimitive: true,
            optionLabel: "Select Rate Plan...",
            optionLabelTemplate: "Select Rate Plan...",
            dataTextField: nameof<RoyaltyDisclosureDocumentRatePlanMinimumDropDownItem>(o => o.Display),
            dataValueField: nameof<RoyaltyDisclosureDocumentRatePlanMinimumDropDownItem>(o => o.RoyaltyDisclosureDocumentRatePlanMinimumId),
            select: (e) =>
            {
                if (this.onSelect)
                    this.onSelect({ entity: e.dataItem, event: e});
            }
        };
        this.dropDown.setOptions(options);
    }

    async LoadDropDownData(disclosureDocumentRatePlanId: number)
    {
        return AngularUtil.TrackLoadingPromise((async () =>
        {
            let disclosureDocumentMinimums = await this.ratePlanMinClient.Query({
                $filter: `${nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum>(o => o.DisclosureDocumentRatePlanId)} eq ${disclosureDocumentRatePlanId}`
            });
            let dropDownItems = disclosureDocumentMinimums.map(ratePlanMin =>
            {
                let item: RoyaltyDisclosureDocumentRatePlanMinimumDropDownItem = {
                    RoyaltyDisclosureDocumentRatePlanMinimumId: ratePlanMin.RoyaltyDisclosureDocumentRatePlanMinimumId,
                    Display: `Id:${ratePlanMin.RoyaltyDisclosureDocumentRatePlanMinimumId}, Weeks ${ratePlanMin.WeeksInBusinessStart} - ${ratePlanMin.WeeksInBusinessEnd}`
                };
                return item;
            });
            this.dropDown.setDataSource(new kendo.data.DataSource({ data: dropDownItems }));
        })(), this);
    }

    static BindComponent(app: ng.IModule)
    {
        let dropDownTemplate = (
            <select kendo-drop-down-list={`$ctrl.${nameof<fddRoyaltiesRatePlanMinimumSelector>(o => o.dropDown)}`}
                k-ng-model={`$ctrl.${nameof<fddRoyaltiesRatePlanMinimumSelector>(o => o.disclosureDocumentRatePlanMinimumId)}`}
            >
            </select>
        );

        let loadingSpinnerTemplate = (
            <loadingSpinner isLoading={`$ctrl.${nameof<fddRoyaltiesRatePlanMinimumSelector>(o => o.isLoading)}`}></loadingSpinner>
        );

        let template = `${dropDownTemplate} ${loadingSpinnerTemplate}`;
        let componentName = nameof<JSX.IntrinsicElements>(o => o.fddRoyaltiesRatePlanMinimumSelector);
        app.component(componentName, {
            bindings: {
                [nameof<fddRoyaltiesRatePlanMinimumSelector>(o => o.onInit)]: "&?",
                [nameof<fddRoyaltiesRatePlanMinimumSelector>(o => o.onSelect)]: "&?",
            },
            controller: fddRoyaltiesRatePlanMinimumSelector,
            template: template
        });
    }
}

