import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { PromiseUtil } from "Utility/PromiseUtil";

type validOptInFranchiseProfileFields =
    "IsBetaTester" |
    "OptInForReferANeighbor" |
    "OptInForTextANeighbor" |
    "OptInForPOSScheduling" |
    "OptInForPOSScheduling2" |
    "OptInForSameDayAvailabilityOnetimeClean" |
    "OptInForSameDayAvailabilityEstimateAvailability" |
    "OptInForOneTimeCleanAvailabilityAndPricing" |
    "OptInForEstimateAvailability" |
    "OptInForRegularServicePriceRange";

export class FranchiseProfileOptInOptionsComponentController implements ng.IController
{
    franchiseId: number;
    OptInOptionsGrid: kendo.ui.Grid;
    isLoading: boolean = false;
    userVm: IUserInfoViewModel;
    canUserExecuteOptInOptions: boolean = false;
    isMly: boolean = false;

    franchiseProfile: CoreResources.IFranchiseProfile;
    isPermittedFunc: (isValid: boolean) => void;

    static $inject = [
        'coreApiResources',
        'identityManager',
        'apiConfig',
        '$q',
        "$timeout"
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService
    )
    {
    }

    $onInit()
    {
        const allowedRoles = [
            this.apiConfig.FranForceConstants.RoleConstants.AdminRole,
            this.apiConfig.FranForceConstants.RoleConstants.CallCenterAdmin,
            this.apiConfig.FranForceConstants.RoleConstants.ConceptManagerRole
        ];

        let adminPromise = this.identityManager.HasRole(allowedRoles)
            .then(isAllowedRole => {
                this.canUserExecuteOptInOptions = isAllowedRole;
                if (this.isPermittedFunc)
                {
                    this.isPermittedFunc(this.canUserExecuteOptInOptions);
                }
            });
    }

    $postLink()
    {
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.franchiseId)] && this.franchiseId)
        {
            let queryParams: ODataQueryParameters & {id: number} = {
                id: this.franchiseId,
                $select: [
                    nameof<CoreResources.IFranchiseProfile>(o => o.IsBetaTester),
                    nameof<CoreResources.IFranchiseProfile>(o => o.OptInForReferANeighbor),
                    nameof<CoreResources.IFranchiseProfile>(o => o.OptInForTextANeighbor),
                    nameof<CoreResources.IFranchiseProfile>(o => o.OptInForPOSScheduling),
                    nameof<CoreResources.IFranchiseProfile>(o => o.OptInForPOSScheduling2),
                    nameof<CoreResources.IFranchiseProfile>(o => o.OptInForSameDayAvailabilityOnetimeClean),
                    nameof<CoreResources.IFranchiseProfile>(o => o.OptInForSameDayAvailabilityEstimateAvailability),
                    nameof<CoreResources.IFranchiseProfile>(o => o.OptInForOneTimeCleanAvailabilityAndPricing),
                    nameof<CoreResources.IFranchiseProfile>(o => o.OptInForEstimateAvailability),
                    nameof<CoreResources.IFranchiseProfile>(o => o.OptInForRegularServicePriceRange)
                ].join(","),
                $expand: `${nameof<CoreResources.IFranchiseProfile>(o => o.Franchise)}($select=${nameof<CoreResources.IFranchiseProfile>(o => o.Franchise.ConceptId)})`
            };

            this.coreApiResources.FranchiseProfileApi.get(queryParams).$promise
                .then((profile) =>
                {
                    this.franchiseProfile = profile;
                    this.isMly = profile.Franchise.ConceptId === 1;
                })
        }
    }

    private SaveIsBetaTester = (newValue: boolean) => this.SaveOptIn(newValue, "IsBetaTester"); 
    private SaveOptInForReferANeighbor = (newValue: boolean) => this.SaveOptIn(newValue, "OptInForReferANeighbor");
    private SaveOptInForTextANeighbor = (newValue: boolean) =>  this.SaveOptIn(newValue, "OptInForTextANeighbor"); 
    private SaveOptInForPOSScheduling = (newValue: boolean) =>  this.SaveOptIn(newValue, "OptInForPOSScheduling"); 
    private SaveOptInForPOSScheduling2 = (newValue: boolean) => this.SaveOptIn(newValue, "OptInForPOSScheduling2"); 
    private SaveOptInForSameDayAvailabilityOnetimeClean = (newValue: boolean) => this.SaveOptIn(newValue, "OptInForSameDayAvailabilityOnetimeClean"); 
    private SaveOptInForSameDayAvailabilityEstimateAvailability = (newValue: boolean) => this.SaveOptIn(newValue, "OptInForSameDayAvailabilityEstimateAvailability");         
    private SaveOptInForOneTimeCleanAvailabilityAndPricing = (newValue: boolean) => this.SaveOptIn(newValue, "OptInForOneTimeCleanAvailabilityAndPricing"); 
    private SaveOptInForEstimateAvailability = (newValue: boolean) => this.SaveOptIn(newValue, "OptInForEstimateAvailability"); 
    private SaveOptInForRegularServicePriceRange = (newValue: boolean) => this.SaveOptIn(newValue, "OptInForRegularServicePriceRange"); 

    private async SaveOptIn(newValue: boolean, field: validOptInFranchiseProfileFields)
    {
        // check to make sure user can execute the command if not early out.
        if (!this.canUserExecuteOptInOptions || !field)
        {
            return this.$q.reject();
        }

        this.franchiseProfile[field] = newValue;
        return await PromiseUtil.TrackLoadingPromise(this, 
            this.coreApiResources.FranchiseProfileApi.patch({
                id: this.franchiseId,
                [field]: this.franchiseProfile[field]
            } as Partial<CoreResources.IFranchiseProfile>).$promise
        );
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfileOptInOptions', {
            bindings: {
                [nameof<FranchiseProfileOptInOptionsComponentController>(o => o.franchiseId)]: "<",
                [nameof<FranchiseProfileOptInOptionsComponentController>(o => o.isPermittedFunc)]: "<"
            },
            transclude: true,
            controller: FranchiseProfileOptInOptionsComponentController,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfileOptInOptions.html"
        });
    }
}


