import { IFranchiseeStateParams } from "Controllers/Rms/FranchiseeManagement/fmRouter";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { CoreEntities, CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { IdentityManager } from "../../../Services/Resources/IdentityManager";
import { FranchiseeStateService } from "../../../Services/State/FranchiseeStateService";

interface IFranchiseeManagementScope extends ng.IScope {

    selectedTab: string;
    targetTab: string;
    kNotifierOptions: any;
    kNotifier: kendo.ui.Notification;
    saveSuccessful: boolean;
    isLoading: boolean;
    isGeneralTabUnsaved: boolean;
}

export class FranchiseeManagementController implements ng.IController {

    franchiseId: number;
    hideTabs: boolean;
    conceptFilters: kendo.data.DataSourceFilter;
    franchisorFilters: kendo.data.DataSourceFilter;

    hasPOSAdminRole: boolean;
    hasPOSReadOnlyRole: boolean;
    hasAdminRole: boolean;
    hasBillingAdminRole: boolean;
    hasVendorRole: boolean;

    isLoading: boolean;

    showInactiveFranchises: boolean;

    static $inject = [
        "$scope",
        "$state",
        "$stateParams",
        "coreApiResources",
        "ngDialog",
        "rpmApiResources",
        "identityManager",
        "franchiseeStateService"
    ];

    constructor(
        private $scope: IFranchiseeManagementScope,
        private $state: ng.ui.IStateService,
        private $stateParams: IFranchiseeStateParams,
        private coreApiResources: CoreApiResources,
        private ngDialog: any,
        private rpmApiResources: RpmApiResources,
        private identityManager: IdentityManager,
        private franchiseeStateService: FranchiseeStateService
    ) { }

    async $onInit() {
        this.showInactiveFranchises = this.franchiseeStateService.showInactiveFranchises;
        this.isLoading = false;

        this.hasAdminRole = false;
        this.hasPOSAdminRole = false;
        this.hasPOSReadOnlyRole = false;
        this.hasBillingAdminRole = false;
        this.hasVendorRole = false;

        await this.checkRequiredUserRoles();
        this.$scope.isGeneralTabUnsaved = false;
        this.$scope.selectedTab = this.$state.current.name;
        this.$scope.targetTab = this.$state.current.name;

        this.franchiseId = this.$stateParams.franchiseeID
            ? parseInt(this.$stateParams.franchiseeID)
            : null;

        this.hideTabs = true;

        if (this.franchiseId) {
            this.$scope.$broadcast('franchiseeSelected', { franchiseId: this.franchiseId });
            this.ChangeRoute(this.$state.current.name);
        }

        this.InitFilters();

        this.$scope.kNotifierOptions = {
            position: {
                top: 50,
                left: $(window).width() / 2
            },
            templates: [{
                type: 'ngTemplate',
                template: $('#kNTemplate').html(),
            }]
        };

        this.$scope.$on('showNotification', (event, args) => {
            this.$scope.saveSuccessful = args.saveSuccessful;
            this.$scope.kNotifier.show({
                kValue: args.text
            }, "ngTemplate");
        });

        this.$scope.$on('setGeneralTabUnsaved', (event, args) => {
            this.$scope.isGeneralTabUnsaved = args.isGeneralTabUnsaved;
        });

        this.$scope.$on("franchiseeSelected", (event, args) => {
            const newFranchiseId = parseInt(args.franchiseId);
            if (newFranchiseId !== this.franchiseId) {
                this.franchiseId = newFranchiseId;
                this.ChangeRoute(this.$state.current.name);

                this.$state.go(this.$state.current.name, { franchiseeID: this.franchiseId });
            }
        });

        this.$scope.$digest();
    }


    async checkRequiredUserRoles() {
        this.isLoading = true;
        await this.identityManager.GetLoggedInUserInfo().then(successResponse => {
            this.isLoading = false;
            if (successResponse && successResponse.data && successResponse.data.Roles && successResponse.data.Roles.length > 0) {

                /*check required roles*/
                successResponse.data.Roles.map(x => {

                    if (x.Name.trim().toLowerCase() == ("Admin").toLowerCase())
                        this.hasAdminRole = true;

                    if (x.Name.trim().toLowerCase() == ("POSAdmin").toLowerCase())
                        this.hasPOSAdminRole = true;

                    if (x.Name.trim().toLowerCase() == ("POSReadOnly").toLowerCase())
                        this.hasPOSReadOnlyRole = true;

                    if (x.Name.trim().toLowerCase() == ("BillingAdmin").toLowerCase())
                        this.hasBillingAdminRole = true;

                    if (x.Name.trim().toLowerCase() == ("Vendor").toLowerCase())
                        this.hasVendorRole = true;

                });

                this.$scope.$digest();

            }
        }).catch(ex => {
            this.isLoading = false;
            console.log("Error! Unable to get user roles.", ex);
        });
    }

    InitFilters() {
        this.conceptFilters = {
            logic: "and",
            filters: [{
                logic: "or",
                filters: [
                    { field: nameof<CoreEntities.Concept>(o => o.Country), operator: "eq", value: "Canada" },
                    { field: nameof<CoreEntities.Concept>(o => o.Country), operator: "eq", value: "United States" }
                ]
            }]
        };
    }

    OnConceptSelect(concept: Partial<CoreResources.IConcept>) {
        this.hideTabs = true;
    }

    OnFranchiseSelect(franchise: Partial<CoreResources.IFranchise>) {
        if (franchise && franchise.FranchiseId) {
            this.franchiseId = franchise.FranchiseId;
            this.ChangeRoute("fm.general");
            this.$scope.$broadcast('franchiseeSelected', { franchiseId: this.franchiseId });
            this.hideTabs = false;
        } else {
            this.hideTabs = true;
        }
        this.$scope.$applyAsync();
    }

    // Add a method to update the state service when the checkbox is toggled
    ToggleShowInactiveFranchises() {
        this.franchiseeStateService.showInactiveFranchises = this.showInactiveFranchises;
    }

    proceedWithUnsavedGeneralTab() {
        if (this.$scope.isGeneralTabUnsaved) {
            let proceed = confirm("Are you sure you want to proceed without saving the changes?");
            if (!proceed) {
                return false;
            } else {
                this.$scope.isGeneralTabUnsaved = false;
                return true;
            }
        }
        return true;
    }

    ChangeRoute(route: string) {
        if (!this.proceedWithUnsavedGeneralTab()) {
            return;
        }
        this.$scope.targetTab = route;
        this.$scope.selectedTab = route;
        this.$state.go(this.$scope.targetTab, { franchiseeID: this.franchiseId });
    }
}
