
import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { PushLeadOptionController, PushLeadOptionGridObject } from "Directives/Common/PushLeadOptionController";
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

export class ConceptPushLeadOptionController extends PushLeadOptionController<CoreResources.IConceptLeadPushOption> {
    // Bindings
    conceptId: number;

    // Other Properties
    disableOptions: boolean = false;
    canFranchiseOverride: boolean;

    static $inject = [
        'callCenterApiResources',
        'coreApiResources',
        'odataHelper',
        '$q',
        '$log',
        '$timeout',
        '$window'
    ];

    constructor(
        protected callCenterApiResources: CallCenterApiResources,
        protected coreApiResources: CoreApiResources,
        protected odataHelper: ODataHelperService,
        protected $q: ng.IQService,
        protected $log: ng.ILogService,
        protected $timeout: ng.ITimeoutService,
        protected $window: ng.IWindowService) {
        
        super(callCenterApiResources,
            coreApiResources,
            $q,
            $log,
            $timeout,
            $window);
    }

    $onInit() {
        this.coreApiResources.ConceptApi.get({
            id: this.conceptId,
            $select: "ConceptId,CanFranchiseOverrideLeadPush"
        }).$promise
            .then((concept) => {
                this.canFranchiseOverride = concept.CanFranchiseOverrideLeadPush;
            });

        super.$onInit();
    }

    LoadLookupData(): ng.IPromise<any> {
        // Retrieve concept lead push options
        return this.coreApiResources.ConceptLeadPushOptionApi
            .query({ $filter: `${nameof<CoreEntities.ConceptLeadPushOption>(o => o.ConceptId)} eq ${this.conceptId}` }).$promise
            .then((leadPushOptions) => {
                this.leadPushOptions = leadPushOptions;
            });
    }

    MapLeadPushOptions(): Array<PushLeadOptionGridObject> {
        // Add already existing items to the collection
        return this.leadPushOptions.map((leadOption) => {
            return new PushLeadOptionGridObject(
                leadOption.C4CallTypeId,
                leadOption.ConceptId,
                leadOption.ConceptLeadPushOptionId, 
                leadOption.LeadPushTypeId,
                this.GetCallTypeName(leadOption.C4CallTypeId)
            )
        });
    }

    UpdateFranchiseOverrideLeadPush() {
        // Call the service to persist the franchise override option
        return this.coreApiResources.ConceptApi
            .patch({ id: this.conceptId }, {
                ConceptId: this.conceptId,
                CanFranchiseOverrideLeadPush: !this.canFranchiseOverride
            }).$promise
            .then(() => {
                // Toggle the franchise override options
                this.canFranchiseOverride = !this.canFranchiseOverride;
            });
    }

    UpdateLeadOption(pushLeadOption: PushLeadOptionGridObject): ng.IPromise<any> {
        // Update the lead option
        let leadOptionId = this.odataHelper.ConvertCompositeKeyToString({
            ConceptId: pushLeadOption.ObjectId,
            C4CallTypeId: pushLeadOption.C4CallTypeId
        });

        let conceptPushLeadOption: CoreResources.IConceptLeadPushOption =
            this.ConvertToIConceptLeadPushOption(pushLeadOption);
        return this.coreApiResources.ConceptLeadPushOptionApi
            .update({ id: leadOptionId }, conceptPushLeadOption).$promise
            .then((a) => {
                pushLeadOption.LeadPushTypeId = a.LeadPushTypeId;
            });
    }

    CreateLeadOption(pushLeadOption: PushLeadOptionGridObject): ng.IPromise<any> {
        // Create the lead option
        let conceptPushLeadOption: CoreResources.IConceptLeadPushOption =
            this.ConvertToIConceptLeadPushOption(pushLeadOption);
        return this.coreApiResources.ConceptLeadPushOptionApi
            .save({}, conceptPushLeadOption).$promise
            .then((a) => {
                pushLeadOption.LeadPushOptionId = a.ConceptLeadPushOptionId;
                pushLeadOption.LeadPushTypeId = a.LeadPushTypeId;
            });
    }

    ConvertToIConceptLeadPushOption(pushLeadOption: PushLeadOptionGridObject): CoreResources.IConceptLeadPushOption {
        return {
            C4CallTypeId: pushLeadOption.C4CallTypeId,
            ConceptId: pushLeadOption.ObjectId,
            ConceptLeadPushOptionId: pushLeadOption.LeadPushOptionId,
            LeadPushTypeId: pushLeadOption.LeadPushTypeId
        } as CoreResources.IConceptLeadPushOption;
    }

    DefineNewPushLeadOption(callTypeId: number) {
        return new PushLeadOptionGridObject(
            callTypeId, this.conceptId, 0, 0, this.GetCallTypeName(callTypeId));
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('conceptPushLeadOption', {
            bindings: {
                conceptId: "<"
            },
            controller: ConceptPushLeadOptionController,
            templateUrl: "/Templates/Concepts/ConceptPushLeadOption.html"
        });
    }
}