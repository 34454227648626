import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";

export class NavigationLink
{
    Section: string;
    DppBuildingId: number;
    DisplayText: string;
    Url: string;
    IconClass: string;
    CssClass: string;

    constructor(section: string, displayText: string, dppBuildingId?: number, cssClass?: string, iconClass?: string)
    {
        this.Section = section;
        this.DisplayText = displayText;
        this.DppBuildingId = dppBuildingId;
        this.CssClass = cssClass;
        this.IconClass = iconClass;

        this.Url = NavigationLink.BuildRedirectUrl(this.Section, this.DppBuildingId);
    }

    static BuildRedirectUrl(section: string, dppBuildingId: string | number = null): string
    {
        let link = "#/" + section;
        if (dppBuildingId) {
            link += "?DppBuildingId=" + dppBuildingId;
        }
        return link;
    }
}

export class NavigationService
{
    dpp = "DisasterPrepProfile";
    homeLink: NavigationLink;

    constructor(
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private resources: DppApiResources)
    {

    }

    static Init(dppApp: ng.IModule)
    {
        dppApp.factory("Navigation", [
            "$location", "$q", "dppApiResources",
            ($location: ng.ILocationService, $q: ng.IQService, dppApiResources: DppApiResources) =>
            {
                return new NavigationService($location, $q, dppApiResources);
            }
        ]);
    }
    
    CreateBuildingLinks(dppBuildingId: number): ng.IPromise<Array<NavigationLink>>
    {
        let links: Array<NavigationLink> = [];
        if (dppBuildingId)
        {
            return this.resources.DisasterPrepProfileBuildingApi.get({ id: dppBuildingId, $select: "BuildingName" }).$promise.then(
                (building: DppResources.IDisasterPrepProfileBuilding) =>
                {
                    this.homeLink = new NavigationLink(`Edit${this.dpp}`, "Profile Home And Buildings", null, "pure-u-1-2", "fa fa-home");
                    let buildingLink = new NavigationLink(`Edit${this.dpp}Building`, "Current Building", dppBuildingId, "pure-u-1-2", "fa fa-building");
                    buildingLink.DisplayText = building.BuildingName;

                    links.push(this.homeLink);
                    links.push(buildingLink);
                    links.push(new NavigationLink(`Edit${this.dpp}BuildingContacts`, "Contacts", dppBuildingId, "pure-u-1-4 pure-u-md-1-8", "fa fa-phone"));
                    links.push(new NavigationLink(`Edit${this.dpp}BuildingInsurance`, "Insurance", dppBuildingId, "pure-u-1-4 pure-u-md-1-8", "fa fa-shield"));
                    links.push(new NavigationLink(`Edit${this.dpp}BuildinginformationTechnology`, "IT", dppBuildingId, "pure-u-1-4 pure-u-md-1-8", "fa fa-desktop"));
                    links.push(new NavigationLink(`Edit${this.dpp}BuildingUtilities`, "Utilities", dppBuildingId, "pure-u-1-4 pure-u-md-1-8", "fa fa-plug"));
                    links.push(new NavigationLink(`Edit${this.dpp}BuildingHazardousMaterials`, "Hazards", dppBuildingId, "pure-u-1-4 pure-u-md-1-8", "fa fa-exclamation-triangle"));
                    links.push(new NavigationLink(`Edit${this.dpp}BuildingServiceProviders`, "Providers", dppBuildingId, "pure-u-1-4 pure-u-md-1-8", "fa fa-users"));
                    links.push(new NavigationLink(`Edit${this.dpp}BuildingAssets`, "Files", dppBuildingId, "pure-u-1-4 pure-u-md-1-8", "fa fa-file-image-o"));
                    links.push(new NavigationLink(`Finish${this.dpp}`, "Finish", dppBuildingId, "pure-u-1-4 pure-u-md-1-8", "fa fa-flag-checkered"));

                    console.log("links created");

                    return links;
                }
            );
        }
        else
        {
            this.homeLink = new NavigationLink(`Edit${this.dpp}`, "Profile Home And Buildings", null, "pure-u-1-2", "fa fa-home");
            links.push(this.homeLink);
            links.push(new NavigationLink(`Finish${this.dpp}`, "Summary And Finish", dppBuildingId, "pure-u-1-2", "fa fa-flag-checkered"));
            return this.$q.when(links);
        }
    }

    Redirect(section: string, dppBuildingId: string | number = null)
    {
        if (dppBuildingId)
        {
            this.$location.path("/" + section).search({ "DppBuildingId": dppBuildingId });
        }
        else
        {
            this.$location.path("/" + section);
        }
    }

    RedirectHome()
    {
        this.Redirect("EditDisasterPrepProfile");
    }

    GetCurrentBuildingLocation(buildingLinks: Array<NavigationLink>): NavigationLink
    {
        let currentLocation = this.$location.path().split("/")[1];
        for (let link of buildingLinks)
        {
            if (link && currentLocation === link.Section)
            {
                return link;
            }
        }
        
        return null;
    }
    
    GetCurrentIndex(buildingLinks: Array<NavigationLink>, currentLocation?: NavigationLink): number
    {
        if (!currentLocation)
        {
            currentLocation = this.GetCurrentBuildingLocation(buildingLinks);
        }

        if (!currentLocation)
        {
            return null;
        }

        let index = buildingLinks.indexOf(currentLocation);
        if (index === -1)
        {
            console.error("currentLocation doesn't exist in buildingLinks. Is this a different object?");
            return null;
        }

        return index;
    }
    
    GetPreviousIndex(buildingLinks: Array<NavigationLink>, currentLocation?: NavigationLink): number
    {
        let index = this.GetCurrentIndex(buildingLinks, currentLocation);
        
        if (index == null)
        {
            return null;
        }

        if (index <= 0)
        {
            //First Item
            return null;
        }

        return --index;
    }

    GetNextIndex(buildingLinks: Array<NavigationLink>, currentLocation?: NavigationLink): number
    {
        let index = this.GetCurrentIndex(buildingLinks, currentLocation);
        
        if (index == null) {
            return null;
        }

        if (index >= buildingLinks.length) {
            //Last Item
            return null;
        }

        return ++index;
    }

    IsCurrentLink(buildingLinks: Array<NavigationLink>, currentLocation: NavigationLink) : boolean
    {
        return this.GetCurrentLink(buildingLinks) === currentLocation;
    }

    GetCurrentLink(buildingLinks: Array<NavigationLink>): NavigationLink
    {
        return buildingLinks[this.GetCurrentIndex(buildingLinks)];
    }

    GetPreviousLink(buildingLinks: Array<NavigationLink>): NavigationLink
    {
        return buildingLinks[this.GetPreviousIndex(buildingLinks)];
    }

    GetNextLink(buildingLinks: Array<NavigationLink>): NavigationLink
    {
        return buildingLinks[this.GetNextIndex(buildingLinks)];
    }
}