import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { ConceptClient, Concept } from '@nbly/engagement-services-clients';
import { AxiosInstance } from "axios";
import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";

export class ConceptLeadPauseTimeController implements ng.IController {
    //---- Bindings ----
    conceptId: number;
    //------------------

    
    isLoading: boolean = true;
 
   
    userHasFranchiseRole: boolean;
    user: IUserInfoViewModel;
    conceptDTO: Concept;
    engagementServicesClient: AxiosInstance;
    conceptClient: ConceptClient;


    static $inject = [
        "coreApiResources",
        "$q",
        "$log",
        'identityManager',
        "apiConfig",
        "$rootScope",
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $rootScope: ng.IRootScopeService
    ) {
        this.engagementServicesClient = FranForceAxiosClientBuilder.BuildEngagementServicesBaseClient();
        this.conceptClient = new ConceptClient("", this.engagementServicesClient);
        identityManager.GetLoggedInUserInfo()
            .then((response) => {
                this.user = response.data;
                this.userHasFranchiseRole = false;

                //skip attempting to set this if the user is an admin or concept manager.
                if (!this.user.Roles.some(r => this.apiConfig.FranForceConstants.RoleConstants.AllConceptManagementRoles.split(",").some(c => c.toLowerCase() == r.Name.toLowerCase()))) {

                    //controls are hidden if logged in user is in franchise role.
                    this.userHasFranchiseRole = this.user.Roles.some(
                        r => r.Name.toLowerCase() == this.apiConfig.FranForceConstants.RoleConstants.FranchiseCSR.toLowerCase() ||
                            r.Name.toLowerCase() == this.apiConfig.FranForceConstants.RoleConstants.FranchiseeEmployeeRole.toLowerCase() ||
                            r.Name.toLowerCase() == this.apiConfig.FranForceConstants.RoleConstants.FranchiseeRole.toLowerCase()
                    );
                }
            });
    }

    async $onInit() {

    }

    async $postLink() {
        await this.loadConceptsInfo();
        this.$rootScope.$on("conceptUpdated", (event, updatedConcept) => {
            if (updatedConcept.conceptId === this.conceptId) {
                this.conceptDTO = updatedConcept;
            }
        });
    }

    async loadConceptsInfo() {
        this.conceptClient.conceptAll().
            then((response) => {
                this.conceptDTO = response.result.find(c => c.conceptId == this.conceptId);
                this.isLoading = false;

            })

    }

    async Save() {

        await this.conceptClient.conceptPUT(
            this.conceptDTO).then((response) => {
                this.$rootScope.$broadcast("conceptUpdated", response.result);
            }).catch((err) => {
                alert("Failed to save ");
            });
    }


    async $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.conceptId)] && this.conceptId) {
            await this.loadConceptsInfo();
        }

    }

    static BindComponent(app: ng.IModule) {
        app.component("conceptLeadPauseTime", {
            bindings: {
                conceptId: "<"
            },
            controller: ConceptLeadPauseTimeController,
            templateUrl: "/Templates/Concepts/ConceptLeadPauseTime.html",
        });
    }
}

