
export type ExpressionBinding<TParams> = (params: TParams) => void;

export class AngularUtil
{
    static async TrackLoadingPromise<TResult = any>(promise: ng.IPromise<TResult>, trackingObj: { isLoading: boolean })
    {
        trackingObj.isLoading = true;
        promise.finally(() =>
        {
            trackingObj.isLoading = false;
        })
        return promise;
    }

    static GetJsonAlertCatch()
    {
        return (err) =>
        {
            console.error(err);
            alert(JSON.stringify(err));
        };
    }

    static GetErrorPromiseLogCallback($log: ng.ILogService, $q: ng.IQService)
    {
        return (err: any) => {
            $log.error(err);
            return $q.reject(err);
        };
    }

    static GetErrorPromiseLogAlertCallback<TErrorType extends any>($log: ng.ILogService, $q: ng.IQService, alertReturnData: (err: TErrorType) => string = (err: any) => err.data)
    {
        return (err: any) => {
            $log.error(err);
            alert("Error: " + alertReturnData(err));
            return $q.reject(err);
        };
    }

    static GetDefaultHttpErrorPromiseLogAlertCallback<TErrorType extends any>($log: ng.ILogService, $q: ng.IQService)
    {
        return AngularUtil.GetErrorPromiseLogAlertCallback<ng.IHttpPromiseCallbackArg<any>>(
            $log,
            $q,
            (err) => { return err.data.Message; })
    }
}