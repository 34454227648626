import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import {FranchiseLookupResponseModel } from "@nbly/unified-sync-platform-clients"

export class ConceptFranchiseLookupComponentController implements ng.IController
 {
    //---- Bindings ----
    conceptId: number;
    //------------------

    address: string;
    isRoundRobin: boolean;
    requireAddressOnPartialPostalCode: boolean;
    isLoading: boolean;
    lookupGrid: kendo.ui.Grid;
    showRequireAddressOnPartialPostalCode: boolean;
    userHasFranchiseRole: boolean;
    user: IUserInfoViewModel;
    static $inject = [
        "coreApiResources",
        "$q",
        "$log",
        'identityManager',
        "apiConfig"
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
    )
    {

        identityManager.GetLoggedInUserInfo()
            .then((response) => 
            {
                this.user = response.data;
                this.userHasFranchiseRole = false;

                //skip attempting to set this if the user is an admin or concept manager.
                if (!this.user.Roles.some(r => this.apiConfig.FranForceConstants.RoleConstants.AllConceptManagementRoles.split(",").some(c => c.toLowerCase() == r.Name.toLowerCase()))) {

                    //controls are hidden if logged in user is in franchise role.
                    this.userHasFranchiseRole = this.user.Roles.some(
                        r => r.Name.toLowerCase() == this.apiConfig.FranForceConstants.RoleConstants.FranchiseCSR.toLowerCase() ||
                            r.Name.toLowerCase() == this.apiConfig.FranForceConstants.RoleConstants.FranchiseeEmployeeRole.toLowerCase() ||
                            r.Name.toLowerCase() == this.apiConfig.FranForceConstants.RoleConstants.FranchiseeRole.toLowerCase()
                    );
                }
            });
    }

    $onInit() 
    {
        this.showRequireAddressOnPartialPostalCode = true;
        this.isRoundRobin = false;
        this.requireAddressOnPartialPostalCode = true;

        this.ShowOrHideRequireAddressOnPartialPostalCode();
    }

    $postLink() 
    {
        this.InitGrid();
    }

    $onChanges(changes: ng.IOnChangesObject)
     {
        if (changes[nameof(this.conceptId)] && this.conceptId) {
            if (this.lookupGrid) {
                this.lookupGrid.dataSource.data([]);
            }
        }
        this.ShowOrHideRequireAddressOnPartialPostalCode();
    }

    ShowOrHideRequireAddressOnPartialPostalCode() {
        if ((this.conceptId == 1 || this.conceptId == 2 || this.conceptId == 13) && this.userHasFranchiseRole) {
            this.showRequireAddressOnPartialPostalCode = false;
        }
        else {
            this.showRequireAddressOnPartialPostalCode = true;
        }
    }

    InitGrid()
     {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof<FranchiseLookupResponseModel>(o => o.licenseNumber),
                title: "Id"
            }, {
                field: nameof<FranchiseLookupResponseModel>(o => o.doingBusinessAs),
                title: "DBA"
            }, {
                field: nameof<FranchiseLookupResponseModel>(o => o.franchiseeName),
                title: "Franchisee"
            }, {
                field: nameof<FranchiseLookupResponseModel>(o => o.openingDate),
                title: "Opening"
            }, {
                field: nameof<FranchiseLookupResponseModel>(o => o.status),
                title: "Status"
            }, {
                field: nameof<FranchiseLookupResponseModel>(o => o.territoryTypeName),
                title: "Territory"
            },
        ]

        let datasource = new kendo.data.DataSource({
            transport: {
                read: (options) =>
                 {
                    this.isLoading = true;
                    this.coreApiResources.FranchiseLookUpByServiceAddressMicro(this.conceptId, this.address, null, this.isRoundRobin, this.requireAddressOnPartialPostalCode)
                        .then((response) =>
                         {
                            for (let i = 0; i < response.data.length; i++) {
                                if (response.data[i].TerritoryTypeName == "Service Territory (TAFS)" && (this.conceptId == 1 || this.conceptId == 2 || this.conceptId == 13)) {
                                    response.data[i].TerritoryTypeName = response.data[i].TerritoryTypeName + " - Open";
                                }
                            }
                            options.success(response.data);
                        })
                        .catch((err: ng.IHttpPromiseCallbackArg<any>) =>
                         {
                            console.error(err);
                            if (err.data.Message) {
                                if (err.data.Message == "Full address is needed to narrow down results") {
                                    alert("A full address is needed to verify coverage.");
                                }
                                else {
                                    alert(err.statusText + ": " + err.data.Message)
                                }
                            }
                            else {
                                alert(err.statusText + ": " + JSON.stringify(err.data));
                            }
                            options.error(err);
                        })
                        .finally(() => 
                        {
                            this.isLoading = false;
                        });
                }
            }
        });

        let options: kendo.ui.GridOptions = {
            columns: columns,
            dataSource: datasource,
            autoBind: false
        };
        this.lookupGrid.setOptions(options);
    }

    Search() 
    {
        return this.$q.when(this.lookupGrid.dataSource.read())
            .then(() =>
             {
                this.lookupGrid.refresh();
            });
    }

    static BindComponent(app: ng.IModule)
     {
        app.component("conceptFranchiseLookup", {
            bindings: {
                conceptId: "<"
            },
            controller: ConceptFranchiseLookupComponentController,
            templateUrl: "/Templates/Concepts/ConceptFranchiseLookup.html",
        });
    }
}

