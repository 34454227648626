import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { FranchiseSelectorComponentControllerState, FranchiseSelectorOnSelectParameters, FranchiseSelectorOnLoadParameters } from "Directives/Common/FranchiseSelector";
import { ConceptSelectorComponentControllerState, ConceptSelectorOnLoadParameters, ConceptSelectorOnSelectParameters } from "Directives/Common/ConceptSelector";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class ConceptFranchiseSelectorComponentController implements ng.IController
{
    //---- Bindings ----
    disable: boolean;
    state: boolean |
        FranchiseSelectorComponentControllerState &
        ConceptSelectorComponentControllerState;

    conceptId: number;
    conceptDisable: boolean;
    conceptSelect: string[];
    conceptFilters: kendo.data.DataSourceFilter;
    conceptSort: (a: Partial<CoreResources.IConcept>, b: Partial<CoreResources.IConcept>) => number;
    conceptDisplayTextTemplate: string;
    conceptExtraOptions: kendo.ui.DropDownListOptions;
    conceptOnSelect: (params: ConceptSelectorOnSelectParameters) => void;
    conceptOnLoad: (params: ConceptSelectorOnLoadParameters) => void;

    franchiseId: number;
    franchiseDisable: boolean;
    franchiseSelect: string[];
    franchiseFilters: kendo.data.DataSourceFilter;
    franchiseSort: (a: Partial<CoreResources.IFranchise>, b: Partial<CoreResources.IFranchise>) => number;
    franchiseDisplayTextTemplate: string;
    franchiseExtraOptions: kendo.ui.DropDownListOptions;
    franchiseShowInactive: boolean;
    franchiseOnSelect: (params: FranchiseSelectorOnSelectParameters) => void;
    franchiseOnLoad: (params: FranchiseSelectorOnLoadParameters) => void;
    //------------------

    isLoading: boolean;
    combinedFranchiseFilters: kendo.data.DataSourceFilters;

    static $inject = [
        "coreApiResources",
    ];

    constructor(
        private coreApiResources: CoreApiResources,
    )
    {

    }

    $onInit()
    {
        this.isLoading = false;

        if (!this.conceptFilters) {
            this.conceptFilters = [];
        }

        if (this.franchiseId)
        {
            this.LoadFromFranchise();
        }

        this.CreateCombinedFranchiseFilters();
    }

    $postLink()
    {

    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
        if (onChanges[nameof(this.conceptId)]) {
            this.CreateCombinedFranchiseFilters()
        }

        if (onChanges[nameof(this.franchiseId)]) {
            if (this.franchiseId) {
                this.LoadFromFranchise();
            }
        }

        if (typeof this.disable === "boolean") {
            this.franchiseDisable = this.disable;
            this.conceptDisable = this.disable;
        }
    }

    OnSelectConcept(concept: kendo.data.ObservableObject & Partial<CoreResources.IConcept>)
    {
        if (!concept || !concept.ConceptId) {
            this.conceptId = null;
            this.franchiseFilters = null;
            this.CreateCombinedFranchiseFilters();
            this.conceptOnSelect({ concept: concept });
            return;
        }

        if (this.conceptId == concept.ConceptId) {
            this.conceptOnSelect({ concept: concept });
            return;
        }

        this.conceptId = concept.ConceptId;
        this.CreateCombinedFranchiseFilters();
        this.conceptOnSelect({ concept: concept });
    }

    OnSelectFranchise(franchise: kendo.data.ObservableObject & Partial<CoreResources.IFranchise>)
    {
        if (!franchise || !franchise.FranchiseId) {
            this.franchiseOnSelect({ franchise: franchise });
            return;
        }

        this.franchiseId = franchise.FranchiseId;
        this.conceptId = franchise.ConceptId;
        this.franchiseOnSelect({ franchise: franchise });
    }

    CreateCombinedFranchiseFilters()
    {
        if (!this.conceptId) {
            this.combinedFranchiseFilters = null;
            return;
        }

        this.combinedFranchiseFilters = {
            logic: "and",
            filters: [
                {
                    field: nameof<CoreResources.IFranchise>(o => o.ConceptId),
                    operator: 'eq',
                    value: this.conceptId
                },
                this.franchiseFilters
            ]
        }
    }

    LoadFromFranchise()
    {
        this.isLoading = true;
        this.coreApiResources.FranchiseApi.get({ id: this.franchiseId, $select: nameof<CoreResources.IFranchise>(o => o.ConceptId) }).$promise
            .then((franchise) =>
            {
                this.conceptId = franchise.ConceptId;
                this.CreateCombinedFranchiseFilters();
            })
            .finally(() =>
            {
                this.isLoading = false;
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("conceptFranchiseSelector", {
            bindings: {
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.disable)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.state)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.conceptId)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.conceptDisable)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.conceptSelect)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.conceptFilters)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.conceptSort)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.conceptDisplayTextTemplate)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.conceptExtraOptions)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.conceptOnSelect)]: "&",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.conceptOnLoad)]: "&",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.franchiseId)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.franchiseDisable)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.franchiseSelect)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.franchiseFilters)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.franchiseSort)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.franchiseDisplayTextTemplate)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.franchiseExtraOptions)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.franchiseShowInactive)]: "<",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.franchiseOnSelect)]: "&",
                [nameof<ConceptFranchiseSelectorComponentController>(o => o.franchiseOnLoad)]: "&",
            },
            controller: ConceptFranchiseSelectorComponentController,
            templateUrl: `/Templates/Common/ConceptFranchiseSelector.html`,
        });
    }
}

