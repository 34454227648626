import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ZeeMailApiResources } from "Services/Resources/ZeeMailApiResources";
import { ApiConfig } from "AppConfig/ApiConfig";
import { FranchiseBillingSubscriptionsComponentController } from "Directives/Billing/FranchiseBillingSubscriptionsComponentController";
import { KendoHelperService } from "Services/Utility/KendoHelperService";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { BooleanProperties } from "Types/BooleanProperties";

export class BillingFranchiseSectionsComponentController implements ng.IController
{   
    franchiseId: number;
    franchise: CoreResources.IFranchise;
    gridOptions: kendo.ui.GridOptions;

    franchiseEmailsBindings: {
        additionalFilters: kendo.data.DataSourceFilter;
        restrictFields: Partial<BooleanProperties<CoreResources.IFranchiseEmail>>;
        defaultModel: Partial<CoreResources.IFranchiseEmail>;
        hideFields: string[];
    };

    isBillingAdmin: boolean;
    isZeeMailAdmin: boolean;
    isZeeMailActive: boolean;
    isLoading: boolean;

    franchiseBillingTabs: kendo.ui.TabStrip;
    franchiseBillingSubscriptionsController: FranchiseBillingSubscriptionsComponentController;

    static $inject = [
        "$location",
        "$window",
        "$q",
        "coreApiResources",
        "identityManager",
        "zeeMailApiResources",
        "apiConfig",
        "kendoHelper"
    ];

    constructor(
        private $location: ng.ILocationService,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private zeeMailApiResources: ZeeMailApiResources,
        private apiConfig: ApiConfig,
        private kendoHelper: KendoHelperService
    )
    {
    }

    $onInit()
    {
        this.InitState();
        this.InitEmailBindings();

        this.isLoading = true;
        this.$q.all([
            this.LoadPermissions(),
            this.LoadFranchiseData()
        ])
            .then(() =>
            {
                this.franchiseBillingTabs.select(0);
            })
            .finally(() =>
            {
                this.isLoading = false;
            });
    }

    $postLink()
    {
        this.kendoHelper.BindTabStripStateHandlers(this.franchiseBillingTabs);
    }

    InitState()
    {
        this.franchiseId = parseInt(this.$location.search()[nameof(this.franchiseId)]);

        if (!this.franchiseId) {
            this.$window.location.href = "/Billing";
        }
    }

    LoadPermissions()
    {
        return this.identityManager.GetBillingPermissions()
            .then((permissions) =>
            {
                this.isBillingAdmin = permissions.isBillingAdmin;
                this.isZeeMailAdmin = permissions.isZeeMailAdmin;
            })
    }

    LoadFranchiseData()
    {
        return this.coreApiResources.FranchiseApi.get({ id: this.franchiseId }).$promise
            .then((franchise) =>
            {
                this.franchise = franchise;
                this.franchiseEmailsBindings.defaultModel.Franchise.LicenseNumber = franchise.LicenseNumber
                return this.zeeMailApiResources.GetFranchisorIsZeeMailEnabled(franchise.FranchisorId);
            })
            .then((enabledResponse) =>
            {
                this.isZeeMailActive = enabledResponse.data;
            });
    }

    InitEmailBindings()
    {
        let zorProcessTypeId = 2;

        this.franchiseEmailsBindings = {
            additionalFilters: <kendo.data.DataSourceFilters>{
                logic: "and",
                filters: <kendo.data.DataSourceFilterItem[]>[
                    {
                        field: nameof<CoreResources.IFranchiseEmail>(o => o.FranchiseId),
                        operator: "eq",
                        value: this.franchiseId
                    }, {
                        field: nameof<CoreResources.IFranchiseEmail>(o => o.EmailProcessTypeID),
                        operator: "eq",
                        value: zorProcessTypeId
                    }
                ]
            },
            restrictFields: {
                FranchiseId: true,
                EmailProcessTypeID: true
            },
            defaultModel: {
                FranchiseId: this.franchiseId,
                Franchise: <any>{},
                EmailProcessTypeID: zorProcessTypeId
            },
            hideFields: [
                nameof<CoreResources.IFranchiseEmail>(o => o.FranchiseEmailId),
                nameof.full<CoreResources.IFranchiseEmail>(o => o.EmailProcessType.Name),
                nameof.full<CoreResources.IFranchiseEmail>(o => o.Franchise.Concept.ConceptCode),
                nameof.full<CoreResources.IFranchiseEmail>(o => o.Franchise.LicenseNumber),
            ]
        };
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("billingFranchiseSections", {
            bindings: {

            },
            controller: BillingFranchiseSectionsComponentController,
            templateUrl: "/Templates/Billing/BillingFranchiseSections.html",
        });
    }
}

