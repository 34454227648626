import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ZeeMailApiResources } from "Services/Resources/ZeeMailApiResources";
import { ApiConfig } from "AppConfig/ApiConfig";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class ZeeMailFranchiseDisplayComponentController implements ng.IController
{
    franchiseId: number;
    franchise: CoreResources.IFranchise;

    static $inject = [
        'coreApiResources',
        'zeeMailApiResources',
        'identityManager',
        'apiConfig'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private zeeMailApiResources: ZeeMailApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig
    )
    {
    }

    $onInit()
    {

    }

    $postLink()
    {
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.franchiseId)] && this.franchiseId)
        {
            if (this.franchise && this.franchise.FranchiseId == this.franchiseId)
                return;

            this.coreApiResources.FranchiseApi.get({ id: this.franchiseId }).$promise
                .then((franchise) =>
                {
                    this.franchise = franchise;
                });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("zeeMailFranchiseDisplay", {
            bindings: {
                franchiseId: "<"
            },
            controller: ZeeMailFranchiseDisplayComponentController,
            templateUrl: "/Templates/ZeeMail/ZeeMailFranchiseDisplay.html"
        });
    }
}


