import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { StringProperties } from "Types/StringProperties";

import * as _ from "underscore"
import * as elements from 'typed-html';

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            conceptMultiSelector: Partial<StringProperties<ConceptMultiSelectorComponentController>>;
        }
    }
}

export type ConceptMultiSelectorComponentControllerState = { conceptIdParameter?: string };
export type ConceptMultiSelectorOnSelectParameters = { concept: kendo.data.ObservableObject & Partial<CoreResources.IConcept> };
export type ConceptMultiSelectorOnLoadParameters = { concepts: Array<Partial<CoreResources.IConcept>> };

export class ConceptMultiSelectorComponentController implements ng.IController
{
    selectedModel: kendo.data.ObservableObject & Partial<CoreResources.IConcept>;
    dropdown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;
    datasource: kendo.data.DataSource;

    //---- Bindings ----
    conceptId: number;
    select: string[];
    filters: kendo.data.DataSourceFilter;
    sort: (a: Partial<CoreResources.IConcept>, b: Partial<CoreResources.IConcept>) => number;
    disable: boolean;
    displayTextTemplate: string;
    queryParams: { [key: string]: any };
    extraOptions: kendo.ui.DropDownListOptions;
    onSelect: (params: ConceptMultiSelectorOnSelectParameters) => void;
    onLoad: (params: ConceptMultiSelectorOnLoadParameters) => void;
    state: boolean | ConceptMultiSelectorComponentControllerState;
    royaltySystemIdExistsCheck: boolean;
    selectedConcepts: any[];
    conceptMultiSelect: kendo.ui.MultiSelect;
    conceptMultiSelectOptions: kendo.ui.MultiSelectOptions;
    conceptList: any[];
    filterConceptIds: any[];
    //------------------

    static readonly DisplayProperty = "DisplayText";

    static $inject = [
        "$q",
        "$interpolate",
        "$location",
        "coreApiResources",
        "identityManager",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $q: ng.IQService,
        private $interpolate: ng.IInterpolateService,
        private $location: ng.ILocationService,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {

    }

    $onInit()
    {
        if (!this.select || !(this.select instanceof Array))
        {
            this.select = [
                nameof<CoreResources.IConcept>(o => o.ConceptId),
                nameof<CoreResources.IConcept>(o => o.DisplayName)
            ];
        }

        if (!this.displayTextTemplate)
            this.displayTextTemplate = `{{${nameof<CoreResources.IConcept>(o => o.DisplayName)}}}`;

        if (!this.sort)
            this.sort = (a, b) => { return a[ConceptMultiSelectorComponentController.DisplayProperty].toLowerCase().localeCompare(b[ConceptMultiSelectorComponentController.DisplayProperty].toLowerCase()); }

        this.InitState();
        this.SetDropDownOptions();
    }

    async SetDropDownOptions()
    {
        this.datasource = new kendo.data.DataSource({
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) =>
                {
                    if (this.filters === undefined || this.filters === null)
                    {
                        return;
                    }

                    let filters: kendo.data.DataSourceFilters;
                    if (!_.isEmpty(this.filters))
                    {
                        filters = {
                            logic: "and",
                            filters: [
                                this.filters
                            ]
                        };
                    }
                    else
                    {
                        filters = {
                            logic: "and",
                            filters: [
            
                            ]
                        };
                    }
            
                    let params: ODataQueryParameters = {};
                    params.$select = this.GetSelectString();
            
                    if (!_.isEmpty(filters))
                    {
                        params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersOrItemToString(filters);
                    }
            
                    if (!_.isEmpty(this.filters))
                    {
                        params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersOrItemToString(this.filters);
                    }
            
                    if (this.queryParams)
                    {
                        params = angular.extend({}, this.queryParams, params);
                    }
            
                     this.coreApiResources.ConceptApi.query(params)
                        .$promise.then((concepts) =>
                        {
                            for (let concept of concepts)
                            {
                                concept[ConceptMultiSelectorComponentController.DisplayProperty] = this.$interpolate(this.displayTextTemplate)(concept);
                            }
                            if (this.sort)
                            {
                                concepts = concepts.sort(this.sort);
                            }
                            if (this.onLoad)
                            {
                                this.onLoad({ concepts: concepts.slice() });
                            }
            
                            this.conceptList = concepts;
                            if (this.filterConceptIds && this.filterConceptIds.length)
                            {

                                this.conceptList = this.conceptList.filter((concepts) => !this.filterConceptIds.includes(concepts.ConceptId));
                            }

                            options.success(this.conceptList);
                        })
                        .catch(err => console.log('err', err));
                },

            },

        });

        this.conceptMultiSelectOptions =
        {
            placeholder: "Select Concepts...",
            dataTextField: "DisplayText",
            dataValueField: "ConceptId",
            autoBind: false,
            autoClose: false,
            dataSource: this.datasource,
            dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) =>
            {
                this.conceptMultiSelect = e.sender;
            }
        };



    }

    $postLink()
    {

    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
        if (onChanges[nameof(this.state)])
        {
            if (this.state === true)
            {
                this.state = {
                    conceptIdParameter: "conceptId"
                };
            }
        }

        if (onChanges[nameof(this.filters)])
        {
            this.Refresh()
        }

        if (onChanges[nameof(this.filterConceptIds)])
        {
            this.Refresh();
        }

        if (onChanges[nameof(this.disable)])
        {
            if (this.conceptMultiSelect)
            {
                this.conceptMultiSelect.enable(!this.disable);
            }
        }
    }

    Refresh()
    {

        if (this.conceptMultiSelect)
        {
            this.conceptMultiSelect.dataSource.read();
        }
    }

    InitState()
    {
        if (this.state && typeof this.state === "object")
        {
            let searchParam = this.$location.search()[this.state.conceptIdParameter];
            if (searchParam)
            {
                this.conceptId = parseInt(searchParam);
            }
        }
    }

    SetState()
    {
        if (this.state && typeof this.state === "object")
        {
            this.$location.search(this.state.conceptIdParameter, this.conceptId);
        }
    }

    private GetSelectString()
    {
        if (!this.select)
            this.select = [];

        if (!this.select.some(s => s == nameof<CoreResources.IConcept>(o => o.ConceptId)))
            this.select.push(nameof<CoreResources.IConcept>(o => o.ConceptId));

        return this.select.join(",")
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.conceptMultiSelector);

        app.component(componentName, {
            bindings: {
                [nameof<ConceptMultiSelectorComponentController>(o => o.conceptId)]: "<",
                [nameof<ConceptMultiSelectorComponentController>(o => o.select)]: "<",
                [nameof<ConceptMultiSelectorComponentController>(o => o.filters)]: "<",
                [nameof<ConceptMultiSelectorComponentController>(o => o.sort)]: "<",
                [nameof<ConceptMultiSelectorComponentController>(o => o.disable)]: "<",
                [nameof<ConceptMultiSelectorComponentController>(o => o.queryParams)]: "<",
                [nameof<ConceptMultiSelectorComponentController>(o => o.displayTextTemplate)]: "<",
                [nameof<ConceptMultiSelectorComponentController>(o => o.extraOptions)]: "<",
                [nameof<ConceptMultiSelectorComponentController>(o => o.onSelect)]: "&",
                [nameof<ConceptMultiSelectorComponentController>(o => o.onLoad)]: "&",
                [nameof<ConceptMultiSelectorComponentController>(o => o.state)]: "<",
                [nameof<ConceptMultiSelectorComponentController>(o => o.royaltySystemIdExistsCheck)]: "<",
                [nameof<ConceptMultiSelectorComponentController>(o => o.selectedConcepts)]: "=",
                [nameof<ConceptMultiSelectorComponentController>(o => o.filterConceptIds)]: "<",

            },
            controller: ConceptMultiSelectorComponentController,
            template: `<select kendo-multi-select
            k-options="$ctrl.conceptMultiSelectOptions"
             k-ng-model="$ctrl.selectedConcepts""></select>`
        });
    }
}

