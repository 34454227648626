import { BaseStateHandler } from "StateHandlers/BaseStateHandler";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";


export class userStateObj
{
    selfUser: IUserInfoViewModel;
    managedUser: IManagedUserViewModel & { Roles: IRoleViewModel[] };
    isManagedUserReadOnly: boolean;
    managedRoles: IRoleViewModel[];
    allRoles: IRoleViewModel[];
};

export class EditUserStateService extends BaseStateHandler<userStateObj, string>
{
    static $inject = [
        "$q",
        "identityManager",
        "apiConfig"
    ]

    constructor(
        private $q: ng.IQService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig
    )
    {
        super();
    }

    Get(managedUserId: string): angular.IPromise<userStateObj>
    {
        let model: Partial<userStateObj> = {};

        let rolesPromise = this.identityManager.GetRoles()
            .then((rolesResponse) =>
            {
                model.allRoles = rolesResponse.data;
            }, (err) =>
            {
                console.log(err);
            });
        let loggedInUserPromise = this.identityManager.GetLoggedInUserInfo()
            .then((loggedInUserResponse) =>
            {
                model.selfUser = loggedInUserResponse.data;
            }, (err) =>
            {
                console.log(err);
            });
        let getManagedRolesPromise = this.identityManager.GetManagedRoles()
            .then((managedRolesResponse) =>
            {
                model.managedRoles = managedRolesResponse.data;
            }, (err) =>
            {
                console.log(err);
            });

        return this.$q.all([
            rolesPromise,
            loggedInUserPromise,
            getManagedRolesPromise
        ])
            
            .then(() =>
            {
                model.isManagedUserReadOnly = this.identityManager.UserHasRole(
                    model.selfUser,
                    [this.apiConfig.FranForceConstants.RoleConstants.HelpDeskRole]
                );

                return this.identityManager.GetManagedUser(managedUserId, { readonly: model.isManagedUserReadOnly })
            })
            .then((response) =>
            {
                model.managedUser = {
                    ...response.data,
                    Roles: model.allRoles
                        .filter((r) =>
                        {
                            return response.data.RoleIds.some(rId => rId == r.Id);
                        })
                };

                return <userStateObj>model;
            });
    }

    GetDefaultObject(): userStateObj
    {
        return {
            managedUser: null,
            isManagedUserReadOnly: null,
            selfUser: null,
            allRoles: null,
            managedRoles: null
        };
    }
}