import { BaseStateHandler } from "StateHandlers/BaseStateHandler";
import { IdentityManager } from "Services/Resources/IdentityManager";

export class AccountInfoStateHandler extends BaseStateHandler<IUserInfoViewModel, string> {

    constructor(
        private identityManager: IdentityManager
    )
    {
        super();
    }

    Get(token: string): angular.IPromise<IUserInfoViewModel>
    {
        return this.identityManager.GetLoggedInUserInfo()
            .then((response) =>
            {
                return response.data;
            })
    }

    GetDefaultObject(): IUserInfoViewModel
    {
        return <any>{};
    }
}