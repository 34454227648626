import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RestAxiosClient } from "Clients/RestAxiosClient";
import { IRestAxiosClient, IRestAxiosQueryClient, IRestAxiosGetClient, IRestAxiosPostClient } from "Clients/IRestAxiosClient";
import { ConvertCompositeKeyToString } from "Services/Utility/ODataHelperService";
import { AxiosRequestConfig } from "axios";

interface CompositeId
{
    RoyaltyDisclosureDocumentRatePlanMinimumId: number;
    MonthId: number;
}

interface IRoyaltyDisclosureDocumentRatePlanMinimumMonthsAppliedClient extends
    IRestAxiosQueryClient<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied>,
    IRestAxiosGetClient<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied, CompositeId>,
    IRestAxiosPostClient<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied>
{

}

export class RoyaltyDisclosureDocumentRatePlanMinimumMonthsAppliedClient implements
    IRoyaltyDisclosureDocumentRatePlanMinimumMonthsAppliedClient

{
    private client: RestAxiosClient<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied, CompositeId>;

    constructor()
    {
        this.client = new RestAxiosClient(
            FranForceAxiosClientBuilder.BuildCoreBaseClient(),
            "/RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied"
        );

        this.client.IdToString = (id) => { return ConvertCompositeKeyToString(id); };
    }

    async Get(id: CompositeId, parameters: ODataQueryParameters, config?: AxiosRequestConfig): Promise<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied>
    {
        return this.client.Get(id, parameters, config);
    }

    async Query(parameters: ODataQueryParameters, config?: AxiosRequestConfig): Promise<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied[]>
    {
        return this.client.Query(parameters, config);
    }

    /**
     * Works as both create and update
     * @param model
     * @param config
     */
    async Post(model: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied, config?: AxiosRequestConfig): Promise<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied>
    {
        return this.client.Post(model, config);
    }
}

const royaltyDisclosureDocumentRatePlanMinimumMonthsAppliedClient = new RoyaltyDisclosureDocumentRatePlanMinimumMonthsAppliedClient() as IRoyaltyDisclosureDocumentRatePlanMinimumMonthsAppliedClient;

export { royaltyDisclosureDocumentRatePlanMinimumMonthsAppliedClient };