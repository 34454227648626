import { IFRMStateParams } from "Controllers/Rms/FranchisorRoyaltyManagement/frmRouter";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { Helpers } from "Utility/Helpers";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { franchisorDataModel } from "../../../Models/Royalty/Franchisor/FranchisorDataModel";
import { royaltySystemDataModel } from "../../../Models/Royalty/RoyaltySystemType/RoyaltySystemDataModel";

export class FRMGeneralController
{
    franchisorId: number;
    franchisor: franchisorDataModel;

    dayOfWeekDropDownOptions: kendo.ui.DropDownListOptions;
    royaltySystemDropDownOptions: kendo.ui.DropDownListOptions;

    selectedRoyaltySystemId: number;
    selectedDayOfWeek: number;

    static $inject = [
        '$scope',
        '$stateParams',
        'rpmUiApiResources',
        'identityManager',
        'royaltyApiResources'
    ];

    constructor(
        private $scope: ng.IScope,
        private $stateParams: IFRMStateParams,
        private rpmUiApiResources: RpmUiApiResources,
        private identityManager: IdentityManager,
        private royaltyApiResources: RoyaltyApiResources
    ) {
        
    }

    $onInit() 
    {
        this.franchisorId = parseInt(this.$stateParams.franchisorId);
        

        this.dayOfWeekDropDownOptions = {
            dataTextField: "name",
            dataValueField:  "id",
            dataSource: new kendo.data.DataSource({ data: Helpers.DaysOfWeekObjects() }),
            valuePrimitive: true
        };

        this.royaltySystemDropDownOptions = {
            valuePrimitive: true,
            autoWidth: true,
            dataTextField: nameof<royaltySystemDataModel>(o => o.description),
            dataValueField: nameof<royaltySystemDataModel>(o => o.royaltySystemId),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        this.royaltyApiResources.GetRoyaltySystemTypes(true)
                            .then((systemsResponse) => {
                                options.success(systemsResponse.data)
                            })
                            .catch((err) => {
                                options.error(err);
                            });
                    }
                }
            })
        }

        this.royaltyApiResources.GetFranchisorById(this.franchisorId)
            .then((franchisorResponse) =>
            {
                this.franchisor = franchisorResponse.data;
                this.selectedRoyaltySystemId = this.franchisor.royaltySystemId;
                this.selectedDayOfWeek = this.franchisor.royaltyRunDayOfWeek - 1;
            });
    }

    SubmitForm(isValid: boolean)
    {
        if (!isValid) {
            alert("Not Saved: Form has invalid entries");
            return;
        }

        this.royaltyApiResources.SetFranchisorRoyaltyFields(
            this.franchisorId,
            this.selectedRoyaltySystemId,
            parseInt(<any>this.selectedDayOfWeek) + 1
        )
            .then((response) =>
            {
                this.$scope.$emit('saveDocumentComplete', { successful: true });
            },
            (error) =>
            {
                console.log(error);

                if (error.data.ExceptionMessage)
                    this.$scope.$emit('frnError', { successful: false, message: error.data.ExceptionMessage });
                else if (error.data.Message)
                    this.$scope.$emit('frmError', { successful: false, message: error.data.Message });
            });
    }
}