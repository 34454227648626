
export class IndexAccountController implements ng.IController {
    token: string;
    localStorage: ILocalStorage;
    
    static $inject = ["$http"];

    constructor(private $http: ng.IHttpService)
    {
    }

    $onInit()
    {
        this.localStorage = <ILocalStorage>localStorage;
        this.token = this.localStorage.FranForceAuthToken;
    }

    Logout()
    {
        this.localStorage.FranForceAuthToken = null;
        this.localStorage.FranForceAuthTokenType = null;
        this.localStorage.PassWordExpirationDate = null;
        this.localStorage.PasswordDisplayStatus = null;
        this.$http.post("/Account/Logout", null).finally(() => {
            window.location.href = "/";
        });
    }
}