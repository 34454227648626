import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyPeriodsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyPeriod(id: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriods/" + id
        };

        return this.$http<RpmEntities.RoyaltyPeriodViewModel>(config);
    }

    GetRoyaltyPeriodsByFranchisor(franchisorId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriods/ByFranchisor/" + franchisorId
        };

        return this.$http<RpmEntities.RoyaltyPeriodViewModel[]>(config);
    }

    GetRoyaltyPeriodDatesByBeforeDate(beforeDate: Date|string)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriods/BeforeDate/" + beforeDate
        };

        return this.$http<RpmEntities.RoyaltyPeriodDropDownViewModel[]>(config);
    }

    GetReportingPeriodYearListingByFranchisorId(franchisorId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriods/ReportingPeriodYearListing/ByFranchisor/" + franchisorId
        };

        return this.$http<RpmEntities.RoyaltyReportingPeriodYearListingViewModel[]>(config);
    }

    GetReportingPeriodInfo(royaltyReportingPeriodId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriods/ByReportingPeriod/" + royaltyReportingPeriodId
        };

        return this.$http<RpmEntities.RoyaltyPeriodViewModel[]>(config);
    }

    LoadReportingPeriodInfo(year: number, royaltyPeriodFrequencyId: number, franchisorId: number, createdUser: string, periodEndDay: string, periodStartDate: string, periodEndDate: string) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriods/LoadReportingPeriodInfo/" + year + "/" + royaltyPeriodFrequencyId + "/" + franchisorId + "/" + createdUser + "/" + periodEndDay + "/" + periodStartDate + "/" + periodEndDate
        };

        return this.$http<RpmEntities.RoyaltyPeriodViewModel[]>(config);
    }

    AddReportingPeriodInfo(year: number, royaltyPeriodFrequencyId: number, franchisorId: number, createdUser: string, periodEndDay: string) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyPeriods/AddReportingPeriodInfo/" + year + "/" + royaltyPeriodFrequencyId + "/" + franchisorId + "/" + createdUser + "/" + periodEndDay 
        };

        return this.$http<number>(config);
    }

    LoadEditReportingPeriodInfo(year: number, royaltyPeriodFrequencyId: number, franchisorId: number, createdUser: string, periodEndDay: string, startDate: string, endDate: string) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriods/LoadEditReportingPeriodInfo/" + year + "/" + royaltyPeriodFrequencyId + "/" + franchisorId + "/" + createdUser + "/" + periodEndDay + "/" + startDate + "/" + endDate
        };

        return this.$http<RpmEntities.RoyaltyPeriodViewModel[]>(config);
    }

    EditReportingPeriodInfo(year: number, royaltyPeriodFrequencyId: number, franchisorId: number, createdUser: string, periodEndDay: string, startDate: string, endDate: string) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyPeriods/EditReportingPeriodInfo/" + year + "/" + royaltyPeriodFrequencyId + "/" + franchisorId + "/" + createdUser + "/" + periodEndDay + "/" + startDate + "/" + endDate
        };

        return this.$http<number>(config);
    }

    DeleteReportingPeriod(royaltyReportingPeriodId: number, createdUser: string) {
        let config: ng.IRequestConfig = {
            method: "DELETE",
            url: this.endpointPrefix + "/RoyaltyPeriods/ByReportingPeriod/" + royaltyReportingPeriodId + "/" + createdUser
        };

        return this.$http<number>(config);
    }
}