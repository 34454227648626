import { UrlQueryParameters } from "Utility/UrlQueryParameters";

export class UriHelperService
{
    queryParameters: UrlQueryParameters;

    static $inject = [
        "$window"
    ];

    constructor(
        private $window: ng.IWindowService
    )
    {
        this.queryParameters = new UrlQueryParameters(this.$window.window);
    }
}