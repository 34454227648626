import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyItemsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyItemsByFranchisor(franchisorId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyItems/ByFranchisor/" + franchisorId
        };

        return this.$http<RpmEntities.usp_RoyaltyItemResult[]>(config);
    }

    GetRoyaltyItemFeeTypesByFranchisor(franchisorId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyItems/FeeTypes/ByFranchisor/" + franchisorId
        };

        return this.$http<RpmEntities.usp_RoyaltyItemsRoyaltyFeeTypesXRefResult[]>(config);
    }

    CreateRoyaltyItem(model: RpmEntities.usp_RoyaltyItemResult)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyItems",
            data: model
        };

        return this.$http<number>(config);
    }

    CreateRoyaltyItemFeeType(model: RpmEntities.usp_RoyaltyItemsRoyaltyFeeTypesXRefResult)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyItems/FeeTypes",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyItem(id: number, model: RpmEntities.usp_RoyaltyItemResult)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyItems/" + id,
            data: model
        };

        return this.$http<void>(config);
    }

    UpdateRoyaltyItemFeeType(id: number, model: RpmEntities.usp_RoyaltyItemsRoyaltyFeeTypesXRefResult)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyItems/FeeTypes/" + id,
            data: model
        };

        return this.$http<void>(config);
    }
}
