import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import * as _ from "underscore"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class BatchManagementInvoiceGridComponentController implements ng.IController {
    // -- Bindings --
    invoicesDataSource: kendo.data.DataSource;
    onInvoicesPosted: (params: {}) => void;
    // --

    isLoading: boolean;

    invoiceGrid: kendo.ui.Grid;
    areInvoicesDownloaded: boolean;

    static $inject = [
        'coreApiResources',
        'rpmUiApiResources',
        'rpmHelper',
        '$timeout',
        '$q'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService
    ) {
    }

    $onInit() {
        if (this.invoicesDataSource)
            this.invoicesDataSource.read()
    }

    $postLink() {
        this.InitInvoiceGrid();
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.invoicesDataSource)] && this.invoiceGrid) {
            this.areInvoicesDownloaded = false;
            this.invoiceGrid.setDataSource(this.invoicesDataSource);
        }
    }

    InitInvoiceGrid() {
        let invoicesColumns: kendo.ui.GridColumn[] = [{
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.Date),
            title: "Date",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.InvoiceType),
            title: "InvoiceType",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.InvoiceNumber),
            title: "InvoiceNumber",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.CustomerID),
            title: "CustomerID",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.ItemDescription),
            title: "ItemDescription",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.ItemNumber),
            title: "ItemNumber",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.Quantity),
            title: "Quantity",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.UnitPrice),
            title: "UnitPrice",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.ExtAmount),
            title: "ExtAmount",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.OverRideTaxDate),
            title: "OverRideTaxDate",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.GPDatabase),
            title: "GPDatabase",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.AvalaraCompanyCode),
            title: "AvalaraCompanyCode",
        }];

        let invoicesGridOptions: kendo.ui.GridOptions = {
            columns: invoicesColumns,
            toolbar: [
                {
                    template: `<label style="margin: 0px 8px; width: 300px;">
                                    <span style="display: block">File Name</span>
                                    <input type="text" style="height: auto; width: 100%; box-sizing: border-box;" ng-model="$ctrl.${nameof(this.invoiceGrid)}.${nameof.full<kendo.ui.Grid>(o => o.options.excel.fileName)}" /> 
                                </label>`
                }, {
                    template: `<button ng-click='$ctrl.${nameof(this.DownloadInvoiceExcel)}()'>
                                    <span style="display: block">Download</span>
                                </button>`
                }
            ],
            excel: {
                fileName: `BatchInvoices-` +
                    `${new Date().getFullYear()}_` +
                    `${new Date().getMonth() + 1 < 10 ? "0" : ""}${new Date().getMonth() + 1}_` +
                    `${new Date().getDate() < 10 ? "0" : ""}${new Date().getDate()}.xlsx`,
                filterable: true,
                allPages: true
            },
            dataBound: (e) => {
            },
            excelExport: (e) => {
                //let date = new Date();
                //(<any>e.workbook).fileName = `FranchisorBatchesExport-${date.getUTCFullYear()}_${date.getUTCMonth()}_${date.getUTCDay()}.xlsx`;
            },
            selectable: false,
            editable: false,
            scrollable: true,
            sortable: true,
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: false
        };

        this.invoiceGrid.setOptions(invoicesGridOptions);
    }

    DownloadInvoiceExcel() {
        this.invoiceGrid.saveAsExcel();
        this.$timeout(() => {
            this.areInvoicesDownloaded = true;
        }, 1000);
    }

    MarkInvoicesPosted() {
        let exportIds = <number[]>this.invoiceGrid.dataSource.data()
            .map((result: { RoyaltyInvoiceExportBatchID: number }) => {
                return result.RoyaltyInvoiceExportBatchID;
            });
        exportIds = _.uniq(exportIds);

        return this.$q.all(
            exportIds.map((id) => {
                return this.rpmUiApiResources.SetRoyaltyInvoiceExportBatchPosted(id)
            })
        )
            .then((response) => {
                if (this.onInvoicesPosted) {
                    this.onInvoicesPosted({});
                }
            });
    }

    static BindComponent(app: ng.IModule) {
        app.component('bmInvoiceGrid', {
            bindings: {
                [nameof<BatchManagementInvoiceGridComponentController>(o => o.invoicesDataSource)]: "<",
                [nameof<BatchManagementInvoiceGridComponentController>(o => o.onInvoicesPosted)]: "&",
            },
            controller: BatchManagementInvoiceGridComponentController,
            templateUrl: "/Templates/BatchManagement/bmInvoiceGrid.html"
        });

    }
}

