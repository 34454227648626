import { RmsDashboardSalesVarianceResultVm, RoyaltyApprovalClient, RoyaltySyncInvoicesForApprovalRm, RoyaltySyncInvoicesForApprovalVm } from '@nbly/royalty-orchestrations-clients';
import { AxiosInstance } from 'axios';
import { FranForceAxiosClientBuilder } from '../../../../Clients/FranForceAxiosClientBuilder';
import { selectedInvoiceForRejectionModel } from '../../../../../Models/RmsApprovals/selectedInvoiceForRejectionModel';
import { isArray } from 'underscore';

export class rmsPreApprovalQueueGridController implements ng.IController {

    //bindings
    showLoader: (params: { isLoading: boolean }) => void;
    //end-bindings

    royaltyOrchestrationsClient: AxiosInstance;
    royaltyApprovalClient: RoyaltyApprovalClient
    rmsPreApprovalGrid: kendo.ui.Grid;
    rmsPreApprovalGridOptions: kendo.ui.GridOptions;
    toolTipOptions: kendo.ui.TooltipOptions;

    royaltyReportBatchId: number;
    royaltyApprovalStatusTypeId: number;
    royaltyApprovalStatus: string;
    royaltyApprovalRequiredReasonTypeId: number;
    royaltyApprovalRequiredReasonType: string;

    selectedInvoicesForRejection: selectedInvoiceForRejectionModel[];

    static $inject = [
        "$rootScope",
        "$scope",
    ];

    constructor(
        private $rootScope: ng.IRootScopeService,
        private $scope: ng.IScope,
    ) {
        this.royaltyOrchestrationsClient = FranForceAxiosClientBuilder.BuildRoyaltyOrchestrationsBaseClient();
        this.royaltyApprovalClient = new RoyaltyApprovalClient("", this.royaltyOrchestrationsClient);
    }

    $onInit() {
        this.royaltyReportBatchId = 0;
        this.selectedInvoicesForRejection = [];
        this.EmitSelectedInvoicesForRejection();
        this.LoadRmsPreApprovalGrid();

        this.$rootScope.$on('preApprovalAllInputsSelected', async (event, args) => {
            this.OnClearInvoiceSelection();
            if (args) {
                this.royaltyReportBatchId = args.royaltyReportBatchId;
                this.royaltyApprovalStatusTypeId = args.royaltyApprovalStatusTypeId;
                this.royaltyApprovalStatus = args.royaltyApprovalStatus;
                this.royaltyApprovalRequiredReasonTypeId = args.royaltyApprovalRequiredReasonTypeId;
                this.royaltyApprovalRequiredReasonType = args.royaltyApprovalRequiredReasonType;
            }

            if (this.rmsPreApprovalGrid && this.rmsPreApprovalGrid.dataSource)
                this.rmsPreApprovalGrid?.dataSource?.read();

        });

        this.$rootScope.$on('refreshPreApprovalGrid', async (event, args) => {
            this.OnClearInvoiceSelection();
            if (this.rmsPreApprovalGrid && this.rmsPreApprovalGrid.dataSource)
                this.rmsPreApprovalGrid?.dataSource?.data([]);
        });

        this.$rootScope.$on('reassignBatchApprovalStatus', async (event, args) => {
            if (args)
                this.royaltyApprovalStatusTypeId = args.royaltyApprovalStatusTypeId;
        });
    }

    EmitSelectedInvoicesForRejection() {
        this.$rootScope.$emit('preApprovalRejectInvoiceChanged', {
            selectedInvoicesForRejection: this.selectedInvoicesForRejection
        });
    }

    LoadRmsPreApprovalGrid() {

        let rmsPreApprovalGridDataSource = new kendo.data.DataSource({
            transport: {
                read: async (options) => {

                    if (options && options.data) {

                        let royaltySyncInvoicesForApprovalRm: RoyaltySyncInvoicesForApprovalRm = {
                            royaltyReportBatchId: this.royaltyReportBatchId ?? 0,
                            pageNumber: options.data.page,
                            rowsOfPage: options.data.pageSize ?? 200000000,
                            sortColumn: (options.data.sort && (options.data.sort instanceof Array && options.data.sort.length > 0)) ? options.data.sort[0]['field'] : 'FranchiseeName',
                            sortOrder: (options.data.sort && (options.data.sort instanceof Array && options.data.sort.length > 0)) ? options.data.sort[0]['dir'] : 'asc'
                        }

                        await this.royaltyApprovalClient.royaltySyncInvoicesForApproval(royaltySyncInvoicesForApprovalRm)
                            .then((response) => {
                                if (response && response.result && response.result.result) {

                                    let royaltySyncInvoicesForApprovalVm: RoyaltySyncInvoicesForApprovalVm[] = response.result.result;

                                    if (response.result.result.length > 0) {
                                        this.royaltyApprovalRequiredReasonType = response.result.result[0].reasonForApproval;
                                        this.royaltyApprovalStatus = response.result.result[0].royaltyFranchiseBatchApprovalStatusType;
                                    }

                                    options.success(response.result);

                                    //if not NeedsApproval status disable checkboxes
                                    if (this.royaltyApprovalStatusTypeId != 2) {
                                        angular.element(document.querySelectorAll(".royaltyInvoiceRec")).prop('disabled', true);
                                    }

                                    royaltySyncInvoicesForApprovalVm.forEach(x => {

                                        if (this.selectedInvoicesForRejection.some(y => y.invoiceId == x.royaltyInvoiceId)) {
                                            angular.element(document.querySelector("#royaltyInvoiceRec_" + x.royaltyInvoiceId)).prop('checked', true)
                                        }

                                        if (x.invoiceRejected == true) {
                                            angular.element(document.querySelector("#royaltyInvoiceRec_" + x.royaltyInvoiceId)).prop('checked', true)
                                            angular.element(document.querySelector("#royaltyInvoiceRec_" + x.royaltyInvoiceId)).prop('disabled', true);
                                        }

                                        if (x.royaltyFranchiseBatchApprovalStatusTypeId == 3 || x.royaltyFranchiseBatchApprovalStatusTypeId == 4) {
                                            angular.element(document.querySelector("#royaltyInvoiceRec_" + x.royaltyInvoiceId)).prop('disabled', true);
                                        }
                                    });

                                }
                            })
                            .catch((ex) => console.log("error in fetching invoices for approval", ex))
                            .finally(() => {
                                this.showLoader({ isLoading: false });
                                this.rmsPreApprovalGrid.resize(true);
                                this.$scope.$digest();
                            });
                    }
                }
            },
            serverPaging: true,
            serverSorting: true,
            pageSize: 25,
            schema: {
                model: {
                    id: "royaltyInvoiceId",
                    fields: {
                        licenseNumber: {
                            type: "string"
                        },
                        doingBusinessAs: {
                            type: "string"
                        },
                        franchiseeName: {
                            type: "string"
                        },
                        periodEndDate: {
                            type: "string"
                        },
                        invoiceNumber: {
                            type: "string"
                        },
                        invoiceAmount: {
                            type: "number"
                        },
                        invoiceRejectReason: {
                            type: "string"
                        },
                        invoiceRejected: {
                            type: "boolean"
                        },
                        reasonForApproval: {
                            type: "string"
                        },
                        royaltyFranchiseBatchApprovalStatusType: {
                            type: "string"
                        },
                        franchiseTotalSales: {
                            type: "number"
                        }
                    }
                },
                data: (data) => {
                    return data.result;
                },
                total: (data) => {
                    return data.total;
                }
            }
        });

        let rmsPreApprovalGridColumns: Array<kendo.ui.GridColumn> =
            [
                {
                    field: "royaltyInvoiceId",
                    template: `<div style='text-align:center'><input class="royaltyInvoiceRec" id="royaltyInvoiceRec_{{dataItem.royaltyInvoiceId}}" type='checkbox' ng-click="$ctrl.${nameof(this.OnInvoiceSelectCheck)}(dataItem)"></div>`,
                    sortable: false,
                    title: "Reject Invoice",
                },
                {
                    field: "licenseNumber",
                    title: "License Number",
                },
                {
                    field: "doingBusinessAs",
                    title: "DBA",
                },
                {
                    field: "franchiseeName",
                    title: "Franchisee Name",
                },
                {
                    field: "periodEndDate",
                    title: "Period End Date",
                    template: "#= kendo.toString(kendo.parseDate(periodEndDate, 'yyyy-MM-dd'), 'MM/dd/yyyy') #",
                    sortable: false,
                },
                {
                    field: "invoiceNumber",
                    title: "Invoice Number",
                },
                {
                    field: "invoiceAmount",
                    title: "Amount",
                },
                {
                    field: "invoiceRejectReason",
                    title: "Reject Reason",
                    sortable: false,
                },
                //{
                //    field: "invoiceRejected",
                //    title: "Rejected",
                //    sortable: false,
                //}
            ];

        this.rmsPreApprovalGridOptions = {
            autoBind: false,
            dataSource: rmsPreApprovalGridDataSource,
            columns: rmsPreApprovalGridColumns,
            sortable: true,
            scrollable: true,

            pageable: {
                numeric: true,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true,
            },
        };

        this.toolTipOptions = {
            filter: "tr",
            position: "right",
            content: function (e) {
                var grid = e.target.closest(".k-grid").getKendoGrid();
                var dataItem = grid.dataItem(e.target.closest("tr"));
                if (dataItem)
                    return ("Franchise Total Sales: " + dataItem.franchiseTotalSales);
                else
                    return null;
            }
        }
    }

    OnInvoiceSelectCheck(dataItem) {
        let invoiceId = dataItem.id;
        let isChecked: boolean = angular.element(document.querySelector("#royaltyInvoiceRec_" + invoiceId)).is(":checked");
        if (isChecked) {
            let selectedInvoiceForRejection: selectedInvoiceForRejectionModel = {
                invoiceId: invoiceId,
                rejectNote: "",
                licenseNumber: dataItem.licenseNumber,
                franchiseeName: dataItem.franchiseeName
            }
            this.selectedInvoicesForRejection.push(selectedInvoiceForRejection)
        }
        else {
            this.RemoveUncheckedInvoiceFromSelectedList(invoiceId);
        }

        this.EmitSelectedInvoicesForRejection();
    }

    RemoveUncheckedInvoiceFromSelectedList(invoiceId: number) {
        if (this.selectedInvoicesForRejection.some(x => x.invoiceId == invoiceId)) {
            this.selectedInvoicesForRejection = this.selectedInvoicesForRejection.filter(function (obj) {
                return (obj.invoiceId != invoiceId);
            });
        }
    }

    OnClearInvoiceSelection() {
        this.selectedInvoicesForRejection = [];
        this.EmitSelectedInvoicesForRejection();
        angular.element(document.querySelectorAll(".royaltyInvoiceRec")).prop('checked', false);
    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsPreApprovalQueueGrid", {
            bindings: {
                [nameof<rmsPreApprovalQueueGridController>(o => o.showLoader)]: "&",
            },
            templateUrl: '/Templates/RMSApproval/RmsPreApprovalQueue/rmsPreApprovalQueueGrid.html',
            controller: rmsPreApprovalQueueGridController
        });
    }
}