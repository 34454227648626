interface ITriStateContentScope extends ng.IScope {
    ngModel: boolean;
    Render: () => void;
}

let triContentDirectiveConfig = [() =>
{
    return {
        restrict: "AE",
        scope: {
            ngModel: "="
        },
        template: "<ng-transclude></ng-transclude>",
        transclude: true,
        link: (scope: ITriStateContentScope, element: angular.IAugmentedJQuery, attr: angular.IAttributes) =>
        {
            scope.Render = () =>
            {
                let trueElements = element.find(".true-content, [true-content], true-content");
                let falseElements = element.find(".false-content, [false-content], false-content");
                let nullElements = element.find(".null-content, [null-content], null-content");

                trueElements.hide();
                falseElements.hide();
                nullElements.hide();

                if (scope.ngModel === true) {
                    trueElements.show();
                }
                else if (scope.ngModel === false) {
                    falseElements.show();
                }
                else {
                    nullElements.show();
                }
            };

            scope.$watch("ngModel", () =>
            {
                scope.Render();
            });

            scope.Render();
        }
    };
}];

export { triContentDirectiveConfig };