import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

interface IIndexServiceScope {
    services: CoreResources.IService[];
    newService: CoreResources.IService;
}

export class IndexServiceController
{
    static $inject = [
        '$scope',
        'coreApiResources'
    ];

    constructor(
        private $scope: IIndexServiceScope,
        private coreApiResources: CoreApiResources
    )
    {
        $scope.services = coreApiResources.ServiceApi.query({ $expand: "ServiceSkus" });
    }
}
