import { AxiosInstance } from "axios";
import { BillingServiceClient, IntegrationFinanceClient, IntegrationFinanceDashboardUserVm, IntegrationFinanceDashboardCompanyCodeVm, IntegrationFinanceDashboardResultRm } from "@nbly/billing-orchestrations-clients";
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";
import { IdentityManager } from "../../../Services/Resources/IdentityManager";

export interface ApplicationSourceVm {
    applicationSource: string | undefined;
    applicationSourceName: string | undefined;
}

export class financeIntegrationDashboardInputComponentController implements ng.IController {

    //bindings
    displaySnackbar: (params: { type: string, message: string }) => void;
    //end bindings

    usersDropdown: kendo.ui.DropDownList;
    user?: string;

    applicationSourceDropdown: kendo.ui.DropDownList;
    applicationSource?: string;

    companyDropdown: kendo.ui.DropDownList;
    company?: string;

    dateFrom: Date;
    dateTo: Date;

    billingOrchestrationsClient: AxiosInstance;
    billingServicesClient: BillingServiceClient
    integrationFinanceClient: IntegrationFinanceClient
    localStorage: ILocalStorage;

    static $inject = [
        '$scope',
        'identityManager',
        '$location',
        '$rootScope',
    ];

    constructor(
        private $scope: ng.IScope,
        private identityManager: IdentityManager,
        private $location: ng.ILocationService,
        private $rootScope: ng.IRootScopeService,
    ) {
        this.billingOrchestrationsClient = FranForceAxiosClientBuilder.BuildBillingOrchestrationsBaseClient();
        this.billingServicesClient = new BillingServiceClient("", this.billingOrchestrationsClient);
        this.integrationFinanceClient = new IntegrationFinanceClient("", this.billingOrchestrationsClient);
    }

    $onInit() {
        this.localStorage = <ILocalStorage>localStorage;
        this.$rootScope.$on('financeIntegrationDashboardRefresh', async (event, args) => {
            this.OnSubmit();
        });
    }

    async $postLink() {
        this.SetDefaultDateRange();
        await Promise.all([this.InitUsersDropdown(), this.InitApplicationSourceDropdown(), this.InitCompanyDropdown()]);
    }

    InitUsersDropdown() {
        this.usersDropdown.setOptions(
            {
                autoBind: true,
                valuePrimitive: true,
                dataValueField: nameof<IntegrationFinanceDashboardUserVm>(o => o.user),
                dataTextField: nameof<IntegrationFinanceDashboardUserVm>(o => o.user),
                optionLabel: "Select Created By User",
                optionLabelTemplate: "Select Created By User",
                autoWidth: true,
                filter: "contains",
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: (options) => {
                            this.integrationFinanceClient.users()
                                .then((response) => {
                                    let users: IntegrationFinanceDashboardUserVm[] = response.result;
                                    options.success(users)

                                    if (this.usersDropdown && this.usersDropdown.dataSource && this.localStorage && this.localStorage.AccountEmail) {
                                        this.usersDropdown.dataSource.data().forEach(x => {
                                            if (this.localStorage.AccountEmail == x["user"]) {
                                                this.usersDropdown.value(x["user"]);
                                                this.usersDropdown.trigger("change");
                                            }
                                        })
                                    }
                                })
                                .catch((err) => {
                                    this.displaySnackbar({ type: "error", message: "Error! Failed to load users." });
                                })
                                .finally(() => {

                                })
                        },
                        
                    }
                }),
                select: async (e) => {

                }
            } as kendo.ui.DropDownListOptions
        );
        this.usersDropdown.setDataSource(this.usersDropdown.options.dataSource);
    }

    InitApplicationSourceDropdown() {
        this.applicationSourceDropdown.setOptions(
            {
                autoBind: true,
                valuePrimitive: true,
                dataValueField: nameof<ApplicationSourceVm>(o => o.applicationSource),
                dataTextField: nameof<ApplicationSourceVm>(o => o.applicationSourceName),
                optionLabel: "Select Application Source",
                optionLabelTemplate: "Select Application Source",
                autoWidth: true,
                filter: "contains",
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: (options) => {
                            let applicationSource: ApplicationSourceVm[] = [{ applicationSource: "RMS" , applicationSourceName:"RMS"}, { applicationSource: "Zorware", applicationSourceName:"Zorware" }, { applicationSource: "IMPORTFILE",  applicationSourceName:"Import Files" }];
                            options.success(applicationSource)
                        },

                    }
                }),
                select: async (e) => {

                }
            } as kendo.ui.DropDownListOptions
        );
        this.applicationSourceDropdown.setDataSource(this.applicationSourceDropdown.options.dataSource);

        let applicationSource = this.$location.absUrl().split('?')[1].split('=')[1];
        if (!applicationSource || applicationSource !== "") {         
            this.applicationSourceDropdown.value(applicationSource);
            this.applicationSource = applicationSource;
            this.applicationSourceDropdown.trigger("change");
            this.applicationSourceDropdown.refresh();
        }

       
    }

    InitCompanyDropdown() {
        this.companyDropdown.setOptions(
            {
                autoBind: true,
                valuePrimitive: true,
                dataValueField: nameof<IntegrationFinanceDashboardCompanyCodeVm>(o => o.companyCode),
                dataTextField: nameof<IntegrationFinanceDashboardCompanyCodeVm>(o => o.companyCode),
                optionLabel: "Select Company",
                optionLabelTemplate: "Select Company",
                autoWidth: true,
                filter: "contains",
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: (options) => {
                            this.integrationFinanceClient.companyCodes()
                                .then((response) => {
                                    let companys: IntegrationFinanceDashboardCompanyCodeVm[] = response.result;
                                    options.success(companys)
                                })
                                .catch((err) => {
                                    this.displaySnackbar({ type: "error", message: "Error! Failed to load companies." });
                                })
                                .finally(() => {

                                })
                        },

                    }
                }),
                select: async (e) => {

                }
            } as kendo.ui.DropDownListOptions
        );
        this.companyDropdown.setDataSource(this.companyDropdown.options.dataSource);
    }

    SetDefaultDateRange() {
        //to date to current date
        var todayDate = new Date();
        $('#financeintegrationDashboard_dateToId').data("kendoDatePicker").value(todayDate);
        $("#financeintegrationDashboard_dateToId").data("kendoDatePicker").trigger("change");
        //this.dateTo = todayDate;

        //from date to last saturdat from current date
        var lastSaturday = new Date(new Date().setDate(todayDate.getDate() - (todayDate.getDay() == 0 ? 7 : todayDate.getDay() + 1)));
        $('#financeintegrationDashboard_dateFromId').data("kendoDatePicker").value(lastSaturday);
        $("#financeintegrationDashboard_dateFromId").data("kendoDatePicker").trigger("change");
        //this.dateFrom = lastSaturday;
    }

    OnSubmit() {
        event.preventDefault()

        if (new Date(this.dateFrom) > new Date(this.dateTo)) {
            this.displaySnackbar({ type: "error", message: "Error! Date From should be less than Date To." });
            return;
        }

        let integrationFinanceDashboardResultRm: IntegrationFinanceDashboardResultRm = {
            createdByUser: this.user == null || this.user == undefined || this.user === "" ? null : this.user,
            applicationSource: this.applicationSource == null || this.applicationSource == undefined || this.applicationSource === "" ? null : this.applicationSource,
            company: this.company == null || this.company == undefined || this.company === "" ? null : this.company,
            dateFrom: this.ConvertDateToISOString(this.dateFrom),
            dateTo: this.ConvertDateToISOString(this.dateTo),
            integrationFinanceInvoiceSourceTypeId: null
        }

        this.$rootScope.$emit('financeIntegrationDashboardFiltersChanged', {
            integrationFinanceDashboardFilters: integrationFinanceDashboardResultRm
        });
    }

    ConvertDateToISOString(dateInput: Date) {
        let date = new Date(dateInput);

        let dateVal = date.getDate().toString();
        if (dateVal.toString().length < 2)
            dateVal = "0" + dateVal;

        let monthVal = (date.getMonth()+1).toString();
        if (monthVal.toString().length < 2)
            monthVal = "0" + monthVal;

        let yearVal = date.getFullYear().toString();

        let isoDateString = yearVal + '-' + monthVal + '-' + dateVal + 'T18:00:00.000Z';
        return isoDateString;
    }

    static BindComponent(app: ng.IModule) {
        app.component("financeIntegrationDashboardInput", {
            bindings: {
                [nameof<financeIntegrationDashboardInputComponentController>(o => o.displaySnackbar)]: "&"
            },
            templateUrl: '/Templates/FinanceIntegrationDashboard/financeIntegrationDashboardInput.html',
            controller: financeIntegrationDashboardInputComponentController
        });
    }
}