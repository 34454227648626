import * as _ from "underscore"
import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import
{
    MeetTheTeamClient, MeetTheTeamInfoWithEmpBioDto, MeetTheTeamInfoDto
} from '@nbly/franchise-employee-orchestrations-clients';
import
{
    FranchiseWebLocationClient,
    FranchiseWebLocationDto
} from '@nbly/franchise-group-api-clients';



export class MeetTheTeamComponent implements ng.IController
{
    franchiseId: number;
    conceptId: number;
    isLoading: boolean;
    franchiseWebLocationId:number;


    //Clients
    employeeOrchestrationsClient: AxiosInstance;
    meetTheTeamClient: MeetTheTeamClient;
    franchiseWebLocationClient: AxiosInstance;
    franchiseWebLocationListClient: FranchiseWebLocationClient;

    //DTOS
    meetTheTeamInfoWithEmpBioDto: MeetTheTeamInfoWithEmpBioDto;
    meetTheTeamInfoDto: MeetTheTeamInfoDto;
    selectedMeetTheTeam: MeetTheTeamInfoDto;

    franchiseWebLocationDtoArray: FranchiseWebLocationDto[];
    franchiseWebLocationDto: FranchiseWebLocationDto;

    //Popup
    meetTheTeamWindow: kendo.ui.Window;
    meetTheTeamWindowOptions: kendo.ui.WindowOptions;
    teamPhotoExsists: boolean;
    isLoadingAfterDrag: boolean;


    static $inject = [
        "$scope",
        "$document"
    ];

    constructor(
        private $scope: ng.IScope,
        private $document: ng.IDocumentService
    )
    {
        this.franchiseWebLocationClient = FranForceAxiosClientBuilder.BuildFranchiseGroupBaseClient();
        this.franchiseWebLocationListClient = new FranchiseWebLocationClient("", this.franchiseWebLocationClient);
        this.employeeOrchestrationsClient = FranForceAxiosClientBuilder.BuildEmployeeOrchestrationsBaseClient();
        this.meetTheTeamClient = new MeetTheTeamClient("", this.employeeOrchestrationsClient);
        this.isLoadingAfterDrag = false;


    }

    async $onInit()
    {
    
    }


    async $postLink()
    {
        this.$document.on('franchiseWebLocationSelector-webLocationSelect', async (ev: any) =>
        {
            this.franchiseWebLocationDto = ev.originalEvent.detail;
            this.conceptId = this.franchiseWebLocationDto.conceptId;
            this.franchiseWebLocationId=this.franchiseWebLocationDto.franchiseWebLocationId;
            await this.GetAllMeetTheTeamList();
            this.loadFranchiseWeblocations();      
        });
    
    }

    async loadFranchiseWeblocations()
    {
        if (this.franchiseId)
        {
            await this.franchiseWebLocationListClient.get_protected_franchiseweblocations_franchises_franchiseid(this.franchiseId)
                .then((res) =>
                {
                    this.franchiseWebLocationDtoArray = res.result;
                    this.$scope.$digest();
                }).catch((err) =>
                {
                    console.log('Error Occured while fetching franchiseWebLocationDtos',this.franchiseWebLocationDtoArray)
                })
        }
    }

    InitDummyMeetTheTeamDTO()
    {
        this.meetTheTeamInfoDto = {
            meetTheTeamInfoId: 0,
            franchiseId: this.franchiseId,
            name: '',
            title: '',
            type: '',
            photoDescription: '',
            altTag: '',
            circularPhotoUrl: '',
            squarePhotoUrl: '',
            photoExtension: '',
            preferredPhotoType: '',
            createdDateTime: null,
            updatedDateTime: null,
            deletedDateTime: null,
            sortOrder: 0,
            webLocationIds:[]
        }



        if(this.franchiseWebLocationDtoArray && this.franchiseWebLocationDtoArray.length){
        /*     this.meetTheTeamInfoDto.webLocationIds=[] */
            this.franchiseWebLocationDtoArray.map((obj)=>{
                this.meetTheTeamInfoDto.webLocationIds.push(obj.franchiseWebLocationId)
            })
        }
        
    }

    async GetAllMeetTheTeamList()
    {

        if (this.franchiseWebLocationId)
        {
            let include_bios = false;
            await this.meetTheTeamClient.franchiseWebLocationId2(this.franchiseWebLocationId, include_bios).then((res) =>
            {
                this.meetTheTeamInfoWithEmpBioDto = res.result;
                this.$scope.$digest();
            })
        } else
        {
            console.log('No Franchise ID Provided!,GetAllMeetTheTeamList')
        }
    }

    async deleteMeetTheTeam(meetTheTeamInfoDto: { staffInfo: MeetTheTeamInfoDto })
    {
        await this.meetTheTeamClient.meetTheTeam2(meetTheTeamInfoDto.staffInfo.meetTheTeamInfoId).then((res) =>
        {

            if (meetTheTeamInfoDto.staffInfo.type == "Team")
            {

                this.meetTheTeamInfoWithEmpBioDto.teamInfo = null;
            } else if (meetTheTeamInfoDto.staffInfo.type == "Staff")
            {
                this.meetTheTeamInfoWithEmpBioDto.staffPhotoInfo = this.meetTheTeamInfoWithEmpBioDto.staffPhotoInfo.filter
                    (function (imageInfo)
                    {
                        return imageInfo.meetTheTeamInfoId !== meetTheTeamInfoDto.staffInfo.meetTheTeamInfoId;
                    });
            }


            this.$scope.$digest();

        }).catch((err) =>
        {
            console.log('Error Occured While Deleteing')
        })
    }



    onOrderChange()
    {
        this.isLoadingAfterDrag = true;
        let arrayOfMeetTheTeamIds = [];
        this.meetTheTeamInfoWithEmpBioDto.staffPhotoInfo.map((item) =>
        {
            arrayOfMeetTheTeamIds.push(item.meetTheTeamInfoId);
        });
        this.meetTheTeamClient.sort(this.franchiseId, arrayOfMeetTheTeamIds).then((res) =>
        {
            this.isLoadingAfterDrag = false;
            this.$scope.$digest();
        }).catch((err) =>
        {
            this.isLoadingAfterDrag = false;
            this.$scope.$digest();
            alert('Error occured processing your request!')
        })
    }


    createNewMeetTheTeamRecord()
    {
        this.InitDummyMeetTheTeamDTO();
        this.openMeetTheTeamPopup(null);
    }

    openMeetTheTeamPopup(meetTheTeamInfoDto: { staffInfo: MeetTheTeamInfoDto })
    {
        let template;
        if (this.meetTheTeamInfoWithEmpBioDto.teamInfo && this.meetTheTeamInfoWithEmpBioDto.teamInfo.franchiseId)
        {
            //If team photo exsists
            this.teamPhotoExsists = true;
        } else
        {
            this.teamPhotoExsists = false;
        }

        if (meetTheTeamInfoDto && meetTheTeamInfoDto.staffInfo && meetTheTeamInfoDto.staffInfo.meetTheTeamInfoId)
        {
            this.selectedMeetTheTeam = angular.copy(meetTheTeamInfoDto.staffInfo);
            template =
                `<div style="width:80vw;height:80vh">
          <meet-the-team-popup-component
                franchise-id="$ctrl.franchiseId"
                meet-the-team-info-dto="$ctrl.selectedMeetTheTeam"
                on-save="$ctrl.GetAllMeetTheTeamList()"
                team-photo-exsists="$ctrl.teamPhotoExsists"
                franchise-Web-Location-Dto="$ctrl.franchiseWebLocationDtoArray"
                franchise-web-location-id="$ctrl.franchiseWebLocationId"
          >
          </meet-the-team-popup-component> 
            </div>`;

        } else
        {
            template =
                `<div style="width:80vw;height:80vh">
            <meet-the-team-popup-component
                  franchise-id="$ctrl.franchiseId"
                  meet-the-team-info-dto="$ctrl.meetTheTeamInfoDto"
                  on-save="$ctrl.GetAllMeetTheTeamList()"
                  team-photo-exsists="$ctrl.teamPhotoExsists"
                  franchise-Web-Location-Dto="$ctrl.franchiseWebLocationDtoArray"
                  franchise-web-location-id="$ctrl.franchiseWebLocationId"
            >
            </meet-the-team-popup-component> 
              </div>`;


        }

        let options: kendo.ui.WindowOptions = {
            modal: true,
            position: { top: 0 }
        };

        this.meetTheTeamWindow.open();
        this.meetTheTeamWindow.title('Meet The Team');
        this.meetTheTeamWindow.content(template)
        this.meetTheTeamWindow.center();
        this.meetTheTeamWindow.setOptions(options);

    }


    static BindComponent(app: ng.IModule)
    {
        app.component('meetTheTeamComponent', {
            bindings: {
                franchiseId: "<"
            },
            controller: MeetTheTeamComponent,
            templateUrl: "/Templates/MeetTheTeam/MeetTheTeamComponent.html"
        });
    }
}


