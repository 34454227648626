import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";


export class MollyDonationsShelterApiResources 
{
    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {
    }


    GetMollyShelterList() 
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/MsMollyDonations/GetShelters`,
        };

        return this.$http<any>(config);
    }


    CreateNewShelterLabel(payload)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/MsMollyDonations/CreateMsMollyShelters`,
            data:payload
        };

        return this.$http<void>(config);
    }

    UpdateShelterLabel(payload,shelterid)
    {
        console.log('payload',payload)
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/MsMollyDonations/UpdateMsMollyShelters/${shelterid}`,
            data:payload
        };

        return this.$http<void>(config);
    }

    DeleteShelterLabel(shelterid)
    {
        let config: ng.IRequestConfig = {
            method: "DELETE",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/MsMollyDonations/DeleteMsMollyShelters/${shelterid}`,
        };

        return this.$http<void>(config);
    }
}
