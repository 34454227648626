import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RmsStateService } from "Services/State/RmsState";

import * as _ from "underscore"
import { CmWeeksInBusinessCalculatorComponentController } from "Directives/RPM/ContractManagement/cmWeeksInBusinessCalculator";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";



export class cmRoyaltiesRatePlanItemsComponentController implements ng.IController {
    
    contractRatePlanId: number;
    readOnly: boolean;
    isMethodologySpecial: boolean;
    contractId: number;
    conceptId: number;
    onSelectedRatePlanItem: (params: { ratePlanItem: RpmEntities.RoyaltyContractRatePlanItemViewModel, isRemoved: boolean }) => void;
    onLoaded: (params: { self: cmRoyaltiesRatePlanItemsComponentController }) => void;

    isLoading: boolean;
    selectedRatePlanItem: RpmEntities.RoyaltyContractRatePlanItemViewModel;
    contract: RpmEntities.RoyaltyContractViewModel;
    royaltyRatePlanItemTypes: RpmEntities.RoyaltyRatePlanItemTypeViewModel[];
    royaltyRatePlanItemGrid: kendo.ui.Grid;
    royaltyRatePlanItemGridOptions: kendo.ui.GridOptions;
    rollInRateGrids: { [uid: string]: kendo.ui.Grid };
    formController: angular.IFormController;
    calc: CmWeeksInBusinessCalculatorComponentController;
    isDynamicFees: boolean;

    static $inject = [
        'rpmUiApiResources',
        'rpmHelper',
        'identityManager',
        'rmsState',
        '$q',
        '$timeout'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private identityManager: IdentityManager,
        private rmsState: RmsStateService,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService)
    {

    }

    $onInit()
    {
        this.isLoading = true;
        this.rollInRateGrids = {};

       this.isDynamicFees = $('#hdnIsDynamicFeesEnabledCm').val() === 'True';
        this.PullContract();       

        this.rpmUiApiResources.GetAllRoyaltyRatePlanItemTypes().then(typesResponse => {
            this.royaltyRatePlanItemTypes = typesResponse.data;
        })
            .then(() => {
                this.InitRoyaltyRatePlanItemGrid();
            })
            .finally(() => {
                this.isLoading = false;
                this.onLoaded({ self: this });
            })
    }
    
    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.contractRatePlanId)])
        {
            //this.InitRoyaltyRatePlanItemGrid();
            this.$timeout(() => { this.ResetChanges(); }, 0);
        }

        if (changes[nameof(this.contractId)])
        {
            this.PullContract();
        }
    }

    InitRoyaltyRatePlanItemGrid()
    {

        let rpitDataSource = new kendo.data.DataSource({
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {
                    if (!this.contractRatePlanId)
                    {
                        options.success([]);
                        return;
                    }

                    this.rpmUiApiResources.GetRoyaltyContractRatePlanItemsByContractPlanItem(this.contractRatePlanId)
                        .then(
                            (ratePlansResponse) => {
                                options.success(ratePlansResponse.data);
                            },
                            (err) => { options.error(err); }
                        )
                },
                create: (options: kendo.data.DataSourceTransportOptions) => {
                    let createModel: RpmEntities.RoyaltyContractRatePlanItemViewModel = options.data;
                    this.rpmUiApiResources.CreateRoyaltyContractRatePlanItem(createModel).then(
                        (idResponse) => {
                            createModel.ContractRatePlanItemId = idResponse.data;
                            options.success({DisclosureDocumentRatePlanItemId: idResponse.data})
                        }, (err) => { options.error(err) });
                },
                update:  (options: kendo.data.DataSourceTransportOptions)  => {
                    let updateModel: RpmEntities.RoyaltyContractRatePlanItemViewModel = options.data;
                    this.rpmUiApiResources.UpdateRoyaltyContractRatePlanItem(updateModel.ContractRatePlanItemId, updateModel)
                        .then((idResponse) => {
                            options.success();
                        }, (err) => { options.error(err) });
                },
                destroy: (options: kendo.data.DataSourceTransportOptions) => {
                    let deleteModel: RpmEntities.RoyaltyContractRatePlanItemViewModel = options.data;
                    deleteModel.DeletedDateTime = new Date().toISOString();
                    this.rpmUiApiResources.UpdateRoyaltyContractRatePlanItem(deleteModel.ContractRatePlanItemId, deleteModel)
                        .then((idResponse) => {
                            options.success();
                        }, (err) => { options.error(err) });
                },
            },
            sort: [
                { field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(d => d.WeeksInBusinessStart), dir: "asc" },
                { field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(d => d.GrossSalesRangeLow), dir: "asc" },
            ],
            schema: {
                model: {
                    id: "ContractRatePlanItemId",
                    fields: {
                        ContractRatePlanItemId: {
                            type: "number",
                            validation: { required: true },
                            editable: false,
                            defaultValue: 0
                        },
                        RoyaltyRatePlanItemType: {
                            type: "object",
                            validation: { required: true },
                            defaultValue: { Description: "Annual Scale", RoyaltyRatePlanItemTypeID: 1 }
                        },
                        RoyaltyRatePlanItemTypeID: {
                            type: "number",
                            validation: { required: true },
                            defaultValue: 1
                        },
                        WeeksInBusinessStart: {
                            type: "number",
                            validation: { required: true }
                        },
                        WeeksInBusinessEnd: {
                            type: "number",
                            validation: { required: true }
                        },
                        GrossSalesRangeLow: { type: "number", validation: { required: true } },
                        GrossSalesRangeHigh: { type: "number", validation: { required: true } },
                        RoyaltyFeeRateResidential: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        RoyaltyFeeFixedRateAmountResidential: { type: "number", validation: { required: true } },
                        RoyaltyFeeRateCommercial: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        RoyaltyFeeFixedRateAmountCommercial: { type: "number", validation: { required: true } },
                        AdFeeRateResidential: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        AdFeeFixedRateAmountResidential: { type: "number", validation: { required: true } },
                        AdFeeRateCommercial: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        AdFeeFixedRateAmountCommercial: { type: "number", validation: { required: true } },
                        TAFSFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        TAFSFeeFixedRateAmount: { type: "number", validation: { required: true } },
                        TechnologyFeeRate: {
                            type: "number",
                            validation: {
                                required: true
                            }
                        },
                        TechnologyFeeFixedRateAmount: { type: "number", validation: { required: true } },
                        DeletedDateTime: {
                            type: "string",
                            defaultValue: null
                        },
                        HasRollInRateChanges: {
                            type: "boolean",
                            defaultValue: false
                        }
                    }
                }
            },
            pageSize: 30,
            filter: { field: "DeletedDateTime", operator: "isnull" }
        });

        if (!this.isMethodologySpecial) {
            this.isMethodologySpecial = (this.contract?.RoyaltyMethodologyTypeId === this.rpmHelper.SpecialRoyaltyMethodologyTypeId);
            console.log('isMethodologySpecial on before initaliasing columns :' + this.isMethodologySpecial);
        }
        
        let rpitColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.ContractRatePlanItemId),
                title: "ID",
                template: `<button 
                    ng-show="dataItem.ContractRatePlanItemId && $ctrl.isMethodologySpecial"
                    ng-class="'pure-button grid-edit-button ' + ($ctrl.selectedRatePlanItem.ContractRatePlanItemId === dataItem.ContractRatePlanItemId ? 'rpm-selected-row-btn' : '')" 
                    ng-click="$ctrl.SelectRatePlanItem(dataItem) ; $event.preventDefault()">
                        <i ng-class="'fa fa-' + ($ctrl.selectedRatePlanItem.ContractRatePlanItemId === dataItem.ContractRatePlanItemId ? 'check-circle-o' : 'circle-o')" aria-hidden="true"></i>
                        <span ng-bind="dataItem.ContractRatePlanItemId"></span>
                    </button>
                    <span ng-show="!dataItem.ContractRatePlanItemId || !$ctrl.isMethodologySpecial" ng-bind="dataItem.ContractRatePlanItemId"></span>`
            }, {
                field: "RoyaltyRatePlanItemTypeID",
                title: "Rate Plan Item Type",
                editor: (container, options) => {
                    let input = "<input required name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: "Description",
                        dataValueField: "RoyaltyRatePlanItemTypeId",
                        dataSource: {
                            data: this.royaltyRatePlanItemTypes
                        },
                        change: (e) => {
                            var dataItem = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.royaltyRatePlanItemTypes[0];
                            }

                            options.model.set("RoyaltyRatePlanItemTypeID", dataItem.RoyaltyRatePlanItemTypeId);
                        }
                    });
                },
                template: "{{$ctrl.GetRoyaltyPlanItemTypeById(dataItem.RoyaltyRatePlanItemTypeID).Description}}",
                width: "150px"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.WeeksInBusinessStart),
                title: "WIB Start",
                editor: this.rpmHelper.WeeksInBusinessEditor()
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.WeeksInBusinessEnd),
                title: "WIB End",
                editor: this.rpmHelper.WeeksInBusinessEditor()
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.GrossSalesRangeLow),
                title: "Sales Scale Begins", 
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.GrossSalesRangeHigh),
                title: "Sales Scale Ends",
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.RoyaltyFeeRateResidential),
                title: "Res Royalty Fee Rate",
                hidden: this.isMethodologySpecial,
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.RoyaltyFeeFixedRateAmountResidential),
                title: "Res Royalty Fixed Amount",
                hidden: this.isMethodologySpecial,
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.RoyaltyFeeRateCommercial),
                title: "Com Royalty Fee Rate",
                hidden: this.isMethodologySpecial,
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.RoyaltyFeeFixedRateAmountCommercial),
                title: "Com Royalty Fixed Amount",
                hidden: this.isMethodologySpecial,
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.AdFeeRateResidential),
                title: "Res Ad Fee Rate",
                hidden: this.isMethodologySpecial,
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.AdFeeFixedRateAmountResidential),
                title: "Res Ad Fee Fixed Amount",
                hidden: this.isMethodologySpecial,
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.AdFeeRateCommercial),
                title: "Com Ad Fee Rate",
                hidden: this.isMethodologySpecial,
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.AdFeeFixedRateAmountCommercial),
                title: "Com Ad Fee Fixed Amount",
                hidden: this.isMethodologySpecial,
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.TAFSFeeRate),
                title: "TAFS Fee Rate",
                hidden: this.isMethodologySpecial,
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.TAFSFeeFixedRateAmount),
                title: "TAFS Fee Fixed Amount",
                hidden: this.isMethodologySpecial,
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.TechnologyFeeRate),
                title: "Tech Fee Rate",
                hidden: this.isMethodologySpecial,
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(o => o.TechnologyFeeFixedRateAmount),
                title: "Tech Fee Fixed Amount",
                hidden: this.isMethodologySpecial,
                format: "{0:c}"
            }, {
                field: "HasRollInRateChanges",
                title: "Has Roll In Rate Changes",
                hidden: true
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }];

        for (let col of rpitColumns)
        {
            col.headerAttributes = {
                style: "white-space: normal; border-left: #AAA 1px solid;"
            };
        }
        rpitColumns[0].headerAttributes = null;

        this.royaltyRatePlanItemGridOptions = {
            selectable: "row",
            dataSource: rpitDataSource,
            columns: rpitColumns,
            editable: !this.readOnly ? { confirmation: false } : false,
            navigatable: true,
            scrollable: false, 
            pageable: true,
            remove: (e) =>
            {
                if (this.selectedRatePlanItem && e.model.id === this.selectedRatePlanItem.ContractRatePlanItemId)
                {
                    this.$timeout(() => {
                        this.SelectRatePlanItem(null, true);
                    }, 0);
                }
            }
        };
    }
    
    
    GetRollInRateGridOptions(ratePlanItem: RpmEntities.RoyaltyContractRatePlanItemViewModel & kendo.data.Model): kendo.ui.GridOptions
    {
        let rirDataSource = new kendo.data.DataSource({
            batch: true,
            pageSize: 20,
            schema: {
                 model: {
                    id: "typeFee",
                    fields: {
                        [nameof<IRollInRateItem>(o => o.ParentId)]: {
                            type: "string",
                            validation: { required: true },
                            editable: false,
                            defaultValue: 0
                        },
                        [nameof<IRollInRateItem>(o => o.typeFee)]: {
                            type: "string",
                            validation: { required: true },
                            editable: false,
                             
                        },
                        [nameof<IRollInRateItem>(o => o.royaltyFeeRate)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<IRollInRateItem>(o => o.royaltyFeeFixedRateAmount)]: { type: "number", validation: { required: true } },
                        [nameof<IRollInRateItem>(o => o.adFeeRate)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<IRollInRateItem>(o => o.adFeeFixedRateAmount)]: { type: "number", validation: { required: true } }
                    }
                 }
            },
             transport: {
                read: (e: kendo.data.DataSourceTransportReadOptions) => {
                    let rollIn: Partial<IRollInRateItem> = {
                        ParentId: ratePlanItem.ContractRatePlanItemId,
                        typePrefix: "Medium",
                        typeFee: "Roll-in",
                        royaltyFeeRate: ratePlanItem.RollInRoyaltyFeeRate,
                        royaltyFeeFixedRateAmount: ratePlanItem.RollInRoyaltyFeeFixedRateAmount,
                        adFeeRate: ratePlanItem.RollInAdFeeRate,
                        adFeeFixedRateAmount: ratePlanItem.RollInAdFeeFixedRateAmount
                    };
                    e.success([rollIn]);
                },
                update: (e: kendo.data.DataSourceTransportOptions) => {
                    let rollInRate: IRollInRateItem = e.data.models[0];
                    ratePlanItem.RollInRoyaltyFeeRate = rollInRate.royaltyFeeRate;
                    ratePlanItem.RollInRoyaltyFeeFixedRateAmount = rollInRate.royaltyFeeFixedRateAmount;
                    ratePlanItem.RollInAdFeeRate = rollInRate.adFeeRate;
                    ratePlanItem.RollInAdFeeFixedRateAmount = rollInRate.adFeeFixedRateAmount;

                    if (ratePlanItem.ContractRatePlanItemId)
                    {
                        //If it's a new item, this flag doesn't need to be set as it will already be gaurenteed to be created.
                        this.royaltyRatePlanItemGrid.dataSource
                            .get(ratePlanItem.ContractRatePlanItemId)
                            .set("HasRollInRateChanges", true);
                    }
                     
                    e.success(e.data.models);
                },
                destroy: (e: kendo.data.DataSourceTransportOptions) => {
                    e.error('Destory is not defined for roll in rates, as it should not be possible.')
                },
                create: (e: kendo.data.DataSourceTransportOptions) => {
                    e.error('Create is not defined for roll in rates, as it should not be possible.')
                },
             }
        });
        let rirColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<IRollInRateItem>(o => o.ParentId),
                title: "ContractRatePlanItemId",
                hidden: true
            }, {
                field: nameof<IRollInRateItem>(o => o.typeFee),
                title: "Type Fee"
            }, {
                field: nameof<IRollInRateItem>(o => o.royaltyFeeRate),
                title: "Royalty Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<IRollInRateItem>(o => o.royaltyFeeFixedRateAmount),
                title: "Royalty Fee Fixed Rate Amount",
                format: "{0:c}"
            }, {
                field: nameof<IRollInRateItem>(o => o.adFeeRate),
                title: "Ad Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<IRollInRateItem>(o => o.adFeeFixedRateAmount),
                title: "Ad Fee Fixed Rate Amount",
                format: "{0:c}"
            }];

        return {
            columns: rirColumns,
            dataSource: rirDataSource,
            editable: !this.readOnly,
            navigatable: true,
            scrollable: false
        };
    }

    PullContract()
    {
        return this.$timeout(() =>
        {
            return this.rmsState.royaltyContractStateHandler.Load(this.contractId, this.isDynamicFees)
                .then((contract) => { this.contract = contract; });
        }, 0);
    }

    SelectRatePlanItem(ratePlanItem: RpmEntities.RoyaltyContractRatePlanItemViewModel, isRemoved: boolean = false)
    {
        if (this.selectedRatePlanItem === ratePlanItem)
            ratePlanItem = null;

        this.selectedRatePlanItem = ratePlanItem;
        if (this.onSelectedRatePlanItem)
        {
            this.onSelectedRatePlanItem({ ratePlanItem: this.selectedRatePlanItem, isRemoved: isRemoved });
        }
    }

    
    SelectRowById(id: number)
    {
        let dataItem = this.royaltyRatePlanItemGrid.dataSource.get(id);
        if (dataItem)
            this.SelectRatePlanItem(<any>dataItem);
    }
    
    GetRoyaltyPlanItemTypeById(planItemTypeId: number)
    {
        return _.find(this.royaltyRatePlanItemTypes, (rpit) => { return rpit.RoyaltyRatePlanItemTypeId === planItemTypeId; });
    }

    AddNewRecord()
    {
        if (!this.readOnly)
        {
            let defaultRatePlanItem: RpmEntities.RoyaltyContractRatePlanItemViewModel = {
                ContractRatePlanItemId: 0,
                ContractRatePlanId: this.contractRatePlanId,
                WeeksInBusinessStart: 0,
                RoyaltyRatePlanItemTypeID: 2,
                GrossSalesRangeLow: 0,
                GrossSalesRangeHigh: 0,
                RoyaltyFeeRateResidential: 0,
                RoyaltyFeeFixedRateAmountResidential: 0,
                RoyaltyFeeRateCommercial: 0,
                RoyaltyFeeFixedRateAmountCommercial: 0,
                AdFeeRateResidential: 0,
                AdFeeFixedRateAmountResidential: 0,
                AdFeeRateCommercial: 0,
                AdFeeFixedRateAmountCommercial: 0,
                RollInAdFeeFixedRateAmount: 0,
                RollInAdFeeRate: 0,
                RollInRoyaltyFeeFixedRateAmount: 0,
                RollInRoyaltyFeeRate: 0,
                TechnologyFeeRate: 0,
                TechnologyFeeFixedRateAmount: 0,
                TAFSFeeRate: 0,
                TAFSFeeFixedRateAmount: 0,
                WeeksInBusinessEnd: 0,
                PerCapitaLow: 0,
                CreatedDateTime: kendo.toString(new Date(), 'd'),
                CreatedUser: null,
                UpdatedDateTime: null,
                UpdatedUser: null,
                DeletedDateTime: null,
                RoyaltyContractRatePlanItemDetails: []
            };
            
            this.royaltyRatePlanItemGrid.dataSource.add(defaultRatePlanItem);
        }
    }
    
    CancelChanges()
    {
        if (!this.royaltyRatePlanItemGrid)
            return false;

        if (!this.readOnly)
        {
            Object.keys(this.rollInRateGrids).forEach(key => {
                this.rollInRateGrids[key].dataSource.cancelChanges();
            });
            this.royaltyRatePlanItemGrid.dataSource.cancelChanges();
        }
    }
    
    HasChanges()
    {
        if (!this.royaltyRatePlanItemGrid)
            return false;

        return this.royaltyRatePlanItemGrid.dataSource.hasChanges() ||
            Object.keys(this.rollInRateGrids)
                .map(key => { return this.rollInRateGrids[key].dataSource.hasChanges(); })
                .some(hasChanges => { return hasChanges; });
    }

    ResetChanges()
    {
        if (!this.royaltyRatePlanItemGrid)
            return this.$q.resolve();

        this.CancelChanges();

        return this.$q.when(this.royaltyRatePlanItemGrid.dataSource.read()).then(() => {
            this.royaltyRatePlanItemGrid.refresh();
            
            this.$timeout(() =>
            {
                this.formController.$setPristine();
            }, 1000);

            this.rollInRateGrids = {};
        });
    }

    SaveChanges()
    {
        let syncPromises: ng.IPromise<any>[] = [];
        Object.keys(this.rollInRateGrids).forEach(key =>
        {
            syncPromises.push(this.$q.when(this.rollInRateGrids[key].dataSource.sync()));
        });
        return this.$q.all(syncPromises).then(() => {
            return this.$q.when(this.royaltyRatePlanItemGrid.dataSource.sync()).then(() =>
            {
                return <ng.IPromise<any>>this.ResetChanges();
            });
        })
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("cmRoyaltiesRatePlanItems", {
                bindings: {
                    [nameof<cmRoyaltiesRatePlanItemsComponentController>(o => o.contractRatePlanId)]: "<",
                    [nameof<cmRoyaltiesRatePlanItemsComponentController>(o => o.readOnly)]: "<",
                    [nameof<cmRoyaltiesRatePlanItemsComponentController>(o => o.isMethodologySpecial)]: "<",
                    [nameof<cmRoyaltiesRatePlanItemsComponentController>(o => o.contractId)]: "<",
                    [nameof<cmRoyaltiesRatePlanItemsComponentController>(o => o.conceptId)]: "<",
                    [nameof<cmRoyaltiesRatePlanItemsComponentController>(o => o.onSelectedRatePlanItem)]: "&?",
                    [nameof<cmRoyaltiesRatePlanItemsComponentController>(o => o.onLoaded)]: "&?"
                },
                templateUrl: '/Templates/ContractManagement/cmRoyalties/RatePlanItems.html',
                controller: cmRoyaltiesRatePlanItemsComponentController
            });
    }
}