import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyReportBatchStatusesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetAllRoyaltyReportBatchStatuses()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyReportBatchStatuses/"
        };

        return this.$http<RpmEntities.usp_RoyaltyReportBatchStatus_GetByRoyaltyReportBatchStatusID_Result[]>(config);
    }

    GetRoyaltyReportBatchStatusById(id: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyReportBatchStatuses/" + id
        };

        return this.$http<RpmEntities.usp_RoyaltyReportBatchStatus_GetByRoyaltyReportBatchStatusID_Result>(config);
    }

    GetRoyaltyReportBatchStatusesForBatchManagement()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyReportBatchStatuses/ByBatchManagement"
        };

        return this.$http<RpmEntities.usp_RoyaltyReportBatchStatus_GetBySetViaBatchManagement_Result[]>(config);
    }
}
    
