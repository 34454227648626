
import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";

import { KendoUtil } from "Utility/KendoUtil";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";


interface IIndexDisasterPrepProfileScope {
    dpps: IDisasterPrepProfileGridDisplay[];
    gridOptions: kendo.ui.GridOptions;
}

interface IDisasterPrepProfileGridDisplay extends DppResources.IDisasterPrepProfile
{
    DisplayCreatedDateTime?: string;
    DisplayModifiedDateTime?: string;
    SortCreatedDateTime?: number;
    SortModifiedDateTime?: number;
    DoingBusinessAs?: string;
    LicenseNumber?: string;
    //PdfLink?: string;
}

export class IndexDisasterPrepProfileController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('IndexDisasterPrepProfileController',
            [
                '$scope', 'dppApiResources', 'coreApiResources', "identityManager", '$window',
                ($scope: IIndexDisasterPrepProfileScope,
                    dppApiResources: DppApiResources,
                    coreApiResource: CoreApiResources,
                    identityManager: IdentityManager,
                    $window: ng.IWindowService) =>
                {
                    let queryParams = {};

                    coreApiResource.FranchiseApi.query({ $select: "FranchiseId,LicenseNumber,DoingBusinessAs" })
                        .$promise.then((franchises: Array<CoreResources.IFranchise>) =>
                        {
                            let franchiseDic: { [id: number]: CoreResources.IFranchise } = {};
                            for (let franchise of franchises) {
                                franchiseDic[franchise.FranchiseId] = franchise;
                            }

                            dppApiResources.DisasterPrepProfileApi.query(queryParams).$promise.then(
                                (profiles: DppResources.IDisasterPrepProfile[]) =>
                                {
                                    $scope.dpps = <IDisasterPrepProfileGridDisplay[]>profiles;

                                    let datetimeFormat = "g";

                                    for (let dpp of $scope.dpps) {
                                        let createdDateTime = new Date(dpp.CreatedDateTime);
                                        let modifiedDateTime = new Date(dpp.ModifiedDateTime);

                                        dpp.DisplayCreatedDateTime = kendo.toString(createdDateTime, datetimeFormat);
                                        dpp.DisplayModifiedDateTime = kendo.toString(modifiedDateTime, datetimeFormat);
                                        dpp.SortCreatedDateTime = createdDateTime.getTime();
                                        dpp.SortModifiedDateTime = modifiedDateTime.getTime();
                                        if (franchiseDic[dpp.FranchiseId]) {
                                            dpp.DoingBusinessAs = franchiseDic[dpp.FranchiseId].DoingBusinessAs;
                                            dpp.LicenseNumber = franchiseDic[dpp.FranchiseId].LicenseNumber;
                                        }

                                        //((currentDpp: IDisasterPrepProfileWithDates) =>
                                        //{
                                        //    coreApiResources.GetDppPdfUrl(currentDpp.DppId).then(url =>
                                        //    {
                                        //        currentDpp.PdfLink = url;
                                        //    });
                                        //})(dpp);
                                    }


                                    let mainGrid = angular.element("#mainGrid");
                                    let windowEle = angular.element($window);

                                    let gridHeight = windowEle.height() - mainGrid.offset().top - 15;

                                    if (gridHeight < 250) {
                                        gridHeight = 250;
                                    }

                                    let columns: Array<kendo.ui.GridColumn> = [
                                        {
                                            field: "CompanyName",
                                            title: "DPP Company Name"
                                        }, {
                                            field: "LicenseNumber",
                                            title: "Account Code",
                                            minScreenWidth: 1000
                                        }, {
                                            field: "DoingBusinessAs",
                                            title: "DBA"
                                        }, {
                                            field: "DisplayCreatedDateTime",
                                            title: "Created Date",
                                            sortable: {
                                                compare: (a: IDisasterPrepProfileGridDisplay, b: IDisasterPrepProfileGridDisplay) =>
                                                { return a.SortModifiedDateTime - b.SortModifiedDateTime }
                                            },
                                            minScreenWidth: 1400
                                        }, {
                                            field: "DisplayModifiedDateTime",
                                            title: "Modified Date",
                                            sortable: {
                                                compare: (a: IDisasterPrepProfileGridDisplay, b: IDisasterPrepProfileGridDisplay) =>
                                                { return a.SortModifiedDateTime - b.SortModifiedDateTime }
                                            },
                                            minScreenWidth: 1200
                                        }, {
                                            title: "Profile Links",
                                            template: "<div style='text-align: center;'>" +
                                                "<a class='pure-button' href='/DisasterPrepProfiles/Edit/#= DppId #'>" +
                                                "Edit" +
                                                "</a> " +
                                                //"<a class='pure-button' href='#= PdfLink #'>" +
                                                //"PDF" +
                                                //"</a>" +
                                                "</div>",
                                            sortable: false
                                        }
                                    ];

                                    $scope.gridOptions = {
                                        dataSource: $scope.dpps,
                                        sortable: true,
                                        resizable: true,
                                        pageable: {
                                            numeric: false,
                                            pageSize: 25,
                                            pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                                            input: true
                                        },
                                        filterable: {
                                            mode: "menu",
                                            operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                                            extra: true
                                        },
                                        columns: columns,
                                        height: gridHeight
                                    };
                                },
                                () =>
                                {
                                });
                        });
                }
            ]);

    }
}

