import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { KendoHelperService } from "Services/Utility/KendoHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";

interface IIndexAdminScope {
    franchiseProfiles: CoreResources.IFranchiseProfile[];

    concepts: CoreResources.IConcept[];
    selectedDeleteConceptId: string;
    selectedCreateConceptId: string;

    queuedCreatePromises: number;
    createdCount: number;
    failToCreateCount: number;

    adminTabs: kendo.ui.TabStrip;
    userInfo: IUserInfoViewModel;
    DeleteProfiles: (successCount: number, failureCount: number) => void;
    CreateProfiles: () => void;
    CreateAccounts: (conceptId: number, isTest: boolean) => void;
    DeleteAllFranchiseeAccounts: () => void;
}

export class IndexAdminController implements ng.IController {
    static $inject = [
        '$scope',
        'coreApiResources',
        'identityManager',
        '$q',
        'kendoHelper',
        'apiConfig'
    ];
    adminTabs: kendo.ui.TabStrip;
    isBroadlyAdmin: boolean;
    isAdmin: boolean;
    isLoading: boolean;

    constructor(
        private $scope: IIndexAdminScope,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private $q: ng.IQService,
        private kendoHelper: KendoHelperService,
        private apiConfig: ApiConfig
    ) {



        $scope.selectedDeleteConceptId = "0";
        $scope.selectedCreateConceptId = "0";
        this.isLoading = true;
        this.isBroadlyAdmin = false;
        this.isAdmin = false;
        $scope.concepts = coreApiResources.ConceptApi.query({ $select: "ConceptId,ConceptCode,DisplayName" });

        $scope.DeleteProfiles = (successCount: number, failureCount: number) => {
            if (!successCount) {
                successCount = 0;
            }
            if (!failureCount) {
                failureCount = 0;
            }

            let queryOptions = { $select: "FranchiseId" };
            if ($scope.selectedDeleteConceptId) {
                queryOptions["$filter"] = "Franchise/ConceptId eq " + $scope.selectedDeleteConceptId;
            }

            coreApiResources.FranchiseProfileApi.query(queryOptions, (franProfiles: CoreResources.IFranchiseProfile[]) => {
                let deleteIds = [];

                let shouldRecurse = false;

                for (let profile of franProfiles) {
                    deleteIds.push(profile.FranchiseId);

                    if (deleteIds.length >= 50) {
                        shouldRecurse = true;
                        break;
                    }
                }

                coreApiResources.FranchiseProfileApi.delete({ ids: deleteIds, forceHardDelete: true }, (response: any) => {
                    for (let result of response.results) {
                        if (result.StatusCode >= 200 && result.StatusCode < 300) {
                            successCount++;
                        }
                        else {
                            failureCount++;
                        }
                    }

                    if (shouldRecurse) {
                        $scope.DeleteProfiles(successCount, failureCount);
                    }
                    else {
                        alert(`Successfully Deleted Count: '${successCount}'. Failure Count: '${failureCount}'.`);
                    }
                }, () => {
                    alert("There was an issue deleting profiles. Some may or may not be deleted.");
                });
            }, () => { alert("Issue getting profiles for deletion.") });
        };

        $scope.CreateProfiles = () => {
            $scope.createdCount = 0;
            $scope.failToCreateCount = 0;
            $scope.queuedCreatePromises = 0;

            let queryOptions = {
                $expand: "FranchiseProfile",
                $filter: "FranchiseProfile eq null"
            };
            if ($scope.selectedCreateConceptId) {
                queryOptions["$filter"] += " and ConceptId eq " + $scope.selectedCreateConceptId;
            }

            coreApiResources.FranchiseApi.query(queryOptions, (data: CoreResources.IFranchise[]) => {
                let createPromises: Array<angular.IPromise<any>> = [];

                for (let franchise of data) {
                    let newProfile = new coreApiResources.FranchiseProfileApi();
                    newProfile.FranchiseId = franchise.FranchiseId;
                    createPromises.push(newProfile.$save({ id: newProfile.FranchiseId }, () => {
                        $scope.createdCount++;
                    }, () => {
                        $scope.failToCreateCount++;
                    }));

                    $scope.queuedCreatePromises++;
                }

                $q.all(createPromises).then(() => {
                    alert("All profiles created successfully.");
                }, () => {
                    alert("There was an issue creating profiles. Some may or may not be created.");
                });
            }, () => { alert("Issue getting franchises for profile creation.") });
        };

        $scope.CreateAccounts = (conceptId: number, isTest: boolean) => {
            if (confirm("Are you sure you want to create these accounts? " + (!isTest ? "This is NOT test mode!" : ""))) {
                identityManager.CreateFranchiseAccountsByFranchiseRecords(conceptId, isTest).then(
                    (response) => {
                        alert("Success");
                    },
                    (err) => {
                        console.error(err);
                        alert("Error");
                    });
            }
        };

        $scope.DeleteAllFranchiseeAccounts = () => {
            if (confirm("Are you sure you want to delete all franchise accounts?") &&
                confirm("Are you really sure? That's ALL of them deleted. I mean, only the franchisees, but still...") &&
                confirm("Alright, last shot to back out of this. Delete them for real?")) {
                identityManager.DeleteAllFranchiseeAccounts().then(
                    (response) => {
                        console.log(response);
                        alert("Success");
                    },
                    (err) => {
                        console.error(err);
                        alert("Error");
                    });
            }
        }
    }




    async $onInit() {

        this.$scope.userInfo = (await this.identityManager.GetLoggedInUserInfo()).data;
        this.isBroadlyAdmin = this.identityManager.UserHasRole(this.$scope.userInfo, [
            this.apiConfig.FranForceConstants.RoleConstants.BroadlyAdmin
        ]);
        this.isAdmin = this.identityManager.UserHasRole(this.$scope.userInfo, [
            this.apiConfig.FranForceConstants.RoleConstants.AdminRole
        ]);

        this.kendoHelper.BindTabStripStateHandlers(this.adminTabs);


        if (this.isBroadlyAdmin && !this.isAdmin) {
            this.adminTabs.remove(0);
            this.adminTabs.remove(1);
            this.adminTabs.select(0)
   

        }
        if(!this.isBroadlyAdmin && this.isAdmin){

            this.adminTabs.remove(1);
        }
        this.isLoading = false;
    }


    async $postLink() {


    }




    RefreshEditors?: () => ng.IPromise<void>;
}

