import { LegalApiResources } from "Services/Resources/LegalApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { LegalEntities } from "Interfaces/FranForce/Legal/LegalResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

interface IViewSectionTwoScope extends ng.IScope {
    cancelationOptions: LegalEntities.CancelationType[];
    CancelationOptionValue: number[];
    WaiverOptionValue: number[];
    contractId: string;
    franchiseId: string;
    selectionDetails: { franchiseID: string, contractID: string };
    activeSection: { section: string }
    model: LegalEntities.SectionTwo;
    waiverOptions: LegalEntities.WaiverType[];
    paymentOptions: LegalEntities.PaymentOption[];
    paymentForOptions: LegalEntities.PaymentForOption[];
}

export class ViewSectionTwoController implements ng.IController {

    static $inject = [
        "$scope",
        "legalApiResources",
        "$window",
        "$q",
        "$log",
        "$http",
        "coreApiResources"
    ];

    constructor(
        private $scope: IViewSectionTwoScope,
        private legalApiResources: LegalApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $http: ng.IHttpService,
        private coreApiResources: CoreApiResources)
    {

    }

    $onInit() {
        let $scope = this.$scope;
        let $http = this.$http;
        let legalApiResources = this.legalApiResources;
        let $q = this.$q;

        // Assign selection details to retrieve associated data
        $scope.franchiseId = location.pathname.split("/")[3] || $scope.selectionDetails.franchiseID;
        $scope.contractId = location.pathname.split("/")[4] || $scope.selectionDetails.contractID;

        this.coreApiResources.FranchiseApi.get({ $expand: "Concept", id: $scope.franchiseId },
            (franchiseResponse: CoreEntities.Franchise) => {
                $scope.$emit("setFranchiseDetails", {
                    FranchiseName: franchiseResponse.Concept.DisplayName,
                    LicenseNum: franchiseResponse.LicenseNumber,
                    FranchiseeCode: $scope.franchiseId,
                    FranchiseAddress: franchiseResponse.Address,
                    FranchisePhoneNumber: franchiseResponse.StorePhone,
                    FranchiseEmail: franchiseResponse.StoreEmail
                });
            },
            errorResponse => {
                console.log(errorResponse);
            });

        legalApiResources.ContractApi.get({
            id: $scope.contractId, $expand: "SectionTwo($expand=SectionTwoCancelationTypes,SectionTwoWaiverTypes)"
        }, (contractResponse: LegalEntities.Contract) => {
            $scope.model = contractResponse.SectionTwo;
            $scope.CancelationOptionValue = [];
            $scope.WaiverOptionValue = [];
            if (contractResponse.SectionTwo.SectionTwoCancelationTypes.length > 0) {
                contractResponse.SectionTwo.SectionTwoCancelationTypes.forEach(value => {
                    $scope.CancelationOptionValue.push(value.CancelationTypeID);
                });
            }
            if (contractResponse.SectionTwo.SectionTwoWaiverTypes.length > 0) {
                contractResponse.SectionTwo.SectionTwoWaiverTypes.forEach(value => {
                    $scope.WaiverOptionValue.push(value.WaiverTypeID);
                });
            }

            $scope.$emit("setSectionAvaliability", {
                hasSectionTwo: contractResponse.SectionTwoID != null,
                hasSectionThree: contractResponse.SectionThreeID != null,
                franchiseID: $scope.franchiseId,
                contractID: $scope.contractId
            });
        }, errorResponse => {
            console.log(errorResponse);
        });

        legalApiResources.CancelationTypeApi.query({}, (successResponse: LegalEntities.CancelationType[]) => {
            $scope.cancelationOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        legalApiResources.WaiverTypeApi.query({}, (successResponse: LegalEntities.WaiverType[]) => {
            $scope.waiverOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        legalApiResources.PaymentOptionApi.query({}, (successResponse: LegalEntities.PaymentOption[]) => {
            $scope.paymentOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        legalApiResources.PaymentForOptionApi.query({}, (successResponse: LegalEntities.PaymentForOption[]) => {
            $scope.paymentForOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });
    }
}

