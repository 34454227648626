import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import * as _ from "underscore"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { AxiosInstance } from "axios";
import { IntegrationFinanceClient, IntegrationFinanceInvoiceCreateRm } from "@nbly/billing-orchestrations-clients";
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";

export class BatchManagementFinanceIntegrationInvoiceGridComponentController implements ng.IController {
    // -- Bindings --
    invoicesDataSource: kendo.data.DataSource;
    selectedBatchIds: number[];
    selectedAdjustments: any[];
    type: string;
    onInvoicesPosted: (params: {}) => void;
    // --

    isLoading: boolean;

    showErrorMessage: boolean;
    errorMessage: string;

    invoiceGrid: kendo.ui.Grid;
    invoicesGridOptions: kendo.ui.GridOptions

    billingOrchestrationsClient: AxiosInstance;
    integrationFinanceClient: IntegrationFinanceClient;

    static $inject = [
        'coreApiResources',
        'rpmUiApiResources',
        'rpmHelper',
        '$timeout',
        '$q',
        '$scope',
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
        private $scope: ng.IScope,
    ) {
        this.billingOrchestrationsClient = FranForceAxiosClientBuilder.BuildBillingOrchestrationsBaseClient();
        this.integrationFinanceClient = new IntegrationFinanceClient("", this.billingOrchestrationsClient);
    }

    $onInit() {
        if (this.invoicesDataSource)
            this.invoicesDataSource.read();
        this.ErrorMessage(false, "");
    }

    $postLink() {
        this.InitInvoiceGrid();
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.invoicesDataSource)] && this.invoiceGrid) {
            this.areInvoicesDownloaded = false;
            this.invoiceGrid.setDataSource(this.invoicesDataSource);
            this.ErrorMessage(false, "");
        }
    }

    ErrorMessage(show: boolean, message: string) {
        this.showErrorMessage = show;
        this.errorMessage = message;
    }

    InitInvoiceGrid() {
        let invoicesColumns: kendo.ui.GridColumn[] = [{
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.Date),
            title: "Date",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.InvoiceType),
            title: "InvoiceType",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.InvoiceNumber),
            title: "InvoiceNumber",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.CustomerID),
            title: "CustomerID",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.ItemDescription),
            title: "ItemDescription",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.ItemNumber),
            title: "ItemNumber",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.Quantity),
            title: "Quantity",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.UnitPrice),
            title: "UnitPrice",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.ExtAmount),
            title: "ExtAmount",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.OverRideTaxDate),
            title: "OverRideTaxDate",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.GPDatabase),
            title: "GPDatabase",
        }, {
            field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.AvalaraCompanyCode),
            title: "AvalaraCompanyCode",
        }];

        this.invoicesGridOptions = {
            columns: invoicesColumns,
            toolbar: [
                {
                    template: `<label style="margin: 0px 8px; width: 300px;">
                                    <span style="display: block">File Name</span>
                                    <input type="text" style="height: auto; width: 100%; box-sizing: border-box;" 
                                           ng-model="$ctrl.${nameof(this.invoiceGrid)}.${nameof.full<kendo.ui.Grid>(o => o.options.excel.fileName)}" /> 
                               </label>`
                }, {
                    template: `<button ng-click='$ctrl.${nameof(this.DownloadInvoiceExcel)}()'>
                                    <span style="display: block">Download</span>
                               </button>`
                }
            ],
            excel: {
                fileName: `BatchInvoices-` +
                    `${new Date().getFullYear()}_` +
                    `${new Date().getMonth() + 1 < 10 ? "0" : ""}${new Date().getMonth() + 1}_` +
                    `${new Date().getDate() < 10 ? "0" : ""}${new Date().getDate()}.xlsx`,
                filterable: true,
                allPages: true
            },
            excelExport: (e) => {},
            dataBound: (e) => {
            },
            selectable: false,
            editable: false,
            scrollable: true,
            sortable: true,
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: false
        };
    }

    

    areInvoicesDownloaded: boolean;

    DownloadInvoiceExcel() {
        this.invoiceGrid.saveAsExcel();
        this.$timeout(() => {
            this.areInvoicesDownloaded = true;
        }, 1000);
    }

    async MarkInvoicesPosted() {
        console.log("this.selectedAdjustments", this.selectedAdjustments);

        let exportIds = this.invoiceGrid?.dataSource?.data()?.map(function (result: any) {
            return result.RoyaltyInvoiceExportBatchID;
        });

        exportIds = _.uniq(exportIds);

        if (exportIds.length < 1) {
            this.ErrorMessage(true, "No data available!");
            return;
        }

        this.isLoading = true;

        if (this.type === "adjustment") {
            try {
                for (const id of exportIds) {
                    await this.integrationFinanceClient.royaltiesPosted(id);
                }

            } catch (error) {
                this.DisplaySnackbar("error", "An error occurred while processing the adjustments. Please try again.");
                console.error("Error during adjustment processing:", error);
                throw error;
            }
            finally {
                this.isLoading = false;
            }

            this.DisplaySnackbar("success", "Successfully marked as posted!");
            this.onInvoicesPosted({});
        }

        else {
            try {
                const failedBatchIds: number[] = [];
            
                for (const batchId of this.selectedBatchIds) {
                    try {
                        const response = await this.integrationFinanceClient.checkRoyaltyBatchPopulated(batchId);
            
                        if (!response.result.hasPeriodReportInvoices && !response.result.hasAdjustmentInvoices) {
                            failedBatchIds.push(batchId);
                        }
                    } catch (err) {
                        this.DisplaySnackbar("error", `Failed to check if royalty batch populated for staging for batch ID: ${batchId}`);
                        return;
                    }
                }
            
                if (failedBatchIds.length > 0) {
                    this.DisplaySnackbar("error", `Error! The following batch IDs: ${failedBatchIds.join(', ')} are not populated to staging. Please click on "Populate Staging" and then click on "Mark as posted" again!`);
                    return;
                }
            
                for (const id of exportIds) {
                    await this.integrationFinanceClient.setRoyaltiesPosted(id);
                }
            
                this.DisplaySnackbar("success", "Successfully marked as posted!");
                this.onInvoicesPosted({});
            } catch (error) {
                this.DisplaySnackbar("error", "An error occurred while marking as posted. Please try again.");
                console.error("Error during posting:", error);
                throw error;
            } finally {
                this.isLoading = false;
            }
        }
    };

    Delay = function(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    ResetGrid() {
        this.invoiceGrid.dataSource.data([]); // Clear the data
        this.invoiceGrid.dataSource.read();   // Reload the data
        this.invoiceGrid.refresh();           // Refresh the grid to apply changes
    }

    showSnackbar: boolean;
    snackbarType: string;
    snackbarMessage: string;

    DisplaySnackbar(type: string, message: string) {
        this.showSnackbar = !this.showSnackbar;
        this.snackbarType = type;
        this.snackbarMessage = message;
        this.$scope.$evalAsync();
    }

    async PopulateRoyaltyBatchInStaging() {
        let exportIds = <number[]>this.invoiceGrid.dataSource.data()
            ?.map((result: { RoyaltyInvoiceExportBatchID: number }) => {
                return result.RoyaltyInvoiceExportBatchID;
            });
    
        exportIds = _.uniq(exportIds);
    
        if (exportIds.length < 1) {
            this.ErrorMessage(true, "No data available!");
            return;
        }

        if (this.type == "adjustment") {
            try {
                for (const id of exportIds) {
                    const isInvoicesCreatedResult = await this.integrationFinanceClient.staging(id);

                    if (!isInvoicesCreatedResult?.result) {
                        //this.DisplaySnackbar("error", "Couldn't populate royalty batches to staging! Please try again");
                        this.DisplaySnackbar("warning", `Couldn't populate royalty batches to staging! There were no invoices to create. 
                            The invoices are either already in staging or have zero sales`)

                        return;
                    }

                    this.DisplaySnackbar("success", `Successfully populated the adjustments to staging`);
                }
            } catch (err) {
                this.DisplaySnackbar("error", "Error! Failed to populate staging, please try again");
            } finally {
                this.isLoading = false;
            }

            

            return;
        }
    
        const failedBatchIds: number[] = [];
        const unpopulatedBatchIds: number[] = [];
        const alreadyPopulatedBatchIds: number[] = [];
        
        if (this.selectedBatchIds) {
            for (const batchId of this.selectedBatchIds) {
                try {
                    const response = await this.integrationFinanceClient.checkRoyaltyBatchPopulated(batchId);

                    if (response.result.hasPeriodReportInvoices || response.result.hasAdjustmentInvoices) {
                        alreadyPopulatedBatchIds.push(batchId);
                    } else {
                        unpopulatedBatchIds.push(batchId);
                    }
                } catch (err) {
                    this.DisplaySnackbar("error", `Failed to check if royalty batch populated for staging for batch ID: ${batchId}`);
                    return;
                }
            }
        }
    
        // Display warning if there are already populated batch IDs
        if (alreadyPopulatedBatchIds.length > 0) {
            this.DisplaySnackbar("warning", `The following batch IDs are already populated: ${alreadyPopulatedBatchIds.join(", ")}`);
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    
        if (unpopulatedBatchIds.length < 1) 
            return;
    
        try {
            this.isLoading = true;
    
            for (const id of exportIds) {
                const integrationFinanceInvoiceCreateRm: IntegrationFinanceInvoiceCreateRm = {
                    royaltyInvoiceExportBatchId: id,
                    royaltyReportBatchIds: unpopulatedBatchIds // Only unpopulated batches
                };

                const isInvoicesCreatedResult = await this.integrationFinanceClient.integrationFinanceInvoices(integrationFinanceInvoiceCreateRm);

                if (!isInvoicesCreatedResult?.result) {
                    //this.DisplaySnackbar("error", "Couldn't populate royalty batches to staging! Please try again");
                    this.DisplaySnackbar("warning", "Couldn't populate royalty batches to staging! There were no invoices to create.");
                    return;
                }
            }
    
            // Include batch IDs in the success message
            this.DisplaySnackbar("success", `Successfully populated the following royalty batch IDs to staging: ${unpopulatedBatchIds.join(", ")}`);
        } catch (err) {
            this.DisplaySnackbar("error", err.message);
        } finally {
            this.isLoading = false;
        }
    }               

    static BindComponent(app: ng.IModule) {
        app.component('bmFinanceIntegrationInvoiceGrid', {
            bindings: {
                [nameof<BatchManagementFinanceIntegrationInvoiceGridComponentController>(o => o.invoicesDataSource)]: "<",
                [nameof<BatchManagementFinanceIntegrationInvoiceGridComponentController>(o => o.selectedBatchIds)]: "<",
                [nameof<BatchManagementFinanceIntegrationInvoiceGridComponentController>(o => o.type)]: "<",
                [nameof<BatchManagementFinanceIntegrationInvoiceGridComponentController>(o => o.selectedAdjustments)]: "<",
                [nameof<BatchManagementFinanceIntegrationInvoiceGridComponentController>(o => o.onInvoicesPosted)]: "&",
            },
            controller: BatchManagementFinanceIntegrationInvoiceGridComponentController,
            templateUrl: "/Templates/BatchManagement/bmFinanceIntegrationInvoiceGrid.html"
        });

    }
}

