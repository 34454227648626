import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CallCenterResourceApi } from "Interfaces/FranForce/CallCenter/CallCenterResourceApi";
import { CallCenterResources } from "Interfaces/FranForce/CallCenter/CallCenterResources";

export class CallCenterApiResources 
{
    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig"
    ];

    C4CallCentersApi: CallCenterResourceApi.IC4CallCenterApi;
    C4CallCentersServicingConceptsApi: CallCenterResourceApi.IC4CallCentersServicingConceptApi;
    C4CallDispositionsApi: CallCenterResourceApi.IC4CallDispositionApi;
    C4CallTypesApi: CallCenterResourceApi.IC4CallTypeApi;
    C4ChannelTypesApi: CallCenterResourceApi.IC4ChannelTypeApi;
    C4ConceptLeadThrottlesApi: CallCenterResourceApi.IC4ConceptLeadThrottleApi;
    C4ConceptLeadTimeUnitsApi: CallCenterResourceApi.IC4ConceptLeadTimeUnitApi
    C4ConceptNotificationConfigurationsApi: CallCenterResourceApi.IC4ConceptNotificationConfigurationApi;
    C4CorporateContactMethodsApi: CallCenterResourceApi.IC4CorporateContactMethodApi;
    C4CorporateContactsApi: CallCenterResourceApi.IC4CorporateContactApi
    C4FranchiseLeadThrottlesApi: CallCenterResourceApi.IC4FranchiseLeadThrottleApi;
    C4PhoneCampaignsApi: CallCenterResourceApi.IC4PhoneCampaignApi;
    C4PhoneCampaignBudgetTypesApi: CallCenterResourceApi.IC4PhoneCampaignBudgetTypeApi;
    C4PhoneCampaignNumberTypesApi: CallCenterResourceApi.IC4PhoneCampaignNumberTypeApi;
    C4PhoneCampaignSupportTypesApi: CallCenterResourceApi.IC4PhoneCampaignSupportTypeApi;
    TimeSlotComparerTypeApi: CallCenterResourceApi.ITimeSlotComparerTypeApi;

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {
        this.RefreshResources()
    }

    RefreshResources()
    {
        this.C4CallCentersApi = this.$resource<CallCenterResources.IC4CallCenter>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4CallCenters/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.C4CallCentersServicingConceptsApi = this.$resource<CallCenterResources.IC4CallCentersServicingConcept>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4CallCentersServicingConcepts/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.C4CallDispositionsApi = this.$resource<CallCenterResources.IC4CallDisposition>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4CallDispositions/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.C4CallTypesApi = this.$resource<CallCenterResources.IC4CallType>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4CallTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.C4ChannelTypesApi = this.$resource<CallCenterResources.IC4ChannelType>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4ChannelTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.C4ConceptLeadThrottlesApi = this.$resource<CallCenterResources.IC4ConceptLeadThrottle>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4ConceptLeadThrottles/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.C4ConceptLeadTimeUnitsApi = this.$resource<CallCenterResources.IC4ConceptLeadTimeUnit>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4ConceptLeadTimeUnits/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.C4ConceptNotificationConfigurationsApi = this.$resource<CallCenterResources.IC4ConceptNotificationConfiguration>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4ConceptNotificationConfigurations/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.C4CorporateContactMethodsApi = this.$resource<CallCenterResources.IC4CorporateContactMethod>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4CorporateContactMethods/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.C4CorporateContactsApi = this.$resource<CallCenterResources.IC4CorporateContact>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4CorporateContacts/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.C4FranchiseLeadThrottlesApi = this.$resource<CallCenterResources.IC4FranchiseLeadThrottle>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4FranchiseLeadThrottles/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.C4PhoneCampaignsApi = this.$resource<CallCenterResources.IC4PhoneCampaign>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4PhoneCampaigns/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.C4PhoneCampaignBudgetTypesApi = this.$resource<CallCenterResources.IC4PhoneCampaignBudgetType>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4PhoneCampaignBudgetTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.C4PhoneCampaignNumberTypesApi = this.$resource<CallCenterResources.IC4PhoneCampaignNumberType>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4PhoneCampaignNumberTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.C4PhoneCampaignSupportTypesApi = this.$resource<CallCenterResources.IC4PhoneCampaignSupportType>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/C4PhoneCampaignSupportTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.TimeSlotComparerTypeApi = this.$resource<CallCenterResources.ITimeSlotComparerType>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/TimeSlotComparerTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
    }

    GetFranchiseLeadState(franchiseId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/C4FranchiseLeadThrottles/Franchise/" + franchiseId + "/CurrentSetting"
        };

        return this.$http<CallCenterResources.IC4FranchiseLeadThrottle>(config).then((response) =>
        {
            return response.data;
        });
    }

    SetFranchiseLeadState(leadStateObject: Partial<CallCenterResources.IC4FranchiseLeadThrottle>)
    {
        const url = this.apiConfig.AppSettings.CoreApiRootUrl +
            "/C4FranchiseLeadThrottles/Franchise/" +
            leadStateObject.FranchiseId;

        return this.$http.post(url, leadStateObject)
    }

    SetConceptLeadThrottleToConcept(conceptLeadThrottleId: number, conceptId: number)
    {
        let url = this.apiConfig.AppSettings.CoreApiRootUrl +
            `/C4ConceptLeadThrottles/${conceptLeadThrottleId}/Concept/${conceptId}`;

        return this.$http.post(url, null);
    }

    GetLeadSource(){
        let config: ng.IRequestConfig = {
            method: "GET",
            url:  this.apiConfig.AppSettings.CallCenterOrchestrationsBaseUrl + "protected/lead-sources"
        };
        return this.$http<any>(config).then((response) =>
        {
            return response.data;
        });
    }
}