import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { CallCenterResources } from "Interfaces/FranForce/CallCenter/CallCenterResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";


export class CallCenterCorporateContactsComponentController implements ng.IController 
{
    contact: CallCenterResources.IC4CorporateContact;

    contactWindow: kendo.ui.Window;
    contactGrid: kendo.ui.Grid;
    methodDropDown: kendo.ui.DropDownList;
    methodDropDownOptions: kendo.ui.DropDownListOptions;
    selectedMethod: CallCenterResources.IC4CorporateContactMethod;
    
    static $inject = [
        'coreApiResources',
        'callCenterApiResources',
        '$q',
        '$log',
        '$timeout'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private callCenterApiResources: CallCenterApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService
    )
    {
    }

    $onInit()
    {
        this.InitMethodDropDown();
    }

    $postLink()
    {
        this.InitGrid();
        this.InitWindow();
    }

    InitGrid()
    {
        let dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) =>
                {
                    let queryParams: ODataQueryParameters = {
                        $expand: `${nameof<CallCenterResources.IC4CorporateContact>(o => o.C4CorporateContactMethod)}($select=${nameof<CallCenterResources.IC4CorporateContact>(o => o.C4CorporateContactMethod.Name)}),` +
                            `${nameof<CallCenterResources.IC4CorporateContact>(o => o.Franchisor)}($select=${nameof<CallCenterResources.IC4CorporateContact>(o => o.Franchisor.DisplayName)}),` +
                            `${nameof<CallCenterResources.IC4CorporateContact>(o => o.Concept)}($select=${nameof<CallCenterResources.IC4CorporateContact>(o => o.Concept.ConceptCode)}),`
                    }
                    this.callCenterApiResources.C4CorporateContactsApi.query(queryParams).$promise
                        .then((contactsResponse) =>
                        {
                            for (let contact of contactsResponse)
                            {
                                if (!contact.C4CorporateContactMethod) {
                                    contact.C4CorporateContactMethod = <any>{};
                                    contact.C4CorporateContactMethod.Name = "";
                                }

                                if (!contact.Concept) {
                                    contact.Concept = <any>{};
                                    contact.Concept.ConceptCode = "";
                                }

                                if (!contact.Franchisor) {
                                    contact.Franchisor = <any>{};
                                    contact.Franchisor.DisplayName = "";
                                }

                                let seperator = "";
                                if (contact.Concept.ConceptCode && contact.Franchisor.DisplayName) {
                                    seperator = " / ";
                                }
                                (<any>contact).ConceptFranchisorDisplay = `${contact.Concept.ConceptCode}${seperator}${contact.Franchisor.DisplayName}`
                                if (!(<any>contact).ConceptFranchisorDisplay) {
                                    (<any>contact).ConceptFranchisorDisplay = "(Dwyer)";
                                }
                            }

                            options.success(contactsResponse);
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        })
                }
            },
            sort: { field: nameof<CallCenterResources.IC4CorporateContact>(o => o.Name), dir: "asc" }
        });

        let columns: kendo.ui.GridColumn[] = [ {
                field: nameof<CallCenterResources.IC4CorporateContact>(o => o.Name),
                title: "Name"
            }, {
                field: nameof.full<CallCenterResources.IC4CorporateContact>(o => o.C4CorporateContactMethod.Name),
                title: "Primary"
            }, {
                field: nameof<CallCenterResources.IC4CorporateContact>(o => o.Phone),
                title: "Phone"
            }, {
                field: nameof<CallCenterResources.IC4CorporateContact>(o => o.Email),
                title: "Email"
            }, {
                field: "ConceptFranchisorDisplay",
                title: "Concept / Franchisor",
            }, {
                template: `<button class='pure-button' ng-click='$ctrl.${nameof(this.OpenEditContactWindow)}(dataItem)'><i class='fa fa-pencil'></i></button>`,
                width: 60
            }, {
                template: `<button class='pure-button button-error' ng-click='$ctrl.${nameof(this.DeleteContact)}(dataItem)'>X</button>`,
                width: 60
            }
        ];

        let options: kendo.ui.GridOptions = {
            dataSource: dataSource,
            columns: columns,
            toolbar: [
                {
                    template: `<button class='pure-button button-secondary' ng-click='$ctrl.${nameof(this.OpenNewContactWindow)}()'>New Contact...</button>`
                }
            ],
            sortable: true,
            filterable: true
        };
        this.contactGrid.setOptions(options);
    }

    InitWindow()
    {
        let options: kendo.ui.WindowOptions = {
            modal: true
        };
        this.contactWindow.setOptions(options);
    }

    InitMethodDropDown()
    {
        this.methodDropDownOptions = {
            optionLabel: "Select Primary...",
            optionLabelTemplate: "Select Primary...",
            dataTextField: nameof<CallCenterResources.IC4CorporateContactMethod>(o => o.Name),
            dataValueField: nameof<CallCenterResources.IC4CorporateContactMethod>(o => o.C4CorporateContactMethodId),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) =>
                    {
                        this.callCenterApiResources.C4CorporateContactMethodsApi.query({}).$promise
                            .then((positions) =>
                            {
                                options.success(positions);
                            })
                            .catch((err) =>
                            {
                                options.error(err);
                            })
                    }
                }
            })
        }
    }

    OnFranchisorSelect(franchisor: CoreResources.IFranchisor)
    {
        let franchisorId = franchisor && franchisor.FranchisorId;
        if (this.contact.FranchisorId == franchisorId) {
            return;
        }
        this.contact.FranchisorId = franchisor && franchisor.FranchisorId;
        this.contact.ConceptId = franchisor && franchisor.ConceptId;
        this.contactGrid.dataSource.read().then(() =>
        {
            this.contactGrid.refresh();
        });
    }

    OpenNewContactWindow()
    {
        this.contact = <any>{};
        this.contact.C4CorporateContactId = 0;
        this.selectedMethod = null;

        this.contactWindow.open();
        this.$timeout(() =>
        {
            this.contactWindow.center();
        });
    }

    OpenEditContactWindow(contact: CallCenterResources.IC4CorporateContact)
    {
        this.contact = angular.copy(contact);
        this.contact.C4CorporateContactMethod = null;
        this.contact.Franchisor = null;
        this.contact.Concept = null;
        this.methodDropDown.select((method: CallCenterResources.IC4CorporateContactMethod) =>
        {
            return method.C4CorporateContactMethodId == this.contact.C4CorporateContactMethodId;
        });
        this.$timeout(() =>
        {
            this.contactWindow.open();
            this.$timeout(() =>
            {
                this.contactWindow.center();
            })
        });
    }

    SaveContact()
    {
        let promise: ng.IPromise<any>;
        this.contact.C4CorporateContactMethodId = this.selectedMethod.C4CorporateContactMethodId;
        if (this.contact.C4CorporateContactId) {
            promise = this.callCenterApiResources.C4CorporateContactsApi.update({ id: this.contact.C4CorporateContactId }, this.contact).$promise;
        }
        else {
            promise = this.callCenterApiResources.C4CorporateContactsApi.save({ }, this.contact).$promise;
        }

        return promise
            .then(() =>
            {
                this.contactWindow.close();
                this.ResetGrid();
            });
    }

    DeleteContact(contact: CallCenterResources.IC4CorporateContact)
    {
        if (confirm(`Are you sure you want to delete the contact: '${contact.Name}'?`))
        {
            let params: ODataQueryParameters = {
                $filter: nameof<CallCenterResources.IC4ConceptNotificationConfiguration>(o => o.C4CorporateContactId) + " eq " + contact.C4CorporateContactId
            };

            return this.callCenterApiResources.C4ConceptNotificationConfigurationsApi.query(params).$promise
                .then((configurations) =>
                {
                    if (configurations.length > 0) {
                        alert(`There are ${configurations.length} assignments attached to this contact. Delete those assignments before deleting this contact.`);
                        return this.$q.reject();
                    }

                    return this.callCenterApiResources.C4CorporateContactsApi.delete({ id: contact.C4CorporateContactId }).$promise
                        .then(() =>
                        {
                            this.ResetGrid();
                        });
                });
        }
    }

    ResetGrid()
    {
        return this.$q.when(this.contactGrid.dataSource.read())
            .then(() =>
            {
                this.contactGrid.refresh();
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('callCenterCorporateContacts', {
            bindings: {

            },
            controller: CallCenterCorporateContactsComponentController,
            templateUrl: "/Templates/C4/CallCenterCorporateContacts.html"
        });

    }
}

