import * as elements from 'typed-html';

import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RmsMethodologyTypeSelectorOnSelectParams } from 'Directives/RPM/Common/rmsMethodologyTypeSelector';
import { RmsFindScaleBasedOnTypesSelectorOnSelectParams } from 'Directives/RPM/Common/RmsFindScaleBasedOnTypesSelector';
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import { StringProperties } from 'Types/StringProperties';
import { RmsRoyaltyScaleCalculationSelectorOnSelectParams } from '../Common/rmsRoyaltyScaleCalculationSelector';
import { RmsRoyaltySummaryInvoiceDisplayTypeSelectorOnSelectParams } from '../Common/rmsRoyaltySummaryInvoiceDisplayTypeSelector';

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            fddNewDocumentButton: Partial<StringProperties<fddNewDocumentButtonComponentController>>;
        }
    }
}

export class fddNewDocumentButtonComponentController implements ng.IController
{
    conceptId: number;
    franchisorId: number;
    readOnly: boolean;

    window: kendo.ui.Window;
    findScaleBasedOnDropDown: kendo.ui.DropDownList;

    model: RpmEntities.DisclosureDocumentViewModel;

    static $inject = [
        '$window',
        '$location',
        '$timeout',
        'rpmUiApiResources',
        'rpmHelper',
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private $timeout: ng.ITimeoutService,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
    )
    {

    }
    $onInit()
    {
    }

    $postLink()
    {

    } 

    CreateNewDiscloureDocument() 
    {
        let now = new Date();
        this.model.CreatedDateTime = now.toISOString();

        return this.rpmUiApiResources.CreateDisclosureDocument(this.model)
            .then((response) =>
            {
                let fddId = response.data;
                let currentPath = this.$window.location.pathname;
                this.$window.location.href = `/FddManagement/Edit#?${nameof(fddId)}=${fddId}`;

                //If only the anchor is changed, page reload needed.
                if (currentPath.match(/FddManagement\/Edit/i))
                {
                    return this.$timeout(() =>
                    {
                        this.$window.location.reload();
                    }) 
                }
            })
            .catch(this.rpmHelper.DefaultCatch());
    }

    OpenNewDocumentWindow()
    {
        this.InitModel();
        this.$timeout(() =>
        {
            this.window.center();
        }, 0);
        this.window.open();
    }

    InitModel()
    {
        let now = new Date();
        this.model = {
            DisclosureDocumentId: 0,
            ConceptId: this.conceptId,
            CreatedDateTime: now.toISOString(),
            FranchisorId: this.franchisorId,
            CreatedUser: null,
            ApprovedDate: now.toISOString(),
            RoyaltyPeriodFrequencyId: 1,
            RoyaltyMethodologyTypeId: 1,
            Year: now.getFullYear(),
            Selectable: true,
            FindScaleBasedOnId: 1,
            RoyaltyScaleCalculationTypeId: 1,
            RoyaltySummaryInvoiceDisplayTypeId: 1,
            //Defaults
            AdFeeFrequencyId: null,
            AmendedDate: null,
            CalculateAdFee: false,
            CalculateRoyaltyFee: false,
            CalculateTAFSFee: false,
            CalculateTechnologyFee: false,
            LateFeeAmount: 0,
            ReportEndDayOfWeek: 0,
            ReportLateDays: 0,
            TAFSFeeFrequencyId: null,
            TechnologyFeeFrequencyId: null,
            UpdatedDateTime: null,
            UpdatedUser: null,
            Description: ""
        };
    }

    static BindComponent(app: ng.IModule)
    {
        const openWindowButtonTemplate =
            <button class="pure-button btn-accept pull-right"
                ng-disabled={`$ctrl.${nameof<fddNewDocumentButtonComponentController>(o => o.readOnly)}`}
                ng-show={`$ctrl.${nameof<fddNewDocumentButtonComponentController>(o => o.conceptId)} && $ctrl.${nameof<fddNewDocumentButtonComponentController>(o => o.franchisorId)}`}
                ng-click={`$ctrl.${nameof<fddNewDocumentButtonComponentController>(o => o.OpenNewDocumentWindow)}()`}
                promise-btn>
                New Document
            </button>;

        const year =
            <div class="year">
                <h2>Year</h2>
                <input kendo-numeric-text-box
                    k-options="{format: '0', spinners: false, decimals: 0}"
                    k-ng-model={`$ctrl.${nameof.full<fddNewDocumentButtonComponentController>(o => o.model.Year)}`}
                    />
            </div>;

        const selectable =
            <div class="selectable">
                <input id="cb-new-selectable"
                    class="rpm-checkbox"
                    type="checkbox"
                    name="new-selectable"
                    ng-model={`$ctrl.${nameof.full<fddNewDocumentButtonComponentController>(o => o.model.Selectable)}`} />

                <label for="cb-new-selectable"
                    class="rpm-checkbox-label">
                    Selectable
                </label>
            </div>;

        const methodology =
            <div class="methodology">
                <h2>Royalty Methodology</h2>
                <rmsMethodologyTypeSelector
                    methodology-id={`$ctrl.${nameof.full<fddNewDocumentButtonComponentController>(o => o.model.RoyaltyMethodologyTypeId)}`}
                    read-only="false"
                    on-select={`$ctrl.${nameof.full<fddNewDocumentButtonComponentController>(o => o.model.RoyaltyMethodologyTypeId)} = ${nameof.full<RmsMethodologyTypeSelectorOnSelectParams>(o => o.methodologyType.RoyaltyMethodologyTypeId)}`}
                >
                </rmsMethodologyTypeSelector>
            </div>;

        const findScale =
            <div class="find-scale">
                <h2>Find Scale Based On</h2>
                <rmsFindScaleBasedOnTypesSelector
                    find-scale-based-on-id={`$ctrl.${nameof.full<fddNewDocumentButtonComponentController>(o => o.model.FindScaleBasedOnId)}`} 
                    read-only="false"
                    on-select={`$ctrl.${nameof.full<fddNewDocumentButtonComponentController>(o => o.model.FindScaleBasedOnId)} = ${nameof.full<RmsFindScaleBasedOnTypesSelectorOnSelectParams>(o => o.findScale.FindScaleBasedOnId)}`}
                    >
                </rmsFindScaleBasedOnTypesSelector>
            </div>;

        const royaltyScaleCalculation =
            <div class="royalty-scale-calculation">
                <h2>Royalty Scale Calculation Type</h2>
                <rmsRoyaltyScaleCalculationSelector
                    royalty-scale-calculation-id={`$ctrl.${nameof.full<fddNewDocumentButtonComponentController>(o => o.model.RoyaltyScaleCalculationTypeId)}`}
                    read-only="false"
                    on-select={`$ctrl.${nameof.full<fddNewDocumentButtonComponentController>(o => o.model.RoyaltyScaleCalculationTypeId)} = ${nameof.full<RmsRoyaltyScaleCalculationSelectorOnSelectParams>(o => o.royaltyScaleCalculation.royaltyScaleCalculationTypeId)}`}
                >
                </rmsRoyaltyScaleCalculationSelector>
            </div>;

        const royaltySummaryInvoiceDisplayType =
            <div class="royalty-summary-invoice-display-type">
                <h2>Royalty Summary Invoice Display Type</h2>
                <rmsRoyaltySummaryInvoiceDisplayTypeSelector
                    royalty-summary-invoice-display-type-id={`$ctrl.${nameof.full<fddNewDocumentButtonComponentController>(o => o.model.RoyaltySummaryInvoiceDisplayTypeId)}`}
                    read-only="false"
                    on-select={`$ctrl.${nameof.full<fddNewDocumentButtonComponentController>(o => o.model.RoyaltySummaryInvoiceDisplayTypeId)} = ${nameof.full<RmsRoyaltySummaryInvoiceDisplayTypeSelectorOnSelectParams>(o => o.royaltySummaryInvoiceDisplayType.royaltySummaryInvoiceDisplayTypeId)}`}
                >
                </rmsRoyaltySummaryInvoiceDisplayTypeSelector>
            </div>;

        const approvedDate =
            <div class="approved-date">
                <h2>Approved Date</h2>
                <input kendo-date-picker
                    class="rpm-datepicker"
                    id="approved-date"
                    name="approvedDate"
                    ng-model={`$ctrl.${nameof.full<fddNewDocumentButtonComponentController>(o => o.model.ApprovedDate)}`}
                />
            </div>;

        const description =
            <div class="description">
                <h2>Description</h2>
                <textarea kendo-text-area
                    maxlength="100"
                    ng-model={`$ctrl.${nameof.full<fddNewDocumentButtonComponentController>(o => o.model.Description)}`}
                    rows="3"></textarea>
            </div>;

        const windowTemplate = 
            <div kendo-window={`$ctrl.${nameof<fddNewDocumentButtonComponentController>(o => o.window)}`}
                k-visible="false">
                <div class="fdd-new-document-window rpm-modal">
                    {year}
                    {selectable}
                    {methodology}
                    {findScale}
                    {royaltyScaleCalculation}
                    {royaltySummaryInvoiceDisplayType}
                    {approvedDate}
                    {description}
                    <div class="buttons">
                        <button
                            ng-click={`$ctrl.${nameof<fddNewDocumentButtonComponentController>(o => o.CreateNewDiscloureDocument)}()`}
                            promise-btn
                            class="pure-button button-secondary"
                            >
                            Create New Document
                        </button>
                    </div>
                </div>
            </div>;

        const template = `${openWindowButtonTemplate}${windowTemplate}`;
        const componentName = nameof<JSX.IntrinsicElements>(o => o.fddNewDocumentButton);

        app.component(componentName, {
            bindings: {
                [nameof<fddNewDocumentButtonComponentController>(o => o.conceptId)]: "<",
                [nameof<fddNewDocumentButtonComponentController>(o => o.franchisorId)]: "<",
                [nameof<fddNewDocumentButtonComponentController>(o => o.readOnly)]: "<"
            },
            template: template,
            controller: fddNewDocumentButtonComponentController
        });
    }
}
