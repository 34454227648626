
import { RmsStateService } from "Services/State/RmsState";
import { Helpers } from "Utility/Helpers";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

export class RpmHelperService {
    public readonly SpecialRoyaltyMethodologyTypeId = 3;
    public readonly StringFormats = {
        Number: {
            Population: "#,0",
            RateNumber: "#.0######",
            RatePercentage: "#0.0#### %"
        }
    };

    public readonly RoyaltySystems = {
        Rms: 1,
        Pivotal: 2,
        RmsManual: 3
    }

    static $inject = [
        "$window",
        "$timeout",
        "$compile",
        "$rootScope",
        "rmsState"
    ];

    constructor(
        private $window: ng.IWindowService,
        private $timeout: ng.ITimeoutService,
        private $compile: ng.ICompileService,
        private $rootScope: ng.IRootScopeService,
        private rmsState: RmsStateService,
    ) {

    }

    GetDefaultRateValidatedNumberField() {
        return {
            type: "number",
            validation: {
                required: true,
                validator: this.RateValidator
            }
        };
    }

    RateValidator(input: JQuery) {
        // set the custom message
        input.attr("data-validator-msg", "Value must be between 0 and 1");
        return ((0 <= input.val()) && (input.val() <= 1));
    }

    RoyaltyRateEditor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
        this.NumericEditor(container, options, {
            format: this.StringFormats.Number.RateNumber,
            decimals: 7,
            min: 0,
            max: 1,
            value: options.model[options.field],
            change: (e) => {
                options.model.set(options.field, e.sender.value());
            }
        });
    }

    DecimalEditor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
        this.NumericEditor(container, options, {
            format: "n4",
            decimals: 4,
            value: options.model[options.field],
            change: (e) => {
                options.model.set(options.field, e.sender.value());
            }
        });
    }

    CurrencyEditor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
        this.NumericEditor(container, options, {
            format: "c",
            decimals: 2,
            value: options.model[options.field],
            change: (e) => {
                options.model.set(options.field, e.sender.value());
            }
        });
    }

    PopulationEditor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
        this.NumericEditor(container, options, {
            format: "n0",
            decimals: 0,
            min: 0,
            max: Helpers.GetInt32Max(),
            value: options.model[options.field],
            change: (e) => {
                options.model.set(options.field, e.sender.value());
            }
        });
    }

    NumericEditor = (container: JQuery, options: kendo.ui.GridColumnEditorOptions, numericOptions: kendo.ui.NumericTextBoxOptions) => {
        numericOptions = angular.extend(<kendo.ui.NumericTextBoxOptions>
            {
                value: options.model[options.field],
                change: (e) => {
                    options.model.set(options.field, e.sender.value());
                },
                spinners: false
            },
            numericOptions
        );
        let input = $(`<input name='${options.field}' />`);
        input.appendTo(container);
        input.kendoNumericTextBox(numericOptions);
    }

    WeeksInBusinessEditor = () => {
        return (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
            this.NumericEditor(container, options, {
                format: "n0",
                decimals: 0,
                spinners: false,
                change: (e) => {
                    const row = $(container).closest("tr");
                    row.attr("data-dirty", "true");
                }
                
            });
        }
    }

    GetRollInRatesTypes() {
        type rollInTypes = "Small" | "Medium" | "Large";
        let arr: rollInTypes[] = [
            "Small",
            "Medium",
            "Large"
        ];
        return arr;
    }

    GetRollInRatesTypesNew() {
        type rollInTypes = "small" | "medium" | "large";
        let arr: rollInTypes[] = [
            "small",
            "medium",
            "large"
        ];
        return arr;
    }

    ConvertToRollInRates_DF(rateGroup: IRollInRateGroupDynamicFees, parentId: number) {
        let rirData: IRollInRateItemNew[] = [];
        for (let rollInType of this.GetRollInRatesTypesNew()) {
            rirData.push({
                typePrefix: rollInType,
                typeFee: rollInType + " Roll-in",
                ParentId: parentId,
                rollInSalesBegin: rateGroup[rollInType + "RollInLowRangeAmount"],
                rollInSalesEnd: rateGroup[rollInType + "RollInHighRangeAmount"],
                royaltyFeeRate: rateGroup[rollInType + "RollInRoyaltyFeeRate"],
                royaltyFeeFixedRateAmount: rateGroup[rollInType + "RollInRoyaltyFeeFixedRateAmount"],
                adFeeRate: rateGroup[rollInType + "RollInAdFeeRate"],
                adFeeFixedRateAmount: rateGroup[rollInType + "RollInAdFeeFixedRateAmount"]
            });
        }
        return rirData;
    }

    ConvertToRollInRates(rateGroup: IRollInRateGroup, parentId: number) {
        let rirData: IRollInRateItem[] = [];

        for (let rollInType of this.GetRollInRatesTypes()) {
            rirData.push({
                typePrefix: rollInType,
                typeFee: rollInType + " Roll-in",
                ParentId: parentId,
                rollInSalesBegin: rateGroup[rollInType + "RollInLowRangeAmount"],
                rollInSalesEnd: rateGroup[rollInType + "RollInHighRangeAmount"],
                royaltyFeeRate: rateGroup[rollInType + "RollInRoyaltyFeeRate"],
                royaltyFeeFixedRateAmount: rateGroup[rollInType + "RollInRoyaltyFeeFixedRateAmount"],
                adFeeRate: rateGroup[rollInType + "RollInAdFeeRate"],
                adFeeFixedRateAmount: rateGroup[rollInType + "RollInAdFeeFixedRateAmount"]
            });
        }

        return rirData;
    }

    ConvertToRollInRateMinimums(rateGroup: IRollInRateMinimumGroup, parentId: number) {
        let rirData: IRollInRateItem[] = [];

        for (let rollInType of this.GetRollInRatesTypes()) {
            rirData.push({
                typePrefix: rollInType,
                typeFee: rollInType + " Roll-in",
                ParentId: parentId,
                rollInSalesBegin: rateGroup[rollInType + "RollInLowRangeAmount"],
                rollInSalesEnd: rateGroup[rollInType + "RollInHighRangeAmount"],
                royaltyFeeRate: rateGroup[rollInType + "RollInMinimumRoyaltyFeeRate"],
                royaltyFeeFixedRateAmount: rateGroup[rollInType + "RollInMinimumRoyaltyFeeFixedRateAmount"],
                adFeeRate: rateGroup[rollInType + "RollInMinimumAdFeeRate"],
                adFeeFixedRateAmount: rateGroup[rollInType + "RollInMinimumAdFeeFixedRateAmount"]
            });
        }

        return rirData;
    }
    

    MergeRollInRateItem(rateGroup: RollInRateGroupUnion, rollInItem: IRollInRateItem) {
        rateGroup[rollInItem.typePrefix + "RollInAdFeeFixedRateAmount"] = rollInItem.adFeeFixedRateAmount;
        rateGroup[rollInItem.typePrefix + "RollInAdFeeRate"] = rollInItem.adFeeRate;
        rateGroup[rollInItem.typePrefix + "RollInHighRangeAmount"] = rollInItem.rollInSalesEnd;
        rateGroup[rollInItem.typePrefix + "RollInLowRangeAmount"] = rollInItem.rollInSalesBegin;
        rateGroup[rollInItem.typePrefix + "RollInRoyaltyFeeFixedRateAmount"] = rollInItem.royaltyFeeFixedRateAmount;
        rateGroup[rollInItem.typePrefix + "RollInRoyaltyFeeRate"] = rollInItem.royaltyFeeRate;
    }

    MergeRollInRateMinimumItem(rateGroup: IRollInRateMinimumGroup, rollInItem: IRollInRateItem) {
        rateGroup[rollInItem.typePrefix + "RollInMinimumAdFeeFixedRateAmount"] = rollInItem.adFeeFixedRateAmount;
        rateGroup[rollInItem.typePrefix + "RollInMinimumAdFeeRate"] = rollInItem.adFeeRate;
        rateGroup[rollInItem.typePrefix + "RollInHighRangeAmount"] = rollInItem.rollInSalesEnd;
        rateGroup[rollInItem.typePrefix + "RollInLowRangeAmount"] = rollInItem.rollInSalesBegin;
        rateGroup[rollInItem.typePrefix + "RollInMinimumRoyaltyFeeFixedRateAmount"] = rollInItem.royaltyFeeFixedRateAmount;
        rateGroup[rollInItem.typePrefix + "RollInMinimumRoyaltyFeeRate"] = rollInItem.royaltyFeeRate;
    }

    CreateIdArrayStringKey(ids: number[]) {
        return ids.sort().join("|");
    }

    IsRpmStatusSuccess(status: { Name: string }) {
        return (
            status.Name === "CalculateFeeComplete" ||
            status.Name === "Posted" ||
            status.Name === "Closed"
        );
    }

    ShowRmsNotification(type: "error" | "success", message: string) {
        let elementId = "rms-notification-element";
        let notificationElement = this.$window.document.getElementById(elementId);
        if (!notificationElement) {
            notificationElement = this.$window.document.createElement("div");
            notificationElement.id = elementId;
            this.$window.document.body.appendChild(notificationElement);
        }

        let notification = angular.element(notificationElement).kendoNotification({
            position: {
                top: 15,
                left: angular.element(this.$window).width() / 2
            },
            templates: [
                {
                    type: "error",
                    template: `<div class='save-error'>Error: #= message #</div>`
                },
                {
                    type: "success",
                    template: `<div class='save-success'><i class="fa fa-check-circle"></i> #= message #</div>`
                }
            ]
        }).data("kendoNotification");

        notification.show({ message: message }, type);
    }

    GetRmsRoyaltySystemConceptFilters() {
        return <kendo.data.DataSourceFilters>{
            logic: "or",
            filters: <kendo.data.DataSourceFilterItem[]>[
                { field: nameof<CoreEntities.Concept>(o => o.RoyaltySystemId), operator: "eq", value: this.RoyaltySystems.Rms },
                { field: nameof<CoreEntities.Concept>(o => o.RoyaltySystemId), operator: "eq", value: this.RoyaltySystems.Pivotal },
                { field: nameof<CoreEntities.Concept>(o => o.RoyaltySystemId), operator: "eq", value: this.RoyaltySystems.RmsManual }
            ]
        }
    }

    GetRmsRoyaltySystemFranchisorFilters() {
        return <kendo.data.DataSourceFilters>{
            logic: "or",
            filters: <kendo.data.DataSourceFilterItem[]>[
                { field: nameof<CoreEntities.Franchisor>(o => o.RoyaltySystemId), operator: "eq", value: this.RoyaltySystems.Rms },
                { field: nameof<CoreEntities.Franchisor>(o => o.RoyaltySystemId), operator: "eq", value: this.RoyaltySystems.Pivotal },
                { field: nameof<CoreEntities.Franchisor>(o => o.RoyaltySystemId), operator: "eq", value: this.RoyaltySystems.RmsManual }
            ]
        }
    }

    DefaultCatch() {
        return (error) => {
            console.log(error);
            this.ShowRmsNotification("error", JSON.stringify(error));
        }
    }
}