import { AngularUtil } from "Utility/AngularUtil";
import * as _ from "underscore"

import { NotificationGroupApiResources } from "Services/Resources/NotificationGroupApiResources";

export class DownTimeNotificationComponentController implements ng.IController {
    //---- Bindings ----
    //------------------

    notificationGrid: kendo.ui.Grid;
    notificationGridOptions: kendo.ui.GridOptions;
    isGridBound: boolean;

    appMultiSelectOptions: kendo.ui.DropDownListOptions;
    notificationTypeOptions: kendo.ui.DropDownListOptions;
    notificationStatusOptions: kendo.ui.DropDownListOptions;

    notificationWindow: kendo.ui.Window;
    notification: any;
    franchiseFilter: kendo.data.DataSourceFilterItem;
    localStorage: ILocalStorage;


    application:any;
    downtimeNotificationStatus:any;
    downtimeNotificationType:any;

    static $inject = [
        'notificationGroupApiResources',
        "$q",
        "$log",
        "$timeout"
    ];
    notificationWindowType: number;

    constructor(
        private notificationGroupApiResources: NotificationGroupApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService
    ) {

    }

    $onInit() {
        this.localStorage = <ILocalStorage>localStorage;
        this.isGridBound = false;
        this.LoadLookupData();
    }

    $postLink() {
        this.InitWindowOptions();
    }

    async LoadLookupData() 
    {
        this.notificationGroupApiResources.getApplications().then((application) => {
            this.application = application.data;
            this.notificationGroupApiResources.getDownTimeNotificationStatus().then((status) => {
                this.downtimeNotificationStatus = status.data;
                this.notificationGroupApiResources.getDownTimeNotificationType().then((downtimeNotificationType) => {
                    this.downtimeNotificationType = downtimeNotificationType.data;
                    this.SetDropdownOptions();
                    this.SetGridOptions(); 
                });
            });
        })
    }


    SetDropdownOptions() {
        this.notificationTypeOptions = {
            valuePrimitive: true,
            dataValueField: "downtimeNotificationTypeId",
            dataTextField: "description",
            optionLabel: "Select Notification Type",
            dataSource: new kendo.data.DataSource({ data: this.downtimeNotificationType })
        };

        this.appMultiSelectOptions = {
            valuePrimitive: true,
            dataValueField: "applicationId",
            dataTextField: "applicationName",
            dataSource: new kendo.data.DataSource({ data: this.application })
        };

        this.notificationStatusOptions = {
            valuePrimitive: true,
            dataValueField: "downtimeNotificationStatusId",
            dataTextField: "description",
            optionLabel: "Select notification status",
            dataSource: new kendo.data.DataSource({ data: this.downtimeNotificationStatus })
        };
    }

    SetGridOptions() {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: 'downtimeNotificationStatus',
                title: 'Status',
                attributes: {
                    "class": "active-icon-cell"
                },
                template: `<i ng-attr-title="{{dataItem.downtimeNotificationStatus === 'Active' ? 'Active' : 'InActive'}}" 
                                ng-class="'fa fa-circle  '+ (dataItem.downtimeNotificationStatus === 'Active' ? 'icon-success' : 'icon-error')">
                            </i> {{dataItem.downtimeNotificationStatus}}`
            }, 
            {
                field: 'downtimeNotificationType',
                title: "Notification Type"
            }, {
                field: 'description',
                title: "Description"
            }, {
                field: 'applications',
                title: "Application",
                template: '{{ dataItem.applications.join(", ")}}',
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            contains: "Contains"                        
                        }
                    }
                }
            },
            {
                field: 'startDate',
                title: "Start Date",
                template: "#= kendo.toString(kendo.parseDate(startDate), 'dd MMM yyyy hh:mm tt') #",
                filterable: false
            }, {
                field: 'endDate',
                title: "End Date",
                template: "#= kendo.toString(kendo.parseDate(endDate), 'dd MMM yyyy hh:mm tt') #",
                filterable: false
            }, {
                field: 'dateSent',
                title: "Email Sent Date",
                template: "#= kendo.toString(kendo.parseDate(dateSent), 'dd MMM yyyy hh:mm tt') #",
                filterable: false
            }, {
                field: 'emailStatus',
                title: "Email Status",
                template: `<p title="New" ng-if='dataItem.emailStatus === 1'><i class='fa fa-circle icon-info'> </i> New</p>
                <p title="In Progress" ng-if='dataItem.emailStatus === 2'><i class='fa fa-circle icon-warning'> </i> In Progress</p>
                <p title="Completed" ng-if='dataItem.emailStatus === 3'><i class='fa fa-circle icon-success'> </i> Completed</p>
                <p title="Error" ng-if='dataItem.emailStatus === 4'><i class='fa fa-circle icon-error'> </i> Error</p>
                `,
                filterable: false
            }, {
                template: `<button class='pure-button' ng-click='$ctrl.${nameof(this.OpenEditNotificationWindow)}(dataItem)'>Edit</button>`,
                width: 100,
                title: "&nbsp;"
            }, {
                template: `<button class='pure-button button-error' ng-click='$ctrl.${nameof(this.DeleteCampaign)}(dataItem)'>X</button>`,
                width: 70,
                title: "&nbsp;"
            }, {
                template: `<button class='pure-button pure-button-primary' style='font-size: 14px;' ng-if='(dataItem.emailStatus === 1 || dataItem.emailStatus === 3) && dataItem.downtimeNotificationStatusId === 1' ng-click='$ctrl.${nameof(this.sendEmail)}(dataItem)'>Send Email</button>`,
                width: 120,
                title: "&nbsp;"
            }];

        this.notificationGridOptions = {
            columns: columns,
            sortable: true,
            filterable: true,
            pageable: true,
            dataBound: (e) => {
                this.isGridBound = true;
            },
            dataSource: new kendo.data.DataSource({
                pageSize: 50,
                transport:
                {
                    read: (options: kendo.data.DataSourceTransportReadOptions) => {
                        this.isGridBound = false;
                        this.notificationGroupApiResources.GetAllNotification().then((notifications) => {

                            let result = notifications.data.map((data) => {
                                data.applications = [];
                                data.downtimeNotificationType = (this.downtimeNotificationType.filter((type) => type.downtimeNotificationTypeId == data.downtimeNotificationTypeId))[0].description;
                                data.downtimeNotificationStatus = (this.downtimeNotificationStatus.filter((type) => type.downtimeNotificationStatusId == data.downtimeNotificationStatusId))[0].description;

                                this.application.map((app) => {
                                    if(data.applicationIds.indexOf(app.applicationId) !== -1)
                                        data.applications.push(app.applicationName)
                                })

                                return data;
                            })
                            options.success(result);
                        },
                            (err) => {
                                options.error(err);
                            });
                    }
                }
            }),
            filterMenuInit: function(e) {
                if (e.field == "applications") {
                    var filterButton = e.container.find("button[type=submit]");
                    var clearButton = e.container.find("button[type=reset]");
                    var dataSource = jQuery("#downtime").data("kendoGrid").dataSource;
                    var firstValueDropDown = e.container.find("select:eq(0)").data("kendoDropDownList");
                    //Get rid of default filter button...
                    filterButton.remove();
                    
                    clearButton.parent().prepend("<button type='button' class='k-button k-primary'>Filter</button>");
                    
                    var filterText = e.container.find(".k-textbox");
                    filterButton = e.container.find("button[type=button]");
                    
                    filterButton.on('click', function(e) {
                      //get the value from dropdown
                        firstValueDropDown.value("contains");
                       dataSource.filter([
                        {
                            field: 'applications',
                            operator: function (items, filterValue) {
                              //filter the array with contains
                                for (var i = 0; i < items.length; i++) {
                                    if (items[i].indexOf(filterText.val()) > -1) {
                                        return true;
                                    }
                                }
                                return false;
                            },
                            value: filterText.val()
                        }
                    ]);
                    //after filter dropdown was loosing its value
                    firstValueDropDown.value("contains");
                    });
                    
                    clearButton.on('click', function() {
                        dataSource.filter([]); //clear filters...
                    });
                }
              },
        }
    }

    InitWindowOptions() {
        this.notificationWindow.setOptions(<kendo.ui.WindowOptions>{
            modal: true
        });
    }

    OpenNewNotificationWindow() {
        this.notification = {};
        this.notification.description = "";
        this.notification.applicationIds = [];
        this.notification.startDate = 0;
        this.notification.endDate = 0;
        this.notification.downtimeNotificationTypeId = 0; 
        this.notification.downtimeNotificationStatusId = 0;

        this.notificationWindowType = 0;
        this.notificationWindow.setOptions(<kendo.ui.WindowOptions>{
            title: "Create Notification"
        });

        this.$timeout(() => {
            this.notificationWindow.center();
        }, 0);
        this.notificationWindow.open();
    }

    OpenEditNotificationWindow(notification: any) {
        this.notificationWindowType = 1;
        this.notification = angular.copy(notification);
        this.notification.startDate = new Date(this.notification.startDate);
        this.notification.endDate = new Date(this.notification.endDate);
        this.notificationWindow.setOptions(<kendo.ui.WindowOptions>{
            title: "Edit Notification"
        });

        this.$timeout(() => {
            this.notificationWindow.center();
        }, 0);
        this.notificationWindow.open();
    }

    async SaveNotification() {

        let errorMessage = this.ValidateCampaign();
        if (errorMessage) {
            alert(errorMessage);
            return;
        }

        if (this.notificationWindowType) {
            this.notification.updatedUser = this.localStorage.AccountEmail;
            await this.notificationGroupApiResources.UpdateDownTimeNotification(this.notification).then(() => {
                return this.notificationGrid.dataSource.read().then(() => {
                    this.notificationWindow.close();
                    this.notificationGrid.refresh();
                });
            }), AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback(this.$log, this.$q)
        }
        else {
            this.notification.createdUser = this.localStorage.AccountEmail;
            await this.notificationGroupApiResources.CreateDownTimeNotification(this.notification).then(() => {
                return this.notificationGrid.dataSource.read().then(() => {
                    this.notificationWindow.close();
                    this.notificationGrid.refresh();
                });
            }), AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback(this.$log, this.$q)

        }
    }

    DeleteCampaign(notification: any) {
        if (confirm(`Are you sure you want to delete?`)) {
            notification.deletedDateTime = new Date();
            return this.notificationGroupApiResources.UpdateDownTimeNotification(notification).then(
                () => {
                    return this.notificationGrid.dataSource.read().then(() => {
                        this.notificationGrid.refresh();
                    });
                },
                AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback(this.$log, this.$q)
            )
        }
    }

    sendEmail(notification: any) {
        if (confirm(`Are you sure you want to send Email to all the active campaign owners?`)) {
            return this.notificationGroupApiResources.triggerEmailNotification(notification.downtimeNotificationId).then(
                () => {
                    alert("Successfully Email has been triggered!");
                    return this.notificationGrid.dataSource.read().then(() => {
                        this.notificationGrid.refresh();
                    });
                },
                AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback(this.$log, this.$q)
            )
        }
    }

    private ValidateCampaign(): string {
        let errorMessages: string[] = [];
        
        if (!this.notification.downtimeNotificationTypeId) { errorMessages.push("You must select a notification type."); }
        if (!this.notification.description) { errorMessages.push("You must enter a description."); }
        if (this.notification.applicationIds.length === 0) { errorMessages.push("You must select a application."); }
        if (!this.notification.downtimeNotificationStatusId) { errorMessages.push("You must select a Status."); }
        if (!this.notification.startDate) { errorMessages.push("You must select a start date."); }
        if (!this.notification.endDate) { errorMessages.push("You must select a end date."); }
        return errorMessages.join("\n");
    }


    static BindComponent(app: ng.IModule) {
        app.component("downTimeNotification", {
            bindings: {
            },
            controller: DownTimeNotificationComponentController,
            templateUrl: "/Templates/DownTimeNotification/DownTimeNotification.html",
        });
    }
}

