import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { NavigationService } from "@DppApp/Services/NavigationService";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { IEditDisasterPrepProfileSectionScope } from "@DppApp/EditDisasterPrepProfileSectionController";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

interface IEditDisasterPrepProfileBuildingServiceProvidersScope extends IEditDisasterPrepProfileSectionScope {
    globalServiceTypes: DppResources.IDisasterPrepProfileServiceProviderType[];
    profileServiceTypes: DppResources.IDisasterPrepProfileServiceProviderType[];

    localFranchises: CoreResources.IFranchise[];
    externalProviders: DppResources.IDisasterPrepProfileExternalProvider[];
    externalProviderDictionary: { [_id: string]: DppResources.IDisasterPrepProfileExternalProvider; };

    newServiceTypeName: string;

    CreateServiceType: (name: string) => void;
    RenameServiceType: (serviceType: DppResources.IDisasterPrepProfileServiceProviderType, newName: string) => void;    
    DeleteServiceType: (serviceType: DppResources.IDisasterPrepProfileServiceProviderType) => void;

    RefreshExternalProviders: () => ng.IPromise<DppResources.IDisasterPrepProfileExternalProvider[]>;
    SaveProviders: () => ng.IPromise<any>;
}

export class EditDisasterPrepProfileBuildingServiceProvidersController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('EditDisasterPrepProfileBuildingServiceProvidersController',
            [
                '$scope', 'coreApiResources', 'dppApiResources', '$q', "Navigation", 'odataHelper',
                ($scope: IEditDisasterPrepProfileBuildingServiceProvidersScope, coreApiResources: CoreApiResources, dppApiResources: DppApiResources, $q: ng.IQService, navigation: NavigationService, odataHelper: ODataHelperService) =>
                {
                    if (!$scope.dppBuildingId) {
                        $scope.RedirectHome();
                        return;
                    }

                    let localFranchisesPromise = $scope.GetCurrentBuilding().then((building: DppResources.IDisasterPrepProfileBuilding) =>
                    {
                        $scope.currentBuilding = building;
                        if (!$scope.currentBuilding.PostalCode) {
                            alert("You must have a valid address and postal code for your building set before you can edit service providers.");
                            navigation.Redirect("EditDisasterPrepProfileBuilding", $scope.dppBuildingId);
                            return null;
                        }

                        return coreApiResources.FranchiseApi.query({
                            $select: 'FranchiseId,ConceptId,DoingBusinessAs',
                            $filter: `FranchisePostalCodes/any(fpc: fpc/PostalCode eq '${$scope.currentBuilding.PostalCode}' and fpc/DeletedDateTime eq null)`
                        }).$promise.then(
                            (franchises: CoreResources.IFranchise[]) =>
                            {
                                $scope.localFranchises = franchises;
                            },
                            (err) =>
                            {
                                console.error(err);
                                alert("Couldn't get franchises in area.");
                            });
                    });

                    if (localFranchisesPromise) {
                        $scope.QueueLoadingPromises(localFranchisesPromise);
                    }

                    let paramsForProviderTypes = {
                        $expand: "DisasterPrepProfileServiceProviders($filter=DppBuildingId eq " + $scope.dppBuildingId + ")",
                        $filter: "DppId eq " + $scope.dppId + " or DppId eq null"
                    }

                    let serviceProvidersPromise = dppApiResources.DisasterPrepProfileServiceProviderTypeApi.query(paramsForProviderTypes).$promise.then(
                        (allServiceTypes: DppResources.IDisasterPrepProfileServiceProviderType[]) =>
                        {
                            for (var serviceType of allServiceTypes) {
                                if (serviceType.DisasterPrepProfileServiceProviders.length > 1) {
                                    console.warn(`More than one provider brought back '${serviceType.DisasterPrepProfileServiceProviders.length}' when there should be 0 to 1. Changes will only happen to the first element.`, serviceType.DisasterPrepProfileServiceProviders);
                                }

                                if (serviceType.DisasterPrepProfileServiceProviders.length === 0) {
                                    let newProvider = new dppApiResources.DisasterPrepProfileServiceProviderApi();
                                    newProvider.DppBuildingId = $scope.dppBuildingId;
                                    newProvider.DppServiceProviderTypeId = serviceType.DppServiceProviderTypeId;
                                    newProvider.UseFranchise = true;
                                    serviceType.DisasterPrepProfileServiceProviders.push(newProvider);
                                }
                            }

                            $scope.globalServiceTypes = allServiceTypes.filter((value) => { return value.DppId === null });
                            $scope.profileServiceTypes = allServiceTypes.filter((value) => { return value.DppId === $scope.dppId });
                        },
                        (err: ng.IHttpPromiseCallbackArg<any>) =>
                        {
                            console.error(err);
                            alert("Could not get the service types for this building.");
                        });

                    $scope.QueueLoadingPromises(serviceProvidersPromise);

                    $scope.RefreshExternalProviders = () =>
                    {
                        let refreshPromise = dppApiResources.DisasterPrepProfileExternalProviderApi.query({ $filter: `DppId eq ${$scope.dppId}` }).$promise.then(
                            (externalProviders: DppResources.IDisasterPrepProfileExternalProvider[]) =>
                            {
                                $scope.externalProviders = externalProviders;
                                $scope.externalProviderDictionary = {};
                                for (let p of $scope.externalProviders) {
                                    $scope.externalProviderDictionary["_" + p.DppExternalProviderId] = p;
                                }
                                return externalProviders;
                            });

                        $scope.QueueLoadingPromises(refreshPromise);

                        return refreshPromise;
                    }

                    $scope.RefreshExternalProviders();

                    $scope.RenameServiceType = (serviceType: DppResources.IDisasterPrepProfileServiceProviderType, newName: string) =>
                    {
                        serviceType.Name = newName;
                        return serviceType.$update({ id: serviceType.DppServiceProviderTypeId },
                            (response) =>
                            {
                                console.log(response);
                            },
                            (err) =>
                            {
                                console.error(err);
                            });
                    };

                    $scope.CreateServiceType = (name: string) =>
                    {
                        let newType = new dppApiResources.DisasterPrepProfileServiceProviderTypeApi();
                        newType.DppId = $scope.dppId;
                        newType.Name = name;

                        return newType.$save(
                            (response: DppResources.IDisasterPrepProfileServiceProviderType) =>
                            {
                                $scope.newServiceTypeName = "";
                                console.log(response);
                                let newProvider = new dppApiResources.DisasterPrepProfileServiceProviderApi();
                                newProvider.DppBuildingId = $scope.dppBuildingId;
                                newProvider.DppServiceProviderTypeId = response.DppServiceProviderTypeId;
                                newProvider.UseFranchise = true;
                                newType.DisasterPrepProfileServiceProviders.push(newProvider);
                                $scope.profileServiceTypes.push(newType);
                            },
                            (err) =>
                            {
                                console.error(err);
                            });
                    }

                    $scope.DeleteServiceType = (serviceType: DppResources.IDisasterPrepProfileServiceProviderType) =>
                    {
                        return serviceType.$delete({ id: serviceType.DppServiceProviderTypeId, allowHardDelete: true },
                            (response) =>
                            {
                                console.log(response);
                                $scope.profileServiceTypes.splice($scope.profileServiceTypes.indexOf(serviceType), 1);
                            },
                            (err) =>
                            {
                                console.error(err);
                            });
                    };

                    $scope.SaveProviders = () =>
                    {
                        let savePromises: Array<ng.IPromise<any>> = [];

                        return dppApiResources.DisasterPrepProfileServiceProviderApi.query({ $filter: "DppBuildingId eq " + $scope.dppBuildingId }).$promise.then(
                            (existingProviders: DppResources.IDisasterPrepProfileServiceProvider[]) =>
                            {
                                let allServiceTypes = $scope.globalServiceTypes.slice().concat($scope.profileServiceTypes.slice());
                                console.log("All service types. ", allServiceTypes);

                                let providersExisting: DppResources.IDisasterPrepProfileServiceProvider[] = [];
                                let providersMissing: DppResources.IDisasterPrepProfileServiceProvider[] = [];
                                allServiceTypes.forEach(
                                    (stValue) =>
                                    {
                                        let providerExists = existingProviders.some((xpValue) =>
                                        {
                                            return stValue.DppServiceProviderTypeId === xpValue.DppServiceProviderTypeId;
                                        });

                                        if (providerExists)
                                            providersExisting.push(<DppResources.IDisasterPrepProfileServiceProvider>stValue.DisasterPrepProfileServiceProviders[0]);
                                        else
                                            providersMissing.push(<DppResources.IDisasterPrepProfileServiceProvider>stValue.DisasterPrepProfileServiceProviders[0]);
                                    });


                                for (let updateProvider of providersExisting) {
                                    let promise = dppApiResources.DisasterPrepProfileServiceProviderApi.update(
                                        {
                                            id: odataHelper.ConvertCompositeKeyToString({
                                                DppBuildingId: updateProvider.DppBuildingId,
                                                DppServiceProviderTypeId: updateProvider.DppServiceProviderTypeId
                                            })
                                        },
                                        updateProvider,
                                        (response) => { console.log(response); },
                                        (err) => { console.error(err); }).$promise;

                                    savePromises.push(promise);
                                }
                                for (let createProvider of providersMissing) {
                                    let promise = dppApiResources.DisasterPrepProfileServiceProviderApi.save(
                                        {},
                                        createProvider,
                                        (response) => { console.log(response); },
                                        (err) => { console.error(err); }).$promise;
                                    savePromises.push(promise);
                                }

                                return $q.all(savePromises).then(
                                    (responses) =>
                                    {
                                        console.log(responses);
                                    },
                                    (err) =>
                                    {
                                        console.error(err);
                                    });
                            },
                            (err: ng.IHttpPromiseCallbackArg<any>) =>
                            {
                                console.error(err);
                            });
                    }
                }
            ]);
    }
}
