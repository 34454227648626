import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { MerchantApiResources } from "Services/Resources/MerchantApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { UrlQueryParameters } from "Utility/UrlQueryParameters";
import { ApiConfig } from "AppConfig/ApiConfig";

export interface IRedeemDisplayGiftCertificateStatus extends IGiftCertificateStatus
{
    ConceptName?: string;
    RedeemedByFranchiseName?: string;
}

export class RedeemGiftCertificateController implements ng.IController {

    franchiseId: number;
    giftCertificateId: string;
    giftCertificateSuffix: string;
    giftCertificateSuffixString: string;

    giftCertificateStatus: IRedeemDisplayGiftCertificateStatus;
    
    isHelpDeskUser: boolean;

    static $inject = [
        "$scope",
        "coreApiResources",
        "merchantApiResources",
        "$q",
        "$log",
        'identityManager',
        "apiConfig"
    ];

    constructor(
        private $scope: ng.IScope,
        private coreApiResources: CoreApiResources,
        private merchantApiResources: MerchantApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig
    )
    {

    }

    $onInit() {
        let gcId : string = new UrlQueryParameters(window).GetValue("cid");
        if (gcId && gcId.indexOf("-") !== -1)
        {
            let splitId = gcId.split("-");
            this.giftCertificateId = splitId[0];
            this.giftCertificateSuffix = splitId[1];
            this.giftCertificateSuffixString = splitId[1];
        }
        this.identityManager.GetLoggedInUserInfo()
            .then((loggedInUser) => {
                let isHelpDesk = (loggedInUser.data.Roles.some((value: IRoleViewModel) => {
                    return (value.Name.toLowerCase() === this.apiConfig.FranForceConstants.RoleConstants.HelpDeskRole.toLowerCase());
                }));
                
                this.isHelpDeskUser = isHelpDesk;
                
            });
    }

    GetCertStatus()
    {
        //TODO - Cert status really should be componentized between the refund and redeem page.
        return this.merchantApiResources.GetGiftCertificateStatus(this.giftCertificateId, (this.giftCertificateSuffixString || this.giftCertificateSuffix))
            .then((response) => {
                this.giftCertificateStatus = response.data;
                this.giftCertificateStatus.ConceptName = this.giftCertificateStatus.ConceptId.toString();

                this.coreApiResources.ConceptApi.get({ id: this.giftCertificateStatus.ConceptId, $select: "DisplayName" }).$promise.then((concept) => {
                    this.giftCertificateStatus.ConceptName = concept.DisplayName;
                });

                if (this.giftCertificateStatus.RedeemedByFranchiseId)
                {
                    this.giftCertificateStatus.RedeemedByFranchiseName = this.giftCertificateStatus.RedeemedByFranchiseId.toString();
                    this.coreApiResources.FranchiseApi.get({ id: this.giftCertificateStatus.RedeemedByFranchiseId, $select: "LicenseNumber" }).$promise.then((franchise) => {
                        this.giftCertificateStatus.RedeemedByFranchiseName = `${franchise.LicenseNumber} - (${this.giftCertificateStatus.RedeemedByFranchiseId})` ;
                    });
                }
            });
    }

    RedeemCertificate()
    {
        return this.merchantApiResources.RedeemGiftCertificate({
            GiftCertificateId: this.giftCertificateStatus.GiftCertificateId,
            GiftCertificateSuffix: (this.giftCertificateStatus.GiftCertificateSuffixString || this.giftCertificateStatus.GiftCertificateSuffix.toString()),
            RedeemingFranchiseId: this.franchiseId
        })
        .then((response) => {
            return this.GetCertStatus();
        });
    }

}