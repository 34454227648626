import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyDataTypesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetAllRoyaltyDataTypes()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyDataTypes"
        };

        return this.$http<RpmEntities.usp_RMS_GetKeyValueDataTypes_Result[]>(config);
    }
}