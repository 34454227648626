import { IdentityManager } from "../../../Services/Resources/IdentityManager";

export class rmsDashboardSectionsComponentController implements ng.IController {
    isLoading: boolean;
    sectionNames: string[] = ["Sales Variance", "Missing Reports", "Fee Variance", "Ad Fee Caps Breached"];
    tab: string;

    static $inject = [
        '$window',
        '$location',
        '$q',
        'identityManager',
        '$scope'
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private identityManager: IdentityManager,
        private $scope: ng.IScope
    ) {

    }

    $onInit() {
        this.isLoading = false;
        this.tab = this.GetSection();
    }

    GetSection() {
        let section = this.$location.search()[nameof<rmsDashboardSectionsComponentController>(o => o.tab)];
        let isValid = this.IsValidSection(section);
        let result = isValid ? section : this.sectionNames[0]
        if (!isValid) {
            this.SetSection(result);
        }
        return result;
    }

    SetSection(sectionName: string) {
        if (!this.IsValidSection(sectionName)) {
            throw "Invalid Section to Set: " + sectionName;
        }

        this.tab = sectionName;
        this.$location.search(nameof<rmsDashboardSectionsComponentController>(o => o.tab), sectionName);
    }

    IsValidSection(sectionName: string): boolean {
        return this.sectionNames.some(n => n === sectionName);
    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsDashboardSections", {
            bindings: {

            },
            templateUrl: '/Templates/RMSDashboard/rmsDashboardSections.html',
            controller: rmsDashboardSectionsComponentController
        });
    }
}