import { SyncApiResources } from "Services/Resources/SyncApiResources";
import { KendoUtil } from "Utility/KendoUtil";
import { SyncEntities } from "Interfaces/FranForce/Sync/SyncResources";

export class IndexMasterCustomersController implements ng.IController {
    mainGrid: kendo.ui.Grid;
    mainGridOptions: kendo.ui.GridOptions;
    mcnListGrid: kendo.ui.Grid;
    mcnListGridOptions: kendo.ui.GridOptions;

    masterCustomerId: number;
    isLoading: boolean;
    isServiceHistoryLoading: boolean;
    resultsLoaded: boolean;
    serviceHistoryResultsLoaded: boolean;
    results:  SyncEntities.McnJobHistorySyncCustomer;
    JobCollection: SyncEntities.McnServiceHistorySyncJob[];
    MasterCustomerInformation: SyncEntities.MasterCustomer;
    SelectedItem: number;
    ErrorMessage: string;

    static $inject = [
        "$scope",
        "syncApiResources",
        "$window",
        "$q",
        "$log"
    ];

    constructor(
        private $scope: ng.IScope,
        private syncApiResources: SyncApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService
    )
    {
        
    }

    $onInit() {
        this.ErrorMessage = null;
        this.mainGridOptions = {
            sortable: true,
            pageable: {
                numeric: false,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true
            },
            resizable: true,
            columns: [{
                field: "ConceptCode",
                title: "Concept",
                width: 150
            },
            {
                field: "CustomerName",
                title: "Name",
                width: 200
            },
            {
                field: "ServiceAddress",
                title: "Service Address",
                width: 250
            },
            {
                field: "CompletedOn",
                title: "Date",
                template: "#= kendo.toString(kendo.parseDate(CompletedOn, 'yyyy-MM-dd'), 'MM/dd/yyyy') #",
                width: 100
            },
            {
                field: "InvoiceTotal",
                title: "Total",
                format: "{0:c2}",
                width: 100
            },
            {
                field: "CustomerType",
                title: "Type",
                width: 100
            },
            {
                field: "JobTypeDescription",
                title: "Job Type",
                width: 250
            },
            {
                field: "JobNotes",
                title: "Notes",
                width: 350
            }
            ]
        };
        this.SelectedItem = -1;
        this.mcnListGridOptions = {
            sortable: true,
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true
            },
            resizable: true,
            columns: [
                {
                    field: "Name",
                    title: "Name",
                    width: 150
                },
                {
                    field: "CompanyName",
                    title: "Company Name",
                    width: 150
                },
                {
                    field: "Street",
                    title: "Street",
                    width: 200
                },
                {
                    field: "City",
                    title: "City",
                    width: 150
                },
                {
                    field: "State",
                    title: "State",
                    width: 100
                },
                {
                    field: "Zip",
                    title: "Zip",
                    width: 100
                },
                {
                    field: "ContactDetailCleaned",
                    title: "Contact Info",
                    width: 200
                }
                ,
                {
                    template: `<div class='pure-u-1' style='text-align: center;'>
                    <button style='width: 100%; box-sizing: border-box; margin-top: 5px;' 
                                    class='pure-button' 
                                    promise-btn
                                    ng-click='$ctrl.GetServiceHistoryByMCN(dataItem.MasterCustomerId,-1)'>
                                    Service History
                                </button>
                    </div>`,
                    width: 150
                }
                
            ],
            rowTemplate: `<tr id="#: uid #" style='background-color:#: UID==` + this.SelectedItem.toString() + ` ? "navy" : "white" #; color:#: UID==` + this.SelectedItem.toString() + ` ? "white" : "black" #'>
                                <td> #:Name #</td>
                                <td> #:CompanyName #</td>
                                <td> #:Street #</td>
                                <td> #:City #</td>
                                <td> #:State #</td>
                                <td> #:Zip #</td>
                                <td> #:ContactDetailCleaned #</td>
                                <td>
                                    <div class='pure-u-1' style='text-align: center;'>
                                        <button style='width: 100%; box-sizing: border-box; margin-top: 5px;' 
                                                        class='pure-button'
                                                        promise-btn
                                                        ng-click='$ctrl.GetServiceHistoryByMCN(#: MasterCustomerId #, #: UID #)'>
                                                        Service History
                                        </button>
                                    </div>
                                </td>
                            </tr>`
            
            

        };
    }
    

    GetServiceHistoryByMCN(masterCustomer: number, uid: number) {
        try {
            this.ErrorMessage = null;
            this.isServiceHistoryLoading = true;
            this.SelectedItem = uid;
            this.mcnListGridOptions.rowTemplate =
                `<tr style='background-color:#: UID==` + this.SelectedItem.toString() + ` ? "navy" : "white" #; color:#: UID==` + this.SelectedItem.toString() + ` ? "white" : "black" #'>
                <td> #:Name #</td>
                <td> #:CompanyName #</td>
                <td> #:Street #</td>
                <td> #:City #</td>
                <td> #:State #</td>
                <td> #:Zip #</td>
                <td> #:ContactDetailCleaned #</td>
                <td>
                    <div class='pure-u-1' style= 'text-align: center;' >
                        <button style='width: 100%; box-sizing: border-box; margin-top: 5px;'
                            class='pure-button'
                            promise-btn
                            ng-click='$ctrl.GetServiceHistoryByMCN(#:MasterCustomerId #,#: UID #)' >
                            Service History
                        </button>
                    </div>
                </td>
            </tr>`;
            this.mcnListGrid.setOptions(this.mcnListGridOptions);
            return this.syncApiResources.GetServiceHistoryByMCN(masterCustomer).then((results) => {
                this.JobCollection = [];
                if (results)
                    if (results.data)
                        for (let result of results.data.Customers) {
                            for (let job of result.Jobs) {
                                (<any>job).InvoiceTotal = 0;
                                for (let invoice of job.Invoices) {
                                    (<any>job).InvoiceTotal = (<any>job).InvoiceTotal + invoice.Total;
                                }
                                (<any>job).CustomerName = result.CustomerName;
                                (<any>job).CustomerType = result.CustomerType;
                                (<any>job).ConceptCode = job.BusinessUnit.ConceptCode;
                                this.JobCollection.push(job);
                            }
                        }
                this.mainGrid.setDataSource(new kendo.data.DataSource({
                    data: this.JobCollection,
                    pageSize: this.mainGrid.pager.pageSize()
                }));


                this.serviceHistoryResultsLoaded = true;
                this.isServiceHistoryLoading = false;

            }).catch((r) => {
                this.ErrorMessage = "An error occurred while retrieving service history. If this error persists, please contact The Dwyer Group with any details you can provide.";
                alert(this.ErrorMessage);
                console.log(r);
                this.serviceHistoryResultsLoaded = false;
                this.isServiceHistoryLoading = false;
                
            });;
        }
        catch (e) {
            this.ErrorMessage = "There was an error when changing the priority of this service. If this error persists, please contact The Dwyer Group with any details you can provide.";
            alert(this.ErrorMessage);
            console.log(e);
            this.serviceHistoryResultsLoaded = false;
            this.isServiceHistoryLoading = false;
            
        }
    }
    
    SearchForMCNListWithCriteria(firstName: string, lastName: string, address: string, city: string, state: string, postalCode: string, phone: string, email: string) {
        try {
            this.ErrorMessage = null;
            this.isLoading = true;
            this.SelectedItem = -1;
            

            this.mcnListGridOptions.rowTemplate =
                `<tr style='background-color:#: UID==` + this.SelectedItem.toString() + ` ? "navy" : "white" #; color:#: UID==` + this.SelectedItem.toString() + ` ? "white" : "black" #'>
                <td> #:Name #</td>
                <td> #:CompanyName #</td>
                <td> #:Street #</td>
                <td> #:City #</td>
                <td> #:State #</td>
                <td> #:Zip #</td>
                <td> #:ContactDetailCleaned #</td>
                <td>
                    <div class='pure-u-1' style= 'text-align: center;' >
                        <button style='width: 100%; box-sizing: border-box; margin-top: 5px;'
                            class='pure-button'
                            promise-btn
                            ng-click='$ctrl.GetServiceHistoryByMCN(#:MasterCustomerId #, #: UID #)' >
                            Service History
                        </button>
                    </div>
                </td>
            </tr>`;
            this.mcnListGrid.setOptions(this.mcnListGridOptions);
            this.serviceHistoryResultsLoaded = false;
            this.syncApiResources.GetMasterCustomerListBySearchCriteria(firstName, lastName, address, city, state, postalCode, phone, email).then((mcnList) => {
                var count = 1;
                if (mcnList)
                    if (mcnList.data)
                        for (let result of mcnList.data) {
                            (<any>result).UID = count;
                            count = count + 1;
                        }
                this.mcnListGrid.setDataSource(new kendo.data.DataSource({
                    data: mcnList.data,
                    pageSize: this.mcnListGrid.pager.pageSize()
                }));
                this.resultsLoaded = true;
                this.isLoading = false;
            }).catch((r) => {
                this.ErrorMessage = "An error occurred while searching for Master Customers. If this error persists, please contact The Dwyer Group with any details you can provide.";
                alert(this.ErrorMessage);
                console.log(r);
                this.resultsLoaded = false;
                this.isLoading = false;
                
            });
        }
        catch (e) {
            this.ErrorMessage = "An error occurred while searching for Master Customers. If this error persists, please contact The Dwyer Group with any details you can provide.";
            alert(this.ErrorMessage);
            console.log(e);
            this.resultsLoaded = false;
            this.isLoading = false;
            
        }
    }
}