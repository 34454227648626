import { KbsBaseComponentController } from "./KbsBaseComponent";

export class SectionsComponentController extends KbsBaseComponentController<KnowledgeResources.ISection> {
    categories: Array<KnowledgeResources.ICategory>;

    $onInit() {
        this.knowledgeApiResources.CategoriesApi.query({})
            .$promise
            .then((categories) => {
                this.categories = categories;
                super.$onInit();
            });
    }

    GetGridColumns(): kendo.ui.GridColumn[] {
        return [
            {
                field: "CategoryId",
                title: "Category",
                template: "{{$ctrl.GetCategoryName(dataItem.CategoryId)}}"
            },
            {
                field: "Description",
                title: "Description",
            },
            {
                template: "<button class='pure-button button-secondary' ng-click='$ctrl.OpenEditEntityWindow(dataItem)'>Edit</button>",
                width: 100,
                title: "&nbsp;"
            },
            {
                template: "<button class='pure-button button-error' ng-click='$ctrl.DeleteEntity(dataItem)'>X</button>",
                width: 70,
                title: "&nbsp;"
            }
        ];
    }

    GetDataSourceSchemaModel(): any {
        return { id: "SectionId" };
    }

    TransportReadOperationHandler(options: kendo.data.DataSourceTransportReadOptions) {
        this.knowledgeApiResources.SectionsApi.query({})
            .$promise.then(
                (sections) => {
                    options.success(sections);
                },
                (err) => {
                    options.error(err);
                });
    }

    CreateNewEntity(): KnowledgeResources.ISection {
        let section = new this.knowledgeApiResources.SectionsApi();
        section.SectionId = 0;
        section.CategoryId = 0;
        section.Description = "";

        return section;
    }

    GetSavePromise(section: KnowledgeResources.ISection): ng.IPromise<KnowledgeResources.ISection> {
        let sectionPromise: ng.IPromise<KnowledgeResources.ISection>;

        if (section.SectionId) {
            // Update
            sectionPromise = this.knowledgeApiResources.SectionsApi.update({ id: section.SectionId }, section).$promise;
        }
        else {
            // Create
            sectionPromise = section.$save();
        }

        return sectionPromise;
    }

    DeleteEntity(section: KnowledgeResources.ISection) {
        if (confirm(`Are you sure you want to delete '${section.Description}'?`)) {
            return this.knowledgeApiResources.SectionsApi.delete({ id: section.SectionId })
                .$promise.then(() => {
                    return this.RefreshGrid();
                });
        }
    }

    GetCategoryName(categoryId: number): string {
        const category = this.categories.find(a => a.CategoryId == categoryId);
        return category ? category.Description : "";
    }

    static BindComponent(app: ng.IModule) {
        app.component("kbsSections", {
            bindings: {
            },
            controller: SectionsComponentController,
            templateUrl: "/Templates/KBS/Sections.html",
        });
    }
}