import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { StringProperties } from "Types/StringProperties";

import * as _ from "underscore"
import * as elements from 'typed-html';

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            conceptSelector: Partial<StringProperties<ConceptSelectorComponentController>>;
        }
    }
}

export type ConceptSelectorComponentControllerState = { conceptIdParameter?: string };
export type ConceptSelectorOnSelectParameters = { concept: kendo.data.ObservableObject & Partial<CoreResources.IConcept> };
export type ConceptSelectorOnLoadParameters = { concepts: Array<Partial<CoreResources.IConcept>> };

export class ConceptSelectorComponentController implements ng.IController
{
    selectedModel: kendo.data.ObservableObject & Partial<CoreResources.IConcept>;
    dropdown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    //---- Bindings ----
    conceptId: number;
    select: string[];
    filters: kendo.data.DataSourceFilter;
    sort: (a: Partial<CoreResources.IConcept>, b: Partial<CoreResources.IConcept>) => number;
    disable: boolean;
    displayTextTemplate: string;
    defaultSelected:boolean;
    queryParams: { [key: string]: any };
    extraOptions: kendo.ui.DropDownListOptions;
    onSelect: (params: ConceptSelectorOnSelectParameters) => void;
    onLoad: (params: ConceptSelectorOnLoadParameters) => void;
    state: boolean | ConceptSelectorComponentControllerState;
    royaltySystemIdExistsCheck: boolean;
    //------------------

    static readonly DisplayProperty = "DisplayText";

    static $inject = [
        "$q",
        "$interpolate",
        "$location",
        "coreApiResources",
        "identityManager",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $q: ng.IQService,
        private $interpolate: ng.IInterpolateService,
        private $location: ng.ILocationService,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {

    }

    $onInit()
    {
        if (!this.select || !(this.select instanceof Array))
        {
            this.select = [
                nameof<CoreResources.IConcept>(o => o.ConceptId),
                nameof<CoreResources.IConcept>(o => o.DisplayName)
            ];
        }

        if (!this.displayTextTemplate)
            this.displayTextTemplate = `{{${nameof<CoreResources.IConcept>(o => o.DisplayName)}}}`;

        if (!this.sort)
            this.sort = (a, b) => { return a[ConceptSelectorComponentController.DisplayProperty].toLowerCase().localeCompare(b[ConceptSelectorComponentController.DisplayProperty].toLowerCase()); }

        this.InitState();
        this.SetDropDownOptions();
    }

    SetDropDownOptions()
    {
        let overridableOptions: kendo.ui.DropDownListOptions = {
            autoWidth: true,
            filter: "contains",
            height: 450,
            enable: !this.disable
        }

        let permanentOptions: kendo.ui.DropDownListOptions = {
            optionLabel: {
                [ConceptSelectorComponentController.DisplayProperty]: "Select Concept...",
                ConceptId: null
            },
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options: kendo.data.DataSourceTransportReadOptions) =>
                    {
                        if (this.filters === undefined || this.filters === null)
                        {
                            options.success([]);
                            return;
                        }

                        let filters: kendo.data.DataSourceFilters;
                        if (!_.isEmpty(this.filters))
                        {
                            filters = {
                                logic: "and",
                                filters: [
                                    this.filters
                                ]
                            };
                        }
                        else
                        {
                            filters = {
                                logic: "and",
                                filters: [

                                ]
                            };
                        }

                        if (this.royaltySystemIdExistsCheck)
                        {
                            let royaltySystemIdFilter: kendo.data.DataSourceFilterItem = {
                                field: nameof<CoreResources.IFranchisor>(o => o.RoyaltySystemId),
                                operator: "neq",
                                value: null
                            }
                            filters.filters.push(royaltySystemIdFilter)
                        }

                        let params: ODataQueryParameters = {};
                        params.$select = this.GetSelectString();

                        if (!_.isEmpty(filters))
                        {
                            params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersOrItemToString(filters);
                        }


                        if (this.queryParams)
                        {
                            params = angular.extend({}, this.queryParams, params);
                        }

                        this.coreApiResources.ConceptApi.query(params)
                            .$promise.then((concepts) =>
                            {
                                for (let concept of concepts)
                                {
                                    concept[ConceptSelectorComponentController.DisplayProperty] = this.$interpolate(this.displayTextTemplate)(concept);
                                }
                                if (this.sort)
                                {
                                    concepts = concepts.sort(this.sort);
                                }
                                if (this.onLoad)
                                {
                                    this.onLoad({ concepts: concepts.slice() });
                                }
                            
                                if(this.defaultSelected){
                                    this.conceptId=concepts.slice()[0].ConceptId;
                                    this.SelectById();
                                }

                                options.success(concepts);

                                if (this.conceptId)
                                {
                                    this.SelectById();
                                }
                            })
                            .catch(err => options.error(err));
                    }
                }
            }),
            dataTextField: ConceptSelectorComponentController.DisplayProperty,
            dataValueField: nameof<CoreResources.IConcept>(o => o.ConceptId),
            select: (e) =>
            {
   
                if (e.dataItem.ConceptId || this.dropdown.dataSource.data().length > 0)
                {
                    this.selectedModel = e.dataItem;
                    this.conceptId = this.selectedModel.ConceptId;
                    this.SetState();
                    this.onSelect({ concept: this.selectedModel });
                }
            }
        }

        this.options = angular.merge({}, overridableOptions, this.extraOptions, permanentOptions);
    }

    $postLink()
    {

    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
        if (onChanges[nameof(this.state)])
        {
            if (this.state === true)
            {
                this.state = {
                    conceptIdParameter: "conceptId"
                };
            }
        }

        if (onChanges[nameof(this.filters)])
        {
            this.Refresh()
        }

        if (onChanges[nameof(this.conceptId)])
        {
            this.SelectById();
        }

        if (onChanges[nameof(this.disable)])
        {
            if (this.dropdown)
            {
                this.dropdown.enable(!this.disable);
            }
        }
    }

    SelectById()
    {
        if (this.dropdown)
        {
            this.dropdown.select(c => c.ConceptId === this.conceptId);
            this.dropdown.trigger("select", <kendo.ui.DropDownListSelectEvent>{
                dataItem: this.dropdown.dataItem(),
                sender: this.dropdown,
                item: this.dropdown.element
            });
        }
    }

    Refresh()
    {
        if (this.dropdown)
        {
            this.dropdown.dataSource.read();
        }
    }

    InitState()
    {
        if (this.state && typeof this.state === "object")
        {
            let searchParam = this.$location.search()[this.state.conceptIdParameter];
            if (searchParam)
            {
                this.conceptId = parseInt(searchParam);
            }
        }
    }

    SetState()
    {
        if (this.state && typeof this.state === "object")
        {
            this.$location.search(this.state.conceptIdParameter, this.conceptId);
        }
    }

    private GetSelectString()
    {
        if (!this.select)
            this.select = [];

        if (!this.select.some(s => s == nameof<CoreResources.IConcept>(o => o.ConceptId)))
            this.select.push(nameof<CoreResources.IConcept>(o => o.ConceptId));

        return this.select.join(",")
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.conceptSelector);

        app.component(componentName, {
            bindings: {
                [nameof<ConceptSelectorComponentController>(o => o.conceptId)]: "<",
                [nameof<ConceptSelectorComponentController>(o => o.select)]: "<",
                [nameof<ConceptSelectorComponentController>(o => o.filters)]: "<",
                [nameof<ConceptSelectorComponentController>(o => o.sort)]: "<",
                [nameof<ConceptSelectorComponentController>(o => o.disable)]: "<",
                [nameof<ConceptSelectorComponentController>(o => o.queryParams)]: "<",
                [nameof<ConceptSelectorComponentController>(o => o.displayTextTemplate)]: "<",
                [nameof<ConceptSelectorComponentController>(o => o.defaultSelected)]: "<",
                [nameof<ConceptSelectorComponentController>(o => o.extraOptions)]: "<",
                [nameof<ConceptSelectorComponentController>(o => o.onSelect)]: "&",
                [nameof<ConceptSelectorComponentController>(o => o.onLoad)]: "&",
                [nameof<ConceptSelectorComponentController>(o => o.state)]: "<",
                [nameof<ConceptSelectorComponentController>(o => o.royaltySystemIdExistsCheck)]: "<"
            },
            controller: ConceptSelectorComponentController,
            template: `<select kendo-drop-down-list="$ctrl.dropdown"
                            k-options="$ctrl.options"></select>`,
        });
    }
}

