import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";


export class RoyaltyDisclosureDocumentRatePlanItemsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyDisclosureDocumentRatePlanItemsByRatePlan(ratePlanId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlanItems/InRatePlan/" + ratePlanId
        };

        return this.$http<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel[]>(config);
    }

    CreateRoyaltyDisclosureDocumentRatePlanItem(model: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlanItems",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyDisclosureDocumentRatePlanItem(id: number, model: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlanItems/" + id,
            data: model
        };

        return this.$http<number>(config);
    }
}