import { IdentityManager } from "Services/Resources/IdentityManager";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { RmsStateService } from "Services/State/RmsState";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { contractTerritoryTypeDataModel } from "../../../Models/Royalty/ContractTerritoryType/ContractTerritoryTypeDataModel";
import { ContractTerritoryTypeParams } from "../../Common";

export class CmTerritoryComponentController implements ng.IController {

    contractId: number;
    conceptId: number;
    franchisorId: number;
    init: (params: { self: CmTerritoryComponentController }) => void;

    isLoading: boolean;

    contract: RpmEntities.RoyaltyContractViewModel;
    territoryGrid: kendo.ui.Grid;
    gridQueryResponseHeaders: ODataQueryResponseHeaders;
    ownedPopulation: number;
    totalPopulation: number;
    formController: ng.IFormController;

    static $inject = [
        'identityManager',
        'coreApiResources',
        'rpmApiResources',
        'rpmUiApiResources',
        'rpmHelper',
        'rmsState',
        'odataHelper',
        '$q',
        '$timeout'
    ]

    constructor(
        private identityManager: IdentityManager,
        private coreApiResources: CoreApiResources,
        private rpmApiResources: RpmApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private rmsState: RmsStateService,
        private odataHelper: ODataHelperService,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService
    ) {
        
    }

    $onInit()
    {
        if (this.init) {
            this.init({ self: this });
        }
    }

    $postLink()
    {
        this.Load();
    }

    InitGrid()
    {
        let territoryGridDataSource = new kendo.data.DataSource({
            pageSize: 25,
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            schema: {
                model: {
                    id: nameof<CoreResources.IFranchisePostalCode>(o => o.FranchisePostalCodeId),
                    fields: {
                        [nameof<CoreResources.IFranchisePostalCode>(o => o.OwnershipType)]: { type: "string" },
                        [nameof<CoreResources.IFranchisePostalCode>(o => o.PostalCode)]: { type: "string" },
                        [nameof<CoreResources.IFranchisePostalCode>(o => o.City)]: { type: "string" },
                        [nameof<CoreResources.IFranchisePostalCode>(o => o.State)]: { type: "string" },
                        [nameof<CoreResources.IFranchisePostalCode>(o => o.County)]: { type: "string" },
                        [nameof<CoreResources.IFranchisePostalCode>(o => o.Country)]: { type: "string" },
                        [nameof<CoreResources.IFranchisePostalCode>(o => o.Population)]: { type: "number"  }
                    }
                },
                total: (data) => {
                    return this.gridQueryResponseHeaders["odata-count"];
                }
            },
            transport: {
                read: (options) => {
                    let params: ODataQueryParameters = this.odataHelper.ConvertKendoDataSourceTransportReadOptionsDataToParameterObject(options.data);
                    params.$filter = (params.$filter ? params.$filter + " and " : "");
                    params.$filter += `(${nameof<CoreResources.IFranchisePostalCode>(o => o.FranchiseId)} eq ${this.contract.FranchiseId})`;
                    params.$count = true;
                    this.coreApiResources.FranchisePostalCodeApi.query(params, (data, headersCallback) => { this.gridQueryResponseHeaders = headersCallback(); }).$promise
                        .then((postalCodes) => {
                            options.success(postalCodes);
                        }, (err) => { options.error(err); });
                }
            },
            
        });

        let territoryColumns: Array<kendo.ui.GridColumn> =
            [{
                field: nameof<CoreResources.IFranchisePostalCode>(o => o.OwnershipType),
                title: "Ownership Type"
            }, {
                field: nameof<CoreResources.IFranchisePostalCode>(o => o.PostalCode),
                title: "Postal Code"
            }, {
                field: nameof<CoreResources.IFranchisePostalCode>(o => o.City),
                title: "City"
            }, {
                field: nameof<CoreResources.IFranchisePostalCode>(o => o.State),
                title: "State"
            }, {
                field: nameof<CoreResources.IFranchisePostalCode>(o => o.County),
                title: "County"
            }, {
                field: nameof<CoreResources.IFranchisePostalCode>(o => o.Country),
                title: "Country"
            }, {
                field: nameof<CoreResources.IFranchisePostalCode>(o => o.Population),
                title: "Population"
            }];

        this.territoryGrid.setOptions(<kendo.ui.GridOptions> {
            columns: territoryColumns,
            dataSource: territoryGridDataSource,
            editable: false,
            sortable: true,
            filterable: true,
            pageable: true,
        });
    }

    Load() 
    {
        this.isLoading = true;
        this.ownedPopulation = -1;
        this.totalPopulation = -1;
        
        this.rmsState.royaltyContractStateHandler.Load(this.contractId)
            .then((contract) =>
            {
                this.contract = contract;
                this.InitGrid();
                return this.CalculateTotals()
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    CalculateTotals()
    {
        this.ownedPopulation = -1;
        this.totalPopulation = -1;

        return this.$q.all([
            this.coreApiResources.GetFranchiseOwnedPopulation(this.contract.FranchiseId).then((ownedPopulation) =>
            {
                this.ownedPopulation = ownedPopulation;
            }),
            this.coreApiResources.GetFranchiseTotalPopulation(this.contract.FranchiseId).then((totalPopulation) =>
            {
                this.totalPopulation = totalPopulation;
            })
        ]);
    }

    HasUnsavedChanges()
    {
        return this.formController.$dirty;
    }

    OnContractTerritoryTypeSelect(contractTerritoryType: ContractTerritoryTypeParams["contractTerritoryType"]) {
        this.contract.ContractTerritoryTypeId = contractTerritoryType.contractTerritoryTypeId;       
    }

    async Save()
    {
        this.isLoading = true;

        // logic to get ad fee cap exception and its amount
        let royaltyContractMinimumFeeIncreasesAndCapsByContract = await this.rpmUiApiResources.RoyaltyContractMinimumFeeIncreasesAndCapsByContract(this.contractId);
        let adFeeCapException = false;
        let adFeeCapExceptionAmount = 0;
        let adFeeCapsYearlyAmounts: RpmEntities.usp_RoyaltyContractMinimumFeeIncreasesAndCaps_GetByRoyaltyContractId_Result[];
        if (royaltyContractMinimumFeeIncreasesAndCapsByContract && royaltyContractMinimumFeeIncreasesAndCapsByContract.data) {

            adFeeCapsYearlyAmounts = royaltyContractMinimumFeeIncreasesAndCapsByContract.data.sort((t1, t2) => {
                if (t1.YearIncreased < t2.YearIncreased) { return 1; }
                if (t1.YearIncreased > t2.YearIncreased) { return -1; }
                return 0;
            })

            if (adFeeCapsYearlyAmounts && adFeeCapsYearlyAmounts.length > 0) {
                adFeeCapException = adFeeCapsYearlyAmounts[0].AdFeeCapException;
                adFeeCapExceptionAmount = adFeeCapsYearlyAmounts[0].AdFeeCap;
            }
        }

        return this.rpmUiApiResources.GetRoyaltyContract(this.contractId)
            .then((contractResponse) =>
            {
                contractResponse.data.OwnedTerritoryPopulation = this.contract.OwnedTerritoryPopulation;
                contractResponse.data.TotalTerritoryPopulation = this.contract.TotalTerritoryPopulation;
                contractResponse.data.THV_THH = this.contract.THV_THH;
                contractResponse.data.TotalTargetedValue = this.contract.TotalTargetedValue;
                contractResponse.data.AdFeeCapException = adFeeCapException;
                contractResponse.data.AdFeeCapExceptionAmount = adFeeCapExceptionAmount;
                if (this.contract.ContractTerritoryTypeId != null && this.contract.ContractTerritoryTypeId.toString().trim() == "")
                    contractResponse.data.ContractTerritoryTypeId = null;
                else
                    contractResponse.data.ContractTerritoryTypeId = this.contract.ContractTerritoryTypeId;
                return this.rpmUiApiResources.UpdateRoyaltyContract(this.contractId, contractResponse.data)
            })
            .then(() =>
            {
                this.rmsState.royaltyContractStateHandler.Clear(this.contractId);

                this.$timeout(() =>
                {
                    this.formController.$setPristine();
                }, 1000);
            })
            .finally(() => { this.isLoading = false;});
    }

    Cancel() {
        return this.Load();
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("cmTerritory", {
                bindings: {
                    [nameof<CmTerritoryComponentController>(o => o.contractId)]: "<",
                    [nameof<CmTerritoryComponentController>(o => o.franchisorId)]: "<",
                    [nameof<CmTerritoryComponentController>(o => o.conceptId)]: "<",
                    [nameof<CmTerritoryComponentController>(o => o.init)]: "&?"
                },
                templateUrl: '/Templates/ContractManagement/cmTerritory.html',
                controller: CmTerritoryComponentController
            });
    }
}
