import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { KendoUtil } from "Utility/KendoUtil";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";


let columns = [
    {
        field: nameof.full<CoreResources.IFranchisePostalCode>(o => o.Franchise.Concept.ConceptCode),
        title: "Concept Code",
    }, {
        field: nameof.full<CoreResources.IFranchisePostalCode>(o => o.Franchise.LicenseNumber),
        title: "Franchise",
    }, {
        field: nameof.full<CoreResources.IFranchisePostalCode>(o => o.FranchisePostalCodeId),
        title: "Id",
    }, {
        field: nameof.full<CoreResources.IFranchisePostalCode>(o => o.PostalCode),
        title: "Postal Code",
    }, {
        field: nameof.full<CoreResources.IFranchisePostalCode>(o => o.City),
    }, {
        field: nameof.full<CoreResources.IFranchisePostalCode>(o => o.State),
    }, {
        field: nameof.full<CoreResources.IFranchisePostalCode>(o => o.County),
    }, {
        field: nameof.full<CoreResources.IFranchisePostalCode>(o => o.OwnershipType),
        title: "Ownership Type",
    }
] as kendo.ui.GridColumn[]; 

export class IndexFranchisePostalCodesController implements ng.IController {

    mainGrid: kendo.ui.Grid;
    mainGridOptions: kendo.ui.GridOptions;
    franchiseId: number;
    
    static $inject = [
        "coreApiResources",
        "$window",
        "odataHelper"
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $window: ng.IWindowService,
        private odataHelper: ODataHelperService
    )
    {

    }

    $onInit()
    {
        this.InitGrid();
    }

    InitGrid()
    {
        this.mainGridOptions = {
            sortable: true,
            pageable: {
                numeric: false,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true
            },
            resizable: true,
            height: this.GetGridHeight(),
            columns: columns
        };
    }

    async SearchByFranchise(franchiseId: number)
    {
        let params = this.GetDefaultQueryParameters();
        params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersToString({
            logic: "and",
            filters: [
                { field: nameof.full<CoreResources.IFranchisePostalCode>(o => o.FranchiseId), operator: "eq", value: franchiseId },
                { field: nameof.full<CoreResources.IFranchisePostalCode>(o => o.Franchise.Status), operator: "eq", value: "Active" }
            ] as kendo.data.DataSourceFilterItem[]
        } as kendo.data.DataSourceFilters);

        let postalCodes = await this.coreApiResources.FranchisePostalCodeApi.query(params).$promise;
        this.mainGrid.setDataSource(new kendo.data.DataSource({
            data: postalCodes,
            pageSize: this.mainGrid.pager.pageSize()
        }));    
    }

    async SearchByPostalCode(postalCode: string)
    {
        let params = this.GetDefaultQueryParameters();
        params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersToString({
            logic: "and",
            filters: [
                { field: nameof.full<CoreResources.IFranchisePostalCode>(o => o.PostalCode), operator: "eq", value: postalCode },
                { field: nameof.full<CoreResources.IFranchisePostalCode>(o => o.Franchise.Status), operator: "eq", value: "Active" }
            ] as kendo.data.DataSourceFilterItem[]
        } as kendo.data.DataSourceFilters);

        let postalCodes = await this.coreApiResources.FranchisePostalCodeApi.query(params).$promise;
        this.mainGrid.setDataSource(new kendo.data.DataSource({
            data: postalCodes,
            pageSize: this.mainGrid.pager.pageSize()
        }));
    }

    GetDefaultQueryParameters(): ODataQueryParameters
    {
        let params: ODataQueryParameters = {};
        params.$select = this.odataHelper.ConvertKendoGridColumnsToSelectionString(columns);
        params.$expand = this.odataHelper.ConvertKendoGridColumnsToExpansionString([
            ...columns,
            { field: nameof.full<CoreResources.IFranchisePostalCode>(o => o.Franchise.ConceptId) },
            { field: nameof.full<CoreResources.IFranchisePostalCode>(o => o.FranchiseId) }
        ]);

        return params;
    }

    GetGridHeight()
    {
        let mainGrid = angular.element("#mainGrid");
        let windowEle = angular.element(this.$window);

        let gridHeight = windowEle.height() - mainGrid.offset().top - 15;

        if (gridHeight < 250) {
            gridHeight = 250;
        }
        return gridHeight;
    }
}
