import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyFeeTypesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetAllRoyaltyFeeTypes()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyFeeTypes"
        };

        return this.$http<RpmEntities.usp_RoyaltyFeeTypeResult[]>(config);
    }

    GetAllRoyaltyFeeTypeById(id: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyFeeTypes/" + id 
        };

        return this.$http<RpmEntities.usp_RoyaltyFeeTypeResult>(config);
    }
}
