import { AngularUtil } from "Utility/AngularUtil";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { BillingApiResources } from "Services/Resources/BillingApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import * as moment from "moment"
import { BillingResources, BillingEntities } from "Interfaces/FranForce/Billing/BillingResources";



export class FranchiseBillingSubscriptionsComponentController implements ng.IController
{
    //---- Bindings ----
    franchiseId: number;
    init: (params: { self: FranchiseBillingSubscriptionsComponentController }) => void;
    //------------------

    isRefreshing: boolean;
    
    billingSubscriptionWindow: kendo.ui.Window;
    billingSubscriptionWindowOptions: kendo.ui.WindowOptions;
    selectedSubscription: BillingResources.IBillingSubscription;

    servicesDropDownOptions: kendo.ui.DropDownListOptions;
    isSelectedServiceActive: boolean;

    typesDropDownOptions: kendo.ui.DropDownListOptions;
    isSelectedTypeActive: boolean;

    amountNumericTextOptions: kendo.ui.NumericTextBoxOptions;
    quantityNumericTextOptions: kendo.ui.NumericTextBoxOptions;

    subscriptionGrid: kendo.ui.Grid;
    
    static $inject = [
        "coreApiResources",
        "billingApiResources",
        "odataHelper",
        "$q",
        "$log",
        "$timeout"
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private billingApiResources: BillingApiResources,
        private odataHelper: ODataHelperService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService
    )
    {

    }

    $onInit()
    {
        if (this.init) {
            this.init({ self: this });
        }

        this.InitDropDownOptions();

        this.amountNumericTextOptions = {
            format: "c",
            decimals: 2, 
        }

        this.quantityNumericTextOptions = {
            format: "n0",
            decimals: 0,
            min: 0
        }

        this.billingSubscriptionWindowOptions = {
            close: (e) => {
                this.$timeout(() => {
                    this.selectedSubscription = null;
                }, 0);
            }
        };
    }

    $postLink()
    {
        this.InitGrid();
    }

    InitDropDownOptions()
    {
        this.servicesDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) =>
                    {
                        this.billingApiResources.BillingServiceApi.query({ AllowInactive: true }).$promise
                            .then((billingServices) =>
                            {
                                options.success(billingServices)
                            })
                            .catch((err) =>
                            {
                                options.error(err);
                            });
                    }
                },
                sort: [
                    { field: nameof<BillingResources.IBillingService>(o => o.Active), dir: "desc" },
                    { field: nameof<BillingResources.IBillingService>(o => o.InternalDescription), dir: "asc" },
                    { field: nameof<BillingResources.IBillingService>(o => o.ServiceCode), dir: "asc" }
                ]
            }),
            valuePrimitive: true,
            dataValueField: nameof<BillingResources.IBillingService>(o => o.BillingServiceID),
            dataTextField: nameof<BillingResources.IBillingService>(o => o.InternalDescription),
            filter: "contains",
            valueTemplate: `<small ng-class="dataItem.${nameof<BillingResources.IBillingService>(o => o.Active)} ? '' : 'icon-error'">
                                {{dataItem.${nameof<BillingResources.IBillingService>(o => o.InternalDescription)}}} 
                                {{dataItem.${nameof<BillingResources.IBillingService>(o => o.ServiceCode)} ? '(' + dataItem.${nameof<BillingResources.IBillingService>(o => o.ServiceCode)} + ')' : ''}}
                            </small>`,
            template: `<small ng-class="dataItem.${nameof<BillingResources.IBillingService>(o => o.Active)} ? '' : 'icon-error'">
                            {{dataItem.${nameof<BillingResources.IBillingService>(o => o.InternalDescription)}}} 
                            {{dataItem.${nameof<BillingResources.IBillingService>(o => o.ServiceCode)} ? '(' + dataItem.${nameof<BillingResources.IBillingService>(o => o.ServiceCode)} + ')' : ''}}
                        </small>`,
            optionLabel: "Select Service...",
            optionLabelTemplate: "Select Service...",
            height: 400,
            change: (e) => {
                this.$timeout(() =>
                {
                    let service: BillingResources.IBillingService = e.sender.dataItem() || {};
                    if (!this.selectedSubscription.BillingSubscriptionID) {
                        this.selectedSubscription.UnitPrice = service.ServicePrice;
                    }
                    this.isSelectedServiceActive = service.Active;
                }, 0);
            }
        };
        
        this.typesDropDownOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) =>
                    {
                        this.billingApiResources.BillingTypeApi.query({ AllowInactive: true }).$promise
                            .then((billingTypes) =>
                            {
                                options.success(billingTypes);
                            })
                            .catch((err) =>
                            {
                                options.error(err);
                            });
                    }
                },
                sort: [
                    { field: nameof<BillingResources.IBillingType>(o => o.Active), dir: "desc" },
                    { field: nameof<BillingResources.IBillingType>(o => o.TypeName), dir: "asc" }
                ]
            }),
            dataValueField: nameof<BillingResources.IBillingType>(o => o.BillingTypeID),
            dataTextField: nameof<BillingResources.IBillingType>(o => o.TypeName),
            valuePrimitive: true,
            filter: "contains",
            valueTemplate: `<small ng-class="dataItem.${nameof<BillingResources.IBillingType>(o => o.Active)} ? '' : 'icon-error'">
                    {{dataItem.${nameof<BillingResources.IBillingType>(o => o.TypeName)}}}
                </small>`,
            template: `<small ng-class="dataItem.${nameof<BillingResources.IBillingType>(o => o.Active)} ? '' : 'icon-error'">
                    {{dataItem.${nameof<BillingResources.IBillingType>(o => o.TypeName)}}}
                </small>`,
            optionLabel: "Select Type...",
            optionLabelTemplate: "Select Type...",
            height: 400,
            change: (e) => {
                this.$timeout(() =>
                {
                    let type: BillingResources.IBillingType = e.sender.dataItem() || {};
                    this.isSelectedTypeActive = type.Active;
                }, 0)
            }
        };
        
    }

    InitGrid()
    {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof.full<BillingEntities.BillingSubscription>(o => o.BillingService.ServiceDescription),
                title: "Service"
            }, {
                field: nameof.full<BillingEntities.BillingSubscription>(o => o.BillingService.ServiceCode),
                title: "Service Code"
            }, {
                field: nameof.full<BillingEntities.BillingSubscription>(o => o.BillingType.TypeName),
                title: "Type"
            }, {
                field: nameof.full<BillingEntities.BillingSubscription>(o => o.UnitPrice),
                title: "Amount",
                format: "{0:c}"
            }, {
                field: nameof.full<BillingEntities.BillingSubscription>(o => o.Notes),
                title: "Notes",
                template: `<a ng-show="dataItem.Notes"
                               kendo-tooltip
                               k-content="dataItem.Notes"
                               k-position="'top'"
                               class="pure-button">
                                <i class="fa fa-sticky-note-o" aria-hidden="true"></i>
                            </a>`
            }, {
                field: nameof.full<BillingEntities.BillingSubscription>(o => o.ServiceStartDate),
                title: "Start",
                format: "{0: MMM d, yyyy}"
            }, {
                field: nameof.full<BillingEntities.BillingSubscription>(o => o.ServiceEndDate),
                title: "End",
                format: "{0: MMM d, yyyy}"
            }, {
                field: nameof.full<BillingEntities.BillingSubscription>(o => o.IsContiguous),
                title: "Contiguous"
            }, {
                template: `<button class="pure-button"
                                kendo-tooltip
                                k-content="'Edit Subscription: ' + dataItem.${nameof.full<BillingEntities.BillingSubscription>(o => o.BillingSubscriptionID)}"
                                k-position="'top'"
                                ng-click="$ctrl.${nameof(this.EditBillingSubscription)}(dataItem)"
                                ng-disabled="$ctrl.${nameof(this.selectedSubscription)}">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>`
            },

        ];

        let options: kendo.ui.GridOptions = {
            sortable: true,
            columns: columns,
            dataSource: new kendo.data.DataSource({
                schema: {
                    model: {
                        fields: {
                            [nameof.full<BillingEntities.BillingSubscription>(o => o.ServiceStartDate)]: {
                                type: "date"
                            },
                            [nameof.full<BillingEntities.BillingSubscription>(o => o.ServiceEndDate)]: {
                                type: "date"
                            },
                            [nameof.full<BillingEntities.BillingSubscription>(o => o.UnitPrice)]: {
                                type: "number"
                            },
                        }
                    }
                },
                transport: {
                    read: (options) =>
                    {
                        this.isRefreshing = true;
                        let params: ODataQueryParameters = {
                            $filter: nameof<BillingEntities.BillingSubscription>(o => o.FranchiseID) + " eq " + this.franchiseId
                        };

                        params.$expand = this.odataHelper.ConvertKendoGridColumnsToExpansionString(columns);

                        this.billingApiResources.BillingSubscriptionApi.query(params).$promise
                            .then((subscriptions) =>
                            {
                                options.success(subscriptions)
                            })
                            .catch((err) =>
                            {
                                options.error(err);
                            })
                            .finally(() => { this.isRefreshing = false; });
                    }
                }
            }),

        };

        this.subscriptionGrid.setOptions(options);
    }

    CreateBillingSubscription()
    {
        this.selectedSubscription = new this.billingApiResources.BillingSubscriptionApi();
        this.selectedSubscription.FranchiseID = this.franchiseId;
        this.selectedSubscription.Active = true;
        this.selectedSubscription.Quantity = 1;

        this.billingSubscriptionWindow.center();
        this.billingSubscriptionWindow.open();
    }

    EditBillingSubscription(billingSubscription: BillingResources.IBillingSubscription)
    {
        this.selectedSubscription = angular.copy(billingSubscription);
        this.isSelectedServiceActive = this.selectedSubscription.Active;
        this.billingSubscriptionWindow.center();
        this.billingSubscriptionWindow.open();
    }

    ReloadSubscriptionGrid()
    {
        if (!this.subscriptionGrid)
            return;

        return this.$q.when(this.subscriptionGrid.dataSource.read())
            .then(() =>
            {
                this.subscriptionGrid.refresh();
            });
    }

    SaveSelectedSubscription() : ng.IPromise<any>
    {
        let errMeesages: string[] = [];

        if (this.selectedSubscription.Quantity < 0) {
            errMeesages.push("Quantity must be 0 or more.");
        }

        if (!this.selectedSubscription.BillingServiceID) {
            errMeesages.push("Service must be selected.");
        }

        if (!this.selectedSubscription.BillingTypeID) {
            errMeesages.push("Type must be selected.");
        }

        if (errMeesages.length > 0)
        {
            let combinedErrMessage = errMeesages.join(" ");
            alert(combinedErrMessage);
            return this.$q.reject(combinedErrMessage);
        }
        
        let savePromise: ng.IPromise<any>;
        
        if (this.selectedSubscription.BillingSubscriptionID) {
            savePromise = this.billingApiResources.BillingSubscriptionApi.get({ id: this.selectedSubscription.BillingSubscriptionID }).$promise
                .then((billingSubscription: BillingResources.IBillingSubscription) =>
                {
                    billingSubscription.BillingServiceID = this.selectedSubscription.BillingServiceID;
                    billingSubscription.BillingTypeID = this.selectedSubscription.BillingTypeID;
                    billingSubscription.UnitPrice = this.selectedSubscription.UnitPrice;
                    billingSubscription.Quantity = this.selectedSubscription.Quantity;
                    billingSubscription.ServiceStartDate = moment(this.selectedSubscription.ServiceStartDate).format("YYYY-MM-DD");
                    billingSubscription.ServiceEndDate = this.selectedSubscription.ServiceEndDate && moment(this.selectedSubscription.ServiceEndDate).format("YYYY-MM-DD");
                    billingSubscription.Notes = this.selectedSubscription.Notes;
                    billingSubscription.BillOps = this.selectedSubscription.BillOps;
                    billingSubscription.BillOpsNotes = this.selectedSubscription.BillOpsNotes;
                    billingSubscription.IsContiguous = this.selectedSubscription.IsContiguous;
                    return billingSubscription.$update({ id: billingSubscription.BillingSubscriptionID });
                });
        }
        else
        {
            this.selectedSubscription.ServiceStartDate = moment(this.selectedSubscription.ServiceStartDate).format("YYYY-MM-DD");
            this.selectedSubscription.ServiceEndDate = this.selectedSubscription.ServiceEndDate && moment(this.selectedSubscription.ServiceEndDate).format("YYYY-MM-DD");
            savePromise = this.selectedSubscription.$save();
        }

        return savePromise
            .then(() =>
            {
                this.selectedSubscription = null;
                this.servicesDropDownOptions.dataSource.read();
                this.typesDropDownOptions.dataSource.read(); 
                this.$q.when(this.subscriptionGrid.dataSource.read())
                    .then(() =>
                    {
                        this.subscriptionGrid.refresh();
                    })
                
                this.billingSubscriptionWindow.close();
            })
            .catch((err) =>
            {
                alert(JSON.stringify(err));
                return this.$q.reject(err);
            });
    }


    static BindComponent(app: ng.IModule)
    {
        let franchiseBillingSubscriptionOptions: ng.IComponentOptions = {
            bindings: {
                [nameof<FranchiseBillingSubscriptionsComponentController>(o => o.franchiseId)]: "<",
                [nameof<FranchiseBillingSubscriptionsComponentController>(o => o.init)]: "<"
            },
            controller: FranchiseBillingSubscriptionsComponentController,
            templateUrl: "/Templates/Billing/BillingSubscriptions.html",
        }

        app.component("franchiseBillingSubscriptions", franchiseBillingSubscriptionOptions);
    }
}

