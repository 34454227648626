import * as elements from 'typed-html';

import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import { ExpressionBinding } from 'Utility/AngularUtil';
import { StringProperties } from 'Types/StringProperties';
import { RoyaltyPeriodFrequencyViewModel } from '@nbly/dataaccess.typings/TypeGen/Dwyer.DataAccess.FranForce.Rpm';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            rmsRoyaltyPeriodFrequencyTypeSelector: StringProperties<Partial<RmsRoyaltyPeriodFrequencyTypeSelectorController>>;
        }
    }
}

export type RmsRoyaltyPeriodFrequencyTypeSelectorOnSelectParams = {
    event: kendo.ui.DropDownListSelectEvent,
    periodReportFrequency: RoyaltyPeriodFrequencyViewModel;
}

export class RmsRoyaltyPeriodFrequencyTypeSelectorController implements ng.IController {
    periodReportFrequencyId: number;
    readOnly: boolean;
    onSelect: (params: RmsRoyaltyPeriodFrequencyTypeSelectorOnSelectParams) => void;
    dropDown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    static $inject = [
        'rpmUiApiResources',
    ]

    constructor(
        private rpmUiApiResources: RpmUiApiResources
    ) {

    }

    $onInit() {
        this.InitDropdown();
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.setOptions({ enable: !this.readOnly })
        }
    }

    InitDropdown() {
        this.options = {
            autoWidth: true,
            valuePrimitive: true,
            dataTextField: nameof<RoyaltyPeriodFrequencyViewModel>(o => o.Description),
            dataValueField: nameof<RoyaltyPeriodFrequencyViewModel>(o => o.RoyaltyPeriodFrequencyId),
            optionLabel: "Select...",
            optionLabelTemplate: "Select...",
            enable: !this.readOnly,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        this.rpmUiApiResources.GetAllRoyaltyPeriodFrequencies()
                            .then((typesResponse) => {
                                options.success(typesResponse.data);
                            })
                            .catch((err) => {
                                options.error(err);
                            });
                    }
                }
            }),
            select: (e) => {
                if (this.onSelect) {
                    this.onSelect({
                        event: e,
                        periodReportFrequency: e.dataItem
                    });
                }
            }
        };
    }

    static BindComponent(app: ng.IModule) {
        const componentName = nameof<JSX.IntrinsicElements>(o => o.rmsRoyaltyPeriodFrequencyTypeSelector);
        app
            .component(nameof<JSX.IntrinsicElements>(o => o.rmsRoyaltyPeriodFrequencyTypeSelector), {
                bindings: {
                    [nameof<RmsRoyaltyPeriodFrequencyTypeSelectorController>(o => o.periodReportFrequencyId)]: "<",
                    [nameof<RmsRoyaltyPeriodFrequencyTypeSelectorController>(o => o.readOnly)]: "<",
                    [nameof<RmsRoyaltyPeriodFrequencyTypeSelectorController>(o => o.onSelect)]: "&?",
                },
                template: `<select kendo-drop-down-list="$ctrl.${nameof<RmsRoyaltyPeriodFrequencyTypeSelectorController>(o => o.dropDown)}"
                            ng-model="$ctrl.${nameof<RmsRoyaltyPeriodFrequencyTypeSelectorController>(o => o.periodReportFrequencyId)}"
                            k-options="$ctrl.${nameof<RmsRoyaltyPeriodFrequencyTypeSelectorController>(o => o.options)}">
                </select>`,
                controller: RmsRoyaltyPeriodFrequencyTypeSelectorController
            });
    }


}

