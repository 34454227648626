import { StringProperties } from "Types/StringProperties";
import { RoyaltyApiResources } from "../../../Services/Resources/RoyaltyApiResources";
import { fddYearDataModel } from "../../../Models/Royalty/DisclosureDocument/FddYearDataModel";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            rmsFddYearSelector: StringProperties<Partial<RmsFddYearSelectorComponentController>>;
        }
    }
}

export type RmsFddYearSelectorOnSelectParams = {
    event: kendo.ui.DropDownListSelectEvent,
    fddYear: fddYearDataModel
};

export class RmsFddYearSelectorComponentController implements ng.IController {
    fddYear: number;
    readOnly: boolean;
    onSelect: (params: RmsFddYearSelectorOnSelectParams) => void;
    dropDown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    static $inject = [
        'royaltyApiResources'
    ]

    constructor(
        private royaltyApiResources: RoyaltyApiResources
    ) {

    }

    $onInit() {
        this.options = {
            autoWidth: true,
            valuePrimitive: true,
            dataTextField: nameof<fddYearDataModel>(o => o.year),
            dataValueField: nameof<fddYearDataModel>(o => o.year),
            optionLabel: "Select...",
            optionLabelTemplate: "Select...",
            enable: !this.readOnly,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        this.royaltyApiResources.GetAllFddYears()
                            .then((yearssResponse) => {
                                options.success(yearssResponse.data);
                            })
                            .catch((err) => {
                                options.error(err);
                            });
                    }
                }
            }),
            select: (e) => {
                if (this.onSelect) {
                    this.onSelect({
                        event: e,
                        fddYear: e.dataItem
                    });
                }
            }
        };
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.setOptions({ enable: !this.readOnly })
        }
    }

    static BindComponent(app: ng.IModule) {
        const componentName = nameof<JSX.IntrinsicElements>(o => o.rmsFddYearSelector);
        app
            .component(nameof<JSX.IntrinsicElements>(o => o.rmsFddYearSelector), {
                bindings: {
                    [nameof<RmsFddYearSelectorComponentController>(o => o.fddYear)]: "<",
                    [nameof<RmsFddYearSelectorComponentController>(o => o.readOnly)]: "<",
                    [nameof<RmsFddYearSelectorComponentController>(o => o.onSelect)]: "&?",
                },
                template: `<select kendo-drop-down-list="$ctrl.${nameof<RmsFddYearSelectorComponentController>(o => o.dropDown)}"
                            ng-model="$ctrl.${nameof<RmsFddYearSelectorComponentController>(o => o.fddYear)}"
                            k-options="$ctrl.${nameof<RmsFddYearSelectorComponentController>(o => o.options)}">
                </select>`,
                controller: RmsFddYearSelectorComponentController
            });
    }
}