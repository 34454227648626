import { AxiosInstance } from "axios";
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";
import {
    BillingServiceClient,
    BillingServicePaginatedResultRm,
    BillingServicePaginatedResultVm,
    BillingGreatPlainsCodeClient,
    BillingGreatPlainsCodesVm,
    UpdateBillingServiceRm,
    UpdateBillingServiceActiveStatusRm
} from '@nbly/billing-orchestrations-clients';
import { isArray } from "underscore";

export class BillingServicesGridComponentController implements ng.IController {

    isLoading: boolean;
    billingGreatPlainsCodes: BillingGreatPlainsCodesVm[];

    //update related
    billingServiceIdForUpdate?: number;

    //delete related
    billingServiceIdForActivateDeactivate?: number;
    billingServiceIdForDeactivateHasSubscriptions?: boolean;

    billingServicesGrid: kendo.ui.Grid;

    updateServicePriceDialog: kendo.ui.Dialog;
    deactiveBillingServiceDialog: kendo.ui.Dialog;
    activateBillingServiceDialog: kendo.ui.Dialog;

    billingServicesGridOptions: kendo.ui.GridOptions;
    billingOrchestrationsClient: AxiosInstance;
    billingServicesClient: BillingServiceClient
    billingGreatPlainsCodeClient: BillingGreatPlainsCodeClient

    showSnackbar: boolean;
    snackbarType: string;
    snackbarMessage: string;

    static $inject = [
        "$rootScope",
        "$scope",
    ];

    constructor(
        private $rootScope: ng.IRootScopeService,
        private $scope: ng.IScope,
    ) {
        this.billingOrchestrationsClient = FranForceAxiosClientBuilder.BuildBillingOrchestrationsBaseClient();
        this.billingServicesClient = new BillingServiceClient("", this.billingOrchestrationsClient);
        this.billingGreatPlainsCodeClient = new BillingGreatPlainsCodeClient("", this.billingOrchestrationsClient);
    }

    async $onInit() {

        this.$rootScope.$on('refreshBillingServiceGrid', async (event, args) => {
            this.billingServicesGrid.dataSource.read();
        });

        await this.LoadGreatPlainsCodes();
        await this.LoadBillingServicesGrid();

        this.billingServiceIdForUpdate = null;
        this.billingServiceIdForActivateDeactivate = null;
        this.billingServiceIdForDeactivateHasSubscriptions = null;

        this.$scope.$digest();
    }

    $postLink() {
        if (this.updateServicePriceDialog) {
            this.updateServicePriceDialog.setOptions(<kendo.ui.DialogOptions>{
                title: "Update Billing Service Price",
                modal: true,
                width: "70%",
                maxHeight: 300,
                animation: true,
                actions: null,
                open: (e) => {

                },
                close: (e) => {
                    this.billingServicesGrid.dataSource.read();
                    this.billingServiceIdForUpdate = null;
                }
            })
        }

        if (this.deactiveBillingServiceDialog) {
            this.deactiveBillingServiceDialog.setOptions(<kendo.ui.DialogOptions>{
                title: "Deactivate Billing Service",
                modal: true,
                width: "50%",
                maxHeight: 300,
                animation: true,
                actions: null,
                open: (e) => {

                },
                close: (e) => {
                    this.billingServicesGrid.dataSource.read();
                    this.billingServiceIdForActivateDeactivate = null;
                    this.billingServiceIdForDeactivateHasSubscriptions = null;
                }
            })
        }

        if (this.activateBillingServiceDialog) {
            this.activateBillingServiceDialog.setOptions(<kendo.ui.DialogOptions>{
                title: "Activate Billing Service",
                modal: true,
                width: "50%",
                maxHeight: 300,
                animation: true,
                actions: null,
                open: (e) => {
                    
                },
                close: (e) => {
                    this.billingServicesGrid.dataSource.read();
                    this.billingServiceIdForActivateDeactivate = null;
                    this.billingServiceIdForDeactivateHasSubscriptions = null;
                }
            })
        }

    }

    DisplaySanackbar(type: string, message: string) {
        this.showSnackbar = !this.showSnackbar;
        this.snackbarType = type;
        this.snackbarMessage = message;
    }

    OnUpdateBillingServicePriceCancel() {
        this.updateServicePriceDialog.close();
        this.billingServiceIdForUpdate = null;
    }

    async LoadGreatPlainsCodes() {
        this.isLoading = true;
        await this.billingGreatPlainsCodeClient.billingGreatPlainsCodes()
            .then(response => {
                if (response && response.result) {
                    this.billingGreatPlainsCodes = response.result;
                }
            })
            .catch(ex => {
                console.log("Error loading GreatPlainsCodes", ex);
                this.DisplaySanackbar("error", "Error! Failed to fetch billing GreatPlainsCodes");
            })
            .finally(() => {
                this.isLoading = false;
                this.$scope.$digest();
            });

    }

    LoadBillingServicesGrid() {
        this.isLoading = true;
        let billingServicesGridDataSource = new kendo.data.DataSource({
            transport: {
                read: async (options) => {
                    if (options && options.data) {

                        let sortColumn = "BillingServiceId";
                        let sortColumnOrder = "DESC";
                        if (options.data && options.data.sort && isArray(options.data.sort) && options.data.sort.length > 0) {

                            if (options.data.sort[0].field == "billingGreatPlainsCodeId") {
                                sortColumn = "GreatPlainsCode";
                            }
                            else {
                                sortColumn = options.data.sort[0].field;
                            }     
                            sortColumnOrder = options.data.sort[0].dir;
                        }

                        let billingServicePaginatedResultRm: BillingServicePaginatedResultRm = {
                            active: null,
                            activeSubscriptions: null,
                            greatPlainsCode: null,
                            internalDescription: null,
                            serviceDescription: null,
                            serviceCode: null,
                            servicePrice: null,
                            pageNo: options.data.page,
                            pageSize: options.data.pageSize ?? 200000000,
                            sortColumn: sortColumn,
                            sortOrder: sortColumnOrder
                        }

                        if (options.data && options.data.filter && options.data.filter.filters && isArray(options.data.filter.filters) && options.data.filter.filters.length > 0) {
                            options.data.filter.filters.forEach(x => {

                                let filterField = x.field;
                                let filterValue = x.value;

                                switch (filterField) {
                                    case "active":
                                        billingServicePaginatedResultRm.active = filterValue;
                                        break;
                                    case "activeSubscriptions":
                                        billingServicePaginatedResultRm.activeSubscriptions = filterValue;
                                        break;
                                    case "billingGreatPlainsCodeId":
                                        billingServicePaginatedResultRm.greatPlainsCode = filterValue;
                                        break;
                                    case "internalDescription":
                                        billingServicePaginatedResultRm.internalDescription = filterValue;
                                        break;
                                    case "serviceDescription":
                                        billingServicePaginatedResultRm.serviceDescription = filterValue;
                                        break;
                                    case "serviceCode":
                                        billingServicePaginatedResultRm.serviceCode = filterValue;
                                        break;
                                    case "servicePrice":
                                        billingServicePaginatedResultRm.servicePrice = filterValue;
                                        break;
                                }
                            })
                        }


                        await this.billingServicesClient.getPaginatedResult(billingServicePaginatedResultRm)
                            .then(response => {

                                let billingServicePaginatedResult: BillingServicePaginatedResultVm = {
                                    result: response?.result?.result,
                                    total: response?.result?.total
                                };

                                options.success(billingServicePaginatedResult);

                                billingServicePaginatedResult.result.forEach(x => {
                                    if (x.active) {
                                        angular.element(document.querySelector("#billingServiceActiveCheckBox_" + x.billingServiceId)).prop('checked', true);

                                    }
                                    if (x.active == null || !x.active)
                                        angular.element(document.querySelector("#billingServiceActiveCheckBox_" + x.billingServiceId)).prop('checked', false);
                                });
                            })
                            .catch(ex => {
                                console.log("error fetching billing services", ex);
                                this.DisplaySanackbar("error", "Error! Failed to fetch billing services");
                            })
                            .finally(() => {
                                this.isLoading = false;
                                this.$scope.$digest();
                            })
                        
                    }
                }
            },
            serverPaging: true,
            serverSorting: true,
            serverFiltering: true,
            pageSize: 25,
            schema: {
                model: {
                    id: "billingServiceId",
                    fields: {
                        billingServiceId: {
                            type: "number",
                        },
                        active: {
                            type: "boolean",
                            editable: false
                        },
                        serviceCode: {
                            type: "string",
                        },
                        servicePrice: {
                            type: "number",
                        },
                        serviceDescription: {
                            type: "string"
                        },
                        internalDescription: {
                            type: "string"
                        },
                        greatPlainsCode: {
                            type: "string"
                        },
                        activeSubscriptions: {
                            type: "number",
                            editable: false
                        },
                    }
                },
                data: (data) => {
                    return data.result;
                },
                total: (data) => {
                    return data.total;
                }
            }
        });

        kendo.ui.FilterMenu.fn.options.operators.number = {
            eq: "Equal to"
        };

        let billingServicesGridColumns: Array<kendo.ui.GridColumn> =
            [
                {
                    field: "active",
                    template: `<div style='text-align:center'><input class="billingServiceActiveCheckBox" id="billingServiceActiveCheckBox_{{dataItem.billingServiceId}}" type='checkbox' ng-click="$ctrl.SetBillingServiceActiveStatus(!dataItem.active, dataItem.billingServiceId)"></div>`,
                    title: "Active",
                    filterable: {
                        extra: false,
                    },
                    width: "10%",
                    sortable: false
                },
                {
                    field: "serviceCode",
                    title: "Service Code",
                    filterable: {
                        extra: false,
                        operators: {
                            string: {
                                contains: "Contains",
                            }
                        }
                    },
                },
                {
                    field: "servicePrice",
                    title: "Service Price",
                    filterable: {
                        extra: false,
                    },
                    template: "$#=kendo.toString(Math.trunc(servicePrice * 100) / 100, 'n2')#",
                    editor: (container, options) => {
                        $('<input name="' + options.field + '"/>')
                            .appendTo(container)
                            .kendoNumericTextBox({
                                format: '{0:n2}',
                                round: false
                            });
                    }
                },
                {
                    field: "serviceDescription",
                    title: "Service Description",
                    filterable: {
                        extra: false,
                        operators: {
                            string: {
                                contains: "Contains",
                            }
                        }
                    },
                },
                {
                    field: "internalDescription",
                    title: "Internal Description",
                    filterable: {
                        extra: false,
                        operators: {
                            string: {
                                contains: "Contains",
                            }
                        }
                    },
                },
                {
                    field: "greatPlainsCode",
                    hidden: true
                },
                {
                    field: "billingGreatPlainsCodeId",
                    title: "Great Plains Code",
                    filterable: {
                        extra: false,
                        operators: {
                            string: {
                                contains: "Contains",
                            }
                        }
                    },
                    editor: (container, options) => {
                        let input = "<input required name='" + options.field + "'/>";
                        angular.element(container).append(input);
                        angular.element(angular.element(container).children()[0]).kendoDropDownList({
                            dataTextField: "greatPlainsCode",
                            dataValueField: "billingGreatPlainsCodeId",
                            dataSource: {
                                data: this.billingGreatPlainsCodes
                            },
                            change: (e) => {
                                //let dataItem = e.sender.dataItem();
                            }
                        });
                    },
                    template: "{{dataItem.greatPlainsCode}}"
                },
                {
                    field: "activeSubscriptions",
                    title: "Active Subscriptions",
                    filterable: {
                        extra: false,
                    }
                },
            ];

        this.billingServicesGridOptions = {
            toolbar: [
                {
                    template: `<button class='billingServices_clearFilterBtn' ng-click='$ctrl.OnClearGridFilters()'>Clear Filters</button>`
                }
            ],
            autoBind: false,
            dataSource: billingServicesGridDataSource,
            columns: billingServicesGridColumns,
            sortable: true,
            scrollable: true,
            filterable: true,
            editable: true,
            cellClose: async (e) => {
                this.billingServiceIdForUpdate = null;

                if (e.model["active"] && e.model["dirty"]) {

                    this.billingServiceIdForUpdate = e.model["billingServiceId"];

                    if (e.model["dirtyFields"]["servicePrice"] && e.model["activeSubscriptions"] > 0) {
                        this.updateServicePriceDialog.open();
                    }
                    else {
                        let updateBillingServiceRm: UpdateBillingServiceRm = {
                            billingServiceId: e.model["billingServiceId"],
                            serviceCode: e.model["serviceCode"],
                            servicePrice: e.model["servicePrice"],
                            serviceDescription: e.model["serviceDescription"],
                            internalDescription: e.model["internalDescription"],
                            billingGreatPlainsCodeId: e.model["billingGreatPlainsCodeId"],
                            isPriceChanged: false,
                            applySubscriptionOnlyWithPrice: true,
                        }

                        await this.UpdateBillingService(updateBillingServiceRm);
                    }
                }
            },
            edit: function (e) {
                if (!e.model["active"])
                    this.closeCell();          
            },
            pageable: {
                numeric: true,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true,
            },
        };

    }

    OnClearGridFilters() {
        this.billingServicesGrid.dataSource.filter([]);
    }

    GreatPlainsCodeDropDownEditor(container, options) {
        $('<input required name="' + options.field + '"/>')
            .appendTo(container)
            .kendoDropDownList({
                autoBind: false,
                dataTextField: "greatPlainsCode",
                dataValueField: "billingGreatPlainsCodeId",
                dataSource: {
                    data: this.billingGreatPlainsCodes
                }
            });
    }

    SetBillingServiceActiveStatus(status, billingServiceId) {

        let datasourceData = this.billingServicesGrid.dataSource.data();

        for (let i = 0; i < datasourceData.length; i++) {

            if (billingServiceId == datasourceData[i].billingServiceId) {

                if (datasourceData[i].activeSubscriptions > 0)
                    this.billingServiceIdForDeactivateHasSubscriptions = true;
            }
        }

        this.billingServiceIdForActivateDeactivate = billingServiceId;

        if (status) {
            this.activateBillingServiceDialog.open();
        }
        else {
            this.deactiveBillingServiceDialog.open();
        }

    }

    async ActivateBillingService() {
        await this.UpdateBillingServiceActiveStatus(true, false);
    }

    async DeactivateBillingService(deleteActiveSubscriptions: boolean) {
        await this.UpdateBillingServiceActiveStatus(false, deleteActiveSubscriptions);
    }

    CancelActivateDeactivateBillingService() {
        this.activateBillingServiceDialog.close();
        this.deactiveBillingServiceDialog.close();
        this.billingServicesGrid.dataSource.read();
        this.billingServiceIdForActivateDeactivate = null;
        this.billingServiceIdForDeactivateHasSubscriptions = null;
    }

    async UpdateBillingServiceActiveStatus(activate: boolean, deactivateActiveSubscriptions: boolean) {
        let updateBillingServiceActiveStatusRm: UpdateBillingServiceActiveStatusRm = {
            activate: activate,
            deactivateActiveSubscriptions: deactivateActiveSubscriptions,
            billingServiceId: this.billingServiceIdForActivateDeactivate
        }
        this.isLoading = true;
        await this.billingServicesClient.updateActiveStatus(this.billingServiceIdForActivateDeactivate, updateBillingServiceActiveStatusRm)
            .then(x => {
                //add success snackbar
            })
            .catch(ex => {
                console.log("error updating billing service status", ex);
                this.DisplaySanackbar("error", "Error! Failed to update billing service status");
            })
            .finally(() => {
                this.isLoading = false;
                this.activateBillingServiceDialog.close();
                this.deactiveBillingServiceDialog.close();
                this.billingServicesGrid.dataSource.read();
                this.billingServiceIdForActivateDeactivate = null;
                this.billingServiceIdForDeactivateHasSubscriptions = null;
                this.$scope.$digest();
            })
    }

    async UpdateBillingServiceSubscriptionPrice(updateForAll?: boolean) {
        let updateBillingServiceRm: UpdateBillingServiceRm = {} as UpdateBillingServiceRm;
        let datasourcedata = this.billingServicesGrid.dataSource.data();
        for (let i = 0; i < datasourcedata.length; i++) {
            let billingServiceId = datasourcedata[i].billingServiceId;
            if (billingServiceId == this.billingServiceIdForUpdate) {
                updateBillingServiceRm.billingServiceId = datasourcedata[i].billingServiceId;
                updateBillingServiceRm.serviceCode = datasourcedata[i].serviceCode;
                updateBillingServiceRm.servicePrice = datasourcedata[i].servicePrice;
                updateBillingServiceRm.serviceDescription = datasourcedata[i].serviceDescription;
                updateBillingServiceRm.internalDescription = datasourcedata[i].internalDescription;
                updateBillingServiceRm.billingGreatPlainsCodeId = datasourcedata[i].billingGreatPlainsCodeId;
                updateBillingServiceRm.isPriceChanged = true;

                if (updateForAll == null)
                    updateBillingServiceRm.applySubscriptionOnlyWithPrice = null;
                else if (updateForAll == true)
                    updateBillingServiceRm.applySubscriptionOnlyWithPrice = false;
                else if (updateForAll == false)
                    updateBillingServiceRm.applySubscriptionOnlyWithPrice = true;
            }
        }
        await this.UpdateBillingService(updateBillingServiceRm);
    }

    UpdateBillingServiceSubscriptionPriceCancel() {
        this.updateServicePriceDialog.close();
    }

    async UpdateBillingService(updateBillingServiceRm: UpdateBillingServiceRm) {
        this.isLoading = true;
        await this.billingServicesClient.billingServices2(updateBillingServiceRm.billingServiceId, updateBillingServiceRm)
            .then(x => {
                this.DisplaySanackbar("success", "Billing service updated successfully");
            })
            .catch(ex => {
                console.log("error updating billing service price", ex);
                this.DisplaySanackbar("error", "Error! Failed to update billing service");
            })
            .finally(() => {
                this.isLoading = false;
                this.updateServicePriceDialog.close();
                this.billingServicesGrid.dataSource.read();
                this.billingServiceIdForUpdate = null;
                this.$scope.$digest();
            })
    }

    static BindComponent(app: ng.IModule) {
        app.component("billingServicesGrid", {
            bindings: {
            },
            controller: BillingServicesGridComponentController,
            templateUrl: "/Templates/Zorware/BillingServices/BillingServicesGrid.html"
        });

    }
}