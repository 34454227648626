import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import { LanguageDto } from '@nbly/franchise-employee-group-clients';
import { FranchiseProfileEmployeeBioLanguageXrefClient,LanguageXrefRm,
    FranchiseProfileEmployeeBioDto,FranchiseProfileEmployeeBioSearchDto,
} from '@nbly/franchise-employee-orchestrations-clients'


export class EmployeeBiosLanguageComponent implements ng.IController
{
    //---- Bindings ----
    franchiseId: number;
    languageList: LanguageDto[];
    conceptCode: string;
    franchiseProfileEmployeeBioId: number;
    franchiseProfileEmployeeBioDetailId: number;
    employeeBioDetail: FranchiseProfileEmployeeBioDto;
    employeeBio: FranchiseProfileEmployeeBioSearchDto;
    onApiSave: () => void;
    //------------------

    isLoading: boolean;
    selectedLangauges: {};
    languageArr: [number];
    

    //Clients
    employeeOrchestrationsClient: AxiosInstance;
    franchiseProfileEmployeeBioLanguageXrefClient: FranchiseProfileEmployeeBioLanguageXrefClient;

    static $inject = [];

    constructor()
    {

    }

    $onInit()
    {
        this.employeeOrchestrationsClient = FranForceAxiosClientBuilder.BuildEmployeeOrchestrationsBaseClient();
        this.franchiseProfileEmployeeBioLanguageXrefClient = new FranchiseProfileEmployeeBioLanguageXrefClient("", this.employeeOrchestrationsClient);
        this.convertToLanguageModel();
    }

    convertToLanguageModel(){
        if(this.employeeBioDetail && this.employeeBioDetail.franchiseProfileEmployeeBioDetail && this.employeeBioDetail.franchiseProfileEmployeeBioDetail.languages)
        {
            if(this.employeeBioDetail.franchiseProfileEmployeeBioDetail.languages.length){
                this.selectedLangauges={};
                this.employeeBioDetail.franchiseProfileEmployeeBioDetail.languages.map((val)=>{
                    this.selectedLangauges[val.languageId]=true
                    });
            }
        }
    }

    async saveLanguageDetails()
    {
        let langaugeObj = Object.entries(this.selectedLangauges);
      let languageDto: LanguageXrefRm=<LanguageXrefRm>{};  
        languageDto.languageIds=[];
        langaugeObj.forEach(([key, value]) =>
        {
            if (value)
            {
                languageDto.languageIds.push(parseInt(key))
            }
        });

        await this.franchiseProfileEmployeeBioLanguageXrefClient.bulkMerge(
            this.employeeBioDetail.franchiseProfileEmployeeBioDetail.franchiseProfileEmployeeBioDetailsId, //should be detail id
            languageDto
        )
        .then(() =>
        {
            this.onApiSave();
           console.log('Language DETAILS SAVED')
        })


    }

    static BindComponent(app: ng.IModule)
    {
        app.component("employeeBiosLanguage", {
            bindings: {
                franchiseId: "<",
                [nameof<EmployeeBiosLanguageComponent>(o => o.languageList)]: "<",
                [nameof<EmployeeBiosLanguageComponent>(o => o.conceptCode)]: "<",
                [nameof<EmployeeBiosLanguageComponent>(o => o.franchiseProfileEmployeeBioId)]: "<",
                [nameof<EmployeeBiosLanguageComponent>(o => o.franchiseProfileEmployeeBioDetailId)]: "<",
                [nameof<EmployeeBiosLanguageComponent>(o => o.employeeBioDetail)]: "=",
                [nameof<EmployeeBiosLanguageComponent>(o => o.employeeBio)]: "=",
                onApiSave: "&",
            },
            controller: EmployeeBiosLanguageComponent,
            templateUrl: "/Templates/EmployeeBios/EmployeeBiosLanguage.html",
        });
    }
}

