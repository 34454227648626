export class PromiseUtil
{
    static async Finally<T = any>(promise: PromiseLike<T>, func: () => void)
    {
        promise.then(
            (result) =>
            {
                func();
                return result;
            },
            (error) =>
            {
                func();
                throw error;
            }
        )
    }

    static async TrackLoadingPromise<TResult = any>(trackingObj: { isLoading: boolean }, promise: PromiseLike<TResult>)
    {
        trackingObj.isLoading = true;
        return this.Finally(promise, () =>
        {
            trackingObj.isLoading = false;
        });
    }
}