import { IdentityManager } from "Services/Resources/IdentityManager";
import { KendoUtil } from "Utility/KendoUtil";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { userStateObj } from "Services/State/EditUserStateService";
import { CoreEntities, CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import
{
    EducationTypeClient, EducationTypeDto,
    GenderClient, GenderDto,
    LanguageClient, LanguageDto,
    FranchiseProfileEmployeeBioClient as FranchiseProfileEmployeeBioGroupClient,
} from '@nbly/franchise-employee-group-clients';
import
{
    FranchiseEmployeeProfileSearchClient, FranchiseProfileEmployeeBioSearchDto,
    FranchiseProfileEmployeeBioCertificationClient,
    GetFranchiseProfileEmployeeBioCertificationDto,
    FranchiseEmployeeBioSearchResultVm,
    FranchiseProfileEmployeeBioClient
} from '@nbly/franchise-employee-orchestrations-clients'
import { ApiConfig } from "AppConfig/ApiConfig";
import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import { EmployeeBiosSearchResources } from "Services/Resources/EmployeeBiosSearchResources";
import
{
    FranchiseWebLocationClient,
    FranchiseWebLocationDto
} from '@nbly/franchise-group-api-clients';


export class EmployeeBiosGridComponentController implements ng.IController
{
    grid: kendo.ui.Grid;
    gridQueryResponseHeaders: ODataQueryResponseHeaders;
    conceptId: number;
    conceptCode: string;
    franchiseId: number;
    employeeBioWindow: kendo.ui.Window;
    employeeBioWindowOptions: kendo.ui.WindowOptions;
    selectedEmployeeBio: FranchiseProfileEmployeeBioSearchDto;
    deletedEmployeeBio: FranchiseProfileEmployeeBioSearchDto;

    employeeGroupClient: AxiosInstance;
    employeeOrchestrationsClient: AxiosInstance;
    educationTypeClient: EducationTypeClient;
    genderClient: GenderClient;
    languageClient: LanguageClient;
    franchiseProfileEmployeeBioClient: FranchiseProfileEmployeeBioClient;
    franchiseProfileEmployeeBioGroupClient: FranchiseProfileEmployeeBioGroupClient;
    franchiseEmployeeProfileSearchClient: FranchiseEmployeeProfileSearchClient;
    franchiseEmployeeBioSearchResultVm: FranchiseEmployeeBioSearchResultVm[]
    genderList: GenderDto[];
    educationTypeList: EducationTypeDto[];
    languageList: LanguageDto[];
    certificateList: GetFranchiseProfileEmployeeBioCertificationDto[];
    franchiseProfileEmployeeBioCertificationClient: FranchiseProfileEmployeeBioCertificationClient;
    searchUrl: string;
    conceptFilters: kendo.data.DataSourceFilter;
    conceptSelect: string[];

    ownedFranchiseIds: number[];
    userState: userStateObj;
    userInfo: IUserInfoViewModel;
    franchiseDetailsArray: CoreResources.IFranchise[] = [];
    isLoading: boolean = true;
    noPermissionFilter: boolean = false;
    noConceptsFound:boolean=false;

    franchiseWebLocationId: number=null;
    franchiseWebLocationListClient: FranchiseWebLocationClient;
    franchiseWebLocationDtoArray: FranchiseWebLocationDto[];
    franchiseWebLocationDto: FranchiseWebLocationDto;
    franchiseWebLocationClient: AxiosInstance;


    static $inject = [
        'identityManager',
        '$window',
        "employeeBiosSearchResources",
        '$http',
        "apiConfig",
        'coreApiResources',
        "$scope",
        '$q',
        '$log',
        '$timeout',

        'odataHelper',

    ];

    constructor(
        private identityManager: IdentityManager,
        private $window: ng.IWindowService,
        private employeeBiosSearchResources: EmployeeBiosSearchResources,
        private $http: ng.IHttpService,
        private apiConfig: ApiConfig,
        private coreApiResources: CoreApiResources,
        private $scope: ng.IScope
    )
    {
        this.conceptSelect = [
            nameof<CoreResources.IConcept>(o => o.ConceptId),
            nameof<CoreResources.IConcept>(o => o.DisplayName),
            nameof<CoreResources.IConcept>(o => o.ConceptCode),
        ];
    }

    async $onInit()
    {
        this.userInfo = (await this.identityManager.GetLoggedInUserInfo()).data;
        this.employeeGroupClient = FranForceAxiosClientBuilder.BuildEmployeeGroupBaseClient();
        this.employeeOrchestrationsClient = FranForceAxiosClientBuilder.BuildEmployeeOrchestrationsBaseClient();
        this.franchiseEmployeeProfileSearchClient = new FranchiseEmployeeProfileSearchClient("", this.employeeOrchestrationsClient);
        this.franchiseProfileEmployeeBioCertificationClient = new FranchiseProfileEmployeeBioCertificationClient("", this.employeeOrchestrationsClient);
        this.franchiseProfileEmployeeBioClient = new FranchiseProfileEmployeeBioClient("", this.employeeOrchestrationsClient);
        this.educationTypeClient = new EducationTypeClient("", this.employeeGroupClient);
        this.genderClient = new GenderClient("", this.employeeGroupClient);
        this.languageClient = new LanguageClient("", this.employeeGroupClient);
        this.franchiseProfileEmployeeBioGroupClient = new FranchiseProfileEmployeeBioGroupClient("", this.employeeGroupClient);
        this.franchiseWebLocationClient = FranForceAxiosClientBuilder.BuildFranchiseGroupBaseClient();
        this.franchiseWebLocationListClient = new FranchiseWebLocationClient("", this.franchiseWebLocationClient);

        this.conceptFilters = [];
        this.conceptSelect = [
            nameof<CoreResources.IConcept>(o => o.ConceptId),
            nameof<CoreResources.IConcept>(o => o.DisplayName),
            nameof<CoreResources.IConcept>(o => o.ConceptCode),
        ];


        this.noPermissionFilter = this.userInfo.Roles.some(r =>
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.AdminRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.ContractManagementAdminRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.ConceptRoyaltyReadOnlyRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.SupportAdminRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.FranchiseCSR ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.DigitalAdmin || 
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.ConceptManagerRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.HelpDeskRole

        );

        if (!this.noPermissionFilter)
        {
            let isFranchiseRole: boolean = false;
            isFranchiseRole = this.userInfo.Roles.some(r =>
                r.Name === this.apiConfig.FranForceConstants.RoleConstants.FranchiseeRole
            );
            if (isFranchiseRole)
            {
                await this.LoadPermissionsForFranchiseeRole().then((res) =>
                {
                    this.isLoading = false;
                });
            }
        }
        this.isLoading = false;
        this.$scope.$digest();

    }

    async LoadPermissionsForFranchiseeRole()
    {
        let conceptFilterItems: Array<kendo.data.DataSourceFilterItem> = [];
        this.ownedFranchiseIds = this.userInfo.Claims
            .filter(c => c.Type === this.apiConfig.FranForceConstants.ClaimConstants.OwnedFranchiseIdClaimType)
            .map(c => parseInt(c.Value));

        let conceptArrays = [];
        if (this.ownedFranchiseIds.length)
        {
            await Promise.all(
                this.ownedFranchiseIds.map(async (id) =>
                {
                    await this.coreApiResources.FranchiseApi.get({ id: id }).$promise
                        .then((franchiseDetails) =>
                        {
                            if (conceptArrays.includes(franchiseDetails.ConceptId) === false)
                            {
                                conceptArrays.push(franchiseDetails.ConceptId);
                            }
                            this.franchiseDetailsArray.push(franchiseDetails);
                        });
                })
            ).then(async () =>
            {
                if (conceptArrays && conceptArrays.length)
                {
                    for (let conceptId of conceptArrays)
                    {
                        conceptFilterItems.push(
                            {
                                field: nameof<CoreEntities.Concept>(o => o.ConceptId),
                                operator: "eq",
                                value: conceptId
                            }
                        );

                    }
                    if (conceptFilterItems.length)
                    {
                        this.conceptFilters = {
                            logic: "or",
                            filters: conceptFilterItems
                        }
                    }
                }

            })
        } else
        {
            this.conceptFilters = <kendo.data.DataSourceFilterItem>{
                field: nameof<CoreEntities.Concept>(o => o.ConceptId),
                operator: "eq",
                value: null
            };
        }

    }


    async LoadStaticInfo()
    {
        this.educationTypeClient.educationTypes().then((response) =>
        {
            this.educationTypeList = response.result;
        })
        this.genderClient.genders().then((response) =>
        {
            this.genderList = response.result;
        })
        this.languageClient.languages().then((response) =>
        {
            this.languageList = response.result;
        })
        this.franchiseProfileEmployeeBioCertificationClient.certifications(this.conceptCode).then((certificates) =>
        {
            this.certificateList = certificates.result;

        }).catch(reject =>
        {
            this.certificateList = null;
            console.log('Error OCCURED')
        })

    }


    async openEmployeeBioPopup(employeeBio: FranchiseProfileEmployeeBioSearchDto)
    {
        this.selectedEmployeeBio = employeeBio;
        if(this.selectedEmployeeBio.franchiseId){
            await this.franchiseWebLocationListClient.get_protected_franchiseweblocations_franchises_franchiseid(this.selectedEmployeeBio.franchiseId)
            .then((res) =>
            {
                this.franchiseWebLocationDtoArray = res.result;
            }).catch((err) =>
            {
                console.log('Error Occured while fetching franchiseWebLocationDtos', this.franchiseWebLocationDtoArray)
            })
        }
        if(this.selectedEmployeeBio)
        this.OpenWindow();

    }

    async deleteEmployeeBio(employeeBio: FranchiseProfileEmployeeBioSearchDto)
    {
        this.deletedEmployeeBio = employeeBio;
        if (confirm(`Do you want to delete the bio?`))
        {
            await this.franchiseProfileEmployeeBioClient.franchise_profile_employee_bios3(
                employeeBio.franchiseProfileEmployeeBioId
            ).then((response) =>
            {
                this.deletedEmployeeBio.franchiseProfileEmployeeBioId = null;
                this.deletedEmployeeBio.franchiseProfileEmployeeBioXrefId = null;
                this.deletedEmployeeBio.displayName = null;
                this.deletedEmployeeBio.profilePic = false;
                this.deletedEmployeeBio.bioDescription = false;
                this.grid.refresh();
            }).catch((err) =>
            {
                console.log("Error Occured")
            })

        }
    }

    async onConceptSelect(concept: Partial<CoreResources.IConcept>)
    {
        this.conceptCode = concept.ConceptCode;
        await this.LoadStaticInfo();
        this.InitGridOptions();
    }

    onConceptLoad(concept)
    {
        if (concept?.concepts?.length)
        {
            this.noConceptsFound=false;
        }else{
            this.noConceptsFound=true;
        }

    }

    onSave()
    {
        this.grid.refresh();
    }

    OpenWindow()
    {
        let template =
            `<div style="width:80vw;height:80vh;">
            <employee-bios-component
                gender-list="$ctrl.genderList"
                education-type-list="$ctrl.educationTypeList"
                language-list="$ctrl.languageList"
                employee-bio="$ctrl.selectedEmployeeBio"
                concept-code="$ctrl.conceptCode"
                certificate-list="$ctrl.certificateList"
                on-save="$ctrl.onSave();"
                franchise-web-location-id="$ctrl.franchiseWebLocationId"
                franchise-Web-Location-Dto="$ctrl.franchiseWebLocationDtoArray"
                >
            </employee-bios-component> 
        </div>`;

        let options: kendo.ui.WindowOptions = {
            modal: true,
            position: { top: 20 },
        };

        this.employeeBioWindow.open();
        this.employeeBioWindow.title(this.selectedEmployeeBio.name);
        this.employeeBioWindow.content(template)
        this.employeeBioWindow.center();
        this.employeeBioWindow.setOptions(options);

    }

    GetEmployeeBios(): ng.IHttpPromise<any>
    {
        this.searchUrl = this.employeeBiosSearchResources.EmployeeBioSearchByConcept(this.conceptCode);
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.searchUrl,

        };
        return this.$http<any>(config);
    }

    InitGridOptions()
    {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof.full<FranchiseProfileEmployeeBioSearchDto>(o => o.conceptCode),
                title: "Concept"
            }, {
                field: nameof.full<FranchiseProfileEmployeeBioSearchDto>(o => o.licenseNumber),
                title: "License Number",
                minScreenWidth: 1000
            }, {
                field: nameof.full<FranchiseProfileEmployeeBioSearchDto>(o => o.doingBusinessAs),
                title: "DBA"
            }, {
                field: nameof.full<FranchiseProfileEmployeeBioSearchDto>(o => o.name),
                title: "Name",
                attributes: {
                    class: 'capitalize'
                }
            }, {
                field: nameof.full<FranchiseProfileEmployeeBioSearchDto>(o => o.displayName),
                title: "Display Name"
            }, {
                field: nameof.full<FranchiseProfileEmployeeBioSearchDto>(o => o.bioDescription),
                title: "Bio Complete",
                attributes: {
                    class: 'capitalize'
                }
            }, {
                field: nameof.full<FranchiseProfileEmployeeBioSearchDto>(o => o.profilePic),
                title: "Photo Complete",
                minScreenWidth: 1200,
                attributes: {
                    class: 'capitalize'
                }
            },
            {
                title: "Bio Link",
                template: "<button class='pure-button edit-button' ng-click='$ctrl.openEmployeeBioPopup(dataItem)'>Edit</button>",
                width: 100
            },
            /*            {
                           template: "<button ng-disabled= class='pure-button button-error' promise-btn ng-click='$ctrl.deleteEmployeeBio(dataItem)'>X</button>",
                           width: 70
                       }, */
            {

                template: (dataItem: FranchiseProfileEmployeeBioSearchDto) =>
                    `<button ng-disabled=${!dataItem.franchiseProfileEmployeeBioId} class='pure-button button-error' promise-btn ng-click='$ctrl.deleteEmployeeBio(dataItem)'>X</button>`,
                sortable: false
            }
        ];

        let dataSource = new kendo.data.DataSource({
            serverSorting: false,
            serverFiltering: false,
            serverPaging: false,
            pageSize: 25,

            transport: {
                read: (options) =>
                {
                    this.GetEmployeeBios().then((response) =>
                    {
                        if (this.noPermissionFilter)
                        {
                            options.success(response.data);
                        } else
                        {
                            let result = response.data.filter(o1 => this.franchiseDetailsArray.some(o2 => o1.licenseNumber === o2.LicenseNumber));
                            options.success(result);
                        }

                    }).catch((err) =>
                    {
                        options.error(err);
                    })

                }
            }
        });

        let gridHeight = angular.element(this.$window).height() - this.grid.element.offset().top - 15;

        if (gridHeight < 250)
        {
            gridHeight = 250;
        }

        this.grid.setOptions(<kendo.ui.GridOptions>{
            dataSource: dataSource,
            columns: columns,
            sortable: true,
            resizable: true,
            pageable: {
                numeric: false,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true
            },
            height: gridHeight,
        });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('employeeBiosGrid', {
            bindings: {

            },
            controller: EmployeeBiosGridComponentController,
            templateUrl: "/Templates/EmployeeBios/EmployeeBiosGrid.html"
        });

    }
}


