import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AngularUtil } from "Utility/AngularUtil";
import { EditUserStateService, userStateObj } from "Services/State/EditUserStateService";

export class EditUserNameComponentController implements ng.IController 
{
    managedUserId: string;
    refreshDate: Date;
    refresh: (params: { refreshedDate: Date }) => void;

    userState: userStateObj;
    isLoading: boolean;
    
    name: {
        first: string;
        last: string;
    };
    isViewable: boolean;

    static $inject = [
        'editUserState',
        'identityManager',
        'apiConfig'
    ];

    constructor(
        private editUserState: EditUserStateService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig
    )
    {
    }

    $onInit()
    {
        
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        this.Load();
    }

    Load()
    {
        let promise = this.editUserState.Load(this.managedUserId)
            .then((result) =>
            {
                this.userState = result;
            })
            .then(() =>
            {
                this.isViewable = this.userState.managedUser ? true : false;
                this.name = {
                    first: this.userState.managedUser.FirstName,
                    last: this.userState.managedUser.LastName
                };
            });
    
        return AngularUtil.TrackLoadingPromise(promise, this);
    }

    ChangeName()
    {
        if (confirm("Are you sure you want to change this user's name?"))
        {
            return this.identityManager.ManagedChangeName(this.userState.managedUser.Id, this.name.first, this.name.last)
                .then((data) =>
                {
                    this.userState.managedUser.FirstName = this.name.first;
                    this.userState.managedUser.LastName = this.name.last;
                    this.RefreshUser();
                })
                .catch(AngularUtil.GetJsonAlertCatch());
        }
    }
    
    RefreshUser()
    {
        this.editUserState.Clear(this.userState.managedUser.Id);
        if (this.refresh) {
            this.refresh({
                refreshedDate: new Date()
            });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("editUserName", {
            bindings: {
                [nameof<EditUserNameComponentController>(o => o.managedUserId)]: "<",
                [nameof<EditUserNameComponentController>(o => o.refreshDate)]: "<",
                [nameof<EditUserNameComponentController>(o => o.refresh)]: "&"
            },
            controller: EditUserNameComponentController,
            templateUrl: "/Templates/Users/EditUser/EditUserName.html"
        });
    }
}
