import { NavigationService } from "@DppApp/Services/NavigationService";
import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { IEditDisasterPrepProfileSectionScope } from "@DppApp/EditDisasterPrepProfileSectionController";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";

interface IAddDisasterPrepProfileBuildingScope extends IEditDisasterPrepProfileSectionScope {
    dppBuildingAssetCategoryId: number;
    currentBuilding: DppResources.IDisasterPrepProfileBuilding;
    floors: DppResources.IDisasterPrepProfileBuildingFloor[];

    ResetNewBuilding: () => void;
    AddBuildingFloor: () => void;
    RemoveBuildingFloor: (index: number) => void;

    SaveBuilding: () => ng.IPromise<any>;
}

export class AddDisasterPrepProfileBuildingController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('AddDisasterPrepProfileBuildingController',
            [
                '$scope',
                '$q',
                "Navigation",
                "dppApiResources",
                ($scope: IAddDisasterPrepProfileBuildingScope,
                    $q: ng.IQService,
                    navigation: NavigationService,
                    dppApiResources: DppApiResources) =>
                {
                    $scope.dppBuildingAssetCategoryId = 1;

                    $scope.ResetNewBuilding = () =>
                    {
                        $scope.currentBuilding = new dppApiResources.DisasterPrepProfileBuildingApi();
                        $scope.floors = [new dppApiResources.DisasterPrepProfileBuildingFloorApi()];
                    };

                    $scope.ResetNewBuilding();

                    $scope.AddBuildingFloor = () =>
                    {
                        $scope.floors.push(new dppApiResources.DisasterPrepProfileBuildingFloorApi());
                    };

                    $scope.RemoveBuildingFloor = (index: number) =>
                    {
                        $scope.floors.splice(index, 1);
                    };

                    $scope.SaveBuilding = () =>
                    {
                        $scope.currentBuilding.DppId = $scope.currentProfile.DppId;
                        return $scope.currentBuilding.$save().then(
                            () =>
                            {
                                let buildingId = $scope.currentBuilding.DppBuildingId;

                                let promises: Array<ng.IPromise<any>> = [];
                                for (let floor of $scope.floors) {
                                    floor.DppBuildingId = buildingId;
                                    promises.push(floor.$save());
                                }

                                return $q.all(promises).then(
                                    (response) =>
                                    {
                                        console.log(response);
                                        $scope.ResetNewBuilding();
                                        navigation.Redirect("EditDisasterPrepProfileBuilding", buildingId);
                                    },
                                    (err) =>
                                    {
                                        console.error(err);
                                        alert("Issue saving one or more floors");
                                        $scope.ResetNewBuilding();
                                        navigation.Redirect("EditDisasterPrepProfileBuilding", buildingId);
                                    });
                            },
                            (err) =>
                            {
                                console.error(err);
                            });
                    };
                }
            ]);
    }
}

