import { RpmUiApiResources } from "../../../Services/Resources/RpmUiApiResources";
import { RmsRoyaltySystemSelectorOnSelectParams } from "../Common/rmsRoyaltySystemSelector";
import { RmsRoyaltyScaleCalculationSelectorOnSelectParams } from "../Common/rmsRoyaltyScaleCalculationSelector";
import { RmsRoyaltySummaryInvoiceDisplayTypeSelectorOnSelectParams } from "../Common/rmsRoyaltySummaryInvoiceDisplayTypeSelector";
import { RpmEntities } from "../../../Interfaces/FranForce/Rpm/RpmResources";
import { RoyaltyContractStatusViewModel, RoyaltyContractSourceViewModel } from "@nbly/dataaccess.typings/TypeGen/Dwyer.DataAccess.FranForce.Rpm";
import { RmsFddYearSelectorOnSelectParams } from "../Common/rmsFddYearSelector";
import { RmsFranchiseLocationStateSelectorOnSelectParams } from "../Common/rmsFranchiseLocationStateSelector";
import { RoyaltyApiResources } from "../../../Services/Resources/RoyaltyApiResources";
import { rmsSearchRequestDataModel } from "../../../Models/Royalty/RmsSearch/RmsSearchRequestDataModel";
import { CoreResources } from "../../../Interfaces/FranForce/Core/CoreResources";
import { RmsFindScaleBasedOnTypesSelectorOnSelectParams } from "../Common/RmsFindScaleBasedOnTypesSelector";
import { RmsRoyaltyPeriodFrequencyTypeSelectorOnSelectParams } from "../Common/rmsRoyaltyPeriodFrequencyTypeSelector";


export class rmsSearchComponentController implements ng.IController {

    isLoading: boolean;
    rmsSearchDataSource: kendo.data.DataSource;

    searchValidationMessage: string;
    showSearchValidationMessage: boolean;

    showSearchResultGrid: boolean;

    //franchisorFilters: kendo.data.DataSourceFilter;
    //combinedFranchisorFilters: kendo.data.DataSourceFilters;

    //search filters

    //Contract And Franchise Filters
    conceptId?: number;
    franchisorId?: number;
    royaltySystemId: number;
    royaltyScaleCalculationTypeId?: number;
    royaltySummaryInvoiceDisplayTypeId?: number;
    royaltyContractStatusId?: number;
    royaltyContractSourceId?: number;
    fddYear?: number;
    franchiseLocationState?: string;
    royaltyFindScaleBasedOnId?: number;
    royaltyPeriodFrequencyId?: number;
    contractEndsDateFrom?: string;
    contractEndsDateTo?: string;
    royaltyEndsDateFrom?: string;
    royaltyEndsDateTo?: string;
    notHavingRoyaltyFeeNotCalculated?: boolean;
    notHavingAdFeeCalculated?: boolean;
    notHavingTechFeeCalculated?: boolean;
    notHavingTafsFeeCalculated?: boolean;

    //Roll-In Filters
    notHavingYearlyRollInAmount?: boolean;
    notHavingMonthlyRollInAmount?: boolean;
    notHavingWeeklyRollInAmount?: boolean;

    //YTD Filters
    ytdSalesOverrideAmount?: number;
    ytdSalesEffectiveDateFrom?: string;
    ytdSalesEffectiveDateTo?: string;

    //Minimums Filters
    notHavingRoyaltyMinimums?: boolean;
    notHavingAdFeeMinimums?: boolean;
    notHavingTechFeeMinimums?: boolean;
    minimumFindScaleBasedOnId?: number;
    minimumRoyaltyPeriodFrequencyId?: number;

    //extras
    resetRoyaltySystemSelect: boolean;
    resetFranchiseLocationStateSelect: boolean;

    static $inject = [
        '$window',
        '$location',
        '$q',
        'royaltyApiResources'
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private royaltyApiResources: RoyaltyApiResources
    ) {

    }

    $onInit() {
        this.isLoading = false;
        this.searchValidationMessage = "";
        this.showSearchValidationMessage = false;
        this.showSearchResultGrid = false;
        //this.CreateCombinedFranchisorFilters();
        this.resetRoyaltySystemSelect = false;
        this.resetFranchiseLocationStateSelect = false;
    }

    $postLink() {
    
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        //if (onChanges[nameof(this.conceptId)]) {
        //    this.CreateCombinedFranchisorFilters()
        //}
    }

    SetRMSSearchValidationMessage(message: string, visibility: boolean) {
        this.searchValidationMessage = message;
        this.showSearchValidationMessage = visibility;
    }

    OnRoyaltySystemSelect(royaltySystem: RmsRoyaltySystemSelectorOnSelectParams["royaltySystem"]) {
        this.royaltySystemId = royaltySystem?.royaltySystemId;       
    }

    OnRoyaltyScaleCalculationTypeSelect(royaltyScaleCalculation: RmsRoyaltyScaleCalculationSelectorOnSelectParams["royaltyScaleCalculation"]) {
        this.royaltyScaleCalculationTypeId = royaltyScaleCalculation?.royaltyScaleCalculationTypeId;
    }

    OnRoyaltySummaryInvoiceDisplayTypeSelect(royaltySummaryInvoiceDisplayType: RmsRoyaltySummaryInvoiceDisplayTypeSelectorOnSelectParams["royaltySummaryInvoiceDisplayType"]) {
        this.royaltySummaryInvoiceDisplayTypeId = royaltySummaryInvoiceDisplayType?.royaltySummaryInvoiceDisplayTypeId;
    }

    OnRoyaltyContractStatusSelect(status: { status: RpmEntities.RoyaltyContractStatusViewModel }) {
        this.royaltyContractStatusId = status[nameof<RoyaltyContractStatusViewModel>(o => o.RoyaltyContractStatusID)];
    }

    OnRoyaltyContractSourceSelect(source: { source: RpmEntities.RoyaltyContractSourceViewModel }) {
        this.royaltyContractSourceId = source[nameof<RoyaltyContractSourceViewModel>(o => o.RoyaltyContractSourceID)];
    }

    OnFddYearSelect(fddYear: RmsFddYearSelectorOnSelectParams["fddYear"]) {
        this.fddYear = fddYear?.year;
    }

    OnFranchiseLocationStateSelect(franchiseLocationState: RmsFranchiseLocationStateSelectorOnSelectParams["franchiseLocationState"]) {
        this.franchiseLocationState = franchiseLocationState?.stateAbbrev;
    }

    OnConceptSelect(concept: Partial<CoreResources.IConcept>) {
        this.conceptId = concept.ConceptId;

        //if (!concept || !concept.ConceptId) {
        //    this.conceptId = null;
        //    this.franchisorFilters = null;
        //    this.CreateCombinedFranchisorFilters();
        //    this.conceptId = concept.ConceptId;
        //    return;
        //}

        //if (this.conceptId == concept.ConceptId) {
        //    this.conceptId = concept.ConceptId;
        //    return;
        //}

        //this.conceptId = concept.ConceptId;
        //this.CreateCombinedFranchisorFilters();
        //this.conceptId = concept.ConceptId;
    }

    //CreateCombinedFranchisorFilters() {
    //    if (!this.conceptId) {
    //        this.combinedFranchisorFilters = null;
    //        return;
    //    }

    //    this.combinedFranchisorFilters = {
    //        logic: "and",
    //        filters: [
    //            {
    //                field: nameof<CoreResources.IFranchisor>(o => o.ConceptId),
    //                operator: 'eq',
    //                value: this.conceptId
    //            },
    //            this.franchisorFilters
    //        ]
    //    }
    //}

    OnFranchisorSelect(franchisor: Partial<CoreResources.IFranchisor>) {
        if (franchisor) {
            this.franchisorId = franchisor.FranchisorId;
        }
    }

    OnFindScaleSelect(findScale: RmsFindScaleBasedOnTypesSelectorOnSelectParams["findScale"]) {
        this.royaltyFindScaleBasedOnId = findScale?.FindScaleBasedOnId;
    }

    OnFindMinimumScaleSelect(findScale: RmsFindScaleBasedOnTypesSelectorOnSelectParams["findScale"]) {
        this.minimumFindScaleBasedOnId = findScale?.FindScaleBasedOnId;
    }

    OnRoyaltyPeriodReportFrequencySelect(periodReportFrequency: RmsRoyaltyPeriodFrequencyTypeSelectorOnSelectParams["periodReportFrequency"]) {
        this.royaltyPeriodFrequencyId = periodReportFrequency?.RoyaltyPeriodFrequencyId;
    }

    OnMinimumPeriodReportFrequencySelect(periodReportFrequency: RmsRoyaltyPeriodFrequencyTypeSelectorOnSelectParams["periodReportFrequency"]) {
        this.minimumRoyaltyPeriodFrequencyId = periodReportFrequency?.RoyaltyPeriodFrequencyId;
    }

    loadRmsSearchResultGridData(rmsSearchRequestDataModel: rmsSearchRequestDataModel) {
        this.rmsSearchDataSource = new kendo.data.DataSource({
            pageSize: 10,
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {
                    this.isLoading = true;

                    this.royaltyApiResources.getRmsSearchDataResult(rmsSearchRequestDataModel)
                        .then((results) => {
                            options.success(results.data);
                            this.isLoading = false;
                        })
                        .catch((err) => {
                            this.showSearchResultGrid = false;
                            this.SetRMSSearchValidationMessage("Error occured in RMS Search.", true);
                            options.error(err);
                        })
                        .finally(() => { this.isLoading = false; });
                }
            }
        });
    }

    async Search() {
        this.isLoading = true;
        this.showSearchResultGrid = false;
        this.SetRMSSearchValidationMessage("", false);

        let rmsSearchRequestDataModel: rmsSearchRequestDataModel = {
            conceptId: !this.conceptId ? null : this.conceptId,
            franchisorId: !this.franchisorId ? null : this.franchisorId,
            royaltyContractSourceId: !this.royaltyContractSourceId ? null : this.royaltyContractSourceId,
            royaltyContractStatusId: !this.royaltyContractStatusId ? null : this.royaltyContractStatusId,
            royaltyScaleCalculationTypeId: !this.royaltyScaleCalculationTypeId ? null : this.royaltyScaleCalculationTypeId,
            royaltySummaryInvoiceDisplayTypeId: !this.royaltySummaryInvoiceDisplayTypeId ? null : this.royaltySummaryInvoiceDisplayTypeId,
            royaltySystemId: !this.royaltySystemId ? (this.royaltySystemId === 0 ? this.royaltySystemId : null) : this.royaltySystemId,
            fddYear: !this.fddYear ? null : this.fddYear,
            royaltyFindScaleBasedOnId: !this.royaltyFindScaleBasedOnId ? null : this.royaltyFindScaleBasedOnId,
            royaltyPeriodFrequencyId: !this.royaltyPeriodFrequencyId ? null : this.royaltyPeriodFrequencyId,
            franchiseLocationState: !this.franchiseLocationState ? null : this.franchiseLocationState,
            contractEndsDateFrom: !this.contractEndsDateFrom ? null : new Date(this.contractEndsDateFrom).toISOString(),
            contractEndsDateTo: !this.contractEndsDateTo ? null : new Date(this.contractEndsDateTo).toISOString(),
            royaltyEndsDateFrom: !this.royaltyEndsDateFrom ? null : new Date(this.royaltyEndsDateFrom).toISOString(),
            royaltyEndsDateTo: !this.royaltyEndsDateTo ? null : new Date(this.royaltyEndsDateTo).toISOString(),
            calculateRoyaltyFee: !this.notHavingRoyaltyFeeNotCalculated ? null : this.notHavingRoyaltyFeeNotCalculated,
            calculateAdFee: !this.notHavingAdFeeCalculated ? null : this.notHavingAdFeeCalculated,
            calculateTechnologyFee: !this.notHavingTechFeeCalculated ? null : this.notHavingTechFeeCalculated,
            calculateTafsFee: !this.notHavingTafsFeeCalculated ? null : this.notHavingTafsFeeCalculated,
            annualRollInSalesAmountNotExistCheck: !this.notHavingYearlyRollInAmount ? null : this.notHavingYearlyRollInAmount,
            monthlyRollInSalesAmountNotExistCheck: !this.notHavingMonthlyRollInAmount ? null : this.notHavingMonthlyRollInAmount,
            weeklyRollInSalesAmountNotExistCheck: !this.notHavingWeeklyRollInAmount ? null : this.notHavingWeeklyRollInAmount,
            ytdSalesOverrideAmount: !this.ytdSalesOverrideAmount ? null : Number(this.ytdSalesOverrideAmount),
            ytdSalesEffectiveDateFrom: !this.ytdSalesEffectiveDateFrom ? null : new Date(this.ytdSalesEffectiveDateFrom).toISOString(),
            ytdSalesEffectiveDateTo: !this.ytdSalesEffectiveDateTo ? null : new Date(this.ytdSalesEffectiveDateTo).toISOString(),
            royaltyFeeMinimumsNotExistCheck: !this.notHavingRoyaltyMinimums ? null : this.notHavingRoyaltyMinimums,
            adFeeMinimumsNotExistCheck: !this.notHavingAdFeeMinimums ? null : this.notHavingAdFeeMinimums,
            techFeeMinimumsNotExistCheck: !this.notHavingTechFeeMinimums ? null : this.notHavingTechFeeMinimums,
            minimumFindScaleBasedOnId: !this.minimumFindScaleBasedOnId ? null : this.minimumFindScaleBasedOnId,
            minimumRoyaltyPeriodFrequencyId: !this.minimumRoyaltyPeriodFrequencyId ? null : this.minimumRoyaltyPeriodFrequencyId,
        }

        if (rmsSearchRequestDataModel) {
            let hasValidationError = false;
            hasValidationError = this.checkDateValidation(rmsSearchRequestDataModel.contractEndsDateFrom, rmsSearchRequestDataModel.contractEndsDateTo, "Contract End Date")
            if (hasValidationError) {
                this.isLoading = false;
                return;
            }
            hasValidationError = this.checkDateValidation(rmsSearchRequestDataModel.royaltyEndsDateFrom, rmsSearchRequestDataModel.royaltyEndsDateTo, "Royalty End Date")
            if (hasValidationError) {
                this.isLoading = false;
                return;
            }
            hasValidationError = this.checkDateValidation(rmsSearchRequestDataModel.ytdSalesEffectiveDateFrom, rmsSearchRequestDataModel.ytdSalesEffectiveDateTo, "YTD Sales Effective Date")
            if (hasValidationError) {
                this.isLoading = false;
                return;
            }
        }

        this.showSearchResultGrid = true;
        this.loadRmsSearchResultGridData(rmsSearchRequestDataModel);
    }

    checkDateValidation(start, end, field) {
        if (end && !start) {
            this.SetRMSSearchValidationMessage(`Please enter \"${field} From\"`, true);
            return true;
        }
        if (!end && start) {
            this.SetRMSSearchValidationMessage(`Please enter \"${field} To\"`, true);
            return true;
        }
        if (end && start) {
            var startDate = new Date(start);
            var endDate = new Date(end);
            if (startDate > endDate) {
                this.SetRMSSearchValidationMessage(`\"${field} From\" is greater than \"${field} To\"`, true);
                return true;
            }
        }
        else {
            return false;
        }
    }

    Reset() {
        this.SetRMSSearchValidationMessage("", false);
        this.conceptId = null;
        this.franchisorId = null;
        this.royaltySystemId = null;
        this.royaltyScaleCalculationTypeId = null;
        this.royaltySummaryInvoiceDisplayTypeId = null;
        this.royaltyContractStatusId = null;
        this.royaltyContractSourceId = null;
        this.fddYear = null;
        this.franchiseLocationState = null;
        this.royaltyFindScaleBasedOnId = null;
        this.royaltyPeriodFrequencyId = null;
        this.contractEndsDateFrom = null;
        this.contractEndsDateTo = null;
        this.royaltyEndsDateFrom = null;
        this.royaltyEndsDateTo = null;
        this.notHavingRoyaltyFeeNotCalculated = null;
        this.notHavingAdFeeCalculated = null;
        this.notHavingTechFeeCalculated = null;
        this.notHavingTafsFeeCalculated = null;
        this.notHavingYearlyRollInAmount = null;
        this.notHavingMonthlyRollInAmount = null;
        this.notHavingWeeklyRollInAmount = null;
        this.ytdSalesOverrideAmount = null;
        this.ytdSalesEffectiveDateFrom = null;
        this.ytdSalesEffectiveDateTo = null;
        this.notHavingRoyaltyMinimums = null;
        this.notHavingAdFeeMinimums = null;
        this.notHavingTechFeeMinimums = null;
        this.minimumFindScaleBasedOnId = null;
        this.minimumRoyaltyPeriodFrequencyId = null;

        this.resetRoyaltySystemSelect = !this.resetRoyaltySystemSelect;
        this.resetFranchiseLocationStateSelect = !this.resetFranchiseLocationStateSelect;

        //hide grid
        this.showSearchResultGrid = false;
    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsSearch", {
            bindings: {

            },
            templateUrl: '/Templates/RMSSearch/rmsSearch.html',
            controller: rmsSearchComponentController
        });
    }
}