import * as _ from "underscore";

export class KendoHelperService
{
    readonly tabIdAttribute = "tab-id";

    static $inject = [
        "$location",
        "$timeout"
    ]

    constructor(
        private $location: ng.ILocationService,
        private $timeout: ng.ITimeoutService
    )
    {

    }

    GetFilteredUnpagedData<T>(dataSource: kendo.data.DataSource): T[]
    {
        let copiedDataSource = new kendo.data.DataSource(<kendo.data.DataSourceOptions>{
            data: dataSource.data(),
            filter: dataSource.filter()
        });
        copiedDataSource.read();
        return <T[]>copiedDataSource.view().slice(0);
    }

    BindTabStripStateHandlers(tabStrip: kendo.ui.TabStrip, queryParamName: string = "tab")
    {
        let initTabId = this.$location.search()[queryParamName];
        if (initTabId) {
            let tabToSelect = _.find(tabStrip.tabGroup.children(),
                (child) =>
                {
                    return initTabId == this.GetTabId(child);
                });

            if (tabToSelect) {
                tabStrip.activateTab($(tabToSelect));
            }
        }


        tabStrip.bind(
            nameof<kendo.ui.TabStripOptions>(o => o.activate),
            (e: kendo.ui.TabStripActivateEvent) =>
            {
                this.$timeout(() =>
                {
                    let tabId = this.GetTabId(e.item);
                    if (tabId) {
                        this.$location.search(queryParamName, tabId);
                    }
                });
            });
    }

    private GetTabId(tab: Element): string
    {
        return tab.getAttribute(this.tabIdAttribute) ||
            tab.id ||
            tab.getAttribute("aria-controls");
    }
}