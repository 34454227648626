import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RestAxiosClient } from "Clients/RestAxiosClient";
import { IRestAxiosClient } from "Clients/IRestAxiosClient";

const royaltyDisclosureDocumentRollInRangeClient = new RestAxiosClient<RpmEntities.RoyaltyDisclosureDocumentRollInRange, number>(
    FranForceAxiosClientBuilder.BuildCoreBaseClient(),
    "/RoyaltyDisclosureDocumentRollInRanges"
) as IRestAxiosClient<RpmEntities.RoyaltyDisclosureDocumentRollInRange, number>;

export { royaltyDisclosureDocumentRollInRangeClient };