import { IFRMStateParams } from "Controllers/Rms/FranchisorRoyaltyManagement/frmRouter";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

interface IFranchisorRoyaltyManagementScope extends ng.IScope {
    
    isLoading: boolean;
    selectedTab: string;
    targetTab: string;
    
    kNotifierOptions: kendo.ui.NotificationOptions;
    kNotifier: kendo.ui.Notification;

    // Functions
    stateGo: () => void;
    broadcastSave: () => void;
    changeRoute: (dest: string) => void;

    //Popup
    confirmationMessage: string;
    Yes: Function;
    No: Function;
    Cancel: Function;
}

export class FranchisorRoyaltyManagementController implements ng.IController
{
    franchisorId: number;

    static $inject = [
        "$scope",
        "$state",
        "$stateParams",
        "coreApiResources",
        "ngDialog",
    ];

    constructor(
        private $scope: IFranchisorRoyaltyManagementScope,
        private $state: ng.ui.IStateService,
        private $stateParams: IFRMStateParams,
        private coreApiResources: CoreApiResources,
        private ngDialog: any,
    ) {

        /* Emitters and Listeners */
        $scope.$on('selectionsMade', function (event, data) {
            $scope.$broadcast('broadcastData', data);
        });

        $scope.$on('saveDocumentComplete', (e, data) => {
            $scope.kNotifier.show({
                kValue: "Save successful!",
                saveSuccessful: true
            }, "ngTemplate");

            if (data.successful)
                $scope.stateGo();
        });

        /* Exposed Functions */
        $scope.broadcastSave = () => this.broadcastSave();
        $scope.stateGo = () => this.ChangeState();
        $scope.changeRoute = (dest: string) => this.ChangeRoute(dest);
        
        $scope.confirmationMessage = "You are about to leave the document being worked on. Would you like to save your changes?";
        $scope.Yes = $scope.broadcastSave;
        $scope.No = $scope.stateGo;
        $scope.Cancel = () => { };
    }

    $onInit() 
    {
        this.$scope.selectedTab = this.$state.current.name;
        this.$scope.targetTab = this.$state.current.name;
        this.franchisorId = parseInt(this.$stateParams.franchisorId);
        
        if (this.franchisorId)
        {
            this.$state.go(this.$state.current.name, <IFRMStateParams>{ franchisorId: this.franchisorId.toString() });
        }

        this.$scope.kNotifierOptions = {
            position: {
                top: 15,
                left: $(window).width() / 2
            },
            templates: [{
                type: 'ngTemplate',
                template: $('#kNTemplate').html(),
            }]
        };
    }

    broadcastSave() {
        this.$scope.$broadcast('saveDocument');
    }

    ChangeRoute(dest: string) {
        this.$scope.targetTab = dest;
        this.ChangeState();
    }

    ChangeState() {
        this.$scope.selectedTab = this.$scope.targetTab;
        this.$state.go(this.$scope.targetTab, <IFRMStateParams>{ franchisorId: this.franchisorId.toString() });
    }

    OnFranchisorSelect(franchisor: Partial<CoreResources.IFranchisor>) 
    {
        let selectedFranchisorId = franchisor && franchisor.FranchisorId;
        if (selectedFranchisorId && this.franchisorId != selectedFranchisorId)
        {
            this.franchisorId = selectedFranchisorId;
            this.ChangeRoute('frm.general');
        }
    }
}