import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { UrlQueryParameters } from "Utility/UrlQueryParameters";
import { DppResources, DppEntities } from "Interfaces/FranForce/Dpp/DppResources";


declare var inlineProfile: DppEntities.DisasterPrepProfile;
declare var hiqPdfConverter: any;

interface IPrintDisasterPrepProfileScope extends ng.IScope {
    query: Object;
    dppProfile: DppResources.IDisasterPrepProfile;
    imgLoadedEvents: { always?: (instance: any) => void, done?: (instance: any) => void, fail?: (instance: any) => void };
    TryStartPdfConversion: () => void;
}

export class PrintDisasterPrepProfileController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('PrintDisasterPrepProfileController',
            [
                '$scope', 'dppApiResources', '$window', '$timeout',
                ($scope: IPrintDisasterPrepProfileScope, dppApiResources: DppApiResources, $window: ng.IWindowService, $timeout: ng.ITimeoutService) => {

                    var urlParts = $window.location.pathname.split("/");
                    if (!urlParts[urlParts.length - 1]) {
                        urlParts.pop();
                    }

                    let profileId = parseInt(urlParts[urlParts.length - 1]);

                    $scope.query = dppApiResources.GetFullProfileQueryObject(profileId);
                    let parameters = new UrlQueryParameters($window.window);
                    let accessToken = parameters.GetValue("access_token");
                    if (accessToken)
                    {
                        $scope.query = angular.extend($scope.query, { access_token: accessToken });
                    }

                    $scope.TryStartPdfConversion = () =>
                    {
                        console.info("Trying to start this PDF conversion...");
                        if (typeof hiqPdfConverter !== "undefined" && hiqPdfConverter)
                        {
                            $timeout(() =>
                            {
                                console.info("Pdf me up!");    
                                hiqPdfConverter.startConversion();
                            }, 1000); 
                        }
                        else
                        {
                            $timeout(() => {
                                console.info("HiQPdf isn't found. You're probably in a browser right now.");    
                            }, 0);
                        }
                    };

                    $scope.imgLoadedEvents = {
                        always(instance) {
                            $scope.TryStartPdfConversion();
                        }
                    };


                    if (inlineProfile)
                    {
                        $scope.dppProfile = <DppResources.IDisasterPrepProfile>inlineProfile;
                        if (!$scope.dppProfile.MainImageDppBuildingAssetId)
                        {
                            $scope.TryStartPdfConversion();
                        }
                    }
                    else
                    {
                        dppApiResources.DisasterPrepProfileApi.get($scope.query).$promise.then(
                            (profile: DppResources.IDisasterPrepProfile) =>
                            {
                                $scope.dppProfile = profile;
                                if (!$scope.dppProfile.MainImageDppBuildingAssetId) {
                                    $scope.TryStartPdfConversion();
                                }
                            },
                            (err) =>
                            {
                                console.error(err);
                                if (!$scope.dppProfile.MainImageDppBuildingAssetId) {
                                    $scope.TryStartPdfConversion();
                                }
                            }); 
                    }
                }
            ]);
    }
}


