import { royaltySystemDataModel } from "../../../Models/Royalty/RoyaltySystemType/RoyaltySystemDataModel";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { KendoUtil } from "../../../Utility/KendoUtil";
import { RpmHelperService } from "../../../Services/Utility/RpmHelperService";
import { rmsSearchResultDataModel } from "../../../Models/Royalty/RmsSearch/RmsSearchResultDataModel";

export class rmsSearchResultGridComponentController implements ng.IController {

    // -- Bindings --
    rmsSearchDataSource: kendo.data.DataSource;
    // --

    rmsSearchGrid: kendo.ui.Grid;

    static $inject = [
        'rpmHelper',
        '$timeout',
        '$q',
        '$window'
    ];

    constructor(
        private rpmHelper: RpmHelperService,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
        private $window: ng.IWindowService
    ) {

    }

    $onInit() {

    }

    $postLink() {
        this.InitRmsSearchGrid();
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.rmsSearchDataSource)] && this.rmsSearchDataSource) {
            this.rmsSearchGrid.setDataSource(this.rmsSearchDataSource);
            this.rmsSearchGrid.resize(true);
        }
    }

    InitRmsSearchGrid() {
        let rmsSearchColumns: kendo.ui.GridColumn[] = [{
            width: 90,
            template: `<div>
                        <a class='pure-button' ng-href='/ContractManagement/Edit/\\#/?tab=general&contractId={{dataItem.royaltyContractId}}' target='_blank'>{{dataItem.royaltyContractId}}</a>
                    <div>`,
            title: "Royalty Contract Id"
        },
        {
            field: nameof<rmsSearchResultDataModel>(o => o.licenseNumber),
            title: "License Number",
        }, {
            field: nameof<rmsSearchResultDataModel>(o => o.franchiseeName),
                title: "Franchisee Name",
        }, {
            field: nameof<rmsSearchResultDataModel>(o => o.doingBusinessAs),
            title: "Doing Business As",
        }, {
            field: nameof<rmsSearchResultDataModel>(o => o.status),
                title: "Status",
            }, {
            field: nameof<rmsSearchResultDataModel>(o => o.stateName),
                title: "State",
        }, {
            field: nameof<rmsSearchResultDataModel>(o => o.royaltySystem),
                title: "Royalty System",
        }, {
            field: nameof<rmsSearchResultDataModel>(o => o.contractSignedDate),
                title: "Contract Signed Date",
        }, {
            field: nameof<rmsSearchResultDataModel>(o => o.contractEndsDate),
                title: "Contract Ends Date",
        }, {
            field: nameof<rmsSearchResultDataModel>(o => o.year),
                title: "Year",
        }, {
            field: nameof<rmsSearchResultDataModel>(o => o.findScaleBasedOn),
                title: "Find Scale Based On",
        }, {
            field: nameof<rmsSearchResultDataModel>(o => o.annualRollInSalesAmount),
                title: "Annual Roll In Sales Amount",
        }, {
            field: nameof<rmsSearchResultDataModel>(o => o.monthlyRollInSalesAmount),
                title: "Monthly Roll In Sales Amount",
        }, {
            field: nameof<rmsSearchResultDataModel>(o => o.weeklyRollInSalesAmount),
                title: "Weekly Roll In Sales Amount",
        }];

        let rmsSearchGridOptions: kendo.ui.GridOptions = {
            columns: rmsSearchColumns,
            selectable: false,
            editable: false,
            scrollable: true,
            sortable: true,
            dataBound: function() {
              for (var i = 0; i < this.columns.length; i++) {
                this.autoFitColumn(i);
              }
            },
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
                
            },
            resizable: true,
            filterable: true
        };

        if (this.rmsSearchGrid) {
            this.rmsSearchGrid.setOptions(rmsSearchGridOptions);
            $("#rmsSearchGrid").kendoTooltip({
                filter: "th",
                position: "right",
                content: function (e) {
                    var cell = $(e.target);
                    var content = cell.text();
                    return content;
                }
            }).data("kendoTooltip");
        }
    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsSearchResultGrid", {
            bindings: {
                [nameof<rmsSearchResultGridComponentController>(o => o.rmsSearchDataSource)]: "<",
            },
            templateUrl: '/Templates/RMSSearch/rmsSearchResultGrid.html',
            controller: rmsSearchResultGridComponentController
        });
    }
}