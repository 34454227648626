import { CoreApiResources } from "../../../Services/Resources/CoreApiResources";
import { royaltyInvoiceReversalFileDataModel } from "../../../Models/Royalty/RoyaltyBatchManagement/RoyaltyInvoiceReversalFileDataModel";
import * as _ from "underscore"
import { RoyaltyApiResources } from "../../../Services/Resources/RoyaltyApiResources";
import { royaltyInvoiceExportVoidRequestDataModel } from "../../../Models/Royalty/RoyaltyBatchManagement/RoyaltyInvoiceExportVoidRequestDataModel";

export class BatchManagementInvoiceReversalComponentController implements ng.IController {
    //bindings
    invoicesDataSource: kendo.data.DataSource;
    onInvoicesVoided: (params: {}) => void;
    closeRefresh: (params: {}) => void;
    errorDescription: string;
    onVoidingInvoicesCancel: () => void;
    // end bindings

    isLoading: boolean;

    invoiceGrid: kendo.ui.Grid;
    areInvoicesDownloaded: boolean;

    static $inject = [
        '$timeout',
        '$q',
        'royaltyApiResources'
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
        private royaltyApiResources: RoyaltyApiResources
    ) {
    }

    $onInit() {
        this.isLoading = true;
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.invoicesDataSource)] && this.invoiceGrid) {
            this.areInvoicesDownloaded = false;
            this.invoiceGrid.setDataSource(this.invoicesDataSource);
            if (this.invoicesDataSource && this.invoicesDataSource.data && this.invoicesDataSource.data.length >=0) {
                this.isLoading = false;
            }
        }
    }

    $postLink() {
        this.InitInvoiceGrid();
    }

    InitInvoiceGrid() {
        let invoicesColumns: kendo.ui.GridColumn[] = [{
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.date),
            title: "Date",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.invoiceType),
            title: "InvoiceType",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.invoiceNumber),
            title: "InvoiceNumber",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.customerID),
            title: "CustomerID",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.itemDescription),
            title: "ItemDescription",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.itemNumber),
            title: "ItemNumber",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.quantity),
            title: "Quantity",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.unitPrice),
            title: "UnitPrice",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.extAmount),
            title: "ExtAmount",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.overRideTaxDate),
            title: "OverRideTaxDate",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.gpDatabase),
            title: "GPDatabase",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.avalaraCompanyCode),
            title: "AvalaraCompanyCode",
        }];

        let invoicesGridOptions: kendo.ui.GridOptions = {
            columns: invoicesColumns,
            toolbar: [
                {
                    template: `<label style="margin: 0px 8px; width: 300px;">
                                    <span style="display: block">File Name</span>
                                    <input type="text" style="height: auto; width: 100%; box-sizing: border-box;" ng-model="$ctrl.${nameof(this.invoiceGrid)}.${nameof.full<kendo.ui.Grid>(o => o.options.excel.fileName)}" /> 
                                </label>`
                }, {
                    template: `<button ng-click='$ctrl.${nameof(this.DownloadInvoiceExcel)}()'>
                                    <span style="display: block">Download</span>
                                </button>`
                }
            ],
            excel: {
                fileName: `ReversalInvoices-` +
                    `${new Date().getFullYear()}_` +
                    `${new Date().getMonth() + 1 < 10 ? "0" : ""}${new Date().getMonth() + 1}_` +
                    `${new Date().getDate() < 10 ? "0" : ""}${new Date().getDate()}.xlsx`,
                filterable: true,
                allPages: true
            },
            dataBound: (e) => {
            },
            excelExport: (e) => {
                //let date = new Date();
                //(<any>e.workbook).fileName = `FranchisorBatchesExport-${date.getUTCFullYear()}_${date.getUTCMonth()}_${date.getUTCDay()}.xlsx`;
            },
            selectable: false,
            editable: false,
            scrollable: true,
            sortable: true,
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: false
        };

        this.invoiceGrid.setOptions(invoicesGridOptions);
    }

    DownloadInvoiceExcel() {
        this.invoiceGrid.saveAsExcel();
        this.$timeout(() => {
            this.areInvoicesDownloaded = true;
        }, 1000);
    }

    MarkInvoicesVoided() {
        let exportIds = <number[]>this.invoiceGrid.dataSource.data()
            .map((result: { royaltyInvoiceExportBatchID: number }) => {
                return result.royaltyInvoiceExportBatchID;
            });
        exportIds = _.uniq(exportIds);

        return this.$q.all(
            exportIds.map((id) => {
                let royaltyInvoiceExportVoidRequestData: royaltyInvoiceExportVoidRequestDataModel = {
                    royaltyInvoiceExportBatchID: id,
                    isBatchVoided: false,
                    errorDescription: this.errorDescription
                }
                return this.royaltyApiResources.SetRoyaltyInvoiceExportToVoided(royaltyInvoiceExportVoidRequestData)
            })
        ).then((response) => {
            if (this.onInvoicesVoided) {
                this.onInvoicesVoided({});
                this.closeRefresh({});
                }
            });
    }

    CancelVoidingInvoices() {
        this.onVoidingInvoicesCancel();
    }

    static BindComponent(app: ng.IModule) {
        app.component('bmInvoiceReversal', {
            bindings: {
                [nameof<BatchManagementInvoiceReversalComponentController>(o => o.invoicesDataSource)]: "<",
                [nameof<BatchManagementInvoiceReversalComponentController>(o => o.onInvoicesVoided)]: "&",
                [nameof<BatchManagementInvoiceReversalComponentController>(o => o.closeRefresh)]: "&",
                [nameof<BatchManagementInvoiceReversalComponentController>(o => o.errorDescription)]: "<",
                [nameof<BatchManagementInvoiceReversalComponentController>(o => o.onVoidingInvoicesCancel)]: "&"
            },
            controller: BatchManagementInvoiceReversalComponentController,
            templateUrl: "/Templates/BatchManagement/bmInvoiceReversal.html"
        });
    }
}