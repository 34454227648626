import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { CallCenterResources } from "Interfaces/FranForce/CallCenter/CallCenterResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";


export class CallCenterConceptNotificationConfigurationsComponentController implements ng.IController 
{
    conceptId: number;

    configuration: CallCenterResources.IC4ConceptNotificationConfiguration;
    configurationWindow: kendo.ui.Window;
    configurationGrid: kendo.ui.Grid;

    contactDropDown: kendo.ui.DropDownList;
    contactDropDownOptions: kendo.ui.DropDownListOptions;
    selectedContact: CallCenterResources.IC4CorporateContact;
    callTypeDropDown: kendo.ui.DropDownList;
    callTypeDropDownOptions: kendo.ui.DropDownListOptions;
    selectedCallType: CallCenterResources.IC4CallType;
    franchisorFilters: kendo.data.DataSourceFilterItem;

    static $inject = [
        'coreApiResources',
        'callCenterApiResources',
        '$q',
        '$log',
        '$timeout'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private callCenterApiResources: CallCenterApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService
    )
    {
    }

    $onInit()
    {
        this.InitContactDropDown();
        this.InitCallTypeDropDown();
    }

    $postLink()
    {
        this.InitGrid();
        this.InitWindow();
        this.ResetGrid();
    }

    $onChanges(changes: ng.IOnChangesObject)
    {

    }

    InitGrid()
    {
        let dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) =>
                {
                    if (!this.conceptId) {
                        options.success([]);
                        return;
                    }

                    let queryParams: ODataQueryParameters = {
                        $filter: `${nameof.full<CallCenterResources.IC4ConceptNotificationConfiguration>(o => o.ConceptId)} eq ${this.conceptId}`,
                        $expand: `${nameof<CallCenterResources.IC4ConceptNotificationConfiguration>(o => o.C4CallType)}($select=${nameof<CallCenterResources.IC4ConceptNotificationConfiguration>(o => o.C4CallType.Name)}),` +
                            `${nameof<CallCenterResources.IC4ConceptNotificationConfiguration>(o => o.C4CorporateContact)}($select=${nameof<CallCenterResources.IC4ConceptNotificationConfiguration>(o => o.C4CorporateContact.Name)}),` +
                            `${nameof<CallCenterResources.IC4ConceptNotificationConfiguration>(o => o.Franchisor)}($select=${nameof<CallCenterResources.IC4ConceptNotificationConfiguration>(o => o.Franchisor.DisplayName)}),` +
                            `${nameof<CallCenterResources.IC4ConceptNotificationConfiguration>(o => o.Concept)}($select=${nameof<CallCenterResources.IC4ConceptNotificationConfiguration>(o => o.Concept.ConceptCode)})`
                    };
                    this.callCenterApiResources.C4ConceptNotificationConfigurationsApi.query(queryParams).$promise
                        .then((configs) =>
                        {
                            for (let config of configs)
                            {
                                config.Concept = config.Concept || <any>{};
                                config.Franchisor = config.Franchisor || <any>{};
                                config.C4CorporateContact = config.C4CorporateContact || <any>{};
                                config.C4CallType = config.C4CallType || <any>{};
                            }
                            options.success(configs);
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        })
                }
            },
            sort: { field: nameof<CallCenterResources.IC4ConceptNotificationConfiguration>(o => o.Concept.ConceptCode), dir: "asc" }
        });

        let columns: kendo.ui.GridColumn[] = [{
                field: nameof.full<CallCenterResources.IC4ConceptNotificationConfiguration>(o => o.C4CorporateContact.Name),
                title: "Contact"
            }, {
                field: nameof.full<CallCenterResources.IC4ConceptNotificationConfiguration>(o => o.C4CallType.Name),
                title: "Call Type"
            }, {
                field: nameof.full<CallCenterResources.IC4ConceptNotificationConfiguration>(o => o.Franchisor.DisplayName),
                title: "Franchisor"
            }, {
                template: `<button class='pure-button button-error' ` +
                    `ng-click='$ctrl.${nameof(this.DeleteConfiguration)}(dataItem)'>X</button>`,
                width: 60
            }];

        let options: kendo.ui.GridOptions = {
            autoBind: false,
            dataSource: dataSource,
            columns: columns,
            toolbar: [
                {
                    template: `<button class='pure-button button-secondary' ng-click='$ctrl.${nameof(this.OpenNewConfigurationWindow)}()'>New Contact Assignment...</button>`
                }
            ],
            sortable: false,
            filterable: false
        };
        this.configurationGrid.setOptions(options);
    }

    InitWindow()
    {
        let options: kendo.ui.WindowOptions = {
            modal: true
        };
        this.configurationWindow.setOptions(options);
    }

    InitContactDropDown()
    {
        this.contactDropDownOptions = {
            optionLabel: "Select Contact...",
            optionLabelTemplate: "Select Contact...",
            dataTextField: nameof<CallCenterResources.IC4CorporateContact>(o => o.Name),
            dataValueField: nameof<CallCenterResources.IC4CorporateContact>(o => o.C4CorporateContactId),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) =>
                    {
                        if (!this.conceptId) {
                            options.success([]);
                            return;
                        }

                        let params = {
                            $select: [
                                nameof<CallCenterResources.IC4CorporateContact>(o => o.C4CorporateContactId),
                                nameof<CallCenterResources.IC4CorporateContact>(o => o.Name)
                            ].join(","),
                            $filter: `${nameof<CallCenterResources.IC4CorporateContact>(o => o.ConceptId)} eq ${this.conceptId} or ` + 
                                `${nameof<CallCenterResources.IC4CorporateContact>(o => o.ConceptId)} eq null`
                        };

                        this.callCenterApiResources.C4CorporateContactsApi.query(params).$promise
                            .then((contacts) =>
                            {
                                options.success(contacts);
                            })
                            .catch((err) =>
                            {
                                options.error(err);
                            })
                    }
                }
            })
        }
    }

    InitCallTypeDropDown()
    {
        this.callTypeDropDownOptions = {
            optionLabel: "Select Call Type...",
            optionLabelTemplate: "Select Call Type...",
            dataTextField: nameof<CallCenterResources.IC4CallType>(o => o.Name),
            dataValueField: nameof<CallCenterResources.IC4CallType>(o => o.C4CallTypeId),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) =>
                    {
                        let params = {
                            $select: [
                                nameof<CallCenterResources.IC4CallType>(o => o.C4CallTypeId),
                                nameof<CallCenterResources.IC4CallType>(o => o.Name)
                            ].join(",")
                        };

                        this.callCenterApiResources.C4CallTypesApi.query(params).$promise
                            .then((contacts) =>
                            {
                                options.success(contacts);
                            })
                            .catch((err) =>
                            {
                                options.error(err);
                            })
                    }
                }
            })
        }
    }

    OpenNewConfigurationWindow()
    {
        this.configuration = <any>{};
        this.configuration.ConceptId = this.conceptId;
        this.contactDropDown.dataSource.read();

        this.configurationWindow.open();
        this.$timeout(() =>
        {
            this.configurationWindow.center();
        });
    }

    OpenEditConfigurationWindow(configuration: CallCenterResources.IC4ConceptNotificationConfiguration)
    {
        this.configuration = angular.copy(configuration);
        this.configuration.C4CallType = null;
        this.configuration.Concept = null;
        this.configuration.Franchisor = null;
        this.configuration.C4CorporateContact = null;

        this.contactDropDown.dataSource.read().then(() =>
        {
            this.contactDropDown.select((contact: CallCenterResources.IC4CorporateContact) =>
            {
                return contact.C4CorporateContactId == this.configuration.C4CorporateContactId;
            });
        })

        this.callTypeDropDown.dataSource.read().then(() =>
        {
            this.callTypeDropDown.select((type: CallCenterResources.IC4CallType) =>
            {
                return type.C4CallTypeId == this.configuration.C4CallTypeId;
            });
        })
        
        this.configurationWindow.open();
        this.$timeout(() =>
        {
            this.configurationWindow.center();
        });
    }

    SaveConfiguration()
    {
        let promise: ng.IPromise<any>;

        if (this.configuration.C4ConceptNotificationConfigurationId)
        {
            promise = this.callCenterApiResources.C4ConceptNotificationConfigurationsApi.update({
                id: this.configuration.C4ConceptNotificationConfigurationId
            }, this.configuration).$promise;
        }
        else
        {
            if (!this.selectedContact)
            {
                alert("Please select a contact.");
                return;
            }

            if (!this.selectedCallType)
            {
                alert("Please select a call type.");
                return;
            }

            this.configuration.C4CorporateContactId = this.selectedContact.C4CorporateContactId;
            this.configuration.C4CallTypeId = this.selectedCallType.C4CallTypeId;
            promise = this.callCenterApiResources.C4ConceptNotificationConfigurationsApi.save({}, this.configuration).$promise;
        }

        return promise
            .then(() =>
            {
                this.configurationWindow.close();
                this.ResetGrid();
            });
    }

    DeleteConfiguration(configuration: CallCenterResources.IC4ConceptNotificationConfiguration)
    {
        if (confirm(`Are you sure you want to delete this ${nameof(configuration)}?`)) {
            return this.callCenterApiResources.C4ConceptNotificationConfigurationsApi.delete({
                id: configuration.C4ConceptNotificationConfigurationId,
                allowHardDelete: true
            }).$promise
                .then(() =>
                {
                    this.ResetGrid()
                });
        }
    }

    OnConceptSelect(concept: Partial<CoreResources.IConcept>)
    {
        this.conceptId = concept.ConceptId;
        this.ResetGrid();
        this.contactDropDown.dataSource.read();
        this.franchisorFilters = this.conceptId ? {
            field: nameof<CoreResources.IFranchisor>(o => o.ConceptId),
            operator: 'eq',
            value: this.conceptId
        } : null;
    }

    ResetGrid()
    {
        if (!this.configurationGrid)
            return;

        return this.$q.when(this.configurationGrid.dataSource.read())
            .then(() =>
            {
                this.configurationGrid.refresh();
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('callCenterConceptNotificationConfigurations', {
            bindings: {

            },
            controller: CallCenterConceptNotificationConfigurationsComponentController,
            templateUrl: "/Templates/C4/CallCenterConceptNotificationConfigurations.html"
        });
    }
}


