import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import * as _ from "underscore";
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { CoreResourceApi } from "Interfaces/FranForce/Core/CoreResourceApiInterfaces";
import { IWebpageToPdfOptions } from "Interfaces/FranForce/Core/Pdf/IWebpageToPdfOptions";
import { RoyaltyContractRatePlanMinimumGridItem } from "../../Directives/Rpm/ContractManagement";
import { RoyaltyContractRatePlanMinimumMonthsApplied, RoyaltyDisclosureDocumentRatePlanMinimum, RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied, RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulation, RoyaltyDisclosureDocumentRollInMinimum, RoyaltyDisclosureDocumentRollInMinimumMonthsApplied, RoyaltyDisclosureDocumentRollInRange } from "@nbly/dataaccess.typings/TypeGen/Dwyer.DataAccess.FranForce.Rpm";

export class CoreApiResources {
	EmailProcessTypeApi: CoreResourceApi.IEmailProcessTypeApi;
	FranchiseApi: CoreResourceApi.IFranchiseApi;
	FranchiseEmailApi: CoreResourceApi.IFranchiseEmailApi;
	FranchiseProfileApi: CoreResourceApi.IFranchiseProfileApi;
	FranchiseProfileContactApi: CoreResourceApi.IFranchiseProfileContactApi;
	FranchiseProfileContactPositionApi: CoreResourceApi.IFranchiseProfileContactPositionApi;
	FranchiseProfileContactAssignmentApi: CoreResourceApi.IFranchiseProfileContactAssignmentApi;
	FranchiseProfileContactAssignmentTypeApi: CoreResourceApi.IFranchiseProfileContactAssignmentTypeApi;
	FranchiseProfileContactsFranchisePostalCodeApi: CoreResourceApi.IFranchiseProfileContactsFranchisePostalCodeApi;
	FranchiseProfileContactsFranchiseServiceSkuApi: CoreResourceApi.IFranchiseProfileContactsFranchiseServiceSkuApi;
	FranchiseProfileContactNotificationTypeApi: CoreResourceApi.IFranchiseProfileContactNotificationTypeApi;
	FranchiseProfileEmployeeBioApi: CoreResourceApi.IFranchiseProfileEmployeeBioApi;
	FranchiseProfileFeesApi: CoreResourceApi.IFranchiseProfileFeesApi;
	FranchisePostalCodeProfileFeeApi: CoreResourceApi.IFranchiseProfilePostalCodeFeeApi;
	FranchiseProfileFeeTypeApi: CoreResourceApi.IFranchiseProfileFeeTypeApi;
	FranchiseProfileOpenTimeApi: CoreResourceApi.IFranchiseProfileOpenTimeApi;
	FranchiseProfileSchedulingTypeApi: CoreResourceApi.IFranchiseProfileSchedulingTypeApi;
	FranchisePostalCodeApi: CoreResourceApi.IFranchisePostalCodeApi;
	FranchiseServiceSkuApi: CoreResourceApi.IFranchiseServiceSkuApi;
	FranchisorApi: CoreResourceApi.IFranchisorApi;
	ConceptApi: CoreResourceApi.IConceptApi;
	ConceptFeeTypeApi: CoreResourceApi.IConceptFeeTypeApi;
	ConceptServiceSkuApi: CoreResourceApi.IConceptServiceSkuApi;
	ConceptOpenTimeApi: CoreResourceApi.IConceptOpenTimeApi;
	ServiceApi: CoreResourceApi.IServiceApi;
	ServiceSkuApi: CoreResourceApi.IServiceSkuApi;
	TimeZoneApi: CoreResourceApi.ITimeZoneApi;
	FranchiseCertificationApi: CoreResourceApi.IFranchiseCertificationApi;
	FranchiseCertificationTypeApi: CoreResourceApi.IFranchiseCertificationTypeApi;
	WebThemeApi: CoreResourceApi.IWebThemeApi;
	ConceptLeadPushOptionApi: CoreResourceApi.IConceptLeadPushOptionApi;
	FranchiseLeadPushOptionApi: CoreResourceApi.IFranchiseLeadPushOptionApi;
	LeadPushTypeOptionApi: CoreResourceApi.ILeadPushTypeOptionApi;

	static $inject = ['$resource', '$http', 'odataHelper', 'apiConfig'];

	constructor(
		private $resource: ng.resource.IResourceService,
		private $http: ng.IHttpService,
		private odataHelper: ODataHelperService,
		private apiConfig: ApiConfig
	) {
		this.RefreshResources();
	}

	RefreshResources() {
		this.EmailProcessTypeApi = this.$resource<CoreResources.IEmailProcessType>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/EmailProcessTypes/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseApi = this.$resource<CoreResources.IFranchise>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/Franchises/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseEmailApi = this.$resource<CoreResources.IFranchiseEmail>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseEmails/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseProfileApi = this.$resource<CoreResources.IFranchiseProfile>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseProfiles/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchisePostalCodeApi = this.$resource<CoreResources.IFranchisePostalCode>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchisePostalCodes/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseProfileEmployeeBioApi = this.$resource<CoreResources.IFranchiseProfileEmployeeBio>(this.apiConfig.AppSettings.CoreApiRootUrl + "/FranchiseProfileEmployeeBios/:id/",
			{ id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

		this.FranchiseProfileFeesApi = this.$resource<CoreResources.IFranchiseProfileFee>(this.apiConfig.AppSettings.CoreApiRootUrl + "/FranchiseProfileFees/:id/",
			{ id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

		this.FranchiseProfileContactApi = this.$resource<CoreResources.IFranchiseProfileContact>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseProfileContacts/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseProfileContactPositionApi = this.$resource<CoreResources.IFranchiseProfileContactPosition>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseProfileContactPositions/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseProfileContactAssignmentApi = this.$resource<CoreResources.IFranchiseProfileContactAssignment>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseProfileContactAssignments/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseProfileContactAssignmentTypeApi = this.$resource<
			CoreResources.IFranchiseProfileContactAssignmentType
		>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseProfileContactAssignmentTypes/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseProfileContactsFranchisePostalCodeApi = this.$resource<
			CoreResources.IFranchiseProfileContactsFranchisePostalCode
		>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseProfileContactsFranchisePostalCodes/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseProfileContactsFranchiseServiceSkuApi = this.$resource<
			CoreResources.IFranchiseProfileContactsFranchiseServiceSku
		>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseProfileContactsFranchiseServiceSkus/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseServiceSkuApi = this.$resource<CoreResources.IFranchiseServiceSku>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseServiceSkus/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseProfileOpenTimeApi = this.$resource<CoreResources.IFranchiseProfileOpenTime>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseProfileOpenTimes/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchisePostalCodeProfileFeeApi = this.$resource<CoreResources.IFranchisePostalCodeProfileFee>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchisePostalCodeProfileFees/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseProfileFeeTypeApi = this.$resource<CoreResources.IFranchiseProfileFeeType>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseProfileFeeTypes/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseProfileSchedulingTypeApi = this.$resource<CoreResources.IFranchiseProfileSchedulingType>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseProfileSchedulingTypes/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseProfileContactNotificationTypeApi = this.$resource<
			CoreResources.IFranchiseProfileContactNotificationType
		>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseProfileContactNotificationTypes/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchisorApi = this.$resource<CoreResources.IFranchisor>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/Franchisor/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.ConceptApi = this.$resource<CoreResources.IConcept>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/Concepts/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.ConceptFeeTypeApi = this.$resource<CoreResources.IConceptFeeType>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/ConceptFeeTypes/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.ConceptServiceSkuApi = this.$resource<CoreResources.IConceptServiceSku>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/ConceptServiceSkus/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.ConceptOpenTimeApi = this.$resource<CoreResources.IConceptOpenTime>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/ConceptOpenTimes/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.ServiceApi = this.$resource<CoreResources.IService>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/Services/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.ServiceSkuApi = this.$resource<CoreResources.IServiceSku>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/ServiceSkus/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.TimeZoneApi = this.$resource<CoreResources.ITimeZone>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/TimeZones/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseCertificationApi = this.$resource<CoreResources.IFranchiseCertification>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseCertifications/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseCertificationTypeApi = this.$resource<CoreResources.IFranchiseCertificationType>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseCertificationTypes/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.WebThemeApi = this.$resource<CoreResources.IWebTheme>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/WebThemes/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.ConceptLeadPushOptionApi = this.$resource<CoreResources.IConceptLeadPushOption>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/ConceptLeadPushOptions/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.FranchiseLeadPushOptionApi = this.$resource<CoreResources.IFranchiseLeadPushOption>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseLeadPushOptions/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);

		this.LeadPushTypeOptionApi = this.$resource<CoreResources.ILeadPushTypeOption>(
			this.apiConfig.AppSettings.CoreApiRootUrl + '/LeadPushTypeOptions/:id/',
			{ id: '@id' },
			this.odataHelper.GetStandardMethodExtensions()
		);
	}

	GetPdfUrl(templateId: number, options: IWebpageToPdfOptions): string {
		let url =
			this.apiConfig.AppSettings.CoreApiRootUrl +
			'/WebpageToPdfTemplates/' +
			templateId +
			(templateId + '' !== '' ? '/' : '') +
			'Render/' +
			'?access_token=' +
			(<ILocalStorage>localStorage).FranForceAuthToken;

		if (options.transcludeAuth) url += '&transcludeAuth=' + options.transcludeAuth;

		if (options.renderingType) url += '&renderingType=' + options.renderingType;

		if (options.model) url += '&modelJson=' + JSON.stringify(options.model);

		if (options.template) url += '&templateJson=' + JSON.stringify(options.template);

		return url;
	}

	UpdateConceptApi(conceptAPIFeilds: CoreResources.IConcept): ng.IHttpPromise<any> {
		let config: ng.IRequestConfig = {
			method: 'PATCH',
			url: this.apiConfig.AppSettings.CoreApiRootUrl + '/Concepts/' + conceptAPIFeilds.ConceptId,
			data: { [nameof<CoreResources.IConcept>((o) => o.IsServiceNameExclusionToggle)]: conceptAPIFeilds.IsServiceNameExclusionToggle }
		};
		return this.$http<any>(config);
	}

	GetFranchiseProfileLastUpdatedDateTime(franchiseId: number): ng.IHttpPromise<string> {
		if (!franchiseId) {
			throw new Error("FranchiseId was not passed in to 'GetFranchiseProfileLastUpdatedDateTime'");
		}

		let config: ng.IRequestConfig = {
			method: 'GET',
			url: this.apiConfig.AppSettings.CoreApiRootUrl + '/FranchiseProfiles/' + franchiseId + '/LastUpdated'
		};

		return this.$http<string>(config);
	}

	GetFranchisePos(franchiseId: number): ng.IHttpPromise<IFranchisePos> {
		let config: ng.IRequestConfig = {
			method: 'GET',
			url: this.apiConfig.AppSettings.CoreApiRootUrl + '/Franchises/' + franchiseId + '/PointOfSale'
		};
		return this.$http<IFranchisePos>(config);
	}

	UpdateFranchisePos(franchisePos: IFranchisePos): ng.IHttpPromise<any> {
		let config: ng.IRequestConfig = {
			method: 'PUT',
			url: this.apiConfig.AppSettings.CoreApiRootUrl + '/Franchises/' + franchisePos.FranchiseId + '/PointOfSale',
			data: franchisePos
		};
		return this.$http<any>(config);
	}

	GetIntuitCompanyId(franchiseId: number): ng.IHttpPromise<string> {
		let config: ng.IRequestConfig = {
			method: 'GET',
			url: this.apiConfig.AppSettings.CoreApiRootUrl + '/Franchises/' + franchiseId + '/IntuitCompanyId'
		};
		return this.$http<string>(config);
	}

	UpdateIntuitCompanyId(franchiseId: number, intuitCompanyId: string): ng.IHttpPromise<any> {
		let config: ng.IRequestConfig = {
			method: 'PUT',
			url: this.apiConfig.AppSettings.CoreApiRootUrl + '/Franchises/' + franchiseId + '/IntuitCompanyId',
			data: `"${intuitCompanyId}"`
		};
		return this.$http<any>(config);
	}

	GetFranchiseRoyaltyInfo(franchiseId: number): ng.IHttpPromise<IFranchiseRoyaltyFields> {
		let config: ng.IRequestConfig = {
			method: 'GET',
			url: this.apiConfig.AppSettings.CoreApiRootUrl + '/Franchises/' + franchiseId + '/RoyaltyInfo'
		};
		return this.$http<IFranchiseRoyaltyFields>(config);
	}

	UpdateFranchiseRoyaltyInfo(franchiseRoyaltyInfo: IFranchiseRoyaltyFields): ng.IHttpPromise<any> {
		let config: ng.IRequestConfig = {
			method: 'PUT',
			url:
				this.apiConfig.AppSettings.CoreApiRootUrl +
				'/Franchises/' +
				franchiseRoyaltyInfo.FranchiseId +
				'/RoyaltyInfo',
			data: franchiseRoyaltyInfo
		};
		return this.$http<any>(config);
	}

	ClearEntityRuleCache(userId: string): ng.IHttpPromise<any> {
		if (!userId) {
			throw new Error("cacheKey was not passed in to 'ClearCache'");
		}

		return this.ClearCache('UserEntityRules.' + userId);
	}

	ClearCache(cacheKey: string): ng.IHttpPromise<any> {
		if (!cacheKey) {
			throw new Error("cacheKey was not passed in to 'ClearCache'");
		}

		let config: ng.IRequestConfig = {
			method: 'DELETE',
			url: encodeURI(this.apiConfig.AppSettings.CoreApiRootUrl + '/Cache/' + cacheKey + '/')
		};

		return this.$http<any>(config);
	}

	GetAllowedConcepts(): ng.IHttpPromise<any> {
		let config: ng.IRequestConfig = {
			method: 'GET',
			url: this.apiConfig.AppSettings.CoreApiRootUrl + '/Concepts/AllowedConcepts'
		};
		return this.$http<any>(config);
	}

	GetFranchiseOwnedPopulation(franchiseId: number) {
		let params: ODataQueryParameters = {};

		let franchiseIdField = nameof<CoreResources.IFranchisePostalCode>((o) => o.FranchiseId);
		let populationField = nameof<CoreResources.IFranchisePostalCode>((o) => o.Population);

		params.$apply =
			`filter(` +
			`${franchiseIdField} eq ${franchiseId} and ` +
			`${nameof<CoreResources.IFranchisePostalCode>((o) => o.OwnershipType)} eq 'Owned' and ` +
			`${populationField} ne null and ` +
			`${nameof<CoreResources.IFranchisePostalCode>((o) => o.DeletedDateTime)} eq null` +
			`)/groupby((${franchiseIdField}), aggregate(${populationField} with sum as ${populationField}))`;

		return this.FranchisePostalCodeApi.query(params).$promise.then((ownedAggResult) => {
			return ownedAggResult.length >= 1 ? ownedAggResult[0].Population : null;
		});
	}

	GetFranchiseTotalPopulation(franchiseId: number) {
		let params: ODataQueryParameters = {};

		let franchiseIdField = nameof<CoreResources.IFranchisePostalCode>((o) => o.FranchiseId);
		let populationField = nameof<CoreResources.IFranchisePostalCode>((o) => o.Population);

		params.$apply =
			`filter(` +
			`${franchiseIdField} eq ${franchiseId} and ` +
			`${populationField} ne null and ` +
			`${nameof<CoreResources.IFranchisePostalCode>((o) => o.DeletedDateTime)} eq null` +
			`)/groupby((${franchiseIdField}), aggregate(${populationField} with sum as ${populationField}))`;

		return this.FranchisePostalCodeApi.query(params).$promise.then((totalAggResult) => {
			return totalAggResult.length >= 1 ? totalAggResult[0].Population : null;
		});
	}

	FranchiseLookUpByServiceAddress(
		conceptId: number,
		address: string,
		serviceId: number = null,
		isRoundRobin: boolean = undefined,
		requireAddressOnPartialPostalCode: boolean = undefined
	) {
		let params = {
			ConceptId: conceptId,
			Address: address,
			ServiceId: serviceId,
			IsRoundRobin: isRoundRobin,
			RequireAddressOnPartialPostalCode: requireAddressOnPartialPostalCode
		};

		params = _.pick(params, (value, key, obj) => {
			return value !== undefined;
		});

		let config: ng.IRequestConfig = {
			method: "GET",
			url: this.apiConfig.AppSettings.CoreApiRootUrl + `/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress`,
			params: params
		};
		return this.$http<CoreEntities.usp_Geography_GetTerritoriesServicingPoint_Result[]>(config);
	}

	FranchiseLookUpByServiceAddressMicro(
		conceptId: number,
		address: string,
		serviceId: number = null,
		isRoundRobin: boolean = undefined,
		requireAddressOnPartialPostalCode: boolean = undefined
	) {
		let params = {
			apikey: this.apiConfig.AppSettings.FranchiseEmployeeOrchestrationsApiKey,
			ConceptId: conceptId,
			Address: address,
			ServiceId: serviceId,
			IsRoundRobin: isRoundRobin,
			RequireAddressOnPartialPostalCode: requireAddressOnPartialPostalCode
		};

		params = _.pick(params, (value, key, obj) => {
			return value !== undefined;
		});

		let config: ng.IRequestConfig = {
			method: "GET",
			url: this.apiConfig.AppSettings.UnifiedSyncPlatformBaseUrl + `UnifiedSyncPlatform/FranchiseLookUpByServiceAddress`,
			params: params
		};
		return this.$http<CoreEntities.usp_Geography_GetTerritoriesServicingPoint_Result[]>(config);
	}

	SetFranchiseProfileEmployeeBioImage(franchiseProfileEmployeeBioId: number, webImageId: number) {
		let config: ng.IRequestConfig = {
			method: "POST",
			url: `${this.apiConfig.AppSettings.CoreApiRootUrl}/FranchiseProfileEmployeeBios/${franchiseProfileEmployeeBioId}/SetWebImage/${webImageId}`,
		}
		return this.$http<void>(config)

	}

	GetConceptImageUrl(conceptId: number) {
		let config: ng.IRequestConfig = {
			method: 'GET',
			url: this.apiConfig.AppSettings.CoreApiRootUrl + `/Concepts/${conceptId}/ImageUrl`
		};
		return this.$http<any>(config).then((url) => url.data);
	}

	UpdateConceptImage(conceptId: number, imageData: any) {
		let imageModel = (<string>imageData).split(',', 2);

		return this.$http.post(this.apiConfig.AppSettings.CoreApiRootUrl + `/Concepts/${conceptId}/Image`, {
			Description: imageModel[0], // data:image/png;base64 portion
			ImageData: imageModel[1] // image data as base 64 string
		});
	}
	GetConceptVanImageUrl(conceptId: number) {
		let config: ng.IRequestConfig = {
			method: 'GET',
			url: this.apiConfig.AppSettings.CoreApiRootUrl + `/Concepts/${conceptId}/VanImageUrl`
		};
		return this.$http<any>(config).then((url) => url.data);
	}

	UpdateConceptVanImage(conceptId: number, imageData: any) {
		let imageModel = (<string>imageData).split(',', 2);

		return this.$http.post(this.apiConfig.AppSettings.CoreApiRootUrl + `/Concepts/${conceptId}/VanImage`, {
			Description: imageModel[0], // data:image/png;base64 portion
			ImageData: imageModel[1] // image data as base 64 string
		});
	}

	async SaveFranchiseProfileWebsiteMessage(franchiseProfileId: number, message: string, active: boolean) {
		if (!message)
			message = null;

		return this.$http<void>({
			method: "POST",
			url: `${this.apiConfig.AppSettings.CoreApiRootUrl}/FranchiseProfiles/${franchiseProfileId}/WebsiteMessage`,
			params: {
				websiteMessage: message,
				websiteMessageActive: active
			}
		});
	}

	async CreateRoyaltyContractRatePlanMinimums(model: RoyaltyContractRatePlanMinimumGridItem) {
		let config: ng.IRequestConfig = {
			method: "POST",
			url: this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyContractRatePlanMinimums",
			data: model
		};

		return this.$http<RoyaltyContractRatePlanMinimumGridItem>(config);
	}

	UpdateRoyaltyContractRatePlanMinimums(royaltyContractRatePlanMinimumId: number, model: RoyaltyContractRatePlanMinimumGridItem) {
		let config: ng.IRequestConfig = {
			method: "PUT",
			url: this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyContractRatePlanMinimums/" + royaltyContractRatePlanMinimumId,
			data: model
		};

		return this.$http<RoyaltyContractRatePlanMinimumGridItem>(config);
	}

	async RoyaltyContractRatePlanMinimumMonthsApplied(model: RoyaltyContractRatePlanMinimumMonthsApplied) {
		let config: ng.IRequestConfig = {
			method: "POST",
			url: this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyContractRatePlanMinimumMonthsApplied",
			data: model
		};

		return this.$http<RoyaltyContractRatePlanMinimumMonthsApplied>(config);
	}

	CreateRoyaltyDisclosureDocumentRatePlanMinimums(model: RoyaltyDisclosureDocumentRatePlanMinimum) {
		let config: ng.IRequestConfig = {
			method: "POST",
			url: this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRatePlanMinimums",
			data: model
		};

		return this.$http<RoyaltyDisclosureDocumentRatePlanMinimum>(config);
	}

	UpdateRoyaltyDisclosureDocumentRatePlanMinimums(royaltyDisclosureDocumentRatePlanMinimumId: number, model: RoyaltyDisclosureDocumentRatePlanMinimum) {
		let config: ng.IRequestConfig = {
			method: "PUT",
			url: this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRatePlanMinimums/" + royaltyDisclosureDocumentRatePlanMinimumId,
			data: model
		};

		return this.$http<RoyaltyDisclosureDocumentRatePlanMinimum>(config);
	}

	RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied(model: RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied) {
		let config: ng.IRequestConfig = {
			method: "POST",
			url: this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied",
			data: model
		};

		return this.$http<RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied>(config);
	}

	CreateRoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulations(model: RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulation) {
		let config: ng.IRequestConfig = {
			method: "POST",
			url: this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulations",
			data: model
		};

		return this.$http<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulation>(config);
	}

	UpdateRoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulations(disclosureDocumentRatePlanMinimumsTerritoryPopulationId: number, model: RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulation) {
		let config: ng.IRequestConfig = {
			method: "PUT",
			url: this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulations/" + disclosureDocumentRatePlanMinimumsTerritoryPopulationId,
			data: model
		};

		return this.$http<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulation>(config);
	}

	CreateRoyaltyDisclosureDocumentRollInMinimums(model: RoyaltyDisclosureDocumentRollInMinimum) {
		let config: ng.IRequestConfig = {
			method: "POST",
			url: this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRollInMinimums",
			data: model
		};

		return this.$http<RoyaltyDisclosureDocumentRollInMinimum>(config);
	}

	UpdateRoyaltyDisclosureDocumentRollInMinimums(royaltyDisclosureDocumentRollInMinimumId: number, model: RoyaltyDisclosureDocumentRollInMinimum) {
		let config: ng.IRequestConfig = {
			method: "PUT",
			url: this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRollInMinimums/" + royaltyDisclosureDocumentRollInMinimumId,
			data: model
		};

		return this.$http<RoyaltyDisclosureDocumentRollInMinimum>(config);
	}

	RoyaltyDisclosureDocumentRollInMinimumMonthsApplied(model: RoyaltyDisclosureDocumentRollInMinimumMonthsApplied) {
		let config: ng.IRequestConfig = {
			method: "POST",
			url: this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRollInMinimumMonthsApplied",
			data: model
		};

		return this.$http<RoyaltyDisclosureDocumentRollInMinimumMonthsApplied>(config);
	}

	CreateRollInRanges(model: RoyaltyDisclosureDocumentRollInRange) {
		let config: ng.IRequestConfig = {
			method: "POST",
			url: this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRollInRanges",
			data: model
		};

		return this.$http<RoyaltyDisclosureDocumentRollInRange>(config);
	}

	UpdateRollInRanges(rollInRangeId: number, model: RoyaltyDisclosureDocumentRollInRange) {
		let config: ng.IRequestConfig = {
			method: "PUT",
			url: this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRollInRanges/" + rollInRangeId,
			data: model
		};

		return this.$http<RoyaltyDisclosureDocumentRollInRange>(config);
	}
}
