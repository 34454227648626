import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class BusinessInfoDisplayComponentController implements ng.IController {
    //---- Bindings ----
    conceptId: number;
    conceptDisable: boolean;
    conceptSelect: string[];
    conceptDisplayTextTemplate: string;

    franchisorId: number;
    franchisorDisable: boolean;
    franchisorSelect: string[];
    franchisorDisplayTextTemplate: string;

    franchiseId: number;
    franchiseDisable: boolean;
    franchiseSelect: string[];
    franchiseDisplayTextTemplate: string;

    showLoading: boolean;

    onLoad: (params: {
        concept: Partial<CoreResources.IConcept>,
        conceptDisplayText: string,
        franchisor: Partial<CoreResources.IFranchisor>,
        franchisorDisplayText: string,
        franchise: Partial<CoreResources.IFranchise>,
        franchiseDisplayText: string
    }) => void;
    //------------------

    concept: Partial<CoreResources.IConcept>;
    conceptDisplayText: string;
    franchisor: Partial<CoreResources.IFranchisor>;
    franchisorDisplayText: string;
    franchise: Partial<CoreResources.IFranchise>;
    franchiseDisplayText: string;

    isLoading: boolean;

    static $inject = [
        "$q",
        "$interpolate",
        "$timeout",
        "coreApiResources",
        "identityManager",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $q: ng.IQService,
        private $interpolate: ng.IInterpolateService,
        private $timeout: ng.ITimeoutService,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    ) {

    }

    $onInit() {
        this.Init();
    }

    $postLink() {

    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        let changedBusinessId = (
            onChanges[nameof(this.conceptId)] ||
            onChanges[nameof(this.franchisorId)] ||
            onChanges[nameof(this.franchiseId)]
        );


        if (changedBusinessId) {
            this.Load();
        }
    }

    get isConceptVisible(): boolean {
        return (
            this.concept &&
            this.conceptDisplayText &&
            !this.conceptDisable
        );
    }

    get isFranchisorVisible(): boolean {
        return (
            this.franchisor &&
            this.franchisorDisplayText &&
            !this.franchisorDisable
        );
    }

    get isFranchiseVisible(): boolean {
        return (
            this.franchise &&
            this.franchiseDisplayText &&
            !this.franchiseDisable
        );
    }

    Init() {
        this.InitConcept();
        this.InitFranchisor();
        this.InitFranchise();
    }

    InitConcept() {
        if (!this.conceptSelect || !(this.conceptSelect instanceof Array)) {
            this.conceptSelect = [
                nameof<CoreResources.IConcept>(o => o.ConceptId),
                nameof<CoreResources.IConcept>(o => o.DisplayName)
            ];
        }

        if (!this.conceptSelect.some(s => s == nameof<CoreResources.IConcept>(o => o.ConceptId)))
            this.conceptSelect.push(nameof<CoreResources.IConcept>(o => o.ConceptId));

        if (!this.conceptDisplayTextTemplate)
            this.conceptDisplayTextTemplate = `{{${nameof<CoreResources.IConcept>(o => o.DisplayName)}}}`;
    }

    InitFranchisor() {
        if (!this.franchisorSelect || !(this.franchisorSelect instanceof Array)) {
            this.franchisorSelect = [
                nameof<CoreResources.IFranchisor>(o => o.FranchisorId),
                nameof<CoreResources.IFranchisor>(o => o.ConceptId),
                nameof<CoreResources.IFranchisor>(o => o.DisplayName)
            ];
        }

        if (!this.franchisorSelect.some(s => s == nameof<CoreResources.IFranchisor>(o => o.FranchisorId)))
            this.franchisorSelect.push(nameof<CoreResources.IFranchisor>(o => o.FranchisorId));

        if (!this.franchisorSelect.some(s => s == nameof<CoreResources.IFranchisor>(o => o.ConceptId)))
            this.franchisorSelect.push(nameof<CoreResources.IFranchisor>(o => o.ConceptId));

        if (!this.franchisorDisplayTextTemplate)
            this.franchisorDisplayTextTemplate = `{{${nameof<CoreResources.IFranchisor>(o => o.DisplayName)}}}`;

    }

    InitFranchise() {
        if (!this.franchiseSelect || !(this.franchiseSelect instanceof Array)) {
            this.franchiseSelect = [
                nameof<CoreResources.IFranchise>(o => o.ConceptId),
                nameof<CoreResources.IFranchise>(o => o.FranchisorId),
                nameof<CoreResources.IFranchise>(o => o.FranchiseId),
                nameof<CoreResources.IFranchise>(o => o.LicenseNumber),
                nameof<CoreResources.IFranchise>(o => o.DoingBusinessAs),
            ];
        };

        if (!this.franchiseSelect.some(s => s == nameof<CoreResources.IFranchise>(o => o.FranchiseId)))
            this.franchiseSelect.push(nameof<CoreResources.IFranchise>(o => o.FranchiseId));

        if (!this.franchiseSelect.some(s => s == nameof<CoreResources.IFranchise>(o => o.FranchisorId)))
            this.franchiseSelect.push(nameof<CoreResources.IFranchise>(o => o.FranchisorId));

        if (!this.franchiseSelect.some(s => s == nameof<CoreResources.IFranchise>(o => o.ConceptId)))
            this.franchiseSelect.push(nameof<CoreResources.IFranchise>(o => o.ConceptId));

        if (!this.franchiseDisplayTextTemplate)
            this.franchiseDisplayTextTemplate = `{{${nameof<CoreResources.IFranchise>(o => o.LicenseNumber)}}} - {{${nameof<CoreResources.IFranchise>(o => o.DoingBusinessAs)}}}`;
    }

    Load() {
        return this.$timeout(() => {
            let promise: ng.IPromise<void>;
            if (this.franchiseId) {
                promise = this.LoadFromFranchise();
            }
            else if (this.franchisorId) {
                promise = this.LoadFromFranchisor();
            }
            else if (this.conceptId) {
                promise = this.LoadFromConcept();
            }
            else {
                promise = this.$q.resolve();
            }

            promise = promise.then(() => {
                this.conceptId = this.concept && this.concept.ConceptId;
                this.franchisorId = this.franchisor && this.franchisor.FranchisorId;
                this.franchiseId = this.franchise && this.franchise.FranchiseId;

                this.RenderDisplayTemplates();

                if (this.onLoad) {
                    this.onLoad({
                        concept: this.concept,
                        conceptDisplayText: this.conceptDisplayText,
                        franchisor: this.franchisor,
                        franchisorDisplayText: this.franchisorDisplayText,
                        franchise: this.franchise,
                        franchiseDisplayText: this.franchiseDisplayText
                    });
                }
            })

            return promise;
        });
    }

    LoadFromFranchise() {
        this.isLoading = true;

        let parameters: ODataQueryParameters & { id: number | string } = {
            id: this.franchiseId,
            $select: this.franchiseSelect.join(',')
        }

        let expands: string[] = [];

        if (this.franchisorSelect && this.franchisorSelect.length && !this.franchisorDisable) {
            expands.push(`${nameof<CoreResources.IFranchise>(o => o.Franchisor)}($select=${this.franchisorSelect.join(',')})`);
        }

        if (this.conceptSelect && this.conceptSelect.length && !this.conceptDisable) {
            expands.push(`${nameof<CoreResources.IFranchise>(o => o.Concept)}($select=${this.conceptSelect.join(',')})`);
        }

        if (expands.length) {
            parameters.$expand = expands.join(",")
        }

        return this.coreApiResources.FranchiseApi.get(parameters).$promise
            .then((franchise) => {
                this.franchise = franchise;
                this.franchisor = franchise.Franchisor;
                this.concept = franchise.Concept;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    LoadFromFranchisor() {
        this.isLoading = true;

        let parameters: ODataQueryParameters & { id: number | string } = {
            id: this.franchisorId,
            $select: this.franchisorSelect.join(','),
        }

        if (this.conceptSelect && this.conceptSelect.length && !this.conceptDisable) {
            parameters.$expand = `${nameof<CoreResources.IFranchisor>(o => o.Concept)}($select=${this.conceptSelect.join(',')})`;
        }
        
        return this.coreApiResources.FranchisorApi.get(parameters).$promise
            .then((franchisor) => {
                this.franchise = null;
                this.franchisor = franchisor;
                this.concept = franchisor.Concept;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    LoadFromConcept() {
        this.isLoading = true;

        let parameters: ODataQueryParameters & { id: number | string } = {
            id: this.conceptId,
            $select: this.conceptSelect.join(',')
        }

        return this.coreApiResources.ConceptApi.get(parameters).$promise
            .then((concept) => {
                this.franchise = null;
                this.franchisor = null;
                this.concept = concept;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    RenderDisplayTemplates() {
        if (this.concept) {
            this.conceptDisplayText = this.$interpolate(this.conceptDisplayTextTemplate)(this.concept)
        }

        if (this.franchisor) {
            this.franchisorDisplayText = this.$interpolate(this.franchisorDisplayTextTemplate)(this.franchisor)
        }

        if (this.franchise) {
            this.franchiseDisplayText = this.$interpolate(this.franchiseDisplayTextTemplate)(this.franchise)
        }
    }

    static BindComponent(app: ng.IModule) {
        app.component("businessInfoDisplay", {
            bindings: {
                [nameof<BusinessInfoDisplayComponentController>(o => o.conceptId)]: "<",
                [nameof<BusinessInfoDisplayComponentController>(o => o.conceptSelect)]: "<",
                [nameof<BusinessInfoDisplayComponentController>(o => o.conceptDisplayTextTemplate)]: "<",
                [nameof<BusinessInfoDisplayComponentController>(o => o.conceptDisable)]: "<",
                [nameof<BusinessInfoDisplayComponentController>(o => o.franchisorId)]: "<",
                [nameof<BusinessInfoDisplayComponentController>(o => o.franchisorSelect)]: "<",
                [nameof<BusinessInfoDisplayComponentController>(o => o.franchisorDisplayTextTemplate)]: "<",
                [nameof<BusinessInfoDisplayComponentController>(o => o.franchisorDisable)]: "<",
                [nameof<BusinessInfoDisplayComponentController>(o => o.franchiseId)]: "<",
                [nameof<BusinessInfoDisplayComponentController>(o => o.franchiseSelect)]: "<",
                [nameof<BusinessInfoDisplayComponentController>(o => o.franchiseDisplayTextTemplate)]: "<",
                [nameof<BusinessInfoDisplayComponentController>(o => o.franchiseDisable)]: "<",

                [nameof<BusinessInfoDisplayComponentController>(o => o.showLoading)]: "<",
                [nameof<BusinessInfoDisplayComponentController>(o => o.onLoad)]: "&",
            },
            controller: BusinessInfoDisplayComponentController,
            templateUrl: `/Templates/Common/BusinessInfoDisplay.html`,
        });
    }
}

