import { ApiConfig } from "AppConfig/ApiConfig";

export class AccountSettingsController implements ng.IController
{
    localStorage: ILocalStorage;
    saveUrl: string;

    timestamp: Date | string;
    
    static $inject = [
        "apiConfig",
        "$timeout"
    ];

    constructor(
        private apiConfig: ApiConfig,
        private $timeout: ng.ITimeoutService
    )
    {

    }

    $onInit()
    {
        this.localStorage = <ILocalStorage>localStorage;
        this.saveUrl = this.apiConfig.AppSettings.IdentityApiRootUrl + "/AccountImages/Self";
    }

    UpdateTimestamp()
    {
        this.$timeout(() =>
        {
            this.timestamp = new Date();
        });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('accountSettings', {
            bindings: {

            },
            controller: AccountSettingsController,
            templateUrl: "/Templates/Account/AccountSettings.html"
        });
    }
}
