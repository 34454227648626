import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AngularUtil } from "Utility/AngularUtil";
import { EditUserStateService, userStateObj } from "Services/State/EditUserStateService";

export class EditUserRolesComponentController implements ng.IController {
    managedUserId: string;
    refreshDate: Date;
    refresh: (params: { refreshedDate: Date }) => void;

    userState: userStateObj;
    isLoading: boolean;
    userRolesMultiSelect: kendo.ui.MultiSelect;
    userRolesMultiSelectOptions: kendo.ui.MultiSelectOptions;
    isAdmin: boolean;
    selectedRoles: { Id: string, Name: string }[];

    static $inject = [
        'editUserState',
        'identityManager',
        'apiConfig',
    ];

    constructor(
        private editUserState: EditUserStateService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
    ) {
    }

    async $onInit() {
        await this.Load();
        this.SetDropDownOptions();
    }

    async SetDropDownOptions() {
        this.userRolesMultiSelectOptions =
        {
            placeholder: "Select User Roles...",
            dataTextField: "Name",
            dataValueField: "Id",
            valuePrimitive: false,
            autoBind: true,
            autoClose: false,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options: kendo.data.DataSourceTransportReadOptions) => {
                        if (!this.userState) {
                            return [];
                        }
                        let roles = this.userState.managedRoles
                            .filter((managedRole) => {
                                return !this.userState.managedUser.RoleIds
                                    .some(r => r == managedRole.Id);
                            });
                        options.success(roles);
                    }
                }
            }),
            dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) => {
                this.userRolesMultiSelect = e.sender;
            },
        };


    }

    $postLink() {

    }

   async $onChanges(changes: ng.IOnChangesObject) {
       await this.Load();
    }

    async Load() {
        let promise = this.editUserState.Load(this.managedUserId)
            .then((result) => {
                this.userState = result;
                if(this.userRolesMultiSelectOptions){
                    this.userRolesMultiSelectOptions.dataSource.read();
                }
            })
            .then(() => {
                this.isAdmin = this.identityManager.UserHasRole(
                    this.userState.selfUser,
                    this.apiConfig.FranForceConstants.RoleConstants.AdminRole
                );
            });

        return AngularUtil.TrackLoadingPromise(promise, this);
    }

    DisplayRoles(roles: IRoleViewModel[]): string {
        if (!this.userState) {
            return "";
        }

        return this.userState.managedUser.Roles
            .map(r => r.Name)
            .join(", ");
    }


    GetRolesToRemoveFromUser(): IRoleViewModel[] {
        if (!this.userState) {
            return [];
        }

        return this.userState.managedRoles
            .filter((managedRole) => {
                return this.userState.managedUser.RoleIds
                    .some(r => r == managedRole.Id);
            });
    }

    async AddRole() {

        if (this.selectedRoles && this.selectedRoles.length) {
            await Promise.all(
                this.selectedRoles.map(async (Roles) => {

                    await this.identityManager.AddUserToRole(this.userState.managedUser.Email, Roles.Id)
                        .then(() => {
                            this.RefreshUser();
                        })
                        .catch(AngularUtil.GetJsonAlertCatch());
                })

            ).then(() => {
                this.selectedRoles = [];
                this.RefreshUser();
            })
        }

    }

    RemoveRole(role: IRoleViewModel) {
        if (confirm(`Are you sure you want to remove the '${role.Name}' role?`)) {
            return this.identityManager.RemoveUserFromRole(this.userState.managedUser.Id, role.Id)
                .then(() => {
                    this.RefreshUser();
                })
                .catch(AngularUtil.GetJsonAlertCatch());
        }
    }

    RefreshUser() {
        this.editUserState.Clear(this.userState.managedUser.Id);
        if (this.refresh) {
            this.refresh({
                refreshedDate: new Date()
            });
            if(this.userRolesMultiSelectOptions){
                this.userRolesMultiSelectOptions.dataSource.read();
            }
        }
    }

    static BindComponent(app: ng.IModule) {
        app.component("editUserRoles", {
            bindings: {
                [nameof<EditUserRolesComponentController>(o => o.managedUserId)]: "<",
                [nameof<EditUserRolesComponentController>(o => o.refreshDate)]: "<",
                [nameof<EditUserRolesComponentController>(o => o.refresh)]: "&"
            },
            controller: EditUserRolesComponentController,
            templateUrl: "/Templates/Users/EditUser/EditUserRoles.html"
        });
    }
}


