    import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AxiosInstance } from "axios";
import {
    SsrsReportsClient,
    SsrsReportByTypeVm
} from '@nbly/service-orchestrations-clients';
import { FranForceAxiosClientBuilder } from "../../Clients/FranForceAxiosClientBuilder";

export class CesReports implements ng.IController {

    /*user roles check*/
    hasAdminRole: boolean;
    hasFranchiseeRole: boolean;
    hasBrokerRole: boolean;
    hasMLYReportRole: boolean;
    hasMHMReportRole: boolean;
    hasMOJOwnerReportRole: boolean;

    sSRSReportsClient: SsrsReportsClient;
    sSRSReportsByType: SsrsReportByTypeVm[];
    serviceOrchestrationsClient: AxiosInstance;

    isLoading: boolean;

    static $inject = [
        "$window",
        "identityManager",
        "apiConfig",
        "$scope"
    ];

    constructor(
        private $window: ng.IWindowService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $scope: ng.IScope
    ) {
        this.serviceOrchestrationsClient = FranForceAxiosClientBuilder.BuildServiceOrchestrationsBaseClient();
        this.sSRSReportsClient = new SsrsReportsClient("", this.serviceOrchestrationsClient);
    }

    async $onInit() {
        this.isLoading = false;
        this.sSRSReportsByType = [];
        await this.getRoles();
        await this.loadReports();    
    }

    async getRoles() {
        this.isLoading = true;
        await this.identityManager.GetLoggedInUserInfo().then(successResponse => {
            this.isLoading = false;
            if (successResponse && successResponse.data && successResponse.data.Roles && successResponse.data.Roles.length > 0) {

                /*check required roles*/
                successResponse.data.Roles.map(x => {
                    if (x.Name.trim() == "Franchisee")
                        this.hasFranchiseeRole = true;

                    if (x.Name.trim() == "Broker")
                        this.hasBrokerRole = true;

                    if (x.Name.trim() == "Admin")
                        this.hasAdminRole = true;

                    if (x.Name.trim() == "MLYTeamSiteReportUser")
                        this.hasMLYReportRole = true

                    if (x.Name == "MHMTeamSiteReportUser")
                        this.hasMHMReportRole = true

                    if (x.Name == "MOJOwners")
                        this.hasMOJOwnerReportRole = true

                    this.$scope.$digest();
                });
            }
        }).catch(ex => {
            this.isLoading = false;
            console.log("Error! Unable to get user roles.", ex);
        });
    }

    async loadReports() {
        this.isLoading = true;

        /*get reports by report type*/
        await this.sSRSReportsClient.ssrsReportsByType().then(res => {
            this.isLoading = false;
            if (res && res.result && res.result.length > 0) {

                /*filter reports according to user permissions*/
                this.sSRSReportsByType = res.result.filter(x => {

                    /*Refer A Neighbor Reports*/
                    if (x.reportTypeId == 1) {
                        if (this.hasAdminRole || this.hasBrokerRole || this.hasFranchiseeRole)
                            return true;
                    }

                    /*Molly Maid Team Site Reports*/
                    if (x.reportTypeId == 2) {
                        if (this.hasAdminRole || this.hasMLYReportRole)
                            return true;
                    }

                    /*Mr.Handyman Team Site Reports*/
                    if (x.reportTypeId == 3) {
                        if (this.hasAdminRole || this.hasMHMReportRole)
                            return true;
                    }
                    /*Mosquito Joe Reports*/
                    if (x.reportTypeId == 4) {
                        if (this.hasAdminRole || this.hasMOJOwnerReportRole)
                            return true;
                    }
                });
            }

            this.$scope.$digest();
        }).catch((ex) => {
            this.isLoading = false;
            console.log("Error! Unable to load reports.", ex);
            this.sSRSReportsByType = [];
            this.$scope.$digest();
        });
    }

    static BindComponent(app: ng.IModule) {
        app.component("cesReports", {
            controller: CesReports,
            templateUrl: "/Templates/Reports/CesReports.html"
        });
    }
}