import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class EditFranchiseController implements ng.IController {
    franchiseId: number;
    franchise: CoreResources.IFranchise;
    gridOptions: kendo.ui.GridOptions;
    
    static $inject = [
        "$scope",
        "coreApiResources",
        "$window",
        "$q",
        "$log"
    ];

    constructor(
        private $scope: ng.IScope,
        private coreApiResources: CoreApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService)
    {

    }

    $onInit()
    {
        var urlParts = this.$window.location.pathname.split("/");
        if (!urlParts[urlParts.length - 1]) {
            urlParts.pop();
        }

        this.franchiseId = parseInt(urlParts[urlParts.length - 1]);

        this.coreApiResources.FranchiseApi.get({ id: this.franchiseId }).$promise.then((franchise) => {
            this.franchise = franchise;
        });
    }
}
