import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

interface INewDisasterPrepProfileScope {

    concepts: CoreResources.IConcept[];
    franchisesInConcept: CoreResources.IFranchise[];

    SetFranchisesInConcept: (conceptId: number) => void;
    CreateProfile: (franchiseId: number) => void;
}

export class NewDisasterPrepProfileController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('NewDisasterPrepProfileController',
            ['$scope', 'coreApiResources', 'dppApiResources', '$window',
                ($scope: INewDisasterPrepProfileScope, coreApiResources: CoreApiResources, dppApiResources: DppApiResources, $window: ng.IWindowService) =>
                {

                    $scope.concepts = coreApiResources.ConceptApi.query({ $select: "ConceptId,ConceptCode,DisplayName" });

                    $scope.SetFranchisesInConcept = (conceptId: number) =>
                    {
                        $scope.franchisesInConcept = coreApiResources.FranchiseApi
                            .query({ $filter: `ConceptId eq ${conceptId}`, $select: "FranchiseId,DoingBusinessAs,ConceptId,LicenseNumber" });
                    }

                    $scope.CreateProfile = (franchiseId: number) =>
                    {
                        var dppProfile = new dppApiResources.DisasterPrepProfileApi();
                        dppProfile.FranchiseId = franchiseId;
                        dppProfile.$save(
                            (response) =>
                            {
                                console.log(response);
                                $window.location.href = `/DisasterPrepProfiles/Edit/${dppProfile.DppId}`;
                            },
                            (err) =>
                            {
                                console.error(err);
                                alert(err);
                            });
                    }
                }]
        );
    }
}

