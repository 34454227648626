import { RpmResources } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";

export class ManualSalesEntryErroredReportRegenerationController implements ng.IController {
    selectedFranchiseId: number;
    selectedConceptId: number;
    selectedFranchisorId: number;
    periodFilters: kendo.data.DataSourceFilter;
    readOnly: boolean;
    conceptFilters: kendo.data.DataSourceFilter;
    franchisorFilters: kendo.data.DataSourceFilter;
    additionalFilters: any;

    static $inject = [
        '$scope',
        'coreApiResources',
        'rpmApiResources',
        'identityManager',
        '$q'
    ];

    constructor(
        private $scope: ng.IScope,
        private coreApiResources: CoreApiResources,
        private rpmApiResources: RpmApiResources,
        private identityManager: IdentityManager,
        private $q: ng.IQService
    ) {
        this.selectedFranchiseId = null;
        this.selectedConceptId = null;
        this.selectedFranchisorId = null;
    }

    $onInit() {
        let adminPromise = this.identityManager.HasRole(["BatchManagementAdmin", "Admin"])
            .then(isAdmin => {
                this.readOnly = !isAdmin;
            });

        this.InitFilters();

        this.$scope.$watch(() => this.selectedFranchiseId, (newVal, oldVal) => {
            if (newVal !== oldVal && newVal) {
                this.periodFilters = <kendo.data.DataSourceFilterItem>{
                    operator: "eq",
                    field: nameof<RpmResources.IRoyaltyPeriodReport>(o => o.FranchiseId),
                    value: this.selectedFranchiseId
                };
            }
        });
    }

    InitFilters(): void {
        this.conceptFilters = <kendo.data.DataSourceFilterItem>{
            field: nameof<CoreEntities.Concept>(o => o.RoyaltySystemId),
            operator: "isnotnull"
        };

        this.franchisorFilters = <kendo.data.DataSourceFilterItem>{
            field: nameof<CoreEntities.Franchisor>(o => o.RoyaltySystemId),
            operator: "isnotnull"
        };
    }

    onConceptSelect(concept: CoreEntities.Concept): void {
        this.selectedConceptId = concept?.ConceptId;
    }

    onFranchiseSelect(franchise: CoreEntities.Franchise): void {
        this.selectedFranchiseId = franchise?.FranchiseId;
        this.selectedFranchisorId = franchise?.FranchisorId;
    }

    static BindComponent(app: ng.IModule) {
        app.component('manualSalesEntryErroredReportRegeneration', {
            bindings: {
                [nameof<ManualSalesEntryErroredReportRegenerationController>(o => o.additionalFilters)]: "<"
            },
            controller: ManualSalesEntryErroredReportRegenerationController,
            controllerAs: '$ctrl',
            templateUrl: '/Templates/RPM/manualSalesEntryErroredReportRegeneration.html'
        });
    }
}
