import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";

import { KendoUtil } from "Utility/KendoUtil";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class IndexBillingController implements ng.IController {

    isLoading: boolean;
    franchises: CoreResources.IFranchise[];
    franchiseGrid: kendo.ui.Grid;
    gridOptions: kendo.ui.GridOptions;

    permissions: { isBillingAdmin: boolean; isZeeMailAdmin: boolean };
    
    static $inject = [
        "$scope",
        "coreApiResources",
        "identityManager",
        "$window",
        "$q",
        "$log",
        "$timeout"
    ];

    constructor(
        private $scope: ng.IScope,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService)
    {

    }

    $onInit()
    {
        this.isLoading = true;

        let params: ODataQueryParameters = {
            $select: [
                nameof<CoreResources.IFranchise>(o => o.FranchiseId),
                nameof<CoreResources.IFranchise>(o => o.LicenseNumber),
                nameof<CoreResources.IFranchise>(o => o.StateAbbr),
                nameof<CoreResources.IFranchise>(o => o.DoingBusinessAs),
                nameof<CoreResources.IFranchise>(o => o.Country),
                nameof<CoreResources.IFranchise>(o => o.FranchiseeName),
                nameof<CoreResources.IFranchise>(o => o.OpeningDate),
            ].join(","),
            $expand: `${nameof<CoreResources.IFranchise>(o => o.Concept)}` +
                `($select=${nameof<CoreResources.IFranchise>(o => o.Concept.ConceptCode)})`,
            $filter: `${nameof<CoreResources.IFranchise>(o => o.Status)} ne 'Inactive'`
        }

        this.identityManager.GetBillingPermissions()
            .then((perms) =>
            {
                this.permissions = perms;
            });

        this.coreApiResources.FranchiseApi.query(params).$promise
            .then((franchises) =>
            {
                this.franchises = franchises;
                
                let mainGrid = angular.element("#billingFranchiseGrid");
                let windowEle = angular.element(this.$window);

                let gridHeight = windowEle.height() - mainGrid.offset().top - 15;

                if (gridHeight < 250) {
                    gridHeight = 250;
                }

                let filtersLoaded = false;

                this.gridOptions = {
                    dataSource: new kendo.data.DataSource({
                        data: this.franchises, 
                        pageSize: 25,
                        schema: {
                            model: {
                                id: nameof<CoreResources.IFranchise>(o => o.FranchiseId),
                                fields: {
                                    FranchiseId: {
                                        type: "number"
                                    },
                                    LicenseNumber: {
                                        type: "string"
                                    },
                                    StateAbbr: {
                                        type: "string"
                                    },
                                    DoingBusinessAs: {
                                        type: "string"
                                    },
                                    Country: {
                                        type: "string"
                                    },
                                    FranchiseeName: {
                                        type: "string"
                                    },
                                    OpeningDate: {
                                        type: "date"
                                    },
                                    "Concept.ConceptCode": {
                                        type: "string"
                                    }
                                }
                            }
                        }
                    }) ,
                    sortable: true,
                    resizable: true,
                    pageable: {
                        numeric: false,
                        pageSize: 25,
                        pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                        input: true
                    },
                    filterable: {
                        mode: "menu",
                        operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                        extra: true
                    },
                    columns: <kendo.ui.GridColumn[]>[
                        {
                            field: nameof.full<CoreResources.IFranchise>(o => o.Concept.ConceptCode),
                            title: "Concept",
                            minScreenWidth: 900,
                        }, {
                            field: nameof.full<CoreResources.IFranchise>(o => o.LicenseNumber),
                            title: "License Number",
                        }, {
                            field: nameof.full<CoreResources.IFranchise>(o => o.DoingBusinessAs),
                            title: "DBA"
                        }, {
                            field: nameof.full<CoreResources.IFranchise>(o => o.FranchiseeName),
                            title: "Franchisee"
                        }, {
                            field: nameof.full<CoreResources.IFranchise>(o => o.StateAbbr),
                            title: "State",
                            minScreenWidth: 1300
                        }, {
                            field: nameof.full<CoreResources.IFranchise>(o => o.Country),
                            title: "Country",
                            minScreenWidth: 1400
                        }, {
                            field: nameof.full<CoreResources.IFranchise>(o => o.OpeningDate),
                            title: "OpeningDate",
                            format: "{0:MMM dd, yyyy}",
                            minScreenWidth: 1500
                        }, {
                            title: "Edit Franchise",
                            template: "<div style='text-align: center;'><a class='pure-button' href='/Billing/EditFranchise\\#?franchiseId=#= FranchiseId #'>Edit: #= LicenseNumber#</a></div>",
                            sortable: false
                        }
                    ],
                    height: gridHeight,
                    dataBound: (e) => {
                        if (!filtersLoaded)
                        {
                            filtersLoaded = true;
                            KendoUtil.LoadGridFilters(e.sender);
                        }
                    },
                    filter: (e) => {
                        this.$timeout(() => {
                            KendoUtil.SaveGridFilters(e.sender);
                        }, 0);
                    }
                };
            })
            .finally(() =>
            {
                this.isLoading = false;
            });
    }

    ClearFilters()
    {
        KendoUtil.ClearSavedGridFilters(this.franchiseGrid);
        this.franchiseGrid.dataSource.filter({});
    }
}