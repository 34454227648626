let crChartAccountsControllerConfig = [
    '$scope', 'ngDialog',
    function ($scope, ngDialog)
    {

        // COA Grid Config
        $scope.coaGridDataSource = new kendo.data.DataSource({
            batch: true,
            pageSize: 20,
            schema: {
                model: {
                    id: "coaID",
                    fields: {
                        CoAID: { editable: false, nullable: true },
                        Document: { type: "string", validation: { required: true } },
                        Product: { type: "string", validation: { required: true } },
                        Account: { type: "number", validation: { required: true } },
                        Debit: { type: "boolean", validation: { required: true } },
                        Credit: { type: "boolean", validation: { required: true } },
                    }
                }
            },
            data: [{
                document: "Invoice",
                product: "Royalty Fees",
                account: 7180,
                debit: false,
                credit: true
            }, {
                document: "Debit Memo",
                product: "Royalty Fees",
                account: 7180,
                debit: false,
                credit: true
            }, {
                document: "Credit Memo",
                product: "Royalty Fees",
                account: 7180,
                debit: true,
                credit: false
            }, {
                document: "Invoice",
                product: "Royalty Fees",
                account: 7180,
                debit: true,
                credit: false
            }]
        });

        let coaColumns: Array<kendo.ui.GridColumn> =
            [{
                field: "document",
                title: "Document"
            }, {
                field: "product",
                title: "Product"
            }, {
                field: "account",
                title: "Account"
            }, {
                field: "debit",
                title: "Debit",
                template: '<input ng-model="dataItem.debit" type="checkbox" ng-change="getClick(dataItem)"></input>'
            }, {
                field: "credit",
                title: "Credit",
                template: '<input ng-model="dataItem.credit" type="checkbox" ng-change="getClick(dataItem)"></input>'
            },
            {
                command: [{ name: "edit" }, { name: "destroy" }],
                title: "&nbsp;",
                width: "250px"
            }]

        $scope.coaGridOptions = {
            columns: coaColumns,
            dataSource: $scope.coaGridDataSource,
            editable: "inline",
            sortable: true,
            pageable: true,
        };
    }];

export { crChartAccountsControllerConfig };
