import { ApiConfig } from "AppConfig/ApiConfig";
import { ODataHelperService } from "../Utility/ODataHelperService";

export class KnowledgeApiResources {
    CategoriesApi: KnowledgeResourcesApi.ICategoryApi;
    ItemsApi: KnowledgeResourcesApi.IItemApi;
    ItemTypesApi: KnowledgeResourcesApi.IItemTypeApi;
    ItemXMediaApi: KnowledgeResourcesApi.IItemXMediumApi;
    SectionsApi: KnowledgeResourcesApi.ISectionApi;

    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    ) {
        this.RefreshResources();
    }

    RefreshResources() {
        this.CategoriesApi = this.$resource<KnowledgeResources.ICategory>(
            this.apiConfig.AppSettings.KnowledgeApiRootUrl + "/Categories/:id/",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.ItemsApi = this.$resource<KnowledgeResources.IItem>(
            this.apiConfig.AppSettings.KnowledgeApiRootUrl + "/Items/:id/",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.ItemTypesApi = this.$resource<KnowledgeResources.IItemType>(
            this.apiConfig.AppSettings.KnowledgeApiRootUrl + "/ItemTypes/:id/",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.ItemXMediaApi = this.$resource<KnowledgeResources.IItemXMedium>(
            this.apiConfig.AppSettings.KnowledgeApiRootUrl + "/ItemsMedium/:id/",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.SectionsApi = this.$resource<KnowledgeResources.ISection>(
            this.apiConfig.AppSettings.KnowledgeApiRootUrl + "/Sections/:id/",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
    }

    ApproveItem(itemId: number) {
        if (!itemId) {
            throw new Error("Item or ItemId was not supplied to ApproveItem");
        }

        let config: ng.IRequestConfig = {
            method: "POST",
            url: `${this.apiConfig.AppSettings.KnowledgeApiRootUrl}/Items/approve/${itemId}`
        };

        return this.$http<KnowledgeResources.IItem>(config);
    }

    DisapproveItem(itemId: number) {
        if (!itemId) {
            throw new Error("Item or ItemId was not supplied to DisapproveItem");
        }

        let config: ng.IRequestConfig = {
            method: "POST",
            url: `${this.apiConfig.AppSettings.KnowledgeApiRootUrl}/Items/disapprove/${itemId}`
        };

        return this.$http<KnowledgeResources.IItem>(config);
    }

    UploadMediaItem(itemMedium: KnowledgeResources.IItemXMedium, filename: string, content: string) {
        if (!itemMedium || !itemMedium.ItemId || !content || !filename) {
            throw new Error("ItemId, Content, or Filename were not supplied to UploadMediaItem");
        }

        let mediaContent = content.split(",", 2);
        if (mediaContent.length != 2) {
            throw new Error("Invalid content supplied to UploadMediaItem");
        }

        return this.$http.post<KnowledgeResources.IItemXMedium>(
            this.apiConfig.AppSettings.KnowledgeApiRootUrl + '/ItemsMedium/upload',
            {
                Filename: filename,
                FileContent: mediaContent[1],
                FileType: mediaContent[0],
                ItemId: itemMedium.ItemId,
            });
    }
}