

import { NavigationLink, NavigationService } from "@DppApp/Services/NavigationService";


interface ISaveButtonsDirectiveScope extends ng.IScope {
    navigationLinks: NavigationLink[];
    saveMessage?: string;
    showMainSaveButton: boolean;
    save: () => ng.IPromise<any>;

    previousLink: NavigationLink;
    nextLink: NavigationLink;
    RefreshLinks: () => void;
    Previous: () => ng.IPromise<any>;
    Next: () => ng.IPromise<any>;
}

export class DppSaveButtonsDirective
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.directive('dppSaveButtons', ['Navigation',
            (navigation: NavigationService): ng.IDirective =>
            {
                return {
                    scope: {
                        showMainSaveButton: '=?',
                        navigationLinks: '=',
                        saveMessage: '=',
                        save: '&',
                    },
                    restrict: "E",
                    replace: false,
                    transclude: false,
                    templateUrl: '/Templates/Dpp/Directives/SaveButtons.html',
                    link: (scope: ISaveButtonsDirectiveScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, controller) =>
                    {
                        if (!angular.isDefined(scope.showMainSaveButton)) {
                            scope.showMainSaveButton = true;
                        }

                        console.log(scope.save);
                        scope.RefreshLinks = () =>
                        {
                            console.log("REFRESH CURRENT LINKS", scope.navigationLinks);
                            if (scope.navigationLinks) {
                                scope.previousLink = navigation.GetPreviousLink(scope.navigationLinks);
                                scope.nextLink = navigation.GetNextLink(scope.navigationLinks);
                            }
                        }

                        scope.RefreshLinks();
                        scope.$watch("navigationLinks", (newValue, oldValue) =>
                        {
                            console.log("Current Links watch", scope.navigationLinks);
                            scope.RefreshLinks();
                        });

                        scope.Next = () =>
                        {
                            let result = scope.save();

                            if (!result || !result.then) {
                                throw "Save function is not returning a promise.";
                            }

                            return result.then(() =>
                            {
                                navigation.Redirect(scope.nextLink.Section, scope.nextLink.DppBuildingId);
                            });
                        }

                        scope.Previous = () =>
                        {
                            let result = scope.save();

                            if (!result || !result.then) {
                                throw "Save function is not returning a promise.";
                            }

                            return result.then(() =>
                            {
                                navigation.Redirect(scope.previousLink.Section, scope.previousLink.DppBuildingId);
                            });
                        }
                    }
                };
            }]);

    }
}

