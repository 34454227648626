import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ZeeMailApiResources } from "Services/Resources/ZeeMailApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

export class ZeeMailFranchisorEnablerComponentController implements ng.IController
{
    franchisorId: number;
    isEnabled: boolean;

    enablerWindow: kendo.ui.Window;
    enablerWindowOptions: kendo.ui.WindowOptions;

    static $inject = [
        'coreApiResources',
        'zeeMailApiResources',
        'identityManager',
        'apiConfig',
        '$timeout'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private zeeMailApiResources: ZeeMailApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $timeout: ng.ITimeoutService
    )
    {
    }

    $onInit()
    {
        this.isEnabled = null;
        this.enablerWindowOptions = {
            modal: true,
        };
    }

    $postLink()
    {
    }

    $onChanges(changes: ng.IOnChangesObject)
    {

    }

    EnablerPopup()
    {
        this.enablerWindow.open();
        this.$timeout(() =>
        {
            this.enablerWindow.center();
        });
    }

    OnFranchisorSelect(franchisor: Partial<CoreEntities.Franchisor>)
    {
        this.franchisorId = franchisor && franchisor.FranchisorId;

        if (this.franchisorId)
        {
            return this.zeeMailApiResources.GetFranchisorIsZeeMailEnabled(this.franchisorId)
                .then((enabledResponse) =>
                {
                    this.isEnabled = enabledResponse.data;
                });
        }
        else
        {
            this.isEnabled = null;
        }
    }

    Save()
    {
        let isEnabled = !this.isEnabled;
        this.isEnabled = null;

        return this.zeeMailApiResources.SetFranchisorIsZeeMailEnabled(this.franchisorId, isEnabled)
            .then(() =>
            {
                this.isEnabled = isEnabled;
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("zeeMailFranchisorEnabler", {
            bindings: {
            },
            controller: ZeeMailFranchisorEnablerComponentController,
            templateUrl: "/Templates/ZeeMail/ZeeMailFranchisorEnabler.html"
        });

    }
}

