
import { AngularUtil } from "Utility/AngularUtil";
import { UrlQueryParameters } from "Utility/UrlQueryParameters";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { UriHelperService } from "Services/Utility/UriHelperService";
import { Constants } from 'Constants';

export class PasswordUpdateAccountController implements ng.IController
{
    expiredPasswordModel: IUpdateExpiredPasswordBindingModel;

    Email: string;


    OldPassword: string;
    NewPassword: string;
    NewPasswordConfirm: string;
    
    static $inject = [
        "identityManager",
        "$window",
        "$q",
        "$log",
        "$location",
        "uriHelper",
        '$http',
        "$rootScope",
    ];

    constructor(
        private identityManager: IdentityManager,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $location: ng.ILocationService,
        private uriHelper: UriHelperService,
        private $http: ng.IHttpService,
        private $rootScope: ng.IRootScopeService,
    )
    {

    }

    $onInit()
    {
       
    }


    async updatePassword(){
        let parameters = this.uriHelper.queryParameters;
        this.Email=parameters.parameters.username;
        this.expiredPasswordModel = {
            Email: parameters.parameters.username,
            OldPassword:this.OldPassword,
            NewPassword: this.NewPassword,
            NewPasswordConfirm: this.NewPasswordConfirm
        };
        return this.identityManager.UpdateExpiredPassword(this.expiredPasswordModel).then(
            (response) =>
            {
                alert("Your password has successfully been reset! Redirecting to home page");
                this.loginUser()
            }).catch((err)=>{
                let error='';

                this.$rootScope.$emit(Constants.PopupEvents.OpenPasswordErrorValidationPopupEvent, err);
                if(err?.data.Message==='The request is invalid.'){
                
                if(typeof err.data.ModelState=='object'){
                
                let modelStateKeysArray=Object.keys(err.data.ModelState);
                    modelStateKeysArray.forEach((obj)=>{
                       error=error+JSON.stringify(err.data.ModelState[obj][0])+'\n';
                        console.log(error);
                    })
                }
                }else{
                    error=err?.data.Message;
                }
               
               // alert(error)
                console.log(err);
            });
    }

    async loginUser(){
        let redirectUrl: string = "";
        return this.identityManager.PostLoginToken({
            username: this.Email,
            password: this.NewPassword,
            grantType: 'password'
        })
            .then((tokenResponse) =>
            {

                (<ILocalStorage>localStorage).FranForceAuthToken = tokenResponse.data.access_token;
                (<ILocalStorage>localStorage).FranForceAuthTokenType = tokenResponse.data.token_type;
                return this.$q.all([
                    this.$http.post<{ redirect: string }>(`/Account/Login?ReturnUrl=%2f`, tokenResponse.data)
                        .then((response) =>
                        {
                            if (response.data.redirect)
                            {
                                redirectUrl = response.data.redirect + window.location.hash;
                            }
                            else
                            {
                                redirectUrl = "/";

                            }
                        }),

                    this.identityManager.GetLoggedInUserInfo()
                        .then((userResponse) =>
                        {
                            (<ILocalStorage>localStorage).AccountEmail = userResponse.data.Email;
                            (<ILocalStorage>localStorage).PassWordExpirationDate = userResponse.data.PassWordExpirationDate;
                        })
                ]);
            })
            .then((response) =>
            {
                window.location.href = redirectUrl;
            })
            .catch((tokenResponse) =>
            {
               
                console.log('Error: ', tokenResponse.data.error_description);
            })
            .finally(function ()
            {
                console.log('This finally block')
            });
    }
    

}
