import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { MerchantApiResources } from "Services/Resources/MerchantApiResources";
import { UrlQueryParameters } from "Utility/UrlQueryParameters";
import { AngularUtil } from "Utility/AngularUtil";
import { MerchantResources } from "Interfaces/FranForce/Merchant/MerchantResources";

export class EditGiftCertificateController implements ng.IController {
    
    giftCertificateId: string;
    giftCertificateSuffix: string;
    giftCertificate: MerchantResources.IGiftCertificate;
    
    static $inject = [
        "$scope",
        "coreApiResources",
        "merchantApiResources",
        "$q",
        "$log"
    ];

    constructor(
        private $scope: ng.IScope,
        private coreApiResources: CoreApiResources,
        private merchantApiResources: MerchantApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService
    )
    {
        this.$scope = $scope;
        this.coreApiResources = coreApiResources;
        this.merchantApiResources = merchantApiResources;
        this.$q = $q
        this.$log = $log;
    }

    $onInit() {
        let gcId : string = new UrlQueryParameters(window).GetValue("cid");
        if (gcId && gcId.indexOf("-") !== -1)
        {
            let splitId = gcId.split("-");
            this.giftCertificateId = splitId[0];
            this.giftCertificateSuffix = splitId[1];
        }
    }

    GetCert()
    {
        return this.merchantApiResources.GiftCertificateApi.get({ id: this.giftCertificateId }).$promise
            .then((gc) => {
                this.giftCertificate = gc;
            });
    }

    SaveEdits()
    {
        if (!this.giftCertificate)
        {
            return this.$q.reject();
        }

        if (confirm("Are you sure you want to edit this gift certificate?"))
        {
            return this.merchantApiResources.GiftCertificateApi.update({id: this.giftCertificate.GiftCertificateId}, this.giftCertificate).$promise
                .then((response) => {
                    return this.GetCert();
                },
                AngularUtil.GetErrorPromiseLogAlertCallback<ng.IHttpPromiseCallbackArg<any>>(this.$log, this.$q, (err) => {
                    return err.data.Message;
                }));
        }
    }

}