import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { Helpers } from "Utility/Helpers";
import { IEditDisasterPrepProfileSectionScope } from "@DppApp/EditDisasterPrepProfileSectionController";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";

interface IEditDisasterPrepProfileBuildingInsuranceScope extends IEditDisasterPrepProfileSectionScope {
    dppBuildingAssetCategoryId: number;
    insurance: DppResources.IDisasterPrepProfileBuildingInsurance;
    SaveInsurance: (insurance: DppResources.IDisasterPrepProfileBuildingInsurance) => void;
}

export class EditDisasterPrepProfileBuildingInsuranceController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('EditDisasterPrepProfileBuildingInsuranceController',
            [
                '$scope', 'dppApiResources',
                ($scope: IEditDisasterPrepProfileBuildingInsuranceScope, dppApiResources: DppApiResources) =>
                {
                    if (!$scope.dppBuildingId) {
                        $scope.RedirectHome();
                        return;
                    }

                    $scope.dppBuildingAssetCategoryId = 2;

                    let promise = dppApiResources.DisasterPrepProfileBuildingInsuranceApi.get({ id: $scope.dppBuildingId },
                        (insurance: DppResources.IDisasterPrepProfileBuildingInsurance) =>
                        {
                            $scope.insurance = insurance;
                        },
                        (err: ng.IHttpPromiseCallbackArg<any>) =>
                        {
                            console.error(err);
                            if (err.status === 404) {
                                $scope.insurance = new dppApiResources.DisasterPrepProfileBuildingInsuranceApi();
                            }
                            else {
                                alert("Could not get the insurance for this building.");
                            }
                        }).$promise;

                    $scope.QueueLoadingPromises(promise);

                    $scope.SaveInsurance = () =>
                    {
                        $scope.insurance.CarrierAfterHoursPhone = Helpers.StripNonNumerices($scope.insurance.CarrierAfterHoursPhone);

                        if ($scope.insurance.DppBuildingId) {
                            return $scope.insurance.$update({ id: $scope.insurance.DppBuildingId },
                                (response) =>
                                {
                                    console.log(response);
                                },
                                (err) =>
                                {
                                    console.error(err);
                                });
                        }
                        else {
                            $scope.insurance.DppBuildingId = $scope.dppBuildingId;
                            return $scope.insurance.$save((response) =>
                            {
                                console.log(response);
                            },
                                (err) =>
                                {
                                    console.error(err);
                                });
                        }
                    };

                }
            ]);
    }
}

