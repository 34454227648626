import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyManualSalesEntryDetailRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyManualSalesEntryDetailsByRoyaltyManualSalesEntry(royaltyManualSalesEntryId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyManualSalesEntryDetails/ByRoyaltyManualSalesEntry/" + royaltyManualSalesEntryId
        };

        return this.$http<RpmEntities.RoyaltyManualSalesEntryDetailViewModel[]>(config);
    }
   
    CreateRoyaltyManualSalesEntryDetail(model: RpmEntities.RoyaltyManualSalesEntryDetailViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyManualSalesEntryDetails",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyManualSalesEntryDetail(id: number, model: RpmEntities.RoyaltyManualSalesEntryDetailViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyManualSalesEntryDetails/" + id,
            data: model
        };

        return this.$http<void>(config);
    }
}