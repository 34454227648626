import { LoanAmortizationSchedulesRequestModel } from "../../Models/LoanAmortization/LoanAmortizationSchedulesRequestModel";
import { LoanInterestRecordDataModel } from "../../Models/LoanAmortization/LoanInterestRecordDataModel";
import { BillingApiResources } from "../../Services/Resources/BillingApiResources";
import { LoanAmortizationSchedulesReponseModel } from "../../Models/LoanAmortization/LoanAmortizationSchedulesReponseModel";

export class LoanAmortizationComponentController implements ng.IController {

    isLoading: boolean;

    originationDate: Date;
    firstPrincipalPaymentDate: Date;
    termDue: number;
    maturityDate: Date;
    loanAmount: number;
    paymentAmount: number;
    interestRate: number;
    effectiveDate: Date;

    loanTemplateName: string = 'ASV STD TEMPLATE';

    validationMessage: string;
    showValidationMessage: boolean;

    loanAmortizationSchedulesGridDataSource: kendo.data.DataSource;
    loanAmortizationSchedulesGridOptions: kendo.ui.GridOptions;
    loanAmortizationSchedulesGrid: kendo.ui.Grid;
    showLoanAmortizationSchedulesGrid: boolean;

    static $inject = [
        'billingApiResources'
    ];

    constructor(
        private billingApiResources: BillingApiResources
    ) {

    }

    $onInit() {
        this.ResetAllValues();
    }

    ResetAllValues() {
        this.isLoading = false;
        this.ValidationsOnSubmit(false, "");
        this.originationDate = null;
        this.firstPrincipalPaymentDate = null;
        this.termDue = 0;
        this.maturityDate = null;
        this.loanAmount = 0;
        this.paymentAmount = 0;
        this.interestRate = 0;
        this.effectiveDate = null;
        this.showLoanAmortizationSchedulesGrid = false;
    }

    ResetAllBtnClick() {
        if (confirm("Are you sure you want to reset all the data entered?") == false) {
            return;
        }
        else {
            this.ResetAllValues();
        }
    }

    Submit() {

        this.ValidationsOnSubmit(false, "")

        if (!this.originationDate) {
            this.ValidationsOnSubmit(true, "Please select \"Origination Date\".");
            this.showLoanAmortizationSchedulesGrid = false;
            return false;
        }

        if (!this.firstPrincipalPaymentDate) {
            this.ValidationsOnSubmit(true, "Please select \"First Principal Payment Date\".");
            this.showLoanAmortizationSchedulesGrid = false;
            return false;
        }

        if (this.termDue == null || this.termDue == undefined) {
            this.ValidationsOnSubmit(true, "Please enter \"Term Due\".");
            this.showLoanAmortizationSchedulesGrid = false;
            return false;
        }

        if (!this.maturityDate) {
            this.ValidationsOnSubmit(true, "Please select \"Maturity Date\".");
            this.showLoanAmortizationSchedulesGrid = false;
            return false;
        }

        if (this.loanAmount == null || this.loanAmount == undefined) {
            this.ValidationsOnSubmit(true, "Please enter \"Loan Amount\".");
            this.showLoanAmortizationSchedulesGrid = false;
            return false;
        }

        if (this.paymentAmount == null || this.paymentAmount == undefined) {
            this.ValidationsOnSubmit(true, "Please enter \"Payment Amount\".");
            this.showLoanAmortizationSchedulesGrid = false;
            return false;
        }

        if (this.interestRate == null || this.interestRate == undefined) {
            this.ValidationsOnSubmit(true, "Please enter \"Interest Rate\".");
            this.showLoanAmortizationSchedulesGrid = false;
            return false;
        }

        if (!this.effectiveDate) {
            this.ValidationsOnSubmit(true, "Please select \"Effective Date\".");
            this.showLoanAmortizationSchedulesGrid = false;
            return false;
        }

        this.isLoading = true;
        
        let originationDate = new Date(this.originationDate);
        let firstPrincipalPaymentDate = new Date(this.firstPrincipalPaymentDate)

        let originationDateISO = originationDate.toISOString();
        let processingStartDateISO = originationDate.toISOString()
        let interestAccruedThruDateISO = new Date(new Date(originationDate).setDate(new Date(originationDate).getDate() - 1)).toISOString();
        let firstPrincipalPaymentDateISO = firstPrincipalPaymentDate.toISOString();
        let firstPrincipalPaymentDayValue = parseInt(firstPrincipalPaymentDateISO.split('T')[0].split("-")[2]);
        let firstInterestPaymentDateISO = firstPrincipalPaymentDate.toISOString();
        let firstInterestPaymentDayValue = parseInt(firstInterestPaymentDateISO.split('T')[0].split("-")[2])
        let nextPrincipalPaymentDateISO = firstPrincipalPaymentDate.toISOString();
        let nextInterestPaymentDateISO = firstPrincipalPaymentDate.toISOString();
        let termType = "Payment";
        let term = this.termDue;
        let termDue = this.termDue;
        let maturityDateISO = new Date(this.maturityDate).toISOString();
        let loanAmount = this.loanAmount;
        let paymentAmount = this.paymentAmount;
        let effectiveDateISO = new Date(this.effectiveDate).toISOString();
        let loanInterestRecord: LoanInterestRecordDataModel = {
            interestType: 0,
            interestRate: this.interestRate,
            effectiveDate: effectiveDateISO
        }

        let loanAmortizationSchedulesRequestModel: LoanAmortizationSchedulesRequestModel = {
            loanTemplateName: this.loanTemplateName,
            originationDate: processingStartDateISO,
            processingStartDate: originationDateISO,
            interestAccruedThruDate: interestAccruedThruDateISO,
            firstPrincipalPaymentDate: firstPrincipalPaymentDateISO,
            firstPrincipalPaymentDayValue: firstPrincipalPaymentDayValue,
            firstInterestPaymentDate: firstInterestPaymentDateISO,
            firstInterestPaymentDayValue: firstInterestPaymentDayValue,
            nextPrincipalPaymentDate: nextPrincipalPaymentDateISO,
            nextInterestPaymentDate: nextInterestPaymentDateISO,
            termType: termType,
            term: term,
            termDue: termDue,
            maturityDate: maturityDateISO,
            loanAmount: loanAmount,
            paymentAmount: paymentAmount,
            loanInterestRecord: loanInterestRecord
        }

        this.billingApiResources.ProcessLoanAmortizationSchedule("NortridgeServices", loanAmortizationSchedulesRequestModel)
            .then((results) => {
                this.isLoading = false;
                this.showLoanAmortizationSchedulesGrid = true;
                var data: LoanAmortizationSchedulesReponseModel[] = results.data;

                data.forEach(x => {
                    let principalAmount = Number((Math.round((x.paymentAmount - x.interestAmount - x.otherAmount) * 100) / 100).toFixed(2));
                    x.principalAmount = principalAmount;
                })

                this.LoadLoanAmortizationSchedulesGridData(data);
            })
            .catch((err) => {
                this.isLoading = false;
                console.log("Error! Failed to load loan amortization schedue data.", err);
                this.ValidationsOnSubmit(true, "Error! Failed to load loan amortization schedue data.");
                this.showLoanAmortizationSchedulesGrid = false;
            })
    }

    exportGridWithTemplatesContent(e) {
        var data = e.data;
        var gridColumns = e.sender.columns;
        var sheet = e.workbook.sheets[0];
        var visibleGridColumns = [];
        var columnTemplates = [];
        var dataItem;
        // Create element to generate templates in.
        var elem = document.createElement('div');

        // Get a list of visible columns
        for (var i = 0; i < gridColumns.length; i++) {
            if (!gridColumns[i].hidden) {
                visibleGridColumns.push(gridColumns[i]);
            }
        }

        // Create a collection of the column templates, together with the current column index
        for (var i = 0; i < visibleGridColumns.length; i++) {
            if (visibleGridColumns[i].template) {
                columnTemplates.push({ cellIndex: i, template: kendo.template(visibleGridColumns[i].template) });
            }
        }

        // Traverse all exported rows.
        for (var i = 1; i < sheet.rows.length; i++) {
            var row = sheet.rows[i];
            // Traverse the column templates and apply them for each row at the stored column position.

            // Get the data item corresponding to the current row.
            var dataItem = data[i - 1];
            for (var j = 0; j < columnTemplates.length; j++) {
                var columnTemplate = columnTemplates[j];
                // Generate the template content for the current cell.
                elem.innerHTML = columnTemplate.template(dataItem);
                if (row.cells[columnTemplate.cellIndex] != undefined)
                    // Output the text content of the templated cell into the exported cell.
                    row.cells[columnTemplate.cellIndex].value = elem.textContent || elem.innerText || "";
            }
        }
    }

    LoadLoanAmortizationSchedulesGridData(data: LoanAmortizationSchedulesReponseModel[]) {
        var dataSource = new kendo.data.DataSource({
            data: data,
            page: 1,
            pageSize: 10
        });
        if (this.loanAmortizationSchedulesGrid) {

            this.loanAmortizationSchedulesGrid.setDataSource(dataSource);
            this.loanAmortizationSchedulesGrid.dataSource.page(1);
            this.loanAmortizationSchedulesGrid.dataSource.pageSize(10);
            this.loanAmortizationSchedulesGrid.refresh();
            this.loanAmortizationSchedulesGrid.resize();
        }
        else {
            let infoColumns: Array<kendo.ui.GridColumn> = [
                {
                    field: nameof<LoanAmortizationSchedulesReponseModel>(o => o.paymentDate),
                    template: "#= kendo.toString(kendo.parseDate(paymentDate, 'yyyy-MM-dd'), 'MM/dd/yyyy') #",
                    title: "Payment Date"
                },
                {
                    field: nameof<LoanAmortizationSchedulesReponseModel>(o => o.principalAmount),
                    title: "Principal Amount"
                },
                {
                    field: nameof<LoanAmortizationSchedulesReponseModel>(o => o.interestAmount),
                    title: "Interest Amount"
                },
                {
                    field: nameof<LoanAmortizationSchedulesReponseModel>(o => o.otherAmount),
                    title: "Other Amount"
                },
                {
                    field: nameof<LoanAmortizationSchedulesReponseModel>(o => o.paymentAmount),
                    title: "Payment Amount"
                },
                {
                    field: nameof<LoanAmortizationSchedulesReponseModel>(o => o.balanceAmount),
                    title: "Balance Amount"
                },
                {
                    field: nameof<LoanAmortizationSchedulesReponseModel>(o => o.paymentNumber),
                    title: "Payment Number"
                }
            ];
            this.loanAmortizationSchedulesGridDataSource = new kendo.data.DataSource({
                page: 1,
                pageSize: 10,
                schema: {
                    model: {
                        fields: {
                            paymentDate: { type: "date" },
                            principalAmount: { type: "number" },
                            interestAmount: { type: "number" },
                            otherAmount: { type: "number" },
                            paymentAmount: { type: "number" },
                            balanceAmount: { type: "number" },
                            paymentNumber: { type: "number" }
                        }
                    }
                }
            });
            this.loanAmortizationSchedulesGridOptions = {
                dataBound: function () {
                    var grid = this;
                    setTimeout(function () {
                        grid.resize();
                        grid.refresh();
                    });
                },
                columns: infoColumns,
                toolbar: [
                    {
                        template: `<button class='loanAmortizationExportToExcelBtn' ng-click='$ctrl.${nameof(this.DownloadLoanAmortizationScheduleToExcel)}()'>
                                    <span style="display: block">Export To Excel</span>
                                </button>`
                    }
                ],
                excel: {
                    fileName: `LoanAmortizationSchedule-` +
                        `${new Date().getFullYear()}_` +
                        `${new Date().getMonth() + 1 < 10 ? "0" : ""}${new Date().getMonth() + 1}_` +
                        `${new Date().getDate() < 10 ? "0" : ""}${new Date().getDate()}.xlsx`,
                    allPages: true
                },
                excelExport: this.exportGridWithTemplatesContent,
                dataSource: dataSource,
                sortable: true,
                scrollable: true,
                resizable: true,
                pageable: {
                    numeric: false,
                    pageSize: 10,
                    pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                    input: true
                },
            };
        }
    }

    DownloadLoanAmortizationScheduleToExcel() {
        if (this.loanAmortizationSchedulesGrid != null && this.loanAmortizationSchedulesGrid != undefined)
            this.loanAmortizationSchedulesGrid.saveAsExcel();
    }

    OnFirstPrincipalPaymentDateChange() {
        var firstPrincipalPaymentDate = new Date(this.firstPrincipalPaymentDate);
        firstPrincipalPaymentDate.setMonth(firstPrincipalPaymentDate.getMonth() - 1);
        firstPrincipalPaymentDate.setDate(firstPrincipalPaymentDate.getDate() - 1);
        this.effectiveDate = firstPrincipalPaymentDate;      
    }

    OnOriginationDateChange() {
        this.setMaturityDate();
    }

    OnTermDueChange() {
        this.setMaturityDate();
    }

    OnPaymentAmountChange() {
        this.setMaturityDate();
    }

    OnLoanAmountChange() {}

    OnInterestRateChange() {}

    setMaturityDate() {
        if (this.originationDate) {
            this.maturityDate = new Date(this.originationDate);
            if (this.paymentAmount > 0) {
                this.maturityDate.setMonth(this.maturityDate.getMonth() + 36);
            }
            else if (this.paymentAmount <= 0) {
                this.maturityDate.setMonth(this.maturityDate.getMonth() + this.termDue);
            }
        }
        else {
            this.maturityDate = null;
        }
    }

    ValidationsOnSubmit(show: boolean, message: string) {
        this.showValidationMessage = show;
        this.validationMessage = message;
    }

    static BindComponent(app: ng.IModule) {
        app.component("loanAmortization", {
            bindings: {

            },
            templateUrl: '/Templates/LoanAmortization/LoanAmortization.html',
            controller: LoanAmortizationComponentController
        });
    }
}