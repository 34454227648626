import { ApiConfig } from "AppConfig/ApiConfig";
import { processSalesReportDataModel } from "../../Models/Royalty/ManualSalesEntry/ProcessSalesReportDataModel";
import { uploadSalesReportDataModel } from "../../Models/Royalty/ManualSalesEntry/UploadSalesReportDataModel";
import { uploadPeriodReportDataModel } from "../../Models/Royalty/PeriodReport/UploadPeriodReportDataModel";
import { franchiseEntityRelationshipGridDataModel } from "../../Models/Royalty/FranchiseEntities/FranchiseEntityRelationshipGridDataModel";
import { franchiseEntityRelationshipDeatilDataModel } from "../../Models/Royalty/FranchiseEntities/FranchiseEntityRelationshipDetailDataModel";
import { franchiseEntityRelationshipDataModel } from "../../Models/Royalty/FranchiseEntities/FranchiseEntityRelationshipDataModel";
import { franchiseEntityDeleteResponseDataModel } from "../../Models/Royalty/FranchiseEntities/FranchiseEntityDeleteResponseDataModel";
import { franchiseEntityRelationshipPeriodReportDataModel } from "../../Models/Royalty/PeriodReport/FranchiseEntityRelationshipPeriodReportDataModel";
import { manualSalesEntryProcessParameterRequestModel } from "../../Models/Royalty/ManualSalesEntry/ManualSalesEntryProcessParameterRequestModel";
import { franchisorDataModel } from "../../Models/Royalty/Franchisor/FranchisorDataModel";
import { royaltyReportBatchDataModel } from "../../Models/Royalty/ManualSalesEntry/RoyaltyReportBatchDataModel";
import { royaltyContractRatePlanMinimumAppliedMonthDataModel } from "../../Models/Royalty/RoyaltyContractRatePlanMinimumAppliedMonth/RoyaltyContractRatePlanMinimumAppliedMonthDataModel";
import { disclosureDocumentDataModel } from "../../Models/Royalty/DisclosureDocument/DisclosureDocumentDataModel";
import { royaltyContractDataModel } from "../../Models/Royalty/RoyaltyContract/RoyaltyContractDataModel";
import { royaltyScaleCalculationTypeDataModel } from "../../Models/Royalty/RoyaltyScaleCalculationType/RoyaltyScaleCalculationTypeDataModel";
import { royaltyDisclosureDocumentRatePlanMinimumMonthsAppliedDataModel } from "../../Models/Royalty/RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied/RoyaltyDisclosureDocumentRatePlanMinimumMonthsAppliedDataModel";
import { royaltyDisclosureDocumentRollInMinimumMonthsAppliedDataModel } from "../../Models/Royalty/RoyaltyDisclosureDocumentRollInMinimumMonthsApplied/RoyaltyDisclosureDocumentRatePlanMinimumMonthsAppliedDataModel";
import { franchiseDataModel } from "../../Models/Royalty/Franchise/FranchiseDataModel";
import { createPosTransactionSyncInvoicesDataModel } from "../../Models/Royalty/PosTransaction/CreatePosTransactionSyncInvoicesDataModel";
import { royaltySummaryInvoiceDisplayTypeDataModel } from "../../Models/Royalty/RoyaltySummaryInvoiceDisplayType/RoyaltySummaryInvoiceDisplayTypeDataModel";
import { royaltySystemDataModel } from "../../Models/Royalty/RoyaltySystemType/RoyaltySystemDataModel";
import { royaltyFranchisorMinimumFeeIncreasesAndCapModel } from "../../Models/Royalty/FranchisorRoyaltyManagement/RoyaltyFranchisorMinimumFeeIncreasesAndCapModel";
import { royaltyFranchiseAdFeeCapModel } from "../../Models/Royalty/FranchisorRoyaltyManagement/RoyaltyFranchiseAdFeeCapModel";
import { createRoyaltyFranchisorMinimumFeeIncreasesAndCapsModel } from "../../Models/Royalty/FranchisorRoyaltyManagement/CreateRoyaltyFranchisorMinimumFeeIncreasesAndCapsModel";
import { royaltyInvoiceReversalFileDataModel } from "../../Models/Royalty/RoyaltyBatchManagement/RoyaltyInvoiceReversalFileDataModel";
import { royaltyInvoiceExportVoidRequestDataModel } from "../../Models/Royalty/RoyaltyBatchManagement/RoyaltyInvoiceExportVoidRequestDataModel";
import { fddYearDataModel } from "../../Models/Royalty/DisclosureDocument/FddYearDataModel";
import { franchiseLocationStateDataModel } from "../../Models/Royalty/Franchise/FranchiseLocationStateDataModel";
import { rmsSearchRequestDataModel } from "../../Models/Royalty/RmsSearch/RmsSearchRequestDataModel";
import { rmsSearchResultDataModel } from "../../Models/Royalty/RmsSearch/RmsSearchResultDataModel";
import { reportingPeriodsDataModel } from "../../Models/Royalty/FranchisorRoyaltyManagement/ReportingPeriods/ReportingPeriodsDataModel";
import { contractTerritoryTypeDataModel } from "../../Models/Royalty/ContractTerritoryType/ContractTerritoryTypeDataModel";
import { RpmResources } from "Interfaces/FranForce/Rpm/RpmResources";
import { AdFeeCapOptions } from "../../Directives/Rpm/ContractManagement";
import { royaltyPeriodReportModel } from "../../Models/Royalty/RoyaltyPeriodReport/RoyaltyPeriodReportModel"
import { RoyaltyContractNotesDtoPagedResult } from "../../Models/Royalty/RoyaltyContractNotes/RoyaltyContractNotesDataModel";

export class RoyaltyApiResources {

    static $inject = [
        "$http",
        "apiConfig"
    ];

    constructor(
        private $http: ng.IHttpService,
        private apiConfig: ApiConfig
    ) {
    }

    GenerateUUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    };

    //get tepmlate types
    GetTemplateTypes(): ng.IHttpPromise<any> {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/salesfiletemplates",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any>(config);
    }

    //download template by Id
    DownloadTemplateById(templateId: number): ng.IHttpPromise<any> {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/salesfiletemplates/" + templateId + "/download",
            responseType: "blob",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any>(config);
    }

    //get reports data for processing
    GetDataForReportsProcessing(franchisorId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/manualsalesentries/availableforprocess/" + franchisorId,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any>(config);
    }

    //process report data
    ProcessSalesReportData(model: manualSalesEntryProcessParameterRequestModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + `/api/manualsalesentries/process`,
            data: model,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<string>(config);
    }

    //upload report
    UploadSalesReport(franchisorId: number, files: any, selectedTemplateType: number) {
        let urlString = "";
        if (selectedTemplateType == 1) {
            urlString = `/api/manualsalesentries/upload`;
        }
        else {
            urlString = `/api/periodreports/upload`;
        }
        const formData = new FormData();
        formData.append('FranchisorId', franchisorId.toString());
        files.forEach(item => {
            formData.append('Files', item);
        })
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + urlString,
            data: formData,
            headers: { 'Content-Type': undefined, 'X-Correlation-Id': this.GenerateUUID() }
        };

        return selectedTemplateType == 1 ? this.$http<uploadSalesReportDataModel>(config) : this.$http<uploadPeriodReportDataModel>(config);
    }

    //get all active franchise entity relationship types
    GetActiveFranchiseEntityRelationshipTypes(activeOnly: boolean) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.FranchiseConfigUrl + "/api/franchise-Entity-relationship-types?activeOnly=" + activeOnly,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any>(config);
    }

    //get all active franchise entity applications
    GetActiveFranchiseEntityApplications(activeOnly: boolean) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.FranchiseConfigUrl + "/api/franchise-Entity-relationship-applications?activeOnly=" + activeOnly,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any>(config);
    }

    //get all active franchise entities
    GetActiveFranchiseEntities(conceptId: number, activeOnly: boolean) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.FranchiseConfigUrl + "/api/concepts/" + conceptId + "/active-franchise-entities",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any>(config);
    }

    //get all franchises by franchiseEntityId
    GetAllFranchisesByFranchiseEntityId(franchiseEntityId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/franchiseentities/" + franchiseEntityId + "/relatableroyaltyfranchises",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any>(config);
    }

    //get franchise entity relationships details
    GetFranchiseEntityRelationshipDetails(franchiseEntityId: number, activeOnly: boolean) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.FranchiseConfigUrl + "/api/franchise-entities/" + franchiseEntityId + "/franchise-entity-relationship-aggregates?activeOnly=" + activeOnly,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<franchiseEntityRelationshipGridDataModel[]>(config);
    }

    //get franchise entity relationships details by FranchisorRelationshipId
    GetFranchiseEntityRelationshipDetailsByFranchiseEntityRelationshipId(franchiseEntityRelationshipId: number, activeOnly: boolean) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.FranchiseConfigUrl + "/api/franchise-entity-relationship-aggregates/" + franchiseEntityRelationshipId + "?activeOnly=" + activeOnly,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<franchiseEntityRelationshipDataModel>(config);
    }

    //Create franchise entity relationship with details
    CreateFranchiseEntityRelationship(franchiseEntityId: number, franchiseEntityRelationshipDeatilDataModel: franchiseEntityRelationshipDeatilDataModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.FranchiseConfigUrl + "/api/franchise-entities/" + franchiseEntityId + "/franchise-entity-relationship-aggregates",
            data: franchiseEntityRelationshipDeatilDataModel,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<franchiseEntityRelationshipDeatilDataModel>(config);
    }

    //delete
    DeleteByFranchiseEntityRelationshipId(franchiseEntityRelationshipId: number) {
        let config: ng.IRequestConfig = {
            method: "DELETE",
            url: this.apiConfig.AppSettings.FranchiseConfigUrl + "/api/franchise-entity-relationship-aggregates/" + franchiseEntityRelationshipId,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<franchiseEntityDeleteResponseDataModel>(config);
    }

    //Update franchise entity relationship with details
    UpdateFranchiseEntityRelationship(franchiseEntityRelationshipId: number, franchiseEntityRelationshipDeatilDataModel: franchiseEntityRelationshipDeatilDataModel) {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.apiConfig.AppSettings.FranchiseConfigUrl + "/api/franchise-entity-relationship-aggregates/" + franchiseEntityRelationshipId,
            data: franchiseEntityRelationshipDeatilDataModel,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<franchiseEntityRelationshipDeatilDataModel>(config);
    }

    //get franchise entity relationships for royalty period report by royaltyPeriodReportId
    GetFranchiseEntityRelationshipForRoyaltyPeriod(royaltyPeriodReportId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/periodreports/" + royaltyPeriodReportId + "/periodentityrelationships",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<franchiseEntityRelationshipPeriodReportDataModel>(config);
    }

    GetFranchisorById(franchisor_id: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty_franchisors/" + franchisor_id,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<franchisorDataModel>(config);
    }

    SetFranchisorRoyaltyFields(franchisor_id: number, royaltySystemId: number | null, royaltyRunDayOfWeek: number | null) {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty_franchisors/" + franchisor_id,
            data: {
                franchisorId: franchisor_id,
                royaltySystemId: royaltySystemId,
                royaltyRunDayOfWeek: royaltyRunDayOfWeek
            },
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<franchisorDataModel>(config);
    }

    GetRoyaltyReportBatchAggregates(royaltyReportBatchId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royaltyreportbatchaggregates/" + royaltyReportBatchId,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<royaltyReportBatchDataModel>(config);
    }

    GetRoyaltyContractRatePlanMinimumAppliedMonth(royaltyContractRatePlanMinimumId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royaltycontractrateplanminimums/" + royaltyContractRatePlanMinimumId + "/royaltycontractrateplanminimumappliedmonths",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<royaltyContractRatePlanMinimumAppliedMonthDataModel[]>(config);
    }

    CopyDisclosureDocument(disclosureDocumentId: number) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/disclosuredocuments/" + disclosureDocumentId + "/copy",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<disclosureDocumentDataModel>(config);
    }

    CopyDisclosureDocumentDynamic(disclosureDocumentId: number) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/disclosuredocuments/" + disclosureDocumentId + "/copy/Dynamic",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<disclosureDocumentDataModel>(config);
    }

    CopyContractToNewFranchiseWithParameters(params: {
        royaltyContractId: number,
        franchiseId: number,
        adFeeCapOption: AdFeeCapOptions,
        adFeeCapAmount: number
    }) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: `${this.apiConfig.AppSettings.RoyaltyRootUrl}/api/royaltycontracts/copy`,
            headers: { 'X-Correlation-Id': this.GenerateUUID() },
            data: params
        };

        return this.$http<royaltyContractDataModel>(config);
    }

    CopyContractToNewFranchiseWithParametersDynamic(params: {
        royaltyContractId: number,
        franchiseId: number,
        adFeeCapOption: AdFeeCapOptions,
        adFeeCapAmount: number
    }) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: `${this.apiConfig.AppSettings.RoyaltyRootUrl}/api/royaltycontracts/copy/Dynamic`,
            headers: { 'X-Correlation-Id': this.GenerateUUID() },
            data: params
        };

        return this.$http<royaltyContractDataModel>(config);
    }

    CopyContractToNewFranchise(royaltyContractId: number, franchiseeId: number) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: `${this.apiConfig.AppSettings.RoyaltyRootUrl}/api/royalty-contracts/${royaltyContractId}/${franchiseeId}/copy`,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<royaltyContractDataModel>(config);
    }

    CopyContractByContractId(royaltyContractId: number) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royaltycontracts/" + royaltyContractId + "/copy",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<royaltyContractDataModel>(config);
    }

    GetRoyaltyScaleCalculationTypes() {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royaltyscalecalculationtypes",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<royaltyScaleCalculationTypeDataModel[]>(config);
    }

    GetRoyaltySummaryInvoiceDisplayTypes() {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royaltysummaryinvoicedisplaytypes",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<royaltySummaryInvoiceDisplayTypeDataModel[]>(config);
    }

    GetRoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied(royaltyDisclosureDocumentRatePlanMinimumId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royaltydisclosuredocumentrateplanminimums/" + royaltyDisclosureDocumentRatePlanMinimumId + "/royaltydisclosuredocumentrateplanminimummonthsapplied",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<royaltyDisclosureDocumentRatePlanMinimumMonthsAppliedDataModel[]>(config);
    }

    GetRoyaltyDisclosureDocumentRollInMinimumMonthsApplied(royaltyDisclosureDocumentRollInMinimumId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royaltydisclosuredocumentrollinminimums/" + royaltyDisclosureDocumentRollInMinimumId + "/royaltydisclosuredocumentrollinminimummonthsapplied",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<royaltyDisclosureDocumentRollInMinimumMonthsAppliedDataModel[]>(config);
    }

    GetFranchiseById(franchiseId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/franchise/" + franchiseId,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<franchiseDataModel>(config);
    }

    ProcessPosTranscations(createPosTransactionSyncInvoicesDataModel: createPosTransactionSyncInvoicesDataModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royaltyreportbatches/generateavaliablesyncinvoices",
            data: createPosTransactionSyncInvoicesDataModel,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<number>(config);
    }

    GetRoyaltySystemTypes(activeOnly: boolean) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-system-types?activeOnly=" + activeOnly,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<royaltySystemDataModel[]>(config);
    }

    RoyaltyFranchisorMinimumFeeIncreasesAndCapsByFranchisor(franchisorId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/franchisors/" + franchisorId + "/royalty-franchisor-minimum-fee-increases-and-caps",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<royaltyFranchisorMinimumFeeIncreasesAndCapModel[]>(config);
    }

    GetFranchiseAdFeeCapInfo(franchisorId: number, year: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/franchisors/" + franchisorId + "/years/" + year + "/royalty-franchise-adFeeCaps",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<royaltyFranchiseAdFeeCapModel[]>(config);
    }

    GetFranchiseAdFeeCapInfoForNewYear(franchisorId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/franchisors/" + franchisorId + "/royalty-franchise-adFeeCaps",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<royaltyFranchiseAdFeeCapModel[]>(config);
    }

    CreateRoyaltyFranchisorMinimumFeeIncreasesAndCaps(franchisorId: number, newlyAddedYear: number, newlyAddedCapFee: number) {
        var createRoyaltyFranchisorMinimumFeeIncreasesAndCapsData = {};
        createRoyaltyFranchisorMinimumFeeIncreasesAndCapsData[`${nameof<createRoyaltyFranchisorMinimumFeeIncreasesAndCapsModel>(o => o.adFeeCap)}`] = newlyAddedCapFee;
        createRoyaltyFranchisorMinimumFeeIncreasesAndCapsData[`${nameof<createRoyaltyFranchisorMinimumFeeIncreasesAndCapsModel>(o => o.franchisorId)}`] = franchisorId;
        createRoyaltyFranchisorMinimumFeeIncreasesAndCapsData[`${nameof<createRoyaltyFranchisorMinimumFeeIncreasesAndCapsModel>(o => o.yearIncreased)}`] = newlyAddedYear

        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-franchise-adFeeCaps",
            data: createRoyaltyFranchisorMinimumFeeIncreasesAndCapsData,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<boolean>(config);
    }

    GetAllFddYears() {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/disclosure-documents/years",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<fddYearDataModel[]>(config);
    }

    GetFranchiseLocationStates() {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/states",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<franchiseLocationStateDataModel[]>(config);
    }

    GetRoyaltyReportPeriodReportReverseInvoices(periodReportIdArray: number[]) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-invoice-export-batches/reverse",
            data: periodReportIdArray,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<royaltyInvoiceReversalFileDataModel[]>(config);
    }

    SetRoyaltyInvoiceExportToVoided(royaltyInvoiceExportVoidRequestData: royaltyInvoiceExportVoidRequestDataModel) {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-invoice-export-batches/" + royaltyInvoiceExportVoidRequestData.royaltyInvoiceExportBatchID + "/void",
            data: royaltyInvoiceExportVoidRequestData,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<boolean>(config);
    }

    getRmsSearchDataResult(rmsSearchRequestDataModel: rmsSearchRequestDataModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/rms-franchise-contracts/search",
            data: rmsSearchRequestDataModel,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<rmsSearchResultDataModel[]>(config);
    }

    AddReportingPeriods(franchisorId: number, reportingPeriods: reportingPeriodsDataModel[]) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/franchisor-royalty-management/add-royalty-reporting-periods/franchisors/" + franchisorId,
            data: reportingPeriods,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<boolean>(config);
    }

    GetContractTerritoryType() {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/contract-territory-types",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<contractTerritoryTypeDataModel[]>(config);
    }

    //v2 changes for FDD

    GetDisclosureDocumentsRatePlans(disclosureDocumentId) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/disclosure-documents/"+disclosureDocumentId,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any[]>(config);
    }

    GetRoyaltyDisclosureDocumentRatePlanItems(disclosureDocumentRatePlanId){
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-disclosure-document-rate-plan-items/"+disclosureDocumentRatePlanId,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any[]>(config);
    }

    GetRoyaltyDisclosureDocumentRatePlanItemDetail(disclosureDocumentRatePlanItemId){
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-disclosure-document-rate-plan-item-details/"+disclosureDocumentRatePlanItemId,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any[]>(config);
    }

     
    UpdateRoyaltyDisclosureDocumentRatePlanItemDetails(disclosureDocumentRatePlanItemModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-disclosure-document-rate-plan-item-details/",
            data: disclosureDocumentRatePlanItemModel,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<any>(config);
    }
    UpdateRoyaltyDisclosureDocumentRatePlanItem(disclosureDocumentRatePlanModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-disclosure-document-rate-plan-items/",
            data: disclosureDocumentRatePlanModel,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<any>(config);
    }

    GetRoyaltySearchDetailsByFranchiseId(franchiseId){
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-contracts/search?franchise_id="+franchiseId,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any[]>(config);
    }

    GetRoyaltySearchDetailsByFranchiseIdAndStatus(
        franchiseId: number, 
        royaltyContractStatusIds:Array<number>){

        const royaltyContractStatusIdParam = royaltyContractStatusIds
            ?.map(statusId => `royalty_contract_status_ids=${statusId}`)
            ?.join('&');

        const url = `${this.apiConfig.AppSettings.RoyaltyRootUrl}/api/royalty-contracts/search?franchise_id=${franchiseId}${royaltyContractStatusIdParam?`&${royaltyContractStatusIdParam}`:''}`

        const config: ng.IRequestConfig = {
            method: "GET",
            url: url,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any[]>(config);
    }

    GetContractManagementDisclosureDocumentRatePlanItem(contactMangementdisclosureDocumentId){
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-contract-rate-plan-items/search?contract_rate_plan_id="+contactMangementdisclosureDocumentId,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any[]>(config);
    }

    GetContractManagementDisclosureDocumentRatePlanItemXREF(rateplanID){
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-contract-rate-plan-items/"+rateplanID+"/royalty-contract-rate-plan-item-xrefs",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any[]>(config);
    }

    GetContractManagementDisclosureDocumentRatePlanItemDetail(contactMangementdisclosureDocumentRatePlanItemId){
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-contract-rate-plan-item-details/search?contract_rate_plan_item_id="+contactMangementdisclosureDocumentRatePlanItemId,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any[]>(config);
    }
    
    GetContractManagementDisclosureDocumentRatePlanItemDetailXREF(ratePlanDetailId){
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-contract-rate-plan-item-details/"+ratePlanDetailId+"/royalty-contract-rate-plan-item-detail-fee-xrefs",
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<any[]>(config);
    }

     
    CreateContractManagementRoyaltyDisclosureDocumentRatePlanItemDetails(contractManagementRatePlanItemDetailModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-contract-rate-plan-item-details/",
            data: contractManagementRatePlanItemDetailModel,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<any>(config);
    }
    CreateContractManagementRoyaltyDisclosureDocumentRatePlanItemDetailXREF(XREFId,royaltyContractPlanRatePlanModelXREF) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-contract-rate-plan-item-details/"+XREFId+"/royalty-contract-rate-plan-item-detail-fee-xrefs",
            data: royaltyContractPlanRatePlanModelXREF,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<any>(config);
    }
    CreateContractManagementRoyaltyDisclosureDocumentRatePlanItem(royaltyContractPlanRatePlanModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-contract-rate-plan-items/",
            data: royaltyContractPlanRatePlanModel,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<any>(config);
    }
    CreateContractManagementRoyaltyDisclosureDocumentRatePlanItemXREF(XREFId,royaltyContractPlanRatePlanModelXREF) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-contract-rate-plan-items/"+XREFId+"/royalty-contract-rate-plan-item-detail-fee-xrefs",
            data: royaltyContractPlanRatePlanModelXREF,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<any>(config);
    }
    UpdateContractManagementRoyaltyDisclosureDocumentRatePlanItem(royaltyContractPlanRatePlanModel,ratePlanItemID) {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-contract-rate-plan-items/"+ratePlanItemID,
            data: royaltyContractPlanRatePlanModel,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<any>(config);
    }

    UpdateContractManagementRoyaltyDisclosureDocumentRatePlanItemDetails(royaltyContractPlanRatePlanModel,ratePlanItemDetailID) {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-contract-rate-plan-item-details/"+ratePlanItemDetailID,
            data: royaltyContractPlanRatePlanModel,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<any>(config);
    }

    GetPeriodReportDetails(periodReportId) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url:  this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-period-report-details/search?royalty_period_report_id=" + periodReportId
        };

        return this.$http<any[]>(config);
    }

    GetPeriodReportDetailDynamic(periodReportId) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-period-report-details/" + periodReportId + "/royalty-period-report-detail-xrefs"
        };

        return this.$http<any[]>(config);
    }


    /**
     * Search for royalty period report 
     * @param royaltyPeriodReportId - Identifier of the royalty period report  to search for (optional)   
     * @returns Promise with an array of royalty period report 
     */
    GetRoyaltyPeriodReport(royaltyPeriodReportId) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.RoyaltyRootUrl + "/api/royalty-period-reports/search?royalty_period_report_id=" + royaltyPeriodReportId
        };

        return this.$http<royaltyPeriodReportModel[]>(config);
    }

    /**
	 * Search for royalty period report adjustments based on the provided search criteria
	 * @param royaltyPeriodReportAdjustmentId - Identifier of the royalty period report adjustment to search for (optional)
	 * @param franchiseId - Identifier of the franchise (optional)
	 * @param periodReportAdjustmentId - Identifier of the period report adjustment (optional)
	 * @returns Promise with an array of royalty period report adjustments
	 */
    GetRoyaltyPeriodReportAdjustments(
        royaltyPeriodReportId?: number,
        royaltyPeriodReportAdjustmentId?: number, 
        franchiseId?: number, 
        periodReportAdjustmentId?: string): ng.IHttpPromise<RoyaltyPeriodReportAdjustmentModel[]> {
        const params: any = {};

        if (royaltyPeriodReportAdjustmentId) params.royalty_period_report_adjustment_id = royaltyPeriodReportAdjustmentId;
        if (franchiseId) params.franchise_id = franchiseId;
        if (periodReportAdjustmentId) params.period_report_adjustment_id = periodReportAdjustmentId;
        if (royaltyPeriodReportId) params.royalty_period_report_id = royaltyPeriodReportId;

        let config: ng.IRequestConfig = {
            method: "GET",
            url: `${this.apiConfig.AppSettings.RoyaltyRootUrl}/api/royalty-period-report-adjustments/search`,
            params: params,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<RoyaltyPeriodReportAdjustmentModel[]>(config);
    }
	
	/**
    * Search for royalty fee types based on the provided search criteria.
    * @param royaltyFeeTypeId - Identifier of the royalty fee type to search for (optional)
    * @param feeCode - Code of the fee type (optional)
    * @param name - Name of the fee type (optional)
    * @param isCancellationRequested - Indicates if the fee type can be cancelled (optional)
    * @param canBeCanceled - Indicates if the fee type can be cancelled (optional)
    * @returns Promise with an array of royalty fee types
    */
    GetRoyaltyFeeTypes(
        royaltyFeeTypeId?: number,
        feeCode?: string,
        name?: string,
        isCancellationRequested?: boolean,
        canBeCanceled?: boolean
    ) : ng.IHttpPromise<RoyaltyFeeTypeModel[]> {
        const params: any = {};

        if (royaltyFeeTypeId) params.royalty_fee_type_id = royaltyFeeTypeId;
        if (feeCode) params.fee_code = feeCode;
        if (name) params.name = name;
        if (isCancellationRequested !== undefined) params.IsCancellationRequested = isCancellationRequested;
        if (canBeCanceled !== undefined) params.CanBeCanceled = canBeCanceled;

        const queryString = Object.keys(params)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
            .join('&');

        const url = `${this.apiConfig.AppSettings.RoyaltyRootUrl}/api/royalty-fee-types/search?${queryString}`;

        const config: ng.IRequestConfig = {
            method: 'GET',
            url: url,
            headers: {
                'X-Correlation-Id': this.GenerateUUID()
            }
        };

        return this.$http<RoyaltyFeeTypeModel[]>(config);
    }

	/**
	 * Create a new royalty period report adjustment
	 * @param royaltyPeriodReportAdjustment - Data model for creating a royalty period report adjustment
	 * @returns Promise with the created royalty period report adjustment
	 */
    CreateRoyaltyPeriodReportAdjustment(royaltyPeriodReportAdjustment: RoyaltyPeriodReportAdjustmentUpsertModel): ng.IHttpPromise<RoyaltyPeriodReportAdjustmentModel> {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: `${this.apiConfig.AppSettings.RoyaltyRootUrl}/api/royalty-period-report-adjustments`,
            data: royaltyPeriodReportAdjustment,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<RoyaltyPeriodReportAdjustmentModel>(config);
    }
	
	/**
	 * Update an existing royalty period report adjustment
	 * @param royaltyPeriodReportAdjustmentId - Identifier of the royalty period report adjustment to update
	 * @param royaltyPeriodReportAdjustment - Data model for updating a royalty period report adjustment
	 * @returns Promise with the updated royalty period report adjustment
	 */
    UpdateRoyaltyPeriodReportAdjustment(royaltyPeriodReportAdjustmentId: number, royaltyPeriodReportAdjustment: RoyaltyPeriodReportAdjustmentModel): ng.IHttpPromise<RoyaltyPeriodReportAdjustmentModel> {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: `${this.apiConfig.AppSettings.RoyaltyRootUrl}/api/royalty-period-report-adjustments/${royaltyPeriodReportAdjustmentId}`,
            data: royaltyPeriodReportAdjustment,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<RoyaltyPeriodReportAdjustmentModel>(config);
    }

    /**
	 * Create multiple royalty period report adjustment fee cross-references
	 * @param royaltyPeriodReportAdjustmentId - Identifier of the royalty period report adjustment
	 * @param feeXrefs - Array of fee cross-reference models for creating
	 * @returns Promise with the created royalty period report adjustment fee cross-references
	 */
    CreateRoyaltyPeriodReportAdjustmentFeeXrefs(
        royaltyPeriodReportAdjustmentId: number, 
        feeXrefs: RoyaltyPeriodReportAdjustmentFeeXrefCreateModel[]): ng.IHttpPromise<RoyaltyPeriodReportAdjustmentFeeXrefModel[]> {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: `${this.apiConfig.AppSettings.RoyaltyRootUrl}/api/royalty-period-report-adjustments/${royaltyPeriodReportAdjustmentId}/royalty-period-report-adjustment-fee-xrefs`,
            data: feeXrefs,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<RoyaltyPeriodReportAdjustmentFeeXrefModel[]>(config);
    }

    /**
	 * Get royalty period report adjustment fee cross-references for a specific report adjustment
	 * @param royaltyPeriodReportAdjustmentId - Identifier of the royalty period report adjustment
	 * @returns Promise with an array of royalty period report adjustment fee cross-references
	 */
    GetRoyaltyPeriodReportAdjustmentFeeXrefs(royaltyPeriodReportAdjustmentId: number): ng.IHttpPromise<RoyaltyPeriodReportAdjustmentFeeXrefModel[]> {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: `${this.apiConfig.AppSettings.RoyaltyRootUrl}/api/royalty-period-report-adjustments/${royaltyPeriodReportAdjustmentId}/royalty-period-report-adjustment-fee-xrefs`,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<RoyaltyPeriodReportAdjustmentFeeXrefModel[]>(config);
    }    

    /**
 * Get royalty contract notes based on ContractId
 * @param contractId - Fetch data based on contractId.
 * @returns Promise with an array of royalty contract notes
 */
    GetRoyaltyContractNotesPageinatedData(contractId:number,auditType: string, skip: number, take: number): ng.IHttpPromise<RoyaltyContractNotesDtoPagedResult> {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: `${this.apiConfig.AppSettings.RoyaltyRootUrl}/api/royalty-contract-notes/search/paginate?audit_type=${auditType}&royalty_contract_id=${contractId}&skip=${skip}&take=${take}`,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<RoyaltyContractNotesDtoPagedResult>(config);
    }

    /**
     * Retriggers manual sales entries processing for specified batch groups
     * @param request - The request containing franchisor, concept, license details and batch groups to process
     * @returns Promise containing the results of reprocessing, including new batch IDs and creation status
     */
    RetriggerManualSalesEntries(request: RpmResources.IRetriggerManualSalesRequest): ng.IPromise<RpmResources.IRetriggerManualSalesResponse> {
        return this.$http.post<RpmResources.IRetriggerManualSalesResponse>(
            `${this.apiConfig.AppSettings.RoyaltyRootUrl}/api/manualsalesentries/retrigger`,
            request
        ).then(response => response.data);
    }
}