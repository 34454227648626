import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { FranchiseSelectorComponentControllerState, FranchiseSelectorOnLoadParameters, FranchiseSelectorOnSelectParameters } from "Directives/Common/FranchiseSelector";
import { FranchisorSelectorComponentControllerState, FranchisorSelectorOnLoadParameters, FranchisorSelectorOnSelectParameters } from "Directives/Common/FranchisorSelector";
import { ConceptSelectorComponentControllerState, ConceptSelectorOnSelectParameters, ConceptSelectorOnLoadParameters } from "Directives/Common/ConceptSelector";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

import * as _ from "lodash";
import { StringProperties } from "Types/StringProperties";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            conceptFranchisorFranchiseSelector: Partial<StringProperties<ConceptFranchisorFranchiseSelectorComponentController>>;
        }
    }
}

export class ConceptFranchisorFranchiseSelectorComponentController implements ng.IController
{
    //---- Bindings ----
    disable: boolean;
    state: boolean |
        FranchiseSelectorComponentControllerState &
        FranchisorSelectorComponentControllerState &
        ConceptSelectorComponentControllerState;

    conceptId: number;
    conceptDisable: boolean;
    conceptSelect: string[];
    conceptFilters: kendo.data.DataSourceFilter;
    conceptSort: (a: Partial<CoreResources.IConcept>, b: Partial<CoreResources.IConcept>) => number;
    conceptDisplayTextTemplate: string;
    conceptExtraOptions: kendo.ui.DropDownListOptions;
    conceptOnSelect: (params: ConceptSelectorOnSelectParameters) => void;
    conceptOnLoad: (params: ConceptSelectorOnLoadParameters) => void;

    franchisorId: number;
    franchisorDisable: boolean;
    franchisorSelect: string[];
    franchisorFilters: kendo.data.DataSourceFilter;
    franchisorSort: (a: Partial<CoreResources.IFranchisor>, b: Partial<CoreResources.IFranchisor>) => number;
    franchisorDisplayTextTemplate: string;
    franchisorExtraOptions: kendo.ui.DropDownListOptions;
    franchisorOnSelect: (params: FranchisorSelectorOnSelectParameters) => void;
    franchisorOnLoad: (params: FranchisorSelectorOnLoadParameters) => void;

    franchiseId: number;
    franchiseDisable: boolean;
    franchiseSelect: string[];
    franchiseFilters: kendo.data.DataSourceFilter;
    franchiseSort: (a: Partial<CoreResources.IFranchise>, b: Partial<CoreResources.IFranchise>) => number;
    franchiseDisplayTextTemplate: string;
    franchiseExtraOptions: kendo.ui.DropDownListOptions;
    franchiseShowInactive: boolean;
    franchiseOnSelect: (params: FranchiseSelectorOnSelectParameters) => void;
    franchiseOnLoad: (params: FranchiseSelectorOnLoadParameters) => void;
    //------------------

    isLoading: boolean;
    combinedFranchisorFilters: kendo.data.DataSourceFilters;
    combinedFranchiseFilters: kendo.data.DataSourceFilters;

    static $inject = [
        "coreApiResources",
    ];

    constructor(
        private coreApiResources: CoreApiResources,
    )
    {

    }

    $onInit()
    {
        this.isLoading = false;

        if (!this.conceptFilters) {
            this.conceptFilters = [];
        }
        
        if (this.franchiseId && (!this.conceptId || !this.franchisorId)) {

            this.LoadFromFranchise();
        }
        else if (this.franchisorId && !this.conceptId)
        {
            this.LoadFromFranchisor();
        }
        
        this.CreateCombinedFranchisorFilters();
        this.CreateCombinedFranchiseFilters();
    }

    $postLink()
    {

    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
        if (onChanges[nameof(this.conceptId)]) {
            this.CreateCombinedFranchisorFilters()
        }

        if (onChanges[nameof(this.franchisorId)]) {
            this.CreateCombinedFranchiseFilters()
        }

        if(onChanges[nameof(this.franchiseId)]) 
        {
            if (this.franchiseId && (!this.conceptId || !this.franchisorId))
            {
                this.LoadFromFranchise();    
            }
        }

        if (typeof this.disable === "boolean") {
            this.franchiseDisable = this.disable;
            this.franchisorDisable = this.disable;
            this.conceptDisable = this.disable;
        }
    }

    OnSelectConcept(concept: kendo.data.ObservableObject & Partial<CoreResources.IConcept>)
    {
        if (!concept || !concept.ConceptId) {
            this.conceptId = null;
            this.franchisorFilters = null;
            this.CreateCombinedFranchisorFilters();
            this.conceptOnSelect({ concept: concept });
            return;
        }

        if (this.conceptId == concept.ConceptId) {
            this.conceptOnSelect({ concept: concept });
            return;
        }

        this.conceptId = concept.ConceptId;
        this.CreateCombinedFranchisorFilters();
        this.conceptOnSelect({ concept: concept });
    }

    OnSelectFranchisor(franchisor: kendo.data.ObservableObject & Partial<CoreResources.IFranchisor>)
    {
        if (!franchisor || !franchisor.FranchisorId) {
            this.franchisorId = null;
            this.franchiseFilters = null;
            this.CreateCombinedFranchiseFilters();
            this.franchisorOnSelect({ franchisor: franchisor });
            return;
        }

        if (this.franchisorId == franchisor.FranchisorId) {
            this.franchisorOnSelect({ franchisor: franchisor });
            return;
        }

        this.conceptId = franchisor.ConceptId;
        this.franchisorId = franchisor.FranchisorId;
        this.CreateCombinedFranchiseFilters();
        this.franchisorOnSelect({ franchisor: franchisor });
    }

    OnSelectFranchise(franchise: kendo.data.ObservableObject & Partial<CoreResources.IFranchise>)
    {
        if (!franchise || !franchise.FranchiseId) {
            this.franchiseId = null;
            this.franchiseOnSelect({ franchise: franchise });
            return;
        }

        this.conceptId = franchise.ConceptId;
        this.franchisorId = franchise.FranchisorId;
        this.franchiseId = franchise.FranchiseId;
        this.franchiseOnSelect({ franchise: franchise });
    }

    CreateCombinedFranchisorFilters()
    {
        if (!this.conceptId) {
            this.combinedFranchisorFilters = null;
            return;
        }

        this.combinedFranchisorFilters = {
            logic: "and",
            filters: [
                {
                    field: nameof<CoreResources.IFranchisor>(o => o.ConceptId),
                    operator: 'eq',
                    value: this.conceptId
                },
                this.franchisorFilters
            ]
        }
    }

    CreateCombinedFranchiseFilters()
    {
        if (!this.franchisorId) {
            this.combinedFranchiseFilters = null;
            return;
        }

        this.combinedFranchiseFilters = {
            logic: "and",
            filters: [
                {
                    field: nameof<CoreResources.IFranchise>(o => o.FranchisorId),
                    operator: 'eq',
                    value: this.franchisorId
                },
                this.franchiseFilters
            ]
        }
    }

    LoadFromFranchise()
    {
        this.isLoading = true;
        this.coreApiResources.FranchiseApi.get({ id: this.franchiseId, $select: `${nameof<CoreResources.IFranchise>(o => o.ConceptId)},${nameof<CoreResources.IFranchise>(o => o.FranchisorId)}` }).$promise
            .then((franchise) =>
            {
                this.conceptId = franchise.ConceptId;
                this.CreateCombinedFranchisorFilters();
                this.franchisorId = franchise.FranchisorId;
                this.CreateCombinedFranchiseFilters();
            })
            .finally(() =>
            {
                this.isLoading = false;
            });
    }

    LoadFromFranchisor()
    {
        this.isLoading = true;
        this.coreApiResources.FranchisorApi.get({ id: this.franchisorId, $select: nameof<CoreResources.IFranchisor>(o => o.ConceptId) }).$promise
            .then((franchisor) =>
            {
                this.conceptId = franchisor.ConceptId;
                this.CreateCombinedFranchisorFilters();
            })
            .finally(() =>
            {
                this.isLoading = false;
            });
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.conceptFranchisorFranchiseSelector);

        app.component(componentName, {
            bindings: {
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.disable)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.state)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.conceptId)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.conceptSelect)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.conceptFilters)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.conceptSort)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.conceptDisplayTextTemplate)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.conceptExtraOptions)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.conceptOnSelect)]: "&",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.conceptOnLoad)]: "&",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchisorId)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchisorSelect)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchisorFilters)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchisorSort)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchisorDisplayTextTemplate)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchisorExtraOptions)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchisorOnSelect)]: "&",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchisorOnLoad)]: "&",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchiseId)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchiseSelect)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchiseFilters)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchiseSort)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchiseDisplayTextTemplate)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchiseExtraOptions)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchiseShowInactive)]: "<",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchiseOnSelect)]: "&",
                [nameof<ConceptFranchisorFranchiseSelectorComponentController>(o => o.franchiseOnLoad)]: "&",
            },
            controller: ConceptFranchisorFranchiseSelectorComponentController,
            templateUrl: `/Templates/Common/ConceptFranchisorFranchiseSelector.html`,
        });
    }
}

