//Shamelessly taken from: https://www.npmjs.com/package/rgba-convert. Version 0.3.0

export class RgbaConvert
{
    static Arr(data)
    {
        var a = RgbaConvert.Parse(data);
        if (a.length == 3)
        {
            return a.concat(255);
        }
        else
        {
            a[3] = Math.round(a[3]);
            return a;
        }
    }

    static Obj(data)
    {
        var a = RgbaConvert.Parse(data);
        return {
            r: a[0],
            g: a[1],
            b: a[2],
            a: a.length == 3
                ? 255
                : Math.round(a[3])
        }
    }

    static Css(data)
    {
        var a = RgbaConvert.Parse(data);
        if (a.length == 3) a.push(255);

        return a[3] == 255
            ? 'rgb(' + a[0] + ', ' + a[1] + ', ' + a[2] + ')'
            : a[3] == 0
                ? 'rgba(' + a[0] + ', ' + a[1] + ', ' + a[2] + ', 0)'
                : 'rgba(' + a[0] + ', ' + a[1] + ', ' + a[2] + ', ' + String(a[3] / 255).substr(1) + ')';
    }

    static Hex(data)
    {
        var parsed = RgbaConvert.Parse(data);
        if (parsed.length === 3) parsed.push(255);
        var opaque = parsed[3] === 255;
        var r = RgbaConvert.Num2Hex(parsed[0]);
        var g = RgbaConvert.Num2Hex(parsed[1]);
        var b = RgbaConvert.Num2Hex(parsed[2]);
        var a = RgbaConvert.Num2Hex(Math.round(parsed[3]));
        var is = RgbaConvert.IsShort(r, g, b, a);
        if (opaque)
        {
            return is
                ? '#' + r.charAt(0) + g.charAt(0) + b.charAt(0)
                : '#' + r + g + b;
        }
        return is
            ? '#' + r.charAt(0) + g.charAt(0) + b.charAt(0) + a.charAt(0)
            : '#' + r + g + b + a;
    }

    static HexNoAlpha(data)
    {
        let hex = RgbaConvert.Hex(data);
        if (hex.length === 5)
        {
            return hex.substr(0, 4);
        }
        else if (hex.length === 9)
        {
            return hex.substr(0, 7);
        }

        return hex;
    }

    /**
     * Returns an ARGB 32bit *unsigned* integer
     * @param data
     */
    static Num(data)
    {
        var a = RgbaConvert.Parse(data);
        if (a.length == 3)
            a.push(255);
        else
            a[3] = Math.round(a[3]);
        return ((a[3] << 24) >>> 0 | a[0] << 16 | a[1] << 8 | a[2]) >>> 0;
    }

    static Parse(data)
    {
        if (typeof data == 'string')
        {
            data = data.toLowerCase();
            return RgbaConvert.Name(data) ||
                RgbaConvert.Hex3(data) ||
                RgbaConvert.Hex6(data) ||
                RgbaConvert.Rgb(data) ||
                RgbaConvert.Rgba(data) ||
                [0, 0, 0, 255];
        }
        return RgbaConvert.Object(data) || RgbaConvert.Array(data) || RgbaConvert.Number(data) || [0, 0, 0, 255];
    }

    static Num2Hex(num)
    {
        var s = num.toString(16);
        return s.length == 1
            ? '0' + s
            : s;
    }

    static IsShort(r, g, b, a)
    {
        var h = [
            'ff', '00', '11', '22', '33', '44', '55', '66',
            '77', '88', '99', 'aa', 'bb', 'cc', 'dd', 'ee'
        ];
        return h.indexOf(r) != -1
            && h.indexOf(g) != -1
            && h.indexOf(b) != -1
            && h.indexOf(a) != -1;
    }

    static Name(str)
    {
        if (str == 'red') return [255, 0, 0];
        if (str == 'green') return [0, 255, 0];
        if (str == 'blue') return [0, 0, 255];
        if (str == 'black') return [0, 0, 0];
        if (str == 'white') return [255, 255, 255];
        if (str == 'cyan') return [0, 255, 255];
        if (str == 'gray') return [128, 128, 128];
        if (str == 'grey') return [128, 128, 128];
        if (str == 'magenta') return [255, 0, 255];
        // ok, not the real css `pink` but my personal `magenta` alias
        // `pink` is simpler than `fuchsia`, whatever...
        if (str == 'pink') return [255, 0, 255];
        if (str == 'yellow') return [255, 255, 0];
    }

    static Hex2Num(str)
    {
        return str.length === 1
            ? parseInt(str + str, 16)
            : parseInt(str, 16);
    }

    static Hex3(str)
    {
        var s = str.replace(/^#/, '');
        var l = s.length
        if (l === 3 || l === 4)
        {
            var r = RgbaConvert.Hex2Num(s[0]);
            var g = RgbaConvert.Hex2Num(s[1]);
            var b = RgbaConvert.Hex2Num(s[2]);
            var a = l === 3
                ? 255
                : RgbaConvert.Hex2Num(s[3]);

            if (isNaN(r) || isNaN(g) || isNaN(b) || isNaN(a)) return;

            return [r, g, b, a];
        }
    }

    static Hex6(str)
    {
        var s = str.replace(/^#/, '');
        var l = s.length;
        if (l === 6 || l === 8)
        {
            var r = RgbaConvert.Hex2Num(s.slice(0, 2));
            var g = RgbaConvert.Hex2Num(s.slice(2, 4));
            var b = RgbaConvert.Hex2Num(s.slice(4, 6));
            var a = l == 6
                ? 255
                : RgbaConvert.Hex2Num(s.slice(6, 8));

            if (isNaN(r) || isNaN(g) || isNaN(b) || isNaN(a)) return;

            return [r, g, b, a];
        }
    }

    static GetNum(val, integer)
    {
        if (typeof val != 'number') return -1;
        if (integer === true && Math.floor(val) !== val) return -1;
        return val >= 0 && val <= 255
            ? val
            : -1;
    }

    static Object(obj)
    {
        if (Object.prototype.toString.call(obj) === '[object Object]'
            && Object.getPrototypeOf(obj) === Object.getPrototypeOf({}))
        {
            var r = RgbaConvert.GetNum(obj.r != undefined ? obj.r : obj.red != undefined ? obj.red : 0, true);
            var g = RgbaConvert.GetNum(obj.g != undefined ? obj.g : obj.green != undefined ? obj.green : 0, true);
            var b = RgbaConvert.GetNum(obj.b != undefined ? obj.b : obj.blue != undefined ? obj.blue : 0, true);
            var a = RgbaConvert.GetNum(obj.a != undefined ? obj.a : obj.alpha != undefined ? obj.alpha : 255, true);
            if (r != -1 && g != -1 && b != -1 && a != -1)
            {
                return [r, g, b, a];
            }
        }
    }

    static Array(arr)
    {
        if (Array.isArray(arr) && (arr.length == 3 || arr.length == 4))
        {
            var r = RgbaConvert.GetNum(arr[0], true);
            var g = RgbaConvert.GetNum(arr[1], true);
            var b = RgbaConvert.GetNum(arr[2], true);
            var a = RgbaConvert.GetNum(arr[3] != undefined ? arr[3] : 255, true);
            if (r != -1 && g != -1 && b != -1 && a != -1)
            {
                return [r, g, b, a];
            }
        }
    }

    /**
     * Converts a 32 bit *unsigned* integer
     * @param num 
     * @returns {} 
     */
    static Number(num)
    {
        if (typeof num == 'number' && Math.floor(num) == num && num <= 4294967295 && num >= 0)
        {
            var a = num >> 24 & 255;
            var r = num >> 16 & 255;
            var g = num >> 8 & 255;
            var b = num & 255;
            return [r, g, b, a];
        }
    }

    static Rgb(str)
    {
        if (str.substr(0, 4) == 'rgb(')
        {
            str = str.match(/^rgb\(([^)]+)\)/)[1];
            var t = str.split(/ *, */).map(Number);
            var r = RgbaConvert.GetNum(t[0], true);
            var g = RgbaConvert.GetNum(t[1], true);
            var b = RgbaConvert.GetNum(t[2], true);
            if (r != -1 && g != -1 && b != -1)
            {
                return [r, g, b, 255];
            }
        }
    }

    static Rgba(str)
    {
        if (str.substr(0, 5) == 'rgba(')
        {
            str = str.match(/^rgba\(([^)]+)\)/)[1];
            var t = str.split(/ *, */).map(Number);
            var r = RgbaConvert.GetNum(t[0], true);
            var g = RgbaConvert.GetNum(t[1], true);
            var b = RgbaConvert.GetNum(t[2], true);
            var a = RgbaConvert.GetNum(t[3] * 255, false);
            if (r != -1 && g != -1 && b != -1 && a != -1)
            {
                return [r, g, b, a];
            }
        }
    }
}

