import { IFranchiseeStateParams } from "Controllers/Rms/FranchiseeManagement/fmRouter";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { SyncApiResources } from "Services/Resources/SyncApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmResources } from "Interfaces/FranForce/Rpm/RpmResources";
import { SyncResources } from "Interfaces/FranForce/Sync/SyncResources";
import {
    FranchiseeManagementClient,
    UpdateFranchiseeManagementGeneralFieldsRm
} from '@nbly/royalty-orchestrations-clients';
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";

export class fmGeneralController implements ng.IController {
    franchiseRoyalty: IFranchiseRoyaltyFields;
    royaltySystems: RpmResources.IRoyaltySystem[];
    readOnly: boolean;
    saveFranchiseDataStatusCSSClass: string;
    showSaveFranchiseDataStatus: boolean;
    saveFranchiseDataStatusMessage: string;

    //this will contain franchise royalty data on load
    initLoadFranchiseRoyalty: IFranchiseRoyaltyFields;

    royaltyOrchestrationsClient: AxiosInstance;
    franchiseeManagementClient: FranchiseeManagementClient;

    isLoading: boolean;

    static $inject = [
        "$scope",
        "$state",
        "$stateParams",
        "coreApiResources",
        "ngDialog",
        "rpmApiResources",
        "syncApiResources",
        "identityManager",
        "$q"
    ];
    constructor(
        private $scope: ng.IScope,
        private $state: any,
        private $stateParams: IFranchiseeStateParams,
        private coreApiResources: CoreApiResources,
        private ngDialog: any,
        private rpmApiResources: RpmApiResources,
        private syncApiResources: SyncApiResources,
        private identityManager: IdentityManager,
        private $q: ng.IQService
    ) {
        this.royaltyOrchestrationsClient = FranForceAxiosClientBuilder.BuildRoyaltyOrchestrationsBaseClient();
        this.franchiseeManagementClient = new FranchiseeManagementClient("", this.royaltyOrchestrationsClient);
    }

    setSaveFranchiseDataStatus(className: string, show: boolean, message: string) {
        this.saveFranchiseDataStatusCSSClass = className;
        this.showSaveFranchiseDataStatus = show;
        this.saveFranchiseDataStatusMessage = message;
    }

    $onInit() {
        this.isLoading = false;
        this.setSaveFranchiseDataStatus("", false, "");
        this.identityManager.HasRole(["FranchiseeManagementAdmin", "Admin"]).then(hasRole => {
            this.readOnly = !hasRole;
        });

        this.rpmApiResources.RoyaltySystemsApi.query({}).$promise.then(royaltySystems => {
            this.royaltySystems = royaltySystems;
        });

        this.RefreshData();

        this.$scope.$on('franchiseeSelected', (event, args) => {
            this.RefreshData();
        });
    }

    onRoyaltySystemChange() {
        if (this.franchiseRoyalty.RoyaltySystemId != this.initLoadFranchiseRoyalty.RoyaltySystemId) {
            this.$scope.$emit('setGeneralTabUnsaved', { isGeneralTabUnsaved: true });
        }
        else {
            this.$scope.$emit('setGeneralTabUnsaved', { isGeneralTabUnsaved: false });
        }
    }

    RefreshData() {
        this.$scope.$emit('setGeneralTabUnsaved', { isGeneralTabUnsaved: false });
        let franchiseId = parseInt(this.$stateParams.franchiseeID);

        this.coreApiResources.GetFranchiseRoyaltyInfo(franchiseId).then((royalty) => {
            this.franchiseRoyalty = royalty.data;
            this.initLoadFranchiseRoyalty = { ...royalty.data };
        });
    }

    SaveChanges() {

        if (this.franchiseRoyalty.RoyaltySystemId == null) {
            this.setSaveFranchiseDataStatus("saveFranchiseDataStatusError", true, "Please select Royalty System.");
            return;
        }
        else {
            this.setSaveFranchiseDataStatus("", false, "");
        }

        let updateFranchiseeManagementGeneralFields: UpdateFranchiseeManagementGeneralFieldsRm = {
            royaltySystemId: this.franchiseRoyalty.RoyaltySystemId
        }

        let franchiseId = parseInt(this.$stateParams.franchiseeID);
        this.isLoading = true;
        this.franchiseeManagementClient.merge(franchiseId, updateFranchiseeManagementGeneralFields)
            .then(() => {
                this.$scope.$emit('setGeneralTabUnsaved', { isGeneralTabUnsaved: false });
                this.initLoadFranchiseRoyalty = { ...this.franchiseRoyalty };
                this.$scope.$emit('showNotification', { saveSuccessful: true, text: "Successfully saved Franchise info" });
            })
            .catch(() => {
                this.$scope.$emit('setGeneralTabUnsaved', { isGeneralTabUnsaved: true });
                this.$scope.$emit('showNotification', { saveSuccessful: false, text: "Failed to saved Franchise info" });
            })
            .finally(() => {
                this.isLoading = false;
                this.$scope.$digest();
            });
    }

    CancelChanges() {
        this.RefreshData();
    }
}