import { ApiConfig } from "AppConfig/ApiConfig";

type mappedObj = { [prop: string]: mappedObj };

export class ODataHelperService
{
    static $inject = [
        "$http",
        "apiConfig"
    ];

    constructor(
        private $http: ng.IHttpService,
        private apiConfig: ApiConfig
    )
    {

    }

    GetStandardCoreODataQuery<T>(urlEndpoint: string, parameters: ODataQueryParameters | Object, requestConfig: Partial<ng.IRequestConfig> = {})
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: `${this.apiConfig.AppSettings.CoreApiRootUrl}/${urlEndpoint}`,
            params: parameters
        };

        return this.$http<T[]>(config);
    }

    GetStandardCoreODataGet<T>(urlEndpoint: string, id: string|number, parameters: ODataQueryParameters | Object, requestConfig: Partial<ng.IRequestConfig> = {})
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: `${this.apiConfig.AppSettings.CoreApiRootUrl}/${urlEndpoint}/${id}`,
            params: parameters
        };

        return this.$http<T>(
            angular.extend({}, config, requestConfig)
        );
    }

    GetStandardMethodExtensions()
    {
        var putMethod = { update: { method: "PUT" } };
        var patchMethod = { patch: { method: "PATCH" } };
        var postMultiMethod = { saveMulti: { method: "POST", isArray: true, params: { optionalMethod: "Multi" } } };

        return angular.extend({}, putMethod, patchMethod);
    }

    /**
     * Example: "property eq value1 or property eq value2"
     * @param property
     * @param values
     */
    CreateInFilterStatement<T>(property: string, values: T[])
    {
        return values
            .map((v) =>
            {
                switch (typeof v) {
                    case "number":
                        return property + " eq " + v;
                    case "string":
                        return property + " eq '" + v + "'";
                    case "object":
                        return property + " eq " + v.toString();
                    default:
                        return property + " eq " + v;
                }
            }).join(" or ");
    }

    ConvertCompositeKeyToString = ConvertCompositeKeyToString;

    ConvertKendoDataSourceTransportReadOptionsDataToParameterObject(readOptions: kendo.data.DataSourceTransportReadOptionsData)
    {
        let result: ODataQueryParameters = {};
        if (readOptions.take)
            result.$top = readOptions.take;

        if (readOptions.skip)
            result.$skip = readOptions.skip;

        if (readOptions.filter) {
            result.$filter = this.ConvertKendoDataSourceFiltersToString(readOptions.filter)
        }

        if (readOptions.sort && (readOptions.sort.length || readOptions.sort["field"]))
        {
            if (readOptions.sort["field"]) {
                readOptions.sort = [ <any>readOptions.sort ];
            }

            result.$orderby = readOptions.sort
                .map(s => `${s.field.replace(/\./g, "/")} ${s.dir}`)
                .join(",");
        }

        return result;
    }

    ConvertKendoGridColumnsToSelectionString(columns: { field?: string }[])
    {
        if (!columns)
            return null;

        let columnMap = this.ColumnsToObjectRepresentation(columns)

        //Remove any properties with sub properties. That way we remove fields that are expansions.
        Object.keys(columnMap)
            .filter(key => Object.keys(columnMap[key]).length)
            .forEach(keyToDelete => delete columnMap[keyToDelete]);

        return Object.keys(columnMap).join(",");
    }

    ConvertKendoGridColumnsToExpansionString(columns: {field?: string}[])
    {
        if (!columns)
            return null;

        let columnMap = this.ColumnsToObjectRepresentation(columns)

        //Remove any properties with a depth of 1. It's a regular field that doesn't need to be expanded.
        Object.keys(columnMap)
            .filter(key => !Object.keys(columnMap[key]).length)
            .forEach(keyToDelete => delete columnMap[keyToDelete]);

        let expansionFunc = (obj: mappedObj, depth: number = 0) =>
        {
            let expansionStrings: string[] = [];
            let selectProperties: string[] = [];

            for (let prop of Object.keys(obj))
            {
                if (Object.keys(obj[prop]).length > 0 || depth == 0) {
                    let exString = `${prop}`;
                    let recursiveString = expansionFunc(obj[prop], depth + 1);
                    if (recursiveString) {
                        exString = `${exString}(${recursiveString})`
                    }
                    expansionStrings.push(exString);
                }
                else {
                    if (prop == "length")
                        continue;
                    selectProperties.push(prop);
                }
            }

            if (!selectProperties.length && !expansionStrings.length)
                return "";

            if (depth == 0) {
                return expansionStrings.join(",");
            }

            let select = "";
            if (selectProperties.length) {
                select = "$select=" + selectProperties.join(",")
            }
            let expand = "";
            if (expansionStrings.length) {
                expand = "$expand=" + expansionStrings.join(",")
            }
            let seperator = (select.length && expand.length ? ";" : "");
            return `${select}${seperator}${expand}`;
        }
        
        return expansionFunc(columnMap);
    }

    /**
     * Example: 
     * [ "a.b.c", "d.e", "d.f"]
     * to 
     * { a: {b: {c: {}}}, d: { e: {}, f: {}}}
     * @param columns
     */
    private ColumnsToObjectRepresentation(columns: { field?: string }[])
    {
        if (!columns)
            return null;

        columns = columns.filter(c => c.field);

        let mapFunc = (objToMap: mappedObj, fields: string[][]) =>
        {
            fields = fields.filter(f => f.length > 0);
            for (let field of fields)
            {
                let prop = field[0];
                if (!objToMap[prop])
                    objToMap[prop] = {};

                objToMap[prop] = mapFunc(objToMap[prop], [field.slice(1)]);
            }
            return objToMap;
        }

        // [ "a.b.c", "d.e", "d.f"] 
        // to 
        // [["a", "b", "c"], ["d", "e"], ["d", "f"]]
        // to 
        // { a: {b: {c: {}}}, d: { e: {}, f: {}}}
        
        let columnMap = mapFunc({},
            columns.map(c =>
            {
                return c.field.split(".");
            }));

        return columnMap;
    }

    ConvertKendoDataSourceFiltersOrItemToString(dataSourceFilter: kendo.data.DataSourceFilters | kendo.data.DataSourceFilterItem): string
    {
        if (!dataSourceFilter)
            return <any>dataSourceFilter;

        if (this.IsDataFilters(dataSourceFilter))
            return (
                dataSourceFilter.filters.length ?
                    "(" + this.ConvertKendoDataSourceFiltersToString(dataSourceFilter) + ")" :
                    undefined
                );
        else
            return this.ConvertKendoDataSourceFilterItemToString(dataSourceFilter);
    }

    ConvertKendoDataSourceFiltersToString(dataSourceFilter: kendo.data.DataSourceFilters): string
    {
        if (!dataSourceFilter)
            return <any>dataSourceFilter;

        return dataSourceFilter.filters
            .filter(f => f !== null && f !== undefined)
            .map((f) =>
            {
                return this.ConvertKendoDataSourceFiltersOrItemToString(f);
            })
            .join(` ${dataSourceFilter.logic} `);
    }

    ConvertKendoDataSourceFilterItemToString(dataSourceFilter: kendo.data.DataSourceFilterItem): string
    {
        if (typeof dataSourceFilter.operator !== "string")
            return "";

        let actualField = dataSourceFilter.field.replace(/\./g, "/");

        let actualValue: string;

        if (typeof dataSourceFilter.value === "string") {
            actualValue = `'${dataSourceFilter.value}'`;
        }
        else if (dataSourceFilter.value instanceof Date) {
            actualValue = `${dataSourceFilter.value.toISOString()}`
        }
        else {
            actualValue = dataSourceFilter.value;
        }

        let odataOperator = this.ConvertKendoOperatorToODataOperator(dataSourceFilter.operator);

        switch (odataOperator) {
            case "contains":
            case "startswith":
            case "endswith":
                return `${odataOperator}(${actualField},${actualValue})`;
            case "doesnotcontain":
                return `contains(${actualField},${actualValue}) eq false`;
            case "isnull":
                return `${actualField} eq null`;
            case "isnotnull":
                return `${actualField} ne null`;
            case "isempty":
                return `${actualField} eq ''`;
            case "isnotempty":
                return `${actualField} ne ''`;
            default:
                return `${actualField} ${odataOperator} ${actualValue}`;
        }
    }

    ConvertKendoOperatorToODataOperator(operator: string)
    {
        switch (operator) {
            case "neq":
                return "ne";
            case "gte":
                return "ge";
            case "lte":
                return "le";
            case "eq":
            case "gt":
            case "lt":
            case "contains":
            case "startswith":
            case "endswith":
            default:
                /*
                Not explicitly mentioned, because there isn't really an operator equivilant, but want it to flow through default:
                    isnull
                    isnotnull
                    isempty
                    isnotempty
                    doesnotcontain
                */
                return operator;
        }
    }

    GetKendoDataSourceTransportParameterMap()
    {
        return (data: kendo.data.DataSourceTransportParameterMapData, type: string): any =>
        {
            if (type.toLowerCase() != "read")
                return data;

            let result: ODataQueryParameters = {};

            if (data.take)
                result.$top = data.take;

            if (data.skip)
                result.$skip = data.skip;

            return result;
        }
    }

    GetFromResponseHeadersCallback(responseHeadersCallback: Function)
    {
        let headersObj = responseHeadersCallback();
        return {
            pageSize: <number>headersObj["odata-page-size"],
            count: <number>headersObj["odata-count"]
        }
    }

    IsDataFilters(dataFilter: kendo.data.DataSourceFilters | kendo.data.DataSourceFilterItem): dataFilter is kendo.data.DataSourceFilters
    {
        return dataFilter.hasOwnProperty(nameof<kendo.data.DataSourceFilters>(o => o.filters));
    }
}

export function ConvertCompositeKeyToString(compositeKey: Object): string
{
    let keys = [];
    for (var prop in compositeKey)
    {
        if (compositeKey.hasOwnProperty(prop))
        {
            keys.push(prop + ";" + compositeKey[prop]);
        }
    }
    return keys.join("|");
}
