import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RmsStateService } from "Services/State/RmsState";
import { CmTerritoryComponentController } from "Directives/RPM/ContractManagement/cmTerritoryController";
import { cmContractSelectorComponentController } from "Directives/RPM/ContractManagement/cmContractSelector";
import { CmGeneralComponentController } from "Directives/RPM/ContractManagement/cmGeneral";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { CmRoyaltiesComponentController } from "Directives/RPM/ContractManagement/cmRoyalties";

interface IRoyaltieScope extends ng.IScope {

    isRoyaltyFrequencyChanged: boolean;
}
export class CmSectionsComponentDynamicFeesController implements ng.IController
{
    readonly sectionNames = ["general", "royalties", "territory"];
    tab: string;
    contractId: number;

    franchiseId: number;

    generalController: CmGeneralComponentController;
    royaltiesController: CmRoyaltiesComponentController;
    territoryController: CmTerritoryComponentController;

    conceptFilters: kendo.data.DataSourceFilter;
    franchisorFilters: kendo.data.DataSourceFilter;

    showMessageInfoModal: boolean;
    infoModalMessage: string;
    infoModalTitle: string;

    royaltyFrequencyChangePopup: kendo.ui.Window;
    royaltyFrequencyChangeReasonOnSaveAll: string;
    royaltyEffectiveDateOnSaveAll?: string;
    isSaveAllClicked: boolean;
    message: string;
    deletemessage: string;

    isDynamicFees: boolean;

    static $inject = [
        'rpmUiApiResources',
        'identityManager',
        'rmsState',
        '$location',
        '$window',
        '$timeout',
        "$q",
        '$scope'
    ];
    

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private identityManager: IdentityManager,
        private rmsState: RmsStateService,
        private $location: ng.ILocationService,
        private $window: ng.IWindowService,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
        private $scope: IRoyaltieScope
    )
    {

    }

    $onInit()
    {
        this.showMessageInfoModal = false;
        this.infoModalMessage = "";
        this.infoModalTitle = "";
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledCm').val() === 'True';
        this.contractId = this.$location.search()[nameof<CmSectionsComponentDynamicFeesController>(o => o.contractId)];
        if (!this.contractId) {
            this.$window.location.href = `/ContractManagement`;
        }
        this.tab = this.GetSection();
        this.SetSection(this.tab);
        this.InitFilters();

        this.$scope.$on('setRoyaltyFrequencyChanged', (event, args) => {
            //this.$scope.isRoyaltyFrequencyChanged = args.isRoyaltyFrequencyChanged;
            this.royaltyFrequencyChangeReasonOnSaveAll = null;
            this.royaltyEffectiveDateOnSaveAll = null;
        });

        this.rmsState.royaltyContractStateHandler.Load(this.contractId, this.isDynamicFees)
            .then((contract) =>
            {
                this.franchiseId = contract.FranchiseId;
            });

        this.$window.addEventListener("beforeunload", (event) =>
        {
            let unsavedChanges = (
                this.generalController.HasUnsavedChanges() ||
                this.royaltiesController.HasUnsavedChanges() ||
                this.territoryController.HasUnsavedChanges()
            );
            if (unsavedChanges) {
                event.preventDefault();
                let message = "You have unsaved changes. Are you sure you want to leave?";
                (event || window.event).returnValue = message; 
                return message;
            }
        })
    }

    InitFilters()
    {
        this.conceptFilters = <kendo.data.DataSourceFilterItem>{
            field: nameof<CoreEntities.Concept>(o => o.RoyaltySystemId),
            operator: "isnotnull"
        };

        this.franchisorFilters = <kendo.data.DataSourceFilterItem>{
            field: nameof<CoreEntities.Franchisor>(o => o.RoyaltySystemId),
            operator: "isnotnull"
        };
    }

    GetSection()
    {
        let section = this.$location.search()[nameof<CmSectionsComponentDynamicFeesController>(o => o.tab)];
        let isValid = this.IsValidSection(section);
        let result = isValid ? section : this.sectionNames[0]
        if (!isValid) {
            this.SetSection(result);
        }
        return result;
    }

    SetSection(sectionName: string)
    {
        if (!this.IsValidSection(sectionName)) {
            throw "Invalid Section to Set: " + sectionName;
        }
        
        this.tab = sectionName;
        this.$location.search(nameof<CmSectionsComponentDynamicFeesController>(o => o.tab), sectionName);
    }

    IsValidSection(sectionName: string): boolean
    {
        return this.sectionNames.some(n => n === sectionName);
    }

    GoToNewContractSelection()
    {
        this.$window.location.href = `/ContractManagement/#?${nameof<cmContractSelectorComponentController>(o => o.franchiseId)}=${this.franchiseId}`;
    }

    validateionPopupMessage(message: string, title: string, show: boolean) {
        this.infoModalMessage = message;
        this.infoModalTitle = title;
        this.showMessageInfoModal = show;
    }


    async CheckIsRoyaltyFequencyIdChangedOnSaveAll() {
        if (this.royaltiesController.IsRoyaltyFrequencyChanged() &&
            !(this.royaltiesController.IsRoyaltyFrequencyChangedReasonEntered() ||
                this.isRoyaltyFrequencyChangeReasonEnteredOnSaveAll())) {
            this.royaltyFrequencyChangePopup?.center();
            this.royaltyFrequencyChangePopup?.open();
        }
        else {
            this.SaveAll();
        }
    }


    isRoyaltyFrequencyChangeReasonEnteredOnSaveAll() {
        if (!this.royaltyEffectiveDateOnSaveAll || !this.royaltyFrequencyChangeReasonOnSaveAll || this.royaltyFrequencyChangeReasonOnSaveAll == null || this.royaltyFrequencyChangeReasonOnSaveAll.trim().length == 0)
            return false;
        return true;
    }

    async SaveFrequencyChangeReasonAndOtherContractDetails() {
        if (!this.royaltyEffectiveDateOnSaveAll) {
            this.deletemessage = "Effective Date is required.";
            return true;
        }
        else {
            this.deletemessage = "";
        }
        if (!this.royaltyFrequencyChangeReasonOnSaveAll || this.royaltyFrequencyChangeReasonOnSaveAll?.trim().length == 0) {
            this.message = "Reason for change is required.";
            //this.validateionPopupMessage("*Reason for Royalty Fee Frequency change is required.", "Error!", true);

            return true;
        }
        else if (this.royaltyFrequencyChangeReasonOnSaveAll?.length > 100) {
            this.message = "Reason for change is limited to 100 characters.";
            //this.validateionPopupMessage("*Reason for Royalty Fee Frequency change has to within 100 characters.", "Error!", true);

            return true;

        }
        else {
            this.royaltiesController.royaltyFrequencyChangeReason = this.royaltyFrequencyChangeReasonOnSaveAll;
            //this.royaltiesController.royaltyEffectiveDate = new Date(this.royaltyEffectiveDateOnSaveAll).toISOString().split('.')[0];
            if (this.royaltyEffectiveDateOnSaveAll) {
                const selectedDate = new Date(this.royaltyEffectiveDateOnSaveAll);
                this.royaltiesController.royaltyEffectiveDate = new Date(Date.UTC(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth(),
                    selectedDate.getDate()
                )).toISOString().split('.')[0];
                console.log("in cmsection_df save all " + this.royaltiesController.royaltyEffectiveDate);
            }
            else {
                this.royaltiesController.royaltyEffectiveDate = null;
            }
            this.message = "";
            this.deletemessage = "";
            this.SaveAll();
        }
    }

    CancelRoyaltyFrequencyChangePopup() {
        // this.royaltiesController.ResetRoyaltyFrequencyId();

        this.royaltyFrequencyChangePopup?.close();
    }

    ResetRoyaltyFrequencyId() {
        if (!this.isSaveAllClicked) {
            this.royaltiesController.ResetRoyaltyFrequencyId();
            this.royaltyFrequencyChangeReasonOnSaveAll = "";
            this.royaltyEffectiveDateOnSaveAll = "";
        }
        this.message = "";
        this.deletemessage = "";
    }

    SaveAll()
    {
        this.isSaveAllClicked = true;
        this.royaltyFrequencyChangePopup?.close();
        this.isSaveAllClicked = false;

        if (this.generalController.royaltyContract && this.generalController.royaltyContract.RoyaltyContractStatusID == 1) {
            if (this.generalController.royaltyContract.RoyaltyContractTerminatedReasonId == null) {
                return false;
            }
            if (this.generalController.royaltyContract.TerminatedDate == null || this.generalController.royaltyContract.TerminatedDate == "") {
                return false;
            }
        }


        let savePromise: ng.IPromise<any> = this.$q.resolve();

        if (this.generalController) {
            savePromise = savePromise.then(() => {
                return this.generalController.Save();
            });
        }
        if (this.royaltiesController) {
            savePromise = savePromise.then(() => {
                return this.royaltiesController.Save();
            });
        }
        if (this.territoryController) {
            savePromise = savePromise.then(() => {
                return this.territoryController.Save();
            });
        }
        this.royaltyFrequencyChangeReasonOnSaveAll = "";
        this.royaltyEffectiveDateOnSaveAll = "";
        return savePromise;
    }
    
    static BindComponent(app: ng.IModule)
    {
        app
            .component("cmSectionsDynamicFees", {
                templateUrl: '/Templates/ContractManagement/cmSections_df.html',
                controller: CmSectionsComponentDynamicFeesController,

            });
    }
}

