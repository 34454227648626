import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RoyaltyContractStateHandler } from "StateHandlers/Rms/RoyaltyContractStateHandler";
import { RoyaltyPeriodReportStateHandler } from "StateHandlers/Rms/RoyaltyPeriodReportStateHandler";
import { RoyaltyPeriodReportDetailsStateHandler } from "StateHandlers/Rms/RoyaltyPeriodReportDetailsStateHandler";
import { RoyaltyServiceCategoriesByFranchisorStateHandler } from "StateHandlers/Rms/RoyaltyServiceCategoriesByFranchisorStateHandler";
import { RoyaltyDisclosureDocumentStateHandler } from "StateHandlers/Rms/RoyaltyDisclosureDocumentStateHandler";
import { RoyaltyMethodologyTypesStateHandler } from "StateHandlers/Rms/RoyaltyMethodologyTypesStateHandler";
import { RoyaltyPeriodFrequenciesStateHandler } from "StateHandlers/Rms/RoyaltyPeriodFrequenciesStateHandler";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { RoyaltyPeriodReportDetailViewModel } from "Interfaces/FranForce/Rpm/PeriodReport/RatePlanItemModel";

export class RmsStateService
{
    royaltyContractStateHandler: RoyaltyContractStateHandler;
    royaltyDisclosureDocumentStateHandler: RoyaltyDisclosureDocumentStateHandler;
    royaltyMethodologyTypesStateHandler: RoyaltyMethodologyTypesStateHandler;
    royaltyPeriodFrequenciesStateHandler: RoyaltyPeriodFrequenciesStateHandler;
    royaltyPeriodReportStateHandler: RoyaltyPeriodReportStateHandler;
    royaltyPeriodReportDetailsStateHandler: RoyaltyPeriodReportDetailsStateHandler
    royaltyServiceCategoriesByFranchisorStateHandler: RoyaltyServiceCategoriesByFranchisorStateHandler;
    
    static $inject = [
        "$http",
        "$q",
        "apiConfig",
        "rpmUiApiResources",
        "royaltyApiResources"
    ]

    constructor(
        private $http: ng.IHttpService,
        private $q: ng.IQService,
        private apiConfig: ApiConfig,
        private rpmUiApiResources: RpmUiApiResources,
        private royaltyApiResources: RoyaltyApiResources
    )
    {
        this.royaltyContractStateHandler = new RoyaltyContractStateHandler(this.rpmUiApiResources);
        this.royaltyDisclosureDocumentStateHandler = new RoyaltyDisclosureDocumentStateHandler(this.rpmUiApiResources);
        this.royaltyMethodologyTypesStateHandler = new RoyaltyMethodologyTypesStateHandler(this.rpmUiApiResources);
        this.royaltyPeriodFrequenciesStateHandler = new RoyaltyPeriodFrequenciesStateHandler(this.rpmUiApiResources);
        this.royaltyPeriodReportStateHandler = new RoyaltyPeriodReportStateHandler(this.rpmUiApiResources);
        this.royaltyPeriodReportDetailsStateHandler = new RoyaltyPeriodReportDetailsStateHandler(this.royaltyApiResources);
        this.royaltyServiceCategoriesByFranchisorStateHandler = new RoyaltyServiceCategoriesByFranchisorStateHandler(this.rpmUiApiResources);
    }

    GetPeriodReportDetails(royaltyPeriodReportId: number, franchisorId: number, isDynamicFees?: boolean)
    {
        let results: Partial<{
            serviceCategories: RpmEntities.RoyaltyServiceCategoryViewModel[];
            royaltyPeriodReport: RpmEntities.RoyaltyPeriodReportViewModel;
            royaltyPeriodReportDetails: RoyaltyPeriodReportDetailViewModel[];
        }> = {};

        let promises = [
            this.royaltyServiceCategoriesByFranchisorStateHandler.Load(franchisorId)
                .then((categories) =>
                {
                    results.serviceCategories = categories;
                }),

            this.royaltyPeriodReportStateHandler.Load(royaltyPeriodReportId, isDynamicFees)
                .then((periodReport) =>
                {
                    results.royaltyPeriodReport = periodReport;
                }),

            this.royaltyPeriodReportDetailsStateHandler.Load(royaltyPeriodReportId, isDynamicFees)
                .then((details) =>
                {
                    console.log('details',details);
                    results.royaltyPeriodReportDetails = details;
                }),
        ]

        return this.$q.all(promises)
            .then(() =>
            {
                return results;
            });
    }
}