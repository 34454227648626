import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyManualSalesProcessStatusesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetAllRoyaltyManualSalesProcessStatuses(includeDeleted: boolean = false)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyManualSalesProcessStatuses",
            params: {
                includeDeleted: includeDeleted
            }
        };

        return this.$http<RpmEntities.usp_RoyaltyManualSalesProcessStatuses_GetAll_Result[]>(config);
    }
}