import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CallCenterStateService } from "Services/State/CallCenterState";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import {
GenderDto,
LanguageDto,
FranchiseProfileEmployeeBioEducationDetailDto,
EducationTypeDto,
FranchiseProfileEmployeeBioEducationDetailClient
} from '@nbly/franchise-employee-group-clients';
import {
FranchiseProfileEmployeeBioDto, FranchiseProfileEmployeeBioSearchDto,
FranchiseProfileEmployeeBioEducationDetailDto as FranchiseProfileEmployeeBioEducationDetailsDto
} from '@nbly/franchise-employee-orchestrations-clients'


export class EmployeeBiosEducationComponent implements ng.IController {
    //---- Bindings ----
    franchiseId: number;
    genderList: GenderDto[];
    languageList: LanguageDto[];
    conceptCode: string;
    educationTypeList: EducationTypeDto[];
    franchiseProfileEmployeeBioId: number;
    franchiseProfileEmployeeBioDetailId: number;
    employeeBioDetail: FranchiseProfileEmployeeBioDto;
    employeeBio: FranchiseProfileEmployeeBioSearchDto;
    onApiSave: () => void;
    //------------------

    conceptId: number;
    canRedThrottle: boolean;
    isLoading: boolean;
    educationGrid: kendo.ui.Grid;
    franchiseProfileEmployeeBioEducationDetailDto:any[];


    //Clients
    employeeOrchestrationsClient: AxiosInstance;
    employeeGroupClient: AxiosInstance;
    franchiseProfileEmployeeBioEducationDetailClient:FranchiseProfileEmployeeBioEducationDetailClient;

    static $inject = [
        "coreApiResources",
        "callCenterApiResources",
        "callCenterState",
        "identityManager",
        "apiConfig",
        "$timeout",
        "$scope",
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private callCenterApiResources: CallCenterApiResources,
        private callCenterState: CallCenterStateService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $timeout: ng.ITimeoutService,
        private $scope: ng.IScope
    ) {

    }

    async GenerateEducationDetailsDto(){
         this.franchiseProfileEmployeeBioEducationDetailDto=[]; 
        if (this.employeeBioDetail && this.employeeBioDetail.franchiseProfileEmployeeBioDetail
            && this.employeeBioDetail.franchiseProfileEmployeeBioDetail.franchiseProfileEmployeeBioEducationDetails.length)
            {
                //records exsits in the details
                this.educationTypeList.map((value,index)=>{
                    //find by id
                    let educationObject=this.employeeBioDetail.franchiseProfileEmployeeBioDetail.franchiseProfileEmployeeBioEducationDetails.find(obj => obj.educationTypeId == value.educationTypeId);
                    if(educationObject){
                        //if exsists
                        this.franchiseProfileEmployeeBioEducationDetailDto.push({
                            franchiseProfileEmployeeBioEducationDetailsId: educationObject.franchiseProfileEmployeeBioEducationDetailsId,
                            franchiseProfileEmployeeBioDetailsId: educationObject.franchiseProfileEmployeeBioDetailsId,
                            educationTypeId: educationObject.educationTypeId,
                            institutionName: educationObject.institutionName,
                            city: educationObject.city,
                            state: educationObject.state,
                            createDateTime: educationObject.createDateTime,
                            updateDateTime: null,
                            deletedDateTime: null,
                            isEducationType: educationObject.isEducationType,
                            description:value.description,
                        })
                    }else{
                        this.franchiseProfileEmployeeBioEducationDetailDto.push({
                            franchiseProfileEmployeeBioEducationDetailsId: 0,
                            franchiseProfileEmployeeBioDetailsId: this.employeeBioDetail.franchiseProfileEmployeeBioDetail.franchiseProfileEmployeeBioDetailsId,
                            educationTypeId: value.educationTypeId,
                            institutionName: '',
                            city: '',
                            state: '',
                            createDateTime: new Date().toISOString(),
                            updateDateTime: null,
                            deletedDateTime: null,
                            isEducationType: false,
                            description:value.description,
                        })
                    }
                })
            }else{
                //Record does not exsist, need to see 
                this.educationTypeList.map((value,index)=>{
                    this.franchiseProfileEmployeeBioEducationDetailDto.push({
                        franchiseProfileEmployeeBioEducationDetailsId: 0,
                        franchiseProfileEmployeeBioDetailsId: this.employeeBioDetail.franchiseProfileEmployeeBioDetail.franchiseProfileEmployeeBioDetailsId,
                        educationTypeId: value.educationTypeId,
                        institutionName: '',
                        city: '',
                        state: '',
                        createDateTime: new Date().toISOString(),
                        updateDateTime: null,
                        deletedDateTime: null,
                        isEducationType: false,
                        description:value.description,
                    })

                })
            }
    }

    $onInit() {
        this.employeeOrchestrationsClient = FranForceAxiosClientBuilder.BuildEmployeeOrchestrationsBaseClient();
        this.employeeGroupClient = FranForceAxiosClientBuilder.BuildEmployeeGroupBaseClient();

        this.franchiseProfileEmployeeBioEducationDetailClient = new FranchiseProfileEmployeeBioEducationDetailClient("", this.employeeGroupClient);
        this.GenerateEducationDetailsDto();

    }

    $postLink() {
        this.InitGridOptions();
    }

 
    InitGridOptions() {
        function textareaEditor1(container, options) {
            $('<input type="checkbox" id="txtAreaValue" name="' + options.field + '" style="height:' + 13+ 'px" />').appendTo(container);
          } 
        let columns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<FranchiseProfileEmployeeBioEducationDetailDto>(o => o.franchiseProfileEmployeeBioEducationDetailsId),
                title: "franchiseProfileEmployeeBioEducationDetailsId",
                hidden: true
            },
            {
                field: nameof<FranchiseProfileEmployeeBioEducationDetailDto>(o => o.educationTypeId),
                title: "Id",
                hidden: true
            },
            {
                field: nameof<EducationTypeDto>(o => o.description),
                title: "Education Level",
                
            }, {
                field: nameof<FranchiseProfileEmployeeBioEducationDetailDto>(o => o.isEducationType),
                title: "Yes/No",
                editor: textareaEditor1,
               template: '<input ng-model="dataItem.isEducationType" type="checkbox" disabled></input>', 
                
          
            }, {
                field: nameof<FranchiseProfileEmployeeBioEducationDetailDto>(o => o.institutionName),
                title: "Institution"
            },{
                field: nameof<FranchiseProfileEmployeeBioEducationDetailDto>(o => o.city),
                title: "City"
            },
            {
                field: nameof<FranchiseProfileEmployeeBioEducationDetailDto>(o => o.state),
                title: "State"
            },
            { command: ["edit"], title: "&nbsp;", width: "200px" }
        ];

        let datasource = new kendo.data.DataSource({
            data:this.franchiseProfileEmployeeBioEducationDetailDto,
            autoSync:false,
            schema: {
                model: {
                    id: 'educationTypeId',
                    fields: {
                        franchiseProfileEmployeeBioEducationDetailsId: {
                            type: "number",
                            editable: false,
                 
                        },
                        educationTypeId: {
                            type: "number",
                            editable: false,
                 
                        },
                        description: {
                            type: "string",
                            editable: false,
                            defaultValue: 0
                        },
                        isEducationType: {
                            type: "boolean",
                            validation: { required: false },
                            editable: true
                        },
                        institutionName: {
                            type: "string",
                            validation: { required: true },
                            editable: true
                        },
                        city: {
                            type: "string",
                            validation: { required: true },
                            editable: true
                        },
                        state: {
                            type: "string",
                            validation: { required: true },
                            editable: true
                        },
                    }
                }
            },
       
  
        });


        let options = <kendo.ui.GridOptions>{
            columns: columns,
            dataSource: datasource,
            headerAttributes: {
                style: "background-color:#1984c8;"
            },
            resizable: true,
            scrollable: false,
            sortable: false,
            filterable: false,
            pageable: false,
            autoBind:true,
            editable: "inline"
            
        }

        this.educationGrid.setOptions(options);
    }

    async saveEducationDetails(){

       await Promise.all(
            this.franchiseProfileEmployeeBioEducationDetailDto.map(async (value,index)=>{
                if(value.franchiseProfileEmployeeBioEducationDetailsId == 0){
                    //make Post Call
                    await this.franchiseProfileEmployeeBioEducationDetailClient.franchiseProfileEmployeeBioEducationDetails(
                        value
                    ).then((response)=>{
                        
                        this.franchiseProfileEmployeeBioEducationDetailDto[index].franchiseProfileEmployeeBioEducationDetailsId=response.result.franchiseProfileEmployeeBioEducationDetailsId;
                       let res=response.result;
                       let FranchiseProfileEmployeeBioEducationDetailsDto:FranchiseProfileEmployeeBioEducationDetailsDto;
                       FranchiseProfileEmployeeBioEducationDetailsDto={
                        franchiseProfileEmployeeBioEducationDetailsId: res.franchiseProfileEmployeeBioEducationDetailsId,
                        franchiseProfileEmployeeBioDetailsId: res.franchiseProfileEmployeeBioDetailsId ,
                        educationTypeId: res.educationTypeId,
                        institutionName: res.institutionName ,
                        city: res.city,
                        state: res.state,
                        createDateTime: res.createDateTime,
                        updateDateTime: res.updateDateTime ,
                        deletedDateTime: res.deletedDateTime ,
                        isEducationType: res.isEducationType ,
                        educationType:this.educationTypeList.find(obj => obj.educationTypeId==response.result.educationTypeId).description
                       }
                       this.employeeBioDetail.franchiseProfileEmployeeBioDetail.franchiseProfileEmployeeBioEducationDetails.push(FranchiseProfileEmployeeBioEducationDetailsDto);
                    }) 
    
                }else{
                    //put call
                    await this.franchiseProfileEmployeeBioEducationDetailClient.franchiseProfileEmployeeBioEducationDetails2(
                        value.franchiseProfileEmployeeBioEducationDetailsId,value
                    ).then((response)=>{
                        
                    }) 
                }
            })
          ).then(()=>{
              this.educationGrid.refresh();
              this.educationGrid.dataSource.read()
            this.onApiSave();
          })
        
    }

    static BindComponent(app: ng.IModule) {
        app.component("employeeBiosEducation", {
            bindings: {
                franchiseId: "<",
                [nameof<EmployeeBiosEducationComponent>(o => o.conceptCode)]: "<",
                [nameof<EmployeeBiosEducationComponent>(o => o.conceptCode)]: "<",
                [nameof<EmployeeBiosEducationComponent>(o => o.educationTypeList)]: "<",
                [nameof<EmployeeBiosEducationComponent>(o => o.franchiseProfileEmployeeBioDetailId)]: "<",
                [nameof<EmployeeBiosEducationComponent>(o => o.employeeBioDetail)]: "=",
                [nameof<EmployeeBiosEducationComponent>(o => o.employeeBio)]: "=",
                onApiSave: "&",
            },
            controller: EmployeeBiosEducationComponent,
            templateUrl: "/Templates/EmployeeBios/EmployeeBiosEducation.html",
        });
    }
}

