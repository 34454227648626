import * as elements from 'typed-html';

import { CoreApiResources } from "Services/Resources/CoreApiResources";
import * as _ from "underscore"
import { AngularUtil } from "Utility/AngularUtil";
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { IdentityManager } from '../../Services/Resources/IdentityManager';
import { ApiConfig } from '../../AppConfig/ApiConfig';

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            franchiseProfileWebsiteDetails: Partial<FranchiseProfileWebsiteDetails>;
        }
    }
}

export class FranchiseProfileWebsiteDetails implements ng.IController
{
    franchiseId: number;
    franchiseProfile: CoreResources.IFranchiseProfile;

    websiteMessageEditor: kendo.ui.Editor;

    isEditable: boolean = false;
    isLoading: boolean = false;

    static $inject = [
        'coreApiResources',
        'identityManager',
        'apiConfig',
        '$timeout'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $timeout: ng.ITimeoutService
    )
    {

    }

    async $onInit()
    {
    }

    async $postLink()
    {
        this.RefreshEditors();

        let user = (await this.identityManager.GetLoggedInUserInfo()).data;
        this.isEditable = user.Roles.some(r =>
        {
            return [
                this.apiConfig.FranForceConstants.RoleConstants.AdminRole,
                this.apiConfig.FranForceConstants.RoleConstants.SupportAdminRole,
                this.apiConfig.FranForceConstants.RoleConstants.ConceptManagerRole,
            ].some(constRole => constRole == r.Name);
        });

        if (!this.isEditable)
        {
            this.websiteMessageEditor.body.setAttribute("contenteditable", "false");
        }
    }

    async $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.franchiseId)] && this.franchiseId)
        {
            this.franchiseProfile = await AngularUtil.TrackLoadingPromise(
                this.coreApiResources.FranchiseProfileApi.get(
                    {
                        id: this.franchiseId,
                        $select: [
                            nameof<CoreEntities.FranchiseProfile>(o => o.FranchiseId),
                            nameof<CoreEntities.FranchiseProfile>(o => o.WebsiteMessageActive),
                            nameof<CoreEntities.FranchiseProfile>(o => o.WebsiteMessage),
                        ].join(",")
                    }).$promise,
                this
            ); 
        }
    }

    RefreshEditors()
    {
        return this.$timeout(() =>
        {
            this.websiteMessageEditor.refresh();
        }, 0)
    }

    async SaveMessage()
    {
        return await this.coreApiResources.SaveFranchiseProfileWebsiteMessage(
            this.franchiseProfile.FranchiseId,
            this.franchiseProfile.WebsiteMessage,
            this.franchiseProfile.WebsiteMessageActive
        );
    }

    static BindComponent(app: ng.IModule)
    {
        let template = (
            <div>
                <loadingGearsOverlay is-loading="$ctrl.isLoading"></loadingGearsOverlay>

                <div class="mt-2">
                    <button
                        class="pure-button"
                        ng-bind={`$ctrl.${nameof.full<FranchiseProfileWebsiteDetails>(o => o.franchiseProfile.WebsiteMessageActive)} ? 'Remove Website Message' : 'Add Website Message'`}
                        ng-click={`$ctrl.${nameof.full<FranchiseProfileWebsiteDetails>(o => o.franchiseProfile.WebsiteMessageActive)} = !$ctrl.${nameof.full<FranchiseProfileWebsiteDetails>(o => o.franchiseProfile.WebsiteMessageActive)}`}>
                    </button>
                </div>

                <div class="mt-2" ng-show={`$ctrl.${nameof.full<FranchiseProfileWebsiteDetails>(o => o.franchiseProfile.WebsiteMessageActive)}`}>
                    <div>
                        <strong>Website Message</strong>
                    </div>

                    <div class="mt-2">
                        <textarea
                            kendo-editor={`$ctrl.${nameof.full<FranchiseProfileWebsiteDetails>(o => o.websiteMessageEditor)}`}
                            rows="6"
                            k-ng-model={`$ctrl.${nameof.full<FranchiseProfileWebsiteDetails>(o => o.franchiseProfile.WebsiteMessage)}`}></textarea>
                    </div>
                </div>

                <div class="mt-2">
                    <button
                        class="pure-button button-secondary"
                        ng-click={`$ctrl.${nameof<FranchiseProfileWebsiteDetails>(o => o.SaveMessage)}()`}
                        promise-btn>
                        Save Website Message
                    </button>
                </div>
            </div>
        );

        app.component('franchiseProfileWebsiteDetails', {
            bindings: {
                [nameof<FranchiseProfileWebsiteDetails>(o => o.franchiseId)]: "<"
            },
            controller: FranchiseProfileWebsiteDetails,
            template: template
        });
    }
}