import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import * as _ from "underscore"
import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import
{
    PhotoGalleryCategoryConceptXrefWithCategoryDescDto,
    PhotoGalleryImageInfoDto, PhotoGalleryImageInfoClient
} from '@nbly/service-orchestrations-clients';

export class PhotoEditorGridViewComponent implements ng.IController
{

    photoGalleryCategoryConcepts: PhotoGalleryCategoryConceptXrefWithCategoryDescDto[];
    photoGalleryImageInfoDto: PhotoGalleryImageInfoDto[];
    openPhotoEditorPopup: any;
    selectedPhoto: any;
    isLoading: boolean;
    //Clients
    serviceOrchestrationsClient: AxiosInstance;
    photoGalleryImageInfoClient: PhotoGalleryImageInfoClient;


    static $inject = [
        "$scope",
    ];


    constructor(
        private $scope: ng.IScope
    )
    {
        this.serviceOrchestrationsClient = FranForceAxiosClientBuilder.BuildServiceOrchestrationsBaseClient();
        this.photoGalleryImageInfoClient = new PhotoGalleryImageInfoClient("", this.serviceOrchestrationsClient);
    }


    async $onInit()
    {


    }

    $postLink()
    {

    }

    openPopUp(photos: any)
    {

        this.selectedPhoto = photos
        this.openPhotoEditorPopup(this.selectedPhoto);
    }

    async deletePhoto(photo: { photo: PhotoGalleryImageInfoDto })
    {

        if (photo && photo.photo.photoGalleryImageInfoId)
        {
            await this.photoGalleryImageInfoClient.photoGalleryImageInfo4(photo.photo.photoGalleryImageInfoId).then((response) =>
            {
                this.photoGalleryImageInfoDto;

                this.photoGalleryImageInfoDto = this.photoGalleryImageInfoDto.filter(function (imageInfo)
                {
                    return imageInfo.photoGalleryImageInfoId !== photo.photo.photoGalleryImageInfoId;
                });
                this.$scope.$digest();

            }).catch((error) =>
            {
                console.log('Error occured while deleteting the imge', error)
            })
        }

    }



    static BindComponent(app: ng.IModule)
    {
        app.component('photoEditorGridViewComponent', {
            bindings: {
                photoGalleryCategoryConcepts: "<",
                photoGalleryImageInfoDto: "=",
                openPhotoEditorPopup: "&"


            },
            controller: PhotoEditorGridViewComponent,
            templateUrl: "/Templates/PhotoEditor/PhotoEditorGridViewComponent.html"
        });
    }
}


