export class KendoUtil
{
    static readonly storagePrefix = "GridOptions.";
    static readonly storageFiltersPrefix = KendoUtil.storagePrefix + "Filters.";
    static readonly storageSortsPrefix = KendoUtil.storagePrefix + "Sorts.";

    static SaveGridFilters(grid: kendo.ui.Grid, gridId: string = null)
    {
        if (!gridId)
            gridId = grid.element.attr("id");

        let dataSource = <kendo.data.DataSource>grid.getOptions().dataSource;
        if (!dataSource.filter)
        {
            KendoUtil.ClearSavedGridFilters(gridId);
            return;
        }

        if (typeof dataSource.filter === "function") {
            localStorage.setItem(KendoUtil.storageFiltersPrefix + gridId, kendo.stringify(dataSource.filter()));
        }
        else if (typeof dataSource.filter === "object") {
            localStorage.setItem(KendoUtil.storageFiltersPrefix + gridId, kendo.stringify(dataSource.filter));
        }
    }

    static LoadGridFilters(grid: kendo.ui.Grid, gridId: string = null)
    {
        if (!gridId)
            gridId = grid.element.attr("id");

        let options = localStorage.getItem(KendoUtil.storageFiltersPrefix + gridId);
        if (options) {
            grid.dataSource.filter(<kendo.data.DataSourceFilters>JSON.parse(options));
        }
        
    }

    static ClearSavedGridFilters(gridOrId: kendo.ui.Grid | string)
    {
        let gridId: string;

        if (!gridOrId)
            return;

        if (typeof gridOrId !== "string") {
            gridId = gridOrId.element.attr("id");
        }

        localStorage.removeItem(KendoUtil.storageFiltersPrefix + gridId);
    }

    static SaveGridSorts(grid: kendo.ui.Grid, gridId: string = null)
    {
        if (!gridId)
            gridId = grid.element.attr("id");

        let dataSource = <kendo.data.DataSource>grid.getOptions().dataSource;
        if (!dataSource.sort) {
            KendoUtil.ClearSavedGridSorts(gridId);
            return;
        }

        if (typeof dataSource.filter === "function") {
            localStorage.setItem(KendoUtil.storageSortsPrefix + gridId, kendo.stringify(dataSource.sort()));
        }
        else if (typeof dataSource.filter === "object") {
            localStorage.setItem(KendoUtil.storageSortsPrefix + gridId, kendo.stringify(dataSource.sort));
        }
    }

    static LoadGridSorts(grid: kendo.ui.Grid, gridId: string = null)
    {
        if (!gridId)
            gridId = grid.element.attr("id");

        let options = localStorage.getItem(KendoUtil.storageSortsPrefix + gridId);
        if (options) {
            grid.dataSource.sort(JSON.parse(options));
        }
    }

    static ClearSavedGridSorts(gridOrId: kendo.ui.Grid | string)
    {
        let gridId: string;

        if (!gridOrId)
            return;

        if (typeof gridOrId !== "string") {
            gridId = gridOrId.element.attr("id");
        }

        localStorage.removeItem(KendoUtil.storageSortsPrefix + gridId);
    }

    static GetDefaultKendoGridFilterOperators() {
        return {
            string: {
                contains: "Contains",
                doesnotcontain: "Does not contain",
                eq: "Is equal to",
                neq: "Is not equal to",
                startswith: "Starts with",
                endswith: "Ends with",
                isnull: "Is null",
                isnotnull: "Is not null",
                isempty: "Is empty",
                isnotempty: "Is not empty"
            }
        };
    }

    static GetDefaultKendoGridPageable(defaultPageSize: number): kendo.ui.GridPageable
    {
        return {
            numeric: false,
            pageSize: defaultPageSize,
            pageSizes: [10, 25, 50, 100, 250, 500, "all"],
            input: true
        };
    }

    /**
     * Simplifies the logical wrappers needed behind a grid data source definition. Just the options and the logic with return data needs to be specified.
     * @param resultsFunc returns the data to the grid.
     */
    static AsyncDataSourceTransportTryWrap<TData>(resultsFunc: (options?: Omit<kendo.data.DataSourceTransportOptions, "success" | "error">) => Promise<TData>, finallyFunc: () => any = null)
    {
        return async (options: kendo.data.DataSourceTransportOptions) =>
        {
            try
            {
                let results = await resultsFunc(options);
                options.success(results);
            }
            catch (ex)
            {
                options.error(ex);
            }
            finally
            {
                if (finallyFunc)
                    finallyFunc();
            }
        }
    }
}

export function BindDropDownEditor(
    container: Parameters<kendo.ui.GridColumn["editor"]>[0],
    options: Parameters<kendo.ui.GridColumn["editor"]>[1],
    dropDownOptions: kendo.ui.DropDownListOptions
)
{
    let input = $(`<input name="${options.field}" />`);
    input.appendTo(container);

    let element = input.kendoDropDownList(dropDownOptions);
    return {
        jqueryElement: element,
        kendoDropDownList: element.data("kendoDropDownList")
    };
}