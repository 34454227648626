import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { RestAxiosClient } from "Clients/RestAxiosClient";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { IRestAxiosQueryClient, IRestAxiosGetClient, IRestAxiosQueryRequestClient } from "Clients/IRestAxiosClient";
import { AxiosRequestConfig, AxiosInstance } from "axios";

export class RmsFranchiseClient implements
    IRestAxiosQueryClient<RpmEntities.RmsFranchiseExtension>,
    IRestAxiosGetClient<RpmEntities.RmsFranchiseExtension, number>

{
    private client: RestAxiosClient<RpmEntities.RmsFranchiseExtension, number>;

    constructor()
    {
        this.client = new RestAxiosClient(
            FranForceAxiosClientBuilder.BuildCoreBaseClient(),
            "/RmsFranchises"
        );
    }

    async Get(id: number, parameters: ODataQueryParameters, config?: AxiosRequestConfig): Promise<RpmEntities.RmsFranchiseExtension>
    {
        return this.client.Get(id, parameters, config);
    }
    async Query(parameters: ODataQueryParameters, config?: AxiosRequestConfig): Promise<RpmEntities.RmsFranchiseExtension[]>
    {
        return this.client.Query(parameters, config);
    }
}
