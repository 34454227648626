let crProductsControllerConfig = [
    '$scope', 'ngDialog',
    function ($scope, ngDialog)
    {

        // PROD Grid Config
        $scope.prodGridDataSource = new kendo.data.DataSource({
            batch: true,
            pageSize: 20,
            schema: {
                model: {
                    id: "prodID",
                    fields: {
                        ProdID: { editable: false, nullable: true },
                        Product: { type: "string", validation: { required: true } },
                        State: { type: "string", validation: { required: true } },

                    }
                }
            },
            data: [{
                product: "Royalty Fees",
                state: "TX"
            }, {
                product: "Royalty Fees",
                state: "PR"
            }, {
                product: "Taxes",
                state: "TX"
            }, {
                product: "Royalty Fees",
                state: "VA"
            }, {
                product: "Taxes",
                state: "NY"
            }]
        });

        let prodColumns: Array<kendo.ui.GridColumn> =
            [{
                field: "product",
                title: "Product"
            }, {
                field: "state",
                title: "State"
            },
            {
                command: [{ name: "edit" }, { name: "destroy" }],
                title: "&nbsp;",
                width: "250px"
            }]

        $scope.prodGridOptions = {
            columns: prodColumns,
            dataSource: $scope.prodGridDataSource,
            editable: "inline",
            sortable: true,
            pageable: true,
        };

        // SAVE & CANCEL LOGIC
        $scope.$on('saveClicked', function (event, data)
        {
            debugger;
        });
        $scope.$on('cancelClicked', function (data)
        {
            debugger;
        });
    }
];
export { crProductsControllerConfig };