import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyKeyValuePairsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetAllRoyaltyKeyValuePairs()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyKeyValuePairs"
        };

        return this.$http<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result[]>(config);
    }

    GetRoyaltyKeyValuePairById(royaltyKeyValuePairId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyKeyValuePairs/" + royaltyKeyValuePairId
        };

        return this.$http<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result>(config);
    }
}