export class rmsApprovalComponentController implements ng.IController {

    static $inject = [
    ];

    constructor() {

    }

    $onInit() {

    }

    $postLink() {

    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsApproval", {
            bindings: {

            },
            templateUrl: '/Templates/RMSApproval/rmsApproval.html',
            controller: rmsApprovalComponentController
        });
    }
}