import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";


export class FranchiseProfileContactsListComponent implements ng.IController 
{
    franchiseId: number;
    onContactSave: (params: { }) => void

    contact: CoreResources.IFranchiseProfileContact;
    isLoading: boolean;
    contactWindow: kendo.ui.Window;
    contactGrid: kendo.ui.Grid;
    positionDropDown: kendo.ui.DropDownList;
    positionDropDownOptions: kendo.ui.DropDownListOptions;
    
    static $inject = [
        'coreApiResources',
        '$q',
        '$log',
        '$timeout'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService
    )
    {
    }

    $onInit()
    {
        this.InitPositionDropDown();
    }

    $postLink()
    {
        this.InitGrid();
        this.InitWindow();
    }

    InitGrid()
    {
        let dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) =>
                {
                    let queryParams: ODataQueryParameters = {
                        $filter: `${nameof<CoreEntities.FranchiseProfileContact>(o => o.FranchiseId)} eq ${this.franchiseId}`,
                        $expand: `${nameof<CoreEntities.FranchiseProfileContact>(o => o.FranchiseProfileContactPosition)}($select=${nameof<CoreEntities.FranchiseProfileContact>(o => o.FranchiseProfileContactPosition.DisplayName)})`
                    }
                    this.coreApiResources.FranchiseProfileContactApi.query(queryParams).$promise
                        .then((contactsResponse) =>
                        {
                            for (let contact of contactsResponse)
                            {
                                if (!contact.FranchiseProfileContactPosition) {
                                    contact.FranchiseProfileContactPosition = <any>{};
                                    contact.FranchiseProfileContactPosition.DisplayName = "";
                                }
                            }

                            options.success(contactsResponse);
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        })
                }
            },
            sort: { field: nameof<CoreEntities.FranchiseProfileContact>(o => o.Name), dir: "asc" }
        });

        let columns: kendo.ui.GridColumn[] = [ {
                field: nameof<CoreEntities.FranchiseProfileContact>(o => o.Name),
                title: "Name"
            }, {
                field: nameof.full<CoreEntities.FranchiseProfileContact>(o => o.FranchiseProfileContactPosition.DisplayName),
                title: "Position"
            }, {
                field: nameof<CoreEntities.FranchiseProfileContact>(o => o.Phone),
                title: "Phone",
                // -- Reenable when confirmations are going out for phone numbers and email addresses -- 

                //template: `<span kendo-tooltip k-content="'This phone number has ' + (dataItem.${nameof<CoreEntities.FranchiseProfileContact>(o => o.IsTextConfirmed)} ? '' : 'not ') + 'been confirmed.'">` +
                //    `<i ng-class="dataItem.${nameof<CoreEntities.FranchiseProfileContact>(o => o.IsTextConfirmed)} ? 'fa fa-check-square-o icon-success' : 'fa fa-square-o icon-error'" aria-hidden="true"></i> ` +
                //    `<span ng-bind="dataItem.${nameof<CoreEntities.FranchiseProfileContact>(o => o.Phone)}"></span>` +
                //    `</span>`
            }, {
                field: nameof<CoreEntities.FranchiseProfileContact>(o => o.Email),
                title: "Email",
                // -- Reenable when confirmations are going out for phone numbers and email addresses -- 

                //template: `<span kendo-tooltip k-content="'This email address has ' + (dataItem.${nameof<CoreEntities.FranchiseProfileContact>(o => o.IsEmailConfirmed)} ? '' : 'not ') + 'been confirmed.'">` +
                //    `<i ng-class="dataItem.${nameof<CoreEntities.FranchiseProfileContact>(o => o.IsEmailConfirmed)} ? 'fa fa-check-square-o icon-success' : 'fa fa-square-o icon-error'" aria-hidden="true"></i> ` +
                //    `<span ng-bind="dataItem.${nameof<CoreEntities.FranchiseProfileContact>(o => o.Email)}"></span>` + 
                //    `</span>`
            }, {
                template: `<button class='pure-button' ng-click='$ctrl.${nameof(this.OpenEditContactWindow)}(dataItem)'><i class='fa fa-pencil'></i></button>`,
                width: 60
            }, {
                template: `<button class='pure-button button-error' ng-click='$ctrl.${nameof(this.DeleteContact)}(dataItem)'>X</button>`,
                width: 60
            }
        ];

        let options: kendo.ui.GridOptions = {
            dataSource: dataSource,
            columns: columns,
            toolbar: [
                {
                    template: "<button class='pure-button button-secondary' ng-click='$ctrl.OpenNewContactWindow()'>New Contact...</button>"
                }
            ],
            sortable: true,
            filterable: true
        };
        this.contactGrid.setOptions(options);
    }

    InitWindow()
    {
        let options: kendo.ui.WindowOptions = {
            modal: true
        };
        this.contactWindow.setOptions(options);
    }

    InitPositionDropDown()
    {
        this.positionDropDownOptions = {
            optionLabel: "Select Position...",
            optionLabelTemplate: "Select Position...",
            autoWidth: true,
            valuePrimitive: true,
            dataTextField: nameof<CoreEntities.FranchiseProfileContactPosition>(o => o.DisplayName),
            dataValueField: nameof<CoreEntities.FranchiseProfileContactPosition>(o => o.FranchiseProfileContactPositionId),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) =>
                    {
                        this.coreApiResources.FranchiseProfileContactPositionApi.query({}).$promise
                            .then((positions) =>
                            {
                                options.success(positions);
                            })
                            .catch((err) =>
                            {
                                options.error(err);
                            })
                    }
                }
            })
        }
    }

   

    OpenNewContactWindow()
    {
        this.contact = <any>{};
        this.contact.FranchiseId = this.franchiseId;
        this.contact.FranchiseProfileContactId = 0;
        this.contact.FranchiseProfileContactNotificationTypeId = 3; //Notify Always. 
        this.contact.FranchiseProfileContactPositionId = 0;

        this.contactWindow.open();
        this.$timeout(() =>
        {
            this.contactWindow.center();
        });
    }

    OpenEditContactWindow(contact: CoreResources.IFranchiseProfileContact)
    {
        this.contact = angular.copy(contact);
        this.contact.FranchiseProfileContactPosition = null;
        this.contactWindow.open();
        this.$timeout(() =>
        {
            this.contactWindow.center();
        });
    }

    SaveContact()
    {
        let promise: ng.IPromise<any>;
        if (this.contact.FranchiseProfileContactId) {
            promise = this.coreApiResources.FranchiseProfileContactApi.update({ id: this.contact.FranchiseProfileContactId }, this.contact).$promise;
        }
        else {
            promise = this.coreApiResources.FranchiseProfileContactApi.save({ }, this.contact).$promise;
        }

        return promise
            .then(() =>
            {
                this.contactWindow.close();
                this.ResetGrid()
                    .then(() =>
                    {
                        if (this.onContactSave) {
                            this.onContactSave({});
                        }
                    });
            });
    }

    DeleteContact(contact: CoreResources.IFranchiseProfileContact)
    {
        if (confirm(`Are you sure you want to delete the contact: '${contact.Name}'?`))
        {
            let params: ODataQueryParameters = {
                $filter: nameof<CoreResources.IFranchiseProfileContactAssignment>(o => o.FranchiseWebLocationFranchiseProfilesContactId) + " eq " + contact.FranchiseProfileContactId  
            };

            return this.coreApiResources.FranchiseProfileContactAssignmentApi.query(params).$promise
                .then((assignments) =>
                {
                    if (assignments.length > 0)
                    {
                        let rejectionMessage = `There are ${assignments.length} assignments attached to this contact. Delete those assignments before deleting this contact.`;
                        alert(rejectionMessage);
                        return this.$q.reject(rejectionMessage);
                    }
                    return this.$q.resolve();
                })
                .then(() =>
                {
                    return this.coreApiResources.FranchiseProfileApi.get({ id: this.franchiseId }).$promise
                        .then((profile) =>
                        {
                            if (profile.FinalNotificationFranchiseProfileContactId === contact.FranchiseProfileContactId)
                            {
                                let rejectionMessage = `You can't delete the final notification contact.`;
                                alert(rejectionMessage);
                                return this.$q.reject(rejectionMessage);
                            }
                            return this.$q.resolve();
                        })
                })
                .then(() =>
                {
                    return this.coreApiResources.FranchiseProfileContactApi.delete({ id: contact.FranchiseProfileContactId }).$promise
                        .then(() =>
                        {
                            this.ResetGrid()
                        });
                });
        }
    }

    ResetGrid()
    {
        return this.$q.when(this.contactGrid.dataSource.read())
            .then(() =>
            {
                this.contactGrid.refresh();
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfileContactsList', {
            bindings: {
                [nameof<FranchiseProfileContactsListComponent>(o => o.franchiseId)]: "<",
                [nameof<FranchiseProfileContactsListComponent>(o => o.onContactSave)]: "&?",
            },
            controller: FranchiseProfileContactsListComponent,
            templateUrl: "/Templates/FranchiseProfiles/franchiseProfileContactsList.html"
        });
    }
}


