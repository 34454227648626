import * as moment from "moment";

export class MomentUtil
{
    static ToLocalTime(date: string)
    {
        let utc = moment.utc(date);
        return utc.isValid() ? utc.toDate().toISOString() : date;
    }

    static EnsureUtcTime(date: string)
    {
        let utc = moment.utc(date);
        return utc.isValid() ? utc.toISOString() : date;
    }
}