import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { Constants } from 'Constants';
import * as _ from "underscore"
import { ServiceOrchestrationApiResources } from "Services/Resources/ServiceOrchestrationApiResources";

export class ComplianceEmailsComponent implements ng.IController
{

    franchiseId: number;
    complianceEmails:CoreEntities.FranchiseEmail[]=[];
    isLoading: boolean;
    DSAREmailsList:CoreEntities.FranchiseEmail[]=[];
    

    static $inject = [
        '$scope',
        '$window',
        '$q',
        '$log',
        'serviceOrchestrationApiResources',
        '$http',
        'coreApiResources',
        'odataHelper',
        "$timeout",
    ];

    constructor(
        private $scope: ng.IScope,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private serviceOrchestrationApiResources: ServiceOrchestrationApiResources,
        private $http: ng.IHttpService,
        private coreApiResources: CoreApiResources,
        private odataHelper: ODataHelperService,
        private $timeout: ng.ITimeoutService
        )

    {

  


    }


    async $onInit()
    {
        this.setDummyComplianceEmailObjects();
        await this.GetFranchises(this.franchiseId)
    }
    async saveComplianceEmails(){

        await Promise.all(
            this.complianceEmails.map(async (emails,index) => {
                if((emails.CreatedDateTime === undefined || emails.FranchiseEmailId === 0)
                    && emails.EmailAddress ){
                    // Post Call
                    return this.coreApiResources.FranchiseEmailApi.save({ id: emails.FranchiseEmailId }, emails);
                }else{
                    if(emails.EmailAddress !== this.DSAREmailsList[index]?.EmailAddress){
                        // Email address modified, update it
                    if(emails.EmailAddress === "" && emails.CreatedDateTime){
                        //Delete the record
                        return    this.coreApiResources.FranchiseEmailApi.
                        delete({id:emails.FranchiseEmailId},emails);
                    }
                    else if(emails.EmailAddress && emails.CreatedDateTime){
                        return     this.coreApiResources.FranchiseEmailApi.
                        update({id:emails.FranchiseEmailId},emails);
    
                    }

                }

            }
            })

        ).then(async () => {
            
            this.$timeout(() => {
                this.GetFranchises(this.franchiseId).then(()=>{
                    this.$scope.$digest();
                    document.dispatchEvent(new Event('apiSuccess'));
                });
            }, 100);
           


        }).catch((error)=>{
            this.GetFranchises(this.franchiseId).then(()=>{
                document.dispatchEvent(new Event('apiError'));
            });
          
        })

    }

    setDummyComplianceEmailObjects(){
        for (let i = 0; i < 3; ++i) {
            this.complianceEmails[i]={
                FranchiseEmailId: 0,
                FranchiseId: this.franchiseId,
                EmailProcessTypeID: Constants.DSAREmailProcessTypeID,
                EmailAddress: null,
                Description: `${this.franchiseId}_complianceEmail`,
                SendAsBCC: false,
                DeletedDateTime: undefined,
                CreatedDateTime: undefined,
                CreatedUser: undefined,
                UpdatedDateTime: undefined,
                UpdatedUser: undefined,
                EmailProcessType: null,
                Franchise: null
            }
                        
        }

    }

   async GetFranchises(franchiseId: number) {

        this.isLoading = true;
        this.setDummyComplianceEmailObjects();
    
        return this.coreApiResources.FranchiseEmailApi.query({
            $filter: `FranchiseId eq ${franchiseId} and EmailProcessTypeID eq ${Constants.DSAREmailProcessTypeID}`,
        }).$promise.then(
            (results: CoreResources.IFranchiseEmail[]) =>
            {
                this.DSAREmailsList=results;
                if(this.DSAREmailsList && this.DSAREmailsList.length){
                    for (let i = 0; i < this.DSAREmailsList.length && i<3; ++i) {
                       this.complianceEmails[i]=angular.copy(this.DSAREmailsList[i]);                   
                    }
                }
                this.isLoading = false;
            },
            (err) => {
                console.error(err);
                 this.isLoading = false; 
                alert("Could not get franchises for concept.");
            });
            
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("complianceEmailsComponent", {
            bindings: {
                franchiseId: "<",
            },
            controller: ComplianceEmailsComponent,
            templateUrl: "/Templates/FranchiseEmails/ComplianceEmails.html",
        });
    }
}

