import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import * as _ from "underscore"


export class FranchiseProfileContactsComponent implements ng.IController
{
    franchiseId: number;

    contactAssignmentTypeDropDown: kendo.ui.DropDownList;
    contactAssignmentTypeDropDownOptions: kendo.ui.DropDownListOptions;
    selectedAssignmentType: CoreResources.IFranchiseProfileContactAssignmentType;

    static $inject = [
        'coreApiResources'
    ];

    constructor(
        private coreApiResources: CoreApiResources
    )
    {

    }

    $onInit()
    {
        const removeAssignmentTypeIds = [
            15, //Other
            13, //Sales / Marketing
            14, //Transfer
            11, //Franchise Interest
            12, //Personal Call
            10  //Service Call
        ];

        const rpmAssignmentTypeIds = [
            16, //A Current Client
            17, // Interested in Renting
            18, //Current Tenant
            19, // Real Estate Agent
            20, //Interested in Property Management
            21 // General Contact
        ];

        this.contactAssignmentTypeDropDownOptions = {
            dataValueField: nameof<CoreResources.IFranchiseProfileContactAssignmentType>(o => o.FranchiseProfileContactAssignmentTypeId),
            dataTextField: nameof<CoreResources.IFranchiseProfileContactAssignmentType>(o => o.DisplayName),
            autoWidth: true,
            height: 400,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: async (options) =>
                    {
                        let conceptId = await this.coreApiResources.FranchiseApi.get({id: this.franchiseId, $select: nameof<CoreResources.IFranchise>(o => o.ConceptId) })
                            .$promise.then(f => f.ConceptId);
                        let assignmentTypes = await this.coreApiResources.FranchiseProfileContactAssignmentTypeApi.query({}).$promise
                            
                        let validTypes = assignmentTypes.filter(type => removeAssignmentTypeIds.every(removeId => removeId != type.FranchiseProfileContactAssignmentTypeId))
                        if (conceptId != 17)
                            validTypes = validTypes.filter(type => rpmAssignmentTypeIds.every(removeId => removeId != type.FranchiseProfileContactAssignmentTypeId));
                        options.success(_.sortBy(validTypes, t => t.Order).reverse());
                    }
                }
            }),
            dataBound: (e) =>
            {
                if (e.sender.select() === -1)
                {
                    e.sender.select((assignmentType: CoreResources.IFranchiseProfileContactAssignmentType) =>
                    {
                        return assignmentType.C4CallTypeId === 1; //Default
                    })
                    e.sender.trigger("change");
                }
            }
        }
    }

    $postLink()
    {

    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfileContacts', {
            bindings: {
                [nameof<FranchiseProfileContactsComponent>(o => o.franchiseId)]: "<"
            },
            controller: FranchiseProfileContactsComponent,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfileContacts.html"
        });
    }
}