import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RmsStateService } from "Services/State/RmsState";
import { CmSectionsComponentController } from "Directives/RPM/ContractManagement/cmSections";
import * as _ from "underscore"
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

export class PeriodReportSectionsComponentController implements ng.IController 
{
    isLoading: boolean;
    canEdit: boolean;

    royaltyPeriodReportId: number;
    royaltyPeriodReport: RpmEntities.RoyaltyPeriodReportViewModel;
    franchise: Partial<CoreResources.IFranchise>;

    tabSections: Array<{ id: string, display: string }>;
    tab: { id: string, display: string };
    controllers: {
        [tabId: string]: any;
    }

    reportLink: string;

    prGrid: kendo.ui.Grid;
    
    static $inject = [
        'coreApiResources',
        '$q',
        '$window',
        '$location',
        'rpmUiApiResources',
        'rmsState',
        'identityManager',
        'apiConfig'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private rpmUiApiResources: RpmUiApiResources,
        private rmsState: RmsStateService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
    )
    {
    }

    $onInit()
    {
        this.tabSections = [
            { id: 'sales', display: "Sales" },
            { id: 'royalty', display: "Royalty" },
            { id: 'adFees', display: "Ad Fees" },
            { id: 'otherFees', display: "Other Fees" },
            { id: 'posTransactions', display: "POS Transactions" },
            { id: 'adjustments', display: "Adjustments" },
        ];
        this.InitIdState();
        this.InitTab();

        this.isLoading = true;

        this.identityManager.GetLoggedInUserInfo()
            .then((userResponse) => userResponse.data)
            .then((user) =>
            {
                let roles = this.apiConfig.FranForceConstants.RoleConstants;
                this.canEdit = user.Roles.some(r => r.Name == roles.AdminRole || r.Name == roles.PeriodReportAdminRole);
            })

        this.rmsState.royaltyPeriodReportStateHandler.Load(this.royaltyPeriodReportId)
            .then((royaltyPeriodReport) =>
            {
                this.royaltyPeriodReport = royaltyPeriodReport;
                return this.coreApiResources.FranchiseApi.get({
                    id: this.royaltyPeriodReport.FranchiseId,
                    $select: `${nameof<CoreEntities.Franchise>(o => o.ConceptId)},${nameof<CoreEntities.Franchise>(o => o.FranchisorId)},${nameof<CoreEntities.Franchise>(o => o.FranchiseId)}`
                }).$promise;
            })
            .then((franchise) =>
            {
                this.franchise = franchise;
                let endDate = kendo.parseDate(this.royaltyPeriodReport.PeriodReportEndDate);

                this.reportLink = `https://reports.dwyergroup.com/reports/report/Production%20Reports/Accounting/RMS%20Reports/Period%20Report%20Report?` +
                    `ConceptID=${this.franchise.ConceptId}&Franchisees=${this.franchise.FranchiseId}&PeriodReports=${this.royaltyPeriodReportId}&Year=${kendo.toString(endDate, "yyyy")}&Month=${kendo.toString(endDate, "MM")}`
            })
            .finally(() =>
            {
                this.isLoading = false;
            });
    }
    
    $postLink()
    {

    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
    }
    
    InitIdState()
    {
        this.royaltyPeriodReportId = parseInt(this.$location.search()[nameof(this.royaltyPeriodReportId)]);
        if (!this.royaltyPeriodReportId) {
            this.$window.location.href = "/PeriodReport"
        }
    }

    InitTab()
    {
        let tabId = this.$location.search()[nameof<PeriodReportSectionsComponentController>(o => o.tab)];
        let isValid = this.IsValidSection(tabId);
        let result: string = isValid ? tabId : this.tabSections[0].id
        this.SetTab(result);
    }

    SetTab(tabId: string)
    {
        if (!this.IsValidSection(tabId)) {
            throw "Invalid Section to Set: " + tabId;
        }

        this.tab = this.GetTab(tabId);
        this.$location.search(nameof<CmSectionsComponentController>(o => o.tab), tabId);
    }

    IsValidSection(tabId: string): boolean
    {
        return (this.GetTab(tabId) ? true : false);
    }

    GetTab(tabId: string) 
    {
        return _.find(this.tabSections, t => t.id === tabId);
    }

    SelectNewPeriodReport()
    {
        this.$window.location.href = `/PeriodReport#?franchiseId=${this.franchise.FranchiseId}&franchisorId=${this.franchise.FranchisorId}&conceptId=${this.franchise.ConceptId}`;
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('periodReportSections', {
            bindings: {

            },
            controller: PeriodReportSectionsComponentController,
            templateUrl: "/Templates/PeriodReport/PeriodReportSections.html"
        });
    }
}