import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AngularUtil } from "Utility/AngularUtil";
import * as _ from "underscore"
import { userStateObj, EditUserStateService } from "Services/State/EditUserStateService";
import { SyncResources } from "Interfaces/FranForce/Sync/SyncResources";
import { CoreEntities, CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { ParsedPosEmployeeId, SyncApiResources } from "Services/Resources/SyncApiResources";
import { AxiosInstance } from "axios";
import {
    FranchiseProfileEmployeeBioXrefDto,
    FranchiseProfileEmployeeBioXrefClient,

} from '@nbly/franchise-employee-group-clients';

import {
    FranchiseProfileEmployeeBioDto,
    FranchiseProfileEmployeeBioSearchDto,
    FranchiseEmployeeBioSearchResultVm
} from '@nbly/franchise-employee-orchestrations-clients'

export interface PosEmployeeDisplay {
    Id: number,
    Display: string;
    Type: "T" | "E";
}

export class EmployeeBiosPosComponent implements ng.IController {
    managedUserId: string;
    refreshDate: Date;
    refresh: (params: { refreshedDate: Date }) => void;

    userState: userStateObj;
    isLoading: boolean;

    isViewable: boolean;
    syncPosEmployeeIds: ParsedPosEmployeeId[];
    franchiseId: number;

    employeesDropDown: kendo.ui.DropDownList;
    employeesDropDownOptions: kendo.ui.DropDownListOptions;

    conceptFilters: kendo.data.DataSourceFilters;
    franchiseFilters: kendo.data.DataSourceFilters;

    techParams: ODataQueryParameters;
    employeeParams: ODataQueryParameters;

    employeeBio: FranchiseProfileEmployeeBioSearchDto;
    employeeBioVm: FranchiseEmployeeBioSearchResultVm;
    employeeBioDetail: FranchiseProfileEmployeeBioDto;
    conceptCode: string;
    franchiseProfileEmployeeBioXrefDto: FranchiseProfileEmployeeBioXrefDto;
    franchiseProfileEmployeeBioXrefList: FranchiseProfileEmployeeBioXrefDto[];
    employeesMultiSelect: kendo.ui.MultiSelect;
    employeesMultiSelectOptions: kendo.ui.MultiSelectOptions;
    selectedEmployees: PosEmployeeDisplay[];

    //Clients

    employeeOrchestrationsClient: AxiosInstance;
    employeeGroupClient: AxiosInstance;
    franchiseProfileEmployeeBioXrefClient: FranchiseProfileEmployeeBioXrefClient;

    static $inject = [
        'editUserState',
        'syncApiResources',
        'identityManager',
        'apiConfig',
        '$q',
        "$scope",
    ];

    constructor(
        private editUserState: EditUserStateService,
        private syncApiResources: SyncApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $q: ng.IQService,
        private $scope: ng.IScope
    ) {
    }

    async $onInit() {
        this.employeeGroupClient = FranForceAxiosClientBuilder.BuildEmployeeGroupBaseClient();
        this.employeeOrchestrationsClient = FranForceAxiosClientBuilder.BuildEmployeeOrchestrationsBaseClient();
        this.franchiseProfileEmployeeBioXrefClient = new FranchiseProfileEmployeeBioXrefClient("", this.employeeGroupClient);
        this.franchiseProfileEmployeeBioXrefDto = <FranchiseProfileEmployeeBioXrefDto>{};

        this.syncPosEmployeeIds = [];
        await this.getEmployeeBioXrefs();
        this.techParams = {
            $select: [
                nameof<SyncResources.ISyncTechnician>(o => o.Email),
                nameof<SyncResources.ISyncTechnician>(o => o.Name),
                nameof<SyncResources.ISyncTechnician>(o => o.SyncTechnicianId)
            ].join(",")
        };

        this.employeeParams = {
            $select: [
                nameof<SyncResources.ISyncEmployee>(o => o.Email),
                nameof<SyncResources.ISyncEmployee>(o => o.Name),
                nameof<SyncResources.ISyncEmployee>(o => o.SyncEmployeeId)
            ].join(",")
        };
        this.employeesMultiSelectOptions =
        {
            placeholder: "Select Employees...",
            dataTextField: "Display",
            dataValueField: "Id",
            valuePrimitive: false,
            autoBind: true,
            filter: "contains",
            autoClose: false,
            dataSource: new kendo.data.DataSource({
                sort: [
                    { field: nameof<PosEmployeeDisplay>(o => o.Display), dir: "asc" }
                ],
                transport: {
                    read: (options) => {
                        if (!this.franchiseId) {
                            options.success([]);
                            return;
                        }

                        this.$q.all([
                            this.syncApiResources.GetSyncEmployeesInFranchises(this.franchiseId, this.employeeParams),
                            this.syncApiResources.GetSyncTechniciansInFranchises(this.franchiseId, this.techParams)
                        ])
                            .then((responses) => {
                                let employees = responses[0].data
                                    .map((employee) => {

                                        if (!this.syncPosEmployeeIds.find(obj => obj.Id == employee.SyncEmployeeId)) {
                                            return <PosEmployeeDisplay>{
                                                Display: `${employee.Name}-E`,
                                                Id: employee.SyncEmployeeId,
                                                Type: "E"
                                            };
                                        }


                                    });
                                let technicians = responses[1].data
                                    .map((tech) => {

                                        if (!this.syncPosEmployeeIds.find(obj => obj.Id == tech.SyncTechnicianId)) {
                                            return <PosEmployeeDisplay>{
                                                Display: `${tech.Name}-T`,
                                                Id: tech.SyncTechnicianId,
                                                Type: "T"
                                            };
                                        }
                                    });

                                let posEmployees = employees.concat(technicians);
                                posEmployees = posEmployees.filter(item => item);
                                options.success(posEmployees);
                            })
                            .catch((err) => {
                                options.error(err);
                            })
                    }
                }
            }),
            dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) => {
                this.employeesMultiSelect = e.sender;
            },
        };

    }

    $postLink() {

    }

    $onChanges(changes: ng.IOnChangesObject) {
        this.Load();
    }

    Load() {

        this.SetFilters();


    }

    SetFilters() {
        this.conceptFilters = <any>[];
        this.franchiseFilters = null;
    }

    OnFranchiseSelect(franchise: Partial<CoreResources.IFranchise>) {
        this.franchiseId = franchise.FranchiseId;
        this.RefreshPosEmployeeDropDown();
    }

    async RemovePosEmployee(posEmployeeId: PosEmployeeDisplay) {
      
        let bioXref: FranchiseProfileEmployeeBioXrefDto = this.franchiseProfileEmployeeBioXrefList.filter(obj => obj.syncTechnicianEmployeeId == posEmployeeId.Id)[0];

        await this.franchiseProfileEmployeeBioXrefClient.franchiseProfileEmployeeBioXref3(bioXref.franchiseProfileEmployeeBioXrefId,bioXref).then(async (response) => {
            await this.getEmployeeBioXrefs();
            this.RefreshPosEmployeeDropDown();
        }).catch((err) => {
            console.log('Error in deleting bioXREF', err);
        })
    }

    async getEmployeeBioXrefs() {
        this.syncPosEmployeeIds = [];
        let franchiseProfileEmployeeBioId;
        if (this.employeeBio) {
            franchiseProfileEmployeeBioId = this.employeeBio.franchiseProfileEmployeeBioId
        } else if (this.employeeBioVm) {
            franchiseProfileEmployeeBioId = this.employeeBioVm.franchiseProfileEmployeeBioId
        }
        await this.franchiseProfileEmployeeBioXrefClient.franchiseProfileEmployeeBioXrefAll(
            franchiseProfileEmployeeBioId
        )
            .then((response) => {
                this.franchiseProfileEmployeeBioXrefList = response.result
                this.franchiseProfileEmployeeBioXrefList=this.franchiseProfileEmployeeBioXrefList.filter(obj=>!obj.deletedDateTime)
                if (this.franchiseProfileEmployeeBioXrefList.length) {
                    this.franchiseProfileEmployeeBioXrefList.map((value) => {
                        this.syncPosEmployeeIds.push({
                            Id: value.syncTechnicianEmployeeId,
                            Type: value.type
                        })
                    })

                }
                this.$scope.$digest();

            })
    }

    async AddPosEmployee() {

        if (this.selectedEmployees && this.selectedEmployees.length) {
            await Promise.all(
                this.selectedEmployees.map(async (employees) => {
                    this.franchiseProfileEmployeeBioXrefDto = {
                        franchiseProfileEmployeeBioXrefId: 0,
                        franchiseProfileEmployeeBioId: this.employeeBioDetail.franchiseProfileEmployeeBioId,
                        syncTechnicianEmployeeId: employees.Id,
                        createdDateTime: new Date().toISOString(),
                        updatedDateTime: new Date().toISOString(),
                        deletedDateTime: null,
                        type: employees.Type,

                    }

                    await this.franchiseProfileEmployeeBioXrefClient.franchiseProfileEmployeeBioXref(
                        this.franchiseProfileEmployeeBioXrefDto
                    )
                        .then(async (response) => {

                        }).catch(AngularUtil.GetJsonAlertCatch());

                })

            ).then(async () => {
                this.selectedEmployees = [];
                await this.getEmployeeBioXrefs();
                this.employeesMultiSelectOptions.dataSource.read();
                this.$scope.$digest();
            })
        }


    }


    async RefreshPosEmployeeDropDown() {
        if (!this.employeesMultiSelectOptions)
            return;

        if (this.employeesMultiSelectOptions) {
            this.employeesMultiSelectOptions.dataSource.read();
        }
    }


    static BindComponent(app: ng.IModule) {
        app.component("employeeBiosPos", {
            bindings: {
                [nameof<EmployeeBiosPosComponent>(o => o.managedUserId)]: "<",
                [nameof<EmployeeBiosPosComponent>(o => o.refreshDate)]: "<",
                [nameof<EmployeeBiosPosComponent>(o => o.refresh)]: "&",
                [nameof<EmployeeBiosPosComponent>(o => o.conceptCode)]: "<",
                [nameof<EmployeeBiosPosComponent>(o => o.employeeBioDetail)]: "=",
                [nameof<EmployeeBiosPosComponent>(o => o.employeeBio)]: "=",
                [nameof<EmployeeBiosPosComponent>(o => o.employeeBioVm)]: "=",
                [nameof<EmployeeBiosPosComponent>(o => o.franchiseId)]: "<",
            },
            controller: EmployeeBiosPosComponent,
            templateUrl: "/Templates/EmployeeBios/EmployeeBiosPos.html",
        });
    }
}

