import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { Helpers } from "Utility/Helpers";

import * as _ from "underscore"
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

export class ConceptHoursOfOperationController implements ng.IController 
{
    conceptId: number;

    conceptOpenTimes: CoreEntities.ConceptOpenTime[];

    DaysOfWeekObjs: { id: number, name: string }[];
    
    static $inject = [
        'coreApiResources',
        'odataHelper',
        '$q',
        '$log',
        '$timeout',
        '$window'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private odataHelper: ODataHelperService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private $window: ng.IWindowService
    )
    {
    }

    $onInit()
    {
        this.DaysOfWeekObjs = Helpers.DaysOfWeekObjects();
    }

    $postLink()
    {
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.conceptId)] && this.conceptId)
        {
            this.LoadOpenTimes();
        }
    }
    

    LoadOpenTimes()
    {
        return this.coreApiResources.ConceptOpenTimeApi
            .query({ $filter: `${nameof<CoreEntities.ConceptOpenTime>(o => o.ConceptId)} eq ${this.conceptId}` }).$promise
            .then((openTimes) =>
            {
                for (let dayOfWeek of this.DaysOfWeekObjs)
                {
                    let foundOpenTime = _.find(openTimes, (ot) =>
                    {
                        return ot.DayOfWeek === dayOfWeek.id
                    });

                    if (!foundOpenTime) 
                    {
                        let freshOpenTime = new this.coreApiResources.ConceptOpenTimeApi();
                        angular.extend(freshOpenTime, <Partial<CoreEntities.ConceptOpenTime>>{
                            DayOfWeek: dayOfWeek.id,
                            ConceptId: this.conceptId,
                            IsOpenForNormalHours: false,
                            IsOpenForBeforeHours: false,
                            IsOpenForAfterHours: false,
                            StartMinutesBeforeHours: 0,
                            EndMinutesBeforeHours: 0,
                            StartMinutes: 0,
                            EndMinutes: 60 * 24,
                            StartMinutesAfterHours: 60 * 24,
                            EndMinutesAfterHours: 60 * 24,
                            CreatedDateTime: null,
                            ModifiedDateTime: null,
                            FranchiseProfile: null
                        })
                        openTimes.push(freshOpenTime);
                    }
                }

                this.conceptOpenTimes = _.sortBy(openTimes, f => f.DayOfWeek);
            });
    }
    
    Cancel()
    {
        return this.LoadOpenTimes();
    }
    
    SaveHours()
    {
        let hoursSavePromises = [];

        for (let openTimes of this.conceptOpenTimes) 
        {
            let openTimeId = this.odataHelper.ConvertCompositeKeyToString({
                ConceptId: openTimes.ConceptId,
                DayOfWeek: openTimes.DayOfWeek
            });

            if (openTimes.CreatedDateTime) {
                hoursSavePromises.push(this.coreApiResources.ConceptOpenTimeApi.update({ id: openTimeId }, openTimes).$promise);
            }
            else {
                hoursSavePromises.push(this.coreApiResources.ConceptOpenTimeApi.save({ }, openTimes).$promise);
            }
        }

        return this.$q.all(hoursSavePromises);
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('conceptHoursOfOperation', {
            bindings: {
                conceptId: "<",
            },
            controller: ConceptHoursOfOperationController,
            templateUrl: "/Templates/Concepts/ConceptHoursOfOperation.html"
        });
    }
}


