import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { Helpers } from "Utility/Helpers";
import { IEditDisasterPrepProfileSectionScope } from "@DppApp/EditDisasterPrepProfileSectionController";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";


interface IEditDisasterPrepProfileScope extends IEditDisasterPrepProfileSectionScope {
    imageExtensions: string[];
    assets: DppResources.IDisasterPrepProfileBuildingAsset[];
    SaveCompany: (profile: DppResources.IDisasterPrepProfile) => void;
    RefreshAssets: () => void;
    DeleteBuilding: (dppBuilding: DppResources.IDisasterPrepProfileBuilding) => void;
}

export class EditDisasterPrepProfileController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('EditDisasterPrepProfileController',
            [
                '$scope', 'dppApiResources',
                ($scope: IEditDisasterPrepProfileScope, dppApiResources: DppApiResources) =>
                {
                    $scope.imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];

                    let assetQuery = {
                        $select: "DppBuildingAssetId,DppBuildingId,CreatedDateTime,ModifiedDateTime,FileName,FileExtension,FileDescription",
                        $filter: `DppId eq ${$scope.dppId}`,
                        $orderby: "CreatedDateTime desc"
                    };

                    $scope.RefreshAssets = () =>
                    {
                        let promise = dppApiResources.DisasterPrepProfileBuildingAssetApi.query(assetQuery).$promise.then(
                            (assets: DppResources.IDisasterPrepProfileBuildingAsset[]) =>
                            {
                                //This should really be filtered in the query... 
                                //http://i.imgur.com/pq2Z2Jo.jpg
                                $scope.assets = assets.filter((val) =>
                                {
                                    return $scope.imageExtensions.some(ext => Helpers.EndsWith(val.FileExtension.toLowerCase(), ext.toLowerCase()));
                                });
                            },
                            (err) => { console.error(err); }
                        );

                        $scope.QueueLoadingPromises(promise);
                    }

                    $scope.RefreshAssets();
                    $scope.QueueLoadingPromises($scope.RefreshBuildings());

                    $scope.SaveCompany = (profile: DppResources.IDisasterPrepProfile) =>
                    {
                        return dppApiResources.DisasterPrepProfileApi.get({ id: profile.DppId }).$promise.then(
                            (profileToSave: DppResources.IDisasterPrepProfile) =>
                            {
                                console.log(profileToSave);
                                profileToSave.DppId = profile.DppId;
                                profileToSave.FranchiseId = profile.FranchiseId;
                                profileToSave.CompanyName = profile.CompanyName;
                                profileToSave.CompanyCity = profile.CompanyCity;
                                profileToSave.CompanyAddress = profile.CompanyAddress;
                                profileToSave.CompanyStateAbbr = profile.CompanyStateAbbr;
                                profileToSave.CompanyPostalCode = profile.CompanyPostalCode;
                                profileToSave.CompanyPhone = profile.CompanyPhone;
                                profileToSave.MainImageDppBuildingAssetId = profile.MainImageDppBuildingAssetId;
                                dppApiResources.DisasterPrepProfileApi.update({ id: profile.DppId }, profileToSave)
                                    .$promise.then(
                                        (response) =>
                                        {
                                            console.log(response);
                                            return $scope.RefreshDpp();
                                        },
                                        (err) => { console.error(err); }
                                    );
                            },
                            (err) =>
                            {
                                console.error(err);
                            }
                        );
                    };

                    $scope.DeleteBuilding = (dppBuilding: DppResources.IDisasterPrepProfileBuilding) =>
                    {
                        if (confirm(`Are you sure you want to delete ${dppBuilding.BuildingName}?`)) {
                            return dppApiResources.DisasterPrepProfileBuildingApi.delete({ id: dppBuilding.DppBuildingId }, () =>
                            {
                                $scope.RefreshBuildings();
                            },
                                (err) =>
                                {
                                    console.error(err);
                                });
                        }
                    }
                }
            ]);
    }
}

