import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RestAxiosClient } from "Clients/RestAxiosClient";
import { IRestAxiosClient } from "Clients/IRestAxiosClient";

const rmsFranchiseEntitiesClient = new RestAxiosClient<RpmEntities.FranchiseEntity, number>(
    FranForceAxiosClientBuilder.BuildCoreBaseClient(),
    "/RmsFranchiseEntities"
) as IRestAxiosClient<RpmEntities.FranchiseEntity, number>;

export { rmsFranchiseEntitiesClient };