
import { AngularUtil } from "Utility/AngularUtil";
import { UrlQueryParameters } from "Utility/UrlQueryParameters";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { UriHelperService } from "Services/Utility/UriHelperService";
import { Constants } from 'Constants';

export class ForgotPasswordAccountController implements ng.IController
{
    resetModel: IForgotPasswordResetBindingModel;

    email: string;
    isEmailSent: boolean;
    
    static $inject = [
        "identityManager",
        "$window",
        "$q",
        "$log",
        "$location",
        "uriHelper",
        "$rootScope",
    ];

    constructor(
        private identityManager: IdentityManager,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $location: ng.ILocationService,
        private uriHelper: UriHelperService,
        private $rootScope: ng.IRootScopeService,
    )
    {

    }

    $onInit()
    {
        let parameters = this.uriHelper.queryParameters;

        this.resetModel = {
            Email: parameters.GetValue("email"),
            ResetGuid: parameters.GetValue("reset"),
            NewPassword: "",
            NewPasswordConfirm: ""
        };

        this.isEmailSent = false;
    }
    
    SendReset()
    {
        return this.identityManager.ForgotPasswordSendEmail(this.email).then(
            (response) =>
            {
                this.isEmailSent = true;
            },
            AngularUtil.GetErrorPromiseLogAlertCallback<ng.IHttpPromiseCallbackArg<any>>(this.$log, this.$q, (err) => {
                return err.data.Message;
            }));
    }

    ChangePassword()
    {
        return this.identityManager.ForgotPasswordReset(this.resetModel).then(
            (response) =>
            {
                alert("Your password has successfully been reset! You will be forwarded to the login page...");
                this.$window.location.href = "/Account/Login";
            }).catch((err)=>{
                this.$rootScope.$emit(Constants.PopupEvents.OpenPasswordErrorValidationPopupEvent, err);
            });
    }
}
