import * as _ from "underscore"
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { IFRMStateParams } from "Controllers/Rms/FranchisorRoyaltyManagement/frmRouter";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

interface IFRMReportingPeriodScope extends ng.IScope {
}

export class FrmReportingPeriodInfoComponentController implements ng.IController {
    //bindings
    selectedRoyaltyReportingPeriodId: number;
    //end bindings

    //load reporting period info grid 
    loadedReportingPeriodInfoGridOptions: kendo.ui.GridOptions;
    loadedReportingPeriodInfoDataSource: kendo.data.DataSource;
    loadedReportingPeriodInfoGrid: kendo.ui.Grid;

    //show grid on reporting period row select
    showReportingPeriodInfoGrid: boolean;

    static $inject = [
        'rpmUiApiResources',
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
    ) {
    }

    $onInit() {
        this.showReportingPeriodInfoGrid = false;
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.selectedRoyaltyReportingPeriodId)]) {
            this.loadReportingPeriodInfo();
        }
    }

    loadReportingPeriodInfo() {
        if (this.selectedRoyaltyReportingPeriodId) {
            this.rpmUiApiResources.GetReportingPeriodInfo(this.selectedRoyaltyReportingPeriodId)
                .then(
                    (response) => {
                        let royaltyPeriodInfo = response.data;
                        royaltyPeriodInfo = _.sortBy(royaltyPeriodInfo, rp => rp.PeriodStartDate);
                        this.loadedReportingPeriodInfoDataSource.data(royaltyPeriodInfo);
                    });


            //********************************************************************
            //***************************** GRIDS ********************************
            //********************************************************************

            let rpInfoColumns: Array<kendo.ui.GridColumn> = [
                {
                    field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.PeriodStartDate),
                    title: "Period Start Date",
                    template: (dataItem) => {
                        return kendo.toString(kendo.parseDate(dataItem.PeriodStartDate), 'yyyy-MM-dd');
                    }
                },
                {
                    field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.PeriodEndDate),
                    title: "Period End Date",
                    template: (dataItem) => {
                        return kendo.toString(kendo.parseDate(dataItem.PeriodEndDate), 'yyyy-MM-dd');
                    }
                },
                {
                    title: "Year",
                    field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.Year),
                },
                {
                    field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.MonthNumber),
                    title: "Month Number"
                },
                {
                    field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.WeekNumber),
                    title: "Week Number"
                },
                {
                    field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.PeriodPlanBudgetAmount),
                    title: "Period Plan Budget Amount"
                },
                {
                    field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.CreatedDateTime),
                    title: "Created DateTime",
                    template: (dataItem) => {
                        return kendo.toString(kendo.parseDate(dataItem.CreatedDateTime), 'yyyy-MM-dd hh:mm:ss');
                    }
                },
            ];
            this.loadedReportingPeriodInfoDataSource = new kendo.data.DataSource({
                transport: {
                    parameterMap: function (options, operation) {
                        if (operation !== "read" && options.models) {
                            return { models: kendo.stringify(options.models) };
                        }
                    }
                },
                schema: {
                    model: {
                        fields: {
                            PeriodStartDate: { type: "string" },
                            PeriodEndDate: { type: "string" },
                            Year: { type: "number" },
                            MonthNumber: { type: "number" },
                            WeekNumber: { type: "number" },
                            PeriodPlanBudgetAmount: { type: "number" },
                            CreatedDateTime: { type: "string" },
                        }
                    }
                }
            });
            this.loadedReportingPeriodInfoGridOptions = {
                columns: rpInfoColumns,
                dataSource: this.loadedReportingPeriodInfoDataSource,
                sortable: true,
                scrollable: true,
                resizable: true,
                height: 400,
            }

            this.showReportingPeriodInfoGrid = true;
        }
    }

    static BindComponent(app: ng.IModule) {
        app.component('frmReportingPeriodInfo', {
            bindings: {
                [nameof<FrmReportingPeriodInfoComponentController>(o => o.selectedRoyaltyReportingPeriodId)]: "<",
            },
            controller: FrmReportingPeriodInfoComponentController,
            templateUrl: "/Templates/FranchisorRoyaltyManagement/frmReportingPeriodInfo.html"
        });
    }

}