import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { BillingApiResources } from "Services/Resources/BillingApiResources";

export class BillingInvoiceGeneratorComponentController implements ng.IController {

    invoiceWindow: kendo.ui.Window;
    invoiceWindowOptions: kendo.ui.WindowOptions;
    isPopupOpen: boolean;

    billingMonthsOptions: kendo.ui.DropDownListOptions;
    billingMonths: { id: number, name: string }[];
    selectedMonth: { id: number, name: string };
    selectedYear: number;
    billingTypesOptions: kendo.ui.DropDownListOptions;
    billingTypes: { id: string, name: string }[];
    selectedBillingType: { id: string, name: string };
    
    static $inject = [
        "coreApiResources",
        "billingApiResources",
        "$q",
        "$log",
        "$timeout"
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private billingApiResources: BillingApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService
    )
    {
    }

    $onInit() {
        this.selectedYear = new Date().getFullYear();

        this.billingMonths = [
            { id: 1, name: "January" },
            { id: 2, name: "February" },
            { id: 3, name: "March" },
            { id: 4, name: "April" },
            { id: 5, name: "May" },
            { id: 6, name: "June" },
            { id: 7, name: "July" },
            { id: 8, name: "August" },
            { id: 9, name: "September" },
            { id: 10, name: "October" },
            { id: 11, name: "November" },
            { id: 12, name: "December" },
        ];

        this.billingMonthsOptions = {
            dataSource: this.billingMonths,
            dataValueField: "id",
            dataTextField: "name"
        };

        this.billingTypes = [
            { id: "All", name: "All Except Late Fees" },
            { id: "Monthly", name: "Monthly" },
            { id: "Adhoc", name: "Adhoc" },
            { id: "Annually", name: "Annually" },
            { id: "LateFee", name: "Late Fees" }
        ];

        this.billingTypesOptions = {
            dataSource: this.billingTypes,
            dataValueField: "id",
            dataTextField: "name"
        }

        this.invoiceWindowOptions = {
            open: (e) => {
                this.isPopupOpen = true;
            },
            close: (e) => {
                this.isPopupOpen = false;
                this.$timeout(() => {
                    
                }, 0);
            }
        };
    }

    InvoicePopup()
    {
        this.invoiceWindow.center();
        this.invoiceWindow.open();
    }

    GenerateInvoices() 
    {
        let errorMessage = null;
        if (!this.selectedMonth || !this.selectedMonth.id)
        {
            errorMessage = "Month must be selected.";
        }
        if (!this.selectedYear)
        {
            errorMessage = "Year is required";
        }
        if (!this.selectedBillingType)
        {
            errorMessage = "Billing type is required.";
        }
        if (errorMessage)
        {
            alert(errorMessage);
            return this.$q.reject(errorMessage);
        }

        if (!confirm("Are you sure you want to generate invoices?"))
        {
            return this.$q.reject();
        }

        return this.billingApiResources.GenerateInvoices(this.selectedBillingType.id, this.selectedMonth.id, this.selectedYear).then(
            () => {
                this.invoiceWindow.close();
            });
    }

    static BindComponent(app: ng.IModule)
    {
        let billingInvoiceGeneratorOptions: ng.IComponentOptions = {
            bindings: {
                //franchiseId: "<"
            },
            controller: BillingInvoiceGeneratorComponentController,
            templateUrl: "/Templates/Billing/InvoiceGenerator.html",
        }

        app.component("billingInvoiceGenerator", billingInvoiceGeneratorOptions);
    }
}
