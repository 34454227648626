import * as elements from 'typed-html';

import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import { ExpressionBinding } from 'Utility/AngularUtil';
import { StringProperties } from 'Types/StringProperties';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            monthSelector: StringProperties<Partial<MonthSelector>>;
        }
    }
}

class Month {
    monthNumber: number;
    monthName: string;
}

export type MonthSelectorOnSelectParams = {
    event: kendo.ui.DropDownListSelectEvent,
    month: Month;
}

export class MonthSelector implements ng.IController {
    month: number;
    readOnly: boolean;
    onSelect: ExpressionBinding<MonthSelectorOnSelectParams>;
    dropDown: kendo.ui.DropDownList;

    static $inject = [

    ]

    constructor(
        private rpmUiApiResources: RpmUiApiResources
    ) {

    }

    $onInit() {

    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.setOptions({ enable: !this.readOnly })
        }
    }

    $postLink() {
        this.InitDropdown();
    }

    InitDropdown() {
        this.dropDown.setOptions(
            {
                autoBind: true,
                valuePrimitive: true,
                dataValueField: nameof<Month>(o => o.monthNumber),
                dataTextField: nameof<Month>(o => o.monthName),
                optionLabel: "Select...",
                optionLabelTemplate: "Select...",
                autoWidth: true,
                enable: !this.readOnly,
                select: (e) => {
                    if (this.onSelect) {
                        this.onSelect({
                            event: e,
                            month: e.dataItem
                        });
                    }
                }
            } as kendo.ui.DropDownListOptions
        );

        let dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    let monthList: Month[] = [];
                    monthList.push({ monthName: 'January', monthNumber: 1 });
                    monthList.push({ monthName: 'February', monthNumber: 2 });
                    monthList.push({ monthName: 'March', monthNumber: 3 });
                    monthList.push({ monthName: 'April', monthNumber: 4 });
                    monthList.push({ monthName: 'May', monthNumber: 5 });
                    monthList.push({ monthName: 'June', monthNumber: 6 });
                    monthList.push({ monthName: 'July', monthNumber: 7 });
                    monthList.push({ monthName: 'August', monthNumber: 8 });
                    monthList.push({ monthName: 'September', monthNumber: 9 });
                    monthList.push({ monthName: 'October', monthNumber: 10 });
                    monthList.push({ monthName: 'November', monthNumber: 11 });
                    monthList.push({ monthName: 'December', monthNumber: 12 });
                    options.success(monthList);
                }
            }
        });

        this.dropDown.setDataSource(dataSource);
    }



    static BindComponent(app: ng.IModule) {
        const template =
            <select kendo-drop-down-list={`$ctrl.${nameof<MonthSelector>(o => o.dropDown)}`}
                k-ng-model={`$ctrl.${nameof<MonthSelector>(o => o.month)}`}
            >
            </select>;

        app.component(nameof<JSX.IntrinsicElements>(o => o.monthSelector), {
            bindings: {
                [nameof<MonthSelector>(o => o.month)]: "<",
                [nameof<MonthSelector>(o => o.readOnly)]: "<",
                [nameof<MonthSelector>(o => o.onSelect)]: "&?",
            },
            template: template,
            controller: MonthSelector
        });
    }


}