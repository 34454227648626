import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { BillingApiResources } from "Services/Resources/BillingApiResources";
import { BillingEntities } from "Interfaces/FranForce/Billing/BillingResources";

export class FranchiseProfileBillingSubscriptionsComponentController implements ng.IController
{
    franchiseId: number;

    subscriptionsGrid: kendo.ui.Grid;

    static $inject = [
        'coreApiResources',
        'billingApiResources',
        '$q'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private billingApiResources: BillingApiResources,
        private $q: ng.IQService
    )
    {
    }

    $onInit()
    {

    }

    $postLink()
    {
        this.InitGridOptions();
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.franchiseId)] && this.franchiseId)
        {
            this.RefreshGrid();
        }
    }

    InitGridOptions()
    {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof<BillingEntities.usp_Billing_GetActiveBillingSubscriptionsForFranchise_Result>(o => o.ServiceDescription),
                title: "Description"
            }, {
                field: nameof<BillingEntities.usp_Billing_GetActiveBillingSubscriptionsForFranchise_Result>(o => o.Quantity),
                title: "Quantity"
            }, {
                field: nameof<BillingEntities.usp_Billing_GetActiveBillingSubscriptionsForFranchise_Result>(o => o.UnitPrice),
                title: "Unit Price",
                format: "{0:c}"
            }, {
                field: nameof<BillingEntities.usp_Billing_GetActiveBillingSubscriptionsForFranchise_Result>(o => o.ExtendedPrice),
                title: "Extended Price",
                format: "{0:c}",
                aggregates: ["sum"],
                footerTemplate: "Total: #= kendo.toString(sum, 'c') #",
            },
        ];

        let dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) =>
                {
                    if (!this.franchiseId) {
                        options.success([])
                        return;
                    }

                    this.billingApiResources.GetActiveMonthlyBillingSubscriptionsByFranchise(this.franchiseId)
                        .then((subscriptionsResponse) =>
                        {
                            options.success(subscriptionsResponse.data);
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        });
                }
            },
            aggregate: [
                {
                    field: nameof<BillingEntities.usp_Billing_GetActiveBillingSubscriptionsForFranchise_Result>(o => o.ExtendedPrice),
                    aggregate: "sum"
                }
            ]
        });

        let options: kendo.ui.GridOptions = {
            columns: columns,
            dataSource: dataSource,
            filterable: true,
            sortable: true
        };

        this.subscriptionsGrid.setOptions(options);
    }

    RefreshGrid()
    {
        if (!this.subscriptionsGrid)
            return;

        return this.$q.when(this.subscriptionsGrid.dataSource.read()).then(() =>
        {
            this.subscriptionsGrid.refresh();
        });
    }
    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfileBillingSubscriptions', {
            bindings: {
                franchiseId: "<"
            },
            controller: FranchiseProfileBillingSubscriptionsComponentController,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfileBillingSubscriptions.html"
        });
    }
}


