import * as elements from 'typed-html';
import { StringProperties } from "Types/StringProperties";
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { franchiseEntityRelationshipTypesDataModel } from "../../../Models/Royalty/FranchiseEntities/FranchiseEntityRelationshipTypeDataModel";
import { ApiConfig } from "AppConfig/ApiConfig";
import { FranchiseEntityRelationshipApplicationModel } from '../../../Models/Royalty/FranchiseEntities/FranchiseEntityRelationshipApplicationModel';
import { IFranchiseEntityRelationshipApplicationsDropDownScope } from './FranchiseEntityRelationshipApplicationsDropDown';

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            rmsFranchiseEntityRelationshipTypesDropDown: Partial<StringProperties<FranchiseEntityRelationshipTypesDropDown>>;
        }
    }
}

export type FranchiseEntityRelationshipTypesDropDownOnSelect = {
    entity: kendo.data.ObservableObject & Partial<RpmEntities.FranchiseEntityRelationshipType>
};

export class FranchiseEntityRelationshipTypesDropDown implements ng.IController 
{
    franchiseEntityRelationshipTypesArr: franchiseEntityRelationshipTypesDataModel[];
    franchiseEntityRelationshipTypesDropDownOptions: any;
    franchiseEntityRelationshipType: number[];
    franchiseEntityRelationshipApplicationId: number;
    onSelect: (params: FranchiseEntityRelationshipTypesDropDownOnSelect) => void;
    onDeselect: (params: FranchiseEntityRelationshipTypesDropDownOnSelect) => void;


    static $inject = [
        "$scope",
        "royaltyApiResources",
        "$http",
        "apiConfig"
    ];

    constructor(
        private $scope: IFranchiseEntityRelationshipApplicationsDropDownScope,
        private royaltyApiResources: RoyaltyApiResources,
        private $http: ng.IHttpService,
        private apiConfig: ApiConfig
    )
    {
        $scope.$on('SelectApplicationEvent', function (event, args) { alert("hi") });
    }

    $onInit()
    {
        this.InitDropDown();
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        
        if (changes[nameof(this.franchiseEntityRelationshipType)]) {
            if (this.franchiseEntityRelationshipType) {
                var multiselect = $("#selectFranchiseEntityTypeDD").data("kendoMultiSelect");
                if (this.franchiseEntityRelationshipType.length > 0) {                   
                    multiselect.value(this.franchiseEntityRelationshipType);
                }
                else if (this.franchiseEntityRelationshipType.length <= 0) {
                    multiselect.value([]);
                }
            }
        }
     
        if (changes[nameof(this.franchiseEntityRelationshipApplicationId)]) {
            if (this.franchiseEntityRelationshipApplicationId)
                this.GetFranchiseRelationMapping(this.franchiseEntityRelationshipApplicationId);
        }
    }

    GetFranchiseRelationMapping(franchiseEntityRelationshipApplicationId: number) {
        this.$http<FranchiseEntityRelationshipApplicationModel[]>({
            method: "GET",
            url: this.apiConfig.AppSettings.FranchiseConfigUrl + `/api/franchise-entity-relationship-applications/${franchiseEntityRelationshipApplicationId}/franchise-entity-application-relationship-types`,
        }).then(res => {
            this.setRelationshipTypeDropDownList(res);
        });
    }

    InitDropDown() {
        this.royaltyApiResources.GetActiveFranchiseEntityRelationshipTypes(true)
            .then(
                (response) => {

                    this.franchiseEntityRelationshipTypesArr = [];
                    response.data.forEach((item, index) => {
                        this.franchiseEntityRelationshipTypesArr.push({
                            franchiseEntityRelationshipTypeId: item.franchiseEntityRelationshipTypeId,
                            name: item.name,
                            franchiseEntityRelationshipTypeBitId: item.franchiseEntityRelationshipTypes,
                        })
                    })
                    this.franchiseEntityRelationshipTypesArr.sort((a, b) => (a.name > b.name) ? 1 : -1)
                    this.franchiseEntityRelationshipTypesDropDownOptions = {
                        placeholder: "Select Type...",
                        dataTextField: nameof<franchiseEntityRelationshipTypesDataModel>(o => o.name),
                        dataValueField: nameof<franchiseEntityRelationshipTypesDataModel>(o => o.franchiseEntityRelationshipTypeBitId),
                        valuePrimitive: true,
                        autoBind: true,
                        dataSource: this.franchiseEntityRelationshipTypesArr,
                        select: (e) => {
                            if (this.onSelect)
                                this.onSelect({ entity: e.dataItem });
                        },
                        deselect: (e) => {
                            if (this.onDeselect)
                                this.onDeselect({ entity: e.dataItem });
                        }
                    }
                });
    }

    setRelationshipTypeDropDownList(response: any) {
        this.franchiseEntityRelationshipTypesArr = [];
        response.data.forEach((item, index) => {
            this.franchiseEntityRelationshipTypesArr.push({
                franchiseEntityRelationshipTypeId: item.franchiseEntityRelationshipTypeId,
                name: item.name,
                franchiseEntityRelationshipTypeBitId: item.franchiseEntityRelationshipTypes,
            })
        })
        this.franchiseEntityRelationshipTypesArr.sort((a, b) => (a.name > b.name) ? 1 : -1)

        var multiselect = $("#selectFranchiseEntityTypeDD").data("kendoMultiSelect");
        if (multiselect) {
            multiselect.setDataSource(new kendo.data.DataSource({ data: this.franchiseEntityRelationshipTypesArr }));
        }
        
    }

    adjustDropDownWidth(e) {
        var listContainer = e.sender.list.closest(".k-list-container");
        listContainer.width(listContainer.width() + kendo.support.scrollbar());
    }  

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.rmsFranchiseEntityRelationshipTypesDropDown);

        let template = (
            <select style="width:100%" id="selectFranchiseEntityTypeDD" kendo-multi-select k-options="$ctrl.franchiseEntityRelationshipTypesDropDownOptions">
            </select>
        );

        app.component(componentName, {
            bindings: {
                [nameof<FranchiseEntityRelationshipTypesDropDown>(o => o.franchiseEntityRelationshipType)]: "<",
                [nameof<FranchiseEntityRelationshipTypesDropDown>(o => o.onSelect)]: "&?",
                [nameof<FranchiseEntityRelationshipTypesDropDown>(o => o.onDeselect)]: "&?",
                [nameof<FranchiseEntityRelationshipTypesDropDown>(o => o.franchiseEntityRelationshipApplicationId)]: "<",
            },
            controller: FranchiseEntityRelationshipTypesDropDown,
            template: template
        });
    }
}

