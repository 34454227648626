import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyManualSalesEntryRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyManualSalesEntryById(id: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyManualSalesEntries/" + id
        };

        return this.$http<RpmEntities.RoyaltyManualSalesEntryViewModel>(config);
    }

    GetRoyaltyManualSalesEntryByFranchiseAndPeriodEndDate(franchiseId: number, periodEndDate: string|Date)
    {
        if (periodEndDate instanceof Date) {
            periodEndDate = periodEndDate.toISOString();
        }

        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyManualSalesEntries/ByFranchiseAndPeriodEndDate",
            params: {
                franchiseId: franchiseId,
                periodEndDate: periodEndDate
            }
        };

        return this.$http<RpmEntities.RoyaltyManualSalesEntryViewModel>(config);
    }

    GetRoyaltyManualSalesEntriesUnprocessed()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyManualSalesEntries/Unprocessed"
        };

        return this.$http<RpmEntities.usp_RoyaltyManualSalesEntry_GetUnprocessedEntries_Result[]>(config);
    }

    SelectRoyaltyManualSalesEntryAvailableSyncInvoices(model: RpmEntities.usp_RPM_SelectAvailableSyncInvoices_ManualSalesEntry_Input)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyManualSalesEntries/SelectAvailableSyncInvoices",
            data: model
        };

        return this.$http<{ RoyaltyReportBatchId: number }>(config);
    }
   
    CreateRoyaltyManualSalesEntry(model: RpmEntities.RoyaltyManualSalesEntryViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyManualSalesEntries",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyManualSalesEntry(id: number, model: RpmEntities.RoyaltyManualSalesEntryViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyManualSalesEntries/" + id,
            data: model
        };

        return this.$http<void>(config);
    }
}