import { MomentUtil } from "Utility/MomentUtil";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ZeeMailApiResources } from "Services/Resources/ZeeMailApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import * as moment from "moment";
import { ZeeMailEntities, ZeeMailResources } from "Interfaces/FranForce/ZeeMail/ZeeMailResources";


export class ZeeMailAccountCommentsController implements ng.IController
{
    zeeMailAccountId: number;
    init: (params: { self: ZeeMailAccountCommentsController }) => void

    isLoading: boolean;
    commentHistory: ZeeMailEntities.ZorWareZeeMailComment[];

    static $inject = [
        'coreApiResources',
        'zeeMailApiResources',
        'identityManager',
        'apiConfig'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private zeeMailApiResources: ZeeMailApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig
    )
    {
    }

    $onInit()
    {
        if (this.init) {
            this.init({ self: this });
        }
    }

    $postLink()
    {
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.zeeMailAccountId)] && this.zeeMailAccountId)
        {
            let commentHistoryParams = {
                $filter: `${nameof<ZeeMailResources.IZorWareZeeMailComment>(o => o.ZorWareZeeMailAccountID)} eq ${this.zeeMailAccountId}`,
                $expand: `${nameof<ZeeMailResources.IZorWareZeeMailComment>(o => o.ZorWareZeeMailActionType)}`,
                $orderby: nameof<ZeeMailResources.IZorWareZeeMailComment>(o => o.CreatedDateTime) + " desc"
            };

            this.isLoading = true;
            this.zeeMailApiResources.ZorWareZeeMailCommentApi.query(commentHistoryParams).$promise
                .then((comments) =>
                {
                    comments.forEach(c =>
                    {
                        c.CreatedDateTime = MomentUtil.EnsureUtcTime(c.CreatedDateTime)
                    });
                    this.commentHistory = comments
                })
                .finally(() =>
                {
                    this.isLoading = false;
                })
        }
    }
    
    static BindComponent(app: ng.IModule)
    {
        app.component("zeeMailAccountComments", {
            bindings: {
                [nameof<ZeeMailAccountCommentsController>(o => o.zeeMailAccountId)]: "<",
                [nameof<ZeeMailAccountCommentsController>(o => o.init)]: "&?",
            },
            controller: ZeeMailAccountCommentsController,
            templateUrl: "/Templates/ZeeMail/ZeeMailAccountComments.html"
        });
    }
}


