import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { MerchantApiResources } from "Services/Resources/MerchantApiResources";

import { KendoUtil } from "Utility/KendoUtil";
import * as _ from "underscore"
import { MerchantResources } from "Interfaces/FranForce/Merchant/MerchantResources";

interface IReimbursableDisplayGiftCertificate extends MerchantResources.IGiftCertificate
{
    Selected?: boolean;
}

export class ReimbursementGiftCertificatesController implements ng.IController {
    mainGrid: kendo.ui.Grid;
    gridOptions: kendo.ui.GridOptions;
    reimbursableGiftCerts: IReimbursableDisplayGiftCertificate[];
    
    static $inject = [
        "$scope",
        "coreApiResources",
        "merchantApiResources",
        "$q",
        "$log",
        "$window"
    ];

    constructor(
        private $scope: ng.IScope,
        private coreApiResources: CoreApiResources,
        private merchantApiResources: MerchantApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $window: ng.IWindowService
    )
    {

    }

    $onInit()
    {
        this.InitGrid();
    }

    InitGrid()
    {
        let queryParams = { $expand: "RedeemedByFranchise($select=LicenseNumber)", $filter: "RedeemUser ne 'Quarantine'" };
        this.merchantApiResources.GetReimbursableGiftCertificates(queryParams).then((reimbursableResponse) =>
        {
            this.reimbursableGiftCerts = reimbursableResponse.data;

            let mainGrid = angular.element("#mainGrid");
            let windowEle = angular.element(this.$window);

            let gridHeight = windowEle.height() - mainGrid.offset().top - 15;

            if (gridHeight < 250)
            {
                gridHeight = 250;
            }

            this.gridOptions = {
                dataSource: this.reimbursableGiftCerts,
                sortable: true,
                resizable: true,
                pageable: false,
                filterable: {
                    mode: "menu",
                    operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                    extra: true 
                },
                columns: <kendo.ui.GridColumn[]>[
                    {
                        width: 70,
                        template: `<button ng-click="$ctrl.ToggleSelection(dataItem)">
                                        <i ng-class="dataItem.Selected ? 'fa fa-check-square-o' : 'fa fa-square-o'" aria-hidden="true"></i>
                                    </button>`,
                    }, {
                        field: "GiftCertificateId",
                        title: "Certificate Id",
                        //template: '#= GiftCertificateId #-#= GiftCertificateSuffix #',
                        //sortable: { compare: (a, b) => { return a.GiftCertificateId - b.GiftCertificateId; } },
                        filterable: true
                    }, {
                        field: "Amount",
                        title: "Amount",
                        format: "{0:c}"
                    }, {
                        title: "Purchaser Name",
                        template: '#= PurchaserFirstName # #= PurchaserLastName #'
                    }, {
                        field: "RedeemedByFranchise.LicenseNumber",
                        title: "Redeeming Franchise"
                    }, {
                        field: "RedeemedDateTime",
                        title: "Redeemed",
                        type: "date",
                        format:"{0:yyyy-MM-dd}" 
                    }
                ],
                height: gridHeight
            };
        });
    }

    ToggleSelection(cert: IReimbursableDisplayGiftCertificate)
    {
        let foundCert = _.find(this.reimbursableGiftCerts, gc => gc.GiftCertificateId === cert.GiftCertificateId);
        cert.Selected = !cert.Selected;
        foundCert.Selected = cert.Selected;
    }

    ToggleAllSelections(val: boolean)
    {
        for (let cert of this.reimbursableGiftCerts)
        {
            cert.Selected = val;
        }
        this.mainGrid.setDataSource(new kendo.data.DataSource({ data: this.reimbursableGiftCerts }));
        this.mainGrid.refresh();
    }

    CreateBatch()
    {
        let certsToReimburse = this.reimbursableGiftCerts.filter(c => c.Selected);

        if (certsToReimburse.length == 0)
        {
            alert("No gift certificates were selected for reimbursement.");
            return;
        }

        if (confirm("You are about to generate a gift certificate batch. All of these gift certificates will be marked as reimbursed. " +
            "You MUST either credit these accounts using the file you will be downloading, OR you need to reverse the batch afterward. " +
            "If this is understood, do you want to continue?"))
        {
            return this.merchantApiResources.CreateGiftCertificateReimbursementBatch(certsToReimburse.map(c => c.GiftCertificateId))
                .then((batchResponse) => {
                    this.$window.location.href = "/GiftCertificates/ReimbursementBatches?reimbursementBatchId=" + batchResponse.data.GiftCertificateReimbursementBatchId;
                });
        }
    }
}
