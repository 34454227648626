import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { IEditDisasterPrepProfileSectionScope } from "@DppApp/EditDisasterPrepProfileSectionController";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";

interface IEditDisasterPrepProfileBuildingContactsScope extends IEditDisasterPrepProfileSectionScope {
    contactGroup: DppResources.IDisasterPrepProfileBuildingContactGroup;
    SaveContactGroup: () => ng.IPromise<DppResources.IDisasterPrepProfileBuildingContactGroup>;
}


export class EditDisasterPrepProfileBuildingContactsController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('EditDisasterPrepProfileBuildingContactsController',
            [
                '$scope', "dppApiResources",
                ($scope: IEditDisasterPrepProfileBuildingContactsScope, dppApiResources: DppApiResources) =>
                {
                    if (!$scope.dppBuildingId) {
                        $scope.RedirectHome();
                        return;
                    }

                    var promise = dppApiResources.DisasterPrepProfileBuildingContactGroupApi.get({ id: $scope.dppBuildingId },
                        (group: DppResources.IDisasterPrepProfileBuildingContactGroup) =>
                        {
                            $scope.contactGroup = group;
                        },
                        (err: ng.IHttpPromiseCallbackArg<any>) =>
                        {
                            console.error(err);
                            if (err.status === 404) {
                                $scope.contactGroup = new dppApiResources.DisasterPrepProfileBuildingContactGroupApi();
                            }
                            else {
                                alert("Could not get the contacts for this building.");
                            }
                        }).$promise;

                    $scope.QueueLoadingPromises(promise);

                    $scope.SaveContactGroup = () =>
                    {
                        let savePromise;
                        if ($scope.contactGroup.DppBuildingId) {
                            savePromise = $scope.contactGroup.$update({ id: $scope.contactGroup.DppBuildingId },
                                (response) =>
                                {
                                    console.log(response);
                                },
                                (err) =>
                                {
                                    console.error(err);
                                });
                        }
                        else {
                            $scope.contactGroup.DppBuildingId = $scope.dppBuildingId;
                            savePromise = $scope.contactGroup.$save((response) =>
                            {
                                console.log(response);
                            },
                                (err) =>
                                {
                                    console.error(err);
                                });
                        }

                        return savePromise;
                    }
                }
            ]);

    }
}

