import { KendoUtil } from "Utility/KendoUtil";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import * as _ from "underscore"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";



export class fddRoyaltiesRatePlansComponentController implements ng.IController {
    
    disclosureDocumentId: number;
    readOnly: boolean;
    onSelectedRatePlan: (params: { ratePlan: RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel, isRemoved: boolean }) => void;
    onLoaded: (params: { self: fddRoyaltiesRatePlansComponentController }) => void;

    isLoading: boolean;
    selectedRatePlan: RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel;
    royaltyRatePlanTypes: RpmEntities.RoyaltyRatePlanTypeViewModel[];
    royaltyRatePlanGrid: kendo.ui.Grid;
    royaltyRatePlanGridOptions: kendo.ui.GridOptions;

    formController: angular.IFormController;

    static $inject = [
        'rpmUiApiResources',
        'identityManager',
        '$q',
        '$timeout',
        'rpmHelper',
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private identityManager: IdentityManager,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService,
        private rpmHelper: RpmHelperService
    )
    {

    }

    $onInit()
    {
        this.isLoading = true;

        this.rpmUiApiResources.GetAllRoyaltyRatePlanTypes().then(typesResponse => {
            this.royaltyRatePlanTypes = typesResponse.data;
        })
            .then(() =>
            {
                return this.ResetChanges();
            })
            .finally(() => {
                this.isLoading = false;
                this.onLoaded({ self: this });
            })

        if (this.disclosureDocumentId) {
            this.InitRoyaltyRatePlanGrid();
        }
    }

    $postLink()
    {
        this.InitRoyaltyRatePlanGrid();
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes.disclosureDocumentId)
        {
            //this.InitRoyaltyRatePlanItemGrid();
            this.$timeout(() => { this.ResetChanges(); }, 0);
        }
    }

    InitRoyaltyRatePlanGrid()
    {
        let rrpDataSource = new kendo.data.DataSource({
            transport: {
                read:  (options: kendo.data.DataSourceTransportReadOptions) =>  {
                    this.rpmUiApiResources.GetRoyaltyDisclosureDocumentRatePlansByDisclosureDocument(this.disclosureDocumentId)
                        .then(
                            (ratePlansResponse) => {
                                options.success(ratePlansResponse.data);
                            },
                            (err) => {
                                options.error(err);
                            }
                        )
                },
                create: (options: kendo.data.DataSourceTransportOptions) => {
                    let createPromises: ng.IPromise<any>[] = [];
                    for (let createModel of <RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel[]>options.data.models)
                    {
                        createPromises.push(
                            this.rpmUiApiResources.CreateRoyaltyDisclosureDocumentRatePlan(createModel).then(
                                (idResponse) => {
                                    createModel.DisclosureDocumentRatePlanId = idResponse.data;
                                }) 
                        );
                    }

                    this.$q.all(createPromises).then(
                        () => {
                            options.success(options.data.models)
                        },
                        (err) => { options.error(err); });
                },
                update:  (options: kendo.data.DataSourceTransportOptions)  => {
                    let updatePromises: ng.IPromise<any>[] = [];
                    for (let updateModel of <RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel[]>options.data.models)
                    {
                        updatePromises.push(
                            this.rpmUiApiResources.UpdateRoyaltyDisclosureDocumentRatePlan(updateModel.DisclosureDocumentRatePlanId, updateModel)
                        );
                    }

                    this.$q.all(updatePromises).then(
                        () => {
                            options.success(options.data.models)
                        },
                        (err) => { options.error(err); });
                },
                destroy: (options: kendo.data.DataSourceTransportOptions) => {
                    let deletePromises: ng.IPromise<any>[] = [];
                    for (let deleteModel of <RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel[]>options.data.models)
                    {
                        deleteModel.DeletedDateTime = new Date().toISOString();
                        deletePromises.push(
                            this.rpmUiApiResources.UpdateRoyaltyDisclosureDocumentRatePlan(deleteModel.DisclosureDocumentRatePlanId, deleteModel)
                        );
                    }
                    this.$q.all(deletePromises).then(
                        () => {
                            options.success(options.data.models)
                        },
                        (err) => { options.error(err); });
                },
            },
            batch: true,
            pageSize: 10,
            schema: {
                model: {
                    id: "DisclosureDocumentRatePlanId",
                    fields: {
                        DisclosureDocumentRatePlanId: {
                            type: "number",
                            editable: false
                        },
                        RoyaltyRatePlanTypeId: {
                            type: "number",
                            validation: { required: true },
                            defaultValue: 1
                        },
                        CreatedDateTime: {
                            type: "string",
                            validation: { required: true },
                            defaultValue: new Date(),
                            editable: false
                        },
                        WeeksInBusinessStart: {
                            type: "number",
                            defaultValue: null
                        },
                        WeeksInBusinessEnd: {
                            type: "number",
                            defaultValue: null
                        },
                        DeletedDateTime: {
                            type: "string",
                            defaultValue: null
                        },
                    }
                }
            },
            filter: { field: "DeletedDateTime", operator: "isnull" }
        });
        
        let rrpColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "DisclosureDocumentRatePlanId",
                title: "Plan ID",
                width: 160,
                template: `<button 
                    ng-show="dataItem.DisclosureDocumentRatePlanId"
                    ng-class="'pure-button grid-edit-button ' + ($ctrl.selectedRatePlan.DisclosureDocumentRatePlanId === dataItem.DisclosureDocumentRatePlanId ? 'rpm-selected-row-btn' : '')" 
                    ng-click="$ctrl.SelectRatePlan(dataItem) ; $event.preventDefault()">
                        <i ng-class="'fa fa-' + ($ctrl.selectedRatePlan.DisclosureDocumentRatePlanId === dataItem.DisclosureDocumentRatePlanId ? 'check-circle-o' : 'circle-o')" aria-hidden="true"></i>
                        <span ng-bind="dataItem.DisclosureDocumentRatePlanId"></span>
                    </button>
                    <span ng-show="!dataItem.DisclosureDocumentRatePlanId" ng-bind="dataItem.DisclosureDocumentRatePlanId"></span>`
            },
            {
                field: "RoyaltyRatePlanTypeId",
                title: "Plan Type",
                editor: (container, options) =>
                {            
                    let input = "<input required name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: "Description",
                        dataValueField: "RoyaltyRatePlanTypeId",
                        dataSource: {
                            data: this.royaltyRatePlanTypes
                        },
                        change: (e)  =>  {                   
                            let dataItem = e.sender.dataItem();
                            
                            if (dataItem == null) {
                                dataItem = this.royaltyRatePlanTypes[0];
                            }

                            options.model.set("RoyaltyRatePlanTypeId", dataItem.RoyaltyRatePlanTypeId); 
                        }
                    });
                },
                template: "{{$ctrl.GetRoyaltyPlanTypeById(#=RoyaltyRatePlanTypeId#).Description}}"
            }, {
                field: "CreatedDateTime",
                title: "Created Date",
                template: "#=kendo.toString(kendo.parseDate(CreatedDateTime),'yyyy-MM-dd')#"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanViewModel>(o => o.WeeksInBusinessStart),
                title: "WIB Start",
                editor: this.rpmHelper.WeeksInBusinessEditor()
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanViewModel>(o => o.WeeksInBusinessEnd),
                title: "WIB End",
                editor: this.rpmHelper.WeeksInBusinessEditor()
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }
        ];

        this.royaltyRatePlanGridOptions = {
            autoBind: false,
            selectable: "row",
            dataSource: rrpDataSource,
            columns: rrpColumns,
            editable: !this.readOnly ? { confirmation: false } : false,
            navigatable: true,
            scrollable: false,
            pageable: KendoUtil.GetDefaultKendoGridPageable(10),
            remove: (e) => {
                if (this.selectedRatePlan && this.selectedRatePlan.DisclosureDocumentRatePlanId) {
                    if (e.model.id === this.selectedRatePlan.DisclosureDocumentRatePlanId) {
                        this.$timeout(() => {
                            this.SelectRatePlan(null, true);
                        }, 0);
                    }
                }
            }
        };
    }

    GetRoyaltyPlanTypeById(planTypeId: number)
    {
        return _.find(this.royaltyRatePlanTypes, (rpt) => { return rpt.RoyaltyRatePlanTypeId === planTypeId; });
    }
    
    AddNewRecord()
    {
        if (!this.readOnly)
        {
            let defaultRatePlan: RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel = {
                DisclosureDocumentRatePlanId: 0,
                DisclosureDocumentId: this.disclosureDocumentId,
                RoyaltyRatePlanTypeId: 1,
                CreatedDateTime: kendo.toString(new Date(), "d"),
                CreatedUser: null,
                DeletedDateTime: null,
                UpdatedDateTime: null,
                UpdatedUser: null,
                WeeksInBusinessStart: null,
                WeeksInBusinessEnd: null
            };
            this.royaltyRatePlanGrid.dataSource.add(defaultRatePlan);
        }
    }
    
    SelectRatePlan(ratePlan: RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel, isRemoved: boolean = false)
    {
        if (this.selectedRatePlan === ratePlan)
            ratePlan = null;

        this.selectedRatePlan = ratePlan;
        if (this.onSelectedRatePlan)
        {
            this.onSelectedRatePlan({ ratePlan: this.selectedRatePlan, isRemoved: isRemoved });
        }
    }
    
    SelectRowById(id: number)
    {
        let dataItem = this.royaltyRatePlanGrid.dataSource.get(id);
        if (dataItem)
            this.SelectRatePlan(<any>dataItem);
    }
    
    CancelChanges()
    {
        if (!this.readOnly) {
            this.royaltyRatePlanGrid.dataSource.cancelChanges();
        }
    }
    
    HasChanges()
    {
        if (!this.royaltyRatePlanGrid)
            return false;

        return this.royaltyRatePlanGrid.dataSource.hasChanges();
    }

    ResetChanges()
    {
        if (!this.royaltyRatePlanGrid) {
            return;
        }

        this.isLoading = true;
        this.royaltyRatePlanGrid.dataSource.cancelChanges();
        return this.$q.when(this.royaltyRatePlanGrid.dataSource.read())
            .then(() =>
            {
                this.royaltyRatePlanGrid.refresh();
                this.$timeout(() =>
                {
                    this.formController.$setPristine();
                }, 1000);
            })
            .finally(() =>
            {
                this.isLoading = false;
            });
    }

    SaveChanges()
    {
        this.isLoading = true;
        return this.royaltyRatePlanGrid.dataSource.sync().then(() => {
            return this.ResetChanges();
        }).always(() => { this.isLoading = false;});
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("fddRoyaltiesRatePlans", {
                bindings: {
                    disclosureDocumentId: "<",
                    readOnly: "<",
                    onSelectedRatePlan: "&?",
                    onLoaded: "&?"
                },
                templateUrl: '/Templates/FDDManagement/fddRoyalties/RatePlans.html',
                controller: fddRoyaltiesRatePlansComponentController
            });
    }
}

