import * as elements from 'typed-html';
import { ApiConfig } from "AppConfig/ApiConfig";
import { StringProperties } from "Types/StringProperties";
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { franchiseEntityRelationshipApplicationDataModel } from "../../../Models/Royalty/FranchiseEntities/FranchiseEntityRelationshipApplicationDataModel";
import { FranchiseEntityRelationshipApplicationModel } from '../../../Models/Royalty/FranchiseEntities/FranchiseEntityRelationshipApplicationModel';

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            rmsFranchiseEntityRelationshipApplicationsDropDown: Partial<StringProperties<FranchiseEntityRelationshipApplicationsDropDown>>;
        }
    }
}

export type FranchiseEntityRelationshipApplicationsDropDownOnSelect = {
    entity: kendo.data.ObservableObject & Partial<RpmEntities.FranchiseEntityRelationshipApplication>
};

export interface IFranchiseEntityRelationshipApplicationsDropDownScope extends ng.IScope {

    result: any;
    GetResults: (ID: number) => void;
}


export class FranchiseEntityRelationshipApplicationsDropDown implements ng.IController 
{
    franchiseEntityRelationshipApplicationId: number;
    onSelect: (params: FranchiseEntityRelationshipApplicationsDropDownOnSelect) => void;
    dropDown: kendo.ui.DropDownList;
    franchiseEntityApplications: franchiseEntityRelationshipApplicationDataModel[];
    franchiseEntityApplicationsDropDownOptions: kendo.ui.DropDownListOptions;

    static $inject = [
        "$scope",
        "royaltyApiResources",
        "$http",
        "apiConfig"
    ];

    constructor(
        private $scope: IFranchiseEntityRelationshipApplicationsDropDownScope,
        private royaltyApiResources: RoyaltyApiResources,
        private $http: ng.IHttpService,
        private apiConfig: ApiConfig
    )
    {
    }

    $onInit()
    {
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.franchiseEntityRelationshipApplicationId)].currentValue != changes[nameof(this.franchiseEntityRelationshipApplicationId)].previousValue)
        {
            if (this.franchiseEntityRelationshipApplicationId)
            {
                this.dropDown.value(this.franchiseEntityRelationshipApplicationId.toString());
                this.dropDown.trigger("change");
            }
        }
    }

    $postLink()
    {
        this.InitDropDown();
    }


    InitDropDown()
    {
        this.royaltyApiResources.GetActiveFranchiseEntityApplications(true)
            .then(
                (response) => {
                    this.franchiseEntityApplications = [];
                    response.data.forEach((item, index) => {
                        this.franchiseEntityApplications.push({
                            franchiseEntityRelationshipApplicationId: item.franchiseEntityRelationshipApplicationId,
                            name: item.name
                        })
                    })
                    this.franchiseEntityApplications.sort((a, b) => (a.name > b.name) ? 1 : -1)
                    this.franchiseEntityApplicationsDropDownOptions = {
                        dataSource: this.franchiseEntityApplications,
                        autoBind: true,
                        valuePrimitive: true,
                        optionLabel: "Select Application...",
                        optionLabelTemplate: "Select Application...",
                        dataTextField: nameof<franchiseEntityRelationshipApplicationDataModel>(o => o.name),
                        dataValueField: nameof<franchiseEntityRelationshipApplicationDataModel>(o => o.franchiseEntityRelationshipApplicationId),
                        select: (e) =>
                        {                          
                            if (this.onSelect)
                                this.onSelect({ entity: e.dataItem });
                        }
                    }
                    this.dropDown.setOptions(this.franchiseEntityApplicationsDropDownOptions);
                    this.dropDown.setDataSource(this.franchiseEntityApplicationsDropDownOptions.dataSource);
                });
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.rmsFranchiseEntityRelationshipApplicationsDropDown);

        let template = (
            <select style="width:100%" kendo-drop-down-list={`$ctrl.${nameof<FranchiseEntityRelationshipApplicationsDropDown>(o => o.dropDown)}`}
                k-ng-model={`$ctrl.${nameof<FranchiseEntityRelationshipApplicationsDropDown>(o => o.franchiseEntityRelationshipApplicationId)}`}>
            </select>
        );

        app.component(componentName, {
            bindings: {
                [nameof<FranchiseEntityRelationshipApplicationsDropDown>(o => o.franchiseEntityRelationshipApplicationId)]: "<",
                [nameof<FranchiseEntityRelationshipApplicationsDropDown>(o => o.onSelect)]: "&?",
            },
            controller: FranchiseEntityRelationshipApplicationsDropDown,
            template: template
        });
    }
}

