import { RmsDashboardFeeVarianceResultVm } from '@nbly/royalty-orchestrations-clients';

export class rmsDashboardFeeVarianceGridComponentController implements ng.IController {

    numberOfPeriods: number;
    feeVarianceFranchisorGrid: kendo.ui.Grid;
    feeVarianceFranchisorGridOptions: kendo.ui.GridOptions;
    feeVarianceFranchiseeGrid: { [uid: string]: kendo.ui.Grid };

    static $inject = [
        '$window',
        '$location',
        '$q',
        '$scope',
        '$rootScope'
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private $scope: ng.IScope,
        private $rootScope: ng.IRootScopeService,
    ) {

    }

    $onInit() {
        this.feeVarianceFranchiseeGrid = {};
        this.LoadGrid();
        this.$rootScope.$on('feeVarianceDataChanged', (event, args) => {
            if (args && args.feeVarianceData && args.numberOfPeriods) {

                let feeVarianceResultData: RmsDashboardFeeVarianceResultVm[] = args.feeVarianceData;
                this.numberOfPeriods = args.numberOfPeriods;
                let feeVarianceData = this.GetReStructuredFeeVarianceData(feeVarianceResultData)
                this.PopulateGrid(feeVarianceData);
            }
        });

        this.$rootScope.$on('resetGrid', () => {
            this.feeVarianceFranchisorGrid.dataSource.data([])
            this.feeVarianceFranchisorGrid.refresh();
        });
    }

    PopulateGrid(data: any[]) {
        this.feeVarianceFranchisorGrid.dataSource.data(data)
        this.feeVarianceFranchisorGrid.refresh();
    }

    LoadGrid() {

        let feeVarianceFranchisorDataSource = new kendo.data.DataSource({
            data: [],
            pageSize: 10,
            schema: {
                model: {
                    id: "franchisorId",
                    fields: {
                        franchisorId: {
                            type: "number"
                        },
                        franchisorName: {
                            type: "string"
                        },
                        franchiseeReportCount: {
                            type: "number"
                        }
                    }
                }
            }
        });

        let feeVarianceFranchisorColumns: Array<kendo.ui.GridColumn> =
            [
                {
                    field: "franchisorId",
                    title: "Franchisor Id"
                },
                {
                    field: "franchisorName",
                    title: "Franchisor Name"
                },
                {
                    field: "franchiseeReportCount",
                    title: "Count"
                },
            ];

        this.feeVarianceFranchisorGridOptions = {
            autoBind: false,
            dataSource: feeVarianceFranchisorDataSource,
            columns: feeVarianceFranchisorColumns,
            pageable: {
                numeric: false,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
        };
    }

    GetFeeVarianceFranchiseeGridOptions(data: any): kendo.ui.GridOptions {
        let feeVarianceFranchiseeGridDataSource = new kendo.data.DataSource({
            batch: true,
            pageSize: 10,
            schema: {
                model: {
                    id: "licenseNumber",
                    fields: {
                        franchisorId: {
                            type: "string"
                        },
                        licenseNumber: {
                            type: "string"
                        },
                        franchiseeName: {
                            type: "string"
                        },
                        doingBusinessAs: {
                            type: "string"
                        },
                        royaltyPeriodFrequency: {
                            type: "string"
                        },
                        feeTypeName: {
                            type: "string"
                        }
                    }
                }
            },
            transport: {
                read: (e: kendo.data.DataSourceTransportReadOptions) => {
                    e.success(data.franchiseeReportFeeVariationInfo);
                },
            }
        });

        let feeVarianceFranchiseeGridColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "franchisorId",
                title: "Franchisor Id",
                hidden: true,
            }, {
                field: "licenseNumber",
                title: "License Number",
            }, {
                field: "franchiseeName",
                title: "Name",
            }, {
                field: "doingBusinessAs",
                title: "DBA",
            }, {
                field: "feeTypeName",
                title: "Fee Type",
            }, {
                field: "royaltyPeriodFrequency",
                title: "Royalty Period Frequency",
            }];

        let numberOfDynamicColumns = +this.numberOfPeriods + +(this.numberOfPeriods - 1)
        let varianceCount = 1;
        let periodCount = 1
        for (let i = 1; i <= numberOfDynamicColumns; i++) {

            let title = '';
            if (i % 2 != 0) {

                if (this.numberOfPeriods - periodCount <= 0)
                    title = 'Most Recent Period';
                else
                    title = 'Most Recent Period - ' + (this.numberOfPeriods - periodCount)

                feeVarianceFranchiseeGridColumns.push({
                    field: 'period' + periodCount,
                    title: title,
                    format: "{0:n}"
                })

                periodCount++;
            }
            else {
                title = 'Calculated Variance'

                feeVarianceFranchiseeGridColumns.push({
                    field: 'variance' + varianceCount,
                    title: title,
                    template: "<span>#=variance" + varianceCount + "# %<\span>"
                })

                varianceCount++;
            }
        }

        return {
            columns: feeVarianceFranchiseeGridColumns,
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            scrollable: true,
            dataSource: feeVarianceFranchiseeGridDataSource,
            resizable: true,
            sortable: true,
            filterable: true,
        };
    }

    GetReStructuredFeeVarianceData(data: any[]) {

        let reStructuredFeeVarianceData = data.map(x => {
            let franchisor = {
                franchisorId: x.franchisorId,
                franchisorName: x.franchisorName,
                franchiseeReportCount: x.franchiseeCount
            }

            let franchiseeReportFeeVariationInfo = x.franchiseeReportFeeVariationInfo.map(x => {
                let franchiseReport = {
                    franchisorId: x.franchisorId,
                    licenseNumber: x.licenseNumber,
                    franchiseeName: x.franchiseeName,
                    doingBusinessAs: x.doingBusinessAs,
                    feeTypeName: x.feeTypeName
                }

                x.rmsDashboardVarianceDynamicColumns.forEach(y => {
                    franchiseReport[y.columnName] = y.value
                })

                return franchiseReport;
            })

            franchisor['franchiseeReportFeeVariationInfo'] = franchiseeReportFeeVariationInfo;

            return franchisor;
        });

        return reStructuredFeeVarianceData;
    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsDashboardFeeVarianceGrid", {
            bindings: {

            },
            templateUrl: '/Templates/RMSDashboard/RmsDashboardFeeVariance/rmsDashboardFeeVarianceGrid.html',
            controller: rmsDashboardFeeVarianceGridComponentController
        });
    }
}