import { LegalApiResources } from "Services/Resources/LegalApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { LegalEntities } from "Interfaces/FranForce/Legal/LegalResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

interface IViewSectionOneScope extends ng.IScope {
    contractId: string;
    franchiseId: string;
    selectionDetails: { franchiseID: string, contractID: string };
    activeSection: { section: string }
    monetaryItemOptions: LegalEntities.MonetaryItem[];
    operationItemOptions: LegalEntities.OperationItem[];
    reportingItemOptions: LegalEntities.ReportingItem[];
    timeScales: LegalEntities.TimeScale[];
    model: LegalEntities.SectionOne;
    MonetaryItem: number[];
    ReportingItem: number[];
    OperationItem: number[];
    SubtotalFees: () => number;
    PromissoryNotes: LegalEntities.SectionOnePromissoryNote[];
    promissoryNotesGridOptions: kendo.ui.GridOptions;
    sectionOneApprovedDateOptions: kendo.ui.DatePickerOptions;
    promissoryFees: (any) => void;
    gridDataSource: kendo.data.DataSource;
    isItt: boolean;
    OperationOtherFlag: boolean;
    OperationOtherComment: string;
}

export class ViewSectionOneController implements ng.IController {

    static $inject = [
        "$scope",
        "$window",
        "$q",
        "$log",
        "$http",
        "legalApiResources",
        "coreApiResources"
    ];

    constructor(
        private $scope: IViewSectionOneScope,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $http: ng.IHttpService,
        private legalApiResources: LegalApiResources,
        private coreApiResources: CoreApiResources
    )
    {

    }

    $onInit() {
        let $scope = this.$scope;
        let $http = this.$http;
        let $q = this.$q;
        let legalApiResources = this.legalApiResources;
        let columns: Array<kendo.ui.GridColumn> =
            [{
                field: "NoteNumber",
                title: "Note Number",
                width: "130px"
            }, {
                    field: "PromissoryNoteDate",
                    title: "Note Date",
                    template: "#= kendo.toString(kendo.parseDate(PromissoryNoteDate, 'yyyy-MM-dd'), 'MM/dd/yyyy') #",
                    width: "130px"
                }, {
                    field: "OriginalPrincipalAmount",
                    title: "Original Principal Amount",
                    format: "{0:c2}"
                }, {
                    field: "AmountToCureArrearage",
                    title: "Amount to Cure Arrearage",
                    format: "{0:c2}"
                }, {
                    field: "OutstandingBalance",
                    title: "Outstanding Balance",
                    format: "{0:c2}"
                }];

        // Assign selection details to retrieve associated data
        $scope.franchiseId = location.pathname.split("/")[3] || $scope.selectionDetails.franchiseID;
        $scope.contractId = location.pathname.split("/")[4] || $scope.selectionDetails.contractID;

        $scope.gridDataSource = new kendo.data.DataSource({
            data: [],
            schema: {
                model: {
                    fields: {
                        NoteNumber: { type: "number" },
                        PromissoryNoteDate: { type: "date" },
                        OriginalPrincipalAmount: { type: "number" },
                        AmountToCureArrearage: { type: "number" },
                        OutstandingBalance: { type: "number" }
                    }
                }
            },
            pageSize: 10
        });

        $scope.promissoryNotesGridOptions = {
            dataSource: $scope.gridDataSource,
            sortable: true,
            pageable: true,
            columns: columns
        }


        $scope.SubtotalFees = function () {
            if ($scope.model != undefined) {
                return $scope.model.MinimumFees + $scope.model.ReportingFees +
                    $scope.model.EstimatedFees + $scope.model.SoftwareFees + $scope.model.NSFFees +
                    $scope.model.LateFees + $scope.model.OtherFees1 + $scope.model.OtherFees2 + $scope.model.OtherFees3;
            }

            return 0;
        }

        $scope.promissoryFees = function () {
            if ($scope.gridDataSource != undefined) {
                var fees = 0;
                $scope.gridDataSource.data().toJSON().forEach(function (value: LegalEntities.SectionOnePromissoryNote) {
                    if ($scope.isItt == true) {
                        fees += value.AmountToCureArrearage;
                    } else {
                        fees += value.OutstandingBalance;
                    }
                });

                return fees;
            }

            return 0;
        }

        this.coreApiResources.FranchiseApi.get({ $expand: "Concept", id: $scope.franchiseId },
            (franchiseResponse: CoreEntities.Franchise) => {
                $scope.$emit("setFranchiseDetails", {
                    FranchiseName: franchiseResponse.Concept.DisplayName,
                    LicenseNum: franchiseResponse.LicenseNumber,
                    FranchiseeCode: $scope.franchiseId,
                    FranchiseAddress: franchiseResponse.Address,
                    FranchisePhoneNumber: franchiseResponse.StorePhone,
                    FranchiseEmail: franchiseResponse.StoreEmail
                });
                ;
            },
            errorResponse => {
                console.log(errorResponse);
            });

        legalApiResources.ContractApi.get({
            id: $scope.contractId,
            $expand: "SectionOne($expand=SectionOneMonetaryItems,SectionOneOperationItems,SectionOnePromissoryNotes,SectionOneReportingItems),ContractStatu"
        }, (contractResponse: LegalEntities.Contract) => {
            if (contractResponse.SectionOneID != null) {
                $scope.model = contractResponse.SectionOne;
                $scope.gridDataSource.data(contractResponse.SectionOne.SectionOnePromissoryNotes);
                $scope.isItt = contractResponse.ContractStatu.Description == "Intent To Terminate";
                $scope.MonetaryItem = [];
                contractResponse.SectionOne.SectionOneMonetaryItems.forEach(function (value) {
                    $scope.MonetaryItem.push(value.MonetaryItemID);
                });
                $scope.ReportingItem = [];
                contractResponse.SectionOne.SectionOneReportingItems.forEach(function (value) {
                    $scope.ReportingItem.push(value.ReportingItemID);
                });
                $scope.OperationItem = [];
                contractResponse.SectionOne.SectionOneOperationItems.forEach(function (value) {
                    if (value.OperationItemID == 5) {
                        $scope.OperationOtherFlag = true;
                        $scope.OperationOtherComment = value.Comment;
                    }
                        $scope.OperationItem.push(value.OperationItemID);
                });

                legalApiResources.MonetaryItemApi.query({}, successResponse => {
                    $scope.monetaryItemOptions = successResponse;
                }, errorResponse => {
                    console.log(errorResponse);
                });

                legalApiResources.OperationItemApi.query({}, successResponse => {
                    $scope.operationItemOptions = successResponse;
                }, errorResponse => {
                    console.log(errorResponse);
                });

                legalApiResources.ReportingItemApi.query({}, successResponse => {
                    $scope.reportingItemOptions = successResponse;
                }, errorResponse => {
                    console.log(errorResponse);
                });

                legalApiResources.TimeScaleApi.query({}, successResponse => {
                    $scope.timeScales = successResponse;
                }, errorResponse => {
                    console.log(errorResponse);
                });
            } else {
                $scope.model = <any>{};
            }

            $scope.$emit("setSectionAvaliability", {
                hasSectionTwo: contractResponse.SectionTwoID != null,
                hasSectionThree: contractResponse.SectionThreeID != null,
                franchiseID: $scope.franchiseId,
                contractID: $scope.contractId
            });

        }, errorResponse => {
            console.log(errorResponse);
        });  
    }
}


