import * as _ from "underscore"
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { IFRMStateParams } from "Controllers/Rms/FranchisorRoyaltyManagement/frmRouter";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { Helpers } from "Utility/Helpers";
import { reportingPeriodsDataModel } from "Models/Royalty/FranchisorRoyaltyManagement/ReportingPeriods/ReportingPeriodsDataModel"
import { RoyaltyApiResources } from "../../../Services/Resources/RoyaltyApiResources";

interface IFRMReportingPeriodScope extends ng.IScope {
}

export class FrmAddReportingPeriodComponentController implements ng.IController {
    //bindings
    royaltyPeriodsData: any;
    //end bindings

    royaltyPeriodsDataInserted: any;

    //add reporting periods dialog
    addReportingPeriodDialog: kendo.ui.Dialog;

    //frequency field for add reporting period
    reportingFrequenciesDropDownOptions: kendo.ui.DropDownListOptions;
    frequencyTypes: { RoyaltyPeriodFrequencyId: number, Description: string }[];
    selectedFrequency: number;

    //period end day field for add reporting period
    periodEndDayDropDownOptions: kendo.ui.DropDownListOptions;
    periodEndDayTypes: { periodEndDayId: string, periodEndDayText: string }[];
    selectedPeriodEndDay: string;

    //Year field for add reporting period
    reportingPeriodYear: number;

    //beginningDate
    beginningDate: Date;
    invalidBeginingDateCSSClass: string;

    //endDate
    endDate: Date;
    invalidEndDateCSSClass: string;

    showDateInputsForWeeklyReportingPeriod: boolean;

    //validations for add reporting period fields
    invalidTextFieldYearCSSClass: string
    isInValidLoadPeriodData: boolean;
    invalidLoadPeriodDataErrorMessage: string;
    invalidTextFieldPeriodEndDayCSSClass: string;
    disablePeriodEndDay: boolean;
    invalidTextFieldFrequencyCSSClass: string

    //show/hide or enable/disable elements till data is loaded for saving to DB add reporting period
    isloadPeriodsGridShow: boolean;
    disableSaveBtn: boolean;

    //username
    userName: string;

    // params data of loaded grid for saving to DB
    saveAddReportingPeriodParams: any;

    //periods to be added grid load data
    loadedPeriodGridOptions: kendo.ui.GridOptions;
    loadedPeriodDataSource: kendo.data.DataSource;
    loadedPeriodGrid: kendo.ui.Grid;


    static $inject = [
        'rpmUiApiResources',
        '$stateParams',
        'identityManager',
        '$window',
        'royaltyApiResources',
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private $stateParams: IFRMStateParams,
        private identityManager: IdentityManager,
        private $window: ng.IWindowService,
        private royaltyApiResources: RoyaltyApiResources,
    ) {
    }

    $onInit() {
        //void all validations
        this.setAllValidationsToFalse();

        this.showDateInputsForWeeklyReportingPeriod = false;

        this.rpmUiApiResources.GetReportingPeriodYearListingByFranchisorId(parseInt(this.$stateParams.franchisorId)).then(
            (response) => {
                this.royaltyPeriodsDataInserted = response.data;
            });

        //get logged in username
        this.identityManager.GetLoggedInUserInfo().then(
            (success) => {
                this.userName = success.data["FirstName"] ? success.data["FirstName"] : "unknown";
            },
            (error) => {
                console.log("error in getting userName", error);
            });
    }

    //on add button click
    onAddReportingPeriodClick() {

        //void all validations
        this.setAllValidationsToFalse();

        //unset all values
        this.setAllValidationsToFalse();
        this.isloadPeriodsGridShow = false;
        this.disableSaveBtn = true;

        //hide grid and disable save button
        this.isloadPeriodsGridShow = false;
        this.disableSaveBtn = true;

        //set drop down options for frequency
        this.rpmUiApiResources.GetAllRoyaltyPeriodFrequencies()
            .then(
                (response) => {
                    this.frequencyTypes = [];
                    response.data.forEach((item, index) => {
                        this.frequencyTypes.push({
                            Description: item.Description,
                            RoyaltyPeriodFrequencyId: item.RoyaltyPeriodFrequencyId,
                        })
                    })
                    this.reportingFrequenciesDropDownOptions = {
                        dataSource: this.frequencyTypes,
                        dataValueField: "RoyaltyPeriodFrequencyId",
                        dataTextField: "Description",
                        optionLabel: "Select Frequency"
                    }

                });

        //set dropdown for period end day
        this.periodEndDayTypes = [];
        Helpers.DaysOfWeekObjects().forEach(item => {
            this.periodEndDayTypes.push({
                periodEndDayId: item.name, periodEndDayText: item.name
            })
        })
        this.periodEndDayDropDownOptions = {
            dataSource: this.periodEndDayTypes,
            dataValueField: "periodEndDayId",
            dataTextField: "periodEndDayText",
            optionLabel: "Select Period End Day"
        };

        this.selectedPeriodEndDay = null;
        this.selectedFrequency = null;
        this.reportingPeriodYear = null;
        this.beginningDate = null;
        this.endDate = null;
        this.showDateInputsForWeeklyReportingPeriod = false;
        this.addReportingPeriodDialog.open();
    }

    //cancel add reporting periods
    cancelAddReportingPeriodDialog() {
        //void all validations
        this.setAllValidationsToFalse();

        //unset all values
        this.selectedPeriodEndDay = null;
        this.selectedFrequency = null;
        this.reportingPeriodYear = null;

        this.isloadPeriodsGridShow = false;
        this.disableSaveBtn = true;

        //close dialog
        this.addReportingPeriodDialog.close();
    }

    //load to be added reporting periods
    loadPeriod() {
        this.isloadPeriodsGridShow = false
        //validation checks
        if (this.checkIfFrequencyNotSelected()) {
            return;
        }
        if (this.checkIfYearFieldIsEmpty(this.reportingPeriodYear)) {
            return;
        }
        if (this.CheckIfPeriodEndDayIsRequired()) {
            return;
        }
        if (this.checkIfYearIsGreaterThanNextYear(this.reportingPeriodYear)) {
            return;
        }
        if (this.checkIfYearFrequencyExists(this.reportingPeriodYear)) {
            return;
        }

        let selectedFrequency = (Object.values(this.selectedFrequency)[1]).toString().toLowerCase();
        let beginningDate = null;
        let endDate = null;
        if (selectedFrequency == 2) {
            if (!this.beginningDate || isNaN(this.beginningDate.getTime())) {
                this.setErrorAddReportingPeriods("Please select Beginning Date");
                this.invalidBeginingDateCSSClass = "invalidBeginingDateCSSClass";
                return true;
            }
            else {
                this.setErrorAddReportingPeriods("");
                this.invalidBeginingDateCSSClass = "";
                this.isInValidLoadPeriodData = false;
            }

            if (!this.endDate || isNaN(this.endDate.getTime())) {
                this.setErrorAddReportingPeriods("Please select End Date");
                this.invalidEndDateCSSClass = "invalidEndDateCSSClass";
                return true;
            }
            else {
                this.setErrorAddReportingPeriods("");
                this.invalidEndDateCSSClass = "";
                this.isInValidLoadPeriodData = false;
            }

            if (this.beginningDate >= this.endDate) {
                this.setErrorAddReportingPeriods("End Date should be greater than Beginning Date");
                this.invalidBeginingDateCSSClass = "invalidEndDateCSSClass";
                this.invalidEndDateCSSClass = "invalidEndDateCSSClass";
                return true;
            }
            else {
                this.setErrorAddReportingPeriods("");
                this.invalidBeginingDateCSSClass = "";
                this.invalidEndDateCSSClass = "";
                this.isInValidLoadPeriodData = false;
            }

            beginningDate = this.getFormattedDate(this.beginningDate);
            endDate = this.getFormattedDate(this.endDate);
        }

        // confirmation for loading historical year
        if (this.reportingPeriodYear < new Date().getFullYear()) {
            var confirmation = confirm("Are you sure you want to add historical year?");
            if (!confirmation) {
                return;
            }
        }

        let selectedFrequencyValue = Object.values(this.selectedFrequency)[1];
        let selectedFrequencyText = Object.values(this.selectedFrequency)[0];
        let periodEndDay = null;
        if (this.selectedPeriodEndDay && selectedFrequencyText.toString().toLowerCase().trim() === "weekly") {
            periodEndDay = Object.values(this.selectedPeriodEndDay)[0];
        }

        this.rpmUiApiResources.LoadReportingPeriodInfo(this.reportingPeriodYear, selectedFrequencyValue, parseInt(this.$stateParams.franchisorId), this.userName, periodEndDay, beginningDate, endDate)
            .then(
                (response) => {

                    if (response && response.data) {
                        let royaltyPeriodInfo = response.data;
                        royaltyPeriodInfo = _.sortBy(royaltyPeriodInfo, rp => rp.PeriodStartDate);
                        if (this.loadedPeriodDataSource && this.loadedPeriodDataSource.data) {
                            this.loadedPeriodDataSource.data(royaltyPeriodInfo);
                        }
                        this.isloadPeriodsGridShow = true;
                        this.disableSaveBtn = false;
                        this.saveAddReportingPeriodParams = {
                            year: this.reportingPeriodYear,
                            frequency: selectedFrequencyValue,
                            franchisorId: parseInt(this.$stateParams.franchisorId),
                            userName: this.userName,
                            periodEndDay: periodEndDay
                        }
                    }
                });

        //********************************************************************
        //***************************** GRIDS ********************************
        //********************************************************************

        let loadedPeriodColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.PeriodStartDate),
                title: "Period Start Date",
                template: (dataItem) => {
                    return kendo.toString(kendo.parseDate(dataItem.PeriodStartDate), 'yyyy-MM-dd');
                },
                editable: () => false
            },
            {
                field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.PeriodEndDate),
                title: "Period End Date",
                template: (dataItem) => {
                    return kendo.toString(kendo.parseDate(dataItem.PeriodEndDate), 'yyyy-MM-dd');
                },
                editable: () => false
            },
            {
                title: "Year",
                field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.Year),
            },
            {
                field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.Quarter),
                title: "Quarter",
            },
            {
                field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.MonthNumber),
                title: "Month",
            },
            {
                field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.WeekNumber),
                title: "Week",
            }
        ];
        this.loadedPeriodDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    fields: {
                        PeriodStartDate: { type: "string" },
                        PeriodEndDate: { type: "string" },
                        Year: { type: "number", validation: { required: true, min: 0  } },
                        Quarter: { type: "number", validation: { required: true, min: 0 } },
                        MonthNumber: { type: "number", validation: { required: true, min: 0 } },
                        WeekNumber: { type: "number", validation: { required: true, min: 0 } },
                    }
                }
            }
        });
        this.loadedPeriodGridOptions = {
            columns: loadedPeriodColumns,
            dataSource: this.loadedPeriodDataSource,
            sortable: true,
            scrollable: true,
            resizable: true,
            editable: true
        }
    }

    //void all validations
    setAllValidationsToFalse() {
        this.invalidTextFieldFrequencyCSSClass = "";
        this.invalidTextFieldPeriodEndDayCSSClass = "";
        this.invalidTextFieldYearCSSClass = "";
        this.disablePeriodEndDay = true;
        this.isInValidLoadPeriodData = false;
        this.invalidLoadPeriodDataErrorMessage = "";
        this.isloadPeriodsGridShow = false;
        this.disableSaveBtn = true;

        this.setErrorAddReportingPeriods("");
        this.invalidBeginingDateCSSClass = "";
        this.invalidEndDateCSSClass = "";
        this.isInValidLoadPeriodData = false;
    }

    setErrorAddReportingPeriods(errorMsg: string) {
        this.isInValidLoadPeriodData = true;
        this.invalidLoadPeriodDataErrorMessage = errorMsg;
    }

    unSetErrorAddReportingPeriods() {
        this.isInValidLoadPeriodData = false;
        this.invalidLoadPeriodDataErrorMessage = "";
    }

    //check if frequency is not selected
    checkIfFrequencyNotSelected() {
        if (!this.selectedFrequency) {
            this.setErrorAddReportingPeriods("Please select frequency");
            this.invalidTextFieldFrequencyCSSClass = "invalidTextFieldFrequencyCSSClass";
            return true;
        }
        else {
            let selectedFrequency = (Object.values(this.selectedFrequency)[1]).toString().toLowerCase();
            if (selectedFrequency.toString().trim() == "") {
                this.setErrorAddReportingPeriods("Please select frequency");
                this.invalidTextFieldFrequencyCSSClass = "invalidTextFieldFrequencyCSSClass";
                return true;
            }
            else {
                this.unSetErrorAddReportingPeriods()
                this.invalidTextFieldFrequencyCSSClass = "";
                return false;
            }
        }
    }

    //validation check for empty year
    checkIfYearFieldIsEmpty(year) {
        if (!year || year.toString().trim() === "") {
            this.setErrorAddReportingPeriods("Please enter year");
            this.invalidTextFieldYearCSSClass = "invalidTextFieldYearCSSClass";
            return true;
        }
        else {
            this.unSetErrorAddReportingPeriods()
            this.invalidTextFieldYearCSSClass = "";
            return false;
        }
    }

    //check if period end day is required
    CheckIfPeriodEndDayIsRequired() {
        //check if frequency is selected to weekly
        if (this.selectedFrequency) {
            let selectedFrequency = (Object.values(this.selectedFrequency)[0]).toString().toLowerCase().trim();
            //if weekly frequency is selected then PeriodEndDay should be selected
            if (selectedFrequency === "weekly") {
                if (!this.selectedPeriodEndDay || (Object.values(this.selectedPeriodEndDay)[1]).toString().toLowerCase().trim() === "") {
                    this.setErrorAddReportingPeriods("Please select period end day");
                    this.invalidTextFieldPeriodEndDayCSSClass = "invalidTextFieldPeriodEndDayCSSClass";
                    return true;
                }
                else {
                    this.unSetErrorAddReportingPeriods()
                    this.invalidTextFieldPeriodEndDayCSSClass = "";
                    return false;
                }
            }
            else {
                return false;
            }
        }

    }

    //validate if year is greater than next year
    checkIfYearIsGreaterThanNextYear(year) {
        if (year > new Date().getFullYear() + 1) {
            this.setErrorAddReportingPeriods("Year should not be greater than next year");
            this.invalidTextFieldYearCSSClass = "invalidTextFieldYearCSSClass";
            return true;
        }
        else {
            this.unSetErrorAddReportingPeriods()
            this.invalidTextFieldYearCSSClass = "";
            return false;
        }
    }

    //validation to check if yearly and frequency exists
    checkIfYearFrequencyExists(year) {
        var yearlyFrequencyExists = false;
        let selectedFrequency = (Object.values(this.selectedFrequency)[0]).toString().toLowerCase();
        if (this.royaltyPeriodsDataInserted && this.royaltyPeriodsDataInserted.length > 0) {
            this.royaltyPeriodsDataInserted.forEach((item, index) => {
                let yearVal = item.Year;
                let frequencyVal = item.Frequency.toString().toLowerCase();
                if ((year == yearVal) && (frequencyVal == selectedFrequency)) {
                    yearlyFrequencyExists = true;
                }
            })
        }

        if (yearlyFrequencyExists) {
            this.setErrorAddReportingPeriods("Frequency for the year already exists");
            this.invalidTextFieldYearCSSClass = "invalidTextFieldYearCSSClass";
            this.invalidTextFieldFrequencyCSSClass = "invalidTextFieldFrequencyCSSClass";
            return true;
        }
        else {
            this.unSetErrorAddReportingPeriods()
            this.invalidTextFieldYearCSSClass = "";
            this.invalidTextFieldFrequencyCSSClass = "";
            return false;
        }
    }

    onFrequencyChange() {
        //set PeriodEndDaySelect to default on frequency change
        let dropdownlist = $("#reportingPeriodEndDaySelect").data("kendoDropDownList");
        dropdownlist.value("");
        dropdownlist.trigger("change");

        //disable PeriodEndDaySelect if week is not selected
        let selectedFrequency = (Object.values(this.selectedFrequency)[1]).toString().toLowerCase();
        this.disablePeriodEndDay = false;
        this.disablePeriodEndDay = (selectedFrequency === "1" || selectedFrequency === "3" || selectedFrequency === "4")
        this.showDateInputsForWeeklyReportingPeriod = (selectedFrequency === "2");
    }

    //Save added reporting period to DB
    onSave() {
        let reportingPeriodsData = this.loadedPeriodDataSource.data();
        let reportingPeriods: reportingPeriodsDataModel[] = [];
        if (reportingPeriodsData && reportingPeriodsData.length > 0) {
            reportingPeriodsData.forEach((x) => {
                let periodStartDate = this.getISOFormattedDate(new Date(x['PeriodStartDate']));
                let periodEndDate = this.getISOFormattedDate(new Date(x['PeriodEndDate']));

                let reportingPeriod: reportingPeriodsDataModel = {
                    periodStartDate: periodStartDate,
                    periodEndDate: periodEndDate,
                    year: x['Year'],
                    quarter: x['Quarter'],
                    month: x['MonthNumber'],
                    week: x['WeekNumber'],
                    frequencyId: x['RoyaltyPeriodFrequencyId']
                }
                reportingPeriods.push(reportingPeriod);
            });
        }
        else {
            this.setErrorAddReportingPeriods("No data available for saving");
            return;
        }

        this.royaltyApiResources.AddReportingPeriods(this.saveAddReportingPeriodParams.franchisorId, reportingPeriods)
            .then(result => {
                if (result && result.data) {
                    this.cancelAddReportingPeriodDialog();
                    this.$window.location.reload();
                }
                else {
                    this.setErrorAddReportingPeriods("Error in adding royalty reporting period.");
                    return;
                }
 
            })
            .catch(ex => {
                this.setErrorAddReportingPeriods("Error in adding royalty reporting period.");
                return;
            });   
    }

    getFormattedDate(date: Date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '-' + day + '-' + year;
    }

    getISOFormattedDate(date: Date) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        return year + '-' + month + '-' + day + 'T00:00:00Z';
    }

    static BindComponent(app: ng.IModule) {
        app.component('frmAddReportingPeriods', {
            bindings: {
                [nameof<FrmAddReportingPeriodComponentController>(o => o.royaltyPeriodsData)]: "<",
            },
            controller: FrmAddReportingPeriodComponentController,
            templateUrl: "/Templates/FranchisorRoyaltyManagement/frmAddReportingPeriods.html"
        });
    }
}


