import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyServiceCategoriesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyServiceCategoriesByFranchisor(franchisorId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyServiceCategories/InFranchisor/" + franchisorId
        };

        return this.$http<RpmEntities.RoyaltyServiceCategoryViewModel[]>(config);
    }

    GetRoyaltyServiceCategoriesByContract(contractId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyServiceCategories/InContract/" + contractId
        };

        return this.$http<RpmEntities.usp_RoyaltyServiceCategorys_GetByContractId_Result[]>(config);
    }
}
