import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { DisclosureDocumentsRpmUiPartialService } from "Services/RpmUiPartials/DisclosureDocumentsRpmUiPartialService";
import { DisclosureDocumentKeyValuePairsRpmUiPartialService } from "Services/RpmUiPartials/DisclosureDocumentKeyValuePairsRpmUiPartialService";
import { FranchisorRpmUiPartialService } from "Services/RpmUiPartials/FranchisorRpmUiPartialService";
import { RoyaltyContractKeyValuePairsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyContractKeyValuePairsRpmUiPartialService";
import { RoyaltyContractMinimumFeeIncreasesAndCapsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyContractMinimumFeeIncreasesAndCapsRpmUiPartialService";
import { RoyaltyContractRatePlanItemDetailsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyContractRatePlanItemDetailsRpmUiPartialService";
import { RoyaltyContractRatePlanItemsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyContractRatePlanItemsRpmUiPartialService";
import { RoyaltyContractRatePlanMinimumItemsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyContractRatePlanMinimumItemsRpmUiPartialService";
import { RoyaltyContractRatePlansRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyContractRatePlansRpmUiPartialService";
import { RoyaltyContractsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyContractsRpmUiPartialService";
import { RoyaltyContractStatusesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyContractStatusesRpmUiPartialService";
import { RoyaltyContractTerminatedReasonsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyContractTerminatedReasonsRpmUiPartialService";
import { RoyaltyDisclosureDocumentRatePlanItemDetailsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyDisclosureDocumentRatePlanItemDetailsRpmUiPartialService";
import { RoyaltyDisclosureDocumentRatePlanItemsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyDisclosureDocumentRatePlanItemsRpmUiPartialService";
import { RoyaltyDisclosureDocumentRatePlanMinimumItemDetailsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyDisclosureDocumentRatePlanMinimumItemDetailsRpmUiPartialService";
import { RoyaltyDisclosureDocumentRatePlanMinimumItemsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyDisclosureDocumentRatePlanMinimumItemsRpmUiPartialService";
import { RoyaltyDisclosureDocumentRatePlansRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyDisclosureDocumentRatePlansRpmUiPartialService";
import { RoyaltyFindScaleBasedOnTypesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyFindScaleBasedOnTypesRpmUiPartialService";
import { RoyaltyFeeTypesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyFeeTypesRpmUiPartialService";
import { RoyaltyItemsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyItemsRpmUiPartialService";
import { RoyaltyMethodologyTypesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyMethodologyTypesRpmUiPartialService";
import { RoyaltyPeriodFrequenciesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyPeriodFrequenciesRpmUiPartialService";
import { RoyaltyPeriodReportDetailsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyPeriodReportDetailsRpmUiPartialService";
import { RoyaltyPeriodReportsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyPeriodReportsRpmUiPartialService";
import { RoyaltyPeriodsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyPeriodsRpmUiPartialService";
import { RoyaltyPosInvoicesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyPosInvoicesRpmUiPartialService";
import { RoyaltyPosPaymentsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyPosPaymentsRpmUiPartialService";
import { RoyaltyPosTransactionsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyPOSTransactionsRpmUiPartialService";
import { RoyaltyReportBatchesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyReportBatchesRpmUiPartialService";
import { RoyaltyReportBatchStatusesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyReportBatchStatusesRpmUiPartialService";
import { RoyaltySyncInvoiceDetailsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltySyncInvoiceDetailsRpmUiPartialService";
import { RoyaltySyncInvoicesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltySyncInvoicesRpmUiPartialService";
import { RoyaltySystemsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltySystemsRpmUiPartialService";
import { RoyaltyRatePlanTypesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyRatePlanTypesRpmUiPartialService";
import { RoyaltyRatePlanItemTypesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyRatePlanItemTypesRpmUiPartialService";
import { RoyaltyServiceCategoriesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyServiceCategoriesRpmUiPartialService";
import { RoyaltyManualSalesEntryRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyManualSalesEntryRpmUiPartialService";
import { RoyaltyManualSalesEntryDetailRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyManualSalesEntryDetailRpmUiPartialService";
import { RoyaltyManualSalesProcessStatusesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyManualSalesProcessStatusesRpmUiPartialService";
import { RoyaltyPeriodReportAdjustmentsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyPeriodReportAdjustmentsRpmUiPartialService";
import { RoyaltyPeriodReportStatusesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyPeriodReportStatusesRpmUiPartialService";
import { RoyaltyInvoiceExportBatchesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyInvoiceExportBatchesRpmUiPartialService";
import { RoyaltyContractSourcesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyContractSourcesRpmUiPartialService";
import { RoyaltyDataTypesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyDataTypesRpmUiPartialService ";
import { RoyaltyKeyValuePairsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyKeyValuePairsRpmUiPartialService";
import { RoyaltyServiceCategoryTypesRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyServiceCategoryTypesRpmUiPartialService";
import { RoyaltyFranchisorMinimumFeeIncreasesAndCapsRpmUiPartialService } from "Services/RpmUiPartials/RoyaltyFranchisorMinimumFeeIncreasesAndCapsRpmUiPartialService";

export class RpmUiApiResources implements
    IRpmUiPartialService,
    DisclosureDocumentKeyValuePairsRpmUiPartialService,
    DisclosureDocumentsRpmUiPartialService,
    FranchisorRpmUiPartialService,
    RoyaltyContractKeyValuePairsRpmUiPartialService,
    RoyaltyContractMinimumFeeIncreasesAndCapsRpmUiPartialService,
    RoyaltyContractRatePlanItemDetailsRpmUiPartialService,
    RoyaltyContractRatePlanItemsRpmUiPartialService,
    RoyaltyContractRatePlanMinimumItemsRpmUiPartialService,
    RoyaltyContractRatePlansRpmUiPartialService,
    RoyaltyContractsRpmUiPartialService,
    RoyaltyContractStatusesRpmUiPartialService,
    RoyaltyContractTerminatedReasonsRpmUiPartialService,
    RoyaltyDataTypesRpmUiPartialService,
    RoyaltyDisclosureDocumentRatePlanItemDetailsRpmUiPartialService,
    RoyaltyDisclosureDocumentRatePlanItemsRpmUiPartialService,
    RoyaltyDisclosureDocumentRatePlanMinimumItemDetailsRpmUiPartialService,
    RoyaltyDisclosureDocumentRatePlanMinimumItemsRpmUiPartialService,
    RoyaltyDisclosureDocumentRatePlansRpmUiPartialService,
    RoyaltyFindScaleBasedOnTypesRpmUiPartialService,
    RoyaltyFeeTypesRpmUiPartialService,
    RoyaltyItemsRpmUiPartialService,
    RoyaltyKeyValuePairsRpmUiPartialService,
    RoyaltyMethodologyTypesRpmUiPartialService,
    RoyaltyPeriodFrequenciesRpmUiPartialService,
    RoyaltyPeriodReportDetailsRpmUiPartialService,
    RoyaltyPeriodReportsRpmUiPartialService,
    RoyaltyPeriodsRpmUiPartialService,
    RoyaltyPosInvoicesRpmUiPartialService,
    RoyaltyPosPaymentsRpmUiPartialService,
    RoyaltyPosTransactionsRpmUiPartialService,
    RoyaltyReportBatchesRpmUiPartialService,
    RoyaltyReportBatchStatusesRpmUiPartialService,
    RoyaltySyncInvoiceDetailsRpmUiPartialService,
    RoyaltySyncInvoicesRpmUiPartialService,
    RoyaltySystemsRpmUiPartialService,
    RoyaltyRatePlanTypesRpmUiPartialService,
    RoyaltyRatePlanItemTypesRpmUiPartialService,
    RoyaltyManualSalesEntryRpmUiPartialService,
    RoyaltyManualSalesEntryDetailRpmUiPartialService,
    RoyaltyManualSalesProcessStatusesRpmUiPartialService,
    RoyaltyServiceCategoriesRpmUiPartialService,
    RoyaltyPeriodReportAdjustmentsRpmUiPartialService,
    RoyaltyPeriodReportStatusesRpmUiPartialService,
    RoyaltyInvoiceExportBatchesRpmUiPartialService,
    RoyaltyServiceCategoryTypesRpmUiPartialService,
    RoyaltyContractSourcesRpmUiPartialService,
    RoyaltyFranchisorMinimumFeeIncreasesAndCapsRpmUiPartialService
{
    endpointPrefix: string;
    
    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $resource: ng.resource.IResourceService,
        public $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {
        this.endpointPrefix = this.apiConfig.AppSettings.CoreApiRootUrl + "/RpmUi";
    }

    CreateDisclosureDocumentKeyValuePair = DisclosureDocumentKeyValuePairsRpmUiPartialService.prototype.CreateDisclosureDocumentKeyValuePair;
    GetDisclosureDocumentKeyValuePairsByDisclosureDocument = DisclosureDocumentKeyValuePairsRpmUiPartialService.prototype.GetDisclosureDocumentKeyValuePairsByDisclosureDocument;
    UpdateDisclosureDocumentKeyValuePair = DisclosureDocumentKeyValuePairsRpmUiPartialService.prototype.UpdateDisclosureDocumentKeyValuePair;
    CreateDisclosureDocument = DisclosureDocumentsRpmUiPartialService.prototype.CreateDisclosureDocument;
    CreateRoyaltyContractByDisclosureDocumentDynamic = DisclosureDocumentsRpmUiPartialService.prototype.CreateRoyaltyContractByDisclosureDocumentDynamic;
    CreateRoyaltyContractByDisclosureDocument = DisclosureDocumentsRpmUiPartialService.prototype.CreateRoyaltyContractByDisclosureDocument;
    DuplicateDisclosureDocument = DisclosureDocumentsRpmUiPartialService.prototype.DuplicateDisclosureDocument;
    GetDisclosureDocumentById = DisclosureDocumentsRpmUiPartialService.prototype.GetDisclosureDocumentById;
    GetDisclosureDocumentByIdDynamic = DisclosureDocumentsRpmUiPartialService.prototype.GetDisclosureDocumentByIdDynamic;
    GetDisclosureDocumentsByFranchisorId = DisclosureDocumentsRpmUiPartialService.prototype.GetDisclosureDocumentsByFranchisorId;
    UpdateDisclosureDocument = DisclosureDocumentsRpmUiPartialService.prototype.UpdateDisclosureDocument;
    UpdateDisclosureDocumentDynamic = DisclosureDocumentsRpmUiPartialService.prototype.UpdateDisclosureDocumentDynamic;
    GetFranchisorById = FranchisorRpmUiPartialService.prototype.GetFranchisorById;
    SetFranchisorRoyaltyFields = FranchisorRpmUiPartialService.prototype.SetFranchisorRoyaltyFields;
    GetRoyaltyContractKeyValuePairsByContract = RoyaltyContractKeyValuePairsRpmUiPartialService.prototype.GetRoyaltyContractKeyValuePairsByContract;
    CreateRoyaltyContractKeyValuePair = RoyaltyContractKeyValuePairsRpmUiPartialService.prototype.CreateRoyaltyContractKeyValuePair;
    UpdateRoyaltyContractKeyValuePair = RoyaltyContractKeyValuePairsRpmUiPartialService.prototype.UpdateRoyaltyContractKeyValuePair;
    GetRoyaltyContractRatePlanItemDetailsByContractPlanItem = RoyaltyContractRatePlanItemDetailsRpmUiPartialService.prototype.GetRoyaltyContractRatePlanItemDetailsByContractPlanItem
    GetRoyaltyContractRatePlanItemDetailsByContractPlanItemDynamic = RoyaltyContractRatePlanItemDetailsRpmUiPartialService.prototype.GetRoyaltyContractRatePlanItemDetailsByContractPlanItemDynamic
    CreateRoyaltyContractRatePlanItemDetail = RoyaltyContractRatePlanItemDetailsRpmUiPartialService.prototype.CreateRoyaltyContractRatePlanItemDetail;
    UpdateRoyaltyContractRatePlanItemDetail = RoyaltyContractRatePlanItemDetailsRpmUiPartialService.prototype.UpdateRoyaltyContractRatePlanItemDetail;
    RoyaltyContractMinimumFeeIncreasesAndCapsByContract = RoyaltyContractMinimumFeeIncreasesAndCapsRpmUiPartialService.prototype.RoyaltyContractMinimumFeeIncreasesAndCapsByContract;
    CreateRoyaltyContractMinimumFeeIncreasesAndCaps = RoyaltyContractMinimumFeeIncreasesAndCapsRpmUiPartialService.prototype.CreateRoyaltyContractMinimumFeeIncreasesAndCaps
    UpdateRoyaltyContractMinimumFeeIncreasesAndCaps = RoyaltyContractMinimumFeeIncreasesAndCapsRpmUiPartialService.prototype.UpdateRoyaltyContractMinimumFeeIncreasesAndCaps; GetRoyaltyContractRatePlanItemsByContractPlanItem = RoyaltyContractRatePlanItemsRpmUiPartialService.prototype.GetRoyaltyContractRatePlanItemsByContractPlanItem;
    CreateRoyaltyContractRatePlanItem = RoyaltyContractRatePlanItemsRpmUiPartialService.prototype.CreateRoyaltyContractRatePlanItem;
    UpdateRoyaltyContractRatePlanItem = RoyaltyContractRatePlanItemsRpmUiPartialService.prototype.UpdateRoyaltyContractRatePlanItem;
    GetRoyaltyContractRatePlanMinimumItemsByContractPlanItem = RoyaltyContractRatePlanMinimumItemsRpmUiPartialService.prototype.GetRoyaltyContractRatePlanMinimumItemsByContractPlanItem;
    CreateRoyaltyContractRatePlanMinimumItem = RoyaltyContractRatePlanMinimumItemsRpmUiPartialService.prototype.CreateRoyaltyContractRatePlanMinimumItem;
    UpdateRoyaltyContractRatePlanMinimumItem = RoyaltyContractRatePlanMinimumItemsRpmUiPartialService.prototype.UpdateRoyaltyContractRatePlanMinimumItem;
    GetRoyaltyContractRatePlansByContract = RoyaltyContractRatePlansRpmUiPartialService.prototype.GetRoyaltyContractRatePlansByContract;
    CreateRoyaltyContractRatePlan = RoyaltyContractRatePlansRpmUiPartialService.prototype.CreateRoyaltyContractRatePlan;
    UpdateRoyaltyContractRatePlan = RoyaltyContractRatePlansRpmUiPartialService.prototype.UpdateRoyaltyContractRatePlan;
    GetRoyaltyContract = RoyaltyContractsRpmUiPartialService.prototype.GetRoyaltyContract;
    GetRoyaltyContractDynamic = RoyaltyContractsRpmUiPartialService.prototype.GetRoyaltyContractDynamic;
    GetRoyaltyContractsByFranchisor = RoyaltyContractsRpmUiPartialService.prototype.GetRoyaltyContractsByFranchisor;
    GetRoyaltyContractsByFranchise = RoyaltyContractsRpmUiPartialService.prototype.GetRoyaltyContractsByFranchise;
    CreateRoyaltyContract = RoyaltyContractsRpmUiPartialService.prototype.CreateRoyaltyContract;
    UpdateRoyaltyContract = RoyaltyContractsRpmUiPartialService.prototype.UpdateRoyaltyContract;
    UpdateRoyaltyContractDynamic = RoyaltyContractsRpmUiPartialService.prototype.UpdateRoyaltyContractDynamic;
    DuplicateRoyaltyContract = RoyaltyContractsRpmUiPartialService.prototype.DuplicateRoyaltyContract;
    GetAllRoyaltyContractStatuses = RoyaltyContractStatusesRpmUiPartialService.prototype.GetAllRoyaltyContractStatuses;
    GetAllRoyaltyContractTerminatedReasons = RoyaltyContractTerminatedReasonsRpmUiPartialService.prototype.GetAllRoyaltyContractTerminatedReasons;
    GetAllRoyaltyDataTypes = RoyaltyDataTypesRpmUiPartialService.prototype.GetAllRoyaltyDataTypes;
    GetRoyaltyDisclosureDocumentRatePlanItemDetailsByRatePlanItem = RoyaltyDisclosureDocumentRatePlanItemDetailsRpmUiPartialService.prototype.GetRoyaltyDisclosureDocumentRatePlanItemDetailsByRatePlanItem;
    CreateRoyaltyDisclosureDocumentRatePlanItemDetail = RoyaltyDisclosureDocumentRatePlanItemDetailsRpmUiPartialService.prototype.CreateRoyaltyDisclosureDocumentRatePlanItemDetail;
    UpdateRoyaltyDisclosureDocumentRatePlanItemDetail = RoyaltyDisclosureDocumentRatePlanItemDetailsRpmUiPartialService.prototype.UpdateRoyaltyDisclosureDocumentRatePlanItemDetail;
    GetRoyaltyDisclosureDocumentRatePlanItemsByRatePlan = RoyaltyDisclosureDocumentRatePlanItemsRpmUiPartialService.prototype.GetRoyaltyDisclosureDocumentRatePlanItemsByRatePlan;
    CreateRoyaltyDisclosureDocumentRatePlanItem = RoyaltyDisclosureDocumentRatePlanItemsRpmUiPartialService.prototype.CreateRoyaltyDisclosureDocumentRatePlanItem;
    UpdateRoyaltyDisclosureDocumentRatePlanItem = RoyaltyDisclosureDocumentRatePlanItemsRpmUiPartialService.prototype.UpdateRoyaltyDisclosureDocumentRatePlanItem;
    GetRoyaltyDisclosureDocumentRatePlanMinimumItemDetailsByRatePlanMinimumItem = RoyaltyDisclosureDocumentRatePlanMinimumItemDetailsRpmUiPartialService.prototype.GetRoyaltyDisclosureDocumentRatePlanMinimumItemDetailsByRatePlanMinimumItem;
    CreateRoyaltyDisclosureDocumentRatePlanMinimumItemDetail = RoyaltyDisclosureDocumentRatePlanMinimumItemDetailsRpmUiPartialService.prototype.CreateRoyaltyDisclosureDocumentRatePlanMinimumItemDetail;
    UpdateRoyaltyDisclosureDocumentRatePlanMinimumItemDetail = RoyaltyDisclosureDocumentRatePlanMinimumItemDetailsRpmUiPartialService.prototype.UpdateRoyaltyDisclosureDocumentRatePlanMinimumItemDetail;
    GetRoyaltyDisclosureDocumentRatePlanMinimumItemsByRatePlan = RoyaltyDisclosureDocumentRatePlanMinimumItemsRpmUiPartialService.prototype.GetRoyaltyDisclosureDocumentRatePlanMinimumItemsByRatePlan;
    CreateRoyaltyDisclosureDocumentRatePlanMinimumItem = RoyaltyDisclosureDocumentRatePlanMinimumItemsRpmUiPartialService.prototype.CreateRoyaltyDisclosureDocumentRatePlanMinimumItem;
    UpdateRoyaltyDisclosureDocumentRatePlanMinimumItem = RoyaltyDisclosureDocumentRatePlanMinimumItemsRpmUiPartialService.prototype.UpdateRoyaltyDisclosureDocumentRatePlanMinimumItem;
    GetRoyaltyDisclosureDocumentRatePlansByDisclosureDocument = RoyaltyDisclosureDocumentRatePlansRpmUiPartialService.prototype.GetRoyaltyDisclosureDocumentRatePlansByDisclosureDocument;
    CreateRoyaltyDisclosureDocumentRatePlan = RoyaltyDisclosureDocumentRatePlansRpmUiPartialService.prototype.CreateRoyaltyDisclosureDocumentRatePlan;
    UpdateRoyaltyDisclosureDocumentRatePlan = RoyaltyDisclosureDocumentRatePlansRpmUiPartialService.prototype.UpdateRoyaltyDisclosureDocumentRatePlan;
    GetAllRoyaltyFindScaleBasedOnTypes = RoyaltyFindScaleBasedOnTypesRpmUiPartialService.prototype.GetAllRoyaltyFindScaleBasedOnTypes;
    GetAllRoyaltyFeeTypes = RoyaltyFeeTypesRpmUiPartialService.prototype.GetAllRoyaltyFeeTypes;
    GetAllRoyaltyFeeTypeById = RoyaltyFeeTypesRpmUiPartialService.prototype.GetAllRoyaltyFeeTypeById;
    GetRoyaltyItemsByFranchisor = RoyaltyItemsRpmUiPartialService.prototype.GetRoyaltyItemsByFranchisor;
    GetRoyaltyItemFeeTypesByFranchisor = RoyaltyItemsRpmUiPartialService.prototype.GetRoyaltyItemFeeTypesByFranchisor;
    CreateRoyaltyItem = RoyaltyItemsRpmUiPartialService.prototype.CreateRoyaltyItem;
    CreateRoyaltyItemFeeType = RoyaltyItemsRpmUiPartialService.prototype.CreateRoyaltyItemFeeType;
    UpdateRoyaltyItem = RoyaltyItemsRpmUiPartialService.prototype.UpdateRoyaltyItem;
    UpdateRoyaltyItemFeeType = RoyaltyItemsRpmUiPartialService.prototype.UpdateRoyaltyItemFeeType;
    GetAllRoyaltyKeyValuePairs = RoyaltyKeyValuePairsRpmUiPartialService.prototype.GetAllRoyaltyKeyValuePairs;
    GetRoyaltyKeyValuePairById = RoyaltyKeyValuePairsRpmUiPartialService.prototype.GetRoyaltyKeyValuePairById;
    GetAllRoyaltyMethodologyTypes = RoyaltyMethodologyTypesRpmUiPartialService.prototype.GetAllRoyaltyMethodologyTypes;
    GetAllRoyaltyPeriodFrequencies = RoyaltyPeriodFrequenciesRpmUiPartialService.prototype.GetAllRoyaltyPeriodFrequencies;
    GetRoyaltyPeriodReportDetailsByPeriodReport = RoyaltyPeriodReportDetailsRpmUiPartialService.prototype.GetRoyaltyPeriodReportDetailsByPeriodReport;
    GetRoyaltyPeriodReportDetailsByPeriodReportDynamic = RoyaltyPeriodReportDetailsRpmUiPartialService.prototype.GetRoyaltyPeriodReportDetailsByPeriodReportDynamic;
    GetRoyaltyPeriodReport = RoyaltyPeriodReportsRpmUiPartialService.prototype.GetRoyaltyPeriodReport;
    GetRoyaltyPeriodReportDynamic = RoyaltyPeriodReportsRpmUiPartialService.prototype.GetRoyaltyPeriodReportDynamic;
    GetRoyaltyPeriodReportsByFranchise = RoyaltyPeriodReportsRpmUiPartialService.prototype.GetRoyaltyPeriodReportsByFranchise;
    GetRoyaltyPeriodReportsByFranchises = RoyaltyPeriodReportsRpmUiPartialService.prototype.GetRoyaltyPeriodReportsByFranchises;
    GetRoyaltyPeriodReportEmail = RoyaltyPeriodReportsRpmUiPartialService.prototype.GetRoyaltyPeriodReportEmail;
    BackoutRoyaltyPeriodReports = RoyaltyPeriodReportsRpmUiPartialService.prototype.BackoutRoyaltyPeriodReports;
    GetRoyaltyPeriod = RoyaltyPeriodsRpmUiPartialService.prototype.GetRoyaltyPeriod;
    GetRoyaltyPeriodsByFranchisor = RoyaltyPeriodsRpmUiPartialService.prototype.GetRoyaltyPeriodsByFranchisor;
    GetRoyaltyPeriodDatesByBeforeDate = RoyaltyPeriodsRpmUiPartialService.prototype.GetRoyaltyPeriodDatesByBeforeDate;
    GetRoyaltyPosInvoicesByFranchiseAndBatch = RoyaltyPosInvoicesRpmUiPartialService.prototype.GetRoyaltyPosInvoicesByFranchiseAndBatch;
    GetRoyaltyPosPaymentsByRoyaltyPeriodReportId = RoyaltyPosPaymentsRpmUiPartialService.prototype.GetRoyaltyPosPaymentsByRoyaltyPeriodReportId;
    GetRoyaltyPosTransactionsByFranchiseAndBatch = RoyaltyPosTransactionsRpmUiPartialService.prototype.GetRoyaltyPosTransactionsByFranchiseAndBatch;
    GetRoyaltyReportBatchById = RoyaltyReportBatchesRpmUiPartialService.prototype.GetRoyaltyReportBatchById;
    GetRoyaltyReportBatchesByPeriodEndDate = RoyaltyReportBatchesRpmUiPartialService.prototype.GetRoyaltyReportBatchesByPeriodEndDate;
    GetRoyaltyReportBatchInvoices = RoyaltyReportBatchesRpmUiPartialService.prototype.GetRoyaltyReportBatchInvoices;
    GetRoyaltyReportBatchInvoicesAdjustmentsOnly = RoyaltyReportBatchesRpmUiPartialService.prototype.GetRoyaltyReportBatchInvoicesAdjustmentsOnly;
    CreateRoyaltyReportBatchesFromSyncInvoices = RoyaltyReportBatchesRpmUiPartialService.prototype.CreateRoyaltyReportBatchesFromSyncInvoices;
    CreateRoyaltyReportBatchesFromSyncInvoicesDynamic = RoyaltyReportBatchesRpmUiPartialService.prototype.CreateRoyaltyReportBatchesFromSyncInvoicesDynamic;
    ResendRoyaltyReportBatchEmails = RoyaltyReportBatchesRpmUiPartialService.prototype.ResendRoyaltyReportBatchEmails;
    BackoutRoyaltyReportBatches = RoyaltyReportBatchesRpmUiPartialService.prototype.BackoutRoyaltyReportBatches;
    GetAllRoyaltyReportBatchStatuses = RoyaltyReportBatchStatusesRpmUiPartialService.prototype.GetAllRoyaltyReportBatchStatuses;
    GetRoyaltyReportBatchStatusById = RoyaltyReportBatchStatusesRpmUiPartialService.prototype.GetRoyaltyReportBatchStatusById;
    GetRoyaltyReportBatchStatusesForBatchManagement = RoyaltyReportBatchStatusesRpmUiPartialService.prototype.GetRoyaltyReportBatchStatusesForBatchManagement;
    GetRoyaltySyncInvoiceDetailsBySyncInvoice = RoyaltySyncInvoiceDetailsRpmUiPartialService.prototype.GetRoyaltySyncInvoiceDetailsBySyncInvoice;
    GetRoyaltySyncInvoicesByFranchise = RoyaltySyncInvoicesRpmUiPartialService.prototype.GetRoyaltySyncInvoicesByFranchise;
    GetAllRoyaltySystems = RoyaltySystemsRpmUiPartialService.prototype.GetAllRoyaltySystems;
    GetAllRoyaltyRatePlanTypes = RoyaltyRatePlanTypesRpmUiPartialService.prototype.GetAllRoyaltyRatePlanTypes;
    GetAllRoyaltyRatePlanItemTypes = RoyaltyRatePlanItemTypesRpmUiPartialService.prototype.GetAllRoyaltyRatePlanItemTypes;
    GetRoyaltyManualSalesEntryById = RoyaltyManualSalesEntryRpmUiPartialService.prototype.GetRoyaltyManualSalesEntryById;
    GetRoyaltyManualSalesEntryByFranchiseAndPeriodEndDate = RoyaltyManualSalesEntryRpmUiPartialService.prototype.GetRoyaltyManualSalesEntryByFranchiseAndPeriodEndDate;
    GetRoyaltyManualSalesEntriesUnprocessed = RoyaltyManualSalesEntryRpmUiPartialService.prototype.GetRoyaltyManualSalesEntriesUnprocessed;
    SelectRoyaltyManualSalesEntryAvailableSyncInvoices = RoyaltyManualSalesEntryRpmUiPartialService.prototype.SelectRoyaltyManualSalesEntryAvailableSyncInvoices;
    CreateRoyaltyManualSalesEntry = RoyaltyManualSalesEntryRpmUiPartialService.prototype.CreateRoyaltyManualSalesEntry;
    UpdateRoyaltyManualSalesEntry = RoyaltyManualSalesEntryRpmUiPartialService.prototype.UpdateRoyaltyManualSalesEntry;
    GetRoyaltyManualSalesEntryDetailsByRoyaltyManualSalesEntry = RoyaltyManualSalesEntryDetailRpmUiPartialService.prototype.GetRoyaltyManualSalesEntryDetailsByRoyaltyManualSalesEntry;
    CreateRoyaltyManualSalesEntryDetail = RoyaltyManualSalesEntryDetailRpmUiPartialService.prototype.CreateRoyaltyManualSalesEntryDetail;
    UpdateRoyaltyManualSalesEntryDetail = RoyaltyManualSalesEntryDetailRpmUiPartialService.prototype.UpdateRoyaltyManualSalesEntryDetail;
    GetAllRoyaltyManualSalesProcessStatuses = RoyaltyManualSalesProcessStatusesRpmUiPartialService.prototype.GetAllRoyaltyManualSalesProcessStatuses;
    GetRoyaltyServiceCategoriesByFranchisor = RoyaltyServiceCategoriesRpmUiPartialService.prototype.GetRoyaltyServiceCategoriesByFranchisor;
    GetRoyaltyServiceCategoriesByContract = RoyaltyServiceCategoriesRpmUiPartialService.prototype.GetRoyaltyServiceCategoriesByContract;
    GetRoyaltyPeriodReportAdjustmentsByRoyaltyPeriodReport = RoyaltyPeriodReportAdjustmentsRpmUiPartialService.prototype.GetRoyaltyPeriodReportAdjustmentsByRoyaltyPeriodReport;
    GetRoyaltyPeriodReportAdjustmentsUnpostedByFranchisorIds = RoyaltyPeriodReportAdjustmentsRpmUiPartialService.prototype.GetRoyaltyPeriodReportAdjustmentsUnpostedByFranchisorIds;
    CreateRoyaltyPeriodReportAdjustment = RoyaltyPeriodReportAdjustmentsRpmUiPartialService.prototype.CreateRoyaltyPeriodReportAdjustment;
    UpdateRoyaltyPeriodReportAdjustment = RoyaltyPeriodReportAdjustmentsRpmUiPartialService.prototype.UpdateRoyaltyPeriodReportAdjustment;
    GetAllRoyaltyPeriodReportStatuses = RoyaltyPeriodReportStatusesRpmUiPartialService.prototype.GetAllRoyaltyPeriodReportStatuses;
    GetRoyaltyPeriodReportStatusById = RoyaltyPeriodReportStatusesRpmUiPartialService.prototype.GetRoyaltyPeriodReportStatusById;
    GetRoyaltyPeriodReportStatusesForBatchManagement = RoyaltyPeriodReportStatusesRpmUiPartialService.prototype.GetRoyaltyPeriodReportStatusesForBatchManagement;
    SetRoyaltyInvoiceExportBatchPosted = RoyaltyInvoiceExportBatchesRpmUiPartialService.prototype.SetRoyaltyInvoiceExportBatchPosted;
    GetAllRoyaltyServiceCategoryTypes = RoyaltyServiceCategoryTypesRpmUiPartialService.prototype.GetAllRoyaltyServiceCategoryTypes;
    GetAllRoyaltyContractSources = RoyaltyContractSourcesRpmUiPartialService.prototype.GetAllRoyaltyContractSources;
    LoadReportingPeriodInfo = RoyaltyPeriodsRpmUiPartialService.prototype.LoadReportingPeriodInfo;
    AddReportingPeriodInfo = RoyaltyPeriodsRpmUiPartialService.prototype.AddReportingPeriodInfo;
    GetReportingPeriodYearListingByFranchisorId = RoyaltyPeriodsRpmUiPartialService.prototype.GetReportingPeriodYearListingByFranchisorId;
    GetReportingPeriodInfo = RoyaltyPeriodsRpmUiPartialService.prototype.GetReportingPeriodInfo;
    LoadEditReportingPeriodInfo = RoyaltyPeriodsRpmUiPartialService.prototype.LoadEditReportingPeriodInfo;
    EditReportingPeriodInfo = RoyaltyPeriodsRpmUiPartialService.prototype.EditReportingPeriodInfo;
    DeleteReportingPeriod = RoyaltyPeriodsRpmUiPartialService.prototype.DeleteReportingPeriod;
    RoyaltyFranchisorMinimumFeeIncreasesAndCapsByFranchisor = RoyaltyFranchisorMinimumFeeIncreasesAndCapsRpmUiPartialService.prototype.RoyaltyFranchisorMinimumFeeIncreasesAndCapsByFranchisor;
    GetFranchiseAdFeeCapInfo = RoyaltyFranchisorMinimumFeeIncreasesAndCapsRpmUiPartialService.prototype.GetFranchiseAdFeeCapInfo;
    GetFranchiseAdFeeCapInfoForNewYear = RoyaltyFranchisorMinimumFeeIncreasesAndCapsRpmUiPartialService.prototype.GetFranchiseAdFeeCapInfoForNewYear;
    CreateRoyaltyFranchisorMinimumFeeIncreasesAndCaps = RoyaltyFranchisorMinimumFeeIncreasesAndCapsRpmUiPartialService.prototype.CreateRoyaltyFranchisorMinimumFeeIncreasesAndCaps;
    RoyaltyFranchiseAdFeeCapForNewYear = RoyaltyFranchisorMinimumFeeIncreasesAndCapsRpmUiPartialService.prototype.RoyaltyFranchiseAdFeeCapForNewYear;
    GetRoyaltyPeriodReportsSimpleByFranchise = RoyaltyPeriodReportsRpmUiPartialService.prototype.GetRoyaltyPeriodReportsSimpleByFranchise;
}