import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RestAxiosClient } from "Clients/RestAxiosClient";
import { IRestAxiosClient } from "Clients/IRestAxiosClient";

const royaltyDisclosureDocumentRollInMinimumClient = new RestAxiosClient<RpmEntities.RoyaltyDisclosureDocumentRollInMinimum, number>(
    FranForceAxiosClientBuilder.BuildCoreBaseClient(),
    "/RoyaltyDisclosureDocumentRollInMinimums"
) as IRestAxiosClient<RpmEntities.RoyaltyDisclosureDocumentRollInMinimum, number>;

export { royaltyDisclosureDocumentRollInMinimumClient };