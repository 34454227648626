
import { NavigationService } from "@DppApp/Services/NavigationService";

export class NavigationDisasterPrepProfileController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('NavigationDisasterPrepProfileController',
            [
                '$scope', 'Navigation',
                ($scope: ng.IScope, navigation: NavigationService) =>
                {
                }
            ]);
    }
}
