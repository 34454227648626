import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class DisclosureDocumentsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetDisclosureDocumentById(id: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/DisclosureDocuments/" + id
        };

        return this.$http<RpmEntities.DisclosureDocumentViewModel>(config);
    }

    GetDisclosureDocumentByIdDynamic(id: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/DisclosureDocuments/" + id + "/Dynamic"
        };

        return this.$http<RpmEntities.DisclosureDocumentViewModel>(config);
    }

    GetDisclosureDocumentsByFranchisorId(franchisorId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/DisclosureDocuments/InFranchisor/" + franchisorId
        };

        return this.$http<RpmEntities.DisclosureDocumentViewModel[]>(config);
    }

    CreateDisclosureDocument(model: RpmEntities.DisclosureDocumentViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/DisclosureDocuments",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateDisclosureDocument(id: number, model: RpmEntities.DisclosureDocumentViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/DisclosureDocuments/" + id,
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateDisclosureDocumentDynamic(id: number, model: RpmEntities.DisclosureDocumentViewModel) {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/DisclosureDocuments/" + id + "/Dynamic",
            data: model
        };

        return this.$http<number>(config);
    }

    DuplicateDisclosureDocument(id: number)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/DisclosureDocuments/" + id + "/Duplicate"
        };

        return this.$http<number>(config);
    }

    CreateRoyaltyContractByDisclosureDocument(disclosureDocumentId: number, model: RpmEntities.usp_RoyaltyContracts_CreateFromDisclosureDocument_Input) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/DisclosureDocuments/" + disclosureDocumentId + "/CreateRoyaltyContract",
            data: model
        };
    
        return this.$http<void>(config);
    }

    CreateRoyaltyContractByDisclosureDocumentDynamic(disclosureDocumentId: number, model: RpmEntities.usp_RoyaltyContracts_CreateFromDisclosureDocument_Dynamic_Input) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/DisclosureDocuments/" + disclosureDocumentId + "/CreateRoyaltyContract/Dynamic",
            data: model
        };

        return this.$http<void>(config);
    }
}
