import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { CallCenterStateService } from "Services/State/CallCenterState";
import * as _ from "underscore"
import { CallCenterResources } from "Interfaces/FranForce/CallCenter/CallCenterResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class FranchiseLeadThrottleDisplayComponentController implements ng.IController 
{
    //---- Bindings ----
    franchiseId: number;
    //------------------
    
    franchiseLeadThrottle: Partial<CallCenterResources.IC4FranchiseLeadThrottle> & { StartDateObj?: Date, EndDateObj?: Date };
    conceptThrottle: Partial<CallCenterResources.IC4ConceptLeadThrottle>;
    timeUnits: CallCenterResources.IC4ConceptLeadTimeUnit[];

    isLoading: boolean;

    static $inject = [
        'coreApiResources',
        'callCenterApiResources',
        'callCenterState',
        '$q',
        '$log',
        '$timeout',
        '$rootScope'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private callCenterApiResources: CallCenterApiResources,
        private callCenterState: CallCenterStateService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private $rootScope: ng.IRootScopeService
    )
    {
    }

    $onInit()
    {
        this.callCenterApiResources.C4ConceptLeadTimeUnitsApi.query({}).$promise
            .then((timeUnits) =>
            {
                this.timeUnits = timeUnits;
            });
    }

    $postLink()
    {

    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.franchiseId)] && this.franchiseId)
        {
            this.RebindState();
        }
    }



    GetCurrentLeadState()    
    {
    }

    RebindState()
    {
        this.isLoading = true;
        let franchiseStatePromise = this.callCenterState.LoadLeadStateForFranchise(this.franchiseId)
            .then((franchiseThrottle) =>
            {
                this.franchiseLeadThrottle = franchiseThrottle;
            });
        
        let conceptStatePromise = this.coreApiResources.FranchiseApi
            .get({ id: this.franchiseId, $select: nameof<CoreResources.IFranchise>(o => o.ConceptId) }).$promise
            .then((f) =>
            {
                return this.callCenterState.LoadConceptLeadStateByConcept(f.ConceptId);
            })
            .then((conceptThrottle) =>
            {
                this.conceptThrottle = conceptThrottle;
            });

        this.$q.all([
            franchiseStatePromise,
            conceptStatePromise
        ])
            .finally(() =>
            {
                this.isLoading = false;
            });
    }

    GetStatusName(throttle: Partial<CallCenterResources.IC4FranchiseLeadThrottle>)
    {
        if (!throttle)
            return null;

        switch (throttle.Status.toUpperCase())
        {
            case "R":
                return "red";
            case "Y":
                return "yellow";
            case "G":
                return "green";
            default:
                return null;
        }
    }

    GetCssClass(throttle: Partial<CallCenterResources.IC4FranchiseLeadThrottle>)
    {
        let statusName = this.GetStatusName(throttle);
        return "label label-" + statusName;
    }

    GetMinimumLeadTimeDisplay(conceptThrottle: Partial<CallCenterResources.IC4ConceptLeadThrottle>, status: string)
    {
        if (!conceptThrottle || !status)
            return null;

        let time = (status == "G" ? conceptThrottle.MinimumLeadGreenTime : conceptThrottle.MinimumLeadYellowTime);
        let timeUnitId = (status == "G" ? conceptThrottle.MinimumLeadGreenTimeUnitId : conceptThrottle.MinimumLeadYellowTimeUnitId);
        let timeUnit = _.find(this.timeUnits, (unit) => { return unit.C4ConceptLeadTimeUnitId == timeUnitId });

        if (!timeUnit)
            return null;

        return `Lead time of ${time} ${timeUnit?.Name} required.`;
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseLeadThrottleDisplay', {
            bindings: {
                [nameof<FranchiseLeadThrottleDisplayComponentController>(o => o.franchiseId)]: "<",
            },
            controller: FranchiseLeadThrottleDisplayComponentController,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseLeadThrottleDisplay.html"
        });
    }
}


