import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { LegalApiResources } from "Services/Resources/LegalApiResources";
import { LegalEntities } from "Interfaces/FranForce/Legal/LegalResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

interface IViewSectionThreeScope extends ng.IScope {
    model: LegalEntities.SectionThree;
    submitSectionThree: () => void;
    cancelSectionThree: () => void;
    contractId: string;
    franchiseId: string;
    selectionDetails: { franchiseID: string, contractID: string };
    isItt: boolean;
    dispositionTelephoneNumbers: LegalEntities.TelephoneNumberDisposition[];
    approvalSelections: any;
}

export class ViewSectionThreeController implements ng.IController {

    static $inject = [
        "$scope",
        "legalApiResources",
        "$window",
        "$q",
        "$log",
        "$http",
        "coreApiResources"
    ];

    constructor(
        private $scope: IViewSectionThreeScope,
        private legalApiResources: LegalApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $http: ng.IHttpService,
        private coreApiResources: CoreApiResources
    )
    {
        $scope.approvalSelections = [
            { Description: "Approved", Value: true },
            { Description: "Not Approved", Value: false }
        ];
    }

    $onInit() {
        let $scope = this.$scope;
        let $http = this.$http;

        $scope.franchiseId = location.pathname.split("/")[3] || $scope.selectionDetails.franchiseID;
        $scope.contractId = location.pathname.split("/")[4] || $scope.selectionDetails.contractID;

        this.legalApiResources.ContractApi.get({ id: $scope.contractId, $expand: "SectionThree,ContractType" }, (contractResponse: LegalEntities.Contract) => {
            $scope.model = contractResponse.SectionThree;
            $scope.isItt = contractResponse.ContractType.Description == "Intent To Terminate";
            $scope.$emit("setSectionAvaliability", {
                hasSectionTwo: contractResponse.SectionTwoID != null,
                hasSectionThree: contractResponse.SectionThreeID != null,
                franchiseID: $scope.franchiseId,
                contractID: $scope.contractId
            });
        }, errorResponse => {
            console.log(errorResponse);
        }); 



        this.coreApiResources.FranchiseApi.query({ $expand: "Concept", $filter: "FranchiseId eq " + $scope.franchiseId },
            (successResponse: CoreEntities.Franchise[]) => {
                $scope.$emit("setFranchiseDetails", {
                    FranchiseName: successResponse[0].Concept.DisplayName,
                    LicenseNum: successResponse[0].LicenseNumber,
                    FranchiseeCode: $scope.franchiseId,
                    FranchiseAddress: successResponse[0].Address,
                    FranchisePhoneNumber: successResponse[0].StorePhone,
                    FranchiseEmail: successResponse[0].StoreEmail
                });
            },
            errorResponse => {

            });

        this.legalApiResources.TelephoneNumberDispositionApi.query({}, (successResponse: LegalEntities.TelephoneNumberDisposition[]) => {
            $scope.dispositionTelephoneNumbers = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });
    }
}