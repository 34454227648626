import * as elements from 'typed-html';
import { Chance } from 'chance';
import { StringProperties } from "Types/StringProperties";
import { KendoUtil } from 'Utility/KendoUtil';
import { rmsFranchiseEntityRelationshipDetailClient } from 'Clients/Rms';
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import { RmsFranchiseClient } from 'Clients/Rms/RmsFranchiseClient';
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            rmsFranchiseEntityRelationshipDetailsGrid: Partial<StringProperties<FranchiseEntityRelationshipDetailsGrid>>;
        }
    }
}

export type FranchiseEntityRelationshipDetailsGridItem = {
    FranchiseId: number;
    FranchiseEntityRelationshipDetailId: number;
    Selected: boolean;
    IsPrimary: boolean;
    LicenseNumber: string;
    FranchiseeName: string;
}

export type FranchiseEntityRelationshipDetailsGridOnInit = {
    Sync: (franchiseEntityRelationshipId?: number) => Promise<any>,
    Cancel: () => Promise<any>,
    Delete: () => Promise<any>,
};

export type FranchiseEntityRelationshipDetailsData = {
    detailData: any;
};

export type FranchiseEntityRelationshipSelectedPrimary = {
    primarySelectedFranchiseId: number;
};

export class FranchiseEntityRelationshipDetailsGrid implements ng.IController 
{
    onSelectRow: (params: FranchiseEntityRelationshipDetailsData) => void;
    onPrimarySelect: (params: FranchiseEntityRelationshipSelectedPrimary) => void;

    franchiseEntityId: number;
    franchiseEntityRelationshipId: number;
    onInit: (params: FranchiseEntityRelationshipDetailsGridOnInit) => void;

    isLoading: boolean;
    grid: kendo.ui.Grid;
    rmsFranchiseClient: RmsFranchiseClient;

    arraySeelctedRows: any[];

    private controlId: string;

    static $inject = [
        "$timeout",
        "royaltyApiResources",
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private royaltyApiResources: RoyaltyApiResources
    )
    {
        this.rmsFranchiseClient = new RmsFranchiseClient();
        this.controlId = new Chance().string({
            length: 16, 
            symbols: false,
            numeric: true,
            alpha: true,
        });
        this.arraySeelctedRows = [];
    }

    $onInit()
    {
        if (this.onInit)
        {
            this.onInit({
                Sync: (franchiseEntityRelationshipId?: number) =>
                {
                    if (franchiseEntityRelationshipId !== undefined)
                        this.franchiseEntityRelationshipId = franchiseEntityRelationshipId;

                    return this.SyncGrid();
                },
                Cancel: () => { return this.CancelAndRefreshGrid(); },
                Delete: () => { return this.DeleteAll(); }
            })
        }
    }

    $postLink()
    {
        this.InitGrid();
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        //Don't refresh the grid if the newly created relationship's Id is resolved and needs to be saved. 
        //This should only happen in the event the previous value was falsy and the new value is the Id.
        let needsRefresh = (
            changes[nameof(this.franchiseEntityId)] ||
            (
                changes[nameof(this.franchiseEntityRelationshipId)] && (
                    !changes[nameof(this.franchiseEntityRelationshipId)].currentValue ||
                    changes[nameof(this.franchiseEntityRelationshipId)].previousValue
                )
            )
        );

        if (needsRefresh)
        {
            if (this.grid)
            {
                this.setGridData();
                this.CancelAndRefreshGrid();
            }
        }
    }

    async InitGrid()
    {
        let columns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<FranchiseEntityRelationshipDetailsGridItem>(o => o.Selected),
                title: "Selected",
                width: 120,
                hidden: this.franchiseEntityRelationshipId!=0?true:false,
                template: (dataItem: kendo.data.Model & FranchiseEntityRelationshipDetailsGridItem) => {
                    return (
                        <button
                            class="pure-button"
                            ng-click={`$ctrl.${nameof(this.OnSelectedChange)}(${nameof.full(dataItem)})`}>
                            <i ng-class={`${nameof.full(dataItem.Selected)} ? 'fa fa-check-square-o' : 'fa fa-square-o'`} aria-hidden="true"></i>
                        </button>
                    );
                }
            }, {
                field: nameof<FranchiseEntityRelationshipDetailsGridItem>(o => o.IsPrimary),
                title: "Primary",
                width: 120,
                template: (dataItem: kendo.data.Model & FranchiseEntityRelationshipDetailsGridItem) => {
                    return (
                        <button
                            class="pure-button"
                            ng-show={`${nameof.full(dataItem.Selected)}`}
                            ng-click={`$ctrl.${nameof(this.OnPrimaryChange)}(${nameof.full(dataItem)})`}>
                            <i ng-class={`${nameof.full(dataItem.IsPrimary)} ? 'fa fa-check-circle-o' : 'fa fa-circle-o'`} aria-hidden="true"></i>
                        </button>
                    );
                }
            }, {
                field: nameof<FranchiseEntityRelationshipDetailsGridItem>(o => o.LicenseNumber),
                title: "License Number",
                width: 300
            }, {
                field: nameof<FranchiseEntityRelationshipDetailsGridItem>(o => o.FranchiseeName),
                title: "Franchisee Name",
            },
            {
                field: nameof<FranchiseEntityRelationshipDetailsGridItem>(o => o.FranchiseId),
                hidden: true
            },
        ];

        this.grid.setOptions({
            dataSource: new kendo.data.DataSource(),
            autoBind: false,
            columns: columns,
            height: 300,
            scrollable: true,
        } as kendo.ui.GridOptions);

        if (this.franchiseEntityId) {
            await this.setGridData();
        }
        else {
            this.grid.dataSource.data([])
        }
    }

    setGridData() {
        let franchiseEntities = [];
        if (this.franchiseEntityRelationshipId && this.franchiseEntityRelationshipId != 0) {
            this.royaltyApiResources.GetFranchiseEntityRelationshipDetailsByFranchiseEntityRelationshipId(this.franchiseEntityRelationshipId, true)
                .then(
                    (response) => {

                        if (response && response.data && response.data.relationshipDetails && response.data.relationshipDetails.length > 0)
                        {
                            response.data.relationshipDetails.forEach((item) => {
                                let objData = {
                                    FranchiseId: item.franchiseId,
                                    FranchiseEntityRelationshipDetailId: item.franchiseEntityRelationshipDetailId,
                                    Selected: true,
                                    IsPrimary: item.isPrimary,
                                    LicenseNumber: item.licenseNumber,
                                    FranchiseeName: item.franchiseeName
                                }
                                franchiseEntities.push(objData)
                            });
                        }
                        this.grid.dataSource.data(franchiseEntities);
                    });
        }
        else{
            this.royaltyApiResources.GetAllFranchisesByFranchiseEntityId(this.franchiseEntityId)
                .then(
                    (response) => {
                        if (response && response.data && response.data.length > 0) {
                            response.data.forEach((item) => {
                                let objData = {
                                    FranchiseId: item.franchiseId,
                                    FranchiseEntityRelationshipDetailId: 0,
                                    Selected: false,
                                    IsPrimary: false,
                                    LicenseNumber: item.licenseNumber,
                                    FranchiseeName: item.franchiseeName
                                }
                                franchiseEntities.push(objData)
                            });
                        }
                        this.grid.dataSource.data(franchiseEntities);
                    });
        }
    }

    OnSelectedChange(dataItem: kendo.data.Model & FranchiseEntityRelationshipDetailsGridItem) {
        if (dataItem) {
            let selectedRow = {
                FranchiseId: dataItem.FranchiseId,
                FranchiseEntityRelationshipDetailId: dataItem.FranchiseEntityRelationshipDetailId,
                IsPrimary: dataItem.IsPrimary,
                Selected: !dataItem.Selected,
                LicenseNumber: dataItem.LicenseNumber,
                FranchiseeName: dataItem.FranchiseeName
            }
            this.onSelectRow({ detailData: selectedRow });
        }

        this.$timeout(() =>
        {
            let inverseValue = !dataItem.get(nameof(dataItem.Selected));
            dataItem.set(nameof(dataItem.Selected), inverseValue);

            if (!dataItem.Selected && dataItem.IsPrimary)
            {
                dataItem.set(nameof(dataItem.IsPrimary), false);
            }
        });
    }

    OnPrimaryChange(dataItem: kendo.data.Model & FranchiseEntityRelationshipDetailsGridItem)
    {
        this.$timeout(() =>
        {
            dataItem.dirty = true;

            this.grid.dataSource.data()
                .forEach((gridItem: kendo.data.Model & FranchiseEntityRelationshipDetailsGridItem) =>
                {
                    gridItem.set(nameof(gridItem.IsPrimary), false);
                });

            dataItem.set(nameof(dataItem.IsPrimary), true);
            this.onPrimarySelect({ primarySelectedFranchiseId: dataItem.FranchiseId });
        });
    }

    SyncGrid()
    {
        return Promise.resolve(this.grid.dataSource.sync());
    }

    async CancelAndRefreshGrid()
    {
        return this.$timeout(async () => {
            this.InitGrid();
        });
    }

    async DeleteAll()
    {
        let details = await rmsFranchiseEntityRelationshipDetailClient.Query({
            $filter: `${nameof<RpmEntities.FranchiseEntityRelationshipDetail>(o => o.FranchiseEntityRelationshipId)} eq ${this.franchiseEntityRelationshipId}`
        });
        let ids = details.map(d => d.FranchiseEntityRelationshipDetailId);
        await rmsFranchiseEntityRelationshipDetailClient.DeleteMultiple(ids, { allowHardDelete: false, forceHardDelete: false });
        return ids;
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.rmsFranchiseEntityRelationshipDetailsGrid);

        let loadingTemplate = (
            <loadingGearsOverlay isLoading={`$ctrl.${nameof<FranchiseEntityRelationshipDetailsGrid>(o => o.isLoading)}`} ></loadingGearsOverlay>
        );

        let gridTemplate = (
            <div style="margin-top:20px;" kendo-grid={`$ctrl.${nameof<FranchiseEntityRelationshipDetailsGrid>(o => o.grid)}`}
                ng-show={`$ctrl.${nameof<FranchiseEntityRelationshipDetailsGrid>(o => o.franchiseEntityId)}`}
                >

            </div>
        );

        let template = `${loadingTemplate} ${gridTemplate}`

        app.component(componentName, {
            bindings: {
                [nameof<FranchiseEntityRelationshipDetailsGrid>(o => o.franchiseEntityId)]: "<",
                [nameof<FranchiseEntityRelationshipDetailsGrid>(o => o.franchiseEntityRelationshipId)]: "<",
                [nameof<FranchiseEntityRelationshipDetailsGrid>(o => o.onInit)]: "&?",
                [nameof<FranchiseEntityRelationshipDetailsGrid>(o => o.onSelectRow)]: "&?",
                [nameof<FranchiseEntityRelationshipDetailsGrid>(o => o.onPrimarySelect)]: "&?",
            },
            controller: FranchiseEntityRelationshipDetailsGrid,
            template: template
        });
    }
}

