import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmResources } from "Interfaces/FranForce/Rpm/RpmResources";

interface IBMBatchIdStateParams extends ng.ui.IStateParamsService
{
    batchId?: string;
}


export class bmBatchIdController implements ng.IController
{
    batchFilters: kendo.data.DataSourceFilter;
    periodFilters: kendo.data.DataSourceFilter;

    searchBatchIds: string;
    selectedBatchIds: number[];
    selectedBatches: RpmResources.IRoyaltyReportBatch[];
    
    isLoading: boolean;
    readOnly: boolean;
    errorMessage: string;

    static $inject = [
        '$scope',
        '$stateParams',
        'coreApiResources',
        'rpmApiResources',
        'odataHelper',
        'identityManager',
        '$state',
        "$q",
        "$timeout",
    ]

    constructor(
        private $scope: ng.IScope,
        private $stateParams: IBMBatchIdStateParams,
        private coreApiResources: CoreApiResources,
        private rpmApiResources: RpmApiResources,
        private odataHelper: ODataHelperService,
        private identityManager: IdentityManager,
        private $state: ng.ui.IStateService,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService,
    ) {
    }

    private validateAndParseBatchIds(batchIdString: string): number[] {
        if (!batchIdString || batchIdString.trim() === '') {
            this.errorMessage = 'Please enter at least one batch ID';
            return [];
        }

        // Handle both formats - query string and comma-separated
        let batchIds: string[];
        if (batchIdString.includes('batchId=')) {
            // Query string format
            batchIds = batchIdString.split('&')
                .map(param => {
                    const match = param.match(/^batchId=(\d+)$/);
                    return match ? match[1].trim() : '';
                });
        } else {
            // Comma-separated format
            batchIds = batchIdString.split(',')
                .map(id => id.trim());
        }

        batchIds = batchIds.filter(id => id !== '');

        if (batchIds.length === 0) {
            this.errorMessage = 'Please enter valid batch IDs';
            return [];
        }

        // Validate each ID
        const parsedIds: number[] = [];
        const invalidIds: string[] = [];

        batchIds.forEach(id => {
            const numId = parseInt(id);
            if (!isNaN(numId) && numId > 0) {
                parsedIds.push(numId);
            } else {
                invalidIds.push(id);
            }
        });

        if (invalidIds.length > 0) {
            this.errorMessage = `Invalid batch IDs: ${invalidIds.join(', ')}. Please enter valid positive numbers.`;
            return parsedIds;
        }

        this.errorMessage = '';
        return parsedIds;
    }

    FindBatch(batchIds: string) {
        const validBatchIds = this.validateAndParseBatchIds(batchIds);
        if (validBatchIds.length > 0) {
            // Keep the UI showing comma-separated format
            this.searchBatchIds = validBatchIds.join(',');
            // Store query string format in state params
            this.$stateParams.batchId = validBatchIds.map(id => `batchId=${id}`).join('&');
            this.selectedBatchIds = validBatchIds;
            this.$state.go("bm.batchId", this.$stateParams, { notify: false }).then(() => {
                this.SetFilters();
            });
        }
    }

    OnSelectedBatch(batches: RpmResources.IRoyaltyReportBatch[])
    {
        this.$timeout(() =>
        {
            this.selectedBatches = batches;
            this.SetPeriodFilters();
        });
    }

    SetFilters()
    {
        this.SetBatchFilters();
        this.SetPeriodFilters();
    }

    SetBatchFilters()
    {
        if (!this.selectedBatchIds) {
            this.batchFilters = null;
            return;
        }

        let filtersArray = <kendo.data.DataSourceFilterItem[]>[]
        this.batchFilters = <kendo.data.DataSourceFilters>{
            filters: filtersArray,
            logic: "or"
        };

        for (let batchId of this.selectedBatchIds)
        {
            filtersArray.push({
                field: nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatchId),
                operator: "eq",
                value: batchId,
            });
        }
    }

    SetPeriodFilters()
    {
        if (!this.selectedBatches || !this.selectedBatches.length) {
            this.periodFilters = null;
            return;
        }

        let filtersArray = <kendo.data.DataSourceFilterItem[]>[]
        this.periodFilters = <kendo.data.DataSourceFilters>{
            filters: filtersArray,
            logic: "or"
        };

        for (let batchId of this.selectedBatches.map(b => b.RoyaltyReportBatchId))
        {
            filtersArray.push({
                field: nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatchId),
                operator: "eq",
                value: batchId,
            });
        }
    }

    $onInit() 
    {
        this.isLoading = true;
        this.selectedBatches = [];
        this.errorMessage = '';

        let adminPromise = this.identityManager.HasRole(["BatchManagementAdmin", "Admin"])
            .then(isAdmin =>
            {
                this.readOnly = !isAdmin;
            })
            .finally(() => { this.isLoading = false; });
        
        if (this.$stateParams.batchId) {
            // Convert query string format to comma-separated for UI
            if (this.$stateParams.batchId.includes('batchId=')) {
                const ids = this.$stateParams.batchId.split('&')
                    .map(param => {
                        const match = param.match(/^batchId=(\d+)$/);
                        return match ? match[1].trim() : '';
                    })
                    .filter(id => id !== '');
                this.searchBatchIds = ids.join(',');
            } else {
                this.searchBatchIds = this.$stateParams.batchId;
            }
            
            this.selectedBatchIds = this.validateAndParseBatchIds(this.$stateParams.batchId);
            if (this.selectedBatchIds.length > 0) {
                this.SetFilters();
            }
        }
    }
}
