import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyPeriodReportsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyPeriodReport(id: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriodReports/" + id
        };

        return this.$http<RpmEntities.RoyaltyPeriodReportViewModel>(config);
    }

    GetRoyaltyPeriodReportDynamic(id: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriodReports/" + id + "/Dynamic"
        };

        return this.$http<RpmEntities.RoyaltyPeriodReportViewModel>(config);
    }

    GetRoyaltyPeriodReportsByFranchise(franchiseId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriodReports/ByFranchise/" + franchiseId
        };

        return this.$http<RpmEntities.RoyaltyPeriodReportViewModel[]>(config);
    }

    GetRoyaltyPeriodReportsSimpleByFranchise(franchiseId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriodReports/ByFranchise/" + franchiseId + "/Simple"
        };

        return this.$http<RpmEntities.usp_RoyaltyPeriodReports_GetByFranchiseId_Simple_Result[]>(config);
    }

    GetRoyaltyPeriodReportsByFranchises(franchiseIds: number[])
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriodReports/ByFranchises",
            params: {
                franchiseIds: franchiseIds
            }
        };

        return this.$http<RpmEntities.RoyaltyPeriodReportViewModel[]>(config);
    }

    GetRoyaltyPeriodReportEmail(id: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriodReports/" + id + "/Email"
        };

        return this.$http<RpmEntities.EmailServiceViewModel>(config);
    }

    BackoutRoyaltyPeriodReports(model: RpmEntities.usp_RoyaltyStatusUpdatePeriodReport_ByPeriodReportID_Input)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyPeriodReports/" + model.RoyaltyPeriodReportId + "/Backout",
            data: model
        };

        return this.$http<number>(config);
    }
}