import * as elements from "typed-html";

import { KendoUtil, BindDropDownEditor } from "Utility/KendoUtil";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

import * as rmsClients from "Clients/Rms";
import { StringProperties } from "Types/StringProperties";
import { MonthEnabled } from "Directives/Common";
import { fddRoyaltiesRollInMinimumsMonthsAppliedOnSelect, fddRoyaltiesRollInMinimumsMonthsAppliedOnInit, fddRoyaltiesRatePlanMinimumsMonthsAppliedOnSelect, fddRoyaltiesRollInMinimumsMonthsApplied } from "Directives/RPM/FddManagement";
import { ExpressionBinding, AngularUtil } from "Utility/AngularUtil";
import { NameOfFullToControllerAs } from "Utility/Helpers";
import { IFddController } from "Directives/RPM/FddManagement/IFddController";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            fddRoyaltiesRollInMinimums: Partial<StringProperties<fddRoyaltiesRollInMinimums>>;
        }
    }
}

export interface RoyaltyDisclosureDocumentRollInMinimumGridItem extends RpmEntities.RoyaltyDisclosureDocumentRollInMinimum
{
    MonthSettings: fddRoyaltiesRollInMinimumsMonthsAppliedOnSelect["all"];
}

export type fddRoyaltiesRollInMinimumsOnInit = { self: fddRoyaltiesRollInMinimums };

interface DataSourcePromise<T>
{
    dataSource: kendo.data.DataSource,
    dataPromise: Promise<T[]>;
}

export class fddRoyaltiesRollInMinimums implements ng.IController, IFddController 
{
    readOnly: boolean;
    onInit: ExpressionBinding<fddRoyaltiesRollInMinimumsOnInit>;

    rollInMinimumGrid: kendo.ui.Grid;

    isLoading: boolean;
    private disclosureDocumentId: number;
    private disclosureDocumentRatePlanId: number;
    private formController: ng.IFormController;
    private feeTypes: DataSourcePromise<RpmEntities.usp_RoyaltyFeeTypeResult>;
    private royaltyPeriodFrequencies: DataSourcePromise<RpmEntities.RoyaltyPeriodFrequencyViewModel>;
    private royaltyFindScaleBasedOnTypes: DataSourcePromise<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>;
    private rollInRanges: DataSourcePromise<RpmEntities.RoyaltyDisclosureDocumentRollInRange>;

    private minimumsClient = rmsClients.royaltyDisclosureDocumentRollInMinimumClient;
    private rollInRangesClient = rmsClients.royaltyDisclosureDocumentRollInRangeClient;

    static $inject = [
        'rpmUiApiResources',
        'rpmHelper',
        "$timeout",
        'coreApiResources',
        "$location"
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private $timeout: ng.ITimeoutService,
        private coreApiResources: CoreApiResources,
        private $location: ng.ILocationService,
    )
    {
    }

    async $onInit() 
    {
    }

    async $postLink()
    {
        this.InitLookupData()
        this.InitRatePlanMinimumItemsGrid();
        if (this.onInit)
            this.onInit({ self: this });
    }

    async LoadGrid(disclosureDocumentId: number, disclosureDocumentRatePlanId: number)
    {
        await AngularUtil.TrackLoadingPromise((async () =>
        {
            this.disclosureDocumentId = disclosureDocumentId;
            this.disclosureDocumentRatePlanId = disclosureDocumentRatePlanId;
            await this.LoadLookupData();
            await Promise.resolve(this.rollInMinimumGrid.dataSource.read());
        })(), this);
    }

    async LoadLookupData()
    {
        let disclosureDocumentId = this.$location.search().fddId;
        this.feeTypes.dataPromise = Promise.resolve(this.rpmUiApiResources.GetAllRoyaltyFeeTypes().then(r => r.data));
        this.royaltyPeriodFrequencies.dataPromise = Promise.resolve(this.rpmUiApiResources.GetAllRoyaltyPeriodFrequencies().then(r => r.data));
        this.royaltyFindScaleBasedOnTypes.dataPromise = Promise.resolve(this.rpmUiApiResources.GetAllRoyaltyFindScaleBasedOnTypes().then(r => r.data));
        this.rollInRanges.dataPromise = this.rollInRangesClient.Query({ $filter: `${nameof<RpmEntities.RoyaltyDisclosureDocumentRollInRange>(o => o.DisclosureDocumentId)} eq ${disclosureDocumentId}` });

        await Promise.all([
            this.feeTypes.dataSource.read(),
            this.royaltyPeriodFrequencies.dataSource.read(),
            this.royaltyFindScaleBasedOnTypes.dataSource.read(),
            this.rollInRanges.dataSource.read()
        ]);
        this.$timeout();
    }

    private InitLookupData()
    {
        this.feeTypes = {
            dataPromise: Promise.resolve(null),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                    {
                        return await this.feeTypes.dataPromise;
                    })
                }
            })
        };

        this.royaltyPeriodFrequencies = {
            dataPromise: Promise.resolve(null),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                    {
                        return await this.royaltyPeriodFrequencies.dataPromise;
                    })
                }
            })
        };

        this.royaltyFindScaleBasedOnTypes = {
            dataPromise: Promise.resolve(null),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                    {
                        return await this.royaltyFindScaleBasedOnTypes.dataPromise;
                    })
                }
            })
        };

        this.rollInRanges = {
            dataPromise: Promise.resolve(null),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                    {
                        return await this.rollInRanges.dataPromise;
                    })
                }
            })
        };
    }

    private InitRatePlanMinimumItemsGrid()
    {
        let schema: kendo.data.DataSourceSchema = {
            model: {
                id: nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.RoyaltyDisclosureDocumentRollInMinimumId),
                fields: {
                    [nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.RoyaltyDisclosureDocumentRollInMinimumId)]: {
                        type: "number",
                        validation: { required: true },
                        editable: false,
                        defaultValue: 0
                    },
                    [nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.DisclosureDocumentRatePlanId)]: {
                        type: "number",
                        validation: { required: true },
                    },
                    [nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.RoyaltyDisclosureDocumentRollInRangeId)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.RoyaltyFeeTypeId)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.RoyaltyPeriodFrequencyId)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.FindScaleBasedOnId)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.WeeksInBusinessStart)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.WeeksInBusinessEnd)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.FeeFixedRateAmount)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.FeeRate)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.GrossSalesMinimumAmount)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.MonthSettings)]: {
                        type: "object",
                        defaultValue: []
                    }
                } as kendo.data.DataSourceSchemaModelFields
            }
        }

        let minItemDataSourceOptions: kendo.data.DataSourceOptions = {
            //filter: { field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum>(o => o.DeletedDateTime), operator: "isnull" },
            transport: {
                read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) => {

                    if (!this.disclosureDocumentRatePlanId) {
                        return [];
                    }

                    return await this.minimumsClient.Query({
                        $filter: `${nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum>(o => o.DisclosureDocumentRatePlanId)} eq ${this.disclosureDocumentRatePlanId}`
                    });
                }),
                create: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let newItem: RoyaltyDisclosureDocumentRollInMinimumGridItem & kendo.data.Model = options.data;
                    newItem.DisclosureDocumentRatePlanId = this.disclosureDocumentRatePlanId;
                    //let minimumResult = await this.minimumsClient.Post(newItem);
                    let minimumResult = await this.coreApiResources.CreateRoyaltyDisclosureDocumentRollInMinimums(newItem).then((response) => {
                        return response.data
                    });

                    if (newItem.MonthSettings?.length)
                    {
                        await fddRoyaltiesRollInMinimumsMonthsApplied.SaveMonthsEnabled(newItem.MonthSettings, minimumResult.RoyaltyDisclosureDocumentRollInMinimumId, this.coreApiResources);
                    }

                    return minimumResult;
                }),
                update: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let updateItem: RoyaltyDisclosureDocumentRollInMinimumGridItem & kendo.data.Model = options.data;

                    if (updateItem.MonthSettings?.length)
                    {
                        await fddRoyaltiesRollInMinimumsMonthsApplied.SaveMonthsEnabled(updateItem.MonthSettings, updateItem.RoyaltyDisclosureDocumentRollInMinimumId, this.coreApiResources);
                    }

                    //return await this.minimumsClient.Put(updateItem.RoyaltyDisclosureDocumentRollInMinimumId, updateItem);
                    return await this.coreApiResources.UpdateRoyaltyDisclosureDocumentRollInMinimums(updateItem.RoyaltyDisclosureDocumentRollInMinimumId, updateItem).then((response) => {
                        return response;
                    });
                }),
                destroy: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let deleteItem: RoyaltyDisclosureDocumentRollInMinimumGridItem = options.data;
                    return await this.minimumsClient.Delete(deleteItem.RoyaltyDisclosureDocumentRollInMinimumId, {allowHardDelete: false, forceHardDelete: false});
                })
            },
            pageSize: 10,
            schema: schema,
            sort: {
                field: nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.WeeksInBusinessStart),
                dir: "asc"
            },
            change: (e) =>
            {
                this.$timeout();
            },
        };

        let minItemColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.RoyaltyDisclosureDocumentRollInMinimumId),
                title: "Id",
                width: 160,
                hidden: true
            }, {
                field: nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.RoyaltyDisclosureDocumentRollInRangeId),
                title: "Roll In Range",
                editor: (container, options) =>
                {
                    let dropDownListOptions: kendo.ui.DropDownListOptions = {
                        dataTextField: nameof<RpmEntities.RoyaltyDisclosureDocumentRollInRange>(o => o.RollInRangeName),
                        dataValueField: nameof<RpmEntities.RoyaltyDisclosureDocumentRollInRange>(o => o.RoyaltyDisclosureDocumentRollInRangeId),
                        dataSource: this.rollInRanges.dataSource,
                        change: (e) =>
                        {
                            let dataItem: RpmEntities.RoyaltyDisclosureDocumentRollInRange = e.sender.dataItem();
                            options.model.set(nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.RoyaltyDisclosureDocumentRollInRangeId), dataItem?.RoyaltyDisclosureDocumentRollInRangeId);
                        },
                        open: (e) => { e.sender.dataSource.read(); }
                    }
                    BindDropDownEditor(container, options, dropDownListOptions);
                },
                template: (dataItem: RoyaltyDisclosureDocumentRollInMinimumGridItem) =>
                {
                    return this.rollInRanges.dataSource.data()
                        .find((range: RpmEntities.RoyaltyDisclosureDocumentRollInRange) => { return range.RoyaltyDisclosureDocumentRollInRangeId === dataItem.RoyaltyDisclosureDocumentRollInRangeId; })
                        ?.RollInRangeName ?? "--";
                }
            }, {
                field: nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.RoyaltyFeeTypeId),
                title: "Fee Type",
                editor: (container, options) =>
                {
                    let dropDownListOptions: kendo.ui.DropDownListOptions = {
                        dataTextField: nameof<RpmEntities.usp_RoyaltyFeeTypeResult>(o => o.Description),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyFeeTypeResult>(o => o.RoyaltyFeeTypeId),
                        dataSource: this.feeTypes.dataSource,
                        change: (e) =>
                        {
                            let dataItem: RpmEntities.usp_RoyaltyFeeTypeResult = e.sender.dataItem();
                            options.model.set(nameof<RpmEntities.usp_RoyaltyFeeTypeResult>(o => o.RoyaltyFeeTypeId), dataItem?.RoyaltyFeeTypeId);
                        },
                        open: (e) => { e.sender.dataSource.read(); }
                    }
                    BindDropDownEditor(container, options, dropDownListOptions);
                },
                template: (dataItem: RoyaltyDisclosureDocumentRollInMinimumGridItem) =>
                {
                    return this.feeTypes.dataSource.data()
                        .find((ft: RpmEntities.usp_RoyaltyFeeTypeResult) => { return ft.RoyaltyFeeTypeId === dataItem.RoyaltyFeeTypeId; })
                        ?.Description ?? "";
                }
            }, {
                field: nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.RoyaltyPeriodFrequencyId),
                title: "Minimum Fee Frequency",
                editor: (container, options) => 
                {
                    let dropDownOptions: kendo.ui.DropDownListOptions = {
                        dataTextField: nameof<RpmEntities.RoyaltyPeriodFrequencyViewModel>(o => o.Description),
                        dataValueField: nameof<RpmEntities.RoyaltyPeriodFrequencyViewModel>(o => o.RoyaltyPeriodFrequencyId),
                        dataSource: this.royaltyPeriodFrequencies.dataSource,
                        select: (e) => 
                        {
                            let dataItem: RpmEntities.RoyaltyPeriodFrequencyViewModel = e.dataItem;

                            options.model.set(nameof(dataItem.RoyaltyPeriodFrequencyId), dataItem.RoyaltyPeriodFrequencyId);
                        },
                        open: (e) => { e.sender.dataSource.read(); }
                    };
                    BindDropDownEditor(container, options, dropDownOptions);
                },
                template: (dataItem: RoyaltyDisclosureDocumentRollInMinimumGridItem) =>
                {
                    return this.royaltyPeriodFrequencies.dataSource.data()
                        .find((f: RpmEntities.RoyaltyPeriodFrequencyViewModel) => { return f.RoyaltyPeriodFrequencyId === dataItem.RoyaltyPeriodFrequencyId; })
                        ?.Description ?? "--";
                }
            }, {
                field: nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.FindScaleBasedOnId),
                title: "Minimum Scale Based On",
                editor: (container, options) => 
                {
                    let dropDownOptions: kendo.ui.DropDownListOptions = {
                        dataTextField: nameof<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>(o => o.Description),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>(o => o.FindScaleBasedOnId),
                        dataSource: this.royaltyFindScaleBasedOnTypes.dataSource,
                        select: (e) => 
                        {
                            let dataItem: RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result = e.dataItem;

                            options.model.set(nameof(dataItem.FindScaleBasedOnId), dataItem.FindScaleBasedOnId);
                        },
                        open: (e) => { e.sender.dataSource.read(); }
                    };
                    let ddl = BindDropDownEditor(container, options, dropDownOptions);
                    ddl.jqueryElement.prop("required", "required");
                },
                template: (dataItem: RoyaltyDisclosureDocumentRollInMinimumGridItem) =>
                {
                    return this.royaltyFindScaleBasedOnTypes.dataSource.data()
                        .find((fs: RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result) => { return fs.FindScaleBasedOnId === dataItem.FindScaleBasedOnId; })
                        ?.Description ?? "--";
                }
            }, {
                field: nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.WeeksInBusinessStart),
                title: "WIB Start"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.WeeksInBusinessEnd),
                title: "WIB End"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.FeeFixedRateAmount),
                title: "Min Royalty Fee",
                format: "{0:c}"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.FeeRate),
                title: "Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.GrossSalesMinimumAmount),
                title: "GrossSalesMinAmount",
                format: "{0:c}"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRollInMinimumGridItem>(o => o.MonthSettings),
                title: "MonthSettings",
                hidden: true
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }];

        let toolbar: kendo.ui.GridToolbarItem[] = [
            {
                template: (
                    <button type="button"
                        ng-click={`${NameOfFullToControllerAs(nameof.full(this.rollInMinimumGrid.addRow))}()`}>
                        Add Row
                    </button>
                )
            }
        ];

        let options: kendo.ui.GridOptions = {
            autoBind: false,
            toolbar: toolbar,
            dataSource: new kendo.data.DataSource(minItemDataSourceOptions),
            columns: minItemColumns,
            selectable: "row",
            editable: !this.readOnly ? { confirmation: false } : false,
            navigatable: true,
            scrollable: false,
            sortable: true,
            pageable: KendoUtil.GetDefaultKendoGridPageable(10),
            edit: (e) => {
                if (e.model.isNew()) {
                    e.sender.expandRow(
                        e.sender.tbody.find("tr[data-uid='" + e.model.uid + "']")
                    );
                }
            },
        };

        this.rollInMinimumGrid.setOptions(options);
    }

    SetHasMonthlyMinimumChanges(dataItem: kendo.data.Model & RoyaltyDisclosureDocumentRollInMinimumGridItem, monthSettings: fddRoyaltiesRatePlanMinimumsMonthsAppliedOnSelect["all"])
    {
        dataItem.MonthSettings = monthSettings;
        dataItem.dirty = true;
    }

    SetMinimumMonthsAppliedController(dataItem: kendo.data.Model & RoyaltyDisclosureDocumentRollInMinimumGridItem, controller: fddRoyaltiesRollInMinimumsMonthsAppliedOnInit["self"])
    {
        this.$timeout(async () =>
        {
            if (dataItem?.MonthSettings?.length)
            {
                controller.SetMonthsData(dataItem.RoyaltyDisclosureDocumentRollInMinimumId, dataItem.MonthSettings, true)
            }
            else
            {
                await controller.LoadMonthsData(dataItem.RoyaltyDisclosureDocumentRollInMinimumId);
            }
        });
    }

    CancelChanges()
    {
        this.rollInMinimumGrid.cancelChanges();
    }
    
    HasChanges()
    {
        //Don't need to check monthly minimums because they should already show up as changes via HasMonthlyMinimumChanges
        return this.rollInMinimumGrid.dataSource.hasChanges();
    }
    
    async ResetChanges()
    {
        this.CancelChanges();
        return await this.LoadGrid(this.disclosureDocumentId, this.disclosureDocumentRatePlanId);
    }

    async SaveChanges()
    {
        await Promise.resolve(this.rollInMinimumGrid.dataSource.sync());
        await this.ResetChanges();
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.fddRoyaltiesRollInMinimums);
        let template = (
            <div ng-form={`$ctrl.${nameof<fddRoyaltiesRollInMinimums>(o => o.formController)}`}>
                <div kendo-grid={`$ctrl.${nameof<fddRoyaltiesRollInMinimums>(o => o.rollInMinimumGrid)}`}>
                    <div k-detail-template>
                        <fddRoyaltiesRollInMinimumsMonthsApplied
                            onInit={`$ctrl.${nameof<fddRoyaltiesRollInMinimums>(o => o.SetMinimumMonthsAppliedController)}(dataItem, ${nameof<fddRoyaltiesRollInMinimumsMonthsAppliedOnInit>(o => o.self)})`}
                            onSelect={`$ctrl.${nameof<fddRoyaltiesRollInMinimums>(o => o.SetHasMonthlyMinimumChanges)}(dataItem, ${nameof<fddRoyaltiesRollInMinimumsMonthsAppliedOnSelect>(o => o.all)})`}
                        >
                        </fddRoyaltiesRollInMinimumsMonthsApplied>
                    </div>
                </div>
            </div>
        );

        app.component(componentName, {
            bindings: {
                [nameof<fddRoyaltiesRollInMinimums>(o => o.readOnly)]: "<",
                [nameof<fddRoyaltiesRollInMinimums>(o => o.onInit)]: "&?"
            },
            template: template,
            controller: fddRoyaltiesRollInMinimums
        });
    }
}

