import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { ZeeMailResources, ZeeMailEntities } from "Interfaces/FranForce/ZeeMail/ZeeMailResources";
import { ZeeMailResourceApi } from "Interfaces/FranForce/ZeeMail/ZeeMailResourceApi";

export class ZeeMailApiResources 
{
    ZorWareZeeMailAccountApi: ZeeMailResourceApi.IZorWareZeeMailAccountApi;
    ZorWareZeeMailActionTypeApi: ZeeMailResourceApi.IZorWareZeeMailActionTypeApi;
    ZorWareZeeMailCommentApi: ZeeMailResourceApi.IZorWareZeeMailCommentApi;
    ZorWareZeeMailDomainApi: ZeeMailResourceApi.IZorWareZeeMailDomainApi;
    ZorWareZeeMailStatusTypeApi: ZeeMailResourceApi.IZorWareZeeMailStatusTypeApi;
    ZorWareZeeMailTemplateApi: ZeeMailResourceApi.IZorWareZeeMailTemplateApi;
    ZorWareZeeMailTypeApi: ZeeMailResourceApi.IZorWareZeeMailTypeApi;

    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {
        this.RefreshResources()
    }

    RefreshResources()
    {
        this.ZorWareZeeMailAccountApi = this.$resource<ZeeMailResources.IZorWareZeeMailAccount>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/ZorWareZeeMailAccounts/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.ZorWareZeeMailActionTypeApi = this.$resource<ZeeMailResources.IZorWareZeeMailActionType>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/ZorWareZeeMailActionTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.ZorWareZeeMailCommentApi = this.$resource<ZeeMailResources.IZorWareZeeMailComment>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/ZorWareZeeMailComments/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.ZorWareZeeMailDomainApi = this.$resource<ZeeMailResources.IZorWareZeeMailDomain>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/ZorWareZeeMailDomains/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.ZorWareZeeMailStatusTypeApi = this.$resource<ZeeMailResources.IZorWareZeeMailStatusType>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/ZorWareZeeMailStatusTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.ZorWareZeeMailTemplateApi = this.$resource<ZeeMailResources.IZorWareZeeMailTemplate>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/ZorWareZeeMailTemplates/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.ZorWareZeeMailTypeApi = this.$resource<ZeeMailResources.IZorWareZeeMailType>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/ZorWareZeeMailTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
    }
    
    ZeeMailAccountStatusChange(zeeMailAccountId: number, comment: ZeeMailEntities.ZorWareZeeMailComment)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/ZorWareZeeMailAccounts/${zeeMailAccountId}/ChangeStatus`,
            data: comment
        };

        return this.$http<any>(config);
    }

    ZeeMailAccountRequestChange(zeeMailAccountId: number, model: IZorWareZeeMailRequestChange)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/ZorWareZeeMailAccounts/${zeeMailAccountId}/RequestChange`,
            data: model
        };

        return this.$http<any>(config);
    }

    ZeeMailAccountCreate(model: IZorWareZeeMailCreateAccount)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/ZorWareZeeMailAccounts/Create`,
            data: model
        };

        return this.$http<any>(config);
    }

    ZeeMailAccountChange(zeeMailAccountId: number, model: IZorWareZeeMailChangeEmail)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/ZorWareZeeMailAccounts/${zeeMailAccountId}/ChangeAccount`,
            data: model
        };

        return this.$http<any>(config);
    }

    ZeeMailFranchiseEmailDbaChange(franchiseId: number, emailDba: string)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/ZorWareZeeMail/Franchises/${franchiseId}/ZeeMailDba`,
            params: {
                emailDba: emailDba
            }
        };

        return this.$http<any>(config);
    }

    ZeeMailFranchiseQuery(parameters: ODataQueryParameters)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/ZorWareZeeMail/Franchises`,
            params: parameters
        };

        return this.$http<ZeeMailEntities.ZeeMailFranchiseExtension[]>(config);
    }

    GetFranchisorIsZeeMailEnabled(franchisorId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/ZorWareZeeMail/Franchisors/${franchisorId}/IsEnabled`,
        };

        return this.$http<boolean>(config);
    }

    SetFranchisorIsZeeMailEnabled(franchisorId: number, isEnabled: boolean)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/ZorWareZeeMail/Franchisors/${franchisorId}/IsEnabled/${isEnabled}`,
        };

        return this.$http<void>(config);
    }
}