import { PushLeadOptionGridObject, PushLeadOptionController } from "Directives/Common/PushLeadOptionController";
import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { CoreEntities, CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class FranchisePushLeadOptionController extends PushLeadOptionController<CoreResources.IFranchiseLeadPushOption> {
    // Bindings
    franchiseId: number;

    // Other Properties
    conceptId: number;
    canFranchiseOverride: boolean;
    disableOptions: boolean;

    static $inject = [
        'callCenterApiResources',
        'coreApiResources',
        'odataHelper',
        '$q',
        '$log',
        '$timeout',
        '$window'
    ];

    constructor(
        protected callCenterApiResources: CallCenterApiResources,
        protected coreApiResources: CoreApiResources,
        protected odataHelper: ODataHelperService,
        protected $q: ng.IQService,
        protected $log: ng.ILogService,
        protected $timeout: ng.ITimeoutService,
        protected $window: ng.IWindowService) {

        super(callCenterApiResources,
            coreApiResources,
            $q,
            $log,
            $timeout,
            $window);
    }

    $onInit() {
        this.coreApiResources.FranchiseApi.get({
            id: this.franchiseId,
            $select: "FranchiseId,ConceptId"
        }).$promise
            .then((franchise) => {
                this.conceptId = franchise.ConceptId;
                this.coreApiResources.ConceptApi.get({
                    id: franchise.ConceptId,
                    $select: "ConceptId,CanFranchiseOverrideLeadPush"
                }).$promise
                    .then((concept) => {
                        this.disableOptions = !concept.CanFranchiseOverrideLeadPush;
                        this.canFranchiseOverride = concept.CanFranchiseOverrideLeadPush;
                    });
            })
            .then(() => super.$onInit());
    }

    LoadLookupData(): ng.IPromise<any> {
        if (this.canFranchiseOverride) {
            // Retrieve franchise lead push options
            return this.coreApiResources.FranchiseLeadPushOptionApi
                .query({ $filter: `${nameof<CoreEntities.FranchiseLeadPushOption>(o => o.FranchiseId)} eq ${this.franchiseId}` }).$promise
                .then((franchisePushLeadOptions) => {
                    this.leadPushOptions = franchisePushLeadOptions;
                });
        }
        else {
            // Retrieve concept lead push options
            return this.coreApiResources.ConceptLeadPushOptionApi
                .query({ $filter: `${nameof<CoreEntities.ConceptLeadPushOption>(o => o.ConceptId)} eq ${this.conceptId}` }).$promise
                .then((conceptLeadPushOptions) => {
                    this.leadPushOptions = conceptLeadPushOptions.map((a) => {
                        return {
                            C4CallTypeId: a.C4CallTypeId,
                            FranchiseId: this.franchiseId,
                            FranchiseLeadPushOptionId: 0,
                            LeadPushTypeId: a.LeadPushTypeId
                        } as CoreResources.IFranchiseLeadPushOption;
                    });
                });
        }
    }

    MapLeadPushOptions(): Array<PushLeadOptionGridObject> {
        // Add already existing items to the collection
        return this.leadPushOptions.map((leadOption) => {
            return new PushLeadOptionGridObject(
                leadOption.C4CallTypeId,
                leadOption.FranchiseId,
                leadOption.FranchiseLeadPushOptionId,
                leadOption.LeadPushTypeId,
                this.GetCallTypeName(leadOption.C4CallTypeId)
            )
        });
    }

    UpdateLeadOption(pushLeadOption: PushLeadOptionGridObject): ng.IPromise<any> {
        // Update the lead option
        let leadOptionId = this.odataHelper.ConvertCompositeKeyToString({
            FranchiseId: pushLeadOption.ObjectId,
            C4CallTypeId: pushLeadOption.C4CallTypeId
        });

        let franchisePushLeadOption: CoreResources.IFranchiseLeadPushOption =
            this.ConvertToIFranchiseLeadPushOption(pushLeadOption);
        return this.coreApiResources.FranchiseLeadPushOptionApi
            .update({ id: leadOptionId }, franchisePushLeadOption).$promise
            .then((a) => {
                pushLeadOption.LeadPushTypeId = a.LeadPushTypeId;
            });
    }

    CreateLeadOption(pushLeadOption: PushLeadOptionGridObject): ng.IPromise<any> {
        // Create the lead option
        let franchisePushLeadOption: CoreResources.IFranchiseLeadPushOption =
            this.ConvertToIFranchiseLeadPushOption(pushLeadOption);
        return this.coreApiResources.FranchiseLeadPushOptionApi
            .save({}, franchisePushLeadOption).$promise
            .then((a) => {
                pushLeadOption.LeadPushOptionId = a.FranchiseLeadPushOptionId;
                pushLeadOption.LeadPushTypeId = a.LeadPushTypeId;
            });
    }

    ConvertToIFranchiseLeadPushOption(pushLeadOption: PushLeadOptionGridObject): CoreResources.IFranchiseLeadPushOption {
        return {
            C4CallTypeId: pushLeadOption.C4CallTypeId,
            FranchiseId: pushLeadOption.ObjectId,
            FranchiseLeadPushOptionId: pushLeadOption.LeadPushOptionId,
            LeadPushTypeId: pushLeadOption.LeadPushTypeId
        } as CoreResources.IFranchiseLeadPushOption;
    }

    DefineNewPushLeadOption(callTypeId: number) {
        return new PushLeadOptionGridObject(
            callTypeId, this.franchiseId, 0, 0, this.GetCallTypeName(callTypeId));
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchisePushLeadOption', {
            bindings: {
                franchiseId: "<"
            },
            controller: FranchisePushLeadOptionController,
            templateUrl: "/Templates/FranchiseProfiles/FranchisePushLeadOption.html"
        });
    }
}
