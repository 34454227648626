
import { NavigationService } from "@DppApp/Services/NavigationService";
import { ProfileCompletenessAssessment, ProfileCompletenessService } from "@DppApp/Services/ProfileCompletenessService";
import { DppContactSelectorDirective } from "@DppApp/Directives/ContactSelector";
import { DppBuildingCategoryImageAssetsDirective } from "@DppApp/Directives/CategoryImageAssets";
import { ImageSelectorDirective } from "@DppApp/Directives/ImageSelector";
import { DppMultiImagesDirective } from "@DppApp/Directives/MultiImageSelector";
import { DppSaveButtonsDirective } from "@DppApp/Directives/SaveButtons";
import { OnOffSwitchTdgDirective } from "@DppApp/Directives/OnOffSwitchTdg";
import { DppServiceProviderSelectorDirective } from "@DppApp/Directives/ServiceProviderSelector";
import { AddDisasterPrepProfileBuildingController } from "@DppApp/Sections/AddDisasterPrepProfileBuildingController";
import { EditDisasterPrepProfileBuildingAssetsController } from "@DppApp/Sections/EditDisasterPrepProfileBuildingAssetsController";
import { EditDisasterPrepProfileBuildingContactsController } from "@DppApp/Sections/EditDisasterPrepProfileBuildingContactsController";
import { EditDisasterPrepProfileBuildingController } from "@DppApp/Sections/EditDisasterPrepProfileBuildingController";
import { EditDisasterPrepProfileBuildingHazardousMaterialsController } from "@DppApp/Sections/EditDisasterPrepProfileBuildingHazardousMaterialsController";
import { EditDisasterPrepProfileBuildingInformationTechnologyController } from "@DppApp/Sections/EditDisasterPrepProfileBuildingInformationTechnologyController";
import { EditDisasterPrepProfileBuildingInsuranceController } from "@DppApp/Sections/EditDisasterPrepProfileBuildingInsuranceController";
import { EditDisasterPrepProfileBuildingServiceProvidersController } from "@DppApp/Sections/EditDisasterPrepProfileBuildingServiceProvidersController";
import { EditDisasterPrepProfileBuildingUtilitiesController } from "@DppApp/Sections/EditDisasterPrepProfileBuildingUtilities";
import { EditDisasterPrepProfileController } from "@DppApp/Sections/EditDisasterPrepProfileController";
import { FinishDisasterPrepProfileController } from "@DppApp/Sections/FinishDisasterPrepProfile";
import { NavigationDisasterPrepProfileController } from "@DppApp/Sections/NavigationDisasterPrepProfileController";
import { IndexDisasterPrepProfileController } from "@DppApp/IndexDisasterPrepProfileController";
import { EditDisasterPrepProfileSectionController } from "@DppApp/EditDisasterPrepProfileSectionController";
import { NewDisasterPrepProfileController } from "@DppApp/NewDisasterPrepProfileController";
import { PrintDisasterPrepProfileController } from "@DppApp/PrintDisasterPrepProfileController";



export function Init()
{
    let dppApp = angular.module('portal.dpp');

    NavigationService.Init(dppApp);
    ProfileCompletenessService.Init(dppApp);

    DppBuildingCategoryImageAssetsDirective.Init(dppApp);
    DppContactSelectorDirective.Init(dppApp);
    ImageSelectorDirective.Init(dppApp);
    DppMultiImagesDirective.Init(dppApp);
    OnOffSwitchTdgDirective.Init(dppApp);
    DppSaveButtonsDirective.Init(dppApp);
    DppServiceProviderSelectorDirective.Init(dppApp);

    IndexDisasterPrepProfileController.Init(dppApp);
    EditDisasterPrepProfileSectionController.Init(dppApp);
    NewDisasterPrepProfileController.Init(dppApp);
    PrintDisasterPrepProfileController.Init(dppApp);

    AddDisasterPrepProfileBuildingController.Init(dppApp);
    EditDisasterPrepProfileBuildingAssetsController.Init(dppApp);
    EditDisasterPrepProfileBuildingContactsController.Init(dppApp);
    EditDisasterPrepProfileBuildingController.Init(dppApp);
    EditDisasterPrepProfileBuildingHazardousMaterialsController.Init(dppApp);
    EditDisasterPrepProfileBuildingInformationTechnologyController.Init(dppApp);
    EditDisasterPrepProfileBuildingInsuranceController.Init(dppApp);
    EditDisasterPrepProfileBuildingServiceProvidersController.Init(dppApp);
    EditDisasterPrepProfileBuildingUtilitiesController.Init(dppApp);
    EditDisasterPrepProfileController.Init(dppApp);
    FinishDisasterPrepProfileController.Init(dppApp);
    NavigationDisasterPrepProfileController.Init(dppApp);

    return dppApp;
}