import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { MerchantResources, MerchantEntities } from "Interfaces/FranForce/Merchant/MerchantResources";
import { MerchantResourceApi } from "Interfaces/FranForce/Merchant/MerchantResourceApiInterfaces";

export class MerchantApiResources {
    
    GiftCertificateApi: MerchantResourceApi.IGiftCertificateApi;
    GiftCertificateReimbursementBatchApi: MerchantResourceApi.IGiftCertificateReimbursementBatchApi;
    MsMollyDonationApi: MerchantResourceApi.IMsMollyDonationApi;

    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {
        this.RefreshResources()
    }

    RefreshResources()
    {
        this.GiftCertificateApi = this.$resource<MerchantResources.IGiftCertificate>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/GiftCertificates/:id/",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.GiftCertificateReimbursementBatchApi = this.$resource<MerchantResources.IGiftCertificateReimbursementBatch>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/GiftCertificateReimbursementBatches/:id/",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.MsMollyDonationApi = this.$resource<MerchantResources.IMsMollyDonation>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/MsMollyDonations/:id/",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
    }
   
    GetGiftCertificateStatus(id: number|string, suffix: number|string): ng.IHttpPromise<IGiftCertificateStatus>
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/GiftCertificates/Status/${id}-${suffix}`,
        };

        return this.$http<IGiftCertificateStatus>(config);
    }

    GetReimbursableGiftCertificates(parameters: string|Object): ng.IHttpPromise<MerchantResources.IGiftCertificate[]>
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/GiftCertificates/Reimbursable`,
            params: parameters
        };

        return this.$http<MerchantResources.IGiftCertificate[]>(config);
    }

    RedeemGiftCertificate(model: IRedeemGiftCertificateRequest)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/GiftCertificates/Redeem`,
            data: model
        };

        return this.$http<IGiftCertificateStatus>(config);
    }

    RefundGiftCertificate(model: IRefundGiftCertificateRequest)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/GiftCertificates/Refund/`,
            data: model
        };

        return this.$http<string>(config);
    }

    ResendGiftCertificateEmails(model: IResendGiftCertificateEmailsRequest)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/GiftCertificates/ResendEmails/`,
            data: model
        };

        return this.$http<any>(config);
    }

    CreateGiftCertificateReimbursementBatch(giftCertificateIds: number[])
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/GiftCertificateReimbursementBatches/GenerateBatch`,
            data: giftCertificateIds
        };

        return this.$http<MerchantEntities.GiftCertificateReimbursementBatch>(config);
    }

    RefundMsMollyDonation(model: IRefundMsMollyDonationRequest)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/MsMollyDonations/Refund/`,
            data: model
        };

        return this.$http<string>(config);
    }
}