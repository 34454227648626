import { LegalApiResources } from "Services/Resources/LegalApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { LegalEntities } from "Interfaces/FranForce/Legal/LegalResources";

interface INewSectionThreeScope  extends ng.IScope {
    model: LegalEntities.SectionThree;
    submitSectionThree: () => void;
    cancelSectionThree: () => void;
    newSectionThreeForm: any;
    contractId: string;
    franchiseId: string;
    selectionDetails: { franchiseID: string, contractID: string };
    isItt: boolean;
    dispositionTelephoneNumbers: LegalEntities.TelephoneNumberDisposition[];
    kNotifierOptions: any;
    kNotifier: kendo.ui.Notification;
    saveSuccessful: boolean;
    approvalSelections: any;
    saveBtnDisabled: boolean;
    denyBtnDisabled: boolean;
    userEmail: string;
}

export class NewSectionThreeController implements ng.IController {

    static $inject = [
        "$scope",
        "legalApiResources",
        "$window",
        "$q",
        "$log",
        "$http",
        "coreApiResources",
        "identityManager"
    ];

    constructor(
        private $scope: INewSectionThreeScope,
        private legalApiResources: LegalApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $http: ng.IHttpService,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager)
    {
        $scope.saveBtnDisabled = false;
        $scope.denyBtnDisabled = false;

        $scope.kNotifierOptions = {
            position: {
                top: 50,
                left: $(window).width() / 2
            },
            templates: [{
                type: 'ngTemplate',
                template: $('#kNTemplate').html(),
            }]
        };

        $scope.approvalSelections = [
            { Description: "Approved", Value: true }, { Description: "Not Approved", Value: false }
        ];
    }

    $onInit() {
        let $scope = this.$scope;
        let $http = this.$http;
        let legalApiResources = this.legalApiResources;
        let identityManager = this.identityManager;

        $scope.franchiseId = location.pathname.split("/")[3] || $scope.selectionDetails.franchiseID;
        $scope.contractId = location.pathname.split("/")[4] || $scope.selectionDetails.contractID;
        // Initialize Approval Sign-Off
        $scope.model = <any>{};
        identityManager.GetLoggedInUserInfo().then(
            (success) => {
                $scope.userEmail = success.data.Email;
            },
            (error) => {
                console.log(error);
            });
        this.coreApiResources.FranchiseApi.get({ $expand: "Concept", id: $scope.franchiseId },
            (successResponse: CoreEntities.Franchise) => {
                $scope.$emit("setFranchiseDetails", {
                    FranchiseName: successResponse.Concept.DisplayName,
                    LicenseNum: successResponse.LicenseNumber,
                    FranchiseeCode: $scope.franchiseId,
                    FranchiseAddress: successResponse.Address,
                    FranchisePhoneNumber: successResponse.StorePhone,
                    FranchiseEmail: successResponse.StoreEmail
                });
            },
            errorResponse => {
                console.log(errorResponse);
            });

        this.legalApiResources.ContractApi.get({ id: $scope.contractId, $expand: "SectionOne, ContractType" },
            (contractResponse: LegalEntities.Contract) => {
                $scope.model.CCADate = contractResponse.SectionOne.ApprovedDate;
                $scope.model.CCASignature = contractResponse.SectionOne.ApprovedName;
                $scope.isItt = contractResponse.ContractType.Description == "Intent To Terminate";
        }, errorResponse => {
            console.log(errorResponse);
        });

        this.legalApiResources.TelephoneNumberDispositionApi.query({}, (successResponse: LegalEntities.TelephoneNumberDisposition[]) => {
            $scope.dispositionTelephoneNumbers = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        $scope.cancelSectionThree = () => {
            finishSectionThree(false);
        }

        $scope.submitSectionThree = () => {
            if ($scope.newSectionThreeForm.$valid) {
                finishSectionThree(true);
            } else {
                $scope.saveSuccessful = false;
                $scope.kNotifier.show({
                    kValue: "Some field(s) require input to proceed "
                }, "ngTemplate");

                Object.keys($scope.newSectionThreeForm.$error).forEach(function (value) {
                    $scope.newSectionThreeForm.$error[value].forEach(function (item) {
                        item.$setDirty();
                    });
                });
            }
        }

        function finishSectionThree(isSubmit: boolean) {
            $scope.saveBtnDisabled = true;
            $scope.denyBtnDisabled = true;

            if (isSubmit) {
                $scope.model.ApprovedBy = $scope.userEmail;
                $scope.model.ApprovedDate = kendo.toString(new Date(), "MM/dd/yyyy");
            }

            legalApiResources.SectionThreeApi.save($scope.model, (successResponse: LegalEntities.SectionThree) => {
                if (isSubmit) {
                    legalApiResources.SectionThreeCompleted($scope.contractId, successResponse.SectionThreeID).then(successResponse => {
                        window.location.href = "/Itt";
                    }, errorResponse => {
                        $scope.kNotifier.show({
                            kValue: "Failed to save to server. Please contact an admin."
                        }, "ngTemplate");
                        console.log(errorResponse);
                        $scope.saveBtnDisabled = false;
                        $scope.denyBtnDisabled = false;
                    });
                } else {
                    legalApiResources.SectionThreeDenied($scope.contractId, successResponse.SectionThreeID).then(successResponse => {
                        window.location.href = "/Itt";
                    }, errorResponse => {
                        $scope.kNotifier.show({
                            kValue: "Failed to save to server. Please contact an admin."
                        }, "ngTemplate");
                        console.log(errorResponse);
                        $scope.saveBtnDisabled = false;
                        $scope.denyBtnDisabled = false;
                    });
                }
            }, errorResponse => {
                $scope.kNotifier.show({
                    kValue: "Failed to save to server. Please contact an admin."
                }, "ngTemplate");
                console.log(errorResponse);
                $scope.saveBtnDisabled = false;
                $scope.denyBtnDisabled = false;
            });    
        }
    }
}