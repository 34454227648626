export interface IFRMStateParams extends ng.ui.IStateParamsService {
    franchisorId: string;
}

let frmRouterConfig = [
    "$stateProvider",
    "$urlMatcherFactoryProvider",
    ($stateProvider: ng.ui.IStateProvider, $urlMatcherFactoryProvider: ng.ui.IUrlMatcherFactory) =>
    {
        let frmIndexState = {
            name: 'frm',
            url: '/frm/:franchisorId',
            resolve: { franchisorId: ['$stateParams', ($stateParams) => { return $stateParams.franchisorId }] },
            templateUrl: ($stateParams: IFRMStateParams) => { return '/Templates/FranchisorRoyaltyManagement/frmIndex.html' }
        }

        let frmGeneralState = {
            name: 'frm.general',
            url: '/general',
            templateUrl: ($stateParams: IFRMStateParams) => { return '/Templates/FranchisorRoyaltyManagement/frmGeneral.html' }
        }

        let frmAdFeeCapsState = {
            name: 'frm.adfeecaps',
            url: '/adfeecaps',
            templateUrl: ($stateParams: IFRMStateParams) => { return '/Templates/FranchisorRoyaltyManagement/frmAdFeeCaps.html' }
        }

        let frmReportingPeriodsState = {
            name: 'frm.reportingperiods',
            url: '/reportingperiods',
            templateUrl: ($stateParams: IFRMStateParams) => { return '/Templates/FranchisorRoyaltyManagement/frmReportingPeriods.html' }

        }

        let frmItems = {
            name: 'frm.items',
            url: '/items',
            templateUrl: ($stateParams: IFRMStateParams) => { return '/Templates/FranchisorRoyaltyManagement/frmItems.html' }
        }

        $urlMatcherFactoryProvider.strictMode(false);
        $stateProvider.state(frmIndexState);
        $stateProvider.state(frmGeneralState);
        $stateProvider.state(frmAdFeeCapsState);
        $stateProvider.state(frmReportingPeriodsState);
        $stateProvider.state(frmItems);
    }
];

export { frmRouterConfig };