import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { StringProperties } from "Types/StringProperties";
import * as _ from "underscore"
import * as elements from 'typed-html';
import { event } from "jquery";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            franchiseMultiSelector: Partial<StringProperties<FranchiseMultiSelectorComponentController>>;
        }
    }
}

export type FranchiseMultiSelectorComponentControllerState = { franchiseIdParameter?: string };
export type FranchiseMultiSelectorOnSelectParameters = { franchise: kendo.data.ObservableObject & Partial<CoreResources.IFranchise> };
export type FranchiseMultiSelectorOnLoadParameters = { franchises: Array<Partial<CoreResources.IFranchise>> };


export class FranchiseMultiSelectorComponentController implements ng.IController {

    selectedModel: kendo.data.ObservableObject & Partial<CoreResources.IFranchise>;
    dropdown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    //---- Bindings ----
    franchiseId: number;
    select: string[];
    filters: kendo.data.DataSourceFilter;
    sort: (a: Partial<CoreResources.IFranchise>, b: Partial<CoreResources.IFranchise>) => number;
    disable: boolean;
    displayTextTemplate: string;
    queryParams: { [key: string]: any };
    extraOptions: kendo.ui.DropDownListOptions;
    onSelect: (params: FranchiseMultiSelectorOnSelectParameters) => void;
    onLoad: (params: FranchiseMultiSelectorOnLoadParameters) => void;
    state: boolean | FranchiseMultiSelectorComponentControllerState;
    selectedFranchises: any[];
    franchiseMultiSelect: kendo.ui.MultiSelect;
    franchiseMultiSelectOptions: kendo.ui.MultiSelectOptions;
    franchiseList: any[];
    filterFranchiseIds: any[];
    enableSelectAll: boolean;
    isLoading: boolean;
    //------------------

    static readonly DisplayProperty = "DisplayText";

    static $inject = [
        "$q",
        "$interpolate",
        "$location",
        "coreApiResources",
        "identityManager",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $q: ng.IQService,
        private $interpolate: ng.IInterpolateService,
        private $location: ng.ILocationService,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    ) {

    }

    async $onInit() {
        this.isLoading = true;
        if (!this.select || !(this.select instanceof Array)) {
            this.select = [
                nameof<CoreResources.IFranchise>(o => o.FranchiseId),
                nameof<CoreResources.IFranchise>(o => o.FranchiseeName)
            ];
        }

        if (!this.displayTextTemplate)
            this.displayTextTemplate = `{{${nameof<CoreResources.IFranchise>(o => o.FranchiseeName)}}}`;

        if (!this.sort)
            this.sort = (a, b) => { return a[FranchiseMultiSelectorComponentController.DisplayProperty].toLowerCase().localeCompare(b[FranchiseMultiSelectorComponentController.DisplayProperty].toLowerCase()); }

        this.InitState();
        this.SetDropDownOptions();
    }

    async SetDropDownOptions() {
        this.franchiseMultiSelectOptions =
        {
            placeholder: "Select Franchises...",
            dataTextField: "DisplayText",
            dataValueField: "FranchiseId",
            valuePrimitive: true,
            autoBind: true,
            autoClose: false,
            clearButton: this.enableSelectAll ? false : true,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options: kendo.data.DataSourceTransportReadOptions) => {
                        if (this.filters === undefined || this.filters === null) {
                            options.success([]);
                            return;
                        }
                        let filters: kendo.data.DataSourceFilters;
                        if (!_.isEmpty(this.filters)) {
                            filters = {
                                logic: "and",
                                filters: [
                                    this.filters
                                ]
                            };
                        }
                        else {
                            filters = {
                                logic: "and",
                                filters: [

                                ]
                            };
                        }

                        let params: ODataQueryParameters = {};
                        params.$select = this.GetSelectString();

                        if (!_.isEmpty(filters)) {
                            params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersOrItemToString(filters);
                        }

                        if (!_.isEmpty(this.filters)) {
                            params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersOrItemToString(this.filters);
                        }

                        if (this.queryParams) {
                            params = angular.extend({}, this.queryParams, params);
                        }

                        this.coreApiResources.FranchiseApi.query(params)
                            .$promise.then((franchises) => {
                                for (let franchise of franchises) {
                                    franchise[FranchiseMultiSelectorComponentController.DisplayProperty] = this.$interpolate(this.displayTextTemplate)(franchise);
                                }
                                if (this.sort) {
                                    franchises = franchises.sort(this.sort);
                                }
                                if (this.onLoad) {
                                    this.onLoad({ franchises: franchises.slice() });
                                }

                                this.franchiseList = franchises;
                                if (this.filterFranchiseIds && this.filterFranchiseIds.length) {
                                    this.franchiseList = this.franchiseList.filter((franchises) => !this.filterFranchiseIds.includes(franchises.FranchiseId));

                                }
                                options.success(this.franchiseList);
                                this.isLoading = false;

                            })
                            .catch(err => {
                                this.isLoading = false;
                                console.log('err in loading multiselect franchises');
                            });
                    }
                }
            }),
            dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) => {
                this.franchiseMultiSelect = e.sender;
            },
        };


    }

    $postLink() {

    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.state)]) {
            if (this.state === true) {
                this.state = {
                    franchiseIdParameter: "franchiseId"
                };
            }
        }

        if (onChanges[nameof(this.filters)]) {
            this.Refresh()
        }

        if (onChanges[nameof(this.filterFranchiseIds)]) {
            this.Refresh();
        }

        if (onChanges[nameof(this.disable)]) {
            if (this.dropdown) {
                this.dropdown.enable(!this.disable);
            }
        }
    }

    Refresh() {
        if (this.franchiseMultiSelectOptions) {
            this.franchiseMultiSelectOptions.dataSource.read();
        }

    }

    InitState() {
        if (this.state && typeof this.state === "object") {
            let searchParam = this.$location.search()[this.state.franchiseIdParameter];
            if (searchParam) {
                this.franchiseId = parseInt(searchParam);
            }
        }
    }

    SetState() {
        if (this.state && typeof this.state === "object") {
            this.$location.search(this.state.franchiseIdParameter, this.franchiseId);
        }
    }

    private GetSelectString() {
        if (!this.select)
            this.select = [];

        if (!this.select.some(s => s == nameof<CoreResources.IFranchise>(o => o.FranchiseId)))
            this.select.push(nameof<CoreResources.IFranchise>(o => o.FranchiseId));

        return this.select.join(",")
    }

    ClearAll() {
        this.franchiseMultiSelect.value([]);
        this.franchiseMultiSelect.trigger("change");
    }

    SelectAll() {
        let allFranchiseList: number[] = this.franchiseMultiSelect.dataSource.data().map((x, i) => x['FranchiseId']);
        this.franchiseMultiSelect.value(allFranchiseList);
        this.franchiseMultiSelect.trigger("change");
    }

    static BindComponent(app: ng.IModule) {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.franchiseMultiSelector);

        app.component(componentName, {
            bindings: {
                [nameof<FranchiseMultiSelectorComponentController>(o => o.franchiseId)]: "<",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.select)]: "<",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.filters)]: "<",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.sort)]: "<",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.disable)]: "<",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.queryParams)]: "<",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.displayTextTemplate)]: "<",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.extraOptions)]: "<",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.onSelect)]: "&",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.onLoad)]: "&",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.state)]: "<",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.selectedFranchises)]: "=",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.filterFranchiseIds)]: "<",
                [nameof<FranchiseMultiSelectorComponentController>(o => o.enableSelectAll)]: "<",

            },
            controller: FranchiseMultiSelectorComponentController,
            templateUrl: `/Templates/Common/FranchiseMultiSelector.html`
        });
    }
}