
interface IBreakingChangesApiHelpScope extends ng.IScope {
    
}

export class BreakingChangesApiHelpController
{
    static $inject = ['$scope'];

    constructor($scope: IBreakingChangesApiHelpScope)
    {

    }
}