import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";

export class IndexGiftCertificateController implements ng.IController {

    userInfo: IUserInfoViewModel;

    isVisibleSearchButton: boolean;
    isVisibleEditButton: boolean;
    isVisibleRedeemButton: boolean;
    isVisibleRefundButton: boolean;
    isVisibleReimbursementButton: boolean;
    
    static $inject = [
        "$scope",
        "identityManager",
        "$timeout",
        "apiConfig"
    ];

    constructor(
        private $scope: ng.IScope,
        private identityManager: IdentityManager,
        private $timeout: ng.ITimeoutService,
        private apiConfig: ApiConfig
    )
    {

    }

    async $onInit()
    {
        this.userInfo = (await this.identityManager.GetLoggedInUserInfo()).data;
        
        this.isVisibleSearchButton = this.userInfo.Roles.some(r =>
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.AdminRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.GiftCertificateAdminRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.MerchantAdminRole
        );
        
        this.isVisibleRedeemButton = this.userInfo.Roles.some(r =>
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.AdminRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.GiftCertificateRedeemerRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.MerchantAdminRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.GiftCertificateAdminRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.FranchiseeRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.FranchiseeEmployeeRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.HelpDeskRole
        );

        this.isVisibleReimbursementButton = this.userInfo.Roles.some(r =>
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.AdminRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.GiftCertificateReimburserRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.GiftCertificateAdminRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.MerchantAdminRole
        );

        this.isVisibleRefundButton = this.userInfo.Roles.some(r =>
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.AdminRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.GiftCertificateAdminRole ||
            r.Name === this.apiConfig.FranForceConstants.RoleConstants.MerchantAdminRole
        );
    
        await this.$timeout();
    }

}