import { fddRoyaltiesRatePlansComponentController } from "Directives/RPM/FddManagement/fddRoyalties/fddRoyaltiesRatePlans";
import { fddRoyaltiesRatePlanItemsComponentController } from "Directives/RPM/FddManagement/fddRoyalties/fddRoyaltiesRatePlanItems";
import { fddRoyaltiesServiceCategoriesComponentController } from "Directives/RPM/FddManagement/fddRoyalties/fddRoyaltiesServiceCategories";
import { fddRoyaltiesFeeScalesComponentController } from "Directives/RPM/FddManagement/fddRoyalties/fddRoyaltiesFeeScales";
import { fddRoyaltiesRatePlanMinimumItemsComponentController } from "Directives/RPM/FddManagement/fddRoyalties/fddRoyaltiesRatePlanMinItems";
import { fddRoyaltiesTerritoryMinimumFeesComponentController } from "Directives/RPM/FddManagement/fddRoyalties/fddRoyaltiesTerritoryMinimumFees";
import { RmsConfirmationDialogComponentController } from "Directives/RPM/Common/rmsConfirmationDialog";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { RmsStateService } from "Services/State/RmsState";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { fddRoyaltyMinimums } from "Directives/RPM/FddManagement";

export class fddRoyaltiesComponentController implements ng.IController
{
    fddId: number;
    readOnly: boolean;
    init: (params: { self: fddRoyaltiesComponentController }) => void;

    isLoading: boolean;

    ratePlansController: fddRoyaltiesRatePlansComponentController;
    ratePlanItemsController: fddRoyaltiesRatePlanItemsComponentController;
    serviceCategoriesController: fddRoyaltiesServiceCategoriesComponentController;
    feeScalesController: fddRoyaltiesFeeScalesComponentController;
    //ratePlanMinimumItemsController: fddRoyaltiesRatePlanMinimumItemsComponentController;
    territoryFeesController: fddRoyaltiesTerritoryMinimumFeesComponentController;
    minimumsController: fddRoyaltyMinimums;
    dialogController: RmsConfirmationDialogComponentController;
    formController: ng.IFormController;

    franchisorId: number;
    selectedRatePlanId: number;
    selectedRatePlanItemId: number;
    selectedRatePlanMinimumItemId: number;

    isMethodologySpecial: boolean;
    minimumFreqIsMonthly: boolean;
    isScaleBasedOnTerritory: boolean;

    currentLoginEmail: string;

    royaltyClicked: number;

    showMessageInfoModal: boolean;
    infoModalMessage: string;
    infoModalTitle: string;

    static $inject = [
        'rpmApiResources',
        'rpmUiApiResources',
        'rpmHelper',
        'rmsState',
        'identityManager',
        '$timeout',
        '$q'
    ];

    constructor(
        private rpmApiResources: RpmApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private rmsState: RmsStateService,
        private identityManager: IdentityManager,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService
    )
    {
    }

    $onInit()
    {
        this.minimumFreqIsMonthly = false;
        this.isScaleBasedOnTerritory = false;
        this.isMethodologySpecial = false;
        this.selectedRatePlanId = null;
        this.selectedRatePlanItemId = null;
        this.selectedRatePlanMinimumItemId = null;
        this.showMessageInfoModal = false;
        this.infoModalMessage = "";
        this.infoModalTitle = "";
    }

    $postLink()
    {
        if (this.init)
        {
            this.init({ self: this });
        }
    }

    OnFeeScalesControllerLoaded(feeScalesController: fddRoyaltiesFeeScalesComponentController)
    {
        this.feeScalesController = feeScalesController;
        this.franchisorId = this.feeScalesController.disclosureDocument.FranchisorId;
        this.isMethodologySpecial = (this.feeScalesController.disclosureDocument.RoyaltyMethodologyTypeId === this.rpmHelper.SpecialRoyaltyMethodologyTypeId);
    }

    OnSelectRatePlan(ratePlan: RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel, isRemoved: boolean = false)
    {
        let hasChanges = (
            this.ratePlanItemsController.HasChanges() ||
            this.serviceCategoriesController.HasChanges() ||
            //this.ratePlanMinimumItemsController.HasChanges() ||
            this.territoryFeesController.HasChanges()
        );
        let needsConfirmation = (
            hasChanges &&
            !isRemoved &&
            this.selectedRatePlanId &&
            (
                ratePlan === null ||
                ratePlan.DisclosureDocumentRatePlanId !== this.selectedRatePlanId
            )
        );

        if (needsConfirmation) {
            this.dialogController.Open({
                title: false,
                message: `There are currently changes on grids based on the current rate plan selected (Id: ${this.selectedRatePlanId}). 
                                                Do you want to save those changes?`,
                actions: [
                    {
                        name: "Yes",
                        cssClass: "pure-button btn-accept",
                        event: (e) =>
                        {
                            this.isLoading = true;
                            this.$q.all([
                                this.ratePlanItemsController.SaveChanges(),
                                this.serviceCategoriesController.SaveChanges(),
                                //this.ratePlanMinimumItemsController.SaveChanges(),
                                this.territoryFeesController.SaveChanges()
                            ])
                                .then(() =>
                                {
                                    this.SetRatePlan(ratePlan);
                                })
                                .finally(() =>
                                {
                                    this.isLoading = false;
                                });
                        }
                    }, {
                        name: "No",
                        cssClass: "pure-button btn-default",
                        event: (e) =>
                        {
                            this.SetRatePlan(ratePlan);
                        }
                    }, {
                        name: "Cancel",
                        cssClass: "pure-button btn-cancel",
                        event: (e) =>
                        {
                            this.ratePlansController.SelectRowById(this.selectedRatePlanId);
                        }
                    },
                ],
            });
        }
        else {
            this.SetRatePlan(ratePlan);
        }
    }

    SetRatePlan(ratePlan: RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel)
    {
        this.$timeout(() =>
        {
            this.selectedRatePlanId = ratePlan && ratePlan.DisclosureDocumentRatePlanId;
            this.selectedRatePlanItemId = null;
            //this.selectedRatePlanMinimumItemId = null;
        }, 0);
    }

    OnSelectRatePlanItem(ratePlanItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel, isRemoved: boolean = false)
    {
        let hasChanges = (this.isMethodologySpecial && this.serviceCategoriesController.HasChanges());
        let needsConfirmation = (
            hasChanges &&
            !isRemoved &&
            this.selectedRatePlanItemId &&
            (
                ratePlanItem === null ||
                ratePlanItem.DisclosureDocumentRatePlanItemId !== this.selectedRatePlanItemId
            )
        );

        if (needsConfirmation) {
            this.dialogController.Open({
                title: false,
                message: `There are currently changes to service categories based on the current rate plan item selected (Id: ${this.selectedRatePlanItemId}). 
                                                Do you want to save those changes?`,
                actions: [
                    {
                        name: "Yes",
                        cssClass: "pure-button btn-accept",
                        event: (e) =>
                        {
                            this.isLoading = true;
                            this.serviceCategoriesController.SaveChanges()
                                .then(() =>
                                {
                                    this.SetRatePlanItem(ratePlanItem);
                                })
                                .finally(() =>
                                {
                                    this.isLoading = false;
                                });
                        }
                    }, {
                        name: "No",
                        cssClass: "pure-button btn-default",
                        event: (e) =>
                        {
                            this.SetRatePlanItem(ratePlanItem);
                        }
                    }, {
                        name: "Cancel",
                        cssClass: "pure-button btn-cancel",
                        event: (e) =>
                        {
                            this.ratePlanItemsController.SelectRowById(this.selectedRatePlanItemId);
                        }
                    },
                ],
            });
        }
        else {
            this.SetRatePlanItem(ratePlanItem);
        }
    }

    SetRatePlanItem(ratePlanItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel)
    {
        this.$timeout(() =>
        {
            this.selectedRatePlanItemId = ratePlanItem && ratePlanItem.DisclosureDocumentRatePlanItemId;
        }, 0);
    }

    OnSelectRatePlanMinimumItem(ratePlanMinItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel, isRemoved: boolean)
    {
        let hasChanges = (this.isScaleBasedOnTerritory && this.territoryFeesController.HasChanges());
        let needsConfirmation = (
            hasChanges &&
            !isRemoved &&
            this.selectedRatePlanMinimumItemId &&
            (
                ratePlanMinItem === null ||
                ratePlanMinItem.DisclosureDocumentRatePlanMinimumItemId !== this.selectedRatePlanMinimumItemId
            )
        );

        if (needsConfirmation) {
            this.dialogController.Open({
                title: false,
                message: `There are currently changes to territory minimum fees based on the current rate plan minimum item selected (Id: ${this.selectedRatePlanMinimumItemId}). 
                                                Do you want to save those changes?`,
                actions: [
                    {
                        name: "Yes",
                        cssClass: "pure-button btn-accept",
                        event: (e) =>
                        {
                            this.isLoading = true;
                            this.serviceCategoriesController.SaveChanges()
                                .then(() =>
                                {
                                    this.SetRatePlanMinimumItem(ratePlanMinItem);
                                })
                                .finally(() =>
                                {
                                    this.isLoading = false;
                                });
                        }
                    }, {
                        name: "No",
                        cssClass: "pure-button btn-default",
                        event: (e) =>
                        {
                            this.SetRatePlanMinimumItem(ratePlanMinItem);
                        }
                    }, {
                        name: "Cancel",
                        cssClass: "pure-button btn-cancel",
                        event: (e) =>
                        {
                            //this.ratePlanMinimumItemsController.SelectRowById(this.selectedRatePlanMinimumItemId);
                        }
                    },
                ],
            });
        }
        else {
            this.SetRatePlanMinimumItem(ratePlanMinItem);
        }
    }

    SetRatePlanMinimumItem(ratePlanMinItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel)
    {
        this.$timeout(() =>
        {
            this.isScaleBasedOnTerritory = false;
            if (ratePlanMinItem)
                this.isScaleBasedOnTerritory = (ratePlanMinItem.MinimumScaleBasedOn == 7);
            this.selectedRatePlanMinimumItemId = ratePlanMinItem && ratePlanMinItem.DisclosureDocumentRatePlanMinimumItemId;
        });
    }

    HasUnsavedChanges()
    {
        return (
            (this.feeScalesController && this.feeScalesController.formController.$dirty) ||
            (this.ratePlansController && this.ratePlansController.HasChanges()) ||
            (this.ratePlanItemsController && this.ratePlanItemsController.HasChanges()) ||
            (this.serviceCategoriesController && this.serviceCategoriesController.HasChanges())
            // || (this.ratePlanMinimumItemsController && this.ratePlanMinimumItemsController.HasChanges())
        );
    }

    CancelChanges()
    {
        this.$q.all([
            this.feeScalesController.ResetChanges(),
            this.ratePlansController.ResetChanges(),
            this.ratePlanItemsController.ResetChanges(),
            this.serviceCategoriesController.ResetChanges(),
            //this.ratePlanMinimumItemsController.ResetChanges(),
            this.territoryFeesController.ResetChanges()
        ]).then(() =>
        {
            this.selectedRatePlanId = null;
            this.selectedRatePlanItemId = null;
            this.selectedRatePlanMinimumItemId = null;
            this.ratePlansController.SelectRowById(null);
            this.ratePlanItemsController.SelectRowById(null);
            //this.ratePlanMinimumItemsController.SelectRowById(null);
        });
    }

    validateionPopupMessage(message: string, title: string, show: boolean) {
        this.infoModalMessage = message;
        this.infoModalTitle = title;
        this.showMessageInfoModal = show;
    }

    Save() {
        this.isLoading = true;

        //rate plan error check
        let isRatePlanError = false;
        if (this.ratePlansController.royaltyRatePlanGrid && this.ratePlansController.royaltyRatePlanGrid.dataSource) {
            let royaltyRatePlanGridData = this.ratePlansController.royaltyRatePlanGrid.dataSource.data().filter(x => { return x["DeletedDateTime"] == null ? true : false });

            for (let i = 0; i < royaltyRatePlanGridData.length; i++) {

                if (royaltyRatePlanGridData && royaltyRatePlanGridData[i]) {

                    //check missing WIB Start
                    if (royaltyRatePlanGridData[i]['WeeksInBusinessStart'] == null || royaltyRatePlanGridData[i]['WeeksInBusinessStart'] == undefined) {
                        this.validateionPopupMessage("Please enter WIB Start for Royalty Rate Plan", "Error!", true);
                        isRatePlanError = true;
                        break;
                    }

                    //check missing WIB End
                    if (royaltyRatePlanGridData[i]['WeeksInBusinessEnd'] == null || royaltyRatePlanGridData[i]['WeeksInBusinessEnd'] == undefined) {
                        this.validateionPopupMessage("Please enter WIB End for Royalty Rate Plan", "Error!", true);
                        isRatePlanError = true;
                        break;
                    }

                    //check if rate plan has equal WIB start and end
                    if (royaltyRatePlanGridData[i]['WeeksInBusinessStart'] == royaltyRatePlanGridData[i]['WeeksInBusinessEnd']) {
                        this.validateionPopupMessage("WIB Start and WIB End cannot be equal for Royalty Rate Plan", "Error!", true);
                        isRatePlanError = true;
                        break;
                    }

                    //check for upper and lower limit
                    if (royaltyRatePlanGridData[i]['WeeksInBusinessEnd'] < -500 || royaltyRatePlanGridData[i]['WeeksInBusinessEnd'] > 10000) {
                        this.validateionPopupMessage("WIB End should range between -500 to 10000 for Royalty Rate Plan", "Error!", true);
                        isRatePlanError = true;
                        break;
                    }

                    //check for upper and lower limit
                    if (royaltyRatePlanGridData[i]['WeeksInBusinessStart'] < -500 || royaltyRatePlanGridData[i]['WeeksInBusinessStart'] > 10000) {
                        this.validateionPopupMessage("WIB Start should range between -500 to 10000 for Royalty Rate Plan", "Error!", true);
                        isRatePlanError = true;
                        break;
                    }

                    //check if wib start is greater than wib end
                    if (royaltyRatePlanGridData[i]['WeeksInBusinessStart'] > royaltyRatePlanGridData[i]['WeeksInBusinessEnd']) {
                        this.validateionPopupMessage("WIB Start should be less than WIB End for Royalty Rate Plan", "Error!", true);
                        isRatePlanError = true;
                        break;
                    }

                    //Add all WIB range values
                    if (royaltyRatePlanGridData && royaltyRatePlanGridData.length > 1 && royaltyRatePlanGridData[i]) {

                        if (i == 0) {

                            if ((royaltyRatePlanGridData[i]['WeeksInBusinessEnd'] + 1) != royaltyRatePlanGridData[i + 1]['WeeksInBusinessStart']) {
                                this.validateionPopupMessage("WIB should not be skipped or overlapped for Royalty Rate Plan", "Error!", true);
                                isRatePlanError = true;
                                break;
                            }
                        }
                        else if (i > 0 && ((i + 1) < royaltyRatePlanGridData.length)) {

                            if ((royaltyRatePlanGridData[i]['WeeksInBusinessStart'] - 1) != royaltyRatePlanGridData[i - 1]['WeeksInBusinessEnd']) {
                                this.validateionPopupMessage("WIB should not be skipped or overlapped for Royalty Rate Plan", "Error!", true);
                                isRatePlanError = true;
                                break;
                            }

                            if ((royaltyRatePlanGridData[i]['WeeksInBusinessEnd'] + 1) != royaltyRatePlanGridData[i + 1]['WeeksInBusinessStart']) {
                                this.validateionPopupMessage("WIB should not be skipped or overlapped for Royalty Rate Plan", "Error!", true);
                                isRatePlanError = true;
                                break;
                            }
                        }
                        else if (i > 0 && ((i + 1) == royaltyRatePlanGridData.length)) {

                            if ((royaltyRatePlanGridData[i]['WeeksInBusinessStart'] - 1) != royaltyRatePlanGridData[i - 1]['WeeksInBusinessEnd']) {
                                this.validateionPopupMessage("WIB should not be skipped or overlapped for Royalty Rate Plan", "Error!", true);
                                isRatePlanError = true;
                                break;
                            }
                        }
                    }
                }
            }
        }

        if (isRatePlanError) {
            this.isLoading = false;
            return true;
        }

        this.$q.all([
            this.feeScalesController.SaveChanges(),
            this.ratePlansController.SaveChanges(),
            this.ratePlanItemsController.SaveChanges(),
            this.serviceCategoriesController.SaveChanges(),
            //this.ratePlanMinimumItemsController.SaveChanges(),
            this.territoryFeesController.SaveChanges(),
            this.minimumsController.SaveChanges()
        ])
            .then(() => {
                this.rmsState.royaltyDisclosureDocumentStateHandler.Clear(this.fddId);

                this.$timeout(() => {
                    this.formController.$setPristine();
                }, 1000);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("fddRoyalties", {
            bindings: {
                [nameof<fddRoyaltiesComponentController>(o => o.fddId)]: "<",
                [nameof<fddRoyaltiesComponentController>(o => o.readOnly)]: "<",
                [nameof<fddRoyaltiesComponentController>(o => o.royaltyClicked)]: "<",
                [nameof<fddRoyaltiesComponentController>(o => o.init)]: "&?"
            },
            templateUrl: '/Templates/FDDManagement/fddRoyalties.html',
            controller: fddRoyaltiesComponentController
        });
    }
}
