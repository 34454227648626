

interface IHeightToWindowBottomScope extends ng.IScope
{
    useMax: boolean;
}

let heightToWindowBottomDirectiveConfig = [
    '$window', '$timeout',
    ($window: ng.IWindowService, $timeout: ng.ITimeoutService) =>
    {
        return {
            scope: {
                    useMax: '='
            },
            restrict: "A",
            replace: false,
            link: (scope: IHeightToWindowBottomScope, element, attrs) =>
            {
                if (!angular.isDefined(scope.useMax)) {
                    scope.useMax = false;
                }
                var windowEle = angular.element($window);
                var heightType = scope.useMax ? "max-height" : 'height';
                windowEle.resize(() =>
                {
                    element.css(heightType, windowEle.height() - element.offset().top);
                });
                scope.$watch(scope => element.offset().top, (oldVal, newVal) =>
                {
                    $timeout(() =>
                    {
                        element.css(heightType, windowEle.height() - element.offset().top);
                    }, 0);
                });
            }
        };
    }
];
export { heightToWindowBottomDirectiveConfig };