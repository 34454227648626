import { RmsDashboardAdFeeCapsBreachedResultVm } from '@nbly/royalty-orchestrations-clients';

export class rmsDashboardAdFeeCapsBreachedGridComponentController implements ng.IController {

    adFeeCapsBreachedFranchisorGrid: kendo.ui.Grid;
    adFeeCapsBreachedFranchisorGridOptions: kendo.ui.GridOptions;
    adFeeCapsBreachedFranchiseeGrid: { [uid: string]: kendo.ui.Grid };

    static $inject = [
        '$window',
        '$location',
        '$q',
        '$scope',
        "$rootScope",
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private $scope: ng.IScope,
        private $rootScope: ng.IRootScopeService,
    ) {

    }

    $onInit() {
        this.adFeeCapsBreachedFranchiseeGrid = {};
        this.LoadGrid();
        this.$rootScope.$on('adFeeCapsBreachedDataChanged', (event, args) => {
            if (args && args.adFeeCapsBreachedData) {

                let adFeeCapsBreachedResultData: RmsDashboardAdFeeCapsBreachedResultVm[] = args.adFeeCapsBreachedData;
                this.PopulateGrid(adFeeCapsBreachedResultData);
            }
        });

        this.$rootScope.$on('resetGrid', () => {
            this.adFeeCapsBreachedFranchisorGrid.dataSource.data([])
            this.adFeeCapsBreachedFranchisorGrid.refresh();
        });
    }

    PopulateGrid(data: RmsDashboardAdFeeCapsBreachedResultVm[]) {
        this.adFeeCapsBreachedFranchisorGrid.dataSource.data(data)
        this.adFeeCapsBreachedFranchisorGrid.refresh();
    }

    LoadGrid() {

        let adFeeCapsBreachedFranchisorDataSource = new kendo.data.DataSource({
            data: [],
            pageSize: 10,
            schema: {
                model: {
                    id: "franchisorId",
                    fields: {
                        franchisorId: {
                            type: "number"
                        },
                        franchisorName: {
                            type: "string"
                        },
                        franchiseeCount: {
                            type: "number"
                        }
                    }
                }
            }
        });

        let adFeeCapsBreachedFranchisorColumns: Array<kendo.ui.GridColumn> =
            [
                {
                    field: "franchisorId",
                    title: "Franchisor Id"
                },
                {
                    field: "franchisorName",
                    title: "Franchisor Name"
                },
                {
                    field: "franchiseeCount",
                    title: "Count"
                },
            ];

        this.adFeeCapsBreachedFranchisorGridOptions = {
            autoBind: false,
            dataSource: adFeeCapsBreachedFranchisorDataSource,
            columns: adFeeCapsBreachedFranchisorColumns,
            pageable: {
                numeric: false,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
        };
    }

    GetadFeeCapsBreachedFranchiseeGridOptions(data: any): kendo.ui.GridOptions {
        let adFeeCapsBreachedFranchiseeGridDataSource = new kendo.data.DataSource({
            batch: true,
            pageSize: 10,
            schema: {
                model: {
                    id: "licenseNumber",
                    fields: {
                        franchisorId: {
                            type: "string"
                        },
                        licenseNumber: {
                            type: "string"
                        },
                        franchiseeName: {
                            type: "string"
                        },
                        doingBusinessAs: {
                            type: "string"
                        },
                        franchisorAdFeeCap: {
                            type: "number"
                        },
                        contractAdFeeCap: {
                            type: "number"
                        },
                        ytdAdFees: {
                            type: "number"
                        },
                        adFeeCapException: {
                            type: "string"
                        }
                    }
                }
            },
            transport: {
                read: (e: kendo.data.DataSourceTransportReadOptions) => {
                    e.success(data.franchiseeAdFeeCapsBreachedInfoList);
                },
            }
        });

        let adFeeCapsBreachedFranchiseeGridColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "franchisorId",
                title: "Franchisor Id",
                hidden: true
            }, {
                field: "licenseNumber",
                title: "License Number"
            }, {
                field: "franchiseeName",
                title: "Name"
            }, {
                field: "doingBusinessAs",
                title: "DBA"
            }, {
                field: "franchisorAdFeeCap",
                title: "Franchisor Ad Fee Cap",
                format: "{0:n}"
            }, {
                field: "contractAdFeeCap",
                title: "Contract Ad Fee Cap",
                format: "{0:n}"
            }, {
                field: "ytdAdFees",
                title: "Ytd Ad Fees",
                format: "{0:n}"
            }, {
                field: "adFeeCapException",
                title: "Ad Cap Exception"
            }];

        return {
            columns: adFeeCapsBreachedFranchiseeGridColumns,
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            dataSource: adFeeCapsBreachedFranchiseeGridDataSource,
            resizable: true,
            sortable: true,
            scrollable: true,
            filterable: true,
        };
    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsDashboardAdFeeCapsBreachedGrid", {
            bindings: {

            },
            templateUrl: '/Templates/RMSDashboard/RMSDashboardAdFeeCapsBreached/rmsDashboardAdFeeCapsBreachedGrid.html',
            controller: rmsDashboardAdFeeCapsBreachedGridComponentController
        });
    }
}