import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
export class RoyaltyRatePlanTypesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetAllRoyaltyRatePlanTypes()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyRatePlanTypes/"
        };

        return this.$http<RpmEntities.RoyaltyRatePlanTypeViewModel[]>(config);
    }
}