
import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";

import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ApiConfig } from "AppConfig/ApiConfig";
import { IEditDisasterPrepProfileSectionScope } from "@DppApp/EditDisasterPrepProfileSectionController";
import { Helpers } from "Utility/Helpers";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";

interface IEditDisasterPrepProfileBuildingAssetsScope extends IEditDisasterPrepProfileSectionScope {
    allAssets: DppResources.IDisasterPrepProfileBuildingAsset[];
    imageAssets: DppResources.IDisasterPrepProfileBuildingAsset[];
    nonImageAssets: DppResources.IDisasterPrepProfileBuildingAsset[];
    imageExtensions: string[];

    fileUpload: File;
    asyncOptions: kendo.ui.UploadAsync;
    AppSettings: IAppSettings;
    
    OnSelect: (e: kendo.ui.UploadSelectEvent) => void;
    OnUpload: (e: kendo.ui.UploadUploadEvent) => void;
    OnError: (e: kendo.ui.UploadErrorEvent) => void;
    OnSuccess: (e: kendo.ui.UploadSuccessEvent) => void;
    ShowFile: () => void;
    RefreshAssets: () => void;
    OpenDialog: (asset: DppResources.IDisasterPrepProfileBuildingAsset) => void;
    GetIconClass: (asset: string) => string;
    Save: () => ng.IPromise<any>;
}

export class EditDisasterPrepProfileBuildingAssetsController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('EditDisasterPrepProfileBuildingAssetsController',
            [
                '$scope', '$q', "dppApiResources", "$timeout", "ngDialog", "apiConfig",
                ($scope: IEditDisasterPrepProfileBuildingAssetsScope, $q: ng.IQService, dppApiResources: DppApiResources, $timeout: ng.ITimeoutService, ngDialog: any, apiConfig: ApiConfig) =>
                {
                    $scope.imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];

                    $scope.asyncOptions = {
                        saveUrl: `${apiConfig.AppSettings.CoreApiRootUrl}/DisasterPrepProfileBuildingAssets/Upload?dppId=${$scope.dppId}&dppBuildingId=${$scope.dppBuildingId}`,
                        autoUpload: true,
                        withCredentials: false
                    }

                    $scope.RefreshAssets = () =>
                    {
                        let assetQuery = {
                            $select: "DppBuildingAssetId,DppBuildingId,CreatedDateTime,ModifiedDateTime,FileName,FileExtension,FileDescription",
                            $filter: `DppId eq ${$scope.dppId} and (DppBuildingId eq ${$scope.dppBuildingId} or DppBuildingId eq null)`,
                            $orderby: "CreatedDateTime desc"
                        };

                        let promise = dppApiResources.DisasterPrepProfileBuildingAssetApi.query(assetQuery).$promise.then(
                            (assets: DppResources.IDisasterPrepProfileBuildingAsset[]) =>
                            {
                                $scope.allAssets = assets;
                                $scope.imageAssets = $scope.allAssets.filter((val) => { return $scope.imageExtensions.some(ext => Helpers.EndsWith(val.FileExtension.toLowerCase(), ext.toLowerCase())) });
                                $scope.nonImageAssets = $scope.allAssets.filter((val) => { return !$scope.imageExtensions.some(ext => Helpers.EndsWith(val.FileExtension.toLowerCase(), ext.toLowerCase())) });
                                console.log("My Assets Loaded", $scope.allAssets);
                            },
                            (err) => { console.error(err); }
                        );
                        $scope.QueueLoadingPromises(promise);
                        return promise;
                    }

                    $scope.RefreshAssets();

                    $scope.AppSettings = apiConfig.AppSettings;

                    $scope.ShowFile = () =>
                    {
                        console.log($scope.fileUpload);
                    }

                    $scope.OnSelect = (e) =>
                    {
                        console.log("OnSelect", e);
                    }

                    $scope.OnUpload = (e) =>
                    {
                        console.log("OnUpload", e);

                        var xhr: XMLHttpRequest = e.XMLHttpRequest;
                        if (xhr) {
                            xhr.addEventListener("readystatechange", (e: ProgressEvent) =>
                            {
                                if (xhr.readyState === XMLHttpRequest.OPENED) {
                                    xhr.setRequestHeader("Authorization", (<ILocalStorage>localStorage).FranForceAuthTokenType + " " + (<ILocalStorage>localStorage).FranForceAuthToken);
                                }

                                if (xhr.readyState === XMLHttpRequest.DONE) {
                                    if (xhr.status >= 400) {
                                        console.error("Upload error xhr:", xhr);
                                        alert("Error In Upload");
                                    }
                                    $scope.RefreshAssets();
                                }
                            });
                        }
                    }

                    $scope.OnError = (e) =>
                    {
                        console.error("Failed Files Event", e);
                    }

                    $scope.OnSuccess = (e) =>
                    {
                        console.log("Files Created Event", e);
                    }

                    $scope.GetIconClass = (extension: string) =>
                    {
                        if (!extension) {
                            return "";
                        }

                        let iconClass = "fa fa-5x fa-";
                        let audioFileExtensions = [".mp3", ".wav", ".wma", ".m4a", ".m4b", ".dvf", ".aiff", ".aif", ".iff", "m3u", ".mpa"];
                        let videoFileExtensions = [".mp4", ".m4p", ".mpg", ".mpeg", ".webm", ".ogg", ".ogv", ".flv", ".gifv", ".avi", ".wmv"];
                        let archiveFileExtensions = [".zip", ".zipx", ".iso", ".bz2", ".gz", "xz", "7z", ".tgz", ".tar.gz", ".tar.bz2", ".deb", ".pkg"];
                        let documentFileExtensions = [".docx", ".doc", ".rtf", ".tex", ".wpd", ".wps", ".odt"];
                        let spreadsheetFileExtensions = [".xlsx", ".xls", ".csv", ".xlr"];
                        let powerpointFileExtensions = [".ppt", ".pptx", ".pps"];
                        let pdfFileExtensions = [".pdf"];
                        let textFileExtensions = [".txt", ".log", ".json", ".xml"];

                        let lowercaseExtension = extension.toLowerCase();
                        if (audioFileExtensions.some(f => f.toLowerCase() === lowercaseExtension)) {
                            return iconClass + "file-audio-o";
                        }
                        if (videoFileExtensions.some(f => f.toLowerCase() === lowercaseExtension)) {
                            return iconClass + "file-movie-o";
                        }
                        if (archiveFileExtensions.some(f => f.toLowerCase() === lowercaseExtension)) {
                            return iconClass + "file-archive-o";
                        }
                        if (documentFileExtensions.some(f => f.toLowerCase() === lowercaseExtension)) {
                            return iconClass + "file-word-o";
                        }
                        if (spreadsheetFileExtensions.some(f => f.toLowerCase() === lowercaseExtension)) {
                            return iconClass + "file-excel-o";
                        }
                        if (powerpointFileExtensions.some(f => f.toLowerCase() === lowercaseExtension)) {
                            return iconClass + "file-powerpoint-o";
                        }
                        if (textFileExtensions.some(f => f.toLowerCase() === lowercaseExtension)) {
                            return iconClass + "file-text-o";
                        }
                        if (pdfFileExtensions.some(f => f.toLowerCase() === lowercaseExtension)) {
                            return iconClass + "file-pdf-o";
                        }

                        return iconClass + "file-o";
                    };

                    $scope.OpenDialog = (asset: DppResources.IDisasterPrepProfileBuildingAsset) =>
                    {
                        let dialogScope = $scope.$new();
                        angular.extend(dialogScope, {
                            AppSettings: $scope.AppSettings,
                            asset: asset,
                            isImage: $scope.imageAssets.indexOf(asset) > -1,
                            dppId: $scope.dppId,
                            dppBuildingId: $scope.dppBuildingId,
                            GetIconClass: $scope.GetIconClass,
                            Delete: (disasterPrepProfileAssetId: number) =>
                            {
                                if (confirm("Are you sure you want to delete this image?")) {
                                    return dppApiResources.DisasterPrepProfileBuildingAssetApi.delete({ id: disasterPrepProfileAssetId, allowHardDelete: true },
                                        (response) =>
                                        {
                                            ngDialog.close();
                                            $scope.RefreshAssets();
                                        }, (err) => { console.error(err); });
                                }
                            },
                            Save: (asset: DppResources.IDisasterPrepProfileBuildingAsset) =>
                            {
                                return dppApiResources.DisasterPrepProfileBuildingAssetApi.patch({ id: asset.DppBuildingAssetId, extraInt32: "DppBuildingId|" + asset.DppBuildingId }, asset).$promise.then(
                                    () =>
                                    {
                                        ngDialog.close();
                                        $scope.RefreshAssets();
                                    }, (err) =>
                                    {
                                        console.error(err);
                                    });
                            }
                        });

                        ngDialog.open({
                            template: 'fileEditPopup',
                            className: 'ngdialog-theme-default ng-dialog-edit-asset-popup',
                            scope: dialogScope,
                            controller: ['$scope', 'coreApiResources', ($scope, coreApiResources: CoreApiResources) => { }]
                        });
                    };

                    $scope.Save = () =>
                    {
                        //Creates a 'fake' promise that resolves in a second. People may be used to navigating with Save & continue.
                        return $q((resolve, reject) => { setTimeout(() => { resolve() }, 1000); });
                    };
                }
            ]);
    }
}


