import { BillingApiResources } from "Services/Resources/BillingApiResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

type BrochureDropDownItem = {
    display: string;
    serviceCode: string;
}

export class MollyMaidBrochureSubcriptionsComponent implements ng.IController 
{
    selectedFranchiseId: number;
    brochureCount: number;
    brochureServiceCode: string;

    brochureDropDownList: kendo.ui.DropDownList;
    brochureDropDownListOptions: kendo.ui.DropDownListOptions;
    franchiseFilter: kendo.data.DataSourceFilterItem;
    
    static $inject = [
        "billingApiResources"
    ];

    constructor(
        private billingApiResources: BillingApiResources
    ) 
    {

    }

    $onInit()
    {
        this.brochureCount = 1;

        this.InitFranchiseDropDown();
        this.InitBrochureDropDownOptions();
    }

    InitFranchiseDropDown()
    {
        const mlyConceptId = 1;

        this.franchiseFilter = {
            field: nameof<CoreResources.IConcept>(o => o.ConceptId),
            operator: 'eq',
            value: mlyConceptId
        }
    }

    InitBrochureDropDownOptions()
    {
        const dropDownData: BrochureDropDownItem[] = [
                /*  { display: "Estimate Brochure", serviceCode: "MLYVIDBROCH" }, */
            { display: "Cleaning Training Brochure", serviceCode: "MLYVIDBROCH2" },
        ];

        this.brochureServiceCode = dropDownData[0].serviceCode;

        this.brochureDropDownListOptions = {
            valuePrimitive: true,
            dataTextField: nameof<BrochureDropDownItem>(o => o.display),
            dataValueField: nameof<BrochureDropDownItem>(o => o.serviceCode),
            dataSource: new kendo.data.DataSource({
                data: dropDownData
            })
        };
    }

    Submit()
    {
        return this.billingApiResources.CreateMollyMaidVideoBrochureSubscription({
            FranchiseId: this.selectedFranchiseId,
            Quantity: this.brochureCount,
            ServiceCode: this.brochureServiceCode
        })
            .then(() => {
                alert("Your subscription request is successful!");
                window.location.href = "/";
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("mollyMaidBrochureSubcriptions",
            {
                bindings: {

                },
                controller: MollyMaidBrochureSubcriptionsComponent,
                templateUrl: "/Templates/Billing/MollyMaidBrochureSubscriptions.html"
            });
    }
}