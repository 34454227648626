import { RmsConfirmationDialogComponentController } from "Directives/RPM/Common/rmsConfirmationDialog";
import { rmsKeyValueGridComponentController } from "Directives/RPM/Common/rmsKeyValueGridController";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RmsStateService } from "Services/State/RmsState";
import * as _ from "underscore"
import * as moment from "moment"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { fddRollInRanges, fddRollInRangesOnInit } from "Directives/RPM/FddManagement/fddRollInRanges";
import { RmsFindScaleBasedOnTypesSelectorOnSelectParams } from "Directives/RPM/Common/RmsFindScaleBasedOnTypesSelector";
import { RmsMethodologyTypeSelectorOnSelectParams } from "Directives/RPM/Common/rmsMethodologyTypeSelector";
import { AngularUtil } from "../../../Utility/AngularUtil";
import { RoyaltyApiResources } from "../../../Services/Resources/RoyaltyApiResources";
import { RmsRoyaltyScaleCalculationSelectorOnSelectParams } from "../Common/rmsRoyaltyScaleCalculationSelector";
import { RmsRoyaltySummaryInvoiceDisplayTypeSelectorOnSelectParams } from "../Common/rmsRoyaltySummaryInvoiceDisplayTypeSelector";

export class fddGeneralComponentController implements ng.IController {

    fddId: number;
    readOnly: boolean;
    isDynamicFees: boolean;
    init: (params: { self: fddGeneralComponentController }) => void;

    isLoading: boolean;
    disclosureDocument: RpmEntities.DisclosureDocumentViewModel;
    isMethodologyChangeable: boolean;

    dialogController: RmsConfirmationDialogComponentController;
    formController: angular.IFormController;
    keyValueController: rmsKeyValueGridComponentController;
    rollInRangesController: fddRollInRanges;
    kvpGridDataSource: kendo.data.DataSource;
    kvpDropDownDataSource: kendo.data.DataSource;

    static $inject = [
        '$window',
        '$timeout',
        'rpmUiApiResources',
        'rpmHelper',
        'rmsState',
        'royaltyApiResources'
    ];

    constructor(
        private $window: ng.IWindowService,
        private $timeout: ng.ITimeoutService,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private rmsState: RmsStateService,
        private royaltyApiResources: RoyaltyApiResources
    ) {

    }

    $onInit() {
        if (this.init) {
            this.init({ self: this });
        }
        
        this.LoadDocument();
        this.InitKeyValueDataSource();
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledFdd').val() === 'True';
    }

    $postLink() {

    }

    InitKeyValueDataSource() {
        this.kvpGridDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof<RpmEntities.RoyaltyDisclosureDocumentKeyValuePairViewModel>(o => o.RoyaltyDisclosureDocumentKeyValuePairId),
                    fields: {
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentKeyValuePairViewModel>(o => o.RoyaltyKeyValuePairId)]: {
                            validation: {
                                required: true,
                                duplicatevalidation: (input: JQuery<HTMLInputElement>) => {
                                    let kvpId = Number.parseInt(<string>input.val());

                                    let matchingKvps = this.kvpGridDataSource.data()
                                        .filter((kvp: RpmEntities.RoyaltyDisclosureDocumentKeyValuePairViewModel) => {
                                            return kvp.RoyaltyKeyValuePairId == kvpId;
                                        });

                                    input.attr("data-duplicatevalidation-msg", "Key has already been selected.");

                                    console.log(kvpId, matchingKvps, input);
                                    return (matchingKvps.length < 2);
                                }
                            }
                        },
                    }
                }
            },
            transport: {
                read: (options) => {
                    if (!this.fddId) {
                        options.success([]);
                        return;
                    }

                    this.rpmUiApiResources.GetDisclosureDocumentKeyValuePairsByDisclosureDocument(this.fddId)
                        .then((kvResponse) => {
                            options.success(_.filter(kvResponse.data, (kvp) => {
                                return kvp.DeletedDateTime == null;
                            }));
                        })
                        .catch((err) => {
                            options.error(err);
                        })
                },
                create: (options) => {
                    let createModel: RpmEntities.RoyaltyDisclosureDocumentKeyValuePairViewModel = options.data;

                    createModel.CreatedDateTime = new Date().toDateString();
                    createModel.RoyaltyDisclosureDocumentId = this.fddId;

                    this.rpmUiApiResources.CreateDisclosureDocumentKeyValuePair(createModel)
                        .then((response) => {
                            createModel.RoyaltyDisclosureDocumentKeyValuePairId = response.data;
                            options.success({
                                [nameof<RpmEntities.RoyaltyDisclosureDocumentKeyValuePairViewModel>(o => o.RoyaltyDisclosureDocumentKeyValuePairId)]: createModel.RoyaltyDisclosureDocumentKeyValuePairId
                            });
                        })
                        .catch((err) => {
                            options.error(err);
                        })
                },
                update: (options) => {
                    let updateModel: RpmEntities.RoyaltyDisclosureDocumentKeyValuePairViewModel = options.data;

                    updateModel.UpdatedDateTime = new Date().toDateString();

                    this.rpmUiApiResources.UpdateDisclosureDocumentKeyValuePair(updateModel.RoyaltyDisclosureDocumentKeyValuePairId, updateModel)
                        .then((response) => {
                            options.success();
                        })
                        .catch((err) => {
                            options.error(err);
                        })
                },
                destroy: (options) => {
                    let deleteModel: RpmEntities.RoyaltyDisclosureDocumentKeyValuePairViewModel = options.data;

                    deleteModel.DeletedDateTime = new Date().toISOString();

                    this.rpmUiApiResources.UpdateDisclosureDocumentKeyValuePair(deleteModel.RoyaltyDisclosureDocumentKeyValuePairId, deleteModel)
                        .then((response) => {
                            options.success();
                        })
                        .catch((err) => {
                            options.error(err);
                        })
                }
            },
            change: function (e) {
                var data = e.sender.data();
                if (data && data.length > 0) {
                    var kvpArr = data.map(function (item) { return item['RoyaltyKeyValuePairId'] });

                    var isDuplicate = kvpArr.some((val, i) => kvpArr.indexOf(val) !== i);
                    let validationError = isDuplicate;

                    var saveBtn = angular.element(document.querySelector('#saveBtn'));
                    var saveGeneral = angular.element(document.querySelector('#saveGeneralBtn'));
                    var saveAll = angular.element(document.querySelector('#saveAllBtn'));

                    if (validationError) {
                        saveBtn.attr('disabled', 'disabled');
                        saveGeneral.attr('disabled', 'disabled');
                        saveAll.attr('disabled', 'disabled');
                    }
                    else if (!this.readOnly) {
                        saveBtn.removeAttr("disabled");
                        saveGeneral.removeAttr("disabled");
                        saveAll.removeAttr("disabled");
                    }
                }
            }.bind(this)
        });

        this.kvpDropDownDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result>(o => o.RoyaltyKeyValuePairId),
                    fields: {
                        [nameof<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result>(o => o.RoyaltyKeyValuePairId)]: {},
                        [nameof<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result>(o => o.KeyName)]: {},
                        [nameof<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result>(o => o.Description)]: {},
                        [nameof<RpmEntities.usp_RoyaltyKeyValuePairs_GetById_Result>(o => o.DataTypeId)]: {},
                    }
                }
            },
            transport: {
                read: (options) => {
                    this.rpmUiApiResources.GetAllRoyaltyKeyValuePairs()
                        .then((pairsResponse) => {
                            options.success(pairsResponse.data);
                        })
                        .catch((err) => { options.error(err); })
                }
            }
        });
    }

    OpenCopyToNewDocPopup() {
        this.dialogController.Open({
            title: '',
            message: `To copy to a new Franchise Disclosure Document you must save first. Do you want to save and then copy this disclosure document?`,
            actions: [
                {
                    name: "Yes",
                    cssClass: "pure-button btn-accept",
                    event: (e) => {
                        this.Save()
                            .then(() => {
                                return this.CopyFDD();
                            });
                    }
                }, {
                    name: "Cancel",
                    cssClass: "pure-button btn-cancel",
                    event: (e) => {

                    }
                },
            ]
        });
    }

    InitRollInRangesController(controller: fddRollInRangesOnInit["self"]) {
        this.rollInRangesController = controller;
        this.rollInRangesController.LoadGrid(this.fddId);
    }

    OnMethodolgySelect(methodology: RmsMethodologyTypeSelectorOnSelectParams["methodologyType"]) {
        if (this.disclosureDocument.RoyaltyMethodologyTypeId != methodology?.RoyaltyMethodologyTypeId) {
            this.formController.$setDirty();
        }
        this.disclosureDocument.RoyaltyMethodologyTypeId = methodology?.RoyaltyMethodologyTypeId;
    }

    OnFindScaleSelect(findScale: RmsFindScaleBasedOnTypesSelectorOnSelectParams["findScale"]) {
        if (this.disclosureDocument.FindScaleBasedOnId != findScale?.FindScaleBasedOnId) {
            this.formController.$setDirty();
        }

        this.disclosureDocument.FindScaleBasedOnId = findScale?.FindScaleBasedOnId;
    }

    OnRoyaltyScaleCalculationSelect(royaltyScaleCalculation: RmsRoyaltyScaleCalculationSelectorOnSelectParams["royaltyScaleCalculation"]) {
        if (this.disclosureDocument.RoyaltyScaleCalculationTypeId != royaltyScaleCalculation?.royaltyScaleCalculationTypeId) {
            this.formController.$setDirty();
        }

        this.disclosureDocument.RoyaltyScaleCalculationTypeId = royaltyScaleCalculation?.royaltyScaleCalculationTypeId;
    }

    OnRoyaltySummaryInvoiceDisplayTypeSelect(royaltySummaryInvoiceDisplayType: RmsRoyaltySummaryInvoiceDisplayTypeSelectorOnSelectParams["royaltySummaryInvoiceDisplayType"]) {
        if (this.disclosureDocument.RoyaltySummaryInvoiceDisplayTypeId != royaltySummaryInvoiceDisplayType?.royaltySummaryInvoiceDisplayTypeId) {
            this.formController.$setDirty();
        }

        this.disclosureDocument.RoyaltySummaryInvoiceDisplayTypeId = royaltySummaryInvoiceDisplayType?.royaltySummaryInvoiceDisplayTypeId;
    }

    async Cancel() {
        return await Promise.all([
            this.LoadDocument(),
            Promise.resolve(this.keyValueController.CancelChanges()),
            Promise.resolve(this.rollInRangesController.CancelChanges())
        ]);
    }

    HasUnsavedChanges() {
        return (
            this.formController.$dirty ||
            this.keyValueController?.HasChanges() ||
            this.rollInRangesController?.HasChanges()
        );
    }

    async LoadDocument() {
        return await AngularUtil.TrackLoadingPromise((async () => {
            this.disclosureDocument = await this.rmsState.royaltyDisclosureDocumentStateHandler.Load(this.fddId, this.isDynamicFees);
            this.isMethodologyChangeable = moment(new Date()).diff(this.disclosureDocument.CreatedDateTime, "days", true) < 7;
        })(), this)
            .catch(this.rpmHelper.DefaultCatch());
    }

    CopyFDD() {
        if (this.isDynamicFees) {
            return this.royaltyApiResources.CopyDisclosureDocumentDynamic(this.fddId)
                .then((newDocResponse => {
                    if (newDocResponse && newDocResponse.data) {
                        this.$window.location.href = `/FddManagement/Edit#?${nameof(this.fddId)}=${newDocResponse.data.disclosureDocumentId}`;
                        this.$window.location.reload();
                    }
                    else
                        console.log("Error fetching new FDD Id.");
                }))
                .catch(() => {
                    alert("Error while copying disclosure document.");
                });
        }
        else {
            return this.royaltyApiResources.CopyDisclosureDocument(this.fddId)
                .then((newDocResponse => {
                    if (newDocResponse && newDocResponse.data)
                        this.$window.location.href = `/FddManagement/Edit#?${nameof(this.fddId)}=${newDocResponse.data.disclosureDocumentId}`;
                    else
                        console.log("Error fetching new FDD Id.");
                }))
                .catch(() => {
                    alert("Error while copying disclosure document.");
                });
        }
    }

    async Save() {
        return AngularUtil.TrackLoadingPromise((async () => {
            await this.keyValueController.SaveChanges();
            await this.rollInRangesController.SaveChanges();
            if (this.isDynamicFees) {
                await this.rpmUiApiResources.UpdateDisclosureDocumentDynamic(
                    this.disclosureDocument.DisclosureDocumentId,
                    this.disclosureDocument
                );
            }
            else {
                await this.rpmUiApiResources.UpdateDisclosureDocument(
                    this.disclosureDocument.DisclosureDocumentId,
                    this.disclosureDocument
                );
            }
            await this.keyValueController.ResetChanges();

            this.rmsState.royaltyDisclosureDocumentStateHandler.Clear(this.fddId);

            this.$timeout(() => {
                this.formController.$setPristine();
            }, 1000);


        })(), this).then(() => this.LoadDocument())
            .catch(this.rpmHelper.DefaultCatch());
    }

    static BindComponent(app: ng.IModule) {
        app.component("fddGeneral", {
            bindings: {
                [nameof<fddGeneralComponentController>(o => o.fddId)]: "<",
                [nameof<fddGeneralComponentController>(o => o.readOnly)]: "<",
                [nameof<fddGeneralComponentController>(o => o.init)]: "&?"
            },
            templateUrl: '/Templates/FDDManagement/fddGeneral.html',
            controller: fddGeneralComponentController
        });
    }
}