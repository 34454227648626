import { ODataHelperService } from "../Utility/ODataHelperService";
import { ApiConfig } from "../../AppConfig/ApiConfig";
import { SyncTechnicianDataModel } from "../../Models/Common/SyncTechnicianDataModel";
import { SyncEmployeesByFranchisesMultiSelectorDataModel } from "../../Models/Common/SyncEmployeesByFranchisesMultiSelectorDataModel";
import { FranchiseeManagementPosResult } from "Models/FmPos/FranchiseeManagementPosResult";


export class FranchiseOrchestrationsApiResources {

    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    ) {

    }

    GenerateUUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    };

    GetSyncTechniciansByFranchiseIds(franchiseIds: number[]) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.FranchiseOrchestrationsRootUrl + "/protected/franchises/sync-technicians/franchise_ids",
            data: franchiseIds,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<SyncTechnicianDataModel[]>(config);
    }

    GetSyncEmployeesByFranchiseIds(franchiseIds: number[]) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.FranchiseOrchestrationsRootUrl + "/protected/franchises/sync-employees/franchise_ids",
            data: franchiseIds,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<SyncEmployeesByFranchisesMultiSelectorDataModel[]>(config);
    }

    GetFranchiseeManagementPosFields(franchiseId: number) {
        let URL = `${this.apiConfig.AppSettings.FranchiseOrchestrationsRootUrl}/protected/franchises/${franchiseId}/franchisee-management-pos-fields`;      
        let config: ng.IRequestConfig = {
            method: "GET",
            url: URL, 
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };
        return this.$http<FranchiseeManagementPosResult>(config);
    }
}