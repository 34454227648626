import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";

export class AccountClient
{
    private client = FranForceAxiosClientBuilder.BuildIdentityBaseClient();

    async GetLoggedInUserInfo()
    {
        //Updating PassWordExpirationDate In LocalStorage 
        let res = await this.client.get<IUserInfoViewModel>("/Account/UserInfo/");
        (<ILocalStorage>localStorage).PassWordExpirationDate=res.data.PassWordExpirationDate;
        return res;
    }
}

