import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import * as _ from "underscore"
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

export class FranchiseProfileServicesComponentController implements ng.IController
{
    franchiseId: number;

    services: CoreEntities.Service[];
    serviceSkus: CoreResources.IServiceSku[];
    conceptServices: CoreResources.IConceptServiceSku[]; 

    residentialServiceSku: { [id: number]: CoreResources.IServiceSku };
    commercialServiceSku: { [id: number]: CoreResources.IServiceSku };
    autoServiceSku: { [id: number]: CoreResources.IServiceSku };

    franchiseOffersService: Array<boolean>;

    static $inject = [
        'coreApiResources',
        '$q',
        'odataHelper'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private odataHelper: ODataHelperService
    )
    {

    }

    $onInit()
    {
        this.residentialServiceSku = {};
        this.commercialServiceSku = {};
        this.autoServiceSku = {};
        this.RefreshConceptAndFranchiseServices();
    }

    GetServiceSku(serviceId: number, serviceSkuType: string)
    {
        for (let serviceSku of this.serviceSkus) {
            if (serviceSku.ServiceId === serviceId &&
                serviceSku.ServiceType.toLowerCase() === serviceSkuType.toLowerCase()) {
                return serviceSku;
            }
        }
        return null;
    }

    RefreshConceptAndFranchiseServices()
    {
        this.coreApiResources.FranchiseApi.get({ id: this.franchiseId }).$promise
            .then((franchise) =>
            {
                return franchise.ConceptId;
            })
            .then((conceptId) =>
            {
                return this.coreApiResources.ServiceSkuApi.query({
                    $filter: `ConceptServiceSkus/any(css: css/ConceptId eq ${conceptId})` +
                        ` or FranchiseServiceSkus/any(fss: fss/FranchiseId eq ${this.franchiseId})`,
                    $expand: 'Service,' +
                        `ConceptServiceSkus($filter=ConceptId eq ${conceptId}),` +
                        `FranchiseServiceSkus($filter=FranchiseId eq ${this.franchiseId})`,
                    $orderby: "Service/Name,ServiceType"
                }).$promise
            })
            .then((serviceSkus) =>
            {
                this.serviceSkus = serviceSkus;
                this.services = [];
                this.franchiseOffersService = [];
                for (let s of this.serviceSkus) {
                    this.services.push(s.Service);
                }
                this.services = _.uniq(this.services, false, i => i.ServiceId);
            });
    }

    ToggleFranchiseProfileService(serviceSkuId: number)
    {
        this.coreApiResources.FranchiseServiceSkuApi.get({
            id: this.odataHelper.ConvertCompositeKeyToString({
                FranchiseId: this.franchiseId,
                ServiceSkuId: serviceSkuId
            }), $expand: "FranchiseProfileContactsFranchiseServiceSkus"
        }).$promise
            .then((foundProfileService: CoreResources.IFranchiseServiceSku) =>
            {
                if (foundProfileService.FranchiseProfileContactsFranchiseServiceSkus.length <= 0 ||
                    confirm("This will remove services from your contacts. Do you still want to remove this service?")) {

                    return foundProfileService.$delete({
                        id: this.odataHelper.ConvertCompositeKeyToString({
                            FranchiseId: foundProfileService.FranchiseId,
                            ServiceSkuId: foundProfileService.ServiceSkuId
                        }), allowHardDelete: true
                    }).finally(() =>
                    {
                        return this.RefreshConceptAndFranchiseServices();
                    });
                }
            },
            (err) =>
            {
                if (err.status === 404) {
                    var newProfileServiceRelation = new this.coreApiResources.FranchiseServiceSkuApi();
                    newProfileServiceRelation.FranchiseId = this.franchiseId;
                    newProfileServiceRelation.ServiceSkuId = serviceSkuId;

                    newProfileServiceRelation.$save({}).finally(
                        () => { this.RefreshConceptAndFranchiseServices(); }); 
                }
                else {
                    alert("There was an issue checking for this franchise service relation.");
                    this.RefreshConceptAndFranchiseServices();
                }
            }
        );
    }

    FilterPriorityService(sku: CoreEntities.ServiceSku): boolean
    {
        return (sku.ConceptServiceSkus && sku.ConceptServiceSkus.length > 0 && sku.ConceptServiceSkus[0].IsPriority);
    }

    FilterRegularService(sku: CoreEntities.ServiceSku): boolean
    {
        return !this.FilterPriorityService(sku);
    };

    static BindComponent(app: ng.IModule)
    {
        app.component("franchiseProfileServices", {
            bindings: {
                franchiseId: "<"
            },
            controller: FranchiseProfileServicesComponentController,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfileServices.html"
        });
    }
}


        