import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { UrlQueryParameters } from "Utility/UrlQueryParameters";

export class FranchiseeEmailMetricsReportController implements ng.IController {

    franchiseAccountCode: string;
    
    static $inject = [
        "$scope",
        "coreApiResources",
        "$window",
        "$q",
        "$log"
    ];

    constructor(
        private $scope: ng.IScope,
        private coreApiResources: CoreApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService
    )
    {

    }

    $onInit() {
        this.franchiseAccountCode = new UrlQueryParameters(window).GetValue("AccountCode");
    }

    RunReport(accountCode: string)
    {
        this.$window.location.href = "/Reports/FranchiseeEmailMetricsReport?AccountCode=" + accountCode;
    }
}
