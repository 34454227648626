import { LegalApiResources } from "Services/Resources/LegalApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { Helpers } from "Utility/Helpers";
import { LegalEntities } from "Interfaces/FranForce/Legal/LegalResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

interface IEditSectionOneScope extends ng.IScope {
    contractId: string;
    franchiseId: string;
    selectionDetails: { franchiseID: string, contractID: string };
    activeSection: { section: string }
    monetaryItemOptions: LegalEntities.MonetaryItem[];
    operationItemOptions: LegalEntities.OperationItem[];
    reportingItemOptions: LegalEntities.ReportingItem[];
    timeScales: LegalEntities.TimeScale[];
    model: LegalEntities.SectionOne;
    MonetaryItem: number[];
    OriginalMonetaryItems: number[];
    ReportingItem: number[];
    OriginalReportingItems: number[];
    OperationItem: number[];
    OperationOtherFlag: boolean;
    OperationOtherComment: string;
    OriginalOperationItems: number[]
    SubtotalFees: () => number;
    PromissoryNotes: LegalEntities.SectionOnePromissoryNote[];
    promissoryNotesGridOptions: kendo.ui.GridOptions;
    sectionOneApprovedDateOptions: kendo.ui.DatePickerOptions;
    editSectionOneForm: any;
    submitSectionOne: (any) => void;
    promissoryFees: (any) => void;
    onOperationMultiSelectChange: (any) => void;
    onOverrideClick: (arg: string) => void;
    addOverrideInfo: (comment: string) => void;
    gridDataSource: kendo.data.DataSource;
    cancelSectionOne: (any) => void;
    isItt: boolean;
    kNotifierOptions: any;
    kNotifier: kendo.ui.Notification;
    saveSuccessful: boolean;
    saveBtnDisabled: boolean;
    denyBtnDisabled: boolean;
    userEmail: string;
    currentlyOverriding: string;
    initialMinimumFee: number;
    initialReportingFee: number;
    initialEstimateFee: number;
    initialSoftwareFee: number;
    initialNSFFee: number;
    initialLateFee: number;
    initialMinimumFeeUser: string;
    initialReportingFeeUser: string;
    initialEstimateFeeUser: string;
    initialSoftwareFeeUser: string;
    initialNSFFeeUser: string;
    initialLateFeeUser: string;
    isCheckChanging: boolean;
    initialMinimumOverride: boolean;
    initialReportingOverride: boolean;
    initialEstimateOverride: boolean;
    initialSoftwareOverride: boolean;
    initialNSFOverride: boolean;
    initialLateOverride: boolean;
}

export class EditSectionOneController implements ng.IController {

    static $inject = [
        "$scope",
        "$window",
        "$q",
        "$log",
        "$http",
        "legalApiResources",
        "coreApiResources",
        "identityManager",
        "ngDialog"
    ];

    constructor(
        private $scope: IEditSectionOneScope,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $http: ng.IHttpService,
        private legalApiResources: LegalApiResources,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private ngDialog: any
    )
    {
        $scope.saveBtnDisabled = false;
        $scope.denyBtnDisabled = false;
    }

    $onInit() {
        let $scope = this.$scope;
        let $http = this.$http;
        let $q = this.$q;
        let legalApiResources = this.legalApiResources;
        let identityManager = this.identityManager;
        let columns: Array<kendo.ui.GridColumn> =
            [{
                field: "NoteNumber",
                title: "Note Number",
                width: "130px"
            }, {
                    field: "PromissoryNoteDate",
                    title: "Note Date",
                    template: "#= kendo.toString(kendo.parseDate(PromissoryNoteDate, 'yyyy-MM-dd'), 'MM/dd/yyyy') #",
                    width: "130px"
                }, {
                    field: "OriginalPrincipalAmount",
                    title: "Original Principal Amount",
                    format: "{0:c2}"
                }, {
                    field: "AmountToCureArrearage",
                    title: "Amount to Cure Arrearage",
                    format: "{0:c2}"
                }, {
                    field: "OutstandingBalance",
                    title: "Outstanding Balance",
                    format: "{0:c2}"
                }];

        // Assign selection details to retrieve associated data
        $scope.franchiseId = location.pathname.split("/")[3] || $scope.selectionDetails.franchiseID;
        $scope.contractId = location.pathname.split("/")[4] || $scope.selectionDetails.contractID;

        identityManager.GetLoggedInUserInfo().then(
            (success) => {
                $scope.userEmail = success.data.Email;
            },
            (error) => {
                console.log(error);
            });

        this.coreApiResources.FranchiseApi.query({ $expand: "Concept", $filter: "FranchiseId eq " + $scope.franchiseId },
            (successResponse: CoreEntities.Franchise[]) => {
                $scope.$emit("setFranchiseDetails", {
                    FranchiseName: successResponse[0].Concept.DisplayName,
                    LicenseNum: successResponse[0].LicenseNumber,
                    FranchiseeCode: $scope.franchiseId,
                    FranchiseAddress: successResponse[0].Address,
                    FranchisePhoneNumber: successResponse[0].StorePhone,
                    FranchiseEmail: successResponse[0].StoreEmail
                });
            },
            errorResponse => {
                console.log(errorResponse);
            });

        $scope.gridDataSource = new kendo.data.DataSource({
            data: [],
            schema: {
                model: {
                    fields: {
                        NoteNumber: { type: "number" },
                        PromissoryNoteDate: { type: "date" },
                        OriginalPrincipalAmount: { type: "number" },
                        AmountToCureArrearage: { type: "number" },
                        OutstandingBalance: { type: "number" }
                    }
                }
            },
            pageSize: 10
        });

        $scope.promissoryNotesGridOptions = {
            dataSource: $scope.gridDataSource,
            sortable: true,
            pageable: true,
            columns: columns,
            editable: true
        }


        $scope.SubtotalFees = function () {
            if ($scope.model != undefined) {
                return $scope.model.MinimumFees + $scope.model.ReportingFees +
                    $scope.model.EstimatedFees + $scope.model.SoftwareFees + $scope.model.NSFFees +
                    $scope.model.LateFees + $scope.model.OtherFees1 + $scope.model.OtherFees2 + $scope.model.OtherFees3;
            }

            return 0;
        }

        $scope.promissoryFees = function () {
            if ($scope.gridDataSource != undefined) {
                var fees = 0;
                $scope.gridDataSource.data().toJSON().forEach(function (value: LegalEntities.SectionOnePromissoryNote) {
                    if ($scope.isItt == true) {
                        fees += value.AmountToCureArrearage;
                    } else {
                        fees += value.OutstandingBalance;
                    }
                });

                return fees;
            }

            return 0;
        }

        $scope.submitSectionOne = () => {
            if ($scope.editSectionOneForm.$valid) {
                finishSectionOne(true);
            } else {
                $scope.saveSuccessful = false;
                $scope.kNotifier.show({
                    kValue: "Some field(s) require input to proceed "
                }, "ngTemplate");

                Object.keys($scope.editSectionOneForm.$error).forEach(function (value) {
                    $scope.editSectionOneForm.$error[value].forEach(function (item) {
                        item.$setDirty();
                    });
                });
            }
        };
        $scope.onOperationMultiSelectChange = () => {
            let boolFound = false;
            
            for (let item of $scope.OperationItem) {
                if (item == 5) {
                    boolFound = true;
                }
            }

            $scope.OperationOtherFlag = boolFound;
        }
        $scope.cancelSectionOne = () => {
            finishSectionOne(false);
        };

        this.$scope.isCheckChanging = false;

        $scope.onOverrideClick = (arg: string) => {
            if (!this.$scope.isCheckChanging) {
                this.$scope.isCheckChanging = true;
                let checkBoxId = "cbx" + arg + "Override";
                let val = <HTMLInputElement>document.getElementById(checkBoxId);
                if (val.checked != true) {
                    switch (arg) {
                        case 'Minimum':
                            this.$scope.model.MinimumFees = this.$scope.initialMinimumFee;
                            this.$scope.model.MinimumFeesOverrideComment = null;
                            break;
                        case 'Reporting':
                            this.$scope.model.ReportingFees = this.$scope.initialReportingFee;
                            this.$scope.model.ReportingFeesOverrideComment = null;
                            break;
                        case 'Estimate':
                            this.$scope.model.EstimatedFees = this.$scope.initialEstimateFee;
                            this.$scope.model.EstimatedFeesOverrideComment = null;
                            break;
                        case 'Software':
                            this.$scope.model.SoftwareFees = this.$scope.initialSoftwareFee;
                            this.$scope.model.SoftwareFeesOverrideComment = null;
                            break;
                        case 'NSF':
                            this.$scope.model.NSFFees = this.$scope.initialNSFFee;
                            this.$scope.model.NSFFeesOverrideComment = null;
                            break;
                        case 'Late':
                            this.$scope.model.LateFees = this.$scope.initialLateFee;
                            this.$scope.model.LateFeesOverrideComment = null;
                            break;
                    }
                    this.$scope.isCheckChanging = false;
                    return;
                }
                this.$scope.currentlyOverriding = arg;
                switch (arg) {
                    case 'Minimum':
                        this.$scope.model.MinimumFeesOverride = this.$scope.initialMinimumOverride;
                        break;
                    case 'Reporting':
                        this.$scope.model.ReportingFeesOverride = this.$scope.initialReportingOverride;
                        break;
                    case 'Estimate':
                        this.$scope.model.EstimatedFeesOverride = this.$scope.initialEstimateOverride;
                        break;
                    case 'Software':
                        this.$scope.model.SoftwareFeesOverride = this.$scope.initialSoftwareOverride;
                        break;
                    case 'NSF':
                        this.$scope.model.NSFFeesOverride = this.$scope.initialNSFOverride;
                        break;
                    case 'Late':
                        this.$scope.model.LateFeesOverride = this.$scope.initialLateOverride;
                        break;
                }
                this.$scope.isCheckChanging = false;

                this.ngDialog.open({
                    template: 'SectionOneOverrideModalTemplate',
                    className: 'ngdialog-theme-default itt-modal',
                    scope: this.$scope,
                    controller: ['$scope', ($scope) => { }]
                });
            }
        };

        $scope.addOverrideInfo = (comment: string) => {
            if (comment == null || comment == "") {
                alert("A comment must be provided in order to override the amount.");
                return;
            }

            switch (this.$scope.currentlyOverriding) {
                case 'Minimum':
                    if (comment != null && comment != "") {
                        this.$scope.model.MinimumFeesOverrideComment = comment;
                        this.$scope.model.MinimumFeesOverride = true;
                    }
                    break;
                case 'Reporting':
                    if (comment != null && comment != "") {
                        this.$scope.model.ReportingFeesOverrideComment = comment;
                        this.$scope.model.ReportingFeesOverride = true;
                    }
                    break;
                case 'Estimate':
                    if (comment != null && comment != "") {
                        this.$scope.model.EstimatedFeesOverrideComment = comment;
                        this.$scope.model.EstimatedFeesOverride = true;
                    }
                    break;
                case 'Software':
                    if (comment != null && comment != "") {
                        this.$scope.model.SoftwareFeesOverrideComment = comment;
                        this.$scope.model.SoftwareFeesOverride = true;
                    }
                    break;
                case 'NSF':
                    if (comment != null && comment != "") {
                        this.$scope.model.NSFFeesOverrideComment = comment;
                        this.$scope.model.NSFFeesOverride = true;
                    }
                    break;
                case 'Late':
                    if (comment != null && comment != "") {
                        this.$scope.model.LateFeesOverrideComment = comment;
                        this.$scope.model.LateFeesOverride = true;
                    }
                    break;
            }
            this.ngDialog.close();

        }
        
        $scope.kNotifierOptions = {
            position: {
                top: 50,
                left: $(window).width() / 2
            },
            templates: [{
                type: 'ngTemplate',
                template: $('#kNTemplate').html(),
            }]
        };

        function finishSectionOne(isAccepted: boolean) {
            $scope.saveBtnDisabled = true;
            $scope.denyBtnDisabled = true;
            let monetaryItemDiff = Helpers.NumberArrayDifference($scope.OriginalMonetaryItems, $scope.MonetaryItem);
            let addMonetaryItems = monetaryItemDiff.add;
            let removeMonetaryItems = monetaryItemDiff.remove;

            let operationItemDiff = Helpers.NumberArrayDifference($scope.OriginalOperationItems, $scope.OperationItem);
            let addOperationItems = operationItemDiff.add;
            let removeOperationItems = operationItemDiff.remove;

            let updatingOperationComment = false;

            if ($scope.OperationItem.indexOf(5) > -1 && $scope.OriginalOperationItems.indexOf(5) > -1)
            {
                updatingOperationComment = true;
            }

            let reportingItemDiff = Helpers.NumberArrayDifference($scope.OriginalReportingItems, $scope.ReportingItem);
            let addReportingItems = reportingItemDiff.add;
            let removeReportingItems = reportingItemDiff.remove;

            if (isAccepted) {
                $scope.model.ApprovedName = $scope.userEmail;
                $scope.model.ApprovedDate = kendo.toString(new Date(), "MM/dd/yyyy");
            }

            legalApiResources.SectionOneApi.update({ id: $scope.model.SectionOneID }, $scope.model, (sectionOneResponse: LegalEntities.SectionOne) => {
                let promises = [];
                if (addMonetaryItems.length > 0) {
                    addMonetaryItems.forEach(function (value) {
                        promises.push(legalApiResources.SectionOneMonetaryItemApi.save({ SectionOneID: sectionOneResponse.SectionOneID, MonetaryItemID: value }, successResponse => {

                        }, errorResponse => {
                            console.log(errorResponse);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        }).$promise);
                    });
                }

                if (removeMonetaryItems.length > 0) {
                    removeMonetaryItems.forEach(function (value) {
                        promises.push(legalApiResources.SectionOneMonetaryItemApi.query({ $filter: "SectionOneID eq " + sectionOneResponse.SectionOneID + " and MonetaryItemID eq " + value },
                            (successResponse: LegalEntities.SectionOneMonetaryItem[]) => {
                                promises.push(legalApiResources.SectionOneMonetaryItemApi.delete({
                                    id: successResponse[0].SectionOneMonetaryItemID, allowHardDelete: true
                                }, deleteCallback => {

                                }, errorCallback => {
                                    $scope.saveBtnDisabled = false;
                                    $scope.denyBtnDisabled = false;
                                }).$promise);
                            }, errorResponse => {
                                console.log(errorResponse);
                                $scope.saveBtnDisabled = false;
                                $scope.denyBtnDisabled = false;
                            }).$promise);
                    });
                }

                if (addOperationItems.length > 0) {
                    addOperationItems.forEach(function (value) {
                        let comment = "";
                        if (value == 5) {
                            comment = $scope.OperationOtherComment;
                        }
                        promises.push(legalApiResources.SectionOneOperationItemApi.save({ SectionOneID: sectionOneResponse.SectionOneID, OperationItemID: value, Comment: comment }, successResponse => {

                        }, errorResponse => {
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        }).$promise);
                    });
                }

                if (removeOperationItems.length > 0) {
                    removeOperationItems.forEach(function (value) {
                        promises.push(legalApiResources.SectionOneOperationItemApi.query({ $filter: "SectionOneID eq " + sectionOneResponse.SectionOneID + " and OperationItemID eq " + value },
                            (successResponse: LegalEntities.SectionOneOperationItem[]) => {
                                promises.push(legalApiResources.SectionOneOperationItemApi.delete({
                                    id: successResponse[0].SectionOneOperationItemID, allowHardDelete: true
                                }, deleteCallback => {

                                }, errorResponse => {
                                    console.log(errorResponse);
                                }).$promise);
                            }, errorCallback => {
                                $scope.saveBtnDisabled = false;
                                $scope.denyBtnDisabled = false;
                            }).$promise);
                    });
                }

                if (updatingOperationComment) {
                    $scope.OperationItem.forEach(function (value) {
                        if (value == 5) {
                            promises.push(legalApiResources.SectionOneOperationItemApi.query({ $filter: "SectionOneID eq " + sectionOneResponse.SectionOneID + " and OperationItemID eq " + value },
                                (successResponse: LegalEntities.SectionOneOperationItem[]) => {
                                    successResponse[0].Comment = $scope.OperationOtherComment;
                                    promises.push(legalApiResources.SectionOneOperationItemApi.update({ id: successResponse[0].SectionOneOperationItemID }, successResponse[0], updateCallback => {
                                    }, errorResponse => {
                                        console.log(errorResponse);
                                    }).$promise);
                                }, errorCallback => {
                                    $scope.saveBtnDisabled = false;
                                    $scope.denyBtnDisabled = false;
                                }).$promise);
                        }
                    });            
                }
                
                if (addReportingItems.length > 0) {
                    addReportingItems.forEach(function (value) {
                        promises.push(legalApiResources.SectionOneOperationItemApi.save({ SectionOneID: sectionOneResponse.SectionOneID, OperationItemID: value }, successResponse => {

                        }, errorResponse => {
                            console.log(errorResponse);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        }).$promise);
                    });
                }

                if (removeReportingItems.length > 0) {
                    removeReportingItems.forEach(function (value) {
                        promises.push(legalApiResources.SectionOneReportingItemApi.query({ $filter: "SectionOneID eq " + sectionOneResponse.SectionOneID + " and ReportingItemID eq " + value },
                            (successResponse: LegalEntities.SectionOneReportingItem[]) => {
                                promises.push(legalApiResources.SectionOneReportingItemApi.delete({
                                    id: successResponse[0].SectionOneReportingItemID, allowHardDelete: true
                                }, deleteCallback => {

                                }, errorCallback => {

                                }).$promise);
                            }, errorResponse => {
                                console.log(errorResponse);
                                $scope.saveBtnDisabled = false;
                                $scope.denyBtnDisabled = false;
                            }).$promise);
                    });
                }

                var promissoryNotes = $scope.promissoryNotesGridOptions.dataSource.data().toJSON();
                if (promissoryNotes.length > 0) {
                    promissoryNotes.forEach(function (value: LegalEntities.SectionOnePromissoryNote) {
                        value.PromissoryNoteDate = kendo.toString(kendo.parseDate(value.PromissoryNoteDate, 'yyyy-MM-dd'), 'MM/dd/yyyy');
                        promises.push(legalApiResources.SectionOnePromissoryNoteApi.update({ id: value.SectionOnePromissoryNoteID }, value, successResponse => {

                        }, errorResponse => {
                            console.log(errorResponse);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        }).$promise);
                    });
                }

                $q.all(promises).then(
                    successCallbackResponse => {
                        var finishPromise;
                        if (isAccepted) {
                            finishPromise = legalApiResources.SectionOneCompleted($scope.contractId, sectionOneResponse.SectionOneID);
                        } else {
                            finishPromise = legalApiResources.SectionOneDenied($scope.contractId, sectionOneResponse.SectionOneID);
                        }

                        finishPromise.then(successResponse => {
                            window.location.href = "/Itt";
                        }, errorResponse => {
                            $scope.kNotifier.show({
                                kValue: "Failed to save to server. Please contact an admin."
                            }, "ngTemplate");
                            console.log(errorResponse);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        });

                    },
                    errorResponse => {
                        $scope.kNotifier.show({
                            kValue: "Failed to save to server. Please contact an admin."
                        }, "ngTemplate");
                        console.log(errorResponse);
                        $scope.saveBtnDisabled = false;
                        $scope.denyBtnDisabled = false;
                    });
            });
        }

        this.legalApiResources.ContractApi.get({
            id: $scope.contractId,
            $expand: "SectionOne($expand=SectionOneMonetaryItems,SectionOneOperationItems,SectionOnePromissoryNotes,SectionOneReportingItems),ContractStatu"
        }, (successResponse: LegalEntities.Contract) => {
            $scope.model = successResponse.SectionOne;

            $scope.initialMinimumFee = $scope.model.MinimumFees;
            $scope.initialReportingFee = $scope.model.ReportingFees;
            $scope.initialEstimateFee = $scope.model.EstimatedFees;
            $scope.initialSoftwareFee = $scope.model.SoftwareFees;
            $scope.initialNSFFee = $scope.model.NSFFees;
            $scope.initialLateFee = $scope.model.LateFees;

            $scope.initialMinimumOverride = $scope.model.MinimumFeesOverride;
            $scope.initialReportingOverride = $scope.model.ReportingFeesOverride;
            $scope.initialEstimateOverride = $scope.model.EstimatedFeesOverride;
            $scope.initialSoftwareOverride = $scope.model.SoftwareFeesOverride;
            $scope.initialNSFOverride = $scope.model.NSFFeesOverride;
            $scope.initialLateOverride = $scope.model.LateFeesOverride;

            $scope.isItt = successResponse.ContractStatu.Description == "Intent To Terminate";

            $scope.gridDataSource.data(successResponse.SectionOne.SectionOnePromissoryNotes);
            $scope.MonetaryItem = [];
            $scope.OriginalMonetaryItems = [];
            successResponse.SectionOne.SectionOneMonetaryItems.forEach(function (value) {
                $scope.MonetaryItem.push(value.MonetaryItemID);
                $scope.OriginalMonetaryItems.push(value.MonetaryItemID);
            });
            $scope.ReportingItem = [];
            $scope.OriginalReportingItems = [];
            successResponse.SectionOne.SectionOneReportingItems.forEach(function (value) {
                $scope.ReportingItem.push(value.ReportingItemID);
                $scope.OriginalReportingItems.push(value.ReportingItemID);
            });
            $scope.OperationItem = [];
            $scope.OriginalOperationItems = [];
            successResponse.SectionOne.SectionOneOperationItems.forEach(function (value) {
                if (value.OperationItemID == 5) {
                    $scope.OperationOtherFlag = true;
                    $scope.OperationOtherComment = value.Comment;
                }
                $scope.OperationItem.push(value.OperationItemID);
                $scope.OriginalOperationItems.push(value.OperationItemID);
            });

            $scope.$emit("setSectionAvaliability", {
                hasSectionTwo: successResponse.SectionTwoID != null,
                hasSectionThree: successResponse.SectionThreeID != null,
                franchiseID: $scope.franchiseId,
                contractID: $scope.contractId
            });
        }, errorResponse => {
            console.log(errorResponse);
        });

        this.legalApiResources.MonetaryItemApi.query({}, successResponse => {
            $scope.monetaryItemOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        this.legalApiResources.OperationItemApi.query({}, successResponse => {
            $scope.operationItemOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        this.legalApiResources.ReportingItemApi.query({}, successResponse => {
            $scope.reportingItemOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        this.legalApiResources.TimeScaleApi.query({}, successResponse => {
            $scope.timeScales = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });
    }
}

