import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { RmsStateService } from "Services/State/RmsState";
import { IdentityManager } from "Services/Resources/IdentityManager";
import * as _ from "underscore"
import { CoreEntities, CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class fddSelectorComponentController implements ng.IController {
    
    conceptId: number;
    franchisorId: number;
    readOnly: boolean;

    isLoading: boolean;

    fddGrid: kendo.ui.Grid;

    conceptFilters: kendo.data.DataSourceFilter;
    franchisorFilters: kendo.data.DataSourceFilter;
    
    static $inject = [
        '$window',
        '$location',
        '$q',
        'rpmUiApiResources',
        'rpmHelper',
        'rmsState',
        'identityManager'
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private rmsState: RmsStateService,
        private identityManager: IdentityManager,
    )
    {

    }
    $onInit()
    {
        this.InitFilters();

        this.readOnly = true;
        this.identityManager.GetLoggedInUserInfo().then(userResponse =>
        {
            this.readOnly = !this.identityManager.UserHasRole(userResponse.data, ["FddManagementAdmin", "Admin"]);
        });
    }

    $postLink()
    {
        this.InitGrid();
    }

    InitFilters()
    {
        this.conceptFilters = <kendo.data.DataSourceFilterItem>{
            field: nameof<CoreEntities.Concept>(o => o.RoyaltySystemId),
            operator: "isnotnull"
        };

        this.franchisorFilters = <kendo.data.DataSourceFilterItem>{
            field: nameof<CoreEntities.Franchisor>(o => o.RoyaltySystemId),
            operator: "isnotnull"
        };
    }

    InitGrid()
    {
        let fddColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.Year),
                title: "Year",
                width: 128
            }, {
                field: "RoyaltyMethodologyDescription",
                title: "Royalty Methodology",
                width: 256
            }, {
                field: "RoyaltyPeriodFrequencyDescription",
                title: "Royalty Period Frequency",
                width: 256
            }, {
                field: nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.ApprovedDate),
                title: "Approved Date",
                format: "{0:yyyy-MM-dd}",
                width: 128
            }, {
                field: nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.AmendedDate),
                title: "Amended Date",
                format: "{0:yyyy-MM-dd}",
                width: 128
            }, {
                field: nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.Selectable),
                title: "Selectable",
                width: 128,
                template: (dataItem: RpmEntities.DisclosureDocumentViewModel) =>
                {
                    return dataItem.Selectable ? 'Yes' : 'No';
                }
            }, {
                field: nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.Description),
                title: "Description",
                width: 128
            },
        ];

        let fddGridDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.DisclosureDocumentId),
                    fields: {
                        [nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.DisclosureDocumentId)]: {
                            editable: false
                        },
                        [nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.Year)]: { type: "string" },
                        [nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.ApprovedDate)]: { type: "date" },
                        [nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.AmendedDate)]: { type: "date" },
                        RoyaltyMethodologyDescription: { type: "string" },
                        RoyaltyPeriodFrequencyDescription: { type: "string" },
                        [nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.Selectable)]: { type: "boolean" },
                        [nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.Description)]: { type: "string" }
                    }
                }
            },
            transport: {
                read: (options) =>
                {
                    if (!this.franchisorId) {
                        options.success([]);
                        return;
                    }
                    this.isLoading = true;

                    this.$q.all([
                        this.rpmUiApiResources.GetDisclosureDocumentsByFranchisorId(this.franchisorId),
                        this.rmsState.royaltyMethodologyTypesStateHandler.Load(),
                        this.rmsState.royaltyPeriodFrequenciesStateHandler.Load(),
                    ])
                        .then((responses) =>
                        {
                            let documents = responses[0].data;
                            let royaltyMethodologyTypes = responses[1];
                            let royaltyPeriodFrequencies = responses[2];

                            for (let doc of documents)
                            {
                                let methodology = _.find(royaltyMethodologyTypes,
                                    (type) =>
                                    {
                                        return doc.RoyaltyMethodologyTypeId == type.RoyaltyMethodologyTypeId;
                                    });

                                let frequency = _.find(royaltyPeriodFrequencies,
                                    (freq) =>
                                    {
                                        return doc.RoyaltyPeriodFrequencyId == freq.RoyaltyPeriodFrequencyId;
                                    });
                                
                                (<any>doc).RoyaltyMethodologyDescription = methodology && methodology.Description;
                                (<any>doc).RoyaltyPeriodFrequencyDescription = frequency && frequency.Description;
                            }

                            options.success(documents);
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        })
                        .finally(() => { this.isLoading = false; });

                }
            }
        });


        this.fddGrid.setOptions(<kendo.ui.GridOptions>{
            columns: fddColumns,
            dataSource: fddGridDataSource,
            selectable: true,
            change: (e) =>
            {
                let fddId = e.sender.dataItem(e.sender.select()).get(nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.DisclosureDocumentId));

                this.GoToNewFddSection(fddId)
            }
        })
    }

    OnConceptSelect(concept: Partial<CoreResources.IConcept>)
    {
        this.conceptId = concept && concept.ConceptId;
    }

    OnFranchisorSelect(franchisor: Partial<CoreResources.IFranchisor>)
    {
        this.conceptId = franchisor && franchisor.ConceptId;
        this.franchisorId = franchisor && franchisor.FranchisorId;

        if (this.franchisorId) {
            this.fddGrid.dataSource.read().then(() =>
            {
                this.fddGrid.refresh();
            });
        }
    }

    GoToNewFddSection(fddId: number)
    {
        this.$window.location.href = `/FddManagement/Edit#?${nameof(fddId)}=${fddId}`
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("fddSelector", {
            bindings: {

            },
            templateUrl: '/Templates/FddManagement/fddSelector.html',
            controller: fddSelectorComponentController
        });
    }
}