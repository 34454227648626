import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class UnprocessedManualSalesEntriesComponentController implements ng.IController 
{
    isLoading: boolean;
    unprocessedEntryGrid: kendo.ui.Grid;
    
    entryDetailsGrids: { [uid: string]: kendo.ui.Grid };

    static $inject = [
        'coreApiResources',
        '$q',
        '$log',
        '$timeout',
        'rpmUiApiResources',
        'odataHelper'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private rpmUiApiResources: RpmUiApiResources,
        private odataHelper: ODataHelperService
    )
    {
    }

    $onInit()
    {
        this.entryDetailsGrids = {};
    }

    $postLink()
    {
        this.InitGridOptions();
    }

    InitGridOptions()
    {
        let columns: kendo.ui.GridColumn[] = [{
                field: nameof<RpmEntities.usp_RoyaltyManualSalesEntry_GetUnprocessedEntries_Result>(o => o.RoyaltyManualSalesEntryID),
                title: "Entry Id"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyManualSalesEntry_GetUnprocessedEntries_Result>(o => o.DisplayName),
                title: "Franchisor"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyManualSalesEntry_GetUnprocessedEntries_Result>(o => o.FranchiseeName),
                title: "Franchise"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyManualSalesEntry_GetUnprocessedEntries_Result>(o => o.LicenseNumber),
                title: "Account Code"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyManualSalesEntry_GetUnprocessedEntries_Result>(o => o.PeriodEndDate) + "Object",
                title: "Period End Date",
                format: "{0:d}"
            }];

        let datasource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof<RpmEntities.usp_RoyaltyManualSalesEntry_GetUnprocessedEntries_Result>(o => o.RoyaltyManualSalesEntryID),
                    fields: {
                        DisplayName: {
                            type: "string",
                        },
                        FranchiseeName: {
                            type: "string"
                        },
                        FranchisorId: {
                            type: "number"
                        },
                        LicenseNumber: {
                            type: "string"
                        },
                        PeriodEndDate: {
                            type: "string"
                        },
                        PeriodEndDateObject: {
                            type: "date"
                        }
                    }
                }
            },
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) =>
                {
                    this.isLoading = true;
                    this.rpmUiApiResources.GetRoyaltyManualSalesEntriesUnprocessed()
                        .then((entriesResponse) =>
                        {
                            for (let entry of entriesResponse.data) {
                                (<any>entry).PeriodEndDateObject = new Date(entry.PeriodEndDate)
                            }

                            options.success(entriesResponse.data);
                        })
                        .catch((err) => { options.error(err); })
                        .finally(() => { this.isLoading = false; })
                }
            },
            sort: [{
                field: nameof<RpmEntities.usp_RoyaltyManualSalesEntry_GetUnprocessedEntries_Result>(o => o.FranchiseeName),
                dir: "desc"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyManualSalesEntry_GetUnprocessedEntries_Result>(o => o.PeriodEndDate),
                dir: "desc"
            }]
        });

        let options: kendo.ui.GridOptions = {
            columns: columns,
            dataSource: datasource,
            editable: false,
            autoBind: true,
            sortable: true,
            filterable: true
        };

        this.unprocessedEntryGrid.setOptions(options);
    }

    GetEntryDetailsGridOptions(entry: RpmEntities.usp_RoyaltyManualSalesEntry_GetUnprocessedEntries_Result & kendo.data.ObservableObject)
    {
        let options: kendo.data.DataSourceOptions = {
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) =>
                {
                    this.rpmUiApiResources.GetRoyaltyManualSalesEntryDetailsByRoyaltyManualSalesEntry(entry.RoyaltyManualSalesEntryID)
                        .then((detailsResponse) =>
                        {
                            options.success(detailsResponse.data);
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        })
                },
            }
        };
        let columns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.RoyaltyManualSalesEntryDetailId),
                title: "Detail Id"
            }, {
                field: nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.Description),
                title: "Description"
            }, {
                field: nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossResidentialSales),
                title: "Gross Residential Sales",
                format: "{0:C}"
            }, {
                field: nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossCommercialSales),
                title: "Gross Commercial Sales",
                format: "{0:C}"
            }, {
                field: nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossTotalSales),
                title: "Gross Total Sales",
                format: "{0:C}"
            }];

        return {
            dataSource: new kendo.data.DataSource(options),
            columns: columns,
            editable: false,
            scrollable: false,
        };
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('unprocessedManualSalesEntries', {
            bindings: {

            },
            controller: UnprocessedManualSalesEntriesComponentController,
            templateUrl: "/Templates/RPM/UnprocessedManualSalesEntries.html"
        });
    }
}

