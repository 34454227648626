import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { IBaseStateHandler } from "StateHandlers/BaseStateHandler";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyMethodologyTypesStateHandler implements IBaseStateHandler<RpmEntities.usp_RoyaltyMethodologyTypes_GetByRoyaltyMethodologyTypeId_Result[], undefined> {

    promise: angular.IPromise<RpmEntities.usp_RoyaltyMethodologyTypes_GetByRoyaltyMethodologyTypeId_Result[]>;

    constructor(
        private rpmUiApiResources: RpmUiApiResources
    )
    {

    }

    Get(id: undefined = undefined): angular.IPromise<RpmEntities.usp_RoyaltyMethodologyTypes_GetByRoyaltyMethodologyTypeId_Result[]>
    {
        return this.rpmUiApiResources.GetAllRoyaltyMethodologyTypes()
            .then(typesResponse =>
            {
                return typesResponse.data;
            });
    }

    Load(id: undefined = undefined): angular.IPromise<RpmEntities.usp_RoyaltyMethodologyTypes_GetByRoyaltyMethodologyTypeId_Result[]>
    {
        if (!this.promise) {
            this.promise = this.Get();
        }

        return this.promise;
    }

    Refresh(id: undefined = undefined): angular.IPromise<RpmEntities.usp_RoyaltyMethodologyTypes_GetByRoyaltyMethodologyTypeId_Result[]>
    {
        this.Clear();
        return this.Load();
    }

    Clear(id: undefined = undefined): void
    {
        this.promise = null;
    }
}