import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { RmsStateService } from "Services/State/RmsState";
import * as _ from "underscore"
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";

enum FeeType {
    AdFee = 1,
    RoyaltyFee = 2,
    TAFSFee = 3,
    TechnologyFee = 4
}

export class PeriodReportAdjustmentsDynamicFeesComponentController implements ng.IController {
    royaltyPeriodReportId: number;
    franchise: Partial<CoreEntities.Franchise>;
    canEdit: boolean;
    init: (params: { self: PeriodReportAdjustmentsDynamicFeesComponentController }) => void;
    localStorage: ILocalStorage;
    userEmail: string;
    isLoading: boolean;
    adjustmentTotal: number;
    adjustmentToCreate: RoyaltyPeriodReportAdjustmentUpsertModel;
    readOnly: boolean;
    grid: kendo.ui.Grid;
    gridOptions: kendo.ui.GridOptions;
    createAdjustmentWindow: kendo.ui.Window;

    adjustmentForm: kendo.ui.Validator;
    adjustmentDate: Date
    adjustmentDescription: string

    addAdjustmentGridOptions: kendo.ui.GridOptions;
    addAdjustmentGrid: kendo.ui.Grid;

    addAdjustmentValidationErrorGrid: kendo.ui.Grid;
    addAdjustmentValidationErrorGridOptions: kendo.ui.GridOptions;

    static $inject = [
        'identityManager',
        '$timeout',
        '$q',
        'rpmApiResources',
        'rpmUiApiResources',
        'rmsState',
        'royaltyApiResources',
        '$scope'
    ];

    royaltyFeeTypes: RoyaltyFeeTypeModel[] = []

    formModel: any = null
    validationRules: any = null

    constructor(
        private identityManager: IdentityManager,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
        private rpmApiResources: RpmApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private rmsState: RmsStateService,
        private royaltyApiResources: RoyaltyApiResources,
        private $scope: ng.IScope
    ) {
    }

    showSnackbar: boolean;
    snackbarType: string;
    snackbarMessage: string;

    DisplaySnackbar(type: string, message: string) {
        this.showSnackbar = !this.showSnackbar;
        this.snackbarType = type;
        this.snackbarMessage = message;
        this.$scope.$evalAsync();
    }

    TotalAdjustment: number = 0

    FormatCurrency = function(number) {
        return '$' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    InitAddAdjustmentGrid = function() {
        this.adjustmentDate = new Date(new Date().toUTCString()) // Current UTC date

        this.adjustmentDateOptions = {
            format: "yyyy-MM-dd",
            dateInput: false,
            open: function(e) {
                e.preventDefault(); // Prevent the date picker from opening
            }
        };
        // Initialize grid options
        this.addAdjustmentGridOptions = {
            dataSource: {
                change: (e) => {
                    // Calculate the sum of fee adjustments
                    const data = e.sender.data();
                    let sum = 0;
                    data.forEach(item => sum += item.feeAdjustment);
                    
                    // Assign the sum to the component variable
                    this.TotalAdjustment = sum;
                },
                data: [], // Initial empty data
                schema: {
                    model: {
                        id: 'royaltyPeriodReportAdjustmentFeeXrefId',
                        fields: {
                            rowNumber: {type:'number', editable: false},
                            royaltyPeriodReportAdjustmentFeeXrefId: { type: 'number' },
                            royaltyPeriodReportAdjustmentId: { type: 'number' },
                            feeTypeId: {
                                type: 'number'
                            },
                            feeAdjustment: { type: 'number', validation: { required: true } },
                            createdDateTime: { type: 'date', nullable: true },
                            createdUser: { type: 'string', nullable: true },
                            description: { type: 'description', nullable: true },
                            updatedDateTime: { type: 'date' },
                            updatedUser: { type: 'string', nullable: true },
                            deletedDateTime: { type: 'date', nullable: true },
                            
                        }
                    }
                }
            },
            columns: [
                {
                    field: 'rowNumber',
                    title: 'Row No.',
                    width: '80px',
                    template: dataItem => this.addAdjustmentGrid.dataSource.indexOf(dataItem) + 1
                },
                {
                    field: 'feeTypeId',
                    title: 'Fee Type',
                    editor: (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
                        const filteredFeeTypes = this.royaltyFeeTypes
                            .filter(({royaltyFeeTypeID}) => royaltyFeeTypeID >= 1 && royaltyFeeTypeID <= 4);

                        $('<input required name="' + options.field + '"/>')
                            .appendTo(container)
                            .kendoDropDownList({
                                autoBind: false,
                                dataSource: filteredFeeTypes,
                                dataTextField: nameof<RoyaltyFeeTypeModel>(o => o.name),
                                dataValueField: nameof<RoyaltyFeeTypeModel>(o => o.royaltyFeeTypeID)
                            });
                    },
                    template: ({ feeTypeId }) => this.GetFeeTypeName(feeTypeId)
                },
                { field: 'feeAdjustment', title: 'Fee Adjustment', format: "{0:c}" },
                { command: "destroy", title: " ", width: "150px" }
            ],
            editable: true,
            toolbar: ['create']
        };

    }

    ValidateAdjustments() {
        const validationErrors = [];

        // Validate adjustmentDate
        if (!this.adjustmentDate) {
            validationErrors.push({
                propertyName: "Adjustment Date",
                errorMessage: "Adjustment date is required."
            });
        } else if (this.adjustmentDate > new Date()) {
            validationErrors.push({
                propertyName: "Adjustment Date",
                errorMessage: "Adjustment date cannot be a future date."
            });
        }

        // Validate description
        if (!this.adjustmentDescription || this.adjustmentDescription.trim() === "") {
            validationErrors.push({
                propertyName: "Description",
                errorMessage: "Please provide a short description of the reason for this adjustment."
            });
        }
    
        const adjustmentItems = this.addAdjustmentGrid.dataSource.data().filter(x => !x["deletedDateTime"]);
    
        if (adjustmentItems.length === 0) {
            validationErrors.push({
                propertyName: "Adjustments",
                errorMessage: "Please add Adjustment amount for atleast one fee type"
            });
        } else {
            adjustmentItems.forEach((dataItem, index) => {
                // Validate feeAdjustment
                if (dataItem.feeTypeId == null || dataItem.feeTypeId === 0 || isNaN(dataItem.feeTypeId)) {
                    validationErrors.push({
                        rowNumber: index + 1,
                        propertyName: "Fee Type",
                        errorMessage: "Fee Type is required"
                    });
                }

                if (dataItem.feeAdjustment == null || dataItem.feeAdjustment === 0 || isNaN(dataItem.feeAdjustment)) {
                    validationErrors.push({
                        rowNumber: index + 1,
                        propertyName: "Fee Adjustment",
                        errorMessage: "Adjustment amount must be provided and cannot be equal to 0."
                    });
                }
    
                // Check for upper and lower limit for feeAdjustment
                if (dataItem.feeAdjustment < -500000 || dataItem.feeAdjustment > 500000) {
                    validationErrors.push({
                        rowNumber: index + 1,
                        propertyName: "Fee Adjustment",
                        errorMessage: "Fee Adjustment must be between -500,000 and 500,000."
                    });
                }
    
                
            });

            // Validate unique feeTypeId
            const feeTypeIdList = adjustmentItems.map(item => item.feeTypeId);

            if (new Set(feeTypeIdList).size !== feeTypeIdList.length) {
                validationErrors.push({
                    propertyName: "Fee Type",
                    errorMessage: "Fee Type must be unique for each row."
                });
            }
        }
    
        return validationErrors;
    }
    

    currentRowNumber = 1;

    // Function to generate the row number column template dynamically
    GenerateRowNumberTemplate = () => (_) => {
        return this.currentRowNumber++;
    };


    GetFeeTypeName = function (feeTypeId: number): string {
        const feeType = this.royaltyFeeTypes.find(({ royaltyFeeTypeID }) => royaltyFeeTypeID === feeTypeId);
        return feeType ? feeType.name : '';
    }


    MapFeeXrefModelToAdjustmentModel(
        feeXrefModels: RoyaltyPeriodReportAdjustmentFeeXrefModel[],
        baseAdjustment: RoyaltyPeriodReportAdjustmentModel,
    ): RoyaltyPeriodReportAdjustmentModel {
        // Initialize fee adjustments
        let adFeeAdjustment = 0;
        let royaltyFeeAdjustment = 0;
        let tafsFeeAdjustment = 0;
        let technologyFeeAdjustment = 0;

        // Iterate over feeXrefModels to find fee adjustments
        for (const { feeTypeId, feeAdjustment } of feeXrefModels) {
            switch (feeTypeId) {
                case FeeType.AdFee:
                    adFeeAdjustment = feeAdjustment;
                    break;
                case FeeType.RoyaltyFee:
                    royaltyFeeAdjustment = feeAdjustment;
                    break;
                case FeeType.TAFSFee:
                    tafsFeeAdjustment = feeAdjustment;
                    break;
                case FeeType.TechnologyFee:
                    technologyFeeAdjustment = feeAdjustment;
                    break;
                default:
                    // unknown fee type
                    break;
            }
        }

        // Create RoyaltyPeriodReportAdjustmentModel
        const adjustmentModel: RoyaltyPeriodReportAdjustmentModel = {
            ...baseAdjustment,
            adFeeAdjustment,
            royaltyFeeAdjustment,
            tafsFeeAdjustment,
            technologyFeeAdjustment
        };

        return adjustmentModel;
    }


    MapUpsertModelToCreateModel(upsertModel: RoyaltyPeriodReportAdjustmentUpsertModel):
        RoyaltyPeriodReportAdjustmentFeeXrefCreateModel[] {

        const feeXrefCreateModels: RoyaltyPeriodReportAdjustmentFeeXrefCreateModel[] = [];

        feeXrefCreateModels.push({
            royaltyPeriodReportAdjustmentId: upsertModel.royaltyPeriodReportAdjustmentId,
            feeTypeId: FeeType.AdFee,
            feeAdjustment: upsertModel.adFeeAdjustment,
            royaltyPeriodReportAdjustmentFeeXrefId: 0
        });

        feeXrefCreateModels.push({
            royaltyPeriodReportAdjustmentId: upsertModel.royaltyPeriodReportAdjustmentId,
            feeTypeId: FeeType.RoyaltyFee,
            feeAdjustment: upsertModel.royaltyFeeAdjustment,
            royaltyPeriodReportAdjustmentFeeXrefId: 0
        });

        feeXrefCreateModels.push({
            royaltyPeriodReportAdjustmentId: upsertModel.royaltyPeriodReportAdjustmentId,
            feeTypeId: FeeType.TAFSFee,
            feeAdjustment: upsertModel.tafsFeeAdjustment,
            royaltyPeriodReportAdjustmentFeeXrefId: 0
        });

        feeXrefCreateModels.push({
            royaltyPeriodReportAdjustmentId: upsertModel.royaltyPeriodReportAdjustmentId,
            feeTypeId: FeeType.TechnologyFee,
            feeAdjustment: upsertModel.technologyFeeAdjustment,
            royaltyPeriodReportAdjustmentFeeXrefId: 0
        });

        return feeXrefCreateModels;
    }

    async ResetChanges() {
        if (!this.grid) {
            return;
        }
    
        this.isLoading = true;

        try {
            this.grid.dataSource.cancelChanges();
            await this.grid.dataSource.read();
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.grid.refresh();
        } catch (error) {
            console.error("Error during ResetChanges:", error);
            // Optionally, handle the error further or display a message to the user
        } finally {
            this.isLoading = false;
        }
    }    

    $onInit() {
        this.isLoading = true;
    
        this.SetGridOptions();
    
        this.GetRoyaltyFeeTypes()
            .then(royaltyFeeTypes => {
                this.ResetChanges();
                
                return this.GetRoyaltyItemFeeTypesByFranchisor(this.franchise.FranchisorId)
                    .then(royaltyItemFeeTypes => {
                        // Filter royaltyItemFeeTypes to include only those items where DeletedDateTime is null
                        const filteredRoyaltyItemFeeTypes = royaltyItemFeeTypes.filter(item => item.DeletedDateTime === null);
    
                        // Create a Set of RoyaltyFeeTypeIds from the filtered royaltyItemFeeTypes for faster lookup
                        const royaltyItemFeeTypeIds = new Set(filteredRoyaltyItemFeeTypes.map(item => item.RoyaltyFeeTypeId));
    
                        // Filter royaltyFeeTypes based on the presence of royaltyFeeTypeID in the filtered royaltyItemFeeTypes
                        const intersection = royaltyFeeTypes.filter(royaltyFeeType =>
                            royaltyItemFeeTypeIds.has(royaltyFeeType.royaltyFeeTypeID)
                        );
    
                        this.royaltyFeeTypes = intersection;
                    });
            })
            .then(() => {
                return this.ResetChanges();
            })
            .then(() => {
                this.InitAddAdjustmentGrid();

                if (this.init) {
                    this.init({ self: this });
                }
            })
            .catch(error => {
                console.error("Error initializing component:", error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    GetRoyaltyFeeTypes = function () {
        return this.royaltyApiResources.GetRoyaltyFeeTypes()
            .then(({ data: royaltyFeeTypes }) => {
                return royaltyFeeTypes
            });
    };

    GetRoyaltyItemFeeTypesByFranchisor = async function (franchisorId: number) {
        const { data: royaltyItemsFeeType } = await this.rpmUiApiResources.GetRoyaltyItemFeeTypesByFranchisor(franchisorId)
        return royaltyItemsFeeType
    }


    $postLink() {
        this.createAdjustmentWindow.setOptions(<kendo.ui.WindowOptions>{
            modal: true,
            width: 1000
        });
        
    }

    MapErrorData = function (originalError: BadRequestErrorResponse): ErrorData[] {
        const errorData: ErrorData[] = [];
    
        for (const [key, value] of Object.entries(originalError.errors)) {
            const matches = key.match(/x\[(\d+)\]\.(.*)/);
            if (matches) {
                const rowNumber = parseInt(matches[1]);
                const propertyName = matches[2];
                const errorMessages = value;
                let rowData = errorData.find(data => data.rowNumber === rowNumber);
                if (!rowData) {
                    rowData = {
                        rowNumber,
                        errors: []
                    };
                    errorData.push(rowData);
                }
                rowData.errors.push({ propertyName, errorMessages });
            } else {
                const errorDataItem: ErrorData = {
                    rowNumber: null, // Indicating it's not related to a specific row
                    errors: [{ propertyName: key, errorMessages: value }]
                };
                errorData.push(errorDataItem);
            }
        }
    
        return errorData;
    }

    ShowErrorGrid = function (errorResponse: BadRequestErrorResponse) {

        const errorDataCollection: ErrorData[] = this.MapErrorData(errorResponse);

        const adjustmentCreationValidationErrors: AdjustmentCreationValidationError[] = [];

        for (const errorData of errorDataCollection) {
            for (const error of errorData.errors) {
                for (const errorMessage of error.errorMessages) {
                    const adjustmentCreationValidationError: AdjustmentCreationValidationError = {
                        rowNumber: errorData.rowNumber,
                        propertyName: error.propertyName,
                        errorMessage
                    };
                    adjustmentCreationValidationErrors.push(adjustmentCreationValidationError);
                }
            }
        }

        this.UpdateGridOptions(adjustmentCreationValidationErrors);
    }

    UpdateGridOptions = function(adjustmentCreationValidationErrors) {
        if (!this.addAdjustmentValidationErrorGridOptions) {
            // Create new object if it's null
            this.addAdjustmentValidationErrorGridOptions = {
                dataSource: {
                    data: adjustmentCreationValidationErrors
                },
                columns: [
                    //{ field: 'rowNumber', title: 'Row No.' },
                    { field: 'propertyName', title: 'Property' },
                    { field: 'errorMessage', title: 'Error Message' }
                ]
            };
        } else {
            // Update dataSource if it exists
            const dataSource = new kendo.data.DataSource({
                data: adjustmentCreationValidationErrors
            });

            this.addAdjustmentValidationErrorGrid.setDataSource(dataSource)
            this.addAdjustmentValidationErrorGrid.dataSource.read(); // Read the new data from the data source
            this.addAdjustmentValidationErrorGrid.refresh();
        }
    };

    adjustmentDateOptions: kendo.ui.DatePickerOptions;
    

    SaveAdjustments = function () {
        if (this.adjustmentForm.validate()) {

            const validationErrors = this.ValidateAdjustments();

            if (validationErrors.length > 0) {
                this.adjustmentCreationError = true;
                this.UpdateGridOptions(validationErrors);
                this.DisplaySnackbar("error", "There are validation errors. Please fix them and try again!");
                //this.validationErrorsKendoWindow.open();
                throw new Error("There are validation errors. Please fix them and try again!");
            }

            this.adjustmentCreationError = false;

            const addAdjustmentGridDataSource = this.addAdjustmentGrid.dataSource;

            const adjustmentFeeXrefs: RoyaltyPeriodReportAdjustmentFeeXrefModel[] = addAdjustmentGridDataSource.view()

            if (!adjustmentFeeXrefs){
                this.DisplaySnackbar("warning", "Please add atleast one adjustment!")
                return;
            }

            const { FranchiseId: franchiseId } = this.franchise;

            const totalAdjustment = adjustmentFeeXrefs
                .reduce((total, feeXrefModel) => total + feeXrefModel.feeAdjustment, 0);

            const baseAdjustment: RoyaltyPeriodReportAdjustmentModel = {
                franchiseId: franchiseId,
                royaltyPeriodReportId: this.royaltyPeriodReportId,
                description: this.adjustmentDescription,
                createdDateTime: this.adjustmentDate,
                periodReportAdjustmentId: "",
                totalAdjustment: totalAdjustment
            }

            const adjustment: RoyaltyPeriodReportAdjustmentModel =
                this.MapFeeXrefModelToAdjustmentModel(adjustmentFeeXrefs, baseAdjustment)

                this.royaltyApiResources.CreateRoyaltyPeriodReportAdjustment(adjustment)
                .then(({ data: createdAdjustmentResponse }) => {
                    // Step 1: Log the created adjustment response
                    return createdAdjustmentResponse;
                })
                .then((createdAdjustmentResponse) => {
                    // Step 2: Update adjustmentFeeXrefs with the created adjustment ID
                    adjustmentFeeXrefs.forEach((feeXref) => feeXref.royaltyPeriodReportAdjustmentId = createdAdjustmentResponse.royaltyPeriodReportAdjustmentId);
                    // Return the updated adjustmentFeeXrefs to pass to the next then block
                    return adjustmentFeeXrefs;
                })
                .then((updatedAdjustmentFeeXrefs) => {
                    // Step 3: Perform any additional actions with the updated adjustmentFeeXrefs
                    
                    // Example: Call another API method with the updated adjustmentFeeXrefs
                    return this.royaltyApiResources.CreateRoyaltyPeriodReportAdjustmentFeeXrefs(
                        updatedAdjustmentFeeXrefs[0].royaltyPeriodReportAdjustmentId, // Assuming you want to use the adjustment ID from the first item
                        updatedAdjustmentFeeXrefs
                    );
                })
                .then(()=>{
                    addAdjustmentGridDataSource.data([]);

                    this.adjustmentCreationError = false;

                    if (this.addAdjustmentValidationErrorGrid)
                        this.addAdjustmentValidationErrorGrid.dataSource.data([])

                    this.adjustmentDate = null
                    this.adjustmentDescription = null
    
                    this.createAdjustmentWindow.close();

                    this.RefreshGrid();
                })
                .catch(({status,data}) => {
                    this.adjustmentCreationError = true

                    switch (status) {
                        case 403:
                            this.DisplaySnackbar("error", "You are unauthorized to create adjustments!")
                        case 401:
                            this.DisplaySnackbar("error", "You are unauthenticated. Please re-login and try again!")
                        case 500:
                            this.DisplaySnackbar("error", "Some error occured while saving adjustments. Please try again!")
                        case 400:
                            const errorResponse: BadRequestErrorResponse = data

                            this.ShowErrorGrid(errorResponse);

                            this.DisplaySnackbar("error", "There are validation errors for your adjustment. Please fix them and try again!")
                    }

                    this.adjustmentCreationError = true
                })
                .finally(() => {
                    
                });
        }
        else {
            this.DisplaySnackbar("error", "Please fix the validation errors and try again!")
        }
    };

    CancelAdjustmentChanges = function () {
        const gridDataSource = this.addAdjustmentGrid.dataSource;
        gridDataSource.data([]);

        this.adjustmentDate = null
        this.adjustmentDescription = null

        this.createAdjustmentWindow.close();
    };

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (this.royaltyPeriodReportId) {
            let hasChanges = (onChanges[nameof(this.royaltyPeriodReportId)]);
            let validBindings = this.royaltyPeriodReportId;
            if (hasChanges && validBindings) {
                this.RefreshGrid();
            }
        }
    }

    async FetchAdjustmentData() {
        try {
            const adjustmentsResponse = await this.royaltyApiResources.GetRoyaltyPeriodReportAdjustments(this.royaltyPeriodReportId);
            const { data: adjustments } = adjustmentsResponse;
    
            const adjustmentPromises = adjustments.map(async (adjustment) => {
                const feeXrefResponse = await this.royaltyApiResources.GetRoyaltyPeriodReportAdjustmentFeeXrefs(adjustment.royaltyPeriodReportAdjustmentId);
                const { data: feeXrefs } = feeXrefResponse;
                return this.MapFeeXrefModelToAdjustmentModel(feeXrefs, adjustment);
            });
    
            const adjustmentFromXrefs = await Promise.all(adjustmentPromises);
    
            this.adjustmentTotal = adjustmentFromXrefs.reduce((runningTotal, adjustment) => {
                return runningTotal + adjustment.totalAdjustment;
            }, 0);
    
            return adjustmentFromXrefs;
        } catch (error) {
            const { status, data } = error;
            switch (status) {
                case 403:
                    this.DisplaySnackbar("error", "You are unauthorized to create adjustments!");
                    break;
                case 401:
                    this.DisplaySnackbar("error", "You are unauthenticated. Please re-login and try again!");
                    break;
                case 500:
                    this.DisplaySnackbar("error", "Some error occurred while fetching adjustments. Please try again!");
                    break;
                case 400:
                    const errorResponse: BadRequestErrorResponse = data;
                    this.ShowErrorGrid(errorResponse);
                    this.DisplaySnackbar("error", "There are validation errors for your adjustment. Please fix them and try again!");
                    break;
            }
            throw error;
        }
    }
    
    SetGridOptions() {
        const adjDataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    this.FetchAdjustmentData()
                        .then((adjustmentData) => {
                            options.success(adjustmentData);
                        })
                        .catch((error) => {
                            options.error(error);
                        });
                }
            },
            schema: {
                model: {
                    id: "royaltyPeriodReportAdjustmentId",
                    fields: {
                        royaltyPeriodReportAdjustmentId: { type: "number", validation: { required: true }, editable: false, defaultValue: 0 },
                        createdDateTime: { type: "date", validation: { required: true } },
                        postedDateTime: { type: "date" },
                        royaltyFeeAdjustment: { type: "number", validation: { required: true } },
                        periodReportAdjustmentId: { type: "string" },
                        adFeeAdjustment: { type: "number", validation: { required: true } },
                        technologyFeeAdjustment: { type: "number", validation: { required: true } },
                        tafsFeeAdjustment: { type: "number", validation: { required: true } },
                        totalAdjustment: { type: "number", validation: { required: true } },
                        description: { type: "string", validation: { required: true } },
                        royaltyPeriodReportId: { type: "string", validation: { required: true } }
                    }
                }
            },
            pageSize: 10,
            filter: { field: "deletedDateTime", operator: "isnull" }
        });
    
        const adjColumns = [
            {
                field: "periodReportAdjustmentId",
                title: "Report Adjustment Id"
            },
            {
                field: "postedDateTime",
                title: "Posted Date (yyyy-MM-dd)",
                format: "{0:yyyy-MM-dd}"
            },
            {
                field: "createdDateTime",
                title: "Adjustment Date (yyyy-MM-dd)",
                format: "{0:yyyy-MM-dd}"
            },
            {
                field: "royaltyFeeAdjustment",
                title: "Royalty Fee Adjustment",
                format: "{0:c2}"
            },
            {
                field: "adFeeAdjustment",
                title: "Ad Fee Adjustment",
                format: "{0:c2}"
            },
            {
                field: "technologyFeeAdjustment",
                title: "Tech Fee Adjustment",
                format: "{0:c2}"
            },
            {
                field: "tafsFeeAdjustment",
                title: "TAFS Fee Adjustment",
                format: "{0:c2}"
            },
            {
                field: "description",
                title: "Description"
            }
        ];
    
        this.gridOptions = {
            autoBind: false,
            toolbar: [
                {
                    template: `<button class='pure-button' ng-disabled='!$ctrl.canEdit' promise-button ng-click="$ctrl.${nameof(this.AddAdjustmentPopup)}()">
                                New Adjustment...
                            </button>`
                },
                { name: 'cancel', template: `<button class='pure-button' promise-button > Cancel </button>` }
            ],
            editable: !this.readOnly ? { confirmation: false } : false,
            columns: adjColumns,
            dataSource: adjDataSource,
            pageable: true,
            resizable: true,
            navigatable: true,
            scrollable: false,
        };
    }

    RefreshGrid() {
        if (this.grid) {
            return this.$q.when(this.grid.dataSource.read())
                .then(() => {
                    this.grid.refresh();
                });
        }
    }

    RefreshNewRecordAdd(gItem) {
        this.grid.dataSource.add(gItem)
    }

    ToISOString = function (date) {
        return date ? date.toISOString() : null;
    };

    AddAdjustmentPopup() {

        this.InitAddAdjustmentGrid();

        this.createAdjustmentWindow.open();

        return this.$timeout(() => {
            this.createAdjustmentWindow.center();
        }, 0);
    }

    CreateNewAdjustments() {
        const adjustmentDataSource = this.grid.dataSource;
        const allAdjustments = adjustmentDataSource.data();

        const createdAdjustments = allAdjustments.filter(function (item: any) {
            return item.dirty;
        });

        const adjustmentPromises = createdAdjustments.map((adjustment: any) => {
            return this.royaltyApiResources.CreateRoyaltyPeriodReportAdjustment(adjustment)
                .then(() => {
                    const feeXrefs = this.MapUpsertModelToCreateModel(adjustment);
                    return this.royaltyApiResources.CreateRoyaltyPeriodReportAdjustmentFeeXrefs(
                        this.adjustmentToCreate.royaltyPeriodReportAdjustmentId,
                        feeXrefs
                    );
                });
        });

        return Promise.all(adjustmentPromises)
            .then(() => this.RefreshGrid())
            .catch(error => {
                console.error('Error in CreateNewAdjustment:', error);
                // Handle error
            });
    }

    CancelNewAdjustment() {
        this.createAdjustmentWindow.close();
    }

    static BindComponent(app: ng.IModule) {
        app.component('prAdjustmentsDynamicFees', {
            bindings: {
                readOnly: "<",

                [nameof<PeriodReportAdjustmentsDynamicFeesComponentController>(o => o.royaltyPeriodReportId)]: "<",
                [nameof<PeriodReportAdjustmentsDynamicFeesComponentController>(o => o.franchise)]: "<",
                [nameof<PeriodReportAdjustmentsDynamicFeesComponentController>(o => o.canEdit)]: "<",
                [nameof<PeriodReportAdjustmentsDynamicFeesComponentController>(o => o.readOnly)]: "<",
                [nameof<PeriodReportAdjustmentsDynamicFeesComponentController>(o => o.init)]: "&"
            },
            controller: PeriodReportAdjustmentsDynamicFeesComponentController,
            templateUrl: "/Templates/PeriodReport/prSections/Adjustments_df.html"
        });
    }
}