import { CoreResources } from "../../../Interfaces/FranForce/Core/CoreResources";
import {
    RmsDashboardClient,
    RmsDashboardSalesVarianceResultVm,
    RmsDashboardRm
} from '@nbly/royalty-orchestrations-clients';
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";

export class rmsDashboardInputComponentController implements ng.IController {

    selectedConcepts: number[];
    date: Date;
    numberOfPeriods: number;

    isLoading: boolean;

    conceptSelect: string[];
    conceptFilters: kendo.data.DataSourceFilters;
    showValidationMessage: boolean;
    validationMessage: string;
    royaltyOrchestrationsClient: AxiosInstance;
    rmsDashboardClient: RmsDashboardClient;

    static $inject = [
        '$window',
        '$location',
        '$q',
        '$scope',
        "$rootScope"
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private $scope: ng.IScope,
        private $rootScope: ng.IRootScopeService,
    ) {
        this.royaltyOrchestrationsClient = FranForceAxiosClientBuilder.BuildRoyaltyOrchestrationsBaseClient();
        this.rmsDashboardClient = new RmsDashboardClient("", this.royaltyOrchestrationsClient);
    }

    $postLink() {
        var rmsDashboardDatePicker = $("#rms_dashboard_date").data("kendoDatePicker");
        var todayDate = kendo.toString(kendo.parseDate(new Date().toString()), 'MM/dd/yyyy');
        rmsDashboardDatePicker.value(todayDate);
        rmsDashboardDatePicker.trigger("change");
    }

    $onInit() {
        this.isLoading = false;
        this.setValidationMessage(false, '');
        this.numberOfPeriods = 2;
        this.CreateConceptFilters();

        this.conceptSelect = [
            nameof<CoreResources.IConcept>(o => o.ConceptId),
            nameof<CoreResources.IConcept>(o => o.ConceptCode),
            nameof<CoreResources.IConcept>(o => o.DisplayName),
        ];
    }

    CreateConceptFilters() {
        this.conceptFilters = {
            logic: "or",
            filters: [
                {
                    field: nameof<CoreResources.IConcept>(o => o.RoyaltySystemId),
                    operator: 'eq',
                    value: 1
                },
                {
                    field: nameof<CoreResources.IConcept>(o => o.RoyaltySystemId),
                    operator: 'eq',
                    value: 3
                }
            ]
        }
    }

    setValidationMessage(showValidationMessage: boolean, validationMessage: string) {
        this.showValidationMessage = showValidationMessage;
        this.validationMessage = validationMessage;
    }

    OnSubmit() {

        if (this.selectedConcepts == undefined || this.selectedConcepts.length < 1) {
            this.setValidationMessage(true, "Please select a concept.");
            return true;
        }
        else {
            this.setValidationMessage(false, "");
        }

        if (this.date == undefined || this.date.toString().trim() == "") {
            this.setValidationMessage(true, "Please select date.");
            return true;
        }
        else {
            this.setValidationMessage(false, "");
        }

        if (this.numberOfPeriods == undefined || this.numberOfPeriods < 2 || this.numberOfPeriods > 6 || this.numberOfPeriods.toString().trim() == "") {
            this.setValidationMessage(true, "Please enter number of periods ranging from 2-6.");
            return true;
        }
        else {
            this.setValidationMessage(false, "");
        }

        this.GetDashboardData();
    }

    GetDashboardData() {
        this.isLoading = true;

        let rmsDashboardRm: RmsDashboardRm = {
            conceptIds: this.selectedConcepts,
            numberOfPeriods: Number(this.numberOfPeriods),
            periodEndDate: this.convertDateToISOString(this.date)
        }

        let rmsDashboardAdFeeCapsBreachedRm: RmsDashboardRm = {
            numberOfPeriods: null,
            periodEndDate: null,
            conceptIds: this.selectedConcepts
        }

        let salesVariancePromise = this.rmsDashboardClient.rmsDashboardSalesVariance(rmsDashboardRm);
        let missingReportsPromise = this.rmsDashboardClient.rmsDashboardMissingReports(rmsDashboardRm);
        let feeVariancePromise = this.rmsDashboardClient.rmsDashboardFeeVariance(rmsDashboardRm);
        let adFeeCapsBreachedPromise = this.rmsDashboardClient.rmsDashboardAdFeeCapsBreached(rmsDashboardAdFeeCapsBreachedRm);

        Promise.all([salesVariancePromise, missingReportsPromise, feeVariancePromise, adFeeCapsBreachedPromise])
            .then(([salesVarianceResponse, missingReportsResponse, feeVarianceResponse, adFeeCapsBreachedResponse]) => {

                if (salesVarianceResponse && salesVarianceResponse.result) {
                    this.$rootScope.$emit('salesVarianceDataChanged', {
                        salesVarianceData: salesVarianceResponse.result,
                        numberOfPeriods: this.numberOfPeriods
                    });
                }

                if (missingReportsResponse && missingReportsResponse.result) {
                    this.$rootScope.$emit('missingReportsDataChanged', {
                        missingReportsData: missingReportsResponse.result,
                        numberOfPeriods: this.numberOfPeriods
                    });
                }

                if (feeVarianceResponse && feeVarianceResponse.result) {
                    this.$rootScope.$emit('feeVarianceDataChanged', {
                        feeVarianceData: feeVarianceResponse.result,
                        numberOfPeriods: this.numberOfPeriods
                    });
                }

                if (adFeeCapsBreachedResponse && adFeeCapsBreachedResponse.result) {
                    this.$rootScope.$emit('adFeeCapsBreachedDataChanged', {
                        adFeeCapsBreachedData: adFeeCapsBreachedResponse.result
                    });
                }
            })
            .catch(error => {
                alert("Error! Unable to load RMS dashboard data.");
                console.log("Error! Unable to load RMS dashboard data.")
            })
            .finally(() => {
                this.isLoading = false;
                this.$scope.$digest();
            });
    }

    convertDateToISOString(dateInput: Date) {
        let date = new Date(dateInput);
        return date.toISOString();
    }

    OnReset() {
        this.setValidationMessage(false, "");
        this.selectedConcepts = [];

        var rmsDashboardDatePicker = $("#rms_dashboard_date").data("kendoDatePicker");
        var todayDate = kendo.toString(kendo.parseDate(new Date().toString()), 'MM/dd/yyyy');
        rmsDashboardDatePicker.value(todayDate);
        rmsDashboardDatePicker.trigger("change");
        
        this.numberOfPeriods = 2;
        this.$rootScope.$emit('resetGrid', {});
    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsDashboardInput", {
            bindings: {

            },
            templateUrl: '/Templates/RMSDashboard/rmsDashboardInput.html',
            controller: rmsDashboardInputComponentController
        });
    }
}