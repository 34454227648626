import { BillingServicesComponentController } from "../Directives/Zorware/BillingServices/BillingServices";
import { BillingServicesGridComponentController } from "../Directives/Zorware/BillingServices/BillingServicesGrid";
import { AddBillingServicePopupComponentController } from "../Directives/Zorware/BillingServices/AddBillingServicePopup";

export function Init() {
    /* Zorware Modules */
    let zorwareApp = InitZorware();
    InitBillingServices();
    return zorwareApp;
}

function InitZorware() {
    let zorwareApp = angular.module('portal.zorware');
    return zorwareApp;
};

function InitBillingServices() {
    let billingServicesApp = angular.module('portal.zorware.billingServices');
    BillingServicesComponentController.BindComponent(billingServicesApp);
    BillingServicesGridComponentController.BindComponent(billingServicesApp);
    AddBillingServicePopupComponentController.BindComponent(billingServicesApp);
}