import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { LegalResourceApi } from "Interfaces/FranForce/Legal/LegalResourceApiInterfaces";
import { LegalResources } from "Interfaces/FranForce/Legal/LegalResources";

export class LegalApiResources
{
    CancelationTypeApi: LegalResourceApi.ICancelationTypeApi;
    ContractApi: LegalResourceApi.IContractApi;
    ContractInformationApi: LegalResourceApi.IContractInformationApi;
    ContractStatuApi: LegalResourceApi.IContactStatuApi;
    ContractTypeApi: LegalResourceApi.IContractTypeApi;
    MonetaryItemApi: LegalResourceApi.IMonetaryItemApi;
    OperationItemApi: LegalResourceApi.IOperationItemApi;
    PaymentForOptionApi: LegalResourceApi.IPaymentForOptionApi;
    PaymentOptionApi: LegalResourceApi.IPaymentOptionApi;
    ReportingItemApi: LegalResourceApi.IReportingItemApi;
    SectionOneApi: LegalResourceApi.ISectionOneApi;
    SectionOneMonetaryItemApi: LegalResourceApi.ISectionOneMonetaryItemApi;
    SectionOneOperationItemApi: LegalResourceApi.ISectionOneOperationItemApi;
    SectionOnePromissoryNoteApi: LegalResourceApi.ISectionOnePromissoryNoteApi;
    SectionOneReportingItemApi: LegalResourceApi.ISectionOneReportingItemApi;
    SectionThreeApi: LegalResourceApi.ISectionThreeApi;
    SectionTwoApi: LegalResourceApi.ISectionTwoApi;
    SectionTwoCancelationTypeApi: LegalResourceApi.ISectionTwoCancelationTypeApi;
    SectionTwoWaiverTypeApi: LegalResourceApi.ISectionTwoWaiverTypeApi;
    SPALoanOptionApi: LegalResourceApi.ISPALoanOptionApi;
    TimeScaleApi: LegalResourceApi.ITimeScaleApi;
    TelephoneNumberDispositionApi: LegalResourceApi.ITelephoneNumberDispositionApi;
    WaiverTypeApi: LegalResourceApi.IWaiverTypeApi;
    
    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {
        this.RefreshResources()
    }

    RefreshResources()
    {
        this.CancelationTypeApi = this.$resource<LegalResources.ICancelationType>(this.apiConfig.AppSettings.CoreApiRootUrl + "/CancelationType/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.ContractApi = this.$resource<LegalResources.IContract>(this.apiConfig.AppSettings.CoreApiRootUrl + "/Contract/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.ContractInformationApi = this.$resource<LegalResources.IContractInformation>(this.apiConfig.AppSettings.CoreApiRootUrl + "/ContractInformation/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.ContractStatuApi = this.$resource<LegalResources.IContractStatu>(this.apiConfig.AppSettings.CoreApiRootUrl + "/ContractStatu/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.ContractTypeApi = this.$resource<LegalResources.IContractType>(this.apiConfig.AppSettings.CoreApiRootUrl + "/ContractType/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.MonetaryItemApi = this.$resource<LegalResources.IMonetaryItem>(this.apiConfig.AppSettings.CoreApiRootUrl + "/MonetaryItem/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.OperationItemApi = this.$resource<LegalResources.IOperationItem>(this.apiConfig.AppSettings.CoreApiRootUrl + "/OperationItem/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.PaymentForOptionApi = this.$resource<LegalResources.IPaymentForOption>(this.apiConfig.AppSettings.CoreApiRootUrl + "/PaymentForOption/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.PaymentOptionApi = this.$resource<LegalResources.IPaymentOption>(this.apiConfig.AppSettings.CoreApiRootUrl + "/PaymentOption/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.ReportingItemApi = this.$resource<LegalResources.IReportingItem>(this.apiConfig.AppSettings.CoreApiRootUrl + "/ReportingItem/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.SectionOneApi = this.$resource<LegalResources.ISectionOne>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SectionOne/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.SectionOneMonetaryItemApi = this.$resource<LegalResources.ISectionOneMonetaryItem>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SectionOneMonetaryItem/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.SectionOneOperationItemApi = this.$resource<LegalResources.ISectionOneOperationItem>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SectionOneOperationItem/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.SectionOnePromissoryNoteApi = this.$resource<LegalResources.ISectionOnePromissoryNote>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SectionOnePromissoryNote/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.SectionOneReportingItemApi = this.$resource<LegalResources.ISectionOneReportingItem>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SectionOneReportingItem/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.SectionThreeApi = this.$resource<LegalResources.ISectionThree>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SectionThree/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.SectionTwoApi = this.$resource<LegalResources.ISectionTwo>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SectionTwo/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.SectionTwoCancelationTypeApi = this.$resource<LegalResources.ISectionTwoCancelationType>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SectionTwoCancelationType/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.SectionTwoWaiverTypeApi = this.$resource<LegalResources.ISectionTwoWaiverType>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SectionTwoWaiverType/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.SPALoanOptionApi = this.$resource<LegalResources.ISPALoanOption>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SPALoanOption/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.TimeScaleApi = this.$resource<LegalResources.ITimeScale>(this.apiConfig.AppSettings.CoreApiRootUrl + "/TimeScale/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.TelephoneNumberDispositionApi = this.$resource<LegalResources.ITelephoneNumberDisposition>(this.apiConfig.AppSettings.CoreApiRootUrl + "/TelephoneNumberDisposition/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        this.WaiverTypeApi = this.$resource<LegalResources.IWaiverType>(this.apiConfig.AppSettings.CoreApiRootUrl + "/WaiverType/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
    }

    SectionOneCompleted(contractID: string, sectionOneID: number): ng.IHttpPromise<any>
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/ContractEmailNotification/SectionOneCompleted/" + contractID + "/" + sectionOneID
        };
        return this.$http<any>(config);
    }

    SectionThreeCompleted(contractID: string, sectionThreeID: number): ng.IHttpPromise<any> {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/ContractEmailNotification/SectionThreeCompleted/" + contractID + "/" + sectionThreeID
        };
        return this.$http<any>(config);
    }

    SectionOneDenied(contractID: string, sectionOneID: number): ng.IHttpPromise<any> {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/ContractEmailNotification/SectionOneDenied/" + contractID + "/" + sectionOneID
        };
        return this.$http<any>(config);
    }

    SectionTwoCompleted(contractID: string, sectionTwoID: number): ng.IHttpPromise<any> {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/ContractEmailNotification/SectionTwoCompleted/" + contractID + "/" + sectionTwoID
        };
        return this.$http<any>(config);
    }

    SectionTwoDenied(contractID: string, sectionTwoID: number): ng.IHttpPromise<any> {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/ContractEmailNotification/SectionTwoDenied/" + contractID + "/" + sectionTwoID
        };
        return this.$http<any>(config);
    }

    SectionThreeDenied(contractID: string, sectionThreeID: number): ng.IHttpPromise<any> {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/ContractEmailNotification/SectionThreeDenied/" + contractID + "/" + sectionThreeID
        };
        return this.$http<any>(config);
    }

    GetSectionOne(contractID: string): ng.IHttpPromise<any> {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/SectionOne/GetSectionOne/" + contractID
        };
        return this.$http<any>(config);
    }

    GetPromissoryNotes(franchiseID: string): ng.IHttpPromise<any> {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/SectionOne/GetPromissoryNotes/" + franchiseID
        };
        return this.$http<any>(config);
    }

    GetSelectedMultiselects(franchiseID: string): ng.IHttpPromise<any> {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/SectionOne/GetSelectedMultiselects/" + franchiseID
        };
        return this.$http<any>(config);
    }

    CancelContract(contractID: string): ng.IHttpPromise<any> {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/Contract/CancelContract/" + contractID
        };
        return this.$http<any>(config);
    }

    EndCurePeriod(contractID: string): ng.IHttpPromise<any> {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/Contract/EndCurePeriod/" + contractID
        };
        return this.$http<any>(config);
    }
}