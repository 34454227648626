import { RgbaConvert } from "Utility/RgbaConvert";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

export class ConceptWebThemeComponentController implements ng.IController {
	//---- Bindings ----
	conceptId: number;
	//------------------

	primaryColor: string;
	secondaryColor: string;
	navigationColor: string;
	linkColor: string;
	linkPalette: string[];

	errorMessage: string = '';
	imageUrl: any;
	newImage: any = null;
	newVanImage: any = null;
	vanImageUrl: string;
	newVanImageUrl: string;
	rawNewImage: any = null;
	rawNewVanImage: any = null;
	conceptImage: kendo.ui.Upload;
	conceptVanImage: any;
	Validations: kendo.ui.UploadValidation;

	concept: CoreEntities.Concept;

	static $inject = [ 'coreApiResources', '$interval', '$q', '$log' ];

	constructor(
		private coreApiResources: CoreApiResources,
		private $interval: angular.ITimeoutService,
		private $q: ng.IQService,
		private $log: ng.ILogService
	) {}

	$onInit() {
		this.Validations = { allowedExtensions: [ '.png' ] };
		this.RefreshData();
		this.linkPalette = [ '#FFFFFF', '#000000' ];
		this.$interval(() => this.PreviewImage(), 3000);
		this.$interval(() => this.PreviewVanImage(), 3000);
	}

	RefreshData() {
		let conceptParams = {
			id: this.conceptId,
			$select: 'ConceptId,DisplayName,WebThemeId,WebTheme',
			$expand: 'WebTheme'
		};

		this.coreApiResources.GetConceptImageUrl(this.conceptId).then((url) => (this.imageUrl = url));
		this.coreApiResources.GetConceptVanImageUrl(this.conceptId).then((url) => (this.vanImageUrl = url));

		return this.coreApiResources.ConceptApi.get(conceptParams).$promise.then((concept) => {
			this.concept = concept;
			if (!this.concept.WebThemeId) {
				this.concept.WebTheme = new this.coreApiResources.WebThemeApi();
				this.primaryColor = '#000000';
				this.secondaryColor = '#000000';
				this.navigationColor = '#000000';
				this.linkColor = '#FFFFFF';
			} else {
				this.primaryColor = RgbaConvert.HexNoAlpha(this.concept.WebTheme.PrimaryColorArgb >>> 0);
				this.secondaryColor = RgbaConvert.HexNoAlpha(this.concept.WebTheme.SecondaryColorArgb >>> 0);
				this.navigationColor = RgbaConvert.HexNoAlpha(this.concept.WebTheme.NavigationPrimaryColorArgb >>> 0);
				this.linkColor = RgbaConvert.HexNoAlpha(this.concept.WebTheme.LinkPrimaryColorArgb >>> 0);
			}
		});
	}

	Cancel() {
		return this.RefreshData();
	}

	Save() {
		this.concept.WebTheme.PrimaryColorArgb = RgbaConvert.Num(this.primaryColor) - Math.pow(2, 32);
		this.concept.WebTheme.SecondaryColorArgb = RgbaConvert.Num(this.secondaryColor) - Math.pow(2, 32);
		this.concept.WebTheme.NavigationPrimaryColorArgb = RgbaConvert.Num(this.navigationColor) - Math.pow(2, 32);
		this.concept.WebTheme.LinkPrimaryColorArgb = RgbaConvert.Num(this.linkColor) - Math.pow(2, 32);

		let savePromise: ng.IPromise<any>;

		if (this.concept.WebThemeId) {
			savePromise = this.coreApiResources.WebThemeApi.update(
				{ id: this.concept.WebThemeId },
				this.concept.WebTheme
			).$promise;
		} else {
			savePromise = this.coreApiResources.WebThemeApi
				.save({}, this.concept.WebTheme)
				.$promise.then((newTheme) => {
					return this.coreApiResources.ConceptApi.patch(
						{ id: this.concept.ConceptId, extraInt32: 'WebThemeId|' + newTheme.WebThemeId },
						{}
					).$promise;
				});
		}

		return savePromise.then(() => {
			return this.RefreshData();
		});
	}

	UploadImage() {
		if (this.newImage) {
			return this.coreApiResources.UpdateConceptImage(this.conceptId, this.newImage).then((result) => {
				let updatedBlob: any = result.data;
				if (updatedBlob.Success) {
					this.imageUrl = updatedBlob.CloudBlobUri + '?rad=' + Math.random();
					this.conceptImage.clearAllFiles();
					this.newImage = null;
				} else {
					this.errorMessage = updatedBlob.Message;
				}
			});
		}
	}
	UploadVanImage() {
		if (this.newVanImage) {
			return this.coreApiResources.UpdateConceptVanImage(this.conceptId, this.newVanImage).then((result) => {
				let updatedBlob: any = result.data;
				if (updatedBlob.Success) {
					this.vanImageUrl = updatedBlob.CloudBlobUri + '?rad=' + Math.random();
					this.conceptVanImage.clearAllFiles();
					this.newVanImage = null;
				} else {
					this.errorMessage = updatedBlob.Message;
				}
			});
		}
	}

	PreviewImage() {
		if (this.conceptImage.getFiles()[0]) {
			if (!this.rawNewImage || this.rawNewImage !== this.conceptImage.getFiles()[0].rawFile) {
				this.rawNewImage = this.conceptImage.getFiles()[0].rawFile;

				if (this.rawNewImage.type === 'image/png') {
					let imageReader = new FileReader();
					imageReader.onload = (ev) => (this.newImage = imageReader.result);
					imageReader.readAsDataURL(this.rawNewImage);
				} else {
					this.newImage = null;
					this.rawNewImage = null;
				}
			}
		} else {
			this.newImage = null;
			this.rawNewImage = null;
		}
	}
	PreviewVanImage() {
		if (this.conceptVanImage && this.conceptVanImage.getFiles()[0])
		{
			if (!this.rawNewVanImage || this.rawNewVanImage !== this.conceptVanImage.getFiles()[0].rawFile) {
				this.rawNewVanImage = this.conceptVanImage.getFiles()[0].rawFile;

				if (this.rawNewVanImage.type === 'image/png') {
					let imageReader = new FileReader();
					imageReader.onload = (ev) => (this.newVanImage = imageReader.result);
					imageReader.readAsDataURL(this.rawNewVanImage);
				} else {
					this.newVanImage = null;
					this.rawNewVanImage = null;
				}
			}
		} else {
			this.newVanImage = null;
			this.rawNewVanImage = null;
		}
	}
	onSelect(e) {
		if (e.files[0].rawFile.type === 'image/png') {
			let imageReader = new FileReader();
			imageReader.onload = (ev) => (this.newVanImage = imageReader.result);
			imageReader.readAsDataURL(e.files.rawFile);
		}
	}

	static BindComponent(app: ng.IModule) {
		app.component('conceptWebTheme', {
			bindings: {
				conceptId: '<'
			},
			controller: ConceptWebThemeComponentController,
			templateUrl: '/Templates/Concepts/ConceptWebTheme.html'
		});
	}
}
