import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RmsDisclosureDocumentSelectorComponentController implements ng.IController 
{
    franchisorId: number;
    disclosureDocumentId: number;
    readOnly: boolean;
    onSelect: (params: { disclosureDocument: RpmEntities.DisclosureDocumentViewModel }) => void;
    makeReadOnly: string;
    selectedDisclosureDocumentId: number;
    dropDown: kendo.ui.DropDownList;

    static $inject = [
        'rpmUiApiResources',
        '$timeout'
    ]

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private $timeout: ng.ITimeoutService
    )
    {

    }

    $onInit()
    {
    }

    $postLink()
    {
        this.dropDown.setOptions(<kendo.ui.DropDownListOptions>{
            autoWidth: true,
            dataTextField: nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.Year),
            dataValueField: nameof<RpmEntities.DisclosureDocumentViewModel>(o => o.DisclosureDocumentId),
            enable: !this.readOnly,
            select: (e) =>
            {
                if (this.onSelect) {
                    this.onSelect({ disclosureDocument: e.dataItem });
                }
            }
        });
        this.dropDown.setDataSource(new kendo.data.DataSource({
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) =>
                {
                    if (!this.franchisorId) {
                        options.success([]);
                        return;
                    }

                    this.rpmUiApiResources.GetDisclosureDocumentsByFranchisorId(this.franchisorId)
                        .then((documentsResponse) =>
                        {
                            options.success(documentsResponse.data);
                            this.selectedDisclosureDocumentId = this.disclosureDocumentId;
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        });
                }
            }
        }));
    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
        if (onChanges[nameof(this.franchisorId)] && this.dropDown && this.franchisorId) {
            this.dropDown.dataSource.read();
        }
        if (onChanges[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.setOptions({ enable: !this.readOnly })
        }

        if (onChanges[nameof(this.disclosureDocumentId)] && this.dropDown) {
            this.selectedDisclosureDocumentId = this.disclosureDocumentId;
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("rmsDisclosureDocumentSelector", {
                bindings: {
                    [nameof<RmsDisclosureDocumentSelectorComponentController>(o => o.franchisorId)]: "<",
                    [nameof<RmsDisclosureDocumentSelectorComponentController>(o => o.disclosureDocumentId)]: "<",
                    [nameof<RmsDisclosureDocumentSelectorComponentController>(o => o.readOnly)]: "<",
                    [nameof<RmsDisclosureDocumentSelectorComponentController>(o => o.makeReadOnly)]: "<",
                    [nameof<RmsDisclosureDocumentSelectorComponentController>(o => o.onSelect)]: "&?",
                },
                template: `<select kendo-drop-down-list="$ctrl.${nameof<RmsDisclosureDocumentSelectorComponentController>(o => o.dropDown)}"
                                    ng-model="$ctrl.${nameof<RmsDisclosureDocumentSelectorComponentController>(o => o.selectedDisclosureDocumentId)}"
                                    ng-readonly="$ctrl.${nameof<RmsDisclosureDocumentSelectorComponentController>(o => o.makeReadOnly)}">
                        </select>`,
                controller: RmsDisclosureDocumentSelectorComponentController
            });
    }
}

