import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

interface INewServiceScope {
    services: CoreResources.IService[];
    serviceSkus: CoreResources.IServiceSku[];

    newService: CoreResources.IService;
    residentialSku: CoreResources.IServiceSku;
    commercialSku: CoreResources.IServiceSku;

    addResidentialSku: boolean;
    addCommercialSku: boolean;

    CreateService: () => void;
}

export class NewServiceController
{
    static $inject = [
        '$scope',
        'coreApiResources',
        '$window',
    ];

    constructor(
        private $scope: INewServiceScope,
        private coreApiResources: CoreApiResources,
        private $window: ng.IWindowService
    )
    {
        $scope.services = <CoreResources.IService[]>coreApiResources.ServiceApi.query({});

        $scope.newService = <CoreResources.IService>new coreApiResources.ServiceApi();
        $scope.newService.Name = "";
        $scope.newService.Description = "";
        $scope.newService.ServiceSkus = [];

        $scope.addResidentialSku = false;
        $scope.addCommercialSku = false;

        $scope.residentialSku = <CoreResources.IServiceSku>new coreApiResources.ServiceSkuApi();
        $scope.residentialSku.ServiceSkuId = 0;
        $scope.residentialSku.ServiceType = "Residential";
        $scope.residentialSku.SKU = "";

        $scope.commercialSku = <CoreResources.IServiceSku>new coreApiResources.ServiceSkuApi();
        $scope.commercialSku.ServiceSkuId = 0;
        $scope.commercialSku.ServiceType = "Commercial";
        $scope.commercialSku.SKU = "";

        $scope.CreateService = () =>
        {
            if ($scope.newService.Name.length <= 2) {
                alert("Service name is too short.");
                return;
            }

            if (!$scope.addResidentialSku && !$scope.addCommercialSku) {
                alert(`Service '${$scope.newService.Name}' needs to be Residential, Commercial, or both.`);
                return;
            }

            $scope.newService.Name = $scope.newService.Name.trim();
            $scope.residentialSku.SKU = ($scope.residentialSku.SKU || "").trim();
            $scope.commercialSku.SKU = ($scope.commercialSku.SKU || "").trim();

            $scope.services = <CoreResources.IService[]>coreApiResources.ServiceApi.query({}, () =>
            {
                let checkResidentialSku = ($scope.addResidentialSku && $scope.residentialSku.SKU && $scope.residentialSku.SKU.trim());
                let checkCommercialSku = ($scope.addCommercialSku && $scope.commercialSku.SKU && $scope.commercialSku.SKU.trim());

                for (var service of $scope.services) {
                    if (service.Name.toLowerCase() === $scope.newService.Name.toLowerCase()) {
                        alert(`Service already exists as name '${service.Name}', ServiceId: '${service.ServiceId}'.`);
                        return;
                    }
                }

                $scope.serviceSkus = <CoreResources.IServiceSku[]>coreApiResources.ServiceSkuApi.query({}, () =>
                {
                    for (let serviceSku of $scope.serviceSkus) {
                        if (serviceSku.SKU) {
                            if (checkResidentialSku && serviceSku.SKU.toLowerCase().trim() === $scope.residentialSku.SKU.toLowerCase().trim()) {
                                alert(`${$scope.residentialSku.ServiceType} SKU '${serviceSku.SKU}' is being used by '${serviceSku.Name}'. ServiceId: '${serviceSku.ServiceId}'`);
                                return;
                            }

                            if (checkCommercialSku && serviceSku.SKU.toLowerCase().trim() === $scope.commercialSku.SKU.toLowerCase().trim()) {
                                alert(`${$scope.commercialSku.ServiceType} SKU '${serviceSku.SKU}' is being used by '${serviceSku.Name}'. ServiceId: '${serviceSku.ServiceId}'`);
                                return;
                            }
                        }
                    }

                    if ($scope.addResidentialSku) {
                        $scope.residentialSku.ServiceType = "Residential";
                        $scope.residentialSku.Name = `${$scope.newService.Name} (${$scope.residentialSku.ServiceType})`;

                        $scope.newService.ServiceSkus.push($scope.residentialSku);
                    }

                    if ($scope.addCommercialSku) {
                        $scope.commercialSku.ServiceType = "Commercial";
                        $scope.commercialSku.Name = `${$scope.newService.Name} (${$scope.commercialSku.ServiceType})`;

                        $scope.newService.ServiceSkus.push($scope.commercialSku);
                    }

                    $scope.newService.$save((savedService: CoreResources.IService) =>
                    {
                        $window.location.href = `/Services/Edit/${savedService.ServiceId}`;
                    }, () =>
                    {
                        alert("Error occured during save.");
                    });
                }, () =>
                    {
                        alert("Error occured during save. Could not get the current list of service skus.");
                    });
            }, () =>
            {
                alert("Error occured during save. Could not get the current list of services.");
            });
        }
    }
}