//Globally injected into main template.
declare var AppSettings: IAppSettings;
declare var FranForceConstants: IFranForceConstants;

export class ApiConfig
{
    AppSettings: IAppSettings;
    FranForceConstants: IFranForceConstants;

    constructor()
    {
        this.AppSettings = AppSettings;
        this.FranForceConstants = FranForceConstants;
        this.FranForceConstants.ResponseHeaders = {
            CreatedEntityId: 'Created-Entity-Id'
        }
    }

    static Default = new ApiConfig();
}
