import * as _ from "underscore";
import * as elements from "typed-html";

import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

import * as rmsClients from "Clients/Rms";
import { MonthsEnabledController, MonthsEnabledControllerOnInit, MonthsEnabledControllerOnSelect, MonthEnabled } from "Directives/Common";
import { StringProperties } from "Types/StringProperties";
import { AngularUtil } from "Utility/AngularUtil";
import { ExpressionBinding } from "Utility/AngularUtil";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { royaltyContractRatePlanMinimumAppliedMonthDataModel } from "../../../../Models/Royalty/RoyaltyContractRatePlanMinimumAppliedMonth/RoyaltyContractRatePlanMinimumAppliedMonthDataModel";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            cmRoyaltiesRatePlanMinimumsMonthsApplied: Partial<StringProperties<cmRoyaltyContractRatePlanMinimumsMonthsApplied>>;
        }
    }
}

export type cmRoyaltyContractRatePlanMinimumsMonthsAppliedOnInit = { self: cmRoyaltyContractRatePlanMinimumsMonthsApplied };
export type cmRoyaltyContractRatePlanMinimumsMonthsAppliedOnSelect = MonthsEnabledControllerOnSelect;

const defaultMonthEnabled: boolean = false;

export class cmRoyaltyContractRatePlanMinimumsMonthsApplied implements ng.IController
{
    onInit: ExpressionBinding<cmRoyaltyContractRatePlanMinimumsMonthsAppliedOnInit>;
    onSelect: ExpressionBinding<cmRoyaltyContractRatePlanMinimumsMonthsAppliedOnSelect>;

    public isLoading: boolean;
    private royaltyContractRatePlanMinimumId: number;

    private monthsEnabledController: MonthsEnabledController;
    private monthsClient = rmsClients.royaltyContractRatePlanMinimumMonthsAppliedClient;

    static $inject = [
        '$timeout',
        'coreApiResources',
        'royaltyApiResources'
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private coreApiResources: CoreApiResources,
        private royaltyApiResources: RoyaltyApiResources
    )
    {
        this.isLoading = false;
    }

    $onInit() 
    {
    }

    $postLink()
    {
        if (this.onInit)
        {
            this.onInit({ self: this });
        }
    }

    async CancelChanges()
    {
        await this.LoadMonthsData(this.royaltyContractRatePlanMinimumId);
    }

    HasChanges = () => this.monthsEnabledController.HasChanges();
    
    async SaveChanges()
    {
        return await AngularUtil.TrackLoadingPromise((async () => {
            return cmRoyaltyContractRatePlanMinimumsMonthsApplied.SaveMonthsEnabled(
                this.monthsEnabledController.GetMonths(),
                this.royaltyContractRatePlanMinimumId,
                this.coreApiResources
            );
        })(), this);
    }

    async MonthsEnabledInit(controller: MonthsEnabledController)
    {
        this.monthsEnabledController = controller;
    }

    SetMonthsData(royaltyContractRatePlanMinimumId: number, monthsEnabled: MonthEnabled[], dirty: boolean = false)
    {
        this.royaltyContractRatePlanMinimumId = royaltyContractRatePlanMinimumId;
        this.monthsEnabledController.Init(monthsEnabled, dirty);
    }

    async LoadMonthsData(royaltyContractRatePlanMinimumId: number)
    {
        this.royaltyContractRatePlanMinimumId = royaltyContractRatePlanMinimumId;

        return await AngularUtil.TrackLoadingPromise((async () =>
        {
            if (this.royaltyContractRatePlanMinimumId == 0) {
                let months = [];
                for (let i = 0; i < 12; i++) {
                    let month: royaltyContractRatePlanMinimumAppliedMonthDataModel = {
                        royaltyContractRatePlanMinimumId: 0,
                        enabled: true,
                        monthId: i+1
                    }
                    months.push(month)
                }
                this.monthsEnabledController.InitAllMonths((month) => {
                    return (
                        months.find(m => m.monthId == month.id)?.enabled ?? defaultMonthEnabled
                    );
                });
            }
            else {
                //let months = await this.monthsClient.Query({
                //    $filter: `${nameof<RpmEntities.RoyaltyContractRatePlanMinimumMonthsApplied>(o => o.RoyaltyContractRatePlanMinimumId)} eq ${this.royaltyContractRatePlanMinimumId}`
                //});

                let months = await this.royaltyApiResources.GetRoyaltyContractRatePlanMinimumAppliedMonth(this.royaltyContractRatePlanMinimumId).then((response) => {
                    return response.data;
                })

                this.monthsEnabledController.InitAllMonths((month) => {
                    return (
                        months.find(m => m.monthId == month.id)?.enabled ?? defaultMonthEnabled
                    );
                });
            }
        })(), this);
    }

    private OnSelect(changed: MonthsEnabledControllerOnSelect["changed"], all: MonthsEnabledControllerOnSelect["all"])
    {
        if (this.onSelect)
        {
            this.onSelect({
                all: all,
                changed: changed
            });
        }
    }

    static async SaveMonthsEnabled(months: MonthEnabled[], royaltyContractRatePlanMinimumId: number, coreApiResources: CoreApiResources)
    {
        let monthSettings = months
            .map<RpmEntities.RoyaltyContractRatePlanMinimumMonthsApplied>(m =>
            {
                return {
                    RoyaltyContractRatePlanMinimumId: royaltyContractRatePlanMinimumId,
                    Enabled: m.enabled,
                    MonthId: m.month.id,
                    RoyaltyContractRatePlanMinimum: null
                }
            });
        //let promises = monthSettings.map(m => rmsClients.royaltyContractRatePlanMinimumMonthsAppliedClient.Post(m));
        let promises = monthSettings.map(m => coreApiResources.RoyaltyContractRatePlanMinimumMonthsApplied(m).then((response) => {
            return response.data;
        }));
        monthSettings = await Promise.all(promises);
        return monthSettings;
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.cmRoyaltiesRatePlanMinimumsMonthsApplied);

        let template = (
            <monthsEnabled
                onInit={`$ctrl.${nameof<cmRoyaltyContractRatePlanMinimumsMonthsApplied>(o => o.MonthsEnabledInit)}(${nameof<MonthsEnabledControllerOnInit>(o => o.self)})`}
                onSelect={`$ctrl.${nameof<cmRoyaltyContractRatePlanMinimumsMonthsApplied>(o => o.OnSelect)}(${nameof<MonthsEnabledControllerOnSelect>(o => o.changed)}, ${nameof<MonthsEnabledControllerOnSelect>(o => o.all)})`}
                >
            </monthsEnabled>
        );

        app.component(componentName, {
                bindings: {
                    [nameof<cmRoyaltyContractRatePlanMinimumsMonthsApplied>(o => o.onInit)]: "&?",
                    [nameof<cmRoyaltyContractRatePlanMinimumsMonthsApplied>(o => o.onSelect)]: "&?"
                },
                template: template,
                controller: cmRoyaltyContractRatePlanMinimumsMonthsApplied
            });
    }
}

