import { IdentityManager } from "Services/Resources/IdentityManager";

export class IttMainController implements ng.IController
{
    static $inject = [
        "$scope",
        "identityManager",
    ];

    constructor(
        private $scope: any,
        private identityManager: IdentityManager,
    )
    {
        $scope.TestName = "Franchise Name";
        $scope.FranchiseName = "";
        $scope.hasSectionTwo = false;
        $scope.hasSectionThree = false;
        $scope.isView = false;

        $scope.$on('setFranchiseDetails', function (event, data)
        {
            $scope.FranchiseName = data.FranchiseName;
            $scope.LicenseNum = data.LicenseNum;
            $scope.FranchiseeCode = data.FranchiseeCode;
            $scope.FranchiseAddress = data.FranchiseAddress;
            $scope.FranchisePhoneNumber = data.FranchisePhoneNumber;
            $scope.FranchiseEmail = data.FranchiseEmail;
        });

        $scope.$on('setSectionAvaliability', function (event, data)
        {
            $scope.hasSectionTwo = data.hasSectionTwo;
            $scope.hasSectionThree = data.hasSectionThree;
            $scope.franchiseID = data.franchiseID;
            $scope.contractID = data.contractID;
        });

        $scope.GoToSection = (section: string) =>
        {
            window.location.href = "/Itt/View" + section + "/" + $scope.franchiseID + "/" + $scope.contractID;
        }
    }
}
