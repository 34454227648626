import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { AngularUtil } from "Utility/AngularUtil";
import { KendoUtil } from "Utility/KendoUtil";

import { ApiConfig } from "AppConfig/ApiConfig";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

interface IIndexUserScope
{
    isLoading: boolean;
    canAddUsers: boolean;
    isAdmin: boolean;
    allRoles: IRoleViewModel[];
    managedUsers: IManagedUserViewModel[];
    gridOptions: kendo.ui.GridOptions;
    loggedInUserEmail: string;
}

export class IndexUserController implements ng.IController 
{

    //Popup
    expireUserPasswordByRolesPopup: kendo.ui.Window;

    isLoading: boolean;
    userRolesMultiSelect: kendo.ui.MultiSelect;
    userRolesMultiSelectOptions: kendo.ui.MultiSelectOptions;
    selectedRoles : IRoleViewModel[];

    static $inject = [
        '$scope',
        'coreApiResources',
        'identityManager',
        '$window',
        '$timeout',
        "apiConfig",
    ];

    constructor(
        private $scope: IIndexUserScope,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private $window: ng.IWindowService,
        private $timeout: ng.ITimeoutService,
        private apiConfig: ApiConfig,
    )
    {
        this.Init();
    }

    Init()
    {
        this.$scope.isLoading = true;
        this.InitAsync().finally(() =>
        {
            this.$timeout(() =>
            {
                this.$scope.isLoading = false;
            });
        });
    }

    openPopup()
    {
        let options: kendo.ui.WindowOptions = {
            modal: true,
            position: { top: 50 },
        
        };

        this.expireUserPasswordByRolesPopup.open();
        this.expireUserPasswordByRolesPopup.title('Expire User passwords by Role');
        this.expireUserPasswordByRolesPopup.center();
        this.expireUserPasswordByRolesPopup.setOptions(options);
    }

    async InitAsync()
    {
        let franchises: CoreResources.IFranchise[] = await this.coreApiResources.FranchiseApi.query({
            $select: "FranchiseId,LicenseNumber,FranchiseeName,DoingBusinessAs"
        }).$promise;

        let concepts: CoreResources.IConcept[] = await this.coreApiResources.ConceptApi.query().$promise;
        let managedRoles: IRoleViewModel[] = (await this.identityManager.GetManagedRoles()).data;

        this.identityManager.GetLoggedInUserInfo()
            .then((loggedInUser) =>
            {

                this.$scope.isAdmin = this.identityManager.UserHasRole(
                    loggedInUser.data,
                    this.apiConfig.FranForceConstants.RoleConstants.AdminRole
                );
            });

        this.$scope.canAddUsers = (managedRoles.length > 0);
        try
        {
            let loggedInUser = (await this.identityManager.GetLoggedInUserInfo()).data;
            this.$scope.loggedInUserEmail = loggedInUser.Email;
            this.$scope.canAddUsers = !this.identityManager.UserHasRole(loggedInUser, this.apiConfig.FranForceConstants.RoleConstants.HelpDeskRole);
        }
        catch (err)
        {
            this.$scope.canAddUsers = false;
            if (err.status === 403 && err.status === 401) //Forbidden / Unauthorized
            {
                console.log(err);
            }

            throw err;
        }
        this.$scope.allRoles = (await this.identityManager.GetRoles()).data;
        this.$scope.managedUsers = (await this.identityManager.GetManagedUsers({ readonly: !this.$scope.canAddUsers })).data;

        let rolesDict = {};
        for (let role of this.$scope.allRoles)
        {
            rolesDict[role.Id.toLowerCase()] = role;
        }

        for (let user of this.$scope.managedUsers)
        {
            user.IsLoggedInUser = user.Email.toLowerCase() === this.$scope.loggedInUserEmail.toLowerCase();
            let stringClaims = [];
            let stringFranNames = [];

            for (let claim of user.Claims)
            {
                let addClaim = false;
                let franName = "";
                let claimType = claim.Type === this.apiConfig.FranForceConstants.ClaimConstants.OwnedFranchiseIdClaimType ? "Owned - " : "";
                claimType = claim.Type === this.apiConfig.FranForceConstants.ClaimConstants.EmployedFranchiseIdClaimType ? "Employed - " : claimType;
                claimType = claim.Type === this.apiConfig.FranForceConstants.ClaimConstants.ManagedConceptIdClaimType ? "Managed Concept - " : claimType;
                let claimInfo = "";
                if (claim.Type === this.apiConfig.FranForceConstants.ClaimConstants.ManagedConceptIdClaimType)
                {
                    let conceptClaim = concepts.filter(c => c.ConceptId.toString() === claim.Value)[0];
                    if (conceptClaim != null)
                    {
                        claimInfo = conceptClaim.ConceptCode;
                        addClaim = true;
                    }
                }
                else if (claim.Type === this.apiConfig.FranForceConstants.ClaimConstants.OwnedFranchiseIdClaimType || claim.Type === this.apiConfig.FranForceConstants.ClaimConstants.EmployedFranchiseIdClaimType)
                {
                    let fran = franchises.filter(f => f.FranchiseId.toString() === claim.Value)[0];
                    if (fran != null)
                    {
                        claimInfo = fran.LicenseNumber + " - " + fran.DoingBusinessAs;
                        franName = fran.FranchiseeName;
                        addClaim = true;
                    }
                }
                if (addClaim)
                {
                    stringClaims.push(claimType + claimInfo);
                    stringFranNames.push(franName);
                }
            }
            user.ClaimsString = stringClaims.join("<br/>");
            user.FranchiseeNameString = stringFranNames.join("<br/>");
            user.RolesString = user.RoleIds.map(id => rolesDict[id.toLowerCase()].Name).join(", ");
        }

        this.InitGridOptions();
        this.SetDropDownOptions();
        this.$scope.gridOptions.dataSource.filter = { field: "IsEnabled", operator: "contains", value: true }
    }

    async SetDropDownOptions()
    {
        this.userRolesMultiSelectOptions =
        {
            placeholder: "Select User Roles...",
            dataTextField: "Name",
            dataValueField: "Id",
            valuePrimitive: false,
            autoBind: true,
            autoClose: false,
            dataSource: this.$scope.allRoles,
            dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) =>
            {
                this.userRolesMultiSelect = e.sender;
            },
        };
    }

    InitGridOptions()
    {
        let mainGrid = angular.element("#mainGrid");
        let windowEle = angular.element(this.$window);
        this.$scope.gridOptions = {
            dataSource: { data: this.$scope.managedUsers },
            sortable: true,
            resizable: true,
            pageable: {
                numeric: false,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true

            },
            filterMenuInit: function onFilterMenuInit(e) {
                if (e.field == "IsEnabled") {
                    // replace default text in filter menu
                    e.container.find(".k-filter-help-text").text("Show items with value that:");
                }
            },
            filter: function onFilter(e) {
                if (e.field === "IsEnabled") {
                    var filter = e.filter;
                    if (filter && filter.filters && filter.filters.length > 0) {
                        var filters = filter.filters;
                        // convert the filter string value to a boolean one
                        filters[0].value = (filters[0].value === "true");
                    }
                }
            },
            columns: <kendo.ui.GridColumn[]>[
                {
                    field: "Email",
                    title: "Email",
                    width: 300
                },
                {
                    field: "IsEnabled",
                    title: "Enabled",
                    encoded: false,
                    filterable: { ui: this.boolFilterTemplate }
                },
                {
                    field: "RolesString",
                    title: "Roles",
                    width: 150
                },
                {
                    field: "ClaimsString",
                    title: "Claims",
                    encoded: false
                },
                {
                    field: "FranchiseeNameString",
                    title: "Franchisee Names",
                    encoded: false
                },
                {
                    template: "<div style='text-align: center;'>" +
                        "<a class='pure-button' href='/Users/Edit/#=encodeURIComponent(Id)#' ng-show='!#= IsLoggedInUser #'>" +
                        "Edit" +
                        "</a>" +
                        "</div>",
                    width: 100
                }
            ],
            height: windowEle.height() - mainGrid.offset().top - 15
        };
    }

    boolFilterTemplate(input) {
        input.kendoDropDownList({
            dataSource: {
                data: [
                    { text: "True", value: true },
                    { text: "False", value: false }
                ]
            },
            dataTextField: "text",
            dataValueField: "value",
            valuePrimitive: true,
            optionLabel: "All"
        });
    }

    openExpirePasswordByRolePopup()
    {
        this.openPopup();
    }

    ResetPasswordByRoleClik(){
        if (!confirm(`Are you sure you want to force expire password for the selected Roles?`))
        {
            return;
        }

        this.isLoading = true;

        return this.identityManager.ResetUsersPasswordByRole(
            this.selectedRoles
        )
            .then(() =>
            {
                this.selectedRoles=[];
                alert("Success");
            })
            .catch(AngularUtil.GetJsonAlertCatch())
            .finally(() =>
            {
                this.isLoading = false;
            });
    }

}
