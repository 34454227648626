import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IRestAxiosClient, RestDeleteParameters } from "Clients/IRestAxiosClient"

export class RestAxiosClient<TObj, TId = number | string> implements IRestAxiosClient<TObj, TId>
{
    constructor(
        private client: AxiosInstance,
        private baseUrl: string,
    )
    {

    }

    async QueryRequest(parameters: ODataQueryParameters, config?: AxiosRequestConfig)
    {
        let mergedConfig = this.MergeParamsAndRequestConfig(config, parameters);

        return this.client.get<TObj[]>(this.baseUrl, mergedConfig);
    }

    async GetRequest(id: TId, parameters: ODataQueryParameters, config?: AxiosRequestConfig)
    {
        let mergedConfig = this.MergeParamsAndRequestConfig(config, parameters)

        return this.client.get<TObj>(`${this.baseUrl}/${this.IdToString(id)}`, mergedConfig);
    }

    async PostRequest(model: TObj, config?: AxiosRequestConfig)
    {
        return this.client.post<TObj>(this.baseUrl, model, config);
    }

    async PutRequest(id: TId, model: TObj, config?: AxiosRequestConfig)
    {
        return this.client.put<void>(`${this.baseUrl}/${this.IdToString(id)}`, model, config);
    }

    async PatchRequest(id: TId, model: Partial<TObj>, config?: AxiosRequestConfig)
    {
        return this.client.patch<void>(`${this.baseUrl}/${this.IdToString(id)}`, model, config);
    }

    async DeleteRequest(id: TId, deleteParams: Partial<RestDeleteParameters>, config?: AxiosRequestConfig)
    {
        let mergedConfig = this.MergeParamsAndRequestConfig(config, deleteParams);
        return this.client.delete<Partial<TObj>>(`${this.baseUrl}/${this.IdToString(id)}`, mergedConfig);
    }

    async DeleteMultipleRequest(ids: TId[], deleteParams: Partial<RestDeleteParameters>, config?: AxiosRequestConfig)
    {
        let mergedConfig = this.MergeParamsAndRequestConfig(config, deleteParams, {
            ids: ids.map(id => this.IdToString(id))
        });
        return this.client.delete<Partial<TObj>[]>(`${this.baseUrl}`, mergedConfig);
    }

    Query = async (parameters: ODataQueryParameters, config?: AxiosRequestConfig) => (await this.QueryRequest(parameters, config)).data;
    Get = async (id: TId, parameters: ODataQueryParameters, config?: AxiosRequestConfig) => (await this.GetRequest(id, parameters, config)).data;
    Post = async (model: TObj, config?: AxiosRequestConfig) => (await this.PostRequest(model, config)).data;
    Put = async (id: TId, model: TObj, config?: AxiosRequestConfig) => (await this.PutRequest(id, model, config)).data;
    Patch = async (id: TId, model: Partial<TObj>, config?: AxiosRequestConfig) => (await this.PatchRequest(id, model, config)).data;
    Delete = async (id: TId, deleteParams: Partial<RestDeleteParameters>, config?: AxiosRequestConfig) => (await this.DeleteRequest(id, deleteParams, config)).data;
    DeleteMultiple = async (ids: TId[], deleteParams: Partial<RestDeleteParameters>, config?: AxiosRequestConfig) => (await this.DeleteMultipleRequest(ids, deleteParams, config)).data;

    public IdToString: (id: TId) => string =
        (id) =>
        {
            return `${id}`;
        };

    private MergeParamsAndRequestConfig(config?: AxiosRequestConfig, ...parameters: Object[]): AxiosRequestConfig
    {
        config = config || {};
        config.params = Object.assign({}, config.params, ...parameters);
        return config;
    }
}

