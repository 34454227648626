import * as elements from 'typed-html';
import * as _ from 'underscore';

import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import { RpmApiResources } from 'Services/Resources/RpmApiResources';
import { ODataHelperService } from 'Services/Utility/ODataHelperService';
import { FranchiseSelectorOnSelectParameters } from 'Directives/Common/FranchiseSelector';
import { CoreResources } from 'Interfaces/FranForce/Core/CoreResources';
import { KendoUtil } from 'Utility/KendoUtil';
import { FranchiseTaxReturnAnalysesRpmUiClient } from 'Clients/Rms/TaxReturn/FranchiseTaxReturnAnalysesRpmUiClient';
import { KendoHelperService } from 'Services/Utility/KendoHelperService';
import { AccountClient } from 'Clients/Identity/AccountClient';
import { ApiConfig } from 'AppConfig/ApiConfig';
import moment = require('moment');
import { MomentUtil } from 'Utility/MomentUtil';
import { IOnChangesObject } from 'angular';

type TaxReturnAnalysisGridItem = RpmEntities.FranchiseTaxReturnAnalysis & {
    MostRecentReportLastUpdated: Date;
    LastPeriodReportDate: Date;
    TotalYearSales: number;
};

export class TaxReturnAnalysisComponent implements ng.IController
{
    conceptId: number;
    franchisorId: number;
    franchiseId: number;

    franchiseDisplayTextTemplate: string;

    grid: kendo.ui.Grid;

    isEditable = false;

    taxClient: FranchiseTaxReturnAnalysesRpmUiClient;
    accountClient: AccountClient;

    yearsPromise: Promise<RpmEntities.usp_RoyaltyContracts_GetYearsOpen_Result[]>;
    periodReportsPromise: Promise<Array<RpmEntities.usp_RoyaltyPeriodReports_GetByFranchiseId_Simple_Result & { year?: number }>>

    static $inject = [
        'rpmUiApiResources',
        'rpmApiResources',
        'rpmHelper',
        'odataHelper',
        '$q',
        '$http',
        'apiConfig'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private rpmApiResources: RpmApiResources,
        private rpmHelper: RpmHelperService,
        private odataHelper: ODataHelperService,
        private $q: ng.IQService,
        private $http: ng.IHttpService,
        private apiConfig: ApiConfig
    )
    {
        this.franchiseDisplayTextTemplate = `{{${nameof<CoreResources.IFranchise>(o => o.LicenseNumber)}}} - {{${nameof<CoreResources.IFranchise>(o => o.FranchiseeName)}}}`;
    }

    $onInit()
    {
        this.taxClient = new FranchiseTaxReturnAnalysesRpmUiClient(this.$http, this.apiConfig);
        this.accountClient = new AccountClient();
    }

    $postLink()
    {
        this.InitGrid();
    }

    async InitGrid()
    {
        const account = (await this.accountClient.GetLoggedInUserInfo()).data;
        const roles = ApiConfig.Default.FranForceConstants.RoleConstants;

        this.isEditable = account.Roles.some(r => r.Name === roles.AdminRole || r.Name == roles.TaxReturnAdminRole);

        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof<TaxReturnAnalysisGridItem>(o => o.TaxYear),
                title: "Year",
                editor: (container, options) =>
                {
                    let input = $(<input required='required' data-required-msg='Select a year' name={options.field} />);
                    container.append(input);
                    const dropDownOptions = {
                        dataTextField: nameof<RpmEntities.usp_RoyaltyContracts_GetYearsOpen_Result>(o => o.Year),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyContracts_GetYearsOpen_Result>(o => o.Year),
                        optionLabel: "Select...",
                        optionLabelTemplate: "Select...",
                        dataSource: new kendo.data.DataSource({
                            transport: {
                                read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                                {
                                    let years = await this.yearsPromise;
                                    let foundYears = this.grid.dataSource.data()
                                        .map((m: RpmEntities.FranchiseTaxReturnAnalysis) => { return m.TaxYear }) as number[];
                                    return _.sortBy(
                                        years.filter(y => foundYears.every(fy => fy != y.Year)),
                                        y => y.Year
                                    ).reverse();
                                })
                            }
                        }),
                        change: (e: kendo.ui.DropDownListChangeEvent) =>
                        {
                            let dataItem = e.sender.dataItem() as RpmEntities.usp_RoyaltyContracts_GetYearsOpen_Result;
                            this.SetGridYearFields(options.model as TaxReturnAnalysisGridItem & kendo.data.Model);
                            options.model.set(nameof<TaxReturnAnalysisGridItem>(o => o.TaxYear), dataItem && dataItem.Year);
                        }
                    } as kendo.ui.DropDownListOptions;

                    input.kendoDropDownList(dropDownOptions);
                },
                editable: (dataItem: RpmEntities.FranchiseTaxReturnAnalysis) =>
                {
                    return dataItem.FranchiseTaxReturnAnalysisId == 0;
                }
            }, {
                field: nameof<TaxReturnAnalysisGridItem>(o => o.TaxReturnGrossSalesAmount),
                title: "Tax Return Gross Sales",
                format: "{0:c}",
            }, {
                field: nameof<TaxReturnAnalysisGridItem>(o => o.ReportedGrossSalesAmount),
                title: "Reported Gross Sales",
                format: "{0:c}",
                editable: (dataItem) => false
            }, {
                field: nameof<TaxReturnAnalysisGridItem>(o => o.TotalYearSales),
                title: "Total Year Sales",
                format: "{0:c}",
                filterable: false,
                editable: (dataItem) => false
            }, {
                field: nameof<TaxReturnAnalysisGridItem>(o => o.MostRecentReportLastUpdated),
                title: "Most Recent Report Last Updated",
                format: "{0:yyyy-MM-dd}",
                filterable: false,
                editable: (dataItem) => false
            }, {
                field: nameof<TaxReturnAnalysisGridItem>(o => o.LastPeriodReportDate),
                title: "Last Period Report Date",
                format: "{0:yyyy-MM-dd}",
                filterable: false,
                editable: (dataItem) => false
            }, {
                field: nameof<TaxReturnAnalysisGridItem>(o => o.GrossSalesAmountVariance),
                title: "$ Variance",
                format: "{0:c}",
                editable: (dataItem) => false
            }, {
                field: nameof<TaxReturnAnalysisGridItem>(o => o.GrossSalesAmountVariancePct),
                title: "% Variance",
                format: "{0:p}",
                editable: (dataItem) => false,
            }, {
                field: nameof<TaxReturnAnalysisGridItem>(o => o.VarianceApproved),
                title: "Approved",
                template: (dataItem: TaxReturnAnalysisGridItem) =>
                {
                    return dataItem.VarianceApproved ? "Yes" : "No"
                }
            }, {
                field: nameof<TaxReturnAnalysisGridItem>(o => o.VarianceApprovedDateTime),
                title: "Approved On",
                format: "{0:yyyy-MM-dd}",
                editable: (dataItem) => false
            }, {
                field: nameof<TaxReturnAnalysisGridItem>(o => o.Comment),
                title: "Comment"
            }
        ];
        
        let dataSourceOptions: kendo.data.DataSourceOptions = {
            schema: {
                model: {
                    id: nameof<TaxReturnAnalysisGridItem>(o => o.FranchiseTaxReturnAnalysisId),
                    fields: {
                        [nameof<TaxReturnAnalysisGridItem>(o => o.FranchiseTaxReturnAnalysisId)]: { type: "number", editable: true },
                        [nameof<TaxReturnAnalysisGridItem>(o => o.FranchiseId)]: { type: "number" },
                        [nameof<TaxReturnAnalysisGridItem>(o => o.TaxYear)]: {
                            type: "number",
                            editable: true,
                            defaultValue: new Date().getFullYear(),
                            validation: {
                                required: true,
                                taxyearvalidation: (input: JQuery) =>
                                {
                                    if (input.is(`[name='${nameof<TaxReturnAnalysisGridItem>(o => o.TaxYear)}']`))
                                    {
                                        let value = input.val();
                                        input.attr("data-taxyearvalidation-msg", "Year must be unique");
                                        return this.grid.dataSource.data()
                                            .filter((item: TaxReturnAnalysisGridItem) =>
                                            {
                                                return item.TaxYear == value
                                            }).length <= 1;
                                    }

                                    return true;
                                }
                            }
                        },
                        [nameof<TaxReturnAnalysisGridItem>(o => o.TaxReturnGrossSalesAmount)]: { type: "number", editable: true  },
                        [nameof<TaxReturnAnalysisGridItem>(o => o.ReportedGrossSalesAmount)]: { type: "number", editable: true  },
                        [nameof<TaxReturnAnalysisGridItem>(o => o.GrossSalesAmountVariance)]: { type: "number", editable: true  },
                        [nameof<TaxReturnAnalysisGridItem>(o => o.GrossSalesAmountVariancePct)]: { type: "number", editable: true },
                        [nameof<TaxReturnAnalysisGridItem>(o => o.VarianceApproved)]: { type: "boolean", editable: true  },
                        [nameof<TaxReturnAnalysisGridItem>(o => o.VarianceApprovedDateTime)]: { type: "date", editable: true, defaultValue: null  },
                        [nameof<TaxReturnAnalysisGridItem>(o => o.Comment)]: { type: "string", editable: true },
                        [nameof<TaxReturnAnalysisGridItem>(o => o.MostRecentReportLastUpdated)]: { type: "date", editable: true, defaultValue: null },
                        [nameof<TaxReturnAnalysisGridItem>(o => o.LastPeriodReportDate)]: { type: "date", editable: true, defaultValue: null  },
                        [nameof<TaxReturnAnalysisGridItem>(o => o.TotalYearSales)]: { type: "number", editable: true, defaultValue: null  }, 
                    }
                } as kendo.data.DataSourceSchemaModelWithFieldsObject 
            },
            transport: {
                read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let filters: kendo.data.DataSourceFilters =
                    {
                        logic: "and",
                        filters: [
                            { field: nameof<RpmEntities.FranchiseTaxReturnAnalysis>(o => o.FranchiseId), operator: "eq", value: this.franchiseId } as kendo.data.DataSourceFilterItem,
                            { field: nameof<RpmEntities.FranchiseTaxReturnAnalysis>(o => o.DeletedDateTime), operator: "isnull", value: null } as kendo.data.DataSourceFilterItem,
                            options.data.filter
                        ]
                    };

                    let params = this.odataHelper.ConvertKendoDataSourceTransportReadOptionsDataToParameterObject(options.data);
                    params.$select = this.odataHelper.ConvertKendoGridColumnsToSelectionString([
                        { field: nameof<RpmEntities.FranchiseTaxReturnAnalysis>(o => o.FranchiseTaxReturnAnalysisId) },
                        ...columns.filter(c => c.filterable === undefined || c.filterable === true)
                    ]);
                    params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersToString(filters);
 
                    let taxRecords = await this.rpmApiResources.FranchiseTaxReturnAnalysisApi.query(params).$promise;
                    let sortedRecords = _.sortBy(taxRecords, t => t.TaxYear).reverse() as Partial<TaxReturnAnalysisGridItem>[];
                    await Promise.all(
                        sortedRecords.map(taxRecord =>
                        {
                            return this.SetGridYearFields(taxRecord as TaxReturnAnalysisGridItem & kendo.data.Model);
                        })
                    );
                    return sortedRecords;
                }),
                create: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let createModel: TaxReturnAnalysisGridItem = options.data;
                    createModel.FranchiseId = this.franchiseId;
                    createModel.ReportedGrossSalesAmount = createModel.TotalYearSales;
                    let response = await this.taxClient.CreateFranchiseTaxReturnAnalysis(createModel);
                    createModel.FranchiseTaxReturnAnalysisId = response.data;
                    return {
                        [nameof<RpmEntities.usp_FranchiseTaxReturnAnalysis_Upsert_Input>(o => o.FranchiseTaxReturnAnalysisId)]: createModel.FranchiseTaxReturnAnalysisId
                    };
                }),
                update: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let updateModel: TaxReturnAnalysisGridItem = options.data;
                    updateModel.FranchiseId = this.franchiseId;
                    if (updateModel.VarianceApproved)
                    {
                        updateModel.ReportedGrossSalesAmount = updateModel.TotalYearSales;
                    }
                    let response = await this.taxClient.UpdateFranchiseTaxReturnAnalysis(updateModel.FranchiseTaxReturnAnalysisId, updateModel);
                    return;
                }),
                destroy: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let deleteModel: RpmEntities.usp_FranchiseTaxReturnAnalysis_Upsert_Input = options.data;
                    let response = await this.taxClient.DeleteFranchiseTaxReturnAnalysis(deleteModel.FranchiseTaxReturnAnalysisId);
                    return;
                }) 
            }
        };

        let toolbarItems: kendo.ui.GridToolbarItem[] = [];

        if (this.isEditable)
        {
            toolbarItems.push(
                {
                    template: (
                        <button ng-click={`$ctrl.${nameof.full<TaxReturnAnalysisComponent>(o => o.grid.addRow)}()`}>
                            New Analysis...
                        </button>
                    )
                }
            );

            columns.push({
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            });
        }

        let gridOptions: kendo.ui.GridOptions = {
            autoBind: false,
            toolbar: toolbarItems,
            columns: columns,
            navigatable: true,
            dataSource: new kendo.data.DataSource(dataSourceOptions),
            editable: (
                this.isEditable ? {
                    confirmation: false,
                    createAt: "bottom"
                } : false
            ),
            cellClose: (e) =>
            {
                let dataItem = e.model as kendo.data.Model & RpmEntities.FranchiseTaxReturnAnalysis;

                dataItem.set(
                    nameof<TaxReturnAnalysisGridItem>(o => o.GrossSalesAmountVariance),
                    dataItem.ReportedGrossSalesAmount - dataItem.TaxReturnGrossSalesAmount
                );
                e.model.set(
                    nameof<TaxReturnAnalysisGridItem>(o => o.GrossSalesAmountVariancePct),
                    (dataItem.ReportedGrossSalesAmount - dataItem.TaxReturnGrossSalesAmount) / dataItem.TaxReturnGrossSalesAmount
                );
            }
        };

        this.grid.setOptions(gridOptions);
    }

    async OnConceptSelect(concept: Partial<CoreResources.IConcept>)
    {
        this.conceptId = concept && concept.ConceptId;
        this.franchisorId = null;
        this.franchiseId = null;
    }

    async OnFranchisorSelect(franchisor: Partial<CoreResources.IFranchisor>)
    {
        this.conceptId = franchisor && franchisor.ConceptId;
        this.franchisorId = franchisor && franchisor.FranchisorId;
        this.franchiseId = null;
    }

    async OnFranchiseSelect(franchise: Partial<CoreResources.IFranchise>)
    {
        this.franchiseId = franchise && franchise.FranchiseId;
        this.franchisorId = franchise && franchise.FranchisorId;
        this.conceptId = franchise && franchise.ConceptId;
        if (this.franchiseId)
        {
            const periodReportTypes = [3, 6, 7];
            this.periodReportsPromise = Promise.resolve(this.rpmUiApiResources.GetRoyaltyPeriodReportsSimpleByFranchise(this.franchiseId)
                .then(response =>
                {
                    response.data.forEach((pr: RpmEntities.usp_RoyaltyPeriodReports_GetByFranchiseId_Simple_Result & { year?: number }) =>
                    {
                        pr.year = moment.utc(MomentUtil.EnsureUtcTime(pr.PeriodReportEndDate)).year();
                    });
                    return response.data.filter(pr => periodReportTypes.some(prType => prType == pr.RoyaltyPeriodReportStatusID));
                }));
            this.yearsPromise = this.taxClient.GetFranchiseTaxReturnAnalysisYears(this.franchiseId, true)
                .then(response => response.data);
            await this.grid.dataSource.read();
            this.grid.refresh();
        }
    }

    async RefreshGrid()
    {
        await this.$q.when(this.grid.dataSource.read());
        this.grid.refresh();
    }

    async Cancel()
    {
        this.grid.cancelChanges();
        await this.RefreshGrid();
    }

    async Save()
    {
        await this.$q.when(this.grid.dataSource.sync())
        this.RefreshGrid();
    }

    private async SetGridYearFields(taxRecord: TaxReturnAnalysisGridItem)
    {
        if (!taxRecord)
            return;

        if (!taxRecord.TaxYear)
        {
            taxRecord.MostRecentReportLastUpdated = null;
            taxRecord.LastPeriodReportDate = null;
            taxRecord.TotalYearSales = null;
            return;
        }

        let periodReports = await this.periodReportsPromise;
        let periodReportsInYear = periodReports.filter(p => p.year == taxRecord.TaxYear);

        taxRecord.MostRecentReportLastUpdated =
            moment.max(periodReportsInYear.map(pr => moment.utc(pr.UpdatedDateTime || pr.CreatedDateTime))).toDate();

        taxRecord.LastPeriodReportDate =
            moment.max(periodReportsInYear.map(pr => moment.utc(pr.PeriodReportEndDate))).toDate();
        
        taxRecord.TotalYearSales =
            periodReportsInYear.map(pr => pr.SourceTotalGrossSalesAmount).reduce((total, current) => { return total + current }, 0);
    }

    static BindComponent(app: ng.IModule)
    {
        const franchiseSelector = (
            <conceptFranchisorFranchiseSelector
                conceptId={`$ctrl.${nameof<TaxReturnAnalysisComponent>(o => o.conceptId)}`}
                franchisorId={`$ctrl.${nameof<TaxReturnAnalysisComponent>(o => o.franchisorId)}`}
                franchiseId={`$ctrl.${nameof<TaxReturnAnalysisComponent>(o => o.franchiseId)}`}
                state='true'
                franchiseOnSelect={`$ctrl.${nameof<TaxReturnAnalysisComponent>(o => o.OnFranchiseSelect)}(${nameof<FranchiseSelectorOnSelectParameters>(o => o.franchise)})`}
                franchiseSelect={`['${nameof<CoreResources.IFranchise>(o => o.LicenseNumber)}', '${nameof<CoreResources.IFranchise>(o => o.FranchiseeName)}']`}
                franchiseDisplayTextTemplate={`$ctrl.${nameof<TaxReturnAnalysisComponent>(o => o.franchiseDisplayTextTemplate)}`}
                >
            </conceptFranchisorFranchiseSelector>
        );

        const gridTemplate = (
            <div class='rpm-card'
                ng-show={`$ctrl.${nameof<TaxReturnAnalysisComponent>(o => o.franchiseId)}`}>
                <h1>Tax Return Analyses</h1>
                <div kendo-grid={`$ctrl.${nameof<TaxReturnAnalysisComponent>(o => o.grid)}`}>
                </div>
                <div class="buttons">
                    <button class="pure-button"
                        ng-click={`$ctrl.${nameof<TaxReturnAnalysisComponent>(o => o.Cancel)}()`}>
                        Cancel
                    </button>
                    <button class="pure-button"
                        promise-btn
                        ng-click={`$ctrl.${nameof<TaxReturnAnalysisComponent>(o => o.Save)}()`}>
                        Save
                    </button>
                </div>
            </div>
        );

        const template = `${franchiseSelector}${gridTemplate}`;

        app.component("taxReturnAnalysis", {
            bindings: {

            },
            template: template,
            controller: TaxReturnAnalysisComponent
        });
    }
}
