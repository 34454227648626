import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AngularUtil } from "Utility/AngularUtil";
import { EditUserStateService, userStateObj } from "Services/State/EditUserStateService";
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

export class EditUserConceptOwnershipComponentController implements ng.IController 
{
    managedUserId: string;
    refreshDate: Date;
    refresh: (params: { refreshedDate: Date }) => void;

    userState: userStateObj;
    isLoading: boolean;

    isViewable: boolean;
    managedConceptIds: number[];
    conceptSelect: string[];
    conceptTemplate: string;
    selectedConcepts: any[];
    static $inject = [
        'editUserState',
        'identityManager',
        'apiConfig',
    ];

    constructor(
        private editUserState: EditUserStateService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
    )
    {
    }

    $onInit()
    {
        this.conceptSelect = [
            nameof<CoreResources.IConcept>(o => o.ConceptId),
            nameof<CoreResources.IConcept>(o => o.ConceptCode),
            nameof<CoreResources.IConcept>(o => o.DisplayName),
        ];

        this.conceptTemplate = `
            <div class="ids">
                <strong>{{${nameof<CoreEntities.Concept>(o => o.ConceptCode)}}}</strong> - ({{${nameof<CoreEntities.Concept>(o => o.ConceptId)}}})
            </div>
            <div class="display-name">
                {{${nameof<CoreEntities.Concept>(o => o.DisplayName)}}}
            </div>`;
    }

    $postLink()
    {

    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        this.Load();
    }

    Load()
    {
        let promise = this.editUserState.Load(this.managedUserId)
            .then((result) =>
            {
                this.userState = result;
            })
            .then(() =>
            {

                this.isViewable = (this.userState.managedUser ? true : false) && (
                    this.userState.managedUser.Roles.some(r => r.Name == this.apiConfig.FranForceConstants.RoleConstants.ConceptManagerRole) ||
                    this.userState.managedUser.Roles.some(r => r.Name == this.apiConfig.FranForceConstants.RoleConstants.FranchiseConsultantRole)
                );

                this.managedConceptIds = this.userState.managedUser.Claims
                    .filter(c => c.Type === this.apiConfig.FranForceConstants.ClaimConstants.ManagedConceptIdClaimType)
                    .map(c => parseInt(c.Value));
            })
    
        return AngularUtil.TrackLoadingPromise(promise, this);
    }

   async AddConcept()
    {
        if(this.selectedConcepts && this.selectedConcepts.length){
            await Promise.all(
                this.selectedConcepts.map(async (concepts)=>{

                    await this.identityManager.AddManagedConceptId({
                        Email: this.userState.managedUser.Email,
                        ConceptId: concepts.ConceptId
                    })
                        .then(() =>
                        {

                        })
                        .catch(AngularUtil.GetJsonAlertCatch());
                })
           
              ).then(()=>{
                  this.selectedConcepts=[];
                this.RefreshUser();
              })
        }
    }

    RemoveConcept(conceptId: number)
    {
        if (confirm(`Are you sure you want to remove the concept with the id of '${conceptId}'?`))
        {
            return this.identityManager.RemoveManagedConceptId({
                Email: this.userState.managedUser.Email,
                ConceptId: conceptId
            })
                .then(() =>
                {
                    this.RefreshUser();
                });
        }
    }

    RefreshUser()
    {
        this.editUserState.Clear(this.userState.managedUser.Id);
        if (this.refresh) {
            this.refresh({
                refreshedDate: new Date()
            });
        }
    }
    
    static BindComponent(app: ng.IModule)
    {
        app.component("editUserConceptOwnership", {
            bindings: {
                [nameof<EditUserConceptOwnershipComponentController>(o => o.managedUserId)]: "<",
                [nameof<EditUserConceptOwnershipComponentController>(o => o.refreshDate)]: "<",
                [nameof<EditUserConceptOwnershipComponentController>(o => o.refresh)]: "&"
            },
            controller: EditUserConceptOwnershipComponentController,
            templateUrl: "/Templates/Users/EditUser/EditUserConceptOwnership.html"
        });
    }
}

