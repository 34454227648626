import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyDisclosureDocumentRatePlansRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;
    
    GetRoyaltyDisclosureDocumentRatePlansByDisclosureDocument(disclosureDocumentId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlans/InDocument/" + disclosureDocumentId
        };

        return this.$http<RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel[]>(config);
    }

    CreateRoyaltyDisclosureDocumentRatePlan(model: RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlans",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyDisclosureDocumentRatePlan(id: number, model: RpmEntities.RoyaltyDisclosureDocumentRatePlanViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlans/" + id,
            data: model
        };

        return this.$http<number>(config);
    }
}