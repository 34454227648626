import { LegalApiResources } from "Services/Resources/LegalApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { Helpers } from "Utility/Helpers";
import { LegalEntities } from "Interfaces/FranForce/Legal/LegalResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

interface IEditSectionTwoScope extends ng.IScope {
    cancelationOptions: LegalEntities.CancelationType[];
    CancelationOptionValue: number[];
    WaiverOptionValue: number[];
    originalCancelationOptionValue: number[];
    originalWaiverOptionValue: number[]
    contractId: string;
    franchiseId: string;
    selectionDetails: { franchiseID: string, contractID: string };
    activeSection: { section: string }
    model: LegalEntities.SectionTwo;
    editSectionTwoForm: any;
    submitSectionTwo: () => void;
    waiverOptions: LegalEntities.WaiverType[];
    cancelSectionTwo: () => void;
    paymentOptions: LegalEntities.PaymentOption[];
    paymentForOptions: LegalEntities.PaymentForOption[];
    kNotifierOptions: any;
    kNotifier: kendo.ui.Notification;
    saveSuccessful: boolean;
    saveBtnDisabled: boolean;
    denyBtnDisabled: boolean;
    userEmail: string;
}

export class EditSectionTwoController implements ng.IController {

    static $inject = [
        "$scope",
        "legalApiResources",
        "$window",
        "$q",
        "$log",
        "$http",
        "coreApiResources",
        "identityManager"
    ];

    constructor(
        private $scope: IEditSectionTwoScope,
        private legalApiResources: LegalApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $http: ng.IHttpService,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager
    )
    {
        $scope.saveBtnDisabled = false;
        $scope.denyBtnDisabled = false;

        $scope.kNotifierOptions = {
            position: {
                top: 50,
                left: $(window).width() / 2
            },
            templates: [{
                type: 'ngTemplate',
                template: $('#kNTemplate').html(),
            }]
        };

        identityManager.GetLoggedInUserInfo().then(
            (success) => {
                $scope.userEmail = success.data.Email;
            },
            (error) => {
                console.log(error);
            }); 
    }

    $onInit() {
        let $scope = this.$scope;
        let $http = this.$http;
        let legalApiResources = this.legalApiResources;
        let $q = this.$q;

        // Assign selection details to retrieve associated data
        $scope.franchiseId = location.pathname.split("/")[3] || $scope.selectionDetails.franchiseID;
        $scope.contractId = location.pathname.split("/")[4] || $scope.selectionDetails.contractID;

        this.coreApiResources.FranchiseApi.query({ $expand: "Concept", $filter: "FranchiseId eq " + $scope.franchiseId },
            (successResponse: CoreEntities.Franchise[]) => {
                $scope.$emit("setFranchiseDetails", {
                    FranchiseName: successResponse[0].Concept.DisplayName,
                    LicenseNum: successResponse[0].LicenseNumber,
                    FranchiseeCode: $scope.franchiseId,
                    FranchiseAddress: successResponse[0].Address,
                    FranchisePhoneNumber: successResponse[0].StorePhone,
                    FranchiseEmail: successResponse[0].StoreEmail
                });
            },
            errorResponse => {
                console.log(errorResponse);
            });

        legalApiResources.ContractApi.get({
            id: $scope.contractId, $expand: "SectionTwo($expand=SectionTwoCancelationTypes,SectionTwoWaiverTypes)"
        }, (successResponse: LegalEntities.Contract) => {
            $scope.model = successResponse.SectionTwo;
            $scope.CancelationOptionValue = [];
            $scope.WaiverOptionValue = [];
            $scope.originalCancelationOptionValue = [];
            $scope.originalWaiverOptionValue = [];
            if (successResponse.SectionTwo.SectionTwoCancelationTypes.length > 0) {
                successResponse.SectionTwo.SectionTwoCancelationTypes.forEach(value => {
                    $scope.CancelationOptionValue.push(value.CancelationTypeID);
                    $scope.originalCancelationOptionValue.push(value.CancelationTypeID);
                });
            }
            if (successResponse.SectionTwo.SectionTwoWaiverTypes.length > 0) {
                successResponse.SectionTwo.SectionTwoWaiverTypes.forEach(value => {
                    $scope.WaiverOptionValue.push(value.WaiverTypeID);
                    $scope.originalWaiverOptionValue.push(value.WaiverTypeID);
                });
            }

            $scope.$emit("setSectionAvaliability", {
                hasSectionTwo: successResponse.SectionTwoID != null,
                hasSectionThree: successResponse.SectionThreeID != null,
                franchiseID: $scope.franchiseId,
                contractID: $scope.contractId
            });
        }, errorResponse => {
            console.log(errorResponse);
        });

        legalApiResources.CancelationTypeApi.query({}, (successResponse: LegalEntities.CancelationType[]) => {
            $scope.cancelationOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        legalApiResources.WaiverTypeApi.query({}, (successResponse: LegalEntities.WaiverType[]) => {
            $scope.waiverOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        legalApiResources.PaymentOptionApi.query({}, (successResponse: LegalEntities.PaymentOption[]) => {
            $scope.paymentOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        legalApiResources.PaymentForOptionApi.query({}, (successResponse: LegalEntities.PaymentForOption[]) => {
            $scope.paymentForOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        $scope.cancelSectionTwo = () => {
            finishSectionTwo(false);
        }

        $scope.submitSectionTwo = () => {
            if ($scope.editSectionTwoForm.$valid) {
                finishSectionTwo(true);
            } else {
                $scope.saveSuccessful = false;
                $scope.kNotifier.show({
                    kValue: "Some field(s) require input to proceed "
                }, "ngTemplate");

                Object.keys($scope.editSectionTwoForm.$error).forEach(function (value) {
                    $scope.editSectionTwoForm.$error[value].forEach(function (item) {
                        item.$setDirty();
                    });
                });
            }
        }

        function finishSectionTwo(isSubmit: boolean) {
            $scope.saveBtnDisabled = true;
            $scope.denyBtnDisabled = true;

            if (isSubmit) {
                $scope.model.ApprovedBy = $scope.userEmail;
                $scope.model.ApprovedDate = kendo.toString(new Date(), "MM/dd/yyyy");
            }

            let cancelationDiff = Helpers.NumberArrayDifference($scope.originalCancelationOptionValue, $scope.CancelationOptionValue);
            let addCancelation = cancelationDiff.add;
            let removeCancelation = cancelationDiff.remove;

            let waiverDiff = Helpers.NumberArrayDifference($scope.originalWaiverOptionValue, $scope.WaiverOptionValue);
            let addWaiver = waiverDiff.add;
            let removeWaiver = waiverDiff.remove;

            legalApiResources.SectionTwoApi.update({ id: $scope.model.SectionTwoID }, $scope.model, (successCallback: LegalEntities.SectionTwo) => {
                let promises = [];

                if (addCancelation.length > 0) {
                    addCancelation.forEach(value => {
                        promises.push(legalApiResources.SectionTwoCancelationTypeApi.save({ SectionTwoID: successCallback.SectionTwoID, CancelationTypeID: value }, cancelationCallback => {

                        }, errorCallback => {
                            console.log(errorCallback);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        }).$promise);
                    });
                }

                if (removeCancelation.length > 0) {
                    removeCancelation.forEach(value => {
                        promises.push(legalApiResources.SectionTwoCancelationTypeApi.query({ $filter: "SectionTwoID eq " + successCallback.SectionTwoID + "and CancelationTypeID eq " + value },
                            (successCallback: LegalEntities.SectionTwoCancelationType[]) => {
                                promises.push(legalApiResources.SectionTwoCancelationTypeApi.delete({ id: successCallback[0].SectionTwoCancelationTypeID, allowHardDelete: true },
                                    successResponse => {

                                    }, errorResponse => {
                                        console.log(errorResponse);
                                        $scope.saveBtnDisabled = false;
                                        $scope.denyBtnDisabled = false;
                                    }).$promise);
                            }, errorResponse => {
                                console.log(errorResponse);
                                $scope.saveBtnDisabled = false;
                                $scope.denyBtnDisabled = false;
                            }).$promise);
                    });
                }

                if (addWaiver.length > 0) {
                    addWaiver.forEach(value => {
                        promises.push(legalApiResources.SectionTwoWaiverTypeApi.save({ SectionTwoID: successCallback.SectionTwoID, WaiverTypeID: value }, waiverCallback => {

                        }, errorCallback => {
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        }).$promise);
                    });
                }

                if (removeWaiver.length > 0) {
                    removeWaiver.forEach(value => {
                        promises.push(legalApiResources.SectionTwoWaiverTypeApi.query({ $filter: "SectionTwoID eq " + successCallback.SectionTwoID + "and WaiverTypeID eq " + value },
                            (successCallback: LegalEntities.SectionTwoWaiverType[]) => {
                                promises.push(legalApiResources.SectionTwoWaiverTypeApi.delete({ id: successCallback[0].SectionTwoWaiverTypeID, allowHardDelete: true },
                                    successResponse => {

                                    }, errorResponse => {
                                        console.log(errorResponse);
                                        $scope.saveBtnDisabled = false;
                                        $scope.denyBtnDisabled = false;    
                                    }).$promise);
                            }, errorCallback => {
                                $scope.saveBtnDisabled = false;
                                $scope.denyBtnDisabled = false;
                            }).$promise);
                    });
                }

                $q.all(promises).then(promisesResponse => {
                    if (isSubmit) {
                        legalApiResources.SectionTwoCompleted($scope.contractId, successCallback.SectionTwoID).then(successResponse => {
                            window.location.href = "/Itt/NewSectionThree/" + $scope.franchiseId + "/" + $scope.contractId;
                        }, errorResponse => {
                            $scope.kNotifier.show({
                                kValue: "Failed to save to server. Please contact an admin."
                            }, "ngTemplate");
                            console.log(errorResponse);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        });
                    } else {
                        legalApiResources.SectionTwoDenied($scope.contractId, successCallback.SectionTwoID).then(deniedCallback => {
                            window.location.href = "/Itt";
                        }, errorResponse => {
                            $scope.kNotifier.show({
                                kValue: "Failed to save to server. Please contact an admin."
                            }, "ngTemplate");
                            console.log(errorResponse);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        });
                    }
                }, errorResponse => {
                    $scope.kNotifier.show({
                        kValue: "Failed to save to server. Please contact an admin."
                    }, "ngTemplate");
                    console.log(errorResponse);
                    $scope.saveBtnDisabled = false;
                    $scope.denyBtnDisabled = false;
                });

            }, errorResponse => {
                $scope.kNotifier.show({
                    kValue: "Failed to save to server. Please contact an admin."
                }, "ngTemplate");
                console.log(errorResponse);
                $scope.saveBtnDisabled = false;
                $scope.denyBtnDisabled = false;
            });
        }
    }
}

