import { AxiosInstance } from "axios";
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";
import { ZorWareBillingInvoiceClient, ZorWareBillingInvoiceEmailCountRequestRm, ZorWareBillingInvoiceEmailCountResultVm } from "@nbly/billing-orchestrations-clients";

export class BillingInvoiceEmailCountPopupController implements ng.IController {

    isLoading: boolean;

    billingOrchestrationsClient: AxiosInstance;
    zorWareBillingInvoiceClient: ZorWareBillingInvoiceClient;

    billingInvoiceEmailCountWindow: kendo.ui.Window;
    billingInvoiceEmailCountWindowOptions: kendo.ui.WindowOptions;

    billingInvoiceEmailGrid: kendo.ui.Grid;
    billingInvoiceEmailGridOptions: kendo.ui.GridOptions;

    errorMessage: string;
    showErrorMessage: boolean;

    selectedConcepts?: number[];
    month: number;
    year: number;

    static $inject = [
        "$timeout",
        "$rootScope",
        "$scope",
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private $rootScope: ng.IRootScopeService,
        private $scope: ng.IScope,
    ) {
        this.billingOrchestrationsClient = FranForceAxiosClientBuilder.BuildBillingOrchestrationsBaseClient();
        this.zorWareBillingInvoiceClient = new ZorWareBillingInvoiceClient("", this.billingOrchestrationsClient);
    }

    $onInit() {
        this.InitWindow();
        this.$rootScope.$on('getBillingInvoiceEmailCount', async (event, args) => {
            this.$timeout(() => {
                this.billingInvoiceEmailCountWindow.center();
            }, 0);
            this.billingInvoiceEmailCountWindow.open();

            this.selectedConcepts = args.selectedConcepts;
            this.month = args.month == '' ? null : args.month;
            this.year = args.year == '' ? null : args.year;

            if (!this.selectedConcepts
                || (this.selectedConcepts && Array.isArray(this.selectedConcepts) && this.selectedConcepts.length < 1))
            {
                this.selectedConcepts = null;
            }

            if (this.selectedConcepts
                && Array.isArray(this.selectedConcepts)
                && this.selectedConcepts.length > 0) {
                this.selectedConcepts = this.selectedConcepts
            }

            if (this.year == undefined || this.year == null) {
                this.ErrorMessage(true, "Please select year and month for retriving billing invoice email count.");
                this.billingInvoiceEmailGrid.dataSource.data([]);
                return;
            }

            if (args.month == undefined || args.month == null || args.month == '') {
                this.ErrorMessage(true, "Please select year and month for retriving billing invoice email count.");
                this.billingInvoiceEmailGrid.dataSource.data([]);
                return;
            }

            this.ErrorMessage(false, "");

            await this.LoadBillingInvoiceEmailGrid();
            await this.billingInvoiceEmailGrid.dataSource.read();
        });
    }

    $postLink() {
    }

    InitWindow() {
        this.billingInvoiceEmailCountWindowOptions = {
            modal: true,
            resizable: false,
            maxWidth: 700
        }
    }

    LoadBillingInvoiceEmailGrid() {
        this.isLoading = true;

        if (this.billingInvoiceEmailGrid) {
            //this.billingInvoiceEmailGrid.dataSource.read();
        }
        else {
            let billingInvoiceEmailGridDataSource = new kendo.data.DataSource({
                transport: {
                    read: async (options) => {

                        let zorWareBillingInvoiceEmailCountRequestRm: ZorWareBillingInvoiceEmailCountRequestRm = {
                            conceptIds: this.selectedConcepts,
                            month: this.month,
                            year: this.year
                        }
                        await this.zorWareBillingInvoiceClient.invoiceEmailAggr(zorWareBillingInvoiceEmailCountRequestRm)
                            .then(response => {
                                let zorWareBillingInvoiceEmailCountResultVm: ZorWareBillingInvoiceEmailCountResultVm[] = response.result;
                                options.success(zorWareBillingInvoiceEmailCountResultVm);
                            })
                            .catch(ex => {
                                console.log("Error! Failed to fetch billing invoices email count data", ex);
                                this.ErrorMessage(true, "Error! Failed to fetch billing invoices email count data");
                            })
                            .finally(() => {
                                this.isLoading = false;
                                this.$scope.$digest();
                            })

                        //options.success([]);
                    }
                },
                pageSize: 25,
                schema: {
                    model: {
                        id: "conceptId",
                        fields: {
                            conceptId: {
                                type: "number",
                            },
                            conceptName: {
                                type: "string",
                            },
                            invoiceEmailCount: {
                                type: "number",
                            },
                            invoiceCount: {
                                type: "number",
                            },
                        }
                    },
                }
            });

            let billingInvoiceEmailGridColumns: Array<kendo.ui.GridColumn> =
                [
                    {
                        field: "conceptId",
                        title: "Concept Id",
                        hidden: true
                    },
                    {
                        field: "conceptName",
                        title: "Concept Name",
                    },
                    {
                        field: "invoiceEmailCount",
                        title: "Invoice Email Count",
                    },
                    {
                        field: "invoiceCount",
                        title: "Invoice Count",
                    },
                ];

            this.billingInvoiceEmailGridOptions = {
                autoBind: false,
                dataSource: billingInvoiceEmailGridDataSource,
                columns: billingInvoiceEmailGridColumns,
                sortable: true,
                scrollable: true,
                filterable: true,
                //height: 350,
                pageable: {
                    numeric: true,
                    pageSize: 10,
                    pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                    input: true,
                },
            };
        }
    }

    ErrorMessage(show: boolean, message: string) {
        this.showErrorMessage = show;
        this.errorMessage = message;
    }

    static BindComponent(app: ng.IModule) {
        app.component("billingInvoiceEmailCountPopup", {
            bindings: {
            },
            controller: BillingInvoiceEmailCountPopupController,
            templateUrl: "/Templates/Billing/GenerateBillingInvoices/BillingInvoiceEmailCountPopup.html"
        });

    }
}