import { KendoUtil } from "Utility/KendoUtil";

import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import * as _ from "underscore"
import * as moment from "moment"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";




export class fddRoyaltiesRatePlanMinimumItemsComponentController implements ng.IController {

    disclosureDocumentRatePlanId: number;
    readOnly: boolean;
    onSelectedRatePlanMinimumItem: (params: { ratePlanMinimumItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel, isRemoved: boolean }) => void;
    onLoaded: (params: { self: fddRoyaltiesRatePlanMinimumItemsComponentController }) => void;

    ratePlanMinimumItemGridOptions: kendo.ui.GridOptions;
    ratePlanMinimumItemGrid: kendo.ui.Grid;
    
    monthlyMinimumGrids: { [uid: string]: kendo.ui.Grid };
    rollInMinimumsGrids: { [uid: string]: kendo.ui.Grid };

    isLoading: boolean;
    selectedRatePlanMinimumItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel;
    royaltyPeriodFrequencies: RpmEntities.RoyaltyPeriodFrequencyViewModel[];
    royaltyFindScaleBasedOnTypes: RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result[];

    formController: angular.IFormController;

    static $inject = [
        'rpmUiApiResources',
        'rpmHelper',
        'identityManager',
        '$q',
        '$timeout'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private identityManager: IdentityManager,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService) {

    }

    $onInit() {
        this.isLoading = true;
        this.monthlyMinimumGrids = {};
        this.rollInMinimumsGrids = {};
        moment.locale("en");

        this.$q.all([
            this.rpmUiApiResources.GetAllRoyaltyPeriodFrequencies().then((freqResponse) => {
                this.royaltyPeriodFrequencies = freqResponse.data;
            }),
            this.rpmUiApiResources.GetAllRoyaltyFindScaleBasedOnTypes().then((findScaleResponse) => {
                this.royaltyFindScaleBasedOnTypes = findScaleResponse.data;
            })
        ])
            .then(() => {
                this.InitRatePlanMinimumItemsGrid();

                if (this.onLoaded)
                    this.onLoaded({ self: this });
            })
            .finally(() => { this.isLoading = false; });
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes.disclosureDocumentRatePlanId && changes.disclosureDocumentRatePlanId.currentValue)
        {
            this.$timeout(() => { this.ResetChanges(); }, 0);
        }
    }

    InitRatePlanMinimumItemsGrid()
    {
        let minItemDataSourceOptions = {
            filter: { field: "DeletedDateTime", operator: "isnull" },
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {
                    if (!this.disclosureDocumentRatePlanId)
                    {
                        options.success([]);
                        return;
                    }

                    this.rpmUiApiResources.GetRoyaltyDisclosureDocumentRatePlanMinimumItemsByRatePlan(this.disclosureDocumentRatePlanId)
                        .then((minPlanItemsResponse) => {
                            options.success(minPlanItemsResponse.data);
                        });
                },
                create: (options: kendo.data.DataSourceTransportOptions) => {
                    let newRpMinItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel = options.data;
                    this.rpmUiApiResources.CreateRoyaltyDisclosureDocumentRatePlanMinimumItem(newRpMinItem)
                        .then((idResponse) => {
                            options.success({ DisclosureDocumentRatePlanMinimumItemId: idResponse.data });
                        }, (err) => { options.error(err); });
                },
                update: (options: kendo.data.DataSourceTransportOptions) => {
                    let updateRpMinItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel = options.data;
                    this.rpmUiApiResources.UpdateRoyaltyDisclosureDocumentRatePlanMinimumItem(updateRpMinItem.DisclosureDocumentRatePlanMinimumItemId, updateRpMinItem)
                        .then(() => {
                            options.success();
                        }, (err) => { options.error(err); });
                },
                destroy: (options: kendo.data.DataSourceTransportOptions) => {
                    let deleteRpMinItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel = options.data;
                    deleteRpMinItem.DeletedDateTime = new Date().toISOString();
                    this.rpmUiApiResources.UpdateRoyaltyDisclosureDocumentRatePlanMinimumItem(deleteRpMinItem.DisclosureDocumentRatePlanMinimumItemId, deleteRpMinItem)
                        .then(() => {
                            options.success();
                        }, (err) => { options.error(err); });
                },
            },
            pageSize: 10,
            schema: this.GetDisclosureDocumentRatePlanMinimumItemSchema(),
            sort: {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel>(o => o.WeeksInBusinessStart),
                dir: "asc"
            }
        };

        let minItemColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel>(o => o.DisclosureDocumentRatePlanMinimumItemId),
                title: "ID",
                width: 160,
                template: `<button 
                    ng-show="dataItem.DisclosureDocumentRatePlanMinimumItemId && dataItem.MinimumScaleBasedOn == 7"
                    ng-class="'pure-button grid-edit-button ' + ($ctrl.selectedRatePlanMinimumItem.DisclosureDocumentRatePlanMinimumItemId === dataItem.DisclosureDocumentRatePlanMinimumItemId ? 'rpm-selected-row-btn' : '')" 
                    ng-click="$ctrl.SelectRatePlanMinimumItem(dataItem) ; $event.preventDefault()">
                        <i ng-class="'fa fa-' + ($ctrl.selectedRatePlanMinimumItem.DisclosureDocumentRatePlanMinimumItemId === dataItem.DisclosureDocumentRatePlanMinimumItemId ? 'check-circle-o' : 'circle-o')" 
                            aria-hidden="true"></i>
                        <span ng-bind="dataItem.DisclosureDocumentRatePlanMinimumItemId"></span>
                    </button>
                    <span ng-show="!dataItem.DisclosureDocumentRatePlanMinimumItemId || dataItem.MinimumScaleBasedOn != 7" 
                            ng-bind="dataItem.DisclosureDocumentRatePlanMinimumItemId"></span>`
            },
            {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel>(o => o.WeeksInBusinessStart),
                title: "WIB Start"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel>(o => o.WeeksInBusinessEnd),
                title: "WIB End"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel>(o => o.RoyaltyFeeMinimumFixedRateAmount),
                title: "Min Royalty Fee",
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel>(o => o.AdFeeMinimumFixedRateAmount),
                title: "Min Ad Fee",
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel>(o => o.NetSalesMinimumAmount),
                title: "Gross Sales Min Amount",
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel>(o => o.RoyaltyPeriodFrequencyId),
                title: "Minimum Fee Frequency",
                editor: (container, options) => {
                    let input = "<input required name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: nameof<RpmEntities.RoyaltyPeriodFrequencyViewModel>(o => o.Description),
                        dataValueField: nameof<RpmEntities.RoyaltyPeriodFrequencyViewModel>(o => o.RoyaltyPeriodFrequencyId),
                        dataSource: {
                            data: this.royaltyPeriodFrequencies
                        },
                        change: (e) => {
                            let dataItem: RpmEntities.RoyaltyPeriodFrequencyViewModel = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.royaltyPeriodFrequencies[0];
                            }

                            options.model.set("RoyaltyPeriodFrequencyId", dataItem.RoyaltyPeriodFrequencyId);
                        }
                    });
                },
                template: "{{$ctrl.GetRoyaltyPeriodFrequencyById(#=RoyaltyPeriodFrequencyId#).Description}}"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel>(o => o.MinimumScaleBasedOn),
                title: "Minimum Scale Based On",
                editor: (container, options) => {
                    let input = "<input required name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: nameof<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>(o => o.Description),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>(o => o.FindScaleBasedOnId),
                        dataSource: {
                            data: this.royaltyFindScaleBasedOnTypes
                        },
                        change: (e) => {
                            let dataItem = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.royaltyFindScaleBasedOnTypes[0];
                            }

                            options.model.set(nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel>(o => o.MinimumScaleBasedOn), dataItem.FindScaleBasedOnId);
                        }
                    });
                },
                template: "{{$ctrl.GetRoyaltyFindScaleBasedOnTypesId(#=MinimumScaleBasedOn#).Description}}"
            }, {
                field: "HasMonthlyMinimumChanges",
                title: "Has Monthly Minimum Changes",
                hidden: true
            }, {
                field: "HasRollInRateChanges",
                title: "Has Roll In Rate Changes",
                hidden: true
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }];
        

        this.ratePlanMinimumItemGridOptions = {
            dataSource: new kendo.data.DataSource(minItemDataSourceOptions),
            columns: minItemColumns,
            selectable: "row",
            editable: !this.readOnly ? { confirmation: false } : false,
            navigatable: true,
            scrollable: false,
            sortable: true,
            pageable: KendoUtil.GetDefaultKendoGridPageable(10),
            remove: (e) =>
            {
                if (this.selectedRatePlanMinimumItem && e.model.id === this.selectedRatePlanMinimumItem.DisclosureDocumentRatePlanMinimumItemId)
                {
                    this.$timeout(() => {
                        this.SelectRatePlanMinimumItem(null, true);
                    }, 0)
                }
            }
        };
    }

    GetMonthlyMinimumsGridOptions(gridItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel)
    {
        let updateOrCreateTransportFunction = (options: kendo.data.DataSourceTransportOptions) => {
            let item: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel = options.data;
            for (let month of moment.months()) {
                gridItem["MinimumApplied" + month] = item["MinimumApplied" + month];
            }
            if (gridItem.DisclosureDocumentRatePlanMinimumItemId) {
                //If it's a new item, this flag doesn't need to be set as it will already be gaurenteed to be created.
                this.ratePlanMinimumItemGrid.dataSource
                    .get(gridItem.DisclosureDocumentRatePlanMinimumItemId)
                    .set("HasMonthlyMinimumChanges", true);
            }
            options.success()
        };

        let mmDataSourceOptions: kendo.data.DataSourceOptions = {
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {

                    let newMinItem: Partial<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel> = {
                        DisclosureDocumentRatePlanMinimumItemId: gridItem.DisclosureDocumentRatePlanMinimumItemId
                    };
                    for (let month of moment.months())
                    {
                        newMinItem["MinimumApplied" + month] = gridItem["MinimumApplied" + month];
                    }

                    options.success([newMinItem]);
                },
                update: updateOrCreateTransportFunction,
                create: updateOrCreateTransportFunction,
                destroy: (e: kendo.data.DataSourceTransportOptions) => {
                    e.error('Destory is not defined for monthly minimums, as it should not be possible.')
                }
            },
            schema: this.GetDisclosureDocumentRatePlanMinimumItemSchema()
        };
        let mmColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "DisclosureDocumentRatePlanMinimumItemId",
                title: "ID",
                hidden: true
            }];

        for (let month of moment.months()) {
            mmColumns.push({
                field: "MinimumApplied" + month,
                title: month,
                template: `<button ng-show="!$ctrl.readOnly" ng-class="'grid-check-button'" ng-click="$ctrl.ToggleMinimumApplied(dataItem, '${month}') ; $event.preventDefault()">
                                <i ng-class="dataItem.MinimumApplied${month} ? 'fa fa-check-square' : 'fa fa-square' " aria-hidden="true"></i>
                            </button>
                            <span ng-show="$ctrl.readOnly">{{dataItem.MinimumApplied${month} ? "Yes" : "No"}}</span>`
            });
        }

        return {
            dataSource: new kendo.data.DataSource(mmDataSourceOptions),
            columns: mmColumns,
            editable: false,
            navigatable: true,
            scrollable: false,
            pageable: false
        };
    }

    GetRollInRateGridOptions(ratePlanMinItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel): kendo.ui.GridOptions
    {
        let rirDataSource = new kendo.data.DataSource({
            batch: true,
            schema: {
                 model: {
                    id: "typeFee",
                    fields: {
                        ParentId: {
                            type: "string",
                            validation: { required: true },
                            editable: false,
                            defaultValue: 0
                        },
                        typeFee: {
                            type: "string",
                            validation: { required: true },
                            editable: false,
                             
                        },
                        rollInSalesBegin: { type: "number", validation: { required: true } },
                        rollInSalesEnd: { type: "number", validation: { required: true } },
                        royaltyFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        royaltyFeeFixedRateAmount: { type: "number", validation: { required: true } },
                        adFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        adFeeFixedRateAmount: { type: "number", validation: { required: true } }
                    }
                 }
            },
             transport: {
                 read: (e: kendo.data.DataSourceTransportReadOptions) => {
                    e.success(this.rpmHelper.ConvertToRollInRateMinimums(ratePlanMinItem, ratePlanMinItem.DisclosureDocumentRatePlanMinimumItemId));
                 },
                 update: (e: kendo.data.DataSourceTransportOptions) => {
                     for (let rollInRate of <IRollInRateItem[]>e.data.models)
                     {
                        this.rpmHelper.MergeRollInRateMinimumItem(ratePlanMinItem, rollInRate)
                     }

                     if (ratePlanMinItem.DisclosureDocumentRatePlanMinimumItemId)
                     {
                         //If it's a new item, this flag doesn't need to be set as it will already be gaurenteed to be created.
                        this.ratePlanMinimumItemGrid.dataSource
                             .get(ratePlanMinItem.DisclosureDocumentRatePlanMinimumItemId)
                             .set("HasRollInRateChanges", true);
                     }
                     
                     e.success();
                 },
                 destroy: (e: kendo.data.DataSourceTransportOptions) => {
                     e.error('Destory is not defined for roll in rates, as it should not be possible.')
                 },
                 create: (e: kendo.data.DataSourceTransportOptions) => {
                     e.error('Create is not defined for roll in rates, as it should not be possible.')
                 },
             }
        });
        let rirColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "ParentId",
                title: "DisclosureDocumentRatePlanItemId",
                hidden: true
            },
            {
                field: "typeFee",
                title: "Type Fee"
            }, {
                field: "rollInSalesBegin",
                title: "Roll-in Sales Begin",
                format: "{0:c}"
            }, {
                field: "rollInSalesEnd",
                title: "Roll-in Sales End",
                format: "{0:c}"
            }, {
                field: "royaltyFeeRate",
                title: "Royalty Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: "royaltyFeeFixedRateAmount",
                title: "Royalty Fee Fixed Rate Amount",
                format: "{0:c}"
            }, {
                field: "adFeeRate",
                title: "Ad Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: "adFeeFixedRateAmount",
                title: "Ad Fee Fixed Rate Amount",
                format: "{0:c}"
            }];

        return {
            columns: rirColumns,
            dataSource: rirDataSource,
            editable: !this.readOnly,
            navigatable: true,
            scrollable: false,
            pageable: false
        };
    }

    GetDisclosureDocumentRatePlanMinimumItemSchema() : kendo.data.DataSourceSchema
    {
        let schema: kendo.data.DataSourceSchema = {
            model: {
                id: "DisclosureDocumentRatePlanMinimumItemId",
                fields: {
                    DisclosureDocumentRatePlanMinimumItemId: {
                        type: "number",
                        validation: { required: true },
                        editable: false,
                        defaultValue: 0
                    },
                    WeeksInBusinessStart: { type: "number", validation: { required: true } },
                    WeeksInBusinessEnd: { type: "number", validation: { required: true } },
                    RoyaltyFeeMinimumFixedRateAmount: { type: "number", validation: { required: true } },
                    AdFeeMinimumFixedRateAmount: { type: "number", validation: { required: true } },
                    NetSalesMinimumAmount: { type: "number", validation: { required: true } },
                    RoyaltyPeriodFrequencyId: { type: "number", validation: { required: true }, defaultValue: 1 },
                    MinimumScaleBasedOn: { type: "number", validation: { required: true }, defaultValue: 1 },
                    HasMonthlyMinimumChanges: {
                        type: "boolean",
                        defaultValue: false
                    },
                    HasRollInRateChanges: {
                        type: "boolean",
                        defaultValue: false
                    }
                }
            }
        };

        for (let month of moment.months()) {
            schema.model.fields["MinimumApplied" + month] = { type: "boolean" };
        }

        return schema;
    }

    ToggleMinimumApplied(dataItem: kendo.data.Model, month: string)
    {
        dataItem.set("MinimumApplied" + month, !dataItem.get("MinimumApplied" + month));
    }

    GetRoyaltyFindScaleBasedOnTypesId(findScaleBasedOnId: number)
    {
        return _.find(this.royaltyFindScaleBasedOnTypes, (fs) => { return fs.FindScaleBasedOnId === findScaleBasedOnId; });
    }

    GetRoyaltyPeriodFrequencyById(royaltyPeriodFrequencyId: number)
    {
        return _.find(this.royaltyPeriodFrequencies, (rpf) => { return rpf.RoyaltyPeriodFrequencyId === royaltyPeriodFrequencyId; });
    }

    AddNewRatePlanMinimumItem()
    {
        if (!this.readOnly)
        {
            let model: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel = {
                DisclosureDocumentRatePlanMinimumItemId: 0,
                DisclosureDocumentRatePlanId: this.disclosureDocumentRatePlanId,
                MinimumScaleBasedOn: null,
                RoyaltyPeriodFrequencyId: null,
                WeeksInBusinessStart: 0,
                SmallRollInLowRangeAmount: 0,
                SmallRollInHighRangeAmount: 0,
                SmallRollInMinimumRoyaltyFeeRate: 0,
                SmallRollInMinimumRoyaltyFeeFixedRateAmount: 0,
                SmallRollInMinimumAdFeeRate: 0,
                SmallRollInMinimumAdFeeFixedRateAmount: 0,
                MediumRollInLowRangeAmount: 0,
                MediumRollInHighRangeAmount: 0,
                MediumRollInMinimumRoyaltyFeeRate: 0,
                MediumRollInMinimumRoyaltyFeeFixedRateAmount: 0,
                MediumRollInMinimumAdFeeRate: 0,
                MediumRollInMinimumAdFeeFixedRateAmount: 0,
                LargeRollInLowRangeAmount: 0,
                LargeRollInHighRangeAmount: 0,
                LargeRollInMinimumRoyaltyFeeRate: 0,
                LargeRollInMinimumRoyaltyFeeFixedRateAmount: 0,
                LargeRollInMinimumAdFeeRate: 0,
                LargeRollInMinimumAdFeeFixedRateAmount: 0,
                RoyaltyFeeMinimumRate: 0,
                RoyaltyFeeMinimumFixedRateAmount: 0,
                AdFeeMinimumRate: 0,
                AdFeeMinimumFixedRateAmount: 0,
                MinimumAppliedJanuary: true,
                MinimumAppliedFebruary: true,
                MinimumAppliedMarch: true,
                MinimumAppliedApril: true,
                MinimumAppliedMay: true,
                MinimumAppliedJune: true,
                MinimumAppliedJuly: true,
                MinimumAppliedAugust: true,
                MinimumAppliedSeptember: true,
                MinimumAppliedOctober: true,
                MinimumAppliedNovember: true,
                MinimumAppliedDecember: true,
                NetSalesMinimumAmount: 0,
                WeeksInBusinessEnd: 0,
                CreatedDateTime: kendo.toString(new Date(), 'd'),
                CreatedUser: null,
                UpdatedDateTime: null,
                UpdatedUser: null,
                DeletedDateTime: null,
            };

            this.ratePlanMinimumItemGrid.dataSource.add(model);
        }
    }

    SelectRatePlanMinimumItem(ratePlanMinimumItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel, isRemoved: boolean = false)
    {
        if (this.selectedRatePlanMinimumItem === ratePlanMinimumItem)
            ratePlanMinimumItem = null;

        this.selectedRatePlanMinimumItem = ratePlanMinimumItem;
        if (this.onSelectedRatePlanMinimumItem)
        {
            this.onSelectedRatePlanMinimumItem({ ratePlanMinimumItem: this.selectedRatePlanMinimumItem, isRemoved: isRemoved });
        }
    }
    
    SelectRowById(id: number)
    {
        let dataItem = this.ratePlanMinimumItemGrid.dataSource.get(id);
        if (dataItem)
            this.SelectRatePlanMinimumItem(<any>dataItem);
    }

    CancelChanges()
    {
        if (!this.readOnly)
        {
            Object.keys(this.monthlyMinimumGrids).forEach(key => {
                this.monthlyMinimumGrids[key].dataSource.cancelChanges();
            });
            Object.keys(this.rollInMinimumsGrids).forEach(key => {
                this.rollInMinimumsGrids[key].dataSource.cancelChanges();
            });
            this.ratePlanMinimumItemGrid.dataSource.cancelChanges();
        }
    }
    
    HasChanges()
    {
        if (!this.ratePlanMinimumItemGrid)
            return false;

        return (
            this.ratePlanMinimumItemGrid.dataSource.hasChanges()
            ||
            Object.keys(this.monthlyMinimumGrids)
                .map(key => { return this.monthlyMinimumGrids[key].dataSource.hasChanges(); })
                .some(hasChanges => { return hasChanges; })
            ||
            Object.keys(this.rollInMinimumsGrids)
                .map(key => { return this.rollInMinimumsGrids[key].dataSource.hasChanges(); })
                .some(hasChanges => { return hasChanges; })
        );
    }

    ResetChanges()
    {    
        this.CancelChanges();

        this.monthlyMinimumGrids = {};
        this.rollInMinimumsGrids = {};

        return this.ratePlanMinimumItemGrid.dataSource.read().then(() => {
            this.ratePlanMinimumItemGrid.refresh();

            this.$timeout(() =>
            {
                this.formController.$setPristine();
            }, 1000);
        });
    }

    SaveChanges()
    {
        let syncPromises: ng.IPromise<any>[] = [];
        Object.keys(this.monthlyMinimumGrids).forEach(key => {
            syncPromises.push(this.$q.when(this.monthlyMinimumGrids[key].dataSource.sync()));
        });
        Object.keys(this.rollInMinimumsGrids).forEach(key => {
            syncPromises.push(this.$q.when(this.rollInMinimumsGrids[key].dataSource.sync()));
        });
        return this.$q.all(syncPromises).then(() => {
            return this.$q.when(this.ratePlanMinimumItemGrid.dataSource.sync()).then(() => {
                return this.ResetChanges();
            });
        })
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("fddRoyaltiesRatePlanMinimumItems", {
                bindings: {
                    disclosureDocumentRatePlanId: "<",
                    readOnly: "<",
                    onSelectedRatePlanMinimumItem: "&?",
                    onLoaded: "&?"
                },
                templateUrl: '/Templates/FDDManagement/fddRoyalties/RatePlanMinItems.html',
                controller: fddRoyaltiesRatePlanMinimumItemsComponentController
            });
    }
}

