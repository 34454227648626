import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { LegalApiResources } from "Services/Resources/LegalApiResources";
import { LegalEntities } from "Interfaces/FranForce/Legal/LegalResources";


interface IIndexIttScope extends ng.IScope {
    comboBoxDataSource: kendo.data.DataSource;  
    gridOptions: kendo.ui.GridOptions;

    gridDataSource: kendo.data.DataSource;
    specificGridOptions: kendo.ui.GridOptions;

    concepts: IConceptOption[];

    newContractActive: boolean;
    sectionTitle: string;
    sectionSpecificName: string;

    selectionDetails: { franchiseID: string, contractID: string };
    selectionsMade: boolean;
    activeSection: { section: string }

    onCancel: (string) => void;
    onChange: (string) => void;
    onConceptChange: (any) => void;
    onLicenseChange: (object) => void;
    newContract: (contractTypeID) => void;
    newContractClick: () => void;
    onGridEdit: (any) => void;

    isConceptSelected: boolean;
    isLicenseSelected: boolean;
    isSpecificSelected: boolean;

    newSpecific: () => void;
    specificChanged: (any) => void;
    selectedConcept: string;
    selectedFranchiseID: string;
    selectedLicenseNumber: string;
    selectedConceptName: string;
    selectedConceptID: string;
    cancelContract: (contractID: any) => void;
    cureContract: (contractID: any) => void
}

export class IndexIttController implements ng.IController {
    
    static $inject = [
        "$scope",
        "coreApiResources",
        "legalApiResources",
        "$window",
        "$q",
        "$log",
        "$http",
        "ngDialog"
    ];

    constructor(
        private $scope: IIndexIttScope,
        private coreApiResources: CoreApiResources,
        private legalApiResources: LegalApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $http: ng.IHttpService,
        private ngDialog: any
    )
    {
        $scope.sectionTitle = "Legal ITT";
        $scope.sectionSpecificName = "CONTRACT";   

        // Initialize global selection variables
        $scope.selectionDetails = { franchiseID: "", contractID: ""};
        $scope.selectionDetails.franchiseID = "";
        $scope.selectionDetails.contractID = "";

        $scope.selectionsMade = false;
        $scope.newContractActive = false;
        $scope.activeSection = { section: "1" };
    }

    $onInit() {
        let $scope = this.$scope;
        let coreApiResources = this.coreApiResources;
        let legalApiResources = this.legalApiResources;
        let window = this.$window;       
        let $http = this.$http;
        let ngDialog = this.ngDialog;     

        $scope.$on('selectionsMade', function (event, data) {
            $scope.selectionsMade = true;
            //$scope.$apply();
        });

        $scope.$on('selectionsChanged', function (event, data) {
            $scope.selectionsMade = false;
        });

        $scope.newContract = function (contractTypeID) {
            window.location.href = "/Itt/CreateNewContract/" + this.selectedLicense.FranchiseId + "/" + contractTypeID;
        }

        $scope.isConceptSelected = false;
        $scope.isLicenseSelected = false;
        $scope.isSpecificSelected = false;

        $scope.newContractClick = function () {
            ngDialog.open({
                template: 'NewContractModal',
                className: 'ngdialog-theme-default itt-modal',
                scope: $scope,
                controller: ['$scope', ($scope) => { }]
            });
        }

        $scope.newContract = function (contractTypeID) {
            window.location.href = "/Itt/CreateNewContract/" + this.selectedLicense.FranchiseId + "/" + contractTypeID;
        }

        $scope.newSpecific = function () {
            $scope.isSpecificSelected = false;
            $scope.$emit('selectionsChanged');
        }

        $scope.onGridEdit = function(contractID) {
            $http.get('/Itt/GoToNextSection/' + $scope.selectedFranchiseID + "/" + contractID).
                then((successResponse: any) => {
                    window.location.href = successResponse.data.url;
                }, errorResponse => {
                    console.log(errorResponse);
                });
        }

        $scope.specificChanged = function (arg) {
            $scope.isSpecificSelected = true;

            var selected = arg.sender.dataItem(this.select());
            var contractID = selected.ContractID;

            $http.get('/Itt/GoToNextSection/' + $scope.selectedFranchiseID + "/" + contractID).
                then((successResponse: any) => {
                    window.location.href = successResponse.data.url;
                }, errorResponse => {
                    console.log(errorResponse);
                });
        }

        $scope.comboBoxDataSource = new kendo.data.DataSource({ data: [] });
        $scope.gridDataSource = new kendo.data.DataSource({ data: [] });

        $scope.onConceptChange = function (concept) {
            if ($scope.selectedConcept != "0") {
                $scope.isConceptSelected = true;
                $scope.isLicenseSelected = false;
                $scope.isSpecificSelected = false;
                $scope.selectedConceptName = concept.DisplayName;
                $scope.selectedConceptID = concept.ConceptId;

                coreApiResources.FranchiseApi.query({
                    $select: "LicenseNumber, FranchiseId",
                    $filter: "ConceptId eq " + $scope.selectedConceptID
                }, (franchises: IFranchiseOption[]) => {
                    $scope.comboBoxDataSource.data(franchises);
                });

            } else {
                $scope.isConceptSelected = false;
            }

            // Clears out text in License combobox
            this.selectedLicense = "";
        }

        $scope.onLicenseChange = function (item) {

            $scope.isSpecificSelected = false;

            if (item == null) {
                $scope.isLicenseSelected = false;
                $scope.selectedLicenseNumber = null;
            }
            else {
                $scope.isLicenseSelected = true;

                $scope.selectedLicenseNumber = item.LicenseNumber;
                $scope.selectedFranchiseID = item.FranchiseId;

                refreshContractGrid();
            }
        }

        let specificColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "DateCreated",
                title: "Date Created",
                template: "#= kendo.toString(kendo.parseDate(DateCreated, 'yyyy-MM-dd'), 'MM/dd/yyyy') #",
                width: 128
            }, {
                field: "ContractType.Description",
                title: "Contract Type"
            }, {
                field: "ContractStatu.Description",
                title: "Status"
            }, {
                template: kendo.template(angular.element("#editTemplate").html()),
                title: "Edit"
            }, {
                title: "",
                template: kendo.template(angular.element("#IttTemplate").html()),
                width: "250px",
                sortable: false
            }
        ];

        $scope.specificGridOptions = {
            columns: specificColumns,
            dataSource: $scope.gridDataSource,
            selectable: true,
            change: $scope.specificChanged
        };

        $scope.cureContract = (contractID: any) => {
            if (confirm("Are you sure you want to cure this release task?")) {
                legalApiResources.EndCurePeriod(contractID).then(successResponse => {
                    refreshContractGrid();
                }, errorResponse => {
                    console.log(errorResponse);
                });
            }
        }

        $scope.cancelContract = (contractID : any) => {
            legalApiResources.CancelContract(contractID).then(successResponse => {
                refreshContractGrid();
            }, errorResponse => {
                console.log(errorResponse);
            });
        }

        coreApiResources.GetAllowedConcepts().then(
            (successResponse) => {
                $scope.concepts = successResponse.data;
            }, errorResponse => {
                console.log(errorResponse);
            });

        function refreshContractGrid() {
            legalApiResources.ContractApi.query({
                $filter: "FranchiseID eq " + $scope.selectedFranchiseID,
                $expand: "ContractType, ContractStatu",
                $select: "DateCreated,ContractID"
            }, function successCallback(response: LegalEntities.Contract[]) {
                $scope.gridDataSource.data(response);
            }, function errorCallback(response) {
                console.log(response);
            });
        }
    }
}