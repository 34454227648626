import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { BaseStateHandler } from "StateHandlers/BaseStateHandler";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyServiceCategoriesByFranchisorStateHandler extends BaseStateHandler<RpmEntities.RoyaltyServiceCategoryViewModel[], number> {

    constructor(
        private rpmUiApiResources: RpmUiApiResources
    )
    {
        super();
    }

    Get(franchisorId: number): angular.IPromise<RpmEntities.RoyaltyServiceCategoryViewModel[]>
    {
        return this.rpmUiApiResources.GetRoyaltyServiceCategoriesByFranchisor(franchisorId)
            .then((response) => { return response.data; })
    }
}