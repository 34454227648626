
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class IndexFranchiseController implements ng.IController {

    franchises: CoreResources.IFranchise[];
    gridOptions: kendo.ui.GridOptions;
    
    static $inject = [
        "$scope",
        "coreApiResources",
        "$window",
        "$q",
        "$log"
    ];

    constructor(
        private $scope: ng.IScope,
        private coreApiResources: CoreApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService)
    {

    }

    $onInit()
    {
        this.coreApiResources.FranchiseApi.query({
            $select: "FranchiseId,LicenseNumber,StateAbbr,DoingBusinessAs,Country,ContactFirstName,ContactLastName",
            $expand: "Concept($select=ConceptCode,ConceptId)",
            $filter: "Status ne 'Inactive'"
        }, (franchises: CoreResources.IFranchise[]) => {
            this.franchises = franchises;

            let mainGrid = angular.element("#mainGrid");
            let windowEle = angular.element(this.$window);

            let gridHeight = windowEle.height() - mainGrid.offset().top - 15;

            if (gridHeight < 250) {
                gridHeight = 250;
            }

            this.gridOptions = {
                dataSource: this.franchises,
                sortable: true,
                resizable: true,
                pageable: {
                    numeric: false,
                    pageSize: 25,
                    pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                    input: true
                },
                filterable: {
                    mode: "row"
                },
                columns: <kendo.ui.GridColumn[]>[
                    {
                        field: "Concept.ConceptCode",
                        title: "Concept"
                    }, {
                        field: "LicenseNumber",
                        title: "License Number",
                        minScreenWidth: 1000
                    }, {
                        field: "DoingBusinessAs",
                        title: "DBA"
                    }, {
                        field: "ContactFirstName",
                        title: "First Name"
                    }, {
                        field: "ContactLastName",
                        title: "Last Name"
                    }, {
                        field: "StateAbbr",
                        title: "State",
                        minScreenWidth: 1200
                    }, {
                        field: "Country",
                        title: "Country",
                        minScreenWidth: 1300
                    }, {
                        title: "Edit Franchise",
                        template: "<div style='text-align: center;'><a class='pure-button' href='/Franchises/Edit/#= FranchiseId #'>Edit: #= LicenseNumber#</a></div>",
                        sortable: false
                    }
                ],
                height: gridHeight
            };
        });
    }
}