import * as elements from 'typed-html';
import { StringProperties } from "Types/StringProperties";
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import { KendoUtil } from 'Utility/KendoUtil';
import { ODataHelperService } from 'Services/Utility/ODataHelperService';
import { RpmUiApiResources } from 'Services/Resources/RpmUiApiResources';
import { ExpressionBinding, AngularUtil } from 'Utility/AngularUtil';

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            fddRoyaltiesRatePlanSelector: Partial<StringProperties<fddRoyaltiesRatePlanSelector>>;
        }
    }
}


export type fddRoyaltiesRatePlanSelectorOnSelect = {
    entity: kendo.data.ObservableObject & RoyaltyDisclosureDocumentRatePlanDropDownItem
};

export interface RoyaltyDisclosureDocumentRatePlanDropDownItem {
    DisclosureDocumentRatePlanId: number,
    Display: string
}

export class fddRoyaltiesRatePlanSelector implements ng.IController 
{
    disclosureDocumentId: number
    disclosureDocumentRatePlanId: number;
    onSelect: ExpressionBinding<fddRoyaltiesRatePlanSelectorOnSelect>;

    isLoading: boolean = false;
    private dropDown: kendo.ui.DropDownList;

    static $inject = [
        "odataHelper",
        "rpmUiApiResources"
    ];

    constructor(
        private odataHelper: ODataHelperService,
        private rpmUiApiResources: RpmUiApiResources,
    )
    {

    }

    $onInit()
    {

    }

    $postLink()
    {
        this.InitDropDown();
    }

    $onChanges(changes: ng.IOnChangesObject)
    {

    }

    async InitDropDown()
    {
        let options: kendo.ui.DropDownListOptions = {
            autoBind: true,
            valuePrimitive: true,
            optionLabel: "Select Rate Plan...",
            optionLabelTemplate: "Select Rate Plan...",
            dataTextField: nameof<RoyaltyDisclosureDocumentRatePlanDropDownItem>(o => o.Display),
            dataValueField: nameof<RoyaltyDisclosureDocumentRatePlanDropDownItem>(o => o.DisclosureDocumentRatePlanId),
            select: (e) =>
            {
                if (this.onSelect)
                    this.onSelect({ entity: e.dataItem });
            }
        };
        this.dropDown.setOptions(options);
        await this.LoadDropDownData(this.disclosureDocumentId);
    }

    async LoadDropDownData(disclosureDocumentId: number)
    {
        return AngularUtil.TrackLoadingPromise((async () =>
        {
            let disclosureDocuments = (await this.rpmUiApiResources.GetRoyaltyDisclosureDocumentRatePlansByDisclosureDocument(disclosureDocumentId)).data;
            let dropDownItems = disclosureDocuments.map(ratePlan =>
            {
                let item: RoyaltyDisclosureDocumentRatePlanDropDownItem = {
                    DisclosureDocumentRatePlanId: ratePlan.DisclosureDocumentRatePlanId,
                    Display: `Id:${ratePlan.DisclosureDocumentRatePlanId} - ${kendo.toString(kendo.parseDate(ratePlan.CreatedDateTime), "d")}`
                };
                return item;
            });
            this.dropDown.setDataSource(new kendo.data.DataSource({ data: dropDownItems }));
        })(), this);
    }


    static BindComponent(app: ng.IModule)
    {
        let dropDownTemplate = (
            <select kendo-drop-down-list={`$ctrl.${nameof<fddRoyaltiesRatePlanSelector>(o => o.dropDown)}`}
                k-ng-model={`$ctrl.${nameof<fddRoyaltiesRatePlanSelector>(o => o.disclosureDocumentRatePlanId)}`}
            >
            </select>
        );

        let loadingSpinnerTemplate = (
            <loadingSpinner isLoading={`$ctrl.${nameof<fddRoyaltiesRatePlanSelector>(o => o.isLoading)}`}></loadingSpinner>
        );

        let template = `${dropDownTemplate} ${loadingSpinnerTemplate}`;
        let componentName = nameof<JSX.IntrinsicElements>(o => o.fddRoyaltiesRatePlanSelector);
        app.component(componentName, {
            bindings: {
                [nameof<fddRoyaltiesRatePlanSelector>(o => o.disclosureDocumentId)]: "<",
                [nameof<fddRoyaltiesRatePlanSelector>(o => o.disclosureDocumentRatePlanId)]: "<",
                [nameof<fddRoyaltiesRatePlanSelector>(o => o.onSelect)]: "&?",
            },
            controller: fddRoyaltiesRatePlanSelector,
            template: template
        });
    }
}

