
import { cacheBusterConfig } from 'AppConfig/CacheBusterConfig';
import { httpInterceptorConfig } from 'AppConfig/HttpInterceptor';
import { locationProviderConfig } from 'AppConfig/LocationProviderConfig';
import { promiseButtonsConfig } from 'AppConfig/PromiseButtonsConfig';
import { routeProviderConfig } from 'AppConfig/RouteProviderConfig';
import { ApiConfig } from 'AppConfig/ApiConfig';

export function Init()
{
    //let app = angular.module("portal.config", [
    //    'ngSanitize',
    //    'ngResource',
    //    'ngRoute',

    //    "portal.services",
    //]);
    let app = angular.module('portal')

    //app.constant('DayOfWeek', System.DayOfWeek);
    app.constant("apiConfig", new ApiConfig());

    app.config(cacheBusterConfig);
    app.config(httpInterceptorConfig);
    app.config(locationProviderConfig);
    app.config(promiseButtonsConfig);
    app.config(routeProviderConfig);
}