import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { KendoHelperService } from "Services/Utility/KendoHelperService";
import * as _ from "underscore"
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import
{
  ConceptClient,ConceptDto
} from '@nbly/franchise-group-api-clients';
import { AxiosInstance } from "axios";
import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { ServiceOrchestrationApiResources } from "Services/Resources/ServiceOrchestrationApiResources";
import
{
    ServiceConfigVm, ServicesClient
} from '@nbly/service-orchestrations-clients';
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";

export class EditConceptController implements ng.IController {
    conceptId: number;
    concept: CoreResources.IConcept;
    franchiseGroupConcept:ConceptDto;
    serviceConfigs: ServiceConfigVm[];
    availableServiceSkus: CoreResources.IServiceSku[];
    franchiseProfileSchedulingTypes: CoreResources.IFranchiseProfileSchedulingType[];
    isServiceNameExclusionToggleOriginalValue:boolean;
    selectedNormalServices: CoreResources.IServiceSku[];
    selectedPriorityServices: CoreResources.IServiceSku[];

    normalMultiSelect: kendo.ui.MultiSelect;
    priorityMultiSelect: kendo.ui.MultiSelect;
    serviceNormalSelectOptions: kendo.ui.MultiSelectOptions;
    servicePrioritySelectOptions: kendo.ui.MultiSelectOptions;
    
    feeOptions: kendo.ui.NumericTextBoxOptions;
    conceptTabStrip: kendo.ui.TabStrip;

    franchiseGroupClient: AxiosInstance;
    conceptClient: ConceptClient;

    serviceOrchestrationsClient: AxiosInstance;
    servicesClient: ServicesClient;
    serviceConfigurationUrl: string;
    showPhotoCategoryTab:boolean;
    isAdmin:boolean;

    static $inject = [
        "$scope",
        "coreApiResources",
        "$window",
        "$q",
        "$log",
        "odataHelper",
        "kendoHelper",
        "serviceOrchestrationApiResources",
        '$http',
        'identityManager',
        'apiConfig'
    ];

    constructor(
        private $scope: ng.IScope,
        private coreApiResources: CoreApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private odataHelper: ODataHelperService,
        private kendoHelper: KendoHelperService,
        private serviceOrchestrationApiResources: ServiceOrchestrationApiResources,
        private $http: ng.IHttpService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig
    )
    {
    }

    async fetchServiceConfig()
    {

        this.serviceConfigurationUrl = this.serviceOrchestrationApiResources.GetServiceConfigurationByConceptUrl(this.concept.ConceptCode);
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.serviceConfigurationUrl,

        };
        return this.$http<any>(config);
    }

    $onInit()
    {
        this.franchiseGroupClient = FranForceAxiosClientBuilder.BuildFranchiseGroupBaseClient();
        this.conceptClient=new ConceptClient("",this.franchiseGroupClient);
        var urlParts = this.$window.location.pathname.split("/");

        this.identityManager.GetLoggedInUserInfo()
        .then((loggedInUser) => {
            
                this.showPhotoCategoryTab = this.identityManager.UserHasRole(loggedInUser.data, [
                this.apiConfig.FranForceConstants.RoleConstants.AdminRole,
                this.apiConfig.FranForceConstants.RoleConstants.DigitalOps,
                this.apiConfig.FranForceConstants.RoleConstants.DigitalAdmin,
            ]);

            this.isAdmin = this.identityManager.UserHasRole(loggedInUser.data, [
                this.apiConfig.FranForceConstants.RoleConstants.AdminRole,
                this.apiConfig.FranForceConstants.RoleConstants.ConceptManagerRole
             /*    this.apiConfig.FranForceConstants.RoleConstants.DigitalAdmin, */
            ]);
        });

        this.conceptId = parseInt(urlParts[urlParts.length - 1]);
        
        this.feeOptions = {
            format: "c",
            decimals: 2,
            step: 1
        };

        this.conceptClient.get_protected_concepts_conceptid(this.conceptId).
        then((response)=>{
            this.franchiseGroupConcept=response.result[0];
            this.isServiceNameExclusionToggleOriginalValue=this.franchiseGroupConcept.isServiceNameExclusionToggle;
     
        })
        

        this.concept = this.coreApiResources.ConceptApi.get({ id: this.conceptId  }, () => {
            this.fetchServiceConfig().then((response) =>
            {
                this.serviceConfigs = response.data;

            });
            this.RefreshServices();
        });

        this.coreApiResources.FranchiseProfileSchedulingTypeApi.query({}, (schedulingTypes: CoreResources.IFranchiseProfileSchedulingType[]) => {
            this.franchiseProfileSchedulingTypes = schedulingTypes;
        }, () => { alert("There was an issue loading the scheduling types."); });
    }

    $postLink()
    {
        this.kendoHelper.BindTabStripStateHandlers(this.conceptTabStrip);
    }

    RefreshServices()
    {
        this.concept.ConceptServiceSkus = this.coreApiResources.ConceptServiceSkuApi.query({ $filter: `ConceptId eq ${this.concept.ConceptId}`},
            () =>
            {
            this.availableServiceSkus = this.coreApiResources.ServiceSkuApi.query({}, () => {
                this.selectedNormalServices = [];
                this.selectedPriorityServices = [];

                for (let serviceSku of this.availableServiceSkus) {
                    for (let conceptServiceSku of this.concept.ConceptServiceSkus) {
                        if (conceptServiceSku.ServiceSkuId === serviceSku.ServiceSkuId) {
                            this.selectedNormalServices.push(serviceSku);
                            if (conceptServiceSku.IsPriority) {
                                this.selectedPriorityServices.push(serviceSku);
                            }
                        }
                    }
                }

                this.selectedNormalServices = _.sortBy(this.selectedNormalServices, (s) => { return s.Name; });
                this.selectedPriorityServices = _.sortBy(this.selectedPriorityServices, (s) => { return s.Name; });

                this.serviceNormalSelectOptions =
                    {
                        placeholder: "Select services...",
                        dataTextField: "Name",
                        dataValueField: "ServiceSkuId",
                        valuePrimitive: false,
                        autoBind: true,
                        dataSource: this.availableServiceSkus,
                        dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) => {
                            this.normalMultiSelect = e.sender;
                        },
                        change: (e: kendo.ui.MultiSelectChangeEvent) => {
                            //Remove potentially selected priority services that may have been deselected in the normal services.
                            let i = this.selectedPriorityServices.length;
                            this.$log.info(this.selectedPriorityServices);
                            while (i--) {
                                let found = false;
                                for (let normalService of this.selectedNormalServices) {
                                    if (normalService.ServiceSkuId === this.selectedPriorityServices[i].ServiceSkuId) {
                                        found = true;
                                        break;
                                    }
                                }
                                this.$log.info(found);
                                if (!found) {
                                    this.$log.info("Deleting...", this.selectedPriorityServices);
                                    this.$log.info(i);
                                    this.selectedPriorityServices.splice(i, 1);
                                    this.$log.info("Deleted", this.selectedPriorityServices);
                                }
                            }

                            this.priorityMultiSelect.setDataSource(new kendo.data.DataSource({ data: this.selectedNormalServices }));
                        }
                    };

                this.servicePrioritySelectOptions =
                    {
                        placeholder: "Select services...",
                        dataTextField: "Name",
                        dataValueField: "ServiceSkuId",
                        valuePrimitive: false,
                        autoBind: true,
                        tagTemplate: "<span class='k-icon k-i-note'></span>#:data.Name#",
                        dataSource: this.selectedNormalServices,
                        dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) => {
                            this.priorityMultiSelect = e.sender;
                        },
                        open: (e: kendo.ui.MultiSelectOpenEvent) => {
                            e.sender.setDataSource(new kendo.data.DataSource({ data: this.selectedNormalServices }));
                        }
                    };
            });
        });
    }

    SaveHours(): ng.IPromise<any>
    {
        return this.coreApiResources.ConceptApi.get({ id: this.concept.ConceptId }).$promise
            .then((concept) =>
            {
                concept.DefaultFranchiseProfileSchedulingTypeId = this.concept.DefaultFranchiseProfileSchedulingTypeId;
                return this.coreApiResources.ConceptApi.update({ id: this.concept.ConceptId }, concept).$promise;
            });
    }

    
    ToggleServiceNameExclusion(newValue)
    {
        this.franchiseGroupConcept.isServiceNameExclusionToggle = newValue;
    }

    SaveServices() : ng.IPromise<any>
    {
        let newConceptServices: CoreResources.IConceptServiceSku[] = [];
        let newCs: CoreResources.IConceptServiceSku;
        if(this.isServiceNameExclusionToggleOriginalValue !==this.franchiseGroupConcept.isServiceNameExclusionToggle){
            this.conceptClient.put_protected_concepts_concept_id(this.conceptId,'',
                this.franchiseGroupConcept).then((response)=>{
                    this.isServiceNameExclusionToggleOriginalValue=response.result.isServiceNameExclusionToggle;
                });
        }
    
        for (let normalService of this.selectedNormalServices) {
            newCs = new this.coreApiResources.ConceptServiceSkuApi();
            newCs.ConceptId = this.concept.ConceptId;
            newCs.ServiceSkuId = normalService.ServiceSkuId;
            newCs.IsPriority = false;

            for (let priorityService of this.selectedPriorityServices) {
                if (newCs.ServiceSkuId === priorityService.ServiceSkuId) {
                    newCs.IsPriority = true;
                    break;
                }
            }
            newConceptServices.push(newCs);
        }

        return this.coreApiResources.ConceptServiceSkuApi.query(
            {
                $filter: `ConceptId eq ${this.concept.ConceptId}`, 
                $expand: "ServiceSku($select=Name)" 
            },
            (conceptServiceSkus: CoreResources.IConceptServiceSku[]) =>
            {
                this.concept.ConceptServiceSkus = conceptServiceSkus;
                let toBeDeletedMessage = "Are you sure you want to remove ";
                let toBeDeleted: CoreResources.IConceptServiceSku[] = [];

                let allPromises = [];
                //Loop through the concept services from the database.
                //If you find them, update them with the new object.
                //If not, it will be queued for a deletion message.
                for (let dbCs of this.concept.ConceptServiceSkus)
                {
                    let i = newConceptServices.length;
                    let foundNewCs = false;
                    while (i--)
                    {
                        if (newConceptServices[i].ServiceSkuId === dbCs.ServiceSkuId)
                        {
                            foundNewCs = true;
                            allPromises.push(newConceptServices[i].$update(
                                {
                                    id: this.odataHelper.ConvertCompositeKeyToString(
                                        {
                                            ConceptId: newConceptServices[i].ConceptId,
                                            ServiceSkuId: newConceptServices[i].ServiceSkuId
                                        })
                                }));
                            break;
                        }
                    }

                    if (!foundNewCs)
                    {
                        if (toBeDeleted.length > 0)
                        {
                            toBeDeletedMessage += " and ";
                        }
                        toBeDeletedMessage += `'${dbCs.ServiceSku.Name}'`;
                        toBeDeleted.push(<CoreResources.IConceptServiceSku>dbCs);
                    }
                }

                for (let remainingCs of newConceptServices)
                {
                    allPromises.push(remainingCs.$save());
                }

                if (toBeDeleted.length > 0 && confirm(toBeDeletedMessage + ". Franchisees are currently offering these services. Are you sure you want to delete?"))
                {
                    for (let deletable of toBeDeleted)
                    {
                        allPromises.push(deletable.$delete({
                            id: this.odataHelper.ConvertCompositeKeyToString(
                                {
                                    ConceptId: deletable.ConceptId,
                                    ServiceSkuId: deletable.ServiceSkuId
                                }), allowHardDelete: true
                        }));
                    }
                }

                return this.$q.all(allPromises);
        }).$promise;
    }
}