import { KendoUtil } from "Utility/KendoUtil";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { MerchantApiResources } from "Services/Resources/MerchantApiResources";
import { AngularUtil } from "Utility/AngularUtil";
import * as moment from "moment"

export class SearchGiftCertificateController implements ng.IController
{
    mainGrid: kendo.ui.Grid;
    gridOptions: kendo.ui.GridOptions;
    conceptId: number;
    resendDdVals:object;
    resendDropDownOptions:kendo.ui.DropDownListOptions;
    selectedResendDdVal: string;
    resendEmailValue:string;
    
    static $inject = [
        "$scope",
        "coreApiResources",
        "merchantApiResources",
        "$q",
        "$log",
        "$window"
    ];

    constructor(
        private $scope: ng.IScope,
        private coreApiResources: CoreApiResources,
        private merchantApiResources: MerchantApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $window: ng.IWindowService)
    {
    }

    $onInit()
    {
        this.gridOptions = {
            sortable: true,
            pageable: {
                numeric: false,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true
            },
            resizable: true,
            height: this.GetGridHeight(),
            columns: [{
                field: "GiftCertificateCombinedId",
                title: "Id",
            }, {
                field: "CreatedDateTime",
                title: "Created",
                format: "{0:yyyy-MM-dd}",
                filterable: {
                    ui: "datetimepicker"
                }
            }, {
                field: "Amount",
                title: "Amount",
                encoded: false,
            }, {
                field: "PurchaserEmail",
                title: "Purchaser Email",
            }, {   
                field: "PurchaserName",
                title: "Purchaser Name",
            }, {  
                field: "RecipientName",
                title: "Recipient",
            }, {    
                field: "RecipientContact",
                title: "Email",
            }, {
                field: "RedeemedByFranchise",
                title: "Redeemed By",
            }, {
                field: "RedeemedDateTime",
                title: "Redeemed On",
                format: "{0:yyyy-MM-dd}",
                filterable: {
                    ui: "datetimepicker"
                }
            }, {
                field: "RefundedDateTime",
                title: "Refunded On",
                format: "{0:yyyy-MM-dd}",
                filterable: {
                    ui: "datetimepicker"
                }
            }]
        };
        this.selectedResendDdVal = 'SendGrid';
        this.resendDdVals = ['SendGrid','SMTP'];
        this.resendDropDownOptions = {
            dataSource: this.resendDdVals,
            change: (e: kendo.ui.DropDownListChangeEvent) => {
                this.resendEmailValue = this.selectedResendDdVal;
            },
        };
    }

    Search()
    {
        return this.merchantApiResources.GiftCertificateApi.query(
            {
                $select: "GiftCertificateId,GiftCertificateSuffix,GiftCertificateSuffixString,GiftCertificateGroupId,Amount,ConceptId,CreatedDateTime,PurchaserEmail,PurchaserFirstName,PurchaserLastName,RecipientEmail,RecipientFirstName,RecipientLastName,RecipientFullName,RedeemedDateTime",
                $expand: "RedeemedByFranchise($select=LicenseNumber),RefundMerchantTransaction($select=CreatedDateTime),GiftCertificateReimbursementBatch($select=CreatedDateTime)",
                $filter: "(MerchantTransactionId ne null or GiftCertificateGroup/MerchantTransactionId ne null or GiftCertificateId lt 200000) and ConceptId eq " + this.conceptId,
                $orderby: "CreatedDateTime desc"
            }).$promise.then((giftCertificates) => {
                let displayList: Array<IGiftCertificateSearchResult> = giftCertificates.map<IGiftCertificateSearchResult>(gc => {
                    let momentRedeemedDateTime = moment.utc(gc.RedeemedDateTime);
                    let momentRefundedDateTime = moment.utc(gc.RefundMerchantTransaction ? gc.RefundMerchantTransaction.CreatedDateTime : null);
                    let momentBatchDateTime =  moment.utc(gc.GiftCertificateReimbursementBatch ? gc.GiftCertificateReimbursementBatch.CreatedDateTime : null);
                    return {
                        Id: gc.GiftCertificateId,
                        Suffix: gc.GiftCertificateSuffix,
                        SuffixString: gc.GiftCertificateSuffixString,
                        CreatedDateTime: moment.utc(gc.CreatedDateTime).toDate(),
                        Amount: gc.Amount,
                        GiftCertificateCombinedId: gc.GiftCertificateId + "-" + (gc.GiftCertificateSuffixString || gc.GiftCertificateSuffix),
                        GiftCertificateGroupId: gc.GiftCertificateGroupId,
                        PurchaserName: (gc.PurchaserFirstName || "") + " " + (gc.PurchaserLastName || ""),
                        PurchaserEmail: gc.PurchaserEmail,
                        RecipientContact: gc.RecipientEmail,
                        RecipientName: gc.RecipientFullName || (gc.RecipientFirstName || "") + " " + (gc.RecipientLastName || ""),
                        RedeemedByFranchise: gc.RedeemedByFranchise != null ? gc.RedeemedByFranchise.LicenseNumber : null,
                        RedeemedDateTime: momentRedeemedDateTime.isValid() ? momentRedeemedDateTime.toDate() : null,
                        RefundedDateTime: momentRefundedDateTime.isValid() ? momentRefundedDateTime.toDate() : null,
                        BatchDateTime: momentBatchDateTime.isValid() ? momentBatchDateTime.toDate() : null
                    }
                });

                this.mainGrid.setDataSource(new kendo.data.DataSource({
                    data: displayList,
                    schema: {
                        model: {
                            fields: {
                                GiftCertificateCombinedId: { type: "string" },
                                GiftCertificateGroupId: { type: "number" },
                                CreatedDateTime: { type: "date" },
                                Amount: { type: "number" },
                                PurchaserEmail: { type: "string" },
                                PurchaserName: { type: "string" },
                                RecipientName: { type: "string" },
                                RecipientContact: { type: "string" },
                                RedeemedByFranchise: { type: "string" },
                                RedeemedDateTime: { type: "date" },
                                RefundedDateTime: { type: "date" }
                            }
                        }
                    },
                    pageSize: this.mainGrid.pager.pageSize()
                }));
            });
    }

    ResendEmails(gc: IGiftCertificateSearchResult)
    {
        this.resendEmailValue = this.selectedResendDdVal;
        if (confirm(`Are you sure you want to send resend emails for ${gc.GiftCertificateCombinedId}? ${(gc.RedeemedByFranchise || gc.RefundedDateTime ? "PLEASE NOTE: This gift certificate has already been redeemed or refunded." : "")}`))
        {
            let sendToSelf = confirm("Do you want your account email to be BCCed as well?");
            return this.merchantApiResources.ResendGiftCertificateEmails({
                GiftCertificateId: gc.Id,
                GiftCertificateSuffix: (gc.SuffixString || gc.Suffix.toString()),
                BccCurrentUser: sendToSelf,
                EmailDeliveryMethod : this.resendEmailValue,
            }
            ).catch(AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback<any>(this.$log, this.$q));
        }
    }

    GetGridHeight()
    {
        let mainGrid = angular.element("#mainGrid");
        let windowEle = angular.element(this.$window);

        let gridHeight = windowEle.height() - mainGrid.offset().top - 15;

        if (gridHeight < 250) {
            gridHeight = 250;
        }
        return gridHeight;
    }
}
