import * as elements from 'typed-html';
import { ExpressionBinding } from 'Utility/AngularUtil';
import { StringProperties } from 'Types/StringProperties';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            quarterSelector: StringProperties<Partial<QuarterSelector>>;
        }
    }
}

export class Quarter {
    quarterValue: number;
    quarterLabel: string;
}

export type QuarterSelectorOnSelectParams = {
    event: kendo.ui.DropDownListSelectEvent,
    quarter: Quarter;
}

export class QuarterSelector implements ng.IController {
    quarter: number;
    readOnly: boolean;
    onSelect: ExpressionBinding<QuarterSelectorOnSelectParams>;
    dropDown: kendo.ui.DropDownList;

    static $inject = []

    constructor() {}

    $onInit() {}

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.setOptions({ enable: !this.readOnly })
        }
    }

    $postLink() {
        this.InitDropdown();
        if (this.quarter) {
            this.dropDown.select(x => x.quarterValue == this.quarter);
            this.dropDown.trigger("change");
        }
    }

    InitDropdown() {
        this.dropDown.setOptions(
            {
                autoBind: true,
                valuePrimitive: true,
                dataValueField: nameof<Quarter>(o => o.quarterValue),
                dataTextField: nameof<Quarter>(o => o.quarterLabel),
                optionLabel: "Select...",
                optionLabelTemplate: "Select...",
                autoWidth: true,
                enable: !this.readOnly,
                select: (e) => {
                    if (this.onSelect) {
                        this.onSelect({
                            event: e,
                            quarter: e.dataItem
                        });
                    }
                }
            } as kendo.ui.DropDownListOptions
        );

        let dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    let quarterList: Quarter[] = [];
                    quarterList.push({ quarterLabel: "Q1", quarterValue: 1 });
                    quarterList.push({ quarterLabel: "Q2", quarterValue: 2 });
                    quarterList.push({ quarterLabel: "Q3", quarterValue: 3 });
                    quarterList.push({ quarterLabel: "Q4", quarterValue: 4 });
                    options.success(quarterList);
                }
            }
        });

        this.dropDown.setDataSource(dataSource);
    }

    static BindComponent(app: ng.IModule) {
        const template =
            <select kendo-drop-down-list={`$ctrl.${nameof<QuarterSelector>(o => o.dropDown)}`}
                k-ng-model={`$ctrl.${nameof<QuarterSelector>(o => o.quarter)}`}
            >
            </select>;

        app.component(nameof<JSX.IntrinsicElements>(o => o.quarterSelector), {
            bindings: {
                [nameof<QuarterSelector>(o => o.quarter)]: "<",
                [nameof<QuarterSelector>(o => o.readOnly)]: "<",
                [nameof<QuarterSelector>(o => o.onSelect)]: "&?",
            },
            template: template,
            controller: QuarterSelector
        });
    }


}