import {RmsDashboardSalesVarianceResultVm} from '@nbly/royalty-orchestrations-clients';

export class rmsDashboardSalesVarianceGridComponentController implements ng.IController {

    numberOfPeriods: number;
    salesVarianceFranchisorGrid: kendo.ui.Grid;
    salesVarianceFranchisorGridOptions: kendo.ui.GridOptions;
    salesVarianceFranchiseeGrid: { [uid: string]: kendo.ui.Grid };

    static $inject = [
        '$window',
        '$location',
        '$q',
        '$scope',
        "$rootScope",
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private $scope: ng.IScope,
        private $rootScope: ng.IRootScopeService,
    ) {

    }

    $onInit() {
        this.salesVarianceFranchiseeGrid = {};
        this.LoadGrid();
        this.$rootScope.$on('salesVarianceDataChanged', (event, args) => {
            if (args && args.salesVarianceData && args.numberOfPeriods) {

                let salesVarianceResultData: RmsDashboardSalesVarianceResultVm[] = args.salesVarianceData;
                this.numberOfPeriods = args.numberOfPeriods;
                let salesVarianceData = this.GetReStructuredSalesVarianceData(salesVarianceResultData)
                this.PopulateGrid(salesVarianceData);
            }
        });

        this.$rootScope.$on('resetGrid', () => {
            this.salesVarianceFranchisorGrid.dataSource.data([])
            this.salesVarianceFranchisorGrid.refresh();
        });
    }

    PopulateGrid(data: any) {
        this.salesVarianceFranchisorGrid.dataSource.data(data)
        this.salesVarianceFranchisorGrid.refresh();
    }

    LoadGrid() {

        let salesVarianceFranchisorDataSource = new kendo.data.DataSource({
            data:[],
            pageSize: 10,
            schema: {
                model: {
                    id: "franchisorId",
                    fields: {
                        franchisorId: {
                            type: "number"
                        },
                        franchisorName: {
                            type: "string"
                        },
                        franchiseeReportCount: {
                            type: "number"
                        }
                    }
                }
            }
        });

        let salesVarianceFranchisorColumns: Array<kendo.ui.GridColumn> =
        [
            {
                field: "franchisorId",
                title: "Franchisor Id"
            },
            {
                field: "franchisorName",
                title: "Franchisor Name"
            },
            {
                field: "franchiseeReportCount",
                title: "Count"
            },
        ];

        this.salesVarianceFranchisorGridOptions = {
            autoBind: false,
            dataSource: salesVarianceFranchisorDataSource,
            columns: salesVarianceFranchisorColumns,
            pageable: {
                numeric: false,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
        };
    }

    GetSalesVarianceFranchiseeGridOptions(data: any): kendo.ui.GridOptions {
        let salesVarianceFranchiseeGridDataSource = new kendo.data.DataSource({
            batch: true,
            pageSize: 10,
            schema: {
                model: {
                    id: "licenseNumber",
                    fields: {
                        franchisorId: {
                            type: "string"
                        },
                        licenseNumber: {
                            type: "string"
                        },
                        franchiseeName: {
                            type: "string"
                        },
                        doingBusinessAs: {
                            type: "string"
                        },
                        royaltyPeriodFrequency: {
                            type: "string"
                        }
                    }
                }
            },
            transport: {
                read: (e: kendo.data.DataSourceTransportReadOptions) => {
                    e.success(data.franchisesReports);
                },
            }
        });

        let salesVarianceFranchiseeGridColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "franchisorId",
                title: "Franchisor Id",
                hidden: true,
            }, {
                field: "licenseNumber",
                title: "License Number",
            }, {
                field: "franchiseeName",
                title: "Name",
            }, {
                field: "doingBusinessAs",
                title: "DBA",
            }, {
                field: "royaltyPeriodFrequency",
                title: "Royalty Period Frequency",
            }];

        let numberOfDynamicColumns = +this.numberOfPeriods + +(this.numberOfPeriods - 1)
        let varianceCount = 1;
        let periodCount = 1
        for (let i = 1; i <= numberOfDynamicColumns; i++) {

            let title = '';
            if (i % 2 != 0) {

                if (this.numberOfPeriods - periodCount <= 0)
                    title = 'Most Recent Period';
                else
                    title = 'Most Recent Period - ' + (this.numberOfPeriods - periodCount)

                salesVarianceFranchiseeGridColumns.push({
                    field: 'period' + periodCount,
                    title: title,
                    format: "{0:n}",
                })

                periodCount++;
            }
            else {
                title = 'Calculated Variance'

                salesVarianceFranchiseeGridColumns.push({
                    field: 'variance' + varianceCount,
                    title: title,
                    template: "<span>#=variance" + varianceCount + "# %<\span>",
                })

                varianceCount++;
            }
        }

        return {
            columns: salesVarianceFranchiseeGridColumns,
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: true,
            sortable: true,
            scrollable: true,
            dataSource: salesVarianceFranchiseeGridDataSource,
            resizable: true
        };
    }

    GetReStructuredSalesVarianceData(data: RmsDashboardSalesVarianceResultVm[]) {

        let reStructuredSalesVarianceData = data.map(x => {
            let franchisor = {
                franchisorId: x.franchisorId,
                franchisorName: x.franchisorName,
                franchiseeReportCount: x.franchiseeCount
            }

            let franchisesReports = x.franchiseeReportSalesVariationInfo.map(x => {
                let franchiseReport = {
                    franchisorId: x.franchisorId,
                    licenseNumber: x.licenseNumber,
                    franchiseeName: x.franchiseeName,
                    doingBusinessAs: x.doingBusinessAs
                }

                x.rmsDashboardVarianceDynamicColumns.forEach(y => {
                    franchiseReport[y.columnName] = y.value
                })

                return franchiseReport;
            })

            franchisor['franchisesReports'] = franchisesReports

            return franchisor;
        });

        return reStructuredSalesVarianceData;
    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsDashboardSalesVarianceGrid", {
            bindings: {

            },
            templateUrl: '/Templates/RMSDashboard/RmsDashboardSalesVariance/rmsDashboardSalesVarianceGrid.html',
            controller: rmsDashboardSalesVarianceGridComponentController
        });
    }
}