import { DayOfWeek } from '@TypeGen/Enums';

export class Helpers
{
    contructor()
    {

    }

    static StartsWithIgnoreCase(str: string, prefix: string): boolean
    {
        return this.StartsWith(str.toLowerCase(), prefix.toLowerCase());
    }

    static StartsWith(str: string, prefix: string): boolean
    {
        if ((<any>String).prototype.startsWith)
        {
            return (<any>str).startsWith(prefix);
        }
        else
        {
            return str.slice(0, prefix.length) === prefix;
        }
    }

    static EndsWithIgnoreCase(str: string, postfix: string): boolean
    {
        return this.EndsWith(str.toLowerCase(), postfix.toLowerCase());
    }

    static EndsWith(str: string, postfix: string): boolean
    {
        if ((<any>String).prototype.endsWith)
        {
            return (<any>str).endsWith(postfix);
        }
        else
        {
            return str.length >= postfix.length && str.substr(str.length - postfix.length) === postfix;
        }
    }

    static StripNonNumerices(str: string): string
    {
        if (str)
            return str.replace(/\D/g, '');
        else
            return str;
    }

    static ReplaceWhitespaceWithVisibleLiterals(str: string)
    {
        if (!str)
            return str;

        return str.replace(/\n/g, '\\n')
            .replace(/\r/g, '\\r')
            .replace(/\t/g, '\\t')
            .replace(/ /g, String.fromCharCode(183));
    }

    static ChunkSplit<T>(array: Array<T>, splitNumber: number): Array<Array<T>>
    {
        let r: Array<Array<T>> = [];
        for (var i = 0; i < this.length; i += splitNumber)
        {
            r.push(array.slice(i, i + splitNumber));
        }
        return r;
    }

    static NumberArrayDifference(orgNumArray: number[], newNumArray: number[])
    {
        let add: number[] = [];
        let remove: number[] = [];

        if (orgNumArray == undefined || orgNumArray == null)
        {
            orgNumArray = [];
        }

        if (newNumArray == undefined || newNumArray == null)
        {
            newNumArray = [];
        }

        for (var i = 0; i < orgNumArray.length; i++)
        {
            if (newNumArray.indexOf(orgNumArray[i]) < 0)
            {
                remove.push(orgNumArray[i]);
            }
        }

        for (var i = 0; i < newNumArray.length; i++)
        {
            if (orgNumArray.indexOf(newNumArray[i]) < 0)
            {
                add.push(newNumArray[i]);
            }
        }

        return { add, remove };
    }
    /**
        * https://www.typescriptlang.org/docs/handbook/mixins.html
        * @param derivedCtor
        * @param baseCtors
        */
    static ApplyMixins(derivedCtor: any, baseCtors: any[])
    {
        baseCtors.forEach(baseCtor =>
        {
            Object.getOwnPropertyNames(baseCtor.prototype).forEach(name =>
            {
                derivedCtor.prototype[name] = baseCtor.prototype[name];
            });
        });
    }

    static GetInt32Max()
    {
        return Math.pow(2, 31) - 1;
    }

    static isUrl(string: string)
    {
        var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        return regexp.test(string);
    }

    static MapEnumToObject(enumObj: any)
    {
        return Object.keys(enumObj)
            .filter((key) => { return isNaN(Number(enumObj[key])) === false })
            .map(key =>
            {
                return {
                    id: <number>enumObj[key],
                    name: <string>key
                }
            });
    }

    static DaysOfWeekObjects(): Array<{ id: number, name: string }>
    {
        return [
            { id: DayOfWeek.Sunday, name: nameof(DayOfWeek.Sunday) },
            { id: DayOfWeek.Monday, name: nameof(DayOfWeek.Monday) },
            { id: DayOfWeek.Tuesday, name: nameof(DayOfWeek.Tuesday) },
            { id: DayOfWeek.Wednesday, name: nameof(DayOfWeek.Wednesday) },
            { id: DayOfWeek.Thursday, name: nameof(DayOfWeek.Thursday) },
            { id: DayOfWeek.Friday, name: nameof(DayOfWeek.Friday) },
            { id: DayOfWeek.Saturday, name: nameof(DayOfWeek.Saturday) }
        ]
    }

    static MinutesToDate(min: number): Date
    {
        min = parseInt(<any>min);

        if (isNaN(min))
            return null;

        let date = new Date();
        date.setHours(min / 60, min % 60, 0);
        return date;
    }

    static formatPolygonData(polygon)
    {
        if (polygon)
        {
            // Regex for extracting the lat lng
            let latLngMatch = /(-?\d{1,3}\.\dE-\d+|-?\d{1,3}\.?\d*)\s(-?\d{1,3}\.\dE-\d+|-?\d{1,3}\.?\d*)\s?0?\s?0?,?/g,
                reformat = /\[,/g;

            // Turn into an array
            let formattedPolygon = polygon.substring(polygon.indexOf("("), polygon.length)
                .replace(latLngMatch, ",[$1,$2]")
                .replace(/\(/g, "[")
                .replace(/\)/g, "]")
                .replace(reformat, "[");

            // Turn the string into a javascript object                                          
            return eval(formattedPolygon);
        }

        return null;
    }

    static parsePolyStrings(ps)
    {
        var i, j, lat, lng, tmp, tmpArr,
            arr = [],
            //match '(' and ')' plus contents between them which contain anything other than '(' or ')'
            m = ps.match(/\([^\(\)]+\)/g);
        if (m !== null)
        {
            for (i = 0; i < m.length; i++)
            {
                //match all numeric strings
                tmp = m[i].match(/-?\d+\.?\d*/g);
                if (tmp !== null)
                {
                    //convert all the coordinate sets in tmp from strings to Numbers and convert to LatLng objects
                    for (j = 0, tmpArr = []; j < tmp.length; j += 2)
                    {
                        lng = Number(tmp[j]);
                        lat = Number(tmp[j + 1]);
                        tmpArr.push({ lat, lng });
                    }
                    arr.push(tmpArr);
                }
            }
        }
        //array of arrays of LatLng objects, or empty array
        return arr;
    }
    static checkDaysToExpiry(date: string)
    {
        let currentDate = new Date();
        let passwordExpiryDate = this.convertUTCDateToLocalDate(new Date(date));
        
        let seconds = (passwordExpiryDate.getTime() - currentDate.getTime()) / 1000;
        let daysToExpiry = seconds / 86400;
        return Math.floor(daysToExpiry);
    }

    static convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
    
        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();
    
        newDate.setHours(hours - offset);
    
        return newDate;   
    }
}




export function NameOfFullToControllerAs(nameofStr: string, controllerAs: string = '$ctrl'): string
{
    return nameofStr.replace(/^this\./, `${controllerAs}.`);
}