import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { RpmEntities } from "../../../Interfaces/FranForce/Rpm/RpmResources";
import { RpmUiApiResources } from "../../../Services/Resources/RpmUiApiResources";
import * as _ from "underscore";
import * as moment from "moment";
import { RpmHelperService } from "../../../Services/Utility/RpmHelperService";
import { ODataHelperService } from "../../../Services/Utility/ODataHelperService";
import { createPosTransactionSyncInvoicesDataModel } from "../../../Models/Royalty/PosTransaction/CreatePosTransactionSyncInvoicesDataModel";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { MomentUtil } from "Utility/MomentUtil";

export interface reportBasedOnTypes {
    reportBasedOnTypeId: number;
    reportBasedOnTypeName: string;
}

export class TerminatedReportEntryController implements ng.IController {

    conceptId: number;
    franchisorId: number;
    franchisorFilter: kendo.data.DataSourceFilter;
    franchiseId: number;
    franchiseFilter: kendo.data.DataSourceFilter;
    franchiseRoyaltySystemId?: number;
    royaltyReportBatchId: number;

    //selectedTab: string;
    isLoadingTerminated: boolean;

    contract: RpmEntities.RoyaltyContractViewModel;
    contractDropDown: kendo.ui.DropDownList;
    contractDropDownOptions: kendo.ui.DropDownListOptions;

    selectedEntry: RpmEntities.RoyaltyManualSalesEntryViewModel;

    salesGrid: kendo.ui.Grid;

    periodReports: RpmEntities.RoyaltyPeriodReportViewModel[];

    selectedPeriodEndDate: string;

    serviceCategories: RpmEntities.usp_RoyaltyServiceCategorys_GetByContractId_Result[];
    frequencies: RpmEntities.RoyaltyPeriodFrequencyViewModel[];

    showManualSalesEntryReports: boolean;
    showPosTransactionReports: boolean;
    showPosTransactionStatusMsg: boolean;
    posTrnsactionStatusMsg: string;
    posTransactionStatusCSSClass: string;

    //ReportBasedOnDropDown
    showReportBasedOnDropDown: boolean;
    reportBasedOnDropDownOptions: kendo.ui.DropDownListOptions;
    reportBasedOnDropDownList: kendo.ui.DropDownList;
    isReportBasedOnDropDownDisabled: boolean;
    selectedReportBasedOn: number;
    reportBasedOnTypes: reportBasedOnTypes[]

    franchise: Partial<CoreResources.IFranchise>;

    currentUserEmail: string;

    showRoyaltyReportBatchId: boolean;

    static $inject = [
        'royaltyApiResources',
        '$q',
        '$log',
        '$timeout',
        'rpmUiApiResources',
        'rpmHelper',
        'odataHelper',
        'identityManager',
    ];

    constructor(
        private royaltyApiResources: RoyaltyApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private odataHelper: ODataHelperService,
        private identityManager: IdentityManager,
    ) {
        //this.selectedTab = 'terminatedReportEntry';
    }

    $onInit() {
        //this.selectedTab = 'terminatedReportEntry';
        this.franchisorFilter = [];
        this.franchiseFilter = null;
        this.contract = null;
        this.periodReports = null;
        this.conceptId = null;
        this.franchisorId = null;
        this.franchiseId = null;

        this.InitContractDropDownOptions();
        this.InitReportBasedOnTypes();

        this.rpmUiApiResources.GetAllRoyaltyPeriodFrequencies().then((frequenciesResponse) => {
            this.frequencies = frequenciesResponse.data;
        });

        this.isLoadingTerminated = false;
        this.showReportBasedOnDropDown = false;
        this.showManualSalesEntryReports = false;
        this.showPosTransactionReports = false;
        this.selectedReportBasedOn = null;
        this.showPosTransactionStatusMsg = false;
        this.posTrnsactionStatusMsg = "";
        this.posTransactionStatusCSSClass = ""

        this.InitCurrentUserEmail();
    }

    InitCurrentUserEmail() {
        this.identityManager.GetLoggedInUserInfo()
            .then(successResponse => {
                this.currentUserEmail = successResponse.data.Email;
            });
    }

    InitContractDropDownOptions() {
        this.contractDropDownOptions = {
            filter: "contains",
            dataValueField: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.RoyaltyPeriodId),
            dataTextField: "DisplayText",
            template: `<span class="has-report-#:HasReport#">#:DisplayText#<span ng-show="#:HasReport#"> - Complete</span></span>`,
            dataSource: new kendo.data.DataSource({
                transport:
                {
                    read: (options) => {
                        if (!this.franchisorId) {
                            options.success([]);
                            return;
                        }
                        this.isLoadingTerminated = true;
                        this.rpmUiApiResources.GetRoyaltyPeriodReportsByFranchise(this.franchiseId)
                            .then((periodReportsResponse) => {
                                this.periodReports = periodReportsResponse.data;
                                return this.rpmUiApiResources.GetRoyaltyPeriodsByFranchisor(this.franchisorId);
                            })
                            .then((response) => {
                                let periods = response.data;
                                periods = _.sortBy(periods, (p) => {
                                    p.PeriodEndDate
                                }).reverse();

                                periods = _.filter(periods, (period) => {
                                    if (this.contract) {
                                        return moment.utc(period.PeriodEndDate).isSameOrBefore(new Date(), "day") &&
                                            period.RoyaltyPeriodFrequencyId == this.contract.RoyaltyPeriodFrequencyId;
                                    }
                                });

                                periods.forEach((period) => {
                                    let validStatusIds = [3, 6, 7];
                                    (<any>period).HasReport = this.periodReports
                                        .filter(p => validStatusIds.some(id => id == p.RoyaltyPeriodReportStatusID))
                                        .some(p => p.RoyaltyPeriodId == period.RoyaltyPeriodId);
                                    (<any>period).DisplayText = moment.utc(period.PeriodEndDate).format("MM/DD/Y");
                                })
                                options.success(periods);
                            })
                            .catch((err) => {
                                options.error(err);
                                return this.$q.reject(err);
                            })
                            .finally(() => {
                                this.isLoadingTerminated = false;
                            });
                    }
                }
            }),
            select: (e) => {
                if (e.dataItem.HasReport) {
                    e.preventDefault();
                    return;
                }

                this.$timeout(() => {
                    if (!e.dataItem) {
                        this.selectedPeriodEndDate = null;
                        this.contract = null;
                        this.selectedEntry = null;
                        return;
                    }

                    let pickedRecord: RpmEntities.RoyaltyPeriodViewModel = e.dataItem
                    let pickedDate = moment.utc(pickedRecord.PeriodEndDate);

                    this.selectedPeriodEndDate = pickedDate.format(`Y-MM-DDT00:00:00.000Z`);
                    this.InitGridOptions();

                    //if (!this.selectedContract) {
                    //    alert("There are no contracts found for " + (<any>pickedRecord).DisplayText);
                    //    e.preventDefault();
                    //    return;
                    //}

                    this.isLoadingTerminated = true;
                    this.rpmUiApiResources.GetRoyaltyManualSalesEntryByFranchiseAndPeriodEndDate(this.franchise.FranchiseId, this.selectedPeriodEndDate)
                        .then(entryResponse => {
                            this.selectedEntry = entryResponse.data;
                            this.RefreshGrid();
                        })
                        .finally(() => {
                            this.isLoadingTerminated = false;
                        });;
                })
            }
        };
    }

    InitGridOptions() {
        let columns: kendo.ui.GridColumn[] = [{
            field: nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.RoyaltyManualSalesEntryDetailId),
            title: "Id",
            hidden: true
        }, {
            field: nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.Description),
            title: "Description"
        }, {
            field: nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossResidentialSales),
            title: "Gross Residential Sales",
            format: "{0:c}",
            aggregates: ["sum"],
            footerTemplate: `Total: {{$ctrl.GetFooterTotal('${nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossResidentialSales)}')}}`
        }, {
            field: nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossCommercialSales),
            title: "Gross Commercial Sales",
            format: "{0:c}",
            aggregates: ["sum"],
            footerTemplate: `Total: {{$ctrl.GetFooterTotal('${nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossCommercialSales)}')}}`
        }, {
            field: nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossTotalSales),
            title: "Gross Total Sales",
            editor: (container, options) => {
                container.append(kendo.toString(options.model.get(nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossTotalSales)), 'c'));
            },
            template: `#= kendo.toString(${nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossTotalSales)}, 'c') #`,
            //template: `#: kendo.toString(${nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossCommercialSales)} + ${nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossResidentialSales)}, 'c') #`,
            aggregates: ["sum"],
            footerTemplate: `Total: {{$ctrl.GetFooterTotal('${nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossTotalSales)}')}}`
        }, {
            command: [{ name: "destroy" }],
            title: "&nbsp;",
            width: "100px"
        }];

        if (this.contract && this.contract.RoyaltyMethodologyTypeId === this.rpmHelper.SpecialRoyaltyMethodologyTypeId) {
            columns.splice(2, 0, {
                field: nameof<RpmEntities.usp_RoyaltyServiceCategorys_GetByContractId_Result>(o => o.RoyaltyServiceCategoryId),
                title: "Service Category",
                editor: (container, options) => {
                    let input = "<input required name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: nameof<RpmEntities.usp_RoyaltyServiceCategorys_GetByContractId_Result>(o => o.Name),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyServiceCategorys_GetByContractId_Result>(o => o.RoyaltyServiceCategoryId),
                        dataSource: {
                            data: this.serviceCategories
                        },
                        filter: "contains",
                        change: (e) => {
                            var dataItem: RpmEntities.usp_RoyaltyServiceCategorys_GetByContractId_Result = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.serviceCategories[0];
                            }

                            options.model.set(nameof<RpmEntities.usp_RoyaltyServiceCategorys_GetByContractId_Result>(o => o.RoyaltyServiceCategoryId), dataItem.RoyaltyServiceCategoryId);
                        }
                    });
                },
                template: `{{$ctrl.${nameof<TerminatedReportEntryController>(o => o.GetServiceCategoryById)}` +
                    `(#=${nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.RoyaltyServiceCategoryId)}#).${nameof<RpmEntities.usp_RoyaltyServiceCategorys_GetByContractId_Result>(o => o.Name)}}}`
            });
        }

        let datasource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.RoyaltyManualSalesEntryDetailId),
                    fields: {
                        RoyaltyManualSalesEntryDetailId: {
                            type: "number",
                            editable: false,
                            defaultValue: 0
                        },
                        Description: {
                            type: "string",
                            validation: { required: true },
                            editable: true
                        },
                        RoyaltyServiceCategoryId: {
                            type: "number",
                            validation: { required: true },
                            editable: true
                        },
                        GrossResidentialSales: {
                            type: "number",
                            validation: { required: true },
                            editable: true
                        },
                        GrossCommercialSales: {
                            type: "number",
                            validation: { required: true },
                            editable: true
                        },
                        GrossTotalSales: {
                            type: "number",
                            validation: { required: true },
                            editable: true
                        },
                    }
                }
            },
            change: (e) => {
                if (e.field === nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossResidentialSales) ||
                    e.field == nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossCommercialSales)) {
                    for (let item of <kendo.data.Model[] & RpmEntities.RoyaltyManualSalesEntryDetailViewModel[]>e.items) {
                        this.$timeout(() => {
                            let total = item.GrossResidentialSales + item.GrossCommercialSales;
                            item.set(nameof<RpmEntities.RoyaltyManualSalesEntryDetailViewModel>(o => o.GrossTotalSales), total);
                        });
                    }
                }
            },
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {
                    let serviceCategoriesPromise: ng.IHttpPromise<RpmEntities.usp_RoyaltyServiceCategorys_GetByContractId_Result[]>;
                    if (this.contract) {
                        serviceCategoriesPromise = this.rpmUiApiResources.GetRoyaltyServiceCategoriesByContract(this.contract.RoyaltyContractId).then((categoriesResponse) => {
                            this.serviceCategories = categoriesResponse.data;
                            return categoriesResponse;
                        });
                    }
                    else {
                        serviceCategoriesPromise = this.$q.resolve(<ng.IHttpPromiseCallbackArg<RpmEntities.usp_RoyaltyServiceCategorys_GetByContractId_Result[]>>{
                            config: null,
                            headers: null,
                            status: 200,
                            statusText: "OK",
                            xhrStatus: null,
                            data: []
                        });
                    }

                    if (!this.selectedEntry) {
                        serviceCategoriesPromise.then(() => {
                            options.success([]);
                        });
                        return;
                    }

                    this.isLoadingTerminated = true;

                    serviceCategoriesPromise
                        .then((categoriesResponse) => {
                            this.serviceCategories = categoriesResponse.data;
                            return this.rpmUiApiResources.GetRoyaltyManualSalesEntryDetailsByRoyaltyManualSalesEntry(this.selectedEntry.RoyaltyManualSalesEntryId)
                        })
                        .then((detailsResponse) => {
                            options.success(detailsResponse.data);
                        })
                        .catch((err) => { options.error(err); })
                        .finally(() => {
                            this.isLoadingTerminated = false;
                        });
                },
                create: (options: kendo.data.DataSourceTransportOptions) => {
                    let createModel: RpmEntities.RoyaltyManualSalesEntryDetailViewModel = options.data;
                    createModel.RoyaltyManualSalesEntryId = this.selectedEntry.RoyaltyManualSalesEntryId;
                    this.rpmUiApiResources.CreateRoyaltyManualSalesEntryDetail(createModel).then(
                        (idResponse) => {
                            createModel.RoyaltyManualSalesEntryDetailId = idResponse.data;
                            options.success({ RoyaltyManualSalesEntryDetailId: idResponse.data })
                        }, (err) => { options.error(err) });
                },
                update: (options: kendo.data.DataSourceTransportOptions) => {
                    let updateModel: RpmEntities.RoyaltyManualSalesEntryDetailViewModel = options.data;
                    this.rpmUiApiResources.UpdateRoyaltyManualSalesEntryDetail(updateModel.RoyaltyManualSalesEntryDetailId, updateModel)
                        .then((idResponse) => {
                            options.success();
                        }, (err) => { options.error(err) });
                },
                destroy: (options: kendo.data.DataSourceTransportOptions) => {
                    let deleteModel: RpmEntities.RoyaltyManualSalesEntryDetailViewModel = options.data;
                    deleteModel.DeletedDateTime = new Date().toISOString();
                    this.rpmUiApiResources.UpdateRoyaltyManualSalesEntryDetail(deleteModel.RoyaltyManualSalesEntryDetailId, deleteModel)
                        .then((idResponse) => {
                            options.success();
                        }, (err) => { options.error(err) });
                }
            }
        });

        let options: kendo.ui.GridOptions = {
            columns: columns,
            dataSource: datasource,
            editable: true,
            autoBind: false,
            navigatable: true,
            toolbar: <kendo.ui.GridToolbarItem[]>[{
                template: `<a class='pure-button' ng-click='$ctrl.${nameof<TerminatedReportEntryController>(o => o.AddNewEntryDetail)}()'>Add New...</a>`
            }, {
                    template: `<a class='pure-button' ng-click='$ctrl.${nameof<TerminatedReportEntryController>(o => o.AddAllNewEntryDetails)}()'>Add All...</a>`
            }],

        }

        this.salesGrid.setOptions(options);
    }

    SelectFranchisor(franchisor: Partial<CoreResources.IFranchisor>) {
        this.franchise = null;
        this.franchiseId = null;

        this.showManualSalesEntryReports = false;
        this.showReportBasedOnDropDown = false;
        this.selectedReportBasedOn = null;
        if (!franchisor || !franchisor.FranchisorId) {
            this.franchiseFilter = null;
            this.selectedEntry = null;
            this.RefreshGrid();
            return;
        }

        if (this.franchisorId === franchisor.FranchisorId) {
            return;
        }

        this.franchisorId = franchisor.FranchisorId;
        this.conceptId = franchisor.ConceptId;

        this.franchiseFilter = <kendo.data.DataSourceFilterItem>{
            field: nameof<CoreResources.IFranchise>(o => o.FranchisorId),
            operator: "eq",
            value: this.franchisorId
        };
    }

    SelectFranchise(franchise: Partial<CoreResources.IFranchise>) {

        this.showPosTransactionStatusMsg = false;

        if (!franchise || !franchise.FranchisorId) {
            this.franchise = null
            this.selectedEntry = null;
            this.RefreshGrid();
            this.isLoadingTerminated = false;
            return;
        }

        if (this.franchiseId === franchise.FranchiseId) {
            this.isLoadingTerminated = false;
            return;
        }

        this.showManualSalesEntryReports = false;
        this.showReportBasedOnDropDown = false;
        this.selectedReportBasedOn = null;
        this.showPosTransactionStatusMsg = false;
        this.posTrnsactionStatusMsg = "";
        this.posTransactionStatusCSSClass = "";
        this.showPosTransactionReports = false;
        this.contract = null;
        this.selectedPeriodEndDate = null;
        this.franchise = null;
        this.franchiseId = null
        this.isLoadingTerminated = true;
        this.SetPosTransactionProcessStatusMessage(false, "", "");

        this.contract = null;
        this.franchise = franchise;
        this.franchiseId = franchise.FranchiseId;

        this.contractDropDown.dataSource.read();

        return this.rpmUiApiResources.GetRoyaltyContractsByFranchise(this.franchiseId)
            .then((contractsResponse) => {
                this.isLoadingTerminated = false;
                let contracts = contractsResponse.data;

                if (contracts.length <= 0) {
                    alert("Error: There are no valid contracts for this franchise.");
                    return;
                }

                this.contract = contracts[0];
                this.contractDropDown.select(null);

                //check if royaltySystemId for the selected franchise is 1
                this.isLoadingTerminated = true;
                this.royaltyApiResources.GetFranchiseById(this.franchiseId).then((franchiseResponse) => {
                    if (franchiseResponse && franchiseResponse.data) {
                        this.isLoadingTerminated = false;
                        this.franchiseRoyaltySystemId = franchiseResponse.data['royaltySystemId'];
                        if (this.franchiseRoyaltySystemId == 1) {
                            this.isReportBasedOnDropDownDisabled = false;
                            this.reportBasedOnDropDownList.value("1");
                            this.reportBasedOnDropDownList.trigger("change");
                            this.showReportBasedOnDropDown = true;
                            this.showManualSalesEntryReports = false;
                            this.showPosTransactionReports = true;
                            this.SetPosTransactionProcessStatusMessage(false, "", "");
                        }
                        else if (this.franchiseRoyaltySystemId == 3) {
                            this.reportBasedOnDropDownList.value("2");
                            this.reportBasedOnDropDownList.trigger("change");
                            this.isReportBasedOnDropDownDisabled = true;
                            this.showReportBasedOnDropDown = true;
                            this.showManualSalesEntryReports = true;
                            this.showPosTransactionReports = false;
                            this.SetPosTransactionProcessStatusMessage(false, "", "");
                        }
                        else {
                            alert("Invalid Franchise RoyaltySystemId");
                            this.showReportBasedOnDropDown = false;
                            this.selectedReportBasedOn = null;
                            this.showManualSalesEntryReports = false;
                        }
                    }
                })
            });
    }

    InitReportBasedOnTypes() {
        this.reportBasedOnTypes = [
            {
                reportBasedOnTypeId: 1,
                reportBasedOnTypeName: "POS transactions",
            },
            {
                reportBasedOnTypeId: 2,
                reportBasedOnTypeName: "Manual Entry",
            }
        ];

        this.reportBasedOnDropDownOptions = {
            dataSource: this.reportBasedOnTypes,
            dataValueField: nameof<reportBasedOnTypes>(o => o.reportBasedOnTypeId),
            dataTextField: nameof<reportBasedOnTypes>(o => o.reportBasedOnTypeName),
            dataBound: (e: kendo.ui.DropDownListDataBoundEvent) => {
                this.reportBasedOnDropDownList = e.sender;
            },
            select: (e: kendo.ui.DropDownListSelectEvent) => {
                if (e && e.dataItem && e.dataItem.reportBasedOnTypeId) {
                    var reportBasedOn = e.dataItem.reportBasedOnTypeId;

                    //show maual sales report container
                    if (reportBasedOn == "2") {

                        //revert report based on value to POS transaction value if period report is not set
                        if (!this.selectedPeriodEndDate) {
                            alert("Please select a period end date for manual entry");
                            e.preventDefault();
                            return;
                        }

                        this.InitContractDropDownOptions();
                        this.InitGridOptions();
                        this.toggleProcessReportActionContainers(true, false);

                    }
                    //show pos transactions container
                    else if (reportBasedOn == "1") {
                        this.toggleProcessReportActionContainers(false, true);
                    }
                }
            } 
        }
    }

    toggleProcessReportActionContainers(showManualSalesEntryReports: boolean, showPosTransactionReports: boolean) {
        this.showManualSalesEntryReports = showManualSalesEntryReports;
        this.showPosTransactionReports = showPosTransactionReports;
    }

    AddNewEntryDetail(category: RpmEntities.usp_RoyaltyServiceCategorys_GetByContractId_Result = null) {
        category = category || this.serviceCategories[0];

        let newEntry: RpmEntities.RoyaltyManualSalesEntryDetailViewModel = {
            CreatedDateTime: new Date().toISOString(),
            CreatedUser: null,
            DeletedDateTime: null,
            Description: category.Name,
            GrossCommercialSales: 0,
            GrossResidentialSales: 0,
            GrossTotalSales: 0,
            RoyaltyManualSalesEntryDetailId: 0,
            RoyaltyManualSalesEntryId: 0,
            RoyaltyServiceCategoryId: category.RoyaltyServiceCategoryId,
            UpdatedDateTime: null,
            UpdatedUser: null
        };

        if (this.selectedEntry) {
            newEntry.RoyaltyManualSalesEntryId = this.selectedEntry.RoyaltyManualSalesEntryId;
        }

        this.salesGrid.dataSource.add(newEntry);
    }

    AddAllNewEntryDetails() {
        let gridData = this.salesGrid.dataSource.data();
        if (gridData && gridData.length) {
            if (!confirm("There are already entries in the grid. Are you sure you want to create a new entry for every available category?")) {
                return;
            }
        }

        for (let category of this.serviceCategories) {
            this.AddNewEntryDetail(category);
        }
    }

    GetFooterTotal(columnName: string) {
        return kendo.toString(_.reduce<kendo.data.Model, number>(this.salesGrid.dataSource.data(), (total, item) => { return total + item.get(columnName); }, 0), "c");
    }

    ProcessPosTranscations() {
        this.isLoadingTerminated = true;
        let createPosTransactionSyncInvoicesData: createPosTransactionSyncInvoicesDataModel;
        if (!this.selectedPeriodEndDate) {
            alert("Please select Period End Date");
            this.isLoadingTerminated = false;
            return;
        }
        let periodEndDate = MomentUtil.EnsureUtcTime(this.selectedPeriodEndDate);
        createPosTransactionSyncInvoicesData = {
            conceptId: this.conceptId,
            franchisorId: this.franchisorId,
            periodEndDate: periodEndDate,
            franchiseId: this.franchiseId,
            royaltyPeriodFrequencyId: this.GetReportFrequencyById(this.contract.RoyaltyPeriodFrequencyId).RoyaltyPeriodFrequencyId,
            userEmail: this.currentUserEmail
        }
        this.royaltyApiResources.ProcessPosTranscations(createPosTransactionSyncInvoicesData).then((response) => {
            if (response && response.data) {
                this.royaltyReportBatchId = response.data;
                this.isLoadingTerminated = false;
                this.SetPosTransactionProcessStatusMessage(true, "POS Transactions processed successfully. Royalty Report Batch ID: ", "posTransactionSuccessStatusCSSClass")
                this.showRoyaltyReportBatchId = true;
            }
        }).catch(error => {
            this.isLoadingTerminated = false;
            let errorMessage = "";
            if (error && error.data && error.data.Message) {
                errorMessage = error.data.Message;
            }
            this.SetPosTransactionProcessStatusMessage(true, "Error! Unable to process POS Transactions." + errorMessage, "posTransactionFailureStatusCSSClass")
            console.log("Error! Unable to process POS Transactions.", error);
            this.showRoyaltyReportBatchId = false;
        })
    }

    SetPosTransactionProcessStatusMessage(show: boolean, errorMessage: string, statusClass: string) {
        this.showPosTransactionStatusMsg = show;
        this.posTrnsactionStatusMsg = errorMessage;
        this.posTransactionStatusCSSClass = statusClass;
    }

    Save() {
        if (!this.selectedEntry && this.salesGrid.dataSource.total() <= 0) {
            let errMessage = "You need at create at least one detail record for a new manual sales entry."
            alert(errMessage);
            return this.$q.reject(errMessage);
        }

        if (this.selectedEntry && this.salesGrid.dataSource.total() <= 0) {
            if (!confirm("No details are specified. This means that the parent entry record will be deleted. Do you want to continue?")) {
                return this.$q.reject();
            }
            this.selectedEntry.DeletedDateTime = new Date().toISOString();
            return this.rpmUiApiResources.UpdateRoyaltyManualSalesEntry(this.selectedEntry.RoyaltyManualSalesEntryId, this.selectedEntry)
                .then(() => {
                    this.selectedEntry = null;
                    return this.SaveGrid();
                });
        }

        if (!this.selectedEntry) {
            this.selectedEntry = {
                CreatedDateTime: new Date().toISOString(),
                CreatedUser: "",
                DeletedDateTime: null,
                FranchiseId: this.franchise.FranchiseId,
                FranchisorId: this.franchise.FranchisorId,
                LastProcessAttemptDateTime: null,
                PeriodEndDate: this.selectedPeriodEndDate,
                ProcessedDateTime: null,
                ProcessRetryCount: 0,
                RoyaltyFrequencyId: this.contract.RoyaltyPeriodFrequencyId,
                RoyaltyManualSalesEntryId: 0,
                RoyaltyManualSalesProcessStatusId: 1, //Ready
                UpdatedDateTime: null,
                UpdatedUser: null
            };

            return this.rpmUiApiResources.CreateRoyaltyManualSalesEntry(this.selectedEntry)
                .then((idResponse) => {
                    this.selectedEntry.RoyaltyManualSalesEntryId = idResponse.data;
                    return this.SaveGrid();
                });
        }
        else {
            return this.SaveGrid();
        }
    }

    Clear() {
        this.conceptId = null;
        this.franchisorId = null;
        this.franchiseId = null;
        this.SelectFranchisor(null);
        this.SelectFranchise(null);
        this.contract = null;
        this.selectedPeriodEndDate = null;
        this.selectedEntry = null;
        this.contractDropDown.select(null);

        this.showManualSalesEntryReports = false;
        this.showPosTransactionReports = false;
        this.showReportBasedOnDropDown = false;
        this.selectedReportBasedOn = null;
        this.showPosTransactionStatusMsg = false;
        this.posTrnsactionStatusMsg = "";
        this.posTransactionStatusCSSClass = "";

        return this.RefreshGrid();
    }

    ClearContract() {
        this.selectedPeriodEndDate = null;
        this.contract = null;
        this.selectedEntry = null;
        this.contractDropDown.select(null);

        return this.RefreshGrid();
    }

    SaveAndClear() {
        this.Save().then(() => {
            return this.Clear()
        });
    }

    SaveAndClearContract() {
        this.Save().then(() => {
            return this.ClearContract()
        });
    }

    DeleteAll() {
        if (!confirm("Are you sure you want to delete this entry and all of its details?")) {
            return this.$q.reject();
        }

        this.selectedEntry.DeletedDateTime = new Date().toISOString();
        return this.rpmUiApiResources.UpdateRoyaltyManualSalesEntry(this.selectedEntry.RoyaltyManualSalesEntryId, this.selectedEntry)
            .then(() => {
                this.selectedEntry = null;
                this.salesGrid.dataSource.data().forEach(row => {
                    this.salesGrid.dataSource.remove(<kendo.data.ObservableObject>row);
                })
                return this.SaveAndClearContract();
            });
    }

    RefreshGrid() {
        return this.$q.when(this.salesGrid.dataSource.read()).then(() => {
            this.salesGrid.refresh();
        })
    }

    SaveGrid() {
        return this.$q.when(this.salesGrid.dataSource.sync()).then(() => {
            this.salesGrid.cancelChanges();
            return this.RefreshGrid();
        });
    }

    GetReportFrequencyById(id: number) {
        return _.find(this.frequencies, (freq) => { return freq.RoyaltyPeriodFrequencyId == id; });
    }

    GetServiceCategoryById(id: number) {
        return _.find(this.serviceCategories, (cat) => { return cat.RoyaltyServiceCategoryId == id; });
    }

    static BindComponent(app: ng.IModule) {
        app.component('terminatedReportEntry', {
            bindings: {

            },
            controller: TerminatedReportEntryController,
            templateUrl: "/Templates/RPM/TerminatedReportEntry.html"
        });
    }
}


