import { KendoUtil } from "Utility/KendoUtil";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";

import
{
    PhotoGalleryCategoryConceptXrefClient, PhotoGalleryCategoryConceptXrefWithCategoryDescDto,
    PhotoGalleryImageInfoDto,PhotoGalleryImageInfoClient,PhotoGalleryForGridDto
} from '@nbly/service-orchestrations-clients';

import
{
    FranchiseWebLocationClient,
    FranchiseWebLocationDto
} from '@nbly/franchise-group-api-clients';


export class PhotoCorporateGridViewComponent implements ng.IController
{
    grid: kendo.ui.Grid;
    gridQueryResponseHeaders: ODataQueryResponseHeaders;
    conceptId: number;
    conceptCode: string;
    franchiseId: number;
    photoEditorWindow: kendo.ui.Window;
    photoEditorWindowOptions: kendo.ui.WindowOptions;
    selectedPhoto:PhotoGalleryImageInfoDto;

    serviceOrchestrationsClient: AxiosInstance;
    photoGalleryImageInfoClient: PhotoGalleryImageInfoClient;
    PhotoGalleryCategoryConceptXrefWithCategoryDescDto: PhotoGalleryCategoryConceptXrefWithCategoryDescDto[];
    franchiseWebLocationDtoArray: FranchiseWebLocationDto[];
    franchiseWebLocationDto: FranchiseWebLocationDto;
    photoGalleryCategoryConceptXrefClient: PhotoGalleryCategoryConceptXrefClient;
    franchiseWebLocationClient: AxiosInstance;
    franchiseWebLocationListClient: FranchiseWebLocationClient;
    photoItem:PhotoGalleryForGridDto;
    searchUrl:string;
    conceptFilters: kendo.data.DataSourceFilter;
    conceptSelect: string[];

    static $inject = [
        '$window',
    ];

    constructor(
        private $window: ng.IWindowService,
    )
    {
        this.serviceOrchestrationsClient = FranForceAxiosClientBuilder.BuildServiceOrchestrationsBaseClient();
        this.franchiseWebLocationClient = FranForceAxiosClientBuilder.BuildFranchiseGroupBaseClient();
        this.photoGalleryImageInfoClient = new PhotoGalleryImageInfoClient("", this.serviceOrchestrationsClient);
        this.photoGalleryCategoryConceptXrefClient = new PhotoGalleryCategoryConceptXrefClient("", this.serviceOrchestrationsClient);
        this.franchiseWebLocationListClient = new FranchiseWebLocationClient("", this.franchiseWebLocationClient);
    }

   async $onInit()
    {

        this.conceptFilters = [];
        this.conceptSelect = [
            nameof<CoreResources.IConcept>(o => o.ConceptId),
            nameof<CoreResources.IConcept>(o => o.DisplayName),
            nameof<CoreResources.IConcept>(o => o.ConceptCode),
        ];
    }

    async LoadStaticInfo()
    {
        await this.loadConceptCategories();

    }


    async loadConceptCategories()
    {

        let selectedCategories = [];
        await this.photoGalleryCategoryConceptXrefClient.concept(this.conceptId).then((res) =>
        {
            this.PhotoGalleryCategoryConceptXrefWithCategoryDescDto = res.result;
             this.PhotoGalleryCategoryConceptXrefWithCategoryDescDto.map((value) =>
            {
                selectedCategories.push(value.photoGalleryCategoryId);

            })
        }).catch((err) =>
        {
            this.PhotoGalleryCategoryConceptXrefWithCategoryDescDto = [];
        });
    }


    async loadFranchiseWeblocations(franchiseId:number)
    {
        if (franchiseId)
        {
            await this.franchiseWebLocationListClient.get_protected_franchiseweblocations_franchises_franchiseid(franchiseId)
                .then((res) =>
                {
                    this.franchiseWebLocationDtoArray = res.result;
                }).catch((err) =>
                {
                    console.log('Error Occured while fetching franchiseWebLocationDtos',this.franchiseWebLocationDtoArray)
                })
        }
    }





    async onConceptSelect(concept: Partial<CoreResources.IConcept>)
    {
        this.conceptCode = concept.ConceptCode;
        this.conceptId = concept.ConceptId;
        await this.LoadStaticInfo();
         this.InitGridOptions();
    }

    onConceptLoad(concept){
  
    }
    
    onSave(){
        this.grid.dataSource.read();
        this.grid.refresh();
    }
    

  async  OpenWindow(photoItem:PhotoGalleryForGridDto)
    {

        this.photoItem=photoItem;

        await Promise.all([this.photoGalleryImageInfoClient.photoGalleryImageInfo3(photoItem.photoGalleryImageInfoId).then((res)=>{
            this.selectedPhoto=res.result;
        }), this.loadFranchiseWeblocations(photoItem.franchiseId)]);

        let template =
                `<div style="width:80vw;height:80vh;">
                <photo-editor-component
                photo-By-Weblocation="$ctrl.selectedPhoto"
                concept-id="$ctrl.conceptId"
                photo-Gallery-Category-Concepts="$ctrl.PhotoGalleryCategoryConceptXrefWithCategoryDescDto"
                franchise-Web-Location-Dto="$ctrl.franchiseWebLocationDtoArray"
                on-save="$ctrl.onSave()"
                ></photo-editor-component>
            </div>`; 


    
        let options: kendo.ui.WindowOptions = {
            modal: true,
            position: { top: 20 },
        };

        this.photoEditorWindow.open();
        this.photoEditorWindow.title('Photo Editor');
        this.photoEditorWindow.content(template)
        this.photoEditorWindow.center();
        this.photoEditorWindow.setOptions(options);

    }

    openPhotoEditor(photoItem:PhotoGalleryForGridDto){
        this.OpenWindow(photoItem);
    }

   async deletePhoto(photoItem:any){

    if (confirm(`Do you want to delete the photo?`))
    {
        await this.photoGalleryImageInfoClient.photoGalleryImageInfo4(
            photoItem.photoGalleryImageInfoId
        ).then((response)=>{
            this.grid.dataSource.read();
            this.grid.refresh();
        }).catch((err)=>{
            console.log("Error Occured while deleting the photo")
        })

    } 
    }

    InitGridOptions()
    {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof.full<PhotoGalleryForGridDto>(o => o.conceptCode),
                title: "Concept"
            }, {
                field: nameof.full<PhotoGalleryForGridDto>(o => o.licenseNumber),
                title: "License Number",
                minScreenWidth: 1000
            }, {
                field: nameof.full<PhotoGalleryForGridDto>(o => o.websiteId),
                title: "Website Id"
            }, {
                field: nameof.full<PhotoGalleryForGridDto>(o => o.doingBusinessAs),
                title: "DBA",
    
            }, {
                field: nameof.full<PhotoGalleryForGridDto>(o => o.franchiseeName),
                title: "Franchisee Name"
            }, {
                field: nameof.full<PhotoGalleryForGridDto>(o => o.altText),
                title: "Alt Text",
            }, {
                field: nameof.full<PhotoGalleryForGridDto>(o => o.photoGalleryCategory),
                title: "Category",

            },
            {
                field: nameof.full<PhotoGalleryForGridDto>(o => o.useForNationalWebsite),
                title: "National Website",
                attributes:{
                    class:'capitalize'
                }

            },
            {
                title: "",
                template: "<button class='pure-button edit-button' ng-click='$ctrl.openPhotoEditor(dataItem)'>Edit</button>",
                width: 100
            },
            {
             
                template: (dataItem: PhotoGalleryForGridDto) => 
                            `<button  class='pure-button button-error' promise-btn ng-click='$ctrl.deletePhoto(dataItem)'>X</button>`,
                sortable: false
            }

        ];

        let dataSource = new kendo.data.DataSource({
            serverSorting: false,
            serverFiltering: false,
            serverPaging: false,
            pageSize: 25,
            
            transport: {
                read: (options) =>
                {
                    this.photoGalleryImageInfoClient.conceptId(this.conceptId).then((response)=>{
                        options.success(response.result);
                    }).catch((err)=>{
                        options.error(err)
                    })

                }
            },
            schema: {
                model: { photoGalleryImageInfoId: "photoGalleryImageInfoId" }
              },
        });

        let gridHeight = angular.element(this.$window).height() - this.grid.element.offset().top - 15;

        if (gridHeight < 250)
        {
            gridHeight = 250;
        }

        this.grid.setOptions(<kendo.ui.GridOptions>{
            dataSource: dataSource,
    
            columns: columns,
            sortable: true,
            resizable: true,
            pageable: {
                numeric: false,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true
            },
            height: gridHeight,
        });
    }




    static BindComponent(app: ng.IModule)
    {
        app.component('photoCorporateGridView', {
            bindings: {

            },
            controller: PhotoCorporateGridViewComponent,
            templateUrl: "/Templates/PhotoEditor/PhotoCorporateGridView.html"
        });

    }
}


