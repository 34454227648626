let promiseButtonsConfig = [
    'angularPromiseButtonsProvider',
    (angularPromiseButtonsProvider: angularPromiseButtons.IPromiseButtonsProvider) =>
    {
        var myConfig: angularPromiseButtons.IPromiseButtonConfigOptions = {
            spinnerTpl: '',
            btnLoadingClass: 'loading',
            onSuccessConfig: {
                resultWaitTime: 1500,
                resultCssClass: "button-success",
                resultHtml: "Success"
            },
            onErrorConfig: {
                resultWaitTime: 1500,
                resultCssClass: "button-error",
                resultHtml: "Error"
            }
        };
        angularPromiseButtonsProvider.extendConfig(myConfig);
    }
];

export { promiseButtonsConfig };