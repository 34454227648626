import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RmsStateService } from "Services/State/RmsState";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { RoyaltyContractNotesDtoPagedResult } from "Models/Royalty/RoyaltyContractNotes/RoyaltyContractNotesDataModel";

export class cmRoyaltiesFeeSettingsComponentController implements ng.IController {

    isLoading: boolean;
    contractId: number;
    readOnly: boolean;
    onLoaded: (params: { self: cmRoyaltiesFeeSettingsComponentController }) => void;
    
    contract: RpmEntities.RoyaltyContractViewModel;
    royaltyPeriodFrequencies: RpmEntities.RoyaltyPeriodFrequencyViewModel[];
    royaltyFindScaleBasedOnTypes: RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result[];

    feeNumericTextBoxOptions: kendo.ui.NumericTextBoxOptions;
    formController: angular.IFormController;
    isDynamicFees: boolean;

    royaltyApiResources: RoyaltyApiResources;


    royaltyContractNotesWindow: kendo.ui.Window;
    royaltyContractNotesWindowOptions: kendo.ui.WindowOptions;

    royaltyContractNotesGrid: kendo.ui.Grid;
    royaltyContractNotesGridOptions: kendo.ui.GridOptions;

    errorMessage: string;
    showErrorMessage: boolean;
    royaltyFrequencyChangeReason: string;
    royaltyEffectiveDate?: string;
    auditType: string = "Frequency Changed";


    static $inject = [
        'rpmUiApiResources',
        'rmsState',
        'identityManager',
        '$q',
        '$timeout',
        "royaltyApiResources",
        '$scope'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private rmsState: RmsStateService,
        private identityManager: IdentityManager,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService,
        royaltyApiResources: RoyaltyApiResources,
        private $scope: ng.IScope,
    )
    {
        this.royaltyApiResources = royaltyApiResources;
        
    }

    $onInit()
    {
       
        this.InitWindow();
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledCm').val() === 'True';
        this.feeNumericTextBoxOptions = {
            format: "c2",
            min: 0
        }

        this.Load().then(() => {
            if (this.onLoaded)
                this.onLoaded({ self: this });
        });
    }

    Load()
    {
        let promises: ng.IPromise<any>[] = [];

        promises.push(this.GetContract()
            .then((contract) => {
                this.contract = contract;
            }));
        
        promises.push(this.rpmUiApiResources.GetAllRoyaltyPeriodFrequencies()
            .then((docResponse) => { this.royaltyPeriodFrequencies = docResponse.data; }));

        promises.push(this.rpmUiApiResources.GetAllRoyaltyFindScaleBasedOnTypes()
            .then((docResponse) => { this.royaltyFindScaleBasedOnTypes = docResponse.data.filter(s => s.UseForContract === true); }));

        return this.$q.all(promises);
    }

    GetContract()
    {
        return this.rmsState.royaltyContractStateHandler.Load(this.contractId, this.isDynamicFees);
    }

    ResetChanges()
    {
        return this.Load();
    }

    async onRoyaltyFrequencyChanged() {
        
        if (this.rmsState && this.rmsState.royaltyContractStateHandler) {
            this.rmsState.royaltyContractStateHandler.Clear(this.contractId);
        }
        const tempContract = await this.GetContract();
        tempContract.CalculateRoyaltyFee = this.contract.CalculateRoyaltyFee;
        tempContract.FindScaleBasedOnId = this.contract.FindScaleBasedOnId;
        if (tempContract.RoyaltyPeriodFrequencyId !== this.contract.RoyaltyPeriodFrequencyId) {
            this.$scope.$emit('setRoyaltyFrequencyChanged', { isRoyaltyFrequencyChanged: true });
         }
         else {
            this.$scope.$emit('setRoyaltyFrequencyChanged', { isRoyaltyFrequencyChanged: false });
         }
        
    }
    async ResetRoyaltyFrequencyId() {
        if (this.rmsState && this.rmsState.royaltyContractStateHandler) {
            this.rmsState.royaltyContractStateHandler.Clear(this.contractId);
        }
        const tempContract = await this.GetContract();
        tempContract.CalculateRoyaltyFee = this.contract.CalculateRoyaltyFee;
        tempContract.FindScaleBasedOnId = this.contract.FindScaleBasedOnId;
        this.contract.RoyaltyPeriodFrequencyId = tempContract.RoyaltyPeriodFrequencyId;
        this.$scope.$evalAsync();
    }

    async SaveChanges() {
        try {                   
            // Logic to get ad fee cap exception and its amount
            const royaltyContractMinimumFeeIncreasesAndCapsByContract = 
                await this.rpmUiApiResources.RoyaltyContractMinimumFeeIncreasesAndCapsByContract(this.contractId);
                
            let adFeeCapException = false;
            let adFeeCapExceptionAmount = 0;
            let adFeeCapsYearlyAmounts;
    
            if (royaltyContractMinimumFeeIncreasesAndCapsByContract && royaltyContractMinimumFeeIncreasesAndCapsByContract.data) {
                adFeeCapsYearlyAmounts = royaltyContractMinimumFeeIncreasesAndCapsByContract.data.sort((t1, t2) => t2.YearIncreased - t1.YearIncreased);
    
                if (adFeeCapsYearlyAmounts && adFeeCapsYearlyAmounts.length > 0) {
                    adFeeCapException = adFeeCapsYearlyAmounts[0].AdFeeCapException;
                    adFeeCapExceptionAmount = adFeeCapsYearlyAmounts[0].AdFeeCap;
                }
            }
    
            const contract = await this.GetContract();
            contract.CalculateRoyaltyFee = this.contract.CalculateRoyaltyFee;
            contract.FindScaleBasedOnId = this.contract.FindScaleBasedOnId;
            contract.RoyaltyPeriodFrequencyId = this.contract.RoyaltyPeriodFrequencyId;
            contract.RoyaltyFrequencyChangeRemark = this.royaltyFrequencyChangeReason;
            contract.EffectiveDate = this.royaltyEffectiveDate;
            contract.CalculateAdFee = this.contract.CalculateAdFee;
            contract.AdFeeFrequencyId = this.contract.AdFeeFrequencyId;
            contract.CalculateTAFSFee = this.contract.CalculateTAFSFee;
            contract.TAFSFeeFrequencyId = this.contract.TAFSFeeFrequencyId;
            contract.CalculateTechnologyFee = this.contract.CalculateTechnologyFee;
            contract.TechnologyFeeFrequencyId = this.contract.TechnologyFeeFrequencyId;
            contract.AnnualRollInSalesAmount = this.contract.AnnualRollInSalesAmount;
            contract.MonthlyRollInSalesAmount = this.contract.MonthlyRollInSalesAmount;
            contract.WeeklyRollInSalesAmount = this.contract.WeeklyRollInSalesAmount;
            contract.AdFeeCapException = adFeeCapException;
            contract.AdFeeCapExceptionAmount = adFeeCapExceptionAmount;
            if (this.isDynamicFees) {
                if (this.rpmUiApiResources && this.rpmUiApiResources.UpdateRoyaltyContractDynamic) {
                    await this.rpmUiApiResources.UpdateRoyaltyContractDynamic(this.contractId, contract);
                }
            }
            else {
            if (this.rpmUiApiResources && this.rpmUiApiResources.UpdateRoyaltyContractDynamic) {
                await this.rpmUiApiResources.UpdateRoyaltyContract(this.contractId, contract);
             }

            }
    
            if (this.rmsState && this.rmsState.royaltyContractStateHandler) {
                this.rmsState.royaltyContractStateHandler.Clear(this.contractId);
            }
    
            await new Promise(resolve => setTimeout(resolve, 1000));

            if (this.formController) {
                this.formController.$setPristine();
            }
    
            return this.contract;
        } catch (error) {
            console.error("Error during SaveChanges:", error);
            // Optionally, handle the error further or display a message to the user
            throw error;
        }
    }    

    async GetRoyaltyContractNotes() {  
        this.royaltyContractNotesWindow.open(); 
         this.$timeout(() => {
             this.royaltyContractNotesWindow.center();
         }, 100);

        this.ErrorMessage(false, "");
        await this.LoadRoyaltyContractNotesGrid();
        await this.royaltyContractNotesGrid.dataSource.read();       
    }

    InitWindow() {
        this.royaltyContractNotesWindowOptions = {
            modal: true,
            resizable: false,
            maxWidth: 1200,
            position: {
                top: '50%',
                left: '50%'
            },
            width: 1200
        }
    }

   

    LoadRoyaltyContractNotesGrid() {
        this.isLoading = true;
        if (!this.royaltyContractNotesGrid) {
            let royaltyContractNotesGridDataSource = new kendo.data.DataSource({
                serverPaging: true,
                
                transport: {
                    read: async (options: kendo.data.DataSourceTransportReadOptions) => {


                        const { skip, take } = options.data;

                        await this.royaltyApiResources.GetRoyaltyContractNotesPageinatedData(this.contractId,this.auditType, skip, take)
                            .then(response => {
                                let royaltyContractNotesResultVm: RoyaltyContractNotesDtoPagedResult = response.data;
                                options.success(royaltyContractNotesResultVm);
                            })
                            .catch(ex => {
                                console.log("Error! Failed to fetch royalty contract notes data", ex);
                                this.ErrorMessage(true, "Error! Failed to fetch royalty contract notes data");
                            })
                            .finally(() => {
                                this.isLoading = false;
                            })
                    }
                },
                pageSize: 25,
                schema: {
                    data: function (data) {
                        return data.results;
                    },
                    total: function (data) {
                        return data.rowCount;
                    },
                    model: {
                        id: "royaltyContractNoteId",
                        fields: {
                            royaltyContractNoteId: {
                                type: "number",
                            },
                            royaltyContractId: {
                                type: "number",
                            },                            
                            oldValue: {
                                type: "number",
                            },
                            newValue: {
                                type: "number",
                            },
                            auditType: {
                                type: "string",
                            },
                            previousFrequency: {
                                type: "string",
                            },
                            newFrequency: {
                                type: "string",
                            },
                            createdUser: {
                                type: "string",
                            },
                            createdDateTime: {
                                type: "string",
                            },
                            remarks: {
                                type: "string",
                            }
                        }
                    },
                }
            });

            let royaltyContractNotesGridColumns: Array<kendo.ui.GridColumn> =
                [
                    {
                        field: "royaltyContractNoteId",
                        title: "RoyaltyContractNote Id",
                        hidden: true,
                        
                    },
                    {
                        field: "royaltyContractId",
                        title: "RoyaltyContract Id",
                        hidden: true
                    },
                    {
                        field: "oldValue",
                        title: "Previous Frequency",
                        hidden: true
                    },
                    {
                        field: "newValue",
                        title: "New Frequency",
                        hidden: true
                    },
                    {
                        field: "auditType",
                        title: "Audit Type",
                    },
                    {
                        field: "previousFrequency",
                        title: "Previous Frequency",
                    },
                    {
                        field: "newFrequency",
                        title: "New Frequency",
                    },
                    {
                        field: "createdUser",
                        title: "Changed By",
                    },
                    {
                        field: "createdDateTime",
                        title: "Changed Datetime",
                    },
                    {
                        field: "remarks",
                        title: "Reason for Change",
                    },
                    {
                        field: "effectiveDate",
                        title: "Effective Date",
                    },
                    
                ];

            this.royaltyContractNotesGridOptions = {
                autoBind: false,
                dataSource: royaltyContractNotesGridDataSource,
                columns: royaltyContractNotesGridColumns,
                resizable:true,
                sortable: true,
                scrollable: true,
                filterable: false,
                pageable: {
                    numeric: true,
                    pageSize: 25,
                    pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                    input: true,
                },
            };
        }
    }

    ErrorMessage(show: boolean, message: string) {
        this.showErrorMessage = show;
        this.errorMessage = message;
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("cmRoyaltiesFeeSettings", {
                bindings: {
                    contractId: "<",
                    readOnly: "<",
                    onLoaded: "&?"
                },
                templateUrl: '/Templates/ContractManagement/cmRoyalties/FeeSettings.html',
                controller: cmRoyaltiesFeeSettingsComponentController
            });

    }
}