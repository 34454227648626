import { CmSectionsComponentController } from "Directives/RPM/ContractManagement/cmSections";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RmsStateService } from "Services/State/RmsState";
import { fddRoyaltiesComponentController } from "Directives/RPM/FddManagement/fddRoyalties";
import { fddGeneralComponentController } from "Directives/RPM/FddManagement/fddGeneral";
import { fddSelectorComponentController } from "Directives/RPM/FddManagement/fddSelector";
import { fddRoyaltyMinimums } from "Directives/RPM/FddManagement";


export class fddSectionsComponentController implements ng.IController {

    fddId: number;
    conceptId: number;
    franchisorId: number;
    readOnly: boolean;
    isDynamicFees: boolean;
    readonly sectionNames = [ "general", "royalties", "minimums" ];
    tab: string;

    generalController: fddGeneralComponentController;
    royaltiesController: fddRoyaltiesComponentController;
    minimumsController: fddRoyaltyMinimums;

    royaltyClicked: number;

    showMessageInfoModal: boolean;
    infoModalMessage: string;
    infoModalTitle: string;
    
    static $inject = [
        '$window',
        '$location',
        'identityManager',
        'rmsState'
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private identityManager: IdentityManager,
        private rmsState: RmsStateService
    )
    {

    }

    $onInit()
    {
        this.showMessageInfoModal = false;
        this.infoModalMessage = "";
        this.infoModalTitle = "";

        this.royaltyClicked = 0;
        this.fddId = parseInt(this.$location.search()[nameof(this.fddId)]);
        if (!this.fddId) {
            this.$window.location.href = '/FddManagement'
        }
        
        this.identityManager.GetLoggedInUserInfo().then(userResponse => {
            this.readOnly = !this.identityManager.UserHasRole(userResponse.data, ["FddManagementAdmin", "Admin"]);
        });
        
        this.tab = this.GetSection();
        this.SetSection(this.tab);
        
        this.rmsState.royaltyDisclosureDocumentStateHandler.Load(this.fddId, this.isDynamicFees)
            .then((document) =>
            {
                this.conceptId = document.ConceptId;
                this.franchisorId = document.FranchisorId;
            });

        this.$window.addEventListener("beforeunload", (event) =>
        {
            let unsavedChanges = (
                this.generalController.HasUnsavedChanges() ||
                this.royaltiesController.HasUnsavedChanges() ||
                this.minimumsController.HasChanges()
            );
            if (unsavedChanges) {
                event.preventDefault();
                let message = "You have unsaved changes. Are you sure you want to leave?";
                (event || window.event).returnValue = message;
                return message;
            }
        })
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledFdd').val() === 'True';
    }

    $postLink()
    {

    }

    GetSection()
    {
        let section = this.$location.search()[nameof<fddSectionsComponentController>(o => o.tab)];
        let isValid = this.IsValidSection(section);
        let result = isValid ? section : this.sectionNames[0]
        if (!isValid) {
            this.SetSection(result);
        }
        return result;
    }

    SetSection(sectionName: string)
    {
        if (!this.IsValidSection(sectionName)) {
            throw "Invalid Section to Set: " + sectionName;
        }

        this.royaltyClicked = this.royaltyClicked + 1;

        this.tab = sectionName;
        this.$location.search(nameof<CmSectionsComponentController>(o => o.tab), sectionName);
    }

    IsValidSection(sectionName: string): boolean
    {
        return this.sectionNames.some(n => n === sectionName);
    }

    GoToNewContractSelection()
    {
        this.$window.location.href = `/FddManagement/#?${nameof<fddSelectorComponentController>(o => o.conceptId)}=${this.conceptId}&${nameof<fddSelectorComponentController>(o => o.franchisorId)}=${this.franchisorId}`;
    }

    validateionPopupMessage(message: string, title: string, show: boolean) {
        this.infoModalMessage = message;
        this.infoModalTitle = title;
        this.showMessageInfoModal = show;
    }

    async SaveAll() {
        await this.generalController.Save();
        await this.royaltiesController.Save();
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("fddSections", {
            bindings: {

            },
            templateUrl: '/Templates/FddManagement/fddSections.html',
            controller: fddSectionsComponentController
        });
    }
}
    
