import { KendoUtil } from "Utility/KendoUtil";

import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import * as _ from "underscore"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { fddRoyaltiesFeeScalesComponentController } from "./fddRoyaltiesFeeScales";
import { RoyaltyDisclosureDocumentRatePlanItemViewModel } from "Interfaces/FranForce/Rpm/FDD/RoyaltyDisclosureRatePlanItemView";
import { RoyaltyDisclosureDocumentRatePlanItemDetailModel } from "Interfaces/FranForce/Rpm/FDD/RoyaltyDisclosureRatePlanItemModel";



export class fddRoyaltiesRatePlanItemsDynamicFeesComponentController implements ng.IController {

    isLoading: boolean;
    readOnly: boolean;
    readOnlySG: boolean;
    franchisorId:number;
    disclosureDocumentRatePlanId: number;
    isMethodologySpecial: boolean;
    rollInRateGrids: { [uid: string]: kendo.ui.Grid };
    royaltyRatePlanItemGrid: kendo.ui.Grid;
    serviceCategoryGrid: kendo.ui.Grid;
    royaltyRatePlanItemGridOptions: kendo.ui.GridOptions;
    feeScalesController: fddRoyaltiesFeeScalesComponentController;
    selectedRatePlanItem: RoyaltyDisclosureDocumentRatePlanItemViewModel;
    royaltyRatePlanItemTypes: RpmEntities.RoyaltyRatePlanItemTypeViewModel[];
    formController: angular.IFormController;
    royaltyServiceCategoryTypes: RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result[];
    royaltyServiceCategories: RpmEntities.RoyaltyServiceCategoryViewModel[];
    localStorage: ILocalStorage;
    hideServiceCategory: Boolean;
    onSelectedRatePlanItem: (params: { ratePlanItem: RoyaltyDisclosureDocumentRatePlanItemViewModel, isRemoved: boolean }) => void;
    onLoaded: (params: { self: fddRoyaltiesRatePlanItemsDynamicFeesComponentController }) => void;
    
    static $inject = [
        'rpmUiApiResources',
        'royaltyApiResources',
        'rpmHelper',
        'identityManager',
        '$q',
        '$timeout'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private royaltyApiResources: RoyaltyApiResources,
        private rpmHelper: RpmHelperService,
        private identityManager: IdentityManager,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService)
    {}

    $onInit()
    {
        this.localStorage = <ILocalStorage>localStorage;
        this.isLoading = true;
        this.rollInRateGrids = {};
        this.InitRoyaltyRatePlanItemGrid();

        if (this.franchisorId)
        {
            this.LoadServiceCategories();
        }
        this.LoadServiceCategoryTypes();

        this.rpmUiApiResources.GetAllRoyaltyRatePlanItemTypes().then(typesResponse => {
            this.royaltyRatePlanItemTypes = typesResponse.data;
        })
            .then(() => {
                return this.CancelChanges();
            })
            .finally(() => {
                this.isLoading = false;
                this.onLoaded({ self: this });
            })
    }
    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes.disclosureDocumentRatePlanId)
        {
            //this.InitRoyaltyRatePlanItemGrid();
            this.$timeout(() => { this.ResetChanges(); }, 0);
        }
        if (changes.franchisorId && changes.franchisorId.currentValue)
        {
            this.LoadServiceCategories();
        }

        if (changes.disclosureDocumentRatePlanItemId)
        {
            this.$timeout(() => { this.ResetChanges(); }, 0);
        }
    }
    LoadServiceCategories()
    {
        this.isLoading = true;

        return this.rpmUiApiResources.GetRoyaltyServiceCategoriesByFranchisor(this.franchisorId)
            .then((serviceCategoriesResponse) => {
                this.royaltyServiceCategories = serviceCategoriesResponse.data
            })
            .finally(() => {
                this.isLoading = false;
                this.onLoaded({ self: this });
            });
    }
    LoadServiceCategoryTypes()
    {
        return this.rpmUiApiResources.GetAllRoyaltyServiceCategoryTypes()
            .then((typesResponse) =>
            {
                this.royaltyServiceCategoryTypes = typesResponse.data;
            });
    }
    GetRoyaltyServiceCategoryTypeById(serviceCategoryTypeId: number)
    {
        return _.find(this.royaltyServiceCategoryTypes, (sc) => { return sc.RoyaltyServiceCategoryTypeId === serviceCategoryTypeId; });
    }
    GetRoyaltyServiceCategoryById(serviceCategoryId: number)
    {
        return _.find(this.royaltyServiceCategories, (sc) => { return sc.RoyaltyServiceCategoryId === serviceCategoryId; });
    }
    InitRoyaltyRatePlanItemGrid()
    {
        let rpitDataSource = new kendo.data.DataSource({
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {
                    if (!this.disclosureDocumentRatePlanId)
                    {
                        options.success([]);
                        return;
                    }
                    this.royaltyApiResources.GetRoyaltyDisclosureDocumentRatePlanItems(this.disclosureDocumentRatePlanId)
                        .then(
                            (ratePlansResponse) => {
                                options.success(ratePlansResponse.data);
                            },
                            (err) => { options.error(err); }
                        )
                },
                create: (options: kendo.data.DataSourceTransportOptions) => {
                    let createModel: RoyaltyDisclosureDocumentRatePlanItemViewModel = options.data;
                    this.royaltyApiResources.UpdateRoyaltyDisclosureDocumentRatePlanItem(createModel)
                    .then((idResponse) => {
                        options.success();
                    }, (err) => { options.error(err) });
                },
                update:  (options: kendo.data.DataSourceTransportOptions)  => {
                    let updateModel: RoyaltyDisclosureDocumentRatePlanItemViewModel = options.data;
                    this.royaltyApiResources.UpdateRoyaltyDisclosureDocumentRatePlanItem(updateModel)
                        .then((idResponse) => {
                            options.success();
                        }, (err) => { options.error(err) });
                },
                destroy: (options: kendo.data.DataSourceTransportOptions) => {
                    let deleteModel: RoyaltyDisclosureDocumentRatePlanItemViewModel = options.data;
                    deleteModel.deletedDateTime = new Date().toISOString();
                    this.royaltyApiResources.UpdateRoyaltyDisclosureDocumentRatePlanItem(deleteModel)
                        .then((idResponse) => {
                            options.success();
                        }, (err) => { options.error(err) });
                    
                },
            },
            pageSize: 10,
            schema: {
                model: {
                    id: "disclosureDocumentRatePlanItemId",
                    fields: {
                        disclosureDocumentRatePlanItemId: {
                            type: "number",
                            validation: { required: true },
                            editable: false,
                            defaultValue: 0
                        },
                        royaltyRatePlanItemType: {
                            type: "object",
                            validation: { required: true },
                            defaultValue: { Description: "Annual Scale", royaltyRatePlanItemTypeId: 1 }
                        },
                        royaltyRatePlanItemTypeId: {
                            type: "number",
                            validation: { required: true },
                            defaultValue: 1
                        },
                        weeksInBusinessStart: {
                            type: "number",
                            validation: { required: true }
                        },
                        weeksInBusinessEnd: {
                            type: "number",
                            validation: { required: true }
                        },
                        grossSalesRangeLow: { type: "number", validation: { required: true } },
                        grossSalesRangeHigh: { type: "number", validation: { required: true } },
                        deletedDateTime: {
                            type: "string",
                            defaultValue: null
                        },
                        hasRollInRateChanges: {
                            type: "boolean",
                            defaultValue: false
                        }
                    }
                }
            },
            filter: { field: "DeletedDateTime", operator: "isnull" },
            sort: [
                { field: nameof<RoyaltyDisclosureDocumentRatePlanItemViewModel>(d => d.weeksInBusinessStart), dir: "asc" },
                { field: nameof<RoyaltyDisclosureDocumentRatePlanItemViewModel>(d => d.weeksInBusinessEnd), dir: "asc" },
                { field: nameof<RoyaltyDisclosureDocumentRatePlanItemViewModel>(d => d.grossSalesRangeLow), dir: "asc" },
                { field: nameof<RoyaltyDisclosureDocumentRatePlanItemViewModel>(d => d.grossSalesRangeHigh), dir: "asc" },
            ]
        });

        let rpitColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.disclosureDocumentRatePlanItemId),
                title: "ID",
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.royaltyRatePlanItemTypeId),
                title: "Rate Plan Item Type",
                editor: (container, options) => {
                    let input = "<input required name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: "Description",
                        dataValueField: "RoyaltyRatePlanItemTypeId",
                        dataSource: {
                            data: this.royaltyRatePlanItemTypes
                        },
                        change: (e) => {
                            var dataItem = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.royaltyRatePlanItemTypes[0];
                            }
                            options.model.set("royaltyRatePlanItemTypeId", dataItem.RoyaltyRatePlanItemTypeId);
                        }
                    });
                },
                template: `{{$ctrl.GetRoyaltyPlanItemTypeById(#=${nameof<RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.royaltyRatePlanItemTypeId)}#).Description}}`,
                width: "150px"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.weeksInBusinessStart),
                title: "WIB Start"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.weeksInBusinessEnd),
                title: "WIB End"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.perCapitaLow),
                title: "Per Capita",
                format: "{0:n4}",
                editor: this.rpmHelper.DecimalEditor
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.grossSalesRangeLow),
                title: "Sales Scale Begins",
                format: "{0:c}",
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.grossSalesRangeHigh),
                title: "Sales Scale Ends",
                format: "{0:c}"
            }, {
                field: "HasRollInRateChanges",
                title: "Has Roll In Rate Changes",
                hidden: true
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }];

        for (let col of rpitColumns)
        {
            col.headerAttributes = {
                style: "white-space: normal; border-left: #AAA 1px solid;"
            };
        }
        rpitColumns[0].headerAttributes = null;

        this.royaltyRatePlanItemGridOptions = {
            autoBind: false,
            selectable: "row",
            dataSource: rpitDataSource,
            columns: rpitColumns,
            editable: !this.readOnly ? { confirmation: false } : false,
            navigatable: true,
            scrollable: false, 
            pageable: KendoUtil.GetDefaultKendoGridPageable(10),
            remove: (e) =>
            {
                if (this.selectedRatePlanItem && e.model.id === this.selectedRatePlanItem.disclosureDocumentRatePlanItemId)
                {
                    this.$timeout(() => {
                        this.SelectRatePlanItem(null, true);
                    }, 0);
                }
            }
        };
    }  
   
    GetRollInRateGridOptions(ratePlanItem: RoyaltyDisclosureDocumentRatePlanItemViewModel): kendo.ui.GridOptions
    {
        let rirDataSource = new kendo.data.DataSource({
            batch: true,
            schema: {
                 model: {
                    id: "typeFee",
                    fields: {
                        ParentId: {
                            type: "string",
                            validation: { required: true },
                            editable: false,
                            defaultValue: 0
                        },
                        typeFee: {
                            type: "string",
                            validation: { required: true },
                            editable: false,
                             
                        },
                        rollInSalesBegin: { type: "number", validation: { required: true } },
                        rollInSalesEnd: { type: "number", validation: { required: true } },
                        royaltyFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        royaltyFeeFixedRateAmount: { type: "number", validation: { required: true } },
                        adFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        adFeeFixedRateAmount: { type: "number", validation: { required: true } }
                    }
                 }
            },
             transport: {
                 read: (e: kendo.data.DataSourceTransportReadOptions) => {
                    e.success(this.rpmHelper.ConvertToRollInRates_DF(ratePlanItem, ratePlanItem.disclosureDocumentRatePlanItemId));
                 },
                 update: (e: kendo.data.DataSourceTransportOptions) => {
                     for (let rollInRate of <IRollInRateItem[]>e.data.models)
                     {
                        this.rpmHelper.MergeRollInRateItem(ratePlanItem, rollInRate)
                     }

                     if (ratePlanItem.disclosureDocumentRatePlanId)
                     {
                         //If it's a new item, this flag doesn't need to be set as it will already be gaurenteed to be created.
                        this.royaltyRatePlanItemGrid.dataSource
                             .get(ratePlanItem.disclosureDocumentRatePlanItemId)
                             .set("HasRollInRateChanges", true);
                     }
                     
                     e.success(e.data.models);
                 },
                 destroy: (e: kendo.data.DataSourceTransportOptions) => {
                     e.error('Destory is not defined for roll in rates, as it should not be possible.')
                 },
                 create: (e: kendo.data.DataSourceTransportOptions) => {
                     e.error('Create is not defined for roll in rates, as it should not be possible.')
                 },
             }
        });
        let rirColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "ParentId",
                title: "disclosureDocumentRatePlanItemId",
                hidden: true
            }, {
                field: "typeFee",
                title: "Type Fee",
                width: "94px"
            }, {
                field: "rollInSalesBegin",
                title: "Roll-in Sales Begin",
                format: "{0:c}",
                width: "94px"
            }, {
                field: "rollInSalesEnd",
                title: "Roll-in Sales End",
                format: "{0:c}",
                width: "98px"
            }, {
                field: "royaltyFeeRate",
                title: "Royalty Fee Rate",

                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: "royaltyFeeFixedRateAmount",
                title: "Royalty Fee Fixed Rate Amount",
                format: "{0:c}"
            }, {
                field: "adFeeRate",
                title: "Ad Fee Rate",
                width: "80px",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: "adFeeFixedRateAmount",
                width: "84px",
                title: "Ad Fee Fixed Rate Amount",
                format: "{0:c}"
            }];

        return {
            columns: rirColumns,
            dataSource: rirDataSource,
            editable: !this.readOnly,
            navigatable: true,
            scrollable: false, 
            pageable: false
        };
    }
    GetServiceCategoryRollInRateGridOptions(itemDetail: RoyaltyDisclosureDocumentRatePlanItemDetailModel): kendo.ui.GridOptions
    {
        let rirDataSource = new kendo.data.DataSource({
            batch: true,
            schema: {
                 model: {
                    id: "typeFee",
                    fields: {
                        ParentId: {
                            type: "string",
                            validation: { required: true },
                            editable: false,
                            defaultValue: 0
                        },
                        typeFee: {
                            type: "string",
                            validation: { required: true },
                            editable: false,
                             
                        },
                        rollInSalesBegin: { type: "number", validation: { required: true } },
                        rollInSalesEnd: { type: "number", validation: { required: true } },
                        royaltyFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        royaltyFeeFixedRateAmount: { type: "number", validation: { required: true } },
                        adFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        adFeeFixedRateAmount: { type: "number", validation: { required: true } }
                    }
                 }
            },
             transport: {
                 read: (e: kendo.data.DataSourceTransportReadOptions) => {
                    e.success(this.rpmHelper.ConvertToRollInRates_DF(itemDetail, itemDetail.disclosureDocumentRatePlanItemDetailId));
                 },
                 update: (e: kendo.data.DataSourceTransportOptions) => {
                     for (let rollInRate of <IRollInRateItem[]>e.data.models)
                     {
                        this.rpmHelper.MergeRollInRateItem(itemDetail, rollInRate)
                     }

                     this.serviceCategoryGrid.dataSource
                         .get(itemDetail.disclosureDocumentRatePlanItemDetailId)
                         .set("HasRollInRateChanges", true);

                     e.success(e.data.models);
                 },
                 destroy: (e) => { },
                 create: (e) => { }
             }
        });
        let rirColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "ParentId",
                title: "DisclosureDocumentRatePlanItemId",
                hidden: true
            },
            {
                field: "typeFee",
                title: "Type Fee"
            }, {
                field: "rollInSalesBegin",
                title: "Roll-in Sales Begin"
            }, {
                field: "rollInSalesEnd",
                title: "Roll-in Sales End"
            }, {
                field: "royaltyFeeRate",
                title: "Royalty Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: "royaltyFeeFixedRateAmount",
                title: "Royalty Fee Fixed Rate Amount"
            }, {
                field: "adFeeRate",
                title: "Ad Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: "adFeeFixedRateAmount",
                title: "Ad Fee Fixed Rate Amount"
            }];

        return {
            columns: rirColumns,
            dataSource: rirDataSource,
            editable: !this.readOnlySG,
            navigatable: true,
            scrollable: false,
            pageable: false
        };
    }
    GetServiceCategroyGridOptions(ratePlanItem: RoyaltyDisclosureDocumentRatePlanItemViewModel): kendo.ui.GridOptions
    {
        let scDataSource = new kendo.data.DataSource({
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {
                    if (!ratePlanItem.disclosureDocumentRatePlanItemId)
                    {
                        options.success([]);
                        return;
                    }
                    this.royaltyApiResources.GetRoyaltyDisclosureDocumentRatePlanItemDetail(ratePlanItem.disclosureDocumentRatePlanItemId)
                        .then((itemDetailsResponse) => {
                           options.success(itemDetailsResponse.data);
                        }, (err) => {
                            if(err.status === 500){
                                options.success([]);
                                return; 
                            }
                         })
                        .catch((err)=>{
                           options.error(err)
                        });
                },
                create: (options: kendo.data.DataSourceTransportOptions) => {
                    let createModel: RoyaltyDisclosureDocumentRatePlanItemDetailModel = options.data;
                    createModel.disclosureDocumentRatePlanItemId =  ratePlanItem.disclosureDocumentRatePlanItemId
                    this.royaltyApiResources.UpdateRoyaltyDisclosureDocumentRatePlanItemDetails(createModel)
                    .then((idResponse) => {
                        options.success();
                    }, (err) => {options.error(err) })
                    .catch((err)=>{
                       options.error(err);
                    })
                },
                update: (options: kendo.data.DataSourceTransportOptions) => {
                    let updateModel: RoyaltyDisclosureDocumentRatePlanItemDetailModel = options.data;
                    this.royaltyApiResources.UpdateRoyaltyDisclosureDocumentRatePlanItemDetails(updateModel)
                        .then((idResponse) => {
                            options.success();
                        }, (err) => { options.error(err); });
                },
                destroy: (options: kendo.data.DataSourceTransportOptions) => {
                    let deleteModel: RoyaltyDisclosureDocumentRatePlanItemDetailModel = options.data;
                    deleteModel.deletedDateTime = new Date().toISOString();
                    this.royaltyApiResources.UpdateRoyaltyDisclosureDocumentRatePlanItemDetails(deleteModel)
                    .then((idResponse) => {
                        options.success();
                    }, (err) => { options.error(err); });
                },
            },
            batch: false,
            pageSize: 20,
            schema: {
                model: {
                    id: nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.disclosureDocumentRatePlanItemDetailId),
                    fields: {
                        [nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.disclosureDocumentRatePlanItemDetailId)]: {
                            type: "number",
                            validation: { required: true },
                            editable: false,
                            defaultValue: 0
                        },
                        [nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryId)]: {
                            type: "number",
                            validation: { required: true }
                        },
                        [nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryTypeId)]: {
                            type: "number",
                            validation: { required: true }
                        },
                        [nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyFeeRateResidential)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyFeeRateCommercial)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.adFeeRateResidential)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.adFeeRateCommercial)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.tafsFeeRate)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.technologyFeeRate)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.deletedDateTime)]: {
                            type: "string",
                            defaultValue: null
                        }
                    }
                }
            },
            filter: {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.deletedDateTime),
                operator: "isnull"
            }
        });
        let scColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.disclosureDocumentRatePlanItemDetailId),
                title: "ID",
                hidden: true
            }, 
            {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryId),
                title: "Service Category",
                editor: (container, options) => {
                    let input = "<input required name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description),
                        dataValueField: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.RoyaltyServiceCategoryId),
                        template: `<span class="#: 'deleted-' + (${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.DeletedDateTime)} ? 'true icon-error ' : 'false') #">#: ${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description)} # </span>`,
                        valueTemplate: `<span class="#: 'deleted-' + (${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.DeletedDateTime)} ? 'true icon-error ' : 'false') #">#: ${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description)} # </span>`,
                        dataSource: new kendo.data.DataSource({
                            data: this.royaltyServiceCategories,
                            sort: [
                                {
                                    field: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.DeletedDateTime),
                                    dir: "desc",
                                    compare: (a: RpmEntities.RoyaltyServiceCategoryViewModel, b: RpmEntities.RoyaltyServiceCategoryViewModel) =>
                                    {
                                        return (a.DeletedDateTime ? 0 : 1) - (b.DeletedDateTime ? 0 : 1) 
                                    }
                                },
                                {
                                    field: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description),
                                    dir: "asc"
                                }
                            ]
                        }),
                        change: (e) => {
                            var dataItem = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.royaltyServiceCategories[0];
                            }

                            options.model.set(
                                nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryId),
                                dataItem[nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.RoyaltyServiceCategoryId)]
                            );
                        }
                    });
                },
                template: `{{$ctrl.${nameof(this.GetRoyaltyServiceCategoryById)}(#=${nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryId)}#).${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description)}}}`
            }, 
            {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryTypeId),
                title: "Service Category Type",
                editor: (container, options) =>
                {
                    let input = "<input required name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>( o => o.Description),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.RoyaltyServiceCategoryTypeId),
                        dataSource: {
                            data: this.royaltyServiceCategoryTypes
                        },
                        change: (e) =>
                        {
                            var dataItem = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.royaltyServiceCategoryTypes[0];
                            }

                            options.model.set(
                                nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryTypeId),
                                dataItem[nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.RoyaltyServiceCategoryTypeId)]
                            );
                        }
                    });
                },
                template: `{{$ctrl.${nameof(this.GetRoyaltyServiceCategoryTypeById)}(#=${nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryTypeId)}#).Description}}`
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyFeeRateResidential),
                title: "Res Royalty Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyFeeRateCommercial),
                title: "Com Royalty Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.adFeeRateResidential),
                title: "Res Ad Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.adFeeRateCommercial),
                title: "Com Ad Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.tafsFeeRate),
                title: "TAFS Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.technologyFeeRate),
                title: "Tech Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: "HasRollInRateChanges",
                title: "Has Roll In Rate Changes",
                hidden: true
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }];

        return {
            columns: scColumns,
            dataSource: scDataSource,
            scrollable: false,
            navigatable: true,
            toolbar: ["create","cancel"],
            editable: !this.readOnlySG ? { confirmation: false } : false,
            pageable: KendoUtil.GetDefaultKendoGridPageable(20),
            edit: (e) => {
                var input = e.container.find(".k-input");
                var value = input.val();

                input.blur((j) => {
                    let columnName = j?.target?.dataset?.bind?.split(":")[1];
                    switch (columnName) {
                        case nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyFeeRateResidential):
                        case nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyFeeRateCommercial):
                        case nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.adFeeRateResidential):
                        case nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.adFeeRateCommercial):
                        case nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.tafsFeeRate):
                        case nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.technologyFeeRate):
                            let newValue = input.val();
                            if (value != newValue && newValue != null && (newValue > 0.1)) {
                                alert("Warning! " + columnName + " value above 10%");
                            }
                            break;
                    }
                });
            },
        };
    }
    SelectRatePlanItem(ratePlanItem: RoyaltyDisclosureDocumentRatePlanItemViewModel, isRemoved: boolean = false)
    {
        if (this.selectedRatePlanItem === ratePlanItem)
            ratePlanItem = null;

        this.selectedRatePlanItem = ratePlanItem;
        if (this.onSelectedRatePlanItem)
        {
            this.onSelectedRatePlanItem({ ratePlanItem: this.selectedRatePlanItem, isRemoved: isRemoved });
        }
    }    
    SelectRowById(id: number)
    {
        let dataItem = this.royaltyRatePlanItemGrid.dataSource.get(id);
        if (dataItem)
            this.SelectRatePlanItem(<any>dataItem);
    }    
    GetRoyaltyPlanItemTypeById(planItemTypeId: number)
    {   
        return _.find(this.royaltyRatePlanItemTypes, (rpit) => { return rpit.RoyaltyRatePlanItemTypeId === planItemTypeId; });
    }
    AddNewRecord()
    {
        if (!this.readOnly)
        {
            let defaultRatePlanItem: RoyaltyDisclosureDocumentRatePlanItemViewModel = {
                largeRollInAdFeeFixedRateAmount: 0,
                grossSalesRangeLow: 0,
                grossSalesRangeHigh: 0,
                royaltyFeeRateResidential: 0,
                royaltyFeeFixedRateAmountResidential: 0,
                royaltyFeeRateCommercial: 0,
                royaltyFeeFixedRateAmountCommercial: 0,
                adFeeRateResidential: 0,
                adFeeFixedRateAmountResidential: 0,
                adFeeRateCommercial: 0,
                adFeeFixedRateAmountCommercial: 0,
                technologyFeeRate: 0,
                technologyFeeFixedRateAmount: 0,
                tafsFeeRate: 0,
                tafsFeeFixedRateAmount: 0,
                weeksInBusinessEnd: 0,
                perCapitaLow: 0,
                createdUser:  this.localStorage.AccountEmail,
                updatedDateTime: null,
                largeRollInAdFeeRate: 0,
                updatedUser: null,
                largeRollInRoyaltyFeeFixedRateAmount: 0,
                largeRollInHighRangeAmount: 0,
                disclosureDocumentRatePlanItemId: 0,
                disclosureDocumentRatePlanId: this.disclosureDocumentRatePlanId,
                weeksInBusinessStart: 0,
                royaltyRatePlanItemTypeId: 2,
                createdDateTime: new Date(),
                deletedDateTime: null,
                smallRollInLowRangeAmount: 0,
                smallRollInHighRangeAmount: 0,
                smallRollInRoyaltyFeeRate: 0,
                smallRollInRoyaltyFeeFixedRateAmount: 0,
                smallRollInAdFeeRate: 0,
                smallRollInAdFeeFixedRateAmount: 0,
                mediumRollInLowRangeAmount: 0,
                mediumRollInHighRangeAmount: 0,
                mediumRollInRoyaltyFeeRate: 0,
                mediumRollInRoyaltyFeeFixedRateAmount: 0,
                mediumRollInAdFeeRate: 0,
                mediumRollInAdFeeFixedRateAmount: 0,
                largeRollInLowRangeAmount: 0,
                largeRollInRoyaltyFeeRate: 0,
            };
            this.royaltyRatePlanItemGrid.dataSource.add(defaultRatePlanItem);
        }
    }
    CancelChanges()
    {
        if (!this.readOnly)
        {
            Object.keys(this.rollInRateGrids).forEach(key => {
                this.rollInRateGrids[key].dataSource.cancelChanges();
            });
            this.royaltyRatePlanItemGrid.dataSource.cancelChanges();
        }
    }
    HasChanges()
    {
        if (!this.royaltyRatePlanItemGrid)
            return false;

        return this.royaltyRatePlanItemGrid.dataSource.hasChanges()|| this.serviceCategoryGrid?.dataSource?.hasChanges() ||
            Object.keys(this.rollInRateGrids)
                .map(key => { return this.rollInRateGrids[key].dataSource.hasChanges(); })
                .some(hasChanges => { return hasChanges; });
                
                
    }
    ResetChanges()
    {    
        this.CancelChanges();
        return this.royaltyRatePlanItemGrid.dataSource.read().then(() => {
            this.royaltyRatePlanItemGrid.refresh();

            this.$timeout(() =>
            {
                this.formController.$setPristine();
            }, 1000);
            
            this.rollInRateGrids = {};
            
        });
        
    }
    SaveChanges(){
        let syncPromises: ng.IPromise<any>[] = [];
        Object.keys(this.rollInRateGrids).forEach(key => {
            syncPromises.push(this.$q.when(this.rollInRateGrids[key].dataSource.sync()));
        });
        return this.$q.all(syncPromises)
            .then(() =>
            {
                return this.$q.when(this.royaltyRatePlanItemGrid.dataSource.sync())
                    .then(() =>
                    {
                        return this.ResetChanges();
                    });
            })
    }
    HasServiceCategoryChanges(){
        if (!this.serviceCategoryGrid)
            return false;
        
        return  Object.keys(this.serviceCategoryGrid)
            .map(key => { return this.serviceCategoryGrid[key].dataSource.hasChanges(); })
            .some(hasChanges => { return hasChanges; })||
                Object.keys(this.rollInRateGrids)
                    .map(key => { return this.rollInRateGrids[key].dataSource.hasChanges(); })
                    .some(hasChanges => { return hasChanges; });
    }

    CancelServiceCatChanges(key)
    {
        if (!this.readOnly)
        {
            Object.keys(this.rollInRateGrids).forEach(keyRG => {
                this.rollInRateGrids[keyRG].dataSource.cancelChanges();
            });
            this.serviceCategoryGrid[key].dataSource.cancelChanges()
        }
    }
    
    ResetServiceCategoryChanges(key){
        this.CancelServiceCatChanges(key)  
        return this.serviceCategoryGrid[key].dataSource.read().then(() => {
            this.serviceCategoryGrid[key].dataSource.refresh();

            this.$timeout(() =>
            {
                this.formController.$setPristine();
            }, 1000);
            
            this.rollInRateGrids = {};
            
        });
    }
    SaveServiceCategoryChanges(){
        let syncPromises: ng.IPromise<any>[] = [];
        Object.keys(this.rollInRateGrids).forEach(key => {
            syncPromises.push(this.$q.when(this.rollInRateGrids[key].dataSource.sync()));
        });
        
        return this.$q.all(syncPromises).then(() => {
            return this.serviceCategoryGrid ? Object.keys(this.serviceCategoryGrid)
            .map(key => { return this.serviceCategoryGrid[key].dataSource.sync().then(
                this.ResetServiceCategoryChanges(key)
            ) }) : false
        })
    }
    static BindComponent(app: ng.IModule)
    {
        app
            .component("fddRoyaltiesRatePlanItemsDynamicFees", {
                bindings: {
                    disclosureDocumentRatePlanId: "<",
                    readOnly: "<",
                    readOnlySG:"<",
                    isMethodologySpecial: "<",
                    onSelectedRatePlanItem: "&?",   
                    onLoaded: "&?",
                 
                 
                    [nameof<fddRoyaltiesRatePlanItemsDynamicFeesComponentController>(o => o.franchisorId)]: "<",
                    [nameof<fddRoyaltiesRatePlanItemsDynamicFeesComponentController>(o => o.readOnly)]: "<",
                    [nameof<fddRoyaltiesRatePlanItemsDynamicFeesComponentController>(o => o.readOnlySG)]: "<",
                    [nameof<fddRoyaltiesRatePlanItemsDynamicFeesComponentController>(o => o.onLoaded)]: "&?"

                },
                templateUrl: '/Templates/FDDManagement/fddRoyalties/RatePlanItems_df.html',
                controller: fddRoyaltiesRatePlanItemsDynamicFeesComponentController
            });

    }
}
