import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";


interface IIndexConceptScope {
    concepts: CoreResources.IConcept[];
}

export class IndexConceptController
{
    static $inject = ['$scope', 'coreApiResources', '$window'];
    
    constructor($scope: IIndexConceptScope, coreApiResources: CoreApiResources, $window: ng.IWindowService) 
    {
        $scope.concepts = coreApiResources.ConceptApi.query({});
    }
}