import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { FranchisorSelectorComponentControllerState, FranchisorSelectorOnSelectParameters, FranchisorSelectorOnLoadParameters } from "Directives/Common/FranchisorSelector";
import { ConceptSelectorComponentControllerState, ConceptSelectorOnSelectParameters, ConceptSelectorOnLoadParameters } from "Directives/Common/ConceptSelector";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class ConceptFranchisorSelectorComponentController implements ng.IController
{
    //---- Bindings ----
    disable: boolean;
    state: boolean |
        FranchisorSelectorComponentControllerState &
        ConceptSelectorComponentControllerState;

    conceptId: number;
    conceptDisable: boolean;
    conceptSelect: string[];
    conceptFilters: kendo.data.DataSourceFilter;
    conceptSort: (a: Partial<CoreResources.IConcept>, b: Partial<CoreResources.IConcept>) => number;
    conceptDisplayTextTemplate: string;
    conceptExtraOptions: kendo.ui.DropDownListOptions;
    conceptOnSelect: (params: ConceptSelectorOnSelectParameters) => void;
    conceptOnLoad: (params: ConceptSelectorOnLoadParameters) => void; 

    franchisorId: number;
    franchisorDisable: boolean;
    franchisorSelect: string[];
    franchisorFilters: kendo.data.DataSourceFilter;
    franchisorSort: (a: Partial<CoreResources.IFranchisor>, b: Partial<CoreResources.IFranchisor>) => number;
    franchisorDisplayTextTemplate: string;
    franchisorExtraOptions: kendo.ui.DropDownListOptions;
    franchisorOnSelect: (params: FranchisorSelectorOnSelectParameters) => void;
    franchisorOnLoad: (params: FranchisorSelectorOnLoadParameters) => void; 
    //------------------

    isLoading: boolean;
    combinedFranchisorFilters: kendo.data.DataSourceFilters;

    static $inject = [
        "coreApiResources",
    ];

    constructor(
        private coreApiResources: CoreApiResources,
    )
    {

    }

    $onInit()
    {
        this.isLoading = false;

        if (!this.conceptFilters) {
            this.conceptFilters = [];
        }

        if (this.franchisorId)
        {
            this.LoadFromFranchisor();
        }

        this.CreateCombinedFranchisorFilters();
    }

    $postLink()
    {

    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
        if (onChanges[nameof(this.conceptId)]) {
            this.CreateCombinedFranchisorFilters()
        }

        if (onChanges[nameof(this.franchisorId)]) {
            if (this.franchisorId) {
                this.LoadFromFranchisor();
            }
        }

        if (onChanges[nameof(this.disable)])
        {
            if (typeof this.disable === "boolean") {
                this.franchisorDisable = this.disable;
                this.conceptDisable = this.disable;
            }
        }
    }

    OnSelectConcept(concept: kendo.data.ObservableObject & Partial<CoreResources.IConcept>)
    {

        if (!concept || !concept.ConceptId) {
            this.conceptId = null;
            this.franchisorFilters = null;
            this.CreateCombinedFranchisorFilters();
            this.conceptOnSelect({ concept: concept });
            return;
        }

        if (this.conceptId == concept.ConceptId) {
            this.conceptOnSelect({ concept: concept });
            return;
        }

        this.conceptId = concept.ConceptId;
        this.CreateCombinedFranchisorFilters();
        this.conceptOnSelect({ concept: concept });
    }

    OnSelectFranchisor(franchisor: kendo.data.ObservableObject & Partial<CoreResources.IFranchisor>)
    {

        if (!franchisor || !franchisor.FranchisorId) {
            this.franchisorOnSelect({ franchisor: franchisor });
            return;
        }

        this.franchisorId = franchisor.FranchisorId;
        this.conceptId = franchisor.ConceptId;
        this.franchisorOnSelect({ franchisor: franchisor });
    }

    CreateCombinedFranchisorFilters()
    {
        if (!this.conceptId) {
            this.combinedFranchisorFilters = null;
            return;
        }

        this.combinedFranchisorFilters = {
            logic: "and",
            filters: [
                {
                    field: nameof<CoreResources.IFranchisor>(o => o.ConceptId),
                    operator: 'eq',
                    value: this.conceptId
                },
                this.franchisorFilters
            ]
        }
    }

    LoadFromFranchisor()
    {
        this.isLoading = true;
        this.coreApiResources.FranchisorApi.get({ id: this.franchisorId, $select: nameof<CoreResources.IFranchisor>(o => o.ConceptId) }).$promise
            .then((franchisor) =>
            {
                this.conceptId = franchisor.ConceptId;
                this.CreateCombinedFranchisorFilters();
            })
            .finally(() =>
            {
                this.isLoading = false;
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("conceptFranchisorSelector", {
            bindings: {
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.disable)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.state)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.conceptId)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.conceptDisable)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.conceptSelect)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.conceptFilters)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.conceptSort)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.conceptDisplayTextTemplate)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.conceptExtraOptions)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.conceptOnSelect)]: "&",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.conceptOnLoad)]: "&",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.franchisorId)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.franchisorDisable)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.franchisorSelect)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.franchisorFilters)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.franchisorSort)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.franchisorDisplayTextTemplate)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.franchisorExtraOptions)]: "<",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.franchisorOnSelect)]: "&",
                [nameof<ConceptFranchisorSelectorComponentController>(o => o.franchisorOnLoad)]: "&",
            },
            controller: ConceptFranchisorSelectorComponentController,
            templateUrl: `/Templates/Common/ConceptFranchisorSelector.html`,
        });
    }
}

