import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import * as _ from "underscore"
import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import
{
    PhotoGalleryCategoryConceptXrefClient, PhotoGalleryCategoryConceptXrefWithCategoryDescDto,
    PhotoGalleryCategoriesClient, PhotoGalleryCategoryDto, CreatePhotoGalleryImageInfoDto
} from '@nbly/service-orchestrations-clients';
import { FranchiseWebLocationDto } from '@nbly/franchise-group-api-clients';

export class PhotoEditorMetaInfoComponent implements ng.IController
{
    franchiseWebLocationDto: FranchiseWebLocationDto[];
    franchiseId: number;
    isLoading: boolean;
    conceptCode: string;
    imageFile: any;

    description: string;
    bioImageUrl: string;
    saveBioImageUrl: string;
    imageUrl: any;
    shortCaptionMaxLength: number = 50;
    longCaptionMaxLength: number = 250;

    //Dummy Models
    DBAName: string;
    shortCaption: string;
    longCaption: string;
    seletedCategory: any;

    photoGalleryInfoDto: CreatePhotoGalleryImageInfoDto;

    selectedFranchiesIds: number[];
    useForNationalWebsite: boolean;
    legalDisclaimer: boolean;

    categoriesOptions: kendo.ui.DropDownListOptions;
    photoGalleryCategoryConcepts: PhotoGalleryCategoryConceptXrefWithCategoryDescDto[];

    //Clients
    PhotoGalleryCategoryDto: PhotoGalleryCategoryDto[];
    PhotoGalleryCategoryConceptXrefDto: PhotoGalleryCategoryConceptXrefWithCategoryDescDto[];
    serviceOrchestrationsClient: AxiosInstance;
    photoGalleryCategoryConceptXrefClient: PhotoGalleryCategoryConceptXrefClient;
    photoGalleryCategoriesClient: PhotoGalleryCategoriesClient;
    categoryOptions: kendo.ui.DropDownListOptions;

    weblocationMultiSelect: kendo.ui.MultiSelect;
    weblocationMultiSelectOptions: kendo.ui.MultiSelectOptions;

    termsWindow: kendo.ui.Window;
    termsWindowOptions: kendo.ui.WindowOptions;
    showNationalWebsite:boolean=false;
    static $inject = [
        'identityManager',
        'apiConfig',
        "$scope",
    ];


    constructor(
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $scope: ng.IScope
    )
    {
        this.serviceOrchestrationsClient = FranForceAxiosClientBuilder.BuildServiceOrchestrationsBaseClient();
        this.photoGalleryCategoriesClient = new PhotoGalleryCategoriesClient("", this.serviceOrchestrationsClient);
        this.photoGalleryCategoryConceptXrefClient = new PhotoGalleryCategoryConceptXrefClient("", this.serviceOrchestrationsClient);
        this.showNationalWebsite=false;
    }

    async $onInit()
    {
        this.isLoading = false;

        this.categoriesOptions = {
            valuePrimitive: false,
            dataValueField: nameof.full<PhotoGalleryCategoryConceptXrefWithCategoryDescDto>(o => o.photoGalleryCategoryId),
            dataTextField: nameof.full<PhotoGalleryCategoryConceptXrefWithCategoryDescDto>(o => o.categoryDescription),
            autoBind: true,
            optionLabel: 'Select Category',
            dataSource: new kendo.data.DataSource({
                data: this.photoGalleryCategoryConcepts
            }),


        };
        await  this.identityManager.GetLoggedInUserInfo()
        .then((loggedInUser) => {
            
                this.showNationalWebsite = this.identityManager.UserHasRole(loggedInUser.data, [
                this.apiConfig.FranForceConstants.RoleConstants.AdminRole,
                this.apiConfig.FranForceConstants.RoleConstants.DigitalOps, 
                this.apiConfig.FranForceConstants.RoleConstants.DigitalAdmin,
                this.apiConfig.FranForceConstants.RoleConstants.ConceptManagerRole,
            ]);
            this.$scope.$digest();
        });
    }

    $postLink()
    {
        this.SetDropdownOptions();
    }

    async SetDropdownOptions()
    {
        this.weblocationMultiSelectOptions =
        {
            placeholder: "Select Weblocations...",
            dataTextField: nameof.full<FranchiseWebLocationDto>(o => o.doingBusinessAs),
            dataValueField: nameof.full<FranchiseWebLocationDto>(o => o.franchiseWebLocationId),
            valuePrimitive: true,
            autoBind: true,
            dataSource: this.franchiseWebLocationDto,
            dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) =>
            {
                this.weblocationMultiSelect = e.sender;
            },
        };
    }

    async SaveMetaInfo()
    {
        this.imageFile.name = this.DBAName;
    }


    openTermsPopup()
    {
        let template =
            `<div style="width:50vw;height:50vh;">
        <p style="text-align:center;"><b>Photo Gallery Terms and Conditions</b></p>
        <p>BY SUBMITTING YOUR ORIGINAL PHOTOGRAPHIC IMAGES (“IMAGES”) TO US, YOU CONFIRM THAT YOU HAVE READ AND 
        AGREE TO THESE PHOTO GALLERY TERMS AND CONDITIONS:
        </p>

        <p>
        You grant Dwyer Franchising LLC d/b/a Neighborly (“Neighborly” or “we”), and its respective agents,
        licensees, nominees, parents, subsidiaries, designees, affiliates, successors and assigns and all others 
        (including digital, print and any other advertising media) acting with the permission of Neighborly or 
        its said client the right and license to use, copy, distribute, display, publish, modify, edit, adapt,
        translate, transmit, create derivative works from, and otherwise use all Images for advertising, publicity, 
        business trade or art purposes or any other purpose through any media whatsoever to use, reproduce, and or
        publish Images, including your image, likeness and/or voice, without compensation.
        You understand that these Images may be used in various marketing materials, publications,
        public affairs releases, recruitment materials, broadcast public service advertising (PSAs) 
        or for other related endeavors. These Images may also appear on Neighborly’s and its affiliate’s web pages. 
        Neighborly may publish Images, use your name, and or make reference to you in any manner that Neighborly
        deems appropriate in order to promote and or publicize certain opportunities. You agree that your name,
        photograph, or any Images may be used for the aforesaid purposes by Neighborly either alone or in conjunction 
        with each other and or with captions, films, artwork, text, images, or other photographs.

        </p>

        <p>
        You agree that you own or have all necessary licenses, rights, consents, and permissions to use and
        submit to us the images and to authorize us to use the Images. If you have registered any Image for
        copyright, you hereby assign said copyright and the right to renew said copyright to Neighborly.
        </p>

        <p>
        You hereby release, discharge, and agree to save harmless Neighborly and all persons acting under the permission
        or authority or those for whom it is acting, from any liability by virtue of blurring, distortion, alteration,
        optical illusion, or use in composite form whether intentional or otherwise, that may occur or be 
        produced in the finished product, unless it can be shown that they and the publication thereof were
        intentionally and maliciously caused, produced and published solely for the purpose of subjecting me
        to conspicuous ridicule, scandal, reproach, scorn and indignity.
        </p>

        <p>
          You agree that we may edit your Images in our sole discretion for any purpose we deem appropriate. 
          You agree we may remove your Images from any platform at any time without notice to you.

        </p>

         <p>
         You hereby waive any right that you have to inspect and or approve the finished work 
         or the advertising copy that may be used in connection with the Images.

         </p>

         <p>
         You hereby waive release, discharge and agree to save harmless Neighborly 
         and all other persons acting under their permission or authority or those
         for whom it is acting from any claim for royalties, residuals or other multiple 
         compensation for repeated showings of Images.

         </p>

         <p>
         You agree that the Images are not defamatory, do not impersonate any person, 
         or falsely state or misrepresent your affiliation with a person or entity.
         </p>

         <p>
         You agree that the Images do not contain, and we will not knowingly publish, the following: 

         </p>
         <ul>
                <li>Any confidential information and that we are not obligated to treat them as confidential.</li>
                <li>Any copyrighted material.
                </li>
                <li>Any illicit, violent, or pornographic material.
                </li>
                <li>Any elements that are unlawful or that we may not lawfully use or publish.
                </li>
                <li>Any individuals under the age of 18. 
                </li>
                <li>Images that contain personally identifiable information including, but not limited to, email addresses, URLs, phone numbers, physical addresses or other forms of contact information.
                </li>
                <li>Digital image files that contain anti-copying or tracking devices.
                </li>
            
            </ul>
        
            <p>
            You must be at least 18 years old to submit Images to us.
            </p>

            <p>
            You agree that this upload complies with all applicable laws and regulations including without limitation, 
            all privacy, database, copyright, trademark, patent, and trade secret laws. 

            </p>

            <p>
            You agree to defend, indemnify and hold us harmless from any and all claims, suits, 
            allegations, liabilities, damages, penalties, fines, costs and expenses, 
            including reasonable attorneys’ fees, related to or arising out of any actual or 
            alleged violation of these terms. We will provide notice to you of any such claim, suit 
            or proceeding at the email address that you submit to us with your images and shall reasonably cooperate with you, 
            at your expense, in your defense of any such claim, suit or proceeding.
            </p>

            <p>
            You hereby warrant that you are of full age and have every right to contract in your
            own name in the above regard.  You acknowledge and agree that you have read the above terms and conditions.
            </p>
        </div>`;

        let options: kendo.ui.WindowOptions = {
            modal: true,
            position: { top: 20 },

        };

        this.termsWindow.open();
        this.termsWindow.title('Terms and Conditions');
        this.termsWindow.content(template)
        this.termsWindow.center();
        this.termsWindow.setOptions(options);

    }

    toggleLegal()
    {
        this.photoGalleryInfoDto.legal_disclaimer = !this.photoGalleryInfoDto.legal_disclaimer;
        if (this.photoGalleryInfoDto.legal_disclaimer)
        {
            this.openTermsPopup();
        }
    }




    static BindComponent(app: ng.IModule)
    {
        app.component('photoEditorMetaInfoComponent', {
            bindings: {
                [nameof<PhotoEditorMetaInfoComponent>(o => o.imageFile)]: "=",

                onSave: "&",
                onBioCreate: "&",
                photoGalleryCategoryConcepts: "<",
                franchiseWebLocationDto: "<",
                selectedFranchiesIds: "=",
                photoGalleryInfoDto: "="

            },
            controller: PhotoEditorMetaInfoComponent,
            templateUrl: "/Templates/PhotoEditor/PhotoEditorMetaInfoComponent.html"
        });
    }
}


