import * as elements from 'typed-html';

import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import { ExpressionBinding } from 'Utility/AngularUtil';
import { StringProperties } from 'Types/StringProperties';

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            rmsFindScaleBasedOnTypesSelector: StringProperties<Partial<RmsFindScaleBasedOnTypesSelector>>;
        }
    }
}

export type RmsFindScaleBasedOnTypesSelectorOnSelectParams = {
    event: kendo.ui.DropDownListSelectEvent,
    findScale: RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result;
} 

export class RmsFindScaleBasedOnTypesSelector implements ng.IController 
{
    findScaleBasedOnId: number;
    readOnly: boolean;
    onSelect: ExpressionBinding<RmsFindScaleBasedOnTypesSelectorOnSelectParams>;

    dropDown: kendo.ui.DropDownList;

    static $inject = [
        'rpmUiApiResources',
    ]

    constructor(
        private rpmUiApiResources: RpmUiApiResources
    )
    {

    }

    $onInit()
    {
        
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.setOptions({ enable: !this.readOnly })
        }
    }

    $postLink()
    {
        this.InitDropdown();
    }

    InitDropdown()
    {
        this.dropDown.setOptions(
            {
                autoBind: true,
                valuePrimitive: true,
                dataValueField: nameof<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>(o => o.FindScaleBasedOnId),
                dataTextField: nameof<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>(o => o.Description),
                optionLabel: "Select...",
                optionLabelTemplate: "Select...",
                autoWidth: true,
                enable: !this.readOnly,
                select: (e) =>
                {
                    if (this.onSelect)
                    {
                        this.onSelect({
                            event: e,
                            findScale: e.dataItem
                        });
                    }
                }
            } as kendo.ui.DropDownListOptions
        );

        let dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) =>
                {
                    this.rpmUiApiResources.GetAllRoyaltyFindScaleBasedOnTypes()
                        .then((scaleBasedOnTypesResponse) =>
                        {
                            options.success(scaleBasedOnTypesResponse.data);
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        });
                }
            }
        });
    
        this.dropDown.setDataSource(dataSource);
    }

    static BindComponent(app: ng.IModule)
    {
        const template =
            <select kendo-drop-down-list={`$ctrl.${nameof<RmsFindScaleBasedOnTypesSelector>(o => o.dropDown)}`}
                k-ng-model={`$ctrl.${nameof<RmsFindScaleBasedOnTypesSelector>(o => o.findScaleBasedOnId)}`}
            >
            </select>;

        app.component(nameof<JSX.IntrinsicElements>(o => o.rmsFindScaleBasedOnTypesSelector), {
            bindings: {
                [nameof<RmsFindScaleBasedOnTypesSelector>(o => o.findScaleBasedOnId)]: "<",
                [nameof<RmsFindScaleBasedOnTypesSelector>(o => o.readOnly)]: "<",
                [nameof<RmsFindScaleBasedOnTypesSelector>(o => o.onSelect)]: "&?",
            },
            template: template,
            controller: RmsFindScaleBasedOnTypesSelector
        });
    }
}

