import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { Helpers } from "Utility/Helpers";

export class DayOfWeekSelectorComponentController implements ng.IController 
{
    dayId: number;
    readOnly: boolean;
    idOffset: number;
    onSelect: (params: { day: { id: number, display: string } }) => void;

    dropDown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    static $inject = [
        'rpmUiApiResources',
    ]

    constructor(
        private rpmUiApiResources: RpmUiApiResources
    )
    {
        if (typeof this.idOffset !== "number") {
            this.idOffset = 0;
        }
    }

    $onInit()
    {
        this.options = {
            valuePrimitive: true,
            optionLabel: "Select Day...",
            optionLabelTemplate: "Select Day...",
            dataTextField: "display",
            dataValueField: "id",
            enable: !this.readOnly,
            dataSource: new kendo.data.DataSource({
                data: Helpers.DaysOfWeekObjects()
                    .map((obj) =>
                    {
                        return {
                            id: obj.id + this.idOffset,
                            display: obj.name
                        }
                    })
            }),
            select: (e) =>
            {
                if (this.onSelect) {
                    this.onSelect({ day: e.dataItem });
                }
            }
        };
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.setOptions({ enable: !this.readOnly })
        }

        if (changes[nameof(this.dayId)] && this.dropDown) {
            this.dropDown.refresh();
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("dayOfWeekSelector", {
                bindings: {
                    [nameof<DayOfWeekSelectorComponentController>(o => o.dayId)]: "<",
                    [nameof<DayOfWeekSelectorComponentController>(o => o.idOffset)]: "<",
                    [nameof<DayOfWeekSelectorComponentController>(o => o.readOnly)]: "<",
                    [nameof<DayOfWeekSelectorComponentController>(o => o.onSelect)]: "&?",
                },
                template: `<select kendo-drop-down-list="$ctrl.${nameof<DayOfWeekSelectorComponentController>(o => o.dropDown)}"
                                   ng-model="$ctrl.${nameof<DayOfWeekSelectorComponentController>(o => o.dayId)}"
                                    k-options="$ctrl.${nameof<DayOfWeekSelectorComponentController>(o => o.options)}">
                        </select>`,
                controller: DayOfWeekSelectorComponentController
            });
    }
}

