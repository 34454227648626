import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { SyncApiResources } from "Services/Resources/SyncApiResources";
import { SyncResources } from "Interfaces/FranForce/Sync/SyncResources";

export class FranchisePosApiKeysComponentController implements ng.IController {
    //---- Bindings ----
    franchiseId: number;
    //------------------
    franchisePos: IFranchisePos;
    posKeysDropDown: kendo.ui.DropDownList;
    
    static $inject = [
        "coreApiResources",
        "syncApiResources",
        "$q",
        "$log"
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private syncApiResources: SyncApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService
    )
    {

    }

    $onInit()
    {
        this.franchisePos = {
            FranchiseId: this.franchiseId,
            PosKey: null,
            PosApiKey: null,
            PosLiveDate: null
        };
    }

    $postLink()
    {
        this.SetDropDownOptions();
    }

    SetDropDownOptions()
    {
        let dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) =>
                {
                    this.syncApiResources.SyncSourceTypesApi.query({ $select: nameof<SyncResources.ISyncSourceType>(o => o.Name) }).$promise
                        .then((sourceTypes) =>
                        {
                            sourceTypes.push(<any>{ [nameof<SyncResources.ISyncSourceType>(o => o.Name)]: "Other" });
                            options.success(sourceTypes)
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        });
                }
            }
        }); 
        this.posKeysDropDown.setOptions(<kendo.ui.DropDownListOptions>{
            dataValueField: nameof<SyncResources.ISyncSourceType>(o => o.Name),
            dataTextField: nameof<SyncResources.ISyncSourceType>(o => o.Name),
            optionLabel: "Select POS Key System...",
            optionLabelTemplate: "Select POS Key System...",
            autoWidth: true,
            height: 400,
            valuePrimitive: true
        });
        this.posKeysDropDown.setDataSource(dataSource);

        this.$q.when(this.posKeysDropDown.dataSource.read())
            .then(() =>
            {
                this.posKeysDropDown.refresh();
            })
    }

    GetFranchisePos()
    {
        return this.coreApiResources.GetFranchisePos(this.franchiseId)
            .then((posResponse) =>
            {
                this.franchisePos = posResponse.data;
                
                this.posKeysDropDown.select((dataItem: { Name: string }) =>
                {
                    if (dataItem && dataItem.Name && this.franchisePos && this.franchisePos.PosKey)
                        return dataItem.Name.toLowerCase() === this.franchisePos.PosKey.toLowerCase();

                    return false;
                });
            });
    }

    SaveFranchisePos()
    {
        if (!this.franchisePos || !this.franchisePos.PosKey)
        {
            return this.$q.reject(); 
        }

        if (confirm("Are you sure you want to change the API key?"))
        {
            return this.coreApiResources.UpdateFranchisePos(this.franchisePos); 
        }
        return this.$q.reject(); 
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("franchisePosApiKeys", {
            bindings: {
                [nameof<FranchisePosApiKeysComponentController>(o => o.franchiseId)]: "<"
            },
            controller: FranchisePosApiKeysComponentController,
            templateUrl: "/Templates/Billing/PosApiKeys.html",
        });
    }
}

