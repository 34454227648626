import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import * as _ from "underscore"
import
{
    ServiceConfigVm, ServicesClient, ServiceConfigUpdatedVm,ServiceMenuRequest,ServiceClient
} from '@nbly/service-orchestrations-clients';
import { ServiceOrchestrationApiResources } from "Services/Resources/ServiceOrchestrationApiResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
export class ConceptServicesComponent implements ng.IController
{
    //---- Bindings ----
    franchiseId: number;
    conceptCode: string;
    conceptId: number;
    concept: CoreResources.IConcept;
    serviceConfigs: ServiceConfigVm[];
    onApiSave: () => void;
    //------------------

    levelValues: { serviceProType: string, serviceCategory: string, serviceSku: string };
    isLoading: boolean;
    isPromoted: boolean;
    isCore: boolean;
    selectedLevelOptions: [];
    selectedLevel1Option: any;
    selectedLevel2Option: any;
    selectedLevel3Option: any;

    level1DropDown: kendo.ui.DropDownList;
    level2DropDown: kendo.ui.DropDownList;
    level3DropDown: kendo.ui.DropDownList;

    level1Option: kendo.ui.DropDownListOptions;
    level2Option: kendo.ui.DropDownListOptions;
    level3Option: kendo.ui.DropDownListOptions;
    serviceTypes: { name: string, value: any }[];
    serviceConfigUpdatedVm: ServiceConfigUpdatedVm;

    serviceTypeObj: any[];
    serviceTypeObjTest: any[];
    dragOptions;
    treeOptions;

    //Clients
    serviceOrchestrationsClient: AxiosInstance;
    servicesClient: ServicesClient;
    serviceClient: ServiceClient;
    updatedserviceConfigurationUrl: string;
    serviceConfigurationUrl: string;
    static $inject = [
        "$scope",
        "$window",
        "$q",
        "$log",
        "serviceOrchestrationApiResources",
        '$http',
    ];

    constructor(
        private $scope: ng.IScope,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private serviceOrchestrationApiResources: ServiceOrchestrationApiResources,
        private $http: ng.IHttpService
        )
    {

        this.treeOptions = {
            beforeDrop : function (e) {
              if (e.source.nodesScope.$parent.$id === e.dest.nodesScope.$parent.$id) {
                return $q.resolve();
              }
              else {
                window.alert("Invalid Drop.");
                return $q.reject();
              }
            },
         };
   

        this.dragOptions = { 
            accept: function(sourceNode, destNodes, destIndex) 
            { 
             var source = sourceNode.$element.attr('data-type');
             var dest = destNodes.$element.attr('data-type');
             if (source == 'child' && dest == 'top-level'){
                 //allow child nodes to be placed under top-level nodes
                 return true;
             }else{
                 return false;
             }
            
            } }

    }


    async $onInit()
    {

        this.serviceTypes = [
            { name: "Pro Type", value: 'serviceProType' },
            { name: "Service Categories", value: 'serviceCategory' },
            { name: "Service SKU's", value: 'serviceSku' },
        ];
        await this.getServiceConfig();
        this.SetDropDownOptions();

        await this.fetchUpdatedServiceConfig().then(async (response) =>
        {
            await this.setLevelsandJSON(response);    

        }).catch((err) =>
        {
            console.log('Error Occured Fetching Updated serviceConfigurations', err)
        });

        this.serviceOrchestrationsClient = FranForceAxiosClientBuilder.BuildServiceOrchestrationsBaseClient();
        this.serviceClient = new ServiceClient("",this.serviceOrchestrationsClient);
        this.servicesClient = new ServicesClient("", this.serviceOrchestrationsClient);

    }

    
    async fetchServiceConfig()
    {

        this.serviceConfigurationUrl = this.serviceOrchestrationApiResources.GetServiceConfigurationByConceptUrl(this.concept.ConceptCode);
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.serviceConfigurationUrl,

        };
        return this.$http<any>(config);
    }


    async getServiceConfig(){


        await this.fetchServiceConfig().then((response) =>
        {
            this.serviceConfigs = response.data;
        });
    }

    async setLevelsandJSON(response){
        if (response.data && response.data.length)
            {
               
                this.serviceConfigUpdatedVm = response.data[0];
                this.isPromoted = this.serviceConfigUpdatedVm.isPromoted;
                this.isCore=this.serviceConfigUpdatedVm.isCore;
                //setting drop down options
                if (this.serviceConfigUpdatedVm.levelOne)
                {
                    this.selectedLevel1Option = this.convertFirstWordToLowerCase(this.serviceConfigUpdatedVm.levelOne);
                }else{
                    this.selectedLevel1Option =null;
                }
                if (this.serviceConfigUpdatedVm.levelTwo)
                {
                    this.selectedLevel2Option = this.convertFirstWordToLowerCase(this.serviceConfigUpdatedVm.levelTwo);

                }else{
                    this.selectedLevel1Option =null;
                }
                if (this.serviceConfigUpdatedVm.levelThree)
                {
                    this.selectedLevel3Option = this.convertFirstWordToLowerCase(this.serviceConfigUpdatedVm.levelThree);

                }else{
                    this.selectedLevel3Option = null;
                }
                if(this.serviceConfigUpdatedVm.updatedContent){
                    this.serviceTypeObjTest= JSON.parse(this.serviceConfigUpdatedVm.updatedContent)
                }else {
                    this.visualize(); 
                }
                this.$scope.$digest();
            }

    }


    
    async fetchUpdatedServiceConfig()
    {

        this.updatedserviceConfigurationUrl = this.serviceOrchestrationApiResources.GetUpdatedServiceConfigurationByConceptUrl(this.conceptCode);
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.updatedserviceConfigurationUrl,

        };
        return this.$http<any>(config);
    }


      toggle = function (scope) {
        scope.toggle();
      };


    convertFirstWordToLowerCase(string)
    {
        if (string)
        {
            return string.charAt(0).toLowerCase() + string.slice(1);
        }
        return string;
    }

    convertFirstWordToUpperCase(string)
    {
        if (string)
        {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        return string;


    }


    visualize()
    {
        this.GenerateNewJSON();
    }

    async refreshServiceTree(){

        if (confirm(`Are you sure you want to refresh the Service Tree'?`))
        {
            await this.serviceClient.refreshServiceTreeMenuByConcept(this.conceptCode).then(async response=>{
                await this.getServiceConfig()
                this.SetDropDownOptions();
          
                await this.fetchUpdatedServiceConfig().then(async (response) =>
                {
                    await this.setLevelsandJSON(response);    
        
                }).catch((err) =>
                {
                    console.log('Error Occured Fetching Updated serviceConfigurations', err)
                });
                alert('Service Tree refreshed succesfully');

               }).catch((err)=>{
                alert('Error Occured Refreshing Service Tree');
               })
                
        }

    }

   async  reset(){
        await this.fetchUpdatedServiceConfig().then(async (response) =>
        {
            await this.setLevelsandJSON(response);    

        }).catch((err) =>
        {
            console.log('Error Occured Fetching Updated serviceConfigurations', err)
        });

    }

    async save()
    {
        if (this.selectedLevel1Option && this.selectedLevel2Option)
        {

            let ServiceMenuRequest:ServiceMenuRequest;
            if (this.isPromoted == null)
            {
                this.isPromoted = false;
            }

            if(this.isCore==null){
                this.isCore=false;
            }
            ServiceMenuRequest={
                levelOne:this.convertFirstWordToUpperCase(this.selectedLevel1Option),
                levelTwo:this.convertFirstWordToUpperCase(this.selectedLevel2Option),
                levelThree:this.convertFirstWordToUpperCase(this.selectedLevel3Option),
                isPromoted:this.isPromoted,
                isCore: this.isCore,
             /*    content:JSON.stringify(this.serviceTypeObjTest) */
             content:angular.toJson(this.serviceTypeObjTest)
            }
            //updates brand service configuration by protype, category, sku level
             await this.servicesClient.levels(this.conceptCode, ServiceMenuRequest).then(async (response) =>
            {
                await this.fetchUpdatedServiceConfig().then(async (response) =>
                {
                    await this.setLevelsandJSON(response);    
        
                })
    
            }).catch((err) =>
            {
                console.log('Error Occured', err)
            }) 

        }
    }

    async GenerateNewJSON(){
        let serviceTypes = _.uniq(this.serviceConfigs, 'serviceTypeId');
        this.serviceTypeObjTest = serviceTypes.map(function (serviceType)
        {
            return { serviceTypeId: serviceType.serviceTypeId, serviceType: serviceType.serviceType,
            id:serviceType.serviceTypeId,title:serviceType.serviceType,isServiceType:true
            };
        });
        this.serviceTypeObjTest.map((serviceType, index) =>
        {
            let filteredServiceType = this.serviceConfigs.filter(obj => obj.serviceTypeId == serviceType.serviceTypeId);

            let serviceTypes: any = _.uniq(filteredServiceType, this.selectedLevel1Option + 'Id');
            serviceTypes = serviceTypes.map((serviceType1) =>
            {
                if(this.selectedLevel1Option=='serviceSku'){
                    if(serviceType1.isCoreService){
                        return {isCoreService:true, [this.selectedLevel1Option + 'Id']: serviceType1[this.selectedLevel1Option + 'Id'],id:serviceType1[this.selectedLevel1Option + 'Id'], [this.selectedLevel1Option + 'Name']: serviceType1[this.selectedLevel1Option + 'Name'],title:serviceType1[this.selectedLevel1Option + 'Name'] };
                
                    }
                }
                return { [this.selectedLevel1Option + 'Id']: serviceType1[this.selectedLevel1Option + 'Id'],id:serviceType1[this.selectedLevel1Option + 'Id'], [this.selectedLevel1Option + 'Name']: serviceType1[this.selectedLevel1Option + 'Name'],title:serviceType1[this.selectedLevel1Option + 'Name'] };
                
            });
            serviceTypes.map((serviceTypes1, index) =>
            {
                let filteredLevel = filteredServiceType.filter(obj => obj[this.selectedLevel1Option + 'Id'] == serviceTypes1[this.selectedLevel1Option + 'Id']);

                let level2: any = _.uniq(filteredLevel, this.selectedLevel2Option + 'Id');
                level2 = level2.map((l2) =>
                {

                    if (l2[this.selectedLevel2Option + 'Name'] == serviceTypes1[this.selectedLevel1Option + 'Name'] && level2.length == 1)
                    {

                        if(this.selectedLevel2Option=='serviceSku'){
                            if(l2.isCoreService){
                                return { isCoreService:true,isPromoted: true, [this.selectedLevel2Option + 'Id']: l2[this.selectedLevel2Option + 'Id'],id:l2[this.selectedLevel2Option + 'Id'], [this.selectedLevel2Option + 'Name']: l2[this.selectedLevel2Option + 'Name'],title: l2[this.selectedLevel2Option + 'Name']  };
          
                            }else{
                                return { isPromoted: true, [this.selectedLevel2Option + 'Id']: l2[this.selectedLevel2Option + 'Id'],id:l2[this.selectedLevel2Option + 'Id'], [this.selectedLevel2Option + 'Name']: l2[this.selectedLevel2Option + 'Name'],title: l2[this.selectedLevel2Option + 'Name']  };
          
                            }
                        }

                        return { isPromoted: true, [this.selectedLevel2Option + 'Id']: l2[this.selectedLevel2Option + 'Id'],id:l2[this.selectedLevel2Option + 'Id'], [this.selectedLevel2Option + 'Name']: l2[this.selectedLevel2Option + 'Name'],title: l2[this.selectedLevel2Option + 'Name']  };
                    } else
                    {

                        if(this.selectedLevel2Option=='serviceSku'){
                            if(l2.isCoreService){
                                return { isPromoted: false,isCoreService:true, [this.selectedLevel2Option + 'Id']: l2[this.selectedLevel2Option + 'Id'],id:l2[this.selectedLevel2Option + 'Id'], [this.selectedLevel2Option + 'Name']: l2[this.selectedLevel2Option + 'Name'] ,title:l2[this.selectedLevel2Option + 'Name']};
      
                            }else{
                                return {isPromoted: false, [this.selectedLevel2Option + 'Id']: l2[this.selectedLevel2Option + 'Id'],id:l2[this.selectedLevel2Option + 'Id'], [this.selectedLevel2Option + 'Name']: l2[this.selectedLevel2Option + 'Name'] ,title:l2[this.selectedLevel2Option + 'Name']};
      
                            }
                        }

                        return { [this.selectedLevel2Option + 'Id']: l2[this.selectedLevel2Option + 'Id'],id:l2[this.selectedLevel2Option + 'Id'], [this.selectedLevel2Option + 'Name']: l2[this.selectedLevel2Option + 'Name'] ,title:l2[this.selectedLevel2Option + 'Name']};
                    }


                });

                if (this.selectedLevel3Option)
                {
                    level2.map((serviceTypes2, index) =>
                    {
                        let filteredLevel = filteredServiceType.filter(obj => obj[this.selectedLevel2Option + 'Id'] == serviceTypes2[this.selectedLevel2Option + 'Id']);
                        let level3: any = _.uniq(filteredLevel, this.selectedLevel3Option + 'Id');
                        level3 = level3.map((l3) =>
                        {
                            if ( l3[this.selectedLevel3Option + 'Name'] == serviceTypes2[this.selectedLevel2Option + 'Name'] && level3.length == 1)
                            {

                                if(this.selectedLevel3Option=='serviceSku'){
                                    if(l3.isCoreService){
                                        return {isCoreService:true, isPromoted: true, [this.selectedLevel3Option + 'Id']: l3[this.selectedLevel3Option + 'Id'],id:l3[this.selectedLevel3Option + 'Id'], [this.selectedLevel3Option + 'Name']: l3[this.selectedLevel3Option + 'Name'],title:l3[this.selectedLevel3Option + 'Name'] };
                   
                                    }else{
                                        return { isPromoted: true, [this.selectedLevel3Option + 'Id']: l3[this.selectedLevel3Option + 'Id'],id:l3[this.selectedLevel3Option + 'Id'], [this.selectedLevel3Option + 'Name']: l3[this.selectedLevel3Option + 'Name'],title:l3[this.selectedLevel3Option + 'Name'] };
                   
                                    }
                                }
                                return { isPromoted: true, [this.selectedLevel3Option + 'Id']: l3[this.selectedLevel3Option + 'Id'],id:l3[this.selectedLevel3Option + 'Id'], [this.selectedLevel3Option + 'Name']: l3[this.selectedLevel3Option + 'Name'],title:l3[this.selectedLevel3Option + 'Name'] };
                            } else
                            {

                                if(this.selectedLevel3Option=='serviceSku'){
                                    if(l3.isCoreService){
                                        return { isPromoted: false,isCoreService:true,[this.selectedLevel3Option + 'Id']: l3[this.selectedLevel3Option + 'Id'],id:l3[this.selectedLevel3Option + 'Id'], [this.selectedLevel3Option + 'Name']: l3[this.selectedLevel3Option + 'Name'] ,title:l3[this.selectedLevel3Option + 'Name']};
                    
                                    }else{
                                        return { isPromoted: false,[this.selectedLevel3Option + 'Id']: l3[this.selectedLevel3Option + 'Id'],id:l3[this.selectedLevel3Option + 'Id'], [this.selectedLevel3Option + 'Name']: l3[this.selectedLevel3Option + 'Name'] ,title:l3[this.selectedLevel3Option + 'Name']};
                    
                                    }
                                }
        
                                return {isPromoted: false, [this.selectedLevel3Option + 'Id']: l3[this.selectedLevel3Option + 'Id'],id:l3[this.selectedLevel3Option + 'Id'], [this.selectedLevel3Option + 'Name']: l3[this.selectedLevel3Option + 'Name'] ,title:l3[this.selectedLevel3Option + 'Name']};
                            }

                        });
                        level2[index][this.selectedLevel3Option] = level3;
                        let l3Obj=[{title:this.selectedLevel3Option,nodes:level3,isHeading:true}]
                        level2[index]['nodes'] = l3Obj;
                    });
                }


                serviceTypes[index][this.selectedLevel2Option] = level2;
                let l2Obj=[{title:this.selectedLevel2Option,nodes:level2,isHeading:true}]
                serviceTypes[index]['nodes'] = l2Obj;
            });
            this.serviceTypeObjTest[index][this.selectedLevel1Option] = serviceTypes;
            let l1Obj=[{title:this.selectedLevel1Option,nodes:serviceTypes,isHeading:true}]
            this.serviceTypeObjTest[index]['nodes'] = l1Obj;
        });
    }

 

    clearSelection(level)
    {
        if (confirm(`Are you sure you want to update the levels, the changes would be lost.`)) {
            this[level] = null;
            if(this.selectedLevel1Option && this.selectedLevel2Option){
                this.visualize();
            }
        }
      
    }

    updateLevelDropdowns(level)
    {
            if (level == 'l1')
            {
                if (this.selectedLevel1Option == this.selectedLevel2Option)
                {
                    this.selectedLevel2Option = null;
                }
                if (this.selectedLevel1Option == this.selectedLevel3Option)
                {
                    this.selectedLevel3Option = null;
                }
            }
            if (level == 'l2')
            {
                if (this.selectedLevel2Option == this.selectedLevel1Option)
                {
    
                    this.selectedLevel1Option = null;
                }
                if (this.selectedLevel2Option == this.selectedLevel3Option)
                {
                    this.selectedLevel3Option = null;
                }
            }
            if (level == 'l3')
            {
                if (this.selectedLevel3Option == this.selectedLevel2Option)
                {
                    this.selectedLevel2Option = null;
                }
                if (this.selectedLevel3Option == this.selectedLevel1Option)
                {
                    this.selectedLevel1Option = null;
                }
            }
            this.visualize();
     
    }

    SetDropDownOptions()
    {
        this.level1Option = {
            valuePrimitive: false,
            dataValueField: "value",
            dataTextField: "name",
            autoBind: true,
            dataSource: new kendo.data.DataSource({
                data: this.serviceTypes
            }),
            change: (e: kendo.ui.DropDownListChangeEvent) => {
                this.updateLevelDropdowns('l1');
            },
            select: (e: kendo.ui.DropDownListSelectEvent) => {
                if(e.dataItem.value == this.selectedLevel1Option){
                    e.preventDefault();
                }else if (!confirm(`Are you sure you want to update the levels, the changes would be lost.`)) {
                    e.preventDefault();
                }
            
            }
        };

        this.level2Option = {
            valuePrimitive: false,
            dataValueField: "value",
            dataTextField: "name",
            autoBind: true,
            dataSource: new kendo.data.DataSource({
                data: this.serviceTypes
            }),
            change: (e: kendo.ui.DropDownListChangeEvent) => {
                this.updateLevelDropdowns('l2');
            },
            select: (e: kendo.ui.DropDownListSelectEvent) => {
                if(e.dataItem.value == this.selectedLevel2Option){
                    e.preventDefault();
                }else if (!confirm(`Are you sure you want to update the levels, the changes would be lost.`)) {
                    e.preventDefault();
                }
            
            }
        };

        this.level3Option = {
            optionLabel: '',
            valuePrimitive: false,
            dataValueField: "value",
            dataTextField: "name",
            autoBind: true,
            dataSource: new kendo.data.DataSource({
                data: this.serviceTypes
            }),
            change: (e: kendo.ui.DropDownListChangeEvent) => {
                this.updateLevelDropdowns('l3');
            },
            select: (e: kendo.ui.DropDownListSelectEvent) => {
                if(e.dataItem.value == this.selectedLevel3Option){
                    e.preventDefault();
                }else if (!confirm(`Are you sure you want to update the levels, the changes would be lost.`)) {
                    e.preventDefault();
                }
            
            }
        };

    }

    static BindComponent(app: ng.IModule)
    {
        app.component("conceptServicesComponent", {
            bindings: {
                franchiseId: "<",
                [nameof<ConceptServicesComponent>(o => o.conceptCode)]: "<",
                [nameof<ConceptServicesComponent>(o => o.conceptId)]: "<",
                [nameof<ConceptServicesComponent>(o => o.concept)]: "<",
                onApiSave: "&",
            },
            controller: ConceptServicesComponent,
            templateUrl: "/Templates/Concepts/ConceptServices.html",
        });
    }
}

