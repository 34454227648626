import { IdentityManager } from "../../../../Services/Resources/IdentityManager";
import {
    RoyaltyUserApprovalConfigurationClient,
    RoyaltyUserApprovalConfigurationVm,
    RoyaltyOrchestrationsClientResponse,
    RoyaltyApprovalConfigurationUserVm,
    RoyaltyUserApprovalConfigurationCreateRm
} from '@nbly/royalty-orchestrations-clients';
import { FranForceAxiosClientBuilder } from "../../../../Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import { CoreResources } from "../../../../Interfaces/FranForce/Core/CoreResources";


export class rmsApprovalConfigurationsComponentController implements ng.IController {


    isLoading: boolean;
    isAdmin: boolean;
    currentUserId: string;
    currentUserName: string;
    showValidationMessage: boolean;
    validationMessage: string;
    validationStatusClass: string;

    conceptId: number;
    userId: string;
    userName: string;
    effectiveDateFrom: string;
    effectiveDateTo: string;
    methodofNotificationTypeId: number;

    approvalConfigUsers: RoyaltyApprovalConfigurationUserVm[];

    royaltyUserApprovalConfigurationId: number;
    disableConfigFields: boolean;

    royaltyOrchestrationsClient: AxiosInstance;
    royaltyUserApprovalConfigurationClient: RoyaltyUserApprovalConfigurationClient;

    conceptFilters: kendo.data.DataSourceFilters;

    static $inject = [
        '$window',
        '$location',
        '$q',
        '$scope',
        '$rootScope',
        'identityManager',
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private $scope: ng.IScope,
        private $rootScope: ng.IRootScopeService,
        private identityManager: IdentityManager,
    ) {
        this.royaltyOrchestrationsClient = FranForceAxiosClientBuilder.BuildRoyaltyOrchestrationsBaseClient();
        this.royaltyUserApprovalConfigurationClient = new RoyaltyUserApprovalConfigurationClient("", this.royaltyOrchestrationsClient);
    }

    async $onInit() {
        this.$scope.$emit('setApprovalConfigurationTabUnsaved', { isApprovalConfigurationTabUnsaved: false });
        this.CreateConceptFilters();
        this.disableConfigFields = false;
        this.royaltyUserApprovalConfigurationId = 0;
        this.isLoading = false;
        this.isAdmin = false;
        await this.GetApprovalConfigUsers();
        this.SetValidationMessage(false, '', '');
        await this.checkIfAdminUser();
        this.$scope.$digest();
    }

    CreateConceptFilters() {
        this.conceptFilters = {
            logic: "or",
            filters: [
                {
                    field: nameof<CoreResources.IConcept>(o => o.RoyaltySystemId),
                    operator: 'eq',
                    value: 1
                },
                {
                    field: nameof<CoreResources.IConcept>(o => o.RoyaltySystemId),
                    operator: 'eq',
                    value: 3
                }
            ]
        }
    }

    async GetApprovalConfigUsers() {
        this.isLoading = true;
        this.royaltyUserApprovalConfigurationClient.users()
            .then((response) => {
                if (response && response.result) {
                    this.approvalConfigUsers = response.result;
                }
            })
            .catch((err) => {
                alert("Error! Unable to load approval configuration users.");
            })
            .finally(() => {
                this.isLoading = false;
                this.$scope.$digest();
            });
    }

    async checkIfAdminUser() {
        this.isLoading = true;
        this.identityManager.GetLoggedInUserInfo()
            .then(response => {
                this.isLoading = false;
                if (response && response.data && response.data.Roles && response.data.Roles.length > 0) {

                    this.currentUserId = response.data['UserID'];
                    this.currentUserName = response.data['FirstName'] + ' ' + response.data['LastName'] + ' (' + response.data['Email'] + ')';
                    this.userName = response.data['FirstName'] + ' ' + response.data['LastName'] + ' (' + response.data['Email'] + ')';

                    response.data.Roles.map(x => {

                        if (x.Name.trim().toLowerCase() == ("Admin").toLowerCase()
                            || x.Name.trim().toLowerCase() == ("RMSApprovalConfigurationAdmin").toLowerCase())
                        {
                            this.isAdmin = true;
                            return;
                        }
                    });
                }
            })
            .catch(ex => {
                this.isLoading = false;
                alert("Error! Unable to get user roles.");
            })
            .finally(() => {
                this.$scope.$digest();
            });
    }

    SetValidationMessage(showValidationMessage: boolean, validationMessage: string, validationStatusClass: string) {
        this.showValidationMessage = showValidationMessage;
        this.validationMessage = validationMessage;
        this.validationStatusClass = validationStatusClass;
    }

    OnSave() {

        let effectiveFromDate = new Date(this.effectiveDateFrom);
        let effectiveFromISODate = this.GetISODate(effectiveFromDate);
        let effectiveToDate = new Date(this.effectiveDateTo);
        let effectiveToISODate = this.GetISODate(effectiveToDate);

        if (!this.conceptId || this.conceptId == null || this.conceptId == undefined) {
            this.SetValidationMessage(true, "Please select Concept.", "rmsApprovalConfigurationValidationError");
            return true;
        }
        else {
            this.SetValidationMessage(false, "", "");
        }

        if (!this.userId || this.userId == null || this.userId == undefined || this.userId == '') {
            this.SetValidationMessage(true, "Please select User.", "rmsApprovalConfigurationValidationError");
            return true;
        }
        else {
            this.SetValidationMessage(false, "", "");
        }

        if (!this.effectiveDateFrom || this.effectiveDateFrom == null || this.effectiveDateFrom == undefined || this.effectiveDateFrom.toString() == '') {
            this.SetValidationMessage(true, "Please select Effective Date From.", "rmsApprovalConfigurationValidationError");
            return true;
        }
        else {
            this.SetValidationMessage(false, "", "");
        }

        if (!this.effectiveDateTo || this.effectiveDateTo == null || this.effectiveDateTo == undefined || this.effectiveDateTo.toString() == '') {
            this.SetValidationMessage(true, "Please select Effective Date To.", "rmsApprovalConfigurationValidationError");
            return true;
        }
        else {
            this.SetValidationMessage(false, "", "");
        }


        if (effectiveFromDate > effectiveToDate) {
            this.SetValidationMessage(true, "Effective From Date should be less than Effective To Date.", "rmsApprovalConfigurationValidationError");
            return true;
        }
        else {
            this.SetValidationMessage(false, "", "");
        }

        if (!this.methodofNotificationTypeId || this.methodofNotificationTypeId == null || this.methodofNotificationTypeId == undefined || this.methodofNotificationTypeId.toString() == '') {
            this.SetValidationMessage(true, "Please select Method of Notification.", "rmsApprovalConfigurationValidationError");
            return true;
        }
        else {
            this.SetValidationMessage(false, "", "");
        }

        let royaltyUserApprovalConfigurationData: RoyaltyUserApprovalConfigurationCreateRm = {
            conceptId: this.conceptId,
            userId: this.userId,
            effectiveDateFrom: effectiveFromISODate,
            effectiveDateTo: effectiveToISODate,
            royaltyApprovalNotificationTypeId: this.methodofNotificationTypeId,
            royaltyUserApprovalConfigurationId: this.royaltyUserApprovalConfigurationId
        }

        this.isLoading = true;

        //create or update
        if (this.royaltyUserApprovalConfigurationId < 1) {
            this.royaltyUserApprovalConfigurationClient.royaltyUserApprovalConfigurations(royaltyUserApprovalConfigurationData)
                .then((response) => {
                    if (response && response.result) {
                        this.$scope.$emit('setApprovalConfigurationTabUnsaved', { isApprovalConfigurationTabUnsaved: false });
                        this.royaltyUserApprovalConfigurationId = response.result.royaltyUserApprovalConfigurationId;
                        this.SetValidationMessage(true, "User approval configurations saved successfully.", "rmsApprovalConfigurationValidationSuccess");
                    }
                    else {
                        this.SetValidationMessage(true, "Error! Failed to save user approval configurations.", "rmsApprovalConfigurationValidationError");
                    }
                })
                .catch(() => {
                    this.SetValidationMessage(true, "Error! Failed to save user approval configurations.", "rmsApprovalConfigurationValidationError");
                })
                .finally(() => {
                    this.isLoading = false;
                    this.$scope.$digest();
                })
        }
        else {
            this.royaltyUserApprovalConfigurationClient.royaltyUserApprovalConfigurations2(this.royaltyUserApprovalConfigurationId, royaltyUserApprovalConfigurationData)
                .then((response) => {
                    if (response && response.result) {
                        this.$scope.$emit('setApprovalConfigurationTabUnsaved', { isApprovalConfigurationTabUnsaved: false });
                        this.SetValidationMessage(true, "User approval configurations saved successfully.", "rmsApprovalConfigurationValidationSuccess");
                    }
                    else {
                        this.SetValidationMessage(true, "Error! Failed to save user approval configurations.", "rmsApprovalConfigurationValidationError");
                    }
                })
                .catch(() => {
                    this.SetValidationMessage(true, "Error! Failed to save user approval configurations.", "rmsApprovalConfigurationValidationError");
                })
                .finally(() => {
                    this.isLoading = false;
                    this.$scope.$digest();
                })
        }
    }

    OnConceptSelect(concept: any) {
        this.disableConfigFields = false;
        if (!this.isAdmin) {
            this.userId = this.currentUserId;
        }
        this.userName = this.currentUserName;
        this.isLoading = true;
        this.SetValidationMessage(false, "", "");
        if (this.conceptId != concept.ConceptId) {
            this.$scope.$emit('setApprovalConfigurationTabUnsaved', { isApprovalConfigurationTabUnsaved: true });
            this.conceptId = concept.ConceptId;

            if (this.conceptId != null || this.conceptId != undefined) {
                this.royaltyUserApprovalConfigurationClient.conceptId(this.conceptId)
                    .then((response) => {

                        if (response && response.result && response.result.length && response.result.length > 0) {

                            if (this.isAdmin) {
                                this.userId = response.result[0].userId;
                                this.effectiveDateFrom = response.result[0].effectiveDateFrom;
                                this.effectiveDateTo = response.result[0].effectiveDateTo;
                                this.methodofNotificationTypeId = response.result[0].royaltyApprovalNotificationTypeId;
                                this.royaltyUserApprovalConfigurationId = response.result[0].royaltyUserApprovalConfigurationId;
                            }
                            else {
                                if (this.currentUserId == response.result[0].userId) {
                                    this.userId = response.result[0].userId;
                                }
                                else {
                                    this.approvalConfigUsers.forEach(x => {
                                        if (response.result[0].userId == x.userId) {
                                            this.userName = x.firstName + ' ' + x.lastName + ' (' + x.email + ')';
                                            this.disableConfigFields = true;
                                            return true;
                                        }
                                    })
                                }

                                this.effectiveDateFrom = response.result[0].effectiveDateFrom;
                                this.effectiveDateTo = response.result[0].effectiveDateTo;
                                this.methodofNotificationTypeId = response.result[0].royaltyApprovalNotificationTypeId;
                                this.royaltyUserApprovalConfigurationId = response.result[0].royaltyUserApprovalConfigurationId;
                            }

                        }
                        else {
                            if (!this.isAdmin)
                                this.userId = this.currentUserId;
                            else
                                this.userId = null;

                            this.effectiveDateFrom = null;
                            this.effectiveDateTo = null;
                            this.methodofNotificationTypeId = null;
                            this.royaltyUserApprovalConfigurationId = 0;
                        }
                        
                    })
                    .catch(() => {
                        alert("Error! Unable to get user approval configurations");
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.$scope.$digest();
                    })
            }
            else {
                this.userId = null;
                this.effectiveDateFrom = null;
                this.effectiveDateTo = null;
                this.methodofNotificationTypeId = null;
                this.royaltyUserApprovalConfigurationId = 0;
                this.SetValidationMessage(false, '', '');
                this.disableConfigFields = false;
            }
        }
    }

    OnUserSelect(user: any) {

        if (this.userId != user.userId) {
            this.$scope.$emit('setApprovalConfigurationTabUnsaved', { isApprovalConfigurationTabUnsaved: true });
        }

        if (user && this.userId != user.userId) {
            this.userId = user.userId;
        }
    }

    OnNotificationTypeSelect(notificationType: any) {

        if (this.methodofNotificationTypeId != notificationType.royaltyApprovalNotificationTypeId) {
            this.$scope.$emit('setApprovalConfigurationTabUnsaved', { isApprovalConfigurationTabUnsaved: true });
        }

        if (notificationType && this.methodofNotificationTypeId != notificationType.royaltyApprovalNotificationTypeId) {
            this.methodofNotificationTypeId = notificationType.royaltyApprovalNotificationTypeId;
        }
    }

    OnEffectiveDateToSelect() {
        this.$scope.$emit('setApprovalConfigurationTabUnsaved', { isApprovalConfigurationTabUnsaved: true });
    }

    OnEffectiveDateFromSelect() {
        this.$scope.$emit('setApprovalConfigurationTabUnsaved', { isApprovalConfigurationTabUnsaved: true });
    }

    GetISODate(date: Date) {

        function pad(number) {
            if (number < 10) {
                return '0' + number;
            }
            return number;
        }

        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            '.' + (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
            'Z';
    };

    static BindComponent(app: ng.IModule) {
        app.component("rmsApprovalConfigurations", {
            bindings: {

            },
            templateUrl: '/Templates/RMSApproval/RmsApprovalConfigurations/rmsApprovalConfigurations.html',
            controller: rmsApprovalConfigurationsComponentController
        });
    }
}