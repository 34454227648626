import { KendoUtil } from "Utility/KendoUtil";

import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import * as _ from "underscore"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";



export class fddRoyaltiesRatePlanItemsComponentController implements ng.IController {

    disclosureDocumentRatePlanId: number;
    readOnly: boolean;
    isMethodologySpecial: boolean;
    onSelectedRatePlanItem: (params: { ratePlanItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel, isRemoved: boolean }) => void;
    onLoaded: (params: { self: fddRoyaltiesRatePlanItemsComponentController }) => void;

    isLoading: boolean;
    selectedRatePlanItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel;
    royaltyRatePlanItemTypes: RpmEntities.RoyaltyRatePlanItemTypeViewModel[];
    royaltyRatePlanItemGrid: kendo.ui.Grid;
    royaltyRatePlanItemGridOptions: kendo.ui.GridOptions;
    rollInRateGrids: { [uid: string]: kendo.ui.Grid };

    formController: angular.IFormController;
    
    static $inject = [
        'rpmUiApiResources',
        'rpmHelper',
        'identityManager',
        '$q',
        '$timeout'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private identityManager: IdentityManager,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService)
    {

    }

    $onInit()
    {
        this.isLoading = true;
        this.rollInRateGrids = {};
        this.InitRoyaltyRatePlanItemGrid();

        this.rpmUiApiResources.GetAllRoyaltyRatePlanItemTypes().then(typesResponse => {
            this.royaltyRatePlanItemTypes = typesResponse.data;
        })
            .then(() => {
                return this.CancelChanges();
            })
            .finally(() => {
                this.isLoading = false;
                this.onLoaded({ self: this });
            })
    }
    
    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes.disclosureDocumentRatePlanId)
        {
            //this.InitRoyaltyRatePlanItemGrid();
            this.$timeout(() => { this.ResetChanges(); }, 0);
        }
    }

    InitRoyaltyRatePlanItemGrid()
    {
        let rpitDataSource = new kendo.data.DataSource({
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {
                    if (!this.disclosureDocumentRatePlanId)
                    {
                        options.success([]);
                        return;
                    }

                    this.rpmUiApiResources.GetRoyaltyDisclosureDocumentRatePlanItemsByRatePlan(this.disclosureDocumentRatePlanId)
                        .then(
                            (ratePlansResponse) => {
                                options.success(ratePlansResponse.data);
                            },
                            (err) => { options.error(err); }
                        )
                },
                create: (options: kendo.data.DataSourceTransportOptions) => {
                    let createModel: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel = options.data;
                    this.rpmUiApiResources.CreateRoyaltyDisclosureDocumentRatePlanItem(createModel).then(
                        (idResponse) => {
                            createModel.DisclosureDocumentRatePlanItemId = idResponse.data;
                            options.success({DisclosureDocumentRatePlanItemId: idResponse.data})
                        }, (err) => { options.error(err) });
                },
                update:  (options: kendo.data.DataSourceTransportOptions)  => {
                    let updateModel: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel = options.data;
                    this.rpmUiApiResources.UpdateRoyaltyDisclosureDocumentRatePlanItem(updateModel.DisclosureDocumentRatePlanItemId, updateModel)
                        .then((idResponse) => {
                            options.success();
                        }, (err) => { options.error(err) });
                },
                destroy: (options: kendo.data.DataSourceTransportOptions) => {
                    let deleteModel: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel = options.data;
                    deleteModel.DeletedDateTime = new Date().toISOString();
                    this.rpmUiApiResources.UpdateRoyaltyDisclosureDocumentRatePlanItem(deleteModel.DisclosureDocumentRatePlanItemId, deleteModel)
                        .then((idResponse) => {
                            options.success();
                        }, (err) => { options.error(err) });
                },
            },
            pageSize: 10,
            schema: {
                model: {
                    id: "DisclosureDocumentRatePlanItemId",
                    fields: {
                        DisclosureDocumentRatePlanItemId: {
                            type: "number",
                            validation: { required: true },
                            editable: false,
                            defaultValue: 0
                        },
                        RoyaltyRatePlanItemType: {
                            type: "object",
                            validation: { required: true },
                            defaultValue: { Description: "Annual Scale", RoyaltyRatePlanItemTypeID: 1 }
                        },
                        RoyaltyRatePlanItemTypeID: {
                            type: "number",
                            validation: { required: true },
                            defaultValue: 1
                        },
                        WeeksInBusinessStart: {
                            type: "number",
                            validation: { required: true }
                        },
                        WeeksInBusinessEnd: {
                            type: "number",
                            validation: { required: true }
                        },
                        GrossSalesRangeLow: { type: "number", validation: { required: true } },
                        GrossSalesRangeHigh: { type: "number", validation: { required: true } },
                        RoyaltyFeeRateResidential: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        RoyaltyFeeFixedRateAmountResidential: { type: "number", validation: { required: true } },
                        RoyaltyFeeRateCommercial: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        RoyaltyFeeFixedRateAmountCommercial: { type: "number", validation: { required: true } },
                        AdFeeRateResidential: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        AdFeeFixedRateAmountResidential: { type: "number", validation: { required: true } },
                        AdFeeRateCommercial: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        AdFeeFixedRateAmountCommercial: { type: "number", validation: { required: true } },
                        PerCapitaLow: { type: "number" },
                        TAFSFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        TAFSFeeFixedRateAmount: { type: "number", validation: { required: true } },
                        TechnologyFeeRate: {
                            type: "number",
                            validation: {
                                required: true
                            }
                        },
                        TechnologyFeeFixedRateAmount: { type: "number", validation: { required: true } },
                        DeletedDateTime: {
                            type: "string",
                            defaultValue: null
                        },
                        HasRollInRateChanges: {
                            type: "boolean",
                            defaultValue: false
                        }
                    }
                }
            },
            filter: { field: "DeletedDateTime", operator: "isnull" },
            sort: [
                { field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(d => d.WeeksInBusinessStart), dir: "asc" },
                { field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(d => d.WeeksInBusinessEnd), dir: "asc" },
                { field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(d => d.GrossSalesRangeLow), dir: "asc" },
                { field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(d => d.GrossSalesRangeHigh), dir: "asc" },
            ]
        });

        let rpitColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.DisclosureDocumentRatePlanItemId),
                title: "ID",
                template: `<button 
                    ng-show="dataItem.DisclosureDocumentRatePlanItemId && $ctrl.isMethodologySpecial"
                    ng-class="'pure-button grid-edit-button ' + ($ctrl.selectedRatePlanItem.DisclosureDocumentRatePlanItemId === dataItem.DisclosureDocumentRatePlanItemId ? 'rpm-selected-row-btn' : '')" 
                    ng-click="$ctrl.SelectRatePlanItem(dataItem) ; $event.preventDefault()">
                        <i ng-class="'fa fa-' + ($ctrl.selectedRatePlanItem.DisclosureDocumentRatePlanItemId === dataItem.DisclosureDocumentRatePlanItemId ? 'check-circle-o' : 'circle-o')" aria-hidden="true"></i>
                        <span ng-bind="dataItem.DisclosureDocumentRatePlanItemId"></span>
                    </button>
                    <span ng-show="!dataItem.DisclosureDocumentRatePlanItemId || !$ctrl.isMethodologySpecial" ng-bind="dataItem.DisclosureDocumentRatePlanItemId"></span>`
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.RoyaltyRatePlanItemTypeID),
                title: "Rate Plan Item Type",
                editor: (container, options) => {
                    let input = "<input required name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: "Description",
                        dataValueField: "RoyaltyRatePlanItemTypeId",
                        dataSource: {
                            data: this.royaltyRatePlanItemTypes
                        },
                        change: (e) => {
                            var dataItem = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.royaltyRatePlanItemTypes[0];
                            }

                            options.model.set("RoyaltyRatePlanItemTypeID", dataItem.RoyaltyRatePlanItemTypeId);
                        }
                    });
                },
                template: `{{$ctrl.GetRoyaltyPlanItemTypeById(#=${nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.RoyaltyRatePlanItemTypeID)}#).Description}}`,
                width: "150px"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.WeeksInBusinessStart),
                title: "WIB Start"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.WeeksInBusinessEnd),
                title: "WIB End"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.PerCapitaLow),
                title: "Per Capita",
                format: "{0:n4}",
                editor: this.rpmHelper.DecimalEditor
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.GrossSalesRangeLow),
                title: "Sales Scale Begins",
                format: "{0:c}",
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.GrossSalesRangeHigh),
                title: "Sales Scale Ends",
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.RoyaltyFeeRateResidential),
                title: "Res Royalty Fee Rate",
                hidden: this.isMethodologySpecial,
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.RoyaltyFeeFixedRateAmountResidential),
                title: "Res Royalty Fixed Amount",
                hidden: this.isMethodologySpecial,
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.RoyaltyFeeRateCommercial),
                title: "Com Royalty Fee Rate",
                hidden: this.isMethodologySpecial,
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.RoyaltyFeeFixedRateAmountCommercial),
                title: "Com Royalty Fixed Amount",
                hidden: this.isMethodologySpecial,
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.AdFeeRateResidential),
                title: "Res Ad Fee Rate",
                hidden: this.isMethodologySpecial,
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.AdFeeFixedRateAmountResidential),
                title: "Res Ad Fee Fixed Amount",
                hidden: this.isMethodologySpecial,
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.AdFeeRateCommercial),
                title: "Com Ad Fee Rate",
                hidden: this.isMethodologySpecial,
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.AdFeeFixedRateAmountCommercial),
                title: "Com Ad Fee Fixed Amount",
                hidden: this.isMethodologySpecial,
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.TAFSFeeRate),
                title: "TAFS Fee Rate",
                hidden: this.isMethodologySpecial,
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.TAFSFeeFixedRateAmount),
                title: "TAFS Fee Fixed Amount",
                hidden: this.isMethodologySpecial,
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.TechnologyFeeRate),
                title: "Tech Fee Rate",
                hidden: this.isMethodologySpecial,
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel>(o => o.TechnologyFeeFixedRateAmount),
                title: "Tech Fee Fixed Amount",
                hidden: this.isMethodologySpecial,
                format: "{0:c}"
            }, {
                field: "HasRollInRateChanges",
                title: "Has Roll In Rate Changes",
                hidden: true
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }];

        for (let col of rpitColumns)
        {
            col.headerAttributes = {
                style: "white-space: normal; border-left: #AAA 1px solid;"
            };
        }
        rpitColumns[0].headerAttributes = null;

        this.royaltyRatePlanItemGridOptions = {
            autoBind: false,
            selectable: "row",
            dataSource: rpitDataSource,
            columns: rpitColumns,
            editable: !this.readOnly ? { confirmation: false } : false,
            navigatable: true,
            scrollable: false, 
            pageable: KendoUtil.GetDefaultKendoGridPageable(10),
            remove: (e) =>
            {
                if (this.selectedRatePlanItem && e.model.id === this.selectedRatePlanItem.DisclosureDocumentRatePlanItemId)
                {
                    this.$timeout(() => {
                        this.SelectRatePlanItem(null, true);
                    }, 0);
                }
            }
        };
    }
    
    
    GetRollInRateGridOptions(ratePlanItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel): kendo.ui.GridOptions
    {
        let rirDataSource = new kendo.data.DataSource({
            batch: true,
            schema: {
                 model: {
                    id: "typeFee",
                    fields: {
                        ParentId: {
                            type: "string",
                            validation: { required: true },
                            editable: false,
                            defaultValue: 0
                        },
                        typeFee: {
                            type: "string",
                            validation: { required: true },
                            editable: false,
                             
                        },
                        rollInSalesBegin: { type: "number", validation: { required: true } },
                        rollInSalesEnd: { type: "number", validation: { required: true } },
                        royaltyFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        royaltyFeeFixedRateAmount: { type: "number", validation: { required: true } },
                        adFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        adFeeFixedRateAmount: { type: "number", validation: { required: true } }
                    }
                 }
            },
             transport: {
                 read: (e: kendo.data.DataSourceTransportReadOptions) => {
                    e.success(this.rpmHelper.ConvertToRollInRates(ratePlanItem, ratePlanItem.DisclosureDocumentRatePlanItemId));
                 },
                 update: (e: kendo.data.DataSourceTransportOptions) => {
                     for (let rollInRate of <IRollInRateItem[]>e.data.models)
                     {
                        this.rpmHelper.MergeRollInRateItem(ratePlanItem, rollInRate)
                     }

                     if (ratePlanItem.DisclosureDocumentRatePlanId)
                     {
                         //If it's a new item, this flag doesn't need to be set as it will already be gaurenteed to be created.
                        this.royaltyRatePlanItemGrid.dataSource
                             .get(ratePlanItem.DisclosureDocumentRatePlanItemId)
                             .set("HasRollInRateChanges", true);
                     }
                     
                     e.success(e.data.models);
                 },
                 destroy: (e: kendo.data.DataSourceTransportOptions) => {
                     e.error('Destory is not defined for roll in rates, as it should not be possible.')
                 },
                 create: (e: kendo.data.DataSourceTransportOptions) => {
                     e.error('Create is not defined for roll in rates, as it should not be possible.')
                 },
             }
        });
        let rirColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "ParentId",
                title: "DisclosureDocumentRatePlanItemId",
                hidden: true
            }, {
                field: "typeFee",
                title: "Type Fee"
            }, {
                field: "rollInSalesBegin",
                title: "Roll-in Sales Begin",
                format: "{0:c}"
            }, {
                field: "rollInSalesEnd",
                title: "Roll-in Sales End",
                format: "{0:c}"
            }, {
                field: "royaltyFeeRate",
                title: "Royalty Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: "royaltyFeeFixedRateAmount",
                title: "Royalty Fee Fixed Rate Amount",
                format: "{0:c}"
            }, {
                field: "adFeeRate",
                title: "Ad Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: "adFeeFixedRateAmount",
                title: "Ad Fee Fixed Rate Amount",
                format: "{0:c}"
            }];

        return {
            columns: rirColumns,
            dataSource: rirDataSource,
            editable: !this.readOnly,
            navigatable: true,
            scrollable: false, 
            pageable: false
        };
    }

    SelectRatePlanItem(ratePlanItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel, isRemoved: boolean = false)
    {
        if (this.selectedRatePlanItem === ratePlanItem)
            ratePlanItem = null;

        this.selectedRatePlanItem = ratePlanItem;
        if (this.onSelectedRatePlanItem)
        {
            this.onSelectedRatePlanItem({ ratePlanItem: this.selectedRatePlanItem, isRemoved: isRemoved });
        }
    }

    
    SelectRowById(id: number)
    {
        let dataItem = this.royaltyRatePlanItemGrid.dataSource.get(id);
        if (dataItem)
            this.SelectRatePlanItem(<any>dataItem);
    }
    
    GetRoyaltyPlanItemTypeById(planItemTypeId: number)
    {
        return _.find(this.royaltyRatePlanItemTypes, (rpit) => { return rpit.RoyaltyRatePlanItemTypeId === planItemTypeId; });
    }

    AddNewRecord()
    {
        if (!this.readOnly)
        {
            let defaultRatePlanItem: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemViewModel = {
                DisclosureDocumentRatePlanItemId: 0,
                DisclosureDocumentRatePlanId: this.disclosureDocumentRatePlanId,
                WeeksInBusinessStart: 0,
                RoyaltyRatePlanItemTypeID: 2,
                SmallRollInLowRangeAmount: 0,
                SmallRollInHighRangeAmount: 0,
                SmallRollInRoyaltyFeeRate: 0,
                SmallRollInRoyaltyFeeFixedRateAmount: 0,
                SmallRollInAdFeeRate: 0,
                SmallRollInAdFeeFixedRateAmount: 0,
                MediumRollInLowRangeAmount: 0,
                MediumRollInHighRangeAmount: 0,
                MediumRollInRoyaltyFeeRate: 0,
                MediumRollInRoyaltyFeeFixedRateAmount: 0,
                MediumRollInAdFeeRate: 0,
                MediumRollInAdFeeFixedRateAmount: 0,
                LargeRollInLowRangeAmount: 0,
                LargeRollInHighRangeAmount: 0,
                LargeRollInRoyaltyFeeRate: 0,
                LargeRollInRoyaltyFeeFixedRateAmount: 0,
                LargeRollInAdFeeRate: 0,
                LargeRollInAdFeeFixedRateAmount: 0,
                GrossSalesRangeLow: 0,
                GrossSalesRangeHigh: 0,
                RoyaltyFeeRateResidential: 0,
                RoyaltyFeeFixedRateAmountResidential: 0,
                RoyaltyFeeRateCommercial: 0,
                RoyaltyFeeFixedRateAmountCommercial: 0,
                AdFeeRateResidential: 0,
                AdFeeFixedRateAmountResidential: 0,
                AdFeeRateCommercial: 0,
                AdFeeFixedRateAmountCommercial: 0,
                TechnologyFeeRate: 0,
                TechnologyFeeFixedRateAmount: 0,
                TAFSFeeRate: 0,
                TAFSFeeFixedRateAmount: 0,
                WeeksInBusinessEnd: 0,
                PerCapitaLow: 0,
                CreatedDateTime: kendo.toString(new Date(), 'd'),
                CreatedUser: null,
                UpdatedDateTime: null,
                UpdatedUser: null,
                DeletedDateTime: null,
                RoyaltyDisclosureDocumentRatePlanItemDetails: null
            };
            
            this.royaltyRatePlanItemGrid.dataSource.add(defaultRatePlanItem);
        }
    }
    
    CancelChanges()
    {
        if (!this.readOnly)
        {
            Object.keys(this.rollInRateGrids).forEach(key => {
                this.rollInRateGrids[key].dataSource.cancelChanges();
            });
            this.royaltyRatePlanItemGrid.dataSource.cancelChanges();
        }
    }
    
    HasChanges()
    {
        if (!this.royaltyRatePlanItemGrid)
            return false;

        return this.royaltyRatePlanItemGrid.dataSource.hasChanges() ||
            Object.keys(this.rollInRateGrids)
                .map(key => { return this.rollInRateGrids[key].dataSource.hasChanges(); })
                .some(hasChanges => { return hasChanges; });
    }

    ResetChanges()
    {    
        this.CancelChanges();

        return this.royaltyRatePlanItemGrid.dataSource.read().then(() => {
            this.royaltyRatePlanItemGrid.refresh();

            this.$timeout(() =>
            {
                this.formController.$setPristine();
            }, 1000);
            
            this.rollInRateGrids = {};
        });
    }

    SaveChanges()
    {
        let syncPromises: ng.IPromise<any>[] = [];
        Object.keys(this.rollInRateGrids).forEach(key => {
            syncPromises.push(this.$q.when(this.rollInRateGrids[key].dataSource.sync()));
        });
        return this.$q.all(syncPromises)
            .then(() =>
            {
                return this.$q.when(this.royaltyRatePlanItemGrid.dataSource.sync())
                    .then(() =>
                    {
                        return this.ResetChanges();
                    });
            })
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("fddRoyaltiesRatePlanItems", {
                bindings: {
                    disclosureDocumentRatePlanId: "<",
                    readOnly: "<",
                    isMethodologySpecial: "<",
                    onSelectedRatePlanItem: "&?",
                    onLoaded: "&?"
                },
                templateUrl: '/Templates/FDDManagement/fddRoyalties/RatePlanItems.html',
                controller: fddRoyaltiesRatePlanItemsComponentController
            });

    }
}
