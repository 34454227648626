import { CoreApiResources } from "Services/Resources/CoreApiResources";

export class FranchiseProfileInstructionPopupController implements ng.IController {

    franchiseId: number;
    isOpen: boolean;
    
    static $inject = ['coreApiResources'];

    constructor(
        private coreApiResources: CoreApiResources
    )
    {
    }

    $onInit()
    {

    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfileInstructionPopup', {
            bindings: {
                franchiseId: "<"
            },
            controller: FranchiseProfileInstructionPopupController,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfileInstructionPopup.html"
        });
    }
}


