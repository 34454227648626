import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RmsFranchiseClient } from "Clients/Rms/RmsFranchiseClient";
import { KendoUtil } from "Utility/KendoUtil";
import { AngularUtil } from "Utility/AngularUtil";
import { StringProperties } from 'Types/StringProperties';
import * as elements from 'typed-html';
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { franchiseListGridDataModel } from "../../../Models/Royalty/FranchiseEntities/FranchiseListGridDataModel";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            rmsFranchisesInEntityGrid: Partial<StringProperties<FranchisesInEntityGrid>>;
        }
    }
}

export class FranchisesInEntityGrid implements ng.IController 
{
    franchiseEntityId: number;

    isLoading: boolean;
    grid: kendo.ui.Grid;

    private franchiseClient: RmsFranchiseClient;

    static $inject = [
        "royaltyApiResources"
    ];

    constructor(
        private royaltyApiResources: RoyaltyApiResources,
    )
    {
        this.franchiseClient = new RmsFranchiseClient();
    }

    $onInit()
    {

    }

    $postLink()
    {
        this.InitGridOptions();
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.franchiseEntityId)])
        {
            if (this.franchiseEntityId) {
                this.InitGridOptions();
                this.grid.dataSource.read();
                this.grid.refresh(); 
            }
        }
    }

    InitGridOptions()
    {
        if (this.franchiseEntityId) {
            this.isLoading = true;
            this.royaltyApiResources.GetAllFranchisesByFranchiseEntityId(this.franchiseEntityId)
                .then(
                    (response) => {
                        this.isLoading = false;
                        const gridData = response.data;
                    
                        if (gridData && gridData.length) {
                            const columns: kendo.ui.GridColumn[] =
                            [
                                    {
                                    field: nameof<franchiseListGridDataModel>(o => o.franchiseId),
                                        title: "Selected"
                                    },
                                    {
                                        field: nameof<franchiseListGridDataModel>(o => o.licenseNumber),
                                        title: "License Number"
                                    },
                                    {
                                        field: nameof<franchiseListGridDataModel>(o => o.franchiseeName),
                                        title: "Franchisee"
                                    },
                                    {
                                        field: nameof<franchiseListGridDataModel>(o => o.status),
                                        title: "Status"
                                    },
                                    {
                                        field: nameof<franchiseListGridDataModel>(o => o.contractBeginsDate),
                                        title: "Contract Start Date",
                                        template: function({status,contractBeginsDate}){
                                            if(!contractBeginsDate || !status)
                                                return '';

                                            return status.toLowerCase() == 'active'? 
                                            `<span>
                                                ${contractBeginsDate.split('T')[0]}
                                            </span>`:``
                                        }
                                    },
                                    {
                                        field: nameof<franchiseListGridDataModel>(o => o.contractEndsDate),
                                        title: "Contract End Date",
                                        template: function({status,contractEndsDate}){
                                            if(!contractEndsDate || !status)
                                                return '';

                                            return status.toLowerCase() == 'active'? 
                                            `<span>
                                                ${contractEndsDate.split('T')[0]}
                                            </span>`:``
                                        }
                                    }
                            ];

                            const options: kendo.ui.GridOptions = {
                                columns: columns,
                                dataSource: new kendo.data.DataSource(),
                                editable: false,
                                sortable: true,
                                filterable: true,
                                height: 300,
                                scrollable: true,
                                resizable: true
                            };

                            this.grid.setOptions(options);
                            this.grid.dataSource.data(gridData)
                        } 

                    }).catch(error => {
                        this.isLoading = false;
                        alert("error in loading franchise entity data");
                    });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.rmsFranchisesInEntityGrid);

        let loadingTemplate = (
            <loadingGearsOverlay isLoading={`$ctrl.${nameof<FranchisesInEntityGrid>(o => o.isLoading)}`} ></loadingGearsOverlay>
        );

        let grid = (
            <div>
                <h1 ng-show={`$ctrl.${nameof<FranchisesInEntityGrid>(o => o.franchiseEntityId)}`}>Entity Franchises</h1>
                <div kendo-grid={`$ctrl.${nameof<FranchisesInEntityGrid>(o => o.grid)}`}
                    ng-show={`$ctrl.${nameof<FranchisesInEntityGrid>(o => o.franchiseEntityId)}`}>
                </div>
            </div>
        );

        let template = `${loadingTemplate} ${grid}`;
        app.component(componentName, {
            bindings: {
                [nameof<FranchisesInEntityGrid>(o => o.franchiseEntityId)]: "<"
            },
            controller: FranchisesInEntityGrid,
            template: template
        });
    }
}

