import { KnowledgeApiResources } from "../../Services/Resources/KnowledgeApiResources";

export abstract class KbsBaseComponentController<TEntity> implements ng.IController {
    gridPageSize: number = 10;
    entityGrid: kendo.ui.Grid;
    entityGridOptions: kendo.ui.GridOptions;
    isGridBound: boolean;

    entity: TEntity;

    entityWindow: kendo.ui.Window;
    entityWindowOptions: kendo.ui.WindowOptions;

    validationErrors: string[];

    static $inject = [
        "knowledgeApiResources",
        "$q",
        "$log",
        "$timeout"
    ];

    constructor(
        protected knowledgeApiResources: KnowledgeApiResources,
        protected $q: ng.IQService,
        protected $log: ng.ILogService,
        protected $timeout: ng.ITimeoutService
    ) {
    }

    $onInit() {
        this.isGridBound = false;
        this.SetGridOptions();
        this.SetWindowOption();
    }

    abstract GetGridColumns(): kendo.ui.GridColumn[];
    abstract GetDataSourceSchemaModel(): any;
    abstract TransportReadOperationHandler(options: kendo.data.DataSourceTransportReadOptions);
    abstract CreateNewEntity(): TEntity;
    abstract GetSavePromise(entity: TEntity): ng.IPromise<TEntity>;
    abstract DeleteEntity(entity: TEntity);

    SetWindowOption() {
        this.entityWindowOptions = { modal: true };
    }

    SetGridOptions() {
        this.entityGridOptions = {
            columns: this.GetGridColumns(),
            sortable: true,
            filterable: true,
            pageable: true,
            dataBound: (a) => {
                this.isGridBound = true;
            },
            dataSource: new kendo.data.DataSource({
                schema: {
                    model: this.GetDataSourceSchemaModel()
                },
                pageSize: this.gridPageSize,
                transport: {
                    read: (options: kendo.data.DataSourceTransportReadOptions) => {
                        this.isGridBound = false;
                        this.TransportReadOperationHandler(options);
                    }
                }
            }),
        }
    }

    OpenNewEntityWindow() {
        this.ResetValidationErrors();
        this.entity = this.CreateNewEntity();
        this.ShowEntityWindow();
    }

    OpenEditEntityWindow(entity: TEntity) {
        this.ResetValidationErrors();
        this.entity = entity;
        this.ShowEntityWindow();
    }

    SaveEntity() {
        this.ResetValidationErrors();

        let entityPromise: ng.IPromise<TEntity> = this.GetSavePromise(this.entity);

        return entityPromise
            .then(() => {
                return this.entityGrid.dataSource.read().always(() => {
                    this.entityWindow.close();
                    this.RefreshGrid();
                });
            })
            .catch((err) => {
                if (err.data) {
                    err.data.forEach(a => {
                        this.validationErrors.push(a.ErrorMessage);
                    });
                }
                throw err;
            });
    }

    RefreshGrid() {
        this.entityGrid.dataSource.read()
            .always(() => {
                this.entityGrid.refresh();
            });
    }

    ResetValidationErrors() {
        this.validationErrors = [];
    }

    ShowEntityWindow() {
        this.$timeout(() => {
            this.entityWindow.center();
        }, 0);
        this.entityWindow.open();
    }
}
