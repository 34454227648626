import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { MerchantApiResources } from "Services/Resources/MerchantApiResources";
import { IRedeemDisplayGiftCertificateStatus } from "Controllers/GiftCertificates/RedeemGiftCertificateController";
import { UrlQueryParameters } from "Utility/UrlQueryParameters";
import { AngularUtil } from "Utility/AngularUtil";

export class RefundGiftCertificateController implements ng.IController {
    
    //giftCertificateId: string;
    //giftCertificateSuffix: string;
    //refundComments: string;
    refundModel: IRefundGiftCertificateRequest;
    giftCertificateStatus: IRedeemDisplayGiftCertificateStatus;
    
    static $inject = [
        "$scope",
        "coreApiResources",
        "merchantApiResources",
        "$q",
        "$log"
    ];

    constructor(
        private $scope: ng.IScope,
        private coreApiResources: CoreApiResources,
        private merchantApiResources: MerchantApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService
    )
    {

    }

    $onInit() {
        let gcId : string = new UrlQueryParameters(window).GetValue("cid");
        if (gcId && gcId.indexOf("-") !== -1)
        {
            let splitId = gcId.split("-");
            this.refundModel = {
                GiftCertificateId: Number(splitId[0]),
                GiftCertificateSuffix: splitId[1],
                IsManual: false,
                Notes: ""
            };
        }
    }

    GetCertStatus()
    {
        //TODO - Cert status really should be componentized between the refund and redeem page.
        return this.merchantApiResources.GetGiftCertificateStatus(this.refundModel.GiftCertificateId, this.refundModel.GiftCertificateSuffix)
            .then((response) => {
                this.giftCertificateStatus = response.data;
                this.giftCertificateStatus.ConceptName = this.giftCertificateStatus.ConceptId.toString();

                this.coreApiResources.ConceptApi.get({ id: this.giftCertificateStatus.ConceptId, $select: "DisplayName" }).$promise.then((concept) => {
                    this.giftCertificateStatus.ConceptName = concept.DisplayName;
                });

                if (this.giftCertificateStatus.RedeemedByFranchiseId)
                {
                    this.giftCertificateStatus.RedeemedByFranchiseName = this.giftCertificateStatus.RedeemedByFranchiseId.toString();
                    this.coreApiResources.FranchiseApi.get({ id: this.giftCertificateStatus.RedeemedByFranchiseId, $select: "LicenseNumber" }).$promise.then((franchise) => {
                        this.giftCertificateStatus.RedeemedByFranchiseName = `${franchise.LicenseNumber} - (${this.giftCertificateStatus.RedeemedByFranchiseId})` ;
                    });
                }
            });
    }

    RefundCertificate()
    {
        if (confirm("Are you sure you want to refund this gift certificate?"))
        {
            return this.merchantApiResources.RefundGiftCertificate(this.refundModel)
                .then((response) => {
                    return this.GetCertStatus();
                    },
                    AngularUtil.GetErrorPromiseLogAlertCallback<ng.IHttpPromiseCallbackArg<any>>(this.$log, this.$q, (err) => {
                        return err.data.Message;
                    }));
        }
    }

}