import _ = require("underscore");
import moment = require("moment");
import * as elements from "typed-html";

import { KendoUtil, BindDropDownEditor } from "Utility/KendoUtil";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";

import * as rmsClients from "Clients/Rms";
import { StringProperties } from "Types/StringProperties";
import { MonthEnabled } from "Directives/Common";
import { fddRoyaltiesRatePlanMinimumsMonthsApplied, fddRoyaltiesRatePlanMinimumsMonthsAppliedOnInit, TerritoryPopulationFindScaleBasedOnId, fddRoyaltiesRatePlanMinimumsMonthsAppliedOnSelect } from "Directives/RPM/FddManagement";
import { ExpressionBinding, AngularUtil } from "Utility/AngularUtil";
import { NameOfFullToControllerAs } from "Utility/Helpers";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            fddRoyaltiesRatePlanMinimums: Partial<StringProperties<fddRoyaltiesRatePlanMinimums>>;
        }
    }
}

export interface RoyaltyDisclosureDocumentRatePlanMinimumGridItem extends RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum
{
    MonthSettings: fddRoyaltiesRatePlanMinimumsMonthsAppliedOnSelect["all"];
}

export type fddRoyaltiesRatePlanMinimumsOnInit = {
    self: fddRoyaltiesRatePlanMinimums
};

export type fddRoyaltiesRatePlanMinimumsOnFindScaleChange = {
    ratePlans: RoyaltyDisclosureDocumentRatePlanMinimumGridItem[]
};

interface DataSourcePromise<T>
{
    dataSource: kendo.data.DataSource,
    dataPromise: Promise<T[]>;
}

export class fddRoyaltiesRatePlanMinimums implements ng.IController {

    readOnly: boolean;
    onInit: ExpressionBinding<fddRoyaltiesRatePlanMinimumsOnInit>;
    onFindScaleChange: ExpressionBinding<fddRoyaltiesRatePlanMinimumsOnFindScaleChange>

    ratePlanMinimumGrid: kendo.ui.Grid;

    isLoading: boolean;

    hasTerritoryFindScaleSelected: boolean = false;
    private disclosureDocumentRatePlanId: number;
    private feeTypes: DataSourcePromise<RpmEntities.usp_RoyaltyFeeTypeResult>;
    private royaltyPeriodFrequencies: DataSourcePromise<RpmEntities.RoyaltyPeriodFrequencyViewModel>; 
    private royaltyFindScaleBasedOnTypes: DataSourcePromise<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>;

    private minimumsClient = rmsClients.royaltyDisclosureDocumentRatePlanMinimumClient;

    static $inject = [
        'rpmUiApiResources',
        'rpmHelper',
        '$timeout',
        'coreApiResources'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private $timeout: ng.ITimeoutService,
        private coreApiResources: CoreApiResources
    )
    {

    }

    $onInit() 
    {
    }

    async $postLink()
    {
        this.InitLookupData();
        this.InitRatePlanMinimumItemsGrid();
        
        if (this.onInit)
            this.onInit({ self: this });
    }

    async LoadGrid(disclosureDocumentRatePlanId: number)
    {
        await AngularUtil.TrackLoadingPromise((async () =>
        {
            this.disclosureDocumentRatePlanId = disclosureDocumentRatePlanId;
            await this.LoadLookupData();
            await Promise.resolve(this.ratePlanMinimumGrid.dataSource.read());
        })(), this);
    }

    async LoadLookupData()
    {
        this.feeTypes.dataPromise = Promise.resolve(this.rpmUiApiResources.GetAllRoyaltyFeeTypes().then(r => r.data));
        this.royaltyPeriodFrequencies.dataPromise = Promise.resolve(this.rpmUiApiResources.GetAllRoyaltyPeriodFrequencies().then(r => r.data));
        this.royaltyFindScaleBasedOnTypes.dataPromise = Promise.resolve(this.rpmUiApiResources.GetAllRoyaltyFindScaleBasedOnTypes().then(r => r.data));

        await Promise.all([
            this.feeTypes.dataSource.read(),
            this.royaltyPeriodFrequencies.dataSource.read(),
            this.royaltyFindScaleBasedOnTypes.dataSource.read() 
        ]);

        this.$timeout();
    }

    private InitLookupData()
    {
        this.feeTypes = {
            dataPromise: Promise.resolve(null),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                    {
                        return await this.feeTypes.dataPromise;
                    })
                }
            })
        };

        this.royaltyPeriodFrequencies = {
            dataPromise: Promise.resolve(null),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                    {
                        return await this.royaltyPeriodFrequencies.dataPromise;
                    })
                }
            })
        };

        this.royaltyFindScaleBasedOnTypes = {
            dataPromise: Promise.resolve(null),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                    {
                        return await this.royaltyFindScaleBasedOnTypes.dataPromise;
                    })
                }
            })
        };
    }

    private InitRatePlanMinimumItemsGrid()
    {
        let schema: kendo.data.DataSourceSchema = {
            model: {
                id: nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.RoyaltyDisclosureDocumentRatePlanMinimumId),
                fields: {
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.RoyaltyDisclosureDocumentRatePlanMinimumId)]: {
                        type: "number",
                        validation: { required: true },
                        editable: false,
                        defaultValue: 0
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.DisclosureDocumentRatePlanId)]: {
                        type: "number",
                        validation: { required: true },
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.RoyaltyFeeTypeId)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.RoyaltyPeriodFrequencyId)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.FindScaleBasedOnId)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.WeeksInBusinessStart)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.WeeksInBusinessEnd)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.FeeFixedRateAmount)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.FeeRate)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.GrossSalesMinimumAmount)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.MonthSettings)]: {
                        type: "object",
                        defaultValue: []
                    }
                } as kendo.data.DataSourceSchemaModelFields
            }
        }

        let minItemDataSourceOptions: kendo.data.DataSourceOptions = {
            //filter: { field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum>(o => o.DeletedDateTime), operator: "isnull" },
            transport: {
                read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) => {

                    if (!this.disclosureDocumentRatePlanId) {
                        return [];
                    }

                    let minimums = await this.minimumsClient.Query({
                        $filter: `${nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum>(o => o.DisclosureDocumentRatePlanId)} eq ${this.disclosureDocumentRatePlanId}`
                    });

                    return minimums;
                }),
                create: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let newItem: RoyaltyDisclosureDocumentRatePlanMinimumGridItem & kendo.data.Model = options.data;
                    newItem.DisclosureDocumentRatePlanId = this.disclosureDocumentRatePlanId;
                    //let minimumResult = await this.minimumsClient.Post(newItem);
                    let minimumResult = await this.coreApiResources.CreateRoyaltyDisclosureDocumentRatePlanMinimums(newItem).then((response) => {
                        return response.data
                    });

                    if (newItem.MonthSettings?.length)
                    {
                        await fddRoyaltiesRatePlanMinimumsMonthsApplied.SaveMonthsEnabled(newItem.MonthSettings, minimumResult.RoyaltyDisclosureDocumentRatePlanMinimumId, this.coreApiResources);
                    }

                    return minimumResult;
                }),
                update: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let updateItem: RoyaltyDisclosureDocumentRatePlanMinimumGridItem & kendo.data.Model = options.data;

                    if (updateItem.MonthSettings?.length)
                    {
                        await fddRoyaltiesRatePlanMinimumsMonthsApplied.SaveMonthsEnabled(updateItem.MonthSettings, updateItem.RoyaltyDisclosureDocumentRatePlanMinimumId, this.coreApiResources);
                    }

                    //let minimumResult = await this.minimumsClient.Put(updateItem.RoyaltyDisclosureDocumentRatePlanMinimumId, updateItem);
                    let minimumResult = await this.coreApiResources.UpdateRoyaltyDisclosureDocumentRatePlanMinimums(updateItem.RoyaltyDisclosureDocumentRatePlanMinimumId, updateItem).then((response) => {
                        return response.data
                    });

                    return minimumResult;
                }),
                destroy: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let deleteItem: RoyaltyDisclosureDocumentRatePlanMinimumGridItem = options.data;
                    return await this.minimumsClient.Delete(deleteItem.RoyaltyDisclosureDocumentRatePlanMinimumId, {allowHardDelete: false, forceHardDelete: false});
                })
            },
            pageSize: 10,
            schema: schema,
            sort: {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.WeeksInBusinessStart),
                dir: "asc"
            }
        };

        let minItemColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.RoyaltyDisclosureDocumentRatePlanMinimumId),
                title: "Id",
                width: 160,
                hidden: true
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.RoyaltyFeeTypeId),
                title: "Fee Type",
                editor: (container, options) =>
                {
                    let dropDownListOptions: kendo.ui.DropDownListOptions = {
                        dataTextField: nameof<RpmEntities.usp_RoyaltyFeeTypeResult>(o => o.Description),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyFeeTypeResult>(o => o.RoyaltyFeeTypeId),
                        dataSource: this.feeTypes.dataSource,
                        change: (e) =>
                        {
                            let dataItem: RpmEntities.usp_RoyaltyFeeTypeResult = e.sender.dataItem();
                            options.model.set(nameof<RpmEntities.usp_RoyaltyFeeTypeResult>(o => o.RoyaltyFeeTypeId), dataItem?.RoyaltyFeeTypeId);
                        },
                        open: (e) => { e.sender.dataSource.read(); }
                    }
                    let ddl = BindDropDownEditor(container, options, dropDownListOptions);
                },
                template: (dataItem: RoyaltyDisclosureDocumentRatePlanMinimumGridItem) =>
                {
                    return this.feeTypes.dataSource.data()
                        .find((ft: RpmEntities.usp_RoyaltyFeeTypeResult) => { return ft.RoyaltyFeeTypeId === dataItem.RoyaltyFeeTypeId; })
                        ?.Description ?? "--";
                }
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.RoyaltyPeriodFrequencyId),
                title: "Minimum Fee Frequency",
                editor: (container, options) => 
                {
                    let dropDownOptions: kendo.ui.DropDownListOptions = {
                        dataTextField: nameof<RpmEntities.RoyaltyPeriodFrequencyViewModel>(o => o.Description),
                        dataValueField: nameof<RpmEntities.RoyaltyPeriodFrequencyViewModel>(o => o.RoyaltyPeriodFrequencyId),
                        dataSource: this.royaltyPeriodFrequencies.dataSource,
                        select: (e) => 
                        {
                            let dataItem: RpmEntities.RoyaltyPeriodFrequencyViewModel = e.dataItem;

                            options.model.set(nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.RoyaltyPeriodFrequencyId), dataItem.RoyaltyPeriodFrequencyId);
                        },
                        open: (e) => { e.sender.dataSource.read(); }
                    };
                    let ddl = BindDropDownEditor(container, options, dropDownOptions);
                },
                template: (dataItem: RoyaltyDisclosureDocumentRatePlanMinimumGridItem) =>
                {
                    return this.royaltyPeriodFrequencies.dataSource.data()
                        .find((f: RpmEntities.RoyaltyPeriodFrequencyViewModel) => { return f.RoyaltyPeriodFrequencyId === dataItem.RoyaltyPeriodFrequencyId; })
                        ?.Description ?? "--";
                }
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.FindScaleBasedOnId),
                title: "Minimum Scale Based On",
                editor: (container, options) => 
                {
                    let dropDownOptions: kendo.ui.DropDownListOptions = {
                        dataTextField: nameof<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>(o => o.Description),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>(o => o.FindScaleBasedOnId),
                        dataSource: this.royaltyFindScaleBasedOnTypes.dataSource,
                        select: (e) => 
                        {
                            this.$timeout(() =>
                            {
                                let dataItem: RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result = e.dataItem;

                                options.model.set(nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.FindScaleBasedOnId), dataItem.FindScaleBasedOnId);
                                if (this.onFindScaleChange)
                                {
                                    this.onFindScaleChange({
                                        ratePlans: e.sender.dataSource.data().filter(
                                            (gridItem: RoyaltyDisclosureDocumentRatePlanMinimumGridItem) => { return (gridItem.FindScaleBasedOnId == TerritoryPopulationFindScaleBasedOnId && gridItem.RoyaltyDisclosureDocumentRatePlanMinimumId > 0); }
                                        )
                                    })
                                }
                            })
                        },
                        open: (e) => { e.sender.dataSource.read(); }
                    };
                    let ddl = BindDropDownEditor(container, options, dropDownOptions);
                    ddl.jqueryElement.prop("required", "required");
                },
                template: (dataItem: RoyaltyDisclosureDocumentRatePlanMinimumGridItem) =>
                {
                    return this.royaltyFindScaleBasedOnTypes.dataSource.data()
                        .find((fs: RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result) => { return fs.FindScaleBasedOnId === dataItem.FindScaleBasedOnId; })
                        ?.Description ?? "--";
                }
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.WeeksInBusinessStart),
                title: "WIB Start"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.WeeksInBusinessEnd),
                title: "WIB End"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.FeeFixedRateAmount),
                title: "Min Royalty Fee",
                format: "{0:c}"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.FeeRate),
                title: "Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.GrossSalesMinimumAmount),
                title: "GrossSalesMinAmount",
                format: "{0:c}"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumGridItem>(o => o.MonthSettings),
                title: "MonthSettings",
                hidden: true
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }];

        let toolbar: kendo.ui.GridToolbarItem[] = [
            {
                template: (
                    <button type="button"
                        ng-click={`${NameOfFullToControllerAs(nameof.full(this.ratePlanMinimumGrid.addRow))}()`}>
                        Add Row
                    </button>
                )
            }
        ];

        let options: kendo.ui.GridOptions = {
            autoBind: false,
            toolbar: toolbar,
            dataSource: new kendo.data.DataSource(minItemDataSourceOptions),
            columns: minItemColumns,
            selectable: "row",
            editable: !this.readOnly ? { confirmation: false } : false,
            navigatable: true,
            scrollable: false,
            sortable: true,
            pageable: KendoUtil.GetDefaultKendoGridPageable(10),
            edit: (e) =>
            {
                if (e.model.isNew())
                {
                    e.sender.expandRow(
                        e.sender.tbody.find("tr[data-uid='" + e.model.uid + "']")
                    );
                }
            },
            dataBound: (e) =>
            {
                if (this.onFindScaleChange)
                {
                    this.$timeout(() =>
                    {
                        this.onFindScaleChange({
                            ratePlans: e.sender.dataSource.data().filter(
                                (gridItem: RoyaltyDisclosureDocumentRatePlanMinimumGridItem) =>
                                {
                                    return (gridItem.FindScaleBasedOnId == TerritoryPopulationFindScaleBasedOnId && gridItem.RoyaltyDisclosureDocumentRatePlanMinimumId > 0);
                                }
                            )
                        });
                    });
                }
            }
        };

        this.ratePlanMinimumGrid.setOptions(options);
    }

    private SetHasMonthlyMinimumChanges(dataItem: kendo.data.Model & RoyaltyDisclosureDocumentRatePlanMinimumGridItem, monthSettings: fddRoyaltiesRatePlanMinimumsMonthsAppliedOnSelect["all"])
    {
        dataItem.MonthSettings = monthSettings;
        dataItem.dirty = true;
    }

    private SetMinimumMonthsAppliedController(dataItem: kendo.data.Model & RoyaltyDisclosureDocumentRatePlanMinimumGridItem, controller: fddRoyaltiesRatePlanMinimumsMonthsAppliedOnInit["self"])
    {
        this.$timeout(async () =>
        {
            if (dataItem?.MonthSettings?.length)
            {
                controller.SetMonthsData(dataItem.RoyaltyDisclosureDocumentRatePlanMinimumId ?? 0, dataItem.MonthSettings, true)
            }
            else
            {
                await controller.LoadMonthsData(dataItem.RoyaltyDisclosureDocumentRatePlanMinimumId);
            }
        });
    }

    CancelChanges()
    {
        this.ratePlanMinimumGrid.cancelChanges();
    }
    
    HasChanges()
    {
        //Don't need to check monthly minimums because they should already show up as changes via HasMonthlyMinimumChanges
        return this.ratePlanMinimumGrid.dataSource.hasChanges();
    }

    ResetChanges()
    {
        this.CancelChanges();
        this.LoadGrid(this.disclosureDocumentRatePlanId);
    }

    async SaveChanges()
    {
        await Promise.resolve(this.ratePlanMinimumGrid.dataSource.sync());
        await this.ResetChanges();
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.fddRoyaltiesRatePlanMinimums);
        let template = (
            <div kendo-grid={`$ctrl.${nameof<fddRoyaltiesRatePlanMinimums>(o => o.ratePlanMinimumGrid)}`}>
                <div k-detail-template>
                    <fddRoyaltiesRatePlanMinimumsMonthsApplied
                        onInit={`$ctrl.${nameof<fddRoyaltiesRatePlanMinimums>(o => o.SetMinimumMonthsAppliedController)}(dataItem, ${nameof<fddRoyaltiesRatePlanMinimumsMonthsAppliedOnInit>(o => o.self)})`}
                        onSelect={`$ctrl.${nameof<fddRoyaltiesRatePlanMinimums>(o => o.SetHasMonthlyMinimumChanges)}(dataItem, ${nameof<fddRoyaltiesRatePlanMinimumsMonthsAppliedOnSelect>(o => o.all)})`}
                        >
                    </fddRoyaltiesRatePlanMinimumsMonthsApplied>
                </div>
            </div>
        );

        app.component(componentName, {
            bindings: {
                [nameof<fddRoyaltiesRatePlanMinimums>(o => o.readOnly)]: "<",
                [nameof<fddRoyaltiesRatePlanMinimums>(o => o.onInit)]: "&?",
                [nameof<fddRoyaltiesRatePlanMinimums>(o => o.onFindScaleChange)]: "&?",
            },
            template: template,
            controller: fddRoyaltiesRatePlanMinimums
        });
    }
}

