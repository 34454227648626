import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { RpmResources } from "Interfaces/FranForce/Rpm/RpmResources";

export class BatchManagementResendEmailsComponentController implements ng.IController 
{
    royaltyBatchIds: number[];
    royaltyPeriodReportIds: number[];

    sendEmailWindow: kendo.ui.Window;
    emailDraftDate: Date;

    isCorrectionMessage: boolean;
    correctionMessage: string;

    static $inject = [
        'coreApiResources',
        '$q',
        '$log',
        '$timeout',
        'rpmApiResources',
        'rpmUiApiResources',
        'rpmHelper'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private rpmApiResources: RpmApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService
    )
    {
    }

    $onInit()
    {

    }

    $postLink()
    {

    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {

    }

    SendEmailsWindowOpen()
    {
        this.emailDraftDate = null;
        this.correctionMessage = "";
        this.isCorrectionMessage = false;

        this.sendEmailWindow.open();
        this.$timeout(() =>
        {
            this.sendEmailWindow.center();
        })
    }

    SendPeriodReportEmails(periodReports: RpmResources.IRoyaltyPeriodReport[])
    {
        if (this.emailDraftDate == null) {
            alert("Specify a draft date");
            return;
        }

        let promises: ng.IPromise<any>[] = [];

        for (let batchId of this.royaltyBatchIds)
        {
            if (this.royaltyPeriodReportIds && this.royaltyPeriodReportIds.length) {
                for (let periodReportId of this.royaltyPeriodReportIds) {
                    promises.push(this.rpmUiApiResources.ResendRoyaltyReportBatchEmails(
                        {
                            RoyaltyBatchId: batchId,
                            DraftDate: <any>this.emailDraftDate,
                            RoyaltyPeriodReportID: periodReportId,
                            InCorrectionMessage: this.isCorrectionMessage ? this.correctionMessage : null,
                            InSendAsCorrection: this.isCorrectionMessage
                        }
                    ));
                }
            }
            else {
                promises.push(this.rpmUiApiResources.ResendRoyaltyReportBatchEmails({
                    RoyaltyBatchId: batchId,
                    DraftDate: <any>this.emailDraftDate,
                    RoyaltyPeriodReportID: null,
                    InCorrectionMessage: this.isCorrectionMessage ? this.correctionMessage : null,
                    InSendAsCorrection: this.isCorrectionMessage
                }));
            }
        }
        
        return this.$q.all(promises).then(() => {
            this.sendEmailWindow.close();
        }, (err) => {
            console.log("err", JSON.stringify(err));
            alert("Error occurred while sending emails for one or more batches.");
        });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('bmResendEmails', {
            bindings: {
                [nameof<BatchManagementResendEmailsComponentController>(o => o.royaltyBatchIds)]: "<",
                [nameof<BatchManagementResendEmailsComponentController>(o => o.royaltyPeriodReportIds)]: "<",
                //[nameof<BatchManagementResendEmailsComponentController>(o => o.disabled)]: "<"
            },
            controller: BatchManagementResendEmailsComponentController,
            templateUrl: "/Templates/BatchManagement/bmResendEmails.html"
        });
    }
}

