import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ApiConfig } from "AppConfig/ApiConfig";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { Helpers } from "Utility/Helpers";
import { Constants } from 'Constants';


interface IChangePasswordAccountScope
{

    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;


    //OnPasswordChangeClick: () => void;
    OnPasteOldPassword: (event: ng.IAngularEvent) => void;
}

export class ChangePasswordAccountController implements ng.IController
{

    localStorage: ILocalStorage;
    daysToExpiry: number;
    passwordExpirationDate: string;
    errorList: any;

    static $inject = [
        '$scope',
        'coreApiResources',
        '$http',
        '$timeout',
        'apiConfig',
        'identityManager',
        '$window',
        "$rootScope",
    ];

    constructor(
        private $scope: IChangePasswordAccountScope,
        private coreApiResources: CoreApiResources,
        private $http: ng.IHttpService,
        private $timeout: ng.ITimeoutService,
        private apiConfig: ApiConfig,
        private identityManager: IdentityManager,
        private $window: ng.IWindowService,
        private $rootScope: ng.IRootScopeService,
    )
    {
        $scope.OnPasteOldPassword = (event: ng.IAngularEvent) =>
        {
            $timeout(() =>
            {
                if ($scope.oldPassword)
                {
                    $scope.oldPassword = $scope.oldPassword.replace(/\s/g, "");
                }
            }, 0);
        }


    }


    async OnPasswordChangeClick()
    {
        let changePasswordData: IChangePasswordBindingModel = {
            OldPassword: this.$scope.oldPassword,
            NewPassword: this.$scope.newPassword,
            ConfirmPassword: this.$scope.confirmNewPassword
        };

        await this.identityManager.ChangePassword(changePasswordData).then(
            (response) =>
            {
                alert("Your password has successfully been reset! You will be forwarded to the login page...");
                this.$window.location.href = "/Account/Login";
            }).catch((err) =>
            {
                let error = '';
                this.errorList = err;
                this.$rootScope.$emit(Constants.PopupEvents.OpenPasswordErrorValidationPopupEvent, err);
                if (err?.data.Message === 'The request is invalid.')
                {

                    if (typeof err.data.ModelState == 'object')
                    {

                        let modelStateKeysArray = Object.keys(err.data.ModelState);
                        modelStateKeysArray.forEach((obj) =>
                        {
                            error = error + JSON.stringify(err.data.ModelState[obj][0]) + '\n';
                            console.log(error);
                        })
                    }
                } else
                {
                    error = err?.data.Message;
                }

            
                console.log(err);
            });
    }

    async $onInit()
    {
        this.localStorage = <ILocalStorage>localStorage;
        this.passwordExpirationDate = this.localStorage.PassWordExpirationDate;
    }

    async $postLink()
    {

        if (this.passwordExpirationDate)
        {
            this.daysToExpiry = Helpers.checkDaysToExpiry(this.passwordExpirationDate);
        }
    }
}


