import { CoreApiResources } from "Services/Resources/CoreApiResources";

interface IConceptRoyaltyScope extends ng.IScope {
    concepts: IConceptOption[];
    selectedConcept: string;
    selectedTab: string;   

    sectionTitle: string;
    sectionSpecificName: string;
}

export class ConceptRoyaltyController implements ng.IController {

    static $inject = ["$scope", "coreApiResources"];

    constructor(
        private $scope: IConceptRoyaltyScope,
        private coreApiResources: CoreApiResources)
    {
        $scope.selectedTab = 'general';
        $scope.sectionTitle = "Concept Royalty";
        $scope.sectionSpecificName = "CONCEPT";

        $scope.$on('selectionsMade', function (event, data) {
            // Collect Concept/License info from Title Directive
            // Broadcast paramters to children for updated datasource
            $scope.$broadcast('broadcastData', data);
        });
    }

    $onInit() {

    }
}