import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RestAxiosClient } from "Clients/RestAxiosClient";
import { IRestAxiosClient } from "Clients/IRestAxiosClient";

const royaltyContractRatePlanMinimumClient = new RestAxiosClient<RpmEntities.RoyaltyContractRatePlanMinimum, number>(
    FranForceAxiosClientBuilder.BuildCoreBaseClient(),
    "/RoyaltyContractRatePlanMinimums"
) as IRestAxiosClient<RpmEntities.RoyaltyContractRatePlanMinimum, number>;

export { royaltyContractRatePlanMinimumClient };