let crReportingPeriodControllerConfig = [
    '$scope', 'ngDialog',
    function ($scope, ngDialog)
    {

        // SAVE & CANCEL LOGIC
        $scope.$on('saveClicked', function (event, data)
        {
            debugger;
        });
        $scope.$on('cancelClicked', function (data)
        {
            debugger;
        });
    }
];

export { crReportingPeriodControllerConfig };
