import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";

interface INewUserScope {
    
    newUser: IManagedRegisterBindingModel;
    managedRoles: string[];
    roleSelectOptions: kendo.ui.MultiSelectOptions;

    CreateAccount: () => void;
}

export class NewUserController
{
    static $inject = [
        '$scope',
        '$window',
        'identityManager',
        'apiConfig'
    ];

    constructor(
        private $scope: INewUserScope,
        private $window: ng.IWindowService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
    )
    {
        identityManager.GetManagedRoles()
            .then((response: ng.IHttpPromiseCallbackArg<IRoleViewModel[]>) =>
            {
                $scope.managedRoles = response.data.map(r => r.Name);
                console.log(response.data);
                $scope.roleSelectOptions = {
                    placeholder: "Select roles...",
                    //dataTextField: "Name",
                    //dataValueField: "Id",
                    valuePrimitive: false,
                    autoBind: true,
                    dataSource: $scope.managedRoles
                };
            });


        $scope.CreateAccount = () =>
        {
            return identityManager.CreateManagedAccount($scope.newUser)
                .then((response: ng.IHttpPromiseCallbackArg<string>) =>
                {
                    $window.location.href = this.apiConfig.AppSettings.ApplicationRootUrl + "/Users/Edit/" + response.data + "/";
                })
                .catch((err: ng.IHttpPromiseCallbackArg<any>) =>
                {
                    console.error(err);
                    alert("Create user failed. " + err.data);
                });
        }
    }
}