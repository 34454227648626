import { AngularUtil } from "Utility/AngularUtil";

import { KendoUtil } from "Utility/KendoUtil";
import * as _ from "underscore"
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ConceptFranchiseSelectorOldComponentController } from "Directives/Common/ConceptFranchiseSelectorOld";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { BooleanProperties } from "Types/BooleanProperties";
import { Constants } from 'Constants';

export class FranchiseEmailsComponentController implements ng.IController 
{
    //-- Bindings --
    additionalFilters: kendo.data.DataSourceFilter;
    restrictFields: Partial<BooleanProperties<CoreResources.IFranchiseEmail>>;
    hideFields: string[];
    defaultModel: Partial<CoreResources.IFranchiseEmail>;
    preDelete: (params: { grid: kendo.ui.Grid, franchiseEmail: Partial<CoreResources.IFranchiseEmail> }) => void;
    //-- --

    isLoading: boolean;
    franchiseEmail: CoreResources.IFranchiseEmail;

    emailWindow: kendo.ui.Window;
    emailGrid: kendo.ui.Grid;
    processTypeDropDownOptions: kendo.ui.DropDownListOptions;
    gridQueryResponseHeaders: ODataQueryResponseHeaders;
    franchiseSelector: ConceptFranchiseSelectorOldComponentController;

    static $inject = [
        'coreApiResources',
        '$q',
        '$log',
        '$timeout',
        'odataHelper'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private odataHelper: ODataHelperService
    )
    {
    }

    $onInit()
    {
        if (!this.hideFields)
            this.hideFields = [];

        if (!this.defaultModel)
            this.defaultModel = {};

        if (!this.restrictFields)
            this.restrictFields = {};
    }

    $postLink()
    {
        this.InitProcessTypeDropDown();
        this.InitGrid();
        this.InitWindow();

        this.LoadGrid();
    }

    InitGrid()
    {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof<CoreResources.IFranchiseEmail>(o => o.FranchiseEmailId),
                title: "Id",
                width: 100
            }, {
                field: nameof.full<CoreResources.IFranchiseEmail>(o => o.EmailProcessType.Name),
                title: "Type",
                width: 100
            }, {
                field: nameof.full<CoreResources.IFranchiseEmail>(o => o.Franchise.Concept.ConceptCode),
                title: "Concept",
                width: 120
            }, {
                field: nameof.full<CoreResources.IFranchiseEmail>(o => o.Franchise.LicenseNumber),
                title: "Franchise"
            }, {
                field: nameof.full<CoreResources.IFranchiseEmail>(o => o.EmailAddress),
                title: "Email"
            }, {
                field: nameof.full<CoreResources.IFranchiseEmail>(o => o.Description),
                title: "Description"
            }, {
                field: nameof.full<CoreResources.IFranchiseEmail>(o => o.SendAsBCC),
                title: "BCC",
                width: 100
            }, {
                template: "<button class='pure-button edit-button' ng-click='$ctrl.EditPopup(dataItem)'>Edit</button>",
                width: 100
            }, {
                template: (dataItem: CoreResources.IFranchiseEmail) =>
                 `<button ng-disabled=${dataItem.EmailProcessTypeID === Constants.DSAREmailProcessTypeID}  class='pure-button button-error' promise-btn ng-click='$ctrl.DeleteFranchiseEmail(dataItem)'>X</button>`,
                width: 60
            }];

        if (this.hideFields)
        {
            for (let hideField of this.hideFields)
            {
                let foundCol = _.find(columns, c => c.field === hideField);
                if (foundCol) {
                    foundCol.hidden = true;
                }
            }
        }

        let dataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof<CoreResources.IFranchiseEmail>(o => o.FranchiseEmailId),
                    fields: {
                        FranchiseEmailId: { type: "number" },
                        "EmailProcessType.Name": { type: "string" },
                        "Franchise.Concept.ConceptCode": { type: "string" },
                        "Franchise.LicenseNumber": { type: "string" },
                        EmailAddress: { type: "string" },
                        Description: { type: "string" },
                        SendAsBCC: {type: "boolean"}
                    }
                },
                total: (data) =>
                {
                    return this.gridQueryResponseHeaders["odata-count"];
                }
            },
            pageSize: 25,
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) =>
                {
                    let filters = this.additionalFilters ?
                        {
                            logic: "and",
                            filters: [this.additionalFilters, options.data.filter]
                        } :
                        options.data.filter;

                    let params = this.odataHelper.ConvertKendoDataSourceTransportReadOptionsDataToParameterObject(options.data);
                    params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersToString(filters);
                    params.$count = true;
                    params.$expand = nameof<CoreResources.IFranchiseEmail>(o => o.Franchise) +
                        `($select=${nameof<CoreResources.IFranchiseEmail>(o => o.Franchise.Concept)},${nameof<CoreResources.IFranchiseEmail>(o => o.Franchise.LicenseNumber)};` +
                        `$expand=${nameof<CoreResources.IFranchiseEmail>(o => o.Franchise.Concept)}($select=${nameof<CoreResources.IFranchiseEmail>(o => o.Franchise.Concept.ConceptCode)})),` +
                        `${nameof<CoreResources.IFranchiseEmail>(o => o.EmailProcessType)}($select=${nameof<CoreResources.IFranchiseEmail>(o => o.EmailProcessType.Name)})`;

                    this.coreApiResources.FranchiseEmailApi.query(params, (data, responseHeaders) => { this.gridQueryResponseHeaders = responseHeaders(); }).$promise
                        .then((franchiseEmails) =>
                        {
                            options.success(franchiseEmails);
                        }, (err) => { options.error(err); });
                }
            },
            sort: { field: nameof<CoreResources.IFranchiseEmail>(o => o.FranchiseEmailId), dir: "desc" }
        });

        let options: kendo.ui.GridOptions = {
            autoBind: false,
            columns: columns,
            dataSource: dataSource,
            toolbar: <kendo.ui.GridToolbarItem[]>[{
                    template: "<button class='pure-button' ng-click='$ctrl.CreatePopup()'>Create New...</button>"
            }],
            pageable: {
                numeric: true,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: false
            },
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true
            },
            sortable: true,
            resizable: true
        }

        this.emailGrid.setOptions(options);
    }

    InitWindow()
    {
        let options: kendo.ui.WindowOptions = {
            modal: true
        };
        this.emailWindow.setOptions(options);
    }

    InitProcessTypeDropDown()
    {
        this.processTypeDropDownOptions = {
            autoBind: true,
            enable: !this.restrictFields.EmailProcessTypeID,
            dataTextField: nameof<CoreResources.IEmailProcessType>(o => o.Name),
            dataValueField: nameof<CoreResources.IEmailProcessType>(o => o.EmailProcessTypeID),
            valuePrimitive: true,
            optionLabel: "Select Type...",
            //valueTemplate: "#= Name # - #= Description #",
            template: "#= Name # - #= Description #",
            dataSource: new kendo.data.DataSource({
                schema: {
                    model: {
                        id: nameof<CoreResources.IEmailProcessType>(o => o.EmailProcessTypeID),
                        fields: {
                            EmailProcessTypeID: { type: "number" },
                            Name: { type: "string" },
                            Description: { type: "string" }
                        }
                    }
                },
                transport: {
                    read: (options) =>
                    {
                        this.coreApiResources.EmailProcessTypeApi.query({}).$promise.then((processTypes) =>
                        {
                            options.success(processTypes);
                        }, (err) => { options.error(err); });
                    }
                }
            })
        };
    }

    LoadGrid()
    {
        this.isLoading = true;
        return this.$q.when(this.emailGrid.dataSource.read())
            .then(() =>
            {
                this.emailGrid.refresh();
            })
            .finally(() => { this.isLoading = false; });
    }

    EditPopup(franchiseEmail: CoreResources.IFranchiseEmail)
    {
        this.franchiseEmail = franchiseEmail;
        this.OpenWindow();
    }

    CreatePopup()
    {
        let email: Partial<CoreResources.IFranchiseEmail> = angular.merge({}, this.defaultModel, {
            FranchiseEmailId: 0
        });
        this.franchiseEmail = <CoreResources.IFranchiseEmail>email;
        this.OpenWindow();
    }

    OpenWindow()
    {
        this.emailWindow.open();
        this.$timeout(() =>
        {
            this.emailWindow.center();
            this.franchiseSelector.MakeInitialSelections();
        }, 0);
    }

    DeleteFranchiseEmail(franchiseEmail: CoreResources.IFranchiseEmail)
    {
        if (this.preDelete) {
            this.preDelete({ grid: this.emailGrid, franchiseEmail: franchiseEmail });
        }

        if (confirm(`Do you want to delete the ${franchiseEmail.EmailProcessType.Name} email of '${franchiseEmail.EmailAddress}'?`))
        {
            let deleteParams = {
                id: franchiseEmail.FranchiseEmailId,
                confirmHardDelete: true
            };
            return this.coreApiResources.FranchiseEmailApi.delete(deleteParams).$promise
                .then(() =>
                {
                    return this.LoadGrid();
                });
        }
    }

    SaveEmail()
    {
        if (!this.franchiseEmail.EmailAddress)
        {
            alert("Email is required");
            return;
        }
        if (!this.franchiseEmail.Description)
        {
            alert("Description is required");
            return;
        }

        let copiedEmail = angular.copy(this.franchiseEmail);
        copiedEmail.Franchise = null;
        copiedEmail.EmailProcessType = null;
        let savePromise: ng.IPromise<CoreResources.IFranchiseEmail>;

        if (this.franchiseEmail.FranchiseEmailId) {
            savePromise = this.coreApiResources.FranchiseEmailApi
                .update({ id: copiedEmail.FranchiseEmailId }, copiedEmail)
                .$promise;
        }
        else {
            savePromise = this.coreApiResources.FranchiseEmailApi
                .save({ id: copiedEmail.FranchiseEmailId }, copiedEmail)
                .$promise;
        }

        return savePromise
            .then(() =>
            {
                this.emailWindow.close();
                this.LoadGrid();
            },
            AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback(this.$log, this.$q));
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseEmails', {
            bindings: {
                additionalFilters: '<',
                restrictFields: "<",
                defaultModel: "<",
                hideFields: "<",
                preDelete: "<"
            },
            controller: FranchiseEmailsComponentController,
            templateUrl: "/Templates/FranchiseEmails/FranchiseEmails.html"
        });

    }
}

