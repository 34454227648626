import { RoyaltyApiResources } from "../../Services/Resources/RoyaltyApiResources";
import { contractTerritoryTypeDataModel } from "../../Models/Royalty/ContractTerritoryType/contractTerritoryTypeDataModel";
import { ExpressionBinding } from "../../Utility/AngularUtil";

export type ContractTerritoryTypeParams = {
    event: kendo.ui.DropDownListSelectEvent,
    contractTerritoryType: contractTerritoryTypeDataModel;
}



export class ContractTerritoryTypeSelectorComponentController {

    contractTerritoryTypeId: number;
    readOnly: boolean;  
    onSelect: ExpressionBinding<ContractTerritoryTypeParams>;

    contractTerritoryDropDown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    refresh: boolean;

    static $inject = [
        'royaltyApiResources'
    ]

    constructor(
        private royaltyApiResources: RoyaltyApiResources
    ) {

    }

    $onInit() {
        this.options = {
            valuePrimitive: true,
            optionLabel: "Select ContractTerritoryType...",
            optionLabelTemplate: "Select ContractTerritoryType...",
            dataTextField: nameof<contractTerritoryTypeDataModel>(o => o.name),
            dataValueField: nameof<contractTerritoryTypeDataModel>(o => o.contractTerritoryTypeId),
            enable: !this.readOnly,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        this.royaltyApiResources.GetContractTerritoryType()
                            .then((typesResponse) => {
                                options.success(typesResponse.data);
                            })
                            .catch((err) => {
                                options.error(err);
                            });
                    }
                }
            }),
            select: (e) => {
                if (this.onSelect) {
                    this.onSelect({
                        event: e,
                        contractTerritoryType: e.dataItem
                    });
                }
            }
        };
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.readOnly)] && this.contractTerritoryDropDown) {
            this.contractTerritoryDropDown.setOptions({ enable: !this.readOnly })
        }
       
        if (changes[nameof(this.refresh)] && this.contractTerritoryDropDown) {
            this.contractTerritoryDropDown.value(null)
            this.contractTerritoryDropDown.refresh()
        }
    }

    static BindComponent(app: ng.IModule) {
        app
            .component("contractTerritoryTypeSelector", {
                bindings: {
                    [nameof<ContractTerritoryTypeSelectorComponentController>(o => o.contractTerritoryTypeId)]: "<",
                    [nameof<ContractTerritoryTypeSelectorComponentController>(o => o.readOnly)]: "<",
                    [nameof<ContractTerritoryTypeSelectorComponentController>(o => o.onSelect)]: "&?",
                    [nameof<ContractTerritoryTypeSelectorComponentController>(o => o.refresh)]: "<",
                },
                template: `<select kendo-drop-down-list="$ctrl.${nameof<ContractTerritoryTypeSelectorComponentController>(o => o.contractTerritoryDropDown)}"
                                   ng-model="$ctrl.${nameof<ContractTerritoryTypeSelectorComponentController>(o => o.contractTerritoryTypeId)}"
                                    k-options="$ctrl.${nameof<ContractTerritoryTypeSelectorComponentController>(o => o.options)}">
                        </select>`,
                controller: ContractTerritoryTypeSelectorComponentController
            });
    }

}