import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { ApiConfig } from "../../../AppConfig/ApiConfig";

export class FranchiseTaxReturnAnalysesRpmUiClient
{
    client = FranForceAxiosClientBuilder.BuildCoreBaseClient();

    static $inject = [
        "$http",
        "apiConfig"
    ];

    constructor(
        private $http: ng.IHttpService,
        private apiConfig: ApiConfig
    ) {

    }


    async GetFranchiseTaxReturnAnalysisYears(franchiseId: number | string, filterRecentYears: boolean)
    {
        return this.client.get<RpmEntities.usp_RoyaltyContracts_GetYearsOpen_Result[]>(
            `RpmUi/FranchiseTaxReturnAnalyses/YearsByFranchise/${franchiseId}`, {
            params: {
                [nameof(filterRecentYears)]: filterRecentYears
            }
        });
    }

    async CreateFranchiseTaxReturnAnalysis(model: RpmEntities.usp_FranchiseTaxReturnAnalysis_Upsert_Input)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/RpmUi/FranchiseTaxReturnAnalyses`,
            data: model
        };
        return this.$http<number>(config);
    }

    async UpdateFranchiseTaxReturnAnalysis(id: number, model: RpmEntities.usp_FranchiseTaxReturnAnalysis_Upsert_Input)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/RpmUi/FranchiseTaxReturnAnalyses/${id}`,
            data: model
        };
        return this.$http<number>(config);
    }

    async DeleteFranchiseTaxReturnAnalysis(id: number)
    {
        return this.client.delete<any>(`RpmUi/FranchiseTaxReturnAnalyses/${id}`);
    }
}
    
