

export class FeeTypeComponentController implements ng.IController
{
    //---- Bindings ----
    feeType: IFeeType;
    onDelete: ({feeType: IFeeType}) => ng.IPromise<any>;
    onSave: ({feeType: IFeeType}) => ng.IPromise<any>;
    saveText: string;
    isDeletable: boolean;
    isReadOnlyName: boolean;

    //Remove - Temporary Fee Type Fix
    hasAfterHours: boolean;
    //------------------

    feeOptions: kendo.ui.NumericTextBoxOptions;

    static $inject = ["$element"];
    
    constructor(
        private $element: ng.IRootElementService
    )
    {

    }

    $onInit()
    {
        this.feeOptions = {
            format: "c",
            decimals: 2,
            step: 1,
            placeholder: "Fee"
        };

        if (!this.saveText) {
            this.saveText = "Save";
        }
        
        if (angular.isUndefined(this.isDeletable))
            this.isDeletable = true;

        if (angular.isUndefined(this.hasAfterHours))
            this.hasAfterHours = true;

        if (!this.isReadOnlyName)
            this.isReadOnlyName = false;
    }

    ShowDelete(): boolean
    {
        return (typeof this.onDelete === "function" && this.isDeletable);
    }

    Save(): ng.IPromise<any>
    {
        return this.onSave({ feeType: this.feeType });
    }

    Delete(): ng.IPromise<any>
    {
        return this.onDelete({ feeType: this.feeType });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("feeType", {
            bindings: {
                feeType: "=",
                onDelete: "&?",
                onSave: "&",
                saveText: "@",
                isDeletable: "<?",
                isReadOnlyName: "<",
                //Remove - Temporary Fee Type Fix
                hasAfterHours: "<?",
            },
            controller: FeeTypeComponentController,
            templateUrl: "/Templates/Common/FeeType.html",
        });
    }
}

