import { CoreResources } from "../../../../Interfaces/FranForce/Core/CoreResources";
import { AxiosInstance } from "axios";
import {
    RoyaltyApprovalClient,
    RoyaltyUserApprovalConfigurationClient,
    RoyaltyUserApprovalConfigurationConceptsVm,
    FranchisorPeriodEndDateFrequencyVm,
    ApprovalRoyaltyReportBatchesRm,
    ApprovalRoyaltyReportBatchesVm
} from '@nbly/royalty-orchestrations-clients';
import { IdentityManager } from "../../../../Services/Resources/IdentityManager";
import { isArray } from "underscore";
import { FranForceAxiosClientBuilder } from "../../../../Clients/FranForceAxiosClientBuilder";

export class FranchisorPeriodEndDateFrequencyDropdownItem {
    franchisorPeriodEndDateFrequencyDropdownValue: FranchisorPeriodEndDateFrequencyVm;
    franchisorPeriodEndDateFrequencyDropdownLabel: string;
}
export class BatchDropdownItem {
    batchDropdownValue: ApprovalRoyaltyReportBatchesVm;
    batchDropdownLabel: string;
}

export class rmsPreApprovalQueueInputComponentController implements ng.IController {

    //bindings
    showLoader: (params: { isLoading: boolean }) => void;
    //end-bindings

    isAdmin: boolean;
    conceptId: number;
    franchisorId: number;
    periodEndDate: string;
    royaltyPeriodFrequencyId: number;
    royaltyReportBatchId: number;
    royaltyApprovalStatusTypeId: number;
    royaltyApprovalStatus: string;
    royaltyApprovalRequiredReasonTypeId: number;
    royaltyApprovalRequiredReasonType: string;

    concept: Partial<CoreResources.IConcept>;
    conceptFilters: kendo.data.DataSourceFilter;
    conceptFiltersByRole: kendo.data.DataSourceFilter[];
    franchisorFilters: kendo.data.DataSourceFilter;
    franchisorPeriodEndDateFrequencyDropdown: kendo.ui.DropDownList;
    batchDropdown: kendo.ui.DropDownList;
    royaltyOrchestrationsClient: AxiosInstance;
    royaltyUserApprovalConfigurationClient: RoyaltyUserApprovalConfigurationClient;
    royaltyApprovalClient: RoyaltyApprovalClient

    public readonly RoyaltySystems = {
        Rms: 1,
        Pivotal: 2,
        RmsManual: 3
    }

    static $inject = [
        '$window',
        '$location',
        '$q',
        '$scope',
        "$rootScope",
        "identityManager",
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private $scope: ng.IScope,
        private $rootScope: ng.IRootScopeService,
        private identityManager: IdentityManager,
    ) {
        this.royaltyOrchestrationsClient = FranForceAxiosClientBuilder.BuildRoyaltyOrchestrationsBaseClient();
        this.royaltyUserApprovalConfigurationClient = new RoyaltyUserApprovalConfigurationClient("", this.royaltyOrchestrationsClient);
        this.royaltyApprovalClient = new RoyaltyApprovalClient("", this.royaltyOrchestrationsClient);
    }

    async $onInit() {
        this.showLoader({ isLoading: false });
        await this.SetConceptFilersByRole();
        this.GetPreApprovalQueueConceptFilters();
        this.$scope.$digest();
        this.InitFranchisorPeriodEndDateFrequencyDropDown();
        this.InitBatchDropdown();
       
        this.$rootScope.$on('reassignBatchApprovalStatus', async (event, args) => {
            if(args)
                this.royaltyApprovalStatusTypeId = args.royaltyApprovalStatusTypeId;

            await this.LoadBatchDropdownData();

            let royaltyApprovalStatusType = this.GetRoyaltyApprovalStatusTypeText();
            var batchIdLabel = this.royaltyReportBatchId + " - " + royaltyApprovalStatusType;
            var batchDropdown = $("#pre_approval_batch_dropDown").data("kendoDropDownList");
            batchDropdown.select(function (dataItem) {
                return dataItem.batchDropdownLabel === batchIdLabel;
            });
            batchDropdown.trigger("change");

            this.$rootScope.$emit('preApprovalAllInputsSelected', {
                royaltyReportBatchId: this.royaltyReportBatchId,
                royaltyApprovalStatusTypeId: this.royaltyApprovalStatusTypeId,
                royaltyApprovalStatus: this.royaltyApprovalStatus,
                royaltyApprovalRequiredReasonTypeId: this.royaltyApprovalRequiredReasonTypeId,
                royaltyApprovalRequiredReasonType: this.royaltyApprovalRequiredReasonType
            });

            this.$rootScope.$emit('preApprovalRejectInvoiceChanged', {
                selectedInvoicesForRejection: []
            });
        });
    }

    GetRoyaltyApprovalStatusTypeText() {
        let royaltyApprovalStatusType = "";
        switch (this.royaltyApprovalStatusTypeId) {
            case 1:
                royaltyApprovalStatusType = "N/A"
                break;
            case 2:
                royaltyApprovalStatusType = "Needs Approval"
                break;
            case 3:
                royaltyApprovalStatusType = "Approved"
                break;
            case 4:
                royaltyApprovalStatusType = "Rejected"
                break;
        }
        return royaltyApprovalStatusType;
    }

    InitFranchisorPeriodEndDateFrequencyDropDown() {
        this.franchisorPeriodEndDateFrequencyDropdown.setOptions(
            {
                autoBind: true,
                valuePrimitive: true,
                dataValueField: nameof<FranchisorPeriodEndDateFrequencyDropdownItem>(o => o.franchisorPeriodEndDateFrequencyDropdownValue),
                dataTextField: nameof<FranchisorPeriodEndDateFrequencyDropdownItem>(o => o.franchisorPeriodEndDateFrequencyDropdownLabel),
                optionLabel: "Select Period End Date - Frequency",
                optionLabelTemplate: "Select Period End Date - Frequency",
                autoWidth: true,
                filter: "contains",
                select: async (e) => {

                    this.$rootScope.$emit('preApprovalAllInputsSelected', {
                        royaltyReportBatchId: null,
                        royaltyApprovalStatusTypeId: null,
                        royaltyApprovalStatus: null,
                        royaltyApprovalRequiredReasonTypeId: null,
                        royaltyApprovalRequiredReasonType: null
                    });

                    this.$rootScope.$emit('preApprovalRejectInvoiceChanged', {
                        selectedInvoicesForRejection: []
                    });

                    this.$rootScope.$emit('refreshPreApprovalGrid', null);

                    if (e?.dataItem?.franchisorPeriodEndDateFrequencyDropdownValue == "") {
                        this.batchDropdown.value(null);
                        this.batchDropdown?.dataSource?.data([]);

                        return;
                    }

                    this.batchDropdown.value(null);
                    this.batchDropdown?.dataSource?.data([]);
                    this.royaltyApprovalStatusTypeId = null;
                    this.royaltyReportBatchId = null;

                    this.periodEndDate = this.GetISOStringFromDate(new Date(e?.dataItem?.franchisorPeriodEndDateFrequencyDropdownValue?.periodEndDate));
                    this.royaltyPeriodFrequencyId = e?.dataItem?.franchisorPeriodEndDateFrequencyDropdownValue?.royaltyPeriodFrequencyId;

                    await this.LoadBatchDropdownData();
                }
            } as kendo.ui.DropDownListOptions
        );

        let franchisorPeriodEndDateFrequencyDropdownDataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    options.success([]);
                }
            }
        });

        this.franchisorPeriodEndDateFrequencyDropdown.setDataSource(franchisorPeriodEndDateFrequencyDropdownDataSource);
    }

    async LoadBatchDropdownData() {

        let approvalRoyaltyReportBatchesRequestData: ApprovalRoyaltyReportBatchesRm = {
            franchisorId: this.franchisorId,
            periodEndDate: this.periodEndDate,
            royaltyPeriodFrequencyId: this.royaltyPeriodFrequencyId
        }

        await this.royaltyApprovalClient.approvalRoyaltyReportBatches(approvalRoyaltyReportBatchesRequestData)
            .then(response => {

                let approvalRoyaltyReportBatches: ApprovalRoyaltyReportBatchesVm[] = response.result;

                let batchDropdownDataSource = new kendo.data.DataSource({
                    transport: {
                        read: (options) => {

                            let batchDropdownItems: BatchDropdownItem[] = []
                            approvalRoyaltyReportBatches.forEach(x => {

                                let batchDropdownValueObj: ApprovalRoyaltyReportBatchesVm = {
                                    royaltyReportBatchId: x.royaltyReportBatchId,
                                    royaltyApprovalStatusTypeId: x.royaltyApprovalStatusTypeId,
                                    royaltyApprovalStatus: x.royaltyApprovalStatus,
                                    royaltyApprovalRequiredReasonTypeId: x.royaltyApprovalRequiredReasonTypeId,
                                    royaltyApprovalRequiredReasonType: x.royaltyApprovalRequiredReasonType
                                }

                                batchDropdownItems.push({
                                    batchDropdownValue: batchDropdownValueObj,
                                    batchDropdownLabel: x.royaltyReportBatchId + " - " + x.royaltyApprovalStatus
                                })
                            });

                            options.success(batchDropdownItems);
                        }
                    }
                });
                this.batchDropdown.setDataSource(batchDropdownDataSource);
            })
            .catch(ex => {
                this.ShowRmsNotification("error", "Error! Unable To Load Batches");
            })
            .finally(() => {

            });

        let approvalRoyaltyReportBatches = await this.royaltyApprovalClient.approvalRoyaltyReportBatches(approvalRoyaltyReportBatchesRequestData);

    }

    InitBatchDropdown() {
        this.batchDropdown.setOptions(
            {
                autoBind: true,
                valuePrimitive: true,
                dataValueField: nameof<BatchDropdownItem>(o => o.batchDropdownValue),
                dataTextField: nameof<BatchDropdownItem>(o => o.batchDropdownLabel),
                optionLabel: "Select Batch Id",
                optionLabelTemplate: "Select Batch Id",
                autoWidth: true,
                filter: "contains",
                select: (e) => {

                    if (e?.dataItem?.batchDropdownValue == "") {
                        this.$rootScope.$emit('preApprovalAllInputsSelected', {
                            royaltyReportBatchId: null,
                            royaltyApprovalStatusTypeId: null,
                            royaltyApprovalStatus: null,
                            royaltyApprovalRequiredReasonTypeId: null,
                            royaltyApprovalRequiredReasonType: null
                        });
                        this.$rootScope.$emit('refreshPreApprovalGrid', null);
                        this.$rootScope.$emit('preApprovalRejectInvoiceChanged', {
                            selectedInvoicesForRejection: []
                        });
                        return;
                    }

                    this.royaltyReportBatchId = e?.dataItem?.batchDropdownValue.royaltyReportBatchId;
                    this.royaltyApprovalStatusTypeId = e?.dataItem?.batchDropdownValue.royaltyApprovalStatusTypeId;
                    this.royaltyApprovalStatus = e?.dataItem?.batchDropdownValue.royaltyApprovalStatus;
                    this.royaltyApprovalRequiredReasonTypeId = e?.dataItem?.batchDropdownValue.royaltyApprovalRequiredReasonTypeId;
                    this.royaltyApprovalRequiredReasonType = e?.dataItem?.batchDropdownValue.royaltyApprovalRequiredReasonType;

                    this.$rootScope.$emit('preApprovalAllInputsSelected', {
                        royaltyReportBatchId: this.royaltyReportBatchId,
                        royaltyApprovalStatusTypeId: this.royaltyApprovalStatusTypeId,
                        royaltyApprovalStatus: this.royaltyApprovalStatus,
                        royaltyApprovalRequiredReasonTypeId: this.royaltyApprovalRequiredReasonTypeId,
                        royaltyApprovalRequiredReasonType: this.royaltyApprovalRequiredReasonType
                    });

                    this.$rootScope.$emit('preApprovalRejectInvoiceChanged', {
                        selectedInvoicesForRejection: []
                    });
                }
            } as kendo.ui.DropDownListOptions
        );

        let batchDropdownDataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    options.success([]);
                }
            }
        });

        this.batchDropdown.setDataSource(batchDropdownDataSource);
    }

    GetISOStringFromDate(date: Date) {
        let yearVal = date.getFullYear()
        let monthVal = ('0' + (date.getMonth() + 1)).slice(-2)
        let dateVal = ('0' + date.getDate()).slice(-2)
        let preiodEndDateISOString = yearVal + "-" + monthVal + "-" + dateVal + "T00:00:00.000Z";
        return preiodEndDateISOString;
    }

    OnConceptChange(concept) {
        this.conceptId = concept.ConceptId;

        if (this.franchisorPeriodEndDateFrequencyDropdown) {
            this.franchisorPeriodEndDateFrequencyDropdown.value(null);
            this.franchisorPeriodEndDateFrequencyDropdown?.dataSource?.data([]);
        }

        if (this.batchDropdown && this.batchDropdown.dataSource) {
            this.batchDropdown.value(null);
            this.batchDropdown?.dataSource?.data([]);
        }
        this.franchisorId = null;
        this.periodEndDate = null;
        this.royaltyApprovalStatusTypeId = null;
        this.royaltyReportBatchId = null;
        this.royaltyPeriodFrequencyId = null;
        this.$rootScope.$emit('refreshPreApprovalGrid', null);
        this.$rootScope.$emit('preApprovalAllInputsSelected', {
            royaltyReportBatchId: null,
            royaltyApprovalStatusTypeId: null,
            royaltyApprovalStatus: null,
            royaltyApprovalRequiredReasonTypeId: null,
            royaltyApprovalRequiredReasonType: null
        });
        this.$rootScope.$emit('preApprovalRejectInvoiceChanged', {
            selectedInvoicesForRejection: []
        });
    }

    async OnFranchisorChange(franchisor) {

        this.franchisorId = franchisor.FranchisorId;
        if (this.franchisorPeriodEndDateFrequencyDropdown && this.franchisorPeriodEndDateFrequencyDropdown.dataSource) {
            this.franchisorPeriodEndDateFrequencyDropdown.value(null);
            this.franchisorPeriodEndDateFrequencyDropdown?.dataSource?.data([]);
        }
        if (this.batchDropdown && this.batchDropdown.dataSource) {
            this.batchDropdown.value(null);
            this.batchDropdown?.dataSource?.data([]);
        }
        this.periodEndDate = null;
        this.royaltyApprovalStatusTypeId = null;
        this.royaltyReportBatchId = null;
        this.royaltyPeriodFrequencyId = null;
        this.$rootScope.$emit('refreshPreApprovalGrid', null);
        this.$rootScope.$emit('preApprovalAllInputsSelected', {
            royaltyReportBatchId: null,
            royaltyApprovalStatusTypeId: null,
            royaltyApprovalStatus: null,
            royaltyApprovalRequiredReasonTypeId: null,
            royaltyApprovalRequiredReasonType: null
        });
        this.$rootScope.$emit('preApprovalRejectInvoiceChanged', {
            selectedInvoicesForRejection: []
        });

        await this.royaltyApprovalClient.franchisor(franchisor.FranchisorId)
            .then(response => {
                let franchisorPeriodEndDateFrequencies: FranchisorPeriodEndDateFrequencyVm[] = response.result;
                let franchisorPeriodEndDateFrequencyDropdownDataSource = new kendo.data.DataSource({
                    transport: {
                        read: (options) => {

                            let franchisorPeriodEndDateFrequencyDropdownItems: FranchisorPeriodEndDateFrequencyDropdownItem[] = []
                            franchisorPeriodEndDateFrequencies.forEach(x => {

                                let date = new Date(x.periodEndDate);

                                franchisorPeriodEndDateFrequencyDropdownItems.push({
                                    franchisorPeriodEndDateFrequencyDropdownValue: x,
                                    franchisorPeriodEndDateFrequencyDropdownLabel: (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()) + " - " + x.royaltyPeriodFrequency
                                })
                            })

                            options.success(franchisorPeriodEndDateFrequencyDropdownItems);
                        }
                    }
                });

                this.franchisorPeriodEndDateFrequencyDropdown.setDataSource(franchisorPeriodEndDateFrequencyDropdownDataSource);
            })
            .catch(ex => {
                this.ShowRmsNotification("error", "Error! Unable To Load Period End Date - Frequency");
            })
            .finally(() => {
            });
    }

    ShowRmsNotification(type: "error" | "success", message: string) {
        let elementId = "rms-notification-element";
        let notificationElement = this.$window.document.getElementById(elementId);
        if (!notificationElement) {
            notificationElement = this.$window.document.createElement("div");
            notificationElement.id = elementId;
            this.$window.document.body.appendChild(notificationElement);
        }

        let notification = angular.element(notificationElement).kendoNotification({
            autoHideAfter: 4000,
            position: {
                pinned: true,
                top: 60,
                right: 0,
            },
            animation: {
                open: {
                    effects: "slideIn:left"
                },
                close: {
                    effects: "slideIn:left",
                    reverse: true
                }
            },
            stacking: "down",
        }).data("kendoNotification");

        notification.show(message, type);
    }

    async checkIfAdminUser() {
        this.showLoader({ isLoading: true });

        this.identityManager.GetLoggedInUserInfo()
            .then(response => {
                this.showLoader({ isLoading: false });
                if (response && response.data && response.data.Roles && response.data.Roles.length > 0) {

                    response.data.Roles.map(x => {

                        if (x.Name.trim().toLowerCase() == ("Admin").trim().toLowerCase() || x.Name.trim().toLowerCase() == ("RMSApprovalQueueAdmin").trim().toLowerCase()) {
                            this.isAdmin = true;
                            return;
                        }
                    });
                }
            })
            .catch(ex => {
                this.showLoader({ isLoading: false });
                alert("Error! Unable to get user roles.");
            })
            .finally(() => {
                this.$scope.$digest();
            });
    }

    async SetConceptFilersByRole() {
        this.conceptFiltersByRole = [];

        this.showLoader({ isLoading: true });
        await this.checkIfAdminUser();
        this.showLoader({ isLoading: false });

        this.showLoader({ isLoading: true });
        let user = await this.identityManager.GetLoggedInUserInfo();
        this.showLoader({ isLoading: false });

        let userEmail = (user?.data?.Email == undefined || null) ? '' : user?.data?.Email;

        if (!this.isAdmin) {

            let userConcepts: RoyaltyUserApprovalConfigurationConceptsVm[] = [];

            this.showLoader({ isLoading: true });
            let userConceptsResult = await this.royaltyUserApprovalConfigurationClient.userConcepts(userEmail);
            this.showLoader({ isLoading: false });

            if (userConceptsResult && userConceptsResult.result && isArray(userConceptsResult.result)) {
                userConcepts = userConceptsResult.result;
                userConcepts.forEach(x => {
                    this.conceptFiltersByRole.push({ field: nameof<CoreResources.IConcept>(o => o.ConceptId), operator: "eq", value: x.conceptId })
                })
            }
        }

        this.$scope.$digest();
    }

    async GetPreApprovalQueueConceptFilters() {

        let result =  <kendo.data.DataSourceFilters>{
            logic: "and",
            filters: [
                ...this.conceptFiltersByRole,
                {
                    logic: "or",
                    filters: [
                        { field: nameof<CoreResources.IConcept>(o => o.RoyaltySystemId), operator: "eq", value: 1 },
                        { field: nameof<CoreResources.IConcept>(o => o.RoyaltySystemId), operator: "eq", value: 3 },
                    ]
                }
            ]
        }

        this.conceptFilters = result;
        this.$scope.$digest();
    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsPreApprovalQueueInput", {
            bindings: {
                [nameof<rmsPreApprovalQueueInputComponentController>(o => o.showLoader)]: "&",
            },
            templateUrl: '/Templates/RMSApproval/RmsPreApprovalQueue/rmsPreApprovalQueueInput.html',
            controller: rmsPreApprovalQueueInputComponentController
        });
    }
}