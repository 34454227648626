import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";

import { FranchiseLookUpClient, FranchiseLookUpDto } from '@nbly/engagement-services-clients'
import { Constants } from 'Constants/Index';


export class ConceptFranchiseLookupSearchComponentController implements ng.IController {
    //---- Bindings ----
    conceptId: number;
    //------------------

    address: string;
    isLoading: boolean;
    lookupGrid: kendo.ui.Grid;
    user: IUserInfoViewModel;
    static $inject = [
        "$q"
    ];

    //Clients
    engagementClient: AxiosInstance;

    lookUpClient: FranchiseLookUpClient;


    constructor(
        private $q: ng.IQService
    ) {

        this.engagementClient = FranForceAxiosClientBuilder.BuildEngagementServicesBaseClient();
        this.lookUpClient = new FranchiseLookUpClient("", this.engagementClient);
    }

    $onInit() {

    }

    $postLink() {
        this.InitGrid();
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.conceptId)] && this.conceptId) {
            if (this.lookupGrid) {
                this.lookupGrid.dataSource.data([]);
            }
        }
    
    }

    InitGrid() {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof<FranchiseLookUpDto>(o => o.licenseNumber),
                title: "License Number"
            }, {
                field: nameof<FranchiseLookUpDto>(o => o.doingBusinessAs),
                title: "DBA"
            }, {
                field: nameof<FranchiseLookUpDto>(o => o.franchiseeName),
                title: "Franchisee"
            }, {
                field: nameof<FranchiseLookUpDto>(o => o.franchiseId),
                title: "Franchise Id"
            }, {
                field: nameof<FranchiseLookUpDto>(o => o.franchiseTerritoryTypeID),
                title: "Territory"
            },
        ]

        let datasource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    this.isLoading = true;

                    this.lookUpClient.franchiseLookUp(this.address, this.conceptId).then((response)=>{


                        let newArr = response.result.map((item) => {
                            let obj = {...item};
                            obj.franchiseTerritoryTypeID = Constants.FranchiseTerritoryTypesConstants[item.franchiseTerritoryTypeID];
                            return obj

                        })

                        console.log("new arr",response.result, newArr)

                        options.success(newArr);
                    }).finally(() => {
                            this.isLoading = false;
                        });
                }
            }
        });

        let options: kendo.ui.GridOptions = {
            columns: columns,
            dataSource: datasource,
            autoBind: false
        };
        this.lookupGrid.setOptions(options);
    }

    Search() {
        return this.$q.when(this.lookupGrid.dataSource.read())
            .then(() => {
                this.lookupGrid.refresh();
            });
    }

    static BindComponent(app: ng.IModule) {
        app.component("conceptFranchiseLookupSearch", {
            bindings: {

            },
            controller: ConceptFranchiseLookupSearchComponentController,
            templateUrl: "/Templates/Concepts/ConceptFranchiseLookupSearch.html",
        });
    }
}

