import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyReportBatchesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyReportBatchById(id: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyReportBatches/" + id
        };

        return this.$http<RpmEntities.usp_RoyaltyReportBatchs_GetByRoyaltyReportBatchId_Result>(config);
    }

    GetRoyaltyReportBatchesByPeriodEndDate(periodEndDate: string|Date)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyReportBatches/ByPeriodEndDate/" + periodEndDate
        };

        return this.$http<RpmEntities.RoyaltyReportBatchViewModel[]>(config);
    }

    GetRoyaltyReportBatchInvoices(royaltyReportBatchIds: number[])
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + `/RoyaltyReportBatches/Invoices`,
            params: {
                royaltyReportBatchIds: royaltyReportBatchIds
            }
        };

        return this.$http<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result[]>(config);
    }

    GetRoyaltyReportBatchInvoicesAdjustmentsOnly(periodReportAdjustmentIds: number[])
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + `/RoyaltyReportBatches/Invoices/AdjustmentsOnly`,
            params: {
                periodReportAdjustmentIds: periodReportAdjustmentIds
            }
        };

        return this.$http<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_AdjustmentsOnly_Result[]>(config);
    }

    CreateRoyaltyReportBatchesFromSyncInvoices(model: RpmEntities.usp_RPM_CreateRoyaltyBatchesFromAvailableSyncInvoices_Input)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyReportBatches/CreateFromSyncInvoices",
            data: model
        };

        return this.$http<void>(config);
    }

    CreateRoyaltyReportBatchesFromSyncInvoicesDynamic(model: RpmEntities.usp_RPM_CreateRoyaltyBatchesFromAvailableSyncInvoices_Input) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyReportBatches/CreateFromSyncInvoices/Dynamic",
            data: model
        };

        return this.$http<void>(config);
    }

    ResendRoyaltyReportBatchEmails(model: RpmEntities.usp_RMS_SendRoyaltyPeriodReportSummaryEmail_Input)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyReportBatches/" + model.RoyaltyBatchId + "/ResendEmails",
            data: model
        };

        return this.$http<number>(config);
    }

    BackoutRoyaltyReportBatches(model: RpmEntities.usp_RoyaltyStatusUpdate_BatchManagement_Input)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyReportBatches/" + model.RoyaltyReportBatchId + "/Backout",
            data: model
        };

        return this.$http<number>(config);
    }
}
    
