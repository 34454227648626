import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";


export class FranchiseProfileContactAssignmentsController implements ng.IController 
{
    franchiseId: number;
    assignmentTypeId: number;
    isAfterHours: boolean;

    assignment: CoreResources.IFranchiseProfileContactAssignment;
    assignmentType: CoreResources.IFranchiseProfileContactAssignmentType;

    assignmentWindow: kendo.ui.Window;
    assignmentGrid: kendo.ui.Grid;
    contactDropDown: kendo.ui.DropDownList;
    contactDropDownOptions: kendo.ui.DropDownListOptions;
    selectedContact: CoreResources.IFranchiseProfileContact;

    static $inject = [
        'coreApiResources',
        '$q',
        '$log',
        '$timeout'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService
    )
    {
    }

    $onInit()
    {
        this.InitContactDropDown();
    }

    $postLink()
    {
        this.InitGrid();
        this.InitWindow();
        this.ResetGrid();
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.assignmentTypeId)] && this.assignmentTypeId)
        {
            this.coreApiResources.FranchiseProfileContactAssignmentTypeApi.get({ id: this.assignmentTypeId }).$promise.then((assignmentType) =>
            {
                this.assignmentType = assignmentType;
            });
            this.ResetGrid();
        }

        if (changes[nameof(this.franchiseId)])
        {
            this.ResetGrid();
        }
    }

    InitGrid()
    {
        let dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) =>
                {
                    if (!this.franchiseId || !this.assignmentTypeId) {
                        options.success([]);
                        return;
                    }

                    //let queryParams: ODataQueryParameters = {
                    //    $filter: `${nameof.full<CoreEntities.FranchiseProfileContactAssignment>(o => o.FranchiseProfileContact.FranchiseId).replace('.','/')} eq ${this.franchiseId} and ` + 
                    //        `${nameof.full<CoreEntities.FranchiseProfileContactAssignment>(o => o.FranchiseProfileContactAssignmentTypeId)} eq ${this.assignmentTypeId} and ` + 
                    //        `${nameof.full<CoreEntities.FranchiseProfileContactAssignment>(o => o.IsAfterHours)} eq ${this.isAfterHours}`,
                    //    $expand: `${nameof<CoreEntities.FranchiseProfileContactAssignment>(o => o.FranchiseProfileContact)}($select=${nameof<CoreEntities.FranchiseProfileContactAssignment>(o => o.FranchiseProfileContact.Name)})`
                    ////}
                    //this.coreApiResources.FranchiseProfileContactAssignmentApi.query(queryParams).$promise
                    //    .then((assignments) =>
                    //    {
                    //        options.success(assignments);
                    //    })
                    //    .catch((err) =>
                    //    {
                    //        options.error(err);
                    //    })
                }
            },
            sort: { field: nameof<CoreEntities.FranchiseProfileContactAssignment>(o => o.CommunicationWaitTimeMinutes), dir: "asc" }
        });

        let columns: kendo.ui.GridColumn[] = [
        //    {
        //        field: nameof.full<CoreEntities.FranchiseProfileContactAssignment>(o => o.FranchiseProfileContact.Name),
        //        title: "Name"
        //},
            {
                field: nameof.full<CoreEntities.FranchiseProfileContactAssignment>(o => o.CommunicationWaitTimeMinutes),
                title: "Minutes from First Communication"
            }, {
                field: nameof<CoreEntities.FranchiseProfileContactAssignment>(o => o.SendEmail),
                title: "Send Email",
                template: `<i style='display: block; text-align: center;' ng-class="dataItem.${nameof<CoreEntities.FranchiseProfileContactAssignment>(o => o.SendEmail)} ? 'fa fa-check-square-o' : 'fa fa-square-o'" aria-hidden="true"></i>`,
                width: 120,
                headerAttributes: {
                    style: 'text-align: center'
                }
            }, {
                field: nameof<CoreEntities.FranchiseProfileContactAssignment>(o => o.SendText),
                title: "Send Text",
                template: `<i style='display: block; text-align: center;' ng-class="dataItem.${nameof<CoreEntities.FranchiseProfileContactAssignment>(o => o.SendText)} ? 'fa fa-check-square-o' : 'fa fa-square-o'" aria-hidden="true"></i>`,
                width: 120,
                headerAttributes: {
                    style: 'text-align: center'
                }
            }, {
                field: nameof<CoreEntities.FranchiseProfileContactAssignment>(o => o.SendPhoneCall),
                title: "Phone Call",
                template: `<i style='display: block; text-align: center;' ng-class="dataItem.${nameof<CoreEntities.FranchiseProfileContactAssignment>(o => o.SendPhoneCall)} ? 'fa fa-check-square-o' : 'fa fa-square-o'" aria-hidden="true"></i>`,
                width: 120,
                headerAttributes: {
                    style: 'text-align: center'
                }
            }, {
                template: `<button class='pure-button' ng-click='$ctrl.${nameof(this.OpenEditAssignmentWindow)}(dataItem)'><i class='fa fa-pencil'></i></button>`,
                width: 60
            }, {
                template: `<button class='pure-button button-error' ` +
                    `ng-show='$ctrl.assignmentGrid.dataSource.data().length > 1 || $ctrl.assignmentTypeId != 1' ` + //Don't allow default values to be deletable if there is only one.
                    `ng-click='$ctrl.${nameof(this.DeleteAssignment)}(dataItem)'>X</button>`,
                width: 60
            }];

        let options: kendo.ui.GridOptions = {
            autoBind: false,
            dataSource: dataSource,
            columns: columns,
            toolbar: [
                {
                    template: `<button class='pure-button button-secondary' ng-click='$ctrl.${nameof(this.OpenNewAssignmentWindow)}()'>New Contact Assignment...</button>`
                }
            ],
            sortable: false,
            filterable: false
        };
        this.assignmentGrid.setOptions(options);
    }

    InitWindow()
    {
        let options: kendo.ui.WindowOptions = {
            modal: true
        };
        this.assignmentWindow.setOptions(options);
    }

    InitContactDropDown()
    {
        this.contactDropDownOptions = {
            optionLabel: "Select Contact...",
            optionLabelTemplate: "Select Contact...",
            dataTextField: nameof<CoreEntities.FranchiseProfileContact>(o => o.Name),
            dataValueField: nameof<CoreEntities.FranchiseProfileContact>(o => o.FranchiseProfileContactId),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) =>
                    {
                        let params = {
                            $filter: nameof<CoreEntities.FranchiseProfileContact>(o => o.FranchiseId) + " eq " + this.franchiseId
                        };

                        this.coreApiResources.FranchiseProfileContactApi.query(params).$promise
                            .then((contacts) =>
                            {
                                options.success(contacts);
                            })
                            .catch((err) =>
                            {
                                options.error(err);
                            })
                    }
                }
            })
        }
    }

    OpenNewAssignmentWindow()
    {
        this.assignment = <any>{};
        this.assignment.FranchiseProfileContactAssignmentTypeId = this.assignmentTypeId;
        this.assignment.IsAfterHours = this.isAfterHours;
        this.assignment.CommunicationWaitTimeMinutes = 0;
        //this.assignment.FranchiseProfileContactId = 0;
        this.assignment.FranchiseProfileContactAssignmentId = 0;
        this.contactDropDown.dataSource.read();

        this.assignmentWindow.open();
        this.$timeout(() =>
        {
            this.assignmentWindow.center();
        });
    }

    OpenEditAssignmentWindow(assignment: CoreResources.IFranchiseProfileContactAssignment)
    {
        this.assignment = angular.copy(assignment);
        //this.assignment.FranchiseProfileContact = null;
        this.contactDropDown.dataSource.read().then(() =>
        {
            this.contactDropDown.select((contact: CoreResources.IFranchiseProfileContact) =>
            {
                //return contact.FranchiseProfileContactId == this.assignment.FranchiseProfileContactId;
            });
        })
        
        this.assignmentWindow.open();
        this.$timeout(() =>
        {
            this.assignmentWindow.center();
        });
    }

    SaveAssignment()
    {
        let promise: ng.IPromise<any>;

        if (this.assignment.FranchiseProfileContactAssignmentTypeId == 1 && this.assignment.CommunicationWaitTimeMinutes <= 0)
        {
            this.assignment.SendPhoneCall = false;
        }

        if (!this.assignment.SendEmail &&
            !this.assignment.SendText &&
            !this.assignment.SendPhoneCall)
        {
            alert("At least one type of communication needs to be specified.");
            return;
        }

        if (this.assignment.FranchiseProfileContactAssignmentId)
        {
            promise = this.coreApiResources.FranchiseProfileContactAssignmentApi.update({
                id: this.assignment.FranchiseProfileContactAssignmentId
            }, this.assignment).$promise;
        }
        else
        {
            if (!this.selectedContact)
            {
                alert("Please select a contact.");
                return;
            }
            //this.assignment.FranchiseProfileContactId = this.selectedContact.FranchiseProfileContactId;
            promise = this.coreApiResources.FranchiseProfileContactAssignmentApi.save({}, this.assignment).$promise;
        }

        return promise
            .then(() =>
            {
                this.assignmentWindow.close();
                this.ResetGrid();
            });
    }

    DeleteAssignment(assignment: CoreResources.IFranchiseProfileContactAssignment)
    {
        if (confirm(`Are you sure you want to delete this assignment?`)) {
            return this.coreApiResources.FranchiseProfileContactAssignmentApi.delete({
                id: assignment.FranchiseProfileContactAssignmentId
            }).$promise
                .then(() =>
                {
                    this.ResetGrid()
                });
        }
    }

    ResetGrid()
    {
        if (!this.assignmentGrid)
            return;

        return this.$q.when(this.assignmentGrid.dataSource.read())
            .then(() =>
            {
                this.assignmentGrid.refresh();
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfileContactAssignments', {
            bindings: {
                franchiseId: "<",
                assignmentTypeId: "<",
                isAfterHours: "<"
            },
            controller: FranchiseProfileContactAssignmentsController,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfileContactAssignments.html"
        });
    }
}


