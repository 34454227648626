import * as _ from "underscore";
import * as elements from "typed-html";

import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";

import { MonthsEnabledController, MonthsEnabledControllerOnInit, MonthsEnabledControllerOnSelect, MonthEnabled } from "Directives/Common";
import { StringProperties } from "Types/StringProperties";
import { ExpressionBinding, AngularUtil } from "Utility/AngularUtil";
import { RoyaltyApiResources } from "../../../../Services/Resources/RoyaltyApiResources";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            fddRoyaltiesRollInMinimumsMonthsApplied: Partial<StringProperties<fddRoyaltiesRollInMinimumsMonthsApplied>>;
        }
    }
}

export type fddRoyaltiesRollInMinimumsMonthsAppliedOnInit = { self: fddRoyaltiesRollInMinimumsMonthsApplied };
export type fddRoyaltiesRollInMinimumsMonthsAppliedOnSelect = MonthsEnabledControllerOnSelect;

const defaultMonthEnabled: boolean = true;

export class fddRoyaltiesRollInMinimumsMonthsApplied implements ng.IController
{
    onInit: ExpressionBinding<fddRoyaltiesRollInMinimumsMonthsAppliedOnInit>;
    onSelect: ExpressionBinding<fddRoyaltiesRollInMinimumsMonthsAppliedOnSelect>;

    public isLoading: boolean;
    private royaltyDisclosureDocumentRollInMinimumId: number;

    private monthsEnabledController: MonthsEnabledController;

    static $inject = [
        '$timeout',
        'coreApiResources',
        'royaltyApiResources'
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private coreApiResources: CoreApiResources,
        private royaltyApiResources: RoyaltyApiResources
    )
    {
        this.isLoading = false;
    }

    $onInit() 
    {
    }

    $postLink()
    {
        if (this.onInit)
        {
            this.onInit({ self: this });
        }
    }

    ChangeLinkedMinimum(royaltyDisclosureDocumentRollInMinimumId: number)
    {
        this.royaltyDisclosureDocumentRollInMinimumId = royaltyDisclosureDocumentRollInMinimumId ?? this.royaltyDisclosureDocumentRollInMinimumId;
    }

    async CancelChanges()
    {
        await this.LoadMonthsData(this.royaltyDisclosureDocumentRollInMinimumId);
    }

    HasChanges = () => this.monthsEnabledController.HasChanges();
    
    async SaveChanges(): Promise<RpmEntities.RoyaltyDisclosureDocumentRollInMinimumMonthsApplied[]>
    {
        return await AngularUtil.TrackLoadingPromise(
            fddRoyaltiesRollInMinimumsMonthsApplied.SaveMonthsEnabled(this.monthsEnabledController.GetMonths(), this.royaltyDisclosureDocumentRollInMinimumId, this.coreApiResources),
            this);
    }

    async MonthsEnabledInit(controller: MonthsEnabledController)
    {
        this.monthsEnabledController = controller;
    }

    SetMonthsData(royaltyDisclosureDocumentRollInMinimumId: number | null, monthsEnabled: MonthEnabled[], dirty: boolean = false)
    {
        this.royaltyDisclosureDocumentRollInMinimumId = royaltyDisclosureDocumentRollInMinimumId;
        this.monthsEnabledController.Init(monthsEnabled, dirty);
    }

    async LoadMonthsData(royaltyDisclosureDocumentRollInMinimumId: number | null)
    {
        this.royaltyDisclosureDocumentRollInMinimumId = royaltyDisclosureDocumentRollInMinimumId;

        return await AngularUtil.TrackLoadingPromise((async () =>
        {
            if (this.royaltyDisclosureDocumentRollInMinimumId == null || this.royaltyDisclosureDocumentRollInMinimumId == undefined)
            {
                this.monthsEnabledController.InitAllMonths(defaultMonthEnabled);
                return Promise.resolve();
            }
            else
            {
                let months = await this.royaltyApiResources.GetRoyaltyDisclosureDocumentRollInMinimumMonthsApplied(this.royaltyDisclosureDocumentRollInMinimumId).then((response) => {
                    return response.data;
                })

                this.monthsEnabledController.InitAllMonths((month) =>
                {
                    return (
                        months.find(m => m.monthId == month.id)?.enabled ?? defaultMonthEnabled
                    );
                });
            }
        })(), this);
    }

    private OnSelect(changed: MonthsEnabledControllerOnSelect["changed"], all: MonthsEnabledControllerOnSelect["all"])
    {
        if (this.onSelect)
        {
            this.onSelect({
                all: all,
                changed: changed
            });
        }
    }

    static async SaveMonthsEnabled(months: MonthEnabled[], royaltyDisclosureDocumentRollInMinimumId: number, coreApiResources: CoreApiResources)
    {
        let monthSettings = months
            .map<RpmEntities.RoyaltyDisclosureDocumentRollInMinimumMonthsApplied>(m =>
            {
                return {
                    RoyaltyDisclosureDocumentRollInMinimumId: royaltyDisclosureDocumentRollInMinimumId,
                    Enabled: m.enabled,
                    MonthId: m.month.id,
                    RoyaltyDisclosureDocumentRollInMinimum: null
                }
            });

        //let promises = monthSettings.map(m => rmsClients.royaltyDisclosureDocumentRollInMinimumMonthsAppliedClient.Post(m));
        let promises = monthSettings.map(m => coreApiResources.RoyaltyDisclosureDocumentRollInMinimumMonthsApplied(m).then((response) => {
            return response.data;
        }));
        monthSettings = await Promise.all(promises);
        return monthSettings;
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.fddRoyaltiesRollInMinimumsMonthsApplied);

        let template = (
            <monthsEnabled
                onInit={`$ctrl.${nameof<fddRoyaltiesRollInMinimumsMonthsApplied>(o => o.MonthsEnabledInit)}(${nameof<MonthsEnabledControllerOnInit>(o => o.self)})`}
                onSelect={`$ctrl.${nameof<fddRoyaltiesRollInMinimumsMonthsApplied>(o => o.OnSelect)}(${nameof<MonthsEnabledControllerOnSelect>(o => o.changed)}, ${nameof<MonthsEnabledControllerOnSelect>(o => o.all)})`}
                >
            </monthsEnabled>
        );

        app.component(componentName, {
                bindings: {
                    [nameof<fddRoyaltiesRollInMinimumsMonthsApplied>(o => o.onInit)]: "&?",
                    [nameof<fddRoyaltiesRollInMinimumsMonthsApplied>(o => o.onSelect)]: "&?"
                },
                template: template,
                controller: fddRoyaltiesRollInMinimumsMonthsApplied
            });
    }
}

