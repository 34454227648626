import { applyPolyfills, defineCustomElements } from "@nbly/ces-components/loader";
import { environment, environmentForEach, getDefaultAllComponentTesterConfig } from "@nbly/ces-components";
import { ApiConfig } from "./AppConfig/ApiConfig";

environment.coreApiClientFactory.axiosConfig.baseURL = ApiConfig.Default.AppSettings.CoreApiRootUrl;
environment.identityClientFactory.axiosConfig.baseURL = ApiConfig.Default.AppSettings.IdentityApiRootUrl;
environment.franchiseGroupClientFactory.axiosConfig.baseURL = ApiConfig.Default.AppSettings.FranchiseGroupApiRootUrl;
environment.unifiedSyncPlatformClientFactory.axiosConfig.baseURL = ApiConfig.Default.AppSettings.UnifiedSyncPlatformBaseUrl;
environment.serviceOrchestrationsClientFactory.axiosConfig.baseURL = ApiConfig.Default.AppSettings.ServiceOrchestrationsApiRootUrl;
environment.callCenterOrchestrationsClientFactory.axiosConfig.baseURL = ApiConfig.Default.AppSettings.CallCenterOrchestrationsBaseUrl;
environment.syncGroupClientFactory.axiosConfig.baseURL = ApiConfig.Default.AppSettings.SyncGroupBaseUrl;
environment.engagementServicesClientFactory.axiosConfig.baseURL = ApiConfig.Default.AppSettings.EngagementServicesBaseUrl;
environment.franchiseOrchestrationsClientFactory.axiosConfig.baseURL = ApiConfig.Default.AppSettings.FranchiseOrchestrationsRootUrl;

environmentForEach((factory) =>
{
    factory.addInstanceTransform((instance) =>
    {
        instance.interceptors.request.use(async (config) =>
        {
            const tokenLocation = "FranForceAuthToken";
            const tokenTypeLocation = "FranForceAuthTokenType";
            let token: string;
            let tokenType: string;

            if (window.sessionStorage[tokenLocation])
            {
                [token, tokenType] = [
                    window.sessionStorage[tokenLocation],
                    window.sessionStorage[tokenTypeLocation]
                ];
            }
            else if (window.localStorage[tokenLocation])
            {
                [token, tokenType] = [
                    window.localStorage[tokenLocation],
                    window.localStorage[tokenTypeLocation]
                ];
            }

            if (config.headers.common['Authorization'] === undefined && token)
            {
                config.headers.common['Authorization'] = `${tokenType ? tokenType : "bearer"} ${token}`;
            }
            return config;
        });
    });
});

window['nbly'] = window['nbly'] || {};
window['nbly']['testComponents'] = getDefaultAllComponentTesterConfig();

applyPolyfills().then(async () =>
{
    let url = new URL('/node_modules/%40nbly/ces-components/dist/', window.location.href);

    await defineCustomElements(window, {
        resourcesUrl: url.href
    });
});