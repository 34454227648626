import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyContractsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyContract(contractId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContracts/" + contractId
        };

        return this.$http<RpmEntities.RoyaltyContractViewModel>(config);
    }

    GetRoyaltyContractDynamic(contractId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContracts/" + contractId +"/Dynamic"
        };

        return this.$http<RpmEntities.RoyaltyContractViewModel>(config);
    }

    GetRoyaltyContractsByFranchisor(franchisorId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContracts/ByFranchisor/" + franchisorId
        };

        return this.$http<RpmEntities.RoyaltyContractViewModel[]>(config);
    }

    GetRoyaltyContractsByFranchise(franchiseId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContracts/ByFranchise/" + franchiseId
        };

        return this.$http<RpmEntities.RoyaltyContractViewModel[]>(config);
    }

    CreateRoyaltyContract(model: RpmEntities.RoyaltyContractViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyContracts",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyContract(id: number, model: RpmEntities.RoyaltyContractViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyContracts/" + id,
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyContractDynamic(id: number, model: RpmEntities.RoyaltyContractViewModel) {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyContracts/" + id + "/Dynamic",
            data: model
        };

        return this.$http<number>(config);
    }

    DuplicateRoyaltyContract(id: number)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: `${this.endpointPrefix}/RoyaltyContracts/${id}/Duplicate`
        };

        return this.$http<number>(config);
    }
}