
interface IIndexApiHelpScope extends ng.IScope {
    userName: string;
    password: string;
    issued: Date;
    expires: Date;
    EncodeUriComponent: (str: string) => string;
}

export class IndexApiHelpController
{
    static $inject = [ '$scope' ]

    constructor(private $scope: IIndexApiHelpScope)
    {
        $scope.userName = "my.account@mydomain.com";
        $scope.password = "MyPassword";
        $scope.issued = new Date();
        $scope.expires = new Date();
        $scope.expires.setDate($scope.issued.getDate() + 3);

        $scope.EncodeUriComponent = (str: string) =>
        {
            return encodeURIComponent(str);
        }
    }
}