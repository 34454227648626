import { CoreApiResources } from "Services/Resources/CoreApiResources";
import * as _ from "underscore"

import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ConceptDto } from '@nbly/franchise-group-api-clients'

export class FindANeighborFilterBar implements ng.IController
{
  franchiseId: number;
  conceptId: number;
  filterOptions: any;
  isLoading: boolean;
  selectedConcept: ConceptDto[];
  selectConcept: () => void;
  getActiveConcept: () => void;
  stateChanged: () => void;
  exportFunction: () => void;

  brandsMultiSelect: kendo.ui.MultiSelect;
  brandsMultiSelectOptions: kendo.ui.MultiSelectOptions;
  searchBox:any;


  //DTO

  conceptDto: ConceptDto[];


  static $inject = [
    'coreApiResources',
    "$scope",
    "odataHelper",
    "$document"

  ];

  constructor(
    private coreApiResources: CoreApiResources,
    private $scope: ng.IScope,
    private odataHelper: ODataHelperService,
    private $document: ng.IDocumentService
  )
  {


  }

  async $onInit()
  {
console.log('the seracb bocx is ',this.searchBox)
  }

  checkInputs(elements)
  {
    elements.each(function ()
    {
      let element = $(this);
      let input = element.children("input");
      input.prop("checked", element.hasClass("k-state-selected"));
    });
  };

  SetDropDownOptions()
  {
    this.brandsMultiSelectOptions = {
      dataValueField: nameof.full<ConceptDto>(o => o.conceptId),
      dataTextField: nameof.full<ConceptDto>(o => o.displayName),
      dataSource: this.conceptDto,
      autoBind: true,
      dataBound: (e) =>
      {
        $('#Header').prop('checked', true)

        $('#Header').click(function (e)
        {

          if ($(this).is(':checked'))
          {
            $('#items_listbox').find("li").each(function ()
            {
              if ($(this).hasClass("k-state-selected"))
              {

              } else
              {
                $(this).prop("checked", true);

                $(this).trigger("click");
                $(this).find("input").prop("checked", true);

              }

            });

          }
          else
          {
            $('#items_listbox').find("li").each(function ()
            {
              if ($(this).hasClass("k-state-selected"))
              {
                $(this).prop("checked", false);
                $(this).trigger("click");
                $(this).find("input").prop("checked", false);
              } 
            });
          }
        });

        let items = e.sender.ul.find("li");
        setTimeout(() =>
        {
          this.checkInputs(items);
        });
      },

      itemTemplate: "<input type='checkbox'/> #:data.displayName#",
      headerTemplate: "<div><input type='checkbox' id='Header'><label> Select All</label></div>",
      autoClose: false,
      change: (e) =>
      {
        let items = e.sender.ul.find("li");
        let that = this;
        let unselectedElementExsists: boolean = false;
        items.each(function ()
        {
          let element = $(this);
          let input = element.children("input");
          input.prop("checked", element.hasClass("k-state-selected"));
          if (!element.hasClass("k-state-selected"))
          {
            unselectedElementExsists = true;
          }

        });
        that.selectConcept();
        if (unselectedElementExsists)
        {
          $('#Header').prop('checked', false)
        } else
        {
          $('#Header').prop('checked', true)
        }
      },

    }

  }

  async $postLink()
  {
    this.SetDropDownOptions();

  }


  static BindComponent(app: ng.IModule)
  {
    app.component('findANeighborFilterBar', {
      bindings: {
        conceptDto: "=",
        selectedConcept: "=",
        selectConcept: "&",
        getActiveConcept: "&",
        exportFunction: "&",
        stateChanged: "&",
        filterOptions: "=",
        searchBox:"&"
      },
      controller: FindANeighborFilterBar,
      templateUrl: "/Templates/FindANeighbor/FindANeighborFilterBar.html"
    });
  }
}


