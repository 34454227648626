import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyContractTerminatedReasonsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetAllRoyaltyContractTerminatedReasons()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContractTerminatedReasons"
        };

        return this.$http<RpmEntities.RoyaltyContractTerminatedReasonViewModel[]>(config);
    }
}