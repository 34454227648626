import { AxiosInstance } from "axios";
import { selectedInvoiceForRejectionModel } from "../../../../../Models/RmsApprovals/selectedInvoiceForRejectionModel";

export class rmsPreApprovalQueueComponentController implements ng.IController {

    isLoading: boolean;
    kNotifier: kendo.ui.Notification;

    static $inject = [
        '$window',
        '$location',
        '$q',
        '$scope',
        "$rootScope",
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private $scope: ng.IScope,
        private $rootScope: ng.IRootScopeService,
    ) {

    }

    $onInit() {
        this.isLoading = false;
    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsPreApprovalQueue", {
            bindings: {

            },
            templateUrl: '/Templates/RMSApproval/RmsPreApprovalQueue/rmsPreApprovalQueue.html',
            controller: rmsPreApprovalQueueComponentController
        });
    }
}