import { CoreApiResources } from "Services/Resources/CoreApiResources";
import * as _ from "underscore"
import { AngularUtil } from "Utility/AngularUtil";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

import { Constants } from "Constants";
import { FranchiseProfile } from "@nbly/dataaccess.typings/TypeGen/Dwyer.DataAccess.FranForce.Core";
import { ApiConfig } from "../../AppConfig/ApiConfig";
import { IdentityManager } from "../../Services/Resources/IdentityManager";
import { PromiseUtil } from "../../Utility/PromiseUtil";

export class FranchiseProfileCallCenterDetailsComponent implements ng.IController
{
    franchiseId: number;
    init: (params: { refreshEditors: () => ng.IPromise<void> }) => void;
    emailPattern: string = Constants.Validation.MultipleEmailPattern;
    franchiseProfile: CoreResources.IFranchiseProfile;
    timeZones: CoreResources.ITimeZone[];
    showSameDayScheduling: boolean = true;
    isMly: boolean = false;
    canUserExecuteOptInOptions: boolean = false;

    promotionsEditor: kendo.ui.Editor;
    additionalInfoEditor: kendo.ui.Editor;
    priorityMessageEditor: kendo.ui.Editor;

    franchisePriorityMessageBoundEndDateTime: Date;

    isLoading: boolean;

    static $inject = [
        'coreApiResources',
        'identityManager',
        'apiConfig',
        '$timeout',
        '$q',
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
    )
    {

    }

    get validEmailId() {
        var rgx = new RegExp(this.emailPattern);
        return !this.franchiseProfile || !this.franchiseProfile.CallCenterLeadRoutingEmail || this.franchiseProfile.CallCenterLeadRoutingEmail.trim().split(';')
            .filter(e => e != "")
            .every(e => rgx.test(e));
    }

    $onInit()
    {
        if (this.init)
        {
            this.init({
                refreshEditors: () =>
                {
                    return this.RefreshEditors();
                }
            })
        }
        const optInChangeRoles = [
            this.apiConfig.FranForceConstants.RoleConstants.AdminRole,
            this.apiConfig.FranForceConstants.RoleConstants.CallCenterAdmin,
            this.apiConfig.FranForceConstants.RoleConstants.ConceptManagerRole,
            this.apiConfig.FranForceConstants.RoleConstants.FranchiseConsultantRole,
            this.apiConfig.FranForceConstants.RoleConstants.FranchiseeRole,
        ];

        let adminPromise = this.identityManager.HasRole(optInChangeRoles)
            .then(isAllowedRole =>
            {
                this.canUserExecuteOptInOptions = isAllowedRole;
            });

        let timeZonePromise = this.coreApiResources.TimeZoneApi.query({}).$promise
            .then((timeZones: CoreResources.ITimeZone[]) =>
            {
                this.timeZones = timeZones;
            });

        const mlyConceptId = 1;

        let profilePromise = this.coreApiResources.FranchiseProfileApi.get(
            {
                id: this.franchiseId,
                $expand: `${nameof<FranchiseProfile>(o => o.Franchise)}($select=${nameof<FranchiseProfile>(o => o.Franchise.ConceptId)})`
            }).$promise
            .then((franchiseProfile) =>
            {
                this.franchiseProfile = franchiseProfile;
                this.isMly = (franchiseProfile.Franchise.ConceptId == mlyConceptId);
                this.showSameDayScheduling = !this.isMly;

                this.franchisePriorityMessageBoundEndDateTime = (this.franchiseProfile.PriorityMessageEndDateTime ? new Date(this.franchiseProfile.PriorityMessageEndDateTime) : null);
            });

        AngularUtil.TrackLoadingPromise(this.$q.all([
            adminPromise,
            timeZonePromise,
            profilePromise,
        ]), this);
    }

    $postLink()
    {
        this.RefreshEditors();
    }

    RefreshEditors()
    {
        return this.$timeout(() =>
        {
            this.additionalInfoEditor.refresh();
            this.priorityMessageEditor.refresh();
            this.promotionsEditor.refresh();
        }, 0)
    }

    async SaveOptIn(field: string, newValue: boolean)
    {
        if (!this.canUserExecuteOptInOptions || !field)
        {
            return this.$q.reject();
        }

        this.franchiseProfile[field] = newValue;
        return await PromiseUtil.TrackLoadingPromise(this,
            this.coreApiResources.FranchiseProfileApi.patch({
                id: this.franchiseId,
                [field]: this.franchiseProfile[field]
            } as Partial<CoreResources.IFranchiseProfile>).$promise
        );
    }

    SaveProfile()
    {
        if (!this.validEmailId) {
            return false;
        }

        return this.coreApiResources.FranchiseProfileApi.get({ id: this.franchiseProfile.FranchiseId }).$promise
            .then((franProfile: CoreResources.IFranchiseProfile) =>
            {
                franProfile.TimeZoneId = this.franchiseProfile.TimeZoneId;
                franProfile.PromotionsForCallCenter = this.franchiseProfile.PromotionsForCallCenter;
                franProfile.AdditionalInfoForCallCenter = this.franchiseProfile.AdditionalInfoForCallCenter;
                franProfile.OverrideBusinessPhone = (this.franchiseProfile.OverrideBusinessPhone || "").replace(/\D/g, '');
                franProfile.CallCenterLeadRoutingEmail = this.franchiseProfile.CallCenterLeadRoutingEmail;            
                franProfile.PriorityMessageActive = this.franchiseProfile.PriorityMessageActive;
                franProfile.PriorityMessage = this.franchiseProfile.PriorityMessage;

                franProfile.HasSameDayScheduling = (this.franchiseProfile.HasSameDayScheduling !== null ?
                    this.franchiseProfile.HasSameDayScheduling :
                    false);

                franProfile.PriorityMessageEndDateTime = null;
                if (this.franchiseProfile.PriorityMessageActive && this.franchisePriorityMessageBoundEndDateTime)
                {
                    franProfile.PriorityMessageEndDateTime = this.franchisePriorityMessageBoundEndDateTime.toUTCString();
                }

                return franProfile.$update({ id: franProfile.FranchiseId });
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfileCallCenterDetails', {
            bindings: {
                franchiseId: "<"
            },
            controller: FranchiseProfileCallCenterDetailsComponent,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfileCallCenterDetails.html"
        });
    }
}