import { BillingApiResources } from "../../Services/Resources/BillingApiResources";
import { BillingSubscriptionImportFileRowValidationDataModel } from "../../Models/Billing/ZorWare/SubscriptionImport/BillingSubscriptionImportFileValidationDataModel";
import { BillingSubscriptionUpdateDataModel } from "../../Models/Billing/ZorWare/SubscriptionImport/BillingSubscriptionProcessDataModel";

class FieldTitleMapping {
    field: string;
    title: string;
}

export class BillingSubscriptionImportComponentController implements ng.IController {

    //upload subscription import
    kendoUploadSubscriptions: kendo.ui.Upload;
    defaultKendoUploadOptions: kendo.ui.UploadOptions;

    //subscription update review grid
    subscriptionUpdateReviewGridOptions: kendo.ui.GridOptions;
    subscriptionUpdateReviewDataSource: kendo.data.DataSource;
    subscriptionUpdateReviewGrid: kendo.ui.Grid;
    showSubscriptionUpdateReviewGrid: boolean;

    //subscription update import error grid
    subscriptionUpdateImportErrorGridOptions: kendo.ui.GridOptions;
    subscriptionUpdateImportErrorDataSource: kendo.data.DataSource;
    subscriptionUpdateImportErrorGrid: kendo.ui.Grid;
    showSubscriptionUpdateImportErrorGrid: boolean;

    //validate upload
    isInValidUpload: boolean;
    invalidUploadErrorMessage: string;

    //process import status
    showBillingSubscriptionProcessStatus: boolean;
    billingSubscriptionProcessStatusTypeCSSClass: string;
    billingSubscriptionProcessStatusMessage: string;

    isLoading: boolean;
    fixedColumns: FieldTitleMapping[];
    serviceTypes: string[];
    updateReviewData: any[];
    fieldTitleGridMappings: FieldTitleMapping[];

    static $inject = [
        '$timeout',
        'billingApiResources',
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private billingApiResources: BillingApiResources,
    ) {
    }

    $onInit() {
        this.voidAllValidations();
        this.initSubscriptionFileUpload();
        this.showSubscriptionUpdateReviewGrid = false;
        this.fixedColumns =
        [
            { field: "concept", title: "Concept" },
            { field: "licenseNumber", title: "License Number" },
            { field: "franchiseId", title: "Franchise Id" },
            { field: "doingBusinessAs", title: "Doing Business As" },
            { field: "franchiseeName", title: "Franchisee Name" }
        ];
    }

    initSubscriptionFileUpload() {
        let allowedUploadExtensions = [".xls", ".xlsx"];

        this.defaultKendoUploadOptions = {
            showFileList: true,
            multiple: false,
            validation: {
                allowedExtensions: allowedUploadExtensions
            },
        }
    }

    loadGrid(fieldTitleGridMappings: FieldTitleMapping[], data: any[]) {
        this.showSubscriptionUpdateReviewGrid = true;

        let infoColumns: Array<kendo.ui.GridColumn> = fieldTitleGridMappings.map(x => {
            return {
                field: x.field,
                title: x.title
            }
        })

        let fieldTypeMapping = {};
        fieldTitleGridMappings.forEach(x => {
            var matchingFixedFields = this.fixedColumns.filter(item => item.field == x.field);
            var isFixedField = matchingFixedFields.length > 0;
            fieldTypeMapping[x.field] = {
                type: isFixedField ? "string" : "number"
            }
        })

        this.subscriptionUpdateReviewDataSource = new kendo.data.DataSource({
            transport: {
                parameterMap: function (options, operation) {
                    if (operation !== "read" && options.models) {
                        return { models: kendo.stringify(options.models) };
                    }
                }
            },
            schema: {
                model: {
                    fields: fieldTypeMapping
                }
            }
        });

        this.subscriptionUpdateReviewDataSource.data(data);

        this.subscriptionUpdateReviewGridOptions = {
            columns: infoColumns,
            dataSource: this.subscriptionUpdateReviewDataSource,
            sortable: true,
            scrollable: true,
            resizable: true,
            height: 400,
            dataBound: function () {
                for (var i = 0; i < this.columns.length; i++) {
                    this.autoFitColumn(i);
                }
            },
        };

        if (this.subscriptionUpdateReviewGrid) {
            var options = this.subscriptionUpdateReviewGridOptions;
            $("#subscriptionUpdateReviewGrid").data("kendoGrid").destroy();
            $("#subscriptionUpdateReviewGrid").empty().kendoGrid(options);
            $("#subscriptionUpdateReviewGrid").data("kendoGrid").refresh();
        }

        $("#subscriptionUpdateReviewGrid").kendoTooltip({
            filter: "th",
            position: "right",
            content: function (e) {
                var cell = $(e.target);
                var content = cell.text();
                return content;
            }
        }).data("kendoTooltip");
    }

    loadSubscriptionUpdateImportErrorGrid(data: BillingSubscriptionImportFileRowValidationDataModel[]) {
        if (this.subscriptionUpdateImportErrorGrid) {
            var dataSource = new kendo.data.DataSource({
                data: data
            });

            this.subscriptionUpdateImportErrorGrid.setDataSource(dataSource);
            this.subscriptionUpdateImportErrorGrid.refresh();
        }
        else {
            let infoColumns: Array<kendo.ui.GridColumn> = [
                {
                    field: nameof<BillingSubscriptionImportFileRowValidationDataModel>(o => o.rowNumber),
                    title: "Line Number"
                },
                {
                    field: nameof<BillingSubscriptionImportFileRowValidationDataModel>(o => o.propertyName),
                    title: "Column Name"
                },
                {
                    field: nameof<BillingSubscriptionImportFileRowValidationDataModel>(o => o.errorMessage),
                    title: "Description"
                }
            ];
            this.subscriptionUpdateImportErrorDataSource = new kendo.data.DataSource({
                transport: {
                    parameterMap: function (options, operation) {
                        if (operation !== "read" && options.models) {
                            return { models: kendo.stringify(options.models) };
                        }
                    }
                },
                schema: {
                    model: {
                        fields: {
                            rowNumber: { type: "number" },
                            propertyName: { type: "string" },
                            errorMessage: { type: "string" }
                        }
                    }
                }
            });
            this.subscriptionUpdateImportErrorGridOptions = {
                columns: infoColumns,
                dataSource: this.subscriptionUpdateImportErrorDataSource,
                sortable: true,
                scrollable: true,
                resizable: true,
                height: 400,
            };

            this.subscriptionUpdateImportErrorDataSource.data(data);
        }
        this.showSubscriptionUpdateImportErrorGrid = true;
    }

    onUploadFileClick() {

        //hide all error messages        
        this.unsetInvalidUploadValidations("");
        this.unsetProcessImportStatus();

        let file = this.kendoUploadSubscriptions.getFiles();

        //validate if file is selected for upload
        if (this.checkIfFileIsSelectedForUpload(file[0])) {
            return;
        }

        this.isLoading = true
        this.billingApiResources.ImportBulkBillingSubscriptionUpdate(file[0]['rawFile'])
            .then(
                (response) => {
                    this.isLoading = false;
                    if (response && response.data && response.data.result && response.data.result.length > 0 && response.data.file && response.data.file.isValid) {
                        //get all unique service types
                        this.serviceTypes = this.getUniqueServiceTypesFromData(response.data.result);                     
                        // form data for the review grid
                        this.updateReviewData = this.buildUpdateReviewData(response.data.result, this.serviceTypes);
                        //field and header name mappings for grid
                        this.fieldTitleGridMappings = this.getFieldTitleMapping(this.serviceTypes);
                        //load grid review
                        this.showSubscriptionUpdateImportErrorGrid = false;
                        this.loadGrid(this.fieldTitleGridMappings, this.updateReviewData);
                    }
                    else if (response && response.data && response.data.file && response.data.file.isValid == false) {
                        //load error grid
                        if (response.data.file.rows && response.data.file.rows.length > 0) {
                            this.showSubscriptionUpdateReviewGrid = false;
                            this.loadSubscriptionUpdateImportErrorGrid(response.data.file.rows);
                        }
                        else {
                            alert("error");
                        }
                        
                    }
                })
            .catch(err => {
                this.isLoading = false;
                alert("Error occured in importing file.")
            });
    }

    //get all service types
    getUniqueServiceTypesFromData(data: any[]) {
        var arrAllServiceTypesArray = data.map(x => {
            if (x && x["servicesKeyValues"]) {
                return Object.keys((x["servicesKeyValues"]))
            }
            else {
                return []
            }
        })

        //manipulate data to get all services types (contains duplicates)
        var serviceTypesWithDuplicates = arrAllServiceTypesArray.map(x => {
            return x.join();
        }).join().replace(/,\s*$/, "");

        //remove duplicate and unique service types
        var serviceTypesUnique = serviceTypesWithDuplicates.split(',').filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
        });

        return serviceTypesUnique
    }

    //build data to populate grid for review (combine static and dynamic columns)
    buildUpdateReviewData(data: any[], serviceTypes: string[]) {
        let billingSubscriptionUpdateReviewData = data.map(item => {
            var staticValues = {
                "concept": item.concept,
                "licenseNumber": item.licenseNumber,
                "franchiseId": item.franchiseId,
                "doingBusinessAs": item.doingBusinessAs,
                "franchiseeName": item.franchiseeName,
            }

            var dynamicValuesArr = serviceTypes.map(x => {
                if (item["servicesKeyValues"] && item["servicesKeyValues"][x]) {
                    let qty = item["servicesKeyValues"][x]["currentQty"];
                    return {
                        [x.replace(/\s/g, '') + "Qty"]: qty,
                        [x.replace(/\s/g, '') + "UnitPrice"]: item["servicesKeyValues"][x]["unitPrice"]
                    }
                }
                else {
                    return {
                        [x.replace(/\s/g, '') + "Qty"]: null,
                        [x.replace(/\s/g, '') + "UnitPrice"]: null
                    }
                }
            });

            //combine dynamic and static fields
            return Object.assign({}, staticValues, ...dynamicValuesArr);
        });
        return billingSubscriptionUpdateReviewData;
    }

    // get mapping for property and grid header
    getFieldTitleMapping(serviceTypes: string[]) {
        let dynamicFieldTitleGridMappings = [];
        serviceTypes.forEach(x => {
            dynamicFieldTitleGridMappings.push({
                field: x.replace(/\s/g, '') + "Qty",
                title: x + " Quantity"
            });
            dynamicFieldTitleGridMappings.push({
                field: x.replace(/\s/g, '') + "UnitPrice",
                title: x + " Unit Price"
            });
        });
        let fieldTitleGridMappings = [...this.fixedColumns, ...dynamicFieldTitleGridMappings];
        return fieldTitleGridMappings;
    }

    onCancelClick() {
        this.showSubscriptionUpdateImportErrorGrid = false;
        this.showSubscriptionUpdateReviewGrid = false;
        this.kendoUploadSubscriptions.removeAllFiles();
        this.unsetProcessImportStatus();
    }

    onProcessClick() {
        this.isLoading = true;
        if (this.updateReviewData && this.updateReviewData.length > 0 && this.serviceTypes && this.serviceTypes.length > 0) {
            let dataToProcess: BillingSubscriptionUpdateDataModel[] = [];

            this.updateReviewData.forEach(x => {
                this.serviceTypes.forEach(y => {
                    if (x[y.replace(/\s/g, '') + "Qty"] != null) {
                        dataToProcess.push({
                            franchiseId: x.franchiseId,
                            serviceType: y,
                            quantity: x[y.replace(/\s/g, '') + "Qty"],
                        });
                    }
                });
            });
            this.billingApiResources.ProcessBulkBillingSubscriptionUpdate(dataToProcess)
                .then((response) => {
                    this.isLoading = false;
                    this.showBillingSubscriptionProcessStatus = true;
                    if (response && response.data && response.data == true) {
                        this.setProcessSuccessStatus("Billing subscription import processed successfully.", "importProcessSuccessTxt");
                    }
                    else {
                        this.setProcessFailureStatus("Billing subscription import processing failed.", "importProcessFailureTxt");
                    }
                })
                .catch(err => {
                    this.isLoading = false;
                    this.setProcessFailureStatus("Billing subscription import processing failed.", "importProcessFailureTxt");
                })
        }
        else {
            this.isLoading = false;
            this.setProcessFailureStatus("Invalid data imported. Billing subscription import processing failed.", "importProcessFailureTxt");
        }
    }

    //check if file is selected for upload
    checkIfFileIsSelectedForUpload(file) {
        if (!file) {
            this.setInvalidUploadValidations("Please select a file to import.");
            return true;
        }
        else {
            this.unsetInvalidUploadValidations("");
            return false;
        }
    }

    //set Invalid Upload Validations
    setInvalidUploadValidations(invalidUploadErrorMessage) {
        this.isInValidUpload = true;
        this.invalidUploadErrorMessage = invalidUploadErrorMessage;
    }

    //unset Invalid Upload Validations
    unsetInvalidUploadValidations(invalidUploadErrorMessage) {
        this.isInValidUpload = false;
        this.invalidUploadErrorMessage = invalidUploadErrorMessage;
    }

    //set Process failure status
    setProcessFailureStatus(message: string, className: string) {
        this.billingSubscriptionProcessStatusMessage = message;
        this.billingSubscriptionProcessStatusTypeCSSClass = className;
    }

    //set Process success status
    setProcessSuccessStatus(message: string, className: string) {
        this.billingSubscriptionProcessStatusMessage = message;
        this.billingSubscriptionProcessStatusTypeCSSClass = className;
    }

    //unset process success status
    unsetProcessImportStatus() {
        this.showBillingSubscriptionProcessStatus = false;
        this.billingSubscriptionProcessStatusTypeCSSClass = "";
        this.billingSubscriptionProcessStatusMessage = "";
    }

    //void all error and status labels
    voidAllValidations() {
        this.unsetInvalidUploadValidations("");
        this.unsetProcessImportStatus();
    }

    static BindComponent(app: ng.IModule) {
        app.component("billingSubscriptionImport", {
            bindings: {
            },
            controller: BillingSubscriptionImportComponentController,
            templateUrl: "/Templates/Billing/BillingSubscriptionImport.html"
        });

    }
}