import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreEntities, CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import
{
  FranchiseWebLocationOptionClient,FranchiseWebLocationOptionDto
} from '@nbly/franchise-group-api-clients';
import { AxiosInstance } from "axios";
import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
interface MainMenuExpansionConfig {
    admin: boolean;
    giftCert: boolean;
    msDonation:boolean;
    rpm: boolean;
    callCenter: boolean;
    kbs: boolean;
    serviceManager: boolean;
}


export class MainMenuController implements ng.IController
{
    sessionStorageKey: string;
    expandedMenus: MainMenuExpansionConfig;

    franchiseTemplate: string;
    optInForReferANeighbor: boolean = false;
    franchiseGroupClient: AxiosInstance;
    franchiseWebLocationOptionClient: FranchiseWebLocationOptionClient;
    franchiseWebLocationOptionDtos : FranchiseWebLocationOptionDto[];
    AppSettings: IAppSettings;

    static $inject = [
        "odataHelper",
        "identityManager",
        "coreApiResources",
        "apiConfig",
        "$scope"
    ];

    constructor(
        private odataHelper: ODataHelperService,
        private identityManager: IdentityManager,
        private coreApiResources: CoreApiResources,
        private apiConfig: ApiConfig,
        private $scope: ng.IScope
    )
    {
        this.AppSettings = apiConfig.AppSettings;
    }

    $onInit()
    {
        this.franchiseGroupClient = FranForceAxiosClientBuilder.BuildFranchiseGroupBaseClient();
        this.franchiseWebLocationOptionClient=new FranchiseWebLocationOptionClient("",this.franchiseGroupClient);
        this.sessionStorageKey = "mainMenuConfiguration";
        let expansionConfigSessionString = sessionStorage.getItem(this.sessionStorageKey);

        this.franchiseTemplate = `{{${nameof<CoreEntities.Franchise>(o => o.LicenseNumber)}}} Profile`;

        if (expansionConfigSessionString) {
            this.expandedMenus = JSON.parse(expansionConfigSessionString);
        }
        else
        {
            this.expandedMenus = {
                admin: false,
                giftCert: false,
                msDonation:false,
                rpm: false,
                callCenter: false,
                kbs: false,
                serviceManager: false
            }
        }

        this.SetOptInForReferANeighbor();
    }

    async SetOptInForReferANeighbor()
    {
        let userResponse = await this.identityManager.GetLoggedInUserInfo();

        let isDirectlyAllowedRole = userResponse.data.Roles.some(r => r.Name == this.apiConfig.FranForceConstants.RoleConstants.AdminRole);

        if (isDirectlyAllowedRole)
        {
            this.optInForReferANeighbor = true;
            return;
        }

        let isFranchisee = userResponse.data.Roles.some(r => r.Name == this.apiConfig.FranForceConstants.RoleConstants.FranchiseeRole);

        if (!isFranchisee)
        {
            this.optInForReferANeighbor = false;
            return;
        }

        let validFranchiseIds = userResponse.data.Claims
            .filter((c) =>
            {
                return c.Type == this.apiConfig.FranForceConstants.ClaimConstants.OwnedFranchiseIdClaimType
            })
            .map((c) =>
            {
                return parseInt(c.Value);
            });
            this.franchiseWebLocationOptionClient.get_protected_franchiseweblocationoption_franchises(validFranchiseIds).
        then((response)=>{
            this.franchiseWebLocationOptionDtos = response.result;
            this.optInForReferANeighbor = this.franchiseWebLocationOptionDtos.some((w) => { return w.optInForReferANeighbor });
            this.$scope.$digest();
        })
        
        // let params: ODataQueryParameters = {
        //     $select: nameof<CoreResources.IFranchiseProfile>(o => o.OptInForReferANeighbor),
        //     $filter: this.odataHelper.CreateInFilterStatement(nameof<CoreResources.IFranchiseProfile>(o => o.FranchiseId), validFranchiseIds)
        // };

        // let profiles = await this.coreApiResources.FranchiseProfileApi.query(params).$promise;
            
    }

    ToggleExpansion(field: string)
    {
        this.expandedMenus[field] = !this.expandedMenus[field];
        sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(this.expandedMenus));
    }
}


