import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyFindScaleBasedOnTypesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetAllRoyaltyFindScaleBasedOnTypes()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyFindScaleBasedOnTypes"
        };

        return this.$http<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result[]>(config);
    }
}