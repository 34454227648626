import { IBaseStateHandler } from "StateHandlers/BaseStateHandler";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyPeriodFrequenciesStateHandler implements IBaseStateHandler<RpmEntities.RoyaltyPeriodFrequencyViewModel[], undefined> {

    promise: angular.IPromise<RpmEntities.RoyaltyPeriodFrequencyViewModel[]>;

    constructor(
        private rpmUiApiResources: RpmUiApiResources
    )
    {

    }

    Get(id: undefined = undefined): angular.IPromise<RpmEntities.RoyaltyPeriodFrequencyViewModel[]>
    {
        return this.rpmUiApiResources.GetAllRoyaltyPeriodFrequencies()
            .then(freqsResponse =>
            {
                return freqsResponse.data;
            });
    }

    Load(id: undefined = undefined): angular.IPromise<RpmEntities.RoyaltyPeriodFrequencyViewModel[]>
    {
        if (!this.promise) {
            this.promise = this.Get();
        }

        return this.promise;
    }

    Refresh(id: undefined = undefined): angular.IPromise<RpmEntities.RoyaltyPeriodFrequencyViewModel[]>
    {
        this.Clear();
        return this.Load();
    }

    Clear(id: undefined = undefined): void
    {
        this.promise = null;
    }
}