import { AxiosInstance } from "axios";
import { InvoiceFileUploadsClient, InvoiceFileStagingDataVm, BillingServiceClient, IntegrationFinanceClient, IntegrationFinanceInvoiceSourceTypeClient, IntegrationFinanceInvoiceSourceTypeVm, IntegrationFinanceDashboardCompanyCodeVm, IntegrationFinanceDashboardResultRm, InvoiceFileUploadEntryRowVm } from "@nbly/billing-orchestrations-clients";
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";
import { IdentityManager } from "../../../Services/Resources/IdentityManager";
import { FinanceSyncWorkerClient } from "@nbly/service-manager-clients";

export class financeIntegrationImportDashboardInputComponentController implements ng.IController {

    //bindings
    displaySnackbar: (params: { type: string, message: string }) => void;
    //end bindings

    usersDropdown: kendo.ui.DropDownList;
    user?: string;
    userEmail: string;

    //upload files
    kendoUpload: kendo.ui.Upload;
    defaultKendoUploadOptions: kendo.ui.UploadOptions;
    invoiceId: number;


    applicationSourceDropdown: kendo.ui.DropDownList;
    applicationSource?: string;

    financeInvoceSoureTypeDropdown: kendo.ui.DropDownList;
    financeInvociceSourceType?: number;

    dateFrom: Date;
    dateTo: Date;

    billingOrchestrationsClient: AxiosInstance;
    billingServicesClient: BillingServiceClient;
    integrationFinanceClient: IntegrationFinanceClient;
    integrationFinanceInvoiceSourceTypeClient: IntegrationFinanceInvoiceSourceTypeClient;
    invoiceFileStagingData: InvoiceFileStagingDataVm;
    invoiceFileUploadsClient: InvoiceFileUploadsClient;
    localStorage: ILocalStorage;

    isLoading: boolean;
    financeIntegrationImportGrid: kendo.ui.Grid;
    financeIntegrationImportGridOptions: kendo.ui.GridOptions;
    financeIntegrationImportErrorGrid: kendo.ui.Grid;
    financeIntegrationImportErrorGridOptions: kendo.ui.GridOptions;

    serviceManagerClient: AxiosInstance;
    financeSyncWorkerClient: FinanceSyncWorkerClient;

    static $inject = [
        '$scope',
        'identityManager',
        '$location',
        '$rootScope',
    ];

    constructor(
        private $scope: ng.IScope,
        private identityManager: IdentityManager,
        private $location: ng.ILocationService,
        private $rootScope: ng.IRootScopeService,
    ) {
        this.billingOrchestrationsClient = FranForceAxiosClientBuilder.BuildBillingOrchestrationsBaseClient();
        this.billingServicesClient = new BillingServiceClient("", this.billingOrchestrationsClient);
        this.invoiceFileUploadsClient = new InvoiceFileUploadsClient("", this.billingOrchestrationsClient);
        this.integrationFinanceClient = new IntegrationFinanceClient("", this.billingOrchestrationsClient);
        this.integrationFinanceInvoiceSourceTypeClient = new IntegrationFinanceInvoiceSourceTypeClient("", this.billingOrchestrationsClient);

        this.serviceManagerClient = FranForceAxiosClientBuilder.BuildServiceManagerBaseClient();
        this.financeSyncWorkerClient = new FinanceSyncWorkerClient("", this.serviceManagerClient);
    }

    $onInit() {
        this.localStorage = <ILocalStorage>localStorage;
        this.initReportUpload();
        this.loadUser();
        this.LoadStagingGridOptions();
        this.SetUploadValidationErrors();
        // this.$rootScope.$on('financeIntegrationDashboardRefresh', async (event, args) => {
        //     this.OnSubmit();
        // });
    }

    async $postLink() {

        await Promise.all([this.InitSourceTypeDropdown(), this.initReportUpload()]);
    }



    loadUser() {
        console.log('Loading ')
        this.identityManager.GetLoggedInUserInfo().then(
            (success) => {
                this.userEmail = success.data.Email;
                console.log(this.userEmail);
            },
            (error) => {
                console.log(error);
            });
    }

    //set file uploader
    initReportUpload() {
        let allowedUploadExtensions = [".xls", ".xlsx"];

        this.defaultKendoUploadOptions = {
            showFileList: true,
            multiple: false,
            validation: {
                allowedExtensions: allowedUploadExtensions
            },
        }
    }

    InitSourceTypeDropdown() {
        this.financeInvoceSoureTypeDropdown.setOptions(
            {
                autoBind: true,
                valuePrimitive: true,
                dataValueField: nameof<IntegrationFinanceInvoiceSourceTypeVm>(o => o.integrationFinanceInvoiceSourceTypeId),
                dataTextField: nameof<IntegrationFinanceInvoiceSourceTypeVm>(o => o.name),
                optionLabel: "Select Source Type",
                optionLabelTemplate: "Select Source Type",
                autoWidth: true,
                filter: "contains",
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: (options) => {
                            this.integrationFinanceInvoiceSourceTypeClient.integrationFinanceInvoiceSourceTypes()
                                .then((response) => {
                                    console.log("response", response)
                                    let companys: IntegrationFinanceInvoiceSourceTypeVm[] = response.result as any;
                                    companys.sort((a, b) => a.name.localeCompare(b.name));
                                    options.success(companys)
                                })
                                .catch((err) => {
                                    this.displaySnackbar({ type: "error", message: "Error! Failed to load Source Types." });
                                })
                                .finally(() => {

                                })
                        },

                    }
                }),
                select: async (e) => {

                }
            } as kendo.ui.DropDownListOptions
        );
        this.financeInvoceSoureTypeDropdown.setDataSource(this.financeInvoceSoureTypeDropdown.options.dataSource);
    }

    SetUploadValidationErrors() {
        const financeIntegrationImportDataSource = new kendo.data.DataSource({
            data: [],
            pageSize: 25,
            schema: {
                model: {
                    id: "RowNumber",
                    type: "number",
                    fields: {
                        rowNumber: {
                            type: "number"
                        },
                        propertyName: {
                            type: "string"
                        },
                        errorMessage: {
                            type: "string"
                        }
                    }
                }
            }
        });

        const financeIntegrationImportGridColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "rowNumber",
                title: "Row Number"
            },
            {
                field: "propertyName",
                title: "Column Name"
            },
            {
                field: "errorMessage",
                title: "Error Message",
                width: '70%'
            }
        ]

        this.financeIntegrationImportErrorGridOptions = {
            autoBind: false,
            dataSource: financeIntegrationImportDataSource,
            columns: financeIntegrationImportGridColumns,
            resizable: true,
            pageable: {
                numeric: true,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
        };
    }

    ShowFileUploadValidationErrors(errorEntries: InvoiceFileUploadEntryRowVm[]) {
        // Reset the grid by clearing the data source
        this.financeIntegrationImportErrorGrid.dataSource.data([]);
        
        // Set the new data and refresh the grid
        this.financeIntegrationImportErrorGrid.dataSource.data(errorEntries);
        this.financeIntegrationImportErrorGrid.refresh();
    }    

    isUploadSuccessful = false;
    showImportedData = false;

    showSnackbar: boolean;
    snackbarType: string;
    snackbarMessage: string;

    DisplaySnackbar(type: string, message: string) {
        this.showSnackbar = !this.showSnackbar;
        this.snackbarType = type;
        this.snackbarMessage = message;
        this.$scope.$evalAsync();
    }

    OnSubmit() {
        event.preventDefault();

        this.showImportedData = false;
        this.isLoading = true;

        const uploadFilesArr = this.kendoUpload.getFiles().map(file => {
            return file.rawFile;
        });

        const fileParameter = {
            data: uploadFilesArr[0],
            fileName: uploadFilesArr[0].name
        };

        this.invoiceFileUploadsClient.invoiceFileUploads(fileParameter,
            1,
            this.userEmail,
            new Date().toISOString(),
            this.financeInvociceSourceType,
            true,
            null,
            this.userEmail,
            null,
            new Date().toISOString(),
            null,
            this.userEmail).then((response) => {
                console.log(response);

                const { result: resultVm } = response
                const { success } = resultVm;

                this.isUploadSuccessful = success;

                if (success) {
                    this.showImportedData = true;

                    this.DisplaySnackbar("success", "File Uploaded Successfully!")

                    this.invoiceId = response.result.invoiceFileUpload.invoiceFileUploadId;
                    //Once we have the invoice Id, make the call for the Grid 
                    this.LoadStagingGridData(this.invoiceId);

                    return;
                }

                this.DisplaySnackbar("warning", "The file uploaded has validation errors. Please fix the errors and try again!")

                const { errorEntries } = resultVm;

                this.ShowFileUploadValidationErrors(errorEntries);

            }).catch(({ response, status }) => {
                console.log(response, status);

                this.showImportedData = false;
                this.isUploadSuccessful = false;

                switch (status) {
                    case 400: // BadRequest
                        this.DisplaySnackbar("warning", "The file uploaded has validation errors. Please fix the errors and try again!")
                        const { errorEntries } = response;
                        console.log(errorEntries);
                        this.ShowFileUploadValidationErrors(errorEntries);
                        break;
                    case 401: //Unauthenticated
                        this.DisplaySnackbar("warning", "You are not authenticated. Please authenticate yourself!")
                        break;
                    case 403: //Unauthorized
                        this.DisplaySnackbar("warning", "You are unauthorized to import files on this page!");
                        break;
                    default:
                        this.DisplaySnackbar("error", "An error occured when trying to upload the file! Please try again")
                }

            }).finally(() => {
                this.isLoading = false;
                this.showImportedData = true;
                this.$scope.$apply();
            });

    }

    OnSubmitStagingData() {
        this.isLoading = true;

        this.financeSyncWorkerClient.execute7(this.invoiceId).then(
            (response) => {
                this.financeIntegrationImportGrid.dataSource.data([]);
                this.DisplaySnackbar("success", "Invoice file upload sync run is queued!")
                console.log(response);
            }
        ).catch(({ status }) => {
            switch (status) {
                case 400: // BadRequest
                    this.DisplaySnackbar("warning",
                        "The request is invalid!")
                    break;
                case 401: //Unauthenticated
                    this.DisplaySnackbar("warning", "You are not authenticated. Please authenticate yourself!")
                    break;
                case 403: //Unauthorized
                    this.DisplaySnackbar("warning", "You are unauthorized to trigger invoice file sync run on this page!");
                    break;
                default:
                    this.DisplaySnackbar("error", "An error occurred when trying to queue invoice file upload sync run!")
            }
        })
            .finally(() => {
                this.isLoading = false;
            });
    }


    LoadStagingGridOptions() {
        let financeIntegrationImportDataSource = new kendo.data.DataSource({
            data: [],
            pageSize: 25,
            schema: {
                model: {
                    id: "invoiceFileUploadStagingDataId",
                    type: "number",
                    fields: {
                        invoiceFileUploadStagingDataId: {
                            type: "number"
                        },
                        invoiceFileUploadId: {
                            type: "number"
                        },
                        itemDescription: {
                            type: "string"
                        },
                        invoiceNumber: {
                            type: "string"
                        },
                        customerId: {
                            type: "string"
                        },
                        itemNumber: {
                            type: "string"
                        },
                        quantity: { type: "string" },
                        unitPrice: { type: "string" },
                        extAmount: { type: "string" },
                        overRideTaxDate: { type: "string" },
                        avalaraCompanyCode: { type: "string" },
                        gpDatabase: { type: "string" },
                        brand: { type: "string" },
                        errorMessage: { type: "string" },
                        active: { type: "string" },
                        deletedDateTime: { type: "string" },
                        createdUser: { type: "string" },
                        createdDateTime: { type: "string" },
                        updatedDateTime: { type: "string" }
                    }
                }
            }
        });

        let financeIntegrationImportGridColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "invoiceNumber",
                title: "Invoice Number",

            },
            {
                field: "invoiceFileUploadStagingDataId",
                title: "Staging Id",
            },
            {
                field: "invoiceFileUploadId",
                title: "Upload ID",
            }, {
                field: "itemDescription",
                title: "Description"
            },
            {
                field: "customerId",
                title: "Customer ID"
            },
            {
                field: "itemNumber",
                title: "Item Number"
            },
            {
                field: "quantity",
                title: "Quantity"
            },
            {
                field: "unitPrice",
                title: "Unit Price"
            },
            {
                field: "extAmount",
                title: "Amount"
            },
            {
                field: "active",
                title: "Active"
            },

        ];

        this.financeIntegrationImportGridOptions = {
            autoBind: false,
            dataSource: financeIntegrationImportDataSource,
            columns: financeIntegrationImportGridColumns,
            pageable: {
                numeric: true,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
        };
    }


    LoadStagingGridData(uploadId: number) {

        this.invoiceFileUploadsClient.invoiceFileStagingDataAll(uploadId, 0, 100).then(
            (response) => {
                console.log(response.result['results']);
                this.financeIntegrationImportGrid.dataSource.data(response.result['results'])
                this.financeIntegrationImportGrid.refresh();
                this.isLoading = false;
            }
        ).finally(
            () => {
                this.isLoading = false;
            }
        )
    }

    ConvertDateToISOString(dateInput: Date) {
        let date = new Date(dateInput);

        let dateVal = date.getDate().toString();
        if (dateVal.toString().length < 2)
            dateVal = "0" + dateVal;

        let monthVal = (date.getMonth() + 1).toString();
        if (monthVal.toString().length < 2)
            monthVal = "0" + monthVal;

        let yearVal = date.getFullYear().toString();

        let isoDateString = yearVal + '-' + monthVal + '-' + dateVal + 'T18:00:00.000Z';
        return isoDateString;
    }

    static BindComponent(app: ng.IModule) {
        app.component("financeIntegrationImportDashboardInput", {
            bindings: {
                [nameof<financeIntegrationImportDashboardInputComponentController>(o => o.displaySnackbar)]: "&"
            },
            templateUrl: '/Templates/FinanceIntegrationDashboard/financeIntegrationImportDashboardInput.html',
            controller: financeIntegrationImportDashboardInputComponentController
        });
    }
}