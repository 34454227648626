/// <reference types="ts-nameof" />
/// <reference types="ng-dialog" />

import 'lib/index';

import * as AppConfig from 'Modules/AppConfig';
import * as AppFilters from 'Modules/AppFilters';
import * as PortalServices from 'Modules/PortalServicesApp';
import * as PortalDirectives from 'Modules/PortalDirectivesApp';
import * as PortalControllers from 'Modules/PortalControllersApp';
import * as DisasterPrepProfileApp from 'Modules/DisasterPrepProfilesApp';
import * as LegalApp from 'Modules/LegalApp';
import * as RmsApp from 'Modules/RmsApp';
import * as ZorwareApp from 'Modules/ZorwareApp';

import "CesComponents";


const coreServices = angular.module('portal.core', [
    /* Angular modules */
    'ngSanitize',
    'ngResource',
    'ngRoute',

/* 3rd-party modules */
    'ngDialog',
    'akoenig.deckgrid',
    'angular-images-loaded',
    'angularPromiseButtons',
    '720kb.tooltips',
    'kendo.directives',
    'ui.router',
    'ng-currency',
    'ui.tree',
    'angular-sortable-view'
]);

let dppApp = angular.module('portal.dpp', [
    'portal.core',
]);
let legalApp = angular.module('portal.legal', [
    'portal.core',
]);

let rmsApp = angular.module('portal.rpm', [
    'portal.rpm.batch',
    'portal.rpm.contract',
    'portal.rpm.conceptRoyalty',
    'portal.rpm.fdd',
    'portal.rpm.franchisee',
    'portal.rpm.franchisorRoyaltyManagement',
    'portal.rpm.periodReport',
    'portal.rpm.manualSales',
    'portal.rpm.taxReturn',
    'portal.rpm.franchiseEntity',
    'portal.rpm.rmsSearch',
    'portal.rpm.rmsDashboard',
    'portal.rpm.rmsApproval',
    'portal.rpm.financeIntegrationDashboard'
]);

let zorwareApp = angular.module('portal.zorware', [
    'portal.zorware.billingServices'
]);

let zorwareModules = {
    billingServices: angular.module('portal.zorware.billingServices', ['portal.zorware']),
};

let rmsModules = {
    bm:             angular.module('portal.rpm.batch', ['portal.core']),
    cm:             angular.module('portal.rpm.contract', ['portal.core']),
    cr:             angular.module('portal.rpm.conceptRoyalty', ['portal.core']),
    fdd:            angular.module('portal.rpm.fdd', ['portal.core']),
    fm:             angular.module('portal.rpm.franchisee', ['portal.core']),
    frm:            angular.module('portal.rpm.franchisorRoyaltyManagement', ['portal.core']),
    manualSales:    angular.module('portal.rpm.manualSales', ['portal.core']),
    pr:             angular.module('portal.rpm.periodReport', ['portal.core']),
    taxReturn:      angular.module('portal.rpm.taxReturn', ['portal.core']),
    franchiseEntity: angular.module('portal.rpm.franchiseEntity', ['portal.core']),
    rmsSearch: angular.module('portal.rpm.rmsSearch', ['portal.core']),
    rmsDashboard: angular.module('portal.rpm.rmsDashboard', ['portal.core']),
    rmsApproval: angular.module('portal.rpm.rmsApproval', ['portal.core']),
    financeIntegrationDashboard: angular.module('portal.rpm.financeIntegrationDashboard', ['portal.core']),
};
    
/* Portal App */
let app = angular.module('portal', [
    'portal.core',
    'portal.dpp',
    'portal.legal',
    'portal.rpm',
    'portal.zorware'
]);

PortalServices.Init();
AppConfig.Init();
AppFilters.Init();

PortalDirectives.Init();
PortalControllers.Init();

DisasterPrepProfileApp.Init();
LegalApp.Init();

RmsApp.Init();
ZorwareApp.Init();
