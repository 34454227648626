import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { RpmEntities } from "../../Interfaces/FranForce/Rpm/RpmResources";


export type FranchiseSelectorComponentControllerState = { franchiseIdParameter?: string }
export type FranchiseSelectorOnSelectParameters = { franchise: kendo.data.ObservableObject & Partial<CoreResources.IFranchise> };
export type FranchiseSelectorOnLoadParameters = { franchises: Array<Partial<CoreResources.IFranchise>> };

export class FranchiseSelectorComponentController implements ng.IController
{
    selectedModel: kendo.data.ObservableObject & Partial<CoreResources.IFranchise>;
    dropdown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    //---- Bindings ----
    franchiseId: number;
    select: string[];
    filters: kendo.data.DataSourceFilter;
    sort: (a: Partial<CoreResources.IFranchise>, b: Partial<CoreResources.IFranchise>) => number;
    disable: boolean;
    displayTextTemplate: string;
    extraOptions: kendo.ui.DropDownListOptions;
    showInactive: boolean;
    onSelect: (params: FranchiseSelectorOnSelectParameters) => void;
    onLoad: (params: FranchiseSelectorOnLoadParameters) => void;
    state: boolean | FranchiseSelectorComponentControllerState;
    showOnlyTerminatedSixMonths: boolean;
    //------------------
    
    static readonly DisplayProperty = "DisplayText";

    static $inject = [
        "$q",
        "$interpolate",
        "$location",
        "coreApiResources",
        "identityManager",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $q: ng.IQService,
        private $interpolate: ng.IInterpolateService,
        private $location: ng.ILocationService,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {

    }

    $onInit()
    {
        if (!this.select || !(this.select instanceof Array)) {
            this.select = [
                nameof<CoreResources.IFranchise>(o => o.ConceptId),
                nameof<CoreResources.IFranchise>(o => o.FranchisorId),
                nameof<CoreResources.IFranchise>(o => o.FranchiseId),
                nameof<CoreResources.IFranchise>(o => o.LicenseNumber),
                nameof<CoreResources.IFranchise>(o => o.DoingBusinessAs),
            ];
        }

        this.selectedModel = null;

        if (!this.displayTextTemplate)
            this.displayTextTemplate = `{{${nameof<CoreResources.IFranchise>(o => o.LicenseNumber)}}} - {{${nameof<CoreResources.IFranchise>(o => o.DoingBusinessAs)}}}`;

        if (!this.sort)
            this.sort = (a, b) => { return a[FranchiseSelectorComponentController.DisplayProperty].toLowerCase().localeCompare(b[FranchiseSelectorComponentController.DisplayProperty].toLowerCase()); }
        
        this.InitState();
        this.SetDropDownOptions();
    }

    SetDropDownOptions() {
        let overridableOptions: kendo.ui.DropDownListOptions = {
            autoWidth: true,
            filter: "contains",
            height: 450,
            enable: !this.disable
        };

        let permanentOptions: kendo.ui.DropDownListOptions = {
            optionLabel: {
                [FranchiseSelectorComponentController.DisplayProperty]: "Select Franchise...",
                FranchiseId: null,
            },
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options: kendo.data.DataSourceTransportReadOptions) => {
                        if (this.filters === undefined || this.filters === null) {
                            options.success([]);
                            return;
                        }
                        let filters: kendo.data.DataSourceFilters = {
                            logic: "and",
                            filters: [
                                this.filters
                            ]
                        };

                        if (!this.showInactive && !this.showOnlyTerminatedSixMonths) {
                            let activeFilter: kendo.data.DataSourceFilterItem = {
                                field: nameof<CoreResources.IFranchise>(o => o.Status),
                                operator: "eq",
                                value: "Active"
                            }
                            filters.filters.push(activeFilter)
                        }

                        if (this.showOnlyTerminatedSixMonths) {
                            let inActiveFilter: kendo.data.DataSourceFilterItem = {
                                field: nameof<CoreResources.IFranchise>(o => o.Status),
                                operator: "eq",
                                value: "Inactive"
                            }
                            filters.filters.push(inActiveFilter)
                        }

                        let params: ODataQueryParameters = {};
                        params.$select = this.GetSelectString();
                        params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersToString(filters);

                        this.coreApiResources.FranchiseApi.query(params)
                            .$promise.then((franchises) => {
                                for (let franchise of franchises) {
                                    franchise[FranchiseSelectorComponentController.DisplayProperty] = this.$interpolate(this.displayTextTemplate)(franchise);
                                }
                                if (this.sort) {
                                    franchises = franchises.sort(this.sort);
                                }
                                if (this.onLoad) {
                                    this.onLoad({ franchises: franchises.slice() });
                                }

                                options.success(franchises);

                                if (this.franchiseId) {
                                    this.SelectById();
                                }
                            })
                            .catch(err => options.error(err));
                    }
                }
            }),
            dataTextField: FranchiseSelectorComponentController.DisplayProperty,
            dataValueField: nameof<CoreResources.IFranchise>(o => o.FranchiseId),
            select: (e) => {
                if (e.dataItem.FranchiseId || this.dropdown.dataSource.data().length > 0) {
                    this.selectedModel = e.dataItem;
                    this.franchiseId = this.selectedModel.FranchiseId;
                    this.SetState();
                    this.onSelect({ franchise: e.dataItem });
                }
            }
        };

        this.options = angular.merge({}, overridableOptions, this.extraOptions, permanentOptions);
    }

    $postLink()
    {

    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
        if (onChanges[nameof(this.state)]) {
            if (this.state === true) {
                this.state = {
                    franchiseIdParameter: "franchiseId"
                };
            }
        }

        if (onChanges[nameof(this.filters)]) {
            this.Refresh()
        }

        if (onChanges[nameof(this.franchiseId)]) {
            this.SelectById();
        }

        if (onChanges[nameof(this.disable)]) {
            if (this.dropdown) {
                this.dropdown.enable(!this.disable);
            }
        }

        if (onChanges[nameof(this.showInactive)]) {
            if (this.dropdown) {
                this.dropdown.dataSource.read();
            }
        }
    }

    SelectById()
    {
        if (this.dropdown) {
            this.dropdown.select(f => f.FranchiseId === this.franchiseId);
            this.dropdown.trigger("select", <kendo.ui.DropDownListSelectEvent>{
                dataItem: this.dropdown.dataItem(),
                sender: this.dropdown,
                item: this.dropdown.element
            });
        }
    }

    Refresh()
    {
        if (this.dropdown) {
            this.dropdown.dataSource.read();
        }
    }

    InitState()
    {
        if (this.state && typeof this.state === "object") {
            let searchParam = this.$location.search()[this.state.franchiseIdParameter];
            if (searchParam) {
                this.franchiseId = parseInt(searchParam);
            }
        }
    }

    SetState()
    {
        if (this.state && typeof this.state === "object") {
            this.$location.search(this.state.franchiseIdParameter, this.franchiseId);
        }
    }

    private GetSelectString()
    {
        if (!this.select)
            this.select = [];

        if (!this.select.some(s => s == nameof<CoreResources.IFranchise>(o => o.FranchiseId)))
            this.select.push(nameof<CoreResources.IFranchise>(o => o.FranchiseId));

        if (!this.select.some(s => s == nameof<CoreResources.IFranchise>(o => o.FranchisorId)))
            this.select.push(nameof<CoreResources.IFranchise>(o => o.FranchisorId));

        if (!this.select.some(s => s == nameof<CoreResources.IFranchise>(o => o.ConceptId)))
            this.select.push(nameof<CoreResources.IFranchise>(o => o.ConceptId));

        return this.select.join(",")
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("franchiseSelector", {
            bindings: {
                [nameof<FranchiseSelectorComponentController>(o => o.franchiseId)]: "<",
                [nameof<FranchiseSelectorComponentController>(o => o.select)]: "<",
                [nameof<FranchiseSelectorComponentController>(o => o.filters)]: "<",
                [nameof<FranchiseSelectorComponentController>(o => o.sort)]: "<",
                [nameof<FranchiseSelectorComponentController>(o => o.disable)]: "<",
                [nameof<FranchiseSelectorComponentController>(o => o.displayTextTemplate)]: "<",
                [nameof<FranchiseSelectorComponentController>(o => o.extraOptions)]: "<",
                [nameof<FranchiseSelectorComponentController>(o => o.showInactive)]: "<",
                [nameof<FranchiseSelectorComponentController>(o => o.onSelect)]: "&",
                [nameof<FranchiseSelectorComponentController>(o => o.onLoad)]: "&",
                [nameof<FranchiseSelectorComponentController>(o => o.state)]: "<",
                [nameof<FranchiseSelectorComponentController>(o => o.showOnlyTerminatedSixMonths)]: "<"
            },
            controller: FranchiseSelectorComponentController,
            template: `<select kendo-drop-down-list="$ctrl.dropdown"
                            k-options="$ctrl.options"></select>`,
        });
    }
}

