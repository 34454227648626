import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { SyncResources, SyncEntities } from "Interfaces/FranForce/Sync/SyncResources";
import { SyncResourceApi } from "Interfaces/FranForce/Sync/SyncResourceApiInterfaces";

export interface SyncTechnicianSimpleDetails
{
    SyncTechnicianId: number
    CreateDateTime: string,
    Email: string,
    Name: string,
    Phone: string,
};

export interface SyncEmployeeSimpleDetails
{
    SyncEmployeeId: number
    CreateDateTime: string,
    Email: string,
    Name: string,
    Phone: string,
};

export interface ParsedPosEmployeeId
{
    Id: number;
    Type: string;
}
export class SyncApiResources {

    SyncSourceTypesApi: SyncResourceApi.ISyncSourceTypeApi;
    SyncTechniciansApi: SyncResourceApi.ISyncTechnicianApi;
    SyncBusinessUnitApi: SyncResourceApi.ISyncBusinessUnitApi;
    SyncTechnicianCertificationApi: SyncResourceApi.ISyncTechnicianCertificationApi;
    SyncTechnicianCertificationTypeApi: SyncResourceApi.ISyncTechnicianCertificationTypeApi;
    SyncTechnicianBackgroundCheckApi: SyncResourceApi.ISyncTechnicianBackgroundCheckApi;

    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {
        this.RefreshResources();
    }

    RefreshResources() {
        this.SyncSourceTypesApi = this.$resource<SyncResources.ISyncSourceType>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SyncSourceTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.SyncTechniciansApi = this.$resource<SyncResources.ISyncTechnician>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SyncTechnicians/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.SyncBusinessUnitApi = this.$resource<SyncResources.ISyncBusinessUnit>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SyncBusinessUnits/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.SyncTechnicianCertificationApi = this.$resource<SyncResources.ISyncTechnicianCertification>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SyncTechnicianCertifications/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.SyncTechnicianCertificationTypeApi = this.$resource<SyncResources.ISyncTechnicianCertificationType>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SyncTechnicianCertificationTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.SyncTechnicianBackgroundCheckApi = this.$resource<SyncResources.ISyncTechnicianBackgroundCheck>(this.apiConfig.AppSettings.CoreApiRootUrl + "/SyncTechnicianBackgroundChecks/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
    }

    ParsePosEmployeeIdClaimValue(claimValue: string): ParsedPosEmployeeId
    {
        let parts = claimValue.split("-");
        return {
            Id: parseInt(parts[0]),
            Type: parts[1]
        };
    }
   
    GetAllSyncPermissionControllerNames(): ng.IHttpPromise<string[]> {
        
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/SyncHelper/ControllerNames"
        };

        return this.$http<string[]>(config);
    }

    SearchMasterCustomerByContactInformation(email1: string, phone1: string)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/MasterCustomers/SearchByContactDetails",
            params: {
                Email1: email1,
                Phone1: phone1,
                Phone1SyncContactTypeId: 4
            }
        };

        return this.$http<SyncEntities.DIH_Sync_CheckMCNExistsByContactInfo_Result>(config);
    }

    GetServiceHistoryByMCN(masterCustomerId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/MasterCustomers/" + masterCustomerId.toString() + "/ServiceHistory"
        };

        return this.$http<SyncEntities.McnServiceHistoryMasterCustomer>(config);
    }

    GetMasterCustomerInfoByMasterCustomerId(masterCustomerId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/MasterCustomers/" + masterCustomerId.toString()
        }

        return this.$http<SyncEntities.MasterCustomer>(config);
    }

    GetMasterCustomerListBySearchCriteria(firstName: string, lastName: string, address: string, city: string, state: string, postalCode: string, phone: string, email: string) 
    {
        let params = {
            FirstName: (firstName || ""),
            LastName: (lastName || ""),
            Address: (address || ""),
            City: (city || ""),
            State: (state || ""),
            PostalCode: (postalCode || ""),
            Phone: (phone || ""),
            Email: (email || "")
        };

        let config: ng.IRequestConfig = {
            method: "GET", 
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/MasterCustomers/SearchByCriteria",
            params: params
        }

        return this.$http<SyncEntities.usp_MasterCustomerList_GetBySearchCriteria_Result[]>(config);
    }

    GetSyncTechniciansInFranchises(franchiseId: number, options: Partial<ODataQueryParameters> = {})
    {
        let params = angular.extend(
            {},
            options
        );

        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/SyncTechnicians/ByFranchise/${franchiseId}`,
            params: params
        }

        return this.$http<SyncResources.ISyncTechnician[]>(config);  
    }

    GetSyncTechnicianSimpleDetails(syncTechnicianId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/SyncTechnicians/${syncTechnicianId}/SimpleDetails`,
        }

        return this.$http<SyncTechnicianSimpleDetails>(config);
    }

    GetSyncEmployeesInFranchises(franchiseId: number, options: Partial<ODataQueryParameters> = {})
    {
        let params = angular.extend(
            {},
            options
        );

        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/SyncEmployees/ByFranchise/${franchiseId}`,
            params: params
        }

        return this.$http<SyncResources.ISyncEmployee[]>(config);
    }

    GetSyncEmployeeSimpleDetails(syncEmployeeId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/SyncEmployees/${syncEmployeeId}/SimpleDetails`,
        }

        return this.$http<SyncEmployeeSimpleDetails>(config);
    }
}