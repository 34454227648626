import * as _ from "underscore"
import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";

import
{
  MeetTheTeamClient,MeetTheTeamInfoWithEmpBioDto
} from '@nbly/franchise-employee-orchestrations-clients'


export class MeetTheTeamGridComponent implements ng.IController
{
    franchiseId: number;
    conceptId: number;
    isLoading: boolean;


    //Clients
    employeeOrchestrationsClient: AxiosInstance;
    meetTheTeamClient:MeetTheTeamClient;


    //DTOS
     meetTheTeamInfoWithEmpBioDto:MeetTheTeamInfoWithEmpBioDto;

     openMeetTheTeamPopup: any;
     deleteMeetTheTeam:any;
     onOrderChange:any;

     isLoadingAfterDrag:boolean;


    static $inject = [
  

    ];

    constructor(

    )
    {
        this.employeeOrchestrationsClient = FranForceAxiosClientBuilder.BuildEmployeeOrchestrationsBaseClient();
        this.meetTheTeamClient = new MeetTheTeamClient("", this.employeeOrchestrationsClient);
   

    }

    async $onInit()
    {

    }

    async $postLink()
    {
  
    
    }

    onCardDrag(item,partFrom,partTo,indexFrom,indexTo){
     
        this.onOrderChange();
    }


 
    static BindComponent(app: ng.IModule)
    {
        app.component('meetTheTeamGridComponent', {
            bindings: {
                franchiseId: "<",
                meetTheTeamInfoWithEmpBioDto:"=",
                openMeetTheTeamPopup: "&",
                deleteMeetTheTeam: "&",
                onOrderChange:"&",
                isLoadingAfterDrag:"="
            },
            controller: MeetTheTeamGridComponent,
            controllerAs:"meetTheTeamGridController",
            templateUrl: "/Templates/MeetTheTeam/MeetTheTeamGridComponent.html"
        });
    }
}


