import { IFRMStateParams } from "Controllers/Rms/FranchisorRoyaltyManagement/frmRouter";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import * as _ from "underscore"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { AxiosInstance } from "axios";

import { IntegrationFinanceClient, IntegrationFinanceSourceTypeVm } from "@nbly/billing-orchestrations-clients"
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";

export class FRMItemsController implements ng.IController {

    readOnly: boolean;
    isLoading: boolean;

    itemsGrid: kendo.ui.Grid;
    itemsGridOptions: kendo.ui.GridOptions;
    itemFeesGrid: kendo.ui.Grid;
    itemFeesGridOptions: kendo.ui.GridOptions;

    feeTypes: RpmEntities.usp_RoyaltyFeeTypeResult[];

    financeSourceTypes: IntegrationFinanceSourceTypeVm[];

    static $inject = [
        '$scope',
        '$stateParams',
        'rpmUiApiResources',
        'identityManager',
        '$q'
    ];

    constructor(
        private $scope: ng.IScope,
        private $stateParams: IFRMStateParams,
        private rpmUiApiResources: RpmUiApiResources,
        private identityManager: IdentityManager,
        private $q: ng.IQService
    ) {
        this.billingOrchestrationsClient = FranForceAxiosClientBuilder.BuildBillingOrchestrationsBaseClient();
        this.integrationFinanceClient = new IntegrationFinanceClient("", this.billingOrchestrationsClient);
    }

    billingOrchestrationsClient: AxiosInstance;
    integrationFinanceClient: IntegrationFinanceClient

    $onInit() {
        this.isLoading = true;

        this.InitItemsGridOptions();
        this.InitItemFeesGridOptions();

        this.identityManager.HasRole(["FranchiseeManagementAdmin", "Admin"]).then(isAdmin => {
            this.readOnly = !isAdmin;
        })
            .then(() => {
                return this.Load();
            })
            .finally(() => {
                this.isLoading = false;
            });

    }

    InitItemsGridOptions()
    {
        const self = this;

        this.itemsGridOptions = {
            autoBind: false,
            editable: !this.readOnly,
            columns: [
                {
                    field: "RoyaltyItemId",
                    title: "Id",
                },
                {
                    field: "IntegrationFinanceSourceTypeId",
                    title: "Finance Source Type",
                    template:
                        "<span ng-bind='$ctrl.GetFinanceSourceById( #= IntegrationFinanceSourceTypeId #).integrationFinanceSourceTypeName'></span>",
                    editor: function (container, options) {
                        container
                            .append(`<input required name="${options.field}"/>`)

                        const inputElement = container.find("input");

                        inputElement.kendoDropDownList({
                            autoBind: false,
                            dataTextField: "integrationFinanceSourceTypeName",
                            dataValueField: "integrationFinanceSourceTypeId",
                            dataSource: {
                                data: self.financeSourceTypes
                            },
                            change: (e) => {
                                let dataItem = <RpmEntities.usp_RoyaltyItemResult>e.sender.dataItem();

                                if (!dataItem) {
                                    dataItem = this.itemsGrid.dataSource.data()[0];
                                }

                                options.model.set("integrationFinanceSourceTypeId", dataItem.IntegrationFinanceSourceTypeId);
                                options.model.set("ItemName", dataItem.Name);
                            }
                        })
                    }
                },
                {
                    field: "Name",
                    title: "Item"
                }, {
                    field: "Description",
                    title: "Description"
                }, {
                    command: [{ name: "destroy" }],
                    title: "&nbsp;",
                    width: "100px"
                }
            ],
            dataSource: new kendo.data.DataSource({
                schema: {
                    model: {
                        id: "RoyaltyItemId",
                        fields: {
                            RoyaltyItemId: {
                                type: "number",
                                defaultValue: 0,
                                editable: false
                            }
                        }
                    }
                },
                transport: {
                    read: (options) => {
                        this.rpmUiApiResources.GetRoyaltyItemsByFranchisor(parseInt(this.$stateParams.franchisorId))
                            .then((response) => {
                                options.success(response.data);
                            }, err => options.error(err));
                    },
                    create: (options) => {
                        let createModel: RpmEntities.usp_RoyaltyItemResult = options.data;
                        console.log("Calling Create", options.data);
                        this.rpmUiApiResources.CreateRoyaltyItem(createModel)
                            .then((response) => {
                                createModel.RoyaltyItemId = response.data;
                                options.success({ RoyaltyItemId: createModel.RoyaltyItemId });
                            }, (err) => { options.error(err); })
                    },
                    update: (options) => {
                        let updateModel: RpmEntities.usp_RoyaltyItemResult = options.data;
                        console.log("Calling Update", options.data);
                        this.rpmUiApiResources.UpdateRoyaltyItem(updateModel.RoyaltyItemId, updateModel)
                            .then((response) => {
                                options.success();
                            }, (err) => { options.error(err); });
                    },
                    destroy: (options) => {
                        let deleteModel: RpmEntities.usp_RoyaltyItemResult = options.data;
                        deleteModel.DeletedDateTime = new Date().toISOString();
                        this.rpmUiApiResources.UpdateRoyaltyItem(deleteModel.RoyaltyItemId, deleteModel)
                            .then((response) => {
                                options.success();
                            }, (err) => { options.error(err); });
                    }
                }
            })
        }
    }

    InitItemFeesGridOptions()
    {
        const self = this;

        this.itemFeesGridOptions = {
            autoBind: false,
            editable: !this.readOnly,
            columns: [
                {
                    field: "RoyaltyItemFeeTypeXrefId",
                    title: "Id"
                }, {
                    field: "IntegrationFinanceSourceTypeId",
                    title: "Finance Source Type",
                    template:
                        "<span ng-bind='$ctrl.GetFinanceSourceById( #= IntegrationFinanceSourceTypeId #).integrationFinanceSourceTypeName'></span>",
                    editor: function (container, options) {
                        container
                            .append(`<input required name="${options.field}"/>`)

                        const inputElement = container.find("input");

                        inputElement.kendoDropDownList({
                            autoBind: false,
                            dataTextField: "integrationFinanceSourceTypeName",
                            dataValueField: "integrationFinanceSourceTypeId",
                            dataSource: {
                                data: self.financeSourceTypes
                            },
                            change: (e) => {
                                let dataItem = <RpmEntities.usp_RoyaltyItemResult>e.sender.dataItem();

                                if (!dataItem) {
                                    dataItem = this.itemsGrid.dataSource.data()[0];
                                }

                                options.model.set("integrationFinanceSourceTypeId", dataItem.IntegrationFinanceSourceTypeId);
                                options.model.set("ItemName", dataItem.Name);
                            }
                        })
                    }
                },{
                    field: "RoyaltyItemId",
                    title: "Item",
                    template: "<span ng-bind='$ctrl.GetItemById( #= RoyaltyItemId #).Name'></span>",
                    editor: (container, options) => {
                        container.append("<input required name='" + options.field + "'/>");
                        let inputElement = container.find("input");
                        inputElement.kendoDropDownList({
                            dataTextField: "Name",
                            dataValueField: "RoyaltyItemId",
                            dataSource: {
                                data: _.filter(this.itemsGrid.dataSource.data(), i => i.RoyaltyItemId)
                            },
                            change: (e) => {
                                let dataItem = <RpmEntities.usp_RoyaltyItemResult>e.sender.dataItem();
                                if (!dataItem) {
                                    dataItem = this.itemsGrid.dataSource.data()[0];
                                }

                                options.model.set("RoyaltyItemId", dataItem.RoyaltyItemId);
                                options.model.set("ItemName", dataItem.Name);
                            }
                        });
                    }
                }, {
                    field: "RoyaltyFeeTypeId",
                    title: "Fee Type",
                    template: "<span ng-bind='$ctrl.GetFeeTypeById(#= RoyaltyFeeTypeId #).Name'></span>",
                    editor: (container, options) => {
                        container.append("<input required name='" + options.field + "'/>");
                        let inputElement = container.find("input");
                        inputElement.kendoDropDownList({
                            dataTextField: "Name",
                            dataValueField: "RoyaltyFeeTypeId",
                            dataSource: {
                                data: this.feeTypes
                            },
                            change: (e) => {
                                let dataItem = <RpmEntities.usp_RoyaltyFeeTypeResult>e.sender.dataItem();
                                if (!dataItem) {
                                    dataItem = this.feeTypes[0];
                                }

                                options.model.set("RoyaltyFeeTypeId", dataItem.RoyaltyFeeTypeId);
                            }
                        });
                    }
                },{
                    command: [{ name: "destroy" }],
                    title: "&nbsp;",
                    width: "100px"
                }
            ],
            dataSource: new kendo.data.DataSource({
                schema: {
                    model: {
                        id: "RoyaltyItemFeeTypeXrefId",
                        fields: {
                            RoyaltyItemFeeTypeXrefId: {
                                type: "number",
                                defaultValue: 0,
                                editable: false
                            }
                        }
                    }
                },
                transport: {
                    read: (options) => {
                        this.rpmUiApiResources.GetRoyaltyItemFeeTypesByFranchisor(parseInt(this.$stateParams.franchisorId))
                            .then((response) => {
                                options.success(response.data);
                            }, err => options.error(err));
                    },
                    create: (options) => {
                        let createModel: RpmEntities.usp_RoyaltyItemsRoyaltyFeeTypesXRefResult = options.data;
                        this.rpmUiApiResources.CreateRoyaltyItemFeeType(createModel)
                            .then((response) => {
                                createModel.RoyaltyItemFeeTypeXrefId = response.data;
                                options.success({ RoyaltyItemFeeTypeXrefId: createModel.RoyaltyItemFeeTypeXrefId });
                            }, (err) => { options.error(err); })
                    },
                    update: (options) => {
                        let updateModel: RpmEntities.usp_RoyaltyItemsRoyaltyFeeTypesXRefResult = options.data;
                        this.rpmUiApiResources.UpdateRoyaltyItemFeeType(updateModel.RoyaltyItemFeeTypeXrefId, updateModel)
                            .then((response) => {
                                options.success();
                            }, (err) => { options.error(err); });
                    },
                    destroy: (options) => {
                        let deleteModel: RpmEntities.usp_RoyaltyItemsRoyaltyFeeTypesXRefResult = options.data;
                        deleteModel.DeletedDateTime = new Date().toISOString();
                        this.rpmUiApiResources.UpdateRoyaltyItemFeeType(deleteModel.RoyaltyItemFeeTypeXrefId, deleteModel)
                            .then((response) => {
                                options.success();
                            }, (err) => { options.error(err); });
                    }
                }
            })
        }
    }

    async Load()
    {
        const financeSourceResult = await this.integrationFinanceClient.integrationFinanceSourceTypes()

        this.financeSourceTypes = financeSourceResult.result;

        return this.rpmUiApiResources.GetAllRoyaltyFeeTypes()
            .then(response => {
                this.feeTypes = response.data;
            })
            .then(() => {
                return this.$q.when(this.itemsGrid.dataSource.read().then(() => {
                    this.itemsGrid.refresh();
                }));
            })
            .then(() => {
                return this.$q.when(this.itemFeesGrid.dataSource.read().then(() => {
                    this.itemFeesGrid.refresh();
                    this.isLoading = false;
                }));
            });
    }

    AddNewItem()
    {
        if (!this.readOnly)
        {
            let model: RpmEntities.usp_RoyaltyItemResult = {
                RoyaltyItemId: 0,
                FranchisorId: parseInt(this.$stateParams.franchisorId),
                Name: "",
                Description: "",
                CreatedDateTime: new Date().toISOString(),
                CreatedUser: "",
                UpdatedDateTime: "",
                UpdatedUser: "",
                DeletedDateTime: null,
                IntegrationFinanceSourceTypeId: 0,
                IntegrationFinanceSourceTypeName: null
            };

            this.itemsGrid.dataSource.add(model);
        }
    }

    AddNewItemFee()
    {
        if (!this.readOnly)
        {
            let model: RpmEntities.usp_RoyaltyItemsRoyaltyFeeTypesXRefResult = {
                RoyaltyItemFeeTypeXrefId: 0,
                RoyaltyFeeTypeId: 0,
                RoyaltyItemId: 0,
                FranchisorID: parseInt(this.$stateParams.franchisorId),
                CreatedDateTime: new Date().toISOString(),
                CreatedUser: "",
                UpdatedDateTime: "",
                UpdatedUser: "",
                DeletedDateTime: null,
                IntegrationFinanceSourceTypeId: 0,
                IntegrationFinanceSourceTypeName: null
            };

            this.itemFeesGrid.dataSource.add(model);
        }
    }

    CancelItemChanges() {
        this.itemsGrid.cancelChanges();
    }

    CancelItemFeeChanges() {
        this.itemFeesGrid.cancelChanges();
    }

    GetFeeTypeById(id: number)
    {
        return _.find(this.feeTypes, f => f.RoyaltyFeeTypeId == id);
    }

    GetFinanceSourceById(id: number) {
        console.log(id);
        console.log(_.find(this.financeSourceTypes, f => f.integrationFinanceSourceTypeId == id))
        return _.find(this.financeSourceTypes, f => f.integrationFinanceSourceTypeId == id);
    }

    GetItemById(id: number)
    {
        return <RpmEntities.usp_RoyaltyItemResult & kendo.data.Model>this.itemsGrid.dataSource.get(id);
    }

    Save() {
        this.isLoading = true;
        this.$q.all([
            this.itemsGrid.dataSource.sync(),
            this.itemFeesGrid.dataSource.sync()
        ])
            .then(() => {
                return this.Load();
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}
