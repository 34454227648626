import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class FranchisorRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetFranchisorById(id: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/Franchisors/" + id
        };

        return this.$http<RpmEntities.FranchisorViewModel>(config);
    }

    SetFranchisorRoyaltyFields(id: number, royaltySystemId: number | null, royaltyRunDayOfWeek: number | null)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/Franchisors/" + id + "/SetRoyaltyFields",
            params: {
                royaltySystemId: royaltySystemId,
                royaltyRunDayOfWeek: royaltyRunDayOfWeek
            }
        };

        return this.$http<void>(config);
    }
}
