import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AngularUtil } from "Utility/AngularUtil";
import { EditUserStateService, userStateObj } from "Services/State/EditUserStateService";
import { IdentityResources } from "Interfaces/FranForce/Identity/IdentityResources";

export class EditUserEntityAccessComponentController implements ng.IController 
{
    managedUserId: string;
    refreshDate: Date;
    refresh: (params: { refreshedDate: Date }) => void;

    userState: userStateObj;
    isLoading: boolean;

    isViewable: boolean;
    currentEntityPermissions: IdentityResources.IEntityUserPermission[];
    newEntityPermission: IdentityResources.IEntityUserPermission;
    ruleGrid: kendo.ui.Grid;

    static $inject = [
        'editUserState',
        'coreApiResources',
        'identityManager',
        'apiConfig',
        '$q'
    ];

    constructor(
        private editUserState: EditUserStateService,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $q: ng.IQService
    )
    {
    }

    $onInit()
    {

    }

    $postLink()
    {
        let crudFieldTemplate = (dataItem: IdentityResources.IEntityUserPermission, field: string) =>
        {
            let iconClass = "";

            if (dataItem[field] === true)
            {
                iconClass = "fa fa-check icon-success";
            }
            else if (dataItem[field] === false)
            {
                iconClass = "fa fa-times icon-error";
            }
            else
            {
                iconClass = "fa fa-square-o";
            }

            return `<i class='${iconClass}' /></i>`;
        };

        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof<IdentityResources.IEntityUserPermission>(o => o.EntityName),
                title: "Rule",
                attributes: {
                    "class": "rule-name"
                },
                footerTemplate: `
                <div class='new-entity-name'>
                    <div class="section-information">
                        'Namespace.Classname' - Wildcards Allowed (* and ?)
                    </div>
                    <input type="text" ng-model="$ctrl.${nameof(this.newEntityPermission)}.${nameof<IdentityResources.IEntityUserPermission>(o => o.EntityName)}" />
                </div>
                `
            }, {
                field: nameof<IdentityResources.IEntityUserPermission>(o => o.CanRead),
                title: "Read",
                template: (dataItem: IdentityResources.IEntityUserPermission) =>
                {
                    return crudFieldTemplate(dataItem, nameof<IdentityResources.IEntityUserPermission>(o => o.CanRead));
                },
                width: 120,
                footerTemplate: `
                <tri-button ng-model="$ctrl.${nameof(this.newEntityPermission)}.${nameof<IdentityResources.IEntityUserPermission>(o => o.CanRead)}"
                    button-css-class="pure-button"
                    true-content="Allow Read"
                    false-content="Deny Read"
                    null-content="Read">
                </tri-button>`
            }, {
                field: nameof<IdentityResources.IEntityUserPermission>(o => o.CanCreate),
                title: "Create",
                template: (dataItem: IdentityResources.IEntityUserPermission) =>
                {
                    return crudFieldTemplate(dataItem, nameof<IdentityResources.IEntityUserPermission>(o => o.CanCreate));
                },
                width: 120,
                footerTemplate: `
                <tri-button ng-model="$ctrl.${nameof(this.newEntityPermission)}.${nameof<IdentityResources.IEntityUserPermission>(o => o.CanCreate)}"
                    button-css-class="pure-button"
                    true-content="Allow Create"
                    false-content="Deny Create"
                    null-content="Create">
                </tri-button>`
            }, {
                field: nameof<IdentityResources.IEntityUserPermission>(o => o.CanUpdate),
                title: "Update",
                template: (dataItem: IdentityResources.IEntityUserPermission) =>
                {
                    return crudFieldTemplate(dataItem, nameof<IdentityResources.IEntityUserPermission>(o => o.CanUpdate));
                },
                width: 120,
                footerTemplate: `
                <tri-button ng-model="$ctrl.${nameof(this.newEntityPermission)}.${nameof<IdentityResources.IEntityUserPermission>(o => o.CanUpdate)}"
                    button-css-class="pure-button"
                    true-content="Allow Update"
                    false-content="Deny Update"
                    null-content="Update">
                </tri-button>`
            }, {
                field: nameof<IdentityResources.IEntityUserPermission>(o => o.CanDelete),
                title: "Delete",
                template: (dataItem: IdentityResources.IEntityUserPermission) =>
                {
                    return crudFieldTemplate(dataItem, nameof<IdentityResources.IEntityUserPermission>(o => o.CanDelete));
                },
                width: 120,
                footerTemplate: `
                <tri-button ng-model="$ctrl.${nameof(this.newEntityPermission)}.${nameof<IdentityResources.IEntityUserPermission>(o => o.CanDelete)}"
                    button-css-class="pure-button"
                    true-content="Allow Delete"
                    false-content="Deny Delete"
                    null-content="Delete">
                </tri-button>`
            }, {
                field: nameof<IdentityResources.IEntityUserPermission>(o => o.Priority),
                title: "Priority",
                width: 120,
                footerTemplate: `<input kendo-numeric-text-box 
                    k-min='-10' k-max='10' k-decimals='0' k-format="'n0'" k-spinners='true' 
                    style='width: 90px'
                    k-ng-model='$ctrl.${nameof(this.newEntityPermission)}.${nameof<IdentityResources.IEntityUserPermission>(o => o.Priority)}' />`
            }, {
                title: "",
                template: `
                <button class="pure-button button-error"
                        promise-btn
                        ng-click="$ctrl.${nameof(this.DeleteEntityPermission)}(dataItem)">
                    X
                </button>`,
                width: 160,
                footerTemplate: `
                <button class="pure-button pure-button-primary"
                        promise-btn
                        ng-click="$ctrl.${nameof(this.AddEntityPermission)}()">
                    Add Rule
                </button>`
            }
        ];

        let datasource = new kendo.data.DataSource({
            transport: {
                read: (options) =>
                {
                    this.identityManager.EntityUserPermissionApi.query({ $filter: `AspNetUserId eq '${this.managedUserId}'` }).$promise
                        .then((currentPermissions) =>
                        {
                            this.ResetNewEntityRule();
                            options.success(currentPermissions);
                        });
                }
            }
        });

        let options: kendo.ui.GridOptions = {
            columns: columns,
            dataSource: datasource
        }

        this.ruleGrid.setOptions(options);
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        this.Load();
    }

    Load()
    {
        let promise = this.editUserState.Load(this.managedUserId)
            .then((result) =>
            {
                this.userState = result;
            })
            .then(() =>
            {
                this.isViewable = (this.userState.managedUser ? true : false) &&
                    this.userState.selfUser.Roles.some(r => r.Name == this.apiConfig.FranForceConstants.RoleConstants.AdminRole);

                this.RefreshCurrentPermissions();
            })

        return AngularUtil.TrackLoadingPromise(promise, this);
    }

    RefreshCurrentPermissions()
    {
        return this.$q.when(this.ruleGrid.dataSource.read())
            .then(() =>
            {
                this.ruleGrid.refresh();
            })
    }

    ResetNewEntityRule()
    {
        this.newEntityPermission = new this.identityManager.EntityUserPermissionApi();
        this.newEntityPermission.Priority = 0;
        this.newEntityPermission.CanCreate = null;
        this.newEntityPermission.CanDelete = null;
        this.newEntityPermission.CanRead = null;
        this.newEntityPermission.CanUpdate = null;
        this.newEntityPermission.AspNetUserId = this.managedUserId;
    }

    AddEntityPermission()
    {
        if (this.newEntityPermission && this.newEntityPermission.EntityName && this.newEntityPermission.AspNetUserId)
        {
            return this.newEntityPermission.$save()
                .then((permission) =>
                {
                    this.RefreshUser();

                    return this.$q.all([
                        this.RefreshCurrentPermissions(),
                        this.coreApiResources.ClearEntityRuleCache(this.userState.managedUser.Id)
                    ])
                        .then(() =>
                        {
                            return permission;
                        })
                })
                .catch(AngularUtil.GetJsonAlertCatch());
        }
        else
        {
            return this.$q.reject("Entity Permission Not Valid");
        }
    }

    DeleteEntityPermission(entityUserPermission: IdentityResources.IEntityUserPermission)
    {
        if (confirm("Are you sure you wish to delete this permission? '" + entityUserPermission.EntityName + "'"))
        {
            return this.identityManager.EntityUserPermissionApi.delete({ id: entityUserPermission.EntityUserPermissionId, allowHardDelete: true }).$promise
                .then(() =>
                {
                    this.RefreshUser();

                    return this.$q.all([
                        this.RefreshCurrentPermissions(),
                        this.coreApiResources.ClearEntityRuleCache(this.userState.managedUser.Id)
                    ]);
                })
                .catch(AngularUtil.GetJsonAlertCatch());
        }
        else
        {
            return this.$q.reject("Cancelled deletion of permission");
        }
    }

    RefreshUser()
    {
        this.editUserState.Clear(this.userState.managedUser.Id);
        if (this.refresh)
        {
            this.refresh({
                refreshedDate: new Date()
            });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("editUserEntityAccess", {
            bindings: {
                [nameof<EditUserEntityAccessComponentController>(o => o.managedUserId)]: "<",
                [nameof<EditUserEntityAccessComponentController>(o => o.refreshDate)]: "<",
                [nameof<EditUserEntityAccessComponentController>(o => o.refresh)]: "&"
            },
            controller: EditUserEntityAccessComponentController,
            templateUrl: "/Templates/Users/EditUser/EditUserEntityAccess.html"
        });
    }
}