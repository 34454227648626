

export type RmsContractManagementStateServiceParameters = {
    conceptId?: number;
    franchisorId?: number;
    franchiseId?: number;
    contractId?: number;
    section?: "general|royalties|territory";
};

export class RmsContractManagementStateService
{
    static $inject = [
        "$location"
    ]

    constructor(
        private $location: ng.ILocationService
    )
    {

    }

    Read(): RmsContractManagementStateServiceParameters
    {
        let params = this.$location.search();
        return {
            contractId: parseInt(params[nameof<RmsContractManagementStateServiceParameters>(o => o.contractId)]) || null,
            conceptId: parseInt(params[nameof<RmsContractManagementStateServiceParameters>(o => o.conceptId)]) || null,
            franchisorId: parseInt(params[nameof<RmsContractManagementStateServiceParameters>(o => o.franchisorId)]) || null,
            franchiseId: parseInt(params[nameof<RmsContractManagementStateServiceParameters>(o => o.franchiseId)]) || null,
            section: params[nameof<RmsContractManagementStateServiceParameters>(o => o.section)] || null
        };
    }

    RemoveParameter(prop: keyof RmsContractManagementStateServiceParameters)
    {
        this.$location.search(prop, null);
    }

    /**
     * Include property to change it.
     * Don't include property to leave it as is.
     * Set property to null to remove it.
     * @param params
     */
    SetParameters(params: RmsContractManagementStateServiceParameters)
    {
        this.$location.search(angular.extend(this.Read(), params));
    }
}