
import { ChangePasswordAccountController } from "Controllers/Account/ChangePasswordAccountController";
import { ForgotPasswordAccountController } from "Controllers/Account/ForgotPasswordAccountController";
import { IndexAccountController } from "Controllers/Account/IndexAccountController";
import { IndexAdminController } from "Controllers/Admin/IndexAdminController";
import { BreakingChangesApiHelpController } from "Controllers/ApiHelp/BreakingChangesApiHelpController";
import { IndexApiHelpController } from "Controllers/ApiHelp/IndexApiHelpController";
import { IndexBillingController } from "Controllers/Billing/IndexBillingController";
import { IndexCertificationsController } from "Controllers/Certifications/IndexCertificationsController";
import { EditConceptController } from "Controllers/Concept/EditConceptController";
import { IndexConceptController } from "Controllers/Concept/IndexConceptController";
import { IndexFranchisePostalCodesController } from "Controllers/FranchisePostalCodes/IndexFranchisePostalCodesController";
import { SearchFranchisePostalCodesController } from "Controllers/FranchisePostalCodes/SearchFranchisePostalCodesController";
import { NewFranchiseProfileController } from "Controllers/FranchiseProfile/NewFranchiseProfileController";
import { IndexFranchiseController } from "Controllers/Franchises/IndexFranchiseController";
import { EditFranchiseController } from "Controllers/Franchises/EditFranchiseController";
import { EditGiftCertificateController } from "Controllers/GiftCertificates/EditGiftCertificateController";
import { IndexGiftCertificateController } from "Controllers/GiftCertificates/IndexGiftCertificateController";
import { RedeemGiftCertificateController } from "Controllers/GiftCertificates/RedeemGiftCertificateController";
import { RefundGiftCertificateController } from "Controllers/GiftCertificates/RefundGiftCertificateController";
import { SearchGiftCertificateController } from "Controllers/GiftCertificates/SearchGiftCertificateController";
import { ReimbursementGiftCertificatesController } from "Controllers/GiftCertificates/ReimbursementGiftCertificateController";
import { ReimbursementBatchGiftCertificatesController } from "Controllers/GiftCertificates/ReimbursementBatchGiftCertificateController";
import { IndexHomeController } from "Controllers/Home/IndexHomeController";
import { IndexMasterCustomersController } from "Controllers/MasterCustomers/IndexMasterCustomersController";
import { IndexMsMollyDonationsController } from "Controllers/MsMollyDonations/IndexMsMollyDonationsController";
import { FranchiseeEmailMetricsReportController } from "Controllers/Reports/FranchiseeEmailMetricsReportController";
import { EditServiceController } from "Controllers/Services/EditServiceController";
import { IndexServiceController } from "Controllers/Services/IndexServiceController";
import { NewServiceController } from "Controllers/Services/NewServiceController";
import { MainMenuController } from "Controllers/Shared/MainMenuController";
import { UserPanelController } from "Controllers/Shared/UserPanelController";
import { IndexUserController } from "Controllers/Users/IndexUserController";
import { NewUserController } from "Controllers/Users/NewUserController";
import { EditUserController } from "Directives/Users/EditUserController";
import { PasswordUpdateAccountController } from "Controllers/Account/PasswordUpdateAccountController";

export function Init()
{
    let app = angular.module('portal');

    InitAccount(app);
    InitAdmin(app);
    InitApiHelp(app);
    InitBilling(app);
    InitCertifications(app);
    InitConcept(app);;
    InitFranchisePostalCodes(app);
    InitFranchiseProfile(app);
    InitFranchises(app);
    InitGiftCertificates(app);
    InitHome(app);
    InitMasterCustomers(app);
    InitMsMollyDonations(app);
    InitReports(app);
    InitServices(app);
    InitShared(app);
    InitUsers(app);

    return app;
}

function InitAccount(app: ng.IModule)
{
    app.controller('ChangePasswordAccountController', ChangePasswordAccountController);
    app.controller("ForgotPasswordAccountController", ForgotPasswordAccountController);
    app.controller('IndexAccountController', IndexAccountController);
    app.controller('PasswordUpdateAccountController', PasswordUpdateAccountController)
}

function InitAdmin(app: ng.IModule)
{
    app.controller('IndexAdminController', IndexAdminController);
}

function InitApiHelp(app: ng.IModule)
{
    app.controller('BreakingChangesApiHelpController', BreakingChangesApiHelpController);
    app.controller('IndexApiHelpController', IndexApiHelpController);
}

function InitBilling(app: ng.IModule)
{
    app.controller("IndexBillingController", IndexBillingController);
}

function InitCertifications(app: ng.IModule)
{
    app.controller("IndexCertificationsController", IndexCertificationsController);
}

function InitConcept(app: ng.IModule)
{
    app.controller("EditConceptController", EditConceptController);
    app.controller("IndexConceptController", IndexConceptController);
}

function InitFranchisePostalCodes(app: ng.IModule)
{
    app.controller("IndexFranchisePostalCodesController", IndexFranchisePostalCodesController);
    app.controller("SearchFranchisePostalCodesController", SearchFranchisePostalCodesController);
}

function InitFranchiseProfile(app: ng.IModule)
{
    app.controller('NewFranchiseProfileController', NewFranchiseProfileController);
}

function InitFranchises(app: ng.IModule)
{
    app.controller("EditFranchiseController", EditFranchiseController);
    app.controller("IndexFranchiseController", IndexFranchiseController);
}

function InitGiftCertificates(app: ng.IModule)
{
    app.controller("IndexGiftCertificateController", IndexGiftCertificateController);
    app.controller("EditGiftCertificateController", EditGiftCertificateController);
    app.controller("RedeemGiftCertificateController", RedeemGiftCertificateController);
    app.controller("RefundGiftCertificateController", RefundGiftCertificateController);
    app.controller("ReimbursementBatchGiftCertificatesController", ReimbursementBatchGiftCertificatesController);
    app.controller("ReimbursementGiftCertificatesController", ReimbursementGiftCertificatesController);
    app.controller("SearchGiftCertificateController", SearchGiftCertificateController);

}

function InitHome(app: ng.IModule)
{
    app.controller('IndexHomeController', IndexHomeController);
}

function InitMasterCustomers(app: ng.IModule)
{
    app.controller("IndexMasterCustomersController", IndexMasterCustomersController);
}

function InitMsMollyDonations(app: ng.IModule)
{
    app.controller("IndexMsMollyDonationsController", IndexMsMollyDonationsController);
}

function InitReports(app: ng.IModule)
{
    app.controller("FranchiseeEmailMetricsReportController", FranchiseeEmailMetricsReportController);
}

function InitServices(app: ng.IModule)
{
    app.controller('EditServiceController', EditServiceController);
    app.controller('IndexServiceController', IndexServiceController);
    app.controller('NewServiceController', NewServiceController);
}

function InitShared(app: ng.IModule)
{
    app.controller('MainMenuController', MainMenuController);
    app.controller('UserPanelController', UserPanelController);
}

function InitUsers(app: ng.IModule)
{
    app.controller('EditUserController', EditUserController);
    app.controller('IndexUserController', IndexUserController);
    app.controller('NewUserController', NewUserController);
}
