import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { AngularUtil } from "Utility/AngularUtil";
import { AxiosInstance } from "axios";
import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import * as _ from "underscore";
import
{
    PhotoGalleryCategoryConceptXrefClient, PhotoGalleryCategoryConceptXrefDto,
    PhotoGalleryCategoriesClient, PhotoGalleryCategoryDto
} from '@nbly/service-orchestrations-clients';
export class ConceptPhotoGalleryCategoriesController implements ng.IController
{
    //---- Bindings ----
    conceptId: number;
    //------------------


    isLoading: boolean = true;

    userHasFranchiseRole: boolean;
    user: IUserInfoViewModel;

    PhotoGalleryCategoryDto: PhotoGalleryCategoryDto[];
    PhotoGalleryCategoryConceptXrefDto: PhotoGalleryCategoryConceptXrefDto[];
    serviceOrchestrationsClient: AxiosInstance;
    photoGalleryCategoryConceptXrefClient: PhotoGalleryCategoryConceptXrefClient;
    photoGalleryCategoriesClient: PhotoGalleryCategoriesClient;
    selectedCategories: number[];
    originalSelectedCategories: number[];
    categoriesMultiSelect: kendo.ui.MultiSelect;
    categoriesMultiSelectOptions: kendo.ui.MultiSelectOptions;

    static $inject = [
        "coreApiResources",
        "$q",
        "$log",
        'identityManager',
        "apiConfig",
        "$scope"
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $scope: ng.IScope
    )
    {
        this.serviceOrchestrationsClient = FranForceAxiosClientBuilder.BuildServiceOrchestrationsBaseClient();
        this.photoGalleryCategoriesClient = new PhotoGalleryCategoriesClient("", this.serviceOrchestrationsClient);
        this.photoGalleryCategoryConceptXrefClient = new PhotoGalleryCategoryConceptXrefClient("", this.serviceOrchestrationsClient);

        identityManager.GetLoggedInUserInfo()
            .then((response) =>
            {
                this.user = response.data;
                this.userHasFranchiseRole = false;

                //skip attempting to set this if the user is an admin or concept manager.
                if (!this.user.Roles.some(r => this.apiConfig.FranForceConstants.RoleConstants.AllConceptManagementRoles.split(",").some(c => c.toLowerCase() == r.Name.toLowerCase())))
                {

                    //controls are hidden if logged in user is in franchise role.
                    this.userHasFranchiseRole = this.user.Roles.some(
                        r => r.Name.toLowerCase() == this.apiConfig.FranForceConstants.RoleConstants.FranchiseCSR.toLowerCase() ||
                            r.Name.toLowerCase() == this.apiConfig.FranForceConstants.RoleConstants.FranchiseeEmployeeRole.toLowerCase() ||
                            r.Name.toLowerCase() == this.apiConfig.FranForceConstants.RoleConstants.FranchiseeRole.toLowerCase()
                    );
                }
            });
    }

    async $onInit()
    {
        await this.loadCategoriesAndConceptCategories();
        this.SetDropdownOptions();

    }

    async loadConceptCategories(){
        this.selectedCategories=[];
        await this.photoGalleryCategoryConceptXrefClient.concept(this.conceptId).then((res) =>
        {
            this.PhotoGalleryCategoryConceptXrefDto = res.result;
            this.PhotoGalleryCategoryConceptXrefDto.map((value) =>
            {
                this.selectedCategories.push(value.photoGalleryCategoryId);
            })


            this.$scope.$digest();
        }).catch((err)=>{
            this.PhotoGalleryCategoryConceptXrefDto=[];
        });
        this.originalSelectedCategories = _.clone(this.selectedCategories);
    }

    async loadCategoriesAndConceptCategories()
    {
        await this.photoGalleryCategoriesClient.photoGalleryCategories().then((res) =>
        {
            this.PhotoGalleryCategoryDto = res.result as unknown as PhotoGalleryCategoryDto[];
            this.$scope.$digest();
        });
        await this.loadConceptCategories();
        this.isLoading = false;
    }


    async SetDropdownOptions()
    {
        this.categoriesMultiSelectOptions =
        {
            clearButton: false,
            placeholder: "Select categories...",
            dataTextField: "description",
            dataValueField: "photoGalleryCategoryId",
            valuePrimitive: true,
            autoBind: true,
            dataSource: this.PhotoGalleryCategoryDto,
            dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) =>
            {
                this.categoriesMultiSelect = e.sender;
            },
        };


    }

    async SaveCategories()
    {
        await Promise.all(
        this.originalSelectedCategories.map(async (value) =>
        {
            if (!this.selectedCategories.includes(value))
            {
                let object = this.PhotoGalleryCategoryConceptXrefDto.find(obj => obj.photoGalleryCategoryId === value);
                await this.photoGalleryCategoryConceptXrefClient.photoGalleryCategoryConceptXref3(
                    object.photoGalleryCategoryConceptXrefId
                )
                    .then(async (response) =>
                    {

                    }).catch(AngularUtil.GetJsonAlertCatch());
            }

        })
        );
        await Promise.all(
            this.selectedCategories.map(async (categoryId) =>
            {
                let object = this.PhotoGalleryCategoryConceptXrefDto.find(obj => obj.photoGalleryCategoryId === categoryId);
                if(!object){
                    let photoXrefDto: PhotoGalleryCategoryConceptXrefDto;
                    photoXrefDto = {
                        photoGalleryCategoryConceptXrefId: 0,
                        photoGalleryCategoryId: categoryId,
                        conceptId: this.conceptId,
                        createdDateTime: null,
                        updatedDateTime: null,
                        deletedDateTime: null
                    }
                    await this.photoGalleryCategoryConceptXrefClient.photoGalleryCategoryConceptXref(
                        photoXrefDto
                    )
                        .then(async (response) =>
                        {
    
                        }).catch(AngularUtil.GetJsonAlertCatch());
                }
            })

        ).then(async () =>
        {
            this.$scope.$digest();
        })
       await this.loadConceptCategories();
    }



    static BindComponent(app: ng.IModule)
    {
        app.component("conceptPhotoGalleryCategories", {
            bindings: {
                conceptId: "<"
            },
            controller: ConceptPhotoGalleryCategoriesController,
            templateUrl: "/Templates/Concepts/ConceptPhotoGalleryCategories.html",
        });
    }
}

