import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import * as _ from "underscore"
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";


export type FranchisorSelectorComponentControllerState = { franchisorIdParameter?: string };
export type FranchisorSelectorOnSelectParameters = { franchisor: kendo.data.ObservableObject & Partial<CoreResources.IFranchisor> };
export type FranchisorSelectorOnLoadParameters = { franchisors: Array<Partial<CoreResources.IFranchisor>> };

export class FranchisorSelectorComponentController implements ng.IController
{
    selectedModel: kendo.data.ObservableObject & Partial<CoreResources.IFranchisor>;
    dropdown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    //---- Bindings ----
    franchisorId: number;
    select: string[];
    filters: kendo.data.DataSourceFilter;
    sort: (a: Partial<CoreResources.IFranchisor>, b: Partial<CoreResources.IFranchisor>) => number;
    disable: boolean;
    displayTextTemplate: string;
    onSelect: (params: FranchisorSelectorOnSelectParameters) => void;
    onLoad: (params: FranchisorSelectorOnLoadParameters) => void;
    extraOptions: kendo.ui.DropDownListOptions;
    state: boolean | FranchisorSelectorComponentControllerState;
    royaltySystemIdExistsCheck: boolean;
    //------------------

    static readonly DisplayProperty = "DisplayText";

    static $inject = [
        "$q",
        "$interpolate",
        "$location",
        "coreApiResources",
        "identityManager",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $q: ng.IQService,
        private $interpolate: ng.IInterpolateService,
        private $location: ng.ILocationService,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {

    }

    $onInit()
    {
        if (!this.select || !(this.select instanceof Array)) {
            this.select = [
                nameof<CoreResources.IFranchisor>(o => o.FranchisorId),
                nameof<CoreResources.IFranchisor>(o => o.ConceptId),
                nameof<CoreResources.IFranchisor>(o => o.DisplayName)
            ];
        }

        if (!this.displayTextTemplate)
            this.displayTextTemplate = `{{${nameof<CoreResources.IFranchisor>(o => o.DisplayName)}}}`;

        if (!this.sort)
            this.sort = (a, b) => { return a[FranchisorSelectorComponentController.DisplayProperty].toLowerCase().localeCompare(b[FranchisorSelectorComponentController.DisplayProperty].toLowerCase()); }

        this.InitState();
        this.SetDropDownOptions();
    }

    SetDropDownOptions()
    {
        let overridableOptions: kendo.ui.DropDownListOptions = {
            autoWidth: true,
            filter: "contains",
            height: 450,
            enable: !this.disable
        };

        let permanentOptions: kendo.ui.DropDownListOptions = {
            optionLabel: {
                [FranchisorSelectorComponentController.DisplayProperty]: "Select Franchisor...",
                FranchisorId: null
            },
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options: kendo.data.DataSourceTransportReadOptions) =>
                    {
                        if (this.filters === undefined || this.filters === null) {
                            options.success([]);
                            return;
                        }

                        let filters: kendo.data.DataSourceFilters;
                        if (!_.isEmpty(this.filters)) {
                            filters = {
                                logic: "and",
                                filters: [
                                    this.filters
                                ]
                            };
                        }
                        else {
                            filters = {
                                logic: "and",
                                filters: [

                                ]
                            };
                        }

                        if (this.royaltySystemIdExistsCheck) {
                            let royaltySystemIdFilter: kendo.data.DataSourceFilterItem = {
                                field: nameof<CoreResources.IFranchisor>(o => o.RoyaltySystemId),
                                operator: "neq",
                                value: null
                            }
                            filters.filters.push(royaltySystemIdFilter)
                        }

                        let params: ODataQueryParameters = {};
                        params.$select = this.GetSelectString();
                        
                        if (!_.isEmpty(filters)) {
                            params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersOrItemToString(filters);
                        }

                        this.coreApiResources.FranchisorApi.query(params)
                            .$promise.then((franchisors) =>
                            {
                                for (let franchisor of franchisors) {
                                    franchisor[FranchisorSelectorComponentController.DisplayProperty] = this.$interpolate(this.displayTextTemplate)(franchisor);
                                }
                                if (this.sort) {
                                    franchisors = franchisors.sort(this.sort);
                                }
                                if (this.onLoad) {
                                    this.onLoad({ franchisors: franchisors.slice() });
                                }

                                options.success(franchisors);

                                if (this.franchisorId) {
                                    this.SelectById();
                                }
                            })
                            .catch(err => options.error(err));
                    }
                }
            }),
            dataTextField: FranchisorSelectorComponentController.DisplayProperty,
            dataValueField: nameof<CoreResources.IFranchisor>(o => o.FranchisorId),
            select: (e) =>
            {
                if (e.dataItem.FranchisorId || this.dropdown.dataSource.data().length > 0)
                {
                    this.selectedModel = e.dataItem;
                    this.franchisorId = this.selectedModel.FranchisorId;
                    this.SetState();
                    this.onSelect({ franchisor: e.dataItem });
                }
            }
        }

        this.options = angular.merge({}, overridableOptions, this.extraOptions, permanentOptions);
    }

    $postLink()
    {
    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
        if (onChanges[nameof(this.state)]) {
            if (this.state === true) {
                this.state = {
                    franchisorIdParameter: "franchisorId"
                };
            }
        }

        if (onChanges[nameof(this.filters)]) {
            this.Refresh()
        }

        if (onChanges[nameof(this.franchisorId)]) {
            this.SelectById();
        }

        if (onChanges[nameof(this.disable)]) {
            if (this.dropdown) {
                this.dropdown.enable(!this.disable);
            }
        }
    }

    SelectById()
    {
        if (this.dropdown) {
            this.dropdown.select(f => f.FranchisorId === this.franchisorId);
            this.dropdown.trigger("select", <kendo.ui.DropDownListSelectEvent>{
                dataItem: this.dropdown.dataItem(),
                sender: this.dropdown,
                item: this.dropdown.element
            });
        }
    }

    Refresh()
    {
        if (this.dropdown) {
            this.dropdown.dataSource.read();
        }
    }

    InitState()
    {
        if (this.state && typeof this.state === "object") {
            let searchParam = this.$location.search()[this.state.franchisorIdParameter];
            if (searchParam) {
                this.franchisorId = parseInt(searchParam);
            }
        }
    }

    SetState()
    {
        if (this.state && typeof this.state === "object") {
            this.$location.search(this.state.franchisorIdParameter, this.franchisorId);
        }
    }

    private GetSelectString()
    {
        if (!this.select)
            this.select = [];

        if (!this.select.some(s => s == nameof<CoreResources.IFranchisor>(o => o.FranchisorId)))
            this.select.push(nameof<CoreResources.IFranchisor>(o => o.FranchisorId));

        if (!this.select.some(s => s == nameof<CoreResources.IFranchisor>(o => o.ConceptId)))
            this.select.push(nameof<CoreResources.IFranchisor>(o => o.ConceptId));

        return this.select.join(",")
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("franchisorSelector", {
            bindings: {
                [nameof<FranchisorSelectorComponentController>(o => o.franchisorId)]: "<",
                [nameof<FranchisorSelectorComponentController>(o => o.select)]: "<",
                [nameof<FranchisorSelectorComponentController>(o => o.filters)]: "<",
                [nameof<FranchisorSelectorComponentController>(o => o.sort)]: "<",
                [nameof<FranchisorSelectorComponentController>(o => o.disable)]: "<",
                [nameof<FranchisorSelectorComponentController>(o => o.displayTextTemplate)]: "<",
                [nameof<FranchisorSelectorComponentController>(o => o.extraOptions)]: "<",
                [nameof<FranchisorSelectorComponentController>(o => o.onSelect)]: "&",
                [nameof<FranchisorSelectorComponentController>(o => o.onLoad)]: "&",
                [nameof<FranchisorSelectorComponentController>(o => o.state)]: "<",
                [nameof<FranchisorSelectorComponentController>(o => o.royaltySystemIdExistsCheck)]: "<"
            },
            controller: FranchisorSelectorComponentController,
            template: `<select kendo-drop-down-list="$ctrl.dropdown"
                            k-options="$ctrl.options"></select>`,
        });
    }
}

