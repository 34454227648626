
import { BillingFranchiseSectionsComponentController } from "Directives/Billing/BillingFranchiseSections";
import { BillingInvoiceGeneratorComponentController } from "Directives/Billing/BillingInvoiceGeneratorComponentController";
import { FranchiseBillingSubscriptionsComponentController } from "Directives/Billing/FranchiseBillingSubscriptionsComponentController";
import { FranchiseIntuitCompanyComponentController } from "Directives/Billing/FranchiseIntuitCompanyComponentController";
import { FranchisePosApiKeysComponentController } from "Directives/Billing/FranchisePosApiKeysComponentController";
import { CallCenterConceptNotificationConfigurationsComponentController } from "Directives/CallCenters/CallCenterConceptNotificationConfigurationsComponent";
import { CallCenterCorporateContactsComponentController } from "Directives/CallCenters/CallCenterCorporateContactsComponent";
import { CallCenterPhoneCampaignsComponentController } from "Directives/CallCenters/CallCenterPhoneCampaignsComponent";
import { CallCentersComponentController } from "Directives/CallCenters/CallCentersComponent";
import { ConceptDefaultFeeTypesComponentController } from "Directives/Concepts/ConceptDefaultFeeTypesComponent";
import { ConceptFranchiseLookupComponentController } from "Directives/Concepts/ConceptFranchiseLookup";
import { ConceptFranchiseLookupSearchComponentController } from "Directives/Concepts/ConceptFranchiseLookupSearch";
import { ConceptContactUsEmailComponentController } from "Directives/Concepts/ConceptContactUsEmail";
import { ConceptPhotoGalleryCategoriesController } from "Directives/Concepts/ConceptPhotoGalleryCategories";
import { ConceptHoursOfOperationController } from "Directives/Concepts/ConceptHoursOfOperationComponent";
import { ConceptLeadPauseTimeController } from "Directives/Concepts/ConceptLeadPauseTime";
import { ConceptLeadStateComponentController } from "Directives/Concepts/ConceptLeadState";
import { ConceptLeadThrottleRulesComponentController } from "Directives/Concepts/ConceptLeadThrottleRules";
import { ConceptWebThemeComponentController } from "Directives/Concepts/ConceptWebTheme";
import { serviceSkuSelectorDirectiveConfig } from "Directives/FranchiseProfiles/ServiceSkuSelector";
import { FranchiseEmailsComponentController } from "Directives/FranchiseEmails/FranchiseEmailsComponent";
import { FranchiseLeadStateComponentController } from "Directives/FranchiseProfiles/FranchiseLeadState";
import { FranchiseLeadThrottleDisplayComponentController } from "Directives/FranchiseProfiles/FranchiseLeadThrottleDisplayComponent";
import { FranchiseProfileBillingSubscriptionsComponentController } from "Directives/FranchiseProfiles/FranchiseProfileBillingSubscriptions";
import { FranchiseProfileContactAssignmentsController } from "Directives/FranchiseProfiles/FranchiseProfileContactAssignmentsComponent";
import { FranchiseProfileEmailsComponentController } from "Directives/FranchiseProfiles/FranchiseProfileEmailsComponent";
import { EditFranchiseProfileFeesController } from "Directives/FranchiseProfiles/FranchiseProfileFeesComponent";
import { FranchiseProfileHoursOfOperationController } from "Directives/FranchiseProfiles/FranchiseProfileHoursOfOperationComponent";
import { FranchiseProfileInstructionPopupController } from "Directives/FranchiseProfiles/FranchiseProfileInstructionPopupComponent";
import { FranchiseProfileServicesComponentController } from "Directives/FranchiseProfiles/FranchiseProfileServicesComponent";
import { heightToWindowBottomDirectiveConfig } from "Directives/Generic/HeightToWindowBottom";
import { selectOnClickDirectiveConfig } from "Directives/Generic/SelectOnClick";
import { triButtonDirectiveConfig } from "Directives/Generic/TriStateButton";
import { triContentDirectiveConfig } from "Directives/Generic/TriStateContent";
//import { EditUserCallCentersComponentController } from "Directives/Users/EditUserCallCenters";
//import { EditUserChangeEmailComponentController } from "Directives/Users/EditUserChangeEmail";
//import { EditUserChangePasswordComponentController } from "Directives/Users/EditUserChangePassword";
import { ZeeMailAccountCommentsController } from "Directives/ZeeMail/ZeeMailAccountComments";
import { ZeeMailCreateAccountComponentController } from "Directives/ZeeMail/ZeeMailCreateAccount";
import { ZeeMailDbaChangeComponentController } from "Directives/ZeeMail/ZeeMailDbaChange";
import { ZeeMailEditAccountComponentController } from "Directives/ZeeMail/ZeeMailEditAccount";
import { ZeeMailFranchiseDisplayComponentController } from "Directives/ZeeMail/ZeeMailFranchiseDisplay";
import { ZeeMailFranchiseEmailsComponentController } from "Directives/ZeeMail/ZeeMailFranchiseEmails";
import { ZeeMailFranchisorEnablerComponentController } from "Directives/ZeeMail/ZeeMailFranchisorEnabler";
import { ZeeMailRequestAccountChangeComponentController } from "Directives/ZeeMail/ZeeMailRequestAccountChange";
import { ZeeMailStatusChangeComponentController } from "Directives/ZeeMail/ZeeMailStatusChange";
import { ZeeMailTemplatesComponentController } from "Directives/ZeeMail/ZeeMailTemplates";
import { ZeeMailCreateAccountRequestComponentController } from "Directives/ZeeMail/ZeeMailCreateAccountRequest";
import { FranchiseProfilesGridComponentController } from "Directives/FranchiseProfiles/FranchiseProfilesGrid";
import { FranchiseProfileContactFinalAssignmentController } from "Directives/FranchiseProfiles/FranchiseProfileContactFinalAssignmentComponent";
import { FranchiseProfileOptInOptionsComponentController } from 'Directives/FranchiseProfiles/FranchiseProfileOptInOptions';
import { MollyMaidBrochureSubcriptionsComponent } from "Directives/Billing/MollyMaidBrochureSubcriptions";
import { ReferANeighborActivityReport } from "Directives/Reports/ReferANeighborActivityReport";
import { EditUserCallCentersComponentController } from "Directives/Users/EditUser/EditUserCallCenters";
import { LoginPageController } from "Directives/Account/LoginPageController";
import { AccountSettingsController } from "Directives/Account/AccountSettings";
import { UserAccountPanelController } from "Directives/Account/UserAccountPanel";
import { AccountResourceLinksController } from "Directives/Account/AccountResourceLinks";
import { EditUserChangeEmailComponentController } from "Directives/Users/EditUser/EditUserChangeEmail";
import { EditUserChangePasswordComponentController } from "Directives/Users/EditUser/EditUserChangePassword";
import { EditUserConceptOwnershipComponentController } from "Directives/Users/EditUser/EditUserConceptOwnership";
import { EditUserDppEmployeeAccessComponentController } from "Directives/Users/EditUser/EditUserDppEmployeeAccess";
import { EditUserEnabledComponentController } from "Directives/Users/EditUser/EditUserEnabled";
import { EditUserEntityAccessComponentController } from "Directives/Users/EditUser/EditUserEntityAccess";
import { EditUserFranchiseEmployeeOwnershipComponentController } from "Directives/Users/EditUser/EditUserFranchiseEmployeeOwnership";
import { EditUserFranchiseOwnershipComponentController } from "Directives/Users/EditUser/EditUserFranchiseOwnership";
import { EditUserFranchisePosEmployeeComponentController } from "Directives/Users/EditUser/EditUserFranchisePosEmployee";
import { EditUserNameComponentController } from "Directives/Users/EditUser/EditUserName";
import { EditUserPosEmployeeDisplayComponentController } from "Directives/Users/EditUser/EditUserPosEmployeeDisplay";
import { EditUserRolesComponentController } from "Directives/Users/EditUser/EditUserRoles";
import { EditUserController } from "Directives/Users/EditUserController";
import { FranchiseProfileContactsComponent } from "Directives/FranchiseProfiles/FranchiseProfileContactsComponent";
import { FranchiseProfileContactsListComponent } from "Directives/FranchiseProfiles/FranchiseProfileContactsListComponent";
import { FranchiseProfileCallCenterDetailsComponent } from "Directives/FranchiseProfiles/FranchiseProfileCallCenterDetailsComponent";
import { FranchiseProfileEditComponent } from "Directives/FranchiseProfiles/FranchiseProfileEdit";
import { FranchiseProfileEmployeeBiosComponent } from "Directives/FranchiseProfiles/FranchiseProfileEmployeeBiosComponent";
import { FranchiseProfilePhotosComponent } from "Directives/FranchiseProfiles/FranchiseProfilePhotosComponent";
import { EmployeeBiosComponent } from "Directives/EmployeeBios/EmployeeBiosComponent";
import { PhotoEditorComponent } from "Directives/PhotoEditor/PhotoEditorComponent";
import { PhotoEditorGridViewComponent } from "Directives/PhotoEditor/PhotoEditorGridViewComponent";
import { PhotoCorporateGridViewComponent } from "Directives/PhotoEditor/PhotoCorporateGridViewComponent";
import { PhotoEditorMetaInfoComponent } from "Directives/PhotoEditor/PhotoEditorMetaInfoComponent";
import { UploadImageComponent } from "Directives/Common/UploadImage";
import { FranchiseProfileEmployeeBioEditComponent } from "Directives/FranchiseProfiles/FranchiseProfileEmployeeBioEditComponent";
import { FranchisePushLeadOptionController } from "Directives/FranchiseProfiles/FranchisePushLeadOptions";
import { ConceptPushLeadOptionController } from "Directives/Concepts/ConceptPushLeadOptions";
import { ConceptServicesComponent } from "Directives/Concepts/ConceptServicesComponent";
import { CategoriesComponentController } from "Directives/KBS/CategoriesComponent";
import { ItemsComponentController } from "Directives/KBS/ItemsComponent";
import { ItemTypesComponentController } from "Directives/KBS/ItemTypesComponent";
import { SectionsComponentController } from "Directives/KBS/SectionsComponent";
import { FranchiseProfileWebsiteDetails } from "Directives/FranchiseProfiles/FranchiseProfileWebsiteDetails";
import { EmployeeBiosGridComponentController } from "Directives/EmployeeBios/EmployeeBiosGrid";
import { EmployeeBiosGeneralInfoComponent } from "Directives/EmployeeBios/EmployeeBiosGeneralInfoComponent";
import { EmployeeBiosCertificationComponent } from "Directives/EmployeeBios/EmployeeBiosCertificationComponent";
import { EmployeeBiosEducationComponent } from "Directives/EmployeeBios/EmployeeBiosEducationComponent";
import { EmployeeBiosLanguageComponent } from "Directives/EmployeeBios/EmployeeBiosLanguageComponent";
import { EmployeeBiosImageComponent } from "Directives/EmployeeBios/EmployeeBiosImageComponent";
import { EmployeeBiosPosComponent } from "Directives/EmployeeBios/EmployeeBiosPosComponent";
import { DownTimeNotificationComponentController } from "Directives/DownTimeNotification/DownTimeNotificationComponent";
import { DisplayDowntimeNotificationController } from "Directives/DownTimeNotification/DisplayDowntimeNotificationComponent";
import {MeetTheTeamComponent} from "Directives/MeetTheTeam/MeetTheTeamComponent";
import {MeetTheTeamGridComponent} from "Directives/MeetTheTeam/MeetTheTeamGridComponent";
import {MeetTheTeamPopupComponent} from "Directives/MeetTheTeam/MeetTheTeamPopupComponent"
import {FindANeighborComponent} from "Directives/FindANeighbor/FindANeighborComponent";
import {FindANeighborFilterBar} from "Directives/FindANeighbor/FindANeighborFilterBar";
import { ComplianceEmailsComponent } from "Directives/ComplianceEmails/ComplianceEmailsComponent"






import * as CommonComponents from "Directives/Common";
import { RpmDataSyncComponentController } from "../Directives/ServiceManager/RpmDataSyncComponent";
import { RpmDataSyncAppfolioController } from "../Directives/ServiceManager/RpmDataSyncAppfolio";
import { RpmDataSyncPropertywareController } from "../Directives/ServiceManager/RpmDataSyncPropertyware";
import { BillingSubscriptionImportComponentController } from "../Directives/Billing/BillingSubscriptionImport";
import { GenerateBillingInvoicesComponentController } from "../Directives/Billing/GenerateBillingInvoices/GenerateBillingInvoices";
import { billingInvoicesTaxGridComponentController } from "../Directives/Billing/GenerateBillingInvoices/BillingInvoicesTaxGrid";
import { BillingInvoiceTaxTrackerGridComponentController } from "../Directives/Billing/GenerateBillingInvoices/BillingInvoiceTaxTrackerGrid";
import { CesReports } from "../Directives/Reports/CesReports";
import { ReportDetails } from "../Directives/Reports/ReportDetails";
import { BillingConceptMultiSelectComponentController } from "../Directives/Billing/Common/BillingConceptMultiSelect";
import { FranchiseMultiSelectorComponentController } from "../Directives/Reports/Common/FranchiseMultiSelect";
import { LoanAmortizationComponentController } from "../Directives/LoanAmortization/LoanAmortization";
import { PasswordPopupComponent } from "../Directives/PasswordPopup/PasswordPopupComponent";
import { PasswordValidationComponent } from "../Directives/PasswordPopup/PasswordValidationComponent";
import { BillingInvoiceEmailCountPopupController } from "../Directives/Billing/GenerateBillingInvoices/BillingInvoiceEmailCountPopup";
import { CesMLYChurnRiskCustomersController } from "../Directives/Reports/CesMLYChurnRiskCustomers";
import { CesRoyaltyReportInvoicesByPostalCodeController } from "../Directives/Reports/CesRoyaltyReportInvoicesByPostalCode";
/*import { LicenseNumberSelectorComponentController } from "../Directives/Common/CesReportsLicenseNumberSelector";*/
import { BroadlyAdminConfigComponent } from "Directives/AdminConfig/BroadlyAdminConfig";
import { VendorAdminConfigComponent } from "Directives/AdminConfig/VendorAdminConfig";
import { MollyDonationsShelterComponent } from "Directives/MollyDonationsShelter/MollyDonationsShelterComponent";

export function Init()
{
    let app = angular.module('portal');

    InitAccount(app);
    InitBilling(app);
    InitCallCenters(app);
    InitCommon(app);
    InitConcepts(app);
    InitFranchiseEmails(app);
    InitFranchiseProfiles(app);
    InitGeneric(app);
    InitReports(app);
    InitUsers(app);
    InitZeeMail(app);
    InitKbs(app);
    InitServeiceManager(app);
    InitEmployeeBios(app);
    InitPhotoEditor(app);
    InitMeetTheTeam(app);
    InitDownTimeNotification(app);
    InitFindANeighbor(app);
    InitLoanAmortization(app);
    InitPasswordExpirationComponent(app);
    InitMollyShelterComponent(app);
}

function InitAccount(app: ng.IModule)
{
    LoginPageController.BindComponent(app);
    AccountSettingsController.BindComponent(app);
    UserAccountPanelController.BindComponent(app);
    AccountResourceLinksController.BindComponent(app);
}

function InitBilling(app: ng.IModule) 
{
    BillingFranchiseSectionsComponentController.BindComponent(app);
    BillingInvoiceGeneratorComponentController.BindComponent(app);
    FranchiseBillingSubscriptionsComponentController.BindComponent(app);
    FranchiseIntuitCompanyComponentController.BindComponent(app);
    FranchisePosApiKeysComponentController.BindComponent(app);
    MollyMaidBrochureSubcriptionsComponent.BindComponent(app);
    BillingSubscriptionImportComponentController.BindComponent(app);
    GenerateBillingInvoicesComponentController.BindComponent(app);
    billingInvoicesTaxGridComponentController.BindComponent(app);
    BillingInvoiceTaxTrackerGridComponentController.BindComponent(app);
    BillingConceptMultiSelectComponentController.BindComponent(app);
    BillingInvoiceEmailCountPopupController.BindComponent(app);
}

function InitCallCenters(app: ng.IModule) 
{
    CallCenterConceptNotificationConfigurationsComponentController.BindComponent(app);
    CallCenterCorporateContactsComponentController.BindComponent(app);
    CallCenterPhoneCampaignsComponentController.BindComponent(app);
    CallCentersComponentController.BindComponent(app);
}

function InitDownTimeNotification(app: ng.IModule) {
    DownTimeNotificationComponentController.BindComponent(app);
    DisplayDowntimeNotificationController.BindComponent(app);
}

function InitCommon(app: ng.IModule) 
{
    CommonComponents.BusinessInfoDisplayComponentController.BindComponent(app);
    CommonComponents.ConceptFranchiseSelectorComponentController.BindComponent(app);
    CommonComponents.ConceptFranchiseSelectorOldComponentController.BindComponent(app);
    CommonComponents.ConceptFranchisorFranchiseSelectorComponentController.BindComponent(app);
    CommonComponents.ConceptFranchisorSelectorComponentController.BindComponent(app);
    CommonComponents.ConceptSelectorComponentController.BindComponent(app);
    CommonComponents.ConceptMultiSelectorComponentController.BindComponent(app);
    CommonComponents.FranchiseMultiSelectorComponentController.BindComponent(app);
    CommonComponents.ConceptSelectorOldComponentController.BindComponent(app);
    CommonComponents.DayOfWeekSelectorComponentController.BindComponent(app);
    CommonComponents.FeeTypeComponentController.BindComponent(app);
    CommonComponents.FeeTypesComponentController.BindComponent(app);
    CommonComponents.FormErrorDisplayComponentController.BindComponent(app);
    CommonComponents.FranchiseSelectorComponentController.BindComponent(app);
    CommonComponents.CesReportsFranchiseSelectorComponentController.BindComponent(app);
    CommonComponents.FranchisorSelectorComponentController.BindComponent(app);
    CommonComponents.HoursOfOperationController.BindComponent(app);
    CommonComponents.LoadingGearsOverlayComponentController.BindComponent(app);
    CommonComponents.OpenTimeEditorComponentController.BindComponent(app);
    CommonComponents.LoadingSpinnerComponentController.BindComponent(app);
    CommonComponents.ToggleCheckboxComponent.BindComponent(app);
    CommonComponents.UploadImageComponent.BindComponent(app);
    CommonComponents.MonthsEnabledController.BindComponent(app);
    CommonComponents.YearSelector.BindComponent(app);
    CommonComponents.MonthSelector.BindComponent(app);
    CommonComponents.WeekEndingDateSelector.BindComponent(app);
    CommonComponents.QuarterSelector.BindComponent(app);
    CommonComponents.FranchiseStatusSelector.BindComponent(app);
    CommonComponents.TechnicianMultiSelectorComponentController.BindComponent(app);
    CommonComponents.MessageInfoModalComponentController.BindComponent(app);
    CommonComponents.SyncEmployeesByFranchisesMultiSelectorComponentController.BindComponent(app);
    CommonComponents.ContractTerritoryTypeSelectorComponentController.BindComponent(app);
    CommonComponents.SnackbarComponentController.BindComponent(app);
}

function InitConcepts(app: ng.IModule) 
{
    ConceptDefaultFeeTypesComponentController.BindComponent(app);
    ConceptFranchiseLookupComponentController.BindComponent(app);
    ConceptFranchiseLookupSearchComponentController.BindComponent(app);
    ConceptContactUsEmailComponentController.BindComponent(app);
    ConceptPhotoGalleryCategoriesController.BindComponent(app);
    ConceptHoursOfOperationController.BindComponent(app);
    ConceptLeadStateComponentController.BindComponent(app);
    ConceptLeadPauseTimeController.BindComponent(app);
    ConceptLeadThrottleRulesComponentController.BindComponent(app);
    ConceptWebThemeComponentController.BindComponent(app);
    ConceptPushLeadOptionController.BindComponent(app);
    ConceptServicesComponent.BindComponent(app);
}

function InitFranchiseEmails(app: ng.IModule) 
{
    ComplianceEmailsComponent.BindComponent(app);
    FranchiseEmailsComponentController.BindComponent(app);
}

function InitPhotoEditor(app:ng.IModule){
    PhotoEditorComponent.BindComponent(app);
    PhotoEditorMetaInfoComponent.BindComponent(app);
    PhotoEditorGridViewComponent.BindComponent(app);
    PhotoCorporateGridViewComponent.BindComponent(app);
}

function InitMeetTheTeam(app:ng.IModule){
    MeetTheTeamComponent.BindComponent(app);
    MeetTheTeamGridComponent.BindComponent(app);
    MeetTheTeamPopupComponent.BindComponent(app);

}

function InitFindANeighbor(app:ng.IModule){

    FindANeighborComponent.BindComponent(app);
    FindANeighborFilterBar.BindComponent(app);

}

function InitEmployeeBios(app: ng.IModule)
{
    EmployeeBiosGridComponentController.BindComponent(app);
    EmployeeBiosComponent.BindComponent(app);
    EmployeeBiosGeneralInfoComponent.BindComponent(app);
    EmployeeBiosCertificationComponent.BindComponent(app);
    EmployeeBiosEducationComponent.BindComponent(app);
    EmployeeBiosLanguageComponent.BindComponent(app);
    EmployeeBiosImageComponent.BindComponent(app);
    EmployeeBiosPosComponent.BindComponent(app);
}

function InitFranchiseProfiles(app: ng.IModule) 
{
    FranchiseProfileEditComponent.BindComponent(app);
    FranchiseLeadStateComponentController.BindComponent(app);
    FranchiseLeadThrottleDisplayComponentController.BindComponent(app);
    FranchiseProfileBillingSubscriptionsComponentController.BindComponent(app);
    FranchiseProfileContactAssignmentsController.BindComponent(app);
    FranchiseProfileContactsComponent.BindComponent(app);
    FranchiseProfileContactsListComponent.BindComponent(app);
    FranchiseProfileCallCenterDetailsComponent.BindComponent(app);
    FranchiseProfileEmailsComponentController.BindComponent(app);
    EditFranchiseProfileFeesController.BindComponent(app);
    FranchiseProfileHoursOfOperationController.BindComponent(app);
    FranchiseProfileInstructionPopupController.BindComponent(app);
    FranchiseProfileServicesComponentController.BindComponent(app);
    FranchiseProfilesGridComponentController.BindComponent(app);
    FranchiseProfileContactFinalAssignmentController.BindComponent(app);
    FranchiseProfileOptInOptionsComponentController.BindComponent(app);
    FranchiseProfileEmployeeBiosComponent.BindComponent(app);
    FranchiseProfilePhotosComponent.BindComponent(app);
    FranchiseProfileEmployeeBioEditComponent.BindComponent(app);
    FranchisePushLeadOptionController.BindComponent(app);
    FranchiseProfileWebsiteDetails.BindComponent(app);

    app.directive("serviceSkuSelector", serviceSkuSelectorDirectiveConfig);
}

function InitGeneric(app: ng.IModule) 
{
    app.directive('heightToWindowBottom', heightToWindowBottomDirectiveConfig );
    app.directive('selectOnClick', selectOnClickDirectiveConfig );
    app.directive("triButton", triButtonDirectiveConfig);
    app.directive("triContent", triContentDirectiveConfig);
}

function InitReports(app: ng.IModule) 
{
    ReferANeighborActivityReport.BindComponent(app);
    CesReports.BindComponent(app);
    ReportDetails.BindComponent(app);
    FranchiseMultiSelectorComponentController.BindComponent(app);
    CesMLYChurnRiskCustomersController.BindComponent(app);
    CesRoyaltyReportInvoicesByPostalCodeController.BindComponent(app);
    /*LicenseNumberSelectorComponentController.BindComponent(app);*/
}

function InitUsers(app: ng.IModule) 
{
    EditUserCallCentersComponentController.BindComponent(app);
    EditUserChangeEmailComponentController.BindComponent(app);
    EditUserChangePasswordComponentController.BindComponent(app);
    EditUserConceptOwnershipComponentController.BindComponent(app);
    EditUserDppEmployeeAccessComponentController.BindComponent(app);
    EditUserEnabledComponentController.BindComponent(app);
    EditUserEntityAccessComponentController.BindComponent(app);
    EditUserFranchiseEmployeeOwnershipComponentController.BindComponent(app);
    EditUserFranchiseOwnershipComponentController.BindComponent(app);
    EditUserFranchisePosEmployeeComponentController.BindComponent(app);
    EditUserNameComponentController.BindComponent(app);
    EditUserPosEmployeeDisplayComponentController.BindComponent(app);
    EditUserRolesComponentController.BindComponent(app);
    EditUserController.BindComponent(app);
}

function InitZeeMail(app: ng.IModule) 
{
    ZeeMailAccountCommentsController.BindComponent(app);
    ZeeMailCreateAccountComponentController.BindComponent(app);
    ZeeMailDbaChangeComponentController.BindComponent(app);
    ZeeMailEditAccountComponentController.BindComponent(app);
    ZeeMailFranchiseDisplayComponentController.BindComponent(app);
    ZeeMailFranchiseEmailsComponentController.BindComponent(app);
    ZeeMailFranchisorEnablerComponentController.BindComponent(app);
    ZeeMailRequestAccountChangeComponentController.BindComponent(app);
    ZeeMailStatusChangeComponentController.BindComponent(app);
    ZeeMailTemplatesComponentController.BindComponent(app);
    ZeeMailCreateAccountRequestComponentController.BindComponent(app);
}

function InitKbs(app: ng.IModule) {
    CategoriesComponentController.BindComponent(app);
    ItemsComponentController.BindComponent(app);
    ItemTypesComponentController.BindComponent(app);
    SectionsComponentController.BindComponent(app);
}

function InitServeiceManager(app: ng.IModule) {
    RpmDataSyncComponentController.BindComponent(app);
    RpmDataSyncAppfolioController.BindComponent(app);
    RpmDataSyncPropertywareController.BindComponent(app);
}

function InitLoanAmortization(app: ng.IModule) {
    LoanAmortizationComponentController.BindComponent(app);
}

function InitPasswordExpirationComponent(app: ng.IModule) {
    PasswordPopupComponent.BindComponent(app);
    PasswordValidationComponent.BindComponent(app);
    BroadlyAdminConfigComponent.BindComponent(app);
    VendorAdminConfigComponent.BindComponent(app);
}

function InitMollyShelterComponent(app: ng.IModule) {
    MollyDonationsShelterComponent.BindComponent(app);
}