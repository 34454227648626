import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { PromiseUtil } from "../../Utility/PromiseUtil";
import {FranchiseWebLocationClient,FranchiseWebLocationConceptDto} from '@nbly/franchise-group-api-clients';
import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";

export class ConceptLeadStateComponentController implements ng.IController
{
    //---- Bindings ----
    conceptId: number;
    //------------------

    isLoading: boolean = false;
    concept: Partial<CoreResources.IConcept>;
    selectedFranchise: Partial<CoreResources.IFranchise>;
    franchiseSelectorFilter: kendo.data.DataSourceFilterItem;
    webLocationListOptions: kendo.ui.DropDownListOptions;

    franhiseGroupClient: AxiosInstance;
    franchiseWebLocationClient: FranchiseWebLocationClient;
    franchiseWebLocationConceptDto:FranchiseWebLocationConceptDto[];
    selectedWebLocation:number=null;
    showBlackOutDates:boolean=false;
    
    static $inject = [
        "coreApiResources",
        "callCenterApiResources",
        "$q",
        "$log"
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private callCenterApiResources: CallCenterApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService
    )
    {
        this.franhiseGroupClient = FranForceAxiosClientBuilder.BuildFranchiseGroupBaseClient();
        this.franchiseWebLocationClient = new FranchiseWebLocationClient("", this.franhiseGroupClient);
    }

    $onInit()
    {
    }

    $postLink()
    {
       
    }

    async SetDropdownOptions()
    {
        this.webLocationListOptions = {
            filter: "contains",
            valuePrimitive: false,
            dataValueField: nameof.full<FranchiseWebLocationConceptDto>(o => o.franchiseWebLocationId),
            dataTextField: nameof.full<FranchiseWebLocationConceptDto>(o => o.doingBusinessAs),
            autoBind: true,
            optionLabel: 'Select Web Location',
            dataSource: new kendo.data.DataSource({
                data: this.franchiseWebLocationConceptDto
            }),


        };
    }


    async $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.conceptId)]) 
        {
            this.franchiseSelectorFilter = {
                field: nameof<CoreResources.IFranchise>(o => o.ConceptId),
                operator: "eq",
                value: this.conceptId
            }
            this.selectedFranchise = null;

            if (this.conceptId > 0)
            {
                this.concept = await this.coreApiResources.ConceptApi.get({
                    id: this.conceptId,
                    $select: [
                        nameof<CoreResources.IConcept>(o => o.ConceptCode),
                        nameof<CoreResources.IConcept>(o => o.LeadStateEnabled),
                    ].join(",")
                }).$promise;

                await this.getWebLocationsByConcept();



            }
            else
                this.concept = null;
        }
    }

    async getWebLocationsByConcept(){
        this.franchiseWebLocationClient.get_protected_franchiseweblocations_conceptid(this.conceptId)
        .then((response)=>{
            this.franchiseWebLocationConceptDto=response.result;
            this.SetDropdownOptions();
        })
    }

    async ToggleLeadStateEnabled(newValue)
    {
        let prevValue = this.concept.LeadStateEnabled;

        this.concept.LeadStateEnabled = newValue;
        try
        {
            return await PromiseUtil.TrackLoadingPromise(this,
                this.coreApiResources.ConceptApi.patch({
                    id: this.conceptId,
                    LeadStateEnabled: this.concept.LeadStateEnabled
                } as Partial<CoreResources.IFranchiseProfile>).$promise
            );
        }
        catch (err)
        {
            console.error(err);
            this.concept.LeadStateEnabled = prevValue;
        }
    }

    SelectFranchise(franchise: Partial<CoreResources.IFranchise>)
    {
        this.selectedFranchise = franchise;
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("conceptLeadState", {
            bindings: {
                conceptId: "<"
            },
            controller: ConceptLeadStateComponentController,
            templateUrl: "/Templates/Concepts/ConceptLeadState.html",
        });
    }
}

