import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AngularUtil } from "Utility/AngularUtil";
import { EditUserStateService, userStateObj } from "Services/State/EditUserStateService";
import { Helpers } from "Utility/Helpers";

export class EditUserEnabledComponentController implements ng.IController 
{
    managedUserId: string;
    refreshDate: Date;
    refresh: (params: { refreshedDate: Date }) => void;

    userState: userStateObj;
    isLoading: boolean;

    isAdmin: boolean;
    daysToExpiry: number;
    passwordExpirationDate: string;

    static $inject = [
        'editUserState',
        'identityManager',
        'apiConfig',
    ];

    constructor(
        private editUserState: EditUserStateService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
    )
    {
    }

    $onInit()
    {

    }

    $postLink()
    {
       
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        this.Load();
    }

    Load()
    {
        let promise = this.editUserState.Load(this.managedUserId)
            .then((result) =>
            {
                this.userState = result;
                this.passwordExpirationDate=result.managedUser.PasswordExpirationDate;
                if (this.passwordExpirationDate)
                {
                    this.daysToExpiry = Helpers.checkDaysToExpiry(this.passwordExpirationDate);
                }
            })
            .then(() =>
            {
                this.isAdmin = this.identityManager.UserHasRole(
                    this.userState.selfUser,
                    this.apiConfig.FranForceConstants.RoleConstants.AdminRole
                );
            });


        return AngularUtil.TrackLoadingPromise(promise, this);
    }

    ToggleUser()
    {
        if (!confirm(`Are you sure you want to ${this.userState.managedUser.IsEnabled ? "disable" : "enable"} this user?`))
        {
            return;
        }

        this.isLoading = true;
        return this.identityManager.SetUserStatus(
            this.userState.managedUser.Id,
            !this.userState.managedUser.IsEnabled
        )
            .then(() =>
            {
                this.RefreshUser();
            })
            .catch(AngularUtil.GetJsonAlertCatch())
            .finally(() =>
            {
                this.isLoading = false;
            });
    }

    ForceExpirePassword()
    {
        if (!confirm(`Are you sure you want to force expire password for this user?`))
        {
            return;
        }

        this.isLoading = true;

        return this.identityManager.ExpireUserPassword(
            this.userState.managedUser.Id
        )
            .then(() =>
            {
                this.RefreshUser();
                alert("Success");
            })
            .catch(AngularUtil.GetJsonAlertCatch())
            .finally(() =>
            {
                this.isLoading = false;
            });

    }


    RefreshUser()
    {
        this.editUserState.Clear(this.userState.managedUser.Id);
        if (this.refresh)
        {
            this.refresh({
                refreshedDate: new Date()
            });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("editUserEnabled", {
            bindings: {
                [nameof<EditUserEnabledComponentController>(o => o.managedUserId)]: "<",
                [nameof<EditUserEnabledComponentController>(o => o.refreshDate)]: "<",
                [nameof<EditUserEnabledComponentController>(o => o.refresh)]: "&"
            },
            controller: EditUserEnabledComponentController,
            templateUrl: "/Templates/Users/EditUser/EditUserEnabled.html"
        });
    }
}
