import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyContractMinimumFeeIncreasesAndCapsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    RoyaltyContractMinimumFeeIncreasesAndCapsByContract(contractId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContractMinimumFeeIncreasesAndCaps/InContract/" + contractId
        };

        return this.$http<RpmEntities.usp_RoyaltyContractMinimumFeeIncreasesAndCaps_GetByRoyaltyContractId_Result[]>(config);
    }

    CreateRoyaltyContractMinimumFeeIncreasesAndCaps(model: RpmEntities.usp_RoyaltyContractMinimumFeeIncreasesAndCaps_GetByRoyaltyContractId_Result)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyContractMinimumFeeIncreasesAndCaps",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyContractMinimumFeeIncreasesAndCaps(id: number, model: RpmEntities.usp_RoyaltyContractMinimumFeeIncreasesAndCaps_GetByRoyaltyContractId_Result)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyContractMinimumFeeIncreasesAndCaps/" + id,
            data: model
        };

        return this.$http<number>(config);
    }
}

