export class UrlQueryParameters
{
    parameters: { [key: string]: string };

    constructor(
        private window: Window
    )
    {
        this.parameters = {};
        this.Load();

        this.window.onpopstate = () =>
        {
            this.Load();
        }
    }

    GetValue(key: string) : string
    {
        return this.parameters[key];
    }

    private Load()
    {
        const search = /([^&=]+)=?([^&]*)/g;
        const pl = /\+/g;  // Regex for replacing addition symbol with a space
        const decode = (s) => decodeURIComponent(s.replace(pl, " "));

        this.Clear();

        let query = window.location.search.substring(1);
        let match: RegExpExecArray;

        while (match = search.exec(query))
        {
            this.parameters[decode(match[1])] = decode(match[2]);
        }
    }

    private Clear()
    {
        for (const prop of Object.keys(this.parameters)) {
            delete this.parameters[prop];
        }
    }
}
