import { AngularUtil } from "Utility/AngularUtil";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ZeeMailApiResources } from "Services/Resources/ZeeMailApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { ZeeMailEntities } from "Interfaces/FranForce/ZeeMail/ZeeMailResources";

export class ZeeMailCreateAccountComponentController implements ng.IController {
    franchiseId: number;
    emailDba: string;
    onSave?: (params: {}) => void;
    onCancel?: (params: {}) => void;

    accountTypeDropDownOptions: kendo.ui.DropDownListOptions;

    isLoading: boolean;
    title: string;
    errorMessage: string;
    isFranchiseUser: boolean;
    franchise: ZeeMailEntities.ZeeMailFranchiseExtension;
    domain: ZeeMailEntities.ZorWareZeeMailDomain;
    template: ZeeMailEntities.ZorWareZeeMailTemplate;

    account: IZorWareZeeMailCreateAccount;


    static $inject = [
        'coreApiResources',
        'zeeMailApiResources',
        'identityManager',
        'apiConfig',
        '$log',
        '$q'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private zeeMailApiResources: ZeeMailApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $log: ng.ILogService,
        private $q: ng.IQService
    ) {
    }

    $onInit() {
        this.title = "Create Email Account";
        this.InitPermissions();
        this.SetDropDownOptions();
    }

    $postLink() {
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.franchiseId)] && this.franchiseId) {
            if (!this.account)
                this.account = <any>{};

            this.account.FranchiseId = this.franchiseId;
            this.franchise = null;
            this.domain = null;
            this.isLoading = true;

            this.zeeMailApiResources.ZeeMailFranchiseQuery({
                $filter: `${nameof<ZeeMailEntities.ZeeMailFranchiseExtension>(o => o.FranchiseId)} eq ${this.franchiseId}`
            })
                .then((franchisesResponse) => {
                    if (franchisesResponse.data.length) {
                        this.franchise = franchisesResponse.data[0];
                        this.emailDba = this.franchise.ZeeMailDBA;
                        let domainsPromise = this.zeeMailApiResources.ZorWareZeeMailDomainApi.query({
                            $filter: `${nameof<ZeeMailEntities.ZorWareZeeMailDomain>(o => o.FranchisorId)} eq ${this.franchise.FranchisorId}`
                        }).$promise
                            .then((domains) => {
                                this.domain = domains[0];
                            })

                        let templatePromise = this.zeeMailApiResources.ZorWareZeeMailTemplateApi.query({
                            $filter: `${nameof<ZeeMailEntities.ZorWareZeeMailTemplate>(o => o.ConceptId)} eq ${this.franchise.ConceptId}`
                        }).$promise
                            .then((templates) => {
                                this.template = templates[0];
                            });

                        return this.$q.all([domainsPromise, templatePromise]);
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                    if (!this.franchise) {
                        this.errorMessage = "Franchise could not be found.";
                    }
                    if (!this.domain) {
                        this.errorMessage = "A domain needs to be set up for this franchisor.";
                    }
                });
        }
    }

    InitPermissions() {
        this.isFranchiseUser = true;
        this.identityManager.HasRole([
            this.apiConfig.FranForceConstants.RoleConstants.FranchiseeRole,
            this.apiConfig.FranForceConstants.RoleConstants.FranchiseeEmployeeRole
        ])
            .then((isFranchiseUser) => {
                this.isFranchiseUser = isFranchiseUser;
                if (this.isFranchiseUser) {
                    //Purchased
                    this.account.ZorWareZeeMailTypeID = 2;
                }
            });
    }

    SetDropDownOptions() {
        this.accountTypeDropDownOptions = {
            valuePrimitive: true,
            optionLabel: "Select a type...",
            optionLabelTemplate: "Select a type...",
            dataTextField: nameof<ZeeMailEntities.ZorWareZeeMailType>(o => o.TypeName),
            dataValueField: nameof<ZeeMailEntities.ZorWareZeeMailType>(o => o.ZorWareZeeMailTypeID),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        this.zeeMailApiResources.ZorWareZeeMailTypeApi.query({}).$promise
                            .then((types) => {
                                //remove Purchased 
                                let typeList = [];
                                for (let type of types) {
                                    
                                    if (type.TypeName === "Purchased") {
                                        continue;
                                    }
                                    else {
                                        typeList.push(type);
                                    }
                                }
                                //remove Purchased 
                                options.success(typeList) //Future if it is needed replace typeList with types 
                            })
                            .catch((err) => {
                                options.error(err);
                            })
                    }
                }
            }),
            dataBound: function () {
                let dropdown = this;
                let items = dropdown.ul.children();

                items.each(function (index, item) {
                    let dataItem = dropdown.dataItem(item);

                    if (dataItem.TypeName === "Purchased") {
                        $(item).addClass("disabled-option"); // Add CSS
                        dropdown.enable(false, dataItem); // Disable option
                    }
                });
            }
        }
    }

    ResetForm() {
        this.account = <any>{};
        this.account.FranchiseId = this.franchiseId;
    }

    Save() {
        return this.zeeMailApiResources.ZeeMailAccountCreate(this.account)
            .then(() => {
                this.ResetForm();
                if (this.onSave) {
                    this.onSave({});
                }
            })
            .catch(AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback(this.$log, this.$q));
    }

    Cancel() {
        this.ResetForm();
        if (this.onCancel) {
            this.onCancel({});
        }
    }

    static BindComponent(app: ng.IModule) {
        app.component("zeeMailCreateAccount", {
            bindings: {
                franchiseId: "<",
                emailDba: "<",
                onSave: "&",
                onCancel: "&"
            },
            controller: ZeeMailCreateAccountComponentController,
            templateUrl: "/Templates/ZeeMail/ZeeMailCreateAccount.html"
        });
    }
}


