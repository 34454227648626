import { LegalApiResources } from "Services/Resources/LegalApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { LegalEntities } from "Interfaces/FranForce/Legal/LegalResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

interface IEditSectionThreeScope extends ng.IScope {
    model: LegalEntities.SectionThree;
    submitSectionThree: () => void;
    cancelSectionThree: () => void;
    editSectionThreeForm: any;
    contractId: string;
    franchiseId: string;
    selectionDetails: { franchiseID: string, contractID: string };
    isItt: boolean;
    dispositionTelephoneNumbers: LegalEntities.TelephoneNumberDisposition[];
    kNotifierOptions: any;
    kNotifier: kendo.ui.Notification;
    saveSuccessful: boolean;
    approvalSelections: any;
    role: string;
    saveBtnDisabled: boolean;
    denyBtnDisabled: boolean;
    contractStatus: string;
}

export class EditSectionThreeController implements ng.IController {

    static $inject = [
        "$scope",
        "legalApiResources",
        "$window",
        "$q",
        "$log",
        "$http",
        "coreApiResources",
        "identityManager"
    ];

    constructor(
        private $scope: IEditSectionThreeScope,
        private legalApiResources: LegalApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $http: ng.IHttpService,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager
    )
    {
        $scope.saveBtnDisabled = false;
        $scope.denyBtnDisabled = false;

        $scope.kNotifierOptions = {
            position: {
                top: 50,
                left: $(window).width() / 2
            },
            templates: [{
                type: 'ngTemplate',
                template: $('#kNTemplate').html(),
            }]
        };

        $scope.approvalSelections = [
            { Description: "Approved", Value: true }, { Description: "Not Approved", Value: false }
        ];
    }

    $onInit() {
        let $scope = this.$scope;
        let $http = this.$http;
        let legalApiResources = this.legalApiResources;
        let identityManager = this.identityManager;

        $scope.franchiseId = location.pathname.split("/")[3] || $scope.selectionDetails.franchiseID;
        $scope.contractId = location.pathname.split("/")[4] || $scope.selectionDetails.contractID;

        legalApiResources.ContractApi.get({ id: $scope.contractId, $expand: "SectionThree,ContractType,ContractStatu" }, (successResponse: LegalEntities.Contract) => {
            $scope.model = successResponse.SectionThree;
            $scope.isItt = successResponse.ContractType.Description == "Intent To Terminate";
            $scope.contractStatus = successResponse.ContractStatu.Description;

            $scope.$emit("setSectionAvaliability", {
                hasSectionTwo: successResponse.SectionTwoID != null,
                hasSectionThree: successResponse.SectionThreeID != null,
                franchiseID: $scope.franchiseId,
                contractID: $scope.contractId
            });
        }, errorResponse => {

        });

        this.coreApiResources.FranchiseApi.get({ $expand: "Concept", id: $scope.franchiseId },
            (successResponse: CoreEntities.Franchise) => {
                $scope.$emit("setFranchiseDetails", {
                    FranchiseName: successResponse.Concept.DisplayName,
                    LicenseNum: successResponse.LicenseNumber,
                    FranchiseeCode: $scope.franchiseId,
                    FranchiseAddress: successResponse.Address,
                    FranchisePhoneNumber: successResponse.StorePhone,
                    FranchiseEmail: successResponse.StoreEmail
                });
            },
            errorResponse => {

            });

        this.legalApiResources.TelephoneNumberDispositionApi.query({}, (successResponse: LegalEntities.TelephoneNumberDisposition[]) => {
            $scope.dispositionTelephoneNumbers = successResponse;
        }, errorResponse => {

        });

        $scope.cancelSectionThree = () => {
            finishSectionThree(false);
        }

        $scope.submitSectionThree = () => {
            if ($scope.editSectionThreeForm.$valid) {
                finishSectionThree(true);
            } else {
                $scope.saveSuccessful = false;
                $scope.kNotifier.show({
                    kValue: "Some field(s) require input to proceed "
                }, "ngTemplate");

                Object.keys($scope.editSectionThreeForm.$error).forEach(function (value) {
                    $scope.editSectionThreeForm.$error[value].forEach(function (item) {
                        item.$setDirty();
                    });
                });
            }
        }

        function finishSectionThree(isSubmit: boolean) {
            $scope.saveBtnDisabled = true;
            $scope.denyBtnDisabled = true;

            //Autofill Signature 
            identityManager.GetLoggedInUserInfo().then(successCallback => {
                if ($scope.contractStatus == "Section 3 Denied") {
                    $scope.model.ApprovedBy = successCallback.data.Email;
                    $scope.model.ApprovedDate = kendo.toString(new Date(), "d");
                }
                else if ($scope.contractStatus == "Director Signature Required") {
                    $scope.model.ControllerSignature = successCallback.data.Email;
                    $scope.model.ControllerDate = kendo.toString(new Date(), "d");
                } else if ($scope.contractStatus == "Legal Signature Required") {
                    $scope.model.LegalSignature = successCallback.data.Email;
                    $scope.model.LegalDate = kendo.toString(new Date(), "d");
                }

                legalApiResources.SectionThreeApi.update({ id: $scope.model.SectionThreeID }, $scope.model, (successResponse: LegalEntities.SectionThree) => {
                    if (isSubmit) {
                        legalApiResources.SectionThreeCompleted($scope.contractId, successResponse.SectionThreeID).then(successResponse => {
                            window.location.href = "/Itt";
                        }, (errorResponse) => {
                            if (errorResponse.status == 404) {
                                window.location.href = "/Itt";
                            }
                            else {
                                $scope.kNotifier.show({
                                    kValue: "Failed to save to server. Please contact an admin."
                                }, "ngTemplate");
                                console.log(errorResponse);
                                $scope.saveBtnDisabled = false;
                                $scope.denyBtnDisabled = false;
                            }
                        });
                    } else {
                        legalApiResources.SectionThreeDenied($scope.contractId, successResponse.SectionThreeID).then(successResponse => {
                            window.location.href = "/Itt";
                        }, errorResponse => {
                            $scope.kNotifier.show({
                                kValue: "Failed to save to server. Please contact an admin."
                            }, "ngTemplate");
                            console.log(errorResponse);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        });
                    }
                }, errorResponse => {
                    $scope.kNotifier.show({
                        kValue: "Failed to save to server. Please contact an admin."
                    }, "ngTemplate");
                    console.log(errorResponse);
                    $scope.saveBtnDisabled = false;
                    $scope.denyBtnDisabled = false;
                });
            }, errorCallback => {
                
            });
        }
    }
}