import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import * as _ from "underscore"
import { PeriodReportSectionsComponentController } from "Directives/RPM/PeriodReport/PeriodReportSections";
import { RpmEntities, RpmResources } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { IdentityManager } from "../../../Services/Resources/IdentityManager";
import { ApiConfig } from "../../../AppConfig/ApiConfig";

export class PeriodReportSelectorComponentController implements ng.IController 
{
    isLoading: boolean;

    conceptId: number;
    franchisorId: number;
    franchiseId: number;

    prGrid: kendo.ui.Grid;

    userInfo: IUserInfoViewModel;
    conceptFilters: kendo.data.DataSourceFilters;
    
    static $inject = [
        '$rootScope',
        'coreApiResources',
        '$q',
        '$window',
        'rpmUiApiResources',
        'rpmHelper',
        'ngDialog',
        '$sce',
        'identityManager',
        "apiConfig",
        '$scope'
    ];

    constructor(
        private $rootScope: ng.IRootScopeService,
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $window: ng.IWindowService,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private ngDialog: ng.dialog.IDialogService,
        private $sce: ng.ISCEService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $scope: ng.IScope
    )
    {
    }

    $onInit()
    {
        this.CreateConceptFilters();
    }

    async CreateConceptFilters() {

        let loginUserDetails = await this.identityManager.GetLoggedInUserInfo()

        if (loginUserDetails && loginUserDetails.data) {

            let isAdminRole = false;
            let isConceptManager = false;
            let isPeriodReportAdminRole = false;
            let conceptClaimIds: number[] = [];

            this.userInfo = loginUserDetails.data;

            if (this.userInfo && this.userInfo.Roles) {

                isAdminRole = this.userInfo.Roles.some(r =>
                    r.Name == this.apiConfig.FranForceConstants.RoleConstants.AdminRole);

                isConceptManager = this.userInfo.Roles.some(r =>
                    r.Name == this.apiConfig.FranForceConstants.RoleConstants.ConceptManagerRole);

                isPeriodReportAdminRole = this.userInfo.Roles.some(r =>
                    r.Name == this.apiConfig.FranForceConstants.RoleConstants.PeriodReportAdminRole);

                conceptClaimIds = this.userInfo.Claims
                    .filter(claim => claim.Type == this.apiConfig.FranForceConstants.ClaimConstants.ManagedConceptIdClaimType)
                    .map(claim => parseInt(claim.Value));

                if ((isConceptManager && conceptClaimIds.length > 0) && !isAdminRole && !isPeriodReportAdminRole) {

                    this.conceptFilters = {
                        logic: "or",
                        filters: [

                        ]
                    }

                    $.each(conceptClaimIds, (index, conceptId) => {
                        this.conceptFilters.filters.push({
                            field: nameof<CoreResources.IFranchisor>(o => o.ConceptId),
                            operator: "eq",
                            value: conceptId
                        });
                    });

                    this.$scope.$digest();
                }
            }
        }
    }
    
    $postLink()
    {
        this.InitPrGrid();
    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {

    }

    InitPrGrid() {
        let prColumns: Array<kendo.ui.GridColumn> = [{
            field: nameof<RpmEntities.RoyaltyPeriodReportViewModel>(o => o.RoyaltyPeriodReportId),
            hidden: true
        }, {
            field: nameof<RpmEntities.RoyaltyPeriodReportViewModel>(o => o.FranchiseId),
            hidden: true
        }, {
            field: 'year',
            title: 'Year'
        }, {
            field: 'month',
            title: 'Month',
        }, {
            field: 'day',
            title: 'Day',
        }, {
            field: "PeriodReportEndDateFormatted",
            title: 'Period End Date'
        }, {
            field: nameof<RpmEntities.RoyaltyPeriodReportViewModel>(o => o.SourceTotalGrossSalesAmount),
            title: 'Gross Sales',
            format: "{0:c2}"
        }, {
            field: nameof<RpmEntities.RoyaltyPeriodReportViewModel>(o => o.TotalFeeAmount),
            title: 'Total Fee',
            format: "{0:c2}"
        }, {
            field: `RoyaltyPeriodReportStatus.${nameof<RpmEntities.usp_RoyaltyPeriodReportStatus_GetByRoyaltyPeriodReportStatusID_Result>(o => o.Name)}`,
            title: 'Status',
        }, {
            title: "View Email",
            template: `<a href='\\#' ng-click='$ctrl.ViewEmail(#= ${nameof<RpmEntities.RoyaltyPeriodReportViewModel>(o => o.RoyaltyPeriodReportId)} #)'>View Email</a>`
        }, {
            field: nameof<RpmEntities.RoyaltyPeriodReportViewModel>(o => o.ErrorDescription),
            title: 'Error Description',
        }];

        let prDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof<RpmEntities.RoyaltyPeriodReportViewModel>(o => o.RoyaltyPeriodReportId),
                    fields: {
                        [nameof<RpmEntities.RoyaltyPeriodReportViewModel>(o => o.RoyaltyPeriodReportId)]: { type: 'number', validation: { required: true } },
                        year: { type: 'string' },
                        month: { type: 'string ' },
                        day: { type: 'string' },
                        [nameof<RpmEntities.RoyaltyPeriodReportViewModel>(o => o.FranchiseId)]: { type: 'number', validation: { required: true } },
                        [nameof<RpmEntities.RoyaltyPeriodReportViewModel>(o => o.PeriodReportEndDate)]: { type: 'date', validation: { required: true } },
                        [nameof<RpmEntities.RoyaltyPeriodReportViewModel>(o => o.ErrorDescription)]: { type: 'string' },
                        [nameof<RpmEntities.RoyaltyPeriodReportViewModel>(o => o.TotalFeeAmount)]: { type: 'number' }
                    }
                }
            },
            group: [
                { field: "year", dir: "desc" },
                { field: "month", dir: "desc" }
            ],
            sort: {
                field: nameof<RpmEntities.RoyaltyPeriodReportViewModel>(o => o.PeriodReportEndDate),
                dir: "desc"
            },
            transport: {
                read: (options) =>
                {
                    if (!this.franchiseId) {
                        options.success([]);
                        return;
                    }

                    this.isLoading = true;
                    let statusPromise = this.rpmUiApiResources.GetAllRoyaltyPeriodReportStatuses();

                    this.rpmUiApiResources.GetRoyaltyPeriodReportsByFranchise(this.franchiseId)
                        .then((periodReportsResponse) =>
                        {
                            let periodReports = periodReportsResponse.data;
                            let sortedPeriodReports = _.sortBy(periodReports, r => r.CreatedDateTime);
                            sortedPeriodReports = _.sortBy(sortedPeriodReports, r => r.PeriodReportEndDate);
                            sortedPeriodReports = sortedPeriodReports.reverse();

                            sortedPeriodReports.forEach(pr =>
                            {
                                //TODO - This is awful.
                                (<any>pr).year = kendo.toString(kendo.parseDate(pr.PeriodReportEndDate), "yyyy");
                                (<any>pr).month = kendo.toString(kendo.parseDate(pr.PeriodReportEndDate), "MM");
                                (<any>pr).day = kendo.toString(kendo.parseDate(pr.PeriodReportEndDate), "dd");
                                (<any>pr).PeriodReportEndDateFormatted = kendo.toString(kendo.parseDate(pr.PeriodReportEndDate), "yyyy-MM-dd");
                            });

                            return statusPromise.then((statusResponse) =>
                            {
                                sortedPeriodReports.forEach(pr =>
                                {
                                    let status = _.find(statusResponse.data, s => s.RoyaltyPeriodReportStatusID === pr.RoyaltyPeriodReportStatusID);
                                    (<any>pr).RoyaltyPeriodReportStatus = status;
                                });
                                return sortedPeriodReports;
                            })
                        })
                        .then((reports) =>
                        {
                            options.success(reports);
                        })
                        .catch((err) => { options.error(err); })
                        .finally(() =>
                        {
                            this.isLoading = false;
                        });
                }
            }
        });

        this.prGrid.setOptions(<kendo.ui.GridOptions>{
            columns: prColumns,
            dataSource: prDataSource,
            sortable: true,
            groupable: true,
            resizable: true,
            selectable: true,
            change: (e) =>
            {
                let royaltyPeriodReportId = e.sender.dataItem(e.sender.select()).get(nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportId));
                this.$window.location.href = `/PeriodReport/Edit#?${nameof<PeriodReportSectionsComponentController>(o => o.royaltyPeriodReportId)}=${royaltyPeriodReportId}`;
            }
        });
    }

    OnFranchiseSelect(franchise: Partial<CoreResources.IFranchise>) 
    {
        this.franchiseId = franchise && franchise.FranchiseId;
        if (this.franchiseId) {
            this.prGrid.dataSource.read().then(() =>
            {
                this.prGrid.refresh();
            })
        }
    }

    ViewEmail(rprID: number) {
        this.rpmUiApiResources.GetRoyaltyPeriodReportEmail(rprID).then(
            (response) => {
                let emailScope = <ng.IScope & { emailModel: RpmEntities.EmailServiceViewModel }>this.$rootScope.$new(true);
                emailScope.emailModel = response.data;
                emailScope.emailModel.ToAddress = (emailScope.emailModel.ToAddress || "").replace(";", " ");
                emailScope.emailModel.CCAddress = (emailScope.emailModel.CCAddress || "").replace(";", " ");
                emailScope.emailModel.BCCAddress = (emailScope.emailModel.BCCAddress || "").replace(";", " ");
                emailScope.emailModel.Body = this.$sce.trustAsHtml(emailScope.emailModel.Body);
                emailScope.emailModel.DateToSend = kendo.toString(kendo.parseDate(response.data.DateToSend), 'd');
                emailScope.emailModel.DateSent = kendo.toString(kendo.parseDate(response.data.DateSent), 'd');
                emailScope.emailModel.LastSendAttemptDate = kendo.toString(kendo.parseDate(response.data.LastSendAttemptDate), 'd');

                let dialog = this.ngDialog.open({
                    template: '/Templates/BatchManagement/bmEmailModal.html',
                    className: 'ngdialog-theme-default ngdialog-email-dialog',
                    scope: <any>emailScope,
                    controller: ['$scope', ($scope: ng.IScope & { emailModel: RpmEntities.EmailServiceViewModel }) => {
                    }],
                    showClose: false
                });
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('periodReportSelector', {
            bindings: {

            },
            controller: PeriodReportSelectorComponentController,
            templateUrl: "/Templates/PeriodReport/PeriodReportSelector.html"
        });

    }
}

