import { KendoUtil } from "Utility/KendoUtil";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class fddRoyaltiesTerritoryMinimumFeesComponentController implements ng.IController {

    disclosureDocumentRatePlanMinimumItemId: number;
    readOnly: boolean;
    isScaleBasedOnTerritory: boolean;
    onLoaded: (params: { self: fddRoyaltiesTerritoryMinimumFeesComponentController }) => void;

    isLoading: boolean;
    territoryMinimumsGrid: kendo.ui.Grid;
    territoryMinimumsGridOptions: kendo.ui.GridOptions;

    formController: angular.IFormController;
    
    static $inject = [
        'rpmUiApiResources',
        'rpmHelper',
        'identityManager',
        '$q',
        '$timeout'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private identityManager: IdentityManager,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService)
    {

    }

    $onInit()
    {
        this.InitMinimumTerritoryFeeGrid();
        
        if (this.onLoaded)
            this.onLoaded({ self: this });
    }
    
    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.disclosureDocumentRatePlanMinimumItemId)])
        {
            this.$timeout(() => { this.ResetChanges(); }, 0);
        }
    }

    InitMinimumTerritoryFeeGrid()
    {
        let dataSourceTransport: kendo.data.DataSourceTransport = {
            read: (options: kendo.data.DataSourceTransportReadOptions) => {

                if (!this.disclosureDocumentRatePlanMinimumItemId) {
                    options.success([]);
                    return;
                }

                this.rpmUiApiResources.GetRoyaltyDisclosureDocumentRatePlanMinimumItemDetailsByRatePlanMinimumItem(this.disclosureDocumentRatePlanMinimumItemId)
                    .then((itemDetailResponse) => {
                        options.success(itemDetailResponse.data);
                    });
            },
            create: (options: kendo.data.DataSourceTransportOptions) => {
                let newRpMinItemDetail: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel = options.data;
                this.rpmUiApiResources.CreateRoyaltyDisclosureDocumentRatePlanMinimumItemDetail(newRpMinItemDetail)
                    .then((idResponse) => {
                        options.success({ DisclosureDocumentRatePlanMinimumItemDetailId: idResponse.data });
                    }, (err) => { options.error(err); });
            },
            update: (options: kendo.data.DataSourceTransportOptions) => {
                let updateRpMinItemDetail: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel = options.data;
                this.rpmUiApiResources.UpdateRoyaltyDisclosureDocumentRatePlanMinimumItemDetail(
                    updateRpMinItemDetail.DisclosureDocumentRatePlanMinimumItemDetailId,
                    updateRpMinItemDetail)
                    .then(() => {
                        options.success(updateRpMinItemDetail);
                    }, (err) => { options.error(err); });
            },
            destroy: (options: kendo.data.DataSourceTransportOptions) => {
                let deleteRpMinItemDetail: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel = options.data;
                deleteRpMinItemDetail.DeletedDateTime = new Date().toISOString();
                this.rpmUiApiResources.UpdateRoyaltyDisclosureDocumentRatePlanMinimumItemDetail(
                    deleteRpMinItemDetail.DisclosureDocumentRatePlanMinimumItemDetailId,
                    deleteRpMinItemDetail)
                    .then(() => {
                        options.success(deleteRpMinItemDetail);
                    }, (err) => { options.error(err); });
            }
        };

        let wtDataSource = new kendo.data.DataSource({
            pageSize: 20,
            filter: { field: "DeletedDateTime", operator: "isnull" },
            schema: {
                model: {
                    id: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel>(o => o.DisclosureDocumentRatePlanMinimumItemDetailId),
                    fields: {
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel>(o => o.DisclosureDocumentRatePlanMinimumItemDetailId)]: { type: "number", defaultValue: 0, editable: false },
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel>(o => o.TerritoryPopulationLowRange)]: { type: "number", validation: { required: true }, defaultValue: 0 },
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel>(o => o.TerritoryPopulationHighRange)]: { type: "number", validation: { required: true }, defaultValue: 0 },
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel>(o => o.MinimumRoyaltyFeeFixedRateAmount)]: { type: "number", validation: { required: true, defaultValue: 0 } },
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel>(o => o.MinimumAdFeeFixedRateAmount)]: { type: "number", validation: { required: true, defaultValue: 0 } }
                    }
                }
            },
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {

                    if (!this.disclosureDocumentRatePlanMinimumItemId)
                    {
                        options.success([]);
                        return;
                    }

                    this.rpmUiApiResources.GetRoyaltyDisclosureDocumentRatePlanMinimumItemDetailsByRatePlanMinimumItem(this.disclosureDocumentRatePlanMinimumItemId)
                        .then((itemDetailResponse) => {
                            options.success(itemDetailResponse.data);
                        });
                },
                create: (options: kendo.data.DataSourceTransportOptions) => {
                    let newRpMinItemDetail: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel = options.data;
                    this.rpmUiApiResources.CreateRoyaltyDisclosureDocumentRatePlanMinimumItemDetail(newRpMinItemDetail)
                        .then((idResponse) => {
                            options.success({ DisclosureDocumentRatePlanMinimumItemDetailId: idResponse.data });
                        }, (err) => { options.error(err); });
                },
                update: (options: kendo.data.DataSourceTransportOptions) => {
                    let updateRpMinItemDetail: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel = options.data;
                    this.rpmUiApiResources.UpdateRoyaltyDisclosureDocumentRatePlanMinimumItemDetail(
                        updateRpMinItemDetail.DisclosureDocumentRatePlanMinimumItemDetailId,
                        updateRpMinItemDetail)
                        .then(() => {
                            options.success(updateRpMinItemDetail);
                        }, (err) => { options.error(err); });
                },
                destroy: (options: kendo.data.DataSourceTransportOptions) => {
                    let deleteRpMinItemDetail: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel = options.data;
                    deleteRpMinItemDetail.DeletedDateTime = new Date().toISOString();
                    this.rpmUiApiResources.UpdateRoyaltyDisclosureDocumentRatePlanMinimumItemDetail(
                        deleteRpMinItemDetail.DisclosureDocumentRatePlanMinimumItemDetailId,
                        deleteRpMinItemDetail)
                        .then(() => {
                            options.success(deleteRpMinItemDetail);
                        }, (err) => { options.error(err); });
                },
            },
            sort: {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel>(o => o.TerritoryPopulationLowRange),
                dir: "asc"
            }
        });
        
        this.territoryMinimumsGridOptions = {
            dataSource: wtDataSource,
            columns: [
                {
                    field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel>(o => o.DisclosureDocumentRatePlanMinimumItemDetailId),
                    title: "Id",
                    hidden: false,
                }, {
                    field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel>(o => o.TerritoryPopulationLowRange),
                    title: "Territory Population Low",
                    format: `{0:${this.rpmHelper.StringFormats.Number.Population}}`,
                    editor: this.rpmHelper.PopulationEditor
                }, {
                    field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel>(o => o.TerritoryPopulationHighRange),
                    title: "Territory Population High",
                    format: `{0:${this.rpmHelper.StringFormats.Number.Population}}`,
                    editor: this.rpmHelper.PopulationEditor
                }, {
                    field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel>(o => o.MinimumRoyaltyFeeFixedRateAmount),
                    title: "Min Royalty Fee",
                    format: "{0:c}",
                    editor: this.rpmHelper.CurrencyEditor
                }, {
                    field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel>(o => o.MinimumAdFeeFixedRateAmount),
                    title: "Min Ad Fee",
                    format: "{0:c}",
                    editor: this.rpmHelper.CurrencyEditor
                }, {
                    command: [{name: "destroy"}],
                    title: "&nbsp;",
                    width: "100px"
                }
            ],
            pageable: KendoUtil.GetDefaultKendoGridPageable(20),
            editable: !this.readOnly ? { confirmation: false } : false,
            sortable: true,
            scrollable: false,
            navigatable: true
        };
    } 

    AddNewRecord()
    {
        if (!this.readOnly)
        {
            let model: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel = {
                DisclosureDocumentRatePlanMinimumItemDetailId: 0,
                DisclosureDocumentRatePlanMinimumItemId: this.disclosureDocumentRatePlanMinimumItemId,
                WeeksInBusinessStart: 0,
                MinimumRoyaltyFeeFixedRateAmount: 0,
                MinimumAdFeeFixedRateAmount: 0,
                TerritoryPopulationHighRange: 0,
                TerritoryPopulationLowRange: 0,
                CreatedDateTime: kendo.toString(new Date(), 'd'),
                CreatedUser: null,
                UpdatedDateTime: null,
                UpdatedUser: null,
                DeletedDateTime: null,
            };

            this.territoryMinimumsGrid.dataSource.add(model);
        }
    }
    
    CancelChanges()
    {
        if (!this.readOnly)
        {
            this.territoryMinimumsGrid.dataSource.cancelChanges();
        }
    }
    
    HasChanges()
    {
        if (!this.territoryMinimumsGrid)
            return false;

        return this.territoryMinimumsGrid.dataSource.hasChanges();
    }

    ResetChanges()
    {    
        this.isLoading = true;
        this.territoryMinimumsGrid.dataSource.cancelChanges();
        return this.$q.when(this.territoryMinimumsGrid.dataSource.read())
            .then(() =>
            {
                this.territoryMinimumsGrid.refresh();
                this.$timeout(() =>
                {
                    this.formController.$setPristine();
                }, 1000);
            })
            .finally(() =>
            {
                this.isLoading = false;
            });
    }

    SaveChanges()
    {
        this.isLoading = true;
        return this.$q.when(this.territoryMinimumsGrid.dataSource.sync())
            .then(() =>
            {
                return this.ResetChanges();
            })
            .finally(() =>
            {
                this.isLoading = false;
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("fddRoyaltiesTerritoryMinimumFees", {
                bindings: {
                    disclosureDocumentRatePlanMinimumItemId: "<",
                    readOnly: "<",
                    isScaleBasedOnTerritory: "<",
                    onLoaded: "&?"
                },
                templateUrl: '/Templates/FDDManagement/fddRoyalties/TerritoryMinimumFees.html',
                controller: fddRoyaltiesTerritoryMinimumFeesComponentController
            });
    }
}

