import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";



export class ServiceOrchestrationApiResources 
{
    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig",
        "$httpParamSerializer"
    ];


  

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig,
        private $httpParamSerializer: ng.IHttpParamSerializer 
    )
    {
      
    }


    GetServiceConfigurationByConceptUrl(conceptCode)
    {
        return `${this.apiConfig.AppSettings.ServiceOrchestrationsApiRootUrl}/public/service/config/${conceptCode}?apikey=${this.apiConfig.AppSettings.FranchiseEmployeeOrchestrationsApiKey}`;
    }

    GetUpdatedServiceConfigurationByConceptUrl(conceptCode)
    {
        return `${this.apiConfig.AppSettings.ServiceOrchestrationsApiRootUrl}/public/service/config/updated/${conceptCode}?apikey=${this.apiConfig.AppSettings.FranchiseEmployeeOrchestrationsApiKey}`;
    }

}