interface RmsConfirmationDialogComponentControllerOptions 
{    
    actions: Array<IRmsDialogButton>;
    title?: string | false;
    message?: string;
}

export class RmsConfirmationDialogComponentController implements ng.IController 
{
    init: (params: { self: RmsConfirmationDialogComponentController }) => void;
    window: kendo.ui.Window;

    private options: RmsConfirmationDialogComponentControllerOptions;

    static $inject = [
        "$timeout"
    ]

    constructor(
        private $timeout: ng.ITimeoutService
    )
    {

    }

    $onInit()
    {
    }

    $postLink()
    {
        this.window.setOptions(<kendo.ui.WindowOptions>{
            modal: true,
            closable: false,
            open: (e) =>
            {
                e.sender.wrapper.addClass("rpm-modal rpm-modal-kendo");
            }
        });
        
        if (this.init)
            this.init({ self: this });
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        
    }

    RebindDialog(options: RmsConfirmationDialogComponentControllerOptions)
    {
        this.options = options;
        this.window.setOptions(<kendo.ui.WindowOptions>{
            title: options.title
        });
    }

    Open(options: RmsConfirmationDialogComponentControllerOptions)
    {
        this.RebindDialog(options);
        this.window.open();
        this.$timeout(() =>
        {
            this.window.center();
        });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("rmsConfirmationDialog", {
                bindings: {
                    [nameof<RmsConfirmationDialogComponentController>(o => o.init)]: `&?`
                },
                template: `<div kendo-window="$ctrl.${nameof<RmsConfirmationDialogComponentController>(o => o.window)}"
                            k-visible="false">
                        <div class="message" ng-bind='$ctrl.${nameof.full<RmsConfirmationDialogComponentController>(o => o.options.message)}'></div>
                        <div class="buttons">
                            <button ng-repeat="action in $ctrl.${nameof.full<RmsConfirmationDialogComponentController>(o => o.options.actions)}"
                                ng-bind="action.name"
                                ng-class="action.cssClass"
                                ng-click="action.event(action) ; $ctrl.${nameof<RmsConfirmationDialogComponentController>(o => o.window)}.close()"
                                >
                            </button>
                        </div>
                </div>`,
                controller: RmsConfirmationDialogComponentController
            });
    }
}

