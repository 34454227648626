export interface IBMStateParams extends ng.ui.IStateParamsService {
    franchisorId?: number;
    periodId?: number;
    franchiseeId?: number;
    batchId?: number;
}

let bmRouterConfig = [
    "$stateProvider",
    "$urlMatcherFactoryProvider",
    ($stateProvider: ng.ui.IStateProvider, $urlMatcherFactoryProvider: ng.ui.IUrlMatcherFactory) =>
    {
        $urlMatcherFactoryProvider.strictMode(false);
        $stateProvider.state({
            name: 'bm',
            url: '/bm/',
            templateUrl: '/Templates/BatchManagement/bmIndex.html'
        });
        $stateProvider.state({
            name: 'bm.franchisor',
            url: 'franchisor/{franchisorId}',
            templateUrl: '/Templates/BatchManagement/bmFranchisor.html'
        });
        $stateProvider.state({
            name: 'bm.period',
            url: 'period/{period}',
            templateUrl: '/Templates/BatchManagement/bmPeriod.html'
        });
        $stateProvider.state({
            name: 'bm.franchise',
            url: 'franchise/{franchiseId}',
            templateUrl: '/Templates/BatchManagement/bmFranchisee.html'
        });
        $stateProvider.state({
            name: 'bm.batchId',
            url: 'batchId/{batchId}',
            templateUrl: '/Templates/BatchManagement/bmBatchId.html'
        });
    }];

export { bmRouterConfig };