import { UrlQueryParameters } from "Utility/UrlQueryParameters";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { SsrsReportsClient, SsrsReportConfigurationDto } from "@nbly/service-orchestrations-clients";
import { AxiosInstance } from "axios";
import { FranForceAxiosClientBuilder } from "../../Clients/FranForceAxiosClientBuilder";
import {
    YearSelectorOnSelectParams,
    WeekEndingDateSelectorOnSelectParams,
    QuarterSelectorOnSelectParams,
    FranchiseStatusSelectorOnSelectParams
} from "../../Directives/Common";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class ReportDetails implements ng.IController {
    //bindings
    reportId: number;
    reportTypeId: number;
    selectedFranchisesParam: number[];
    selectedTechniciansParam: number[];
    selectedEmployeesParam: number[];
    //end bindings
    isLoading: boolean;

    reportDescription: string;
    reportPath: string;

    //reportPathURL: string;

    sSRSReportsClient: SsrsReportsClient;
    sSRSReportConfigurationDto: SsrsReportConfigurationDto;
    serviceOrchestrationsClient: AxiosInstance;

    hasConceptId: boolean;
    conceptId: number;

    hasFranchiseId: boolean;
    franchiseId: number;

    hasStartDate: boolean;
    startDateTime: Date;

    hasEndDate: boolean;
    endDateTime: Date;

    hasYear: boolean;
    year: number;
    yearFrom: number;
    yearTo: number;

    hasWeekEnding: boolean;
    weekEnding: Date;

    hasQuarter: boolean;
    quarter: number;

    hasFirstWeek: boolean;
    firstWeek: number;

    hasLastWeek: boolean;
    lastWeek: number;

    hasCurrentWeek: boolean;
    currentWeek: number;

    combinedFranchiseFilters: kendo.data.DataSourceFilters;

    hasMultipleFranchises: boolean;
    comparisonFranchiseSelect: string[];
    selectedFranchises: any[];

    hasMultipleTechnicians: boolean;
    comparisonTechnicianSelect: string[];
    selectedTechnicians: any[];

    hasMultipleEmployees: boolean;
    comparisonEmployeeSelect: string[];
    selectedEmployees: any[];

    franchiseStatus: string;
    hasFranchiseStatus: boolean;

    hasFromWeek: boolean;
    fromWeek: number;

    hasToWeek: boolean;
    toWeek: number;

    hasCyWeDate: boolean;
    cyWeDate: Date;

    hasPyWeDate: boolean;
    pyWeDate: Date;

    royaltyPeriodReportId: number;
    hasMOJReport: boolean;

    static $inject = [
        "$window",
        "identityManager",
        "apiConfig",
        "$scope",
        "$location"
    ];

    constructor(
        private $window: ng.IWindowService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $scope: ng.IScope,
        private $location: ng.ILocationService,
    ) {
        this.serviceOrchestrationsClient = FranForceAxiosClientBuilder.BuildServiceOrchestrationsBaseClient();
        this.sSRSReportsClient = new SsrsReportsClient("", this.serviceOrchestrationsClient);
    }

    $postLink() {

    }

    async $onInit() {
        this.SetConceptIdByReportId();

        if (this.conceptId) {
            this.combinedFranchiseFilters = {
                logic: "and",
                filters: [
                    {
                        field: nameof<CoreResources.IFranchise>(o => o.ConceptId),
                        operator: 'eq',
                        value: this.conceptId
                    }
                ]
            }
        }

        if (this.selectedFranchisesParam && this.selectedFranchisesParam.length > 0) {
            this.selectedFranchises = this.selectedFranchisesParam;
        }
        if (this.selectedTechniciansParam && this.selectedTechniciansParam.length > 0) {
            this.selectedTechnicians = this.selectedTechniciansParam;
        }
        if (this.selectedEmployeesParam && this.selectedEmployeesParam.length > 0) {
            this.selectedEmployees = this.selectedEmployeesParam;
        }

        this.isLoading = false;
        let params = new UrlQueryParameters(this.$window.window);

        this.comparisonFranchiseSelect = [
            nameof<CoreResources.IFranchise>(o => o.FranchiseId),
            nameof<CoreResources.IFranchise>(o => o.LicenseNumber),
            nameof<CoreResources.IFranchise>(o => o.FranchiseeName),
        ];

        // all names below should be the same names that the report accepts
        if (params.GetValue("FieldsPopulated") == "true") {
            this.conceptId = parseInt(params.GetValue("ConceptID"));
            this.franchiseId = parseInt(params.GetValue("FranchiseId"));
            this.startDateTime = new Date(params.GetValue("StartDate"));
            this.endDateTime = new Date(params.GetValue("EndDate"));
            this.year = parseInt(params.GetValue("Year"));
            this.weekEnding = new Date(params.GetValue("WeekEnding"));
            this.quarter = parseInt(params.GetValue("Quarter"));
            this.firstWeek = parseInt(params.GetValue("FirstWeek"));
            this.lastWeek = parseInt(params.GetValue("LastWeek"));
            this.currentWeek = parseInt(params.GetValue("CurrentWeek"));
            this.franchiseStatus = params.GetValue("FranchiseStatus");
            this.fromWeek = parseInt(params.GetValue("FromWeek"));
            this.toWeek = parseInt(params.GetValue("ToWeek"));
            this.cyWeDate = new Date(params.GetValue("CYWeDate"));
            this.pyWeDate = new Date(params.GetValue("PYWeDate"));
            this.royaltyPeriodReportId = parseInt(params.GetValue("RoyaltyPeriodReportId"));
            this.hasMOJReport = this.royaltyPeriodReportId != null && this.royaltyPeriodReportId > 0 ? true : false;
        }

        this.yearFrom = 1980;
        this.yearTo = new Date().getFullYear() + 1;

        await this.LoadReportDetails();
        await this.LoadReportConfigurations();

        if (!this.franchiseId)
            this.SelectFranchiseIfSingleOwnership();

    }

    SetConceptIdByReportId() {
        if (this.reportTypeId == 2)
            this.conceptId = 1;
        else if (this.reportTypeId == 3)
            this.conceptId = 2;
        else if (this.reportTypeId == 4)
            this.conceptId = 18;
        else
            return;
    }

    CreateComparisonFranchiseFilters() {
        this.CreateCombinedFranchiseFilters();

        if (this.franchiseStatus && this.franchiseStatus != "" && this.combinedFranchiseFilters && this.combinedFranchiseFilters.filters && this.combinedFranchiseFilters.filters.length > 0) {
            this.combinedFranchiseFilters.filters.push({
                field: nameof<CoreResources.IFranchise>(o => o.Status),
                operator: 'eq',
                value: this.franchiseStatus
            })
        }
    }

    CreateCombinedFranchiseFilters() {
        if (this.reportTypeId == 2)
            this.conceptId = 1;
        else if (this.reportTypeId == 3)
            this.conceptId = 2;
        else if (this.reportTypeId == 4)
            this.conceptId = 18;
        else
            return;

        this.combinedFranchiseFilters = {
            logic: "and",
            filters: [
                {
                    field: nameof<CoreResources.IFranchise>(o => o.ConceptId),
                    operator: 'eq',
                    value: this.conceptId
                }
            ]
        }
    }

    async LoadReportDetails() {
        this.isLoading = true;
        await this.sSRSReportsClient.ssrsReports().then(res => {
            this.isLoading = false;
            if (res && res.result && res.result.length > 0 && this.reportId) {
                let reportData = res.result.filter(x => x.reportId == this.reportId);
                this.reportDescription = reportData[0].reportDescription;
                this.reportPath = reportData[0].reportPath;
                this.reportTypeId = reportData[0].reportTypeId;
                this.$scope.$digest();
            }
        }).catch((ex) => {
            this.isLoading = false;
            this.reportDescription = null;
            this.reportPath = null
            console.log("Error! Failed to load report details", ex);
        });
    }

    async LoadReportConfigurations() {
        if (this.reportId) {
            this.isLoading = true;
            await this.sSRSReportsClient.ssrsReportConfigurations(this.reportId).then(res => {
                this.isLoading = false;
                this.sSRSReportConfigurationDto = res.result;

                if (this.sSRSReportConfigurationDto) {
                    this.hasConceptId = this.sSRSReportConfigurationDto.hasConceptId;
                    this.hasFranchiseId = this.sSRSReportConfigurationDto.hasFranchiseId;
                    this.hasStartDate = this.sSRSReportConfigurationDto.hasStartDate;
                    this.hasEndDate = this.sSRSReportConfigurationDto.hasEndDate;
                    this.hasYear = this.sSRSReportConfigurationDto.hasYear;
                    this.hasWeekEnding = this.sSRSReportConfigurationDto.hasWeekEnding; // 4= Monthly Metrics Report
                    this.hasQuarter = this.sSRSReportConfigurationDto.hasQuarter;
                    this.hasFirstWeek = this.sSRSReportConfigurationDto.hasFirstWeek;
                    this.hasLastWeek = this.sSRSReportConfigurationDto.hasLastWeek;
                    this.hasCurrentWeek = this.sSRSReportConfigurationDto.hasCurrentWeek;
                    this.hasFranchiseStatus = this.sSRSReportConfigurationDto.hasFranchiseStatus;
                    this.hasMultipleFranchises = this.sSRSReportConfigurationDto.hasMultipleFranchises;
                    this.hasFromWeek = this.sSRSReportConfigurationDto.hasFromWeek;
                    this.hasToWeek = this.sSRSReportConfigurationDto.hasToWeek;
                    this.hasCyWeDate = this.sSRSReportConfigurationDto.hasCyWeDate;
                    this.hasPyWeDate = this.sSRSReportConfigurationDto.hasPyWeDate;
                    this.hasMultipleTechnicians = this.sSRSReportConfigurationDto.hasMultipleTechnicians;
                    this.hasMultipleEmployees = this.sSRSReportConfigurationDto.hasCSRs;

                    this.$scope.$digest();
                }
            }).catch((ex) => {
                this.isLoading = false;
                this.sSRSReportConfigurationDto = null;
                console.log("Error! Failed to load report configurations", ex);
            });

        }
    }

    RunReport() {
        switch (this.reportId) {

            //ReferANeighborActivityReport
            case 1:
                if (!this.IsFranchiseIdSet() || !this.IsStartDateSet() || !this.IsEndDateSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&ConceptID=${this.conceptId}&FranchiseId=${this.franchiseId}&StartDate=${kendo.toString(this.startDateTime, "d", "en-US")}&EndDate=${kendo.toString(this.endDateTime, "d", "en-US")}`;
                break;

            //Franchisee Contact Information
            //MLY
            case 2:
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&ConceptID=1`;
                break;
            //MHM
            case 3:
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&ConceptID=2`;
                break;

            //Monthly Metrics Report
            case 4:
                if (!this.IsYearSet() || !this.IsWeekEndingDateSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&Year=${this.year}&WeekEnding=${kendo.toString(this.weekEnding, "d", "en-US")}`
                break;

            //Weekly Milestones Report
            case 5:
                if (!this.IsYearSet() || !this.IsCurrentWeekSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&Year=${this.year}&CurrentWeek=${kendo.toString(this.currentWeek, "d", "en-US")}`
                break;

            //Year-to-Date Franchise Detail Report
            case 6:
                if (!this.IsFranchiseIdSet() || !this.IsYearSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}&Year=${this.year}`
                break;

            //Annual Sales By Franchisee (mhm report)
            case 7:
                if (!this.IsFranchiseIdSet() || !this.IsYearSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}&Year=${this.year}`
                break;

            //Weekly Franchise Detail Report (mhm report)
            case 8:
                if (!this.IsFranchiseIdSet() || !this.IsYearSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}&Year=${this.year}`
                break;

            //Royalty Summary (mhm report)
            case 9:
                if (!this.IsFranchiseIdSet() || !this.IsYearSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}&Year=${this.year}`
                break;

            //Service Titan Close Rate Standard (mhm report)
            case 10:
                if (!this.IsFranchiseIdSet() || !this.IsStartDateSet() || !this.IsEndDateSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}&StartDate=${kendo.toString(this.startDateTime, "d", "en-US")}&EndDate=${kendo.toString(this.endDateTime, "d", "en-US")}`
                break;

            //Quarterly MileStones Report (mhm report)
            case 11:
                if (!this.IsQuarterSet() || !this.IsYearSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&Quarter=${this.quarter}&Year=${this.year}`
                break;

            //Management Information Report (mhm report)
            case 12:
                if (!this.IsFranchiseIdSet() || !this.IsYearSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}&Year=${this.year}`;
                break;

            //Customer Trends (mhm report)
            case 13:
                if (!this.IsFranchiseIdSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}`;
                break;

            //Management Information Report (mly report)
            case 14:
                if (!this.IsFranchiseIdSet() || !this.IsFranchiseStatusSet() || !this.IsQuarterSet() || !this.IsYearSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}&FranchiseStatus=${this.franchiseStatus}&Year=${this.year}&Quarter=${this.quarter}`;
                break;

            //Management Information Comparison Report
            case 15:
                if (!this.IsFranchiseIdSet() || !this.IsMultipleFranchiseSelected() || !this.IsYearSet())
                    break;
                else {
                    $.ajax({
                        url: `/Reports/setMultiSelectTempData`,
                        type: 'POST',
                        data: {
                            'reportParameters': {
                                "ComparisonFranchiseIds": this.selectedFranchises,
                                "FranchiseId": this.franchiseId,
                                "Year": this.year,
                                "ReportDescription": this.reportDescription,
                                "ReportTypeId": this.reportTypeId,
                                "ReportPath": this.reportPath,
                                "FieldsPopulated": true,
                                "ReportId": this.reportId,
                                "HasComparisonFranchises": true,
                            }

                        },
                        success: function (data) {
                            if (data) {
                                window.location.href = `/Reports/${data.ReportId}?ReportDescription=${data.ReportDescription}&ReportTypeId=${data.ReportTypeId}&ReportPath=${data.ReportPath}&FieldsPopulated=${true}&HasComparisonFranchises=${true}&FranchiseId=${data.FranchiseId}&Year=${data.Year}`;
                            }

                        }
                    });
                    break;
                }

            //Monthly Metrics Report (mhm report)
            case 16:
                if (!this.IsYearSet() || !this.IsFromWeekNumberSet() || !this.IsToWeekNumberSet() || !this.IsCYWeDateSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&Year=${this.year}&FromWeek=${this.fromWeek}&ToWeek=${this.toWeek}&CYWeDate=${kendo.toString(this.cyWeDate, "d", "en-US")}`;
                break;

            //Service Titan Close Rate Report By CSR (mhm report)
            case 17:
                if (!this.IsMultipleEmployeesSelected() || !this.IsStartDateSet() || !this.IsEndDateSet() || !this.IsMultipleFranchiseSelected())
                    break;
                else {
                    $.ajax({
                        url: `/Reports/setMultiSelectTempData`,
                        type: 'POST',
                        data: {
                            'reportParameters': {
                                "ComparisonFranchiseIds": this.selectedFranchises,
                                "ComparisonEmployeeIds": this.selectedEmployees,
                                "ReportDescription": this.reportDescription,
                                "ReportTypeId": this.reportTypeId,
                                "ReportPath": this.reportPath,
                                "StartDate": this.startDateTime,
                                "EndDate": this.endDateTime,
                                "FieldsPopulated": true,
                                "ReportId": this.reportId,
                                "HasComparisonFranchises": true,
                                "HasComparisonEmployees": true
                            }

                        },
                        success: function (data) {

                            if (data) {
                                let startDate = new Date(data.StartDate);
                                let startDateFormatted = ((startDate.getMonth() > 8) ? (startDate.getMonth() + 1) : ('0' + (startDate.getMonth() + 1))) + '/' + ((startDate.getDate() > 9) ? startDate.getDate() : ('0' + startDate.getDate())) + '/' + startDate.getFullYear()
                                let endDate = new Date(data.EndDate);
                                let endDateFormatted = ((endDate.getMonth() > 8) ? (endDate.getMonth() + 1) : ('0' + (endDate.getMonth() + 1))) + '/' + ((endDate.getDate() > 9) ? endDate.getDate() : ('0' + endDate.getDate())) + '/' + endDate.getFullYear();
                                window.location.href = `/Reports/${data.ReportId}?ReportDescription=${data.ReportDescription}&ReportTypeId=${data.ReportTypeId}&ReportPath=${data.ReportPath}&FieldsPopulated=${true}&HasComparisonEmployees=${true}&HasComparisonFranchises=${true}&StartDate=${startDateFormatted}&EndDate=${endDateFormatted}`;
                            }

                        }
                    });
                    break;
                }
            //Service Titan Owner Tech Metrics Report (mhm report)
            case 18:
                if (!this.IsMultipleFranchiseSelected() || !this.IsMultipleTechniciansSelected() || !this.IsStartDateSet() || !this.IsEndDateSet())
                    break;
                else {
                    $.ajax({
                        url: `/Reports/setMultiSelectTempData`,
                        type: 'POST',
                        data: {
                            'reportParameters': {
                                "ComparisonTechnicianIds": this.selectedTechnicians,
                                "ComparisonFranchiseIds": this.selectedFranchises,
                                "ReportDescription": this.reportDescription,
                                "ReportTypeId": this.reportTypeId,
                                "ReportPath": this.reportPath,
                                "StartDate": this.startDateTime,
                                "EndDate": this.endDateTime,
                                "FieldsPopulated": true,
                                "ReportId": this.reportId,
                                "HasComparisonFranchises": true,
                                "HasComparisonTechnicians": true
                            }

                        },
                        success: function (data) {

                            if (data) {
                                let startDate = new Date(data.StartDate);
                                let startDateFormatted = ((startDate.getMonth() > 8) ? (startDate.getMonth() + 1) : ('0' + (startDate.getMonth() + 1))) + '/' + ((startDate.getDate() > 9) ? startDate.getDate() : ('0' + startDate.getDate())) + '/' + startDate.getFullYear()
                                let endDate = new Date(data.EndDate);
                                let endDateFormatted = ((endDate.getMonth() > 8) ? (endDate.getMonth() + 1) : ('0' + (endDate.getMonth() + 1))) + '/' + ((endDate.getDate() > 9) ? endDate.getDate() : ('0' + endDate.getDate())) + '/' + endDate.getFullYear();
                                window.location.href = `/Reports/${data.ReportId}?ReportDescription=${data.ReportDescription}&ReportTypeId=${data.ReportTypeId}&ReportPath=${data.ReportPath}&FieldsPopulated=${true}&HasComparisonTechnicians=${true}&HasComparisonFranchises=${true}&StartDate=${startDateFormatted}&EndDate=${endDateFormatted}`;
                            }

                        }
                    });
                    break;
                }
            //Web Lead Conversions
            case 19:
                if (!this.IsFranchiseIdSet() || !this.IsStartDateSet() || !this.IsEndDateSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}&StartDate=${kendo.toString(this.startDateTime, "d", "en-US")}&EndDate=${kendo.toString(this.endDateTime, "d", "en-US")}`;
                break;
            //Territory Analysis
            case 20:
                if (!this.IsFranchiseIdSet() || !this.IsStartDateSet() || !this.IsEndDateSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}&StartDate=${kendo.toString(this.startDateTime, "d", "en-US")}&EndDate=${kendo.toString(this.endDateTime, "d", "en-US")}`;
                break;
            //Commercial Customer Report
            case 21:
                if (!this.IsFranchiseIdSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}`;
                break;
            //Project Size Analysis
            case 22:
                if (!this.IsFranchiseIdSet() || !this.IsStartDateSet() || !this.IsEndDateSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}&StartDate=${kendo.toString(this.startDateTime, "d", "en-US")}&EndDate=${kendo.toString(this.endDateTime, "d", "en-US")}`;
                break;
            //Net Promoter Score
            case 23:
                if (!this.IsFranchiseIdSet() || !this.IsStartDateSet() || !this.IsEndDateSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}&StartDate=${kendo.toString(this.startDateTime, "d", "en-US")}&EndDate=${kendo.toString(this.endDateTime, "d", "en-US")}`;
                break;
            //LMR Analysis
            case 24:
                if (!this.IsFranchiseIdSet() || !this.IsYearSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}&Year=${this.year}`;
                break;
            //PL Analysis
            case 25:
                if (!this.IsFranchiseIdSet() || !this.IsYearSet())
                    break;
                this.$window.location.href = `/Reports/${this.reportId}?ReportDescription=${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}&Year=${this.year}`;
                break;
            //MLY Churn Risk Customers
            case 26:
                if (!this.IsFranchiseIdSet())
                    break;

                this.$window.location.href = `/Reports/${this.reportId}/MollyMaidChurnRiskCustomers?ReportDescription=` +
                    `${this.reportDescription}&ReportTypeId=${this.reportTypeId}& ReportPath=${this.reportPath}&FieldsPopulated=${true}&FranchiseId=${this.franchiseId}`;
                break;

            //Mosquito Joe Reports
            case 27:

                this.$window.location.href = `/Reports/${this.reportId}/RoyaltyReportInvoicesByPostalCode?ReportDescription=` +
                    `${this.reportDescription}&ReportTypeId=${this.reportTypeId}&ReportPath=${this.reportPath}&FieldsPopulated=${true}&RoyaltyPeriodReportId=${this.royaltyPeriodReportId}`;
                break;

            default:
                console.log("No such report exists!");
                break;
        }
    }

    ViewAllReports() {
        this.$window.location.href = `/Reports`;
    }

    IsMultipleFranchiseSelected(): boolean {
        if (!this.selectedFranchises || this.selectedFranchises && this.selectedFranchises.length <= 0) {
            alert("Please select at least one franchise");
            return false;
        }
        else {
            return true;
        }
    }

    IsMultipleTechniciansSelected(): boolean {
        if (!this.selectedTechnicians || this.selectedTechnicians && this.selectedTechnicians.length <= 0) {
            alert("Please select at least one technician");
            return false;
        }
        else {
            return true;
        }
    }

    IsMultipleEmployeesSelected(): boolean {
        if (!this.selectedEmployees || this.selectedEmployees && this.selectedEmployees.length <= 0) {
            alert("Please select at least one employee");
            return false;
        }
        else {
            return true;
        }
    }

    IsFranchiseStatusSet(): boolean {
        if (this.franchiseStatus == "Active" || this.franchiseStatus == "Inactive") {
            return true;
        }
        else {
            alert("Please select franchise status.");
            return false;
        }
    }

    IsCurrentWeekSet(): boolean {
        if (!this.currentWeek || this.currentWeek && this.currentWeek <= 0) {
            alert("Please select current week.");
            return false;
        }
        else {
            return true;
        }
    }

    IsFromWeekSet(): boolean {
        if (!this.currentWeek || this.currentWeek && this.currentWeek <= 0) {
            alert("Please select current week.");
            return false;
        }
        else {
            return true;
        }
    }

    IsFromWeekNumberSet(): boolean {
        if (!this.fromWeek || this.fromWeek && this.fromWeek <= 0) {
            alert("Please select From Week.");
            return false;
        }
        else {
            return true;
        }
    }

    IsToWeekNumberSet(): boolean {
        if (!this.toWeek || this.toWeek && this.toWeek <= 0) {
            alert("Please select To Week.");
            return false;
        }
        else {
            return true;
        }
    }

    IsYearSet(): boolean {
        if (!this.year || this.year && this.year <= 0) {
            alert("Please select year");
            return false;
        }
        else {
            return true;
        }
    }

    IsQuarterSet(): boolean {
        if (!this.quarter || this.quarter && this.quarter <= 0) {
            alert("Please select Quarter");
            return false;
        }
        else {
            return true;
        }
    }

    IsWeekEndingDateSet(): boolean {
        if (!this.weekEnding || isNaN(this.weekEnding.getTime())) {
            alert("Please select week ending date");
            return false;
        }
        else {
            return true;
        }
    }

    IsFranchiseIdSet(): boolean {
        if (!this.franchiseId || this.franchiseId && this.franchiseId <= 0) {
            alert("Please select Franchise Id");
            return false;
        }
        else {
            return true;
        }
    }

    IsStartDateSet(): boolean {
        if (!this.startDateTime || isNaN(this.startDateTime.getTime())) {
            alert("Please select start date");
            return false;
        }
        else {
            return true;
        }
    }

    IsEndDateSet(): boolean {
        if (!this.endDateTime || isNaN(this.endDateTime.getTime())) {
            alert("Please select end date");
            return false;
        }
        else {
            return true;
        }
    }

    IsCYWeDateSet(): boolean {
        if (!this.cyWeDate || isNaN(this.cyWeDate.getTime())) {
            alert("Please select CYWe Date");
            return false;
        }
        else {
            return true;
        }
    }

    IsPYWeDateSet(): boolean {
        if (!this.pyWeDate || isNaN(this.pyWeDate.getTime())) {
            alert("Please select PYWe Date");
            return false;
        }
        else {
            return true;
        }
    }

    async SelectFranchiseIfSingleOwnership() {
        let userInfo = (await this.identityManager.GetLoggedInUserInfo()).data;
        let franchiseIds = userInfo.Claims
            .filter(c => c.Type == this.apiConfig.FranForceConstants.ClaimConstants.OwnedFranchiseIdClaimType)
            .map(c => parseInt(c.Value));

        if (franchiseIds.length === 1)
            this.franchiseId = franchiseIds[0];
    }

    OnFranchiseChange(franchise) {
        this.franchiseId = franchise.FranchiseId;
        this.conceptId = franchise.ConceptId;
    }

    OnYearSelect(year: YearSelectorOnSelectParams["year"]) {
        this.year = year?.year;
    }

    OnDayOfWeekSelect(weekEndingDate: WeekEndingDateSelectorOnSelectParams["weekEndingDate"]) {
        this.weekEnding = weekEndingDate?.weekEndingDateValue;
    }

    OnQuarterSelect(quarter: QuarterSelectorOnSelectParams["quarter"]) {
        this.quarter = quarter?.quarterValue;
    }

    OnFranchiseStatusSelect(franchiseStatus: FranchiseStatusSelectorOnSelectParams["franchiseStatus"]) {
        this.franchiseStatus = franchiseStatus?.franchiseStatusValue;

        this.CreateComparisonFranchiseFilters();
    }

    static BindComponent(app: ng.IModule) {
        app.component("reportDetails", {
            bindings: {
                reportId: "<",
                reportTypeId: "<",
                selectedFranchisesParam: "<",
                selectedTechniciansParam: "<",
                selectedEmployeesParam: "<"
            },
            controller: ReportDetails,
            transclude: {
                'report': 'report'
            },
            templateUrl: "/Templates/Reports/ReportDetails.html"
        });
    }
}

