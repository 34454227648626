import * as elements from 'typed-html';
import { ExpressionBinding } from 'Utility/AngularUtil';
import { StringProperties } from 'Types/StringProperties';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            franchiseStatusSelector: StringProperties<Partial<FranchiseStatusSelector>>;
        }
    }
}

export class FranchiseStatus {
    franchiseStatusValue: string;
    franchiseStatusLabel: string;
}

export type FranchiseStatusSelectorOnSelectParams = {
    event: kendo.ui.DropDownListSelectEvent,
    franchiseStatus: FranchiseStatus;
}

export class FranchiseStatusSelector implements ng.IController {
    franchiseStatus: string;
    readOnly: boolean;
    onSelect: ExpressionBinding<FranchiseStatusSelectorOnSelectParams>;
    dropDown: kendo.ui.DropDownList;

    static $inject = []

    constructor() { }

    $onInit() { }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.setOptions({ enable: !this.readOnly })
        }
    }

    $postLink() {
        this.InitDropdown();
        if (this.franchiseStatus) {
            this.dropDown.select(x => x.franchiseStatusValue == this.franchiseStatus);
            this.dropDown.trigger("change");
        }
    }

    InitDropdown() {
        this.dropDown.setOptions(
            {
                autoBind: true,
                valuePrimitive: true,
                dataValueField: nameof<FranchiseStatus>(o => o.franchiseStatusValue),
                dataTextField: nameof<FranchiseStatus>(o => o.franchiseStatusLabel),
                optionLabel: "Select...",
                optionLabelTemplate: "Select...",
                autoWidth: true,
                enable: !this.readOnly,
                select: (e) => {
                    if (this.onSelect) {
                        this.onSelect({
                            event: e,
                            franchiseStatus: e.dataItem
                        });
                    }
                }
            } as kendo.ui.DropDownListOptions
        );

        let dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    let franchiseStatusList: FranchiseStatus[] = [];
                    franchiseStatusList.push({ franchiseStatusLabel: "Active", franchiseStatusValue: "Active" });
                    franchiseStatusList.push({ franchiseStatusLabel: "Inactive", franchiseStatusValue: "Inactive" });
                    options.success(franchiseStatusList);
                }
            }
        });

        this.dropDown.setDataSource(dataSource);
    }

    static BindComponent(app: ng.IModule) {
        const template =
            <select kendo-drop-down-list={`$ctrl.${nameof<FranchiseStatusSelector>(o => o.dropDown)}`}
                k-ng-model={`$ctrl.${nameof<FranchiseStatusSelector>(o => o.franchiseStatus)}`}
            >
            </select>;

        app.component(nameof<JSX.IntrinsicElements>(o => o.franchiseStatusSelector), {
            bindings: {
                [nameof<FranchiseStatusSelector>(o => o.franchiseStatus)]: "<",
                [nameof<FranchiseStatusSelector>(o => o.readOnly)]: "<",
                [nameof<FranchiseStatusSelector>(o => o.onSelect)]: "&?",
            },
            template: template,
            controller: FranchiseStatusSelector
        });
    }


}