import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";

import { Helpers } from "Utility/Helpers";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

interface IDppServiceProviderSelectorDirectiveScope extends ng.IScope
{
    //External scope
    dppId: number;
    dppServiceProvider: DppResources.IDisasterPrepProfileServiceProvider;
    dppServiceProviderType: DppResources.IDisasterPrepProfileServiceProviderType;
    availableFranchises: CoreResources.IFranchise[];
    dppExternalProviderDictionary: { [_id: string]: DppResources.IDisasterPrepProfileExternalProvider };
    dppRefreshExternalProviders: () => ng.IPromise<DppResources.IDisasterPrepProfileExternalProvider[]>;

    //Internal Scope
    isCreatingNewExternalProvider: boolean;
    isEditingExternalProvider: boolean;

    newExternalProvider: DppResources.IDisasterPrepProfileExternalProvider;
    editingExternalProvider: DppResources.IDisasterPrepProfileExternalProvider;

    ToggleEdit: (providerToEdit: DppResources.IDisasterPrepProfileExternalProvider) => void;
    ToggleAddNew: () => void;
    RefreshNewExternalProvider: () => void;

    Cancel: (skipPrompt?: boolean) => void;
    DeleteExternalProvider: (externalProvider: DppResources.IDisasterPrepProfileExternalProvider) => void;
    UpdateExternalProvider: (externalProvider: DppResources.IDisasterPrepProfileExternalProvider) => void;
    SaveAndSelectExternalProvider: (externalProvider: DppResources.IDisasterPrepProfileExternalProvider) => void;
}

export class DppServiceProviderSelectorDirective
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.directive('dppServiceProviderSelector', ['$window', '$timeout', '$resource', 'dppApiResources',
            ($window: ng.IWindowService, $timeout: ng.ITimeoutService, $resource: ng.resource.IResourceService, dppApiResources: DppApiResources): ng.IDirective =>
            {
                return {
                    scope: {
                        dppId: '=',
                        dppServiceProvider: '=',
                        dppServiceProviderType: '=',
                        availableFranchises: '=',
                        dppExternalProviderDictionary: '=',
                        dppRefreshExternalProviders: '&'
                    },
                    restrict: "A",
                    replace: false,
                    transclude: false,
                    templateUrl: '/Templates/Dpp/Directives/ServiceProviderSelector.html',
                    link: (scope: IDppServiceProviderSelectorDirectiveScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) =>
                    {
                        scope.isCreatingNewExternalProvider = false;
                        scope.isEditingExternalProvider = false;

                        scope.ToggleEdit = (providerToEdit: DppResources.IDisasterPrepProfileExternalProvider) =>
                        {
                            scope.isEditingExternalProvider = !scope.isEditingExternalProvider;
                            scope.isCreatingNewExternalProvider = false;
                            scope.editingExternalProvider = angular.copy(providerToEdit);
                        };

                        scope.ToggleAddNew = () =>
                        {
                            scope.isCreatingNewExternalProvider = !scope.isCreatingNewExternalProvider;
                            scope.isEditingExternalProvider = false;
                        };

                        scope.RefreshNewExternalProvider = () =>
                        {
                            scope.newExternalProvider = new dppApiResources.DisasterPrepProfileExternalProviderApi();
                            scope.newExternalProvider.DppId = scope.dppId;
                        }

                        scope.RefreshNewExternalProvider();

                        scope.Cancel = (skipPrompt: boolean) =>
                        {
                            if (skipPrompt || confirm("Are you sure you want to cancel?")) {
                                scope.isEditingExternalProvider = false;
                                scope.isCreatingNewExternalProvider = false;
                                scope.editingExternalProvider = null;
                            }
                        }

                        scope.DeleteExternalProvider = (externalProvider: DppResources.IDisasterPrepProfileExternalProvider) =>
                        {
                            if (confirm(`Are you sure you want to delete the external provider, '${externalProvider.Name}'`)) {
                                return externalProvider.$delete({ id: externalProvider.DppExternalProviderId, allowHardDelete: true },
                                    (response) =>
                                    {
                                        return scope.dppRefreshExternalProviders().then(() =>
                                        {
                                            scope.Cancel(true);
                                        });
                                    },
                                    (err) =>
                                    {
                                        console.error(err);
                                    });
                            }
                        };

                        scope.UpdateExternalProvider = (externalProvider: DppResources.IDisasterPrepProfileExternalProvider) =>
                        {
                            externalProvider.PhoneNumber = Helpers.StripNonNumerices(externalProvider.PhoneNumber);
                            return externalProvider.$update({ id: externalProvider.DppExternalProviderId }, (response) =>
                            {
                                console.log(response);
                                return scope.dppRefreshExternalProviders().then(() =>
                                {
                                    scope.Cancel(true);
                                });
                            },
                                (err) =>
                                {
                                    console.error(err);
                                });
                        };

                        scope.SaveAndSelectExternalProvider = (externalProvider: DppResources.IDisasterPrepProfileExternalProvider) =>
                        {
                            externalProvider.DppId = scope.dppId;
                            externalProvider.PhoneNumber = Helpers.StripNonNumerices(externalProvider.PhoneNumber);

                            return externalProvider.$save((response) =>
                            {
                                console.log(response);
                                return scope.dppRefreshExternalProviders().then(() =>
                                {

                                    scope.dppServiceProvider.DppExternalProviderId = externalProvider.DppExternalProviderId;
                                    scope.RefreshNewExternalProvider();
                                    scope.isEditingExternalProvider = false;
                                    scope.isCreatingNewExternalProvider = false;
                                });
                            },
                                (err) =>
                                {
                                    console.error(err);
                                });
                        };
                    }
                };
            }]);
    }
}

