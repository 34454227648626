import { LegalApiResources } from "Services/Resources/LegalApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { LegalEntities } from "Interfaces/FranForce/Legal/LegalResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

interface INewContractInformationScope {
    newContactInformation: LegalEntities.ContractInformation;
    newContractInformationForm: any;
    ContractTypeID: string;
    SPALoanOptions: LegalEntities.SPALoanOption[];
    legalApiResources: LegalApiResources;
    acceptInformation: () => void;
    cancelInformation: () => void;
    kNotifierOptions: any;
    kNotifier: kendo.ui.Notification;
    saveSuccessful: boolean;
}

export class NewContractInformationController implements ng.IController 
{
    static $inject = [
        "$scope",
        "coreApiResources",
        "$window",
        "$q",
        "$log",
        "$http",
        "legalApiResources"
    ];
    
    constructor(
        private $scope: INewContractInformationScope,
        private coreApiResources: CoreApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $http: ng.IHttpService,
        private legalApiResources: LegalApiResources
    )
    {
        $scope.kNotifierOptions = {
            position: {
                top: 50,
                left: $(window).width() / 2
            },
            templates: [{
                type: 'ngTemplate',
                template: $('#kNTemplate').html(),
            }]
        };
    }

    $onInit() {
        let $scope = this.$scope;
        let legalApiResources = this.legalApiResources;
        let franchiseID = location.pathname.split("/")[3]
        $scope.legalApiResources = legalApiResources;
        $scope.ContractTypeID = location.pathname.split("/")[4];

        this.coreApiResources.FranchiseApi.get({
            id: franchiseID, $expand: "Concept"
        }, (franchise: CoreEntities.Franchise) => {
            let address = franchise.Address == null ? "" : franchise.Address;
            let address2 = franchise.Address2 == null ? "" : franchise.Address2;
            let city = franchise.City == null ? "" : franchise.City;
            let stateAbbr = franchise.StateAbbr == null ? "" : franchise.StateAbbr;
            let postalCode = franchise.PostalCode == null ? "" : franchise.PostalCode;
            $scope.newContactInformation = <any>{};
            $scope.newContactInformation.ConceptID = franchise.ConceptId;
            $scope.newContactInformation.LicenseNumber = franchise.LicenseNumber;
            $scope.newContactInformation.Franchisees = franchise.FranchiseeName;
            $scope.newContactInformation.BusinessAddress = address + " " + address2 ;
            $scope.newContactInformation.BusinessAddress1 = city + ", " + stateAbbr + " " + postalCode;
            $scope.newContactInformation.ConceptName = franchise.Concept.DisplayName
            $scope.newContactInformation.BusinessPhone = franchise.StorePhone;
            $scope.newContactInformation.BusinessEmailAddress = franchise.StoreEmail;
            $scope.newContactInformation.OwnerName = franchise.ContactFirstName + " " + franchise.ContactLastName;
            $scope.newContactInformation.DoingBusinessAs = franchise.DoingBusinessAs;
            $scope.newContactInformation.SPALoanOptionID = 1;
        });

        this.legalApiResources.SPALoanOptionApi.query({}, successCallback => {
            $scope.SPALoanOptions = successCallback;
        }, errorCallback => {
            $scope.SPALoanOptions = <any>[{ Description: "Yes", SPALoanOptionID: 1 }, { Description: "No", SPALoanOptionID: 2 }, { Description: "Unknown", SPALoanOptionID: 3 }];
        });

        $scope.cancelInformation = function () {
            window.location.href = "/Itt";   
        }

        $scope.acceptInformation = function () {
            if ($scope.newContractInformationForm.$valid) {
                $scope.newContactInformation.ContractInformationID = 0;
                legalApiResources.ContractInformationApi.save($scope.newContactInformation, function successCallback(response: LegalEntities.ContractInformation) {
                    legalApiResources.ContractStatuApi.query({
                        $filter: "Description eq " + "'In Progress'"    
                    }, (contractStatuses: LegalEntities.ContractStatu[]) => {
                        var contract = <LegalEntities.Contract>{};
                        contract.ContractInformationID = response.ContractInformationID;
                        contract.DateCreated = new Date().toISOString();
                        contract.ContractStatusID = contractStatuses[0].ContractStatusID;
                        contract.ContractTypeID = Number($scope.ContractTypeID);
                        contract.FranchiseID = Number(franchiseID);
                        contract.IsCureNotificationSent = false;
                        $scope.legalApiResources.ContractApi.save({}, contract, function successCallback(response: LegalEntities.Contract) {
                            window.location.href = "/Itt/NewSectionOne/" + franchiseID + "/" + response.ContractID;    
                        }, function errorCallback(response) {

                        });
                    });               
                }, function errorCallback(response) {
                    console.log(response);
                });

            } else {
                $scope.saveSuccessful = false;
                $scope.kNotifier.show({
                    kValue: "Some field(s) require input to proceed "
                }, "ngTemplate");

                Object.keys($scope.newContractInformationForm.$error).forEach(function (value) {             
                    $scope.newContractInformationForm.$error[value].forEach(function (item) {
                        item.$setDirty();
                    });
                });    
            }
        }
    }
}