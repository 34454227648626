import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyDisclosureDocumentRatePlanItemDetailsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyDisclosureDocumentRatePlanItemDetailsByRatePlanItem(ratePlanItemId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlanItemDetails/InRatePlanItem/" + ratePlanItemId
        };

        return this.$http<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel[]>(config);
    }

    CreateRoyaltyDisclosureDocumentRatePlanItemDetail(model: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlanItemDetails",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyDisclosureDocumentRatePlanItemDetail(id: number, model: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlanItemDetails/" + id,
            data: model
        };

        return this.$http<number>(config);
    }
}