import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyMethodologyTypesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetAllRoyaltyMethodologyTypes()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyMethodologyTypes"
        };

        return this.$http<RpmEntities.usp_RoyaltyMethodologyTypes_GetByRoyaltyMethodologyTypeId_Result[]>(config);
    }
}