
import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { IEditDisasterPrepProfileSectionScope } from "@DppApp/EditDisasterPrepProfileSectionController";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";

interface IEditDisasterPrepProfileBuildingHazardousMaterialsScope extends IEditDisasterPrepProfileSectionScope {
    dppBuildingAssetCategoryId: number;
    hazardInfo: DppResources.IDisasterPrepProfileBuildingHazardousMaterial;
    SaveHazards: () => ng.IPromise<DppResources.IDisasterPrepProfileBuildingHazardousMaterial>;
}


export class EditDisasterPrepProfileBuildingHazardousMaterialsController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('EditDisasterPrepProfileBuildingHazardousMaterialsController',
            [
                '$scope', 'dppApiResources',
                ($scope: IEditDisasterPrepProfileBuildingHazardousMaterialsScope, dppApiResources: DppApiResources) =>
                {
                    if (!$scope.dppBuildingId) {
                        $scope.RedirectHome();
                        return;
                    }

                    $scope.dppBuildingAssetCategoryId = 4;

                    let promise = dppApiResources.DisasterPrepProfileBuildingHazardousMaterialApi.get({ id: $scope.dppBuildingId },
                        (hazardInfo: DppResources.IDisasterPrepProfileBuildingHazardousMaterial) =>
                        {
                            $scope.hazardInfo = hazardInfo;
                        },
                        (err: ng.IHttpPromiseCallbackArg<any>) =>
                        {
                            console.error(err);
                            if (err.status === 404) {
                                $scope.hazardInfo = new dppApiResources.DisasterPrepProfileBuildingHazardousMaterialApi();
                            }
                            else {
                                alert("Could not get the Hazardous Material info for this building.");
                            }
                        }).$promise;

                    $scope.QueueLoadingPromises(promise);

                    $scope.SaveHazards = () =>
                    {
                        if ($scope.hazardInfo.DppBuildingId) {
                            return $scope.hazardInfo.$update({ id: $scope.hazardInfo.DppBuildingId },
                                (response) =>
                                {
                                    console.log(response);
                                },
                                (err) =>
                                {
                                    console.error(err);
                                });
                        }
                        else {
                            $scope.hazardInfo.DppBuildingId = $scope.dppBuildingId;
                            return $scope.hazardInfo.$save((response) =>
                            {
                                console.log(response);
                            },
                                (err) =>
                                {
                                    console.error(err);
                                });
                        }
                    };
                }
            ]);
    }
}
