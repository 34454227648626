import { LegalApiResources } from "Services/Resources/LegalApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { LegalEntities } from "Interfaces/FranForce/Legal/LegalResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

interface INewSectionOneScope extends ng.IScope {
    contractId: string;
    franchiseId: string;
    selectionDetails: { franchiseID: string, contractID: string };
    monetaryItemOptions: LegalEntities.MonetaryItem[];
    operationItemOptions: LegalEntities.OperationItem[];
    reportingItemOptions: LegalEntities.ReportingItem[];
    timeScales: LegalEntities.TimeScale[];
    model: LegalEntities.SectionOne;
    MonetaryItem: number[];
    ReportingItem: number[];
    OperationItem: number[];
    OperationOtherFlag: boolean;
    OperationOtherComment: string;
    SubtotalFees: () => number;
    PromissoryNotes: LegalEntities.SectionOnePromissoryNote[];
    promissoryNotesGridOptions: kendo.ui.GridOptions;
    sectionOneApprovedDateOptions: kendo.ui.DatePickerOptions;
    newSectionOneForm: any;
    submitSectionOne: (any) => void;
    promissoryFees: (any) => void;
    onOperationMultiSelectChange: (any) => void;
    onOverrideClick: (arg: string) => void;
    addOverrideInfo: (comment: string) => void;
    gridDataSource: kendo.data.DataSource;
    options: kendo.ui.NumericTextBoxOptions;
    cancelSectionOne: (any) => void;
    isItt: boolean;
    userEmail: string;
    kNotifierOptions: any;
    kNotifier: kendo.ui.Notification;
    saveSuccessful: boolean;
    saveBtnDisabled: boolean;
    denyBtnDisabled: boolean;
    currentlyOverriding: string;
    initialMinimumFee: number;
    initialReportingFee: number;
    initialEstimateFee: number;
    initialSoftwareFee: number;
    initialNSFFee: number;
    initialLateFee: number;
    isCheckChanging: boolean;
}

export class NewSectionOneController implements ng.IController {

    static $inject = [
        "$scope",
        "$window",
        "$q",
        "$log",
        "$http",
        "legalApiResources",
        "coreApiResources",
        "identityManager",
        "ngDialog"
    ];

    constructor(
        private $scope: INewSectionOneScope,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $http: ng.IHttpService,
        private legalApiResources: LegalApiResources,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private ngDialog: any
    )
    {
        $scope.saveBtnDisabled = false;
        $scope.denyBtnDisabled = false;

        $scope.kNotifierOptions = {
            position: {
                top: 50,
                left: $(window).width() / 2
            },
            templates: [{
                type: 'ngTemplate',
                template: $('#kNTemplate').html(),
            }]
        };
    }

    $onInit() {
        let $scope = this.$scope;
        let $http = this.$http;
        let $q = this.$q;
        let legalApiResources = this.legalApiResources;
        let coreApiResources = this.coreApiResources;
        let identityManager = this.identityManager;
        let columns: Array<kendo.ui.GridColumn> =
            [{
                field: "NoteNumber",
                title: "Note Number",
                width: "130px"
            }, {
                    field: "PromissoryNoteDate",
                    title: "Note Date",
                    template: "#= kendo.toString(kendo.parseDate(PromissoryNoteDate, 'yyyy-MM-dd'), 'MM/dd/yyyy') #",
                    width: "130px"
                }, {
                    field: "OriginalPrincipalAmount",
                    title: "Original Principal Amount",
                    format: "{0:c2}"
                }, {
                    field: "AmountToCureArrearage",
                    title: "Amount to Cure Arrearage",
                    format: "{0:c2}"
                }, {
                    field: "OutstandingBalance",
                    title: "Outstanding Balance",
                    format: "{0:c2}"
                }];
        

        // Assign selection details to retrieve associated data
        $scope.franchiseId = location.pathname.split("/")[3] || $scope.selectionDetails.franchiseID;
        $scope.contractId = location.pathname.split("/")[4] || $scope.selectionDetails.contractID;

        coreApiResources.FranchiseApi.get({ $expand: "Concept", id: $scope.franchiseId },
            (successResponse: CoreEntities.Franchise) => {
                $scope.$emit("setFranchiseDetails", {
                    FranchiseName: successResponse.Concept.DisplayName,
                    LicenseNum: successResponse.LicenseNumber,
                    FranchiseeCode: $scope.franchiseId,
                    FranchiseAddress: successResponse.Address,
                    FranchisePhoneNumber: successResponse.StorePhone,
                    FranchiseEmail: successResponse.StoreEmail
                });
            },
            errorResponse => {
                console.log(errorResponse);
            });

        $scope.gridDataSource = new kendo.data.DataSource({
            data: [],
            schema: {
                model: {
                    fields: {
                        NoteNumber: { type: "number" },
                        PromissoryNoteDate: { type: "date" },
                        OriginalPrincipalAmount: { type: "number" },
                        AmountToCureArrearage: { type: "number" },
                        OutstandingBalance: { type: "number" }
                    }
                }
            },
            pageSize: 10
        });

        $scope.promissoryNotesGridOptions = {
            dataSource: $scope.gridDataSource,
            sortable: true,
            pageable: true,
            columns: columns,
            editable: true
        }
        
        $scope.options = {
            format: "c0",
            spinners: false 
        }

        $scope.SubtotalFees = function () {
            if ($scope.model != undefined) {
                return $scope.model.MinimumFees + $scope.model.ReportingFees +
                    $scope.model.EstimatedFees + $scope.model.SoftwareFees + $scope.model.NSFFees +
                    $scope.model.LateFees + Number($scope.model.OtherFees1) + $scope.model.OtherFees2 + $scope.model.OtherFees3;
            }

            return 0;
        }

        $scope.promissoryFees = function () {
            if ($scope.gridDataSource != undefined) {
                var fees = 0;
                $scope.gridDataSource.data().toJSON().forEach(function (value: LegalEntities.SectionOnePromissoryNote) {
                    if ($scope.isItt == true) {
                        fees += value.AmountToCureArrearage;
                    } else {
                        fees += value.OutstandingBalance;
                    }
                });

                return fees;
            }

            return 0;
        }

        $scope.submitSectionOne = () => {
            if ($scope.newSectionOneForm.$valid) {
                saveSectionOne("Section 1 Completed", true)    
            } else {
                $scope.saveSuccessful = false;
                $scope.kNotifier.show({
                    kValue: "Some field(s) require input to proceed "
                }, "ngTemplate");

                Object.keys($scope.newSectionOneForm.$error).forEach(function (value) {
                    $scope.newSectionOneForm.$error[value].forEach(function (item) {
                        item.$setDirty();
                    });
                });
            }
        };
        $scope.onOperationMultiSelectChange = () =>
        {
            let boolFound = false;
            
            for (let item of $scope.OperationItem)
            {
                if (item == 5)
                {
                    boolFound = true;
                }
            }

            $scope.OperationOtherFlag = boolFound;
        }
        $scope.cancelSectionOne = () => {
            saveSectionOne("Section 1 Denied", false); 
        };

        this.$scope.isCheckChanging = false;

        $scope.onOverrideClick = (arg: string) => {
            if (!this.$scope.isCheckChanging) {
                this.$scope.isCheckChanging = true;
                let checkBoxId = "cbx" + arg + "Override";
                let val = <HTMLInputElement>document.getElementById(checkBoxId);
                if (val.checked != true) {
                    switch (arg) {
                        case 'Minimum':
                            this.$scope.model.MinimumFees = this.$scope.initialMinimumFee;
                            this.$scope.model.MinimumFeesOverrideComment = null;
                            break;
                        case 'Reporting':
                            this.$scope.model.ReportingFees = this.$scope.initialReportingFee;
                            this.$scope.model.ReportingFeesOverrideComment = null;
                            break;
                        case 'Estimate':
                            this.$scope.model.EstimatedFees = this.$scope.initialEstimateFee;
                            this.$scope.model.EstimatedFeesOverrideComment = null;
                            break;
                        case 'Software':
                            this.$scope.model.SoftwareFees = this.$scope.initialSoftwareFee;
                            this.$scope.model.SoftwareFeesOverrideComment = null;
                            break;
                        case 'NSF':
                            this.$scope.model.NSFFees = this.$scope.initialNSFFee;
                            this.$scope.model.NSFFeesOverrideComment = null;
                            break;
                        case 'Late':
                            this.$scope.model.LateFees = this.$scope.initialLateFee;
                            this.$scope.model.LateFeesOverrideComment = null;
                            break;
                    }
                    this.$scope.isCheckChanging = false;
                    return;
                }
                this.$scope.currentlyOverriding = arg;
                switch (arg) {
                    case 'Minimum':
                        this.$scope.model.MinimumFeesOverride = false;
                        break;
                    case 'Reporting':
                        this.$scope.model.ReportingFeesOverride = false;
                        break;
                    case 'Estimate':
                        this.$scope.model.EstimatedFeesOverride = false;
                        break;
                    case 'Software':
                        this.$scope.model.SoftwareFeesOverride = false;
                        break;
                    case 'NSF':
                        this.$scope.model.NSFFeesOverride = false;
                        break;
                    case 'Late':
                        this.$scope.model.LateFeesOverride = false;
                        break;
                }
                this.$scope.isCheckChanging = false;

                this.ngDialog.open({
                    template: 'SectionOneOverrideModalTemplate',
                    className: 'ngdialog-theme-default itt-modal',
                    scope: this.$scope,
                    controller: ['$scope', ($scope) => { }]
                });
            }
        };

        $scope.addOverrideInfo = (comment: string) =>
        {
            if (comment == null || comment == "") {
                alert("A comment must be provided in order to override the amount.");
                return;
            }

            switch (this.$scope.currentlyOverriding)
            {
                case 'Minimum':
                    if (comment != null && comment != "") {
                        this.$scope.model.MinimumFeesOverrideComment = comment;
                        this.$scope.model.MinimumFeesOverride = true;
                    }
                    break;
                case 'Reporting':
                    if (comment != null && comment != "") {
                        this.$scope.model.ReportingFeesOverrideComment = comment;
                        this.$scope.model.ReportingFeesOverride = true;
                    }
                    break;
                case 'Estimate':
                    if (comment != null && comment != "") {
                        this.$scope.model.EstimatedFeesOverrideComment = comment;
                        this.$scope.model.EstimatedFeesOverride = true;
                    }
                    break;
                case 'Software':
                    if (comment != null && comment != "") {
                        this.$scope.model.SoftwareFeesOverrideComment = comment;
                        this.$scope.model.SoftwareFeesOverride = true;
                    }
                    break;
                case 'NSF':
                    if (comment != null && comment != "") {
                        this.$scope.model.NSFFeesOverrideComment = comment;
                        this.$scope.model.NSFFeesOverride = true;
                    }
                    break;
                case 'Late':
                    if (comment != null && comment != "") {
                        this.$scope.model.LateFeesOverrideComment = comment;
                        this.$scope.model.LateFeesOverride = true;
                    }
                    break;
            }
            this.ngDialog.close();

        }

        this.legalApiResources.ContractApi.get({
            id: $scope.contractId,
            $expand: "SectionOne($expand=SectionOneMonetaryItems,SectionOneOperationItems,SectionOnePromissoryNotes,SectionOneReportingItems),ContractStatu"
        }, (successResponse: LegalEntities.Contract) => {
            $scope.isItt = successResponse.ContractStatu.Description == "Intent To Terminate";

            legalApiResources.GetSectionOne($scope.contractId).then(successResponse => {
                $scope.model = successResponse.data[0];
                this.$scope.initialMinimumFee = $scope.model.MinimumFees;
                this.$scope.initialReportingFee = $scope.model.ReportingFees;
                this.$scope.initialEstimateFee = $scope.model.EstimatedFees;
                this.$scope.initialSoftwareFee = $scope.model.SoftwareFees;
                this.$scope.initialNSFFee = $scope.model.NSFFees;
                this.$scope.initialLateFee = $scope.model.LateFees;
                // Initialize Approval Sign-Off
                identityManager.GetLoggedInUserInfo().then(
                    (success) => {
                        $scope.userEmail = success.data.Email;
                    },
                    (error) => {
                        console.log(error);
                    });
            }, errorResponse => {
                console.log(errorResponse);
            });

            legalApiResources.GetPromissoryNotes($scope.franchiseId).then(successResponse => {
                $scope.gridDataSource.data(successResponse.data);
            }, errorResponse => {
                console.log(errorResponse);
            });

            legalApiResources.GetSelectedMultiselects($scope.franchiseId).then(successResponse => {
                $scope.MonetaryItem = successResponse.data.selectedMonetaryItems;
                $scope.ReportingItem = successResponse.data.selectedOperationalItems;
                $scope.OperationItem = successResponse.data.selectedReportingItems;
            }, errorResponse => {

            });
        }, errorResponse => {
            console.log(errorResponse);
        });

        this.legalApiResources.MonetaryItemApi.query({}, successResponse => {
            $scope.monetaryItemOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        this.legalApiResources.OperationItemApi.query({}, successResponse => {
            $scope.operationItemOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        this.legalApiResources.ReportingItemApi.query({}, successResponse => {
            $scope.reportingItemOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        this.legalApiResources.TimeScaleApi.query({}, successResponse => {
            $scope.timeScales = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        function saveSectionOne(status: string, isAccepted: boolean) {
            $scope.saveBtnDisabled = true;
            $scope.denyBtnDisabled = true;

            // Remove signature if NOT APPROVED
            if (isAccepted)
            {
                $scope.model.ApprovedName = $scope.userEmail;
                $scope.model.ApprovedDate = kendo.toString(new Date(), 'MM/dd/yyyy');
            }

            legalApiResources.SectionOneApi.save({}, $scope.model, (sectionOneResponse: LegalEntities.SectionOne) => {

                var promises = [];

                if ($scope.MonetaryItem.length > 0) {
                    $scope.MonetaryItem.forEach(function (value) {
                        promises.push(legalApiResources.SectionOneMonetaryItemApi.save({ SectionOneID: sectionOneResponse.SectionOneID, MonetaryItemID: value }, successResponse => {

                        }, errorResponse => {
                            console.log(errorResponse);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        }).$promise);
                    });
                }

                if ($scope.ReportingItem.length > 0) {
                    $scope.ReportingItem.forEach(function (value) {
                        promises.push(legalApiResources.SectionOneReportingItemApi.save({ SectionOneID: sectionOneResponse.SectionOneID, ReportingItemID: value }, successResponse => {

                        }, errorResponse => {
                            console.log(errorResponse);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        }).$promise);
                    });
                }

                if ($scope.OperationItem.length > 0) {
                    $scope.OperationItem.forEach(function (value) {
                        let comment = "";
                        if (value == 5)
                        {
                            comment = $scope.OperationOtherComment;
                        }
                        promises.push(legalApiResources.SectionOneOperationItemApi.save({ SectionOneID: sectionOneResponse.SectionOneID, OperationItemID: value, Comment: comment}, successResponse => {

                        }, errorResponse => {
                            console.log(errorResponse);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        }).$promise);
                    });
                }
                var promissoryNotes = $scope.promissoryNotesGridOptions.dataSource.data().toJSON();
                if (promissoryNotes.length > 0) {
                    promissoryNotes.forEach(function (value) {
                        value.PromissoryNoteDate = kendo.toString(kendo.parseDate(value.PromissoryNoteDate, 'yyyy-MM-dd'), 'MM/dd/yyyy');
                        value.SectionOneID = sectionOneResponse.SectionOneID;
                        promises.push(legalApiResources.SectionOnePromissoryNoteApi.save(value, successResponse => {

                        }, errorResponse => {
                            console.log(errorResponse);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        }).$promise);
                    });
                }

                if (promises.length > 0) {
                    $q.all(promises).then(
                        successCallbackResponse => {
                            var finishPromise;
                            if (isAccepted) {
                                finishPromise = legalApiResources.SectionOneCompleted($scope.contractId, sectionOneResponse.SectionOneID);
                            } else {
                                finishPromise = legalApiResources.SectionOneDenied($scope.contractId, sectionOneResponse.SectionOneID);
                            }

                            finishPromise.then(response => {
                                window.location.href = "/Itt";
                            }, errorResponse => {
                                if (errorResponse.status == 404) {
                                    window.location.href = "/Itt";
                                }
                                else
                                {
                                    $scope.kNotifier.show({
                                        kValue: "Failed to save to server. Please contact an admin."
                                    }, "ngTemplate");
                                    console.log(errorResponse);
                                    $scope.saveBtnDisabled = false;
                                    $scope.denyBtnDisabled = false;
                                }
                            });
                        },
                        errorCallbackResponse => {
                            $scope.kNotifier.show({
                                kValue: "Failed to save to server. Please contact an admin."
                            }, "ngTemplate");
                            console.log(errorCallbackResponse);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        });
                }

                
            }, sectionOneErrorResponse => {
                $scope.saveBtnDisabled = false;
                $scope.denyBtnDisabled = false;
            });
        }
    }
}

