import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AccountInfoStateHandler } from "StateHandlers/Identity/AccountInfoStateHandler";
import { IdentityResources } from "Interfaces/FranForce/Identity/IdentityResources";
import { IdentityResourceApi } from "Interfaces/FranForce/Identity/IdentityResourceApiInterfaces";
import { AccountClient } from "Clients/Identity/AccountClient";

export class IdentityManager
{
    EntityUserPermissionApi: IdentityResourceApi.IEntityUserPermissionApi;

    AccountInfoStateHandler: AccountInfoStateHandler; 

    accountClient: AccountClient;

    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {
        this.RefreshResources();
    }

    RefreshResources(): void
    {
        this.EntityUserPermissionApi = this.$resource<IdentityResources.IEntityUserPermission>(this.apiConfig.AppSettings.IdentityApiRootUrl + "/EntityUserPermissions/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.AccountInfoStateHandler = new AccountInfoStateHandler(this);
        this.accountClient = new AccountClient();
    }

    PostLoginToken(model: { username: string, password: string, grantType: string })
    {
        let urlEncodedData = `userName=${encodeURIComponent(model.username)}&password=${encodeURIComponent(model.password)}&grant_type=${encodeURIComponent(model.grantType)}`;

        return this.$http.post<IAuthToken>(this.apiConfig.AppSettings.IdentityApiRootUrl + "/Token",
            urlEncodedData,
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        );
    }

    GetLoggedInUserInfo() { return this.accountClient.GetLoggedInUserInfo(); }
    
    GetManagedUser(userId: string, parameters: ODataQueryParameters & { readonly?: boolean } = null): ng.IHttpPromise<IManagedUserViewModel> 
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Users/Managed/" + userId,
            params: parameters
        };

        return this.$http<IManagedUserViewModel>(config);
    }

    GetManagedUsers(parameters: ODataQueryParameters & { readonly?: boolean } = null): ng.IHttpPromise<IManagedUserViewModel[]> 
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Users/Managed/",
            params: parameters
        };

        return this.$http<IManagedUserViewModel[]>(config);
    }

    GetRoles(params: ODataQueryParameters | Object = null): ng.IHttpPromise<IRoleViewModel[]> {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Roles/",
            params: params
        };

        return this.$http<IRoleViewModel[]>(config);
    }

    GetManagedRole(roleId: string, params: ODataQueryParameters | Object = null): ng.IHttpPromise<IRoleViewModel>
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Roles/Managed/" + roleId,
            params: params
        };

        return this.$http<IRoleViewModel>(config);
    }

    GetManagedRoles(params: ODataQueryParameters | Object = null): ng.IHttpPromise<IRoleViewModel[]>
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Roles/Managed/",
            params: params
        };

        return this.$http<IRoleViewModel[]>(config);
    }

    GetRoleByName(roleName: string, params: ODataQueryParameters | Object = null): ng.IHttpPromise<IRoleViewModel>
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: `${this.apiConfig.AppSettings.IdentityApiRootUrl}/Roles/GetByName/${roleName}`,
            params: params
        };

        return this.$http<IRoleViewModel>(config);
    }

    AddUserToRole(userId: string, roleId: string): ng.IHttpPromise<any> {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Roles/" + roleId + "/AddUser/" + userId 
        };

        return this.$http<any>(config);
    }

    RemoveUserFromRole(userId: string, roleId: string): ng.IHttpPromise<any> {
        let config: ng.IRequestConfig = {
            method: "DELETE",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Roles/" + roleId + "/RemoveUser/" + userId 
        };

        return this.$http<any>(config);
    }

    CreateManagedAccount(account: IManagedRegisterBindingModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Users/ManagedRegister",
            data: account
        };

        return this.$http<string>(config);
    }

    ManagedChangeEmail(userId: string, newEmail: string)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Users/" + userId + "/ChangeEmail",
            data: JSON.stringify(newEmail)
        };

        return this.$http<any>(config);
    }

    ManagedChangeName(userId: string, firstName: string, lastName: string)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Users/" + userId + "/ManagedChangeName",
            params: {
                firstName: firstName,
                lastName: lastName
            }
        };

        return this.$http<any>(config);
    }

    ManagedResetPassword(userIdOrEmail: string, sendEmailToUserAccount: boolean, passwordModel: IResetPasswordBindingModel = null)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Users/" + encodeURIComponent(userIdOrEmail.trim()) + "/ResetPassword/",
            data: passwordModel,
            params: {
                sendEmailToUserAccount: sendEmailToUserAccount
            }
        };

        return this.$http<string>(config);
    }

    AddManagedFranchiseId(claimType: string, model: IManagedFranchiseBindingModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Claims/ManagedFranchise/" + claimType + "/",
            data: model
        };

        return this.$http<any>(config);
    }

    RemoveManagedFranchiseId(claimType: string, model: IManagedFranchiseBindingModel)
    {
        let config: ng.IRequestConfig = {
            method: "DELETE",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Claims/ManagedFranchise/" + claimType + "/",
            params: model
        };

        return this.$http<any>(config);
    }

    AddManagedConceptId(model: IManagedConceptBindingModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Claims/ManagedConcept/",
            data: model
        };

        return this.$http<any>(config);
    }

    RemoveManagedConceptId(model: IManagedConceptBindingModel) {
        let config: ng.IRequestConfig = {
            method: "DELETE",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Claims/ManagedConcept/",
            params: model
        };

        return this.$http<any>(config);
    }

    AddDppEmployeePermission(model: IDppEmployeePermissionBindingModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Claims/DppEmployee/",
            data: model
        };

        return this.$http<any>(config);
    }

    RemoveDppEmployeePermission(model: IDppEmployeePermissionBindingModel) {
        let config: ng.IRequestConfig = {
            method: "DELETE",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Claims/DppEmployee/",
            params: model
        };

        return this.$http<any>(config);
    }

    AddC4CallCenterPermission(model: IC4CallCenterPermissionBindingModel)
    { 
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Claims/CallCenter/",
            data: model
        };

        return this.$http<any>(config);
    }

    RemoveC4CallCenterPermission(model: IC4CallCenterPermissionBindingModel)
    {
        let config: ng.IRequestConfig = {
            method: "DELETE",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Claims/CallCenter/",
            params: model
        };

        return this.$http<any>(config);
    }

    AddC4CallCenterConceptPermission(model: IC4CallCenterConceptPermissionBindingModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Claims/CallCenterConcept/",
            data: model
        };

        return this.$http<any>(config);
    }

    RemoveC4CallCenterConceptPermission(model: IC4CallCenterConceptPermissionBindingModel)
    {
        let config: ng.IRequestConfig = {
            method: "DELETE",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Claims/CallCenterConcept/",
            params: model
        };

        return this.$http<any>(config);
    }

    AddAssignedPosEmployeePermission(model: IAssignedPosEmployeeBindingModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Claims/AssignedPosEmployee/",
            data: model
        };

        return this.$http<any>(config);
    }

    RemoveAssignedPosEmployeePermission(model: IAssignedPosEmployeeBindingModel)
    {
        let config: ng.IRequestConfig = {
            method: "DELETE",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Claims/AssignedPosEmployee/",
            params: model
        };

        return this.$http<any>(config);
    }

    CreateFranchiseAccountsByFranchiseRecords(conceptId: number, isTest: boolean)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Users/Franchisees/RegisterByConcept/" + conceptId + "/",
            params: {
                isTest: (isTest === true)
            }
        };

        return this.$http<any>(config);
    }

    DeleteAllFranchiseeAccounts() {
        let config: ng.IRequestConfig = {
            method: "DELETE",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Users/Franchisees"
        };

        return this.$http<any>(config);
    }

    ForgotPasswordSendEmail(email: string)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Account/ForgotPasswordSend/",
            data: '"' + email + '"'
        };

        return this.$http<string>(config);
    }

    ForgotPasswordReset(model: IForgotPasswordResetBindingModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Account/ForgotPasswordReset/",
            data: model
        };

        return this.$http<string>(config);
    }

    ResetUsersPasswordByRole(model: IRoleViewModel[]) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Account/ExpireUserPasswordsByRole/",
            data: model
        };

        return this.$http<string>(config);
    }

    SetUserStatus(userId: string, isEnabled: boolean) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Users/" + userId + "/SetStatus",
            params: {
                enabled: isEnabled
            }
        }

        return this.$http<any>(config);
    }

    ExpireUserPassword(userId: string) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Users/" + userId + "/ExpireUserPassword",
        }

        return this.$http<any>(config);
    }

    HasRole(role: string | string[]): ng.IPromise<boolean> {
        return this.GetLoggedInUserInfo()
            .then((response) => {
                return this.UserHasRole(response.data, role);
            });
    }

    UserHasRole(userInfo: IUserInfoViewModel, role: string | string[]): boolean {
        let roles = (Array.isArray(role) ? role : [role]);
        for (let checkRole of roles) {
            if (userInfo.Roles.some(r => r.Name.toLowerCase() === checkRole.toLowerCase()))
                return true;
        }
        return false;
    }

    CanManageCallCenterUser(userEmail: string)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Users/CanManageCallCenterUser/",
            params: {
                email: userEmail
            }
        };

        return this.$http<{ CanManage: boolean, Reason: string }>(config);
    }

    GetBillingPermissions()
    {
        return this.GetLoggedInUserInfo()
            .then((userResponse) =>
            {
                let user = userResponse.data;
                return {
                    isBillingAdmin: user.Roles.some(r =>
                        r.Name == this.apiConfig.FranForceConstants.RoleConstants.BillingAdminRole ||
                        r.Name == this.apiConfig.FranForceConstants.RoleConstants.AdminRole
                    ),
                    isZeeMailAdmin: user.Roles.some(r =>
                        r.Name == this.apiConfig.FranForceConstants.RoleConstants.ZorWareZeeMailAdminRole ||
                        r.Name == this.apiConfig.FranForceConstants.RoleConstants.AdminRole
                    )
                };
            })
    }

    /**
     * @deprecated Use HasRole instead.
     */
    CheckRole(role: string): ng.IPromise<{data: string}> {
        return this.HasRole(role).then(hasRole => {
            return (hasRole ?
                { data: "Allowed" } :
                { data: "Denied" });
        })
    }

    ChangePassword(model: IChangePasswordBindingModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Account/ChangePassword/",
            data: model
        };
        return this.$http<string>(config);
    }
    
    UpdateExpiredPassword(model: IUpdateExpiredPasswordBindingModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.IdentityApiRootUrl + "/Account/UpdateExpiredPassword/",
            data: model
        };
        return this.$http<string>(config);
    }
}