import { BillingApiResources } from "../../../Services/Resources/BillingApiResources";
import { CoreResources } from "../../../Interfaces/FranForce/Core/CoreResources";
import { AngularUtil } from "../../../Utility/AngularUtil";
import { BillingInvoiceTaxRequestRmDataModel } from "../../../Models/Billing/ZorWare/BillingInvoices/BillingInvoiceTaxRequestRmDataModel";
import { MonthSelectorOnSelectParams, YearSelectorOnSelectParams } from "../../Common";
import { GenerateCopyOfInvoiceEmailRequestDataModel } from "../../../Models/Billing/ZorWare/BillingInvoices/GenerateCopyOfInvoiceEmailRequestDataModel";
import { BillingInvoiceTaxTrackerResponseDataModel } from "../../../Models/Billing/ZorWare/BillingInvoices/BillingInvoiceTaxTrackerResponseDataModel";

export class GenerateBillingInvoicesComponentController implements ng.IController {

    conceptSelect: string[];
    selectedConcepts: any[];
    year: number;
    month: number;

    franchiseSelect: string[];
    selectedFranchises: any[];
    billingDateFrom: string;
    billingDateTo: string;

    resendEmailConceptId: number;
    resendEmailFranchisorId: number;
    resendEmailFranchisorFilters: kendo.data.DataSourceFilter;
    resendEmailFranchiseFilters: kendo.data.DataSourceFilter;
    resendEmailCombinedFranchisorFilters: kendo.data.DataSourceFilters;
    resendEmailCombinedFranchiseFilters: kendo.data.DataSourceFilters;

    showGenerateBillingInvoiceValidationMessage: boolean;
    generateBillingInvoiceValidationMessage: string;

    showGenerateCopyInvoiceEmailStatus: boolean;
    generateCopyInvoiceEmailStatusTypeCSSClass: string;
    generateCopyInvoiceEmailStatusMessage: string;

    showGenerateInvoiceTaxStatus: boolean;
    generateInvoiceTaxStatusMessage: string;
    generateInvoiceTaxStatusTypeCSSClass: string;

    yearFrom: number;
    yearTo: number;



    conceptFilters: kendo.data.DataSourceFilters;

    showGenerateBillingInvoicesDisplayGrid: boolean;

    billingInvoicesTaxGridDataSource: kendo.data.DataSource;

    billingInvoiceTaxTrackerGridDataSource: kendo.data.DataSource;

    showBillingInvoiceTaxTrackerGridContainer: boolean;

    isLoading: boolean;

    isContinueGenerateBillingInvoicesDisabled: boolean;

    static $inject = [
        '$timeout',
        'billingApiResources',
        '$rootScope',
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private billingApiResources: BillingApiResources,
        private $rootScope: ng.IRootScopeService,
    ) {
    }

    $onInit() {
        this.conceptSelect = [
            nameof<CoreResources.IConcept>(o => o.ConceptId),
            nameof<CoreResources.IConcept>(o => o.ConceptCode),
            nameof<CoreResources.IConcept>(o => o.DisplayName),
        ];

        this.franchiseSelect = [
            nameof<CoreResources.IFranchise>(o => o.FranchiseId),
            nameof<CoreResources.IFranchise>(o => o.LicenseNumber),
            nameof<CoreResources.IFranchise>(o => o.FranchiseeName),
        ];

        this.isContinueGenerateBillingInvoicesDisabled = false;

        this.CreateConceptFilters();
        this.showGenerateBillingInvoicesDisplayGrid = false;
        this.isLoading = false;
        this.CreateCombinedFranchisorFilters();
        this.CreateCombinedFranchiseFilters();

        this.showGenerateBillingInvoiceValidationMessage = false;
        this.generateBillingInvoiceValidationMessage = "";

        this.showGenerateCopyInvoiceEmailStatus = false;
        this.generateCopyInvoiceEmailStatusMessage = "";
        this.generateCopyInvoiceEmailStatusTypeCSSClass = "";

        this.showGenerateInvoiceTaxStatus = false;
        this.generateInvoiceTaxStatusMessage = "";
        this.generateInvoiceTaxStatusTypeCSSClass = "";

        this.showBillingInvoiceTaxTrackerGridContainer = false;

        this.yearFrom = 1980;
        this.yearTo = new Date().getFullYear() + 1;
    }

    CreateConceptFilters() {
        this.conceptFilters = {
            logic: "or",
            filters: [
                {
                    field: nameof<CoreResources.IConcept>(o => o.Country),
                    operator: 'eq',
                    value: "Canada"
                },
                {
                    field: nameof<CoreResources.IConcept>(o => o.Country),
                    operator: 'eq',
                    value: "United States"
                }
            ]
        }
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.resendEmailConceptId)]) {
            this.CreateCombinedFranchisorFilters()
        }
        if (changes[nameof(this.resendEmailFranchisorId)]) {
            this.CreateCombinedFranchiseFilters()
        }
    }

    GetBillingInvoiceEmailCount() {
        this.$rootScope.$emit('getBillingInvoiceEmailCount',
        {
            selectedConcepts: this.selectedConcepts,
            month: this.month,
            year: this.year
        });
    }

    SetGenerateBillingInvoiceValidationMessage(show: boolean, message: string) {
        this.showGenerateBillingInvoiceValidationMessage = show;
        this.generateBillingInvoiceValidationMessage = message;
    }

    SetGenerateEmailValidationMessage(show: boolean, message: string, className: string) {
        this.showGenerateCopyInvoiceEmailStatus = show;
        this.generateCopyInvoiceEmailStatusMessage = message;
        this.generateCopyInvoiceEmailStatusTypeCSSClass = className;
    }

    SetGenerateInvoiceTaxValidationMessage(show: boolean, message: string, className: string) {
        this.showGenerateInvoiceTaxStatus = show;
        this.generateInvoiceTaxStatusMessage = message;
        this.generateInvoiceTaxStatusTypeCSSClass = className;
    }

    checkDateValidation(start, end, field) {
        if (!start && !end) {
            this.SetGenerateEmailValidationMessage(true, `Please Select \"${field}\".`, "generateCopyInvoiceEmailFailure");
            return true;
        }
        if (end && !start) {
            this.SetGenerateEmailValidationMessage(true, `Please Select \"${field} From\".`, "generateCopyInvoiceEmailFailure");
            return true;
        }
        if (!end && start) {
            this.SetGenerateEmailValidationMessage(true, `Please Select \"${field} To\".`, "generateCopyInvoiceEmailFailure");
            return true;
        }
        if (end && start) {
            var startDate = new Date(start);
            var endDate = new Date(end);
            if (startDate > endDate) {
                this.SetGenerateEmailValidationMessage(true, `\"${field} From\" is greater than \"${field} To\".`, "generateCopyInvoiceEmailFailure");
                return true;
            }
        }
        else {
            return false;
        }
    }

    GenerateBillingBatch() {

        this.isLoading = true;
        this.showBillingInvoiceTaxTrackerGridContainer = false;
        this.SetGenerateBillingInvoiceValidationMessage(false, "");

        if (!this.selectedConcepts || (this.selectedConcepts && this.selectedConcepts.length < 1)) {
            this.SetGenerateBillingInvoiceValidationMessage(true, "Please Select Concept.");
            this.isLoading = false;
            this.showGenerateBillingInvoicesDisplayGrid = false;
            this.showBillingInvoiceTaxTrackerGridContainer = false;
            return;
        }

        if (!this.year) {
            this.SetGenerateBillingInvoiceValidationMessage(true, "Please Select Year.");
            this.isLoading = false;
            this.showGenerateBillingInvoicesDisplayGrid = false;
            this.showBillingInvoiceTaxTrackerGridContainer = false;
            return;
        }

        if (!this.month) {
            this.SetGenerateBillingInvoiceValidationMessage(true, "Please Select Month.");
            this.isLoading = false;
            this.showGenerateBillingInvoicesDisplayGrid = false;
            this.showBillingInvoiceTaxTrackerGridContainer = false;
            return;
        }

        this.showGenerateBillingInvoicesDisplayGrid = true;

        let rquestData: BillingInvoiceTaxRequestRmDataModel = {
            conceptIds: this.selectedConcepts,
            year: this.year,
            month: this.month
        }

        this.loadBillingInvoicesTaxGridData(rquestData);
    }

    GenerateEmail() {

        this.isLoading = true;
        this.SetGenerateEmailValidationMessage(false, "", "");

        let billingDateFrom = !this.billingDateFrom ? null : new Date(this.billingDateFrom).toISOString();
        let billingDateTo = !this.billingDateTo ? null : new Date(this.billingDateTo).toISOString();


        if (!this.selectedFranchises || (this.selectedFranchises && this.selectedFranchises.length < 1)) {
            this.SetGenerateEmailValidationMessage(true, "Please Select Franchise.", "generateCopyInvoiceEmailFailure");
            this.isLoading = false;
            return;
        }

        let hasValidationError = false;
        hasValidationError = this.checkDateValidation(billingDateFrom, billingDateTo, "Billing Date")
        if (hasValidationError) {
            this.isLoading = false;
            return;
        }

        let generateCopyOfInvoiceEmailRequestData: GenerateCopyOfInvoiceEmailRequestDataModel = {
            franchiseIds: this.selectedFranchises,
            billStartDate: billingDateFrom,
            billEndDate: billingDateTo
        }

        this.billingApiResources.GenerateCopyOfInvoiceEmail(generateCopyOfInvoiceEmailRequestData)
            .then((results) => {
                this.isLoading = false;
                if (results && results.data && results.data == true) {
                    this.SetGenerateEmailValidationMessage(true, "Invoice Email Copy Generated Successfully.", "generateCopyInvoiceEmailSuccess");
                }
                else {
                    this.SetGenerateEmailValidationMessage(true, "Failed To Generate Invoice Email Copy.", "generateCopyInvoiceEmailFailure");
                }
            })
            .catch((err) => {
                this.isLoading = false;
                this.SetGenerateEmailValidationMessage(true, "Failed To Generate Invoice Email Copy.", "generateCopyInvoiceEmailFailure");
            })
            .finally(() => { this.isLoading = false; });
    }

    OnYearSelect(year: YearSelectorOnSelectParams["year"]) {
        this.year = year?.year;
    }

    OnMonthSelect(month: MonthSelectorOnSelectParams["month"]) {
        this.month = month?.monthNumber;
    }

    loadBillingInvoicesTaxGridData(data: BillingInvoiceTaxRequestRmDataModel) {
        this.billingInvoicesTaxGridDataSource = new kendo.data.DataSource({
            pageSize: 10,
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {
                    this.isLoading = true;
                    this.billingApiResources.GetBillingInvoicesTaxReviewData(data)
                        .then((results) => {

                            if (results.data.length < 1) {
                                this.isContinueGenerateBillingInvoicesDisabled = true;
                            }
                            else {
                                this.isContinueGenerateBillingInvoicesDisabled = false;
                            }

                            this.showGenerateBillingInvoicesDisplayGrid = true;
                            this.showBillingInvoiceTaxTrackerGridContainer = false;
                            options.success(results.data);
                            this.isLoading = false;
                        })
                        .catch((err) => {
                            this.isLoading = false;
                            this.showGenerateBillingInvoicesDisplayGrid = false;
                            this.showBillingInvoiceTaxTrackerGridContainer = false;
                            this.SetGenerateBillingInvoiceValidationMessage(true, "Error In Loading Billing Invoices Data.");
                            options.error(err);
                        })
                        .finally(() => { this.isLoading = false; });
                }
            }
        });
    }

    loadBillingInvoiceTaxTrackerGridDataSource(data: BillingInvoiceTaxTrackerResponseDataModel[]) {
        this.billingInvoiceTaxTrackerGridDataSource = new kendo.data.DataSource({
            transport: {
                parameterMap: function (options, operation) {
                    if (operation !== "read" && options.models) {
                        return { models: kendo.stringify(options.models) };
                    }
                }
            },
            schema: {
                model: {
                    fields: {
                        conceptId: { type: "number" },
                        conceptCode: { type: "string" },
                        conceptName: { type: "string" },
                        year: { type: "number" },
                        month: { type: "number" },
                        statusType: { type: "string" }
                    }
                }
            }
        });

        this.billingInvoiceTaxTrackerGridDataSource.data(data);

    }

    CancelGenerateBillingBatch() {
        this.showGenerateBillingInvoicesDisplayGrid = false;
        this.selectedConcepts = null;
        this.year = null;
        this.month = null;
    }

    CreateCombinedFranchisorFilters() {
        if (!this.resendEmailConceptId) {
            this.resendEmailCombinedFranchisorFilters = null;
            return;
        }

        this.resendEmailCombinedFranchisorFilters = {
            logic: "and",
            filters: [
                {
                    field: nameof<CoreResources.IFranchisor>(o => o.ConceptId),
                    operator: 'eq',
                    value: this.resendEmailConceptId
                },
                this.resendEmailFranchisorFilters
            ]
        }
    }

    CreateCombinedFranchiseFilters() {
        if (!this.resendEmailFranchisorId) {
            this.resendEmailCombinedFranchiseFilters = null;
            return;
        }

        this.resendEmailCombinedFranchiseFilters = {
            logic: "and",
            filters: [
                {
                    field: nameof<CoreResources.IFranchise>(o => o.FranchisorId),
                    operator: 'eq',
                    value: this.resendEmailFranchisorId
                },
                this.resendEmailFranchiseFilters
            ]
        }
    }


    OnResendEmailConceptSelect(concept: Partial<CoreResources.IConcept>) {
        //this.resendEmailConceptId = concept.ConceptId;

        if (!concept || !concept.ConceptId) {
            this.resendEmailConceptId = null;
            this.resendEmailFranchisorFilters = null;
            this.CreateCombinedFranchisorFilters();
            this.resendEmailConceptId = concept.ConceptId;
            this.selectedFranchises = [];
            return;
        }

        if (this.resendEmailConceptId == concept.ConceptId) {
            this.resendEmailConceptId = concept.ConceptId;
            return;
        }

        this.resendEmailConceptId = concept.ConceptId;
        this.CreateCombinedFranchisorFilters();
        this.resendEmailConceptId = concept.ConceptId;
    }

    OnResendEmailFranchisorSelect(franchisor: Partial<CoreResources.IFranchisor>) {
        //if (franchisor) {
        //    this.resendEmailFranchisorId = franchisor.FranchisorId;
        //}

        if (!franchisor || !franchisor.FranchisorId) {
            this.resendEmailFranchisorId = null;
            this.resendEmailFranchiseFilters = null;
            this.CreateCombinedFranchiseFilters();
            this.resendEmailFranchisorId = franchisor.FranchisorId;
            this.selectedFranchises = [];
            return;
        }

        if (this.resendEmailFranchisorId == franchisor.FranchisorId) {
            this.resendEmailFranchisorId = franchisor.FranchisorId;
            return;
        }

        this.resendEmailFranchisorId = franchisor.FranchisorId;
        this.CreateCombinedFranchiseFilters();
        this.resendEmailFranchisorId = franchisor.FranchisorId;
    }

    ContinueGenerateBillingBatch() {
        this.isLoading = true;

        let rquestData: BillingInvoiceTaxRequestRmDataModel = {
            conceptIds: this.selectedConcepts,
            year: this.year,
            month: this.month
        }

        this.billingApiResources.GenerateBillingInvoiceTaxes(rquestData)
        .then((result) => {
            this.isLoading = false;
            if (result && result.data && result.data.length && result.data.length > 0) {
                this.showBillingInvoiceTaxTrackerGridContainer = true;
                this.SetGenerateInvoiceTaxValidationMessage(false, "", "");
                this.loadBillingInvoiceTaxTrackerGridDataSource(result.data);
            }
            else if (result && result.data && result.data.length == 0) {
                this.showBillingInvoiceTaxTrackerGridContainer = false;
                this.SetGenerateInvoiceTaxValidationMessage(true, "Processing Billing Invoices for selected Concepts. You will be notified via email as individual concepts complete processing.", "generateInvoiceTaxSuccess");
            }
            else {
                this.showBillingInvoiceTaxTrackerGridContainer = false;
                this.SetGenerateInvoiceTaxValidationMessage(true, "Error! Unable To Generate Billing Invoices.", "generateInvoiceTaxFailure");
            }
        })
        .catch((err) => {
            this.isLoading = false;
            this.SetGenerateInvoiceTaxValidationMessage(true, "Error! Unable To Generate Billing Invoices.", "generateInvoiceTaxFailure");
        })
        .finally(() => { this.isLoading = false; });
    }

    ResetGenerateEmail() {
        this.showGenerateCopyInvoiceEmailStatus = false;
        this.selectedFranchises = [];
        this.billingDateFrom = null;
        this.billingDateTo = null;
        this.resendEmailConceptId = null;
        this.resendEmailFranchisorId = null;
    }

    static BindComponent(app: ng.IModule) {
        app.component("generateBillingInvoices", {
            bindings: {
            },
            controller: GenerateBillingInvoicesComponentController,
            templateUrl: "/Templates/Billing/GenerateBillingInvoices/GenerateBillingInvoices.html"
        });

    }
}