

import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { MomentUtil } from "Utility/MomentUtil";
import { CallCenterEntities } from "Interfaces/FranForce/CallCenter/CallCenterResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class CallCenterStateService
{
    franchiseRefreshEventName = `${nameof(CallCenterStateService)}.${nameof<CallCenterStateService>(o => o.franchiseRefreshEventName)}`;

    private franchiseLeadStates: {
        [franchiseId: string]: CallCenterEntities.C4FranchiseLeadThrottle & { StartDateObj?: Date, EndDateObj?: Date }
    }
    private franchiseLeadStatePromises: { [franchiseId: string]: ng.IPromise<CallCenterEntities.C4FranchiseLeadThrottle & { StartDateObj?: Date, EndDateObj?: Date }> }


    private conceptLeadStates: {
        [conceptId: string]: CallCenterEntities.C4ConceptLeadThrottle
    }
    private conceptLeadStatePromises: { [conceptId: string]: ng.IPromise<CallCenterEntities.C4ConceptLeadThrottle> }

    static $inject = [
        "$resource",
        "$http",
        "$q",
        "$rootScope",
        "odataHelper",
        "apiConfig",
        "coreApiResources",
        "callCenterApiResources"
    ]

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig,
        private coreApiResources: CoreApiResources,
        private callCenterApiResources: CallCenterApiResources
    )
    {
        this.franchiseLeadStatePromises = {};
        this.franchiseLeadStates = {};
        this.conceptLeadStates = {};
        this.conceptLeadStatePromises = {};
    }

    //BindOnRefreshEvent(listener: (event: ng.IAngularEvent, args: CallCenterEntities.C4FranchiseLeadThrottle & { StartDateObj?: Date, EndDateObj?: Date }) => any)
    //{
    //    this.$rootScope.$on(this.franchiseRefreshEventName, listener);
    //}

    LoadLeadStateForFranchise(franchiseId: number)
    {
        if (!this.franchiseLeadStatePromises[franchiseId.toString()])
        {
            this.franchiseLeadStatePromises[franchiseId.toString()] = this.callCenterApiResources.GetFranchiseLeadState(franchiseId)
                .then((loadedLeadState) =>
                {
                    let leadStateObj = this.GetFranchiseLeadStateObject(franchiseId);
                    angular.extend(leadStateObj, loadedLeadState);
                    
                    leadStateObj.StartDate = MomentUtil.EnsureUtcTime(leadStateObj.StartDate);
                    leadStateObj.EndDate = MomentUtil.EnsureUtcTime(leadStateObj.EndDate);

                    if (leadStateObj.StartDate)
                        leadStateObj.StartDateObj = new Date(leadStateObj.StartDate);
                    if (leadStateObj.EndDate)
                        leadStateObj.EndDateObj = new Date(leadStateObj.EndDate);

                    return leadStateObj;
                });
        }

        return this.franchiseLeadStatePromises[franchiseId.toString()];
    }

    RefreshLeadStateForFranchise(franchiseId: number)
    {
        this.franchiseLeadStatePromises[franchiseId.toString()] = null;
        return this.LoadLeadStateForFranchise(franchiseId)
            .then((throttle) =>
            {
                this.$rootScope.$emit(this.franchiseRefreshEventName, throttle);
                return throttle;
            });
    }

    LoadConceptLeadStateByConcept(conceptId: number)
    {
        if (!this.conceptLeadStatePromises[conceptId.toString()])
        {
            this.conceptLeadStatePromises[conceptId.toString()] = this.coreApiResources.ConceptApi
                .get({ id: conceptId, $select: nameof<CoreResources.IConcept>(o => o.C4ConceptLeadThrottleId) }).$promise
                .then((c) =>
                {
                    if (!c.C4ConceptLeadThrottleId) {
                        return null
                    };

                    return this.callCenterApiResources.C4ConceptLeadThrottlesApi.get({ id: c.C4ConceptLeadThrottleId }).$promise;
                })
                .then((conceptThrottle) =>
                {
                    let leadStateObj = this.GetConceptLeadStateObject(conceptId);
                    if (conceptThrottle) {
                        angular.extend(leadStateObj, conceptThrottle);
                    }
                    return leadStateObj;
                });
        }

        return this.conceptLeadStatePromises[conceptId.toString()];
    }

    RefreshLeadStateForConcept(conceptId: number)
    {
        this.conceptLeadStatePromises[conceptId.toString()] = null;
        return this.LoadConceptLeadStateByConcept(conceptId)
            .then((throttle) =>
            {
                this.$rootScope.$applyAsync();
                return throttle;
            });
    }
    
    private GetFranchiseLeadStateObject(franchiseId: number)
    {
        if (!this.franchiseLeadStates[franchiseId.toString()])
        {
            this.franchiseLeadStates[franchiseId.toString()] = {
                Status: "G",
                C4FranchiseLeadThrottleId: 0,
                FranchiseId: franchiseId,
                EndDate: null,
                StartDate: null,
                CreatedDateTime: null,
                CreatedUser: null,
                DeletedDateTime: null,
                DeletedUser: null,
                Franchise: null
            }
        }

        return this.franchiseLeadStates[franchiseId.toString()];
    }

    private GetConceptLeadStateObject(conceptId: number)
    {
        if (!this.conceptLeadStates[conceptId.toString()]) {
            this.conceptLeadStates[conceptId.toString()] = {
                C4ConceptLeadThrottleId: 0,
                C4MinimumGreenConceptLeadTimeUnit: null,
                C4MinimumYellowConceptLeadTimeUnit: null,
                MinimumLeadGreenTime: 0,
                MinimumLeadGreenTimeUnitId: 0,
                MinimumLeadYellowTime: 0,
                MinimumLeadYellowTimeUnitId: 0,
                SlotAfterHoursLeadsMaximum: 0,
                SlotAfterHoursLeadsPerTech: 0,
                SlotAmLeadsMaximum: 0,
                SlotAmLeadsPerTech: 0,
                SlotPmLeadsMaximum: 0,
                SlotPmLeadsPerTech: 0,
                TimeSlotComparerTypeId: 0,
                CreatedDateTime: null,
                CreatedUser: null,
                UpdatedDateTime: null,
                UpdatedUser: null,
                TimeSlotComparerType: null,
            }
        }

        return this.conceptLeadStates[conceptId.toString()];
    }
}