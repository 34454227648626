
import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { IEditDisasterPrepProfileSectionScope } from "@DppApp/EditDisasterPrepProfileSectionController";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";

interface IEditDisasterPrepProfileBuildingScope extends IEditDisasterPrepProfileSectionScope {
    dppBuildingAssetCategoryId: number;
    floors: DppResources.IDisasterPrepProfileBuildingFloor[];

    ResetBuilding: () => void;
    ResetBuildingFloors: () => void;
    ResetBuildingAndFloors: () => void;

    AddBuildingFloor: () => void;
    RemoveBuildingFloor: (index: number) => void;

    SaveBuilding: () => void;
}

export class EditDisasterPrepProfileBuildingController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('EditDisasterPrepProfileBuildingController',
            [
                '$scope', '$q', 'dppApiResources', "coreApiResources",
                ($scope: IEditDisasterPrepProfileBuildingScope, $q: ng.IQService, dppApiResources: DppApiResources) =>
                {
                    if (!$scope.dppBuildingId) {
                        $scope.RedirectHome();
                        return;
                    }

                    $scope.dppBuildingAssetCategoryId = 1;

                    $scope.ResetBuilding = () =>
                    {
                        let promise = dppApiResources.DisasterPrepProfileBuildingApi.get({ id: $scope.dppBuildingId },
                            (bldg: DppResources.IDisasterPrepProfileBuilding) =>
                            {
                                $scope.currentBuilding = bldg;
                            },
                            (err) =>
                            {
                                console.error(err);
                                alert("Could not retrieve floors.");
                            }).$promise;

                        $scope.QueueLoadingPromises(promise);
                    };

                    $scope.ResetBuildingFloors = () =>
                    {
                        let promise = dppApiResources.DisasterPrepProfileBuildingFloorApi.query({ $filter: 'DppBuildingId eq ' + $scope.dppBuildingId },
                            (floors: DppResources.IDisasterPrepProfileBuildingFloor[]) =>
                            {
                                $scope.floors = floors;
                            },
                            (err) =>
                            {
                                console.error(err);
                                alert("Could not retrieve floors.");
                            }).$promise;

                        $scope.QueueLoadingPromises(promise);
                    };

                    $scope.ResetBuildingAndFloors = () =>
                    {
                        $scope.ResetBuilding();
                        $scope.ResetBuildingFloors();
                    };

                    $scope.ResetBuildingAndFloors();

                    $scope.AddBuildingFloor = () =>
                    {
                        let newFloor = <DppResources.IDisasterPrepProfileBuildingFloor>new dppApiResources.DisasterPrepProfileBuildingFloorApi();
                        newFloor.DppBuildingId = $scope.dppBuildingId;
                        $scope.floors.push(newFloor);
                    };

                    $scope.RemoveBuildingFloor = (index: number) =>
                    {
                        if (!$scope.floors[index].DppBuildingFloorId) {
                            $scope.floors.splice(index, 1);
                        }
                        if ($scope.floors[index].DppBuildingFloorId && confirm("Are you sure you want to permanently delete this floor?")) {
                            $scope.floors[index].$delete({ id: $scope.floors[index].DppBuildingFloorId, allowHardDelete: true },
                                () =>
                                {
                                    $scope.floors.splice(index, 1);
                                },
                                (err) =>
                                {
                                    console.error(err);
                                    alert("Failed to delete floor.");
                                });
                        }
                    };

                    $scope.SaveBuilding = () =>
                    {
                        return dppApiResources.DisasterPrepProfileBuildingApi.get({ id: $scope.currentBuilding.DppBuildingId }).$promise.then(
                            (dppBuildingToSave: DppResources.IDisasterPrepProfileBuilding) =>
                            {
                                dppBuildingToSave.BuildingName = $scope.currentBuilding.BuildingName;

                                dppBuildingToSave.Address = $scope.currentBuilding.Address;
                                dppBuildingToSave.Address2 = $scope.currentBuilding.Address2;
                                dppBuildingToSave.City = $scope.currentBuilding.City;
                                dppBuildingToSave.StateAbbr = $scope.currentBuilding.StateAbbr;
                                dppBuildingToSave.PostalCode = $scope.currentBuilding.PostalCode;

                                dppBuildingToSave.YearBuilt = $scope.currentBuilding.YearBuilt;
                                dppBuildingToSave.SquareFeet = $scope.currentBuilding.SquareFeet;
                                dppBuildingToSave.Stories = $scope.currentBuilding.Stories;

                                dppBuildingToSave.HasBackupGenerator = $scope.currentBuilding.HasBackupGenerator;
                                dppBuildingToSave.BackupGeneratorMakeModel = $scope.currentBuilding.BackupGeneratorMakeModel;
                                dppBuildingToSave.BackupGeneratorSize = $scope.currentBuilding.BackupGeneratorSize;
                                dppBuildingToSave.BackupGeneratorLocation = $scope.currentBuilding.BackupGeneratorLocation;

                                dppBuildingToSave.HasBlueprint = $scope.currentBuilding.HasBlueprint;
                                dppBuildingToSave.BlueprintLocation = $scope.currentBuilding.BlueprintLocation;

                                return dppBuildingToSave.$update({ id: dppBuildingToSave.DppBuildingId });
                            }).then((response) =>
                            {
                                console.log("Successful Building Save", response);

                                let saveFloorPromises = [];
                                for (let floor of $scope.floors) {
                                    let saveFloorPromise;
                                    if (floor.DppBuildingFloorId) {
                                        saveFloorPromise = floor.$update({ id: floor.DppBuildingFloorId },
                                            (response) =>
                                            {
                                                console.log("Floor Update", response);
                                            },
                                            (err) =>
                                            {
                                                console.error(err);
                                            });
                                    } else {
                                        saveFloorPromise = floor.$save((response) => { console.log("Floor Create", response) });
                                    }

                                    saveFloorPromises.push(saveFloorPromise);
                                }
                                return $q.all(saveFloorPromises);
                            }, (err) => { console.error(err); });
                    };
                }
            ]);
    }
}

