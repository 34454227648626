import * as _ from "underscore"
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RmsStateService } from "Services/State/RmsState";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { rmsKeyValueGridComponentController } from "Directives/RPM/Common/rmsKeyValueGridController";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CmExcludedServicesComponent } from "Directives/RPM/ContractManagement/cmExcludedServices";
import { AngularUtil } from "Utility/AngularUtil";
import { RoyaltyApiResources } from "../../../Services/Resources/RoyaltyApiResources";
import { YearSelectorOnSelectParams } from "../../Common/YearSelector";
import { CoreResources } from "../../../Interfaces/FranForce/Core/CoreResources";
import { CesReportsFranchiseSelectorOnSelectParameters } from "../../Common";

export enum AdFeeCapOptions
{
    CONTRACT = 1,
    FRANCHISE = 2,
    SPECIFY_AMOUNTS = 3
}


export class CmGeneralComponentController implements ng.IController {

    contractId: number;
    conceptId: number;
    franchiseId: number;
    franchiseIdToCopy: number;
    franchisorId: number;
    franchisorDefaultCapFeeForLatestYear: number;
    init: (params: { self: CmGeneralComponentController }) => void;

    adFeeCapOptionsMap = {
        [AdFeeCapOptions.CONTRACT]: 'Contract',
        [AdFeeCapOptions.FRANCHISE]: 'Franchisor',
        [AdFeeCapOptions.SPECIFY_AMOUNTS]: 'Specify ad fee cap amounts'
    };

    adFeeCapOptions: typeof AdFeeCapOptions;
    selectedAdFeeCapOption: AdFeeCapOptions;

    adFeeCapAmount: number;

    currentLoginEmail: string;
    isLoading: boolean;
    readOnly: boolean;
    royaltyContract: RpmEntities.RoyaltyContractViewModel;
    formController: angular.IFormController;
    validator: kendo.ui.Validator;
    terminatedReasons: RpmEntities.RoyaltyContractTerminatedReasonViewModel[];
    disclosureDocumentPairs: RpmEntities.RoyaltyDisclosureDocumentKeyValuePairViewModel[];

    kvpGridDataSource: kendo.data.DataSource;
    kvpDropDownDataSource: kendo.data.DataSource;
    keyValueController: rmsKeyValueGridComponentController;
    adFeeCapsGrid: kendo.ui.Grid;
    excludedServicesController: CmExcludedServicesComponent;

    isDynamicFees: boolean;

    window: kendo.ui.Window;

    previousYearFrom: number;
    previousYearTo: number;

    franchiseFilters: kendo.data.DataSourceFilter;

    static readonly isValidProperty = "isValid";

    static $inject = [
        'rpmUiApiResources',
        'rmsState',
        'identityManager',
        'rpmHelper',
        '$q',
        '$timeout',
        '$window',
        'royaltyApiResources',
        '$scope'
    ]

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private rmsState: RmsStateService,
        private identityManager: IdentityManager,
        private rpmHelper: RpmHelperService,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService,
        private $window: ng.IWindowService,
        private royaltyApiResources: RoyaltyApiResources,
        private $scope: ng.IScope
    ) {
        this.adFeeCapOptions = AdFeeCapOptions;
        this.selectedAdFeeCapOption = AdFeeCapOptions.CONTRACT;
    }

    OnFranchiseSelect(franchise) {
        console.log("OnFranchiseSelect", franchise);
        this.franchiseIdToCopy = franchise.FranchiseId;
    }

    $onInit() {
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledCm').val() === 'True';
        if (this.init) {
            this.init({ self: this });
        }

        this.window.setOptions(<kendo.ui.WindowOptions>{
            modal: true
        });
    }

    OpenFranchiseSelectionPopup() {
        this.franchiseIdToCopy = this.franchiseId;

        this.franchiseFilters =
        {
            logic: "and",
            filters: [
                {
                    field: nameof<CoreResources.IFranchise>(o => o.FranchisorId),
                    operator: 'eq',
                    value: this.franchisorId
                },
                {
                    field: nameof<CoreResources.IFranchise>(o => o.Status),
                    operator: 'eq',
                    value: "Active"
                },
                {
                    field: nameof<CoreResources.IFranchise>(o => o.DeletedDateTime),
                    operator: 'eq',
                    value: null
                },
                {
                    field: nameof<CoreResources.IFranchise>(o => o.IsTest),
                    operator: 'eq',
                    value: false
                }]
        }

        this.window.open();
        this.$timeout(() => {
            this.window.center();
        })
    }

    CreateFranchiseFilter() {
        this.franchiseFilters =
        {
            logic: "and",
            filters: [
                {
                    field: nameof<CoreResources.IFranchise>(o => o.FranchisorId),
                    operator: 'eq',
                    value: this.franchisorId
                },
                {
                    field: nameof<CoreResources.IFranchise>(o => o.DeletedDateTime),
                    operator: 'eq',
                    value: null
                },
                {
                    field: nameof<CoreResources.IFranchise>(o => o.IsTest),
                    operator: 'eq',
                    value: false
                }]
        }
    }

    OnSelectFranchise(franchise: kendo.data.ObservableObject & Partial<CoreResources.IFranchise>) {
        this.royaltyContract.ResaleFromFranchiseId = franchise.FranchiseId;
    }

    OnPrevYearSelect(year: YearSelectorOnSelectParams["year"]) {
        if (this.royaltyContract) {
            this.royaltyContract.PreviousYear = year?.year;
        }
    }

    //set Franchisor Default Cap Fee For latest Year
    setFranchisorDefaultCapFeeForYear(year) {
        let yearAdCapFee = null;
        this.rpmUiApiResources.RoyaltyFranchisorMinimumFeeIncreasesAndCapsByFranchisor(this.franchisorId as unknown as number)
            .then((response) => {
                response.data.forEach((item, index) => {
                    if (item.YearIncreased.toString() === year.toString()) {
                        yearAdCapFee = parseInt(item.AdFeeCap.toString());
                        this.franchisorDefaultCapFeeForLatestYear = yearAdCapFee;
                        var LatestYearAdCapFeeRow = $('#adFeeCapsGrid').data().kendoGrid.dataSource.data()[0];
                        LatestYearAdCapFeeRow.set(nameof<RpmEntities.usp_RoyaltyContractMinimumFeeIncreasesAndCaps_GetByRoyaltyContractId_Result>(o => o.AdFeeCap), (this.numberWithCommas(this.franchisorDefaultCapFeeForLatestYear)).toString());
                        return;
                    }
                });
            })
            .catch((err) => {
                console.log("response yearly ad cap fee data error", err);
            });
    }

    //on is exception checkbox change
    onIsExceptionChange(isChecked) {

        // cell latest year
        let latestYear;
        let cellLatestYear = $('#adFeeCapsGrid').find('tbody tr:eq(0) td:eq(1)');
        if (cellLatestYear) {
            latestYear = parseInt(cellLatestYear.text());
        }

        // cell ad fee cap amount of latest year
        let cellLatestYearAdCapFee = $('#adFeeCapsGrid').find('tbody tr:eq(0) td:eq(2)');

        //if checkbox is checked make cell editable
        if (isChecked) {
            this.adFeeCapsGrid.editCell(cellLatestYearAdCapFee);
        }
        else {
            this.adFeeCapsGrid.closeCell();
            this.setFranchisorDefaultCapFeeForYear(latestYear);
        }
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.contractId)] && this.contractId) {
            this.InitKeyValueDataSource();
            this.LoadContract();

            if (this.adFeeCapsGrid) {
                this.$q.when(this.adFeeCapsGrid.dataSource.read())
                    .then(() => {
                        this.adFeeCapsGrid.refresh();
                    });
            }
        }

        if (onChanges[nameof(this.franchisorId)] && this.franchisorId) {
            this.CreateFranchiseFilter();
        }
    }

    $postLink() {
        this.InitAdFeeCapDataSource();
    }

    InitKeyValueDataSource() {
        this.kvpGridDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof<RpmEntities.RoyaltyContractKeyValuePairViewModel>(o => o.RoyaltyContractKeyValuePairId),
                    fields: {
                        isValid: { type: "boolean" },
                        [nameof<RpmEntities.RoyaltyContractKeyValuePairViewModel>(o => o.RoyaltyKeyValuePairId)]: {
                            validation: {
                                required: true,
                                duplicatevalidation: (input: JQuery<HTMLInputElement>) => {
                                    let kvpId = Number.parseInt(<string>input.val());

                                    let matchingKvps = this.kvpGridDataSource.data()
                                        .filter((kvp: RpmEntities.RoyaltyContractKeyValuePairViewModel) => {
                                            return kvp.RoyaltyKeyValuePairId == kvpId;
                                        });

                                    input.attr("data-duplicatevalidation-msg", "Key has already been selected.");

                                    //console.log(kvpId, matchingKvps, input);
                                    return (matchingKvps.length < 2);
                                }
                            }
                        },
                    }
                }
            },
            transport: {
                read: (options) => {
                    if (!this.contractId) {
                        options.success([]);
                        return;
                    }

                    this.rpmUiApiResources.GetRoyaltyContractKeyValuePairsByContract(this.contractId)
                        .then((kvResponse) => {
                            options.success(_.filter(kvResponse.data, (kvp) => {
                                return kvp.DeletedDateTime == null;
                            }));
                        }, err => options.error(err));
                },
                create: (options) => {
                    let createModel: RpmEntities.RoyaltyContractKeyValuePairViewModel = options.data;

                    createModel.CreatedDateTime = new Date().toDateString();
                    createModel.CreatedUser = this.currentLoginEmail;
                    createModel.RoyaltyContractId = this.contractId;

                    this.rpmUiApiResources.CreateRoyaltyContractKeyValuePair(createModel)
                        .then((response) => {
                            createModel.RoyaltyContractKeyValuePairId = response.data;
                            options.success({ RoyaltyContractKeyValuePairId: createModel.RoyaltyContractKeyValuePairId });
                        }, (err) => { options.error(err); })
                },
                update: (options) => {
                    let updateModel: RpmEntities.RoyaltyContractKeyValuePairViewModel = options.data;

                    updateModel.UpdatedDateTime = new Date().toDateString();
                    updateModel.UpdatedUser = this.currentLoginEmail;

                    this.rpmUiApiResources.UpdateRoyaltyContractKeyValuePair(updateModel.RoyaltyContractKeyValuePairId, updateModel)
                        .then((response) => {
                            options.success();
                        }, (err) => { options.error(err); });
                },
                destroy: (options) => {
                    let deleteModel: RpmEntities.RoyaltyContractKeyValuePairViewModel = options.data;

                    deleteModel.DeletedDateTime = new Date().toISOString();

                    this.rpmUiApiResources.UpdateRoyaltyContractKeyValuePair(deleteModel.RoyaltyContractKeyValuePairId, deleteModel)
                        .then((response) => {
                            options.success();
                        }, (err) => { options.error(err); });
                }
            }
        });

        this.kvpDropDownDataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    this.$q.all([
                        this.rpmUiApiResources.GetAllRoyaltyKeyValuePairs(),
                        this.GetContract().then((contract) => {
                            return this.rpmUiApiResources.GetDisclosureDocumentKeyValuePairsByDisclosureDocument(contract.DisclosureDocumentId)
                        })
                    ])
                        .then((responses) => {
                            let contractPairs = responses[0].data;
                            this.disclosureDocumentPairs = responses[1].data;

                            contractPairs = contractPairs.filter((c) => {
                                return this.disclosureDocumentPairs.some(dd => dd.RoyaltyKeyValuePairId === c.RoyaltyKeyValuePairId)
                            })

                            options.success(contractPairs);
                        })
                        .catch((err) => { options.error(err); })
                }
            }
        });
    }

    InitAdFeeCapDataSource() {
        if (!this.adFeeCapsGrid)
            return;

        this.adFeeCapsGrid.setOptions(<kendo.ui.GridOptions>{
            columns: <kendo.ui.GridColumn[]>[
                {
                    field: nameof<RpmEntities.usp_RoyaltyContractMinimumFeeIncreasesAndCaps_GetByRoyaltyContractId_Result>(o => o.ContractMinimumFeeIncreasesAndCapsId),
                    hidden: true,
                    sortable: false
                }, {
                    field: nameof<RpmEntities.usp_RoyaltyContractMinimumFeeIncreasesAndCaps_GetByRoyaltyContractId_Result>(o => o.YearIncreased),
                    title: "Year Changed",
                    sortable: false
                }, {
                    field: nameof<RpmEntities.usp_RoyaltyContractMinimumFeeIncreasesAndCaps_GetByRoyaltyContractId_Result>(o => o.AdFeeCap),
                    title: "Ad Fee Cap Amount",
                    type: "number",
                    sortable: false
                }
            ],
            dataSource: new kendo.data.DataSource({
                schema: {
                    model: {
                        id: nameof<RpmEntities.usp_RoyaltyContractMinimumFeeIncreasesAndCaps_GetByRoyaltyContractId_Result>(o => o.ContractMinimumFeeIncreasesAndCapsId),
                    }
                },
                sort: {
                    field: nameof<RpmEntities.usp_RoyaltyContractMinimumFeeIncreasesAndCaps_GetByRoyaltyContractId_Result>(o => o.YearIncreased),
                    dir: "desc"
                },
                transport: {
                    read: (options) => {
                        this.LoadMinimumFees(options);
                    }
                }
            }),
        })
    }

    LoadMinimumFees(options: any) {
        this.rpmUiApiResources.RoyaltyContractMinimumFeeIncreasesAndCapsByContract(this.contractId)
            .then((response) => {
                let responseArray = [];
                response.data.forEach((item, index) => {
                    responseArray.push({
                        ...item,
                        AdFeeCap: this.numberWithCommas(item.AdFeeCap)
                    })
                })
                options.success(responseArray);
                this.royaltyContract.AdFeeCapException = response.data.sort((t1, t2) => {

                    if (t1.YearIncreased < t2.YearIncreased) { return 1; }
                    if (t1.YearIncreased > t2.YearIncreased) { return -1; }
                    return 0;
                })[0].AdFeeCapException;

                if (this.royaltyContract.AdFeeCapException) {
                    let cell = $('#adFeeCapsGrid').find('tbody tr:eq(0) td:eq(2)');
                    this.adFeeCapsGrid.editCell(cell);
                }
            })
            .catch((err) => {
                options.error(err);
            });
    }

    LoadContract() {
        this.isLoading = true;

        let contractPromise = this.GetContract()
            .then((contract) => {
                this.royaltyContract = contract;
                if (this.royaltyContract) {
                    let contractSignedDate = new Date(this.royaltyContract.ContractSignedDate);
                    this.previousYearFrom = contractSignedDate.getFullYear() - 4;
                    this.previousYearTo = contractSignedDate.getFullYear() - 1;
                }
            });

        let permissionsPromise = this.identityManager.GetLoggedInUserInfo()
            .then(successResponse => {
                this.currentLoginEmail = successResponse.data.Email;
                this.readOnly = !this.identityManager.UserHasRole(successResponse.data, ["ContractManagementAdmin", "Admin"])
            });

        let terminatedPromise = this.rpmUiApiResources.GetAllRoyaltyContractTerminatedReasons()
            .then((terminatedResponse) => {
                this.terminatedReasons = terminatedResponse.data;
            });


        this.$q.all([
            contractPromise,
            permissionsPromise,
            terminatedPromise
        ])
            .finally(() => {
                this.isLoading = false;
            });
    }

    GetContract() {
        return this.rmsState.royaltyContractStateHandler.Load(this.contractId, this.isDynamicFees);
    }

    showSnackbar: boolean;
    snackbarType: string;
    snackbarMessage: string;

    DisplaySnackbar(type: string, message: string) {
        this.showSnackbar = !this.showSnackbar;
        this.snackbarType = type;
        this.snackbarMessage = message;
        this.$scope.$evalAsync();
    }


    HasUnsavedChanges() {
        return (
            this.formController.$dirty || 
            (this.keyValueController && this.keyValueController.HasChanges()) || 
            (this.excludedServicesController && this.excludedServicesController.HasChanges())
        );
    }

    // async CopyContract() {
    //     console.log("CopyContract", this.selectedAdFeeCapOption);

    //     return await AngularUtil.TrackLoadingPromise(
    //         this.royaltyApiResources.CopyContractByContractId(this.contractId)
    //             .then((idResponse) => {
    //                 if (idResponse && idResponse.data)
    //                     this.$window.location.href = `/ContractManagement/Edit/#?tab=general&contractId=${idResponse.data.royaltyContractId}`
    //             })
    //             .catch((ex) => {
    //                 alert("Error occured while copying contract");
    //             })
    //         , this
    //     );
    // }

    async CopyContract()
    {
       this.CreateFranchiseFilter();

       console.log(this.franchiseIdToCopy);

       if (!this.franchiseIdToCopy) {
           this.DisplaySnackbar("error", "Please select the franchise to copy the contract to!")
           return;
       }

       if(!this.selectedAdFeeCapOption){
            this.DisplaySnackbar("error", "Please choose the source for ad fee cap amount!")
            return;
       }

       if(this.selectedAdFeeCapOption == this.adFeeCapOptions.SPECIFY_AMOUNTS && !this.adFeeCapAmount){
            this.DisplaySnackbar("error", "Please enter a valid ad fee cap amount!")
            return;
        }

        const params = {
            royaltyContractId: +this.contractId,
            franchiseId: this.franchiseIdToCopy,
            adFeeCapOption : this.selectedAdFeeCapOption,
            adFeeCapAmount : +this.adFeeCapAmount
        }

        if (this.isDynamicFees) {
            return await AngularUtil.TrackLoadingPromise(
                this.royaltyApiResources.CopyContractToNewFranchiseWithParametersDynamic(params)
                    .then((idResponse) => {
                        this.DisplaySnackbar("success", "Successfully copied the contract. Redirecting now!")

                        if (idResponse && idResponse.data)
                            this.$window.location.href = `/ContractManagement/Edit/#?tab=general&contractId=${idResponse.data.royaltyContractId}`
                        this.$window.location.reload();
                    })
                    .catch((ex) => {
                        this.DisplaySnackbar("error", "Error occured while copying contract")
                    }).finally(() => this.window.close())
                , this
            );
        }
        else {
           return await AngularUtil.TrackLoadingPromise(
               this.royaltyApiResources.CopyContractToNewFranchiseWithParameters(params)
                   .then((idResponse) =>
                   {
                       this.DisplaySnackbar("success", "Successfully copied the contract. Redirecting now!")

                       if (idResponse && idResponse.data)
                           this.$window.location.href = `/ContractManagement/Edit/#?tab=general&contractId=${idResponse.data.royaltyContractId}`
                           this.$window.location.reload();
                   })
                   .catch((ex) => {
                       this.DisplaySnackbar("error", "Error occured while copying contract")
                   }).finally(() => this.window.close())
               , this
           );
        }
    }

    //numbers with commas
    numberWithCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
    }

    async Save()
    {
        this.isLoading = true;
        this.adFeeCapsGrid.closeCell();

        // cell ad fee cap amount of latest year
        let cell = $('#adFeeCapsGrid').find('tbody tr:eq(0) td:eq(2)');

        // latest Year Ad Cap Fee Amount
        if (cell) {
            //validate ad fee cap amount 
            //if (isNaN(parseInt(cell.text()))
            //    || parseInt(cell.text()) <= 0) {
            //    alert("Please enter a valid ad fee cap amount");
            //    this.isLoading = false;
            //    if (this.royaltyContract.AdFeeCapException) {
            //        let cell = $('#adFeeCapsGrid').find('tbody tr:eq(0) td:eq(2)');
            //        this.adFeeCapsGrid.editCell(cell);
            //    }
            //    return;
            //}
            this.royaltyContract.AdFeeCapExceptionAmount = parseInt(cell.text().toString().replace(/,/g, ""));
        }
        try
        {
            let contract = await this.GetContract();
            contract.DisclosureDocumentId = this.royaltyContract.DisclosureDocumentId;
            contract.ContractBeginsDate = this.royaltyContract.ContractBeginsDate;
            contract.ContractEndsDate = this.royaltyContract.ContractEndsDate;
            contract.RoyaltyMethodologyTypeId = this.royaltyContract.RoyaltyMethodologyTypeId;
            contract.RoyaltyContractSourceID = this.royaltyContract.RoyaltyContractSourceID;
            contract.RoyaltyContractStatusID = this.royaltyContract.RoyaltyContractStatusID;
            contract.ReportLateDays = this.royaltyContract.ReportLateDays;
            contract.ReportEndDayOfWeek = this.royaltyContract.ReportEndDayOfWeek;
            contract.LateFeeAmount = this.royaltyContract.LateFeeAmount;
            contract.OriginalRoyaltyContractID = this.royaltyContract.OriginalRoyaltyContractID;
            contract.ContractSignedDate = this.royaltyContract.ContractSignedDate;
            contract.UpdatedDateTime = this.royaltyContract.UpdatedDateTime;
            contract.RoyaltyBeginsDate = this.royaltyContract.RoyaltyBeginsDate;
            contract.RoyaltyEndsDate = this.royaltyContract.RoyaltyEndsDate;
            contract.ContractLength = this.royaltyContract.ContractLength;
            contract.Notes = this.royaltyContract.Notes;
            contract.RoyaltyScaleCalculationTypeId = this.royaltyContract.RoyaltyScaleCalculationTypeId;
            contract.RoyaltySummaryInvoiceDisplayTypeId = this.royaltyContract.RoyaltySummaryInvoiceDisplayTypeId;
            contract.YTDSalesOverrideAmount = this.royaltyContract.YTDSalesOverrideAmount;
            contract.YTDSalesEffectiveDate = this.royaltyContract.YTDSalesEffectiveDate;
            contract.PreviousYear = this.royaltyContract.PreviousYear;
            contract.PreviousYearSales = this.royaltyContract.PreviousYearSales;
            contract.AdFeeCapException = this.royaltyContract.AdFeeCapException;
            contract.AdFeeCapExceptionAmount = this.royaltyContract.AdFeeCapExceptionAmount;
            contract.RoyaltyContractTerminatedReasonId = parseInt(<any>this.royaltyContract.RoyaltyContractStatusID) === 1 ? this.royaltyContract.RoyaltyContractTerminatedReasonId : null;
            contract.TerminatedDate = parseInt(<any>this.royaltyContract.RoyaltyContractStatusID) === 1 ? this.royaltyContract.TerminatedDate : null;
            contract.ResaleFromFranchiseId = this.royaltyContract.ResaleFromFranchiseId;

            if (contract.RoyaltyContractStatusID == 1) {
                if (contract.RoyaltyContractTerminatedReasonId == null) {
                    return false;
                }
                if (contract.TerminatedDate == null || contract.TerminatedDate == "") {
                    return false;
                }
            }
            if (this.isDynamicFees) {
                await this.rpmUiApiResources.UpdateRoyaltyContractDynamic(contract.RoyaltyContractId, contract);
            }
            else {
                await this.rpmUiApiResources.UpdateRoyaltyContract(contract.RoyaltyContractId, contract);
            }
            await this.keyValueController.SaveChanges();
            await this.excludedServicesController.SaveChanges();
            this.rmsState.royaltyContractStateHandler.Clear(this.contractId);
            await this.$timeout(() =>
            {
                this.formController.$setPristine();
            }, 1000);
        }
        finally
        {
            this.$timeout(() =>
            {
                this.isLoading = false;
                //set ad fee cap amount to editable if is exception is checked
                if (this.royaltyContract.AdFeeCapException) {
                    let cell = $('#adFeeCapsGrid').find('tbody tr:eq(0) td:eq(2)');
                    this.adFeeCapsGrid.editCell(cell);
                }
                else {
                    this.adFeeCapsGrid.closeCell();
                }
            }, 0);
        }
    }

    RmsKeyValueSelect(options: kendo.ui.GridColumnEditorOptions) {
        let keyId: number = options.model.get(nameof<IRmsKeyValueRecord>(o => o.RoyaltyKeyValuePairId));
        let value: any = options.model.get(nameof<IRmsKeyValueRecord>(o => o.Value));

        if (value === null || value === "") {
            let foundDisclosureKvp = _.find(this.disclosureDocumentPairs, d => d.RoyaltyKeyValuePairId == keyId);
            if (foundDisclosureKvp) {
                options.model.set(nameof<IRmsKeyValueRecord>(o => o.Value), foundDisclosureKvp.Value);
            }
        }
    }

    combinedFranchiseFilters: kendo.data.DataSourceFilters;

    static BindComponent(app: ng.IModule)
    {
        app
            .component("cmGeneral", {
                bindings: {
                    [nameof<CmGeneralComponentController>(o => o.contractId)]: "<",
                    [nameof<CmGeneralComponentController>(o => o.franchisorId)]: "<",
                    [nameof<CmGeneralComponentController>(o => o.franchiseId)]: "<",
                    [nameof<CmGeneralComponentController>(o => o.conceptId)]: "<",
                    [nameof<CmGeneralComponentController>(o => o.init)]: "&?"
                },
                templateUrl: '/Templates/ContractManagement/cmGeneral.html',
                controller: CmGeneralComponentController
            });
    }
}

