import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";

import { Helpers } from "Utility/Helpers";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";

interface IDppContactSelectorDirectiveScope extends ng.IScope //extends IEditDisasterPrepProfileSectionScope //ng.IScope
{
    //External scope
    dppId: number;
    dppContactId: number;
    dppContacts: DppResources.IDisasterPrepProfileContact[];
    dppRefreshContacts: () => ng.IPromise<DppResources.IDisasterPrepProfileContact[]>;

    //Internal Scope
    dppSelectedContact: DppResources.IDisasterPrepProfileContact;
    isCreatingNewContact: boolean;
    isEditingContact: boolean;
    
    newContact: DppResources.IDisasterPrepProfileContact;
    editingContact: DppResources.IDisasterPrepProfileContact;

    ToggleEdit: () => void;
    ToggleAddNew: () => void;
    RefreshNewContact: () => void;
    
    Cancel: (skipPrompt?: boolean) => void;
    DeleteContact: (contact: DppResources.IDisasterPrepProfileContact) => ng.IPromise<any>;
    UpdateContact: (contact: DppResources.IDisasterPrepProfileContact) => ng.IPromise<any>;
    SaveAndSelect: (contact: DppResources.IDisasterPrepProfileContact) => ng.IPromise<any>;
}

export class DppContactSelectorDirective
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.directive('dppContactSelector', ['$window', '$timeout', '$parse', "dppApiResources",
            ($window: ng.IWindowService, $timeout: ng.ITimeoutService, $parse: ng.IParseService, dppApiResources: DppApiResources): ng.IDirective =>
            {
                let scopeBinding: IScopeBinding = {
                    dppId: '=',
                    dppContactId: '=',
                    dppContacts: '=',
                    dppRefreshContacts: '&'
                };

                return {
                    scope: scopeBinding,
                    restrict: "A",
                    replace: false,
                    transclude: false,
                    templateUrl: '/Templates/Dpp/Directives/ContactSelector.html',
                    link: (scope: IDppContactSelectorDirectiveScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) =>
                    {
                        scope.isCreatingNewContact = false;
                        scope.isEditingContact = false;

                        let onWatch = () =>
                        {
                            if (scope.dppContacts && scope.dppContactId) {
                                scope.dppSelectedContact = scope.dppContacts.filter(c => c.DppContactId === scope.dppContactId)[0];
                            }
                            else {
                                scope.dppSelectedContact = null;
                            }
                        }

                        scope.$watch("dppContactId", onWatch);
                        scope.$watch("dppContacts", onWatch);

                        scope.ToggleEdit = () =>
                        {
                            scope.isEditingContact = !scope.isEditingContact;
                            scope.isCreatingNewContact = false;
                            scope.editingContact = angular.copy(scope.dppSelectedContact);
                        };

                        scope.ToggleAddNew = () =>
                        {
                            scope.isCreatingNewContact = !scope.isCreatingNewContact;
                            scope.isEditingContact = false;
                        };

                        scope.RefreshNewContact = () =>
                        {
                            scope.newContact = new dppApiResources.DisasterPrepProfileContactApi();
                            scope.newContact.DppId = scope.dppId;
                        }

                        scope.RefreshNewContact();

                        scope.Cancel = (skipPrompt: boolean) =>
                        {
                            if (skipPrompt || confirm("Are you sure you want to cancel?")) {
                                scope.isEditingContact = false;
                                scope.isCreatingNewContact = false;
                                scope.editingContact = null;
                            }
                        }

                        scope.DeleteContact = (contact: DppResources.IDisasterPrepProfileContact) =>
                        {
                            if (confirm(`Are you sure you want to delete the contact, '${contact.Name}'`)) {
                                return contact.$delete({ id: contact.DppContactId, allowHardDelete: true },
                                    (response) =>
                                    {
                                        return scope.dppRefreshContacts().then((refreshedContacts: DppResources.IDisasterPrepProfileContact[]) =>
                                        {
                                            scope.dppContacts = refreshedContacts;
                                            scope.dppSelectedContact = null;
                                        }, (err) => { console.error(err); });
                                    },
                                    (err) =>
                                    {
                                        console.error(err);
                                    });
                            }
                        };

                        scope.UpdateContact = (contact: DppResources.IDisasterPrepProfileContact) =>
                        {
                            contact.PhoneNumber = Helpers.StripNonNumerices(contact.PhoneNumber);
                            return contact.$update({ id: contact.DppContactId }, (response) =>
                            {
                                console.log(response);
                                return scope.dppRefreshContacts().then((refreshedContacts: DppResources.IDisasterPrepProfileContact[]) =>
                                {
                                    scope.dppContacts = refreshedContacts;
                                    scope.Cancel(true);
                                }, (err) => { console.error(err); });
                            },
                                (err) =>
                                {
                                    console.error(err);
                                });
                        };

                        scope.SaveAndSelect = (contact: DppResources.IDisasterPrepProfileContact) =>
                        {
                            contact.DppId = scope.dppId;
                            contact.PhoneNumber = Helpers.StripNonNumerices(contact.PhoneNumber);

                            return contact.$save((response) =>
                            {
                                console.log(response);
                                return scope.dppRefreshContacts().then((refreshedContacts: DppResources.IDisasterPrepProfileContact[]) =>
                                {
                                    scope.dppContacts = refreshedContacts;
                                    for (var refreshedContact of scope.dppContacts) {
                                        if (refreshedContact.DppContactId === contact.DppContactId) {
                                            scope.dppSelectedContact = refreshedContact;
                                            break;
                                        }
                                    }
                                    scope.dppContactId = scope.dppSelectedContact.DppContactId;
                                    scope.RefreshNewContact();
                                    scope.isEditingContact = false;
                                    scope.isCreatingNewContact = false;
                                });
                            },
                                (err) =>
                                {
                                    console.error(err);
                                });
                        };
                    }
                };
            }]);
    }
}

