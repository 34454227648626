import * as _ from "underscore"
import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import {
    ConceptClient, ConceptDto
} from '@nbly/franchise-group-api-clients';

import { BroadlyRulesSpecificationsClient, BroadlyRulesSpecificationsDto } from '@nbly/engagement-services-clients'

export class BroadlyAdminConfigComponent implements ng.IController {
    franchiseId: number;
    conceptId: number;
    isLoading: boolean;
    franchiseWebLocationId: number;


    //Clients
    engagementClient: AxiosInstance;
    broadlyRulesClient: BroadlyRulesSpecificationsClient;


    //DTOS
    broadlyRulesSpecificationsDto: BroadlyRulesSpecificationsDto;

    newRulesDto: BroadlyRulesSpecificationsDto

    franchiseGroupClient: AxiosInstance;
    conceptClient: ConceptClient;

    //Grid
    broadlyConfigGrid: kendo.ui.Grid;
    concepts: any = [];

    //Popup
    broadlyCreatePopup: kendo.ui.Window;

    kNotifierOptions: kendo.ui.NotificationOptions;
    kNotifier: kendo.ui.Notification;

    saveSuccessful = false;

    static $inject = [
        "$scope",
        "$document"
    ];

    constructor(
        private $scope: ng.IScope,
        private $document: ng.IDocumentService
    ) {
        this.newRulesDto = {
            broadlyRuleSpecificationId: 0,
            conceptId: 0,
            minJobAmountCheck: 0,
            jobCompleteCheck: false,
            projectAssociatedJobsCheck: false,
            invoiceNotPendingCheck: false,
            invoiceCompleteCheck: false,
            customerTypeValidityCheck: 0,
            onlyTopAssignmentSplitCheck: false,
            donotMailCheck: false,
            donotServiceCheck: false,
            masterJobCheck: false,
            syncSourceTypeIdCheck: null,
            oneTimeCleanCheck: false,
            delayDayCheck: null,
            syncProjectCheck: false,
            invoicePaidCheck: false,
            posTypeCheck: null,
            employeeTypeCheck: false,
        } as any;
        this.engagementClient = FranForceAxiosClientBuilder.BuildEngagementServicesBaseClient();
        this.broadlyRulesClient = new BroadlyRulesSpecificationsClient("", this.engagementClient);

        this.franchiseGroupClient = FranForceAxiosClientBuilder.BuildFranchiseGroupBaseClient();
        this.conceptClient = new ConceptClient("", this.franchiseGroupClient);

        this.kNotifierOptions = {
            position: {
                    top: null,
                    left: null,
                    bottom: 20,
                    right: 20
            },
            stacking: "up",
            button: true,
            templates: [{
                type: 'ngTemplate',
                template: $('#kNTemplate').html(),
            }]
        };
    }

    generateEmptyDto(){
        this.newRulesDto = {
            broadlyRuleSpecificationId: 0,
            conceptId: 0,
            minJobAmountCheck: 0,
            jobCompleteCheck: false,
            projectAssociatedJobsCheck: false,
            invoiceNotPendingCheck: false,
            invoiceCompleteCheck: false,
            customerTypeValidityCheck: 0,
            onlyTopAssignmentSplitCheck: false,
            donotMailCheck: false,
            donotServiceCheck: false,
            masterJobCheck: false,
            syncSourceTypeIdCheck: null,
            oneTimeCleanCheck: false,
            delayDayCheck: null,
            syncProjectCheck: false,
            invoicePaidCheck: false,
            posTypeCheck: null,
            employeeTypeCheck: false,
        } as any;
    }

    async $onInit() {
        //load the broadlyRulesSpecifications
        this.isLoading=true;
        await this.conceptClient.get_protected_concepts().
            then((response) => {
                this.concepts = response.result;
                this.broadlyRulesClient.broadlyRulesSpecificationsAll().then((response) => {
                    this.broadlyRulesSpecificationsDto = response.result as any;
                    this.isLoading=false;
                    this.InitGridOptions();
                    var checkboxes = $("#mainGrid :checkbox");
                    checkboxes.prop("disabled", true);
                  
                })
            }).catch((error) => {
                this.isLoading=false;
            }).finally(() => {
                this.isLoading=false;
            });

    }

    $postLink()
    {
        this.InitWindow();
    }


    InitWindow()
    {
        let options: kendo.ui.WindowOptions = {
            modal: true,
            width: 1000,
            title: "Create New Broadly Rules",
            
        };
        this.broadlyCreatePopup.setOptions(options);
    }

    OnConceptSelect(concept)
    {
        this.newRulesDto.conceptId = concept.ConceptId;

    }


    createNewBroadlyRules(){
    
        this.broadlyCreatePopup.open().center();
    }

    saveBroadlyNewRules(){
        this.broadlyRulesClient.broadlyRulesSpecificationsPOST(this.newRulesDto).then((response) => {
            this.refreshGrid()
            this.broadlyCreatePopup.close();
            this.generateEmptyDto();
            this.saveSuccessful = true;
            this.kNotifier.show({
                kValue: 'Success'
            }, "ngTemplate");
        }).catch(({ err, response }) => {
            console.log('Error occuered',err,response)
            this.saveSuccessful = false;
            this.kNotifier.show({
                kValue: response.Message
            }, "ngTemplate");
        });
    }

    async refreshGrid(){
        await this.broadlyRulesClient.broadlyRulesSpecificationsAll().then((response) => {
            this.broadlyRulesSpecificationsDto = response.result as any;
            this.broadlyConfigGrid.dataSource.data(this.broadlyRulesSpecificationsDto);
            this.broadlyConfigGrid.refresh();
            var checkboxes = $("#mainGrid :checkbox");
            checkboxes.prop("disabled", true); // Disable all checkboxes
            this.isLoading=false;
        })
    }

    async save(dataItem) {
        this.isLoading=true;
        try{
            await this.broadlyRulesClient.broadlyRulesSpecificationsPUT(dataItem).then((response) => {
                this.isLoading=false;
                this.saveSuccessful = true;
                this.kNotifier.show({
                    kValue: "Success"
                }, "ngTemplate");
                this.broadlyRulesClient.broadlyRulesSpecificationsAll().then((response) => {
                    this.broadlyRulesSpecificationsDto = response.result as any;
                    this.broadlyConfigGrid.refresh();
                    var checkboxes = $("#mainGrid :checkbox");
                    checkboxes.prop("disabled", true); // Disable all checkboxes
                    this.isLoading=false;
                })
            });
        }catch(err){
            this.isLoading=false;
            this.saveSuccessful = false;
            this.kNotifier.show({
                kValue: "Error occured"
            }, "ngTemplate");
        }
     

    }

    InitGridOptions() {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.conceptId),
                title: "Concept Id",
                locked: true,
                template: (dataItem) => {
                    let concept = this.concepts.find((c) => c.conceptId === dataItem.conceptId);
                    return "<strong>" + kendo.htmlEncode(dataItem.conceptId + " (" + concept.conceptCode + ")") + "</strong>";
                },
                width: 100,
            }, {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.minJobAmountCheck),
                title: "minJobAmountCheck",
                width: 200,
            }, {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.jobCompleteCheck),
                title: "jobCompleteCheck",
                template: '<input type="checkbox" #= jobCompleteCheck ? "checked=checked" : "" #  ></input>',
                width: 200,
            }, {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.projectAssociatedJobsCheck),
                title: "projectAssociatedJobsCheck",
                width: 200,
                template: '<input type="checkbox" #= projectAssociatedJobsCheck ? "checked=checked" : "" #  ></input>'
            }, {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.invoiceNotPendingCheck),
                title: "invoiceNotPendingCheck",
                width: 200,
                template: '<input type="checkbox" #= invoiceNotPendingCheck ? "checked=checked" : "" #  ></input>'
            }, {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.invoiceCompleteCheck),
                title: "invoiceCompleteCheck",
                width: 200,
                template: '<input type="checkbox" #= invoiceCompleteCheck ? "checked=checked" : "" #  ></input>'
            },
            {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.customerTypeValidityCheck),
                width: 200,
                title: "customerTypeValidityCheck",

            }, {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.onlyTopAssignmentSplitCheck),
                width: 200,
                title: "onlyTopAssignmentSplitCheck",
                template: '<input type="checkbox" #= onlyTopAssignmentSplitCheck ? "checked=checked" : "" #  ></input>'
            },
            {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.donotMailCheck),
                width: 200,
                title: "donotMailCheck",
                template: '<input type="checkbox" #= donotMailCheck ? "checked=checked" : "" #  ></input>'
            },
            {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.donotServiceCheck),
                width: 200,
                title: "donotServiceCheck",
                template: '<input type="checkbox" #= donotServiceCheck ? "checked=checked" : "" #  ></input>'
            },
            {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.masterJobCheck),
                title: "masterJobCheck",
                width: 200,
                template: '<input type="checkbox" #= masterJobCheck ? "checked=checked" : "" #  ></input>'
            },
            {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.syncSourceTypeIdCheck),
                width: 200,
                title: "syncSourceTypeIdCheck",
            },
            {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.oneTimeCleanCheck),
                width: 200,
                template: '<input type="checkbox" #= oneTimeCleanCheck ? "checked=checked" : "" #  ></input>',
                title: "oneTimeCleanCheck",
            },
            {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.delayDayCheck),
                width: 200,
                title: "delayDayCheck",
            },
            {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.syncProjectCheck),
                width: 200,
                title: "syncProjectCheck",
                template: '<input type="checkbox" #= syncProjectCheck ? "checked=checked" : "" #  ></input>',
            },
            {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.invoicePaidCheck),
                width: 200,
                title: "invoicePaidCheck",
                template: '<input type="checkbox" #= invoicePaidCheck ? "checked=checked" : "" #  ></input>',
            },
            {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.posTypeCheck),
                width: 200,
                title: "posTypeCheck",
            },
            {
                field: nameof.full<BroadlyRulesSpecificationsDto>(o => o.employeeTypeCheck),
                width: 200,
                title: "employeeTypeCheck",
                template: '<input type="checkbox" #= employeeTypeCheck ? "checked=checked" : "" #  ></input>',
            },
            { command: "edit", width: 100 }

        ];

        let dataSource = new kendo.data.DataSource({
            serverSorting: false,
            serverFiltering: false,

            serverPaging: false,
            pageSize: 25,
            data: this.broadlyRulesSpecificationsDto,
            schema: {
                model: {
                    fields: {
                        conceptId: { type: "number", editable: false },
                        customerTypeValidityCheck: { type: "number", editable: true, defaultValue: null },
                        broadlyRuleSpecificationId: { type: "number", editable: true },
                        minJobAmountCheck: { type: "number", editable: true, nullable: true, min: 0 },
                        projectAssociatedJobsCheck: { type: "boolean", editable: true },
                        jobCompleteCheck: { type: "boolean", editable: true },
                        invoiceNotPendingCheck: { type: "boolean", editable: true },
                        invoiceCompleteCheck: { type: "boolean", editable: true },
                        onlyTopAssignmentSplitCheck: { type: "boolean", editable: true },
                        donotMailCheck: { type: "boolean", editable: true },
                        donotServiceCheck: { type: "boolean", editable: true },
                        masterJobCheck: { type: "boolean", editable: true },
                        syncSourceTypeIdCheck: { type: "number", editable: true,nullable: true,  defaultValue: null },
                        oneTimeCleanCheck: { type: "boolean", editable: true, nullable: true,  defaultValue: null },
                        delayDayCheck: { type: "number", editable: true,nullable: true,  defaultValue: null },
                        syncProjectCheck: { type: "boolean", editable: true, nullable: true,  defaultValue: null },
                        invoicePaidCheck: { type: "boolean", editable: true , nullable: true,  defaultValue: null},
                        posTypeCheck: { type: "number", editable: true, nullable: true,  defaultValue: null },
                        employeeTypeCheck: { type: "boolean", editable: true, nullable: true,  defaultValue: null },
                    }
                }
            },

        });

        this.broadlyConfigGrid.setOptions(<kendo.ui.GridOptions>{
            dataSource: dataSource,
            columns: columns,
            sortable: true,
            editable: "popup",
            resizable: true,
            pagable: false,
            edit: function (e) {
                $(e.container).parent().css({
                    width: '1000px',

                });

                $(e.container).find('.k-edit-form-container').attr('style', 'width:100% !important');
            },
            sort:(e) => {
                var checkboxes = $("#mainGrid :checkbox");
                checkboxes.prop("disabled", true);
            },

            filterable: false,
            save: (e) => {
                this.save(e.model);
            }
        });
    }

    static BindComponent(app: ng.IModule) {
        app.component('broadlyAdminConfigComponent', {
            bindings: {
                franchiseId: "<"
            },
            controller: BroadlyAdminConfigComponent,
            templateUrl: "/Templates/AdminConfig/BroadlyAdminConfig.html"
        });
    }
}


