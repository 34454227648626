import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyContractKeyValuePairsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyContractKeyValuePairsByContract(contractId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContractKeyValuePairs/InContract/" + contractId
        };

        return this.$http<RpmEntities.RoyaltyContractKeyValuePairViewModel[]>(config);
    }

    CreateRoyaltyContractKeyValuePair(model: RpmEntities.RoyaltyContractKeyValuePairViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyContractKeyValuePairs",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyContractKeyValuePair(id: number, model: RpmEntities.RoyaltyContractKeyValuePairViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyContractKeyValuePairs/" + id,
            data: model
        };

        return this.$http<number>(config);
    }
}
    
