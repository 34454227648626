import * as _ from "underscore";
import * as elements from "typed-html";

import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";

import { MonthsEnabledController, MonthsEnabledControllerOnInit, MonthsEnabledControllerOnSelect, MonthEnabled } from "Directives/Common";
import { StringProperties } from "Types/StringProperties";
import { ExpressionBinding, AngularUtil } from "Utility/AngularUtil";
import { RoyaltyApiResources } from "../../../../Services/Resources/RoyaltyApiResources";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            fddRoyaltiesRatePlanMinimumsMonthsApplied: Partial<StringProperties<fddRoyaltiesRatePlanMinimumsMonthsApplied>>;
        }
    }
}

export type fddRoyaltiesRatePlanMinimumsMonthsAppliedOnInit = { self: fddRoyaltiesRatePlanMinimumsMonthsApplied };
export type fddRoyaltiesRatePlanMinimumsMonthsAppliedOnSelect = MonthsEnabledControllerOnSelect;

const defaultMonthEnabled: boolean = true;

export class fddRoyaltiesRatePlanMinimumsMonthsApplied implements ng.IController {
    onInit: ExpressionBinding<fddRoyaltiesRatePlanMinimumsMonthsAppliedOnInit>;
    onSelect: ExpressionBinding<fddRoyaltiesRatePlanMinimumsMonthsAppliedOnSelect>;

    public isLoading: boolean;
    private royaltyDisclosureDocumentRatePlanMinimumId: number;

    private monthsEnabledController: MonthsEnabledController;

    static $inject = [
        '$timeout',
        'coreApiResources',
        'royaltyApiResources'
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private coreApiResources: CoreApiResources,
        private royaltyApiResources: RoyaltyApiResources
    ) {
        this.isLoading = false;
    }

    $onInit() {

    }

    $postLink() {
        if (this.onInit) {
            this.onInit({ self: this });
        }
    }

    async CancelChanges() {
        await this.LoadMonthsData(this.royaltyDisclosureDocumentRatePlanMinimumId);
    }

    HasChanges = () => this.monthsEnabledController.HasChanges();

    async SaveChanges(): Promise<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied[]> {
        return await AngularUtil.TrackLoadingPromise(
            fddRoyaltiesRatePlanMinimumsMonthsApplied.SaveMonthsEnabled(this.monthsEnabledController.GetMonths(), this.royaltyDisclosureDocumentRatePlanMinimumId, this.coreApiResources),
            this);
    }

    async MonthsEnabledInit(controller: MonthsEnabledController) {
        this.monthsEnabledController = controller;
    }

    SetMonthsData(royaltyDisclosureDocumentRatePlanMinimumId: number, monthsEnabled: MonthEnabled[], dirty: boolean = false) {
        this.royaltyDisclosureDocumentRatePlanMinimumId = royaltyDisclosureDocumentRatePlanMinimumId;
        this.monthsEnabledController.Init(monthsEnabled, dirty);
    }

    async LoadMonthsData(royaltyDisclosureDocumentRatePlanMinimumId: number | null) {
        this.royaltyDisclosureDocumentRatePlanMinimumId = royaltyDisclosureDocumentRatePlanMinimumId;

        return await AngularUtil.TrackLoadingPromise((async () => {
            if (this.royaltyDisclosureDocumentRatePlanMinimumId == null || this.royaltyDisclosureDocumentRatePlanMinimumId == undefined) {
                this.monthsEnabledController.InitAllMonths(defaultMonthEnabled);
                return Promise.resolve();
            }
            else {
                let months = await this.royaltyApiResources.GetRoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied(this.royaltyDisclosureDocumentRatePlanMinimumId).then((response) => {
                    return response.data;
                })

                this.monthsEnabledController.InitAllMonths((month) => {
                    return (
                        months.find(m => m.monthId == month.id)?.enabled ?? defaultMonthEnabled
                    );
                });
            }
        })(), this);
    }

    private OnSelect(changed: MonthsEnabledControllerOnSelect["changed"], all: MonthsEnabledControllerOnSelect["all"]) {
        if (this.onSelect) {
            this.onSelect({
                all: all,
                changed: changed
            });
        }
    }

    static async SaveMonthsEnabled(months: MonthEnabled[], royaltyDisclosureDocumentRatePlanMinimumId: number, coreApiResources: CoreApiResources) {
        let monthSettings = months
            .map<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied>(m => {
                return {
                    RoyaltyDisclosureDocumentRatePlanMinimumId: royaltyDisclosureDocumentRatePlanMinimumId,
                    Enabled: m.enabled,
                    MonthId: m.month.id,
                    RoyaltyDisclosureDocumentRatePlanMinimum: null
                }
            });

        //let promises = monthSettings.map(m => rmsClients.royaltyDisclosureDocumentRatePlanMinimumMonthsAppliedClient.Post(m));
        let promises = monthSettings.map(m => coreApiResources.RoyaltyDisclosureDocumentRatePlanMinimumMonthsApplied(m).then((response) => {
            return response.data;
        }));

        monthSettings = await Promise.all(promises);
        return monthSettings;
    }

    static BindComponent(app: ng.IModule) {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.fddRoyaltiesRatePlanMinimumsMonthsApplied);

        let template = (
            <monthsEnabled
                onInit={`$ctrl.${nameof<fddRoyaltiesRatePlanMinimumsMonthsApplied>(o => o.MonthsEnabledInit)}(${nameof<MonthsEnabledControllerOnInit>(o => o.self)})`}
                onSelect={`$ctrl.${nameof<fddRoyaltiesRatePlanMinimumsMonthsApplied>(o => o.OnSelect)}(${nameof<MonthsEnabledControllerOnSelect>(o => o.changed)}, ${nameof<MonthsEnabledControllerOnSelect>(o => o.all)})`}
            >
            </monthsEnabled>
        );

        app.component(componentName, {
            bindings: {
                [nameof<fddRoyaltiesRatePlanMinimumsMonthsApplied>(o => o.onInit)]: "&?",
                [nameof<fddRoyaltiesRatePlanMinimumsMonthsApplied>(o => o.onSelect)]: "&?"
            },
            template: template,
            controller: fddRoyaltiesRatePlanMinimumsMonthsApplied
        });
    }
}

