import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RmsContractSourceSelectorComponentController implements ng.IController 
{
    sourceId: number;
    readOnly: boolean;
    onSelect: (params: { source: RpmEntities.RoyaltyContractSourceViewModel }) => void;

    dropDown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    static $inject = [
        'rpmUiApiResources',
    ]

    constructor(
        private rpmUiApiResources: RpmUiApiResources
    )
    {

    }

    $onInit()
    {
        this.options = {
            autoWidth: true,
            valuePrimitive: true,
            dataTextField: nameof<RpmEntities.RoyaltyContractSourceViewModel>(o => o.Name),
            dataValueField: nameof<RpmEntities.RoyaltyContractSourceViewModel>(o => o.RoyaltyContractSourceID),
            optionLabel: "Select...",
            optionLabelTemplate: "Select...",
            enable: !this.readOnly,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) =>
                    {
                        this.rpmUiApiResources.GetAllRoyaltyContractSources()
                            .then((sourcesResponse) =>
                            {
                                options.success(sourcesResponse.data);
                            })
                            .catch((err) =>
                            {
                                options.error(err);
                            });
                    }
                }
            }),
            select: (e) =>
            {
                if (this.onSelect) {
                    this.onSelect({ source: e.dataItem });
                }
            }
        };
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.setOptions({ enable: !this.readOnly })
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("rmsContractSourceSelector", {
                bindings: {
                    [nameof<RmsContractSourceSelectorComponentController>(o => o.sourceId)]: "<",
                    [nameof<RmsContractSourceSelectorComponentController>(o => o.readOnly)]: "<",
                    [nameof<RmsContractSourceSelectorComponentController>(o => o.onSelect)]: "&?",
                },
                template: `<select kendo-drop-down-list="$ctrl.${nameof<RmsContractSourceSelectorComponentController>(o => o.dropDown)}"
                            ng-model="$ctrl.${nameof<RmsContractSourceSelectorComponentController>(o => o.sourceId)}"
                            k-options="$ctrl.${nameof<RmsContractSourceSelectorComponentController>(o => o.options)}">
                </select>`,
                controller: RmsContractSourceSelectorComponentController
            });
    }
}

