import { KendoHelperService } from "Services/Utility/KendoHelperService";
import { userStateObj, EditUserStateService } from "Services/State/EditUserStateService";

export class EditUserController implements ng.IController
{
    managedUserId: string;
    refreshedDate: Date;

    userState: userStateObj;
    tabs: kendo.ui.TabStrip;

    static $inject = [
        "$window",
        "$timeout",
        "editUserState",
        "kendoHelper"
    ];

    constructor(
        private $window: ng.IWindowService,
        private $timeout: ng.ITimeoutService,
        private editUserState: EditUserStateService,
        private kendoHelper: KendoHelperService,
    )
    {

    }

    $onInit()
    {
        let urlParts = this.$window.location.pathname.split("/");
        if (!urlParts[urlParts.length - 1]) {
            urlParts.pop();
        }

        this.managedUserId = urlParts[urlParts.length - 1];
        this.ReloadState();

    }

    $postLink()
    {
        this.kendoHelper.BindTabStripStateHandlers(this.tabs);
    }

    Refresh(date: Date)
    {
        this.$timeout(() =>
        {
            this.refreshedDate = date;
            this.ReloadState();
        });
    }

    ReloadState()
    {
        return this.editUserState.Load(this.managedUserId)
            .then((result) =>
            {
                this.userState = result;
            })
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("editUser", {
            bindings: {

            },
            controller: EditUserController,
            templateUrl: "/Templates/Users/EditUser/EditUser.html"
        });
    }
}



