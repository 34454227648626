import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { StringProperties } from "Types/StringProperties";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            rmsMethodologyTypeSelector: StringProperties<Partial<RmsMethodologyTypeSelectorComponentController>>;
        }
    }
}

export type RmsMethodologyTypeSelectorOnSelectParams = {
    event: kendo.ui.DropDownListSelectEvent,
    methodologyType: RpmEntities.usp_RoyaltyMethodologyTypes_GetByRoyaltyMethodologyTypeId_Result
};

export class RmsMethodologyTypeSelectorComponentController implements ng.IController 
{
    methodologyId: number;
    readOnly: boolean;
    onSelect: (params: RmsMethodologyTypeSelectorOnSelectParams) => void;
    makeReadOnly: string;
    dropDown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    static $inject = [
        'rpmUiApiResources',
    ]

    constructor(
        private rpmUiApiResources: RpmUiApiResources
    )
    {

    }

    $onInit()
    {
        this.options = {
            autoWidth: true,
            valuePrimitive: true,
            dataTextField: nameof<RpmEntities.usp_RoyaltyMethodologyTypes_GetByRoyaltyMethodologyTypeId_Result>(o => o.Name),
            dataValueField: nameof<RpmEntities.usp_RoyaltyMethodologyTypes_GetByRoyaltyMethodologyTypeId_Result>(o => o.RoyaltyMethodologyTypeId),
            enable: !this.readOnly,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) =>
                    {
                        this.rpmUiApiResources.GetAllRoyaltyMethodologyTypes()
                            .then((typesResponse) =>
                            {
                                options.success(typesResponse.data);
                            })
                            .catch((err) =>
                            {
                                options.error(err);
                            });
                    }
                }
            }),
            select: (e) =>
            {
                if (this.onSelect) 
                {
                    this.onSelect({
                        event: e,
                        methodologyType: e.dataItem
                    });
                }
            }
        };
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.setOptions({ enable: !this.readOnly })
        }
    }

    static BindComponent(app: ng.IModule)
    {
        const componentName = nameof<JSX.IntrinsicElements>(o => o.rmsMethodologyTypeSelector);
        app
            .component(nameof<JSX.IntrinsicElements>(o => o.rmsMethodologyTypeSelector), {
                bindings: {
                    [nameof<RmsMethodologyTypeSelectorComponentController>(o => o.methodologyId)]: "<",
                    [nameof<RmsMethodologyTypeSelectorComponentController>(o => o.readOnly)]: "<",
                    [nameof<RmsMethodologyTypeSelectorComponentController>(o => o.makeReadOnly)]: "<",
                    [nameof<RmsMethodologyTypeSelectorComponentController>(o => o.onSelect)]: "&?",
                },
                template: `<select kendo-drop-down-list="$ctrl.${nameof<RmsMethodologyTypeSelectorComponentController>(o => o.dropDown)}"
                            ng-model="$ctrl.${nameof<RmsMethodologyTypeSelectorComponentController>(o => o.methodologyId)}"
                            k-options="$ctrl.${nameof<RmsMethodologyTypeSelectorComponentController>(o => o.options)}"
                            ng-readonly="$ctrl.${nameof<RmsMethodologyTypeSelectorComponentController>(o => o.makeReadOnly)}">
                </select>`,
                controller: RmsMethodologyTypeSelectorComponentController
            });
    }
}

