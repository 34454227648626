import * as elements from 'typed-html';
import { StringProperties } from "Types/StringProperties";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            loadingSpinner: Partial<StringProperties<LoadingSpinnerComponentController>>;
        }
    }
}

export class LoadingSpinnerComponentController implements ng.IController
{
    isLoading: boolean;

    static $inject = [];

    constructor() {
    }

    $onInit() {
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.loadingSpinner);

        app.component(componentName, {
            bindings: {
                [nameof<LoadingSpinnerComponentController>(o => o.isLoading)]: "<",
            },
            controller: LoadingSpinnerComponentController,
            template: <img ng-show={`$ctrl.${nameof<LoadingSpinnerComponentController>(o => o.isLoading)}`} src='/Content/css/images/icons/loading.gif' />,
        });
    }
}

