import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { RpmResourceApi } from "Interfaces/FranForce/Rpm/RpmResourceApiInterfaces";
import { RpmResources, RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";


export class RpmApiResources
{
    DisclosureDocumentApi: RpmResourceApi.IDisclosureDocumentApi;
    DisclosureDocumentKeyValuePairApi: RpmResourceApi.IDisclosureDocumentKeyValuePairApi;
    FranchiseTaxReturnAnalysisApi: RpmResourceApi.IFranchiseTaxReturnAnalysisApi;
    RoyaltyContractApi: RpmResourceApi.IRoyaltyContractApi;
    RoyaltyContractKeyValuePairApi: RpmResourceApi.IRoyaltyContractKeyValuePairApi;
    RoyaltyContractRatePlanApi: RpmResourceApi.IRoyaltyContractRatePlanApi;
    RoyaltyContractRatePlanItemApi: RpmResourceApi.IRoyaltyContractRatePlanItemApi;
    RoyaltyContractRatePlanItemDetailApi: RpmResourceApi.IRoyaltyContractRatePlanItemDetailApi;
    RoyaltyContractRatePlanMinimumItemApi: RpmResourceApi.IRoyaltyContractRatePlanMinimumItemApi;
    RoyaltyContractSourceApi: RpmResourceApi.IRoyaltyContractSourceApi;
    RoyaltyContractStatusApi: RpmResourceApi.IRoyaltyContractStatusApi;
    RoyaltyContractTerminatedReasonApi: RpmResourceApi.IRoyaltyContractTerminatedReasonApi;
    RoyaltyDisclosureDocumentRatePlanApi: RpmResourceApi.IRoyaltyDisclosureDocumentRatePlanApi;
    RoyaltyDisclosureDocumentRatePlanItemApi: RpmResourceApi.IRoyaltyDisclosureDocumentRatePlanItemApi;
    RoyaltyDisclosureDocumentRatePlanItemDetailApi: RpmResourceApi.IRoyaltyDisclosureDocumentRatePlanItemDetailApi;
    RoyaltyDisclosureDocumentRatePlanMinimumItemApi: RpmResourceApi.IRoyaltyDisclosureDocumentRatePlanMinimumItemApi;
    RoyaltyDisclosureDocumentRatePlanMinimumItemDetailApi: RpmResourceApi.IRoyaltyDisclosureDocumentRatePlanMinimumItemDetailApi;
    RoyaltyEngineConfigKeyValuePairApi: RpmResourceApi.IRoyaltyEngineConfigKeyValuePairApi;
    RoyaltyFindScaleBasedOnTypeApi: RpmResourceApi.IRoyaltyFindScaleBasedOnTypeApi;
    RoyaltyMethodologyTypeApi: RpmResourceApi.IRoyaltyMethodologyTypeApi;
    RoyaltyPeriodApi: RpmResourceApi.IRoyaltyPeriodApi;
    RoyaltyPeriodFrequencyApi: RpmResourceApi.IRoyaltyPeriodFrequencyApi;
    RoyaltyPeriodReportApi: RpmResourceApi.IRoyaltyPeriodReportApi;
    RoyaltyPeriodReportDetailApi: RpmResourceApi.IRoyaltyPeriodReportDetailApi;
    RoyaltyPeriodReportStatusApi: RpmResourceApi.IRoyaltyPeriodReportStatusApi;
    RoyaltyRatePlanItemTypeApi: RpmResourceApi.IRoyaltyRatePlanItemTypeApi;
    RoyaltyRatePlanTypeApi: RpmResourceApi.IRoyaltyRatePlanTypeApi;
    RoyaltyReportBatchApi: RpmResourceApi.IRoyaltyReportBatchApi;
    RoyaltyReportBatchStatusApi: RpmResourceApi.IRoyaltyReportBatchStatusApi;
    RoyaltyServiceCategoryApi: RpmResourceApi.IRoyaltyServiceCategoryApi;
    RoyaltySyncInvoiceApi: RpmResourceApi.IRoyaltySyncInvoiceApi;
    RoyaltySystemsApi: RpmResourceApi.IRoyaltySystemApi;

    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig"
    ];
    
    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {
        this.RefreshResources()
    }
    
    RefreshResources()
    {
        this.DisclosureDocumentApi = this.$resource<RpmResources.IDisclosureDocument>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/DisclosureDocuments/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.DisclosureDocumentKeyValuePairApi = this.$resource<RpmResources.IDisclosureDocumentKeyValuePair>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/DisclosureDocumentKeyValuePairs/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.FranchiseTaxReturnAnalysisApi = this.$resource<RpmResources.IFranchiseTaxReturnAnalysis>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/FranchiseTaxReturnAnalyses/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyContractApi = this.$resource<RpmResources.IRoyaltyContract>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyContracts/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        
        this.RoyaltyContractKeyValuePairApi = this.$resource<RpmResources.IRoyaltyContractKeyValuePair>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyContractKeyValuePair/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
        
        this.RoyaltyContractRatePlanApi = this.$resource<RpmResources.IRoyaltyContractRatePlan>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyContractRatePlan/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyContractRatePlanItemApi = this.$resource<RpmResources.IRoyaltyContractRatePlanItem>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyContractRatePlanItem/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyContractRatePlanItemDetailApi = this.$resource<RpmResources.IRoyaltyContractRatePlanItemDetail>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyContractRatePlanItemDetail/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyContractRatePlanMinimumItemApi = this.$resource<RpmResources.IRoyaltyContractRatePlanMinimumItem>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyContractRatePlanMinimumItem/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyContractSourceApi = this.$resource<RpmResources.IRoyaltyContractSource>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyContractSource/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyContractStatusApi = this.$resource<RpmResources.IRoyaltyContractStatus>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyContractStatus/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyContractTerminatedReasonApi = this.$resource<RpmResources.IRoyaltyContractTerminatedReason>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyContractTerminatedReason/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyDisclosureDocumentRatePlanApi = this.$resource<RpmResources.IRoyaltyDisclosureDocumentRatePlan>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRatePlan/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyDisclosureDocumentRatePlanItemApi = this.$resource<RpmResources.IRoyaltyDisclosureDocumentRatePlanItem>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRatePlanItems/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyDisclosureDocumentRatePlanItemDetailApi = this.$resource<RpmResources.IRoyaltyDisclosureDocumentRatePlanItemDetail>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRatePlanItemDetail/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyDisclosureDocumentRatePlanMinimumItemApi = this.$resource<RpmResources.IRoyaltyDisclosureDocumentRatePlanMinimumItem>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRatePlanMinimumItem/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailApi = this.$resource<RpmResources.IRoyaltyDisclosureDocumentRatePlanMinimumItemDetail>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyDisclosureDocumentRatePlanMinimumItemDetail/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyEngineConfigKeyValuePairApi = this.$resource<RpmResources.IRoyaltyEngineConfigKeyValuePair>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyEngineConfigKeyValuePair/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyFindScaleBasedOnTypeApi = this.$resource<RpmResources.IRoyaltyFindScaleBasedOnType>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyFindScaleBasedOnTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyMethodologyTypeApi = this.$resource<RpmResources.IRoyaltyMethodologyType>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyMethodologyType/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyPeriodApi = this.$resource<RpmResources.IRoyaltyPeriod>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyPeriod/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyPeriodFrequencyApi = this.$resource<RpmResources.IRoyaltyPeriodFrequency>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyPeriodFrequency/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyPeriodReportApi = this.$resource<RpmResources.IRoyaltyPeriodReport>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyPeriodReport/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyPeriodReportDetailApi = this.$resource<RpmResources.IRoyaltyPeriodReportDetail>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyPeriodReportDetail/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyPeriodReportStatusApi = this.$resource<RpmResources.IRoyaltyPeriodReportStatus>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyPeriodReportStatus/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyRatePlanItemTypeApi = this.$resource<RpmResources.IRoyaltyRatePlanItemType>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyRatePlanItemTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyRatePlanTypeApi = this.$resource<RpmResources.IRoyaltyRatePlanType>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyRatePlanType/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyReportBatchApi = this.$resource<RpmResources.IRoyaltyReportBatch>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyReportBatch/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyReportBatchStatusApi = this.$resource<RpmResources.IRoyaltyReportBatchStatus>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyReportBatchStatus/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltyServiceCategoryApi = this.$resource<RpmResources.IRoyaltyServiceCategory>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltyServiceCategory/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltySyncInvoiceApi = this.$resource<RpmResources.IRoyaltySyncInvoice>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltySyncInvoice/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.RoyaltySystemsApi = this.$resource<RpmResources.IRoyaltySystem>(
            this.apiConfig.AppSettings.CoreApiRootUrl + "/RoyaltySystems/:id/",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
    }
    
    GetRoyaltyPeriodReport(royaltyPeriodReportId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url:this.apiConfig.AppSettings.CoreApiRootUrl + "/PeriodReports/" + royaltyPeriodReportId
        };
        
        return this.$http<RpmEntities.PrViewModel>(config);
    }

    InsertRoyaltyContractExcludedServices(model: RpmEntities.RoyaltyContractExcludedService) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/RoyaltyContractExcludedServices/InsertRoyaltyContractExcludedServices`,
            data: model
        };
        return this.$http<string>(config);
    }
}