import * as elements from 'typed-html';
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import * as rmsClients from "Clients/Rms";
import { StringProperties } from 'Types/StringProperties';
import { KendoUtil } from "Utility/KendoUtil";
import { NameOfFullToControllerAs } from 'Utility/Helpers';
import { ExpressionBinding } from 'Utility/AngularUtil';
import { IFddController } from 'Directives/RPM/FddManagement/IFddController';
import { CoreApiResources } from "Services/Resources/CoreApiResources";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            fddRollInRanges: Partial<StringProperties<fddRollInRanges>>;
        }
    }
}

export type fddRollInRangesOnInit = {
    self: fddRollInRanges;
} 

export class fddRollInRanges implements ng.IController, IFddController
{
    onInit: ExpressionBinding<fddRollInRangesOnInit>;

    private disclosureDocumentId: number;
    private readOnly: boolean = false;
    private formController: angular.IFormController;
    private grid: kendo.ui.Grid;
    private rollInRangesClient = rmsClients.royaltyDisclosureDocumentRollInRangeClient;

    static $inject = [
        '$window',
        '$timeout',
        'coreApiResources'
    ];

    constructor(
        private $window: ng.IWindowService,
        private $timeout: ng.ITimeoutService,
        private coreApiResources: CoreApiResources
    )
    {

    }

    $onInit()
    {
    }

    $postLink()
    {
        this.InitGrid();
        if (this.onInit)
        {
            this.onInit({ self: this });
        }
    }

    private InitGrid()
    {
        let columns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRollInRange>(o => o.RollInRangeName),
                title: "Range Name",
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRollInRange>(o => o.RollInSalesLowRangeAmount),
                title: "Sales Low",
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRollInRange>(o => o.RollInSalesHighRangeAmount),
                title: "Sales High",
                format: "{0:c}"
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            },
        ];

        let datasource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof<RpmEntities.RoyaltyDisclosureDocumentRollInRange>(o => o.RoyaltyDisclosureDocumentRollInRangeId),
                    fields: {
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRollInRange>(o => o.RoyaltyDisclosureDocumentRollInRangeId)]: {
                            type: "number"
                        },
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRollInRange>(o => o.DisclosureDocumentId)]: {
                            type: "number"
                        },
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRollInRange>(o => o.RollInRangeName)]: {
                            type: "string"
                        },
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRollInRange>(o => o.RollInSalesLowRangeAmount)]: {
                            type: "number"
                        },
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRollInRange>(o => o.RollInSalesHighRangeAmount)]: {
                            type: "number"
                        }
                    } as kendo.data.DataSourceSchemaModelFields
                } as kendo.data.DataSourceSchemaModel
            },

            transport: {
                read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    if (!this.disclosureDocumentId)
                        return [];

                    return this.rollInRangesClient.Query({
                        $filter: `${nameof<RpmEntities.RoyaltyDisclosureDocumentRollInRange>(o => o.DisclosureDocumentId)} eq ${this.disclosureDocumentId}`
                    })
                }),
                create: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let model = options.data as RpmEntities.RoyaltyDisclosureDocumentRollInRange;
                    model.DisclosureDocumentId = this.disclosureDocumentId;
                    //return this.rollInRangesClient.Post(model);
                    return await this.coreApiResources.CreateRollInRanges(model).then((response) => {
                        return response.data
                    });
                }),
                update: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let model = options.data as RpmEntities.RoyaltyDisclosureDocumentRollInRange;
                    //return this.rollInRangesClient.Put(model.RoyaltyDisclosureDocumentRollInRangeId, model);
                    return await this.coreApiResources.UpdateRollInRanges(model.RoyaltyDisclosureDocumentRollInRangeId, model).then((response) => {
                        return response.data
                    });
                }),
                destroy: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let model = options.data as RpmEntities.RoyaltyDisclosureDocumentRollInRange;
                    return this.rollInRangesClient.Delete(model.RoyaltyDisclosureDocumentRollInRangeId, {allowHardDelete: true, forceHardDelete: false})
                }),
            }
        });

        let toolbar: kendo.ui.GridToolbarItem[] = [
            {
                template: (
                    <button type="button"
                        ng-click={`${NameOfFullToControllerAs(nameof.full(this.grid.addRow))}()`}>
                        Add Row
                    </button>  
                )
            }
        ];

        let options: kendo.ui.GridOptions = {
            autoBind: false,
            editable: !this.readOnly,
            navigatable: true,
            toolbar: toolbar,
            columns: columns,
            remove: (e) => { this.$timeout(); },
            dataSource: datasource
        };

        this.grid.setOptions(options);
    }

    async LoadGrid(disclosureDocumentId: number)
    {
        this.disclosureDocumentId = disclosureDocumentId;

        await this.grid.dataSource.read()
        this.grid.refresh();
    }

    SetReadOnly(readOnly: boolean)
    {
        this.readOnly = readOnly;

        let options: kendo.ui.GridOptions = {
            editable: !this.readOnly
        };

        this.grid.setOptions(options);
    }

    HasChanges()
    {
        return this.grid?.dataSource?.hasChanges() ?? false;
    }

    CancelChanges()
    {
        this.grid.cancelChanges();
    }

    async ResetChanges()
    {
        this.CancelChanges();

        await this.grid.dataSource.read()
        this.grid.refresh();

        this.$timeout(() =>
        {
            this.formController.$setPristine();
        }, 1000);
    }

    async SaveChanges()
    {
        await this.grid.dataSource.sync()
        this.ResetChanges(); 
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.fddRollInRanges);

        const gridTemplate = (
            <div kendo-grid={`$ctrl.${nameof<fddRollInRanges>(o => o.grid)}`}></div>
        );

        const template = (
            <div ng-form={`$ctrl.${nameof<fddRollInRanges>(o => o.formController)}`}>
                {gridTemplate} 
            </div>
        );

        app.component(componentName, {
            bindings: {
                [nameof<fddRollInRanges>(o => o.onInit)]: "&",
            },
            template: template,
            controller: fddRollInRanges
        });
    }
}
