import * as _ from "underscore";
import { MollyDonationsShelterApiResources } from "Services/Resources/MollyDonationsShelterResources";
import { GeoService } from "Services/Utility/GeoService";

export class MollyDonationsShelterComponent implements ng.IController {
    shelterList: [];
    isEdit: boolean;
    shelterToggle: boolean;
    saveSuccessful: boolean;
    mollyShelterOptions: kendo.ui.DropDownListOptions;
    selectedShelterValue;
    state: string;
    city: string;
    country: string;
    kNotifierOptions: kendo.ui.NotificationOptions;
    kNotifier: kendo.ui.Notification;
    stateAutoComplete: kendo.ui.AutoComplete;
    static $inject = ["$scope", "MollyDonationsShelterApiResources", "geo"];

    constructor(
        private $scope: ng.IScope,
        private MollyDonationsShelterApiResources: MollyDonationsShelterApiResources,
        private geo: GeoService
    ) {
        this.isEdit = false;
        this.shelterToggle = false;
        this.saveSuccessful = false;
        this.country = "United States";
        this.kNotifierOptions = {
            position: {
                top: null,
                left: null,
                bottom: 20,
                right: 20,
            },
            stacking: "up",
            button: true,
            templates: [
                {
                    type: "ngTemplate",
                    template: $("#kNTemplate").html(),
                },
            ],
        };
    }

    async $onInit() {
        await this.MollyDonationsShelterApiResources.GetMollyShelterList().then(
            (res) => {
                this.shelterList = res.data;
                this.SetDropDownOptions();
            }
        );
    }

    async $postLink() {
        this.getStatesList();
    }

    OnShelterOptionSelect() {
        let shelter: any = this.shelterList.find(
            (x: any) =>
                x.MsMollyShelterId == this.selectedShelterValue.MsMollyShelterId
        );
        console.log("shelter", shelter);
        this.state = shelter.State;
        this.city = shelter.City;
        if (this.selectedShelterValue.IsActive) {
            this.shelterToggle = true;
        } else {
            this.shelterToggle = false;
        }
        this.isEdit = true;
    }

    getStatesList() {
        this.stateAutoComplete.setDataSource(
            new kendo.data.DataSource({
                data: this.geo
                    .GetByCountryName(this.country, true)
                    .map((c) => c.Abbreviation)
                    .sort(),
            })
        );
    }

    handleShelterToggle(newValue) {
        this.shelterToggle = newValue;
    }

    resetSheterOptions() {
        this.isEdit = false;
        this.selectedShelterValue = null;
        this.state = null;
        this.city = null;
    }

    SetDropDownOptions() {
        this.mollyShelterOptions = {
            valuePrimitive: false,
            dataValueField: "$id",
            dataTextField: "Name",
            autoBind: true,
            optionLabel: "Select Shelter Label",
            dataSource: new kendo.data.DataSource({
                data: this.shelterList,
            }),
        };
    }

    async handleShelterBtn() {
        let shelterLabelObj = {
            Name: this.selectedShelterValue.Name,
            City: this.city,
            State: this.state,
            Zip: "",
            IsActive: true,
        };
        if (!this.isEdit) {
            await this.MollyDonationsShelterApiResources.CreateNewShelterLabel(
                shelterLabelObj
            ).then(async (res) => {
                await this.MollyDonationsShelterApiResources.GetMollyShelterList().then(
                    (res) => {
                        this.shelterList = res.data;
                        this.SetDropDownOptions();
                        this.selectedShelterValue = null;
                        this.state = null;
                        this.city = null;
                        this.saveSuccessful = true;
                        this.kNotifier.show(
                            {
                                kValue: "Label Created Successfully",
                            },
                            "ngTemplate"
                        );
                    }
                );
            });
        } else {
            shelterLabelObj.IsActive = this.shelterToggle;
            await this.MollyDonationsShelterApiResources.UpdateShelterLabel(
                shelterLabelObj,
                this.selectedShelterValue.MsMollyShelterId
            ).then(async (res) => {
                await this.MollyDonationsShelterApiResources.GetMollyShelterList().then(
                    (res) => {
                        this.shelterList = res.data;
                        this.SetDropDownOptions();
                        this.saveSuccessful = true;
                        this.kNotifier.show(
                            {
                                kValue: "Updated Label Successfully",
                            },
                            "ngTemplate"
                        );
                    }
                );
            });
        }
    }

    async deleteShelter() {
        await this.MollyDonationsShelterApiResources.DeleteShelterLabel(
            this.selectedShelterValue.MsMollyShelterId
        ).then(async (res) => {
            await this.MollyDonationsShelterApiResources.GetMollyShelterList().then(
                (res) => {
                    this.shelterList = res.data;
                    this.SetDropDownOptions();
                    this.selectedShelterValue = null;
                    this.state = null;
                    this.city = null;
                    this.isEdit = false;
                    this.saveSuccessful = true;
                    this.kNotifier.show(
                        {
                            kValue: "Deleted Label Successfully",
                        },
                        "ngTemplate"
                    );
                }
            );
        });
    }

    static BindComponent(app: ng.IModule) {
        app.component("mollyDonationsShelterComponent", {
            bindings: {},
            controller: MollyDonationsShelterComponent,
            templateUrl:
                "/Templates/MollyDonationsShelter/MollyDonationsShelter.html",
        });
    }
}
