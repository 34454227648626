import * as _ from "underscore"
import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import {
    ConceptClient, 
} from '@nbly/franchise-group-api-clients';

import {  VendorClient,Vendor } from '@nbly/engagement-services-clients'

export class VendorAdminConfigComponent implements ng.IController {
    franchiseId: number;
    conceptId: number;
    isLoading: boolean;
    franchiseWebLocationId: number;


    //Clients
    engagementClient: AxiosInstance;
    vendorClient: VendorClient;


    //DTOS
    vendorDtoList: Vendor[] = [];

    newVendorDto: Vendor

    franchiseGroupClient: AxiosInstance;
    conceptClient: ConceptClient;

    //Grid
    VendorConfigGrid: kendo.ui.Grid;
    concepts: any = [];

    //Popup
    vendorAdminPopup: kendo.ui.Window;

    kNotifierOptions: kendo.ui.NotificationOptions;
    kNotifier: kendo.ui.Notification;

    saveSuccessful = false;

    static $inject = [
        "$scope",
        "$document"
    ];

    constructor(
        private $scope: ng.IScope,
        private $document: ng.IDocumentService
    ) {

        this.engagementClient = FranForceAxiosClientBuilder.BuildEngagementServicesBaseClient();
        this.vendorClient = new VendorClient("", this.engagementClient);

        this.franchiseGroupClient = FranForceAxiosClientBuilder.BuildFranchiseGroupBaseClient();
        this.conceptClient = new ConceptClient("", this.franchiseGroupClient);

        this.kNotifierOptions = {
            position: {
                    top: null,
                    left: null,
                    bottom: 20,
                    right: 20
            },
            stacking: "up",
            button: true,
            templates: [{
                type: 'ngTemplate',
                template: $('#kNTemplate').html(),
            }]
        };
    }

    generateEmptyDto(){
         this.newVendorDto = {
            vendorId: 0,
            createdDateTime: new Date().toISOString(),
            modifiedDateTime:new Date().toISOString(),
            deletedDateTime: null,
            name: null,
            description: null,
            apiKey: null,
            enabled: false,
            enableCESCommunications: false,
            displayName: null,
            isC4Vendor: false,
            tagName: null,
            sendEmailsThroughEloqua: false,
            sendTextsThroughEloqua: false,
            isOpusBrand: false,
        } 
    }

    async $onInit() {
        //load the broadlyRulesSpecifications
        this.isLoading=true;
        await this.vendorClient.vendorAll().then((response) => {
            this.vendorDtoList = response.result;
            this.isLoading=false;
            this.InitGridOptions();

          
        })
    }

    $postLink()
    {
        this.InitWindow();
    }


    InitWindow()
    {
        let options: kendo.ui.WindowOptions = {
            modal: true,
            width: 1000,
            title: "Create New Vendor Rule",
            
        };
        this.vendorAdminPopup.setOptions(options);
    }


    createNewVendorRules(){
        this.generateEmptyDto();
        this.vendorAdminPopup.title("Create new Vendor Rule"); 
        this.vendorAdminPopup.open().center();
    }

    async saveVendorRules(){
        this.isLoading=true;
        if(this.newVendorDto.vendorId == 0){
            await this.vendorClient.vendorPOST(this.newVendorDto).then((response) => {
                this.saveSuccessful = true;
                this.isLoading=false;
                this.kNotifier.show({
                    kValue: 'Success'
                }, "ngTemplate");
            }).catch(({ err, response }) => {
                this.saveSuccessful = false;
                this.isLoading=false;
                this.kNotifier.show({
                    kValue: response.Message
                }, "ngTemplate");
            });

        }else{

           await  this.vendorClient.vendorPUT(this.newVendorDto).then((response) => {
                this.saveSuccessful = true;
                this.isLoading=false;
                this.kNotifier.show({
                    kValue: 'Success'
                }, "ngTemplate");
            }).catch(({ err, response }) => {
                this.isLoading=false;
                this.saveSuccessful = false;
                this.kNotifier.show({
                    kValue: response.Message
                }, "ngTemplate");
            });
            //Update
        }
        


    await this.vendorClient.vendorAll().then((response) => {
        this.vendorDtoList = response.result;
        this.VendorConfigGrid.dataSource.data(this.vendorDtoList);
        this.VendorConfigGrid.refresh();
        this.isLoading=false;
        this.vendorAdminPopup.close();
    })
         
    }


    InitGridOptions() {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof.full<Vendor>(o => o.vendorId),
                title: "VendorId",
                locked: true,
                width: 100,
            }, {
                field: nameof.full<Vendor>(o => o.name),
                title: "Name",
                width: 200,
            }, {
                field: nameof.full<Vendor>(o => o.description),
                title: "Description",
                width: 200,
            }, {
                field: nameof.full<Vendor>(o => o.apiKey),
                title: "apiKey",
                width: 200,
            }, {
                field: nameof.full<Vendor>(o => o.enabled),
                title: "Enabled",
                width: 200,
                template: '<input disabled type="checkbox" #= enabled ? "checked=checked" : "" #  ></input>'
            }, {
                field: nameof.full<Vendor>(o => o.enableCESCommunications),
                title: "enableCESCommunications",
                width: 200,
                template: '<input disabled type="checkbox" #= enableCESCommunications ? "checked=checked" : "" #  ></input>'
            },
            {
                field: nameof.full<Vendor>(o => o.displayName),
                width: 200,
                title: "displayName",

            }, {
                field: nameof.full<Vendor>(o => o.isC4Vendor),
                width: 200,
                title: "isC4Vendor",
                template: '<input disabled type="checkbox" #= isC4Vendor ? "checked=checked" : "" #  ></input>'
            },
            {
                field: nameof.full<Vendor>(o => o.tagName),
                width: 200,
                title: "tagName",
            },
            {
                field: nameof.full<Vendor>(o => o.sendEmailsThroughEloqua),
                width: 200,
                title: "sendEmailsThroughEloqua",
                template: '<input disabled type="checkbox" #= sendEmailsThroughEloqua ? "checked=checked" : "" #  ></input>'
            },
            {
                field: nameof.full<Vendor>(o => o.sendTextsThroughEloqua),
                title: "sendTextsThroughEloqua",
                width: 200,
                template: '<input disabled type="checkbox" #= sendTextsThroughEloqua ? "checked=checked" : "" #  ></input>'
            },
            {
                field: nameof.full<Vendor>(o => o.isOpusBrand),
                title: "isOpusBrand",
                width: 200,
                template: '<input disabled type="checkbox" #= isOpusBrand ? "checked=checked" : "" #  ></input>'
            },
            { command:  [{
                name: "edit",
                click: (e,f) => {
                    // prevent page scroll position change
                    e.preventDefault();
                    // e.target is the DOM element representing the button
                    var tr = $(e.target).closest("tr"); // get the current table row (tr)
                    // get the data bound to the current table row
                    var data = this.VendorConfigGrid.dataItem(tr);
                    this.newVendorDto = data as any;
                    this.vendorAdminPopup.title("Edit Vendor Rule"); 
                    this.vendorAdminPopup.open().center();
                    this.newVendorDto = data as any;
                    this.$scope.$digest();
                }
              }], width: 100  }

        ];

        let dataSource = new kendo.data.DataSource({
            serverSorting: false,
            serverFiltering: false,

            serverPaging: false,
            pageSize: 50,
            data: this.vendorDtoList,
            schema: {
                model: {
                    fields: {
                        vendorId: { type: "number", editable: false },
                        name: { type: "string", editable: false, nullable: false, defaultValue: null },
                        description: { type: "string", editable: false, nullable: true,  defaultValue: null },
                        apiKey: { type: "string", editable: false,  nullable: false, defaultValue: null },
                        enabled: { type: "boolean", editable: true },
                        enableCESCommunications: { type: "boolean", editable: true },
                        displayName: { type: "string", editable: false ,nullable:false,  defaultValue: null },
                        isC4Vendor: { type: "boolean", editable: true },
                        tagName: { type: "string", editable: false, nullable: true,  defaultValue: null },
                        sendEmailsThroughEloqua: { type: "boolean", editable: true },
                        sendTextsThroughEloqua: { type: "boolean", editable: true },
                        isOpusBrand: { type: "boolean", editable: true },

                    }
                }
            },

        });

        this.VendorConfigGrid.setOptions(<kendo.ui.GridOptions>{
            dataSource: dataSource,
            columns: columns,
            sortable: true,
/*             editable: "popup", */
            resizable: true,
            pageable: {
                numeric: false,
                pageSize: 50,
                pageSizes: [50, 75, 100, 250, 500, "all"],
                input: true
            },

            filterable: true,

        });
    }

    static BindComponent(app: ng.IModule) {
        app.component('vendorAdminConfigComponent', {
            bindings: {
                franchiseId: "<"
            },
            controller: VendorAdminConfigComponent,
            templateUrl: "/Templates/AdminConfig/VendorAdminConfig.html"
        });
    }
}


