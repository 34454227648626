import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";

import { MomentUtil } from "Utility/MomentUtil";
import * as _ from "underscore"
import * as moment from "moment"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";


export class ProcessManualSalesEntriesComponentController implements ng.IController 
{
    //---- Bindings ----
    isForActiveFranchise: boolean;
    franchiseId?: number;
    //------------------

    isLoading: boolean;

    reportWindow: kendo.ui.Window;
    unprocessedGrid: kendo.ui.Grid;

    frequencies: RpmEntities.RoyaltyPeriodFrequencyViewModel[];
    franchisors: CoreResources.IFranchisor[];
    isDynamicFees: boolean;

    static $inject = [
        'coreApiResources',
        '$q',
        '$log',
        '$timeout',
        'rpmUiApiResources',
        'odataHelper'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private rpmUiApiResources: RpmUiApiResources,
        private odataHelper: ODataHelperService
    )
    {
    }

    $onInit()
    {
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledMS').val() === 'True';
    }

    $postLink()
    {
        this.InitWindowOptions();
        this.InitUnprocessedGridOptions();
    }

    InitWindowOptions()
    {
        let options: kendo.ui.WindowOptions = {
            modal: true
        };

        this.reportWindow.setOptions(options);
    }

    InitUnprocessedGridOptions()
    {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: "Franchisor",
                title: "Franchisor"
            },
            {
                field: "Frequency",
                title: "Frequency",
            },
            {
                field: "DisplayDate",
                title: "Period End Date"
            },
            {
                field: "EntryIds",
                title: "Entries",
                template: `<span ng-bind='dataItem.EntryIds.length' kendo-tooltip k-content='dataItem.EntryIds.join(", ")'></span>`
            },
            {
                width: 180,
                template: `<button promise-btn ng-show='!dataItem.RoyaltyReportBatchId' class='pure-button' style='width: 100%; box-sizing: border-box;' ng-click='$ctrl.Process(dataItem)'>Process</button>
                    <div ng-show='dataItem.RoyaltyReportBatchId'>
                        <a ng-href='/BatchManagement\\#/bm/batchId/{{dataItem.RoyaltyReportBatchId}}' target='_blank'>View Report Batch</a> <br>
                        Status: <span ng-bind='dataItem.BatchStatus'></span> <img src='/Content/css/images/icons/loading.gif' ng-show='!dataItem.BatchStatus'>
                    <div>`
            }
        ];

        let dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) =>
                {
                    this.rpmUiApiResources.GetRoyaltyManualSalesEntriesUnprocessed()
                        .then((unprocessedResponse) =>
                        {
                            let unprocessedEntries = unprocessedResponse.data
                                .map(u =>
                                {
                                    return angular.extend(u, {
                                        UniqueId: u.FranchisorId + "~" + u.RoyaltyFrequencyID + "~" + u.PeriodEndDate
                                    })
                                });

                            for (let entry of unprocessedEntries) {
                                let uniqueMatches = _.filter(unprocessedEntries, u => u.UniqueId == entry.UniqueId);
                                entry.EntryIds = uniqueMatches.map(m => m.RoyaltyManualSalesEntryID);
                            }

                            unprocessedEntries = _.uniq(unprocessedEntries, (f) => f.UniqueId);
                            unprocessedEntries = unprocessedEntries
                                .map((u: RpmEntities.usp_RoyaltyManualSalesEntry_GetUnprocessedEntries_Result) =>
                                {
                                    let zor = _.find(this.franchisors, f => f.FranchisorId == u.FranchisorId);
                                    let frequency = _.find(this.frequencies, f => f.RoyaltyPeriodFrequencyId == u.RoyaltyFrequencyID);
                                    u.PeriodEndDate = MomentUtil.EnsureUtcTime(u.PeriodEndDate);

                                    return angular.extend(u, {
                                        Franchisor: zor && zor.DisplayName,
                                        Frequency: frequency && frequency.Name,
                                        DisplayDate: moment.utc(u.PeriodEndDate).format("MM/DD/Y")
                                    });
                                });

                            options.success(unprocessedEntries);
                        })
                        .catch((err) => { options.error(err); });
                }
            },
            sort: [
                { field: "PeriodEndDate", dir: "desc" },
                { field: "Franchisor", dir: "asc" },
            ]
        });

        let options: kendo.ui.GridOptions = {
            autoBind: false,
            columns: columns,
            dataSource: dataSource,
            sortable: true,
            filterable: true
        };

        this.unprocessedGrid.setOptions(options);
    }

    LoadData()
    {
        return this.$q.all([
            this.rpmUiApiResources.GetAllRoyaltyPeriodFrequencies().then((frequenciesResponse) =>
            {
                this.frequencies = frequenciesResponse.data;
            }),
            this.coreApiResources.FranchisorApi.query({}).$promise.then((franchisors) =>
            {
                this.franchisors = franchisors;
            })
        ])
            .then(() =>
            {
                return this.$q.when(this.unprocessedGrid.dataSource.read()).then(() =>
                {
                    this.unprocessedGrid.refresh();
                });
            });
    }

    OpenWindow()
    {
        this.isLoading = true;
        this.reportWindow.open();
        this.$timeout(() =>
        {
            this.reportWindow.center();
        })

        return this.LoadData()
            .then(() =>
            {
                this.reportWindow.center();
            })
            .finally(() => { this.isLoading = false; });
    }

    Process(dataItem: RpmEntities.usp_RoyaltyManualSalesEntry_GetUnprocessedEntries_Result)
    {
        return this.coreApiResources.FranchisorApi.get({ id: dataItem.FranchisorId, $select: nameof<CoreEntities.Franchisor>(o => o.ConceptId) }).$promise
            .then((franchisor) =>
            {
                let input: RpmEntities.usp_RPM_SelectAvailableSyncInvoices_ManualSalesEntry_Input = {
                    FranchisorId: dataItem.FranchisorId,
                    FrequencyId: dataItem.RoyaltyFrequencyID,
                    PeriodEndDate: dataItem.PeriodEndDate,
                    FranchiseID: this.isForActiveFranchise ? null : this.franchiseId,
                    UserName: null,
                    FranchiseStatus: this.isForActiveFranchise ? "Active" : "Inactive"
                }
                return this.rpmUiApiResources.SelectRoyaltyManualSalesEntryAvailableSyncInvoices(input)
                    .then((createdBatchResponse) =>
                    {
                        (<any>dataItem).RoyaltyReportBatchId = createdBatchResponse.data.RoyaltyReportBatchId;

                        if (this.isDynamicFees) {
                            return this.rpmUiApiResources.CreateRoyaltyReportBatchesFromSyncInvoicesDynamic({
                                ConceptId: franchisor.ConceptId,
                                FranchisorId: dataItem.FranchisorId,
                                PeriodEndDate: dataItem.PeriodEndDate,
                                ProcessAllNew: true,
                                RoyaltyBatchID: createdBatchResponse.data.RoyaltyReportBatchId,
                                FranchiseStatus: this.isForActiveFranchise ? "Active" : "Inactive",
                                FranchiseId: this.isForActiveFranchise ? null : this.franchiseId,
                            })
                        }
                        else {
                            return this.rpmUiApiResources.CreateRoyaltyReportBatchesFromSyncInvoices({
                                ConceptId: franchisor.ConceptId,
                                FranchisorId: dataItem.FranchisorId,
                                PeriodEndDate: dataItem.PeriodEndDate,
                                ProcessAllNew: true,
                                RoyaltyBatchID: createdBatchResponse.data.RoyaltyReportBatchId,
                                FranchiseStatus: this.isForActiveFranchise ? "Active" : "Inactive",
                                FranchiseId: this.isForActiveFranchise ? null : this.franchiseId,
                            })
                        }
                    })
            })
            .then(() =>
            {
                return this.rpmUiApiResources.GetRoyaltyReportBatchById((<any>dataItem).RoyaltyReportBatchId);
            })
            .then((batchResponse) =>
            {
                return this.rpmUiApiResources.GetRoyaltyReportBatchStatusById(batchResponse.data.RoyaltyReportBatchStatusID);
            })
            .then((batchStatusResponse) =>
            {
                (<any>dataItem).BatchStatus = batchStatusResponse.data.Name
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('processManualSalesEntries', {
            bindings: {
                [nameof<ProcessManualSalesEntriesComponentController>(o => o.isForActiveFranchise)]: "<",
                [nameof<ProcessManualSalesEntriesComponentController>(o => o.franchiseId)]: "<"
            },
            controller: ProcessManualSalesEntriesComponentController,
            templateUrl: "/Templates/RPM/ProcessManualSalesEntries.html"
        });
    }
}


