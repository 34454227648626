import * as elements from 'typed-html';
import { ExpressionBinding } from "Utility/AngularUtil";
import { StringProperties } from "Types/StringProperties";
import
{
    fddRoyaltiesRatePlanMinimumsOnInit,
    fddRoyaltiesRatePlanMinimums,
    fddRoyaltiesRatePlanMinimumsTerritoryPopulations,
    fddRoyaltiesRatePlanMinimumsTerritoryPopulationsOnInit,
    fddRoyaltiesRollInMinimumsOnInit,
    fddRoyaltiesRollInMinimums,

    fddRoyaltiesRatePlanMinimumsOnFindScaleChange
} from 'Directives/RPM/FddManagement';
import { IFddController } from 'Directives/RPM/FddManagement/IFddController';
import { fddRoyaltiesRatePlanSelectorOnSelect } from 'Directives/RPM/FddManagement/fddRoyalties/fddRoyaltiesRatePlanSelector';

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            fddRoyaltyMinimums: Partial<StringProperties<fddRoyaltyMinimums>>;
        }
    }
}

export type fddRoyaltyMinimumsOnInit = { self: fddRoyaltyMinimums };

export class fddRoyaltyMinimums implements ng.IController, IFddController
{
    fddId: number;
    readOnly: boolean;
    init: ExpressionBinding<fddRoyaltyMinimumsOnInit>;
    
    private showGrids: boolean;
    private hasTerritoryFindScaleSelected: boolean = false;
    private ratePlanMinimumsController: fddRoyaltiesRatePlanMinimums;
    private minimumTerritoriesController: fddRoyaltiesRatePlanMinimumsTerritoryPopulations;
    private rollInMinimumsController: fddRoyaltiesRollInMinimums;

    static $inject = [

    ];

    constructor(

    )
    {
        this.showGrids = false;
    }

    $onInit()
    {
    }

    $postLink()
    {
        if (this.init)
        {
            this.init({ self: this });
        }
    }

    OnRatePlanSelect(ratePlanItem: fddRoyaltiesRatePlanSelectorOnSelect["entity"])
    {
        this.showGrids = true;
        this.ratePlanMinimumsController?.LoadGrid(ratePlanItem.DisclosureDocumentRatePlanId);
        this.minimumTerritoriesController?.LoadSelector(ratePlanItem.DisclosureDocumentRatePlanId);
        this.rollInMinimumsController?.LoadGrid(this.fddId, ratePlanItem.DisclosureDocumentRatePlanId);
    }

    async InitRatePlanMinimumsController(controller: fddRoyaltiesRatePlanMinimumsOnInit["self"])
    {
        this.ratePlanMinimumsController = controller;
    }

    async InitMinimumsTerritoryController(controller: fddRoyaltiesRatePlanMinimumsTerritoryPopulationsOnInit["self"])
    {
        this.minimumTerritoriesController = controller;
    }

    async InitRollInMinimumsController(controller: fddRoyaltiesRollInMinimumsOnInit["self"])
    {
        this.rollInMinimumsController = controller;
    }

    async LoadLookupData()
    {
        await this.rollInMinimumsController.LoadLookupData();
    }

    private OnFindScaleChange(ratePlans: fddRoyaltiesRatePlanMinimumsOnFindScaleChange["ratePlans"])
    {
        this.hasTerritoryFindScaleSelected = ratePlans.length > 0;
    }

    CancelChanges()
    {
        this.ratePlanMinimumsController?.CancelChanges();
        this.minimumTerritoriesController?.CancelChanges();
        this.rollInMinimumsController?.CancelChanges();
    }

    HasChanges()
    {
        return (
            this.ratePlanMinimumsController?.HasChanges() ||
            this.minimumTerritoriesController?.HasChanges() ||
            this.rollInMinimumsController?.HasChanges()
        );
    }

    async ResetChanges()
    {
        await this.ratePlanMinimumsController?.ResetChanges();
        await this.minimumTerritoriesController?.ResetChanges();
        await this.rollInMinimumsController?.ResetChanges();
    }

    async SaveChanges()
    {
        await this.ratePlanMinimumsController?.SaveChanges();
        await this.minimumTerritoriesController?.SaveChanges();
        await this.rollInMinimumsController?.SaveChanges();
    }

    static BindComponent(app: ng.IModule)
    {
        let ratePlanSelector = (
            <fddRoyaltiesRatePlanSelector
                disclosureDocumentId={`$ctrl.${nameof<fddRoyaltyMinimums>(o => o.fddId)}`}
                onSelect={`$ctrl.${nameof<fddRoyaltyMinimums>(o => o.OnRatePlanSelect)}(${nameof<fddRoyaltiesRatePlanSelectorOnSelect>(o => o.entity)})`}>
            </fddRoyaltiesRatePlanSelector>
        );

        let minimumsTemplate = (
            <div ng-show={`$ctrl.${nameof<fddRoyaltyMinimums>(o => o.showGrids)}`}>
                <h3>Rate Plan Minimums</h3>
                <fddRoyaltiesRatePlanMinimums
                    onInit={`$ctrl.${nameof<fddRoyaltyMinimums>(o => o.InitRatePlanMinimumsController)}(${nameof<fddRoyaltiesRatePlanMinimumsOnInit>(o => o.self)})`}
                    onFindScaleChange={`$ctrl.${nameof<fddRoyaltyMinimums>(o => o.OnFindScaleChange)}(${nameof<fddRoyaltiesRatePlanMinimumsOnFindScaleChange>(o => o.ratePlans)})`}
                    >
                </fddRoyaltiesRatePlanMinimums>
            </div>
        );

        let territoryBasedMinimums = (
            <div ng-show={`$ctrl.${nameof<fddRoyaltyMinimums>(o => o.showGrids)} && ` +
                `$ctrl.${nameof.full<fddRoyaltyMinimums>(o => o.ratePlanMinimumsController)} && ` +
                `$ctrl.${nameof.full<fddRoyaltyMinimums>(o => o.hasTerritoryFindScaleSelected)}`}>
                <h3>Territory Population Minimums</h3>
                <fddRoyaltiesRatePlanMinimumsTerritoryPopulations
                    onInit={`$ctrl.${nameof<fddRoyaltyMinimums>(o => o.InitMinimumsTerritoryController)}(${nameof<fddRoyaltiesRatePlanMinimumsTerritoryPopulationsOnInit>(o => o.self)})`}
                >
                </fddRoyaltiesRatePlanMinimumsTerritoryPopulations>
            </div>
        );

        let rollInMinimums = (
            <div ng-show={`$ctrl.${nameof<fddRoyaltyMinimums>(o => o.showGrids)}`}>
                <h3>Roll In Minimums</h3>
                <fddRoyaltiesRollInMinimums
                    onInit={`$ctrl.${nameof<fddRoyaltyMinimums>(o => o.InitRollInMinimumsController)}(${nameof<fddRoyaltiesRollInMinimumsOnInit>(o => o.self)})`}
                >
                </fddRoyaltiesRollInMinimums>
            </div>
        );

        let template = [
            ratePlanSelector,
            minimumsTemplate,
            territoryBasedMinimums,
            rollInMinimums
        ].join(" ");

        const componentName = nameof<JSX.IntrinsicElements>(o => o.fddRoyaltyMinimums);

        app.component(componentName, {
            bindings: {
                [nameof<fddRoyaltyMinimums>(o => o.fddId)]: "<",
                [nameof<fddRoyaltyMinimums>(o => o.init)]: "&?",
            },
            template: template,
            controller: fddRoyaltyMinimums
        });
    }
}
