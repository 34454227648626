import { StringProperties } from "../../Types/StringProperties";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            messageInfoModalPopup: Partial<StringProperties<MessageInfoModalComponentController>>;
        }
    }
}

export class MessageInfoModalComponentController implements ng.IController {

    //---- Bindings ----
    title: string;
    message: string;
    visible: boolean;
    close: () => void;
    //------------------

    messageInfoModalPopup: kendo.ui.Window;
    messageInfoModalPopupOptions: kendo.ui.WindowOptions;
    isLoading: boolean;


    static $inject = [
        "$q",
        "$timeout",
    ];

    constructor(
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService,
    ) {
        
    }

    $onInit() {
        this.messageInfoModalPopupOptions = {
            close: () => { this.close() },
        }
    }

    $postLink() {
        
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.visible)] && this.messageInfoModalPopup) {
            if (this.visible) {
                this.messageInfoModalPopup.open();
                this.$timeout(() => {
                    this.messageInfoModalPopup.center();
                }, 0)
            }
            else {
                this.messageInfoModalPopup.close();
            }
        }

        if (onChanges[nameof(this.title)] && this.messageInfoModalPopup) {
            if (this.title && this.title != '') {
                this.messageInfoModalPopup.title(this.title);
            }
        }
    }

    static BindComponent(app: ng.IModule) {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.messageInfoModalPopup);

        app.component(componentName, {
            bindings: {
                [nameof<MessageInfoModalComponentController>(o => o.title)]: "<",
                [nameof<MessageInfoModalComponentController>(o => o.message)]: "<",
                [nameof<MessageInfoModalComponentController>(o => o.visible)]: "<",
                [nameof<MessageInfoModalComponentController>(o => o.close)]: "&",
            },
            controller: MessageInfoModalComponentController,
            templateUrl: `/Templates/Common/MessageInfoModalComponent.html`
        });
    }
}