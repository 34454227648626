import { RmsStateService } from "Services/State/RmsState";
import * as moment from "moment"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";


export class CmWeeksInBusinessCalculatorComponentController implements ng.IController
{
    conceptId: number;
    canChangeDates: boolean
    contractSignedDate: string | Date;
    royaltyBeginDate: string | Date;
    onInit: (params: { self: CmWeeksInBusinessCalculatorComponentController }) => void;
    onCalc: (params: {
        weeksInBusiness: number,
        daysInBusiness: number,
        duration: moment.Duration,
        pivotField: string
    }) => void;

    pickedDate: Date;
    window: kendo.ui.Window;
    contractSignedDatePicker: kendo.ui.DatePicker;
    royaltyBeginDatePicker: kendo.ui.DatePicker;
    pickedDatePicker: kendo.ui.DatePicker;
    royaltyContract: RpmEntities.RoyaltyContractViewModel; 

    static $inject = [
        "rmsState"
    ]

    constructor(
        private rmsState: RmsStateService
    )
    {
        this.canChangeDates = false;
    }

    $onInit()
    {
        if (this.onInit) {
            this.onInit({ self: this });
        }
    }

    $postLink()
    {
        this.window.setOptions(<kendo.ui.WindowOptions>{
            title: "Weeks in Business Calculator",
            actions: [
                "Pin",
                "Close"
            ],
            close: (e) =>
            {
                this.pickedDate = null;
            }
        });

        this.SetReadonly();
    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
        if (onChanges[nameof(this.canChangeDates)])
        {
            this.SetReadonly();
        }

        if (onChanges[nameof(this.contractSignedDate)] && typeof this.contractSignedDate === "string")
        {
            this.contractSignedDate = new Date(this.contractSignedDate)
        }

        if (onChanges[nameof(this.royaltyBeginDate)] && typeof this.royaltyBeginDate === "string")
        {
            this.royaltyBeginDate = new Date(this.royaltyBeginDate)
        }
    }

    SetReadonly()
    {
        if (this.contractSignedDatePicker)
            this.contractSignedDatePicker.enable(this.canChangeDates);
        if (this.royaltyBeginDatePicker)
            this.royaltyBeginDatePicker.enable(this.canChangeDates);
    }

    Show(button: HTMLElement)
    {
        let position = $(button).position();
        this.window.setOptions(<kendo.ui.WindowOptions>{
            position: {
                left: position.left,
                top: position.top
            }
        })

        this.window.open();
    }
    
    static BindComponent(app: ng.IModule)
    {
        app
            .component("cmWeeksInBusinessCalculator", {
                bindings: {
                    [nameof<CmWeeksInBusinessCalculatorComponentController>(o => o.conceptId)]: "<",
                    [nameof<CmWeeksInBusinessCalculatorComponentController>(o => o.canChangeDates)]: "<",
                    [nameof<CmWeeksInBusinessCalculatorComponentController>(o => o.contractSignedDate)]: "<",
                    [nameof<CmWeeksInBusinessCalculatorComponentController>(o => o.royaltyBeginDate)]: "<",
                    [nameof<CmWeeksInBusinessCalculatorComponentController>(o => o.onInit)]: "&?",
                    [nameof<CmWeeksInBusinessCalculatorComponentController>(o => o.onCalc)]: "&?"
                },
                templateUrl: "/Templates/ContractManagement/cmWeeksInBusinessCalculator.html",
                controller: CmWeeksInBusinessCalculatorComponentController
            });
    }
}

