import {
    RoyaltyApprovalNotificationTypeClient,
    RoyaltyApprovalNotificationTypeVm,
    RoyaltyOrchestrationsClientResponse
} from '@nbly/royalty-orchestrations-clients';
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";

export class RmsApprovalNotificationTypeSelectorComponentController implements ng.IController {
    royaltyApprovalNotificationTypeId: number;
    readOnly: boolean;
    onSelect: (params: { notificationType: RoyaltyApprovalNotificationTypeVm }) => void;

    dropDown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    royaltyOrchestrationsClient: AxiosInstance;
    royaltyApprovalNotificationTypeClient: RoyaltyApprovalNotificationTypeClient;

    static $inject = [

    ]

    constructor(

    ) {
        this.royaltyOrchestrationsClient = FranForceAxiosClientBuilder.BuildRoyaltyOrchestrationsBaseClient();
        this.royaltyApprovalNotificationTypeClient = new RoyaltyApprovalNotificationTypeClient("", this.royaltyOrchestrationsClient);
    }

    $postLink() {

    }

    $onInit() {
        this.options = {
            autoWidth: true,
            valuePrimitive: true,
            filter: "contains",
            optionLabel: "Select...",
            optionLabelTemplate: "Select...",
            dataTextField: nameof<RoyaltyApprovalNotificationTypeVm>(o => o.name),
            dataValueField: nameof<RoyaltyApprovalNotificationTypeVm>(o => o.royaltyApprovalNotificationTypeId),
            //enable: !this.readOnly,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        this.royaltyApprovalNotificationTypeClient.royaltyApprovalNotificationTypes()
                            .then((response) => {
                                options.success(response.result);
                            })
                            .catch((err) => {
                                options.error(err);
                            });
                    }
                }
            }),
            select: (e) => {
                if (this.onSelect) {
                    this.onSelect({ notificationType: e.dataItem });
                }
            }
        };
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.readonly(this.readOnly);
            this.dropDown.refresh();
        }
    }

    static BindComponent(app: ng.IModule) {
        app
            .component("rmsApprovalNotificationTypeSelector", {
                bindings: {
                    [nameof<RmsApprovalNotificationTypeSelectorComponentController>(o => o.royaltyApprovalNotificationTypeId)]: "<",
                    [nameof<RmsApprovalNotificationTypeSelectorComponentController>(o => o.readOnly)]: "<",
                    [nameof<RmsApprovalNotificationTypeSelectorComponentController>(o => o.onSelect)]: "&?",
                },
                template: `<select kendo-drop-down-list="$ctrl.${nameof<RmsApprovalNotificationTypeSelectorComponentController>(o => o.dropDown)}"
                            ng-model="$ctrl.${nameof<RmsApprovalNotificationTypeSelectorComponentController>(o => o.royaltyApprovalNotificationTypeId)}"
                            k-options="$ctrl.${nameof<RmsApprovalNotificationTypeSelectorComponentController>(o => o.options)}">
                </select>`,
                controller: RmsApprovalNotificationTypeSelectorComponentController
            });
    }
}

