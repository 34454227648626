import { AngularUtil } from "Utility/AngularUtil";

import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import * as _ from "underscore";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class EditFranchiseProfileFeesController implements ng.IController 
{
    franchiseId: number;

    newFeeType: CoreResources.IFranchiseProfileFeeType;
    franchiseFeeTypes: CoreResources.IFranchiseProfileFeeType[];
    franchisePostalCodes: CoreResources.IFranchisePostalCode[];

    feeOptions: kendo.ui.NumericTextBoxOptions;
    postalCodeDropDown: kendo.ui.DropDownList;
    feeTypeDropDown: kendo.ui.DropDownList;

    isLoadingFee: boolean;
    isNew: boolean;
    postalCodeFee: CoreResources.IFranchisePostalCodeProfileFee;

    postalCodeFeeGrid: kendo.ui.Grid;
    postalCodeFeeWindow: kendo.ui.Window;

    static $inject = [
        'coreApiResources',
        '$q',
        '$log',
        '$timeout',
        'odataHelper'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private odataHelper: ODataHelperService
    )
    {
    }

    $onInit()
    {
        this.feeOptions = {
            format: "c",
            decimals: 2,
            step: 1,
            min: 0,
            placeholder: "Fee"
        };

        this.RefreshNewFeeType();
    }

    $postLink()
    {
        this.InitWindowOptions();
        this.InitDropDownOptions();
        this.InitPostalCodeFeesGrid();
    }

    InitPostalCodeFeesGrid()
    {
        let dataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: "CompositeId",
                    fields: {
                        CompositeId: { type: "string" },
                        AfterHoursFee: { type: "number" },
                        AfterHoursIsWaived: { type: "boolean" },
                        Fee: { type: "number" },
                        FranchiseId: { type: "number" },
                        "FranchisePostalCode.PostalCode": { type: "string", editable: false },
                        "FranchiseProfileFeeType.Name": { type: "string", editable: false },
                        IsWaived: { type: "boolean" }
                    }
                }
            },
            transport: {
                read: (options) =>
                {
                    this.coreApiResources.FranchisePostalCodeProfileFeeApi.query(
                        {
                            $filter: "FranchisePostalCode/FranchiseId eq " + this.franchiseId + " and FranchiseProfileFeeType/FranchiseId eq " + this.franchiseId,
                            $expand: `${nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.FranchiseProfileFeeType)}($select=${nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.FranchiseProfileFeeType.Name)},${nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.FranchiseProfileFeeType.ConceptFeeTypeId)}),` +
                                `${nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.FranchisePostalCode)}($select=${nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.FranchisePostalCode.PostalCode)})`
                        }).$promise
                        .then((franchiseFees) =>
                        {
                            for (let postalCodeFee of franchiseFees) {
                                this.AddCompositeKeyToPostalCodeFee(postalCodeFee);
                            }
                            options.success(franchiseFees)
                        })
                        .catch((err) =>
                        {
                            options.error(err)
                        });
                }
            },
            pageSize: 20
        });

        let columns: kendo.ui.GridColumn[] = [
            {
                field: "CompositeId",
                title: "CompositeId",
                hidden: true
            }, {
                field: nameof.full<CoreResources.IFranchisePostalCodeProfileFee>(o => o.FranchiseProfileFeeType.Name),
                title: "Fee Name"
            }, {
                field: nameof.full<CoreResources.IFranchisePostalCodeProfileFee>(o => o.FranchisePostalCode.PostalCode),
                title: "Postal Code"
            }, {
                field: nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.Fee),
                title: "Fee",
                format: "{0:c}"
            }, {
                field: nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.IsWaived),
                title: "Waived",
                template: `<span ng-bind="dataItem.${nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.IsWaived)} ? 'Yes' : 'No'" 
                        ng-class="dataItem.${nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.IsWaived)} ? 'icon-success' : 'icon-error'"></span>`
            }, {
                field: nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.AfterHoursFee),
                title: "After Hours Fee",
                //format: "{0:c}",
                template: `<span ng-class="dataItem.${nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.FranchiseProfileFeeType.ConceptFeeTypeId)} ? 'concept-parent' : ''">
                                <span ng-bind="dataItem.${nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.AfterHoursFee)} | currency"></span>
                            </span>`
            }, {
                field: nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.AfterHoursIsWaived),
                title: "After Hours Waived",
                template: `<span ng-class="dataItem.${nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.FranchiseProfileFeeType.ConceptFeeTypeId)} ? 'concept-parent' : ''">
                                <span ng-bind="dataItem.${nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.AfterHoursIsWaived)} ? 'Yes' : 'No'" 
                                ng-class="dataItem.${nameof<CoreResources.IFranchisePostalCodeProfileFee>(o => o.AfterHoursIsWaived)} ? 'icon-success' : 'icon-error'"></span>
                            </span>`
            }, {
                template: `<button class='edit-btn pure-button' ` +
                    `ng-click='$ctrl.${nameof(this.OpenFeeWindow)}(dataItem)'>Edit</button>`,
                width: 100
            }, {
                template: `<button class='delete-btn pure-button button-error' ` +
                    `ng-click='$ctrl.${nameof(this.DeletePostalCodeFeeOverride)}(dataItem)'>X</button>`,
                width: 60
            }];

        let options = <kendo.ui.GridOptions>{
            dataSource: dataSource,
            columns: columns,
            editable: "inline",
            sortable: true,
            pageable: true,
            filterable: true,
            toolbar: <kendo.ui.GridToolbarItem[]> [{
                template: `<button class='pure-button' ng-click='$ctrl.${nameof(this.OpenFeeWindow)}({ FranchiseId: $ctrl.${nameof(this.franchiseId)} })'>Create New...</button>`
            }],
        }
        this.postalCodeFeeGrid.setOptions(options);
    }

    InitDropDownOptions()
    {
        this.postalCodeDropDown.setOptions(<kendo.ui.DropDownListOptions>{
            optionLabel: "Postal Code...",
            filter: "contains",
            valuePrimitive: true,
            dataTextField: nameof<CoreResources.IFranchisePostalCode>(o => o.PostalCode),
            dataValueField: nameof<CoreResources.IFranchisePostalCode>(o => o.FranchisePostalCodeId),
            dataSource: new kendo.data.DataSource({ data: [] }),
            select: (e: kendo.ui.DropDownListSelectEvent) =>
            {
                this.OnFeeTypeOrPostalCodeSelect();
            }
        })

        this.feeTypeDropDown.setOptions(<kendo.ui.DropDownListOptions>{
            optionLabel: "Fee Type...",
            filter: "contains",
            valuePrimitive: true,
            dataTextField: nameof<CoreResources.IFranchiseProfileFeeType>(o => o.Name),
            dataValueField: nameof<CoreResources.IFranchiseProfileFeeType>(o => o.FranchiseProfileFeeTypeId),
            dataSource: new kendo.data.DataSource({ data: [] }),
            select: (e: kendo.ui.DropDownListSelectEvent) =>
            {
                this.OnFeeTypeOrPostalCodeSelect();
            }
        });
        
        this.RefreshFeeTypes();
        this.RefreshPostalCodes();
    }

    InitWindowOptions()
    {
        this.postalCodeFeeWindow.setOptions(<kendo.ui.WindowOptions>{
            modal: true,
            open: (e) =>
            {
                this.OnFeeTypeOrPostalCodeSelect();
            }
        });
    }

    RefreshNewFeeType()
    {
        this.newFeeType = new this.coreApiResources.FranchiseProfileFeeTypeApi();
        this.newFeeType.DefaultIsWaived = true;
        this.newFeeType.DefaultAfterHoursIsWaived = true;
        this.newFeeType.FranchiseId = this.franchiseId;
        this.newFeeType.ConceptFeeTypeId = null;
    }

    CreateFeeType(feeType: CoreResources.IFranchiseProfileFeeType): ng.IPromise<any>
    {
        if (feeType.DefaultFee < 0 || feeType.DefaultAfterHoursFee < 0 || !feeType.Name) {
            let reason = "Name and Fee amounts are required. Fees must be 0 or greater.";
            alert(reason);
            return this.$q.reject(reason);
        }

        return feeType.$save().then(
            () =>
            {
                this.franchiseFeeTypes.push(feeType);
                this.RefreshNewFeeType();
            },
            AngularUtil.GetErrorPromiseLogCallback(this.$log, this.$q));
    }

    SaveFeeType(feeType: CoreResources.IFranchiseProfileFeeType): ng.IPromise<any>
    {
        if (feeType.DefaultFee < 0 || feeType.DefaultAfterHoursFee < 0 || !feeType.Name) {
            let reason = "Name and Fee amounts are required. Fees must be 0 or greater.";
            alert(reason);
            return this.$q.reject(reason);
        }

        return this.coreApiResources.FranchiseProfileFeeTypeApi.get({ id: feeType.FranchiseProfileFeeTypeId }).$promise.then(
            (apiFeeType: CoreResources.IFranchiseProfileFeeType) =>
            {
                apiFeeType.DefaultFee = feeType.DefaultFee;
                apiFeeType.DefaultIsWaived = feeType.DefaultIsWaived;
                apiFeeType.DefaultAfterHoursFee = feeType.DefaultAfterHoursFee;
                apiFeeType.DefaultAfterHoursIsWaived = feeType.DefaultAfterHoursIsWaived;
                apiFeeType.Name = feeType.Name;
                return apiFeeType.$update({ id: feeType.FranchiseProfileFeeTypeId });
            },
            AngularUtil.GetErrorPromiseLogCallback(this.$log, this.$q));
    }

    DeleteFeeType(feeType: CoreResources.IFranchiseProfileFeeType): ng.IPromise<any>
    {
        if (confirm("This will also delete all of the overriden fees for this fee type. Are you sure you want to delete fee type of '" + feeType.Name + "'?")) {
            return this.coreApiResources.FranchiseProfileFeeTypeApi.delete({ id: feeType.FranchiseProfileFeeTypeId, allowHardDelete: true }).$promise.then(
                () =>
                {
                    this.$q.all([
                        this.RefreshFeeTypes(),
                        this.RefreshPostalCodes()
                    ]);
                });
        }
    }

    OnFeeTypeOrPostalCodeSelect()
    {
        this.$timeout(() =>
        {
            if (this.postalCodeFee && this.postalCodeFee.FranchisePostalCodeId && this.postalCodeFee.FranchiseProfileFeeTypeId) {
                this.isLoadingFee = true;
                let fee = this.AddCompositeKeyToPostalCodeFee(this.postalCodeFee);
                this.coreApiResources.FranchisePostalCodeProfileFeeApi.get({ id: fee.CompositeId }).$promise
                    .then((foundFee) =>
                    {
                        this.isNew = false;
                        this.postalCodeFee = foundFee;
                    })
                    .catch((err) =>
                    {
                        this.isNew = true;
                        let matchingType = _.find(this.franchiseFeeTypes, type => type.FranchiseProfileFeeTypeId == this.postalCodeFee.FranchiseProfileFeeTypeId);
                        this.postalCodeFee.Fee = matchingType.DefaultFee;
                        this.postalCodeFee.IsWaived = matchingType.DefaultIsWaived
                        this.postalCodeFee.AfterHoursFee = matchingType.DefaultAfterHoursFee;
                        this.postalCodeFee.AfterHoursIsWaived = matchingType.DefaultAfterHoursIsWaived;
                    })
                    .finally(() =>
                    {
                        return this.$timeout(() =>
                        {
                            this.postalCodeFeeWindow.center();
                            this.isLoadingFee = false;
                        });
                    });
            }
        }, 0);
    }

    OpenFeeWindow(postalCodeFee: CoreResources.IFranchisePostalCodeProfileFee)
    {
        this.postalCodeFee = angular.copy(postalCodeFee);
        this.postalCodeFeeWindow.open();
        this.$timeout(() =>
        {
            this.postalCodeFeeWindow.center();
        }, 0);
    }

    SavePostalCodeFeeOverride()
    {
        if (!this.postalCodeFee.FranchiseProfileFeeTypeId) {
            alert("Fee Type is required to create an override");
            return;
        }
        if (!this.postalCodeFee.FranchisePostalCodeId) {
            alert("Postal Code is required to create an override");
            return;
        }
        if (this.postalCodeFee.Fee < 0) {
            alert("Fee is required, and must be a number 0 or greater");
            return;
        }
        if (this.postalCodeFee.AfterHoursFee < 0) {
            alert("After Hours Fee is required, and must be a number 0 or greater");
            return;
        }

        let fee = this.AddCompositeKeyToPostalCodeFee(this.postalCodeFee);

        return this.coreApiResources.FranchisePostalCodeProfileFeeApi.get({
            id: fee.CompositeId
        }).$promise
            .then((postalCodeFee) => {
                postalCodeFee.Fee = this.postalCodeFee.Fee;
                postalCodeFee.IsWaived = this.postalCodeFee.IsWaived;
                postalCodeFee.AfterHoursFee = this.postalCodeFee.AfterHoursFee;
                postalCodeFee.AfterHoursIsWaived = this.postalCodeFee.AfterHoursIsWaived;
                return postalCodeFee.$update({ id: fee.CompositeId });
            })
            .catch((err: ng.IHttpPromiseCallbackArg<any>) => {
                if (err.status == 404) {
                    fee.FranchisePostalCode = null;
                    fee.FranchiseProfileFeeType = null;
                    return this.coreApiResources.FranchisePostalCodeProfileFeeApi.save({}, fee).$promise;
                }

                return AngularUtil.GetErrorPromiseLogCallback(this.$log, this.$q)(err);
            })
            .then((postalCodeFee) => {
                return this.RefreshGrid()
                    .then(() =>
                    {
                        this.postalCodeFeeWindow.close();
                    });
            });
    }

    DeletePostalCodeFeeOverride(postalCodeFee: CoreResources.IFranchisePostalCodeProfileFee)
    {
        let fee = this.AddCompositeKeyToPostalCodeFee(postalCodeFee);

        if (confirm("Are you sure you want to delete '" + fee.FranchiseProfileFeeType.Name + "' for postal code '" + fee.FranchisePostalCode.PostalCode + "'?"))
        {
            return this.coreApiResources.FranchisePostalCodeProfileFeeApi.delete({ id: fee.CompositeId, allowHardDelete: true }).$promise
                .then(() => {
                    return this.RefreshGrid();
                })
                .catch(AngularUtil.GetErrorPromiseLogCallback(this.$log, this.$q));
        }
    }

    RefreshFeeTypes()
    {
        return this.coreApiResources.FranchiseProfileFeeTypeApi.query({ $filter: "FranchiseId eq " + this.franchiseId }).$promise
            .then((franchiseFeeTypes) => {
                this.franchiseFeeTypes = _.chain(franchiseFeeTypes)
                    .sortBy(fft => fft.Name)
                    .sortBy(fft => !fft.ConceptFeeTypeId)
                    .value();
                this.feeTypeDropDown.setDataSource(new kendo.data.DataSource({ data: this.franchiseFeeTypes }));
            })
            .catch(AngularUtil.GetErrorPromiseLogCallback(this.$log, this.$q));
    }

    RefreshPostalCodes()
    {
        return this.coreApiResources.FranchisePostalCodeApi.query({ $filter: "FranchiseId eq " + this.franchiseId }).$promise
            .then((franchisePostalCodes) => {
                this.franchisePostalCodes = franchisePostalCodes;
                this.postalCodeDropDown.setDataSource(new kendo.data.DataSource({ data: this.franchisePostalCodes }));
            })
            .catch(AngularUtil.GetErrorPromiseLogCallback(this.$log, this.$q));
    }

    RefreshGrid()
    {
        return this.$q.when(this.postalCodeFeeGrid.dataSource.read())
            .then(() =>
            {
                this.postalCodeFeeGrid.refresh();
            });
    }

    AddCompositeKeyToPostalCodeFee(postalCodeFee: CoreResources.IFranchisePostalCodeProfileFee & { CompositeId?: string })
    {
        postalCodeFee.CompositeId= this.odataHelper.ConvertCompositeKeyToString({
            FranchisePostalCodeId: postalCodeFee.FranchisePostalCodeId,
            FranchiseProfileFeeTypeId: postalCodeFee.FranchiseProfileFeeTypeId
        });
        return <CoreResources.IFranchisePostalCodeProfileFee & { CompositeId: string }>postalCodeFee;
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfileFees', {
            bindings: {
                franchiseId: "<"
            },
            controller: EditFranchiseProfileFeesController,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfileFees.html"
        });
    }
}


        