import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AxiosInstance } from "axios";
import { MOJOwnedSalesInvoiceClient, FranchiseOrchestrationClientResponse, MOJOwnedSalesInvoiceVm } from "@nbly/franchise-orchestration-clients"
import { FranForceAxiosClientBuilder } from "../../Clients/FranForceAxiosClientBuilder";
import { randomBytes } from "crypto";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";

export class CesRoyaltyReportInvoicesByPostalCodeController implements ng.IController {
    royaltyPeriodReportId: number;
    userInfo: IUserInfoViewModel;
    franchiseId: number = 0;
    userFranchiseIds: number[];
    canViewReport: boolean = true;
    isAdminRole: boolean = false;
    isPeriodReportReadOnlyRole: boolean = false;
    isPeriodReportAdminRole: boolean = false;
    static $inject = [
        "$window",
        "identityManager",
        "apiConfig",
        "$scope",
        'royaltyApiResources'
    ];

    franchiseOrchestrationClient: AxiosInstance;
    franchiseMOJOwnedSalesInvoiceClient: MOJOwnedSalesInvoiceClient
    constructor(
        private $window: ng.IWindowService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $scope: ng.IScope,
        private royaltyApiResources: RoyaltyApiResources
    ) {
        this.franchiseOrchestrationClient = FranForceAxiosClientBuilder.BuildFranchiseOrchestrationClient();
        this.franchiseMOJOwnedSalesInvoiceClient = new MOJOwnedSalesInvoiceClient("", this.franchiseOrchestrationClient);
    }


    InitRiskCustomersGrid = function () {

        const columns: kendo.ui.GridColumn[] = [
            {
                field: "invoiceNumber",
                title: "Invoice Number",
            },
            {
                field: "invoiceDate",
                title: "Invoice Date",
                template: `<p>{{ dataItem.invoiceDate | date: 'MM/dd/yyyy' }}</p>`
            },
            {
                field: "salesCategory",
                title: "Sales Category"
            },
            {
                field: "postalCode",
                title: "Postal Code"
            },
            {
                field: "amount",
                title: "Amount",
                template: "#= kendo.toString(amount, 'c') #"
            }
        ]

        const royaltyReportInvoicesByPostalCodeGridOptions: kendo.ui.GridOptions = {
            columns,
            toolbar: ["excel"],
            excel: {
                fileName: `MOJRoyaltyReportInvoicesPC-` +
                    `${randomBytes(10).toString("hex")}` +
                    `${new Date().getFullYear()}_` +
                    `${new Date().getMonth() + 1 < 10 ? "0" : ""}${new Date().getMonth() + 1}_` +
                    `${new Date().getDate() < 10 ? "0" : ""}${new Date().getDate()}.xlsx`,
                filterable: true,
                allPages: true
            },
            selectable: false,
            resizable: true,
            editable: false,
            scrollable: true,
            noRecords: true,
            sortable: true,
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [5, 10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: true
        }

        this.royaltyReportInvoicesByPostalCodeDataSource = new kendo.data.DataSource({
            pageSize: 10,
            serverPaging: false,
            transport: {
                read: async (options: kendo.data.DataSourceTransportReadOptions) => {
                    try {


                        if (!this.canViewReport) {
                            options.error({
                                status: 403,
                                message: "You do not have permission to view this report."
                            });
                        } else {
                            const royaltyperiodreport_id = this.royaltyPeriodReportId


                            console.log("Fetching invoice report...");
                            const MOJOwnedSalesInvoiceReportResult: FranchiseOrchestrationClientResponse<MOJOwnedSalesInvoiceVm[]> =
                                await this.franchiseMOJOwnedSalesInvoiceClient.invoiceReport(royaltyperiodreport_id);

                            const { result: MOJOwnedSalesInvoiceReportDtoResult } = MOJOwnedSalesInvoiceReportResult;

                            options.success(MOJOwnedSalesInvoiceReportDtoResult);
                        }

                    } catch (error) {
                        console.error("Error loading data:", error);
                        options.error(error);
                    } finally {
                        this.isLoading = false;
                        console.log("Data load complete.");
                    }
                }
            }
        });

        this.royaltyReportInvoicesByPostalCodeGrid.setOptions(royaltyReportInvoicesByPostalCodeGridOptions);
        this.royaltyReportInvoicesByPostalCodeGrid.setDataSource(this.royaltyReportInvoicesByPostalCodeDataSource);

    }

    async $postLink() {
        await this.SelectFranchiseID();
        console.log("Fetching royalty period report...");
        const royaltyPeriodReportResult = await this.royaltyApiResources.GetRoyaltyPeriodReport(this.royaltyPeriodReportId);

        if (royaltyPeriodReportResult && royaltyPeriodReportResult.data.length > 0) {
            this.franchiseId = royaltyPeriodReportResult.data[0].franchiseId;
            if (this.userFranchiseIds.includes(this.franchiseId) || this.isAdminRole || this.isPeriodReportReadOnlyRole || this.isPeriodReportAdminRole) {
                this.canViewReport = true;
            }
            else
                this.canViewReport = false;
        }
        this.InitRiskCustomersGrid();
    }

    isLoading = false;

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.royaltyReportInvoicesByPostalCodeDataSource)] && this.royaltyReportInvoicesByPostalCodeGrid) {
            this.royaltyReportInvoicesByPostalCodeGrid.setDataSource(this.royaltyReportInvoicesByPostalCodeDataSource);
        }
    }

    licenseNumbers: Array<string>

    async $onInit() {

        this.$scope.$on('refreshCustomerRiskData', (event, args) => {
            if (this.royaltyReportInvoicesByPostalCodeGrid && this.royaltyReportInvoicesByPostalCodeGrid.dataSource) {
                this.royaltyReportInvoicesByPostalCodeGrid.dataSource.read();
                this.royaltyReportInvoicesByPostalCodeGrid.refresh();
            }
        });
    }
    async SelectFranchiseID() {
        this.userInfo = (await this.identityManager.GetLoggedInUserInfo()).data;
        console.log("Fetching user franchise IDs...");
        if (this.userInfo && this.userInfo.Roles) {
            this.userFranchiseIds = this.userInfo.Claims
                .filter(c => c.Type === this.apiConfig.FranForceConstants.ClaimConstants.OwnedFranchiseIdClaimType)
                .map(c => parseInt(c.Value));
            this.isAdminRole = this.userInfo.Roles.some(r =>
                r.Name == this.apiConfig.FranForceConstants.RoleConstants.AdminRole);
            this.isPeriodReportReadOnlyRole = this.userInfo.Roles.some(r =>
                r.Name == this.apiConfig.FranForceConstants.RoleConstants.PeriodReportReadOnlyRole);
            this.isPeriodReportAdminRole = this.userInfo.Roles.some(r =>
                r.Name == this.apiConfig.FranForceConstants.RoleConstants.PeriodReportAdminRole);
        }
    }


    royaltyReportInvoicesByPostalCodeDataSource: kendo.data.DataSource;

    royaltyReportInvoicesByPostalCodeGrid: kendo.ui.Grid;

    static BindComponent(app: ng.IModule) {
        app.component('royaltyReportInvoicesByPostalCode', {
            bindings: {
                royaltyPeriodReportId: "<",
            },
            controller: CesRoyaltyReportInvoicesByPostalCodeController,
            templateUrl: "/Templates/Reports/CesRoyaltyReportInvoicesByPostalCode.html"
        });
    }
}
