import _ = require("underscore");
import moment = require("moment");
import * as elements from "typed-html";

import { KendoUtil, BindDropDownEditor } from "Utility/KendoUtil";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

import * as rmsClients from "Clients/Rms";
import { StringProperties } from "Types/StringProperties";
import { MonthEnabled } from "Directives/Common";
import { fddRoyaltiesRatePlanMinimumSelectorOnSelect, fddRoyaltiesRatePlanMinimumSelectorOnInit } from "Directives/RPM/FddManagement";
import { ExpressionBinding, AngularUtil } from "Utility/AngularUtil";
import { NameOfFullToControllerAs } from "Utility/Helpers";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            fddRoyaltiesRatePlanMinimumsTerritoryPopulations: Partial<StringProperties<fddRoyaltiesRatePlanMinimumsTerritoryPopulations>>;
        }
    }
}

type RoyaltyDisclosureDocumentRatePlanMinimumDropDownItem = Pick<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum, "RoyaltyDisclosureDocumentRatePlanMinimumId"> &
    { Display: string };

export interface RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem extends RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulation
{
}

export type fddRoyaltiesRatePlanMinimumsTerritoryPopulationsOnInit = { self: fddRoyaltiesRatePlanMinimumsTerritoryPopulations };

export const TerritoryPopulationFindScaleBasedOnId = 7;

export class fddRoyaltiesRatePlanMinimumsTerritoryPopulations implements ng.IController {

    readOnly: boolean;
    onInit: ExpressionBinding<fddRoyaltiesRatePlanMinimumsTerritoryPopulationsOnInit>;

    grid: kendo.ui.Grid;
    feeTypes: RpmEntities.usp_RoyaltyFeeTypeResult[];
    royaltyRatePlanMinimums: RoyaltyDisclosureDocumentRatePlanMinimumDropDownItem[];

    isLoading: boolean;
    private disclosureDocumentRatePlanId: number;
    private disclosureDocumentRatePlanMinimumId: number;
    private ratePlanMinimumSelector: fddRoyaltiesRatePlanMinimumSelectorOnInit["self"];

    private ratePlansClient = rmsClients.royaltyDisclosureDocumentRatePlanMinimumClient;
    private territoryClient = rmsClients.royaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationClient;

    static $inject = [
        'rpmUiApiResources',
        'rpmHelper',
        '$timeout',
        'coreApiResources'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private $timeout: ng.ITimeoutService,
        private coreApiResources: CoreApiResources
    )
    {

    }

    async $onInit() 
    {
    }

    async $postLink()
    {
        this.InitRatePlanMinimumItemsGrid();

        if (this.onInit)
            this.onInit({ self: this });
    }

    async LoadSelector(disclosureDocumentRatePlanId: number)
    {
        await AngularUtil.TrackLoadingPromise((async () =>
        {
            this.disclosureDocumentRatePlanId = disclosureDocumentRatePlanId;
            if (this.disclosureDocumentRatePlanId) {
                await this.ratePlanMinimumSelector.LoadDropDownData(this.disclosureDocumentRatePlanId);
            }
            await this.InitLookupData();
        })(), this);
    }

    async LoadGrid(disclosureDocumentRatePlanMinimumId: number)
    {
        await AngularUtil.TrackLoadingPromise((async () =>
        {
            this.disclosureDocumentRatePlanMinimumId = disclosureDocumentRatePlanMinimumId;
            await Promise.resolve(this.grid.dataSource.read());
        })(), this);
    }

    private async InitLookupData()
    {
        return [this.feeTypes, this.royaltyRatePlanMinimums] = await Promise.all([
            this.rpmUiApiResources.GetAllRoyaltyFeeTypes().then(r => r.data),
            this.GetRoyaltyDisclosureDocumentRatePlanMinimumDropDownItems(this.disclosureDocumentRatePlanId)
        ]);
    }

    private OnSelectorInit(controller: fddRoyaltiesRatePlanMinimumSelectorOnInit["self"])
    {
        this.ratePlanMinimumSelector = controller;
    }

    private async OnMinimumSelect(disclosureDocumentRatePlanMinimumId: number, event: fddRoyaltiesRatePlanMinimumSelectorOnSelect["event"])
    {
        if (this.HasChanges() && !confirm("There are unsaved changes in the territory population minimums grid. Do you wish to change selected rate plan minimum without saving?"))
        {
            event.preventDefault();
        }
        else
        {
            await this.LoadGrid(disclosureDocumentRatePlanMinimumId);
        }
    }

    private InitRatePlanMinimumItemsGrid()
    {
        let schema: kendo.data.DataSourceSchema = {
            model: {
                id: nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.DisclosureDocumentRatePlanMinimumsTerritoryPopulationId),
                fields: {
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.DisclosureDocumentRatePlanMinimumsTerritoryPopulationId)]: {
                        type: "number",
                        validation: { required: true },
                        editable: false,
                        defaultValue: 0
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.RoyaltyDisclosureDocumentRatePlanMinimumId)]: {
                        type: "number",
                        validation: { required: true },
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.RoyaltyFeeTypeId)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.WeeksInBusinessStart)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.WeeksInBusinessEnd)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.FeeAmount)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.TerritoryPopulationLowRange)]: {
                        type: "number",
                        validation: { required: true }
                    },
                    [nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.TerritoryPopulationHighRange)]: {
                        type: "number",
                        validation: { required: true }
                    }
                } as kendo.data.DataSourceSchemaModelFields
            }
        }

        let dataSource = new kendo.data.DataSource({
            //filter: { field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum>(o => o.DeletedDateTime), operator: "isnull" },
            transport: {
                read: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    if (!this.disclosureDocumentRatePlanMinimumId)
                    {
                        return [];
                    }

                    return await this.territoryClient.Query({
                        $filter: `${nameof.full<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulation>(o => o.RoyaltyDisclosureDocumentRatePlanMinimumId)} eq ${this.disclosureDocumentRatePlanMinimumId}`
                            .replace(/\./g, "/")
                    });
                }),
                create: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let newItem: RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem & kendo.data.Model = options.data;
                    newItem.RoyaltyDisclosureDocumentRatePlanMinimumId = this.disclosureDocumentRatePlanMinimumId;
                    //let minimumResult = await this.territoryClient.Post(newItem);
                    let minimumResult = await this.coreApiResources.CreateRoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulations(newItem).then((response) => {
                        return response.data
                    });

                    return minimumResult;
                }),
                update: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let updateItem: RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem & kendo.data.Model = options.data;
                    //return await this.territoryClient.Put(updateItem.DisclosureDocumentRatePlanMinimumsTerritoryPopulationId, updateItem);
                    return await this.coreApiResources.UpdateRoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulations(updateItem.DisclosureDocumentRatePlanMinimumsTerritoryPopulationId, updateItem).then((response) => {
                        return response;
                    });
                }),
                destroy: KendoUtil.AsyncDataSourceTransportTryWrap(async (options) =>
                {
                    let deleteItem: RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem = options.data;
                    return await this.territoryClient.Delete(deleteItem.DisclosureDocumentRatePlanMinimumsTerritoryPopulationId, {allowHardDelete: false, forceHardDelete: false});
                })
            },
            pageSize: 10,
            schema: schema,
            sort: {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.WeeksInBusinessStart),
                dir: "asc"
            }
        });

        let minItemColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.DisclosureDocumentRatePlanMinimumsTerritoryPopulationId),
                title: "Id",
                width: 160,
                hidden: true
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.RoyaltyFeeTypeId),
                title: "Fee Type",
                editor: (container, options) =>
                {
                    let dropDownListOptions: kendo.ui.DropDownListOptions = {
                        dataTextField: nameof<RpmEntities.usp_RoyaltyFeeTypeResult>(o => o.Description),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyFeeTypeResult>(o => o.RoyaltyFeeTypeId),
                        dataSource: {
                            data: this.feeTypes
                        },
                        change: (e) =>
                        {
                            let dataItem: RpmEntities.usp_RoyaltyFeeTypeResult = e.sender.dataItem();
                            options.model.set(nameof<RpmEntities.usp_RoyaltyFeeTypeResult>(o => o.RoyaltyFeeTypeId), dataItem?.RoyaltyFeeTypeId);
                        }
                    }
                    BindDropDownEditor(container, options, dropDownListOptions);
                },
                template: (dataItem: RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem) =>
                {
                    return this.feeTypes
                        .find((ft) => { return ft.RoyaltyFeeTypeId === dataItem.RoyaltyFeeTypeId; })
                        ?.Description ?? "--";
                }
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.WeeksInBusinessStart),
                title: "WIB Start"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.WeeksInBusinessEnd),
                title: "WIB End"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.TerritoryPopulationLowRange),
                title: "TerritoryPopulationLowRange",
                format: "{0:n0}"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.TerritoryPopulationHighRange),
                title: "TerritoryPopulationHighRange",
                format: "{0:n0}"
            }, {
                field: nameof<RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationGridItem>(o => o.FeeAmount),
                title: "Fee Amount",
                format: "{0:c}"
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }];

        let toolbar = [
            {
                template: (
                    <button type="button" ng-click={`${NameOfFullToControllerAs(nameof.full(this.grid.addRow))}()`}>
                        Add Row
                    </button>
                )
            }
        ] as kendo.ui.GridToolbarItem[]

        let options: kendo.ui.GridOptions = {
            toolbar: toolbar,
            autoBind: false,
            dataSource: dataSource,
            columns: minItemColumns,
            selectable: "row",
            editable: !this.readOnly ? { confirmation: false } : false,
            navigatable: true,
            scrollable: false,
            sortable: true,
            pageable: KendoUtil.GetDefaultKendoGridPageable(10), 
            dataBound: (e) => { this.$timeout(); }
        };

        this.grid.setOptions(options);
    }

    async GetRoyaltyDisclosureDocumentRatePlanMinimumDropDownItems(disclosureDocumentRatePlanId: number)
    {
        let minimums = await this.ratePlansClient.Query({
            $filter: `${nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum>(o => o.DisclosureDocumentRatePlanId)} eq ${disclosureDocumentRatePlanId} and ` +
                `${nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum>(o => o.FindScaleBasedOnId)} eq ${TerritoryPopulationFindScaleBasedOnId} `,
            $select: [
                nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum>(o => o.DisclosureDocumentRatePlanId),
                nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum>(o => o.WeeksInBusinessStart),
                nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimum>(o => o.WeeksInBusinessEnd)
            ].join(",")
        });

        return minimums.map(m =>
        {
            return {
                RoyaltyDisclosureDocumentRatePlanMinimumId: m.RoyaltyDisclosureDocumentRatePlanMinimumId,
                Display: `Weeks: ${m.WeeksInBusinessStart} - ${m.WeeksInBusinessEnd}`
            } as RoyaltyDisclosureDocumentRatePlanMinimumDropDownItem
        });
    }

    CancelChanges()
    {
        this.grid.cancelChanges();
    }
    
    HasChanges()
    {
        return this.grid.dataSource.hasChanges();
    }

    ResetChanges()
    {
        this.CancelChanges();
        this.LoadGrid(this.disclosureDocumentRatePlanMinimumId);
    }

    async SaveChanges()
    {
        await Promise.resolve(this.grid.dataSource.sync());
        await this.ResetChanges();
    }

    static BindComponent(app: ng.IModule)
    {
        let selectorTemplate = (
            <fddRoyaltiesRatePlanMinimumSelector
                onInit={`$ctrl.${nameof<fddRoyaltiesRatePlanMinimumsTerritoryPopulations>(o => o.OnSelectorInit)}(${nameof<fddRoyaltiesRatePlanMinimumSelectorOnInit>(o => o.self)})`}
                onSelect={
                    `$ctrl.${nameof<fddRoyaltiesRatePlanMinimumsTerritoryPopulations>(o => o.OnMinimumSelect)}(` +
                    `${nameof.full<fddRoyaltiesRatePlanMinimumSelectorOnSelect>(o => o.entity.RoyaltyDisclosureDocumentRatePlanMinimumId)},` +
                    `${nameof.full<fddRoyaltiesRatePlanMinimumSelectorOnSelect>(o => o.event)}` +
                    `)`
                }
            >
            </fddRoyaltiesRatePlanMinimumSelector>
            );
        let gridTemplate = (
            <div ng-show={`$ctrl.${nameof<fddRoyaltiesRatePlanMinimumsTerritoryPopulations>(o => o.disclosureDocumentRatePlanMinimumId)}`}
                kendo-grid={`$ctrl.${nameof<fddRoyaltiesRatePlanMinimumsTerritoryPopulations>(o => o.grid)}`}>

            </div>
        );

        let template = `${selectorTemplate} ${gridTemplate}`

        let componentName = nameof<JSX.IntrinsicElements>(o => o.fddRoyaltiesRatePlanMinimumsTerritoryPopulations);
        app.component(componentName, {
            bindings: {
                [nameof<fddRoyaltiesRatePlanMinimumsTerritoryPopulations>(o => o.readOnly)]: "<",
                [nameof<fddRoyaltiesRatePlanMinimumsTerritoryPopulations>(o => o.onInit)]: "&?"
            },
            template: template,
            controller: fddRoyaltiesRatePlanMinimumsTerritoryPopulations
        });
    }
}

