import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { KendoUtil } from "Utility/KendoUtil";
import * as _ from "underscore"
import * as moment from "moment"
import { RpmResources, RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RoyaltyApiResources } from "../../../Services/Resources/RoyaltyApiResources";
import { royaltyInvoiceReversalFileDataModel } from "../../../Models/Royalty/RoyaltyBatchManagement/RoyaltyInvoiceReversalFileDataModel";
import { AxiosInstance } from "axios";
import { BillingServiceClient, IntegrationFinanceCompanyClient, IntegrationFinanceCompanyVm } from "@nbly/billing-orchestrations-clients";
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";
import { IHttpResponse } from "angular";

interface RoyaltyReportBatchBackedOutParams {
    status: RpmEntities.usp_RoyaltyReportBatchStatus_GetBySetViaBatchManagement_Result;
    reason: string;
    selectedBatch: RpmEntities.usp_RoyaltyReportBatchs_GetByRoyaltyReportBatchId_Result;
  }

export class BatchManagementBatchesComponentController implements ng.IController 
{
    // -- Bindings --
    additionalFilters: kendo.data.DataSourceFilter;
    onSelectedBatch: (params: { batchIds: number[], batches: RpmResources.IRoyaltyReportBatch[] }) => void;
    displayConcept: boolean;
    displayFranchise: boolean;
    // --
    
    selectedBatches: RpmResources.IRoyaltyReportBatch[];
    selectedBatchIds: number[];
    isSelectedBatchSuccess: boolean;
    batchesGrid: kendo.ui.Grid;
    gridQueryResponseHeaders: ODataQueryResponseHeaders;
    
    invoiceWindow: kendo.ui.Window;
    invoicesDataSource: kendo.data.DataSource;

    //invoice reversal 
    invoiceReversalWindow: kendo.ui.Window;
    invoicesReversalDataSource: kendo.data.DataSource;

    isLoading: boolean;
    backoutBatchErrorDesc: string;
    postedPeriodReportIdsForInvoiceReversal: number[];

    franchisorIdForGeneratingInvoice: number;

    franchisorIdForVoidingInvoice: number;

    integrationFinanceSourceTypeId: number;

    billingOrchestrationsClient: AxiosInstance;
    billingServicesClient: BillingServiceClient
    integrationFinanceCompanyClient: IntegrationFinanceCompanyClient

    static $inject = [
        'coreApiResources',
        '$q',
        '$log',
        '$timeout',
        'rpmApiResources',
        'rpmUiApiResources',
        'rpmHelper',
        'odataHelper',
        'royaltyApiResources',
        '$scope',
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private rpmApiResources: RpmApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private odataHelper: ODataHelperService,
        private royaltyApiResources: RoyaltyApiResources,
        private $scope: ng.IScope,
    )
    {
        this.billingOrchestrationsClient = FranForceAxiosClientBuilder.BuildBillingOrchestrationsBaseClient();
        this.billingServicesClient = new BillingServiceClient("", this.billingOrchestrationsClient);
        this.integrationFinanceCompanyClient = new IntegrationFinanceCompanyClient("", this.billingOrchestrationsClient);
    }

    $onInit()
    {
        this.selectedBatches = [];
        this.selectedBatchIds = [];
        this.$scope.$on('refreshBatchManagementData', (event, args) => {
            if (this.batchesGrid && this.batchesGrid.dataSource) {
                this.batchesGrid.dataSource.read();
                this.batchesGrid.refresh();
                this.ClearSelections();
            }
        });
    }

    $postLink()
    {
        this.InitBackoutStatusDropdown();
        this.InitBatchesGrid();
        if (this.additionalFilters) {
            this.LoadGrid();
        }
    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
        if (onChanges[nameof<BatchManagementBatchesComponentController>(o => o.additionalFilters)])
        {
            this.LoadGrid();
        }
    }

    SetOpenResetBatches(open) {
        this.OpenResetBatches = open;
    }

    backoutParams: RoyaltyReportBatchBackedOutParams

    onBatchBackedOut(backout: RoyaltyReportBatchBackedOutParams){
        this.backoutParams = backout;
    }

    OpenResetBatches: (selectedBatchId: number) => ng.IPromise<any>;

    InitBackoutStatusDropdown()
    {
        let datasource = new kendo.data.DataSource({
            transport: {
                read: (options) =>
                {
                    this.rpmUiApiResources.GetRoyaltyReportBatchStatusesForBatchManagement()
                        .then((statusesResponse) =>
                        {
                            options.success(statusesResponse.data);
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        })
                }
            }
        });
    }

    InitBatchesGrid()
    {
        let batchesColumns: kendo.ui.GridColumn[] = [{
                field: nameof<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyReportBatchId),
            title: "Batch ID",
            headerAttributes: { style: "white-space: normal; word-wrap: break-word" },
                template: `<button 
                    ng-show="dataItem.RoyaltyReportBatchId"
                    ng-class="'pure-button grid-edit-button ' + ($ctrl.${nameof(this.IsSelectedBatch)}(dataItem.${nameof<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyReportBatchId)}) ? 'rpm-selected-row-btn' : '')" 
                    ng-click="$ctrl.${nameof(this.ToggleSelect)}(dataItem) ; $event.preventDefault()">
                        <i ng-class="'fa fa-' + ($ctrl.${nameof(this.IsSelectedBatch)}(dataItem.${nameof<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyReportBatchId)}) ? 'check-circle-o' : 'circle-o')" aria-hidden="true"></i>
                        <span ng-bind="dataItem.${nameof<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyReportBatchId)}"></span>
                    </button>
                    <span ng-show="!dataItem.${nameof<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyReportBatchId)}" ng-bind="dataItem.${nameof<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyReportBatchId)}"></span>`
        },
            {
                field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.Franchisor.DisplayName),
                title: "Franchisor Name"
            },
            {
                field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyPeriod.WeekNumber),
                title: "Week",
                width: 90,
                headerAttributes: { style: "white-space: normal; word-wrap: break-word" }
            }, {
                field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyPeriod.PeriodEndDate),
                title: "Period End Date",
                template: "#= kendo.toString(kendo.parseDate(RoyaltyPeriod.PeriodEndDate), 'd') #",
                headerAttributes: { style: "white-space: normal; word-wrap: break-word" }
            }, {
                field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyReportBatchStatus.Description),
                title: "Status",
                headerAttributes: { style: "white-space: normal; word-wrap: break-word" }
            }, {
                field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyPeriod.RoyaltyPeriodFrequency.Description),
                title: "Frequency",
                width: 100,
                headerAttributes: { style: "white-space: normal; word-wrap: break-word" }
            }, {
                field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyPeriodReports.length),
                title: "Report Count",
                filterable: false,
                sortable: false,
                width: 80,
                headerAttributes: { style: "white-space: normal; word-wrap: break-word" }
            }, {
                field: "TotalFees",
                title: "Total Fees",
                format: "{0:c2}",
                filterable: false,
                sortable: false,
                headerAttributes: { style: "white-space: normal; word-wrap: break-word" }
            }, {
                field: "TotalSales",
                title: "Total Sales",
                format: "{0:c2}",
                filterable: false,
                sortable: false,
                headerAttributes: { style: "white-space: normal; word-wrap: break-word" }
            }, {
                field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.LastAttemptedDateTime),
                title: "Last Attempted Date",
                width: 100,
                headerAttributes: { style: "white-space: normal; word-wrap: break-word" },
                template: "#= kendo.toString(kendo.parseDate(LastAttemptedDateTime), 'd') #"
            }, {
                field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.ErrorDescription),
                title: "Error Description",
                width: 100,
                headerAttributes: { style: "white-space: normal; word-wrap: break-word" },
                template: `<a kendo-tooltip 
                            style='box-sizing: border-box; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'
                            k-content='dataItem.ErrorDescription'>
                                {{dataItem.ErrorDescription}}
                            </a>`
            }];

        if (this.displayFranchise) {
            batchesColumns.splice(1, 0, {
                field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.Franchisor.DisplayName),
                title: "Franchisor",
                headerAttributes: { style: "white-space: normal; word-wrap: break-word" }
            });
        }

        if (this.displayConcept) {
            batchesColumns.splice(1, 0, {
                field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.Concept.ConceptCode),
                title: "Concept",
                width: 100,
                headerAttributes: { style: "white-space: normal; word-wrap: break-word" }
            });
        }

        let batchesDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: "RoyaltyReportBatchId",
                    fields: {
                        RoyaltyReportBatchId: {
                            type: "number"
                        },
                        "RoyaltyPeriod.WeekNumber": {
                            type: "number"
                        },
                        "Concept.ConceptCode": {
                            type: "string"
                        },
                        "Franchisor.DisplayName": {
                            type: "string"
                        },
                        "RoyaltyPeriod.PeriodEndDate": {
                            type: "date"
                        },
                        "RoyaltyReportBatchStatus.Description": {
                            type: "string"
                        },
                        "RoyaltyPeriod.RoyaltyPeriodFrequency.Description": {
                            type: "string"
                        },
                        "RoyaltyPeriodReports.length": {
                            type: "number"
                        },
                        TotalFees: {
                            type: "number"
                        },
                        TotalSales: {
                            type: "number"
                        },
                        LastAttemptedDateTime: {
                            type: "date"
                        },
                        RetryCount: {
                            type: "number"
                        },
                        ErrorDescription: {
                            type: "string"
                        }
                    }
                },
                total: (response) =>
                {
                    return this.gridQueryResponseHeaders["odata-count"];
                }
            },
            pageSize: 10,
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) =>
                {
                    this.isLoading = true;

                    let filters = this.additionalFilters ?
                        {
                            logic: "and",
                            filters: [this.additionalFilters, options.data.filter]
                        } :
                        options.data.filter;

                    let queryParams = this.odataHelper.ConvertKendoDataSourceTransportReadOptionsDataToParameterObject(options.data);
                    queryParams.$filter = this.odataHelper.ConvertKendoDataSourceFiltersToString(filters);
                    queryParams.$expand = this.odataHelper.ConvertKendoGridColumnsToExpansionString([
                        ...batchesColumns,
                        { field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyReportBatchStatus.Name) },
                        { field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyPeriodReports[0].RoyaltyPeriodId).replace("[0]", "") },
                        { field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyPeriodReports[0].TotalFeeAmount).replace("[0]", "") },
                        { field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyPeriodReports[0].SourceTotalGrossSalesAmount).replace("[0]", "") },
                        { field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyReportBatchStatus.Name) },
                    ])

                    if (this.displayFranchise) {

                    }

                    queryParams.$count = true;
                    this.rpmApiResources.RoyaltyReportBatchApi.query(queryParams, (data, headersCallback) => { this.gridQueryResponseHeaders = headersCallback(); }).$promise
                        .then((batches) =>
                        {
                            for (let batch of batches)
                            {
                                (<any>batch).TotalFees = batch.RoyaltyPeriodReports
                                    .map(r => r.TotalFeeAmount)
                                    .reduce((a, b) => { return a + b }, 0);

                                (<any>batch).TotalSales = batch.RoyaltyPeriodReports
                                    .map(r => r.SourceTotalGrossSalesAmount)
                                    .reduce((a, b) => { return a + b }, 0);
                            }
                            options.success(batches);
                        }, (err) => { options.error(err); })
                        .finally(() => { this.isLoading = false; });
                }
            },
            serverPaging: true,
            serverSorting: true,
            serverFiltering: true,
            sort: { field: nameof<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyReportBatchId), dir: "desc" }
        });

        const self = this;

        const batchesGridOptions: kendo.ui.GridOptions = {
            autoBind: false,
            columns: batchesColumns,
            dataSource: batchesDataSource,
            detailExpand: function (e: kendo.ui.GridDetailExpandEvent ) {
                // Access the parent data item and its FranchisorId property
                const parentDataItem = e.detailRow;

                const selectedItem = this.dataItem(parentDataItem);

                self.franchisorIdForVoidingInvoice = selectedItem.FranchisorId;
            },
            toolbar: [
                {
                    template: `<label style="margin: 0px 8px; width: 300px;">
                                    <span style="display: block">File Name</span>
                                    <input type="text" style="height: auto; width: 100%; box-sizing: border-box;" ng-model="$ctrl.${nameof(this.batchesGrid)}.${nameof.full<kendo.ui.Grid>(o => o.options.excel.fileName)}" /> 
                                </label>`
                }, {
                    template: `<label style="margin: 0px 8px; text-align: center;">
                                        <span style="display: block">All Pages</span>
                                        <input type="checkbox" style="height: auto" ng-model="$ctrl.${nameof(this.batchesGrid)}.${nameof.full<kendo.ui.Grid>(o => o.options.excel.allPages)}" /> 
                                    </label>`
                },
                "excel",
                {
                    template: `<button class='pure-button' ng-click='$ctrl.${nameof(this.ClearSelections)}()'>Clear Selections</button>`
                },
                
            ],
            excel: {
                fileName: `Batches-` +
                    `${new Date().getFullYear()}_` +
                    `${new Date().getMonth() + 1 < 10 ? "0" : ""}${new Date().getMonth() + 1}_` +
                    `${new Date().getDate() < 10 ? "0" : ""}${new Date().getDate()}.xlsx`,
                filterable: true,
                allPages: false
            },
            excelExport: (e) =>
            {
                //let date = new Date();
                //(<any>e.workbook).fileName = `FranchisorBatchesExport-${date.getUTCFullYear()}_${date.getUTCMonth()}_${date.getUTCDay()}.xlsx`;
            },
            selectable: "multiple row",
            editable: false,
            scrollable: true,
            sortable: true,
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true
            },
        };

        this.batchesGrid.setOptions(batchesGridOptions);
        this.batchesGrid.thead.kendoTooltip({
            filter: "th",
            content: function (e) {
                var target = e.target;
                return $(target).text();
            }
        });
    }

    LoadGrid()
    {
        if (!this.batchesGrid)
            return this.$q.resolve();

        this.isLoading = true;
        return this.$q.when(this.batchesGrid.dataSource.read())
            .then(() =>
            {
                this.batchesGrid.refresh();
            })
            .finally(() => { this.isLoading = false; });
    }

    ClearSelections() 
    {
        this.selectedBatches = [];
        this.selectedBatchIds = [];
        this.isSelectedBatchSuccess = false;

        if (this.onSelectedBatch) {
            this.onSelectedBatch({
                batchIds: this.selectedBatchIds,
                batches: this.selectedBatches
            });
        }
    }

    async OpenInvoiceReversalModal() {
        this.isLoading = true;

        const integrationFinanceCompanyResponse = await this.integrationFinanceCompanyClient
            .searchAll(null, null, this.franchisorIdForVoidingInvoice, null, true);

        const integrationFinanceCompanies = integrationFinanceCompanyResponse.result;

        if (integrationFinanceCompanies && integrationFinanceCompanies.length)
            this.integrationFinanceSourceTypeId = integrationFinanceCompanies[0].integrationFinanceSourceTypeId;
        else
            this.integrationFinanceSourceTypeId = 2; //d365

        this.invoicesReversalDataSource = new kendo.data.DataSource({
            pageSize: 10,
            transport: {
                read: async (options: kendo.data.DataSourceTransportReadOptions) => {
                    this.isLoading = true;

                    try {
                        const results: IHttpResponse<royaltyInvoiceReversalFileDataModel[]>
                            = await this.royaltyApiResources.GetRoyaltyReportPeriodReportReverseInvoices(this.postedPeriodReportIdsForInvoiceReversal);

                        for (let invoice of results.data) {
                            invoice.date = moment(new Date(invoice.date)).format("M/D/Y");
                        }

                        options.success(results.data);
                    }
                    catch (err) {
                        options.error(err);
                    }
                    finally {
                        this.isLoading = false;
                    }
                }
            },
            sort: {
                field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.extAmount),
                dir: "asc"
            }
        });

        this.invoiceReversalWindow.open();

        this.$timeout(() => {
            this.invoiceReversalWindow.center();
        })
    }

    async OnInvoiceVoided() {
        this.invoiceReversalWindow.close();

        const {selectedBatch, reason, status} = this.backoutParams
        const {RoyaltyReportBatchId: royaltyReportBatchId} = selectedBatch

        await this.rpmUiApiResources.BackoutRoyaltyReportBatches({
                        RoyaltyReportBatchId: royaltyReportBatchId,
                        RoyaltyPeriodReportId: null,
                        ErrorDescription: reason,
                        RoyaltyReportBatchStatusID: status.RoyaltyReportBatchStatusID,
                        RetryCount: null,
                        UserName: null
                    })

        return this.LoadGrid();
    }
    
    ToggleSelect(selectedItem: RpmResources.IRoyaltyReportBatch)
    {
        this.franchisorIdForGeneratingInvoice = selectedItem.FranchisorId;

        let foundBatch = _.find(this.selectedBatches, (b) => { return b.RoyaltyReportBatchId == selectedItem.RoyaltyReportBatchId; });
        if (foundBatch) {
            this.selectedBatches = _.without(this.selectedBatches, foundBatch);
        }
        else {
            this.selectedBatches.push(selectedItem);
        }

        this.isSelectedBatchSuccess = this.selectedBatches.length && this.selectedBatches.every((b) => { return this.rpmHelper.IsRpmStatusSuccess(b.RoyaltyReportBatchStatus) });
        this.selectedBatchIds = this.selectedBatches.map((b) => { return b.RoyaltyReportBatchId });

        this.franchisorIdForGeneratingInvoice = this.selectedBatches.map(x => {
            return x.FranchisorId;
        })[0];


        if (this.onSelectedBatch) {
            this.onSelectedBatch({
                batchIds: this.selectedBatchIds,
                batches: this.selectedBatches
            });
        }
    }
    
    async InvoiceFile() {

        let integrationFinanceCompanyResponse = await this.integrationFinanceCompanyClient
            .searchAll(null, null, this.franchisorIdForGeneratingInvoice, null, true);

        let integrationFinanceCompanies: IntegrationFinanceCompanyVm[] = integrationFinanceCompanyResponse.result;

        if (integrationFinanceCompanies && integrationFinanceCompanies.length)
            this.integrationFinanceSourceTypeId = integrationFinanceCompanies[0].integrationFinanceSourceTypeId;
        else
            this.integrationFinanceSourceTypeId = 1; //gp

        this.invoicesDataSource = new kendo.data.DataSource({
            pageSize: 10,
            transport: {
                read: async (options: kendo.data.DataSourceTransportReadOptions) => {
                    this.isLoading = true;

                    try {
                        const results = await this.rpmUiApiResources
                            .GetRoyaltyReportBatchInvoices(this.selectedBatches.map(b => b.RoyaltyReportBatchId))

                        const { data } = results;

                        for (let invoice of data) {
                            invoice.Date = moment(new Date(invoice.Date)).format("M/D/Y");
                        }

                        options.success(data);
                    }
                    catch (error) {
                        options.error(error);
                    }
                    finally {
                        this.isLoading = false;
                    }
                }
            },
            sort: {
                field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.ExtAmount),
                dir: "asc"
            }
        });

        this.invoiceWindow.open();
        this.$timeout(() => {
            this.invoiceWindow.center();
            //this.invoicesDataSource.read();
        })
    }

    IsSelectedBatch(batchId: number)
    {
        return this.selectedBatches.some((b) =>
        {
            return b.RoyaltyReportBatchId === batchId;
        })
    }

    OnInvoicePosted()
    {
        this.invoiceWindow.close();
        return this.LoadGrid();
    }

    IsValidForReset(royaltyReportBatchStatusID: number)
    {
        return [1, 2, 3, 4, 6].some(num => num === royaltyReportBatchStatusID);
    }

    SetBatchBackoutErrorDesc(errorDesc: string) {
        this.backoutBatchErrorDesc = errorDesc;
    }

    SetPostedPeriodReportIdsForInvoiceReversal(postedPeriodReportIdsForInvoiceReversal: number[]) {
        console.log("SetPostedPeriodReportIdsForInvoiceReversal");
        console.log(postedPeriodReportIdsForInvoiceReversal);
        this.postedPeriodReportIdsForInvoiceReversal = postedPeriodReportIdsForInvoiceReversal
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('bmBatches', {
            bindings: {
                [nameof<BatchManagementBatchesComponentController>(o => o.additionalFilters)]: "<",
                [nameof<BatchManagementBatchesComponentController>(o => o.onSelectedBatch)]: "&?",
                [nameof<BatchManagementBatchesComponentController>(o => o.displayConcept)]: "<",
                [nameof<BatchManagementBatchesComponentController>(o => o.displayFranchise)]: "<"
            },
            controller: BatchManagementBatchesComponentController,
            templateUrl: "/Templates/BatchManagement/bmBatches.html" 
        });
    }
}

