import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RmsStateService } from "Services/State/RmsState";

import * as _ from "underscore"
import * as moment from "moment"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";


export class cmRoyaltiesRatePlanMinimumItemsComponentController implements ng.IController
{

    contractRatePlanId: number;
    contractId: number;
    conceptId: number;
    readOnly: boolean;
    onLoaded: (params: { self: cmRoyaltiesRatePlanMinimumItemsComponentController }) => void;
    isDynamicFees: boolean;
    ratePlanMinimumItemGridOptions: kendo.ui.GridOptions;
    ratePlanMinimumItemGrid: kendo.ui.Grid;
    
    monthlyMinimumGrids: { [uid: string]: kendo.ui.Grid };

    isLoading: boolean;
    contract: RpmEntities.RoyaltyContractViewModel;
    royaltyPeriodFrequencies: RpmEntities.RoyaltyPeriodFrequencyViewModel[];
    royaltyFindScaleBasedOnTypes: RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result[];
    formController: angular.IFormController;

    static $inject = [
        'rpmUiApiResources',
        'rpmHelper',
        'rmsState',
        'identityManager',
        '$q',
        '$timeout'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private rmsState: RmsStateService,
        private identityManager: IdentityManager,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService) {

    }

    $onInit() {
        this.isLoading = true;
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledCm').val() === 'True';
        this.monthlyMinimumGrids = {};
        moment.locale("en");

        this.$q.all([
            this.rpmUiApiResources.GetAllRoyaltyPeriodFrequencies().then((freqResponse) => {
                this.royaltyPeriodFrequencies = freqResponse.data;
            }),
            this.rpmUiApiResources.GetAllRoyaltyFindScaleBasedOnTypes().then((findScaleResponse) => {
                this.royaltyFindScaleBasedOnTypes = findScaleResponse.data;
            })
        ])
            .then(() => {
                this.InitRatePlanMinimumItemsGrid();

                if (this.onLoaded)
                    this.onLoaded({ self: this });
            })
            .finally(() => { this.isLoading = false; });
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.contractRatePlanId)] && changes[nameof(this.contractRatePlanId)].currentValue)
        {
            this.$timeout(() => { this.ResetChanges(); }, 0);
        }

        if (changes[nameof(this.contractId)]) {
            this.PullContract();
        }
    }

    InitRatePlanMinimumItemsGrid()
    {
        let minItemDataSourceOptions = {
            filter: { field: nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.DeletedDateTime), operator: "isnull" },
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {
                    if (!this.contractRatePlanId)
                    {
                        options.success([]);
                        return;
                    }

                    this.rpmUiApiResources.GetRoyaltyContractRatePlanMinimumItemsByContractPlanItem(this.contractRatePlanId)
                        .then((minPlanItemsResponse) => {
                            options.success(minPlanItemsResponse.data);
                        });
                },
                create: (options: kendo.data.DataSourceTransportOptions) => {
                    let newMinItem: RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel = options.data;
                    this.rpmUiApiResources.CreateRoyaltyContractRatePlanMinimumItem(newMinItem)
                        .then((idResponse) => {
                            options.success({ ContractRatePlanMinimumItemId: idResponse.data });
                        }, (err) => { options.error(err); });
                },
                update: (options: kendo.data.DataSourceTransportOptions) => {
                    let updateMinItem: RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel = options.data;
                    this.rpmUiApiResources.UpdateRoyaltyContractRatePlanMinimumItem(updateMinItem.ContractRatePlanMinimumItemId, updateMinItem)
                        .then(() => {
                            options.success();
                        }, (err) => { options.error(err); });
                },
                destroy: (options: kendo.data.DataSourceTransportOptions) => {
                    let deleteMinItem: RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel = options.data;
                    deleteMinItem.DeletedDateTime = new Date().toISOString();
                    this.rpmUiApiResources.UpdateRoyaltyContractRatePlanMinimumItem(deleteMinItem.ContractRatePlanMinimumItemId, deleteMinItem)
                        .then(() => {
                            options.success();
                        }, (err) => { options.error(err); });
                },
            },
            pageSize: 20,
            schema: this.GetRatePlanMinimumItemSchema(),
            sort: {
                field: nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.WeeksInBusinessStart),
                dir: "asc"
            }
        };

        let minItemColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.ContractRatePlanMinimumItemId),
                title: "ID"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.WeeksInBusinessStart),
                title: "WIB Start",
                editor: this.rpmHelper.WeeksInBusinessEditor()
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.WeeksInBusinessEnd),
                title: "WIB End",
                editor: this.rpmHelper.WeeksInBusinessEditor()
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.RoyaltyFeeMinimumFixedRateAmount),
                title: "Min Royalty Fee",
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.AdFeeMinimumFixedRateAmount),
                title: "Min Ad Fee",
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.GrossSalesMinimumAmount),
                title: "Gross Sales Min Amount",
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.RollInMinimumRoyaltyFeeFixedRateAmount),
                title: "Rollin Royalty Fee",
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.RollInMinimumAdFeeFixedRateAmount),
                title: "Rollin Ad Fee",
                format: "{0:c}"
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.RoyaltyPeriodFrequencyId),
                title: "Minimum Fee Frequency",
                editor: (container, options) => {
                    let input = "<input required data-required-msg='Select a value for Minimum Fee Frequency' name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: nameof<RpmEntities.RoyaltyPeriodFrequencyViewModel>(o => o.Description),
                        dataValueField: nameof<RpmEntities.RoyaltyPeriodFrequencyViewModel>(o => o.RoyaltyPeriodFrequencyId),
                        optionLabel: "Select...",
                        optionLabelTemplate: "Select...",
                        dataSource: {
                            data: this.royaltyPeriodFrequencies
                        },
                        change: (e: kendo.ui.DropDownListChangeEvent) =>
                        {
                            let dataItem: RpmEntities.RoyaltyPeriodFrequencyViewModel = e.sender.dataItem();
                            options.model.set(nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.RoyaltyPeriodFrequencyId), dataItem && dataItem.RoyaltyPeriodFrequencyId);
                        }
                    });
                },
                template: `{{$ctrl.${nameof(this.GetRoyaltyPeriodFrequencyById)}(dataItem.${nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.RoyaltyPeriodFrequencyId)}).${nameof<RpmEntities.RoyaltyPeriodFrequencyViewModel>(o => o.Description)} || 'Select...'}}`
            }, {
                field: nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.MinimumScaleBasedOnId),
                title: "Minimum Scale Based On",
                editor: (container, options) => {
                    let input = "<input required data-required-msg='Select a value for Minimum Scale Based On' name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: nameof<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>(o => o.Description),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>(o => o.FindScaleBasedOnId),
                        optionLabel: "Select...",
                        optionLabelTemplate: "Select...",
                        dataSource: {
                            data: this.royaltyFindScaleBasedOnTypes
                        },
                        change: (e: kendo.ui.DropDownListChangeEvent) => {
                            let dataItem = e.sender.dataItem();
                            options.model.set(nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.MinimumScaleBasedOnId), dataItem && dataItem.FindScaleBasedOnId);
                        }
                    });
                },
                template: `{{
                    $ctrl.${nameof(this.GetRoyaltyFindScaleBasedOnTypesId)}(dataItem.${nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.MinimumScaleBasedOnId)}).${nameof<RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result>(o => o.Description)} || 
                    'Select...'
                }}`
            }, {
                field: "HasMonthlyMinimumChanges",
                title: "Has Monthly Minimum Changes",
                hidden: true
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }];
        

        this.ratePlanMinimumItemGridOptions = {
            dataSource: new kendo.data.DataSource(minItemDataSourceOptions),
            columns: minItemColumns,
            selectable: "row",
            editable: !this.readOnly ? { confirmation: false } : false,
            navigatable: true,
            sortable: true,
            scrollable: false,
            pageable: true
        };
    }

    GetMonthlyMinimumsGridOptions(gridItem: RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel)
    {
        let updateOrCreateTransportFunction = (options: kendo.data.DataSourceTransportOptions) => {
            let item: RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel = options.data;
            for (let month of moment.months()) {
                gridItem["MinimumApplied" + month] = item["MinimumApplied" + month];
            }
            if (gridItem.ContractRatePlanMinimumItemId) {
                //If it's a new item, this flag doesn't need to be set as it will already be gaurenteed to be created.
                this.ratePlanMinimumItemGrid.dataSource
                    .get(gridItem.ContractRatePlanMinimumItemId)
                    .set("HasMonthlyMinimumChanges", true);
            }
            options.success()
        };

        let mmDataSourceOptions: kendo.data.DataSourceOptions = {
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {

                    let newMinItem: Partial<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel> = {
                        ContractRatePlanMinimumItemId: gridItem.ContractRatePlanMinimumItemId
                    };
                    for (let month of moment.months())
                    {
                        newMinItem["MinimumApplied" + month] = gridItem["MinimumApplied" + month];
                    }

                    options.success([newMinItem]);
                },
                update: updateOrCreateTransportFunction,
                create: updateOrCreateTransportFunction,
                destroy: (e: kendo.data.DataSourceTransportOptions) => {
                    e.error('Destory is not defined for monthly minimums, as it should not be possible.')
                }
            },
            pageSize: 20,
            schema: this.GetRatePlanMinimumItemSchema()
        };
        let mmColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "ContractRatePlanMinimumItemId",
                title: "ID",
                hidden: true
            }];

        for (let month of moment.months()) {
            mmColumns.push({
                field: "MinimumApplied" + month,
                title: month,
                template: `<button ng-show="!$ctrl.readOnly" ng-class="'grid-check-button'" ng-click="$ctrl.${nameof(this.ToggleMinimumApplied)}(dataItem, '${month}') ; $event.preventDefault()">
                                <i ng-class="dataItem.MinimumApplied${month} ? 'fa fa-check-square' : 'fa fa-square' " aria-hidden="true"></i>
                            </button>
                            <span ng-show="$ctrl.readOnly">{{dataItem.MinimumApplied${month} ? "Yes" : "No"}}</span>`
            });
        }

        return {
            dataSource: new kendo.data.DataSource(mmDataSourceOptions),
            columns: mmColumns,
            editable: false,
            navigatable: true,
            scrollable: false,
        };
    }

    GetRatePlanMinimumItemSchema() : kendo.data.DataSourceSchema
    {
        let schema: kendo.data.DataSourceSchema = {
            model: {
                id: "ContractRatePlanMinimumItemId",
                fields: {
                    [nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.ContractRatePlanMinimumItemId)]: {
                        type: "number",
                        validation: { required: true },
                        editable: false,
                        defaultValue: 0
                    },
                    [nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.WeeksInBusinessStart)]: { type: "number", validation: { required: true } },
                    [nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.WeeksInBusinessEnd)]: { type: "number", validation: { required: true } },
                    [nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.RoyaltyFeeMinimumFixedRateAmount)]: { type: "number", validation: { required: true } },
                    [nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.AdFeeMinimumFixedRateAmount)]: { type: "number", validation: { required: true } },
                    [nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.GrossSalesMinimumAmount)]: { type: "number", validation: { required: true } },
                    [nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.RoyaltyPeriodFrequencyId)]: { type: "number", validation: { required: true } },
                    [nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.MinimumScaleBasedOnId)]: { type: "number", validation: { required: true } },
                    [nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.RollInMinimumAdFeeFixedRateAmount)]: { type: "number", validation: { required: false } },
                    [nameof<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>(o => o.RollInMinimumRoyaltyFeeFixedRateAmount)]: { type: "number", validation: { required: false } },
                    HasMonthlyMinimumChanges: {
                        type: "boolean",
                        defaultValue: false,
                    }
                }
            }
        };

        for (let month of moment.months()) {
            schema.model.fields["MinimumApplied" + month] = { type: "boolean" };
        }

        return schema;
    }
    
    PullContract()
    {
        return this.$timeout(() =>
        {
            return this.rmsState.royaltyContractStateHandler.Load(this.contractId, this.isDynamicFees)
                .then((contract) => { this.contract = contract; });
        }, 0);
    }

    ToggleMinimumApplied(dataItem: kendo.data.Model, month: string)
    {
        dataItem.set("MinimumApplied" + month, !dataItem.get("MinimumApplied" + month));
    }

    GetRoyaltyFindScaleBasedOnTypesId(findScaleBasedOnId: number)
    {
        return _.find(this.royaltyFindScaleBasedOnTypes, (fs) => { return fs.FindScaleBasedOnId === findScaleBasedOnId; });
    }

    GetRoyaltyPeriodFrequencyById(royaltyPeriodFrequencyId: number)
    {
        return _.find(this.royaltyPeriodFrequencies, (rpf) => { return rpf.RoyaltyPeriodFrequencyId === royaltyPeriodFrequencyId; });
    }

    AddNewRatePlanMinimumItem()
    {
        if (!this.readOnly)
        {
            let model: RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel = {
                ContractRatePlanMinimumItemId: 0,
                ContractRatePlanId: this.contractRatePlanId,
                MinimumScaleBasedOnId: null,
                RoyaltyPeriodFrequencyId: null,
                WeeksInBusinessStart: 0,
                WeeksInBusinessEnd: 0,
                GrossSalesMinimumAmount: 0,
                RollInMinimumAdFeeFixedRateAmount: 0,
                RollInMinimumRoyaltyFeeFixedRateAmount: 0,
                RoyaltyFeeMinimumFixedRateAmount: 0,
                AdFeeMinimumFixedRateAmount: 0,
                MinimumAppliedJanuary: true,
                MinimumAppliedFebruary: true,
                MinimumAppliedMarch: true,
                MinimumAppliedApril: true,
                MinimumAppliedMay: true,
                MinimumAppliedJune: true,
                MinimumAppliedJuly: true,
                MinimumAppliedAugust: true,
                MinimumAppliedSeptember: true,
                MinimumAppliedOctober: true,
                MinimumAppliedNovember: true,
                MinimumAppliedDecember: true,
                CreatedDateTime: kendo.toString(new Date(), 'd'),
                CreatedUser: null,
                UpdatedDateTime: null,
                UpdatedUser: null,
                DeletedDateTime: null,
            };

            this.ratePlanMinimumItemGrid.dataSource.add(model);
        }
    }
    

    CancelChanges()
    {
        if (!this.readOnly)
        {
            Object.keys(this.monthlyMinimumGrids).forEach(key => {
                this.monthlyMinimumGrids[key].dataSource.cancelChanges();
            });
            this.ratePlanMinimumItemGrid.dataSource.cancelChanges();
        }
    }
    
    HasChanges()
    {
        if (!this.ratePlanMinimumItemGrid)
            return false;

        return (
            this.ratePlanMinimumItemGrid.dataSource.hasChanges()
            ||
            Object.keys(this.monthlyMinimumGrids)
                .map(key => { return this.monthlyMinimumGrids[key].dataSource.hasChanges(); })
                .some(hasChanges => { return hasChanges; })
        );
    }

    ResetChanges()
    {    
        this.CancelChanges();

        this.monthlyMinimumGrids = {};

        return this.$q.when(this.ratePlanMinimumItemGrid.dataSource.read()).then(() =>
        {
            this.ratePlanMinimumItemGrid.refresh();
            this.$timeout(() =>
            {
                this.formController.$setPristine();
            }, 1000);
        });
    }

    SaveChanges()
    {
        let currentData = <Array<RpmEntities.RoyaltyContractRatePlanMinimumItemViewModel>>this.ratePlanMinimumItemGrid.dataSource.data().slice(0);
        if (currentData.some(d => !d.MinimumScaleBasedOnId || !d.RoyaltyPeriodFrequencyId)) {
            let message = "Minimum Item Grid missing required data selections.";
            this.rpmHelper.ShowRmsNotification("error", message);
            return this.$q.reject(message);
        }


        let syncPromises: ng.IPromise<any>[] = [];
        Object.keys(this.monthlyMinimumGrids).forEach(key => {
            syncPromises.push(this.$q.when(this.monthlyMinimumGrids[key].dataSource.sync()));
        });
        return this.$q.all(syncPromises).then(() => {
            return this.$q.when(this.ratePlanMinimumItemGrid.dataSource.sync())
                .then(() =>
                {
                    return this.ResetChanges();
                });
        })
    }


    static BindComponent(app: ng.IModule)
    {
        app
            .component("cmRoyaltiesRatePlanMinimumItems", {
                bindings: {
                    [nameof<cmRoyaltiesRatePlanMinimumItemsComponentController>(o => o.contractRatePlanId)]: "<",
                    [nameof<cmRoyaltiesRatePlanMinimumItemsComponentController>(o => o.readOnly)]: "<",
                    [nameof<cmRoyaltiesRatePlanMinimumItemsComponentController>(o => o.contractId)]: "<",
                    [nameof<cmRoyaltiesRatePlanMinimumItemsComponentController>(o => o.conceptId)]: "<",
                    [nameof<cmRoyaltiesRatePlanMinimumItemsComponentController>(o => o.onLoaded)]: "&?"
                },
                templateUrl: '/Templates/ContractManagement/cmRoyalties/RatePlanMinItems.html',
                controller: cmRoyaltiesRatePlanMinimumItemsComponentController
            });
    }
}
