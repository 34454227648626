import { KbsBaseComponentController } from "./KbsBaseComponent";

export class ItemTypesComponentController extends KbsBaseComponentController<KnowledgeResources.IItemType> {

    GetGridColumns(): kendo.ui.GridColumn[] {
        return [
            {
                field: "Description",
                title: "Description",
            },
            {
                template: "<button class='pure-button button-secondary' ng-click='$ctrl.OpenEditEntityWindow(dataItem)'>Edit</button>",
                width: 100,
                title: "&nbsp;"
            },
            {
                template: "<button class='pure-button button-error' ng-click='$ctrl.DeleteEntity(dataItem)'>X</button>",
                width: 70,
                title: "&nbsp;"
            }
        ];
    }

    GetDataSourceSchemaModel(): any {
        return { id: "ItemTypeId" };
    }

    TransportReadOperationHandler(options: kendo.data.DataSourceTransportReadOptions) {
        this.knowledgeApiResources.ItemTypesApi.query({})
            .$promise.then(
                (itemTypes) => {
                    options.success(itemTypes);
                },
                (err) => {
                    options.error(err);
                });
    }

    CreateNewEntity(): KnowledgeResources.IItemType {
        let itemType = new this.knowledgeApiResources.ItemTypesApi();
        itemType.ItemTypeId = 0;
        itemType.Description = "";

        return itemType;
    }

    GetSavePromise(itemType: KnowledgeResources.IItemType) {
        let itemTypePromise: ng.IPromise<KnowledgeResources.IItemType>;

        if (itemType.ItemTypeId) {
            // Update
            itemTypePromise = this.knowledgeApiResources.ItemTypesApi.update({ id: itemType.ItemTypeId }, itemType).$promise;
        }
        else {
            // Create
            itemTypePromise = itemType.$save();
        }

        return itemTypePromise;
    }

    DeleteEntity(itemType: KnowledgeResources.IItemType) {
        if (confirm(`Are you sure you want to delete '${itemType.Description}'?`)) {
            return this.knowledgeApiResources.ItemTypesApi.delete({ id: itemType.ItemTypeId })
                .$promise.then(() => {
                    return this.RefreshGrid();
                });
        }
    }

    static BindComponent(app: ng.IModule) {
        app.component("kbsItemTypes", {
            bindings: {
            },
            controller: ItemTypesComponentController, 
            templateUrl: "/Templates/KBS/ItemTypes.html",
        });
    }
}