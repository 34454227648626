import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import * as _ from "underscore"
import { CoreEntities, CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { ApiConfig } from "../../../AppConfig/ApiConfig";

export class cmContractSelectorComponentController implements ng.IController
{
    conceptId: number;
    franchisorId: number;
    franchiseId: number;
    //onContractSelect: (params: {
    //    contract: kendo.data.ObservableObject & Partial<RpmEntities.RoyaltyContractViewModel> &
    //        { RoyaltyMethodologyDescription?: string, RoyaltyPeriodFrequencyDescription?: string, ContractStatusDescription?: string  } 
    //}) => void;

    isLoading: boolean;
    contractGrid: kendo.ui.Grid;

    conceptFilters: kendo.data.DataSourceFilters;
    franchisorFilters: kendo.data.DataSourceFilter;

    userInfo: IUserInfoViewModel;

    showInactiveFranchises: boolean;

    static $inject = [
        'rpmUiApiResources',
        'identityManager',
        '$q',
        '$timeout',
        '$window',
        '$location',
        "apiConfig",
        '$scope'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private identityManager: IdentityManager,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService,
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private apiConfig: ApiConfig,
        private $scope: ng.IScope
    )
    {

    }

    $onInit()
    {
        this.showInactiveFranchises = false;

        if (!this.franchiseId) {
            this.franchiseId = parseInt(this.$location.search()[nameof(this.franchiseId)]);
        }

        this.InitFilters()
    }

    $postLink()
    {
        this.InitGridOptions();
    }

    InitFilters()
    {
        //this.conceptFilters = <kendo.data.DataSourceFilterItem>{
        //    field: nameof<CoreEntities.Concept>(o => o.RoyaltySystemId),
        //    operator: "isnotnull"
        //};

        this.CreateConceptFilters();

        this.franchisorFilters = <kendo.data.DataSourceFilterItem>{
            field: nameof<CoreEntities.Franchisor>(o => o.RoyaltySystemId),
            operator: "isnotnull"
        };
    }

    async CreateConceptFilters() {

        let loginUserDetails = await this.identityManager.GetLoggedInUserInfo()

        if (loginUserDetails && loginUserDetails.data) {

            let isAdminRole = false;
            let isConceptManager = false;
            let isContractManagementAdminRole = false;
            let conceptClaimIds: number[] = [];

            this.userInfo = loginUserDetails.data;

            if (this.userInfo && this.userInfo.Roles) {

                isAdminRole = this.userInfo.Roles.some(r =>
                    r.Name == this.apiConfig.FranForceConstants.RoleConstants.AdminRole);

                isConceptManager = this.userInfo.Roles.some(r =>
                    r.Name == this.apiConfig.FranForceConstants.RoleConstants.ConceptManagerRole);

                isContractManagementAdminRole = this.userInfo.Roles.some(r =>
                    r.Name == this.apiConfig.FranForceConstants.RoleConstants.ContractManagementAdminRole);

                conceptClaimIds = this.userInfo.Claims
                    .filter(claim => claim.Type == this.apiConfig.FranForceConstants.ClaimConstants.ManagedConceptIdClaimType)
                    .map(claim => parseInt(claim.Value));

                if ((isConceptManager && conceptClaimIds.length > 0) && !isAdminRole && !isContractManagementAdminRole) {

                    this.conceptFilters = {
                        logic: "or",
                        filters: [

                        ]
                    }

                    $.each(conceptClaimIds, (index, conceptId) => {
                        this.conceptFilters.filters.push({
                            field: nameof<CoreResources.IFranchisor>(o => o.ConceptId),
                            operator: "eq",
                            value: conceptId
                        });
                    });

                    this.$scope.$digest();
                }
            }
        }
    }
    
    InitGridOptions()
    {
        let columns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.RoyaltyContractViewModel>(o => o.RoyaltyContractId),
                title: "RoyaltyContractId",
                width: 128
            }, {
                field: "RoyaltyMethodologyDescription",
                title: "Royalty Methodology",
                width: 256
            }, {
                field: "RoyaltyPeriodFrequencyDescription",
                title: "Royalty Period Frequency",
                width: 256
            }, {
                field: nameof<RpmEntities.RoyaltyContractViewModel>(o => o.ContractSignedDate),
                title: "Contract Signed",
                template: `#= ${nameof<RpmEntities.RoyaltyContractViewModel>(o => o.ContractSignedDate)} != null ? kendo.toString(kendo.parseDate(${nameof<RpmEntities.RoyaltyContractViewModel>(o => o.ContractSignedDate)}, 'yyyy-MM-dd'), 'yyyy-MM-dd') : '' # `
            }, {
                field: nameof<RpmEntities.RoyaltyContractViewModel>(o => o.ContractBeginsDate),
                title: "Contract Begins",
                template: "#= ContractBeginsDate != null ? kendo.toString(kendo.parseDate(ContractBeginsDate, 'yyyy-MM-dd'), 'yyyy-MM-dd') : '' # "
            }, {
                field: nameof<RpmEntities.RoyaltyContractViewModel>(o => o.ContractEndsDate),
                title: "Contract Ends",
                template: "#= ContractEndsDate != null ? kendo.toString(kendo.parseDate(ContractEndsDate, 'yyyy-MM-dd'), 'yyyy-MM-dd') : '' # "
            }, {
                field: nameof<RpmEntities.RoyaltyContractViewModel>(o => o.RoyaltyBeginsDate),
                title: "Royalty Begins",
                template: "#= RoyaltyBeginsDate != null ? kendo.toString(kendo.parseDate(RoyaltyBeginsDate, 'yyyy-MM-dd'), 'yyyy-MM-dd') : '' # "
            }, {
                field: nameof<RpmEntities.RoyaltyContractViewModel>(o => o.RoyaltyEndsDate),
                title: "Royalty Ends",
                template: "#= RoyaltyEndsDate != null ? kendo.toString(kendo.parseDate(RoyaltyEndsDate, 'yyyy-MM-dd'), 'yyyy-MM-dd') : '' # "
            }, {
                field: nameof<RpmEntities.RoyaltyContractViewModel>(o => o.CreatedDateTime),
                title: "Created Date",
                template: "#= CreatedDateTime != null ? kendo.toString(kendo.parseDate(CreatedDateTime,'yyyy-MM-dd'), 'yyyy-MM-dd') : '' #"
            }, {
                field: "ContractStatusDescription",
                title: "Status"
            }
        ];
        let dataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof<RpmEntities.RoyaltyContractViewModel>(o => o.RoyaltyContractId)
                }
            },
            transport: {
                read: (options) =>
                {
                    if (!this.franchiseId) {
                        options.success([]);
                        return;
                    }
                    this.isLoading = true;

                    let lookupPromise = this.LoadLookupData();
                    
                    this.rpmUiApiResources.GetRoyaltyContractsByFranchise(this.franchiseId)
                        .then((contractsResponse) =>
                        {
                            return lookupPromise
                                .then(([typesResponse, frequencyResponse, statusesResponse]) =>
                                {
                                    for (let contract of contractsResponse.data) {

                                        let methodology = _.find(typesResponse.data,
                                            (type) =>
                                            {
                                                return contract.RoyaltyMethodologyTypeId == type.RoyaltyMethodologyTypeId;
                                            });

                                        let frequency = _.find(frequencyResponse.data,
                                            (freq) =>
                                            {
                                                return contract.RoyaltyPeriodFrequencyId == freq.RoyaltyPeriodFrequencyId;
                                            });

                                        let contractStatus = _.find(statusesResponse.data,
                                            (status) =>
                                            {
                                                return contract.RoyaltyContractStatusID == status.RoyaltyContractStatusID;
                                            });

                                        (<any>contract).RoyaltyMethodologyDescription = methodology && methodology.Description;
                                        (<any>contract).RoyaltyPeriodFrequencyDescription = frequency && frequency.Description;
                                        (<any>contract).ContractStatusDescription = contractStatus && contractStatus.Description;
                                    }
                                    options.success(contractsResponse.data);
                                });
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        })
                        .finally(() => { this.isLoading = false; });

                }
            },
            sort: [
                {
                    field: nameof<RpmEntities.RoyaltyContractViewModel>(o => o.RoyaltyContractStatusID),
                    dir: "desc",
                    compare: (a: RpmEntities.RoyaltyContractViewModel, b: RpmEntities.RoyaltyContractViewModel) =>
                    {
                        // 1 - Terminated
                        // 2 - Active
                        // 3 - Pending
                        let realA = a.RoyaltyContractStatusID;
                        let realB = b.RoyaltyContractStatusID;

                        //Leapfrog the active values ahead of pending so they can be sorted.
                        if (realA == 2)
                            realA += 3;
                        if (realB == 2)
                            realB += 3;

                        return realA - realB;
                    }
                },
                {
                    field: nameof<RpmEntities.RoyaltyContractViewModel>(o => o.ContractBeginsDate),
                    dir: "desc",
                }
            ]
        });

        this.contractGrid.setOptions(<kendo.ui.GridOptions>{
            autoBind: false,
            columns: columns,
            dataSource: dataSource,
            sortable: true,
            selectable: true,
            change: (e) =>
            {
                let contract = e.sender.dataItem(e.sender.select())
                this.$window.location.href = `/ContractManagement/Edit/#?tab=general&contractId=${contract.get(nameof<RpmEntities.RoyaltyContractViewModel>(o => o.RoyaltyContractId))}`
            }
        })
    }

    OnFranchiseSelect(franchise: CoreResources.IFranchise)
    {
        this.franchiseId = franchise && franchise.FranchiseId;
        this.franchisorId = franchise && franchise.FranchisorId;
        this.conceptId = franchise && franchise.ConceptId;
        if (this.franchiseId)
        {
            this.contractGrid.dataSource.read().then(() =>
            {
                this.contractGrid.refresh();
            });
        }
    }
    
    LoadLookupData()
    {
        return this.$q.all([
            this.rpmUiApiResources.GetAllRoyaltyMethodologyTypes(),
            this.rpmUiApiResources.GetAllRoyaltyPeriodFrequencies(),
            this.rpmUiApiResources.GetAllRoyaltyContractStatuses()
        ]);
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("cmContractSelector", {
                bindings: {
                    //onContractSelect: "&"
                },
                templateUrl: '/Templates/ContractManagement/cmContractSelector.html',
                controller: cmContractSelectorComponentController
            });
    }
}

