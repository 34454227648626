import "jquery";
import "angular";
import "angular-resource/angular-resource.min";
import "angular-route/angular-route.min";
import "angular-sanitize/angular-sanitize.min";
import "angular-ui-router/release/angular-ui-router.min";
import "ng-dialog/js/ngDialog.min";
import "jszip";
import "@progress/kendo-ui/js/kendo.grid";
import "@progress/kendo-ui/js/kendo.tabstrip";
import "@progress/kendo-ui/js/kendo.window";
import "@progress/kendo-ui/js/kendo.dropdownlist";
import "@progress/kendo-ui/js/kendo.datepicker";
import "@progress/kendo-ui/js/kendo.editor";
import "@progress/kendo-ui/js/kendo.tooltip";
import "@progress/kendo-ui/js/kendo.autocomplete";
import "@progress/kendo-ui/js/kendo.numerictextbox";
import "@progress/kendo-ui/js/kendo.dialog";
import "@progress/kendo-ui/js/kendo.validator";
import "@progress/kendo-ui/js/kendo.notification";
import "@progress/kendo-ui/js/kendo.button";
import "@progress/kendo-ui/js/kendo.maskedtextbox";
import "@progress/kendo-ui/js/kendo.combobox";
import "@progress/kendo-ui/js/kendo.multiselect";
import "@progress/kendo-ui/js/kendo.upload";
import "@progress/kendo-ui/js/kendo.angular";
import "@progress/kendo-ui/js/kendo.datetimepicker";
import "js-cookie/src/js.cookie";
import "imagesloaded/imagesloaded.pkgd.min";
import "angular-images-loaded/angular-images-loaded";
import "angular-promise-buttons/dist/angular-promise-buttons.min";
import "angular-tooltips/dist/angular-tooltips.min";
import "angular-animate/angular-animate.min";
import "angular-aria/angular-aria.min";
import "ng-currency/dist/ng-currency";
import "angular-deckgrid/angular-deckgrid.min.js";
import "jquery-timepicker/jquery.timepicker";
import "croppie/croppie.js";
import "cropperjs/dist/cropper.esm.js"
import "angular-ui-tree/dist/angular-ui-tree.min";
import "angular-sortable-view/src/angular-sortable-view";





