import { RmsConfirmationDialogComponentController } from "Directives/RPM/Common/rmsConfirmationDialog";
import { RmsContractSourceSelectorComponentController } from "Directives/RPM/Common/rmsContractSourceSelector";
import { RmsContractStatusSelectorComponentController } from "Directives/RPM/Common/rmsContractStatusSelector";
import { RmsContractTerminationReasonSelectorComponentController } from "Directives/RPM/Common/rmsContractTerminationReasonSelector";
import { RmsDisclosureDocumentSelectorComponentController } from "Directives/RPM/Common/rmsDisclosureDocumentSelector";
import { rmsKeyValueGridComponentController } from "Directives/RPM/Common/rmsKeyValueGridController";
import { RmsMethodologyTypeSelectorComponentController } from "Directives/RPM/Common/rmsMethodologyTypeSelector";
import { fmRouterConfig } from "Controllers/Rms/FranchiseeManagement/fmRouter";
import { FranchiseeManagementController } from "Controllers/Rms/FranchiseeManagement/FranchiseeManagementController";
import { fmGeneralController } from "Controllers/Rms/FranchiseeManagement/fmGeneralController";
import { fmEmailController } from "Controllers/Rms/FranchiseeManagement/fmEmailManagementController";
import { fmContractsController } from "Controllers/Rms/FranchiseeManagement/fmContractsController";
import { cmRoyaltiesFeeSettingsComponentController } from "Directives/RPM/ContractManagement/cmRoyalties/cmRoyaltiesFeeSettings";
import { cmRoyaltiesRatePlanItemsComponentController } from "Directives/RPM/ContractManagement/cmRoyalties/cmRoyaltiesRatePlanItems";
import { cmRoyaltiesRatePlanMinimumItemsComponentController } from "Directives/RPM/ContractManagement/cmRoyalties/cmRoyaltiesRatePlanMinItems";
import { cmRoyaltiesRatePlansComponentController } from "Directives/RPM/ContractManagement/cmRoyalties/cmRoyaltiesRatePlans";
import { cmRoyaltiesServiceCategoriesComponentController } from "Directives/RPM/ContractManagement/cmRoyalties/cmRoyaltiesServiceCategories";
import { cmContractSelectorComponentController } from "Directives/RPM/ContractManagement/cmContractSelector";
import { CmGeneralComponentController } from "Directives/RPM/ContractManagement/cmGeneral";
import { CmSectionsComponentDynamicFeesController } from "Directives/RPM/ContractManagement/cmSections_df";
import { CmTerritoryComponentController } from "Directives/RPM/ContractManagement/cmTerritoryController";
import { CmWeeksInBusinessCalculatorComponentController } from "Directives/RPM/ContractManagement/cmWeeksInBusinessCalculator";
import { bmRouterConfig } from "Controllers/Rms/BatchManagement/bmRouter";
import { bmBatchIdController } from "Controllers/Rms/BatchManagement/bmBatchIdController";
import { bmFranchiseeController } from "Controllers/Rms/BatchManagement/bmFranchiseeController";
import { bmFranchisorController } from "Controllers/Rms/BatchManagement/bmFranchisorController";
import { bmPeriodController } from "Controllers/Rms/BatchManagement/bmPeriodController";
import { BatchManagementController } from "Controllers/Rms/BatchManagement/IndexBMController";
import { BatchManagementBatchesComponentController } from "Directives/RPM/BatchManagement/bmBatches";
import { BatchManagementInvoiceAdjustmentsComponentController } from "Directives/RPM/BatchManagement/bmInvoiceAdjustments";
import { BatchManagementInvoiceGridComponentController } from "Directives/RPM/BatchManagement/bmInvoiceGrid";
import { BatchManagementPeriodReportsComponentController } from "Directives/RPM/BatchManagement/bmPeriodReports";
import { BatchManagementResendEmailsComponentController } from "Directives/RPM/BatchManagement/bmResendEmails";
import { BatchManagementResetBatchesComponentController } from "Directives/RPM/BatchManagement/bmResetBatches";
import { BatchManagementInvoiceReversalComponentController } from "Directives/RPM/BatchManagement/bmInvoiceReversal";
import { BatchManagementResetPeriodReportsComponentController } from "Directives/RPM/BatchManagement/bmResetPeriodReports";
import { frmRouterConfig } from "Controllers/Rms/FranchisorRoyaltyManagement/frmRouter";
import { FranchisorRoyaltyManagementController } from "Controllers/Rms/FranchisorRoyaltyManagement/FranchisorRoyaltyManagementController";
import { FRMAdFeeCapController } from "Controllers/Rms/FranchisorRoyaltyManagement/frmAdFeeCaps";
import { FRMGeneralController } from "Controllers/Rms/FranchisorRoyaltyManagement/frmGeneral";
import { FRMItemsController } from "Controllers/Rms/FranchisorRoyaltyManagement/frmItems";
import { FRMReportingPeriodController } from "Controllers/Rms/FranchisorRoyaltyManagement/frmReportingPeriods";
import { ManualSalesEntryComponentController } from "Directives/RPM/ManualSales/ManualSalesEntry";
import { ProcessManualSalesEntriesComponentController } from "Directives/RPM/ManualSales/ProcessManualSalesEntries";
import { UnprocessedManualSalesEntriesComponentController } from "Directives/RPM/ManualSales/UnprocessedManualSalesEntries";

import { ManualSalesEntryErroredReportRegenerationController } from "Directives/RPM/ManualSales/manualSalesEntryErroredReportRegeneration";
import { ManualSalesPeriodReportsController } from "Directives/RPM/ManualSales/manualSalesPeriodReports";
import { PeriodReportAdFeesComponentController } from "Directives/RPM/PeriodReport/prSections/prAdFees";
import { PeriodReportAdjustmentsComponentController } from "Directives/RPM/PeriodReport/prSections/prAdjustments";
import { PeriodReportOtherFeesComponentController } from "Directives/RPM/PeriodReport/prSections/prOtherFees";
import { PeriodReportPosTransactionsComponentController } from "Directives/RPM/PeriodReport/prSections/prPosTransactions";
import { PeriodReportSalesComponentController } from "Directives/RPM/PeriodReport/prSections/prSales";
import { PeriodReportRoyaltyComponentController } from "Directives/RPM/PeriodReport/prSections/prRoyalty";
import { PeriodReportInfoDisplayComponentController } from "Directives/RPM/PeriodReport/PeriodReportInfoDisplay";
import { PeriodReportSectionsComponentController } from "Directives/RPM/PeriodReport/PeriodReportSections";
import { PeriodReportSelectorComponentController } from "Directives/RPM/PeriodReport/PeriodReportSelector";
import { ConceptRoyaltyController } from "Controllers/Rms/ConceptRoyalty/ConceptRoyaltyController";
import { crAdFeeCapControllerConfig } from "Controllers/Rms/ConceptRoyalty/crAdFeeCapController";
import { crChartAccountsControllerConfig } from "Controllers/Rms/ConceptRoyalty/crChartAccountsController";
import { crGeneralControllerConfig } from "Controllers/Rms/ConceptRoyalty/crGeneralController";
import { crProductsControllerConfig } from "Controllers/Rms/ConceptRoyalty/crProductsController";
import { crReportingPeriodControllerConfig } from "Controllers/Rms/ConceptRoyalty/crReportingPeriodController";
import { RmsWeeksInBusinessDisplayComponentController } from "Directives/RPM/Common/rmsWeeksInBusinessDisplay";
import { RmsFindScaleBasedOnTypesSelector } from "Directives/RPM/Common/RmsFindScaleBasedOnTypesSelector";
import { FrmAddReportingPeriodComponentController } from "Directives/RMS/FranchisorRoyaltyManagement/frmAddReportingPeriods";
import { FrmReportingPeriodInfoComponentController } from "Directives/RMS/FranchisorRoyaltyManagement/frmReportingPeriodInfo";
import { CmExcludedServicesComponent } from "Directives/RPM/ContractManagement/cmExcludedServices";
import { TaxReturnAnalysisComponent } from "Directives/RPM/TaxReturn/TaxReturnAnalysis"
import { ManualSalesProcessGridController } from "Directives/RPM/ManualSales/ManualSalesProcessGrid";
import { ManualSalesReportUploadController } from "Directives/RPM/ManualSales/ManualSalesReportUpload";
import { CmRoyaltiesComponentController, cmRoyaltyContractRatePlanMinimums, cmRoyaltyContractRatePlanMinimumsMonthsApplied } from "Directives/Rpm/ContractManagement";

import * as FddManagement from "Directives/RPM/FddManagement";
import * as FranchiseEntity from "Directives/RPM/FranchiseEntity";
import * as RMSSearch from "Directives/RPM/RMSSearch";
import * as RMSDashboard from "Directives/RPM/RMSDashboard";
import * as RMSApproval from "Directives/RPM/RmsApproval";
import * as FinanceIntegrationDashboard from "Directives/RPM/FinanceIntegrationDashboard";
import { RmsRoyaltyScaleCalculationSelectorComponentController } from "../Directives/RPM/Common/rmsRoyaltyScaleCalculationSelector";
import { TerminatedReportEntryController } from "../Directives/RPM/ManualSales/TerminatedReportEntry";
import { RmsRoyaltySummaryInvoiceDisplayTypeSelectorComponentController } from "../Directives/RPM/Common/rmsRoyaltySummaryInvoiceDisplayTypeSelector";
import { RmsRoyaltySystemSelectorComponentController } from "../Directives/RPM/Common/rmsRoyaltySystemSelector";
import { RmsFddYearSelectorComponentController } from "../Directives/RPM/Common/rmsFddYearSelector";
import { RmsFranchiseLocationStateSelectorComponentController } from "../Directives/RPM/Common/rmsFranchiseLocationStateSelector";
import { RmsRoyaltyPeriodFrequencyTypeSelectorController } from "../Directives/RPM/Common/rmsRoyaltyPeriodFrequencyTypeSelector";
import { fmPosController } from "../Controllers/Rms/FranchiseeManagement/fmPosController";
import { RmsApprovalConfigurationUserSelectorComponentController } from "../Directives/RPM/Common/rmsApprovalConfigurationUserSelector";
import { RmsApprovalNotificationTypeSelectorComponentController } from "../Directives/RPM/Common/rmsApprovalNotificationTypeSelector";
import { BatchManagementFinanceIntegrationInvoiceGridComponentController } from "../Directives/RPM/BatchManagement/bmFinanceIntegrationInvoiceGrid";
import { BatchManagementFinanceIntegrationInvoiceReversalGridComponentController } from "../Directives/RPM/BatchManagement/bmFinanceIntegrationInvoiceReversalGrid";
import { PeriodReportTafsFeesComponentController } from "Directives/RPM/PeriodReport/prSections/prTafsFees";
import { PeriodReportTechFeesComponentController } from "Directives/RPM/PeriodReport/prSections/prTechFees";
import { CmRoyaltiesDynamicFeesComponentController } from "Directives/RPM/ContractManagement/cmRoyalties_df";
import { cmRoyaltiesRatePlanItemsDynamicFeesComponentController } from "Directives/RPM/ContractManagement/cmRoyalties/cmRoyaltiesRatePlanItems_df";
import { CmSectionsComponentController } from "Directives/RPM/ContractManagement/cmSections";
import { PeriodReportSectionsDynamicFeesComponentController } from "Directives/RPM/PeriodReport/PeriodReportSections_df";
import { PeriodReportAdjustmentsDynamicFeesComponentController } from "Directives/RPM/PeriodReport/prSections/prAdjustments_df";


export function Init()
{
    /* RPM Modules */
    let rmsApp = InitRms();

    InitBatchManagement();
    InitContractManagement();
    InitConceptRoyalty();
    InitFdd();
    InitFranchiseeManagement();
    InitFranchisorRoyaltyManagement();
    InitManualSales();
    InitPeriodReports();
    InitTaxReturns();
    InitFranchiseEntity();
    InitRmsSearch();
    InitRmsDashboard();
    InitRmsApproval();
    InitFinanceIntegrationDashboard();

    return rmsApp;
}

function InitRms()
{
    let rmsApp = angular.module('portal.rpm');
    RmsConfirmationDialogComponentController.BindComponent(rmsApp);
    RmsContractSourceSelectorComponentController.BindComponent(rmsApp);
    RmsContractStatusSelectorComponentController.BindComponent(rmsApp);
    RmsContractTerminationReasonSelectorComponentController.BindComponent(rmsApp);
    RmsDisclosureDocumentSelectorComponentController.BindComponent(rmsApp);
    rmsKeyValueGridComponentController.BindComponent(rmsApp);
    RmsMethodologyTypeSelectorComponentController.BindComponent(rmsApp);
    RmsWeeksInBusinessDisplayComponentController.BindComponent(rmsApp);
    RmsFindScaleBasedOnTypesSelector.BindComponent(rmsApp);
    RmsRoyaltyScaleCalculationSelectorComponentController.BindComponent(rmsApp);
    RmsRoyaltySummaryInvoiceDisplayTypeSelectorComponentController.BindComponent(rmsApp);
    RmsRoyaltySystemSelectorComponentController.BindComponent(rmsApp);
    RmsFddYearSelectorComponentController.BindComponent(rmsApp);
    RmsFranchiseLocationStateSelectorComponentController.BindComponent(rmsApp);
    RmsRoyaltyPeriodFrequencyTypeSelectorController.BindComponent(rmsApp);
    RmsApprovalConfigurationUserSelectorComponentController.BindComponent(rmsApp);
    RmsApprovalNotificationTypeSelectorComponentController.BindComponent(rmsApp);
    return rmsApp;
};

function InitBatchManagement()
{
    let batchApp = angular.module('portal.rpm.batch');
    batchApp.config(bmRouterConfig);

    BatchManagementBatchesComponentController.BindComponent(batchApp);
    BatchManagementInvoiceAdjustmentsComponentController.BindComponent(batchApp);
    BatchManagementInvoiceGridComponentController.BindComponent(batchApp);
    BatchManagementPeriodReportsComponentController.BindComponent(batchApp);
    BatchManagementResendEmailsComponentController.BindComponent(batchApp);
    BatchManagementResetBatchesComponentController.BindComponent(batchApp);
    BatchManagementInvoiceReversalComponentController.BindComponent(batchApp);
    BatchManagementResetPeriodReportsComponentController.BindComponent(batchApp);
    BatchManagementFinanceIntegrationInvoiceGridComponentController.BindComponent(batchApp);
    BatchManagementFinanceIntegrationInvoiceReversalGridComponentController.BindComponent(batchApp);

    batchApp.controller("BatchManagementController", BatchManagementController);
    batchApp.controller("bmBatchIdController", bmBatchIdController); 
    batchApp.controller("bmFranchiseeController", bmFranchiseeController);
    batchApp.controller("bmFranchisorController", bmFranchisorController);
    batchApp.controller("bmPeriodController", bmPeriodController);
}

function InitContractManagement()
{
    let contractApp = angular.module('portal.rpm.contract');

    cmRoyaltiesFeeSettingsComponentController.BindComponent(contractApp);
    cmRoyaltiesRatePlanMinimumItemsComponentController.BindComponent(contractApp);
    cmRoyaltiesRatePlansComponentController.BindComponent(contractApp);
    cmRoyaltiesServiceCategoriesComponentController.BindComponent(contractApp);

    CmRoyaltiesComponentController.BindComponent(contractApp);
    CmRoyaltiesDynamicFeesComponentController.BindComponent(contractApp);

    CmSectionsComponentController.BindComponent(contractApp);
    CmSectionsComponentDynamicFeesController.BindComponent(contractApp);

    cmRoyaltiesRatePlanItemsComponentController.BindComponent(contractApp);
    cmRoyaltiesRatePlanItemsDynamicFeesComponentController.BindComponent(contractApp);

    cmContractSelectorComponentController.BindComponent(contractApp);
    CmGeneralComponentController.BindComponent(contractApp);
   
    CmTerritoryComponentController.BindComponent(contractApp);
    CmWeeksInBusinessCalculatorComponentController.BindComponent(contractApp);
    CmExcludedServicesComponent.BindComponent(contractApp);
    cmRoyaltyContractRatePlanMinimums.BindComponent(contractApp);
    cmRoyaltyContractRatePlanMinimumsMonthsApplied.BindComponent(contractApp);
}

function InitConceptRoyalty()
{
    let conceptRoyaltyApp = angular.module('portal.rpm.conceptRoyalty');
    
    conceptRoyaltyApp.controller("ConceptRoyaltyController", ConceptRoyaltyController);
    conceptRoyaltyApp.controller('crAdFeeCapController', crAdFeeCapControllerConfig);
    conceptRoyaltyApp.controller('crChartAccountsController', crChartAccountsControllerConfig);
    conceptRoyaltyApp.controller('crGeneralController', crGeneralControllerConfig);
    conceptRoyaltyApp.controller('crProductsController', crProductsControllerConfig);
    conceptRoyaltyApp.controller('crReportingPeriodController', crReportingPeriodControllerConfig);
}


function InitFdd()
{
    let fddApp = angular.module('portal.rpm.fdd');

    FddManagement.fddSelectorComponentController.BindComponent(fddApp);

    FddManagement.fddSectionsComponentController.BindComponent(fddApp);
    FddManagement.fddSectionsDynamicFeesComponentController.BindComponent(fddApp);
    
    FddManagement.fddGeneralComponentController.BindComponent(fddApp);

    FddManagement.fddRoyaltiesComponentController.BindComponent(fddApp);
    FddManagement.fddRoyaltiesDynamicFeesComponentController.BindComponent(fddApp);

    FddManagement.fddRoyaltyMinimums.BindComponent(fddApp);
    FddManagement.fddNewDocumentButtonComponentController.BindComponent(fddApp);
    FddManagement.fddRollInRanges.BindComponent(fddApp);
    FddManagement.fddRoyaltiesRatePlanMinimumSelector.BindComponent(fddApp);

    FddManagement.fddRoyaltiesFeeScalesComponentController.BindComponent(fddApp);

    FddManagement.fddRoyaltiesRatePlanItemsComponentController.BindComponent(fddApp);
    FddManagement.fddRoyaltiesRatePlanItemsDynamicFeesComponentController.BindComponent(fddApp);

    FddManagement.fddRoyaltiesRatePlanMinimumItemsComponentController.BindComponent(fddApp);
    FddManagement.fddRoyaltiesRatePlansComponentController.BindComponent(fddApp);
    FddManagement.fddRoyaltiesServiceCategoriesComponentController.BindComponent(fddApp);
    FddManagement.fddRoyaltiesTerritoryMinimumFeesComponentController.BindComponent(fddApp);

    FddManagement.fddRoyaltiesRatePlanSelector.BindComponent(fddApp);
    FddManagement.fddRoyaltiesRatePlanMinimums.BindComponent(fddApp);
    FddManagement.fddRoyaltiesRatePlanMinimumsMonthsApplied.BindComponent(fddApp);
    FddManagement.fddRoyaltiesRatePlanMinimumsTerritoryPopulations.BindComponent(fddApp);
    FddManagement.fddRoyaltiesRollInMinimums.BindComponent(fddApp);
    FddManagement.fddRoyaltiesRollInMinimumsMonthsApplied.BindComponent(fddApp);
}

function InitFranchiseeManagement()
{
    let franchiseeApp = angular.module('portal.rpm.franchisee');

    franchiseeApp.config(fmRouterConfig);

    franchiseeApp.controller('FranchiseeManagementController', FranchiseeManagementController);
    franchiseeApp.controller('fmGeneralController', fmGeneralController);
    franchiseeApp.controller('fmEmailController', fmEmailController);
    franchiseeApp.controller('fmContractsController', fmContractsController);
    franchiseeApp.controller('fmPosController', fmPosController);
}

function InitFranchisorRoyaltyManagement()
{
    let frmApp = angular.module('portal.rpm.franchisorRoyaltyManagement');

    frmApp.config(frmRouterConfig);

    frmApp.controller('FranchisorRoyaltyManagementController', FranchisorRoyaltyManagementController);
    frmApp.controller('FRMAdFeeCapController', FRMAdFeeCapController);
    frmApp.controller('FRMGeneralController', FRMGeneralController);
    frmApp.controller('FRMItemsController', FRMItemsController);
    frmApp.controller('FRMReportingPeriodController', FRMReportingPeriodController);
    FrmAddReportingPeriodComponentController.BindComponent(frmApp);
    FrmReportingPeriodInfoComponentController.BindComponent(frmApp);
}

function InitManualSales()
{
    let manualSalesApp = angular.module('portal.rpm.manualSales', ['portal.rpm.batch']);

    ManualSalesEntryComponentController.BindComponent(manualSalesApp);
    ProcessManualSalesEntriesComponentController.BindComponent(manualSalesApp);
    UnprocessedManualSalesEntriesComponentController.BindComponent(manualSalesApp);
    ManualSalesProcessGridController.BindComponent(manualSalesApp);
    ManualSalesReportUploadController.BindComponent(manualSalesApp);
    TerminatedReportEntryController.BindComponent(manualSalesApp);
    ManualSalesEntryErroredReportRegenerationController.BindComponent(manualSalesApp);
    ManualSalesPeriodReportsController.BindComponent(manualSalesApp);
}

function InitPeriodReports()
{
    let periodReportApp = angular.module('portal.rpm.periodReport');

    PeriodReportAdFeesComponentController.BindComponent(periodReportApp);

    PeriodReportAdjustmentsComponentController.BindComponent(periodReportApp);
    PeriodReportAdjustmentsDynamicFeesComponentController.BindComponent(periodReportApp);

    PeriodReportOtherFeesComponentController.BindComponent(periodReportApp);
    PeriodReportTafsFeesComponentController.BindComponent(periodReportApp);
    PeriodReportTechFeesComponentController.BindComponent(periodReportApp);
    PeriodReportPosTransactionsComponentController.BindComponent(periodReportApp);
    PeriodReportRoyaltyComponentController.BindComponent(periodReportApp);
    PeriodReportSalesComponentController.BindComponent(periodReportApp);

    PeriodReportInfoDisplayComponentController.BindComponent(periodReportApp);

    PeriodReportSectionsComponentController.BindComponent(periodReportApp);
    PeriodReportSectionsDynamicFeesComponentController.BindComponent(periodReportApp);

    PeriodReportSelectorComponentController.BindComponent(periodReportApp);
}

function InitTaxReturns() 
{
    let taxReturnApp = angular.module('portal.rpm.taxReturn');

    TaxReturnAnalysisComponent.BindComponent(taxReturnApp);
}

function InitFranchiseEntity()
{
    let franchiseEntityApp = angular.module('portal.rpm.franchiseEntity');

    FranchiseEntity.FranchiseEntityDropDown.BindComponent(franchiseEntityApp);
    FranchiseEntity.FranchiseEntityRelationshipApplicationsDropDown.BindComponent(franchiseEntityApp);
    FranchiseEntity.FranchiseEntityRelationshipDetailsGrid.BindComponent(franchiseEntityApp);
    FranchiseEntity.FranchiseEntityRelationshipEditor.BindComponent(franchiseEntityApp);
    FranchiseEntity.FranchiseEntityRelationshipManagement.BindComponent(franchiseEntityApp);
    FranchiseEntity.FranchiseEntityRelationshipsGrid.BindComponent(franchiseEntityApp);
    FranchiseEntity.FranchiseEntityRelationshipTypesDropDown.BindComponent(franchiseEntityApp);
    FranchiseEntity.FranchisesInEntityGrid.BindComponent(franchiseEntityApp);
}

function InitRmsSearch()
{
    let rmsSearchApp = angular.module('portal.rpm.rmsSearch');

    RMSSearch.rmsSearchComponentController.BindComponent(rmsSearchApp);
    RMSSearch.rmsSearchResultGridComponentController.BindComponent(rmsSearchApp)
}

function InitRmsDashboard() {
    let rmsDashboardApp = angular.module('portal.rpm.rmsDashboard');

    RMSDashboard.rmsDashboardComponentController.BindComponent(rmsDashboardApp);
    RMSDashboard.rmsDashboardInputComponentController.BindComponent(rmsDashboardApp);
    RMSDashboard.rmsDashboardSectionsComponentController.BindComponent(rmsDashboardApp);
    RMSDashboard.rmsDashboardSalesVarianceGridComponentController.BindComponent(rmsDashboardApp);
    RMSDashboard.rmsDashboardMissingReportsGridComponentController.BindComponent(rmsDashboardApp);
    RMSDashboard.rmsDashboardFeeVarianceGridComponentController.BindComponent(rmsDashboardApp);
    RMSDashboard.rmsDashboardAdFeeCapsBreachedGridComponentController.BindComponent(rmsDashboardApp);
}

function InitRmsApproval() {
    let rmsApprovalApp = angular.module('portal.rpm.rmsApproval');

    RMSApproval.rmsApprovalComponentController.BindComponent(rmsApprovalApp);
    RMSApproval.rmsApprovalSectionsComponentController.BindComponent(rmsApprovalApp);
    RMSApproval.rmsApprovalConfigurationsComponentController.BindComponent(rmsApprovalApp);
    RMSApproval.rmsPreApprovalQueueComponentController.BindComponent(rmsApprovalApp);
    RMSApproval.rmsPostApprovalQueueComponentController.BindComponent(rmsApprovalApp);
    RMSApproval.rmsPreApprovalQueueInputComponentController.BindComponent(rmsApprovalApp);
    RMSApproval.rmsPreApprovalQueueGridController.BindComponent(rmsApprovalApp);
    RMSApproval.rmsPreApprovalQueueActionController.BindComponent(rmsApprovalApp);
    RMSApproval.rmsPreApprovalRejectInvoicesPopupController.BindComponent(rmsApprovalApp);
}

function InitFinanceIntegrationDashboard() {
    let financeIntegrationDashboardApp = angular.module('portal.rpm.financeIntegrationDashboard');
    FinanceIntegrationDashboard.financeIntegrationDashboardComponentController.BindComponent(financeIntegrationDashboardApp);
    FinanceIntegrationDashboard.financeIntegrationDashboardInputComponentController.BindComponent(financeIntegrationDashboardApp);
    FinanceIntegrationDashboard.financeIntegrationImportDashboardInputComponentController.BindComponent(financeIntegrationDashboardApp);
    FinanceIntegrationDashboard.financeIntegrationDashboardGridComponentController.BindComponent(financeIntegrationDashboardApp);
    FinanceIntegrationDashboard.financeIntegrationDashboardFileUploadGridController.BindComponent(financeIntegrationDashboardApp);
}
