
export class rmsPostApprovalQueueComponentController implements ng.IController {

    isLoading: boolean;

    postApprovalQueueGrid: kendo.ui.Grid;
    postApprovalQueueGridOptions: kendo.ui.GridOptions;

    static $inject = [
        '$scope',
    ];

    constructor(
        private $scope: ng.IScope,
    ) {

    }

    $onInit() {
        this.isLoading = false;
        this.LoadGrid();
    }

    LoadGrid() {

        let postApprovalQueueGridDataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {

                    options.success([])

                }
            },
            pageSize: 25,
            schema: {
                model: {
                    id: "royaltyPeriodReportId",
                    fields: {


                        royaltyPeriodReportId: {
                            type: "number"
                        },
                        licenseNumber: {
                            type: "string"
                        },
                        franchiseeName: {
                            type: "string"
                        },
                        totalSales: {
                            type: "number"
                        },
                        adFee: {
                            type: "number"
                        },
                        royaltyFee: {
                            type: "number"
                        },
                        technologyFee: {
                            type: "number"
                        },
                        totalFees: {
                            type: "number"
                        },
                        reasonForApproval: {
                            type: "string"
                        }
                    }
                }
            }
        });

        let postApprovalQueueFranchiseGridColumns: Array<kendo.ui.GridColumn> =
            [
                {
                    field: "royaltyPeriodReportId",
                    title: "Report ID",
                    sortable: false
                },
                {
                    field: "licenseNumber",
                    title: "License Number",
                    sortable: false
                },
                {
                    field: "franchiseeName",
                    title: "Franchisee Name",
                    sortable: false
                },
                {
                    field: "totalSales",
                    title: "Total Sales"
                },
                {
                    field: "adFee",
                    title: "Ad/MAP Fee",
                    sortable: false
                },
                {
                    field: "royaltyFee",
                    title: "Royalty/License Fee",
                    sortable: false
                },
                {
                    field: "technologyFee",
                    title: "Technology Fee",
                    sortable: false
                },
                {
                    field: "totalFees",
                    title: "Total Fees",
                    sortable: false
                },
                {
                    field: "reasonForApproval",
                    title: "Reason For Approval",
                    sortable: false
                },
            ];

        this.postApprovalQueueGridOptions = {
            autoBind: false,
            dataSource: postApprovalQueueGridDataSource,
            columns: postApprovalQueueFranchiseGridColumns,
            sortable: true,
            scrollable: true,
            pageable: {
                numeric: true,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true,
            },
        };

    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsPostApprovalQueue", {
            bindings: {

            },
            templateUrl: '/Templates/RMSApproval/RmsPostApprovalQueue/rmsPostApprovalQueue.html',
            controller: rmsPostApprovalQueueComponentController
        });
    }
}