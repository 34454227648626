import { NotificationGroupApiResources } from "Services/Resources/NotificationGroupApiResources";
import { AngularUtil } from "Utility/AngularUtil";

export class DisplayDowntimeNotificationController implements ng.IController {

    downTimeNotification: any;

    static $inject = [
        'notificationGroupApiResources',
        "$q",
        "$log"
    ];

    constructor(
        private notificationGroupApiResources: NotificationGroupApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
    ) {

    }

    $onInit() {
         this.GetApplicationNotification();
    }

    async GetApplicationNotification() {
        const cesAppId = 4;
        await this.notificationGroupApiResources.GetNotificationByAppId(cesAppId).then((res) => {
            this.downTimeNotification = res.data;
        }), AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback(this.$log, this.$q)
    }

    static BindComponent(app: ng.IModule) {
        app.component('displayDowntimeNotification', {
            bindings: {

            },
            controller: DisplayDowntimeNotificationController,
            templateUrl: "/Templates/DownTimeNotification/DisplayDowntimeNotification.html"
        });
    }
}
