
  export const photoGalleryImageDimensions = {width: 324 , height: 243 };

  export const getNaturalImageDimensions = (file: File): Promise<{ width: number, height: number }> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => resolve({
          width: img.width,
          height: img.height,
        });
        img.onerror = (error) => reject(error);
  
        if (event.target && typeof event.target.result === 'string') {
          img.src = event.target.result;
        } else {
          reject(new Error('Failed to read file.'));
        }
      };
  
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  export const getNaturalImageDimensionsFromBase64 = (base64Image: string): Promise<{ width: number, height: number }> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve({
        width: img.width,
        height: img.height,
      });
      img.onerror = (error) => reject(error);
      img.src = base64Image;
    });
  };

  export const getNaturalImageDimensionsFromBlob = (blob: Blob): Promise<{ width: number, height: number }> => {
    return new Promise((resolve, reject) => {
      const blobUrl = URL.createObjectURL(blob);
  
      const img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(blobUrl); // Clean up the Blob URL
        resolve({
          width: img.width,
          height: img.height,
        });
      };
      img.onerror = (error) => {
        URL.revokeObjectURL(blobUrl); // Clean up the Blob URL in case of an error
        reject(error);
      };
      img.src = blobUrl;
    });
  };
  
  
