import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AngularUtil } from "Utility/AngularUtil";
import { userStateObj, EditUserStateService } from "Services/State/EditUserStateService";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class EditUserFranchiseOwnershipComponentController implements ng.IController 
{
    managedUserId: string;
    refreshDate: Date;
    refresh: (params: { refreshedDate: Date }) => void;

    userState: userStateObj;
    isLoading: boolean;

    isViewable: boolean;

    ownedFranchiseIds: number[];
    franchiseSelect: string[];
    franchiseTemplate: string;

    static $inject = [
        'editUserState',
        'identityManager',
        'apiConfig',
    ];

    constructor(
        private editUserState: EditUserStateService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
    )
    {
    }

    $onInit()
    {
        this.franchiseSelect = [
            nameof<CoreResources.IFranchise>(o => o.FranchiseId),
            nameof<CoreResources.IFranchise>(o => o.LicenseNumber),
            nameof<CoreResources.IFranchise>(o => o.DoingBusinessAs),
            nameof<CoreResources.IFranchise>(o => o.Address),
            nameof<CoreResources.IFranchise>(o => o.Address2),
            nameof<CoreResources.IFranchise>(o => o.City),
            nameof<CoreResources.IFranchise>(o => o.StateAbbr),
            nameof<CoreResources.IFranchise>(o => o.PostalCode),
            nameof<CoreResources.IFranchise>(o => o.ContactLastName),
            nameof<CoreResources.IFranchise>(o => o.ContactFirstName),
        ];

        this.franchiseTemplate = `
            <div>
                <strong>{{${nameof<CoreResources.IFranchise>(o => o.LicenseNumber)}}}</strong> - ({{${nameof<CoreResources.IFranchise>(o => o.FranchiseId)}}})
            </div>
            <div>
                {{${nameof<CoreResources.IFranchise>(o => o.DoingBusinessAs)}}}
            </div>
            <div>
                <a target='_blank' href="${this.apiConfig.AppSettings.ApplicationRootUrl}/FranchiseProfiles/Edit/{{${nameof<CoreResources.IFranchise>(o => o.FranchiseId)}}}">
                    Franchise Profile
                </a>
            </div>
            <div>
                <div>
                    {{${nameof<CoreResources.IFranchise>(o => o.Address)}}}
                </div>
                <div>
                    {{${nameof<CoreResources.IFranchise>(o => o.Address2)}}}
                </div>
                <div>
                    {{${nameof<CoreResources.IFranchise>(o => o.City)}}}, {{${nameof<CoreResources.IFranchise>(o => o.StateAbbr)}}} {{${nameof<CoreResources.IFranchise>(o => o.PostalCode)}}}
                </div>
            </div>
            <div ng-show="{{(${nameof<CoreResources.IFranchise>(o => o.ContactLastName)} || ${nameof<CoreResources.IFranchise>(o => o.ContactFirstName)})}}">
                {{${nameof<CoreResources.IFranchise>(o => o.ContactLastName)}}}<span 
                    ng-show="{{(${nameof<CoreResources.IFranchise>(o => o.ContactLastName)} && ${nameof<CoreResources.IFranchise>(o => o.ContactFirstName)})}}"
                    >,</span> 
                {{${nameof<CoreResources.IFranchise>(o => o.ContactFirstName)}}}
            </div>`;
    }

    $postLink()
    {

    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        this.Load();
    }

    Load()
    {
        let promise = this.editUserState.Load(this.managedUserId)
            .then((result) =>
            {
                this.userState = result;
            })
            .then(() =>
            {
                this.isViewable = (this.userState.managedUser ? true : false) &&
                    this.userState.managedUser.Roles.some(r => r.Name == this.apiConfig.FranForceConstants.RoleConstants.FranchiseeRole);

                this.ownedFranchiseIds = this.userState.managedUser.Claims
                    .filter(c => c.Type === this.apiConfig.FranForceConstants.ClaimConstants.OwnedFranchiseIdClaimType)
                    .map(c => parseInt(c.Value));
            });

        return AngularUtil.TrackLoadingPromise(promise, this);
    }

    AddFranchise(franchiseId: number)
    {
        let claimType = this.apiConfig.FranForceConstants.ClaimConstants.OwnedFranchiseIdClaimType;

        return this.identityManager.AddManagedFranchiseId(claimType, { Email: this.userState.managedUser.Email, FranchiseId: franchiseId })
            .then(() =>
            {
                this.RefreshUser();
            })
            .catch(AngularUtil.GetJsonAlertCatch());
    }

    RemoveFranchise(franchiseId: number)
    {
        let claimType = this.apiConfig.FranForceConstants.ClaimConstants.OwnedFranchiseIdClaimType;

        if (confirm(`Are you sure you want to remove this franchise?`))
        {
            return this.identityManager.RemoveManagedFranchiseId(claimType, { Email: this.userState.managedUser.Email, FranchiseId: franchiseId })
                .then(() =>
                {
                    this.RefreshUser();
                })
                .catch(AngularUtil.GetJsonAlertCatch());
        }
    }
    
    RefreshUser()
    {
        this.editUserState.Clear(this.userState.managedUser.Id);
        if (this.refresh) {
            this.refresh({
                refreshedDate: new Date()
            });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("editUserFranchiseOwnership", {
            bindings: {
                [nameof<EditUserFranchiseOwnershipComponentController>(o => o.managedUserId)]: "<",
                [nameof<EditUserFranchiseOwnershipComponentController>(o => o.refreshDate)]: "<",
                [nameof<EditUserFranchiseOwnershipComponentController>(o => o.refresh)]: "&"
            },
            controller: EditUserFranchiseOwnershipComponentController,
            templateUrl: "/Templates/Users/EditUser/EditUserFranchiseOwnership.html"
        });
    }
}

