import { AngularUtil } from "Utility/AngularUtil";
import { Helpers } from "Utility/Helpers";
import * as _ from "underscore"
import * as moment from "moment"


import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { GeoService } from "Services/Utility/GeoService";
import { CallCenterResources } from "Interfaces/FranForce/CallCenter/CallCenterResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { ApiConfig } from "AppConfig/ApiConfig";

interface LeadSourceDto {
    source_id:	number
    source_name: string
    group_id:	string
    country:	string
}
export class CallCenterPhoneCampaignsComponentController implements ng.IController
{
    //---- Bindings ----
    //------------------

    campaignGrid: kendo.ui.Grid;
    campaignGridOptions: kendo.ui.GridOptions;
    gridQueryResponseHeaders: ODataQueryResponseHeaders;
    budgetTypeOptions: kendo.ui.DropDownListOptions;
    numberTypeOptions: kendo.ui.DropDownListOptions;
    supportTypeOptions: kendo.ui.DropDownListOptions;
    callTypeOptions: kendo.ui.DropDownListOptions;
    countryOptions: kendo.ui.DropDownListOptions;
    introScriptCallTypeOptions: kendo.ui.DropDownListOptions;
    callCenterOptions: kendo.ui.DropDownListOptions;
    channelTypeOptions: kendo.ui.DropDownListOptions;
    leadSourceOptions: kendo.ui.DropDownListOptions;
    isGridBound: boolean;

    callTypeDropDown: kendo.ui.DropDownList;
    introScriptCallTypeDropDown: kendo.ui.DropDownList;

    concepts: CoreResources.IConcept[];
    callTypes: CallCenterResources.IC4CallType[];
    budgetTypes: CallCenterResources.IC4PhoneCampaignBudgetType[]; 
    numberTypes: CallCenterResources.IC4PhoneCampaignNumberType[];
    supportTypes: CallCenterResources.IC4PhoneCampaignSupportType[];
    callCenters: CallCenterResources.IC4CallCenter[];
    channelTypes: CallCenterResources.IC4ChannelType[];
    filteredCallTypes: CallCenterResources.IC4CallType[];
    leadSourceList: LeadSourceDto[]

    campaignWindow: kendo.ui.Window;
    campaign: CallCenterResources.IC4PhoneCampaign;
    franchiseFilter: kendo.data.DataSourceFilterItem;
    marchexVendorId:number=55;
    isMarchexVendor:boolean=false;
    isAdmin:boolean=false;
    isDisabled:boolean=false;

    static $inject = [
        "coreApiResources",
        'callCenterApiResources',
        'identityManager',
        'odataHelper',
        "geo",
        "$q",
        "$log",
        "$timeout",
        "apiConfig"
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private callCenterApiResources: CallCenterApiResources,
        private identityManager: IdentityManager,
        private odataHelper: ODataHelperService,
        private geo: GeoService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private apiConfig: ApiConfig
    )
    {

    }

    $onInit()
    {
        this.isGridBound = false;

        this.LoadLookupData().then(() => {
            this.SetDropdownOptions();
            this.SetGridOptions();
            this.identityManager.GetLoggedInUserInfo()
            .then((loggedInUser) =>
            {
              this.isAdmin = this.identityManager.UserHasRole(loggedInUser.data, [
                this.apiConfig.FranForceConstants.RoleConstants.AdminRole,
                this.apiConfig.FranForceConstants.RoleConstants.CallCenterAdmin,
              ]);
            });
        });
    }

    $postLink()
    {
        this.InitWindowOptions();
    }

    LoadLookupData() 
    {
        let conceptPromise = this.coreApiResources.ConceptApi.query({}).$promise.then((concepts) => {
            this.concepts = concepts;
        });

        let callTypesPromise = this.callCenterApiResources.C4CallTypesApi.query({}).$promise.then((callTypes) => {
            this.callTypes = callTypes;
        });

        let budgetTypesPromise = this.callCenterApiResources.C4PhoneCampaignBudgetTypesApi.query({}).$promise.then((budgetTypes) => {
            this.budgetTypes = budgetTypes;
        });

        let numberTypesPromise = this.callCenterApiResources.C4PhoneCampaignNumberTypesApi.query({}).$promise.then((numberTypes) => {
            this.numberTypes = numberTypes;
        });

        let supportTypesPromise = this.callCenterApiResources.C4PhoneCampaignSupportTypesApi.query({}).$promise.then((supportTypes) =>
        {
            this.supportTypes = supportTypes;
        });

        let callCentersPromise = this.callCenterApiResources.C4CallCentersApi.query({}).$promise.then((callCenters) =>
        {
            this.callCenters = callCenters
        });

        // Set parameters to include the allowed call types
        let channelTypesParams: ODataQueryParameters = {};
        channelTypesParams.$expand = `${nameof<CallCenterResources.IC4ChannelType>(o => o.C4CallTypes)}` +
            `($select=${nameof<CallCenterResources.IC4CallType>(o => o.C4CallTypeId)},${nameof<CallCenterResources.IC4CallType>(o => o.Name)})`;
        let channelTypesPromise = this.callCenterApiResources.C4ChannelTypesApi.query(channelTypesParams).$promise.then((channelTypes) => {
            this.channelTypes = channelTypes
        });
        let leadSourcePromise = this.callCenterApiResources.GetLeadSource().then((leadSourceList: LeadSourceDto[]) => {
            this.leadSourceList = leadSourceList
        });

        return this.$q.all([
            conceptPromise,
            callTypesPromise,
            budgetTypesPromise,
            numberTypesPromise,
            supportTypesPromise,
            callCentersPromise,
            channelTypesPromise,
            leadSourcePromise
        ]);
    }

    SetDropdownOptions()
    {
        this.countryOptions = {
            valuePrimitive: true,
            dataValueField: "name",
            dataTextField: "name",
            dataSource: new kendo.data.DataSource({
                data: [
                    { name: "United States" },
                    { name: "Canada" },
                ]
            })
        };

        this.budgetTypeOptions = {
            valuePrimitive: true,
            dataValueField: nameof<CallCenterResources.IC4PhoneCampaignBudgetType>(o => o.C4PhoneCampaignBudgetTypeId),
            dataTextField: nameof<CallCenterResources.IC4PhoneCampaignBudgetType>(o => o.Name),
            dataSource: new kendo.data.DataSource({ data: this.budgetTypes })
        };

        this.numberTypeOptions = {
            valuePrimitive: true,
            dataValueField: nameof<CallCenterResources.IC4PhoneCampaignNumberType>(o => o.C4PhoneCampaignNumberTypeId),
            dataTextField: nameof<CallCenterResources.IC4PhoneCampaignNumberType>(o => o.Name),
            dataSource: new kendo.data.DataSource({ data: this.numberTypes })
        };

        this.supportTypeOptions = {
            valuePrimitive: true,
            dataValueField: nameof<CallCenterResources.IC4PhoneCampaignSupportType>(o => o.C4PhoneCampaignSupportTypeId),
            dataTextField: nameof<CallCenterResources.IC4PhoneCampaignSupportType>(o => o.Name),
            dataSource: new kendo.data.DataSource({ data: this.supportTypes })
        };

        this.callTypeOptions = {
            valuePrimitive: true,
            dataValueField: nameof<CallCenterResources.IC4CallType>(o => o.C4CallTypeId),
            dataTextField: nameof<CallCenterResources.IC4CallType>(o => o.Name),
            dataSource: new kendo.data.DataSource({ data: this.callTypes })
        };

        this.introScriptCallTypeOptions = {
            valuePrimitive: true,
            dataValueField: nameof<CallCenterResources.IC4CallType>(o => o.C4CallTypeId),
            dataTextField: nameof<CallCenterResources.IC4CallType>(o => o.Name),
            dataSource: new kendo.data.DataSource({ data: this.callTypes })
        };

        this.callCenterOptions = {
            valuePrimitive: true,
            dataValueField: nameof<CallCenterResources.IC4CallCenter>(o => o.C4CallCenterId),
            dataTextField: nameof<CallCenterResources.IC4CallCenter>(o => o.Name),
            dataSource: new kendo.data.DataSource({ data: this.callCenters })
        };

        this.channelTypeOptions = {
            valuePrimitive: true,
            dataValueField: nameof<CallCenterResources.IC4ChannelType>(o => o.C4ChannelTypeId),
            dataTextField: nameof<CallCenterResources.IC4ChannelType>(o => o.Name),
            dataSource: new kendo.data.DataSource({ data: this.channelTypes }),
            select: (selectedChannel) => {
                // Reset any selected values
                if (!selectedChannel.dataItem.C4CallTypes.some(a => a.C4CallTypeId === this.campaign.C4CallTypeId)) {
                    this.campaign.C4CallTypeId = 0;
                }

                if (!selectedChannel.dataItem.C4CallTypes.some(a => a.C4CallTypeId === this.campaign.IntroScriptC4CallTypeId)) {
                    this.campaign.IntroScriptC4CallTypeId = 0;
                }

                // Create filters to the call type, based on the selected channel type
                let callTypeFilters: kendo.data.DataSourceFilters = {
                    logic: "or",
                    filters: []
                };

                selectedChannel.dataItem.C4CallTypes.forEach(callType => {
                    callTypeFilters.filters.push({
                        field: "C4CallTypeId",
                        operator: "eq",
                        value: callType.C4CallTypeId
                    });
                });

                // Apply filter to the call type drop down
                this.callTypeDropDown.dataSource.filter(callTypeFilters);
                this.callTypeDropDown.dataSource.read().then(() => {
                    this.callTypeDropDown.refresh();
                });

                // Apply filter to the intro script call type drop down
                this.introScriptCallTypeDropDown.dataSource.filter(callTypeFilters);
                this.introScriptCallTypeDropDown.dataSource.read().then(() => {
                    this.introScriptCallTypeDropDown.refresh();
                });
            }
        };

        this.leadSourceOptions = {
            valuePrimitive: true,
            dataValueField: nameof<LeadSourceDto>(o => o.source_id),
            dataTextField: nameof<LeadSourceDto>(o => o.source_name),
            optionLabel: "Select Lead Source...",
            dataSource: new kendo.data.DataSource({ data: this.leadSourceList })
        };

    }

    SetGridOptions()
    {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof.full<CallCenterResources.IC4PhoneCampaign>(o => o.Active),
                title: "&nbsp;",
                width: 50,
                attributes: {
                    "class": "active-icon-cell"
                },
                template: `<i ng-attr-title="{{dataItem.Active ? 'Active' : 'Disabled'}}" 
                                ng-class="'fa fa-circle ' + (dataItem.Active ? 'icon-success' : 'icon-error')">
                            </i>`
            }, {
                field: nameof.full<CallCenterResources.IC4PhoneCampaign>(o => o.C4CallCenter.Name),
                title: "Call Center"
            }, {
                field: nameof.full<CallCenterResources.IC4PhoneCampaign>(o => o.DisplayName),
                title: "Campaign"
            }, {
                field: nameof.full<CallCenterResources.IC4PhoneCampaign>(o => o.Concept.ConceptCode),
                title: "Brand"
            }, {
                field: nameof.full<CallCenterResources.IC4PhoneCampaign>(o => o.PhoneNumber),
                title: "Phone"
            }, {
                field: nameof.full<CallCenterResources.IC4PhoneCampaign>(o => o.C4PhoneCampaignNumberType.Name),
                title: "Number Type"
            }, {
                field: nameof.full<CallCenterResources.IC4PhoneCampaign>(o => o.StartDateTime),
                title: "Start Date",
                format: "{0: yyyy-MM-dd}",
            }, {
                field: nameof.full<CallCenterResources.IC4PhoneCampaign>(o => o.EndDateTime),
                title: "End Date",
                format: "{0: yyyy-MM-dd}"
            }, {
                template: `<button class='pure-button' ng-click='$ctrl.${nameof(this.OpenEditCampaignWindow)}(dataItem)'>Edit</button>`,
                width: 100,
                title: "&nbsp;"
            }, {
                template: `<button ng-disabled='dataItem.VendorId == ${this.marchexVendorId} ? true : false' class='pure-button button-error' ng-click='$ctrl.${nameof(this.DeleteCampaign)}(dataItem)'>X</button>`,
                width: 70,
                title: "&nbsp;"
            }];

        this.campaignGridOptions = {
            columns: columns,
            sortable: true,
            filterable: true,
            pageable: true,
            dataBound: (e) => {
                this.isGridBound = true;
            },
            dataSource: new kendo.data.DataSource({
                schema: {
                    model: {
                        id: "C4PhoneCampaignId",
                        fields: {
                            Active: { type: "boolean" },
                            DisplayName: { type: "string" },
                            PhoneNumber: { type: "string"},
                            StartDateTime: { type: "date" },
                            EndDateTime: { type: "date" },
                        }
                    },
                    total: (data) => {
                        return this.gridQueryResponseHeaders["odata-count"];
                    }
                },
                pageSize: 50,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport:
                {
                    read: (options: kendo.data.DataSourceTransportReadOptions) => {
                        this.isGridBound = false;
                        let queryParams: IRestfulServiceParameters = this.odataHelper.ConvertKendoDataSourceTransportReadOptionsDataToParameterObject(options.data);
                        queryParams.$count = true;
                        queryParams.$expand = `${nameof<CallCenterResources.IC4PhoneCampaign>(o => o.Concept)}($select=${nameof<CallCenterResources.IC4PhoneCampaign>(o => o.Concept.ConceptCode)}),` + 
                            `${nameof<CallCenterResources.IC4PhoneCampaign>(o => o.C4CallCenter)}($select=${nameof<CallCenterResources.IC4PhoneCampaign>(o => o.C4CallCenter.Name)}),` +
                            `${nameof<CallCenterResources.IC4PhoneCampaign>(o => o.C4PhoneCampaignNumberType)}($select=${nameof<CallCenterResources.IC4PhoneCampaign>(o => o.C4PhoneCampaignNumberType.Name)})`
                        
                        this.callCenterApiResources.C4PhoneCampaignsApi.query(queryParams, (data, responseHeaders) => { this.gridQueryResponseHeaders = responseHeaders() }).$promise
                            .then((campaigns) => {
                                for (let campaign of campaigns)
                                {
                                    campaign.StartDateTime = campaign.StartDateTime ? moment.utc(campaign.StartDateTime).local().toISOString() : null;
                                    campaign.EndDateTime = campaign.EndDateTime ? moment.utc(campaign.EndDateTime).local().toISOString() : null;

                                    if (!campaign.C4CallCenter) {
                                        campaign.C4CallCenter = <any>{ Name: "" };
                                    }

                                    if (!campaign.Concept) {
                                        campaign.Concept = <any>{ ConceptCode: "" };
                                    }

                                    if (!campaign.C4PhoneCampaignNumberType) {
                                        campaign.C4PhoneCampaignNumberType = <any>{ Name: "" };
                                    }
                                }
                                options.success(campaigns);
                            },
                            (err) => {
                                options.error(err);
                            });
                    }
                }
            })
        };
    } 

    InitWindowOptions() {
        this.campaignWindow.setOptions(<kendo.ui.WindowOptions>{
            modal: true
        });
    }

    OpenNewCampaignWindow()
    {
        this.campaign = new this.callCenterApiResources.C4PhoneCampaignsApi();
        this.campaign.C4PhoneCampaignId = 0;
        this.campaign.CreatedDateTime = new Date().toISOString();
        this.campaign.Active = true;
        this.campaign.ConceptId = 0;
        this.campaign.Country = "United States";
        this.campaign.Name = "";
        this.campaign.DisplayName = "";
        this.campaign.PhoneNumber = "";
        this.campaign.C4ChannelTypeId = 0;
        this.campaign.C4CallTypeId = 0;
        this.campaign.IntroScriptC4CallTypeId = 0;
        this.campaign.C4PhoneCampaignBudgetTypeId = 0;
        this.campaign.C4PhoneCampaignNumberTypeId = 0;
        this.campaign.C4CallCenterId = null;
        this.campaign.LeadSourceId = null;
        this.campaign.LeadSourceName = null;

        this.campaignWindow.setOptions(<kendo.ui.WindowOptions>{
            title: "Create Global Phone Campaign"
        });

        this.$timeout(() => {
            this.campaignWindow.center();
        }, 0);
        this.campaignWindow.open();
    }

    OpenEditCampaignWindow(campaign: CallCenterResources.IC4PhoneCampaign)
    {
        this.campaign = angular.copy(campaign);
        if(this.campaign.VendorId==this.marchexVendorId){
            this.isMarchexVendor=true;
            if(this.isAdmin){
                this.isDisabled=false;
            }else{
                this.isDisabled=true;
            }
        }else{
            this.isMarchexVendor=false;
        }

        this.campaignWindow.setOptions(<kendo.ui.WindowOptions>{
            title: "Edit Global Phone Campaign"
        });

        if (campaign.ConceptId) {
            this.SetFranchiseDropDownFilter(campaign.ConceptId);
        }

        this.$timeout(() => {
            this.campaignWindow.center();
        }, 0);
        this.campaignWindow.open();
    }
    
    SaveCampaign()
    {
        let campaignPromise: ng.IPromise<CallCenterResources.IC4PhoneCampaign>;

        this.campaign.PhoneNumber = Helpers.StripNonNumerices(this.campaign.PhoneNumber);

        let errorMessage = this.ValidateCampaign();
        if (errorMessage) {
            alert(errorMessage);
            return;
        }
        if(this.campaign.LeadSourceId){
            this.campaign.LeadSourceName = this.leadSourceList.filter((lead) => this.campaign.LeadSourceId === lead.source_id)[0].source_name;
        }else{
            this.campaign.LeadSourceId = null;
            this.campaign.LeadSourceName = null;
        }

        if (this.campaign.C4PhoneCampaignId)
        {
            this.campaign.C4CallCenter = null;
            this.campaign.C4PhoneCampaignBudgetType = null;
            this.campaign.C4PhoneCampaignNumberType = null;
            this.campaign.C4PhoneCampaignSupportType = null;
            this.campaign.Concept = null;

            campaignPromise = this.campaign.$update({ id: this.campaign.C4PhoneCampaignId });
        }
        else
        {
            campaignPromise = this.campaign.$save();
        }

        return campaignPromise
            .then(() => {
                return this.campaignGrid.dataSource.read().always(() => {
                    this.campaignWindow.close();
                });
            },
            AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback(this.$log, this.$q));
    }

    DeleteCampaign(campaign: CallCenterResources.IC4PhoneCampaign)
    {
        if (confirm(`Are you sure you want to delete '${campaign.DisplayName}'?`))
        {
            return this.callCenterApiResources.C4PhoneCampaignsApi.delete({ id: campaign.C4PhoneCampaignId }).$promise.then(
                () => {
                    return this.campaignGrid.dataSource.read().then(() => {
                        this.campaignGrid.refresh();
                    });
                },
                AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback(this.$log, this.$q)
            )
        }
    }

    private ValidateCampaign(): string 
    {
        let errorMessages: string[] = [];

        if (!this.campaign.Name) { errorMessages.push("You must enter a name."); }
        if (!this.campaign.DisplayName) { errorMessages.push("You must enter a display name."); }
        if (!this.campaign.ConceptId || this.campaign.ConceptId <= 0) { errorMessages.push("You must select a concept."); }
        if (!this.campaign.PhoneNumber) { errorMessages.push("You must enter a phone number."); }
        if (!this.campaign.Country) { errorMessages.push("You must select a country."); }
        if (!this.campaign.C4PhoneCampaignNumberTypeId) { errorMessages.push("You must select a number type."); }
        if (!this.campaign.C4PhoneCampaignBudgetTypeId) { errorMessages.push("You must select a budget type."); }
        if (!this.campaign.C4PhoneCampaignSupportTypeId) { errorMessages.push("You must select a support type."); }
        if (this.campaign.C4PhoneCampaignNumberTypeId == 2 && !this.campaign.C4CallCenterId) { errorMessages.push("You must select a call center."); }
        if (!this.campaign.C4ChannelTypeId) { errorMessages.push("You must select a channel type."); }
        if (!this.campaign.C4CallTypeId) { errorMessages.push("You must select a call type."); }
        if (!this.campaign.IntroScriptC4CallTypeId) { errorMessages.push("You must select an intro script call type."); }
        
        return errorMessages.join("\n");
    }

    OnlyActive() {
        this.campaignGrid.dataSource.filter({
            logic: "and", filters: [
                { field: "Active", operator: "eq", value: true },
                {
                    logic: "or", filters: [
                        { field: "StartDateTime", operator: "isnull" },
                        { field: "StartDateTime", operator: "lt", value: new Date() },
                    ]
                },
                {
                    logic: "or", filters: [
                        { field: "EndDateTime", operator: "isnull" },
                        { field: "EndDateTime", operator: "gt", value: new Date() },
                    ]
                }
            ]
        });
        return this.campaignGrid.dataSource.read().then(() => {
            this.campaignGrid.refresh();
        });
    }

    OnlyInactive()
    {
        this.campaignGrid.dataSource.filter({
            logic: "or", filters: [
                { field: "Active", operator: "eq", value: false },
                {
                    logic: "and", filters: [
                        { field: "StartDateTime", operator: "isnotnull" },
                        { field: "StartDateTime", operator: "gt", value: new Date() },
                    ]
                },
                {
                    logic: "and", filters: [
                        { field: "EndDateTime", operator: "isnotnull" },
                        { field: "EndDateTime", operator: "lt", value: new Date() },
                    ]
                }
            ]
        });
        return this.campaignGrid.dataSource.read().then(() => {
            this.campaignGrid.refresh();
        });
    }

    SelectConcept(concept: CoreResources.IConcept)
    {
        if (!concept) {
            this.campaign.ConceptId = null;
            this.campaign.FranchiseId = null;
            this.franchiseFilter = null;
            return;
        }

        if (concept.ConceptId == this.campaign.ConceptId) {
            return;
        }

        this.campaign.ConceptId = concept.ConceptId;
        this.SetFranchiseDropDownFilter(concept.ConceptId);
    }

    SetFranchiseDropDownFilter(conceptId: number)
    {
        this.franchiseFilter = {
            field: nameof<CoreResources.IConcept>(o => o.ConceptId),
            operator: 'eq',
            value: conceptId
        }
    }

    ClearFilters() 
    {
        this.campaignGrid.dataSource.filter([]);
        return this.campaignGrid.dataSource.read().then(() => {
            this.campaignGrid.refresh();
        });
    }

    GetConceptById(id: number)
    {
        return _.find(this.concepts, c => c.ConceptId == id);
    }

    GetCallTypeById(id: number)
    {
        return _.find(this.callTypes, c => c.C4CallTypeId == id);
    }

    GetCampaignNumberTypeById(id: number)
    {
        return _.find(this.numberTypes, n => n.C4PhoneCampaignNumberTypeId == id);
    }

    GetCampaignBudgetTypeById(id: number)
    {
        return _.find(this.budgetTypes, b => b.C4PhoneCampaignBudgetTypeId == id);
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("callCenterPhoneCampaigns", {
            bindings: {
            },
            controller: CallCenterPhoneCampaignsComponentController,
            templateUrl: "/Templates/C4/CallCenterPhoneCampaigns.html",
        });
    }
}

