import { LegalApiResources } from "Services/Resources/LegalApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { LegalEntities } from "Interfaces/FranForce/Legal/LegalResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

interface INewSectionTwoScope extends ng.IScope {
    cancelationOptions: LegalEntities.CancelationType[];
    CancelationOptionValue: number[];
    WaiverOptionValue: number[];
    contractId: string;
    franchiseId: string;
    selectionDetails: { franchiseID: string, contractID: string };
    activeSection: { section: string }
    model: LegalEntities.SectionTwo;
    newSectionTwoForm: any;
    submitSectionTwo: () => void;
    waiverOptions: LegalEntities.WaiverType[];
    cancelSectionTwo: () => void;
    paymentOptions: LegalEntities.PaymentOption[];
    paymentForOptions: LegalEntities.PaymentForOption[];
    userEmail: string;
    kNotifierOptions: any;
    kNotifier: kendo.ui.Notification;
    saveSuccessful: boolean;
    saveBtnDisabled: boolean;
    denyBtnDisabled: boolean;
}

export class NewSectionTwoController implements ng.IController {

    static $inject = [
        "$scope",
        "legalApiResources",
        "$window",
        "$q",
        "$log",
        "$http",
        "coreApiResources",
        "identityManager"
    ];

    constructor(
        private $scope: INewSectionTwoScope,
        private legalApiResources: LegalApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $http: ng.IHttpService,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager
    )
    {
        $scope.saveBtnDisabled = false;
        $scope.denyBtnDisabled = false;

        $scope.kNotifierOptions = {
            position: {
                top: 50,
                left: $(window).width() / 2
            },
            templates: [{
                type: 'ngTemplate',
                template: $('#kNTemplate').html(),
            }]
        };
    }

    $onInit() {
        let $scope = this.$scope;
        let $http = this.$http;
        let legalApiResources = this.legalApiResources;
        let coreApiResources = this.coreApiResources;
        let $q = this.$q;
        let identityManager = this.identityManager;
        // Initialize Approval Sign-Off
        $scope.model = <any>{};
        identityManager.GetLoggedInUserInfo().then(
            (success) => {
                $scope.userEmail = success.data.Email;
            },
            (error) => {
                console.log(error);
            });     
        // Assign selection details to retrieve associated data
        $scope.franchiseId = location.pathname.split("/")[3] || $scope.selectionDetails.franchiseID;
        $scope.contractId = location.pathname.split("/")[4] || $scope.selectionDetails.contractID;

        coreApiResources.FranchiseApi.get({ $expand: "Concept", id: $scope.franchiseId },
            (successResponse: CoreEntities.Franchise) => {
                $scope.$emit("setFranchiseDetails", {
                    FranchiseName: successResponse.Concept.DisplayName,
                    LicenseNum: successResponse.LicenseNumber,
                    FranchiseeCode: $scope.franchiseId,
                    FranchiseAddress: successResponse.Address,
                    FranchisePhoneNumber: successResponse.StorePhone,
                    FranchiseEmail: successResponse.StoreEmail
                });
            },
            errorResponse => {
                console.log(errorResponse);
            });

        legalApiResources.CancelationTypeApi.query({}, (successResponse: LegalEntities.CancelationType[]) => {
            $scope.cancelationOptions = successResponse;
        }, errorResponse => {
                console.log(errorResponse);
            });

        legalApiResources.WaiverTypeApi.query({}, (successResponse: LegalEntities.WaiverType[]) => {
            $scope.waiverOptions = successResponse;
        }, errorResponse => {
                console.log(errorResponse);
            });

        legalApiResources.PaymentOptionApi.query({}, (successResponse: LegalEntities.PaymentOption[]) => {
            $scope.paymentOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
            });

        legalApiResources.PaymentForOptionApi.query({}, (successResponse: LegalEntities.PaymentForOption[]) => {
            $scope.paymentForOptions = successResponse;
        }, errorResponse => {
            console.log(errorResponse);
        });

        $scope.cancelSectionTwo = () => {
            finishSectionTwo(false);
        }

        $scope.submitSectionTwo = () => {
            if ($scope.newSectionTwoForm.$valid) {
                finishSectionTwo(true);
            } else {
               
                $scope.saveSuccessful = false;
                $scope.kNotifier.show({
                    kValue: "Some field(s) require input to proceed "
                }, "ngTemplate");

                Object.keys($scope.newSectionTwoForm.$error).forEach(function (value) {
                    $scope.newSectionTwoForm.$error[value].forEach(function (item) {
                        item.$setDirty();
                    });
                });
            }
        }

        function finishSectionTwo(isSubmit: boolean) {
            $scope.saveBtnDisabled = true;
            $scope.denyBtnDisabled = true;

            if (isSubmit) {
                $scope.model.ApprovedBy = $scope.userEmail;
                $scope.model.ApprovedDate = kendo.toString(new Date(), 'MM/dd/yyyy');
            }

            legalApiResources.SectionTwoApi.save($scope.model, (successCallback: LegalEntities.SectionTwo) => {
                let promises = [];
                if ($scope.CancelationOptionValue != undefined && $scope.CancelationOptionValue.length > 0) {
                    $scope.CancelationOptionValue.forEach(function (value) {
                        promises.push(legalApiResources.SectionTwoCancelationTypeApi.save({ CancelationTypeID: value, SectionTwoID: successCallback.SectionTwoID }, cancelResponse => {

                        }, errorCallback => {
                            console.log(errorCallback);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        }).$promise);
                    });
                }

                if ($scope.WaiverOptionValue != undefined && $scope.WaiverOptionValue.length > 0) {
                    $scope.WaiverOptionValue.forEach(function (value) {
                        promises.push(legalApiResources.SectionTwoWaiverTypeApi.save({ WaiverTypeID: value, SectionTwoID: successCallback.SectionTwoID }, cancelResponse => {

                        }, errorCallback => {
                            console.log(errorCallback);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        }).$promise);
                    });
                }

                $q.all(promises).then(successResponse => {
                    if (isSubmit) {
                        legalApiResources.ContractStatuApi.query({ $filter: "Description eq 'Section 2 Completed'" }, (contractStatuses: LegalEntities.ContractStatu[]) => {
                            legalApiResources.ContractApi.get({ id: $scope.contractId }, (sectionTwoResponse: LegalEntities.Contract) => {
                                sectionTwoResponse.SectionTwoID = successCallback.SectionTwoID;
                                sectionTwoResponse.ContractStatusID = contractStatuses[0].ContractStatusID;
                                sectionTwoResponse.LastUpdated = new Date().toISOString();
                                promises.push(legalApiResources.ContractApi.update({ id: $scope.contractId }, sectionTwoResponse, sectionTwoChangeResponse => {
                                    window.location.href = "/Itt/NewSectionThree/" + $scope.franchiseId + "/" + $scope.contractId;
                                }, errorResponse => {
                                    $scope.kNotifier.show({
                                        kValue: "Failed to save to server. Please contact an admin."
                                    }, "ngTemplate");
                                    console.log(errorResponse);
                                    $scope.saveBtnDisabled = false;
                                    $scope.denyBtnDisabled = false;
                                }).$promise);
                            }, errorResponse => {
                                $scope.kNotifier.show({
                                    kValue: "Failed to save to server. Please contact an admin."
                                }, "ngTemplate");
                                console.log(errorResponse);
                                $scope.saveBtnDisabled = false;
                                $scope.denyBtnDisabled = false;
                            });
                        }, errorResponse => {
                            $scope.kNotifier.show({
                                kValue: "Failed to save to server. Please contact an admin."
                            }, "ngTemplate");
                            console.log(errorResponse);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        });
                    } else {
                        legalApiResources.SectionTwoDenied($scope.contractId, successCallback.SectionTwoID).then(successCallback => {
                            window.location.href = "/Itt";
                        }, errorCallback => {
                            $scope.kNotifier.show({
                                kValue: "Failed to save to server. Please contact an admin."
                            }, "ngTemplate");
                            console.log(errorCallback);
                            $scope.saveBtnDisabled = false;
                            $scope.denyBtnDisabled = false;
                        });
                    }
                }, errorResponse => {
                    $scope.kNotifier.show({
                        kValue: "Failed to save to server. Please contact an admin."
                    }, "ngTemplate");
                    console.log(errorResponse);
                    $scope.saveBtnDisabled = false;
                    $scope.denyBtnDisabled = false;
                });

            }, errorCallback => {
                $scope.kNotifier.show({
                    kValue: "Failed to save to server. Please contact an admin."
                }, "ngTemplate");
                console.log(errorCallback);
                $scope.saveBtnDisabled = false;
                $scope.denyBtnDisabled = false;
            });    
        }
    }
}


