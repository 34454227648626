import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { RmsStateService } from "Services/State/RmsState";
import * as _ from "underscore"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { franchiseEntityRelationshipPeriodReportDataModel } from "../../../Models/Royalty/PeriodReport/FranchiseEntityRelationshipPeriodReportDataModel";

export class PeriodReportInfoDisplayComponentController implements ng.IController 
{
    isLoading: boolean;
    isDynamic: boolean;
    royaltyPeriodReportId: number;
    royaltyPeriodReport: RpmEntities.RoyaltyPeriodReportViewModel;
    adjustmentTotal: number;
    royaltyPeriodReportEntityRelationshipData: franchiseEntityRelationshipPeriodReportDataModel;
    showPeriodReportEntityRelationshipDataTable: boolean;
    
    static $inject = [
        'coreApiResources',
        '$q',
        '$window',
        '$location',
        'rpmUiApiResources',
        'rpmHelper',
        'rmsState',
        'royaltyApiResources'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private rmsState: RmsStateService,
        private royaltyApiResources: RoyaltyApiResources,
    )
    {
    }

    $onInit()
    {
        this.showPeriodReportEntityRelationshipDataTable = false;
    }
    
    $postLink()
    {

    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
        if (onChanges[nameof(this.royaltyPeriodReportId)] && this.royaltyPeriodReportId)
        {
            this.isLoading = true;
            let promises = <ng.IPromise<any>[]>[];

            this.isDynamic = $('#hdnIsDynamicFeesEnabledPm').val() === 'True';
            promises.push(this.rmsState.royaltyPeriodReportStateHandler.Load(this.royaltyPeriodReportId, this.isDynamic)
                .then((royaltyPeriod) =>
                {
                    this.royaltyPeriodReport = royaltyPeriod;
                })
            );

            promises.push(this.GetAdjustmentTotal()
                .then((adjustmentTotal) =>
                {
                    this.adjustmentTotal = adjustmentTotal;
                })
            );

            promises.push(
                this.royaltyApiResources.GetFranchiseEntityRelationshipForRoyaltyPeriod(this.royaltyPeriodReportId)
                    .then(
                        (response) => {
                            if (response && response.data) {
                                this.royaltyPeriodReportEntityRelationshipData = response.data;
                                if (this.royaltyPeriodReportEntityRelationshipData.franchiseEntityRelationshipId && this.royaltyPeriodReportEntityRelationshipData.franchiseEntityRelationshipId != 0) {
                                    this.showPeriodReportEntityRelationshipDataTable = true;
                                }
                            }
                        }).catch(error => {
                            alert("error in loading franchise entity relationship for royalty period report data");
                        })
            );

            this.$q.all(promises)
                .finally(() => { this.isLoading = false; });
        }
    }

    GetAdjustmentTotal()
    {
        return this.rpmUiApiResources.GetRoyaltyPeriodReportAdjustmentsByRoyaltyPeriodReport(this.royaltyPeriodReportId)
            .then(adjustmentsResponse =>
            {
                return _.reduce(adjustmentsResponse.data, (runningTotal, adjustment) => { return runningTotal + adjustment.TotalAdjustment; }, 0);
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('periodReportInfoDisplay', {
            bindings: {
                [nameof<PeriodReportInfoDisplayComponentController>(o => o.royaltyPeriodReportId)]: "<"
            },
            controller: PeriodReportInfoDisplayComponentController,
            templateUrl: "/Templates/PeriodReport/PeriodReportInfoDisplay.html"
        });
    }
}


