import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CallCenterEntities } from "Interfaces/FranForce/CallCenter/CallCenterResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

export abstract class PushLeadOptionController<T> implements ng.IController {
    // Reference type
    callTypes: CallCenterEntities.C4CallType[];
    leadPushTypes: CoreEntities.LeadPushTypeOption[];
    leadPushOptions: T[];

    // Properties
    abstract disableOptions: boolean;
    abstract conceptId: number;
    abstract canFranchiseOverride: boolean;

    // Kendo options
    leadOptionsGrid: kendo.ui.Grid;
    leadOptionsGridOptions: kendo.ui.GridOptions;
    gridDataOptions: Array<PushLeadOptionGridObject>;

    static $inject = [
        'callCenterApiResources',
        'coreApiResources',
        '$q',
        '$log',
        '$timeout',
        '$window'
    ];

    constructor(
        protected callCenterApiResources: CallCenterApiResources,
        protected coreApiResources: CoreApiResources,
        protected $q: ng.IQService,
        protected $log: ng.ILogService,
        protected $timeout: ng.ITimeoutService,
        protected $window: ng.IWindowService) {
    }

    abstract LoadLookupData(): ng.IPromise<any>;
    abstract MapLeadPushOptions(): Array<PushLeadOptionGridObject>;
    abstract UpdateLeadOption(pushLeadOption: PushLeadOptionGridObject): ng.IPromise<any>;
    abstract CreateLeadOption(pushLeadOption: PushLeadOptionGridObject): ng.IPromise<any>;
    abstract DefineNewPushLeadOption(callTypeId: number): PushLeadOptionGridObject;

    $onInit() {
        this.LoadCallTypes()
            .then(() => this.LoadLeadPushTypes())
            .then(() => this.RefreshGridData())
            .then(() => this.SetGridOptions());
    }

    RefreshGridData(): ng.IPromise<any> {
        return this.LoadLookupData()
            .then(() => this.ComposeDataForGrid());
    }

    LoadCallTypes(): ng.IPromise<any> {
        // Retrieve call types
        return this.callCenterApiResources.C4CallTypesApi
            .query({
                $select: "C4CallTypeId,Name",
                $filter: "Name ne 'Customer Follow Up'"
            }).$promise
            .then((callTypes) => {
                this.callTypes = callTypes;
            });
    }

    LoadLeadPushTypes(): ng.IPromise<any> {
        // Retrieve lead push types
        return this.coreApiResources.LeadPushTypeOptionApi
            .query({
                $select: "LeadPushTypeOptionId,ConceptId,C4CallTypeId,LeadPushTypeId",
                $expand: "LeadPushType",
                $filter: `ConceptId eq ${this.conceptId}`
            }).$promise
            .then((leadPushTypes) => {
                this.leadPushTypes = leadPushTypes;
            });
    }
    
    ComposeDataForGrid() {
        // Initialize original list of values
        this.gridDataOptions = [];

        // Add already existing items to the collection
        this.gridDataOptions = this.MapLeadPushOptions();

        // Add missing items to the collection
        this.callTypes.forEach((callType) => {
            if (!this.gridDataOptions.find((a) => a.C4CallTypeId == callType.C4CallTypeId)) {
                this.gridDataOptions.push(this.DefineNewPushLeadOption(callType.C4CallTypeId));
            }
        });

        // Sort collection by call type id
        this.gridDataOptions.sort((a, b) => a.C4CallTypeId - b.C4CallTypeId);
    }

    protected GetCallTypeName(callTypeId: number): string {
        return this.callTypes.find((a) => a.C4CallTypeId == callTypeId).Name;
    }

    SetGridOptions() {
        // Create columns
        let columns: kendo.ui.GridColumn[] = this.CreateColumns();

        // Create grid
        this.leadOptionsGridOptions = {
            columns: columns,
            sortable: false,
            filterable: false,
            pageable: false,
            dataSource: {
                data: this.gridDataOptions
            }
        }
    }

    CreateColumns(): kendo.ui.GridColumn[] {
        let pushLeadTemplate: string = '';
        let conceptOptions: CoreEntities.LeadPushType[] = [];

        this.leadPushTypes.forEach(option => {
            if (!conceptOptions.find(a => a.LeadPushTypeId === option.LeadPushTypeId)) {
                conceptOptions.push(option.LeadPushType);
            }
        });
        conceptOptions.sort((a, b) => a.LeadPushTypeId - b.LeadPushTypeId);

        conceptOptions.forEach(option => {
            pushLeadTemplate += `<kendo-button class="pure-button"
                                    ng-show="$ctrl.ShowLeadOption(${option.LeadPushTypeId}, dataItem.C4CallTypeId)" 
                                    ng-disabled="${this.disableOptions}"
                                    ng-click="$ctrl.SaveLeadOption(dataItem, ${option.LeadPushTypeId})"
                                    ng-class="dataItem.LeadPushTypeId === ${option.LeadPushTypeId} ? 'button-save' : 'button-error'">
                                    ${option.Name}
                                </kendo-button>`
        });

        return [
            {
                field: "C4CallTypeName",
                title: "Call Type"
            },
            {
                title: "Push Lead",
                template: pushLeadTemplate
            }
        ];
    }

    ShowLeadOption(leadPushTypeId: number, c4CallTypeId: number) {
        const option = this.leadPushTypes.find(a => a.LeadPushTypeId === leadPushTypeId && a.C4CallTypeId === c4CallTypeId);
        return option && option != null;
    }

    SaveLeadOption(pushLeadOption: PushLeadOptionGridObject, leadPushTypeId: number) {
        if (pushLeadOption.LeadPushTypeId !== leadPushTypeId) {
            // Toggle the lead option
            pushLeadOption.LeadPushTypeId = leadPushTypeId;

            if (pushLeadOption.LeadPushOptionId) {
                // Update lead option
                return this.UpdateLeadOption(pushLeadOption);
            }
            else {
                // Create lead option
                return this.CreateLeadOption(pushLeadOption);
            }
        }
    }
}

export class PushLeadOptionGridObject {
    constructor(
        public C4CallTypeId: number,
        public ObjectId: number,
        public LeadPushOptionId: number,
        public LeadPushTypeId: number,
        public C4CallTypeName: string) {
    }
}