import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import { isApi404 } from '../../Utility/isApi404';
import
{
   GenderDto,
   LanguageDto
} from '@nbly/franchise-employee-group-clients';
import
{
    FranchiseProfileEmployeeBiosRm,FranchiseProfileEmployeeBioCertificationClient,
    FranchiseProfileEmployeeBioDto,FranchiseProfileEmployeeBioSearchDto,
    GetFranchiseProfileEmployeeBioCertificationDto,
    CertificationRm
} from '@nbly/franchise-employee-orchestrations-clients'



export class EmployeeBiosCertificationComponent implements ng.IController
{
    //---- Bindings ----
    franchiseId: number;
    genderList: GenderDto[];
    languageList: LanguageDto[];
    conceptCode: string;
    employeeBioDetail: FranchiseProfileEmployeeBioDto;
    employeeBio: FranchiseProfileEmployeeBioSearchDto;
    certificateList:GetFranchiseProfileEmployeeBioCertificationDto[];
    onApiSave: () => void;
    //------------------

    conceptId: number;
    isLoading: boolean;
    cetrificatesMultiSelect: kendo.ui.MultiSelect;
    cetrificatesMultiSelectOptions: kendo.ui.MultiSelectOptions;
    certificatesNotFound:boolean;
    selectedCertificates: any[];
    
    //Clients
    employeeOrchestrationsClient: AxiosInstance;
    franchiseProfileEmployeeBioCertificationClient: FranchiseProfileEmployeeBioCertificationClient;
    
    static $inject = [
        "coreApiResources",
        "callCenterApiResources",
        "callCenterState",
        "identityManager",
        "apiConfig",
        "$timeout",
    ];

    constructor()
    {

    }

    async $onInit()
    {
        this.employeeOrchestrationsClient = FranForceAxiosClientBuilder.BuildEmployeeOrchestrationsBaseClient();
        this.franchiseProfileEmployeeBioCertificationClient = new FranchiseProfileEmployeeBioCertificationClient("", this.employeeOrchestrationsClient);
  
        this.convertToCertificateModel();
    }

    $postLink(){
         this.SetDropdownOptions();
    }

    convertToCertificateModel(){
        if(this.employeeBioDetail && this.employeeBioDetail.franchiseProfileEmployeeBioDetail && this.employeeBioDetail.franchiseProfileEmployeeBioDetail.franchiseProfileEmployeeBioCertificationDetails)
        {
            if(this.employeeBioDetail.franchiseProfileEmployeeBioDetail.franchiseProfileEmployeeBioCertificationDetails.length){
                this.selectedCertificates=[];
                this.employeeBioDetail.franchiseProfileEmployeeBioDetail.franchiseProfileEmployeeBioCertificationDetails.map((val)=>{
                        this.selectedCertificates.push(val.franchiseProfileEmployeeBioCertificationId)
                    });
            }
        }
    }
    async SaveCertificateDetails(){
        let selectedCerts: CertificationRm=<CertificationRm>{};
        selectedCerts.certificationIds=this.selectedCertificates;
         await this.franchiseProfileEmployeeBioCertificationClient.bulkMergeAll(
            this.employeeBioDetail.franchiseProfileEmployeeBioDetail.franchiseProfileEmployeeBioDetailsId,
            selectedCerts
        ).then((response)=>{
            this.onApiSave();
            console.log('Certificates Saved')
        })  
    }

    async SetDropdownOptions()
    {
        this.cetrificatesMultiSelectOptions =
        {
            placeholder: "Select certificates...",
            dataTextField: "description",
            dataValueField: "franchiseProfileEmployeeBioCertificationId",
            valuePrimitive: true,
            autoBind: true,
            dataSource: this.certificateList ,
            dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) =>
            {
                this.cetrificatesMultiSelect = e.sender;
            },
        };
  
 
    }

 
    static BindComponent(app: ng.IModule)
    {
        app.component("employeeBiosCertification", {
            bindings: {
                franchiseId: "<",
                [nameof<EmployeeBiosCertificationComponent>(o => o.genderList)]: "<",
                [nameof<EmployeeBiosCertificationComponent>(o => o.languageList)]: "<",
                [nameof<EmployeeBiosCertificationComponent>(o => o.certificateList)]: "<",
                [nameof<EmployeeBiosCertificationComponent>(o => o.conceptCode)]: "<",
                [nameof<EmployeeBiosCertificationComponent>(o => o.employeeBioDetail)]: "=",
                [nameof<EmployeeBiosCertificationComponent>(o => o.employeeBio)]: "=",
                onApiSave: "&",
            },
            controller: EmployeeBiosCertificationComponent,
            templateUrl: "/Templates/EmployeeBios/EmployeeBiosCertification.html",
        });
    }
}

