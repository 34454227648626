import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyPeriodReportStatusesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetAllRoyaltyPeriodReportStatuses()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriodReportStatuses/"
        };

        return this.$http<RpmEntities.usp_RoyaltyPeriodReportStatus_GetByRoyaltyPeriodReportStatusID_Result[]>(config);
    }

    GetRoyaltyPeriodReportStatusById(id: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriodReportStatuses/" + id
        };

        return this.$http<RpmEntities.usp_RoyaltyPeriodReportStatus_GetByRoyaltyPeriodReportStatusID_Result>(config);
    }

    GetRoyaltyPeriodReportStatusesForBatchManagement()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriodReportStatuses/ByBatchManagement"
        };

        return this.$http<RpmEntities.usp_RoyaltyPeriodReportStatus_GetBySetViaBatchManagement_Result[]>(config);
    }
}
    
