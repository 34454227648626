import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { MerchantApiResources } from "Services/Resources/MerchantApiResources";
import { KendoUtil } from "Utility/KendoUtil";
import { AngularUtil } from "Utility/AngularUtil";
import { ApiConfig } from "AppConfig/ApiConfig";
import * as moment from "moment"
import { MerchantResources } from "Interfaces/FranForce/Merchant/MerchantResources";


export class IndexMsMollyDonationsController implements ng.IController
{
    mainGrid: kendo.ui.Grid;
    gridOptions: kendo.ui.GridOptions;
    
    refundRequests: IRefundMsMollyDonationRequest[];

    static $inject = [
        "$scope",
        "coreApiResources",
        "merchantApiResources",
        "$q",
        "$log",
        "$window",
        "$timeout",
        "apiConfig"
    ];

    constructor(
        private $scope: ng.IScope,
        private coreApiResources: CoreApiResources,
        private merchantApiResources: MerchantApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $window: ng.IWindowService,
        private $timeout: ng.ITimeoutService,
        private apiConfig: ApiConfig,
    )
    {

    }

    $onInit()
    {
        this.gridOptions = {
            sortable: true,
            pageable: {
                numeric: false,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true
            },
            resizable: true,
            height: this.GetGridHeight(),
            columns: [{
                field: "MsMollyDonationId",
                title: "Id",
            }, {
                field: "CreatedDateTime",
                title: "Created",
                format: "{0:yyyy-MM-dd}",
                filterable: {
                    ui: "datetimepicker"
                }
            }, {
                field: "Amount",
                title: "Amount",
                encoded: false,
            }, {
                field: "ShelterName",
                title: "Shelter"
            }, {
                field: "DonorName",
                title: "Name",
            }, {
                field: "DonorPhone",
                title: "Phone",
            }, {        
                field: "DonorEmail",
                title: "Email",
            }, {
                field: "IsCurrentCustomer",
                title: "Customer",
                template: "#= IsCurrentCustomer ? 'Yes' : 'No'#"
            }, {
                field: "RefundedDateTime",
                title: "Refunded On",
                format: "{0:yyyy-MM-dd}",
                filterable: {
                    ui: "datetimepicker"
                }
            }]
        };

        this.refundRequests = [];

        this.Search();
    }

    GetDonationSelectQuery() {
        return "MsMollyDonationId,Amount,CreatedDateTime,DonorFirstName,DonorLastName,DonorPhone,DonorEmail,DonorAddress,DonorCity,DonorState,DonorPostalCode,Comments,IsCurrentCustomer"
    }

    GetDonationExpandQuery() {
        return "RefundMerchantTransaction($select=CreatedDateTime,CreatedUser,TransactionReference),MsMollyShelter($select=Name)";
    }

    DonationsToGridItems(donations: angular.resource.IResourceArray<MerchantResources.IMsMollyDonation>): Array<IMsMollyDonationGridItem>
    {
        let displayList: Array<IMsMollyDonationGridItem> = donations.map<IMsMollyDonationGridItem>(d =>
        {
            let momentRefundedDateTime = moment.utc(d.RefundMerchantTransaction ? d.RefundMerchantTransaction.CreatedDateTime : null);
            return {
                MsMollyDonationId: d.MsMollyDonationId,
                CreatedDateTime: moment.utc(d.CreatedDateTime).toDate(),
                Amount: d.Amount,
                DonorName: (d.DonorFirstName || "") + " " + (d.DonorLastName || ""),
                DonorPhone: d.DonorPhone,
                DonorEmail: d.DonorEmail,
                DonorLocation: d.DonorAddress + " - " + d.DonorCity + " " + d.DonorState + " " + d.DonorPostalCode,
                Comments: d.Comments,
                IsCurrentCustomer: d.IsCurrentCustomer,
                ShelterName: d.MsMollyShelter ? d.MsMollyShelter.Name : "",
                RefundedDateTime: momentRefundedDateTime.isValid() ? momentRefundedDateTime.toDate() : null,
                RefundTransactionReference: d.RefundMerchantTransaction ? d.RefundMerchantTransaction.TransactionReference : null,
                RefundCreatedUser: d.RefundMerchantTransaction ? d.RefundMerchantTransaction.CreatedUser : null,
                RefundNotes: d.RefundNotes
            }
        });
        return displayList;
    }

    Search()
    {
        return this.merchantApiResources.MsMollyDonationApi.query(
            {
                $select: this.GetDonationSelectQuery(),
                $expand: this.GetDonationExpandQuery(),
                $filter: "MerchantTransactionId ne null",
                $orderby: "CreatedDateTime desc"
            }).$promise
            .then(this.DonationsToGridItems)
            .then((gridItems) => {
                this.mainGrid.setDataSource(new kendo.data.DataSource({
                    data: gridItems,
                    schema: {
                        model: {
                            fields: {
                                MsMollyDonationId: { type: "number" },
                                CreatedDateTime: { type: "date" },
                                Amount: { type: "number" },
                                DonorName: { type: "string" },
                                DonorPhone: { type: "string" },
                                DonorEmail: { type: "string" },
                                ShelterName: { type: "string" },
                                IsCurrentCustomer: { type: "boolean" },
                                RefundedDateTime: { type: "date" },
                                AdditionalFields: { type: "string" }
                            }
                        }
                    },
                    pageSize: this.mainGrid.pager.pageSize()
                }));

                this.refundRequests = [];
                let unsortedRequests = gridItems.map<IRefundMsMollyDonationRequest>(d => {
                    return {
                        MsMollyDonationId: d.MsMollyDonationId,
                        IsManual: false,
                        Notes: ""
                    };
                });
                for(let r of unsortedRequests){
                    this.refundRequests[r.MsMollyDonationId] = r;
                }
            });
    }

    Refund(donationRefundRequest: IRefundMsMollyDonationRequest, gridItem: IMsMollyDonationGridItem)
    {
        if (gridItem.RefundedDateTime)
        {
            alert(`This donation has already been refunded`);
            return;
        }
        else
        {
            let confirmationMessage = `Are you sure you want to refund the $${gridItem.Amount} donation made by ${gridItem.DonorName}? ` +
                (donationRefundRequest.IsManual ?
                    "Please note that by selecting a manual refund, you will be responsible for making sure that the refund takes place in some other form (ex: via check in the mail). " +
                    "Make sure good notes are taken on the manual refund. Proceed with refund?" : "");
            if (confirm(confirmationMessage))
            {
                this.merchantApiResources.RefundMsMollyDonation(donationRefundRequest).then(
                    () => {
                        this.Search();
                    }, AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback(this.$log, this.$q));
            }
        }
    }

    GetCsvLink()
    {
        return `${this.apiConfig.AppSettings.CoreApiRootUrl}/MsMollyDonations/RenderCsv?access_token=${(<ILocalStorage>localStorage).FranForceAuthToken}`; 
    }
    
    GetGridHeight()
    {
        let mainGrid = angular.element("#mainGrid");
        let windowEle = angular.element(this.$window);

        let gridHeight = windowEle.height() - mainGrid.offset().top - 15;

        if (gridHeight < 250) {
            gridHeight = 250;
        }
        return gridHeight;
    }
}