import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";

export class FranchiseProfileEmailsComponentController implements ng.IController 
{
    franchiseId: number;

    freeEmailCount: number;
    purchasedEmailCount: number;

    emailGrid: kendo.ui.Grid;

    static $inject = [
        'coreApiResources',
        '$q',
        '$log',
        '$timeout',
        'odataHelper'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private odataHelper: ODataHelperService
    )
    {
    }

    $onInit()
    {
    }

    $postLink()
    {
        this.InitGridOptions();
    }

    InitGridOptions()
    {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: "",
                title: "Primary Email"
            }, {
                field: "",
                title: "Reply To Email"
            }, {
                field: "",
                title: "Email Type"
            }, {
                field: "",
                title: "Bill Date"
            }, {
                field: "",
                title: "Status"
            }, {
                field: "",
                title: "Date"
            }
        ];

        let dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) =>
                {

                }
            }
        });

        let options: kendo.ui.GridOptions = {
            columns: columns,
            dataSource: dataSource,
            editable: false,
            sortable: true,
            filterable: true,
            pageable: true,
        }

        this.emailGrid.setOptions(options);
    }

    AddEmailPopup()
    {

    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfileEmails', {
            bindings: {
                franchiseId: "<"
            },
            controller: FranchiseProfileEmailsComponentController,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfileEmails.html"
        });
    }
}



        