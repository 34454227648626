import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import * as _ from "underscore";
import { AxiosInstance } from "axios";
import { IntegrationFinanceClient, IntegrationFinanceInvoiceCreateSetRoyaltiesStatusRm, IntegrationFinanceInvoiceCreateRm } from "@nbly/billing-orchestrations-clients";
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";
import { royaltyInvoiceReversalFileDataModel } from "../../../Models/Royalty/RoyaltyBatchManagement/RoyaltyInvoiceReversalFileDataModel";

export class BatchManagementFinanceIntegrationInvoiceReversalGridComponentController implements ng.IController {
    // -- Bindings --
    invoicesDataSource: kendo.data.DataSource;
    selectedBatchIds: number[];
    type: string;
    onInvoicesVoided: (params: {}) => void;
    onVoidingInvoicesCancel: () => void;
    // --
    isLoading: boolean;

    showErrorMessage: boolean;
    errorMessage: string;

    invoiceGrid: kendo.ui.Grid;
    invoicesGridOptions: kendo.ui.GridOptions;

    billingOrchestrationsClient: AxiosInstance;
    integrationFinanceClient: IntegrationFinanceClient;

    private readonly VOIDED_ROYALTY_REPORT_BATCH_STATUS_ID = 9;


    static $inject = [
        'coreApiResources',
        'rpmUiApiResources',
        'rpmHelper',
        '$timeout',
        '$q',
        '$scope',
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
        private $scope: ng.IScope) {
        this.billingOrchestrationsClient = FranForceAxiosClientBuilder.BuildBillingOrchestrationsBaseClient();
        this.integrationFinanceClient = new IntegrationFinanceClient("", this.billingOrchestrationsClient);
    }

    $onInit() {
        //if (this.invoicesDataSource)
        //    this.invoicesDataSource.read();
        this.ErrorMessage(false, "");
    }

    $postLink() {
        this.InitInvoiceGrid();
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.invoicesDataSource)] && this.invoiceGrid) {
            this.invoiceGrid.setDataSource(this.invoicesDataSource);
            this.ErrorMessage(false, "");
        }
    }

    ErrorMessage(show: boolean, message: string) {
        this.showErrorMessage = show;
        this.errorMessage = message;
    }

    InitInvoiceGrid() {
        let invoicesColumns: kendo.ui.GridColumn[] = [{
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.date),
            title: "Date",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.invoiceType),
            title: "InvoiceType",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.invoiceNumber),
            title: "InvoiceNumber",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.customerID),
            title: "CustomerID",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.itemDescription),
            title: "ItemDescription",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.itemNumber),
            title: "ItemNumber",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.quantity),
            title: "Quantity",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.unitPrice),
            title: "UnitPrice",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.extAmount),
            title: "ExtAmount",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.overRideTaxDate),
            title: "OverRideTaxDate",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.gpDatabase),
            title: "GPDatabase",
        }, {
            field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.avalaraCompanyCode),
            title: "AvalaraCompanyCode",
        }];

        const invoicesGridOptions = {
            columns: invoicesColumns,
            noRecords: {
                template: "No data available on current page. Current page is: #=this.dataSource.page()#"
            },
            dataBound: (e) => {
            },
            selectable: false,
            editable: false,
            scrollable: true,
            sortable: true,
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: false
        };

        this.invoiceGrid.setOptions(invoicesGridOptions);
    }

    CancelVoidingInvoices() {
        this.onVoidingInvoicesCancel();
    }

    MarkInvoicesVoided() {
        let exportIds = <number[]>this.invoiceGrid.dataSource.data()
            .map((result: { royaltyInvoiceExportBatchID: number }) => {
                return result.royaltyInvoiceExportBatchID;
            });
        exportIds = _.uniq(exportIds);

        if (exportIds.length < 1) {
            this.ErrorMessage(true, "No data available!");
            return;
        }

        return this.$q.all(
            exportIds.map((id) => {
                this.isLoading = true;

                if (this.type && this.type == "adjustment") {
                    return this.integrationFinanceClient.integrationFinanceAdjustmentInvoices(id)
                }
                else {
                    let integrationFinanceInvoiceCreateRm: IntegrationFinanceInvoiceCreateSetRoyaltiesStatusRm = {
                        royaltyInvoiceExportBatchId: id,
                        royaltyReportBatchIds: this.selectedBatchIds,
                        royaltyReportBatchStatusId: this.VOIDED_ROYALTY_REPORT_BATCH_STATUS_ID
                    }
                    return this.integrationFinanceClient.setRoyaltiesStatus(integrationFinanceInvoiceCreateRm)
                }
            })
        )
            .then((response) => {
                if (this.onInvoicesVoided) {
                    this.onInvoicesVoided({});
                }
            })
            .catch(err => {
                this.ErrorMessage(true, "Error! Failed to populate staging & mark as posted");
            })
            .finally(() => {
                this.isLoading = false;
            })
    }

    


    static BindComponent(app: ng.IModule) {
        app.component('bmFinanceIntegrationInvoiceReversalGrid', {
            bindings: {
                [nameof<BatchManagementFinanceIntegrationInvoiceReversalGridComponentController>(o => o.invoicesDataSource)]: "<",
                [nameof<BatchManagementFinanceIntegrationInvoiceReversalGridComponentController>(o => o.selectedBatchIds)]: "<",
                [nameof<BatchManagementFinanceIntegrationInvoiceReversalGridComponentController>(o => o.type)]: "<",
                [nameof<BatchManagementFinanceIntegrationInvoiceReversalGridComponentController>(o => o.onInvoicesVoided)]: "&",
                [nameof<BatchManagementFinanceIntegrationInvoiceReversalGridComponentController>(o => o.onVoidingInvoicesCancel)]: "&",
            },
            controller: BatchManagementFinanceIntegrationInvoiceReversalGridComponentController,
            templateUrl: "/Templates/BatchManagement/bmFinanceIntegrationInvoiceReversalGrid.html"
        });

    }
}