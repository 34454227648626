import * as elements from 'typed-html';
import { ExpressionBinding } from 'Utility/AngularUtil';
import { StringProperties } from 'Types/StringProperties';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            weekEndingDateSelector: StringProperties<Partial<WeekEndingDateSelector>>;
        }
    }
}

export class WeekEndingDate {
    weekEndingDateValue: Date;
    weekEndingDateLabel: string;
}

export type WeekEndingDateSelectorOnSelectParams = {
    event: kendo.ui.DropDownListSelectEvent,
    weekEndingDate: WeekEndingDate;
}

export class WeekEndingDateSelector implements ng.IController {
    
    onSelect: ExpressionBinding<WeekEndingDateSelectorOnSelectParams>;
    dropDown: kendo.ui.DropDownList;

    //bindings
    weekEndingDate: Date;
    readOnly: boolean;
    year: number;
    dayOfWeek: number;
    //end bindings

    static $inject = [

    ]

    constructor() {

    }

    $onInit() {
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.setOptions({ enable: !this.readOnly })
        }
        if (changes[nameof(this.year)] && this.dropDown) {
            this.InitDropdown();
        }
    }

    $postLink() {
        this.InitDropdown();
        if (this.weekEndingDate) {
            this.dropDown.select(x => x.weekEndingDateValue.getTime() === this.weekEndingDate.getTime());
            this.dropDown.trigger("change");       
        }
    }

    InitDropdown() {
        if (this.year && this.dayOfWeek) {
            this.dropDown.setOptions(
                {
                    autoBind: true,
                    valuePrimitive: true,
                    dataValueField: nameof<WeekEndingDate>(o => o.weekEndingDateValue),
                    dataTextField: nameof<WeekEndingDate>(o => o.weekEndingDateLabel),
                    optionLabel: "Select...",
                    optionLabelTemplate: "Select...",
                    autoWidth: true,
                    enable: !this.readOnly,
                    select: (e) => {
                        if (this.onSelect) {
                            this.onSelect({
                                event: e,
                                weekEndingDate: e.dataItem
                            });
                        }
                    }
                } as kendo.ui.DropDownListOptions
            );

            let dataSource = new kendo.data.DataSource({
                transport: {
                    read: (options) => {
                        let weekEndingDates: WeekEndingDate[] = [];
                        let start = new Date("01/01/" + this.year);
                        let end = new Date("12/31/" + this.year);
                        var current = new Date(start);
                        while (current <= end) {
                            if (this.dayOfWeek === current.getDay()) {
                                let newWeekEndingDateValue = new Date(current);
                                let newWeekEndingDateLabel = (newWeekEndingDateValue.getMonth() + 1).toString() + "/" + newWeekEndingDateValue.getDate().toString() + "/" + newWeekEndingDateValue.getFullYear().toString();
                                weekEndingDates.push({
                                    weekEndingDateValue: newWeekEndingDateValue,
                                    weekEndingDateLabel: newWeekEndingDateLabel
                                });
                            }
                            current = new Date(current.setDate(current.getDate() + 1));
                        }
                        options.success(weekEndingDates);
                    }
                }
            });

            this.dropDown.setDataSource(dataSource);
        }
    }

    static BindComponent(app: ng.IModule) {
        const template =
            <select kendo-drop-down-list={`$ctrl.${nameof<WeekEndingDateSelector>(o => o.dropDown)}`}
                k-ng-model={`$ctrl.${nameof<WeekEndingDateSelector>(o => o.weekEndingDate)}`}
            >
            </select>;

        app.component(nameof<JSX.IntrinsicElements>(o => o.weekEndingDateSelector), {
            bindings: {
                [nameof<WeekEndingDateSelector>(o => o.weekEndingDate)]: "<",
                [nameof<WeekEndingDateSelector>(o => o.readOnly)]: "<",
                [nameof<WeekEndingDateSelector>(o => o.year)]: "<",
                [nameof<WeekEndingDateSelector>(o => o.dayOfWeek)]: "<",
                [nameof<WeekEndingDateSelector>(o => o.onSelect)]: "&?",
            },
            template: template,
            controller: WeekEndingDateSelector
        });
    }


}