export interface GeoState {
    Abbreviation: string;
    Name: string;
}

export class GeoService {

    private UnitedStates: GeoState[];
    private UnitedStatesTerritories: GeoState[];
    private CanadianProvinces: GeoState[];

    static $inject = [];

    constructor()
    {
        this.UnitedStates = [
            { Abbreviation: "AL", Name: "Alabama" },
            { Abbreviation: "AK", Name: "Alaska" },
            { Abbreviation: "AZ", Name: "Arizona" },
            { Abbreviation: "AR", Name: "Arkansas" },
            { Abbreviation: "CA", Name: "California" },
            { Abbreviation: "CO", Name: "Colorado" },
            { Abbreviation: "CT", Name: "Connecticut" },
            { Abbreviation: "DE", Name: "Delaware" },
            { Abbreviation: "FL", Name: "Florida" },
            { Abbreviation: "GA", Name: "Georgia" },
            { Abbreviation: "HI", Name: "Hawaii" },
            { Abbreviation: "ID", Name: "Idaho" },
            { Abbreviation: "IL", Name: "Illinois" },
            { Abbreviation: "IN", Name: "Indiana" },
            { Abbreviation: "IA", Name: "Iowa" },
            { Abbreviation: "KS", Name: "Kansas" },
            { Abbreviation: "KY", Name: "Kentucky" },
            { Abbreviation: "LA", Name: "Louisiana" },
            { Abbreviation: "ME", Name: "Maine" },
            { Abbreviation: "MD", Name: "Maryland" },
            { Abbreviation: "MA", Name: "Massachusetts" },
            { Abbreviation: "MI", Name: "Michigan" },
            { Abbreviation: "MN", Name: "Minnesota" },
            { Abbreviation: "MS", Name: "Mississippi" },
            { Abbreviation: "MO", Name: "Missouri" },
            { Abbreviation: "MT", Name: "Montana" },
            { Abbreviation: "NE", Name: "Nebraska" },
            { Abbreviation: "NV", Name: "Nevada" },
            { Abbreviation: "NH", Name: "New Hampshire" },
            { Abbreviation: "NJ", Name: "New Jersey" },
            { Abbreviation: "NM", Name: "New Mexico" },
            { Abbreviation: "NY", Name: "New York" },
            { Abbreviation: "NC", Name: "North Carolina" },
            { Abbreviation: "ND", Name: "North Dakota" },
            { Abbreviation: "OH", Name: "Ohio" },
            { Abbreviation: "OK", Name: "Oklahoma" },
            { Abbreviation: "OR", Name: "Oregon" },
            { Abbreviation: "PA", Name: "Pennsylvania" },
            { Abbreviation: "RI", Name: "Rhode Island" },
            { Abbreviation: "SC", Name: "South Carolina" },
            { Abbreviation: "SD", Name: "South Dakota" },
            { Abbreviation: "TN", Name: "Tennessee" },
            { Abbreviation: "TX", Name: "Texas" },
            { Abbreviation: "UT", Name: "Utah" },
            { Abbreviation: "VT", Name: "Vermont" },
            { Abbreviation: "VA", Name: "Virginia" },
            { Abbreviation: "WA", Name: "Washington" },
            { Abbreviation: "WV", Name: "West Virginia" },
            { Abbreviation: "WI", Name: "Wisconsin" },
            { Abbreviation: "WY", Name: "Wyoming" }
        ];

        this.UnitedStatesTerritories = [
            { Abbreviation: "AS", Name: "American Samoa" },
            { Abbreviation: "DC", Name: "District Of Columbia" },
            { Abbreviation: "FM", Name: "Federated States Of Micronesia" },
            { Abbreviation: "GU", Name: "Guam" },
            { Abbreviation: "MH", Name: "Marshall Islands" },
            { Abbreviation: "MP", Name: "Northern Mariana Islands" },
            { Abbreviation: "PW", Name: "Palau" },
            { Abbreviation: "VI", Name: "Virgin Islands" },
        ];

         this.CanadianProvinces = [
             { Abbreviation: "AB", Name: "Alberta" },
             { Abbreviation: "BC", Name: "British Columbia" },
             { Abbreviation: "MB", Name: "Manitoba" },
             { Abbreviation: "NB", Name: "New Brunswick" },
             { Abbreviation: "NL", Name: "Newfoundland and Labrador" },
             { Abbreviation: "NS", Name: "Nova Scotia" },
             { Abbreviation: "NT", Name: "Northwest Territories" },
             { Abbreviation: "NU", Name: "Nunavut" },
             { Abbreviation: "ON", Name: "Ontario" },
             { Abbreviation: "PE", Name: "Prince Edward Island" },
             { Abbreviation: "QC", Name: "Québec" },
             { Abbreviation: "SK", Name: "Saskatchewan" },
             { Abbreviation: "YT", Name: "Yukon" }
         ];
    }

    GetUnitedStates(includeNonStates: boolean): GeoState[]
    {
        if (includeNonStates)
            return [
                ...this.UnitedStates,
                ...this.UnitedStatesTerritories
            ];
        else
            return this.UnitedStates.slice();
    }

    GetCanadianProvinces() {
        return this.CanadianProvinces.slice();
    }

    GetByCountryName(countryName: string, includeNonStates: boolean)
    {
        if (!countryName)
            return [];

        switch (countryName.toLowerCase().trim()) {
            case "canada":
                return this.GetCanadianProvinces();
            case "united states":
            case "us":
                return this.GetUnitedStates(includeNonStates);
            default:
                return [];
        }
    }
}
