import * as elements from 'typed-html';

import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import { ExpressionBinding } from 'Utility/AngularUtil';
import { StringProperties } from 'Types/StringProperties';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            yearSelector: StringProperties<Partial<YearSelector>>;
        }
    }
}

export class Year {
    year: number;
}

export type YearSelectorOnSelectParams = {
    event: kendo.ui.DropDownListSelectEvent,
    year: Year;
}

export class YearSelector implements ng.IController {
    year: number;
    readOnly: boolean;
    onSelect: ExpressionBinding<YearSelectorOnSelectParams>;
    dropDown: kendo.ui.DropDownList;

    yearFrom: number;
    yearTo: number;

    static $inject = [

    ]

    constructor(
        private rpmUiApiResources: RpmUiApiResources
    ) {

    }

    $onInit() {
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.setOptions({ enable: !this.readOnly })
        }

        if ((changes[nameof(this.yearFrom)] || changes[nameof(this.yearTo)]) && this.dropDown) {
            this.InitDropdown();
        }
    }

    $postLink() {
        this.InitDropdown();
        if (this.year) {
            this.dropDown.select(x => x.year == this.year);
            this.dropDown.trigger("change");
        }
    }

    InitDropdown() {
        this.dropDown.setOptions(
            {
                autoBind: true,
                valuePrimitive: true,
                dataValueField: nameof<Year>(o => o.year),
                dataTextField: nameof<Year>(o => o.year),
                optionLabel: "Select...",
                optionLabelTemplate: "Select...",
                autoWidth: true,
                enable: !this.readOnly,
                select: (e) => {
                    if (this.onSelect) {
                        this.onSelect({
                            event: e,
                            year: e.dataItem
                        });
                    }
                }
            } as kendo.ui.DropDownListOptions
        );

        let dataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    if (this.yearFrom && this.yearTo) {
                        let yearList: Year[] = [];
                        for (let i = this.yearFrom; i <= this.yearTo; i++) {
                            yearList.push({ year: i });
                        }
                        yearList.reverse();
                        options.success(yearList);
                    }
                }
            }
        });

        this.dropDown.setDataSource(dataSource);
    }

    static BindComponent(app: ng.IModule) {
        const template =
            <select kendo-drop-down-list={`$ctrl.${nameof<YearSelector>(o => o.dropDown)}`}
                k-ng-model={`$ctrl.${nameof<YearSelector>(o => o.year)}`}
            >
            </select>;

        app.component(nameof<JSX.IntrinsicElements>(o => o.yearSelector), {
            bindings: {
                [nameof<YearSelector>(o => o.year)]: "<",
                [nameof<YearSelector>(o => o.readOnly)]: "<",
                [nameof<YearSelector>(o => o.yearFrom)]: "<",
                [nameof<YearSelector>(o => o.yearTo)]: "<",
                [nameof<YearSelector>(o => o.onSelect)]: "&?",
            },
            template: template,
            controller: YearSelector
        });
    }


}