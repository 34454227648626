import { RmsDashboardSalesVarianceResultVm, RoyaltyApprovalClient, RoyaltySyncInvoicesForApprovalRm, RoyaltyUserApprovalConfigurationClient, ApproveRoyaltyReportBatchRm } from '@nbly/royalty-orchestrations-clients';
import { AxiosInstance } from 'axios';
import { FranForceAxiosClientBuilder } from '../../../../Clients/FranForceAxiosClientBuilder';
import { selectedInvoiceForRejectionModel } from '../../../../../Models/RmsApprovals/selectedInvoiceForRejectionModel';

export class rmsPreApprovalQueueActionController implements ng.IController {

    //bindings
    showLoader: (params: { isLoading: boolean }) => void;
    //end-bindings

    royaltyOrchestrationsClient: AxiosInstance;
    royaltyApprovalClient: RoyaltyApprovalClient

    rejectBatchNoteDialog: kendo.ui.Dialog;

    royaltyReportBatchId: number;
    royaltyApprovalStatusTypeId: number;
    royaltyApprovalRequiredReasonTypeId: number;
    selectedInvoicesForRejection: selectedInvoiceForRejectionModel[];
    disableActions: boolean;
    rejectBatchReason: string;

    static $inject = [
        "$rootScope",
        "$scope",
        "$window",
    ];

    constructor(
        private $rootScope: ng.IRootScopeService,
        private $scope: ng.IScope,
        private $window: ng.IWindowService,
    ) {
        this.royaltyOrchestrationsClient = FranForceAxiosClientBuilder.BuildRoyaltyOrchestrationsBaseClient();
        this.royaltyApprovalClient = new RoyaltyApprovalClient("", this.royaltyOrchestrationsClient);
    }

    $onInit() {
        this.disableActions = true;
        this.rejectBatchReason = "";
        this.$rootScope.$on('preApprovalAllInputsSelected', async (event, args) => {
            if (args) {
                this.royaltyReportBatchId = args.royaltyReportBatchId;
                this.royaltyApprovalStatusTypeId = args.royaltyApprovalStatusTypeId;
                this.royaltyApprovalRequiredReasonTypeId = args.royaltyApprovalRequiredReasonTypeId;

                if (this.royaltyApprovalStatusTypeId == 2) //needs approval
                {
                    this.disableActions = false;
                }
                else {
                    this.disableActions = true;
                }
            }
        });

        this.$rootScope.$on('preApprovalRejectInvoiceChanged', async (event, args) => {
            if (args) {
                this.selectedInvoicesForRejection = args.selectedInvoicesForRejection;
            }
        });
    }

    $postLink() {
        this.rejectBatchNoteDialog.setOptions(<kendo.ui.DialogOptions>{
            title: "Reject Batch",
            modal: true,
            width: "40%",
            maxHeight: 300,
            animation: true,
            actions: null,
            open: (e) => {
                this.rejectBatchReason = "";
            }
        })
    }

    OnRejectBatchAfterNoteCancel() {
        this.rejectBatchReason = "";
        this.rejectBatchNoteDialog.close();
    }

    OnRejectInvoices() {
        this.$rootScope.$emit('preApprovalRejectInvoices', {
            show: true,
        });
    }

    async OnRejectBatchAfterNote() {
        if (this.rejectBatchReason.trim() == "") {
            this.ShowRmsNotification("error", "Please Enter A Reason For Rejection");
            return;
        }
        else {
            let approveRoyaltyReportBatchRm: ApproveRoyaltyReportBatchRm = {
                isApproved: false,
                royaltyReportBatchId: this.royaltyReportBatchId,
                user: "",
                rejectedBatchNote: this.rejectBatchReason,
                rejectedInvoices: []
            }

            this.showLoader({ isLoading: true });
            await this.royaltyApprovalClient.approveRoyaltyReportBatch(approveRoyaltyReportBatchRm)
                .then(x => {
                    this.$rootScope.$emit('reassignBatchApprovalStatus', {
                        royaltyApprovalStatusTypeId: 4 //rejected
                    });

                    this.rejectBatchNoteDialog.close();

                    this.disableActions = true;

                    this.ShowRmsNotification("success", "Batch Rejected Successfully.");
                })
                .catch(ex => {
                    this.ShowRmsNotification("error", "Error! Unable To Reject Batch");
                })
                .finally(() => {
                    this.showLoader({ isLoading: false });
                    this.$scope.$digest();
                });
        }

    }

    OnRejectBatch() {
        this.rejectBatchNoteDialog.open();
    }

    async OnApproveBatch() {
        if (this.selectedInvoicesForRejection.length > 0) {
            this.$rootScope.$emit('preApprovalApproveBatch', {
                show: true,
                royaltyReportBatchId: this.royaltyReportBatchId
            });
        }
        else {

            let approveRoyaltyReportBatchRm: ApproveRoyaltyReportBatchRm = {
                isApproved: true,
                royaltyReportBatchId: this.royaltyReportBatchId,
                user: "",
                rejectedBatchNote: null,
                rejectedInvoices: []
            }

            this.showLoader({ isLoading: true });
            await this.royaltyApprovalClient.approveRoyaltyReportBatch(approveRoyaltyReportBatchRm)
                .then(x => {
                    this.$rootScope.$emit('reassignBatchApprovalStatus', {
                        royaltyApprovalStatusTypeId: 3 //Approved
                    });

                    this.disableActions = true;

                    this.ShowRmsNotification("success", "Batch Approved Successfully.");
                })
                .catch(ex => {
                    this.ShowRmsNotification("error", "Error! Unable To Approve Batch");
                })
                .finally(() => {
                    this.showLoader({ isLoading: false });
                    this.$scope.$digest();
                });
        }

    }

    ShowRmsNotification(type: "error" | "success", message: string) {
        let elementId = "rms-notification-element";
        let notificationElement = this.$window.document.getElementById(elementId);
        if (!notificationElement) {
            notificationElement = this.$window.document.createElement("div");
            notificationElement.id = elementId;
            this.$window.document.body.appendChild(notificationElement);
        }

        let notification = angular.element(notificationElement).kendoNotification({
            autoHideAfter: 4000,
            position: {
                pinned: true,
                top: 60,
                right: 0,
            },
            animation: {
                open: {
                    effects: "slideIn:left"
                },
                close: {
                    effects: "slideIn:left",
                    reverse: true
                }
            },
            stacking: "down",
        }).data("kendoNotification");

        notification.show(message, type);
    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsPreApprovalQueueAction", {
            bindings: {
                [nameof<rmsPreApprovalQueueActionController>(o => o.showLoader)]: "&",
            },
            templateUrl: '/Templates/RMSApproval/RmsPreApprovalQueue/rmsPreApprovalQueueAction.html',
            controller: rmsPreApprovalQueueActionController
        });
    }
}