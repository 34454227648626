
import { IdentityManager } from "../../../Services/Resources/IdentityManager";
export class financeIntegrationDashboardComponentController implements ng.IController {

    showSnackbar: boolean;
    snackbarType: string;
    snackbarMessage: string;
    sectionNames: string[];
    tab: string;

    static $inject = [
        '$window',
        '$location',
        '$q',
        'identityManager',
        '$scope'
    ];

    constructor(
        private $window: ng.IWindowService,
        private $location: ng.ILocationService,
        private $q: ng.IQService,
        private identityManager: IdentityManager,
        private $scope: ng.IScope
    ) {

    }

    $onInit() {
        this.sectionNames = [];
        this.sectionNames.push("Finance Integration Dashboard");
        this.sectionNames.push("Finance Integration Imports");
        this.sectionNames.push("Import Entries");
        this.getRequiredUserRoles();
        this.tab = this.GetSection();
    }

    DisplaySnackbar(type: string, message: string) {
        this.showSnackbar = !this.showSnackbar;
        this.snackbarType = type;
        this.snackbarMessage = message;
        this.$scope.$evalAsync();
    }

    async getRequiredUserRoles() {

        await this.identityManager.GetLoggedInUserInfo().then(successResponse => {

            if (successResponse && successResponse.data && successResponse.data.Roles && successResponse.data.Roles.length > 0) {

                if (this.sectionNames && this.sectionNames.length && this.sectionNames.length > 0) {
                    this.tab = this.GetSection();
                }

                this.$scope.$digest();

            }
        }).catch(ex => {

            console.log("Error! Unable to get user roles.", ex);
        });
    }

    GetSection() {
        let section = this.$location.search()[nameof<financeIntegrationDashboardComponentController>(o => o.tab)];
        let isValid = this.IsValidSection(section);
        let result = isValid ? section : this.sectionNames[0]
        if (!isValid) {
            this.SetSection(result);
        }
        return result;
    }

    IsValidSection(sectionName: string): boolean {
        return this.sectionNames.some(n => n === sectionName);
    }

    SetSection(sectionName: string) {

        /*       if (this.tab == "Approval Configurations") {
      
                  if (this.isApprovalConfigurationTabUnsaved) {
                      let proceed = confirm("Are you sure you want to proceed without saving the changes?");
                      if (!proceed) {
                          return;
                      }
                      else {
                          this.isApprovalConfigurationTabUnsaved = false;
                      }
                  }
      
                  this.$scope.$broadcast('approvalQueueInit', {});
              }
      
              if (!this.IsValidSection(sectionName)) {
                  throw "Invalid Section to Set: " + sectionName;
              } */

        this.tab = sectionName;
        this.$location.search(nameof<financeIntegrationDashboardComponentController>(o => o.tab), sectionName);
    }

    static BindComponent(app: ng.IModule) {
        app.component("financeIntegrationDashboard", {
            bindings: {

            },
            templateUrl: '/Templates/FinanceIntegrationDashboard/financeIntegrationDashboard.html',
            controller: financeIntegrationDashboardComponentController
        });
    }
}