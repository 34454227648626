import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { Helpers } from "Utility/Helpers";
import * as _ from "underscore"
import { IEditDisasterPrepProfileSectionScope } from "@DppApp/EditDisasterPrepProfileSectionController";
import { DppResources, DppEntities } from "Interfaces/FranForce/Dpp/DppResources";
import { CoreEntities, CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { TraceLevel } from "@TypeGen/Enums";
import { IDppAndSummary } from "Interfaces/FranForce/Portal/IDppAndSummary";

interface IFinishDisasterPrepProfileScope extends IEditDisasterPrepProfileSectionScope {
    pdfUrl: string;
    profile: DppResources.IDisasterPrepProfile;
    summary: DppEntities.DppCompletenessSummary;
    treeData: kendo.data.HierarchicalDataSource;
    emailSelectOptions: kendo.ui.MultiSelectOptions;
    multiSelectEmails: kendo.ui.MultiSelect;
    selectedEmails: string[];

    systemEmails: string[];
    userDefinedEmails: string[];
    mergedEmailDataSource: string[];
    
    DisplayPercent: (percentDecimal: number) => string;
    GetTraceLevelCssClass: (traceLevel: TraceLevel) => string;
    EmailPdfs: () => void;
    RefreshEmails: () => void;
    RebindEmails: () => void;
    AddUserDefinedEmail: (email: string) => void;
}

export class FinishDisasterPrepProfileController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('FinishDisasterPrepProfileController',
            [
                '$scope', 'dppApiResources', 'coreApiResources', '$window', '$q',
                ($scope: IFinishDisasterPrepProfileScope, dppApiResources: DppApiResources, coreApiResources: CoreApiResources, $window: ng.IWindowService, $q: ng.IQService) =>
                {
                    let date = new Date();

                    $scope.selectedEmails = [];
                    $scope.systemEmails = [];
                    $scope.userDefinedEmails = [];
                    $scope.mergedEmailDataSource = [];

                    $scope.emailSelectOptions = {
                        placeholder: "Select profile emails to send pdf to...",
                        //dataTextField: "EmailAddress",
                        //dataValueField: "EmailAddress",
                        valuePrimitive: true,
                        autoBind: true
                    };

                    dppApiResources.GetDppAndSummary($scope.dppId).then((dppAndSummary: IDppAndSummary) =>
                    {
                        $scope.profile = dppAndSummary.dpp;
                        $scope.summary = dppAndSummary.summary;
                        dppAndSummary.summary.RootMessage["expanded"] = true;
                        dppAndSummary.summary.RootMessage.Children.forEach(child => child["expanded"] = true);

                        $scope.treeData = new kendo.data.HierarchicalDataSource({
                            data: dppAndSummary.summary.RootMessage,
                            schema: {
                                model: {
                                    children: "Children",
                                    fields: {
                                        AdditionalDetails: { field: "AdditionalDetails", type: "string" },
                                        CheckName: { field: "CheckName", type: "string" },
                                        TraceLevel: { field: "TraceLevel", type: "number" },
                                        PropertySummaries: { field: "PropertySummaries", type: "Object" }
                                    },
                                    hasChildren: (e) =>
                                    {
                                        return e.Children && e.Children.length;
                                    }
                                }
                            }
                        });
                    }, (err) => { console.error(err) });

                    $scope.RefreshEmails = () =>
                    {
                        let promises = new Array<ng.IPromise<any>>();
                        $scope.systemEmails = new Array<string>();

                        promises.push(dppApiResources.DisasterPrepProfileContactApi.query(
                            { $select: "EmailAddress", $filter: "DppId eq " + $scope.dppId + " and EmailAddress ne null" }).$promise.then(
                                (contacts: DppResources.IDisasterPrepProfileContact[]) =>
                                {
                                    $scope.systemEmails.push(...contacts.map(val => val.EmailAddress));
                                },
                                (err) =>
                                {
                                    console.error(err);
                                }
                            ));

                        promises.push(dppApiResources.DisasterPrepProfileServiceProviderApi.query(
                            {
                                $select: "DppExternalProviderId,FranchiseId,UseFranchise",
                                $expand: "DisasterPrepProfileExternalProvider($select=EmailAddress)",
                                $filter: "DisasterPrepProfileBuilding/DppId eq " + $scope.dppId + " and DisasterPrepProfileBuilding/DeletedDateTime eq null"
                            }).$promise.then(
                                (providers: DppResources.IDisasterPrepProfileServiceProvider[]) =>
                                {
                                    $scope.systemEmails.push(...providers.filter((val) =>
                                    {
                                        return (!val.UseFranchise &&
                                            !!val.DisasterPrepProfileExternalProvider &&
                                            !!val.DisasterPrepProfileExternalProvider.EmailAddress);
                                    }).map(val => val.DisasterPrepProfileExternalProvider.EmailAddress));

                                    let franchiseIds = providers.filter((val) =>
                                    {
                                        return val.UseFranchise;
                                    }).map(val => val.FranchiseId);

                                    franchiseIds = _.uniq(franchiseIds, false);

                                    let findFranchiseEmailPromises = [];

                                    let chunkedFranchiseIds = Helpers.ChunkSplit(franchiseIds, 20);
                                    for (let idsChunk of chunkedFranchiseIds) {
                                        let promise = coreApiResources.FranchiseApi
                                            .query({ $select: "StoreEmail", $filter: "FranchiseId/any(id:contains(id)" })
                                            .$promise.then((franchises: Array<CoreResources.IFranchise>) =>
                                            {
                                                $scope.systemEmails.push(...franchises.map(f => f.StoreEmail));
                                            });
                                        findFranchiseEmailPromises.push(promise);
                                    }
                                    return $q.all(findFranchiseEmailPromises);
                                },
                                (err) =>
                                {
                                    console.error(err);
                                }
                            ));

                        promises.push(dppApiResources.DisasterPrepProfileApi.get({ id: $scope.dppId, $select: "FranchiseId" })
                            .$promise.then(
                                (dppProfile: DppResources.IDisasterPrepProfile) =>
                                {
                                    return coreApiResources.FranchiseApi.get({ id: dppProfile.FranchiseId, $select: "StoreEmail" })
                                        .$promise.then((franchise: CoreResources.IFranchise) =>
                                        {
                                            $scope.systemEmails.unshift(franchise.StoreEmail);
                                        });
                                },
                                (err) =>
                                {
                                    console.error(err);
                                }));

                        let finishedPromise = $q.all(promises).then(() =>
                        {
                            $scope.systemEmails = _.uniq($scope.systemEmails.filter(val => { return typeof val === "string" })
                                .map(val => val.split(/,|;/))
                                .reduce((a, b) => a.concat(b), [])
                                .map(val => val.trim().toLowerCase())
                                .sort());

                            $scope.RebindEmails();
                        }, (err) => { console.error(err); });

                        $scope.QueueLoadingPromises(finishedPromise);
                    };

                    $scope.RebindEmails = () =>
                    {
                        let emails = [];
                        $scope.mergedEmailDataSource = emails.concat($scope.userDefinedEmails, $scope.systemEmails).sort();
                    };

                    $scope.$watchCollection("selectedEmails", (newVal: string[], oldVal: string[]) =>
                    {
                        let remainingValues = _.without(oldVal, ...newVal);
                        console.log("Remaining Values", remainingValues, newVal, oldVal);
                        if (remainingValues.length > 0) {
                            $scope.userDefinedEmails = $scope.userDefinedEmails.filter(val => { return remainingValues[0] !== val });
                            $scope.RebindEmails();
                        }
                    });

                    $scope.AddUserDefinedEmail = (email: string) =>
                    {
                        console.log("Add: ", email);
                        email = email.toLowerCase();
                        let rebind = false;

                        if (!$scope.selectedEmails.some(val => val === email)) {
                            $scope.selectedEmails.push(email);
                            rebind = true;
                        }

                        if (!($scope.userDefinedEmails.some(val => val === email) || $scope.systemEmails.some(val => val === email))) {
                            $scope.userDefinedEmails.push(email);
                            rebind = true;
                        }

                        if (rebind) {
                            $scope.RebindEmails();
                            console.log($scope.multiSelectEmails.dataSource);
                            $scope.multiSelectEmails.refresh();
                        }
                    };

                    $scope.RefreshEmails();

                    $scope.$watch("profile", () =>
                    {
                        if ($scope.profile) {
                            $scope.pdfUrl = "";

                            let pdfUrlPromise = dppApiResources.GetDppPdfUrl($scope.profile.DppId).then((url: string) =>
                            {
                                $scope.pdfUrl = url;
                            });
                            $scope.QueueLoadingPromises(pdfUrlPromise);
                        }
                    });

                    $scope.EmailPdfs = () =>
                    {
                        if (!$scope.selectedEmails || $scope.selectedEmails.length <= 0) {
                            alert("No emails selected.");
                            return $q.when(null);
                        }

                        if (confirm(`Are you sure you want to send emails to: '${$scope.selectedEmails.join(", ")}'?`)) {
                            return dppApiResources.EmailPdfs($scope.dppId, $scope.selectedEmails).then(
                                () => { },
                                (err) =>
                                {
                                    console.error(err);
                                });
                        }

                        return $q.when(null);
                    }

                    $scope.DisplayPercent = (percentDecimal: number): string =>
                    {
                        return Math.round(percentDecimal * 100).toFixed(0);
                    }

                    $scope.GetTraceLevelCssClass = (traceLevel: TraceLevel): string =>
                    {
                        if (traceLevel === TraceLevel.Info) {
                            return "fa fa-check result-ok";
                        }
                        if (traceLevel === TraceLevel.Warning) {
                            return "fa fa-exclamation-triangle result-warning";
                        }
                        if (traceLevel === TraceLevel.Error) {
                            return "fa fa-times-circle result-error";
                        }

                        return null;
                    }
                }
            ]);
    }
}

