import {
    RoyaltyUserApprovalConfigurationClient,
    RoyaltyApprovalConfigurationUserVm,
    RoyaltyOrchestrationsClientResponse
} from '@nbly/royalty-orchestrations-clients';
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";

export class RmsApprovalConfigurationUserSelectorComponentController implements ng.IController {
    userId: number;
    readOnly: boolean;
    onSelect: (params: { user: RoyaltyApprovalConfigurationUserVm }) => void;

    dropDown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    royaltyOrchestrationsClient: AxiosInstance;
    royaltyUserApprovalConfigurationClient: RoyaltyUserApprovalConfigurationClient;

    static $inject = [

    ]

    constructor(

    ) {
        this.royaltyOrchestrationsClient = FranForceAxiosClientBuilder.BuildRoyaltyOrchestrationsBaseClient();
        this.royaltyUserApprovalConfigurationClient = new RoyaltyUserApprovalConfigurationClient("", this.royaltyOrchestrationsClient);
    }

    $postLink() {

    }

    $onInit() {
        this.options = {
            autoWidth: true,
            valuePrimitive: true,
            filter: "contains",
            optionLabel: "Select...",
            optionLabelTemplate: "Select...",
            dataTextField: nameof<RoyaltyApprovalConfigurationUserVm>(o => o.displayName),
            dataValueField: nameof<RoyaltyApprovalConfigurationUserVm>(o => o.userId),
            enable: !this.readOnly,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) => {

                        this.royaltyUserApprovalConfigurationClient.users()
                            .then((response) => {
                                options.success(response.result);
                            })
                            .catch((err) => {
                                options.error(err);
                            });
                    }
                }
            }),
            select: (e) => {
                if (this.onSelect) {
                    this.onSelect({ user: e.dataItem });
                }
            }
        }
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.readOnly)] && this.dropDown) {
            this.dropDown.setOptions({ enable: !this.readOnly })
        }
    }

    static BindComponent(app: ng.IModule) {
        app
            .component("rmsApprovalConfigurationUserSelector", {
                bindings: {
                    [nameof<RmsApprovalConfigurationUserSelectorComponentController>(o => o.userId)]: "<",
                    [nameof<RmsApprovalConfigurationUserSelectorComponentController>(o => o.readOnly)]: "<",
                    [nameof<RmsApprovalConfigurationUserSelectorComponentController>(o => o.onSelect)]: "&?",
                },
                template: `<select kendo-drop-down-list="$ctrl.${nameof<RmsApprovalConfigurationUserSelectorComponentController>(o => o.dropDown)}"
                            ng-model="$ctrl.${nameof<RmsApprovalConfigurationUserSelectorComponentController>(o => o.userId)}"
                            k-options="$ctrl.${nameof<RmsApprovalConfigurationUserSelectorComponentController>(o => o.options)}">
                </select>`,
                controller: RmsApprovalConfigurationUserSelectorComponentController
            });
    }
}

