export * from './RmsFranchiseClient';
export * from './RmsFranchiseEntityClient';
export * from './RmsFranchiseEntityRelationshipApplicationClient';
export * from './RmsFranchiseEntityRelationshipClient';
export * from './RmsFranchiseEntityRelationshipDetailClient';
export * from './RmsFranchiseEntityRelationshipTypeClient';
export * from './RoyaltyDisclosureDocumentRatePlanMinimumClient';
export * from './RoyaltyDisclosureDocumentRatePlanMinimumMonthsAppliedClient';
export * from './RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationClient';
export * from './RoyaltyDisclosureDocumentRollInMinimumClient';
export * from './RoyaltyDisclosureDocumentRollInMinimumMonthsAppliedClient';
export * from './RoyaltyDisclosureDocumentRollInRangeClient';
export * from './RoyaltyDisclosureDocumentRollInRatesClient';
export * from './RoyaltyContractRatePlanMinimumClient';
export * from './RoyaltyContractRatePlanMinimumMonthsAppliedClient';



