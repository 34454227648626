import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
export class RoyaltySyncInvoicesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltySyncInvoicesByFranchise(franchiseId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltySyncInvoices/ByFranchise/" + franchiseId
        };

        return this.$http<RpmEntities.RoyaltySyncInvoiceViewModel[]>(config);
    }
}
