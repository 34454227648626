import * as _ from "underscore"

import { Helpers } from "Utility/Helpers";

import { IdentityManager } from "Services/Resources/IdentityManager";
import { Constants } from 'Constants';


export class PasswordPopupComponent implements ng.IController
{
    //Props
    passwordExpirationDate: string;

    daysToExpiry: number;
    channgePasswordModel: IChangePasswordBindingModel;;

    //Popup
    passwordPopup: kendo.ui.Window;
    passwordPopupOptions: kendo.ui.WindowOptions;

    localStorage: ILocalStorage;
    errorList: any;

    static $inject = [
        "identityManager",
        "$scope",
        '$timeout',
        "$window",
        "$rootScope",
    ];

    constructor(
        private identityManager: IdentityManager,
        private $scope: ng.IScope,
        private $timeout: ng.ITimeoutService,
        private $window: ng.IWindowService,
        private $rootScope: ng.IRootScopeService,
    )
    {
        this.$rootScope.$on(Constants.PopupEvents.OpenPasswordPopupEvent, () => {
            this.openPasswordPopup();
        });
    }

    async $onInit()
    {
        this.localStorage = <ILocalStorage>localStorage;
        this.channgePasswordModel={
            OldPassword:'',
            NewPassword:'',
            ConfirmPassword:''
        };

        this.passwordExpirationDate = this.localStorage.PassWordExpirationDate;

    }

    async $postLink()
    {
       
        if (this.passwordExpirationDate)
        {
            this.daysToExpiry = Helpers.checkDaysToExpiry(this.passwordExpirationDate);
            if(this.daysToExpiry < 30 && !JSON.parse(this.localStorage.PasswordDisplayStatus)){
                this.localStorage.PasswordDisplayStatus='true';
                this.openPasswordPopup();
            }
        }
    }

    openPasswordPopup()
    {
        let template = 'Your password will expire in ' + this.daysToExpiry + 'days';


        let options: kendo.ui.WindowOptions = {
            modal: true,
            position: { top: 50 },

        };

        this.passwordPopup.open();
        this.passwordPopup.title('Update Password'); 
       // this.passwordPopup.content(template)
        this.passwordPopup.center();
        this.passwordPopup.setOptions(options);

    }
    closePopup(){
        this.passwordPopup.close()
    }

    updatePassword(){
        return this.identityManager.ChangePassword(this.channgePasswordModel).then(
            (response) =>
            {
                alert("Your password has successfully been reset! You will be forwarded to the login page...");
                 this.$window.location.href = "/Account/Login"; 
            }).catch((err)=>{
                let error='';
                this.errorList=err;

                this.$rootScope.$emit(Constants.PopupEvents.OpenPasswordErrorValidationPopupEvent, this.errorList);
                if(err?.data.Message==='The request is invalid.'){
                
                if(typeof err.data.ModelState=='object'){
                
                let modelStateKeysArray=Object.keys(err.data.ModelState);
                    modelStateKeysArray.forEach((obj)=>{
                       error=error+JSON.stringify(err.data.ModelState[obj][0])+'\n';
                        console.log(error);
                    })
                }
                }else{
                    error=err?.data.Message;
                }
               
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('passwordPopupComponent', {
            controller: PasswordPopupComponent,
            templateUrl: "/Templates/PasswordPopup/PasswordPopupComponent.html"
        });
    }
}



