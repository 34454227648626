import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import * as _ from "underscore"
import { RpmResources } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

interface IBMFranchisorStateParams extends ng.ui.IStateParamsService
{
    franchisorId?: string|number;
}


export class bmFranchisorController implements ng.IController
{
    franchisors: CoreResources.IFranchisor[];

    batchFilters: kendo.data.DataSourceFilter;
    periodFilters: kendo.data.DataSourceFilter;
    
    selectedFranchisorId: number;
    franchisorFilters: kendo.data.DataSourceFilter;
    selectedBatches: RpmResources.IRoyaltyReportBatch[];
    
    isLoading: boolean;
    readOnly: boolean;

    static $inject = [
        '$scope',
        '$stateParams',
        'coreApiResources',
        'rpmApiResources',
        'rpmHelper',
        'odataHelper',
        'identityManager',
        '$state',
        "$q",
        "$timeout"
    ]

    constructor(
        private $scope: ng.IScope,
        private $stateParams: IBMFranchisorStateParams,
        private coreApiResources: CoreApiResources,
        private rpmApiResources: RpmApiResources,
        private rpmHelper: RpmHelperService,
        private odataHelper: ODataHelperService,
        private identityManager: IdentityManager,
        private $state: ng.ui.IStateService,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService
    ) {
    }

    $onInit() 
    {
        this.isLoading = true;

        this.franchisorFilters = this.rpmHelper.GetRmsRoyaltySystemFranchisorFilters();

        let adminPromise = this.identityManager.HasRole(["BatchManagementAdmin", "Admin"])
            .then(isAdmin =>
            {
                this.readOnly = !isAdmin;
            });
        
        let franchisorPromise = this.coreApiResources.FranchisorApi.query({}).$promise
            .then((franchisors: CoreResources.IFranchisor[]) =>
            {
                this.franchisors = _.sortBy(franchisors, f => f.DisplayName);
            })
            .finally(() =>
            {
                this.isLoading = false;
            });
        
        if (this.$stateParams.franchisorId)
        {
            this.selectedFranchisorId = parseInt(<string>this.$stateParams.franchisorId);
            this.SetFilters();
        }
    }

    OnFranchisorSelect(franchisor: Partial<CoreResources.IFranchisor>)
    {
        if (franchisor) {
            this.$stateParams.franchisorId = franchisor.FranchisorId;
            this.$state.go("bm.franchisor", this.$stateParams);
        }
    }

    OnSelectedBatch(batches: RpmResources.IRoyaltyReportBatch[])
    {
        this.$timeout(() =>
        {
            this.selectedBatches = batches;
            this.SetPeriodFilters();
        });
    }

    SetFilters()
    {
        this.SetBatchFilters();
        this.SetPeriodFilters();
    }

    SetBatchFilters()
    {
        if (!this.selectedFranchisorId) {
            this.batchFilters = null;
            return;
        }
        
        this.batchFilters = <kendo.data.DataSourceFilterItem>{
            operator: "eq",
            field: nameof<RpmResources.IRoyaltyReportBatch>(o => o.FranchisorId),
            value: this.selectedFranchisorId
        };
    }

    SetPeriodFilters()
    {
        if (!this.selectedBatches || !this.selectedBatches.length) {
            this.periodFilters = null;
            return;
        }

        let filtersArray = <kendo.data.DataSourceFilterItem[]>[]
        this.periodFilters = <kendo.data.DataSourceFilters>{
            filters: filtersArray,
            logic: "or"
        };

        for (let batchId of this.selectedBatches.map(b => b.RoyaltyReportBatchId)) {
            filtersArray.push({
                field: nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatchId),
                operator: "eq",
                value: batchId,
            });
        }
    }
}