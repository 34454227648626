import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RmsStateService } from "Services/State/RmsState";

import * as _ from "underscore"
import { CmWeeksInBusinessCalculatorComponentController } from "Directives/RPM/ContractManagement/cmWeeksInBusinessCalculator";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RoyaltyDisclosureDocumentRatePlanItemDetailModel } from "Interfaces/FranForce/Rpm/FDD/RoyaltyDisclosureRatePlanItemModel";
import { KendoHelperService } from "Services/Utility/KendoHelperService";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { ContractRatePlanItem, ContractRatePlanItemDetail, ContractRatePlanItemDetailFeeXref, ContractRatePlanItemFeeXref } from "../../../../Models/Royalty/RoyaltyContract/RoyaltyContractDataModel";
import { IHttpPromise } from "angular";

interface GridData {
    ContractRatePlanItemDetailId: number;
    [key: string]: number | string;
}

interface GridColumn {
    field: string;
    title: string;
}

enum FeeType {
    AdFee = 1,
    RoyaltyFee,
    TAFSFee,
    TechnologyFee,
    LateFee,
    RollInRoyaltyFee,
    RollInAdFee,
    SmallRollInAdFee,
    SmallRollInRoyaltyFee,
    MediumRollInAdFee,
    MediumRollInRoyaltyFee,
    LargeRollInAdFee,
    LargeRollInRoyaltyFee
}

export class cmRoyaltiesRatePlanItemsDynamicFeesComponentController implements ng.IController {

    contractRatePlanId: number;
    readOnly: boolean;
    isMethodologySpecial: boolean;
    contractId: number;
    conceptId: number;
    onSelectedRatePlanItem: (params: { ratePlanItem: RpmEntities.RoyaltyContractRatePlanItemViewModel, isRemoved: boolean }) => void;
    onLoaded: (params: { self: cmRoyaltiesRatePlanItemsDynamicFeesComponentController }) => void;
    localStorage: ILocalStorage;
    isLoading: boolean;
    selectedRatePlanItem: RpmEntities.RoyaltyContractRatePlanItemViewModel;
    contract: RpmEntities.RoyaltyContractViewModel;
    royaltyRatePlanItemTypes: RpmEntities.RoyaltyRatePlanItemTypeViewModel[];
    royaltyRatePlanItemGrid: kendo.ui.Grid;
    royaltyRatePlanItemGridOptions: kendo.ui.GridOptions;
    rollInRateGrids: { [uid: string]: kendo.ui.Grid };
    formController: angular.IFormController;
    calc: CmWeeksInBusinessCalculatorComponentController;
    serviceCategoryGridOptions: kendo.ui.GridOptions;
    contractRatePlanItemId: number;
    franchiseId: number;

    royaltyServiceCategories: any[];
    royaltyServiceCategoryTypes: RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result[];
    serviceCategoryGrid: kendo.ui.Grid;
    formControllerSC: angular.IFormController;

    addAllCategoriesWindow: kendo.ui.Window;
    addAllRateTextBoxOptions: kendo.ui.NumericTextBoxOptions;
    addAllServiceCategoryModel: Partial<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel>;

    isDynamicFees: boolean;

    royaltyFeeTypes: RoyaltyFeeTypeModel[] = []


    static $inject = [
        'rpmUiApiResources',
        'rpmHelper',
        'identityManager',
        'rmsState',
        '$q',
        'kendoHelper',
        'coreApiResources',
        'royaltyApiResources',
        '$timeout',
        '$scope'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private identityManager: IdentityManager,
        private rmsState: RmsStateService,
        private $q: ng.IQService,
        private kendoHelper: KendoHelperService,
        private coreApiResources: CoreApiResources,
        private royaltyApiResources: RoyaltyApiResources,
        private $timeout: ng.ITimeoutService,
        private $scope: ng.IScope) {

    }
    $postLink() {
        this.addAllCategoriesWindow.setOptions(<kendo.ui.WindowOptions>{
            width: 800,
            modal: true,
            title: "Add For All Service Categories",
            pinned: true,
            position: {
                top: "10%", // Adjust based on your requirement for vertical positioning
                left: "20%" // Centers the window since 60% width + 20% left + 20% right = 100%
            }
        });

        this.validationErrorsKendoWindow.setOptions(<kendo.ui.WindowOptions>{
            modal: false,
            title: "Validation errors",
            width: 600,
            actions: ["minimize", "close"],
            position: {
                top: 50,
                right: 50
            },
            pinned: true
        });

        this.validationErrorsKendoWindow.element.closest(".k-window").css({
            top: 50,
            right: 50
        });

        this.addAllRateTextBoxOptions = {
            spinners: false,
            format: this.rpmHelper.StringFormats.Number.RatePercentage,
            decimals: 7,
            min: 0,
            max: 1,
        }
    }

    GetRoyaltyFeeTypes = async function (): Promise<Array<RoyaltyFeeTypeModel>> {
        const { data: royaltyFeeTypes } = await this.royaltyApiResources.GetRoyaltyFeeTypes();
        return royaltyFeeTypes.filter(
            (feeType: RoyaltyFeeTypeModel) =>
                !(feeType.name?.toLowerCase()?.startsWith('late') || feeType.name?.toLowerCase()?.includes('rollin'))
        );
    };

    royaltyContractRatePlanItemDetailGridOptions: kendo.ui.GridOptions;
    royaltyContractRatePlanItemDetailGrid: kendo.ui.Grid;

    royaltyContractRatePlanItemDetailValidationErrorGrid: kendo.ui.Grid;
    royaltyContractRatePlanItemDetailErrorGridOptions: kendo.ui.GridOptions;

    TotalAdjustment: number = 0

    // Function to generate a random UUID
    GenerateUUID = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    GetFeeTypeName = function (feeTypeId: number): string {
        const feeType = this.royaltyFeeTypes.find(({ royaltyFeeTypeID }) => royaltyFeeTypeID === feeTypeId);
        return feeType ? feeType.name : '';
    }

    // Custom editor function for feeTypeId
    CustomFeeTypeEditor = (container, options) => {
        $('<input required name="' + options.field + '"/>')
            .appendTo(container)
            .kendoDropDownList({
                autoBind: false,
                optionLabel: "Select a fee type...",
                dataSource: this.royaltyFeeTypes, // You need to provide data source here
                dataTextField: nameof<RoyaltyFeeTypeModel>(o => o.name),
                dataValueField: nameof<RoyaltyFeeTypeModel>(o => o.royaltyFeeTypeID)
            });
    };

    GetDefaultAddAllCategoriesData() {
        return this.royaltyFeeTypes.map((feeType, index) => {
            const feeTypeId = feeType.royaltyFeeTypeID;
            let RateCommercial = 0;
            let RateResidential = 0;
            let FeeRate = 0;

            return {
                rowNumber: index + 1,
                feeTypeId: feeTypeId,
                feeRateId: this.GenerateUUID(),
                RateCommercial: RateCommercial,
                RateResidential: RateResidential,
                FeeRate: FeeRate
            };
        });
    }


    InitAddAllCategoriesGrid(uid: any, ratePlanItemId: number) {

        this.royaltyContractRatePlanItemDetailGridOptions = {
            dataSource: {
                change: (e) => {
                    // Calculate the sum of fee adjustments
                    const data = e.sender.data();
                    let sum = 0;
                    data.forEach(({ feeAdjustment }) => sum += feeAdjustment);

                    // Assign the sum to the component variable
                    this.TotalAdjustment = sum;
                },
                data: this.GetDefaultAddAllCategoriesData(),
                schema: {
                    model: {
                        id: 'feeRateId', // Use the generated UUID as the ID
                        fields: {
                            rowNumber: { type: 'number', editable: false },
                            feeTypeId: {
                                editable: false,
                                type: 'number',
                                validation: {
                                    required: true,
                                    uniqueFeeType: function (input) {
                                        const existingValues = [];
                                        const currentRow = input.closest("tr");
                                        const currentId = currentRow.attr('data-uid');
                                        const grid = input.closest('[kendo-grid]').data('kendoGrid');

                                        if (grid) {
                                            const gridView = grid.dataSource.view();
                                            gridView.forEach(function ({ uid, feeTypeId }) {
                                                if (uid !== currentId) {
                                                    existingValues.push(feeTypeId);
                                                }
                                            });
                                        }

                                        const valid = existingValues.indexOf(parseInt(input.val())) === -1;

                                        if (!valid) {
                                            var message = "Fee Type must be unique.";
                                            input.attr("data-uniqueFeeType-msg", message);
                                        }

                                        return valid;
                                    }
                                }
                            },
                            feeRateId: { type: 'string', editable: false }, // UUID field
                            RateCommercial: { type: 'number' }, // Rate Commercial column
                            RateResidential: { type: 'number' }, // Rate Residential column
                            FeeRate: { type: 'number', editable: false } // Fee Rate column
                        }
                    }
                }
            },
            columns: [
                {
                    field: 'rowNumber',
                    title: 'Row No.',
                    width: '80px',
                },
                {
                    field: 'feeTypeId',
                    title: 'Fee Type',
                    editor: this.CustomFeeTypeEditor, // Custom editor function
                    template: ({ feeTypeId }) => this.GetFeeTypeName(feeTypeId)
                },
                {
                    field: 'RateCommercial',
                    title: 'Rate Commercial',
                    format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                    editor: this.rpmHelper.RoyaltyRateEditor
                },
                {
                    field: 'RateResidential',
                    title: 'Rate Residential',
                    format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                    editor: this.rpmHelper.RoyaltyRateEditor
                }
            ],
            editable: true,
            toolbar: [{
                name: "cancel",
                text: "Reset/Clear Changes"
            }, {
                name: "Add All Categories",
                template: (function (uid, ratePlanItemId) {
                    // Assuming `item` needs to be safely encoded to avoid XSS when used in HTML
                    const gridId = encodeURIComponent(uid);
                    const itemId = encodeURIComponent(ratePlanItemId);

                    return `<a class='k-button' ng-click='$ctrl.AddAllCategoriesSave("${gridId}",${itemId}); $event.preventDefault()'>Save Changes </a>`;
                })(uid, ratePlanItemId)
            }],
        };

        this.royaltyContractRatePlanItemDetailGrid?.setOptions(this.royaltyContractRatePlanItemDetailGridOptions);
        this.royaltyContractRatePlanItemDetailGrid?.dataSource.read();
        this.royaltyContractRatePlanItemDetailGrid?.refresh();
    };

    async $onInit() {
        this.localStorage = <ILocalStorage>localStorage;
        this.isLoading = true;
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledCm').val() === 'True';
        this.rollInRateGrids = {};
        if (this.franchiseId) {
            this.LoadServiceCategories();
        }

        this.royaltyFeeTypes = await this.GetRoyaltyFeeTypes();

        this.rpmUiApiResources.GetAllRoyaltyRatePlanItemTypes().then(typesResponse => {
            this.royaltyRatePlanItemTypes = typesResponse.data;
        })
            .then(() => {
                this.InitRoyaltyRatePlanItemGrid();
            })
            .finally(() => {
                this.isLoading = false;
                this.onLoaded({ self: this });
            })
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.contractRatePlanId)]) {

            this.$timeout(() => { this.ResetChanges(); }, 0);
        }

        if (changes[nameof(this.contractId)]) {
            this.PullContract();
        }
        if (changes.franchiseId && changes.franchiseId.currentValue) {
            this.LoadServiceCategories();
        }
    }

    ToCamelCase(str) {
        return str
            .split(/(?=[A-Z])/) // Split the string at each uppercase letter
            .map(function (word, index) {
                // Convert the first word to lowercase, and capitalize the initial letter of subsequent words
                if (index === 0) {
                    return word.toLowerCase(); // Lowercase the entire first word
                } else {
                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Keep as is
                }
            })
            .join(''); // Rejoin all parts without any spaces
    }

    GetServiceCategoryGridDataFromXref = function (feeTypes: Array<RoyaltyFeeTypeModel>, ratePlanItems: Array<ContractRatePlanItemDetailFeeXref>) {
        const gridData: GridData[] = [];

        ratePlanItems.forEach(item => {
            const existingEntry = gridData.find(
                entry => entry.ContractRatePlanItemDetailId === item.contractRatePlanItemDetailId
            );

            const feeType = feeTypes.find(fee => fee.royaltyFeeTypeID === item.feeTypeId);

            if (feeType) {
                const residentialFieldName = `${this.ToCamelCase(feeType.name)}RateResidential`;
                const commercialFieldName = `${this.ToCamelCase(feeType.name)}RateCommercial`;
                const fixedFeeFieldName = `${this.ToCamelCase(feeType.name)}FixedFeeAmount`;

                if (existingEntry) {
                    existingEntry[residentialFieldName] = item.feeRateResidential;
                    existingEntry[commercialFieldName] = item.feeRateCommercial;
                    existingEntry[fixedFeeFieldName] = item.fixedFeeAmount;
                } else {
                    gridData.push({
                        ContractRatePlanItemDetailId: item.contractRatePlanItemDetailId,
                        [residentialFieldName]: item.feeRateResidential,
                        [commercialFieldName]: item.feeRateCommercial,
                        [fixedFeeFieldName]: item.fixedFeeAmount,
                    });
                }
            }
        });

        return gridData;
    };

    InitRoyaltyRatePlanItemGrid() {

        let rpitDataSource = new kendo.data.DataSource({
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {
                    if (!this.contractRatePlanId) {
                        options.success([]);
                        return;
                    }
                    this.royaltyApiResources.GetContractManagementDisclosureDocumentRatePlanItem(this.contractRatePlanId)
                        .then(
                            (ratePlansResponse) => {
                                let updatedRes = [];
                                ratePlansResponse.data.map((e) => {
                                    if (!e.deletedDateTime) {
                                        updatedRes.push(e)
                                    }
                                })
                                options.success(updatedRes);
                            },
                            (err) => { options.error(err); }
                        )
                },
                create: (options: kendo.data.DataSourceTransportOptions) => {
                    let createModel: RpmEntities.RoyaltyContractRatePlanItemViewModel = options.data;
                    this.royaltyApiResources.CreateContractManagementRoyaltyDisclosureDocumentRatePlanItem(createModel)
                        .then(({ data }) => {
                            options.success(data)
                        },
                            ({ status, data }) => {
                                console.log({ status, data })
                                switch (status) {
                                    case 403:
                                        this.DisplaySnackbar("error", "You are unauthorized to create rate plan items!")
                                    case 401:
                                        this.DisplaySnackbar("error", "You are unauthenticated. Please re-login and try again!")
                                    case 500:
                                        this.DisplaySnackbar("error", "Some error occured while saving rate plan items. Please try again!")
                                    case 400:
                                        const errorResponse: BadRequestErrorResponse = data

                                        this.ShowErrorGrid(errorResponse);
                                        this.validationErrorsKendoWindow.open()

                                        this.DisplaySnackbar("error", "There are validation errors for your rate plan items. Please fix them and try again!")
                                }

                                options.error(data)
                            })
                },
                update: (options: kendo.data.DataSourceTransportOptions) => {
                    let updateModel: any = options.data;
                    updateModel.updatedDateTime = new Date();
                    this.royaltyApiResources.UpdateContractManagementRoyaltyDisclosureDocumentRatePlanItem(updateModel, options.data.contractRatePlanItemId)
                        .then(({ data }) => {
                            options.success(data)
                        },
                            ({ status, data }) => {
                                console.log({ status, data })
                                switch (status) {
                                    case 403:
                                        this.DisplaySnackbar("error", "You are unauthorized to create rate plan items!")
                                    case 401:
                                        this.DisplaySnackbar("error", "You are unauthenticated. Please re-login and try again!")
                                    case 500:
                                        this.DisplaySnackbar("error", "Some error occured while saving rate plan items. Please try again!")
                                    case 400:
                                        const errorResponse: BadRequestErrorResponse = data

                                        this.ShowErrorGrid(errorResponse);
                                        this.validationErrorsKendoWindow.open()

                                        this.DisplaySnackbar("error", "There are validation errors for your rate plan items. Please fix them and try again!")
                                }

                                options.error(data)
                            })
                },
                destroy: (options: kendo.data.DataSourceTransportOptions) => {
                    let deleteModel: any = options.data;
                    deleteModel.deletedDateTime = new Date();
                    this.royaltyApiResources.UpdateContractManagementRoyaltyDisclosureDocumentRatePlanItem(deleteModel, options.data.contractRatePlanItemId).then(
                        ({ data }) => {
                            options.success(data);
                        }
                    )
                },
            },
            sort: [
                { field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(d => d.WeeksInBusinessStart), dir: "asc" },
                { field: nameof<RpmEntities.RoyaltyContractRatePlanItemViewModel>(d => d.GrossSalesRangeLow), dir: "asc" },
            ],
            schema: {
                model: {
                    id: "contractRatePlanItemId",
                    fields: {
                        contractRatePlanItemId: {
                            type: "number",
                            validation: { required: true },
                            editable: false
                        },
                        royaltyRatePlanItemType: {
                            type: "object",
                            validation: { required: true },
                            defaultValue: { Description: "Annual Scale", royaltyRatePlanItemTypeID: 1 }
                        },
                        royaltyRatePlanItemTypeID: {
                            type: "number",
                            validation: { required: true },
                            defaultValue: 1
                        },
                        weeksInBusinessStart: {
                            type: "number",
                            validation: { required: true },
                            default: 0
                        },
                        weeksInBusinessEnd: {
                            type: "number",
                            validation: { required: true },
                            default: 0
                        },
                        grossSalesRangeLow: { type: "number", validation: { required: true } },
                        grossSalesRangeHigh: { type: "number", validation: { required: true } },
                        royaltyFeeRateResidential: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        royaltyFeeFixedRateAmountResidential: { type: "number", validation: { required: true } },
                        royaltyFeeRateCommercial: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        royaltyFeeFixedRateAmountCommercial: { type: "number", validation: { required: true } },
                        adFeeRateResidential: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        adFeeFixedRateAmountResidential: { type: "number", validation: { required: true } },
                        adFeeRateCommercial: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        adFeeFixedRateAmountCommercial: { type: "number", validation: { required: true } },
                        tafsFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        tafsFeeFixedRateAmount: { type: "number", validation: { required: true } },
                        technologyFeeRate: {
                            type: "number",
                            validation: {
                                required: true
                            }
                        },
                        technologyFeeFixedRateAmount: { type: "number", validation: { required: true } },
                        deletedDateTime: {
                            type: "string",
                            defaultValue: null
                        },
                        hasRollInRateChanges: {
                            type: "boolean",
                            defaultValue: false
                        }
                    }
                }
            },
            pageSize: 30,
            filter: { field: "DeletedDateTime", operator: "isnull" }
        });

        let rpitColumns: Array<kendo.ui.GridColumn> = [
            {
                field: 'contractRatePlanItemId',
                title: "ID",
            }, {
                field: "royaltyRatePlanItemTypeID",
                title: "Rate Plan Item Type",
                editor: (container, options) => {
                    let input = "<input required name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: "Description",
                        dataValueField: "RoyaltyRatePlanItemTypeId",
                        dataSource: {
                            data: this.royaltyRatePlanItemTypes
                        },
                        change: (e) => {
                            var dataItem = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.royaltyRatePlanItemTypes[0];
                            }
                            dataItem.dirty = true
                            options.model.set("royaltyRatePlanItemTypeID", dataItem.RoyaltyRatePlanItemTypeId);
                        }
                    });
                },
                template: "{{$ctrl.GetRoyaltyPlanItemTypeById(dataItem.royaltyRatePlanItemTypeID).Description}}",
                width: "150px"
            }, {
                field: 'weeksInBusinessStart',
                title: "WIB Start",
                editor: this.rpmHelper.WeeksInBusinessEditor()
            }, {
                field: 'weeksInBusinessEnd',
                title: "WIB End",
                editor: this.rpmHelper.WeeksInBusinessEditor()
            }, {
                field: 'grossSalesRangeLow',
                title: "Sales Scale Begins",
                format: "{0:c}",
            }, {
                field: 'grossSalesRangeHigh',
                title: "Sales Scale Ends",
                format: "{0:c}"
            },
            {
                field: "hasRollInRateChanges",
                title: "Has Roll In Rate Changes",
                hidden: true
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }];

        for (let col of rpitColumns) {
            col.headerAttributes = {
                style: "white-space: normal; border-left: #AAA 1px solid;"
            };
        }
        rpitColumns[0].headerAttributes = null;

        this.royaltyRatePlanItemGridOptions = {
            dataSource: rpitDataSource,
            columns: rpitColumns,
            scrollable: false,
            pageable: true,
            editable: true,
            remove: (e) => {
                if (this.selectedRatePlanItem && e.model.id === this.selectedRatePlanItem.ContractRatePlanItemId) {
                    this.$timeout(() => {
                        this.SelectRatePlanItem(null, true);
                    }, 0);
                }
            }
        };
    }


    GetRollInRateGridOptions(ratePlanItem: any & kendo.data.Model): kendo.ui.GridOptions {
        let rirDataSource = new kendo.data.DataSource({
            batch: true,
            pageSize: 20,
            schema: {
                model: {
                    id: "typeFee",
                    fields: {
                        [nameof<IRollInRateItem>(o => o.ParentId)]: {
                            type: "string",
                            validation: { required: true },
                            editable: false,
                            defaultValue: 0
                        },
                        [nameof<IRollInRateItem>(o => o.typeFee)]: {
                            type: "string",
                            validation: { required: true },
                            editable: false,

                        },
                        [nameof<IRollInRateItem>(o => o.royaltyFeeRate)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<IRollInRateItem>(o => o.royaltyFeeFixedRateAmount)]: { type: "number", validation: { required: true } },
                        [nameof<IRollInRateItem>(o => o.adFeeRate)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<IRollInRateItem>(o => o.adFeeFixedRateAmount)]: { type: "number", validation: { required: true } }
                    }
                }
            },
            transport: {
                read: (e: kendo.data.DataSourceTransportReadOptions) => {
                    let rollIn: Partial<IRollInRateItem> = {
                        ParentId: ratePlanItem.contractRatePlanItemId,
                        typePrefix: "Medium",
                        typeFee: "Roll-in",
                        royaltyFeeRate: ratePlanItem.rollInRoyaltyFeeRate,
                        royaltyFeeFixedRateAmount: ratePlanItem.rollInRoyaltyFeeFixedRateAmount,
                        adFeeRate: ratePlanItem.rollInAdFeeRate,
                        adFeeFixedRateAmount: ratePlanItem.rollInAdFeeFixedRateAmount
                    };
                    e.success([rollIn]);
                },
                update: (e: kendo.data.DataSourceTransportOptions) => {
                    const rollInRate: IRollInRateItem = e.data.models[0];
                    ratePlanItem.rollInRoyaltyFeeRate = rollInRate.royaltyFeeRate;
                    ratePlanItem.rollInRoyaltyFeeFixedRateAmount = rollInRate.royaltyFeeFixedRateAmount;
                    ratePlanItem.rollInAdFeeRate = rollInRate.adFeeRate;
                    ratePlanItem.rollInAdFeeFixedRateAmount = rollInRate.adFeeFixedRateAmount;

                    if (ratePlanItem.ContractRatePlanItemId) {
                        //If it's a new item, this flag doesn't need to be set as it will already be gaurenteed to be created.
                        this.royaltyRatePlanItemGrid.dataSource
                            .get(ratePlanItem.contractRatePlanItemId)
                            .set("HasRollInRateChanges", true);
                    }

                    const processRatePlanItem = (ratePlanItemPromise: IHttpPromise<any>) => {
                        ratePlanItemPromise
                            .then(({ data }: any) => {
                                const xrefs = this.MapRatePlanItemsToXrefCollection([data]);

                                return this.royaltyApiResources
                                    .CreateContractManagementRoyaltyDisclosureDocumentRatePlanItemXREF(data.contractRatePlanItemId, xrefs);
                            })
                            .then(() => {
                                e.success(e.data.models);
                            })
                            .catch((error) => {
                                e.error(error);
                            });
                    };

                    if (ratePlanItem.contractRatePlanItemId > 0) {
                        processRatePlanItem(
                            this.royaltyApiResources.UpdateContractManagementRoyaltyDisclosureDocumentRatePlanItem(ratePlanItem, ratePlanItem.id)
                        );
                    } else {
                        processRatePlanItem(
                            this.royaltyApiResources.CreateContractManagementRoyaltyDisclosureDocumentRatePlanItem(ratePlanItem)
                        );
                    }

                },
                destroy: (e: kendo.data.DataSourceTransportOptions) => {
                    e.error('Destory is not defined for roll in rates, as it should not be possible.')
                },
                create: (e: kendo.data.DataSourceTransportOptions) => {
                    e.error('Create is not defined for roll in rates, as it should not be possible.')
                },
            }
        });
        let rirColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<IRollInRateItem>(o => o.ParentId),
                title: "ContractRatePlanItemId",
                hidden: true
            }, {
                field: nameof<IRollInRateItem>(o => o.typeFee),
                title: "Type Fee"
            }, {
                field: nameof<IRollInRateItem>(o => o.royaltyFeeRate),
                title: "Royalty Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<IRollInRateItem>(o => o.royaltyFeeFixedRateAmount),
                title: "Royalty Fee Fixed Rate Amount",
                format: "{0:c}"
            }, {
                field: nameof<IRollInRateItem>(o => o.adFeeRate),
                title: "Ad Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<IRollInRateItem>(o => o.adFeeFixedRateAmount),
                title: "Ad Fee Fixed Rate Amount",
                format: "{0:c}"
            }];

        return {
            columns: rirColumns,
            dataSource: rirDataSource,
            editable: !this.readOnly,
            navigatable: true,
            scrollable: false
        };
    }

    GetRoyaltyServiceCategoryById(serviceCategoryId: number) {
        return _.find(this.royaltyServiceCategories, (sc) => { return sc.RoyaltyServiceCategoryId === serviceCategoryId; });
    }

    GetRoyaltyServiceCategoryTypeById(serviceCategoryTypeId: number) {
        return _.find(this.royaltyServiceCategoryTypes, (ct) => { return ct.RoyaltyServiceCategoryTypeId === serviceCategoryTypeId; });
    }
    GetDefaultRoyaltyContractRatePlanItemDetail(): any {
        return {
            contractRatePlanItemDetailId: 0,
            royaltyServiceCategoryTypeId: 0,
            createdDateTime: null,
            deletedDateTime: null,
            royaltyServiceCategoryId: null,
            annualRollInSalesAmount: 0,
            monthlyRollInSalesAmount: 0,
            rollinAdFeeRate: 0,
            rollInRoyaltyFeeRate: 0,
            weeklyRollInSalesAmount: 0,
            royaltyFeeRateResidential: 0,
            royaltyFeeRateCommercial: 0,
            adFeeRateResidential: 0,
            adFeeRateCommercial: 0,
            technologyFeeRate: 0,
            tafsFeeRate: 0,
            createdUser: null,
            updatedDateTime: null,
            updatedUser: null
        };
    }

    AddAllCategoriesPopup(event) {
        const childGrid = $(event.target).closest("[data-role=grid]").data("kendoGrid");
        const childData = childGrid.dataSource.data();
        console.log("Child Grid Data:", childData);

        // Traverse up to find the parent grid row
        const parentRow = $(event.target).closest(".k-detail-row").prev();
        const parentGrid = parentRow.closest("[data-role=grid]").data("kendoGrid");
        const parentData: any = parentGrid.dataItem(parentRow);

        console.log("Parent Grid Row Data:", parentData);

        const { uid: gridIdentifier, contractRatePlanItemId: ratePlanItemId } = parentData;

        this.InitAddAllCategoriesGrid(gridIdentifier, ratePlanItemId);

        this.addAllCategoriesWindow.center();

        this.$timeout(() => {
            this.addAllCategoriesWindow.open();
        })
    }

    AddAllCategoriesSave(uid: any, itemId: number) {

        let isValid = true;
        const messages = [];
        const addAllCategoriesData: any = this.royaltyContractRatePlanItemDetailGrid.dataSource.view()

        addAllCategoriesData.forEach((item: any) => {
            if (item.feeTypeId === 3 || item.feeTypeId === 4) {
                if (item.RateCommercial + item.RateResidential > 1) {
                    messages.push(`For ${this.GetFeeTypeName(item.feeTypeId)} fee type, the sum of Rate Commercial and Rate Residential must be less than equal to 1 (100%).`);
                    isValid = false;
                }
            }
        });

        if (!isValid) {
            alert("Validation errors:\n" + messages.join("\n"));
            return;
        }

        const data = this.kendoHelper.GetFilteredUnpagedData<any>(this.serviceCategoryGrid[uid].dataSource)

        const validCategorySubset = this.royaltyServiceCategories.filter((category) => {
            if (category.deletedDateTime)
                return false;

            return data.every((dataItem: any) => {
                return dataItem.royaltyServiceCategoryId != category.RoyaltyServiceCategoryId
            })
        });

        // Check if validCategorySubset is empty using the `some()` method
        if (!validCategorySubset.some(() => true)) {
            // No categories to add, display a snackbar notification
            this.DisplaySnackbar('info', `All service categories have been represented in the service category grid. 
            Hence, no rows will be added to the service category grid.`);

            this.royaltyContractRatePlanItemDetailGrid.dataSource.data([]);
            this.royaltyContractRatePlanItemDetailGrid.refresh();

            this.addAllCategoriesWindow.close();
            return; // Exit the function early as there's nothing to process
        }

        const contractRatePlanItemDetail: any = this.royaltyContractRatePlanItemDetailGrid.dataSource.data();

        // Initialize the addAllServiceCategoryModel
        const addAllServiceCategoryModel = {
            contractRatePlanItemDetailId: 0,
            contractRatePlanItemId: itemId,
            contractRatePlanId: this.contractRatePlanId,
            adFeeRateCommercial: 0,
            adFeeRateResidential: 0,
            royaltyFeeRateCommercial: 0,
            royaltyFeeRateResidential: 0,
            tafsFeeRate: 0,
            technologyFeeRate: 0,
        };

        // Use reduce to create a new model object immutably
        const updatedModel = contractRatePlanItemDetail.reduce((model, item) => {
            switch (item.feeTypeId) {
                case 1: // AdFee
                    return {
                        ...model,
                        adFeeRateCommercial: item.RateCommercial,
                        adFeeRateResidential: item.RateResidential,
                    };
                case 2: // RoyaltyFee
                    return {
                        ...model,
                        royaltyFeeRateCommercial: item.RateCommercial,
                        royaltyFeeRateResidential: item.RateResidential,
                    };
                case 3: // TAFSFee
                    return {
                        ...model,
                        tafsFeeRate: item.RateCommercial + item.RateResidential,
                    };
                case 4: // TechnologyFee
                    return {
                        ...model,
                        technologyFeeRate: item.RateCommercial + item.RateResidential,
                    };
                default:
                    return model;
            }
        }, addAllServiceCategoryModel);


        // Initialize an array to hold all new detail items
        let newDetails = [];

        const newDetail = { ...this.GetDefaultRoyaltyContractRatePlanItemDetail(), ...updatedModel };

        for (let category of validCategorySubset) {
            // Set the category IDs
            newDetail.royaltyServiceCategoryId = category.RoyaltyServiceCategoryId;
            newDetail.royaltyServiceCategoryTypeId = category.RoyaltyServiceCategoryTypeId;

            // Add the new detail item to the array
            this.serviceCategoryGrid[uid].dataSource.add(newDetail)
            newDetails.push(newDetail);
        }

        // Once all items are created and added to the array, update the data source in one operation
        //this.serviceCategoryGrid[uid].dataSource.add(newDetails);
        //this.serviceCategoryGrid[uid].dataSource.refresh();

        this.royaltyContractRatePlanItemDetailGrid.dataSource.data(this.GetDefaultAddAllCategoriesData());
        this.royaltyContractRatePlanItemDetailGrid.refresh();

        this.addAllCategoriesWindow.close();
    }


    LoadServiceCategories() {
        this.isLoading = true;

        return this.coreApiResources.FranchiseApi.get({ id: this.franchiseId, $select: nameof<CoreResources.IFranchise>(o => o.FranchisorId) }).$promise
            .then((franchise) => {
                return franchise.FranchisorId;
            })
            .then((franchiseId) => {
                return this.$q.all([
                    this.rpmUiApiResources.GetRoyaltyServiceCategoriesByFranchisor(franchiseId)
                        .then((serviceCategoriesResponse) => {
                            this.royaltyServiceCategories = serviceCategoriesResponse.data
                        }),
                    this.rpmUiApiResources.GetAllRoyaltyServiceCategoryTypes()
                        .then((categoryTypesResponse) => {
                            this.royaltyServiceCategoryTypes = categoryTypesResponse.data;
                        })
                ]);
            })
            .finally(() => {
                this.isLoading = false;
                this.onLoaded({ self: this });
            });
    }

    GetServiceCategroyGridOptions(ratePlanItem: any): kendo.ui.GridOptions {
        let scDataSource = new kendo.data.DataSource({
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {
                    if (!ratePlanItem.contractRatePlanItemId) {
                        options.success([]);
                        return;
                    }
                    if (this.isDynamicFees) {
                        this.rpmUiApiResources.GetRoyaltyContractRatePlanItemDetailsByContractPlanItemDynamic(ratePlanItem.contractRatePlanItemId)
                            .then((itemDetailResponse) => {
                                let updatedRes = [];
                                itemDetailResponse.data.map((e) => {
                                    if (!e.DeletedDateTime) {
                                        updatedRes.push({
                                            contractRatePlanItemDetailId: e.ContractRatePlanItemDetailId,
                                            contractRatePlanItemId: e.ContractRatePlanItemId,
                                            createdDateTime: e.CreatedDateTime,
                                            deletedDateTime: e.DeletedDateTime,
                                            royaltyServiceCategoryId: e.RoyaltyServiceCategoryId,
                                            annualRollInSalesAmount: e.AnnualRollInSalesAmount,
                                            monthlyRollInSalesAmount: e.MonthlyRollInSalesAmount,
                                            weeklyRollInSalesAmount: e.WeeklyRollInSalesAmount,
                                            rollInRoyaltyFeeRate: e.RollInRoyaltyFeeRate,
                                            royaltyFeeRateResidential: e.RoyaltyFeeRateResidential,
                                            royaltyFeeRateCommercial: e.RoyaltyFeeRateCommercial,
                                            adFeeRateResidential: e.AdFeeRateResidential,
                                            adFeeRateCommercial: e.AdFeeRateCommercial,
                                            technologyFeeRate: e.TechnologyFeeRate,
                                            tafsFeeRate: e.TAFSFeeRate,
                                            createdUser: e.CreatedUser,
                                            updatedDateTime: e.UpdatedDateTime,
                                            updatedUser: e.UpdatedUser,
                                            rollinAdFeeRate: e.RollinAdFeeRate,
                                            royaltyServiceCategoryTypeId: e.RoyaltyServiceCategoryTypeId
                                        });
                                    }
                                })
                                options.success(updatedRes);
                            });
                    }
                    else {
                        this.royaltyApiResources.GetContractManagementDisclosureDocumentRatePlanItemDetail(ratePlanItem.contractRatePlanItemId)
                            .then((itemDetailResponse) => {
                                let updatedRes = [];
                                itemDetailResponse.data.map((e) => {
                                    if (!e.DeletedDateTime) {
                                        updatedRes.push(e);
                                    }
                                })
                                options.success(updatedRes)
                            })
                    }
                },
                create: (options: kendo.data.DataSourceTransportOptions) => {
                    let createModel = options.data;

                    this.royaltyApiResources.CreateContractManagementRoyaltyDisclosureDocumentRatePlanItemDetails(createModel)
                        .then(({ data }) => {
                            const xrefs = this.MapRatePlanItemDetailsToXrefCollection([data])

                            this.royaltyApiResources
                                .CreateContractManagementRoyaltyDisclosureDocumentRatePlanItemDetailXREF(data.contractRatePlanItemDetailId, xrefs)
                                .then(() => options.success(data))

                        }, (err) => { options.error(err) })

                },
                update: (options: kendo.data.DataSourceTransportOptions) => {
                    let updateModel: any = options.data;
                    this.royaltyApiResources.UpdateContractManagementRoyaltyDisclosureDocumentRatePlanItemDetails(updateModel, updateModel.contractRatePlanItemDetailId)
                        .then(({ data }) => {
                            const xrefs = this.MapRatePlanItemDetailsToXrefCollection([data])

                            this.royaltyApiResources
                                .CreateContractManagementRoyaltyDisclosureDocumentRatePlanItemDetailXREF(data.contractRatePlanItemDetailId, xrefs)
                                .then(() => options.success(data))
                        }, (err) => { options.error(err) })

                },
                destroy: (options: kendo.data.DataSourceTransportOptions) => {
                    let deleteModel = options.data;
                    deleteModel.DeletedDateTime = new Date();
                    deleteModel.RoyaltyServiceCategoryId = deleteModel.RoyaltyServiceCategoryId || 1
                    deleteModel.RoyaltyServiceCategoryTypeId = deleteModel.RoyaltyServiceCategoryTypeId || 1

                    this.royaltyApiResources.UpdateContractManagementRoyaltyDisclosureDocumentRatePlanItemDetails(deleteModel, deleteModel.contractRatePlanItemDetailId)
                        .then(({ data }) => {
                            const xrefs = this.MapRatePlanItemDetailsToXrefCollection([data])

                            this.royaltyApiResources
                                .CreateContractManagementRoyaltyDisclosureDocumentRatePlanItemDetailXREF(data.contractRatePlanItemDetailId, xrefs)
                                .then(() => options.success(data))

                            options.success(data)
                        }, (err) => { options.error(err) })

                },
            },
            batch: false,
            schema: {
                model: {
                    id: "contractRatePlanItemDetailId",
                    fields: {
                        contractRatePlanItemDetailId: {
                            type: "number",
                            validation: { required: true },
                            editable: false,
                            defaultValue: 0
                        },
                        [nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryId)]: {
                            type: "number"
                        },
                        [nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryTypeId)]: {
                            type: "number"
                        },
                        contractRatePlanItemId: {
                            type: "number",
                            defaultValue: ratePlanItem.contractRatePlanItemId
                        },
                        contractRatePlanId: {
                            type: "number",
                            validation: { required: true },
                            editable: false,
                            defaultValue: ratePlanItem.contractRatePlanId
                        },
                        royaltyFeeRateResidential: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        royaltyFeeRateCommercial: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        adFeeRateResidential: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        adFeeRateCommercial: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        tafsFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        technologyFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        createdDateTime: {
                            type: "date",
                            defaultValue: new Date()
                        },
                        deletedDateTime: {
                            type: "date",
                            defaultValue: null
                        }
                    }
                }
            },
            pageSize: 20,
            filter: { field: "deletedDateTime", operator: "isnull" }
        });

        let scColumns: Array<kendo.ui.GridColumn> = [
            {
                field: 'contractRatePlanItemDetailId',
                title: "ID",
                hidden: true
            },
            {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryId),
                title: "Service Category",
                editor: (container, options) => {
                    let input = "<input name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description),
                        dataValueField: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.RoyaltyServiceCategoryId),
                        template: `<span class="#: 'deleted-' + (${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.DeletedDateTime)} ? 'true icon-error ' : 'false') #">#: ${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description)} # </span>`,
                        valueTemplate: `<span class="#: 'deleted-' + (${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.DeletedDateTime)} ? 'true icon-error ' : 'false') #">#: ${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description)} # </span>`,
                        dataSource: new kendo.data.DataSource({
                            data: this.royaltyServiceCategories,
                            sort: [
                                {
                                    field: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.DeletedDateTime),
                                    dir: "desc",
                                    compare: (a: RpmEntities.RoyaltyServiceCategoryViewModel, b: RpmEntities.RoyaltyServiceCategoryViewModel) => {
                                        return (a.DeletedDateTime ? 0 : 1) - (b.DeletedDateTime ? 0 : 1)
                                    }
                                },
                                {
                                    field: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description),
                                    dir: "asc"
                                }
                            ]
                        }),
                        change: (e) => {
                            var dataItem = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.royaltyServiceCategories[0];
                            }

                            options.model.set(
                                nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryId),
                                dataItem[nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.RoyaltyServiceCategoryId)]
                            );
                        }
                    });
                },
                template: `{{$ctrl.${nameof(this.GetRoyaltyServiceCategoryById)}(#=${nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryId)}#).${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description)}}}`
            },
            {
                field: nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryTypeId),
                title: "Service Category Type",
                editor: (container, options) => {
                    const input = "<input name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.Description),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.RoyaltyServiceCategoryTypeId),
                        dataSource: {
                            data: this.royaltyServiceCategoryTypes
                        },
                        change: (e) => {
                            var dataItem = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.royaltyServiceCategoryTypes[0];
                            }

                            options.model.set(
                                nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryTypeId),
                                dataItem[nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.RoyaltyServiceCategoryTypeId)]
                            );
                        }
                    });
                },
                template: `{{$ctrl.${nameof(this.GetRoyaltyServiceCategoryTypeById)}(#=${nameof<RoyaltyDisclosureDocumentRatePlanItemDetailModel>(o => o.royaltyServiceCategoryTypeId)}#).Description}}`
            },
            {
                field: 'royaltyFeeRateResidential',
                title: "Res Royalty Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: 'royaltyFeeRateCommercial',
                title: "Com Royalty Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: 'adFeeRateResidential',
                title: "Res Ad Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: 'adFeeRateCommercial',
                title: "Com Ad Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: 'tafsFeeRate',
                title: "TAFS Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: 'technologyFeeRate',
                title: "Tech Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }];
        return {
            navigatable: true,
            scrollable: false,
            columns: scColumns,
            dataSource: scDataSource,
            toolbar: ["create", "cancel",
                {
                    name: "Add All Categories",
                    template: function (ratePlanItem) {
                        return `<a class='k-button' ng-click='$ctrl.AddAllCategoriesPopup($event); $event.preventDefault()'>Add All Categories...</a>`;
                    }(ratePlanItem)
                }],
            editable: !this.readOnly ? { confirmation: false } : false,
            pageable: true,
            edit: (e) => {
                var input = e.container.find(".k-input");
                var value = input.val();

                input.blur((j) => {
                    let columnName = j?.target?.dataset?.bind?.split(":")[1];
                    switch (columnName) {
                        case 'royaltyFeeRateResidential':
                        case 'royaltyFeeRateCommercial':
                        case 'adFeeRateResidential':
                        case 'adFeeRateCommercial':
                        case 'tafsFeeRate':
                        case 'technologyFeeRate':
                            let newValue = input.val();
                            if (value != newValue && newValue != null && (newValue > 0.1)) {
                                alert("Warning! " + columnName + " value above 10%");
                            }
                            break;
                    }
                });
            },
        };
    }

    PullContract() {
        return this.$timeout(() => {
            return this.rmsState.royaltyContractStateHandler.Load(this.contractId, this.isDynamicFees)
                .then((contract) => { this.contract = contract; });
        }, 0);
    }

    SelectRatePlanItem(ratePlanItem: RpmEntities.RoyaltyContractRatePlanItemViewModel, isRemoved: boolean = false) {
        if (this.selectedRatePlanItem === ratePlanItem)
            ratePlanItem = null;

        this.selectedRatePlanItem = ratePlanItem;
        if (this.onSelectedRatePlanItem) {
            this.onSelectedRatePlanItem({ ratePlanItem: this.selectedRatePlanItem, isRemoved: isRemoved });
        }
    }


    SelectRowById(id: number) {
        let dataItem = this.royaltyRatePlanItemGrid.dataSource.get(id);
        if (dataItem)
            this.SelectRatePlanItem(<any>dataItem);
    }

    GetRoyaltyPlanItemTypeById(planItemTypeId: number) {
        return _.find(this.royaltyRatePlanItemTypes, (rpit) => { return rpit.RoyaltyRatePlanItemTypeId === planItemTypeId; });
    }

    AddNewRecord() {
        if (!this.readOnly) {
            let defaultRatePlanItem: any = {
                contractRatePlanItemId: 0,
                contractRatePlanId: this.contractRatePlanId,
                weeksInBusinessStart: 0,
                royaltyRatePlanItemTypeID: 2,
                grossSalesRangeLow: 0,
                grossSalesRangeHigh: 0,
                royaltyFeeRateResidential: 0,
                royaltyFeeFixedRateAmountResidential: 0,
                royaltyFeeRateCommercial: 0,
                royaltyFeeFixedRateAmountCommercial: 0,
                adFeeRateResidential: 0,
                adFeeFixedRateAmountResidential: 0,
                adFeeRateCommercial: 0,
                adFeeFixedRateAmountCommercial: 0,
                rollInAdFeeFixedRateAmount: 0,
                rollInAdFeeRate: 0,
                rollInRoyaltyFeeFixedRateAmount: 0,
                rollInRoyaltyFeeRate: 0,
                technologyFeeRate: 0,
                technologyFeeFixedRateAmount: 0,
                tafsFeeRate: 0,
                tafsFeeFixedRateAmount: 0,
                weeksInBusinessEnd: 0,
                perCapitaLow: 0,
                createdDateTime: new Date(),
                createdUser: this.localStorage.AccountEmail,
            };

            this.royaltyRatePlanItemGrid.dataSource.add(defaultRatePlanItem);
        }
    }

    CancelChanges() {
        if (!this.royaltyRatePlanItemGrid)
            return false;

        if (!this.readOnly) {
            Object.keys(this.rollInRateGrids).forEach(key => {
                this.rollInRateGrids[key].dataSource.cancelChanges();
            });
            this.royaltyRatePlanItemGrid.dataSource.cancelChanges();
        }
    }

    HasChanges() {
        if (!this.royaltyRatePlanItemGrid)
            return false;

        return this.royaltyRatePlanItemGrid.dataSource.hasChanges() ||
            Object.keys(this.rollInRateGrids)
                .map(key => { return this.rollInRateGrids[key].dataSource.hasChanges(); })
                .some(hasChanges => { return hasChanges; });
    }

    ResetChanges() {
        if (!this.royaltyRatePlanItemGrid)
            return this.$q.resolve();

        this.CancelChanges();

        return this.$q.when(this.royaltyRatePlanItemGrid.dataSource.read()).then(() => {
            this.royaltyRatePlanItemGrid.refresh();

            this.$timeout(() => {
                this.formController.$setPristine();
            }, 1000);

            this.rollInRateGrids = {};
        });
    }

    validationErrorsKendoWindow: kendo.ui.Window

    addRatePlanItemsValidationErrorGrid: kendo.ui.Grid;
    addRatePlanItemsValidationErrorGridOptions: kendo.ui.GridOptions;

    MapErrorData = function (originalError: BadRequestErrorResponse): ErrorData[] {
        const errorData: ErrorData[] = [];

        for (const [key, value] of Object.entries(originalError.errors)) {
            const matches = key.match(/x\[(\d+)\]\.(.*)/);
            if (matches) {
                const rowNumber = parseInt(matches[1]);
                const propertyName = matches[2];
                const errorMessages = value;
                let rowData = errorData.find(data => data.rowNumber === rowNumber);
                if (!rowData) {
                    rowData = {
                        rowNumber,
                        errors: []
                    };
                    errorData.push(rowData);
                }
                rowData.errors.push({ propertyName, errorMessages });
            } else {
                const errorDataItem: ErrorData = {
                    rowNumber: null, // Indicating it's not related to a specific row
                    errors: [{ propertyName: key, errorMessages: value }]
                };
                errorData.push(errorDataItem);
            }
        }

        return errorData;
    }

    ShowErrorGrid = function (errorResponse: BadRequestErrorResponse) {

        const errorDataCollection: ErrorData[] = this.MapErrorData(errorResponse);

        const adjustmentCreationValidationErrors: AdjustmentCreationValidationError[] = [];

        for (const errorData of errorDataCollection) {
            for (const error of errorData.errors) {
                for (const errorMessage of error.errorMessages) {
                    const adjustmentCreationValidationError: AdjustmentCreationValidationError = {
                        rowNumber: errorData.rowNumber,
                        propertyName: error.propertyName,
                        errorMessage
                    };
                    adjustmentCreationValidationErrors.push(adjustmentCreationValidationError);
                }
            }
        }

        this.UpdateGridOptions(adjustmentCreationValidationErrors);
    }

    showSnackbar: boolean;
    snackbarType: string;
    snackbarMessage: string;

    DisplaySnackbar(type: string, message: string) {
        this.showSnackbar = !this.showSnackbar;
        this.snackbarType = type;
        this.snackbarMessage = message;
        this.$scope.$evalAsync();
    }

    UpdateGridOptions = function (adjustmentCreationValidationErrors) {
        if (!this.addRatePlanItemsValidationErrorGridOptions) {
            // Create new object if it's null
            this.addRatePlanItemsValidationErrorGridOptions = {
                dataSource: {
                    data: adjustmentCreationValidationErrors
                },
                columns: [
                    // { field: 'rowNumber', title: 'Row No.' },
                    { field: 'propertyName', title: 'Property' },
                    { field: 'errorMessage', title: 'Error Message' }
                ]
            };
        } else {
            // Update dataSource if it exists
            const dataSource = new kendo.data.DataSource({
                data: adjustmentCreationValidationErrors
            });

            this.addRatePlanItemsValidationErrorGrid.setDataSource(dataSource)
            this.addRatePlanItemsValidationErrorGrid.dataSource.read(); // Read the new data from the data source
            this.addRatePlanItemsValidationErrorGrid.refresh();
        }
    };

    ValidateRoyaltyRatePlanItems() {
        const validationErrors = [];

        // Ensure royaltyRatePlanItemGrid exists and has a dataSource
        if (!this.royaltyRatePlanItemGrid || !this.royaltyRatePlanItemGrid.dataSource) {
            return validationErrors;
        }

        // Check if there are changes in the dataSource
        if (!this.royaltyRatePlanItemGrid.dataSource.hasChanges()) {
            return validationErrors;
        }

        // Get ratePlanItems and filter out deleted items
        const ratePlanItems = this.royaltyRatePlanItemGrid.dataSource.data()?.filter(x => !x["deletedDateTime"]);

        // Continue only if ratePlanItems is not empty or null
        if (!ratePlanItems || ratePlanItems.length === 0) {
            return validationErrors;
        }


        for (let i = 0; i < ratePlanItems.length; i++) {
            const dataItem = ratePlanItems[i];

            // Check missing WIB Start
            if (dataItem.weeksInBusinessStart == null || dataItem.weeksInBusinessStart == undefined) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "WIB Start",
                    errorMessage: "Please enter WIB Start for Royalty Rate Plan Item"
                });
            }

            // Check missing WIB End
            if (dataItem.weeksInBusinessEnd == null || dataItem.weeksInBusinessEnd == undefined) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "WIB End",
                    errorMessage: "Please enter WIB End for Royalty Rate Plan Item"
                });
            }

            // Check for upper and lower limit for WIB End
            if (dataItem.weeksInBusinessEnd < -500 || dataItem.weeksInBusinessEnd > 1_000_000) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "WIB End",
                    errorMessage: "WIB End should range between -500 to 1M for Royalty Rate Plan Item"
                });
            }

            // Check for upper and lower limit for WIB Start
            if (dataItem.weeksInBusinessStart < -500 || dataItem.weeksInBusinessStart > 1_000_000) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "WIB Start",
                    errorMessage: "WIB Start should range between -500 to 1M for Royalty Rate Plan Item"
                });
            }

            // Check if WIB start is greater than WIB end
            if (dataItem.weeksInBusinessStart > dataItem.weeksInBusinessEnd) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "WIB start/WIB end",
                    errorMessage: "WIB Start should be less than or equal to WIB End for Royalty Rate Plan Item"
                });
            }

            // Check if grossSalesRangeLow is empty, not a number, or outside the range
            if (
                dataItem.grossSalesRangeLow == null ||
                isNaN(dataItem.grossSalesRangeLow) ||
                dataItem.grossSalesRangeLow < 0 ||
                dataItem.grossSalesRangeLow > 9_99_99_999.99
            ) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "Sales Scale Begins",
                    errorMessage: "Sales Scale Begins must be a number within the range of 0 to 99,999,999 for Royalty Rate Plan Item"
                });
            }

            // Check if grossSalesRangeHigh is empty, not a number, or outside the range
            if (
                dataItem.grossSalesRangeHigh == null ||
                isNaN(dataItem.grossSalesRangeHigh) ||
                dataItem.grossSalesRangeHigh < 0 ||
                dataItem.grossSalesRangeHigh > 9_99_99_999.99
            ) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "Sales Scale Ends",
                    errorMessage: "Sales Scale Ends must be a number within the range of 0 to 99,999,999 for Royalty Rate Plan Item"
                });
            }

            // Validate that Sales Scale Begins is less than or equal to Sales Scale Ends
            if (dataItem.grossSalesRangeLow > dataItem.grossSalesRangeHigh) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "Sales Scale Begins and Sales Scale Ends",
                    errorMessage: "Sales Scale Begins should be less than or equal to Sales Scale Ends"
                });
            }

            // Check if royaltyRatePlanItemTypeID is empty
            if (dataItem.royaltyRatePlanItemTypeID == null || dataItem.royaltyRatePlanItemTypeID == undefined) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "Royalty Rate Plan Item Type",
                    errorMessage: "Rate Plan Item Type is required for Royalty Rate Plan Item"
                });
            }

            // Check if grossSalesRangeLow has less than 4 decimal places
            if (!this.HasLessThanDecimalPlaces(dataItem.grossSalesRangeLow, 4)) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "Sales Scale Begins",
                    errorMessage: "Sales Scale Begins must have less than 4 decimal places"
                });
            }

            // Check if grossSalesRangeHigh has less than 4 decimal places
            if (!this.HasLessThanDecimalPlaces(dataItem.grossSalesRangeHigh, 4)) {
                validationErrors.push({
                    rowNumber: i + 1,
                    propertyName: "Sales Scale Ends",
                    errorMessage: "Sales Scale Ends must have less than 4 decimal places"
                });
            }
        }
        return validationErrors;
    }

    ValidateRollInRateGrids() {
        return this.ValidateRollInRates(); // Assuming ValidateRollInRates is already a separate method
    }

    ValidateServiceCategoryGrid() {
        const validationErrors = [];

        // Ensure serviceCategoryGrid exists
        if (!this.serviceCategoryGrid) {
            return validationErrors;
        }

        if (this.serviceCategoryGrid) {
            for (const key of Object.keys(this.serviceCategoryGrid)) {
                const grid = this.serviceCategoryGrid[key];

                // Ensure grid and its dataSource exist
                if (!grid || !grid.dataSource) {
                    continue;
                }

                // Check if there are changes in the dataSource
                if (!grid.dataSource.hasChanges()) {
                    continue;
                }

                const dataSource = grid.dataSource.data();

                // Continue only if dataSource is not empty or null
                if (!dataSource || dataSource.length === 0) {
                    continue;
                }

                for (let i = 0; i < dataSource.length; i++) {
                    const dataItem = dataSource[i];

                    // Validate Royalty Service Category
                    if (dataItem.royaltyServiceCategoryId == null || dataItem.royaltyServiceCategoryId <= 0) {
                        validationErrors.push({
                            rowNumber: i + 1,
                            propertyName: "Royalty Service Category",
                            errorMessage: "Royalty Service Category is required in Service Category Grid"
                        });
                    }

                    // Validate Royalty Service Category Type
                    if (dataItem.royaltyServiceCategoryTypeId == null || dataItem.royaltyServiceCategoryTypeId <= 0) {
                        validationErrors.push({
                            rowNumber: i + 1,
                            propertyName: "Royalty Service Category Type",
                            errorMessage: "Royalty Service Category Type is required in Service Category Grid"
                        });
                    }

                    // Add more validation checks here if needed
                }
            }
        }
        return validationErrors;
    }



    async SaveChanges() {
        let validationErrors = [];
        validationErrors = validationErrors.concat(this.ValidateRoyaltyRatePlanItems());
        validationErrors = validationErrors.concat(this.ValidateRollInRateGrids());
        validationErrors = validationErrors.concat(this.ValidateServiceCategoryGrid());

        if (validationErrors.length > 0) {
            this.UpdateGridOptions(validationErrors);
            this.DisplaySnackbar("error", "There are validation errors. Please fix them and try again!");
            this.validationErrorsKendoWindow.open();
            throw new Error("There are validation errors. Please fix them and try again!");
        }

        try {
            console.log("Syncing rollInRateGrids...");
            for (const key of Object.keys(this.rollInRateGrids)) {
                await this.rollInRateGrids[key].dataSource.sync();
            }

            console.log("Syncing royaltyRatePlanItemGrid...");
            await this.royaltyRatePlanItemGrid.dataSource.sync();

            // Capture updated IDs after syncing royaltyRatePlanItemGrid
            const updatedRoyaltyRatePlanItems = new Map<string, number>();
            this.royaltyRatePlanItemGrid.dataSource.view().forEach((item: any) => {
                updatedRoyaltyRatePlanItems.set(item.uid, item.id);
            });

            // Now process serviceCategoryGrid with correct contractRatePlanItemId
            if (this.serviceCategoryGrid) {
                console.log("Updating serviceCategoryGrid...");
                for (const key of Object.keys(this.serviceCategoryGrid)) {
                    const serviceCategoryDataSource = this.serviceCategoryGrid[key].dataSource;

                    serviceCategoryDataSource.data().forEach(serviceCategoryItem => {
                        if (serviceCategoryItem.contractRatePlanItemId === 0) {
                            const updatedId = updatedRoyaltyRatePlanItems.get(key);
                            if (updatedId) {
                                serviceCategoryItem.contractRatePlanItemId = updatedId;
                            }
                        }
                    });

                    await serviceCategoryDataSource.sync();
                    await this.ResetServiceCategoryChanges(key);
                }
            }

            console.log("SaveChanges completed successfully.");
        } catch (error) {
            console.error("Error during SaveChanges:", error);
            throw error;
        }
    }



    ValidateRollInRates = function () {
        const validationErrors = [];

        // Ensure rollInRateGrids exists
        if (!this.rollInRateGrids) {
            return validationErrors;
        }

        const validationRules = [
            {
                property: 'royaltyFeeRate',
                conditions: [
                    {
                        type: 'range',
                        validate: function (value) {
                            return value != null && value >= 0 && value <= 1;
                        },
                        errorMessage: 'Royalty Fee Rate must be between 0 and 1'
                    },
                    {
                        type: 'decimal',
                        validate: value => this.HasLessThanDecimalPlaces(value, 4),
                        errorMessage: 'Royalty Fee Rate must have less than 4 decimal places (e.g., 0.123 for 12.3%)'
                    }
                ]
            },
            {
                property: 'royaltyFeeFixedRateAmount',
                conditions: [
                    {
                        type: 'range',
                        validate: function (value) {
                            return value != null && value >= 0 && value <= 1000;
                        },
                        errorMessage: 'Royalty Fee Fixed Rate Amount must be between 0 and 1,000'
                    },
                    {
                        type: 'decimal',
                        validate: value => this.HasLessThanDecimalPlaces(value, 4),
                        errorMessage: 'Royalty Fee Fixed Rate Amount must have less than 4 decimal places'
                    }
                ]
            },
            {
                property: 'adFeeRate',
                conditions: [
                    {
                        type: 'range',
                        validate: function (value) {
                            return value != null && value >= 0 && value <= 1;
                        },
                        errorMessage: 'Ad Fee Rate must be between 0 and 1'
                    },
                    {
                        type: 'decimal',
                        validate: value => this.HasLessThanDecimalPlaces(value, 4),
                        errorMessage: 'Ad Fee Rate must have less than 4 decimal places (e.g., 0.123 for 12.3%)'
                    }
                ]
            },
            {
                property: 'adFeeFixedRateAmount',
                conditions: [
                    {
                        type: 'range',
                        validate: function (value) {
                            return value != null && value >= 0 && value <= 1000;
                        },
                        errorMessage: 'Ad Fee Fixed Rate Amount must be between 0 and 1,000'
                    },
                    {
                        type: 'decimal',
                        validate: value => this.HasLessThanDecimalPlaces(value, 4),
                        errorMessage: 'Ad Fee Fixed Rate Amount must have less than 4 decimal places'
                    }
                ]
            }
        ];

        Object.keys(this.rollInRateGrids).forEach(key => {
            const grid = this.rollInRateGrids[key];

            // Ensure grid and its dataSource exist
            if (!grid || !grid.dataSource) {
                return;
            }

            // Check if there are changes in the dataSource
            if (!grid.dataSource.hasChanges()) {
                return;
            }

            const rollInRates = grid.dataSource.data()?.filter(x => !x.deletedDateTime);

            // Continue only if rollInRates is not empty or null
            if (!rollInRates || rollInRates.length === 0) {
                return;
            }

            rollInRates.forEach((rateItem, index) => {
                validationRules.forEach(rule => {
                    const value = rateItem[rule.property];

                    rule.conditions.forEach(condition => {
                        if (!condition.validate(value)) {
                            validationErrors.push({
                                gridKey: key,
                                rowNumber: index + 1,
                                propertyName: rule.property,
                                errorMessage: condition.errorMessage
                            });
                        }
                    });
                });
            });
        });

        return validationErrors;
    };


    HasLessThanDecimalPlaces(value: number, decimalPlacesAllowed: number = 2) {
        if (typeof value !== 'number') return false;
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || decimalPart.length <= decimalPlacesAllowed;
    }

    HasServiceCategoryChanges() {
        if (!this.serviceCategoryGrid)
            return false;

        return Object.keys(this.serviceCategoryGrid)
            .map(key => { return this.serviceCategoryGrid[key].dataSource.hasChanges(); })
            .some(hasChanges => { return hasChanges; }) ||
            Object.keys(this.rollInRateGrids)
                .map(key => { return this.rollInRateGrids[key].dataSource.hasChanges(); })
                .some(hasChanges => { return hasChanges; });
    }

    CancelServiceCatChanges(key) {
        if (!this.readOnly) {
            Object.keys(this.rollInRateGrids).forEach(keyRG => {
                this.rollInRateGrids[keyRG].dataSource.cancelChanges();
            });
            this.serviceCategoryGrid[key].dataSource.cancelChanges()
        }
    }
    ResetServiceCategoryChanges(key) {
        this.CancelServiceCatChanges(key)
        return this.serviceCategoryGrid[key].dataSource.read().then(() => {
            this.$timeout(() => {
                this.formController.$setPristine();
            }, 1000);

            this.rollInRateGrids = {};

        });
    }

    MapRatePlanItemDetailsToXrefCollection = function (
        ratePlanItemDetails: Array<ContractRatePlanItemDetail>
    ): ContractRatePlanItemDetailFeeXref[] {
        const xrefCollection: ContractRatePlanItemDetailFeeXref[] = [];

        for (const ratePlanItemDetail of (ratePlanItemDetails || [])) {
            const createXrefEntry = (
                feeTypeId: FeeType,
                feeRateResidential: number,
                feeRateCommercial: number,
                fixedFeeAmount: number
            ): ContractRatePlanItemDetailFeeXref => ({
                royaltyContractRatePlanItemDetailFeeXrefId: null,
                contractRatePlanItemDetailId: ratePlanItemDetail.contractRatePlanItemDetailId,
                feeTypeId,
                feeRateResidential,
                feeRateCommercial,
                fixedFeeAmount,
                createdDateTime: ratePlanItemDetail.createdDateTime,
                createdUser: ratePlanItemDetail.createdUser || '',
                updatedDateTime: ratePlanItemDetail.updatedDateTime,
                updatedUser: ratePlanItemDetail.updatedUser || '',
                deletedDateTime: ratePlanItemDetail.deletedDateTime
            });

            // Add Xref entries for each fee type in the ratePlanItemDetail
            if (ratePlanItemDetail.technologyFeeRate !== undefined) {
                xrefCollection.push(createXrefEntry(
                    FeeType.TechnologyFee,
                    ratePlanItemDetail.technologyFeeRate,
                    0,
                    0
                ));
            }

            if (ratePlanItemDetail.tafsFeeRate !== undefined) {
                xrefCollection.push(createXrefEntry(
                    FeeType.TAFSFee,
                    ratePlanItemDetail.tafsFeeRate,
                    0,
                    0
                ));
            }

            if (ratePlanItemDetail.royaltyFeeRateResidential !== undefined || ratePlanItemDetail.royaltyFeeRateCommercial !== undefined) {
                xrefCollection.push(createXrefEntry(
                    FeeType.RoyaltyFee,
                    ratePlanItemDetail.royaltyFeeRateResidential,
                    ratePlanItemDetail.royaltyFeeRateCommercial,
                    0
                ));
            }

            if (ratePlanItemDetail.adFeeRateResidential !== undefined || ratePlanItemDetail.adFeeRateCommercial !== undefined) {
                xrefCollection.push(createXrefEntry(
                    FeeType.AdFee,
                    ratePlanItemDetail.adFeeRateResidential,
                    ratePlanItemDetail.adFeeRateCommercial,
                    0
                ));
            }

            if (ratePlanItemDetail.rollInRoyaltyFeeRate !== undefined) {
                xrefCollection.push(createXrefEntry(
                    FeeType.RollInRoyaltyFee,
                    ratePlanItemDetail.rollInRoyaltyFeeRate,
                    0,
                    0
                ));
            }

            if (ratePlanItemDetail.rollInAdFeeRate !== undefined) {
                xrefCollection.push(createXrefEntry(
                    FeeType.RollInAdFee,
                    ratePlanItemDetail.rollInAdFeeRate,
                    0,
                    0
                ));
            }
        }

        return xrefCollection;
    }


    MapRatePlanItemsToXrefCollection = function (
        ratePlanItems: Array<ContractRatePlanItem>
    ): ContractRatePlanItemFeeXref[] {
        const xrefCollection: ContractRatePlanItemFeeXref[] = [];

        for (const ratePlanItem of (ratePlanItems || [])) {
            const createXrefEntry = (
                feeTypeId: FeeType,
                feeRateResidential: number,
                feeRateCommercial: number,
                fixedFeeAmount: number
            ): ContractRatePlanItemFeeXref => ({
                royaltyContractRatePlanItemFeeXrefId: null,
                contractRatePlanItemId: ratePlanItem.contractRatePlanItemId!,
                feeTypeId,
                feeRateResidential,
                feeRateCommercial,
                fixedFeeAmount,
                createdDateTime: ratePlanItem.createdDateTime!,
                createdUser: ratePlanItem.createdUser || '',
                updatedDateTime: ratePlanItem.updatedDateTime,
                updatedUser: ratePlanItem.updatedUser || '',
                deletedDateTime: ratePlanItem.deletedDateTime
            });

            // Add Xref entries for each fee type in the ratePlanItem
            if (ratePlanItem.technologyFeeRate !== undefined || ratePlanItem.technologyFeeFixedRateAmount !== undefined) {
                xrefCollection.push(createXrefEntry(
                    FeeType.TechnologyFee,
                    ratePlanItem.technologyFeeRate ?? 0,
                    0,
                    ratePlanItem.technologyFeeFixedRateAmount ?? 0
                ));
            }

            if (ratePlanItem.tafsFeeRate !== undefined || ratePlanItem.tafsFeeFixedRateAmount !== undefined) {
                xrefCollection.push(createXrefEntry(
                    FeeType.TAFSFee,
                    ratePlanItem.tafsFeeRate ?? 0,
                    0,
                    ratePlanItem.tafsFeeFixedRateAmount ?? 0
                ));
            }

            if (ratePlanItem.royaltyFeeRateResidential !== undefined || ratePlanItem.royaltyFeeRateCommercial !== undefined || ratePlanItem.royaltyFeeFixedRateAmountResidential !== undefined) {
                xrefCollection.push(createXrefEntry(
                    FeeType.RoyaltyFee,
                    ratePlanItem.royaltyFeeRateResidential ?? 0,
                    ratePlanItem.royaltyFeeRateCommercial ?? 0,
                    ratePlanItem.royaltyFeeFixedRateAmountResidential ?? 0
                ));
            }

            if (ratePlanItem.adFeeRateResidential !== undefined || ratePlanItem.adFeeRateCommercial !== undefined || ratePlanItem.adFeeFixedRateAmountCommercial !== undefined) {
                xrefCollection.push(createXrefEntry(
                    FeeType.AdFee,
                    ratePlanItem.adFeeRateResidential ?? 0,
                    ratePlanItem.adFeeRateCommercial ?? 0,
                    ratePlanItem.adFeeFixedRateAmountCommercial ?? 0
                ));
            }

            if (ratePlanItem.rollInRoyaltyFeeRate !== undefined || ratePlanItem.rollInRoyaltyFeeFixedRateAmount !== undefined) {
                xrefCollection.push(createXrefEntry(
                    FeeType.RollInRoyaltyFee,
                    ratePlanItem.rollInRoyaltyFeeRate ?? 0,
                    0,
                    ratePlanItem.rollInRoyaltyFeeFixedRateAmount ?? 0
                ));
            }

            if (ratePlanItem.rollInAdFeeRate !== undefined || ratePlanItem.rollInAdFeeFixedRateAmount !== undefined) {
                xrefCollection.push(createXrefEntry(
                    FeeType.RollInAdFee,
                    ratePlanItem.rollInAdFeeRate ?? 0,
                    0,
                    ratePlanItem.rollInAdFeeFixedRateAmount ?? 0
                ));
            }
        }


        return xrefCollection;
    }


    static BindComponent(app: ng.IModule) {
        app
            .component("cmRoyaltiesRatePlanItemsDynamicFees", {
                bindings: {
                    [nameof<cmRoyaltiesRatePlanItemsDynamicFeesComponentController>(o => o.contractRatePlanId)]: "<",
                    [nameof<cmRoyaltiesRatePlanItemsDynamicFeesComponentController>(o => o.readOnly)]: "<",
                    [nameof<cmRoyaltiesRatePlanItemsDynamicFeesComponentController>(o => o.franchiseId)]: "<",
                    [nameof<cmRoyaltiesRatePlanItemsDynamicFeesComponentController>(o => o.isMethodologySpecial)]: "<",
                    [nameof<cmRoyaltiesRatePlanItemsDynamicFeesComponentController>(o => o.contractId)]: "<",
                    [nameof<cmRoyaltiesRatePlanItemsDynamicFeesComponentController>(o => o.conceptId)]: "<",
                    [nameof<cmRoyaltiesRatePlanItemsDynamicFeesComponentController>(o => o.onSelectedRatePlanItem)]: "&?",
                    [nameof<cmRoyaltiesRatePlanItemsDynamicFeesComponentController>(o => o.onLoaded)]: "&?"
                },
                templateUrl: '/Templates/ContractManagement/cmRoyalties/RatePlanItems_df.html',
                controller: cmRoyaltiesRatePlanItemsDynamicFeesComponentController
            });
    }
}