import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import {
    GenderDto,
    LanguageDto
} from '@nbly/franchise-employee-group-clients';
import {
    FranchiseProfileEmployeeBiosRm, FranchiseProfileEmployeeBioDto,
    FranchiseProfileEmployeeBioClient, FranchiseProfileEmployeeBioDetailRm,
    FranchiseProfileEmployeeBioXrefRm, FranchiseProfileEmployeeBioSearchDto,
    FranchiseEmployeeBioSearchResultVm
} from '@nbly/franchise-employee-orchestrations-clients';
import {
    FranchiseWebLocationDto
} from '@nbly/franchise-group-api-clients';


export class EmployeeBiosGeneralInfoComponent implements ng.IController {
    //---- Bindings ----
    franchiseId: number;
    genderList: GenderDto[];
    conceptCode: string;
    employeeBioDetail: FranchiseProfileEmployeeBioDto;
    employeeBio: FranchiseProfileEmployeeBioSearchDto;
    employeeBioVm: FranchiseEmployeeBioSearchResultVm;
    //------------------

    employeeOrchestrationsClient: AxiosInstance;
    franchiseProfileEmployeeBioClient: FranchiseProfileEmployeeBioClient;

    conceptId: number;
    canRedThrottle: boolean;
    isLoading: boolean;
    genderOptions: kendo.ui.DropDownListOptions;
    serviceProfessionalTitleOptions: kendo.ui.DropDownListOptions = null;
    maritialOptions: kendo.ui.DropDownListOptions;
    franchiseProfileEmployeeBiosRmDto: FranchiseProfileEmployeeBiosRm;

    weblocationMultiSelect: kendo.ui.MultiSelect;
    weblocationMultiSelectOptions: kendo.ui.MultiSelectOptions;
    franchiseWebLocationId: number;

    franchiseWebLocationDto: FranchiseWebLocationDto[];
    currentYear: number;
    onApiSave: () => void;


    static $inject = [
        "coreApiResources",
        "callCenterApiResources",
        "callCenterState",
        "identityManager",
        "apiConfig",
        "$timeout",
        "$scope",
        "$q",
    ];

    constructor() {
        this.currentYear = new Date().getFullYear();
    }

    $onInit() {
        this.employeeOrchestrationsClient = FranForceAxiosClientBuilder.BuildEmployeeOrchestrationsBaseClient();
        this.franchiseProfileEmployeeBioClient = new FranchiseProfileEmployeeBioClient("", this.employeeOrchestrationsClient);
    }

    $postLink() {
        this.SetDropDownOptions();
    }

    SetDropDownOptions() {

        this.weblocationMultiSelectOptions =
        {
            placeholder: "Select Weblocations...",
            dataTextField: nameof.full<FranchiseWebLocationDto>(o => o.doingBusinessAs),
            dataValueField: nameof.full<FranchiseWebLocationDto>(o => o.franchiseWebLocationId),
            valuePrimitive: true,
            autoBind: true,
            dataSource: this.franchiseWebLocationDto,
            dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) => {
                this.weblocationMultiSelect = e.sender;
            },
        };


        this.maritialOptions = {
            /*  valuePrimitive: false, */
            dataValueField: "value",
            dataTextField: "name",
            /*  autoBind:false, */
            optionLabel: 'None',
            dataSource: new kendo.data.DataSource({
                data: [
                    { name: "Married", value: true },
                    { name: "Un-Married", value: false },
                ],
            }),
        };

        this.genderOptions = {
            valuePrimitive: false,
            dataValueField: nameof.full<GenderDto>(o => o.genderId),
            dataTextField: nameof.full<GenderDto>(o => o.description),
            autoBind: true,
            dataSource: new kendo.data.DataSource({
                data: this.genderList
            }),
        };
        if (this.conceptCode === 'PDS') {
            this.serviceProfessionalTitleOptions = {
                valuePrimitive: false,
                dataValueField: 'value',
                dataTextField: 'name',
                autoBind: true,
                dataSource: [
                    { name: "Garage Door Technician", value: 22 },
                    { name: "Garage Door Installer", value: 23 },
                    { name: "Garage Designer", value: 24 },
                ]
            };
        } else if(this.conceptCode === 'MDG') {
            this.serviceProfessionalTitleOptions = {
                valuePrimitive: false,
                dataValueField: 'value',
                dataTextField: 'name',
                autoBind: true,
                dataSource: [
                    { name: "Glass Specialist (Auto)", value: 20 },
                    { name: "Glass Specialist (Home and Business)", value: 19 },
                    { name: "Glass Specialist (Full Service)", value: 21 },
                ]
            };
        }
    }

    async SaveBioDetail() {

        this.franchiseProfileEmployeeBiosRmDto = <FranchiseProfileEmployeeBiosRm>{};
        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails = <FranchiseProfileEmployeeBioDetailRm>{};
        this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioId = this.employeeBioDetail.franchiseProfileEmployeeBioId;
        if (this.employeeBio?.franchiseProfileEmployeeBioXrefId) {
            this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm = <FranchiseProfileEmployeeBioXrefRm>{};
            this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm.franchiseProfileEmployeeBioXrefId = this.employeeBio.franchiseProfileEmployeeBioXrefId;
            this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm.syncTechnicianEmployeeId = this.employeeBio.syncTechnicianId;;
            this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm.type = this.employeeBio.type;
        } else if (this.employeeBioVm?.franchiseProfileEmployeeBioXrefId) {
            this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm = <FranchiseProfileEmployeeBioXrefRm>{};
            this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm.franchiseProfileEmployeeBioXrefId = this.employeeBioVm.franchiseProfileEmployeeBioXrefId;
            this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm.syncTechnicianEmployeeId = this.employeeBioVm.syncTechnicianEmployeeId;
            this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm.type = this.employeeBioVm.type;

        }

        this.franchiseProfileEmployeeBiosRmDto.webImageId = this.employeeBioDetail.webImageId;
        this.franchiseProfileEmployeeBiosRmDto.franchiseId = this.employeeBioDetail.franchiseId;
        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.genderTypeId = Number(this.employeeBioDetail.franchiseProfileEmployeeBioDetail.genderTypeId);
        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.franchiseProfileEmployeeBioId = this.employeeBioDetail.franchiseProfileEmployeeBioId;
        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.state = this.employeeBioDetail.franchiseProfileEmployeeBioDetail.state;
        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.city = this.employeeBioDetail.franchiseProfileEmployeeBioDetail.city;
        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.yearsExpierenceDateOfHire = this.employeeBioDetail.franchiseProfileEmployeeBioDetail.yearsExpierenceDateOfHire;
        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.yearHired = this.employeeBioDetail.franchiseProfileEmployeeBioDetail.yearHired;
        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.firstName = this.employeeBioDetail.franchiseProfileEmployeeBioDetail.firstName;
        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.lastName = this.employeeBioDetail.franchiseProfileEmployeeBioDetail.lastName;
        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.title = this.employeeBioDetail.franchiseProfileEmployeeBioDetail.title;

        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.maritalStatus = this.employeeBioDetail.franchiseProfileEmployeeBioDetail.maritalStatus;


        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.circularPhotoUrl = this.employeeBioDetail.franchiseProfileEmployeeBioDetail.circularPhotoUrl;
        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.squarePhotoUrl = this.employeeBioDetail.franchiseProfileEmployeeBioDetail.squarePhotoUrl;
        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.photoExtension = this.employeeBioDetail.franchiseProfileEmployeeBioDetail.photoExtension;
        let martialStatus: any = this.employeeBioDetail.franchiseProfileEmployeeBioDetail.maritalStatus;
        if (martialStatus && martialStatus.toString()) {
            this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.maritalStatus = JSON.parse(martialStatus.toString());
        }
        if (martialStatus === "") {
            this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.maritalStatus = null;
        }

        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.numberOfChildren = this.employeeBioDetail.franchiseProfileEmployeeBioDetail.numberOfChildren;
        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.franchiseProfileEmployeeBioDetailsId = this.employeeBioDetail.franchiseProfileEmployeeBioDetail.franchiseProfileEmployeeBioDetailsId;
        this.franchiseProfileEmployeeBiosRmDto.createdUser = this.employeeBioDetail.createdUser;
        this.franchiseProfileEmployeeBiosRmDto.displayName = this.employeeBioDetail.displayName;
        this.franchiseProfileEmployeeBiosRmDto.webLocationIds = this.employeeBioDetail.webLocationIds;
        this.franchiseProfileEmployeeBiosRmDto.webLocationIds = this.employeeBioDetail.webLocationIds;
        this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.franchiseProfileEmployeeBioVariableId = this.employeeBioDetail.franchiseProfileEmployeeBioDetail?.franchiseProfileEmployeeBioVariableId ?
            parseInt(this.employeeBioDetail.franchiseProfileEmployeeBioDetail?.franchiseProfileEmployeeBioVariableId.toString()) : null;
        await this.franchiseProfileEmployeeBioClient.franchise_profile_employee_bios2(
            this.franchiseProfileEmployeeBiosRmDto
        )
            .then((response) => {
                if (this.employeeBio) {
                    this.employeeBio.displayName = response.result.displayName;
                } else if (this.employeeBioVm) {
                    this.employeeBioVm.displayName = response.result.displayName;
                }

                this.onApiSave();
            })

    }


    static BindComponent(app: ng.IModule) {
        app.component("employeeBiosGeneralInfo", {
            bindings: {
                franchiseId: "<",
                [nameof<EmployeeBiosGeneralInfoComponent>(o => o.genderList)]: "<",
                [nameof<EmployeeBiosGeneralInfoComponent>(o => o.conceptCode)]: "<",
                [nameof<EmployeeBiosGeneralInfoComponent>(o => o.employeeBioDetail)]: "=",
                [nameof<EmployeeBiosGeneralInfoComponent>(o => o.employeeBio)]: "=",
                [nameof<EmployeeBiosGeneralInfoComponent>(o => o.employeeBioVm)]: "=",
                onApiSave: "&",
                franchiseWebLocationDto: "<",
                [nameof<EmployeeBiosGeneralInfoComponent>(o => o.franchiseWebLocationId)]: "<",
            },
            controller: EmployeeBiosGeneralInfoComponent,
            templateUrl: "/Templates/EmployeeBios/EmployeeBiosGeneralInfo.html",
        });
    }
}

