import { Helpers } from "Utility/Helpers";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import * as _ from "underscore"
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";


export class FranchiseProfileHoursOfOperationController implements ng.IController 
{
    franchiseId: number;

    franchiseProfileOpenTimes: CoreEntities.FranchiseProfileOpenTime[];

    DaysOfWeekObjs: { id: number, name: string }[];
    
    static $inject = [
        'coreApiResources',
        'odataHelper',
        '$q',
        '$log',
        '$timeout',
        '$window'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private odataHelper: ODataHelperService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private $window: ng.IWindowService
    )
    {
    }

    $onInit()
    {
        this.DaysOfWeekObjs = Helpers.DaysOfWeekObjects();
    }

    $postLink()
    {
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.franchiseId)] && this.franchiseId)
        {
            this.LoadOpenTimes();
        }
    }
    

    LoadOpenTimes()
    {
        return this.coreApiResources.FranchiseProfileOpenTimeApi
            .query({ $filter: `${nameof<CoreEntities.FranchiseProfileOpenTime>(o => o.FranchiseId)} eq ${this.franchiseId}` }).$promise
            .then((openTimes) =>
            {
                let conceptOpenTimesPromise: ng.IPromise<CoreEntities.ConceptOpenTime[]> = null;
                for (let dayOfWeek of this.DaysOfWeekObjs) {
                    let foundOpenTime = _.find(openTimes, (ot) =>
                    {
                        return ot.DayOfWeek === dayOfWeek.id
                    });

                    if (!foundOpenTime) {
                        conceptOpenTimesPromise = conceptOpenTimesPromise || this.coreApiResources.FranchiseApi
                            .get({ id: this.franchiseId, $select: nameof<CoreEntities.Franchise>(o => o.ConceptId) }).$promise
                            .then((f) =>
                            {
                                return this.coreApiResources.ConceptOpenTimeApi.query({ $filter: `${nameof<CoreEntities.ConceptOpenTime>(o => o.ConceptId)} eq ${f.ConceptId}` }).$promise
                            });

                        conceptOpenTimesPromise = conceptOpenTimesPromise.then((conceptOpenTimes) =>
                        {
                            let foundConceptOpenTime = _.find(openTimes, (cot) =>
                            {
                                return cot.DayOfWeek == dayOfWeek.id;
                            });

                            let freshFranchiseOpenTime = new this.coreApiResources.FranchiseProfileOpenTimeApi();
                            angular.extend(freshFranchiseOpenTime, <Partial<CoreEntities.FranchiseProfileOpenTime>>{
                                DayOfWeek: dayOfWeek.id,
                                FranchiseId: this.franchiseId,
                                CreatedDateTime: null,
                                ModifiedDateTime: null,
                                FranchiseProfile: null
                            });

                            if (foundConceptOpenTime)
                            {
                                angular.extend(freshFranchiseOpenTime, <Partial<CoreEntities.FranchiseProfileOpenTime>>{
                                    IsOpenForNormalHours: foundConceptOpenTime.IsOpenForNormalHours,
                                    IsOpenForBeforeHours: foundConceptOpenTime.IsOpenForBeforeHours,
                                    IsOpenForAfterHours: foundConceptOpenTime.IsOpenForAfterHours,
                                    StartMinutesBeforeHours: foundConceptOpenTime.StartMinutesBeforeHours,
                                    EndMinutesBeforeHours: foundConceptOpenTime.EndMinutesBeforeHours,
                                    StartMinutes: foundConceptOpenTime.StartMinutes,
                                    EndMinutes: foundConceptOpenTime.EndMinutes,
                                    StartMinutesAfterHours: foundConceptOpenTime.StartMinutesAfterHours,
                                    EndMinutesAfterHours: foundConceptOpenTime.EndMinutesAfterHours,
                                });
                            }
                            else {
                                angular.extend(freshFranchiseOpenTime, <Partial<CoreEntities.FranchiseProfileOpenTime>>{
                                    IsOpenForNormalHours: false,
                                    IsOpenForBeforeHours: false,
                                    IsOpenForAfterHours: false,
                                    StartMinutesBeforeHours: 0,
                                    EndMinutesBeforeHours: 0,
                                    StartMinutes: 0,
                                    EndMinutes: 60 * 24,
                                    StartMinutesAfterHours: 60 * 24,
                                    EndMinutesAfterHours: 60 * 24,
                                });
                            }

                            openTimes.push(freshFranchiseOpenTime)

                            return conceptOpenTimes;
                        });
                    }
                }

                return this.$q.resolve(conceptOpenTimesPromise)
                    .then(() =>
                    {
                        this.franchiseProfileOpenTimes = _.sortBy(openTimes, f => f.DayOfWeek);
                    });
            });
    }
    
    Cancel()
    {
        return this.LoadOpenTimes();
    }
    
    SaveHours()
    {
        let hoursSavePromises = [];

        for (let openTimes of this.franchiseProfileOpenTimes) 
        {
            let openTimeId = this.odataHelper.ConvertCompositeKeyToString({
                FranchiseId: openTimes.FranchiseId,
                DayOfWeek: openTimes.DayOfWeek
            });

            if (openTimes.CreatedDateTime) {
                hoursSavePromises.push(this.coreApiResources.FranchiseProfileOpenTimeApi.update({ id: openTimeId }, openTimes).$promise);
            }
            else {
                hoursSavePromises.push(this.coreApiResources.FranchiseProfileOpenTimeApi.save({ }, openTimes).$promise);
            }
        }

        return this.$q.all(hoursSavePromises);
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfileHoursOfOperation', {
            bindings: {
                franchiseId: "<",
            },
            controller: FranchiseProfileHoursOfOperationController,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfileHoursOfOperation.html"
        });
    }
}


