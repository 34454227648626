
import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { IEditDisasterPrepProfileSectionScope } from "@DppApp/EditDisasterPrepProfileSectionController";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";

interface IEditDisasterPrepProfileBuildingInformationTechnologyScope extends IEditDisasterPrepProfileSectionScope {
    dppBuildingAssetCategoryId: number;
    itInfo: DppResources.IDisasterPrepProfileBuildingInformationTechnology;
    SaveInformationTechnology: () => ng.IPromise<DppResources.IDisasterPrepProfileBuildingInformationTechnology>;
}

export class EditDisasterPrepProfileBuildingInformationTechnologyController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('EditDisasterPrepProfileBuildingInformationTechnologyController',
            [
                '$scope', "dppApiResources",
                ($scope: IEditDisasterPrepProfileBuildingInformationTechnologyScope, dppApiResources: DppApiResources) =>
                {
                    if (!$scope.dppBuildingId) {
                        $scope.RedirectHome();
                        return;
                    }

                    $scope.dppBuildingAssetCategoryId = 3;

                    let promise = dppApiResources.DisasterPrepProfileBuildingInformationTechnologyApi.get({ id: $scope.dppBuildingId },
                        (itInfo: DppResources.IDisasterPrepProfileBuildingInformationTechnology) =>
                        {
                            $scope.itInfo = itInfo;
                        },
                        (err: ng.IHttpPromiseCallbackArg<any>) =>
                        {
                            if (err.status === 404) {
                                $scope.itInfo = new dppApiResources.DisasterPrepProfileBuildingInformationTechnologyApi();
                            }
                            else {
                                alert("Could not get the IT info for this building.");
                            }
                        }).$promise;

                    $scope.QueueLoadingPromises(promise);

                    $scope.SaveInformationTechnology = () =>
                    {
                        if ($scope.itInfo.DppBuildingId) {
                            return $scope.itInfo.$update({ id: $scope.itInfo.DppBuildingId },
                                (response) =>
                                {
                                    console.log(response);
                                },
                                (err) =>
                                {
                                    console.error(err);
                                });
                        }
                        else {
                            $scope.itInfo.DppBuildingId = $scope.dppBuildingId;
                            return $scope.itInfo.$save((response) =>
                            {
                                console.log(response);
                            },
                                (err) =>
                                {
                                    console.error(err);
                                });
                        }
                    };
                }
            ]);
    }
}

