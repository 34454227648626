import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";


interface IEditServiceScope {
    service: CoreResources.IService;
    residentialSku: CoreEntities.ServiceSku;
    commercialSku: CoreEntities.ServiceSku;

    originalResidentialSkuString: string;
    originalCommercialSkuString: string;
    
    serviceToUpdate: CoreResources.IService;
    residentialSkuToUpdate: CoreResources.IServiceSku;
    commercialSkuToUpdate: CoreResources.IServiceSku;
    
    addResidentialSku: boolean;
    addCommercialSku: boolean;

    UpdateService: () => void;
}

export class EditServiceController
{
    static $inject = [
        '$scope',
        'coreApiResources',
        '$window',
        '$q',
    ];

    constructor(
        private $scope: IEditServiceScope,
        private coreApiResources: CoreApiResources,
        private $window: ng.IWindowService,
        private $q: ng.IQService
    )
    {
        var urlParts = $window.location.pathname.split("/");
        if (!urlParts[urlParts.length - 1]) {
            urlParts.pop();
        }

        $scope.service = <CoreResources.IService>coreApiResources.ServiceApi.get({ id: urlParts[urlParts.length - 1], $expand: "ServiceSkus" }, () =>
        {
            $scope.residentialSku = null;
            $scope.commercialSku = null;

            for (let sku of $scope.service.ServiceSkus) {
                if (sku.ServiceType === "Residential") {
                    $scope.residentialSku = sku;
                }

                if (sku.ServiceType === "Commercial") {
                    $scope.commercialSku = sku;
                }
            }

            if ($scope.residentialSku == null) {
                $scope.residentialSku = new coreApiResources.ServiceSkuApi();
                $scope.residentialSku.ServiceSkuId = 0;
                $scope.residentialSku.ServiceId = $scope.service.ServiceId;
                $scope.residentialSku.ServiceType = "Residential";
                $scope.residentialSku.SKU = "";
            }
            if ($scope.commercialSku == null) {
                $scope.commercialSku = new coreApiResources.ServiceSkuApi();
                $scope.commercialSku.ServiceSkuId = 0;
                $scope.commercialSku.ServiceId = $scope.service.ServiceId;
                $scope.commercialSku.ServiceType = "Commercial";
                $scope.commercialSku.SKU = "";
            }

            $scope.originalResidentialSkuString = $scope.residentialSku.SKU;
            $scope.originalCommercialSkuString = $scope.commercialSku.SKU;

        }, () =>
            {
                alert("There was an issue retrieving the service with id '" + urlParts[urlParts.length - 1] + "'.");
            });

        $scope.UpdateService = () =>
        {
            $scope.residentialSku.SKU = ($scope.residentialSku.SKU || "").trim();
            $scope.commercialSku.SKU = ($scope.commercialSku.SKU || "").trim();

            var promises: angular.IPromise<any>[] = [];

            $scope.serviceToUpdate = null;
            $scope.residentialSkuToUpdate = null;
            $scope.commercialSkuToUpdate = null;

            promises.push(coreApiResources.ServiceApi.get({ id: $scope.service.ServiceId }, (data: CoreResources.IService) =>
            {
                console.log("ServiceToUpdate", data);
                $scope.serviceToUpdate = data;
            }).$promise);

            if ($scope.residentialSku.ServiceSkuId) {
                promises.push(coreApiResources.ServiceSkuApi.get({ id: $scope.residentialSku.ServiceSkuId }, (data: CoreResources.IServiceSku) =>
                {
                    $scope.residentialSkuToUpdate = data;
                }).$promise);
            }

            if ($scope.commercialSku.ServiceSkuId) {
                promises.push(coreApiResources.ServiceSkuApi.get({ id: $scope.commercialSku.ServiceSkuId }, (data: CoreResources.IServiceSku) =>
                {
                    $scope.commercialSkuToUpdate = data;
                }).$promise);
            }

            console.log(promises);

            $q.all(promises).then(() =>
            {
                promises = [];

                $scope.serviceToUpdate.Name = $scope.service.Name;
                $scope.serviceToUpdate.Description = $scope.service.Description;
                promises.push($scope.serviceToUpdate.$update({ id: $scope.serviceToUpdate.ServiceId }));

                if ($scope.residentialSkuToUpdate) {
                    $scope.residentialSkuToUpdate.Name = $scope.residentialSku.Name;
                    $scope.residentialSkuToUpdate.Description = $scope.residentialSku.Description;
                    $scope.residentialSkuToUpdate.SKU = ($scope.residentialSku.SKU || "").trim();
                    promises.push($scope.residentialSkuToUpdate.$update({ id: $scope.residentialSkuToUpdate.ServiceSkuId }));
                }
                else if ($scope.addResidentialSku) {
                    $scope.residentialSku.Name = `${$scope.service.Name} (${$scope.residentialSku.ServiceType})`;
                    promises.push(coreApiResources.ServiceSkuApi.save($scope.residentialSku).$promise);
                }

                if ($scope.commercialSkuToUpdate) {
                    $scope.commercialSkuToUpdate.Name = $scope.commercialSku.Name;
                    $scope.commercialSkuToUpdate.Description = $scope.commercialSku.Description;
                    $scope.commercialSkuToUpdate.SKU = ($scope.commercialSku.SKU || "").trim();
                    promises.push($scope.commercialSkuToUpdate.$update({ id: $scope.commercialSkuToUpdate.ServiceSkuId }));
                }
                else if ($scope.addCommercialSku) {
                    $scope.commercialSku.Name = `${$scope.service.Name} (${$scope.commercialSku.ServiceType})`;
                    promises.push(coreApiResources.ServiceSkuApi.save($scope.commercialSku).$promise);
                }

                $q.all(promises).then(() =>
                {
                    window.location.reload();
                });
            });
        }
    }
}
