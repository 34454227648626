import { StringProperties } from "../../Types/StringProperties";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            snackbar: Partial<StringProperties<SnackbarComponentController>>;
        }
    }
}

export class SnackbarComponentController implements ng.IController {
    show: boolean;
    type: string;
    message: string;

    static $inject = [
        "$window",
    ]

    constructor(
        private $window: ng.IWindowService,
    ) {

    }

    $onInit() {

    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.show)]) {
            this.ShowRmsNotification(this.type, this.message);
        }
    }

    //type should be "error" or "success"
    ShowRmsNotification(type: string, message: string) {
        let elementId = "rms-notification-element";
        let notificationElement = this.$window.document.getElementById(elementId);
        if (!notificationElement) {
            notificationElement = this.$window.document.createElement("div");
            notificationElement.id = elementId;
            this.$window.document.body.appendChild(notificationElement);
        }

        let notification = angular.element(notificationElement).kendoNotification({
            autoHideAfter: 4000,
            position: {
                pinned: true,
                top: 60,
                right: 0,
            },
            animation: {
                open: {
                    effects: "slideIn:left"
                },
                close: {
                    effects: "slideIn:left",
                    reverse: true
                }
            },
            stacking: "down",
        }).data("kendoNotification");

        notification.show(message, type);
    }

    static BindComponent(app: ng.IModule) {
        app
            .component("snackbar", {
                bindings: {
                    [nameof<SnackbarComponentController>(o => o.show)]: "<",
                    [nameof<SnackbarComponentController>(o => o.type)]: "<",
                    [nameof<SnackbarComponentController>(o => o.message)]: "<",
                },
                template: ``,
                controller: SnackbarComponentController
            });
    }
}

