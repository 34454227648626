import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RmsFranchiseClient } from "Clients/Rms/RmsFranchiseClient";
import { KendoUtil } from "Utility/KendoUtil";
import { AngularUtil } from "Utility/AngularUtil";
import * as elements from 'typed-html';
import { StringProperties } from "Types/StringProperties";
import { CoreEntities, CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { ConceptSelectorOnSelectParameters } from "Directives/Common/ConceptSelector";
import { FranchiseEntityDropDownOnSelect } from "Directives/RPM/FranchiseEntity/FranchiseEntityDropDown";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            rmsFranchiseEntityRelationshipManagement: Partial<StringProperties<FranchiseEntityRelationshipManagement>>;
        }
    }
}

export class FranchiseEntityRelationshipManagement implements ng.IController 
{
    conceptId: number;
    franchiseEntityId: number;
    entityDropDownFilters: kendo.data.DataSourceFilter;

    isLoading: boolean;
    grid: kendo.ui.Grid;

    private franchiseClient: RmsFranchiseClient;

    static $inject = [
    ];

    constructor(
    )
    {
    }

    $onInit()
    {

    }

    $postLink()
    {
    }

    OnConceptSelect(concept: Partial<CoreResources.IConcept>)
    {
        console.log(concept);
        this.conceptId = concept && concept.ConceptId;
        if (this.conceptId)
        {
            this.entityDropDownFilters = {
                field: nameof<RpmEntities.FranchiseEntity>(o => o.ConceptId),
                operator: "eq",
                value: this.conceptId
            } as kendo.data.DataSourceFilterItem;
        }
        else
        {
            this.entityDropDownFilters = null;
        }
    }

    OnEntitySelect(selectedFranchiseEntity)
    {
        if (selectedFranchiseEntity)
            this.franchiseEntityId = selectedFranchiseEntity.franchiseEntityId;
    }

    static BindComponent(app: ng.IModule)
    {

        let conceptDropDown = (
            <conceptSelector
                filters='[]'
                onSelect={`$ctrl.${nameof<FranchiseEntityRelationshipManagement>(o => o.OnConceptSelect)}(${nameof<ConceptSelectorOnSelectParameters>(o => o.concept)})`}
                royalty-system-id-exists-check="true">

            </conceptSelector>
        );

        let entityDropDown = (
            <rmsFranchiseEntityDropDown
                filters={`$ctrl.${nameof<FranchiseEntityRelationshipManagement>(o => o.entityDropDownFilters)}`}
                onSelect={`$ctrl.${nameof<FranchiseEntityRelationshipManagement>(o => o.OnEntitySelect)}(${nameof<FranchiseEntityDropDownOnSelect>(o => o.entity)})`}
                >

            </rmsFranchiseEntityDropDown>
        );

        let franchiseGridTemplate = (
            <rmsFranchisesInEntityGrid
                franchiseEntityId={`$ctrl.${nameof<FranchiseEntityRelationshipManagement>(o => o.franchiseEntityId)}`}
                >
            </rmsFranchisesInEntityGrid>
        );

        let relationshipsGridTemplate = (
            <rmsFranchiseEntityRelationshipsGrid
                franchiseEntityId={`$ctrl.${nameof<FranchiseEntityRelationshipManagement>(o => o.franchiseEntityId)}`}
                >
            </rmsFranchiseEntityRelationshipsGrid>
        );

        //let buttonRowTemplate = (
        //    <div ng-show={`$ctrl.${nameof<FranchiseEntityRelationshipManagement>(o => o.franchiseEntityId)}`}>
        //        <button>Cancel</button>
        //        <button>Save</button>
        //    </div>
        //);

        //${ buttonRowTemplate }

        let template = `${conceptDropDown} ${entityDropDown} ${franchiseGridTemplate} ${relationshipsGridTemplate}`;

        let componentName = nameof<JSX.IntrinsicElements>(o => o.rmsFranchiseEntityRelationshipManagement);

        app.component(componentName, {
            bindings: {
                
            },
            controller: FranchiseEntityRelationshipManagement,
            template: template
        });
    }
}

