import { AngularUtil } from "Utility/AngularUtil";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ZeeMailApiResources } from "Services/Resources/ZeeMailApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { FranchiseEmailAccountRequestClient, FranchiseOrchestrationClientResponse, FranchiseEmailAccountRequestVm } from "@nbly/franchise-orchestration-clients"
import { AxiosInstance } from "axios";
import { FranForceAxiosClientBuilder } from "../../Clients/FranForceAxiosClientBuilder";
import { result } from "underscore";
import { ZeeMailEntities } from "Interfaces/FranForce/ZeeMail/ZeeMailResources";



export class ZeeMailCreateAccountRequestComponentController implements ng.IController {
    franchiseId: number;
    emailDba: string;
    onSave?: (params: {}) => void;
    onCancel?: (params: {}) => void;
    registerChild?: (ref: any) => void;

    isLoading: boolean;
    title: string;
    errorMessage: string[] = [];

    account: IZorWareZeeMailCreateAccountRequest = {
        OwnerName: '',
        YourName: '',
        LicenseType: '',
        RequestedAccountName: '',
        AliasName: '',
        Comments: '',
        EmailDBA: ''
    };

    franchiseOrchestrationClient: AxiosInstance;
    franchiseEmailAccountRequestClient: FranchiseEmailAccountRequestClient
    domain: ZeeMailEntities.ZorWareZeeMailDomain;
    franchise: ZeeMailEntities.ZeeMailFranchiseExtension;
    domainName: string;

    static $inject = [
        'coreApiResources',
        'zeeMailApiResources',
        'identityManager',
        'apiConfig',
        '$log',
        '$q',
        '$scope',
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private zeeMailApiResources: ZeeMailApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $log: ng.ILogService,
        private $q: ng.IQService,
        private $scope: ng.IScope
    ) {
        this.franchiseOrchestrationClient = FranForceAxiosClientBuilder.BuildFranchiseOrchestrationClient();
        this.franchiseEmailAccountRequestClient = new FranchiseEmailAccountRequestClient("", this.franchiseOrchestrationClient);
    }

    $onInit() {
        let errors: string[] = [];
        this.title = "Franshisee Email Account Request";
        this.franchise = null;
        this.domain = null;
        this.account = this.account || {
            OwnerName: '',
            YourName: '',
            LicenseType: '',
            RequestedAccountName: '',
            AliasName: '',            
            Comments: '',
            EmailDBA: ''
        };
        if (this.registerChild) {
            console.log("🔹 Child Component is registering:", this);
            this.registerChild({ ref: this }); // ✅ Pass reference to parent
        }

        //Load domain name based on franchise id
        this.zeeMailApiResources.ZeeMailFranchiseQuery({
            $filter: `${nameof<ZeeMailEntities.ZeeMailFranchiseExtension>(o => o.FranchiseId)} eq ${this.franchiseId}`
        })
            .then((franchisesResponse) => {
                if (franchisesResponse.data.length) {
                    this.franchise = franchisesResponse.data[0];
                    let domainsPromise = this.zeeMailApiResources.ZorWareZeeMailDomainApi.query({
                        $filter: `${nameof<ZeeMailEntities.ZorWareZeeMailDomain>(o => o.FranchisorId)} eq ${this.franchise.FranchisorId}`
                    }).$promise
                        .then((domains) => {
                            this.domain = domains[0];
                            this.domainName = this.domain.DomainName;
                        })
                }
            })
            .finally(() => {
                this.isLoading = false;
                if (!this.franchise) {
                    errors.push("Franchise could not be found.");
                }
                if (!this.domain) {
                    errors.push("A domain needs to be set up for this franchisor.");
                }
            });

        this.InitPermissions();
    }

    $postLink() {
    }


    InitPermissions() {

    }


    ResetForm() {
        this.account = <any>{};
        this.errorMessage = [];
    }
    ValidateForm(): boolean {
        let errors: string[] = [];
        const emailDbaRegex = /^[a-zA-Z0-9]+$/;
        const emailRegex = /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*$/;
        const nameRegex = /^[a-zA-Z ]+$/;
        const domainRegex = /^(?!:\/\/)([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/

        if (!this.account.OwnerName || this.account.OwnerName.trim() === '')
            errors.push("Owner name is required.");
        else {
            if (this.account.OwnerName.length > 250)
                errors.push("Owner name cannot exceed 250 characters.");

            if (!nameRegex.test(this.account.OwnerName))
                errors.push("Invalid Owner name.");
        }
        if (!this.account.YourName || this.account.YourName.trim() === '')
            errors.push("Your name is required.");
        else {
            if (this.account.YourName.length > 250)
                errors.push("Your name cannot exceed 250 characters.");

            if (!nameRegex.test(this.account.YourName))
                errors.push("Invalid Your name.");
        }
        if (!this.account.EmailDBA || this.account.EmailDBA.trim() === '')
            errors.push("Email DBA is required.");
        else {
            if (this.account.EmailDBA.length > 200)
                errors.push("Email DBA cannot exceed 200 characters.");

            if (!emailDbaRegex.test(this.account.EmailDBA))
                errors.push("Email DBA can only contain letters and numbers.");
        }
        if (!this.account.LicenseType || this.account.LicenseType.trim() === '')
            errors.push("License type is required.");

        if (!this.account.RequestedAccountName || this.account.RequestedAccountName.trim() === '')
            errors.push("Requested account name is required.");
        else {
            if (this.account.RequestedAccountName.length > 200)
                errors.push("Requested account name cannot exceed 200 characters.");

            if (!emailRegex.test(this.account.RequestedAccountName))
                errors.push("Invalid Requested account name.");
        }        
        
        if (this.account.AliasName && this.account.AliasName.length > 200)
            errors.push("Alias name cannot exceed 200 characters");
       

        if (this.account.AliasName && !emailRegex.test(this.account.AliasName))
            errors.push("Invalid Alias name.");



        if (!this.account.Comments || this.account.Comments.trim() === '')
            errors.push("Comments is required.");
        else {
            if (this.account.Comments.length > 500)
                errors.push("Comments cannot exceed 500 characters.");
        }

        if (errors.length > 0) {
            this.errorMessage = errors; // Combine errors into a single message
            return false;
        }

        this.errorMessage = []; // Clear error message if validation passes
        return true;
    }

    showSnackbar: boolean;
    snackbarType: string;
    snackbarMessage: string;
    DisplaySnackbar(type: string, message: string) {
        this.showSnackbar = !this.showSnackbar;
        this.snackbarType = type;
        this.snackbarMessage = message;
        this.$scope.$evalAsync();
    }

    mapToFranchiseEmailAccountRequestRm(account) {
        return {
            ownerName: account.OwnerName,
            yourName: account.YourName,
            licenseType: account.LicenseType,
            requestedAccountName: account.RequestedAccountName,
            aliasName: account.AliasName,
            domain: this.domainName,
            aliasDomain: (account.AliasName) ? this.domainName : "",
            comments: account.Comments,
            emailDBA: account.EmailDBA,
        };
    }

    async Save() {
        if (!this.ValidateForm()) {
            return;
        }
        try {

            var mappedAccount = this.mapToFranchiseEmailAccountRequestRm(this.account);

            const franchiseEmailAccountRequestResult: FranchiseOrchestrationClientResponse<FranchiseEmailAccountRequestVm[]> =
                await this.franchiseEmailAccountRequestClient.emailAccountRequest(mappedAccount);

            const { result: franchiseEmailAccountRequestVMResult } = franchiseEmailAccountRequestResult;
            if (franchiseEmailAccountRequestResult != null && franchiseEmailAccountRequestResult.status == 200) {
                alert("Email account request completed.");
                this.ResetForm();
                if (this.onSave) {
                    this.onSave({});
                }

            }
        } catch (error) {
            alert("Error while create email account request.");
            console.error("Error while create email account request:", error);
        } finally {
            this.isLoading = false;
            console.log("Email account request completed.");
        }


    }

    Cancel() {
        this.errorMessage = [];
        this.ResetForm();
        if (this.onCancel) {
            this.onCancel({});
        }
    }


    static BindComponent(app: ng.IModule) {
        app.component("zeeMailCreateAccountRequest", {
            bindings: {
                franchiseId: "<",
                onSave: "&",
                onCancel: "&",
                registerChild: "&" // ✅ Accept function to register in parent
            },
            controller: ZeeMailCreateAccountRequestComponentController,
            templateUrl: "/Templates/ZeeMail/ZeeMailCreateAccountRequest.html"
        });
    }
}


