import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyPeriodFrequenciesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetAllRoyaltyPeriodFrequencies()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriodFrequencies"
        };

        return this.$http<RpmEntities.RoyaltyPeriodFrequencyViewModel[]>(config);
    }
}
