import { KbsBaseComponentController } from "./KbsBaseComponent";
import { KnowledgeApiResources } from "Services/Resources/KnowledgeApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { CallCenterResources } from "Interfaces/FranForce/CallCenter/CallCenterResources";

export class ItemsComponentController extends KbsBaseComponentController<KnowledgeResources.IItem> {
    isApprover: any;
    currentUser: string;

    gridPageSize = 25;

    newMediaFile: any;
    rawNewMediaFile: any;
    isUploading: boolean = false;
    itemMedia: KnowledgeResources.IItemXMedium;

    sections: Array<any>;
    itemTypes: Array<KnowledgeResources.IItemType>;
    mediaTypes: Array<KnowledgeResources.IMediaType>;
    concepts: Array<CoreResources.IConcept>;
    callCenters: Array<CallCenterResources.IC4CallCenter>;

    selectedCallCenters: Array<number> = [];
    callCenterSelectOptions: kendo.ui.MultiSelectOptions;

    selectedConcepts: Array<number> = [];
    conceptSelectOptions: kendo.ui.MultiSelectOptions;

    selectedSections: Array<number> = [];
    sectionSelectOptions: kendo.ui.MultiSelectOptions;

    selectedItemTypes: Array<number> = [];
    itemTypeSelectOptions: kendo.ui.MultiSelectOptions;

    mediaUpload: kendo.ui.Upload;
    mediaTypeSelect: kendo.ui.DropDownList;
    mediaTypeSelectOptions: kendo.ui.DropDownListOptions;

    selectedKeywords: string;

    attachmentWindow: kendo.ui.Window;
    attachmentWindowOptions: kendo.ui.WindowOptions;

    static $inject = [
        "coreApiResources",
        "callCenterApiResources",
        "knowledgeApiResources",
        "$q",
        "$log",
        "$timeout",
        "$interval"
    ];

    constructor(
        protected coreApiResources: CoreApiResources,
        protected callCenterApiResources: CallCenterApiResources,
        protected knowledgeApiResources: KnowledgeApiResources,
        protected $q: ng.IQService,
        protected $log: ng.ILogService,
        protected $timeout: ng.ITimeoutService,
        protected $interval: ng.ITimeoutService
    ) {
        super(knowledgeApiResources, $q, $log, $timeout);
    }

    $onInit() {
        let promises: Array<ng.IPromise<any>> = [];

        promises.push(
            this.knowledgeApiResources.SectionsApi.query({})
                .$promise
                .then((sections) => {
                    this.sections = sections;
                    this.sections.forEach(a => {
                        a.SectionLabel = `${a.Description} (${a.Category.Description})`;
                    });
                })
        );

        promises.push(
            this.knowledgeApiResources.ItemTypesApi.query({})
                .$promise
                .then((itemTypes) => {
                    this.itemTypes = itemTypes;
                })
        );

        promises.push(
            this.callCenterApiResources.C4CallCentersApi.query({})
                .$promise
                .then((callCenters) => {
                    this.callCenters = callCenters;
                })
        );

        promises.push(
            this.coreApiResources.ConceptApi.query({})
                .$promise
                .then((concepts) => {
                    this.concepts = concepts; 
                })
        );

        this.$q.all(promises).then(() => {
            super.$onInit();

            this.conceptSelectOptions = {
                placeholder: "Select Concepts...",
                dataTextField: "DisplayName",
                dataValueField: "ConceptId",
                valuePrimitive: true,
                autoBind: true,
                dataSource: this.concepts,
            };

            this.callCenterSelectOptions = {
                placeholder: "Select Call Centers...",
                dataTextField: "Name",
                dataValueField: "C4CallCenterId",
                valuePrimitive: true,
                autoBind: true,
                dataSource: this.callCenters,
            };

            this.itemTypeSelectOptions = {
                placeholder: "Select Item Types...",
                dataTextField: "Description",
                dataValueField: "ItemTypeId",
                valuePrimitive: true,
                autoBind: true,
                dataSource: this.itemTypes,
            };

            this.sectionSelectOptions = {
                placeholder: "Select Sections...",
                dataTextField: "SectionLabel",
                dataValueField: "SectionId",
                valuePrimitive: true,
                autoBind: true,
                dataSource: this.sections,
            };

            this.mediaTypeSelectOptions = {
                dataTextField: "Description",
                dataValueField: "MediaTypeId",
                valuePrimitive: true,
                autoBind: true,
                dataSource: this.mediaTypes,
            };

            this.$interval(() => this.CheckForUpload(), 1500);
        });
    }

    GetGridColumns(): kendo.ui.GridColumn[] {
        return [
            {
                field: "Title",
                title: "Title",
            },
            {
                title: "Call Centers",
                template: "{{ $ctrl.GetCallCentersName(dataItem) }}",
            },
            {
                title: "Concepts",
                template: "{{ $ctrl.GetConceptsName(dataItem) }}",
            },
            {
                title: "Sections",
                template: "{{ $ctrl.GetSectionsName(dataItem) }}",
            },
            {
                title: "Item Types",
                template: "{{ $ctrl.GetItemTypesName(dataItem) }}"
            },
            {
                field: "ModifiedBy",
                title: "Last Updated By",
            },
            {
                field: "ApprovedBy",
                title: "Approved By",
            },
            {
                template: "<button class='pure-button button-secondary' ng-disabled='$ctrl.isApprover === \"False\" ? ($ctrl.currentUser !== dataItem.CreatedBy || dataItem.Approved) : false' ng-click='$ctrl.OpenEditEntityWindow(dataItem)'>Edit</button>",
                width: 100,
                title: "&nbsp;"
            },
            {
                template: "<button class='pure-button button-secondary' ng-click='$ctrl.OpenAttachmentWindow(dataItem)'>Attachments</button>",
                width: 140,
                title: "&nbsp;"
            },
            {
                template:
                    "<button class='pure-button button-success' ng-disabled='$ctrl.isApprover !== \"True\"' ng-hide='dataItem.ApprovedBy' ng-click='$ctrl.ApproveItem(dataItem)'>Approve</button>" + 
                    "<button class='pure-button button-error' ng-disabled='$ctrl.isApprover !== \"True\"' ng-hide='!dataItem.ApprovedBy' ng-click='$ctrl.DisapproveItem(dataItem)'>Disapprove</button>",
                width: 130,
                title: "&nbsp;"
            },
            {
                template: "<button class='pure-button button-error' ng-disabled='$ctrl.isApprover === \"False\" ? ($ctrl.currentUser !== dataItem.CreatedBy || dataItem.Approved) : false' ng-click='$ctrl.DeleteEntity(dataItem)'>X</button>",
                width: 70,
                title: "&nbsp;"
            }
        ];
    }

    GetDataSourceSchemaModel() {
        return { id: "ItemId" };
    }

    TransportReadOperationHandler(options: kendo.data.DataSourceTransportReadOptions) {
        this.knowledgeApiResources.ItemsApi.query({})
            .$promise.then(
                (items) => {
                    options.success(items);
                },
                (err) => {
                    options.error(err);
                });
    }

    CreateNewEntity(): KnowledgeResources.IItem {
        let item = new this.knowledgeApiResources.ItemsApi();
        item.ItemId = 0;
        item.Title = "";
        item.Body = "";
        item.AllCallCenters = false;
        item.AllConcepts = false;
        item.AllSections = false;
        item.AllTypes = false;
        item.Approved = false;
        item.ApprovedBy = "";
        item.ModifiedBy = "";
        item.CreatedBy = "";

        return item;
    }

    SetWindowOption() {
        this.attachmentWindowOptions = { modal: true };
        super.SetWindowOption();
    }

    OpenNewEntityWindow() {
        this.selectedCallCenters = [];
        this.selectedConcepts = [];
        this.selectedItemTypes = [];
        this.selectedSections = [];
        this.selectedKeywords = '';
        super.OpenNewEntityWindow();
    }

    OpenEditEntityWindow(item: KnowledgeResources.IItem) {
        this.selectedCallCenters = item.ItemCallCenters.map(a => a.C4callCenterId);
        this.selectedConcepts = item.ItemConcepts.map(a => a.ConceptId);
        this.selectedItemTypes = item.ItemItemTypes.map(a => a.ItemTypeId);
        this.selectedSections = item.ItemSections.map(a => a.SectionId);
        this.selectedKeywords = item.ItemKeywords.map(a => a.Keyword).join(" ");
        super.OpenEditEntityWindow(item);
    }

    OpenAttachmentWindow(item: KnowledgeResources.IItem) {
        this.ResetValidationErrors();
        this.itemMedia = new this.knowledgeApiResources.ItemXMediaApi();
        this.itemMedia.ItemId = item.ItemId;
        this.entity = item;
        this.$timeout(() => {
            this.attachmentWindow.center();
        }, 0);
        this.attachmentWindow.open();
    }

    GetSavePromise(item: KnowledgeResources.IItem): ng.IPromise<KnowledgeResources.IItem> {
        let itemPromise: ng.IPromise<KnowledgeResources.IItem>;
        this.PopulateNavigationProperties(item);

        if (item.ItemId) {
            // Update
            itemPromise = this.knowledgeApiResources.ItemsApi.update({ id: item.ItemId }, item).$promise;
        }
        else {
            // Create
            itemPromise = item.$save();
        }

        return itemPromise;
    }

    DeleteEntity(item: KnowledgeResources.IItem) {
        if (confirm(`Are you sure you want to delete '${item.Title}'?`)) {
            return this.knowledgeApiResources.ItemsApi.delete({ id: item.ItemId })
                .$promise.then(() => {
                    return this.RefreshGrid();
                });
        }
    }

    ApproveItem(item: KnowledgeResources.IItem) {
        this.knowledgeApiResources.ApproveItem(item.ItemId)
            .then(() => this.RefreshGrid());
    }

    DisapproveItem(item: KnowledgeResources.IItem) {
        this.knowledgeApiResources.DisapproveItem(item.ItemId)
            .then(() => this.RefreshGrid());
    }

    PopulateNavigationProperties(item: KnowledgeResources.IItem) {
        item.ItemKeywords = [];

        item.ItemCallCenters = [];
        if (!item.AllCallCenters) {
            this.selectedCallCenters.forEach(a =>
                item.ItemCallCenters.push(
                    {
                        C4callCenterId: a,
                        ItemId: item.ItemId,
                        ItemXcallCenterId: 0
                    })
            );
        }

        item.ItemConcepts = [];
        if (!item.AllConcepts) {
            this.selectedConcepts.forEach(a =>
                item.ItemConcepts.push({
                    ConceptId: a,
                    ItemId: item.ItemId,
                    ItemXconceptId: 0
                })
            );
        }

        item.ItemItemTypes = [];
        if (!item.AllTypes) {
            this.selectedItemTypes.forEach(a =>
                item.ItemItemTypes.push({
                    ItemId: item.ItemId,
                    ItemTypeId: a,
                    ItemXitemTypeId: 0
                })
            );
        }

        item.ItemSections = [];
        if (!item.AllSections) {
            this.selectedSections.forEach(a =>
                item.ItemSections.push({
                    ItemId: item.ItemId,
                    ItemXsectionId: 0,
                    SectionId: a
                })
            );
        }

        if (this.selectedKeywords) {
            let keywords = this.selectedKeywords.split(" ");
            if (keywords) {
                keywords.forEach(a => {
                    item.ItemKeywords.push({
                        ItemId: item.ItemId,
                        ItemXkeywordId: 0,
                        Keyword: a
                    })
                });
            }
        }
    }

    GetCallCentersName(item: KnowledgeResources.IItem) : string {
        if (item.AllCallCenters) {
            return "All";
        }
        else {
            return item.ItemCallCenters
                .map(icc => {
                    const callCenter = this.callCenters.find(a => a.C4CallCenterId === icc.C4callCenterId);
                    return callCenter ? callCenter.Name : "";
                })
                .filter(a => a !== "")
                .sort()
                .join(", ");
        }
    }

    GetConceptsName(item: KnowledgeResources.IItem): string {
        if (item.AllConcepts) {
            return "All";
        }
        else {
            return item.ItemConcepts
                .map(ic => {
                    const concept = this.concepts.find(a => a.ConceptId === ic.ConceptId);
                    return concept ? concept.DisplayName : "";
                })
                .filter(a => a !== "")
                .sort()
                .join(", ");
        }
    }

    GetSectionsName(item: KnowledgeResources.IItem): string {
        if (item.AllSections) {
            return "All";
        }
        else {
            return item.ItemSections
                .map(is => {
                    const section = this.sections.find(a => a.SectionId === is.SectionId);
                    return section ? `${section.Description} (${section.Category.Description})` : "";
                })
                .filter(a => a !== "")
                .sort()
                .join(", ");
        }
    }

    GetItemTypesName(item: KnowledgeResources.IItem): string {
        if (item.AllTypes) {
            return "All";
        }
        else {
            return item.ItemItemTypes
                .map(it => {
                    const itemType = this.itemTypes.find(a => a.ItemTypeId === it.ItemTypeId);
                    return itemType ? itemType.Description : "";
                })
                .filter(a => a !== "")
                .sort()
                .join(", ");
        }
    }

    UploadFile() {
        this.ResetValidationErrors();
        if (this.newMediaFile && this.rawNewMediaFile.name) {
            this.isUploading = true;
            this.knowledgeApiResources.UploadMediaItem(this.itemMedia, this.rawNewMediaFile.name, this.newMediaFile)
                .then(result => {
                    this.RefreshGrid();
                    this.mediaUpload.clearAllFiles();
                    this.newMediaFile = null;
                    this.rawNewMediaFile = null;
                    this.entity.ItemMedium.push(result.data);
                    this.isUploading = false;
                })
                .catch(err => {
                    if (err.data) {
                        err.data.forEach(a => {
                            this.validationErrors.push(a.ErrorMessage);
                        });
                    }
                    this.isUploading = false;
                    throw err;
                });
        }
    }

    DeleteFile(itemMediaId: number, filename: string) {
        if (confirm(`Are you sure you want to delete '${filename}'?`)) {
            return this.knowledgeApiResources.ItemXMediaApi.delete({ id: itemMediaId })
                .$promise.then(() => {
                    this.RefreshGrid();
                    this.knowledgeApiResources.ItemsApi.get({ id: this.entity.ItemId })
                        .$promise.then((a) => {
                            this.OpenAttachmentWindow(a);
                        });
                });
        }
    }

    CheckForUpload() {
        if (this.mediaUpload.getFiles()[0]) {
            if (!this.rawNewMediaFile || this.rawNewMediaFile !== this.mediaUpload.getFiles()[0].rawFile) {
                this.rawNewMediaFile = this.mediaUpload.getFiles()[0].rawFile;

                let imageReader = new FileReader();
                imageReader.onload = ev => this.newMediaFile = imageReader.result;
                imageReader.readAsDataURL(this.rawNewMediaFile);
            }
        }
        else {
            this.newMediaFile = null;
            this.rawNewMediaFile = null;
        }
    }

    static BindComponent(app: ng.IModule) {
        app.component("kbsItems", {
            bindings: {
                isApprover: '@',
                currentUser: '@',
            },
            controller: ItemsComponentController,
            templateUrl: "/Templates/KBS/Items.html",
        });
    }
}