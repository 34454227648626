export module Constants
{

  // Regex by https://get.foundation/sites/docs/abide.html#js-members
  export const Validation = {
    MultipleEmailPattern: "^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
  }

  export const DSAREmailProcessTypeID = 3;

  export const PopupEvents = {
    OpenPasswordPopupEvent: 'OpenPasswordPopupEvent',
    OpenPasswordErrorValidationPopupEvent: 'OpenPasswordErrorValidationPopupEvent'
  }




  export let googleMapsLightModeStyles = [{
    stylers: [{
      hue: "#68c0ff"
    }, {
      saturation: 20
    }]
  }, {
    featureType: "road",
    stylers: [{
      weight: 0.5
    }]
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        "color": "#73b0ff"
      },
      {
        "visibility": "on"
      },
      {
        "weight": 7.5
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      { hue: '#edf1f5' },
      { saturation: 2 },
      { lightness: 50 },
      { visibility: 'on' }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      { hue: '#ffffff' },
      { saturation: -100 },
      { lightness: 100 },
      { visibility: 'on' }
    ]
  }, {
    featureType: 'poi.school',
    elementType: 'all',
    stylers: [
      { hue: '#eef3f8' },
      { saturation: -13 },
      { lightness: 72 },
      { visibility: 'off' }
    ]
  }, {
    featureType: 'poi.medical',
    elementType: 'all',
    stylers: [
      { hue: '#eef3f8' },
      { saturation: 1 },
      { lightness: 64 },
      { visibility: 'off' }
    ]
  }, {
    featureType: 'poi.business',
    elementType: 'all',
    stylers: [
      { hue: '#eef3f8' },
      { saturation: 31 },
      { lightness: 69 },
      { visibility: 'off' }
    ]
  }, {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      { hue: '#d5d8db' },
      { saturation: -82 },
      { lightness: 30 },
      { visibility: 'on' }
    ]
  }, {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      { hue: '#eef3f8' },
      { saturation: -3 },
      { lightness: 79 },
      { visibility: 'on' }
    ]
  }, {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      { hue: '#e5e5e5' },
      { saturation: -100 },
      { lightness: 56 },
      { visibility: 'on' }
    ]
  }, {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      { hue: '#f0f4f4' },
      { saturation: 15 },
      { lightness: 80 },
      { visibility: 'on' }
    ]
  }, {
    featureType: 'poi.park',
    elementType: 'labels',
    stylers: [
      { hue: '#000000' },
      { saturation: -100 },
      { lightness: -100 },
      { visibility: 'off' }
    ]
  }, {
    featureType: 'poi.attraction',
    elementType: 'labels',
    stylers: [
      { hue: '#000000' },
      { saturation: -100 },
      { lightness: -100 },
      { visibility: 'off' }
    ]
  }, {
    featureType: 'administrative.neighborhood',
    elementType: 'labels',
    stylers: [
      { hue: '#000000' },
      { saturation: 0 },
      { lightness: -100 },
      { visibility: 'off' }
    ]
  }

  ]

  export let googleMapsDarkModeStyles = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#202124" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#202124" }],
    },
  ]

  export let FANMapOptions: google.maps.MapOptions = {
    center: {
      lat: 43.3450051,
      lng: -107.0963073
    },
    zoom: 4,
    panControl: false,
    rotateControl: false,
    streetViewControl: false,
    zoomControl: true,
    fullscreenControl: true,
    scaleControl: true,
    minZoom: 3,
    /* maxZoom: 5, */
    mapTypeControl: false,
    disableDoubleClickZoom: true,
    disableDefaultUI: true,
    draggable: true,
    mapTypeId: 'roadmap',
    scrollwheel: true,
    styles: [{
      stylers: [{
        hue: "#68c0ff"
      }, {
        saturation: 20
      }]
    }, {
      featureType: "road",
      stylers: [{
        weight: 0.5
      }]
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          "color": "#73b0ff"
        },
        {
          "visibility": "on"
        },
        {
          "weight": 7.5
        }
      ]
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        { hue: '#edf1f5' },
        { saturation: 2 },
        { lightness: 50 },
        { visibility: 'on' }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        { hue: '#ffffff' },
        { saturation: -100 },
        { lightness: 100 },
        { visibility: 'on' }
      ]
    }, {
      featureType: 'poi.school',
      elementType: 'all',
      stylers: [
        { hue: '#eef3f8' },
        { saturation: -13 },
        { lightness: 72 },
        { visibility: 'off' }
      ]
    }, {
      featureType: 'poi.medical',
      elementType: 'all',
      stylers: [
        { hue: '#eef3f8' },
        { saturation: 1 },
        { lightness: 64 },
        { visibility: 'off' }
      ]
    }, {
      featureType: 'poi.business',
      elementType: 'all',
      stylers: [
        { hue: '#eef3f8' },
        { saturation: 31 },
        { lightness: 69 },
        { visibility: 'off' }
      ]
    }, {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        { hue: '#d5d8db' },
        { saturation: -82 },
        { lightness: 30 },
        { visibility: 'on' }
      ]
    }, {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        { hue: '#eef3f8' },
        { saturation: -3 },
        { lightness: 79 },
        { visibility: 'on' }
      ]
    }, {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        { hue: '#e5e5e5' },
        { saturation: -100 },
        { lightness: 56 },
        { visibility: 'on' }
      ]
    }, {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        { hue: '#f0f4f4' },
        { saturation: 15 },
        { lightness: 80 },
        { visibility: 'on' }
      ]
    }, {
      featureType: 'poi.park',
      elementType: 'labels',
      stylers: [
        { hue: '#000000' },
        { saturation: -100 },
        { lightness: -100 },
        { visibility: 'off' }
      ]
    }, {
      featureType: 'poi.attraction',
      elementType: 'labels',
      stylers: [
        { hue: '#000000' },
        { saturation: -100 },
        { lightness: -100 },
        { visibility: 'off' }
      ]
    }, {
      featureType: 'administrative.neighborhood',
      elementType: 'labels',
      stylers: [
        { hue: '#000000' },
        { saturation: 0 },
        { lightness: -100 },
        { visibility: 'off' }
      ]
    }

    ],
  };

  
export const FranchiseTerritoryTypesConstants  = {
  1 : "Owned",
  2 : "Service Territory (TAFS)",
  3 : "Purchase Option" 
};

}




