import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ZeeMailApiResources } from "Services/Resources/ZeeMailApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { ZeeMailResources, ZeeMailEntities } from "Interfaces/FranForce/ZeeMail/ZeeMailResources";

export class ZeeMailTemplatesComponentController implements ng.IController
{
    template: ZeeMailEntities.ZorWareZeeMailTemplate;
    isPlainText: { [name: string]: boolean }; 

    static $inject = [
        'coreApiResources',
        'zeeMailApiResources',
        'identityManager',
        'apiConfig'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private zeeMailApiResources: ZeeMailApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig
    )
    {
    }

    $onInit()
    {
        this.isPlainText = {};
        this.isPlainText.CreateEmail = false;
    }

    $postLink()
    {
    }

    $onChanges(changes: ng.IOnChangesObject)
    {

    }

    OnSelectConcept(conceptId: number)
    {
        this.template = null;

        let params: ODataQueryParameters = {
            $filter: `${nameof<ZeeMailResources.IZorWareZeeMailTemplate>(o => o.ConceptId)} eq ${conceptId}`
        };

        return this.zeeMailApiResources.ZorWareZeeMailTemplateApi.query(params).$promise
            .then((templates) =>
            {
                if (templates.length)
                {
                    this.template = templates[0];
                }
                else
                {
                    this.template = {
                        ZorWareZeeMailTemplateID: 0,
                        ConceptId: conceptId,
                        ActivateEmail: "",
                        ChangeReplyEmail: "",
                        CreateEmail: "",
                        DeactivateEmail: "",
                        EditEmail: "",
                        ReactivateEmail: ""
                    }
                }
            })
    }

    Save()
    {
        if (this.template.ZorWareZeeMailTemplateID) {
            return this.zeeMailApiResources.ZorWareZeeMailTemplateApi.update({ id: this.template.ZorWareZeeMailTemplateID }, this.template).$promise
        }
        else {
            return this.zeeMailApiResources.ZorWareZeeMailTemplateApi.save({}, this.template).$promise
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("zeeMailTemplates", {
            bindings: {
            },
            controller: ZeeMailTemplatesComponentController,
            templateUrl: "/Templates/ZeeMail/ZeeMailTemplates.html"
        });
    }
}


