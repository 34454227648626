export class RpmDataSyncComponentController implements ng.IController  {

    selectedTab: string;

    constructor(

    ) {
        this.selectedTab = 'appFolio';
    }

    $onInit() {
        this.selectedTab = 'appFolio';
    }

    static BindComponent(app: ng.IModule) {
        app.component("servicemanagerRpmDataSync", {
            bindings: {
            },
            controller: RpmDataSyncComponentController,
            templateUrl: "/Templates/ServiceManager/RpmDataSync.html",
        });
    }
}