let crAdFeeCapControllerConfig = ['$scope', 'ngDialog', function ($scope, ngDialog)
{

    // AFC Modal Grid Config
    $scope.afcModalGridDataSource = new kendo.data.DataSource({
        batch: true,
        pageSize: 20,
        schema: {
            model: {
                id: "ID",
                fields: {
                    ID: { editable: false, nullable: true },
                    Apply: { type: "boolean" },
                    FranchiseAccountCode: { type: "string" }
                }
            }
        },
        data: [{
            FranchiseAccountCode: "MRA1234",
            Apply: true,
            ID: 1
        }, {
            FranchiseAccountCode: "MRA1235",
            Apply: false,
            ID: 2
        }, {
            FranchiseAccountCode: "MRA1236",
            Apply: true,
            ID: 3
        }]
    });

    let modalCols: Array<kendo.ui.GridColumn> =
        [{
            field: "Apply",
            width: "100px",
            template: "<input ng-model='dataItem.Apply' type='checkbox' ng-change='getClick(dataItem)'></input>"
        }, {
            field: "FranchiseAccountCode",
            title: "Franchise Account Code"
        }]

    $scope.afcModalGridOptions = {
        columns: modalCols,
        dataSource: $scope.afcModalGridDataSource,
        sortable: true,
        pageable: true
    }

    // AFC Grid Config
    $scope.onSelect = function (e)
    {
        var grid = e.sender;
        var data = grid.dataItem(grid.select()[0]);

        var modalJson = JSON.stringify({
            adFeeCap: data.adFeeCap,
            year: data.year
        });

        ngDialog.open({
            template: 'adFeeCapModal',
            className: 'ngdialog-theme-default rpm-section',
            scope: $scope,
            data: modalJson,
            controller: ['$scope', ($scope) => { }],
            showClose: false
        });
    }

    $scope.afcGridDataSource = new kendo.data.DataSource({
        batch: true,
        pageSize: 20,
        schema: {
            model: {
                id: "afcID",
                fields: {
                    afcID: { editable: false, nullable: true },
                    Year: { type: "number", validation: { required: true } },
                    AdFeeCap: { type: "number", validation: { required: true } }
                }
            }
        },
        data: [{
            year: "2016",
            adFeeCap: "25000",
            id: 1
        },
        {
            year: "2016",
            adFeeCap: "24000",
            id: 2
        },
        {
            year: "2016",
            adFeeCap: "23000",
            id: 3
        }]
    });

    let afcColumns: Array<kendo.ui.GridColumn> =
        [{
            field: "year",
            title: "Year"
        }, {
            field: "adFeeCap",
            title: "Ad Fee Cap"
        },
        {
            command: [{ name: "edit" }, { name: "destroy" }],
            title: "&nbsp;",
            width: "250px"
        }]

    $scope.afcGridOptions = {
        columns: afcColumns,
        dataSource: $scope.afcGridDataSource,
        editable: "inline",
        selectable: true,
        sortable: true,
        pageable: true,
        change: $scope.onSelect
    };


    // SAVE & CANCEL LOGIC
    $scope.$on('saveClicked', function (event, data)
    {
        debugger;
    });
    $scope.$on('cancelClicked', function (data)
    {
        debugger;
    });

}];

export { crAdFeeCapControllerConfig };