import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { ApiConfig } from "AppConfig/ApiConfig";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";

interface IDppMultiImagesDirectiveScope extends ng.IScope
{
    //External scope
    dppId: number;
    dppBuildingId: number;
    selectedDppBuildingAssetId: number;
    selectedDppBuildingAssets: DppResources.IDisasterPrepProfileBuildingAsset[];
    availableDppBuildingAssets: DppResources.IDisasterPrepProfileBuildingAsset[];
    dppRefreshAssets: () => () => void;
    onSelect: () => (asset: DppResources.IDisasterPrepProfileBuildingAsset) => void;
    onRemove: () => (asset: DppResources.IDisasterPrepProfileBuildingAsset) => void;

    AppSettings: IAppSettings;
    
    Remove: (asset: DppResources.IDisasterPrepProfileBuildingAsset) => void;
}

export class DppMultiImagesDirective
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.directive('dppMultiImageSelector', ['$window', '$timeout', '$parse', "dppApiResources", "apiConfig",
            ($window: ng.IWindowService, $timeout: ng.ITimeoutService, $parse: ng.IParseService, dppApiResources: DppApiResources, apiConfig: ApiConfig): ng.IDirective =>
            {
                let scopeBinding: IScopeBinding = {
                    dppId: "=",
                    dppBuildingId: "=",
                    selectedDppBuildingAssetId: "=",
                    selectedDppBuildingAssets: "=",
                    availableDppBuildingAssets: "=",
                    dppRefreshAssets: "&?",
                    onSelect: "&?",
                    onRemove: "&?"
                };

                return {
                    scope: scopeBinding,
                    restrict: "A",
                    replace: false,
                    transclude: false,
                    templateUrl: '/Templates/Dpp/Directives/MultiImageSelector.html',
                    link: (scope: IDppMultiImagesDirectiveScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) =>
                    {
                        scope.AppSettings = apiConfig.AppSettings;
                        scope.Remove = (asset: DppResources.IDisasterPrepProfileBuildingAsset) =>
                        {
                            if (scope.onRemove) {
                                scope.onRemove()(asset);
                            }
                        }

                        scope.$watch("selectedDppBuildingAssetId", () =>
                        {
                            if (scope.onSelect && scope.selectedDppBuildingAssetId > 0 && scope.availableDppBuildingAssets) {
                                let foundMatches = scope.availableDppBuildingAssets.filter(asset => asset.DppBuildingAssetId === scope.selectedDppBuildingAssetId);
                                if (foundMatches.length > 0) {
                                    scope.onSelect()(foundMatches[0]);
                                }
                            }
                        });
                    }
                };
            }]);
    }
}

