import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { RpmEntities } from "../../Interfaces/FranForce/Rpm/RpmResources";

export type CesReportsFranchiseSelectorOnSelectParameters = { franchise: kendo.data.ObservableObject & Partial<CoreResources.IFranchise> };

export class CesReportsFranchiseSelectorComponentController implements ng.IController {
    selectedModel: kendo.data.ObservableObject & Partial<CoreResources.IFranchise>;
    dropdown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    //---- Bindings ----
    franchiseId: number;
    select: string[];
    filters: kendo.data.DataSourceFilters;
    sort: (a: Partial<CoreResources.IFranchise>, b: Partial<CoreResources.IFranchise>) => number;
    disable: boolean;
    displayTextTemplate: string;
    extraOptions: kendo.ui.DropDownListOptions;
    onSelect: (params: CesReportsFranchiseSelectorOnSelectParameters) => void;
    //------------------

    static readonly DisplayProperty = "DisplayText";

    static $inject = [
        "$q",
        "$interpolate",
        "$location",
        "coreApiResources",
        "identityManager",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $q: ng.IQService,
        private $interpolate: ng.IInterpolateService,
        private $location: ng.ILocationService,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    ) {

    }

    $onInit() {
        console.log("inside init francchise id | filters", this.franchiseId, this.filters);

        if (!this.select || !(this.select instanceof Array)) {
            this.select = [
                nameof<CoreResources.IFranchise>(o => o.ConceptId),
                nameof<CoreResources.IFranchise>(o => o.FranchisorId),
                nameof<CoreResources.IFranchise>(o => o.FranchiseId),
                nameof<CoreResources.IFranchise>(o => o.LicenseNumber),
                nameof<CoreResources.IFranchise>(o => o.DoingBusinessAs),
                nameof<CoreResources.IFranchise>(o => o.FranchiseeName),
            ];
        }

        this.selectedModel = null;

        if (!this.displayTextTemplate)
            this.displayTextTemplate = `{{${nameof<CoreResources.IFranchise>(o => o.FranchiseeName)}}}`;

        if (!this.sort)
            this.sort = (a, b) => { return a[CesReportsFranchiseSelectorComponentController.DisplayProperty].toLowerCase().localeCompare(b[CesReportsFranchiseSelectorComponentController.DisplayProperty].toLowerCase()); }

        this.SetDropDownOptions();
    }

    SetDropDownOptions() {
        let overridableOptions: kendo.ui.DropDownListOptions = {
            autoWidth: true,
            filter: "contains",
            height: 450,
            enable: !this.disable
        };

        let permanentOptions: kendo.ui.DropDownListOptions = {
            optionLabel: {
                [CesReportsFranchiseSelectorComponentController.DisplayProperty]: "Select Franchise...",
                FranchiseId: null,
            },
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options: kendo.data.DataSourceTransportReadOptions) => {
                        console.log("this.filters in read", this.filters);

                        if (this.filters === undefined || this.filters === null) {
                            options.success([]);
                            return;
                        }

                        let filters: kendo.data.DataSourceFilters = this.filters;

                        let params: ODataQueryParameters = {};
                        params.$select = this.GetSelectString();

                        console.log("odata filters", this.odataHelper.ConvertKendoDataSourceFiltersToString(filters));

                        params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersToString(filters);

                        this.coreApiResources.FranchiseApi.query(params)
                            .$promise.then((franchises) => {

                                console.log("this.franchiseId |  franchises in then", this.franchiseId, franchises);

                                for (let franchise of franchises) {
                                    franchise[CesReportsFranchiseSelectorComponentController.DisplayProperty] =
                                        this.$interpolate(this.displayTextTemplate)(franchise);
                                }
                                if (this.sort) {
                                    franchises = franchises.sort(this.sort);
                                }

                                options.success(franchises);

                                if (this.franchiseId) {
                                    this.SelectById();
                                }
                            })
                            .catch(err => options.error(err));
                    }
                }
            }),
            dataTextField: CesReportsFranchiseSelectorComponentController.DisplayProperty,
            dataValueField: nameof<CoreResources.IFranchise>(o => o.FranchiseId),
            
            select: (e) => {
                console.log("inside triggered select", e);
                if (e.dataItem.FranchiseId || this.dropdown.dataSource.data().length > 0) {
                    this.selectedModel = e.dataItem;
                    this.franchiseId = this.selectedModel.FranchiseId;
                    this.onSelect({ franchise: e.dataItem });
                    return;
                }

                console.log("default", e);
                this.onSelect({ franchise: e.dataItem });
            }
        };

        this.options = angular.merge({}, overridableOptions, this.extraOptions, permanentOptions);
    }

    $postLink() {
        console.log("inside postlink", this.filters, this.franchiseId);
    }

    $onChanges(onChanges: ng.IOnChangesObject) {

        if (onChanges[nameof(this.filters)]) {
            console.log("inside on change filters", this.filters);
            this.Refresh()
        }

        if (onChanges[nameof(this.franchiseId)]) {
            this.SelectById();
        }

        if (onChanges[nameof(this.disable)]) {
            if (this.dropdown) {
                this.dropdown.enable(!this.disable);
            }
        }
    }

    SelectById() {
        console.log(this.franchiseId);

        if (this.dropdown) {
            console.log("inside SelectById", this.franchiseId);
            this.dropdown.select(f => f.FranchiseId === this.franchiseId);
            this.dropdown.trigger("select", <kendo.ui.DropDownListSelectEvent>{
                dataItem: this.dropdown.dataItem(),
                sender: this.dropdown,
                item: this.dropdown.element
            });
        }
    }

    Refresh() {
        if (this.dropdown) {
            this.dropdown.dataSource.read();
        }
    }

    private GetSelectString() {
        if (!this.select)
            this.select = [];

        if (!this.select.some(s => s == nameof<CoreResources.IFranchise>(o => o.FranchiseId)))
            this.select.push(nameof<CoreResources.IFranchise>(o => o.FranchiseId));

        if (!this.select.some(s => s == nameof<CoreResources.IFranchise>(o => o.FranchisorId)))
            this.select.push(nameof<CoreResources.IFranchise>(o => o.FranchisorId));

        if (!this.select.some(s => s == nameof<CoreResources.IFranchise>(o => o.ConceptId)))
            this.select.push(nameof<CoreResources.IFranchise>(o => o.ConceptId));

        return this.select.join(",")
    }

    static BindComponent(app: ng.IModule) {
        app.component("cesReportsFranchiseSelector", {
            bindings: {
                [nameof<CesReportsFranchiseSelectorComponentController>(o => o.franchiseId)]: "<",
                [nameof<CesReportsFranchiseSelectorComponentController>(o => o.select)]: "<",
                [nameof<CesReportsFranchiseSelectorComponentController>(o => o.filters)]: "<",
                [nameof<CesReportsFranchiseSelectorComponentController>(o => o.sort)]: "<",
                [nameof<CesReportsFranchiseSelectorComponentController>(o => o.disable)]: "<",
                [nameof<CesReportsFranchiseSelectorComponentController>(o => o.displayTextTemplate)]: "<",
                [nameof<CesReportsFranchiseSelectorComponentController>(o => o.extraOptions)]: "<",
                [nameof<CesReportsFranchiseSelectorComponentController>(o => o.onSelect)]: "&"
            },
            controller: CesReportsFranchiseSelectorComponentController,
            template: `<select kendo-drop-down-list="$ctrl.dropdown"
                            k-options="$ctrl.options"></select>`,
        });
    }
}

