import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import * as _ from "underscore"
import * as moment from "moment"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { AxiosInstance } from "axios";
import { IntegrationFinanceCompanyClient, IntegrationFinanceCompanyVm } from "@nbly/billing-orchestrations-clients";
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";

export class BatchManagementInvoiceAdjustmentsComponentController implements ng.IController
{
    // -- Bindings --

    // --

    integrationFinanceSourceTypeId: number;

    isLoading: boolean;

    isClosingFromMarkPosted: boolean = false; // Flag to track if closing from MarkInvoicesPosted

    window: kendo.ui.Window;
    concept: Partial<CoreResources.IConcept>;
    franchisor: Partial<CoreResources.IFranchisor>;
    franchisors: Array<Partial<CoreResources.IFranchisor>>;

    selectedFranchisors: Array<Partial<CoreResources.IFranchisor>>;
    conceptFilters: kendo.data.DataSourceFilter;
    franchisorFilters: kendo.data.DataSourceFilter;

    adjustmentGrid: kendo.ui.Grid
    adjustmentsLoaded: boolean;
    selectedAdjustmentIds: number[];
    selectedAdjustments: RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result[] = [];

    invoicesDataSource: kendo.data.DataSource;

    billingOrchestrationsClient: AxiosInstance;
    integrationFinanceCompanyClient: IntegrationFinanceCompanyClient

    hasUnsavedChanges: boolean = false;

    static $inject = [
        'coreApiResources',
        'rpmUiApiResources',
        'rpmHelper',
        '$timeout',
        '$q',
        '$scope',
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
        private $scope: ng.IScope
    )
    {
        this.billingOrchestrationsClient = FranForceAxiosClientBuilder.BuildBillingOrchestrationsBaseClient();
        this.integrationFinanceCompanyClient = new IntegrationFinanceCompanyClient("", this.billingOrchestrationsClient);
    }

    $onInit()
    {
        this.Clear();

        this.conceptFilters = this.rpmHelper.GetRmsRoyaltySystemConceptFilters();
        this.franchisorFilters = this.rpmHelper.GetRmsRoyaltySystemFranchisorFilters();
    }

    showSnackbar: boolean;
    snackbarType: string;
    snackbarMessage: string;

    DisplaySnackbar(type: string, message: string) {
        this.showSnackbar = !this.showSnackbar;
        this.snackbarType = type;
        this.snackbarMessage = message;
        this.$scope.$evalAsync();
    }

    $postLink()
    {
        this.window.setOptions(<kendo.ui.WindowOptions>{
            modal: true,
            position: {
                left: 0
            },
            title: "Invoice Adjustments",
            actions: [ 
                "Close"
            ],
            close: (event: kendo.ui.WindowCloseEvent) => {
                this.onWindowClose(event);
            }
        });

        this.InitAdjustmentGrid()
    }

    onWindowClose(e: kendo.ui.WindowCloseEvent): void {
        if (!this.isClosingFromMarkPosted && this.selectedAdjustmentIds && this.selectedAdjustmentIds.length > 0) {
            const confirmed = confirm(
                `You have ${this.selectedAdjustmentIds.length} adjustment(s) selected.\n\n` +
                `If you haven't populated the adjustments to staging yet, you can safely close this window.\n` +
                `However, if you have already populated them to staging, closing this window will prevent you from posting these adjustments.\n\n` +
                `Are you sure you want to close this window?`
            );
            if (!confirmed) {
                e.preventDefault();
            }
        }
        // Reset the flag after window closes
        this.isClosingFromMarkPosted = false;
    }


    // Corresponding close method
    ClosePopup() {
        // Check if there are selected adjustments that need to be posted
        const confirmed = confirm(
            `You have ${this.selectedAdjustmentIds.length} adjustment(s) selected.\n\n` +
            `If you haven't populated the adjustments to staging yet, you can safely close this window.\n` +
            `However, if you have already populated them to staging, closing this window will prevent you from posting these adjustments.\n\n` +
            `Are you sure you want to close this window?`
        );

        if (confirmed) {
            // User confirmed closing despite unsaved adjustments
            this.window.close();
        }
    }

    $onChanges(onChanges: ng.IOnChangesObject) {

    }

    Clear()
    {
        this.selectedAdjustmentIds = [];
        this.selectedFranchisors = [];
        this.selectedAdjustmentIds = [];
        this.selectedAdjustments = [];
        this.adjustmentsLoaded = false;
    }

    InitAdjustmentGrid()
    {
        let adjDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.RoyaltyPeriodReportAdjustmentId),
                    fields: {
                        RoyaltyPeriodReportAdjustmentId: { type: "number", validation: { required: true } },
                        [nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.PeriodReportEndDate)]: { type: "date", validation: { required: true } },
                        CreatedDateTime: { type: "string", validation: { required: true } },
                        TotalAdjustment: { type: "number", validaiton: { required: true } },
                        Description: { type: "string", validation: { required: true } },
                        RoyaltyPeriodReportId: { type: "string", validation: { required: true } }
                    }
                }
            },
            pageSize: 10,
            transport: {
                read: (options) =>
                {
                    this.isLoading = true;
                    this.rpmUiApiResources.GetRoyaltyPeriodReportAdjustmentsUnpostedByFranchisorIds(this.selectedFranchisors.map(f => f.FranchisorId))
                        .then(adjustmentsResponse =>
                        {
                            this.selectedAdjustmentIds = [];
                            this.selectedAdjustments = [];

                            options.success(adjustmentsResponse.data);

                            this.adjustmentsLoaded = true;

                            return this.$timeout(() =>
                            {
                                this.window.center();
                            })
                        })
                        .catch((err) => { options.error(err); })
                        .finally(() => { this.isLoading = false; });
                }
            }
        });

        let adjColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.PeriodReportAdjustmentID),
                title: "Report Adjustment Id",
                template: `<button 
                    ng-show="dataItem.${nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.RoyaltyPeriodReportAdjustmentId)}"
                    ng-class="'pure-button grid-edit-button ' + ($ctrl.${nameof(this.IsSelectedAdjustment)}(dataItem.${nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.RoyaltyPeriodReportAdjustmentId)}) ? 'rpm-selected-row-btn' : '')" 
                    ng-click="$ctrl.${nameof(this.ToggleSelect)}(dataItem) ; $event.preventDefault()">
                        <i ng-class="'fa fa-' + ($ctrl.${nameof(this.IsSelectedAdjustment)}(dataItem.${nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.RoyaltyPeriodReportAdjustmentId)}) ? 'check-circle-o' : 'circle-o')" aria-hidden="true"></i>
                        <span ng-bind="dataItem.${nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.PeriodReportAdjustmentID)}"></span>
                    </button>
                    <span ng-show="!dataItem.${nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.PeriodReportAdjustmentID)}" 
                            ng-bind="dataItem.${nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.PeriodReportAdjustmentID)}"></span>`
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.LicenseNumber),
                title: "Account Code",
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.FranchiseeName),
                title: "Franchisee",
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.PeriodReportEndDate),
                title: "End Date",
                format: "{0:d}"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.CreatedDateTime),
                title: "Adjustment Date",
                template: `#= kendo.toString(kendo.parseDate(${nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.CreatedDateTime)}), 'd') #`
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.RoyaltyFeeAdjustment),
                title: "Royalty Fee Adjustment",
                format: "{0:c2}"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.AdFeeAdjustment),
                title: "Ad Fee Adjustment",
                format: "{0:c2}"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.TechnologyFeeAdjustment),
                title: "Tech Fee Adjustment",
                format: "{0:c2}"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.TAFSFeeAdjustment),
                title: "TAFS Fee Adjustment",
                format: "{0:c2}"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result>(o => o.Description),
                title: "Description"
            }
        ];

        this.adjustmentGrid.setOptions(<kendo.ui.GridOptions>{
            autoBind: false,
            columns: adjColumns,
            dataSource: adjDataSource,
            pageable: true,
            resizable: true
        });
    }

    AddFranchisor()
    {
        let pushFranchisor = (zor: Partial<CoreResources.IFranchisor>) =>
        {
            if (this.selectedFranchisors.some(sZor => sZor.FranchisorId == zor.FranchisorId)) {
                return;
            }
            this.selectedFranchisors.push(zor);

            this.invoicesDataSource = null;
            this.adjustmentGrid.dataSource.data([]);
        }

        if (this.franchisor && this.franchisor.FranchisorId) {
            pushFranchisor(this.franchisor);
        }
        else {
            this.franchisors.forEach(f => pushFranchisor(f));
        }
    }

    RemoveFranchisor(franchisor: Partial<CoreResources.IFranchisor>)
    {
        this.selectedFranchisors = _.filter(this.selectedFranchisors, (zor) =>
        {
            return zor.FranchisorId != franchisor.FranchisorId;
        });
        this.invoicesDataSource = null;
        this.adjustmentGrid.dataSource.data([]);
    }

    OpenAdjustmentsPopup()
    {
        this.Clear();

        this.window.open();
        
        this.$timeout(() =>
        {
            this.window.center();
        })
    }

    LoadAdjustments()
    {
        return this.$q.when(this.adjustmentGrid.dataSource.read())
            .then(() =>
            {
                this.adjustmentGrid.refresh();
            })
    }

    async GetIntegrationFinanceSourceTypeIdForFranchisor(franchisorId: number) {
        const integrationFinanceCompanyResponse =
            await this.integrationFinanceCompanyClient.searchAll(null, null, franchisorId, null, true);

        const integrationFinanceCompanies: IntegrationFinanceCompanyVm[] = integrationFinanceCompanyResponse.result;

        let integrationFinanceSourceTypeId = 1;

        if (integrationFinanceCompanies && integrationFinanceCompanies.length > 0) {
            integrationFinanceSourceTypeId = integrationFinanceCompanies[0].integrationFinanceSourceTypeId;
        }

        return integrationFinanceSourceTypeId;
    }

    MarkInvoicesPosted(){
        // Set flag before closing
        this.isClosingFromMarkPosted = true;
        this.window.close();
    }

    async LoadInvoiceAdjustments()
    {
        const self = this;

        this.invoicesDataSource = new kendo.data.DataSource({
            pageSize: 10,
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) =>
                {
                    this.isLoading = true;

                    this.rpmUiApiResources.GetRoyaltyReportBatchInvoicesAdjustmentsOnly(this.selectedAdjustmentIds)
                        .then((invoicesResponse) =>
                        {
                            for (let invoice of invoicesResponse.data) {
                                invoice.Date = moment(new Date(invoice.Date)).format("M/D/Y");
                            }
                            options.success(invoicesResponse.data);

                            return this.$timeout(() =>
                            {
                                this.window.center();
                            })
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        })
                        .finally(() => { this.isLoading = false; });
                }
            },
            sort: {
                field: nameof<RpmEntities.usp_RMS_GetGreatPlainsRoyaltiesInvoiceList_Result>(o => o.ExtAmount),
                dir: "asc"
            }
        });

        const deferred = this.$q.defer();

        const [selectedFranchisor, _] = this.selectedFranchisors;

        const { FranchisorId: franchisorId } = selectedFranchisor;

        this.GetIntegrationFinanceSourceTypeIdForFranchisor(franchisorId).then(
            function (id) {
                self.integrationFinanceSourceTypeId = id;
                deferred.resolve();
            }
        )

        return deferred.promise;
    }

    setUnsavedChanges(hasChanges: boolean) {
        this.hasUnsavedChanges = hasChanges;
    }

    ToggleSelect(selectedItem: RpmEntities.usp_RoyaltyPeriodReportAdjustments_GetUnpostedByFranchisorId_Result) {
        if (this.selectedAdjustmentIds.some(id => id === selectedItem.RoyaltyPeriodReportAdjustmentId)) {
            this.selectedAdjustmentIds = _.without(this.selectedAdjustmentIds, selectedItem.RoyaltyPeriodReportAdjustmentId);
    
            const itemToRemove = this.selectedAdjustments.find(item => item.RoyaltyPeriodReportAdjustmentId === selectedItem.RoyaltyPeriodReportAdjustmentId);
            if (itemToRemove) {
                this.selectedAdjustments = _.without(this.selectedAdjustments, itemToRemove);
            }
        } else {
            this.selectedAdjustmentIds.push(selectedItem.RoyaltyPeriodReportAdjustmentId);
    
            this.selectedAdjustments.push(selectedItem);
        }
    }
    


    IsSelectedAdjustment(royaltyPeriodReportAdjustmentId: number)
    {
        return this.selectedAdjustmentIds.some((id) =>
        {
            return id === royaltyPeriodReportAdjustmentId;
        })
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('bmInvoiceAdjustments', {
            bindings: {
                //[nameof<BatchManagementInvoiceAdjustmentsComponentController>(o => o.)]: "<",
            },
            controller: BatchManagementInvoiceAdjustmentsComponentController,
            templateUrl: "/Templates/BatchManagement/bmInvoiceAdjustments.html"
        });
    }
}
