import { ApiConfig } from "AppConfig/ApiConfig";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

interface IEditFranchiseProfileServiceSkuSelectorScope extends ng.IScope {
    franchiseId: string;
    serviceSku: CoreEntities.ServiceSku;

    isLoading: boolean;
    selectedConceptOptionsId: number;
    ConceptOptions: kendo.ui.DropDownListOptions;
    NoConceptOptions: kendo.ui.DropDownListOptions;
    ToggleLoading: (isLoading: boolean) => void;
}

let serviceSkuSelectorDirectiveConfig = [
    'coreApiResources', "$timeout", 'odataHelper', 'apiConfig',
    (coreApiResources: CoreApiResources, $timeout: ng.ITimeoutService, odataHelper: ODataHelperService, apiHelper: ApiConfig) =>
    {
        return {
            restrict: "E",
            scope: {
                franchiseId: "@",
                serviceSku: "="
            },
            templateUrl: apiHelper.AppSettings.ApplicationRootUrl + "/Templates/FranchiseProfiles/ServiceSkuSelector.html",
            link: (scope: IEditFranchiseProfileServiceSkuSelectorScope, element: angular.IAugmentedJQuery, attr: angular.IAttributes) =>
            {
                scope.ToggleLoading = (isLoading: boolean) =>
                {
                    $timeout(() =>
                    {
                        scope.isLoading = isLoading;
                    });
                }

                scope.ToggleLoading(false);
                scope.$watch("serviceSku.ServiceSkuId",
                    () =>
                    {
                        if (scope.serviceSku) {
                            if (scope.serviceSku.FranchiseServiceSkus.length) {
                                let fSku = scope.serviceSku.FranchiseServiceSkus.filter(f => f.FranchiseId === parseInt(scope.franchiseId))[0];
                                if (fSku) {
                                    scope.selectedConceptOptionsId = (fSku.IsPriority ? 2 : 1);
                                }
                                else {
                                    scope.selectedConceptOptionsId = -1;
                                }
                            }
                            else {
                                scope.selectedConceptOptionsId = -1;
                            }

                            scope.ConceptOptions = {
                                dataSource: [
                                    { id: 2, name: "High Priority", icon: "fa-exclamation-circle icon-warning" },
                                    { id: 1, name: "Normal Service", icon: "fa-check icon-success" },
                                    { id: -1, name: "Unavailable", icon: "fa-times" }
                                ],
                                dataTextField: "name",
                                dataValueField: "id",
                                valueTemplate: "<i class='fa {{dataItem.icon}}' aria-hidden='true'></i> <span style='padding: 0 5px;'>{{dataItem.name}}</span>",
                                template: "<i class='fa {{dataItem.icon}}' aria-hidden='true'></i> <span style='padding: 0 5px;'>{{dataItem.name}}</span>",
                                select: (e) =>
                                {
                                    if (e.dataItem && e.dataItem.id != scope.selectedConceptOptionsId && scope.franchiseId && scope.serviceSku.ServiceSkuId) {
                                        scope.ToggleLoading(true);
                                        let compositeId = odataHelper.ConvertCompositeKeyToString({
                                            FranchiseId: scope.franchiseId,
                                            ServiceSkuId: scope.serviceSku.ServiceSkuId
                                        });

                                        let getFranSkuPromise = coreApiResources.FranchiseServiceSkuApi.get({ id: compositeId }).$promise;

                                        if (e.dataItem.id === 1 || e.dataItem.id === 2) {
                                            getFranSkuPromise.then(
                                                (franchiseServiceSku) =>
                                                {
                                                    if (franchiseServiceSku.IsPriority !== (e.dataItem.id === 2)) {
                                                        franchiseServiceSku.IsPriority = (e.dataItem.id === 2);
                                                        return franchiseServiceSku.$update({ id: compositeId })
                                                            .catch((reason) =>
                                                            {
                                                                alert("There was an error when changing the priority of this service. If this error persists, please contact The Dwyer Group with any details you can provide.");
                                                                console.error(reason);
                                                            });
                                                    }
                                                },
                                                (err: ng.IHttpPromiseCallbackArg<any>) =>
                                                {
                                                    if (err.status === 404) {
                                                        let newFranSku = new coreApiResources.FranchiseServiceSkuApi();
                                                        newFranSku.FranchiseId = parseInt(scope.franchiseId);
                                                        newFranSku.ServiceSkuId = scope.serviceSku.ServiceSkuId;
                                                        newFranSku.IsPriority = (e.dataItem.id === 2);
                                                        return newFranSku.$save({ id: compositeId })
                                                            .catch((reason) =>
                                                            {
                                                                alert("There was an error when trying to add this service. If this error persists, please contact The Dwyer Group with any details you can provide.");
                                                                console.error(reason);
                                                            });
                                                    }
                                                    else {
                                                        alert("There was an error when trying to add this service. If this error persists, please contact The Dwyer Group with any details you can provide.");
                                                        console.error(err);
                                                    }
                                                }
                                            ).finally(() => { scope.ToggleLoading(false); });
                                        }
                                        else if (e.dataItem.id === -1) {
                                            getFranSkuPromise.then(
                                                (franchiseServiceSku) =>
                                                {
                                                    return franchiseServiceSku.$delete({ id: compositeId, allowHardDelete: true })
                                                        .catch((reason) =>
                                                        {
                                                            alert("There was an error when trying to remove this service. If this error persists, please contact The Dwyer Group with any details you can provide.");
                                                            console.error(reason);
                                                        });
                                                },
                                                (err: ng.IHttpPromiseCallbackArg<any>) =>
                                                {
                                                    if (err.status !== 404) {
                                                        alert("There was an error when trying to remove this service. If this error persists, please contact The Dwyer Group with any details you can provide.");
                                                        console.error(err);
                                                    }
                                                })
                                                .finally(() => { scope.ToggleLoading(false); });
                                        }
                                        else {
                                            scope.ToggleLoading(false);
                                        }
                                    }
                                }
                            };
                        }
                    });
            }
        };
    }
];

export { serviceSkuSelectorDirectiveConfig };