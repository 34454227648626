import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyContractRatePlanItemsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyContractRatePlanItemsByContractPlanItem(contractRatePlanId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContractRatePlanItems/InContractRatePlan/" + contractRatePlanId
        };

        return this.$http<RpmEntities.RoyaltyContractRatePlanItemViewModel[]>(config);
    }

    CreateRoyaltyContractRatePlanItem(model: RpmEntities.RoyaltyContractRatePlanItemViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyContractRatePlanItems",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyContractRatePlanItem(id: number, model: RpmEntities.RoyaltyContractRatePlanItemViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyContractRatePlanItems/" + id,
            data: model
        };

        return this.$http<number>(config);
    }
}