import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { RpmEntities, RpmResources } from "Interfaces/FranForce/Rpm/RpmResources";

interface RoyaltyReportBatchBackedOutParams {
    status: RpmEntities.usp_RoyaltyReportBatchStatus_GetBySetViaBatchManagement_Result;
    reason: string;
    selectedBatch: RpmEntities.usp_RoyaltyReportBatchs_GetByRoyaltyReportBatchId_Result;
  }

export class BatchManagementResetBatchesComponentController implements ng.IController 
{
    
    onInit: (params: {
        open: (selectedBatchId: number) => ng.IPromise<void>
    }) => void;
    onSave: (params: {}) => void;
    onVoidReset: (params: {}) => void;
    setErrorDescription: (params: { errorDesc: string }) => void;
    setPostedPeriodReportIdsForReversal: (params: { postedPeriodReportIdsForInvoiceReversal: number[] }) => void;

    onRoyaltyReportBatchStatusSelected: (params: {
        backout: RoyaltyReportBatchBackedOutParams
    }) => void;

    selectedBatch: RpmEntities.usp_RoyaltyReportBatchs_GetByRoyaltyReportBatchId_Result;
    batchStatusesPromise: ng.IHttpPromise<RpmEntities.usp_RoyaltyReportBatchStatus_GetBySetViaBatchManagement_Result[]>;

    backoutBatchWindow: kendo.ui.Window;
    backoutStatusDropDown: kendo.ui.DropDownList;
    backoutStatusDropDownOptions: kendo.ui.DropDownListOptions;
    backout: RoyaltyReportBatchBackedOutParams;

    isLoading: boolean;

    static $inject = [
        'coreApiResources',
        '$q',
        '$log',
        '$timeout',
        'rpmApiResources',
        'rpmUiApiResources',
        'rpmHelper',
        'odataHelper'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private rpmApiResources: RpmApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private odataHelper: ODataHelperService
    )
    {
    }

    $onInit()
    {
        if (this.onInit) {
            this.onInit({
                open: (selectedBatchId: number) =>
                {
                    return this.BackoutBatchWindowOpen(selectedBatchId);
                }
            })
        }

        this.batchStatusesPromise = this.rpmUiApiResources.GetRoyaltyReportBatchStatusesForBatchManagement();
    }

    $postLink()
    {
        this.InitBackoutStatusDropdown();
    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {

    }

    InitBackoutStatusDropdown()
    {
        let datasource = new kendo.data.DataSource({
            transport: {
                read: (options) =>
                {
                    this.GetFilteredBatchStatuses(this.selectedBatch.RoyaltyReportBatchStatusID)
                        .then((statuses) =>
                        {
                            options.success(statuses);
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        })
                }
            }
        })

        this.backoutStatusDropDownOptions = {
            autoWidth: true,
            optionLabel: "Select Status...",
            height: 450,
            dataSource: datasource,
            autoBind: false,
            dataTextField: nameof<RpmEntities.usp_RoyaltyReportBatchStatus_GetBySetViaBatchManagement_Result>(o => o.Name),
            dataValueField: nameof<RpmEntities.usp_RoyaltyReportBatchStatus_GetBySetViaBatchManagement_Result>(o => o.RoyaltyReportBatchStatusID),
        };
    }

    BackoutBatchWindowOpen(selectedBatchId: number)
    {
        this.backout = {
            reason: "",
            status: null,
            selectedBatch: null
        };

        this.selectedBatch = null;
        this.isLoading = true;

        return this.rpmUiApiResources.GetRoyaltyReportBatchById(selectedBatchId)
            .then((batchResponse) =>
            {
                this.selectedBatch = batchResponse.data;
                return this.backoutStatusDropDown.dataSource.read()
            })
            .then(() =>
            {
                this.backoutBatchWindow.open();
                return this.$timeout(() =>
                {
                    this.backoutBatchWindow.center();
                }, 0)
            })
            .finally(() =>
            {
                this.isLoading = false;
            });
    }

    async GetPostedPeriodReportIdsForReversal() {
        let selectedBatchs: number[] = [this.selectedBatch.RoyaltyReportBatchId];
        let parameters: ODataQueryParameters = {};
        parameters.$filter = this.odataHelper.CreateInFilterStatement(nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatchId), selectedBatchs)
        let postedPeriodReportIdArray = await this.rpmApiResources.RoyaltyPeriodReportApi.query(parameters).$promise
            .then(periodReports => {
                return periodReports.map(x => {
                    return x.RoyaltyPeriodReportStatusID == 6 ? x.RoyaltyPeriodReportId : null
                }).filter(x => x != null);
            });
        return postedPeriodReportIdArray;
    }

    async BackoutBatch()
    {
        if (!this.backout.reason) {
            alert("Specify a reason for the backout");
            return this.$q.reject();
        }

        if (!this.backout.status || !this.backout.status.RoyaltyReportBatchStatusID) {
            alert("Specify a status for the backout");
            return this.$q.reject();
        }

        this.onRoyaltyReportBatchStatusSelected(
            {backout: {...this.backout, selectedBatch: this.selectedBatch}});

        //IF NOT VOIDED
        if (this.backout.status.RoyaltyReportBatchStatusID == 9) {
            let postedPeriodReportIdsForInvoiveReversal = await this.GetPostedPeriodReportIdsForReversal();

            if (this.setPostedPeriodReportIdsForReversal)
                this.setPostedPeriodReportIdsForReversal({ postedPeriodReportIdsForInvoiceReversal: postedPeriodReportIdsForInvoiveReversal });

            if (this.setErrorDescription)
                this.setErrorDescription({ errorDesc: this.backout.reason });  
            
            if (this.onVoidReset) {
                this.onVoidReset({});
            }
        }
        else{

            this.rpmUiApiResources.BackoutRoyaltyReportBatches({
                            RoyaltyReportBatchId: this.selectedBatch.RoyaltyReportBatchId,
                            RoyaltyPeriodReportId: null,
                            ErrorDescription: this.backout.reason,
                            RoyaltyReportBatchStatusID: this.backout.status.RoyaltyReportBatchStatusID,
                            RetryCount: null,
                            UserName: null
                        })
                        .then(() =>
                        {

                            if (this.onSave) {
                                this.onSave({});
                            }
                        });
        }

        this.backoutBatchWindow.close();
    }

    OnVoidRoyaltyBatch() {
        return this.rpmUiApiResources.BackoutRoyaltyReportBatches({
            RoyaltyReportBatchId: this.selectedBatch.RoyaltyReportBatchId,
            RoyaltyPeriodReportId: null,
            ErrorDescription: this.backout.reason,
            RoyaltyReportBatchStatusID: this.backout.status.RoyaltyReportBatchStatusID,
            RetryCount: null,
            UserName: null
        })
            .then(() => {
                this.backoutBatchWindow.close();
                if (this.backout.status.RoyaltyReportBatchStatusID == 9) {
                    if (this.onVoidReset) {
                        this.onVoidReset({});
                    }
                }
                else {
                    if (this.onSave) {
                        this.onSave({});
                    }
                }
            });
    }

    GetFilteredBatchStatuses(batchStatusId: number)
    {
        return this.batchStatusesPromise
            .then((statusesResponse) =>
            {
                let statuses = statusesResponse.data;

                return statuses.filter((status) =>
                {
                    console.log("batchStatusId",batchStatusId)

                    switch (batchStatusId)
                    {
                        case 1:
                        case 2:
                        case 3:
                            return status.RoyaltyReportBatchStatusID == 4;
                        case 4:
                            return status.RoyaltyReportBatchStatusID == 3;
                        case 6:
                            return status.RoyaltyReportBatchStatusID == 9;
                        default:
                            return false;
                    }
                });
            })
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('bmResetBatches', {
            bindings: {
                [nameof<BatchManagementResetBatchesComponentController>(o => o.onInit)]: "&",
                [nameof<BatchManagementResetBatchesComponentController>(o => o.onSave)]: "&",
                [nameof<BatchManagementResetBatchesComponentController>(o => o.onRoyaltyReportBatchStatusSelected)]: "&",
                [nameof<BatchManagementResetBatchesComponentController>(o => o.onVoidReset)]: "&",
                [nameof<BatchManagementResetBatchesComponentController>(o => o.setErrorDescription)]: "&",
                [nameof<BatchManagementResetBatchesComponentController>(o => o.setPostedPeriodReportIdsForReversal)]: "&"
            },
            controller: BatchManagementResetBatchesComponentController,
            templateUrl: "/Templates/BatchManagement/bmResetBatches.html"
        });
    }
}

