import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import * as _ from "underscore"
import { DisplayFranchise } from "Interfaces/FranForce/Common/DisplayFranchise";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

var franchiseConceptSelectorCount = 0;

export class ConceptFranchiseSelectorOldComponentController implements ng.IController {

    id: string;

    userInfo: IUserInfoViewModel;
    concepts: CoreResources.IConcept[];
    franchises: DisplayFranchise[];
    selectedConcept: CoreResources.IConcept;
    selectedFranchise: DisplayFranchise;
    conceptDropDownList: kendo.ui.DropDownList;
    conceptDropDownOptions: kendo.ui.DropDownListOptions;
    franchiseDropDownList: kendo.ui.DropDownList;
    franchiseDropDownOptions: kendo.ui.DropDownListOptions;

    //---- Bindings ----
    franchiseId: number;
    franchiseAccountCode: string;
    conceptId: number;
    conceptCssClass: string;
    franchiseCssClass: string;
    permissionsBased: boolean;
    activeOnly: boolean;
    willSelectOnOneChoice: boolean;
    hideConceptOnOneChoice: boolean;
    limitToConceptIds: number[];
    onInit: (params: { self: ConceptFranchiseSelectorOldComponentController }) => void;
    //------------------

    static $inject = [
        "$q",
        "coreApiResources",
        "identityManager",
        "$timeout",
        "apiConfig"
    ];
    
    constructor(
        private $q: ng.IQService,
        private coreApiResources: CoreApiResources,
        private identityManager: IdentityManager,
        private $timeout: ng.ITimeoutService,
        private apiConfig: ApiConfig
    )
    {

    }

    $onInit()
    {
        this.id = Math.random().toString(36).substr(2, 9);
        let franchiseParams: ODataQueryParameters = { $select: "ConceptId,FranchiseId,LicenseNumber,DoingBusinessAs" };
        if (this.activeOnly) {
            franchiseParams.$filter = nameof<CoreResources.IFranchise>(o => o.Status) + " eq 'Active'";
        }

        let franchisePromise = this.coreApiResources.FranchiseApi.query(franchiseParams)
            .$promise.then((franchises) => {
                this.franchises = franchises;
                for (let franchise of this.franchises) {
                    franchise.DisplayText = franchise.LicenseNumber + " " + franchise.DoingBusinessAs;
                }
            });
        let conceptPromise = this.coreApiResources.ConceptApi.query({ PermissionFilter: !this.permissionsBased, $select: "ConceptId,ConceptCode,DisplayName" })
            .$promise.then((concepts) => {
                this.concepts = concepts;
            });

        let userInfoPromise = this.identityManager.GetLoggedInUserInfo().then((userInfoResponse) => {
            this.userInfo = userInfoResponse.data;
        });

        this.$q.all([franchisePromise, conceptPromise, userInfoPromise]).then(() => {
            
            this.PermissionsBasedFilter();
            this.LimitConceptsFilter();
            this.MakeInitialSelections();
            this.SetDefaultCssClasses();
            
            this.conceptDropDownOptions = {
                dataSource: this.concepts,
                dataTextField: "DisplayName",
                dataValueField: "ConceptId",
                height: 450,
                optionLabel: "Select Concept...",
                select: (e) => {
                    this.conceptId = (<CoreResources.IConcept>e.dataItem).ConceptId;
                }
            };

            this.franchiseDropDownOptions = {
                dataSource: this.franchises,
                dataTextField: "DisplayText",
                dataValueField: "FranchiseId",
                cascadeFrom: "conceptDropDown_" + this.id,
                filter: "contains",
                height: 450,
                optionLabel: "Select Franchise...",
                select: (e) => {
                    this.franchiseId = (<DisplayFranchise>e.dataItem).FranchiseId;
                    this.franchiseAccountCode = (<DisplayFranchise>e.dataItem).LicenseNumber;
                }
            };
        });

        this.onInit({ self: this });
    }

    LimitConceptsFilter()
    {
        if (this.limitToConceptIds && this.limitToConceptIds.length)
        {
            this.concepts = this.concepts.filter((concept) => { return this.limitToConceptIds.some(cId => cId === concept.ConceptId) });
            this.franchises = this.franchises.filter((franchise) => { return this.limitToConceptIds.some(cId => cId === franchise.ConceptId) });
        }
    }

    PermissionsBasedFilter()
    {
        if (this.permissionsBased)
        {
            let conceptRunningList: CoreResources.IConcept[] = [];
            let franchiseRunningList: DisplayFranchise[] = [];

            let hasAllViewingPermissions = this.userInfo.Roles.some(r =>
                r.Name == this.apiConfig.FranForceConstants.RoleConstants.AdminRole ||
                r.Name == this.apiConfig.FranForceConstants.RoleConstants.SupportAdminRole ||
                r.Name == this.apiConfig.FranForceConstants.RoleConstants.VendorRole ||
                r.Name == this.apiConfig.FranForceConstants.RoleConstants.BrokerRole);

            if (hasAllViewingPermissions) {
                conceptRunningList.push(...this.concepts);
                franchiseRunningList.push(...this.franchises);
            }
            else
            {
                let managesConcepts = this.userInfo.Roles.some(r =>
                    r.Name == this.apiConfig.FranForceConstants.RoleConstants.ConceptManagerRole ||
                    r.Name == this.apiConfig.FranForceConstants.RoleConstants.FranchiseConsultantRole);
                let managesFranchises = this.userInfo.Roles.some(r =>
                    r.Name == this.apiConfig.FranForceConstants.RoleConstants.FranchiseeRole ||
                    r.Name == this.apiConfig.FranForceConstants.RoleConstants.FranchiseeEmployeeRole ||
                    r.Name == this.apiConfig.FranForceConstants.RoleConstants.FranchiseCSR);
                
                if (managesConcepts) {
                    let conceptClaimIds = this.userInfo.Claims
                        .filter(claim => claim.Type == this.apiConfig.FranForceConstants.ClaimConstants.ManagedConceptIdClaimType)
                        .map(claim => parseInt(claim.Value));
                    conceptRunningList.push(...this.concepts.filter(c => conceptClaimIds.some(cId => cId == c.ConceptId)));
                    
                    for (let concept of conceptRunningList) {
                        franchiseRunningList = _.union(franchiseRunningList, _.filter(this.franchises, f => f.ConceptId == concept.ConceptId));
                    }
                }
                if (managesFranchises)
                {
                    let franchiseClaimIds = this.userInfo.Claims
                        .filter(claim => claim.Type == this.apiConfig.FranForceConstants.ClaimConstants.OwnedFranchiseIdClaimType ||
                            claim.Type == this.apiConfig.FranForceConstants.ClaimConstants.EmployedFranchiseIdClaimType)
                        .map(claim => parseInt(claim.Value));
                    
                    franchiseRunningList = _.union(franchiseRunningList, this.franchises.filter(f => franchiseClaimIds.some(fId => fId == f.FranchiseId)));
                    
                    let missingConceptIds = _.difference(franchiseRunningList.map(f => f.ConceptId), conceptRunningList.map(c => c.ConceptId));
                    for (let missingConceptId of missingConceptIds)
                    {
                        let foundConcept = _.find(this.concepts, c => c.ConceptId == missingConceptId);
                        if (foundConcept)
                        {
                            conceptRunningList.push(foundConcept);
                        }
                    }
                }

                franchiseRunningList = _.unique(franchiseRunningList);
                conceptRunningList = _.unique(conceptRunningList);
            }

            this.franchises = franchiseRunningList;
            this.concepts = conceptRunningList;
        }
    }

    MakeInitialSelections()
    {
        this.selectedConcept = null;
        this.selectedFranchise = null;

        if (!this.franchises || !this.concepts)
            return;
        
        if (this.conceptId) {
            this.selectedConcept = _.find(this.concepts, c => c.ConceptId === this.conceptId);
        }
        if (this.franchiseAccountCode) {
            let foundFranchise = _.find(this.franchises, f => f.LicenseNumber === this.franchiseAccountCode);
            this.$timeout(() =>
            {
                this.selectedFranchise = foundFranchise;
            }, 0);
            this.selectedConcept = _.find(this.concepts, c => c.ConceptId === foundFranchise.ConceptId);
        }
        if (this.franchiseId) {
            let foundFranchise = _.find(this.franchises, f => f.FranchiseId === this.franchiseId);
            this.$timeout(() =>
            {
                this.selectedFranchise = foundFranchise;
            }, 0);
            this.selectedConcept = _.find(this.concepts, c => c.ConceptId === foundFranchise.ConceptId);
        }

        if (this.willSelectOnOneChoice)
        {
            if (this.franchises.length === 1)
            {
                this.selectedFranchise = this.franchises[0];
                this.selectedConcept = _.find(this.concepts, c => c.ConceptId == this.selectedFranchise.ConceptId);
            }
            else if (this.concepts.length === 1)
            {
                this.selectedConcept = this.concepts[0];
            }
        }
    }

    SetDefaultCssClasses()
    {
        if (!this.conceptCssClass && !this.franchiseCssClass)
        {
            if (this.hideConceptOnOneChoice && this.concepts.length === 1)
            {
                this.conceptCssClass = "pure-u-1";
                this.franchiseCssClass = "pure-u-1";

            }
            else
            {
                this.conceptCssClass = "pure-u-1 pure-u-md-1-3";
                this.franchiseCssClass = "pure-u-1 pure-u-md-2-3";
            }
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("conceptFranchiseSelectorOld", {
            bindings: {
                conceptId: "=?",
                franchiseId: "=?",
                franchiseAccountCode: "=?",
                conceptCssClass: "<",
                franchiseCssClass: "<",
                permissionsBased: "<",
                activeOnly: "<",
                willSelectOnOneChoice: "<",
                hideConceptOnOneChoice: "<?",
                limitToConceptIds: "<?",
                disabled: "<",
                onInit: "&"
            },
            controller: ConceptFranchiseSelectorOldComponentController,
            templateUrl: "/Templates/Common/ConceptFranchiseSelectorOld.html",
        });
    }
}

