let crGeneralControllerConfig = [
    '$scope',
    function ($scope)
    {
        // SAVE & CANCEL LOGIC
        $scope.$on('saveClicked', function (event, data)
        {
            debugger;
        });
        $scope.$on('cancelClicked', function (data)
        {
            debugger;
        });
    }
];

export { crGeneralControllerConfig };