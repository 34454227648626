import { ApiConfig } from "AppConfig/ApiConfig";

export class NotificationGroupApiResources {

    static $inject = [
        "$http",
        "apiConfig"
    ];

    constructor(
        private $http: ng.IHttpService,
        private apiConfig: ApiConfig
    ) {
    }

    GetAllNotification() {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.ServiceOrchestrationsApiRootUrl + "/protected/downtime-notifications?skip=0&take=25",
        };

        return this.$http<any[]>(config);
    }

    GetNotificationByAppId(applicationId) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.ServiceOrchestrationsApiRootUrl + "/public/applications/"+ applicationId +"/downtime-notifications?apikey=" + this.apiConfig.AppSettings.FranchiseEmployeeOrchestrationsApiKey,
        };

        return this.$http<any[]>(config);
    }

    CreateDownTimeNotification(notification: any) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.ServiceOrchestrationsApiRootUrl + "/protected/downtime-notifications",
            data: notification
        };

        return this.$http<string>(config);
    }

    UpdateDownTimeNotification(notification: any) {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.apiConfig.AppSettings.ServiceOrchestrationsApiRootUrl + "/protected/downtime-notifications/" + notification.downtimeNotificationId,
            data: notification
        };

        return this.$http<string>(config);
    }

    getApplications(){
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.ServiceOrchestrationsApiRootUrl + "/public/applications?apikey=" + this.apiConfig.AppSettings.FranchiseEmployeeOrchestrationsApiKey,
        };

        return this.$http<any[]>(config);
    }

    getDownTimeNotificationStatus(){
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.ServiceOrchestrationsApiRootUrl + "/public/downtime-notification-statuses?apikey=" + this.apiConfig.AppSettings.FranchiseEmployeeOrchestrationsApiKey,
        };

        return this.$http<any[]>(config);
    }

    getDownTimeNotificationType(){
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.ServiceOrchestrationsApiRootUrl + "/public/downtime-notification-types?apikey=" + this.apiConfig.AppSettings.FranchiseEmployeeOrchestrationsApiKey,
        };

        return this.$http<any[]>(config);
    }

    triggerEmailNotification(downtimeNotificationId :any){
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.apiConfig.AppSettings.ServiceOrchestrationsApiRootUrl + "/protected/downtime-notifications/"+ downtimeNotificationId +"/franchisers/alert",
        };

        return this.$http<any[]>(config);
    }
}