import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyDisclosureDocumentRatePlanMinimumItemsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;
    
    GetRoyaltyDisclosureDocumentRatePlanMinimumItemsByRatePlan(ratePlanId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlanMinimumItems/InRatePlan/" + ratePlanId
        };

        return this.$http<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel[]>(config);
    }

    CreateRoyaltyDisclosureDocumentRatePlanMinimumItem(model: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlanMinimumItems",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyDisclosureDocumentRatePlanMinimumItem(id: number, model: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlanMinimumItems/" + id,
            data: model
        };

        return this.$http<number>(config);
    }
}
