import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { AxiosInstance } from "axios";
import { FranchiseRiskCustomersClient, FranchiseOrchestrationClientResponse, FranchiseRiskCustomerDtoPagedResult } from "@nbly/franchise-orchestration-clients"
import { FranForceAxiosClientBuilder } from "../../Clients/FranForceAxiosClientBuilder";

import { randomBytes } from "crypto";

export class CesMLYChurnRiskCustomersController implements ng.IController {
    //bindings
    franshiseId: number;

    static $inject = [
        "$window",
        "identityManager",
        "apiConfig",
        "$scope"
    ];

    franchiseOrchestrationClient: AxiosInstance;
    franchiseRiskCustomerClient: FranchiseRiskCustomersClient
    constructor(
        private $window: ng.IWindowService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $scope: ng.IScope
    ) {
        this.franchiseOrchestrationClient = FranForceAxiosClientBuilder.BuildFranchiseOrchestrationClient();
        this.franchiseRiskCustomerClient = new FranchiseRiskCustomersClient("", this.franchiseOrchestrationClient);
    }

    InitRiskCustomersGrid = function () {
        const columns: kendo.ui.GridColumn[] = [
            {
                field: "jobFrequency",
                title: "Job Frequency"
            },
            {
                field: "fullName",
                title: "Full Name"
            },
            {
                field: "firstName",
                title: "First Name"
            },
            {
                field: "lastName",
                title: "Last Name"
            },
            {
                field: "street",
                title: "Street",
                headerAttributes: { style: "white-space: normal; word-wrap: break-word" },
                template: `<p kendo-tooltip 
                            style='box-sizing: border-box; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'
                            k-content='dataItem.street'>
                                {{dataItem.street}}
                            </p>`
            },
            {
                field: "unit",
                title: "Unit"
            },
            {
                field: "stateAbbr",
                title: "State Abbr",
            },
            {
                field: "phone1",
                title: "Phone1",
            },
            {
                field: "phone2",
                title: "Phone2",
            },
            {
                field: "phone3",
                title: "Phone3",
            },
            {
                field: "phone4",
                title: "Phone4",
            },
            {
                field: "phone5",
                title: "Phone5",
            },
            {
                field: "riskLevel",
                title: "Risk Level",
            },
            {
                field: "newCustomer",
                title: "New Customer",
            },
            {
                field: "lowNPSLast3Months",
                title: "Low NPS Last 3 Months",
            },
            {
                field: "twoMoreCanceledJobsLast3Months",
                title: "2 More Canceled Jobs Last 3 Months",
            },
            {
                field: "attendedByManyProfessionals",
                title: "Attended By Many Professionals",
            },
            {
                field: "attendedByNewProfessionals",
                title: "Attended By New Professionals",
            },
            {
                field: "lastNPSDate",
                title: "Last NPS Date",
            },
            {
                field: "lastNPSGrade",
                title: "Last NPS Grade",
            },
            {
                field: "franchiseeName",
                title: "Franchisee Name",
                headerAttributes: { style: "white-space: normal; word-wrap: break-word" },
                template: `<p kendo-tooltip 
                            style='box-sizing: border-box; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'
                            k-content='dataItem.franchiseeName'>
                                {{dataItem.franchiseeName}}
                            </p>`
            },
            {
                field: "franchiseId",
                title: "Franchise Id",
            },
            {
                field: "licenseNumber",
                title: "License Number",
                filterable: false
            }
        ]

        const churnRiskCustomersGridOptions: kendo.ui.GridOptions = {
            columns,
            toolbar: ["excel"],
            excel: {
                fileName: `MLYChurnCustomers-` +
                    `${randomBytes(10).toString("hex")}` +
                    `${new Date().getFullYear()}_` +
                    `${new Date().getMonth() + 1 < 10 ? "0" : ""}${new Date().getMonth() + 1}_` +
                    `${new Date().getDate() < 10 ? "0" : ""}${new Date().getDate()}.xlsx`,
                filterable: true,
                allPages: true
            },
            selectable: false,
            resizable: true,
            editable: false,
            scrollable: true,
            noRecords: true,
            sortable: true,
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [5, 10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: true
        }

        this.churnRiskCustomersDataSource = new kendo.data.DataSource({
            pageSize: 10,
            serverPaging: true,
            schema: {
                data: function (data) {
                    return data.results;
                },
                total: function (data) {
                    return data.rowCount;
                }
            },
            transport: {
                read: async (options: kendo.data.DataSourceTransportReadOptions) => {
                    try {
                        const { skip, take } = options.data;

                        const franchise_id = this.franchiseId;

                        const franchiseRiskCustomerPagedResult:
                            FranchiseOrchestrationClientResponse<FranchiseRiskCustomerDtoPagedResult> =
                            await this.franchiseRiskCustomerClient.paginate("", franchise_id, skip, take)

                        const { result: franchiseRiskCustomerDtoPagedResult } = franchiseRiskCustomerPagedResult;

                        options.success(franchiseRiskCustomerDtoPagedResult);
                    }
                    catch (error) {
                        options.error(error);
                    }
                    finally {
                        this.isLoading = false;
                    }
                }
            }
        });

        this.churnRiskCustomersGrid.setOptions(churnRiskCustomersGridOptions);
        this.churnRiskCustomersGrid.setDataSource(this.churnRiskCustomersDataSource);
    }

    $postLink() {
        this.InitRiskCustomersGrid();
    }

    isLoading = false;

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.churnRiskCustomersDataSource)] && this.churnRiskCustomersGrid) {
            this.churnRiskCustomersGrid.setDataSource(this.churnRiskCustomersDataSource);
        }
    }

    licenseNumbers: Array<string>

    async $onInit() {
        this.$scope.$on('refreshCustomerRiskData', (event, args) => {
            if (this.churnRiskCustomersGrid && this.churnRiskCustomersGrid.dataSource) {
                this.churnRiskCustomersGrid.dataSource.read();
                this.churnRiskCustomersGrid.refresh();
            }
        });
    }

    churnRiskCustomersDataSource: kendo.data.DataSource;

    churnRiskCustomersGrid: kendo.ui.Grid;

    static BindComponent(app: ng.IModule) {
        app.component('churnRiskCustomers', {
            bindings: {
                franchiseId: "<",
            },
            controller: CesMLYChurnRiskCustomersController,
            templateUrl: "/Templates/Reports/CesMLYChurnRiskCustomers.html"
        });
    }
}