import { StringProperties } from "../../Types/StringProperties";
import { SyncEmployeesByFranchisesMultiSelectorDataModel } from "../../Models/Common/SyncEmployeesByFranchisesMultiSelectorDataModel";
import { FranchiseOrchestrationsApiResources } from "../../Services/Resources/FranchiseOrchestrationsApiResources";


declare global {
    namespace JSX {
        interface IntrinsicElements {
            syncEmployeesByFranchisesMultiSelector: Partial<StringProperties<SyncEmployeesByFranchisesMultiSelectorComponentController>>;
        }
    }
}

export class SyncEmployeesByFranchisesMultiSelectorComponentController implements ng.IController {
    selectedModel: kendo.data.ObservableObject & SyncEmployeesByFranchisesMultiSelectorDataModel;
    dropdown: kendo.ui.DropDownList;
    options: kendo.ui.DropDownListOptions;

    syncEmployeesByFranchisesMultiSelectOptions: kendo.ui.MultiSelectOptions;
    syncEmployeesByFranchisesMultiSelect: kendo.ui.MultiSelect;

    //---- Bindings ----
    franchiseIds: number[];
    select: string[];
    displayTextTemplate: string;
    sort: (a: Partial<SyncEmployeesByFranchisesMultiSelectorDataModel>, b: Partial<SyncEmployeesByFranchisesMultiSelectorDataModel>) => number;
    selectedSyncEmployees: any[];
    enableSelectAll: boolean;
    //------------------

    static readonly DisplayProperty = "DisplayText";

    static $inject = [
        "$q",
        "$interpolate",
        "$location",
        "franchiseOrchestrationsApiResources"
    ];

    constructor(
        private $q: ng.IQService,
        private $interpolate: ng.IInterpolateService,
        private $location: ng.ILocationService,
        private franchiseOrchestrationsApiResources: FranchiseOrchestrationsApiResources
    ) {

    }

    async $onInit() {

        if (!this.select || !(this.select instanceof Array)) {
            this.select = [
                nameof<SyncEmployeesByFranchisesMultiSelectorDataModel>(o => o.syncEmployeeId),
                nameof<SyncEmployeesByFranchisesMultiSelectorDataModel>(o => o.name)
            ];
        }

        if (!this.displayTextTemplate)
            this.displayTextTemplate = `{{${nameof<SyncEmployeesByFranchisesMultiSelectorDataModel>(o => o.name)}}}`;

        if (!this.sort)
            this.sort = (a, b) => { return a[SyncEmployeesByFranchisesMultiSelectorComponentController.DisplayProperty].toLowerCase().localeCompare(b[SyncEmployeesByFranchisesMultiSelectorComponentController.DisplayProperty].toLowerCase()); }

        this.SetDropDownOptions();

    }

    async SetDropDownOptions() {
        this.syncEmployeesByFranchisesMultiSelectOptions =
        {
            placeholder: "Select Employees...",
            dataTextField: nameof<SyncEmployeesByFranchisesMultiSelectorDataModel>(o => o.name),
            dataValueField: nameof<SyncEmployeesByFranchisesMultiSelectorDataModel>(o => o.syncEmployeeId),
            valuePrimitive: true,
            autoBind: true,
            autoClose: false,
            clearButton: this.enableSelectAll ? false : true,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options: kendo.data.DataSourceTransportReadOptions) => {

                        if (!this.franchiseIds || this.franchiseIds == undefined || (this.franchiseIds && this.franchiseIds.length <= 0) && (!this.selectedSyncEmployees || (this.selectedSyncEmployees && this.selectedSyncEmployees.length <= 0))) {
                            options.success([]);
                        }
                        else {
                            this.franchiseOrchestrationsApiResources.GetSyncEmployeesByFranchiseIds(this.franchiseIds)
                                .then((result) => {

                                    let employees = result.data;
                                    for (let employee of employees) {
                                        employee[SyncEmployeesByFranchisesMultiSelectorComponentController.DisplayProperty] = this.$interpolate(this.displayTextTemplate)(employee);
                                    }
                                    if (this.sort) {
                                        employees = employees.sort(this.sort);
                                    }

                                    options.success(employees);
                                })
                                .catch(err => console.log('err'));
                        }
                    }
                }
            }),
            dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) => {
                this.syncEmployeesByFranchisesMultiSelect = e.sender;
            }
        };
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof(this.franchiseIds)] && this.franchiseIds && this.franchiseIds != null && this.franchiseIds.length > 0) {
            if (!this.syncEmployeesByFranchisesMultiSelectOptions)
                this.SetDropDownOptions();
            else {
                this.syncEmployeesByFranchisesMultiSelect.dataSource.read();
            }

        }
        else {
            if (this.syncEmployeesByFranchisesMultiSelectOptions && this.syncEmployeesByFranchisesMultiSelectOptions.dataSource) {
                this.syncEmployeesByFranchisesMultiSelectOptions.dataSource.data([]);
            }
        }
    }

    ClearAll() {
        this.syncEmployeesByFranchisesMultiSelect.value([]);
        this.syncEmployeesByFranchisesMultiSelect.trigger("change");
    }

    SelectAll() {
        let allEmployeeList: number[] = this.syncEmployeesByFranchisesMultiSelect.dataSource.data().map((x, i) => x[nameof<SyncEmployeesByFranchisesMultiSelectorDataModel>(o => o.syncEmployeeId)]);
        this.syncEmployeesByFranchisesMultiSelect.value(allEmployeeList);
        this.syncEmployeesByFranchisesMultiSelect.trigger("change");
    }

    static BindComponent(app: ng.IModule) {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.syncEmployeesByFranchisesMultiSelector);

        app.component(componentName, {
            bindings: {
                [nameof<SyncEmployeesByFranchisesMultiSelectorComponentController>(o => o.franchiseIds)]: "<",
                [nameof<SyncEmployeesByFranchisesMultiSelectorComponentController>(o => o.select)]: "<",
                [nameof<SyncEmployeesByFranchisesMultiSelectorComponentController>(o => o.displayTextTemplate)]: "<",
                [nameof<SyncEmployeesByFranchisesMultiSelectorComponentController>(o => o.sort)]: "<",
                [nameof<SyncEmployeesByFranchisesMultiSelectorComponentController>(o => o.selectedSyncEmployees)]: "=",
                [nameof<SyncEmployeesByFranchisesMultiSelectorComponentController>(o => o.enableSelectAll)]: "<",

            },
            controller: SyncEmployeesByFranchisesMultiSelectorComponentController,
            templateUrl: `/Templates/Common/SyncEmployeesByFranchisesMultiSelector.html`
        });
    }
}