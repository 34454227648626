import { MomentUtil } from "Utility/MomentUtil";
import { ZeeMailApiResources } from "Services/Resources/ZeeMailApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { ZeeMailEntities, ZeeMailResources } from "Interfaces/FranForce/ZeeMail/ZeeMailResources";


export class ZeeMailFranchiseEmailsComponentController implements ng.IController
{
    franchiseId: number;
    onSave: (params: { type: string, data: any }) => void;

    emailsGrid: kendo.ui.Grid;
    includedCount: number;
    purchasedCount: number;
    emailDba: string;
    isAdmin: boolean;
    isFranchisee: boolean;
    isLoading: boolean;
    isZWInternalUser: boolean;

    accountId: number;
    actionTypeId: number;
    createWindow: kendo.ui.Window;
    statusChangeWindow: kendo.ui.Window;
    requestChangeWindow: kendo.ui.Window;
    editDbaWindow: kendo.ui.Window;
    editAccountWindow: kendo.ui.Window;
    addEmailRequestWindow: kendo.ui.Window;
    childComponentRef: any; // ✅ Store child component reference

    static $inject = [
        'coreApiResources',
        'zeeMailApiResources',
        'identityManager',
        'apiConfig',
        '$timeout',
        '$q'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private zeeMailApiResources: ZeeMailApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService
    )
    {
    }

    $onInit()
    {

    }

    $postLink()
    {
        this.InitGridOptions();
        this.InitWindows();
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.franchiseId)])
        {
            if (this.emailsGrid) {
                this.emailsGrid.dataSource.read()
                    .then(() =>
                    {
                        this.emailsGrid.refresh();
                    });
            }

            if (this.franchiseId)
            {
                this.zeeMailApiResources.ZeeMailFranchiseQuery({
                    $filter: `${nameof<ZeeMailEntities.ZeeMailFranchiseExtension>(o => o.FranchiseId)} eq ${this.franchiseId}`
                })
                    .then((franchisesResponse) =>
                    {
                        if (franchisesResponse.data.length) {
                            this.emailDba = franchisesResponse.data[0].ZeeMailDBA;
                        }
                    })
            }
        }
    }

    InitWindows()
    {
        let windowOptions: kendo.ui.WindowOptions = {
            modal: true
        };
        this.createWindow.setOptions(windowOptions);
        this.statusChangeWindow.setOptions(windowOptions);
        this.editDbaWindow.setOptions(windowOptions);
        this.editAccountWindow.setOptions(windowOptions);
        this.requestChangeWindow.setOptions(windowOptions);
        this.isZWInternalUser = true;
    }

    InitGridOptions()
    {
        let userInfoPromise = this.identityManager.GetLoggedInUserInfo()
            .then((userInfoResponse) =>
            {
                this.isAdmin = this.identityManager.UserHasRole(userInfoResponse.data, [
                    this.apiConfig.FranForceConstants.RoleConstants.AdminRole,
                    this.apiConfig.FranForceConstants.RoleConstants.ZorWareZeeMailAdminRole
                ]);

                this.isFranchisee = this.identityManager.UserHasRole(userInfoResponse.data, [
                    this.apiConfig.FranForceConstants.RoleConstants.AdminRole,
                    this.apiConfig.FranForceConstants.RoleConstants.FranchiseeRole
                ]);

                this.isZWInternalUser = this.identityManager.UserHasRole(userInfoResponse.data, [
                    this.apiConfig.FranForceConstants.RoleConstants.ZWInternal
                ]);
            });

        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof<ZeeMailResources.IZorWareZeeMailAccount>(o => o.Description),
                title: "Description",
            }, {
                field: "Email",
                title: "Email",
            }, {
                field: "ReplyToEmail",
                title: "Reply To",
            }, {
                field: nameof.full<ZeeMailResources.IZorWareZeeMailAccount>(o => o.ZorWareZeeMailType.TypeName),
                title: "Type",
            }, {
                field: nameof.full<ZeeMailResources.IZorWareZeeMailAccount>(o => o.ZorWareZeeMailStatusType.TypeName),
                title: "Status",
            }, {
                field: nameof<ZeeMailResources.IZorWareZeeMailAccount>(o => o.EmailCreatedDateTime),
                title: "Activation Date",
                format: "{0: yyyy-MM-dd}"
            },
        ];

        let datasource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof<ZeeMailResources.IZorWareZeeMailAccount>(o => o.ZorWareZeeMailAccountID),
                    fields: {
                        Description: {
                            type: "string"
                        },
                        Email: {
                            type: "string"
                        },
                        ReplyToEmail: {
                            type: "string"
                        },
                        "ZorWareZeeMailType.TypeName": {
                            type: "string"
                        },
                        "ZorWareZeeMailStatusType.TypeName": {
                            type: "string"
                        },
                        EmailCreatedDateTime: {
                            type: "date"
                        }
                    }
                }
            },
            pageSize: 20,
            transport: {
                read: (options) =>
                {
                    if (!this.franchiseId) {
                        options.success([]);
                        return;
                    }

                    this.isLoading = true;

                    let params: ODataQueryParameters = {
                        $filter: `${nameof<ZeeMailResources.IZorWareZeeMailAccount>(o => o.FranchiseId)} eq ${this.franchiseId}`,
                        $expand: `${nameof<ZeeMailResources.IZorWareZeeMailAccount>(o => o.Franchise)}($select=${nameof<ZeeMailResources.IZorWareZeeMailAccount>(o => o.Franchise.ZeeMailDBA)}),` +
                            `${nameof<ZeeMailResources.IZorWareZeeMailAccount>(o => o.ZorWareZeeMailType)}($select=${nameof<ZeeMailResources.IZorWareZeeMailAccount>(o => o.ZorWareZeeMailType.TypeName)}),` +
                            `${nameof<ZeeMailResources.IZorWareZeeMailAccount>(o => o.ZorWareZeeMailStatusType)}($select=${nameof<ZeeMailResources.IZorWareZeeMailAccount>(o => o.ZorWareZeeMailStatusType.TypeName)}),` +
                            `${nameof<ZeeMailResources.IZorWareZeeMailAccount>(o => o.ZorWareZeeMailDomain)}($select=${nameof<ZeeMailResources.IZorWareZeeMailAccount>(o => o.ZorWareZeeMailDomain.DomainName)})`
                    }

                    userInfoPromise
                        .then(() =>
                        {
                            return this.zeeMailApiResources.ZorWareZeeMailAccountApi.query(params).$promise
                                .then((accounts) =>
                                {
                                    this.includedCount = 0;
                                    this.purchasedCount = 0;

                                    for (let acc of accounts) {
                                        (<any>acc).Email = `${acc.Franchise.ZeeMailDBA}${(acc.AccountName ? "." : "")}${acc.AccountName}@${acc.ZorWareZeeMailDomain.DomainName}`;
                                        (<any>acc).ReplyToEmail = `${acc.ReplyToAccountName}@${acc.ZorWareZeeMailDomain.DomainName}`;

                                        if (acc.ZorWareZeeMailTypeID === 1) {
                                            this.includedCount++;
                                        }
                                        if (acc.ZorWareZeeMailTypeID === 2) {
                                            this.purchasedCount++;
                                        }

                                        acc.EmailCreatedDateTime = MomentUtil.EnsureUtcTime(acc.EmailCreatedDateTime);
                                    }

                                    options.success(accounts);
                                })

                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        })
                        .finally(() =>
                        {
                            this.isLoading = false;
                        });
                }
            }
        });

        let options = <kendo.ui.GridOptions>{
            columns: columns,
            dataSource: datasource,
            resizable: true,
            scrollable: true,
            sortable: true,
            filterable: true,
            pageable: {
                pageSize: 20
            }
        }

        this.emailsGrid.setOptions(options);
    }

    EditDbaPopup()
    {
        this.editDbaWindow.open();
        this.$timeout(() =>
        {
            this.editDbaWindow.center();
        });
    }

    AddEmailPopup()
    {
        if (!this.isZWInternalUser) {
            this.addEmailRequestWindow.open();
            this.$timeout(() => {
                this.addEmailRequestWindow.center();
            });
        }
        else {
            this.createWindow.open();
            this.$timeout(() => {
                this.createWindow.center();
            });
        }
    }

    ShowEditAccountPopup(accountId: number)
    {
        this.accountId = accountId;

        this.editAccountWindow.open();
        this.$timeout(() =>
        {
            this.editAccountWindow.center();
        });
    }

    ShowRequestChangePopup(accountId: number)
    {
        this.accountId = accountId;

        this.requestChangeWindow.open();
        this.$timeout(() =>
        {
            this.requestChangeWindow.center();
        });
    }

    ShowStatusPopup(accountId: number, actionTypeId: number)
    {
        this.accountId = accountId;
        this.actionTypeId = actionTypeId;

        this.statusChangeWindow.open();
        this.$timeout(() =>
        {
            this.statusChangeWindow.center();
        });
    }
    Cancel() {
        this.addEmailRequestWindow.close();
    }
    onClose() {
        if (this.childComponentRef) {
            this.childComponentRef.ResetForm(); // ✅ Reset the child form
        }
       
    }
    registerChild({ ref }) {
        console.log("🔹 Registering child component:", ref);
        this.childComponentRef = ref; // ✅ Store reference
    }
    ResetGrid()
    {
        return this.$q.when(this.emailsGrid.dataSource.read())
            .then(() =>
            {
                this.emailsGrid.refresh();
            });
    }

    OnWindowSave(window: kendo.ui.Window, type: string, data: any)
    {
        if (type == 'zee-mail-dba-change') {
            this.emailDba = data.emailDba;
        }

        window.close();
        this.ResetGrid();
    }



    static BindComponent(app: ng.IModule)
    {
        app.component("zeeMailFranchiseEmails", {
            bindings: {
                [nameof<ZeeMailFranchiseEmailsComponentController>(o => o.franchiseId)]: "<",
                [nameof<ZeeMailFranchiseEmailsComponentController>(o => o.onSave)]: "&?"
            },
            controller: ZeeMailFranchiseEmailsComponentController,
            templateUrl: "/Templates/ZeeMail/ZeeMailFranchiseEmails.html"
        });
    }
}


