import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RmsStateService } from "Services/State/RmsState";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class fddRoyaltiesFeeScalesComponentController implements ng.IController 
{
    disclosureDocumentId: number;
    readOnly: boolean;
    isDynamicFees: boolean;
    onLoaded: (params: { self: fddRoyaltiesFeeScalesComponentController }) => void;
    
    disclosureDocument: RpmEntities.DisclosureDocumentViewModel;
    royaltyPeriodFrequencies: RpmEntities.RoyaltyPeriodFrequencyViewModel[];
    royaltyFindScaleBasedOnTypes: RpmEntities.usp_RoyaltyFindScaleBasedOnTypes_GetByFindScaleBasedOnId_Result[];

    formController: angular.IFormController;

    royaltyClicked: number;

    static $inject = [
        'rpmUiApiResources',
        'identityManager',
        '$q',
        '$timeout',
        'rmsState'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private identityManager: IdentityManager,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService,
        private rmsState: RmsStateService
    )
    {

    }

    $onInit()
    {
        this.Load().then(() => {
            if (this.onLoaded)
                this.onLoaded({ self: this });
        });
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledFdd').val() === 'True';
    }

    $onChanges(changes: ng.IOnChangesObject) {
        if (changes[nameof(this.royaltyClicked)]) {
            this.Load().then(() => {
                if (this.onLoaded)
                    this.onLoaded({ self: this });
            });
        }
    }

    Load()
    {
        let promises: ng.IPromise<any>[] = [];

        promises.push(this.rmsState.royaltyDisclosureDocumentStateHandler.Load(this.disclosureDocumentId, this.isDynamicFees)
            .then((doc) => {
                this.disclosureDocument = doc;
            }));
        
        promises.push(this.rmsState.royaltyPeriodFrequenciesStateHandler.Load()
            .then((frequencies) => { this.royaltyPeriodFrequencies = frequencies; }));

        promises.push(this.rpmUiApiResources.GetAllRoyaltyFindScaleBasedOnTypes()
            .then((docResponse) => { this.royaltyFindScaleBasedOnTypes = docResponse.data; }));

        return this.$q.all(promises);
    }

    ResetChanges()
    {
        return this.Load();
    }

    async SaveChanges()
    {
        let royaltyPeriodFrequencyId = this.disclosureDocument.RoyaltyPeriodFrequencyId;
        let adFeeFrequencyId = this.disclosureDocument.AdFeeFrequencyId
        let tAFSFeeFrequencyId = this.disclosureDocument.TAFSFeeFrequencyId
        let technologyFeeFrequencyId = this.disclosureDocument.TechnologyFeeFrequencyId
        let calculateAdFee = this.disclosureDocument.CalculateAdFee
        let calculateRoyaltyFee = this.disclosureDocument.CalculateRoyaltyFee
        let calculateTAFSFee = this.disclosureDocument.CalculateTAFSFee
        let calculateTechnologyFee = this.disclosureDocument.CalculateTechnologyFee

        this.disclosureDocument = await this.rmsState.royaltyDisclosureDocumentStateHandler.Get(this.disclosureDocumentId, this.isDynamicFees);

        this.disclosureDocument.RoyaltyPeriodFrequencyId = royaltyPeriodFrequencyId;
        this.disclosureDocument.AdFeeFrequencyId = adFeeFrequencyId
        this.disclosureDocument.TAFSFeeFrequencyId = tAFSFeeFrequencyId
        this.disclosureDocument.TechnologyFeeFrequencyId = technologyFeeFrequencyId
        this.disclosureDocument.CalculateAdFee = calculateAdFee
        this.disclosureDocument.CalculateRoyaltyFee = calculateRoyaltyFee
        this.disclosureDocument.CalculateTAFSFee = calculateTAFSFee
        this.disclosureDocument.CalculateTechnologyFee = calculateTechnologyFee

        if (this.isDynamicFees) {
            return this.rpmUiApiResources.UpdateDisclosureDocumentDynamic(this.disclosureDocumentId, this.disclosureDocument)
                .then((idResponse) => {
                    this.rmsState.royaltyDisclosureDocumentStateHandler.Clear(this.disclosureDocumentId);

                    this.$timeout(() => {
                        this.formController.$setPristine();
                    }, 1000);

                    return this.disclosureDocument;
                });
        }

        return this.rpmUiApiResources.UpdateDisclosureDocument(this.disclosureDocumentId, this.disclosureDocument)
            .then((idResponse) =>
            {
                this.rmsState.royaltyDisclosureDocumentStateHandler.Clear(this.disclosureDocumentId);

                this.$timeout(() =>
                {
                    this.formController.$setPristine();
                }, 1000);

                return this.disclosureDocument;
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("fddRoyaltiesFeeScales", {
            bindings: {
                disclosureDocumentId: "<",
                readOnly: "<",
                [nameof<fddRoyaltiesFeeScalesComponentController>(o => o.royaltyClicked)]: "<",
                onLoaded: "&?"
            },
            templateUrl: '/Templates/FDDManagement/fddRoyalties/FeeScales.html',
            controller: fddRoyaltiesFeeScalesComponentController
        });
    }
}
