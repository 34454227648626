import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltySystemsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetAllRoyaltySystems()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltySystems"
        };

        return this.$http<RpmEntities.RoyaltySystemViewModel[]>(config);
    }
}
