import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { AngularUtil } from "Utility/AngularUtil";
import * as _ from "underscore"
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class ConceptDefaultFeeTypesComponentController implements ng.IController
{
    //---- Bindings ----
    conceptId: number;
    //------------------

    newFeeType: CoreResources.IConceptFeeType;
    conceptFeeTypes: CoreResources.IConceptFeeType[];
    
    static $inject = [
        "coreApiResources",
        "$q",
        "$log"
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService)
    {

    }

    $onInit()
    {
        this.coreApiResources.ConceptFeeTypeApi.query({ $filter: "ConceptId eq " + this.conceptId }).$promise.then(
            (conceptFeeTypes: CoreResources.IConceptFeeType[]) =>
            {
                this.conceptFeeTypes = conceptFeeTypes;
            }, AngularUtil.GetErrorPromiseLogCallback(this.$log, this.$q));
        
        this.RefreshNewFeeType();
    }
    
    RefreshNewFeeType() {
        this.newFeeType = new this.coreApiResources.ConceptFeeTypeApi();
        this.newFeeType.DefaultIsWaived = true;
        this.newFeeType.DefaultAfterHoursIsWaived = true;
        this.newFeeType.ConceptId = this.conceptId;
    }

    CreateFeeType(feeType: CoreResources.IConceptFeeType): ng.IPromise<any>
    {
        if (!feeType.DefaultFee || feeType.DefaultFee < 0 || !feeType.DefaultAfterHoursFee || feeType.DefaultAfterHoursFee < 0 || !feeType.Name) {
            let reason = "Name and Fee amounts are required.";
            alert(reason);
            return this.$q.reject(reason);
        }

        if (!confirm("You can't change the fees for all the profiles that get this new fee type when created. Are you sure you want to create this fee with the current fee defaults?"))
            return this.$q.reject("Unsure of default fees.");

        return feeType.$save().then(
            () => {
                this.conceptFeeTypes.push(feeType);
                this.RefreshNewFeeType();
            },
            AngularUtil.GetErrorPromiseLogCallback(this.$log, this.$q));
    }

    SaveFeeType(feeType: CoreResources.IConceptFeeType): ng.IPromise<any>
    {
        if (!feeType.DefaultFee || feeType.DefaultFee < 0 || !feeType.DefaultAfterHoursFee || feeType.DefaultAfterHoursFee < 0 || !feeType.Name) {
            let reason = "Name and Fee amounts are required.";
            alert(reason);
            return this.$q.reject(reason);
        }

        return this.coreApiResources.ConceptFeeTypeApi.get({ id: feeType.ConceptFeeTypeId }).$promise.then(
            (apiFeeType: CoreResources.IConceptFeeType) => {
                apiFeeType.DefaultFee = feeType.DefaultFee;
                apiFeeType.DefaultIsWaived = feeType.DefaultIsWaived;
                apiFeeType.DefaultAfterHoursFee = feeType.DefaultAfterHoursFee;
                apiFeeType.DefaultAfterHoursIsWaived = feeType.DefaultAfterHoursIsWaived;
                apiFeeType.Name = feeType.Name;
                return apiFeeType.$update({ id: feeType.ConceptFeeTypeId });
            },
            AngularUtil.GetErrorPromiseLogCallback(this.$log, this.$q));
    }

    DeleteFeeType(feeType: CoreResources.IConceptFeeType): ng.IPromise<any>
    {
        if (confirm("Are you sure you want to delete fee type of '" + feeType.Name + "'? This will delete the fee for all franchisees as well.")) {
            return this.coreApiResources.ConceptFeeTypeApi.delete({ id: feeType.ConceptFeeTypeId }).$promise.then(
                () => {
                    this.conceptFeeTypes = _.filter(this.conceptFeeTypes, (ft) => { return ft.ConceptFeeTypeId != feeType.ConceptFeeTypeId });
                });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("conceptDefaultFeeTypes", {
            bindings: {
                conceptId: "<"
            },
            controller: ConceptDefaultFeeTypesComponentController,
            templateUrl: "/Templates/Concepts/ConceptDefaultFeeTypes.html",
        });
    }
}

