import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import 
{
    FranchiseProfileEmployeeBioDto,
    FranchiseProfileEmployeeBioSearchDto,
    WebImageClient
}
    from '@nbly/franchise-employee-orchestrations-clients';

import
{
    FranchiseProfileEmployeeBioDto as FranchiseProfileGroupEmployeeBioDto,
    FranchiseProfileEmployeeBioClient,FranchiseProfileEmployeeBioDetailClient,
} from '@nbly/franchise-employee-group-clients';


import { WebImageApiResources } from "Services/Resources/WebImageApiResources";
import { AngularUtil } from "Utility/AngularUtil";
import { ApiConfig } from "AppConfig/ApiConfig";
import * as CropperTypes from 'cropperjs';
import {Helpers} from "Utility/Helpers"
import Cropper from 'cropperjs/dist/cropper.esm';
const Compressor = require('compressorjs');


import Croppie = require("croppie");

export class EmployeeBiosImageComponent implements ng.IController
{
    //---- Bindings ----
    franchiseId: number;
    conceptCode: string;
    franchiseProfileEmployeeBioId: number;
    franchiseProfileEmployeeBioDetailId: number;
    employeeBioDetail: FranchiseProfileEmployeeBioDto;
    employeeBio: FranchiseProfileEmployeeBioSearchDto;
    onApiSave: () => void;
    //------------------

    isLoading: boolean;
    selectedLangauges: {};
    languageArr: [number];
    rawFile: kendo.ui.UploadSelectEvent;
    bioImageUrl: string;
    saveBioImageUrl: string;
    franchiseProfileGroupEmployeeBioDto: FranchiseProfileGroupEmployeeBioDto;
    kendoUpload: kendo.ui.Upload;
    defaultKendoUploadOptions: kendo.ui.UploadOptions;
    allowedUploadExtensions: string[];
    croppieOptions: Croppie.CroppieOptions;
    croppie: Croppie;
    showCroppie: boolean;


    //Clients
    employeeOrchestrationsClient: AxiosInstance;
    employeeGroupClient: AxiosInstance;
    webImageClient: WebImageClient;
    franchiseProfileEmployeeBioClient: FranchiseProfileEmployeeBioClient;
    franchiseProfileEmployeeBioDetailClient:FranchiseProfileEmployeeBioDetailClient;

     //Images
     squareImageURL:any;
     circleImageURL:any;
     inSquareImageEditMode:boolean;
     inCircleImageEditMode:boolean;
     squareImageFile: any;
     circleImageFile: any;
     isImageUploadSquare:boolean=false;
     isImageUploadCircle:boolean=false;

      //Cropper Tool
    squareCropperOption: CropperTypes.default.Options
    squareCropper: CropperTypes.default;
    circleCropper: CropperTypes.default;
    cricleCropperOptions: CropperTypes.default.Options;
    imageFile:any;

    //Compressor
    Compressor: any;



    static $inject = [
        'webImageApiResources',
        '$timeout',
        '$scope',
        "apiConfig"
    ];

    constructor(private webImageApiResources: WebImageApiResources,
        private $timeout: ng.ITimeoutService, private $scope: any,
        private apiConfig: ApiConfig)
    {
        this.showCroppie = true;
    }

    $onInit()
    {
        this.employeeOrchestrationsClient = FranForceAxiosClientBuilder.BuildEmployeeOrchestrationsBaseClient();
        this.employeeGroupClient = FranForceAxiosClientBuilder.BuildEmployeeGroupBaseClient();
        this.webImageClient = new WebImageClient("", this.employeeOrchestrationsClient);
        this.franchiseProfileEmployeeBioClient = new FranchiseProfileEmployeeBioClient("", this.employeeGroupClient);
        this.franchiseProfileEmployeeBioDetailClient= new FranchiseProfileEmployeeBioDetailClient("",this.employeeGroupClient);
        this.InitBio();
        this.InitUploadComponent();
      
    }

    croppieSetZoom(){
        let crSlider = angular.element(".cr-slider");
        let minZoom=crSlider.attr('min');
         this.croppie.setZoom(parseInt(minZoom));
          
    }

    dataURLtoFile(dataurl, filename)
    {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--)
        {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    getBase64FromUrl = async (url) =>
    {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) =>
        {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function ()
            {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }

    async uploadImage()
    {
        let base64Image;
        let dataUrlToFile;

        await this.getBase64FromUrl(this.bioImageUrl).then(async (response) =>
        {
            base64Image = response;
            dataUrlToFile = await this.dataURLtoFile(base64Image, 'bioImg.png');
        });

        await this.webImageClient.upload(2, null, null, this.franchiseId, { data: dataUrlToFile, fileName: 'bioImg.png' })
            .then((response) =>
            {
                this.OnUploadComplete(response.result);
                this.$scope().digest();
            });

    }


    InitUploadComponent()
    {
        this.allowedUploadExtensions = [".jpg", ".png", ".webp", ".jpeg"];
        this.defaultKendoUploadOptions = {
            showFileList: true,
            multiple: false,
            async: {
                autoUpload: false,
            },
            validation: {
                allowedExtensions: this.allowedUploadExtensions
            },
            localization: {
                select: 'Select Photo',
        
            },
            select: this.onSelect
        };
    }

    onSelect = (e) =>
    {
        if (e.files[0].validationErrors?.length)
        {
            console.log('error occured')
        } else
        {
            this.isImageUploadCircle=true;
            this.isImageUploadSquare=true;
            this.setImage(e);
        }
    }

    async FindOptimalQuality(file)
    {
        let result;
        for (let i = 10; i > 0; i--)
        {
            result = await new Promise((resolve, reject) =>
            {
                new Compressor(file, {
                    quality: i / 10,
                    maxWidth: 1000,
                    convertSize: 500000,
                    success: resolve,
                    error: reject,
                });
            });

            if (result.size < 500000)
            {
                console.log('break from the loop');
                break;
            }
        }
        return result;
    }

    async compress(file)
    {
        try
        {
            let response = await this.FindOptimalQuality(file);
            return response;
        } catch (err)
        {

        }

    }

    async setImage(e: kendo.ui.UploadSelectEvent)
    {

        const file = e.files[0].rawFile;
        await this.compress(file).then(res =>
        {
            this.rawFile = e.files[0].rawFile; 
            this.imageFile = res;
            let reader = new FileReader();
            reader.onload = (event) =>
            {
                this.squareImageURL = event.target.result;
                this.circleImageURL = angular.copy(this.squareImageURL);

                this.$scope.$digest();
                this.$timeout(() =>
                {
                   
                    this.InitCircularEditTool();
                    this.InitSquareEditTool();
                });
            };
            reader.readAsDataURL(res);
         

        })
        
    }


    InitCircularEditTool()
    {

        this.inCircleImageEditMode = !this.inCircleImageEditMode;
        if (this.inCircleImageEditMode)
        {
            let contents = $('#circleImageContainer')[0] as HTMLImageElement;
            this.cricleCropperOptions = {
                dragMode: 'move',
            /*     scalable: true,
                rotatable: true, */
                aspectRatio: 1,
              /*   center: true,
                autoCrop: true, */
                checkCrossOrigin: false,
                checkOrientation: false,
                viewMode: 2,
         
                crop(event)
                {

                },
            }
            this.circleCropper = new Cropper(contents, this.cricleCropperOptions);
        } else
        {
            this.circleCropper.destroy();
            this.circleCropper = null;
        } 


    }

    InitSquareEditTool()
    {
        this.inSquareImageEditMode = !this.inSquareImageEditMode;
        if (this.inSquareImageEditMode)
        {
            if (this.squareCropper)
            {
                this.squareCropper.destroy();
                this.squareCropper = null;
            }

            let contents = $('#squareImageContainer')[0] as HTMLImageElement;
            this.squareCropperOption = {
                dragMode: 'move',
                checkCrossOrigin: false,
                checkOrientation: false,
                aspectRatio: 1,
                scalable: true,
                rotatable: true,
                center: true,
                autoCrop: true,
                viewMode: 2,
                crop(event)
                {

                },
            }
            this.squareCropper = new Cropper(contents, this.squareCropperOption);
        } else
        {
            this.squareCropper.destroy();
            this.squareCropper = null;
        } 

    }


    async masterSave(){
        let employeeBioDetailObject: FranchiseProfileEmployeeBioDto;
        employeeBioDetailObject=angular.copy(this.employeeBioDetail);

        
        if(!Helpers.isUrl(this.squareImageURL)){
            [employeeBioDetailObject.franchiseProfileEmployeeBioDetail.photoExtension, employeeBioDetailObject.franchiseProfileEmployeeBioDetail.squarePhotoUrl] = this.GenerateBase64WithoutMetaInfo(this.squareImageURL);
         
        }

        if(!Helpers.isUrl(this.circleImageURL)){
            [employeeBioDetailObject.franchiseProfileEmployeeBioDetail.photoExtension, employeeBioDetailObject.franchiseProfileEmployeeBioDetail.circularPhotoUrl] = this.GenerateBase64WithoutMetaInfo(this.circleImageURL);

        }

        if(!Helpers.isUrl(employeeBioDetailObject.franchiseProfileEmployeeBioDetail.circularPhotoUrl)){
            employeeBioDetailObject.franchiseProfileEmployeeBioDetail.photoExtension = employeeBioDetailObject.franchiseProfileEmployeeBioDetail.photoExtension.replace('data:image/', '').replace(';base64', '');
        }

        if(!Helpers.isUrl(employeeBioDetailObject.franchiseProfileEmployeeBioDetail.squarePhotoUrl)){
            employeeBioDetailObject.franchiseProfileEmployeeBioDetail.photoExtension = employeeBioDetailObject.franchiseProfileEmployeeBioDetail.photoExtension.replace('data:image/', '').replace(';base64', '');
        }

        employeeBioDetailObject.franchiseProfileEmployeeBioDetail.genderTypeId=1;
        employeeBioDetailObject.franchiseProfileEmployeeBioDetail.maritalStatus=false;
        employeeBioDetailObject.franchiseProfileEmployeeBioDetail.franchiseProfileEmployeeBioVariableId = employeeBioDetailObject.franchiseProfileEmployeeBioDetail?.franchiseProfileEmployeeBioVariableId ?
         (parseInt(employeeBioDetailObject.franchiseProfileEmployeeBioDetail?.franchiseProfileEmployeeBioVariableId.toString())): null;

        if(!Helpers.isUrl(this.circleImageURL)){
            let dataUrlToFile = await this.dataURLtoFile(this.circleImageURL, 'bioImg.png');
            await this.webImageClient.upload(2, null, null, this.franchiseId, { data: dataUrlToFile, fileName: 'bioImg.png' })
            .then((response) =>
            {
                this.OnUploadComplete(response.result);
            });
    
        }
    
         await this.franchiseProfileEmployeeBioDetailClient.upload
        (employeeBioDetailObject.franchiseProfileEmployeeBioDetail).then((res)=>{
     
            this.employeeBioDetail.franchiseProfileEmployeeBioDetail.squarePhotoUrl=res.result.squarePhotoUrl;
            this.employeeBioDetail.franchiseProfileEmployeeBioDetail.circularPhotoUrl=res.result.circularPhotoUrl;
            this.employeeBioDetail.franchiseProfileEmployeeBioDetail.photoExtension=res.result.photoExtension;
            this.squareImageURL=res.result.squarePhotoUrl;
        })
 

        this.inCircleImageEditMode = false;
    }
   async saveSquareImage(){
        try
        {
            this.squareImageURL = this.squareCropper.getCroppedCanvas({ imageSmoothingEnabled: false, imageSmoothingQuality: "high" }).toDataURL();
          /*    [this.employeeBioDetail.franchiseProfileEmployeeBioDetail.photoExtension, this.employeeBioDetail.franchiseProfileEmployeeBioDetail.squarePhotoUrl] = this.GenerateBase64WithoutMetaInfo(this.squareImageURL);
          */   this.squareCropper.destroy();
       
            this.isImageUploadSquare=false;
            
            this.inSquareImageEditMode = false;

        } catch (err)
        {
            console.log('error occured while saving image',err);
        }  
    }

    
  async  saveCircularImage(){
    try
  {
      let croppedCanvas = this.circleCropper.getCroppedCanvas({ imageSmoothingEnabled: false, imageSmoothingQuality: "high" });
      let  roundedCanvas = this.getRoundedCanvas(croppedCanvas);
      this.circleImageURL = roundedCanvas.toDataURL(/* imageType */);
    
      this.isImageUploadCircle=false;
      this.inCircleImageEditMode = false;    
      this.circleCropper.destroy();
   
  } catch (err)
  {
      console.log('error occured while saving image',err);
  }  
}

    GenerateBase64WithoutMetaInfo(base64URL: string)
    {
        return base64URL.split(',');
    }

     getRoundedCanvas(sourceCanvas) {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        var width = sourceCanvas.width;
        var height = sourceCanvas.height;
  
        canvas.width = width;
        canvas.height = height;
        context.imageSmoothingEnabled = true;
        context.drawImage(sourceCanvas, 0, 0, width, height);
        context.globalCompositeOperation = 'destination-in';
        context.beginPath();
        context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
        context.fill();
        return canvas;
      }



    InitBio()
    {
        if (!this.employeeBioDetail)
            return;

        if (this.employeeBioDetail.webImageId)
        {
            this.SetBioImageUrl(this.employeeBioDetail.webImageId,true);
        }
        const franchiseBioWebImageTypeId = 2;
        this.saveBioImageUrl = this.webImageApiResources.EmployeeBioImageUploadUrl({
            WebImageTypeId: franchiseBioWebImageTypeId,
            FranchiseId: this.employeeBioDetail.franchiseId

        });
        if(this.employeeBioDetail && this.employeeBioDetail.franchiseProfileEmployeeBioDetail && this.employeeBioDetail.franchiseProfileEmployeeBioDetail.circularPhotoUrl){
         /*    this.circleImageURL=this.employeeBioDetail.franchiseProfileEmployeeBioDetail.circularPhotoUrl; */
        }

        if(this.employeeBioDetail &&  this.employeeBioDetail.franchiseProfileEmployeeBioDetail && this.employeeBioDetail.franchiseProfileEmployeeBioDetail.squarePhotoUrl){
            this.squareImageURL=this.employeeBioDetail.franchiseProfileEmployeeBioDetail.squarePhotoUrl;
        }
    }

    private async SetBioImageUrl(webImageId: number,firstLoad)
    {
        if (!webImageId)
            return null;

        this.bioImageUrl = (
            webImageId ?
                this.webImageApiResources.EmployeeBioRenderUrl(webImageId) :
                null
        );
        this.circleImageURL=this.bioImageUrl;

    }

    OnUploadComplete(createdId)
    {
        let webImageId = parseInt(createdId);
        this.franchiseProfileGroupEmployeeBioDto = <FranchiseProfileGroupEmployeeBioDto>{};

        this.franchiseProfileGroupEmployeeBioDto = {
            franchiseProfileEmployeeBioId: this.employeeBioDetail.franchiseProfileEmployeeBioId,
            franchiseId: this.employeeBioDetail.franchiseId,
            aspNetUserId: this.employeeBioDetail.aspNetUserId,
            createdDateTime: new Date().toISOString(),
            createdUser: this.employeeBioDetail.createdUser,
            updatedDateTime: null,
            updatedUser: null,
            deletedDateTime: null,
            displayName: this.employeeBioDetail.displayName,
            description: this.employeeBioDetail.description,
            webImageId: webImageId,
            webLocationIds:this.employeeBioDetail.webLocationIds
        }

        let promise = this.franchiseProfileEmployeeBioClient.franchiseProfileEmployeeBios2(
            this.employeeBioDetail.franchiseProfileEmployeeBioId,
            this.franchiseProfileGroupEmployeeBioDto
        ).then(async (response) =>
        {
            this.employeeBioDetail.webImageId=webImageId;
            if(this.employeeBio){
                this.employeeBio.profilePic = true;
            }
           
            await this.SetBioImageUrl(webImageId,false);
            this.onApiSave();
        }).catch((err)=>{
            console.log('Error Occured while saving')
        })

        AngularUtil.TrackLoadingPromise(promise, this);
    }


    static BindComponent(app: ng.IModule)
    {
        app.component("employeeBiosImage", {
            bindings: {
                franchiseId: "<",
                [nameof<EmployeeBiosImageComponent>(o => o.conceptCode)]: "<",
                [nameof<EmployeeBiosImageComponent>(o => o.franchiseProfileEmployeeBioId)]: "<",
                [nameof<EmployeeBiosImageComponent>(o => o.franchiseProfileEmployeeBioDetailId)]: "<",
                [nameof<EmployeeBiosImageComponent>(o => o.employeeBioDetail)]: "=",
                [nameof<EmployeeBiosImageComponent>(o => o.employeeBio)]: "=",
                onApiSave: "&",
            },
            controller: EmployeeBiosImageComponent,
            templateUrl: "/Templates/EmployeeBios/EmployeeBiosImage.html",
        });
    }
}

