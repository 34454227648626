import { KendoUtil } from "Utility/KendoUtil";

import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { IdentityManager } from "Services/Resources/IdentityManager";
import * as _ from "underscore"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class fddRoyaltiesServiceCategoriesComponentController implements ng.IController {

    disclosureDocumentRatePlanItemId: number;
    franchisorId: number;
    readOnly: boolean;
    onLoaded: (params: { self: fddRoyaltiesServiceCategoriesComponentController }) => void;

    isLoading: boolean; 
    royaltyServiceCategories: RpmEntities.RoyaltyServiceCategoryViewModel[];
    royaltyServiceCategoryTypes: RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result[];
    serviceCategoryGridOptions: kendo.ui.GridOptions;
    serviceCategoryGrid: kendo.ui.Grid;
    rollInRateGrids: { [ratePlanDetailId: string]: kendo.ui.Grid };

    formController: angular.IFormController;
    
    static $inject = [
        'rpmUiApiResources',
        'rpmHelper',
        'identityManager',
        '$q',
        '$timeout'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private identityManager: IdentityManager,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService)
    {

    }

    $onInit()
    {
        this.isLoading = true;
        this.rollInRateGrids = {};
        
        this.InitServiceCategoryGrid();
        if (this.franchisorId)
        {
            this.LoadServiceCategories();
        }
        this.LoadServiceCategoryTypes();
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes.franchisorId && changes.franchisorId.currentValue)
        {
            this.LoadServiceCategories();
        }

        if (changes.disclosureDocumentRatePlanItemId)
        {
            this.$timeout(() => { this.ResetChanges(); }, 0);
        }
    }

    LoadServiceCategories()
    {
        this.isLoading = true;

        return this.rpmUiApiResources.GetRoyaltyServiceCategoriesByFranchisor(this.franchisorId)
            .then((serviceCategoriesResponse) => {
                this.royaltyServiceCategories = serviceCategoriesResponse.data
            })
            .finally(() => {
                this.isLoading = false;
                this.onLoaded({ self: this });
            });
    }

    LoadServiceCategoryTypes()
    {
        return this.rpmUiApiResources.GetAllRoyaltyServiceCategoryTypes()
            .then((typesResponse) =>
            {
                this.royaltyServiceCategoryTypes = typesResponse.data;
            });
    }

    InitServiceCategoryGrid() {
        let scDataSource = new kendo.data.DataSource({
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {
                    if (!this.disclosureDocumentRatePlanItemId)
                    {
                        options.success([]);
                        return;
                    }
                    this.rpmUiApiResources.GetRoyaltyDisclosureDocumentRatePlanItemDetailsByRatePlanItem(this.disclosureDocumentRatePlanItemId)
                        .then((itemDetailsResponse) => {
                            options.success(itemDetailsResponse.data);
                        }, (err) => { options.error(err); })
                },
                create: (options: kendo.data.DataSourceTransportOptions) => {

                    let createModel: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel = options.data;
                    this.rpmUiApiResources.CreateRoyaltyDisclosureDocumentRatePlanItemDetail(createModel)
                        .then((itemDetailResponse) => {
                            createModel.DisclosureDocumentRatePlanItemDetailId = itemDetailResponse.data
                            options.success({ DisclosureDocumentRatePlanItemDetailId: createModel.DisclosureDocumentRatePlanItemDetailId});
                        }, (err) => { options.error(err); });
                },
                update: (options: kendo.data.DataSourceTransportOptions) => {
                    let updateModel: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel = options.data;
                    this.rpmUiApiResources.UpdateRoyaltyDisclosureDocumentRatePlanItemDetail(updateModel.DisclosureDocumentRatePlanItemDetailId, updateModel)
                        .then((idResponse) => {
                            options.success();
                        }, (err) => { options.error(err); });
                },
                destroy: (options: kendo.data.DataSourceTransportOptions) => {
                    let deleteModel: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel = options.data;
                    deleteModel.DeletedDateTime = new Date().toISOString();
                    this.rpmUiApiResources.UpdateRoyaltyDisclosureDocumentRatePlanItemDetail(deleteModel.DisclosureDocumentRatePlanItemDetailId, deleteModel)
                        .then((idResponse) => {
                            options.success();
                        }, (err) => { options.error(err); });
                },
            },
            batch: false,
            pageSize: 20,
            schema: {
                model: {
                    id: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.DisclosureDocumentRatePlanItemDetailId),
                    fields: {
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.DisclosureDocumentRatePlanItemDetailId)]: {
                            type: "number",
                            validation: { required: true },
                            editable: false,
                            defaultValue: 0
                        },
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryId)]: {
                            type: "number",
                            validation: { required: true }
                        },
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryTypeId)]: {
                            type: "number",
                            validation: { required: true }
                        },
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.RoyaltyFeeRateResidential)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.RoyaltyFeeRateCommercial)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.AdFeeRateResidential)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.AdFeeRateCommercial)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.TAFSFeeRate)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.TechnologyFeeRate)]: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        [nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.DeletedDateTime)]: {
                            type: "string",
                            defaultValue: null
                        }
                    }
                }
            },
            filter: {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.DeletedDateTime),
                operator: "isnull"
            }
        });

        let scColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.DisclosureDocumentRatePlanItemDetailId),
                title: "ID",
                hidden: true
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryId),
                title: "Service Category",
                editor: (container, options) => {
                    let input = "<input required name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description),
                        dataValueField: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.RoyaltyServiceCategoryId),
                        template: `<span class="#: 'deleted-' + (${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.DeletedDateTime)} ? 'true icon-error ' : 'false') #">#: ${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description)} # </span>`,
                        valueTemplate: `<span class="#: 'deleted-' + (${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.DeletedDateTime)} ? 'true icon-error ' : 'false') #">#: ${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description)} # </span>`,
                        dataSource: new kendo.data.DataSource({
                            data: this.royaltyServiceCategories,
                            sort: [
                                {
                                    field: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.DeletedDateTime),
                                    dir: "desc",
                                    compare: (a: RpmEntities.RoyaltyServiceCategoryViewModel, b: RpmEntities.RoyaltyServiceCategoryViewModel) =>
                                    {
                                        return (a.DeletedDateTime ? 0 : 1) - (b.DeletedDateTime ? 0 : 1) 
                                    }
                                },
                                {
                                    field: nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description),
                                    dir: "asc"
                                }
                            ]
                        }),
                        change: (e) => {
                            var dataItem = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.royaltyServiceCategories[0];
                            }

                            options.model.set(
                                nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryId),
                                dataItem[nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.RoyaltyServiceCategoryId)]
                            );
                        }
                    });
                },
                template: `{{$ctrl.${nameof(this.GetRoyaltyServiceCategoryById)}(#=${nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryId)}#).${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description)}}}`
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryTypeId),
                title: "Service Category Type",
                editor: (container, options) =>
                {
                    let input = "<input required name='" + options.field + "'/>";
                    angular.element(container).append(input);
                    angular.element(angular.element(container).children()[0]).kendoDropDownList({
                        dataTextField: nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>( o => o.Description),
                        dataValueField: nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.RoyaltyServiceCategoryTypeId),
                        dataSource: {
                            data: this.royaltyServiceCategoryTypes
                        },
                        change: (e) =>
                        {
                            var dataItem = e.sender.dataItem();
                            if (dataItem == null) {
                                dataItem = this.royaltyServiceCategoryTypes[0];
                            }

                            options.model.set(
                                nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryTypeId),
                                dataItem[nameof<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result>(o => o.RoyaltyServiceCategoryTypeId)]
                            );
                        }
                    });
                },
                template: `{{$ctrl.${nameof(this.GetRoyaltyServiceCategoryTypeById)}(#=${nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.RoyaltyServiceCategoryTypeId)}#).Description}}`
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.RoyaltyFeeRateResidential),
                title: "Res Royalty Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.RoyaltyFeeRateCommercial),
                title: "Com Royalty Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.AdFeeRateResidential),
                title: "Res Ad Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.AdFeeRateCommercial),
                title: "Com Ad Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.TAFSFeeRate),
                title: "TAFS Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.TechnologyFeeRate),
                title: "Tech Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: "HasRollInRateChanges",
                title: "Has Roll In Rate Changes",
                hidden: true
            }, {
                command: [{ name: "destroy" }],
                title: "&nbsp;",
                width: "100px"
            }];

        this.serviceCategoryGridOptions = {
            columns: scColumns,
            dataSource: scDataSource,
            scrollable: false,
            navigatable: true,
            editable: !this.readOnly ? { confirmation: false } : false,
            pageable: KendoUtil.GetDefaultKendoGridPageable(20),
            edit: (e) => {
                var input = e.container.find(".k-input");
                var value = input.val();

                input.blur((j) => {
                    let columnName = j?.target?.dataset?.bind?.split(":")[1];
                    switch (columnName) {
                        case nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.RoyaltyFeeRateResidential):
                        case nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.RoyaltyFeeRateCommercial):
                        case nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.AdFeeRateResidential):
                        case nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.AdFeeRateCommercial):
                        case nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.TAFSFeeRate):
                        case nameof<RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel>(o => o.TechnologyFeeRate):
                            let newValue = input.val();
                            if (value != newValue && newValue != null && (newValue > 0.1)) {
                                alert("Warning! " + columnName + " value above 10%");
                            }
                            break;
                    }
                });
            },
        };
    }

    GetRollInRateGridOptions(itemDetail): kendo.ui.GridOptions
    {
        let rirDataSource = new kendo.data.DataSource({
            batch: true,
            schema: {
                 model: {
                    id: "typeFee",
                    fields: {
                        ParentId: {
                            type: "string",
                            validation: { required: true },
                            editable: false,
                            defaultValue: 0
                        },
                        typeFee: {
                            type: "string",
                            validation: { required: true },
                            editable: false,
                             
                        },
                        rollInSalesBegin: { type: "number", validation: { required: true } },
                        rollInSalesEnd: { type: "number", validation: { required: true } },
                        royaltyFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        royaltyFeeFixedRateAmount: { type: "number", validation: { required: true } },
                        adFeeRate: this.rpmHelper.GetDefaultRateValidatedNumberField(),
                        adFeeFixedRateAmount: { type: "number", validation: { required: true } }
                    }
                 }
            },
             transport: {
                 read: (e: kendo.data.DataSourceTransportReadOptions) => {
                    e.success(this.rpmHelper.ConvertToRollInRates(itemDetail, itemDetail.DisclosureDocumentRatePlanItemDetailId));
                 },
                 update: (e: kendo.data.DataSourceTransportOptions) => {
                     for (let rollInRate of <IRollInRateItem[]>e.data.models)
                     {
                        this.rpmHelper.MergeRollInRateItem(itemDetail, rollInRate)
                     }

                     this.serviceCategoryGrid.dataSource
                         .get(itemDetail.DisclosureDocumentRatePlanItemDetailId)
                         .set("HasRollInRateChanges", true);

                     e.success(e.data.models);
                 },
                 destroy: (e) => { },
                 create: (e) => { }
             }
        });
        let rirColumns: Array<kendo.ui.GridColumn> = [
            {
                field: "ParentId",
                title: "DisclosureDocumentRatePlanItemId",
                hidden: true
            },
            {
                field: "typeFee",
                title: "Type Fee"
            }, {
                field: "rollInSalesBegin",
                title: "Roll-in Sales Begin"
            }, {
                field: "rollInSalesEnd",
                title: "Roll-in Sales End"
            }, {
                field: "royaltyFeeRate",
                title: "Royalty Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: "royaltyFeeFixedRateAmount",
                title: "Royalty Fee Fixed Rate Amount"
            }, {
                field: "adFeeRate",
                title: "Ad Fee Rate",
                format: `{0:${this.rpmHelper.StringFormats.Number.RatePercentage}}`,
                editor: this.rpmHelper.RoyaltyRateEditor
            }, {
                field: "adFeeFixedRateAmount",
                title: "Ad Fee Fixed Rate Amount"
            }];

        return {
            columns: rirColumns,
            dataSource: rirDataSource,
            editable: !this.readOnly,
            navigatable: true,
            scrollable: false,
            pageable: false
        };
    }

    AddNewServiceCategory()
    {
        if (!this.readOnly)
        {
            let serviceCategoryId = this.royaltyServiceCategories[0] && this.royaltyServiceCategories[0].RoyaltyServiceCategoryId;
            if (!serviceCategoryId)
                serviceCategoryId = 0;

            let defaultItemDetail: RpmEntities.RoyaltyDisclosureDocumentRatePlanItemDetailViewModel = {
                DisclosureDocumentRatePlanItemDetailId: 0,
                DisclosureDocumentRatePlanItemId: this.disclosureDocumentRatePlanItemId,
                RoyaltyServiceCategoryTypeId: 0,
                CreatedDateTime: kendo.toString(new Date(), 'd'),
                DeletedDateTime: null,
                RoyaltyServiceCategoryId: serviceCategoryId,
                SmallRollInLowRangeAmount: 0,
                SmallRollInHighRangeAmount: 0,
                SmallRollInRoyaltyFeeRate: 0,
                SmallRollInRoyaltyFeeFixedRateAmount: 0,
                SmallRollInAdFeeRate: 0,
                SmallRollInAdFeeFixedRateAmount: 0,
                MediumRollInLowRangeAmount: 0,
                MediumRollInHighRangeAmount: 0,
                MediumRollInRoyaltyFeeRate: 0,
                MediumRollInRoyaltyFeeFixedRateAmount: 0,
                MediumRollInAdFeeRate: 0,
                MediumRollInAdFeeFixedRateAmount: 0,
                LargeRollInLowRangeAmount: 0,
                LargeRollInHighRangeAmount: 0,
                LargeRollInRoyaltyFeeRate: 0,
                LargeRollInRoyaltyFeeFixedRateAmount: 0,
                LargeRollInAdFeeRate: 0,
                LargeRollInAdFeeFixedRateAmount: 0,
                RoyaltyFeeRateResidential: 0,
                RoyaltyFeeRateCommercial: 0,
                AdFeeRateResidential: 0,
                AdFeeRateCommercial: 0,
                TechnologyFeeRate: 0,
                TAFSFeeRate: 0,
                CreatedUser: null,
                UpdatedDateTime: null,
                UpdatedUser: null
            };

            this.serviceCategoryGrid.dataSource.add(defaultItemDetail);
        }
    }

    GetRoyaltyServiceCategoryById(serviceCategoryId: number)
    {
        return _.find(this.royaltyServiceCategories, (sc) => { return sc.RoyaltyServiceCategoryId === serviceCategoryId; });
    }

    GetRoyaltyServiceCategoryTypeById(serviceCategoryTypeId: number)
    {
        return _.find(this.royaltyServiceCategoryTypes, (sc) => { return sc.RoyaltyServiceCategoryTypeId === serviceCategoryTypeId; });
    }
    
    CancelChanges()
    {
        if (!this.readOnly)
        {
            Object.keys(this.rollInRateGrids).forEach(key => {
                this.rollInRateGrids[key].dataSource.cancelChanges();
            });
            this.serviceCategoryGrid.dataSource.cancelChanges();
        }
    }
    
    HasChanges()
    {
        if (!this.serviceCategoryGrid)
            return false;

        return this.serviceCategoryGrid.dataSource.hasChanges() ||
            Object.keys(this.rollInRateGrids)
                .map(key => { return this.rollInRateGrids[key].dataSource.hasChanges(); })
                .some(hasChanges => { return hasChanges; });
    }

    ResetChanges()
    {    
        this.CancelChanges();

        return this.$q.when(this.serviceCategoryGrid.dataSource.read())
            .then(() =>
            {
                this.serviceCategoryGrid.refresh();
            
                let readPromises = [];
                for (let key of Object.keys(this.rollInRateGrids))
                {
                    let readPromise = this.rollInRateGrids[key].dataSource.read().then(() => {
                        this.rollInRateGrids[key].refresh();
                    });
                    readPromises.push(readPromise);
                }

                return this.$q.all(readPromises)
                    .then(() =>
                    {
                        this.$timeout(() =>
                        {
                            this.formController.$setPristine();
                        }, 1000);
                    });
            });
    }

    SaveChanges()
    {
        let syncPromises: ng.IPromise<any>[] = [];
        Object.keys(this.rollInRateGrids).forEach(key => {
            syncPromises.push(this.$q.when(this.rollInRateGrids[key].dataSource.sync()));
        });
        return this.$q.all(syncPromises).then(() => {
            return this.$q.when(this.serviceCategoryGrid.dataSource.sync()).then(() => {
                this.ResetChanges();
            });
        })
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("fddRoyaltiesServiceCategories", {
                bindings: {
                    [nameof<fddRoyaltiesServiceCategoriesComponentController>(o => o.disclosureDocumentRatePlanItemId)]: "<",
                    [nameof<fddRoyaltiesServiceCategoriesComponentController>(o => o.franchisorId)]: "<",
                    [nameof<fddRoyaltiesServiceCategoriesComponentController>(o => o.readOnly)]: "<",
                    [nameof<fddRoyaltiesServiceCategoriesComponentController>(o => o.onLoaded)]: "&?"
                },
                templateUrl: '/Templates/FDDManagement/fddRoyalties/ServiceCategories.html',
                controller: fddRoyaltiesServiceCategoriesComponentController
            });

    }
}
