import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyPeriodReportDetailsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyPeriodReportDetailsByPeriodReport(periodReportId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriodReportDetails/ByPeriodReport/" + periodReportId
        };

        return this.$http<RpmEntities.RoyaltyPeriodReportDetailViewModel[]>(config);
    }

    GetRoyaltyPeriodReportDetailsByPeriodReportDynamic(periodReportId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPeriodReportDetails/ByPeriodReport/" + periodReportId + "/Dynamic"
        };

        return this.$http<RpmEntities.RoyaltyPeriodReportDetailViewModel[]>(config);
    }
}