import { AngularUtil } from "Utility/AngularUtil";
import * as _ from "underscore"
import { Helpers } from "Utility/Helpers";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { GeoService } from "Services/Utility/GeoService";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { CallCenterResources } from "Interfaces/FranForce/CallCenter/CallCenterResources";

export class CallCentersComponentController implements ng.IController
{
    //---- Bindings ----
    //------------------

    callCentersGrid: kendo.ui.Grid;
    callCentersGridOptions: kendo.ui.GridOptions;
    isGridBound: boolean;

    concepts: CoreResources.IConcept[];

    callCenterWindow: kendo.ui.Window;
    callCenterWindowOptions: kendo.ui.WindowOptions;
    callCenter: CallCenterResources.IC4CallCenter;
    stateAutoComplete: kendo.ui.AutoComplete;

    callCenterPermissionsWindow: kendo.ui.Window;
    callCenterPermissionsWindowOptions: kendo.ui.WindowOptions;
    selectedConcepts: any[];
    assignedConceptIds: number[] = [];
    conceptSelect: string[];

    static $inject = [
        "coreApiResources",
        'callCenterApiResources',
        'identityManager',
        'odataHelper',
        "geo",
        "$q",
        "$log",
        "$timeout",
        "$scope",
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private callCenterApiResources: CallCenterApiResources,
        private identityManager: IdentityManager,
        private odataHelper: ODataHelperService,
        private geo: GeoService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private $scope: ng.IScope
    )
    {

    }

    $onInit()
    {
        this.isGridBound = false;
        this.conceptSelect = [
            nameof<CoreResources.IConcept>(o => o.ConceptId),
            nameof<CoreResources.IConcept>(o => o.ConceptCode),
            nameof<CoreResources.IConcept>(o => o.DisplayName),
        ];

        this.coreApiResources.ConceptApi.query({}).$promise.then((concepts) =>
        {
            this.concepts = concepts;
            this.SetGridOptions();
            this.SetWindowOptions();
        });
    }

    SetGridOptions()
    {
        let columns: kendo.ui.GridColumn[] = [
            {
                width: 50,
                attributes: {
                    "class": "active-icon-cell"
                },
                template: `<i ng-attr-title="{{dataItem.Active ? 'Active' : 'Disabled'}}" 
                                ng-class="'fa fa-circle ' + (dataItem.Active ? 'icon-success' : 'icon-error')">
                            </i>`
            },
            {
                field: "Name",
                title: "Call Center Name"
            }, {
                field: "Name",
                title: "Brands Serviced",
                template: "{{$ctrl.GetConceptCodesFromCallCenter(dataItem)}}"
            }, {
                field: "PhoneNumber",
                title: "Phone"
            }, {
                template: "<button class='pure-button' ng-click='$ctrl.OpenEditCallCenterWindow(dataItem)'>Edit</button>",
                width: 100,
                title: "&nbsp;"
            }, {
                template: "<button class='pure-button' ng-click='$ctrl.OpenCallCenterPermissions(dataItem)'>Permissions</button>",
                width: 140,
                title: "&nbsp;"
            }, {
                template: "<button class='pure-button button-error' ng-click='$ctrl.DeleteCallCenter(dataItem)'>X</button>",
                width: 70,
                title: "&nbsp;"
            }];

        this.callCentersGridOptions = {
            columns: columns,
            sortable: true,
            filterable: true,
            pageable: true,
            dataBound: (e) =>
            {
                this.isGridBound = true;
            },
            dataSource: new kendo.data.DataSource({
                schema: {
                    model: { id: "C4CallCenterId" }
                },
                pageSize: 10,
                transport:
                {
                    read: (options: kendo.data.DataSourceTransportReadOptions) =>
                    {
                        this.isGridBound = false;
                        let queryParams = {
                            $select: "C4CallCenterId,Name,PhoneNumber,PhoneNumberValidation,Country,State,City,Active",
                            $expand: "C4CallCentersServicingConcepts($select=ConceptId,C4CallCenterId)"
                        };
                        this.callCenterApiResources.C4CallCentersApi.query(queryParams).$promise.then(
                            (callCenters) =>
                            {
                                for (let callCenter of callCenters)
                                {
                                    callCenter.C4CallCentersServicingConcepts = _.sortBy(callCenter.C4CallCentersServicingConcepts, c => c.ConceptId);
                                }
                                options.success(callCenters);
                            },
                            (err) =>
                            {
                                options.error(err);
                            });
                    }
                }
            })
        };
    }

    SetWindowOptions()
    {
        this.callCenterWindowOptions = {
            modal: true
        }

        this.callCenterPermissionsWindowOptions = {
            modal: true
        }
    }

    OpenNewCallCenterWindow()
    {
        this.callCenter = new this.callCenterApiResources.C4CallCentersApi();
        this.callCenter.C4CallCenterId = 0;
        this.callCenter.CreatedDateTime = new Date().toISOString();
        this.callCenter.Active = true;
        this.callCenter.C4CallCentersServicingConcepts = [];
        this.callCenter.Country = "United States";
        this.OnCountryChange();
        this.$timeout(() =>
        {
            this.callCenterWindow.center();
        }, 0);
        this.callCenterWindow.open();
    }

    OpenEditCallCenterWindow(callCenter: CallCenterResources.IC4CallCenter)
    {
        this.callCenter = callCenter;

        this.OnCountryChange();
        this.assignedConceptIds = [];
        this.$timeout(() =>
        {
            this.callCenterWindow.center();
        }, 0);
        this.callCenterWindow.open();
        this.callCenter.C4CallCentersServicingConcepts.map((obj) =>
        {
            this.assignedConceptIds.push(obj.ConceptId)
        })
    }

    OpenCallCenterPermissions(callCenter: CallCenterResources.IC4CallCenter)
    {
        this.callCenter = callCenter;
        this.OnCountryChange();
        this.$timeout(() =>
        {
            this.callCenterPermissionsWindow.center();
        }, 0);
        this.callCenterPermissionsWindow.open();
    }

    OnCountryChange()
    {
        this.stateAutoComplete.setDataSource(
            new kendo.data.DataSource({
                data: this.GetAutocompleteStates(this.callCenter.Country)
            }));
    }

    RemoveConceptFromCallCenter(servicedConcept: CallCenterResources.IC4CallCentersServicingConcept)
    {
        let servicedConceptIndex = this.callCenter.C4CallCentersServicingConcepts.indexOf(servicedConcept);
        if (servicedConcept.C4CallCenterId)
        {
            if (confirm(`Are you sure you want to remove ${this.GetConcept(servicedConcept.ConceptId).DisplayName} from the serviced list?`))
            {
                return this.callCenterApiResources.C4CallCentersServicingConceptsApi.delete({
                    id: this.odataHelper.ConvertCompositeKeyToString({
                        C4CallCenterId: servicedConcept.C4CallCenterId,
                        ConceptId: servicedConcept.ConceptId
                    }),
                    allowHardDelete: true
                }).$promise.then(() =>
                {
                    this.callCenter.C4CallCentersServicingConcepts.splice(servicedConceptIndex, 1);
                    this.assignedConceptIds = _.filter(this.assignedConceptIds, (id) =>
                    {
                        return id != servicedConcept.ConceptId;
                    });
                });
            }
        }
        else
        {
            this.callCenter.C4CallCentersServicingConcepts.splice(servicedConceptIndex, 1);
            this.assignedConceptIds = _.filter(this.assignedConceptIds, (id) =>
            {
                return id != servicedConcept.ConceptId;
            });

        }
    }

    AddServicedConcept(conceptId: number)
    {
        if (conceptId && !this.callCenter.C4CallCentersServicingConcepts.some(s => s.ConceptId === conceptId))
        {
            let newServicedCallCenter = new this.callCenterApiResources.C4CallCentersServicingConceptsApi();
            newServicedCallCenter.ConceptId = conceptId;
            newServicedCallCenter.C4CallCenterId = 0;
            this.callCenter.C4CallCentersServicingConcepts.push(newServicedCallCenter);
            this.$timeout(() =>
            {
                this.callCenterWindow.center();
            }, 0);
        }

        this.callCenter.C4CallCentersServicingConcepts = _.sortBy(this.callCenter.C4CallCentersServicingConcepts, c => c.ConceptId);
    }


    async AddServicedConceptNew()
    {
        if (this.selectedConcepts && this.selectedConcepts.length)
        {
            await Promise.all(
                this.selectedConcepts.map(async (concept) =>
                {
                    let newServicedCallCenter = new this.callCenterApiResources.C4CallCentersServicingConceptsApi();
                    newServicedCallCenter.ConceptId = concept.ConceptId;
                    newServicedCallCenter.C4CallCenterId = 0;
                    this.callCenter.C4CallCentersServicingConcepts.push(newServicedCallCenter);
                    this.callCenter.C4CallCentersServicingConcepts = _.sortBy(this.callCenter.C4CallCentersServicingConcepts, c => c.ConceptId);
                    this.assignedConceptIds = this.assignedConceptIds.concat(concept.ConceptId);
                })

            ).then(() =>
            {
                this.$timeout(() =>
                {
                    this.callCenterWindow.center();
                }, 0);

                this.selectedConcepts = [];
            })
        }

    }


    SaveCallCenter()
    {
        let callCenterConcepts = this.callCenter.C4CallCentersServicingConcepts;
        this.callCenter.C4CallCentersServicingConcepts = [];

        let callCenterPromise: ng.IPromise<CallCenterResources.IC4CallCenter>;

        this.callCenter.PhoneNumber = Helpers.StripNonNumerices(this.callCenter.PhoneNumber);

        if (this.callCenter.C4CallCenterId)
        {
            //Update
            callCenterPromise = this.callCenterApiResources.C4CallCentersApi.get({ id: this.callCenter.C4CallCenterId }).$promise
                .then((callCenter) =>
                {
                    callCenter.Name = this.callCenter.Name;
                    callCenter.PhoneNumber = this.callCenter.PhoneNumber;
                    callCenter.PhoneNumberValidation = this.callCenter.PhoneNumberValidation;
                    callCenter.Active = this.callCenter.Active;
                    return callCenter;
                })
                .then((callCenter) =>
                {
                    return this.callCenterApiResources.C4CallCentersApi.update({ id: callCenter.C4CallCenterId }, callCenter).$promise
                });
        }
        else
        {
            //Create
            callCenterPromise = this.callCenter.$save();
        }

        return callCenterPromise
            .then((savedCallCenter) =>
            {
                //Serviced Concepts
                let servicedPromises: ng.IPromise<any>[] = [];
                for (let servicedConcept of callCenterConcepts)
                {
                    this.assignedConceptIds.push(servicedConcept.ConceptId)
                    if (!servicedConcept.C4CallCenterId)
                    {
                        servicedConcept.C4CallCenterId = savedCallCenter.C4CallCenterId;
                        servicedPromises.push(
                            this.callCenterApiResources.C4CallCentersServicingConceptsApi.save({}, servicedConcept).$promise
                        );
                    }

                }
                return this.$q.all(servicedPromises)
            })
            .then(() =>
            {
                return this.callCentersGrid.dataSource.read().always(() =>
                {
                    this.callCenterWindow.close();
                });
            });
    }

    DeleteCallCenter(callCenter: CallCenterResources.IC4CallCenter)
    {
        if (confirm(`Are you sure you want to delete '${callCenter.Name}'?`))
        {
            return this.callCenterApiResources.C4CallCentersApi.delete({ id: callCenter.C4CallCenterId }).$promise.then(
                () =>
                {
                    return this.callCentersGrid.dataSource.read().then(() =>
                    {
                        this.callCentersGrid.refresh();
                    });
                }
            )
        }
    }

    GetConcept(conceptId: number)
    {
        return _.find(this.concepts, c => c.ConceptId === conceptId);
    }

    GetConceptDisplay(conceptId: number)
    {
        let concept = this.GetConcept(conceptId);
        if (concept)
        {
            return concept.ConceptCode + " - " + concept.DisplayName;
        }

    }

    GetConceptCodesFromCallCenter(callCenter: CallCenterResources.IC4CallCenter)
    {
        return callCenter.C4CallCentersServicingConcepts
            .map(cc => this.GetConcept(cc.ConceptId).ConceptCode)
            .sort()
            .join(" ");
    }

    GetAutocompleteStates(country: string): string[]
    {
        return this.geo.GetByCountryName(country, true)
            .map(c => c.Name)
            .sort();
    }

    ResetAccount(accountEmail: string)
    {
        return this.identityManager.ManagedResetPassword(accountEmail, true)
            .catch(AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback(this.$log, this.$q));
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("callCenters", {
            bindings: {
            },
            controller: CallCentersComponentController,
            templateUrl: "/Templates/C4/CallCenters.html",
        });
    }
}

