
import { EditSectionOneController } from "Controllers/Legal/Itt/EditSectionOneController";
import { EditSectionTwoController } from "Controllers/Legal/Itt/EditSectionTwoController";
import { EditSectionThreeController } from "Controllers/Legal/Itt/EditSectionThreeController";
import { IndexIttController } from "Controllers/Legal/Itt/IndexIttController";
import { IttMainController } from "Controllers/Legal/Itt/IttMainController";
import { NewContractInformationController } from "Controllers/Legal/Itt/NewContractInformationController";
import { NewSectionOneController } from "Controllers/Legal/Itt/NewSectionOneController";
import { NewSectionTwoController } from "Controllers/Legal/Itt/NewSectionTwoController";
import { NewSectionThreeController } from "Controllers/Legal/Itt/NewSectionThreeController";
import { ViewSectionOneController } from "Controllers/Legal/Itt/ViewSectionOneController";
import { ViewSectionTwoController } from "Controllers/Legal/Itt/ViewSectionTwoController";
import { ViewSectionThreeController } from "Controllers/Legal/Itt/ViewSectionThreeController";
import { ittTitleInfoDirectiveConfig } from "Directives/Itt/IttTitleDirective";


export function Init()
{
    let app = angular.module('portal.legal');

    app.directive('ittTitleInfo', ittTitleInfoDirectiveConfig);

    app.controller("EditSectionOneController", EditSectionOneController);
    app.controller("EditSectionTwoController", EditSectionTwoController);
    app.controller("EditSectionThreeController", EditSectionThreeController);

    app.controller("IndexIttController", IndexIttController);
    app.controller('IttMainController', IttMainController);
    
    app.controller("NewContractInformationController", NewContractInformationController);

    app.controller("NewSectionOneController", NewSectionOneController);
    app.controller("NewSectionTwoController", NewSectionTwoController);
    app.controller("NewSectionThreeController", NewSectionThreeController);
    
    app.controller("ViewSectionOneController", ViewSectionOneController);
    app.controller("ViewSectionTwoController", ViewSectionTwoController);
    app.controller("ViewSectionThreeController", ViewSectionThreeController);

    return app;
}
