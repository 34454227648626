import { IdentityManager } from "Services/Resources/IdentityManager";
import { Helpers } from "Utility/Helpers";
import { Constants } from 'Constants';
export class UserAccountPanelController implements ng.IController
{

    //Props
    passwordExpirationDate: string;
    daysToExpiry: number;

    token: string;
    email: string;
    localStorage: ILocalStorage;
    
    static $inject = [
        "$http",
        "identityManager",
        "$rootScope"
    ];

    constructor(
        private $http: ng.IHttpService,
        private identityManager: IdentityManager,
        private $rootScope: ng.IRootScopeService
    )
    {

    }

    $onInit()
    {
        this.localStorage = <ILocalStorage>localStorage;
        this.token = this.localStorage.FranForceAuthToken;
        this.email = this.localStorage.AccountEmail;
        this.passwordExpirationDate = this.localStorage.PassWordExpirationDate;
    }

    $postLink()
    {
        if (this.passwordExpirationDate)
        {
            this.daysToExpiry = Helpers.checkDaysToExpiry(this.passwordExpirationDate)
        }
    }

    OpenPasswordPopup(){
        this.$rootScope.$emit(Constants.PopupEvents.OpenPasswordPopupEvent, {});
    }

    Logout()
    {
        this.localStorage.FranForceAuthToken = null;
        this.localStorage.FranForceAuthTokenType = null;
        this.localStorage.AccountEmail = null;
        this.localStorage.PasswordDisplayStatus=null;
        this.localStorage.PassWordExpirationDate=null;

        this.$http.post("/Account/Logout", null)
            .finally(() =>
            {
                window.location.href = "/";
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('userAccountPanel', {
            bindings: {

            },
            controller: UserAccountPanelController,
            templateUrl: "/Templates/Account/UserAccountPanel.html"
        });
    }
}
