export class rmsDashboardComponentController implements ng.IController {

    static $inject = [
    ];

    constructor() {

    }

    $onInit() {

    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsDashboard", {
            bindings: {

            },
            templateUrl: '/Templates/RMSDashboard/rmsDashboard.html',
            controller: rmsDashboardComponentController
        });
    }
}