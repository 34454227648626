import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";

interface IDppBuildingCategoryImageAssetsDirectiveScope extends ng.IScope //extends IEditDisasterPrepProfileSectionScope //ng.IScope
{
    //External scope
    dppId: number;
    dppBuildingId: number;
    dppBuildingAssetCategoryId: number;
         
    //Internal Scope
    AppSettings: IAppSettings;
    isLoading: boolean;
    category: DppResources.IDisasterPrepProfileBuildingAssetCategory;
    selectedDppBuildingAssetId: number;
    availableBuildingAssets: DppResources.IDisasterPrepProfileBuildingAsset[];
    RefreshCategory: () => ng.IPromise<any>;
    RefreshAvailableCategoryAssets: () => ng.IPromise<any>;
    OnAssetSelection: (asset: DppResources.IDisasterPrepProfileBuildingAsset, model: DppResources.IDisasterPrepProfileBuildingAssetCategory) => ng.IPromise<any>;
    RemoveAssetRelation: (assetRelation: DppResources.IDisasterPrepProfileCategorizedBuildingAsset) => ng.IPromise<any>;
}

export class DppBuildingCategoryImageAssetsDirective
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.directive('dppCategoryImageAssets', ['$window', '$timeout', '$parse', '$q', "dppApiResources", "odataHelper", "apiConfig",
            ($window: ng.IWindowService, $timeout: ng.ITimeoutService, $parse: ng.IParseService, $q: ng.IQService, dppApiResources: DppApiResources, odataHelper: ODataHelperService, apiConfig: ApiConfig) =>
            {
                let scopeBinding: IScopeBinding = {
                    dppId: "=",
                    dppBuildingId: '=',
                    dppBuildingAssetCategoryId: '=',
                };

                return <ng.IDirective>{
                    scope: scopeBinding,
                    restrict: "A",
                    replace: false,
                    transclude: false,
                    templateUrl: '/Templates/Dpp/Directives/CategoryImageAssets.html',
                    link: (scope: IDppBuildingCategoryImageAssetsDirectiveScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) =>
                    {
                        scope.AppSettings = apiConfig.AppSettings;
                        let onWatch = () =>
                        {
                            let shouldRefresh = ((scope.dppId && scope.dppBuildingId && scope.dppBuildingAssetCategoryId) &&
                                (!scope.category || !scope.selectedDppBuildingAssetId || !scope.availableBuildingAssets));

                            if (shouldRefresh) {
                                scope.isLoading = true;
                                $q.all([scope.RefreshCategory(), scope.RefreshAvailableCategoryAssets()]).finally(() =>
                                {
                                    scope.isLoading = false;
                                });
                            }
                            else if (!(scope.dppId && scope.dppBuildingId && scope.dppBuildingAssetCategoryId)) {
                                scope.category = null;
                                scope.availableBuildingAssets = null;
                            }
                        }

                        scope.RefreshCategory = () =>
                        {
                            let params = {
                                id: scope.dppBuildingAssetCategoryId,
                                $expand: `DisasterPrepProfileCategorizedBuildingAssets($filter=DppBuildingId eq ${scope.dppBuildingId};$expand=DisasterPrepProfileBuildingAsset($select=${dppApiResources.DppAssetSelectParametersWithoutFileContent.join(",")}))`
                            };
                            return dppApiResources.DisasterPrepProfileBuildingAssetCategoryApi.get(params)
                                .$promise.then((category) =>
                                {
                                    scope.category = category;
                                });
                        }

                        scope.RefreshAvailableCategoryAssets = () =>
                        {
                            return dppApiResources.DisasterPrepProfileBuildingAssetApi.query({
                                $filter: "DppId eq " + scope.dppId + " and (DppBuildingId eq null or DppBuildingId eq " + scope.dppBuildingId + ")",
                                $select: dppApiResources.DppAssetSelectParametersWithoutFileContent.join(","),
                                onlyImages: true
                            }).$promise.then((assets) =>
                            {
                                scope.availableBuildingAssets = assets;
                            });
                        }

                        scope.$watch("dppId", onWatch);
                        scope.$watch("dppBuildingId", onWatch);
                        scope.$watch("dppBuildingAssetCategoryId", onWatch);

                        scope.OnAssetSelection = (asset: DppResources.IDisasterPrepProfileBuildingAsset, model: DppResources.IDisasterPrepProfileBuildingAssetCategory) =>
                        {
                            scope.isLoading = true;
                            let newAssetRelation = new dppApiResources.DisasterPrepProfileCategorizedBuildingAssetApi();

                            newAssetRelation.DppBuildingAssetCategoryId = model.DppBuildingAssetCategoryId;
                            newAssetRelation.DppBuildingAssetId = asset.DppBuildingAssetId;
                            newAssetRelation.DppBuildingId = scope.dppBuildingId;

                            return newAssetRelation.$save().then(
                                (assetRelation: DppResources.IDisasterPrepProfileCategorizedBuildingAsset) =>
                                {
                                    assetRelation.DisasterPrepProfileBuildingAsset = asset;
                                    scope.category.DisasterPrepProfileCategorizedBuildingAssets.push(assetRelation);
                                },
                                (err: ng.IHttpPromiseCallbackArg<any>) =>
                                {
                                    if (err.status === 409) //Conflict
                                    {
                                        console.warn("Relation already exists", err);
                                    }
                                    else {
                                        console.error(err);
                                    }
                                }
                            ).finally(() =>
                            {
                                scope.isLoading = false;
                            });
                        }

                        scope.RemoveAssetRelation = (assetRelation: DppResources.IDisasterPrepProfileCategorizedBuildingAsset) =>
                        {
                            return dppApiResources.DisasterPrepProfileCategorizedBuildingAssetApi.delete({
                                id: odataHelper.ConvertCompositeKeyToString({
                                    DppBuildingAssetId: assetRelation.DppBuildingAssetId,
                                    DppBuildingId: assetRelation.DppBuildingId,
                                    DppBuildingAssetCategoryId: assetRelation.DppBuildingAssetCategoryId
                                }),
                                allowHardDelete: true
                            }).$promise.then((data) =>
                            {
                                return scope.category.DisasterPrepProfileCategorizedBuildingAssets = scope.category.DisasterPrepProfileCategorizedBuildingAssets.filter(
                                    categorizedAsset => categorizedAsset.DppBuildingAssetId !== assetRelation.DppBuildingAssetId
                                );
                            },
                                (err: ng.IHttpPromiseCallbackArg<any>) =>
                                {
                                    if (err.status !== 404) {
                                        console.error(err);
                                        return err;
                                    }
                                    else {
                                        return $q.when(err.data);
                                    }
                                });
                        }
                    }
                };
            }]);
    }
}

