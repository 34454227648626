import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { BaseStateHandler } from "StateHandlers/BaseStateHandler";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyPeriodReportStateHandler extends BaseStateHandler<RpmEntities.RoyaltyPeriodReportViewModel, number> {

    constructor(
        private rpmUiApiResources: RpmUiApiResources
    )
    {
        super();
    }

    Get(royaltyPeriodReportId: number, isDynamicFees?: boolean): angular.IPromise<RpmEntities.RoyaltyPeriodReportViewModel> {
        if (isDynamicFees) {
            return this.rpmUiApiResources.GetRoyaltyPeriodReportDynamic(royaltyPeriodReportId)
                .then((response) => { return response.data; })
        }
        else { 
        return this.rpmUiApiResources.GetRoyaltyPeriodReport(royaltyPeriodReportId)
            .then((response) => { return response.data; })
        }
    }
}