import * as elements from 'typed-html';
import { StringProperties } from "Types/StringProperties";
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import { KendoUtil } from 'Utility/KendoUtil';
import { rmsFranchiseEntitiesClient } from 'Clients/Rms';
import { ODataHelperService } from 'Services/Utility/ODataHelperService';
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { franchiseEntityDataModel } from "../../../Models/Royalty/FranchiseEntities/FranchiseEntityDataModel";


declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            rmsFranchiseEntityDropDown: Partial<StringProperties<FranchiseEntityDropDown>>;
        }
    }
}

export type FranchiseEntityDropDownOnSelect = {
    entity: kendo.data.ObservableObject & Partial<RpmEntities.FranchiseEntity>
};

export class FranchiseEntityDropDown implements ng.IController 
{
    conceptId: number;
    filters: kendo.data.DataSourceFilter;
    onSelect: (params: FranchiseEntityDropDownOnSelect) => void;
    franchiseEntities: franchiseEntityDataModel[];
    franchiseEntityDropDownOptions: kendo.ui.DropDownListOptions;

    dropDown: kendo.ui.DropDownList;

    static $inject = [
        "odataHelper",
        "royaltyApiResources"
    ];

    constructor(
        private odataHelper: ODataHelperService,
        private royaltyApiResources: RoyaltyApiResources,
    )
    {
    }

    $onInit()
    {
    }

    $postLink()
    {
        this.InitDropDown();
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.filters)]) {
            if (this.filters) {
                this.conceptId = this.filters["value"];
                this.InitDropDown();
            }
        }
    }

    InitDropDown()
    {
        this.franchiseEntityDropDownOptions = {
            optionLabel: "Select Entity...",
            optionLabelTemplate: "Select Entity...",
        }
        this.dropDown.setOptions(this.franchiseEntityDropDownOptions);
        if (this.conceptId) {
            this.royaltyApiResources.GetActiveFranchiseEntities(this.conceptId,true)
                .then(
                    (response) => {
                        this.franchiseEntities = [];
                        response.data.forEach((item, index) => {
                            this.franchiseEntities.push({
                                franchiseEntityId: item.franchiseEntityId,
                                name: item.entityName
                            })
                        })
                        this.franchiseEntities.sort((a, b) => (a.name > b.name) ? 1 : -1)
                        this.franchiseEntityDropDownOptions = {
                            autoWidth: true,
                            filter: "startswith",
                            dataSource: this.franchiseEntities,
                            autoBind: false,
                            valuePrimitive: true,
                            optionLabel: "Select Entity...",
                            optionLabelTemplate: "Select Entity...",
                            dataBound: this.adjustDropDownWidth,
                            dataTextField: nameof<franchiseEntityDataModel>(o => o.name),
                            dataValueField: nameof<franchiseEntityDataModel>(o => o.franchiseEntityId),
                            select: (e) => {
                                if (this.onSelect)
                                    this.onSelect({ entity: e.dataItem });
                            }
                        }
                        this.dropDown.setOptions(this.franchiseEntityDropDownOptions);
                        this.dropDown.setDataSource(this.franchiseEntityDropDownOptions.dataSource);

                        this.dropDown.dataSource.sort({
                            field: nameof<RpmEntities.FranchiseEntity>(o => o.EntityName),
                            dir: 'asc'
                        })
                        if (this.filters) {
                            this.dropDown.dataSource.read();
                        }
                        this.dropDown.list.width("auto");
                    });
        }

        this.dropDown.list.width("auto");
    }

    adjustDropDownWidth(e) {
        if (e && e.sender && e.sender.list) {
            var listContainer = e.sender.list.closest(".k-list-container");
            listContainer.width(listContainer.width() + kendo.support.scrollbar());
        }
    }  

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.rmsFranchiseEntityDropDown);

        let template = (
            <select kendo-drop-down-list={`$ctrl.${nameof<FranchiseEntityDropDown>(o => o.dropDown)}`}>
            </select>
        );

        app.component(componentName, {
            bindings: {
                [nameof<FranchiseEntityDropDown>(o => o.filters)]: "<",
                [nameof<FranchiseEntityDropDown>(o => o.onSelect)]: "&?",
            },
            controller: FranchiseEntityDropDown,
            template: template
        });
    }
}

