import { AngularUtil } from "Utility/AngularUtil";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ZeeMailApiResources } from "Services/Resources/ZeeMailApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { ZeeMailEntities } from "Interfaces/FranForce/ZeeMail/ZeeMailResources";

export class ZeeMailRequestAccountChangeComponentController implements ng.IController
{
    zeeMailAccountId: number;
    onSave?: (params: {}) => void;
    onCancel?: (params: {}) => void;

    isLoading: boolean;
    title: string;

    franchiseId: number;
    replyToAccountName: string;

    requestTypeDropDownOptions: kendo.ui.DropDownListOptions;
    template: ZeeMailEntities.ZorWareZeeMailTemplate;
    
    account: ZeeMailEntities.ZorWareZeeMailAccount;
    changeRequest: IZorWareZeeMailRequestChange;

    static $inject = [
        'coreApiResources',
        'zeeMailApiResources',
        'identityManager',
        'apiConfig',
        '$log',
        '$q'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private zeeMailApiResources: ZeeMailApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $log: ng.ILogService,
        private $q: ng.IQService
    )
    {
    }

    $onInit()
    {
        this.title = "Request Account Change";
        this.ResetForm();
        this.InitDropDownOptions();
    }

    $postLink()
    {
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.zeeMailAccountId)] && this.zeeMailAccountId)
        {
            this.isLoading = true;
            this.zeeMailApiResources.ZorWareZeeMailAccountApi.get({
                id: this.zeeMailAccountId,
                $expand: `${nameof<ZeeMailEntities.ZorWareZeeMailAccount>(o => o.ZorWareZeeMailDomain)},${nameof<ZeeMailEntities.ZorWareZeeMailAccount>(o => o.Franchise)}`
            }).$promise
                .then((account) =>
                {
                    this.franchiseId = account.FranchiseId;
                    this.account = account;
                    this.replyToAccountName = account.ReplyToAccountName;

                    return this.zeeMailApiResources.ZorWareZeeMailTemplateApi.query({
                        $filter: `${nameof<ZeeMailEntities.ZorWareZeeMailTemplate>(o => o.ConceptId)} eq ${account.Franchise.ConceptId}`
                    }).$promise
                })
                .then((templates) =>
                { 
                    this.template = templates[0];
                })
                .finally(() =>
                {
                    this.isLoading = false;
                });
        }
    }

    InitDropDownOptions()
    {
        this.requestTypeDropDownOptions = {
            valuePrimitive: true,
            dataSource: new kendo.data.DataSource({
                data: <ZeeMailEntities.ZorWareZeeMailActionType[]>[
                    {
                        ZorWareZeeMailActionTypeID: 7,
                        TypeName: "Email Deactivation"
                    }, {
                        ZorWareZeeMailActionTypeID: 8,
                        TypeName: "ReplyTo Email Change"
                    }
                ]
            }),
            dataTextField: nameof<ZeeMailEntities.ZorWareZeeMailActionType>(o => o.TypeName),
            dataValueField: nameof<ZeeMailEntities.ZorWareZeeMailActionType>(o => o.ZorWareZeeMailActionTypeID),
            optionLabel: "Select type...",
            optionLabelTemplate: "Select type..."
        }
    }

    ResetForm()
    {
        this.changeRequest = {
            Comments: "",
            EnteredName: "",
            ReplyToAccount: "",
            ZorWareZeeMailActionTypeID: null
        };

        if (this.account) {
            this.replyToAccountName = this.account.ReplyToAccountName;
        }
    }

    Save()
    {
        this.changeRequest.ReplyToAccount = this.replyToAccountName;
        return this.zeeMailApiResources.ZeeMailAccountRequestChange(this.zeeMailAccountId, this.changeRequest)
            .then(() =>
            {
                this.ResetForm();
                if (this.onSave) {
                    this.onSave({});
                }
            })
            .catch(AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback(this.$log, this.$q));
    }

    Cancel()
    {
        this.ResetForm()
        if (this.onCancel) {
            this.onCancel({});
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("zeeMailRequestAccountChange", {
            bindings: {
                zeeMailAccountId: "<",
                onSave: "&",
                onCancel: "&"
            },
            controller: ZeeMailRequestAccountChangeComponentController,
            templateUrl: "/Templates/ZeeMail/ZeeMailRequestAccountChange.html"
        });
    }
}


