import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { Helpers } from "Utility/Helpers";

import * as _ from "underscore"
import { IHoursOfOperation } from "Interfaces/FranForce/Core/IHoursOfOperation";

export class HoursOfOperationController implements ng.IController 
{
    openTimesList: Array<IHoursOfOperation & { Is24HourService?: boolean }>;
    sliders: kendo.dataviz.ui.LinearGauge[];
    is24Hours: boolean[];
    sliderOptions: kendo.dataviz.ui.LinearGaugeOptions;

    DaysOfWeekObjs: { id: number, name: string, apply?: boolean }[];
    timeCopyDialog: kendo.ui.Dialog;
    applyOpenTime: IHoursOfOperation & { Is24HourService?: boolean };
    
    static $inject = [
        'coreApiResources',
        'odataHelper',
        '$q',
        '$log',
        '$timeout',
        '$window'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private odataHelper: ODataHelperService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private $window: ng.IWindowService
    )
    {
    }

    $onInit()
    {
        this.DaysOfWeekObjs = Helpers.DaysOfWeekObjects();
        //this.InitSliderSettings()
    }

    InitSliderSettings()
    {
        this.sliders = [];
        this.sliderOptions = {
            pointer: [{ value: 0, opacity: 0 }],
            scale: {
                min: 0,
                max: 1440,
                vertical: false,
                majorUnit: 60,
                minorUnit: 15,
                labels: {
                    font: "9px Arial, Helvetica, sans-serif",
                    template: (tick: { value: number }) =>
                    {
                        let date = Helpers.MinutesToDate(tick.value);
                        return kendo.toString(date, "htt").slice(0, -1);
                    }
                },
                ranges: []
            }
        };

        angular.element(this.$window).bind("resize", () =>
        {
            this.$timeout(() =>
            {
                for (let slider of this.sliders) {
                    slider.resize()
                }
            });
        })
    }

    $postLink()
    {
        this.timeCopyDialog.setOptions(<kendo.ui.DialogOptions>{
            modal: true,
            actions: [
                {
                    text: "Cancel",
                    action: () =>
                    {

                    }
                },
                {
                    text: "Apply",
                    primary: true,
                    action: () =>
                    {
                        this.$timeout(() =>
                        {
                            this.ApplyTimeCopyChanges();
                        });
                    }
                },
            ]
        })
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.openTimesList)] && this.openTimesList)
        {
            for (let ot of this.openTimesList)
            {
                this.RenderRanges(ot);
                ot.Is24HourService = this.Is24HourService(ot);
            }
        }
    }

    GetDay(dayOfWeekId: number)
    {
        return _.find(this.DaysOfWeekObjs, o => o.id == dayOfWeekId).name;
    }

    OnBeforeHoursChange(openTimes: IHoursOfOperation & { Is24HourService?: boolean }, loadRanges: boolean = true)
    {
        return this.$timeout(() =>
        {
            if (openTimes.IsOpenForBeforeHours)
            {
                if (openTimes.IsOpenForAfterHours && openTimes.EndMinutesBeforeHours > openTimes.StartMinutesAfterHours) {
                    openTimes.EndMinutesBeforeHours = openTimes.StartMinutesAfterHours;
                }
                if (openTimes.IsOpenForNormalHours && openTimes.EndMinutesBeforeHours > openTimes.StartMinutes) {
                    openTimes.EndMinutesBeforeHours = openTimes.StartMinutes;
                }
                if (openTimes.StartMinutesBeforeHours > openTimes.EndMinutesBeforeHours) {
                    openTimes.StartMinutesBeforeHours = openTimes.EndMinutesBeforeHours;
                }
            }
            openTimes.Is24HourService = this.Is24HourService(openTimes);
        })
            .then(() =>
            {
                if (loadRanges) {
                    this.RenderRanges(openTimes);
                }
            });
    }

    OnNormalHoursChange(openTimes: IHoursOfOperation & { Is24HourService?: boolean }, loadRanges: boolean = true)
    {
        return this.$timeout(() =>
        {
            if (openTimes.IsOpenForNormalHours)
            {
                if (openTimes.IsOpenForAfterHours && openTimes.EndMinutes > openTimes.StartMinutesAfterHours) {
                    openTimes.StartMinutesAfterHours = openTimes.EndMinutes;
                }
                if (openTimes.IsOpenForBeforeHours && openTimes.EndMinutesBeforeHours > openTimes.StartMinutes) {
                    openTimes.EndMinutesBeforeHours = openTimes.StartMinutes;
                }

                return this.$q.all([
                    this.OnBeforeHoursChange(openTimes, false),
                    this.OnAfterHoursChange(openTimes, false)
                ]);
            }
            openTimes.Is24HourService = this.Is24HourService(openTimes);
        })
            .then(() =>
            {
                if (loadRanges) {
                    this.RenderRanges(openTimes);
                }
            });
    }

    OnAfterHoursChange(openTimes: IHoursOfOperation & { Is24HourService?: boolean }, loadRanges: boolean = true)
    {
        return this.$timeout(() =>
        {
            if (openTimes.IsOpenForAfterHours)
            {
                if (openTimes.IsOpenForBeforeHours && openTimes.EndMinutesBeforeHours > openTimes.StartMinutesAfterHours) {
                    openTimes.StartMinutesAfterHours = openTimes.EndMinutesBeforeHours;
                }
                if (openTimes.IsOpenForNormalHours && openTimes.EndMinutes > openTimes.StartMinutesAfterHours) {
                    openTimes.StartMinutesAfterHours = openTimes.EndMinutes;
                }
                if (openTimes.StartMinutesAfterHours > openTimes.EndMinutesAfterHours) {
                    openTimes.EndMinutesAfterHours = 60 * 24;
                }
            }
            openTimes.Is24HourService = this.Is24HourService(openTimes);
        })
            .then(() =>
            {
                if (loadRanges) {
                    this.RenderRanges(openTimes);
                }
            });
    }

    FocusChange(openTimes: IHoursOfOperation, field: string, isFocused: boolean)
    {
        this.$timeout(() =>
        {
            openTimes[field] = isFocused;
        });
    }

    OpenTimeCopyDialog(openTimes: IHoursOfOperation)
    {
        for (let d of this.DaysOfWeekObjs) {
            d.apply = false;
        }
        this.applyOpenTime = openTimes;
        this.timeCopyDialog.open();
    }

    ApplyTimeCopyChanges()
    {
        this.$timeout(() =>
        {
            this.DaysOfWeekObjs
                .filter((d) =>
                {
                    return d.apply
                })
                .map((d) =>
                {
                    return _.find(this.openTimesList, (time) => { return time.DayOfWeek == d.id })
                })
                .forEach((openTime) =>
                {
                    openTime.IsOpenForNormalHours = this.applyOpenTime.IsOpenForNormalHours;
                    openTime.IsOpenForBeforeHours = this.applyOpenTime.IsOpenForBeforeHours;
                    openTime.IsOpenForAfterHours = this.applyOpenTime.IsOpenForAfterHours;
                    openTime.StartMinutes = this.applyOpenTime.StartMinutes;
                    openTime.EndMinutes = this.applyOpenTime.EndMinutes;
                    openTime.StartMinutesBeforeHours = this.applyOpenTime.StartMinutesBeforeHours;
                    openTime.EndMinutesBeforeHours = this.applyOpenTime.EndMinutesBeforeHours;
                    openTime.StartMinutesAfterHours = this.applyOpenTime.StartMinutesAfterHours;
                    openTime.EndMinutesAfterHours = this.applyOpenTime.EndMinutesAfterHours;
                    openTime.Is24HourService = this.applyOpenTime.Is24HourService;
                    this.RenderRanges(openTime);
                });

            this.timeCopyDialog.close();
        });
    }

    RenderRanges(openTimes: IHoursOfOperation)
    {
        //Don't do any logic, linear gauges disabled.
        if (<any>true)
            return;

        this.$timeout(() =>
        {
            let ranges: kendo.dataviz.ui.LinearGaugeScaleRange[] = [];
            
            if (openTimes.IsOpenForBeforeHours) {
                ranges.push({
                    from: openTimes.StartMinutesBeforeHours,
                    to: openTimes.EndMinutesBeforeHours,
                    color: "#0A2D44"
                })
            }

            if (openTimes.IsOpenForNormalHours) {
                ranges.push({
                    from: openTimes.StartMinutes,
                    to: openTimes.EndMinutes,
                    color: "#03C03C"
                })
            }

            if (openTimes.IsOpenForAfterHours) {
                ranges.push({
                    from: openTimes.StartMinutesAfterHours,
                    to: openTimes.EndMinutesAfterHours,
                    color: "#0A2D44"
                })
            }

            this.sliders[openTimes.DayOfWeek].options.scale.ranges = ranges;
            this.sliders[openTimes.DayOfWeek].redraw();
        });
    }

    Set24HourService(openTimes: IHoursOfOperation)
    {
        if (openTimes.IsOpenForNormalHours)
        {
            if (openTimes.StartMinutes > 0)
            {
                openTimes.IsOpenForBeforeHours = true;
                openTimes.StartMinutesBeforeHours = 0;
                openTimes.EndMinutesBeforeHours = 1440;
            }
            if (openTimes.EndMinutes < 1440)
            {
                openTimes.IsOpenForAfterHours = true;
                openTimes.StartMinutesAfterHours = 0;
                openTimes.EndMinutesAfterHours = 1440;
            }
        }
        else
        {
            openTimes.IsOpenForBeforeHours = true;
            openTimes.StartMinutesBeforeHours = 0;
            openTimes.EndMinutesBeforeHours = 720;

            openTimes.IsOpenForAfterHours = true;
            openTimes.StartMinutesAfterHours = 720;
            openTimes.EndMinutesAfterHours = 1440;
        }
        this.OnNormalHoursChange(openTimes);
    }

    Is24HourService(openTimes: IHoursOfOperation): boolean
    {
        let startOfDay = 0;
        let endOfDay = 1440;

        let startEndTimes: Array<{ start: number, end: number }> = [];
        if (openTimes.IsOpenForBeforeHours)
        {
            startEndTimes.push({
                start: openTimes.StartMinutesBeforeHours,
                end: openTimes.EndMinutesBeforeHours
            });
        }
        if (openTimes.IsOpenForNormalHours)
        {
            startEndTimes.push({
                start: openTimes.StartMinutes,
                end: openTimes.EndMinutes
            });
        }
        if (openTimes.IsOpenForAfterHours)
        {
            startEndTimes.push({
                start: openTimes.StartMinutesAfterHours,
                end: openTimes.EndMinutesAfterHours
            });
        }

        if (!startEndTimes.length) {
            return false;
        }

        if (startEndTimes[0].start != startOfDay) {
            return false;
        }
        if (startEndTimes[startEndTimes.length - 1].end != endOfDay) {
            return false;
        }

        return startEndTimes.every((times, index, array) =>
        {
            if (index > 0)
            {
                let before = array[index - 1];
                if (before.end != times.start) {
                    return false;
                }
            }
            if (index < array.length - 2)
            {
                let after = array[index + 1];
                if (times.end != after.start) {
                    return false;
                }
            }
            return true;
        });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('hoursOfOperation', {
            bindings: {
                openTimesList: "<",
            },
            controller: HoursOfOperationController,
            templateUrl: "/Templates/Common/HoursOfOperation.html"
        });
    }
}


