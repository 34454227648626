import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";
import { reportsProcessModel } from "Models/Royalty/ManualSalesEntry/ReportsProcessModel";
import { templateTypesModel } from "../../../Models/Royalty/ManualSalesEntry/TemplateTypesModel";
import { uploadSalesReportDataModel } from "../../../Models/Royalty/ManualSalesEntry/UploadSalesReportDataModel";
import { uploadReportErrorGridDataModel } from "../../../Models/Royalty/ManualSalesEntry/UploadReportErrorGridDataModel";
import { CoreResources } from "../../../Interfaces/FranForce/Core/CoreResources";


export class ManualSalesReportUploadController implements ng.IController {

    //template type dropdown
    templateTypes: templateTypesModel[];
    templateTypeDropDownOptions: kendo.ui.DropDownListOptions;
    selectedTemplateType: number;

    //upload files
    kendoUpload: kendo.ui.Upload;
    defaultKendoUploadOptions: kendo.ui.UploadOptions;

    //validations for upload
    isInValidUpload: boolean;
    invalidUploadErrorMessage: string;
    invalidTemplateTypeCSSClass: string;

    //upload status
    uploadReportStatusCSSClass: string;
    showUploadReportStatus: boolean;
    uploadReportStatusMessage: string;

    //upload report error grid 
    uploadReportErrorGridOptions: kendo.ui.GridOptions;
    uploadReportErrorGridDataSource: kendo.data.DataSource;
    uploadReportErrorGrid: kendo.ui.Grid;
    isUploadReportErrorGridShow: boolean;

    //franchisorId
    franchisorId: number;
    franchisorFilter: kendo.data.DataSourceFilter;

    showContent: boolean;
    isLoading: boolean;

    static $inject = [
        'royaltyApiResources',
        '$timeout',
    ];

    constructor(
        private royaltyApiResources: RoyaltyApiResources,
        private $timeout: ng.ITimeoutService,
    ) {
    }

    SelectFranchisor(franchisor: Partial<CoreResources.IFranchisor>) {
        if (franchisor.FranchisorId) {
            this.showContent = true;

            if (franchisor.FranchisorId != this.franchisorId) {
                this.isInValidUpload = false;
                this.invalidUploadErrorMessage = "";
                this.invalidTemplateTypeCSSClass = "";
                this.showUploadReportStatus = false;
                this.uploadReportStatusCSSClass = "";
                this.uploadReportStatusMessage = "";
                this.isUploadReportErrorGridShow = false;
            }
        }
        else {
            this.showContent = false;
        }
        this.franchisorId = franchisor.FranchisorId;
        this.resetUploadReportFields();
    }

    $onInit() {
        this.franchisorFilter = [];
        this.voidAllValidations();
        this.initTemplateTypeDropDown();
        this.initReportUpload();
    }

    //set file uploader
    initReportUpload() {
        let allowedUploadExtensions = [".xls", ".xlsx"];

        this.defaultKendoUploadOptions = {
            showFileList: true,
            multiple: true,
            validation: {
                allowedExtensions: allowedUploadExtensions
            },
        }
    }

    //set drop down options for template types
    initTemplateTypeDropDown() {
        this.royaltyApiResources.GetTemplateTypes()
            .then(
                (response) => {
                    this.templateTypes = [];
                    response.data.forEach((item, index) => {
                        this.templateTypes.push({
                            salesFileTemplateId: item.salesFileTemplateId,
                            salesFileTemplateName: item.salesFileTemplateName,
                        })
                    })
                    this.templateTypeDropDownOptions = {
                        dataSource: this.templateTypes,
                        dataValueField: nameof<templateTypesModel>(o => o.salesFileTemplateId),
                        dataTextField: nameof<templateTypesModel>(o => o.salesFileTemplateName),
                        optionLabel: "Select template type",
                        change: () => {
                            let upload = $("#uploadReport").data("kendoUpload");
                            upload.removeAllFiles();
                        },
                        select: () => {
                            this.showUploadReportStatus = false;
                            this.isUploadReportErrorGridShow = false;
                        }
                    }
                });
    }

    //on file upload click
    onUploadFileClick() {
        this.isUploadReportErrorGridShow = false;
        this.uploadReportStatusCSSClass = "";
        this.uploadReportStatusMessage = "";

        //validate if template type is selected
        if (this.checkIfTemplateTypeNotSelected()) {
            return;
        }
        //validate if file is selected for upload
        if (this.checkIfFileIsSelectedForUpload()) {
            return;
        }

        let uploadFilesArr = this.kendoUpload.getFiles().map(file => {
            return file.rawFile
        });

        this.isLoading = true
        this.royaltyApiResources.UploadSalesReport(this.franchisorId, uploadFilesArr, Object.values(this.selectedTemplateType)[0])
            .then(
                (response) => {
                    this.isLoading = false;
                    let obj: uploadSalesReportDataModel;
                    if (response.data) {
                        obj = response.data;
                        if (obj.files.filter(function (e) { return e.isValid == false; }).length > 0) {

                            this.showUploadReportStatus = true;
                            this.uploadReportStatusCSSClass = "uploadReportFailureStatusCSSClass";
                            this.uploadReportStatusMessage = "Error encountered in report data. Refer below grid for details.";

                            let errorRows = [];
                            obj.files.forEach(item => {
                                if (item.isValid == false) {
                                    let fileName = item.fileName;
                                    item.rows.forEach(subItem => {
                                        errorRows.push({
                                            fileName: fileName,
                                            rowNumber: subItem.rowNumber,
                                            propertyName: subItem.propertyName,
                                            errorMessage: subItem.errorMessage
                                        })
                                    })
                                }
                            });
                            this.isUploadReportErrorGridShow = true;

                            let uploadReportErrorGridColumns: Array<kendo.ui.GridColumn> = [
                                {
                                    field: nameof<uploadReportErrorGridDataModel>(o => o.fileName),
                                    title: "File Name",
                                },
                                {
                                    field: nameof<uploadReportErrorGridDataModel>(o => o.rowNumber),
                                    title: "Row Number"
                                },
                                {
                                    field: nameof<uploadReportErrorGridDataModel>(o => o.propertyName),
                                    title: "Property Name"
                                },
                                {
                                    field: nameof<uploadReportErrorGridDataModel>(o => o.errorMessage),
                                    title: "Error Message"
                                },
                            ];

                            this.uploadReportErrorGridDataSource = new kendo.data.DataSource({
                                transport: {
                                    parameterMap: function (options, operation) {
                                        if (operation !== "read" && options.models) {
                                            return { models: kendo.stringify(options.models) };
                                        }
                                    }
                                },
                                schema: {
                                    model: {
                                        fields: {
                                            fileName: { type: "string" },
                                            rowNumber: { type: "number" },
                                            propertyName: { type: "string" },
                                            errorMessage: { type: "string" },
                                        }
                                    }
                                }
                            });

                            this.uploadReportErrorGridOptions = {
                                columns: uploadReportErrorGridColumns,
                                dataSource: this.uploadReportErrorGridDataSource,
                                sortable: true,
                                scrollable: true,
                                resizable: true,
                                height: 300
                            }

                            this.uploadReportErrorGridDataSource.data(errorRows);
                        }
                        else {
                            this.showUploadReportStatus = true;
                            this.uploadReportStatusCSSClass = "uploadReportSuccessStatusCSSClass";
                            this.uploadReportStatusMessage = "Report uploaded sucessfully";
                            this.isUploadReportErrorGridShow = false;
                            this.isInValidUpload = false;
                            this.invalidUploadErrorMessage = "";
                            this.invalidTemplateTypeCSSClass = "";
                        }
                    }
                }).catch(err => {
                    this.isLoading = false;
                    alert("Error occured in uploading file.")
                });
    }

    onDownloadTemplateClick() {
        this.isInValidUpload = false
        //validate if template type is selected
        if (this.checkIfTemplateTypeNotSelected()) {
            return;
        }
        let selectedTemplateType = Object.values(this.selectedTemplateType)[0];
        //download template
        this.royaltyApiResources.DownloadTemplateById(selectedTemplateType)
            .then(
                (response) => {
                    if (selectedTemplateType == 1) {
                        this.saveFile("Sales_Template_Sample.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", response.data);
                    }
                    else {
                        this.saveFile("Period_Report_Template.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", response.data);
                    }
                });
    }

    saveFile(name, type, data) {
        if (data !== null && navigator.msSaveBlob)
            return navigator.msSaveBlob(new Blob([data], { type: type }), name);
        var a = $("<a style='display: none;'/>");
        var url = window.URL.createObjectURL(new Blob([data], { type: type }));
        a.attr("href", url);
        a.attr("download", name);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    //check if frequency is not selected
    checkIfTemplateTypeNotSelected() {
        if (!this.selectedTemplateType) {
            this.setInvalidUploadValidations("invalidTemplateTypeCSSClass", "Please select template type");
            return true;
        }
        else {
            let selectedTemplateType = (Object.values(this.selectedTemplateType)[1]).toString().toLowerCase();
            if (selectedTemplateType.toString().trim() == "") {
                this.setInvalidUploadValidations("invalidTemplateTypeCSSClass", "Please select template type");
                return true;
            }
            else {
                this.unsetInvalidUploadValidations("", "");
                return false;
            }
        }
    }

    //check if file is selected for upload
    checkIfFileIsSelectedForUpload() {
        let uploadFiles = this.kendoUpload.getFiles();
        if (uploadFiles && uploadFiles.length < 1) {
            this.setInvalidUploadValidations("", "Please choose a file to upload");
            return true;
        }
        else {
            this.unsetInvalidUploadValidations("", "");
            return false;
        }
    }

    //set Invalid Upload Validations
    setInvalidUploadValidations(invalidTemplateTypeCSSClass, invalidUploadErrorMessage) {
        this.isInValidUpload = true;
        this.invalidTemplateTypeCSSClass = invalidTemplateTypeCSSClass;
        this.invalidUploadErrorMessage = invalidUploadErrorMessage;
    }

    //unset Invalid Upload Validations
    unsetInvalidUploadValidations(invalidTemplateTypeCSSClass, invalidUploadErrorMessage) {
        this.isInValidUpload = false;
        this.invalidTemplateTypeCSSClass = invalidTemplateTypeCSSClass;
        this.invalidUploadErrorMessage = invalidUploadErrorMessage;
    }

    //void all error and status labels
    voidAllValidations() {
        this.isInValidUpload = false;
        this.invalidUploadErrorMessage ="";
        this.invalidTemplateTypeCSSClass = "";

        this.showUploadReportStatus = false;
        this.uploadReportStatusCSSClass = "";
        this.uploadReportStatusMessage = "";

        this.isUploadReportErrorGridShow = false;
        this.showContent = false;
    }

    //reset fields
    resetUploadReportFields() {
        //reset upload field
        let upload = $("#uploadReport").data("kendoUpload");
        upload.removeAllFiles();
        //reset template type field
        let dropdownFrequency = $("#templateTypeSelectUpload").data("kendoDropDownList");
        dropdownFrequency.value(null);
        dropdownFrequency.trigger("change");
    }

    static BindComponent(app: ng.IModule) {
        app.component('manualSalesReportUpload', {
            bindings: {

            },
            controller: ManualSalesReportUploadController,
            templateUrl: "/Templates/RPM/ManualSalesReportUpload.html"
        });
    }
}


