import { ServiceManagerApiResources } from "Services/Resources/ServiceManagerApiResources";

export class RpmDataSyncAppfolioController implements ng.IController {

    //upload files
    kendoUploadAppfolio: kendo.ui.Upload;
    defaultKendoUploadOptionsAppfolio: kendo.ui.UploadOptions;

    showPropertywareUploadFailureStatus: boolean;
    showPropertywareUploadSuccessStatus: boolean;

    isLoadingAppfolio: boolean;

    static $inject = [
        'serviceManagerApiResources',
        '$timeout',
    ];

    constructor(
        private serviceManagerApiResources: ServiceManagerApiResources,
        private $timeout: ng.ITimeoutService,
    ) {
    }

    $onInit() {
        this.isLoadingAppfolio = false;
        this.initUploadsAppfolio();
        this.showHidePropertywareUploadStatusMessage(false, false)
    }

    initUploadsAppfolio() {
        this.defaultKendoUploadOptionsAppfolio = {
            showFileList: true,
            multiple: true,
        }
    }

    showHidePropertywareUploadStatusMessage(showSuccessMsg: boolean, showFailureMsg: boolean) {
        this.showPropertywareUploadSuccessStatus = showSuccessMsg;
        this.showPropertywareUploadFailureStatus = showFailureMsg;
    }

    onAppfolioUpload() {
        let uploadFilesArr = this.kendoUploadAppfolio.getFiles().map(file => {
            return file.rawFile
        });

        if (!uploadFilesArr || (uploadFilesArr && uploadFilesArr.length < 1)) {
            alert("Please select file to upload.");
            return;
        }

        this.isLoadingAppfolio = true
        this.serviceManagerApiResources.UploadAppfolio(uploadFilesArr)
            .then(
                (response) => {
                    this.isLoadingAppfolio = false;
                    if (response.data) {
                        this.showHidePropertywareUploadStatusMessage(true, false)
                    }
                    this.kendoUploadAppfolio.removeAllFiles();
                }).catch(err => {
                    this.isLoadingAppfolio = false;
                    this.showHidePropertywareUploadStatusMessage(false, true)
                });
    }

    static BindComponent(app: ng.IModule) {
        app.component("rpmDataSyncAppfolio", {
            bindings: {
            },
            controller: RpmDataSyncAppfolioController,
            templateUrl: "/Templates/ServiceManager/RpmDataSyncAppfolio.html",
        });
    }
}