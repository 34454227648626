import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyServiceCategoryTypesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetAllRoyaltyServiceCategoryTypes()
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyServiceCategoryTypes"
        };

        return this.$http<RpmEntities.usp_RoyaltyServiceCategoryTypes_GetAll_Result[]>(config);
    }
}
