import { NavigationLink, NavigationService } from "@DppApp/Services/NavigationService";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export interface IDppRouteParamsService extends ng.route.IRouteParamsService
{
    section: string;
    DppBuildingId?: string;
}

export interface IEditDisasterPrepProfileSectionScope extends angular.IScope {
    dppId: number;
    dppBuildingId: number;

    currentFranchise: CoreResources.IFranchise;
    currentProfile: DppResources.IDisasterPrepProfile;
    currentBuilding: DppResources.IDisasterPrepProfileBuilding;
    isParent: boolean;

    promiseQueue: ILoadingPromise<any>[];

    RedirectHome: () => void;
    GetCurrentBuilding: () => ng.IPromise<DppResources.IDisasterPrepProfileBuilding>;

    RefreshDpp: () => ng.IPromise<any>;
    RefreshBuildings: (onFinishRefresh?: () => void) => ng.IPromise<DppResources.IDisasterPrepProfileBuilding[]>;
    RefreshContacts: () => ng.IPromise<DppResources.IDisasterPrepProfileContact[]>;
    QueueLoadingPromises: (...promise: ng.IPromise<any>[]) => void;
    IsLoading: () => boolean;
    
    currentLink: NavigationLink;
    homeLink: NavigationLink;
    navigationLinks: NavigationLink[];
    BuildLinks: () => ng.IPromise<NavigationLink[]>;
}

export class EditDisasterPrepProfileSectionController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.config(['$routeProvider', ($routeProvider: ng.route.IRouteProvider) =>
        {
            $routeProvider
                .when('/:section',
                    {
                        templateUrl: (urlAttributes: IDppRouteParamsService) => { return "/Templates/Dpp/" + urlAttributes.section + ".html" }
                    })
                .otherwise({ redirectTo: '/EditDisasterPrepProfile' });
        }]);


        dppApp.controller('EditDisasterPrepProfileSectionController',
            [
                '$scope', '$window', '$q', '$routeParams', "coreApiResources", "dppApiResources", "Navigation",
                ($scope: IEditDisasterPrepProfileSectionScope,
                    $window: ng.IWindowService,
                    $q: ng.IQService,
                    $routeParams: IDppRouteParamsService,
                    coreApiResources: CoreApiResources,
                    dppApiResources: DppApiResources,
                    navigation: NavigationService) =>
                {
                    $scope.isParent = true;
                    $scope.promiseQueue = [];

                    var urlParts = $window.location.pathname.split("/");
                    if (!urlParts[urlParts.length - 1]) {
                        urlParts.pop();
                    }

                    $scope.dppId = parseInt(urlParts[urlParts.length - 1]);
                    $scope.$on('$routeChangeSuccess', () =>
                    {
                        $scope.dppBuildingId = parseInt($routeParams.DppBuildingId);
                        $scope.BuildLinks();
                    });

                    $scope.RefreshDpp = () =>
                    {
                        return dppApiResources.DisasterPrepProfileApi.get({ id: $scope.dppId, $expand: "DisasterPrepProfileBuildings($filter=DeletedDateTime eq null),DisasterPrepProfileContacts" })
                            .$promise.then(
                                (dppProfile: DppResources.IDisasterPrepProfile) =>
                                {
                                    $scope.currentProfile = dppProfile;
                                    return $scope.RefreshContacts();
                                },
                                (err: ng.IHttpPromiseCallbackArg<any>) =>
                                {
                                    console.error(err);
                                    if (err.status === 404) {
                                        $window.location.href = "/DisasterPrepProfiles/";
                                    }
                                }
                            ).then(() =>
                            {
                                return coreApiResources.FranchiseApi.get({ id: $scope.currentProfile.FranchiseId })
                                    .$promise.then(
                                        (franchise: CoreResources.IFranchise) =>
                                        {
                                            $scope.currentFranchise = franchise;
                                        });
                            });
                    };
                    $scope.RefreshDpp();

                    $scope.RefreshBuildings = (onFinishRefresh: Function): ng.IPromise<DppResources.IDisasterPrepProfileBuilding[]> =>
                    {
                        return dppApiResources.DisasterPrepProfileBuildingApi.query({ $filter: "DppId eq " + $scope.dppId },
                            (buildings: DppResources.IDisasterPrepProfileBuilding[]) =>
                            {
                                if (!$scope.currentProfile) {
                                    $scope.currentProfile = <any>{};
                                }
                                $scope.currentProfile.DisasterPrepProfileBuildings = buildings;
                                if (onFinishRefresh) {
                                    onFinishRefresh();
                                }
                            },
                            (err) =>
                            {
                                console.error(err);
                            }).$promise;
                    };

                    $scope.RefreshContacts = (): ng.IPromise<DppResources.IDisasterPrepProfileContact[]> =>
                    {
                        return dppApiResources.DisasterPrepProfileContactApi.query({ $filter: "DppId eq " + $scope.dppId },
                            (contacts: DppResources.IDisasterPrepProfileContact[]) =>
                            {
                                $scope.currentProfile.DisasterPrepProfileContacts = contacts;
                            },
                            (err) =>
                            {
                                console.error(err);
                            }).$promise;
                    };

                    $scope.GetCurrentBuilding = (): ng.IPromise<DppResources.IDisasterPrepProfileBuilding> =>
                    {
                        if (!$scope.dppBuildingId) {
                            return null;
                        }

                        return dppApiResources.DisasterPrepProfileBuildingApi.get({ id: $scope.dppBuildingId }).$promise;
                    }

                    $scope.BuildLinks = () =>
                    {
                        $scope.homeLink = navigation.homeLink;
                        let linksPromise = navigation.CreateBuildingLinks($scope.dppBuildingId).then((links: Array<NavigationLink>) =>
                        {
                            $scope.navigationLinks = links;
                            console.log("Links Built", $scope.navigationLinks);
                            $scope.currentLink = navigation.GetCurrentBuildingLocation($scope.navigationLinks) || navigation.GetCurrentBuildingLocation([$scope.homeLink]);
                            if ($scope.currentLink) {
                                $scope.currentLink.CssClass += " current-link-location";
                            }
                            return links;
                        });

                        $scope.QueueLoadingPromises(linksPromise);
                        return linksPromise;
                    };

                    $scope.RedirectHome = () => navigation.RedirectHome();

                    $scope.QueueLoadingPromises = (...promises: ng.IPromise<any>[]) =>
                    {
                        var loadingPromises = <ILoadingPromise<any>[]>promises;
                        loadingPromises.forEach(promise => promise.isLoading = true);

                        $scope.promiseQueue.push(...loadingPromises);

                        $q.all(loadingPromises).finally(() => 
                        {
                            loadingPromises.forEach(promise => promise.isLoading = false);

                            if (!$scope.IsLoading()) {
                                $scope.promiseQueue = [];
                            }
                        });
                    }

                    $scope.IsLoading = () =>
                    {
                        return $scope.promiseQueue.some(p => p.isLoading);
                    }
                }
            ]);
    }
}

