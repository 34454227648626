export abstract class BaseStateHandler<TObj, TKey> implements IBaseStateHandler<TObj, TKey>
{
    private objectPromises: Map<TKey, ng.IPromise<TObj>>
    
    constructor(

    )
    {
        this.objectPromises = new Map();
    }

    abstract Get(id: TKey, isDynamicFees?: boolean): ng.IPromise<TObj>;
    abstract Get(id: TKey): ng.IPromise<TObj>;

    /**
     * Load method works the same if isDynamicFees is not passed.
     * @param id
     * @param isDynamicFees (Optional)
     */
    Load(id: TKey, isDynamicFees?: boolean): ng.IPromise<TObj>
    {
        if (!this.objectPromises.has(id)) {
            if (isDynamicFees === undefined || isDynamicFees == false) {
                this.objectPromises.set(id, this.Get(id));
            } else {
                this.objectPromises.set(id, this.Get(id, isDynamicFees));
            }
        }
        return this.objectPromises.get(id);
    }

    Refresh(id: TKey, isDynamicFees?: boolean): ng.IPromise<TObj> {
        this.Clear(id);
        return this.Load(id, isDynamicFees);
    }

    Clear(id: TKey)
    {
        this.objectPromises.delete(id);
    }
}

export interface IBaseStateHandler<TObj, TKey>
{
    /**
     * Use this one.
     * @param id
     */
    Load(id: TKey): ng.IPromise<TObj>;
    Refresh(id: TKey): ng.IPromise<TObj>;
    Clear(id: TKey): void;
}