import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
export class RoyaltyPosTransactionsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyPosTransactionsByFranchiseAndBatch(batchId: number, franchiseId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPosTransactions/ByFranchiseAndBatch/",
            params: {
                batchId: batchId,
                franchiseId: franchiseId
            }
        };

        return this.$http<RpmEntities.RoyaltyPOSServiceTransactionViewModel[]>(config);
    }
}
