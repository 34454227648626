import { AngularUtil } from "Utility/AngularUtil";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ZeeMailApiResources } from "Services/Resources/ZeeMailApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { ZeeMailEntities } from "Interfaces/FranForce/ZeeMail/ZeeMailResources";

export class ZeeMailDbaChangeComponentController implements ng.IController
{
    franchiseId: number;
    onSave?: (params: { emailDba: string }) => void;
    onCancel?: (params: {}) => void;
    
    title: string;
    emailDba: string;
    isDbaLoading: boolean;

    static $inject = [
        'coreApiResources',
        'zeeMailApiResources',
        'identityManager',
        'apiConfig',
        '$log',
        '$q'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private zeeMailApiResources: ZeeMailApiResources,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $log: ng.ILogService,
        private $q: ng.IQService
    )
    {
    }

    $onInit()
    {
        this.title = "Change Email DBA";
    }

    $postLink()
    {
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.franchiseId)] && this.franchiseId)
        {
            this.emailDba = null;

            this.isDbaLoading = true;
            this.zeeMailApiResources.ZeeMailFranchiseQuery({
                $filter: `${nameof<ZeeMailEntities.ZeeMailFranchiseExtension>(o => o.FranchiseId)} eq ${this.franchiseId}`
            })
                .then((franchisesResponse) =>
                {
                    if (franchisesResponse.data.length)
                    {
                        this.emailDba = franchisesResponse.data[0].ZeeMailDBA;
                    }
                })
                .finally(() =>
                {
                    this.isDbaLoading = false;
                });
        }
    }

    Save()
    {
        return this.zeeMailApiResources.ZeeMailFranchiseEmailDbaChange(this.franchiseId, this.emailDba)
            .then(() =>
            {
                if (this.onSave) {
                    this.onSave({emailDba: this.emailDba});
                }
            })
            .catch(AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback(this.$log, this.$q));
    }

    Cancel()
    {
        if (this.onCancel) {
            this.onCancel({});
        }
    }
    
    static BindComponent(app: ng.IModule)
    {
        app.component("zeeMailDbaChange", {
            bindings: {
                franchiseId: "<",
                onSave: "&",
                onCancel: "&"
            },
            controller: ZeeMailDbaChangeComponentController,
            templateUrl: "/Templates/ZeeMail/ZeeMailDbaChange.html"
        });
    }
}


