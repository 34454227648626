

interface ITriStateButtonScope extends ng.IScope {
    ngModel: boolean;
    buttonCssClass: string;
    trueCssClass: string;
    falseCssClass: string;
    nullCssClass: string;
    trueContent: string;
    falseContent: string;
    nullContent: string;

    Toggle: () => void;
    RenderButton: () => void;
}

let triButtonDirectiveConfig = [() =>
{
    return {
        restrict: "E",
        scope: {
            ngModel: "=",
            buttonCssClass: "@",
            trueCssClass: "@",
            falseCssClass: "@",
            nullCssClass: "@",
            trueContent: "@",
            falseContent: "@",
            nullContent: "@"
        },
        transclude: true,
        template: "<button ng-click='Toggle()'>" +
            "<span tri-content ng-model='ngModel'> " +
            "<true-content>{{trueContent}}</true-content>" +
            "<false-content>{{falseContent}}</false-content>" +
            "<null-content>{{nullContent}}</null-content>" +
            "</span>" +
            "<ng-transclude></ng-transclude>" +
            "</button>",
        link: (scope: ITriStateButtonScope, element: angular.IAugmentedJQuery, attr: angular.IAttributes) =>
        {
            if (!angular.isDefined(scope.ngModel))
                scope.ngModel = null;

            if (!angular.isDefined(scope.buttonCssClass))
                scope.buttonCssClass = "pure-button";

            if (!angular.isDefined(scope.trueCssClass))
                scope.trueCssClass = "button-success";

            if (!angular.isDefined(scope.falseCssClass))
                scope.falseCssClass = "button-error";

            if (!angular.isDefined(scope.nullCssClass))
                scope.nullCssClass = "";

            scope.Toggle = () =>
            {
                if (scope.ngModel === true) {
                    scope.ngModel = false;
                }
                else if (scope.ngModel === false) {
                    scope.ngModel = null;
                }
                else {
                    scope.ngModel = true;
                }
                scope.RenderButton();
            };

            scope.RenderButton = () =>
            {
                let button = element.children("button");
                button.removeClass();

                if (scope.buttonCssClass)
                    button.addClass(scope.buttonCssClass);

                if (scope.ngModel === true) {
                    if (scope.trueCssClass)
                        button.addClass(scope.trueCssClass);
                }
                else if (scope.ngModel === false) {
                    if (scope.falseCssClass)
                        button.addClass(scope.falseCssClass);
                }
                else {
                    scope.ngModel = null;
                    if (scope.nullCssClass)
                        button.addClass(scope.nullCssClass);
                }
            };

            scope.$watch("ngModel", () =>
            {
                scope.RenderButton();
            });

            scope.RenderButton();
        }
    };
}];

export { triButtonDirectiveConfig };