import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { BaseStateHandler } from "StateHandlers/BaseStateHandler";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";

export class RoyaltyPeriodReportDetailsStateHandler extends BaseStateHandler<any[], number> {

    constructor(
        private royaltyAPIResources: RoyaltyApiResources
    )
    {
        super();
    }

    Get(royaltyPeriodReportId: number, isDynamicFees?: boolean): angular.IPromise<any[]>
    {
        console.log('reached');
        if (isDynamicFees === undefined || isDynamicFees == false) {
            console.log('RoyaltyPeriodReportDetailsStateHandler Non-Dynamic Route');
            return this.royaltyAPIResources.GetPeriodReportDetails(royaltyPeriodReportId)
                .then((response) => {
                    return response.data;
                })
        }
        else {
            console.log('RoyaltyPeriodReportDetailsStateHandler Dynamic Route');
            return this.royaltyAPIResources.GetPeriodReportDetailDynamic(royaltyPeriodReportId)
                .then((response) => {
                    return response.data;
                })
        }
    }
}