import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
export class RoyaltyPosPaymentsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyPosPaymentsByRoyaltyPeriodReportId(royaltyPeriodReportId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyPosPayments/ByPeriodReport/",
            params: {
                royaltyPeriodReportId: royaltyPeriodReportId
            }
        };

        return this.$http<RpmEntities.RoyaltyPOSInvoiceViewModel[]>(config);
    }
}