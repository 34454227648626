import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RmsStateService } from "Services/State/RmsState";
import * as _ from "underscore"
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RoyaltyPeriodReportDetailViewModel } from "Interfaces/FranForce/Rpm/PeriodReport/RatePlanItemModel";

export class PeriodReportRoyaltyComponentController implements ng.IController
{
    royaltyPeriodReportId: number;
    franchise: Partial<CoreEntities.Franchise>;
    init: (params: { self: PeriodReportRoyaltyComponentController }) => void;

    isLoading: boolean;
    grid: kendo.ui.Grid;

    calculatedRoyaltyAmount: number;
    royaltyMinimum: number;
    royaltyFeeToBePaid: number;
    isDynamicFees: boolean;

    static $inject = [
        'coreApiResources',
        '$q',
        'rpmUiApiResources',
        'rmsState'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private rpmUiApiResources: RpmUiApiResources,
        private rmsState: RmsStateService,
    )
    {
    }

    $onInit()
    {
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledPm').val() === 'True';
        if (this.init) {
            this.init({ self: this });
        }
    }

    $postLink()
    {
        this.InitGrid();

        if (this.franchise && this.royaltyPeriodReportId) {
            this.RefreshGrid();
        }
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (this.franchise && this.royaltyPeriodReportId) {
            let hasChanges = (onChanges[nameof(this.franchise)] || onChanges[nameof(this.royaltyPeriodReportId)]);
            let validBindings = this.franchise && this.royaltyPeriodReportId;
            if (hasChanges && validBindings) {
                this.RefreshGrid();
            }
        }
    }

    InitGrid()
    {

        let scColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.royaltyServiceCategoryId),
                hidden: true
            }, {
                field: `ServiceCategory.${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Description)}`,
                title: "Service Category"
            }, {
                field: nameof<RoyaltyPeriodReportDetailViewModel>(o => o.calculatedRoyaltyFeeAmountResidential),
                title: "Residential",
                format: "{0:c}",
                attributes: {
                    style: "text-align:right"
                },
                headerAttributes: {
                    style: "text-align:right"
                }
            }, {
                field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.royaltyFeeRateResidential),
                title: "Res %",
                format: "{0:p}",
                attributes: {
                    style: "text-align:right"
                },
                headerAttributes: {
                    style: "text-align:right"
                }
            }, {
                field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.calculatedRoyaltyFeeAmountCommercial),
                title: "Commercial",
                format: "{0:c}",
                attributes: {
                    style: "text-align:right"
                },
                headerAttributes: {
                    style: "text-align:right"
                }
            }, {
                field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.royaltyFeeRateCommercial),
                title: "Com %",
                format: "{0:p}",
                attributes: {
                    style: "text-align:right"
                },
                headerAttributes: {
                    style: "text-align:right"
                }
            }, {
                field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.actualRoyaltyFeeAmount),
                title: "Total Royalty",
                format: "{0:c}",
                attributes: {
                    style: "text-align:right"
                },
                headerAttributes: {
                    style: "text-align:right"
                }
            }
        ];

        let scDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.royaltyServiceCategoryId),
                    fields: {
                        [nameof< RoyaltyPeriodReportDetailViewModel>(o => o.royaltyServiceCategoryId)]: { type: "number", validation: { required: true } },
                        [nameof< RoyaltyPeriodReportDetailViewModel>(o => o.royaltyFeeFixedRateAmountResidential)]: { type: "number", validation: { required: true } },
                        [nameof< RoyaltyPeriodReportDetailViewModel>(o => o.royaltyFeeRateResidential)]: { type: "number", validation: { required: true } },
                        [nameof< RoyaltyPeriodReportDetailViewModel>(o => o.royaltyFeeFixedRateAmountCommercial)]: { type: "number", validation: { required: true } },
                        [nameof< RoyaltyPeriodReportDetailViewModel>(o => o.royaltyFeeRateCommercial)]: { type: "number", validation: { required: true } },
                        totalRoyalty: { type: "number", validation: { required: true } },
                        ServiceCategory: { type: "object" }
                    }
                }
            },
            aggregate: [
                { field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceTotalGrossSalesAmount), aggregate: "sum" },
                { field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceResidentialGrossSalesAmount), aggregate: "sum" },
                { field: nameof< RoyaltyPeriodReportDetailViewModel>(o => o.sourceCommercialGrossSalesAmount), aggregate: "sum" }
            ],
            transport: {
                read: (options) =>
                {
                    this.isDynamicFees = $('#hdnIsDynamicFeesEnabledPm').val() === 'True';
                    this.isLoading = true;
                    if (this.isDynamicFees) {
                        //Dynamic Flow
                        console.log("Dynamic Flow");

                        this.rpmUiApiResources.GetRoyaltyPeriodReportDetailsByPeriodReportDynamic(this.royaltyPeriodReportId)
                            .then((response) => {
                                // Map the response data to camelCase property names
                                const mappedDetails = response.data.map(detail => {
                                    // Create a new object with camelCase property names
                                    const mappedDetail: any = {};

                                    // Map each property to camelCase
                                    Object.keys(detail).forEach(key => {
                                        // Skip $id property
                                        if (key === '$id') return;

                                        const camelKey = key.charAt(0).toLowerCase() + key.slice(1);
                                        mappedDetail[camelKey] = detail[key];
                                    });

                                    return mappedDetail;
                                });
                                 // Get period report data for Royalty fee calculations
                                 this.rpmUiApiResources.GetRoyaltyPeriodReportDynamic(this.royaltyPeriodReportId)
                                 .then((periodreports) => {
                                     this.calculatedRoyaltyAmount = (periodreports.data.CalculatedRoyaltyFeeAmountResidential + periodreports.data.CalculatedRoyaltyFeeAmountCommercial) ;
                                     this.royaltyMinimum = periodreports.data.CalculatedRoyaltyFeeMinimumAmount;
                                     this.royaltyFeeToBePaid = periodreports.data.ActualRoyaltyFeeAmount;
                                 });
                                // Get service categories if needed for dynamic flow
                                if (this.franchise && this.franchise.FranchisorId) {
                                    this.rmsState.royaltyServiceCategoriesByFranchisorStateHandler.Load(this.franchise.FranchisorId)
                                        .then((serviceCategories) => {
                                            mappedDetails.map(detail => {
                                                (<any>detail).ServiceCategory = _.find(serviceCategories, (sc) => { return sc.RoyaltyServiceCategoryId == detail.royaltyServiceCategoryId; }) || { };
                                            });
                                            options.success(mappedDetails);
                                        })
                                        .finally(() => { this.isLoading = false; });
                                    }
                            })
                    }
                    else {
                        this.rmsState.GetPeriodReportDetails(this.royaltyPeriodReportId, this.franchise.FranchisorId)
                            .then((periodDetails) =>
                            {
                                this.calculatedRoyaltyAmount = (periodDetails.royaltyPeriodReport.CalculatedRoyaltyFeeAmountResidential + periodDetails.royaltyPeriodReport.CalculatedRoyaltyFeeAmountCommercial) ;
                                this.royaltyMinimum = periodDetails.royaltyPeriodReport.CalculatedRoyaltyFeeMinimumAmount;
                                this.royaltyFeeToBePaid = periodDetails.royaltyPeriodReport.ActualRoyaltyFeeAmount;

                                if (periodDetails.royaltyPeriodReportDetails.length)
                                {
                                    for (let detail of periodDetails.royaltyPeriodReportDetails) {
                                        (<any>detail).ServiceCategory = _.find(periodDetails.serviceCategories, sc => sc.RoyaltyServiceCategoryId === detail.royaltyServiceCategoryId) || {};
                                    }
                                }

                                options.success(periodDetails.royaltyPeriodReportDetails);
                            })
                            .catch((err) => { options.error(err); })
                            .finally(() => { this.isLoading = false; });
                    }
                }
            }
        });

        this.grid.setOptions(<kendo.ui.GridOptions>{
            autoBind: false,
            columns: scColumns,
            dataSource: scDataSource,
            resizable: true
        });
    }

    RefreshGrid()
    {
        if (this.grid) {
            return this.$q.when(this.grid.dataSource.read())
                .then(() => {
                    this.grid.refresh();
                });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('prRoyalty', {
            bindings: {
                [nameof<PeriodReportRoyaltyComponentController>(o => o.royaltyPeriodReportId)]: "<",
                [nameof<PeriodReportRoyaltyComponentController>(o => o.franchise)]: "<",
                [nameof<PeriodReportRoyaltyComponentController>(o => o.init)]: "&"
            },
            controller: PeriodReportRoyaltyComponentController,
            templateUrl: "/Templates/PeriodReport/prSections/Royalty.html"
        });
    }
}


