import { ApiConfig } from "AppConfig/ApiConfig";

export type onUploadCompleteParametersEvent = {
    e: kendo.ui.UploadEvent,
    createdId: string
};

export class UploadImageComponent implements ng.IController
{
    saveUrl: string;
    onUploadComplete: (params: { event: onUploadCompleteParametersEvent }) => void;

    kendoUpload: kendo.ui.Upload;
    defaultKendoUploadOptions: kendo.ui.UploadOptions;
    allowedUploadExtensions: string[];

    private createdId: string;

    static $inject = [
        "apiConfig"
    ]

    constructor(
        private apiConfig: ApiConfig
    )
    {

    }

    $onInit()
    {
        this.allowedUploadExtensions = [".gif", ".jpg", ".png"];

        this.defaultKendoUploadOptions = {
            showFileList: true,
            multiple: false,
            async: {
                saveUrl: this.saveUrl,
                autoUpload: true,
                withCredentials: false
            },
            validation: {
                allowedExtensions: this.allowedUploadExtensions
            },
            upload: (e) =>
            {
                var xhr: XMLHttpRequest = e.XMLHttpRequest;
                if (xhr) {
                    xhr.addEventListener("readystatechange", (e: ProgressEvent) =>
                    {
                        if (xhr.readyState === XMLHttpRequest.OPENED) 
                        {
                            xhr.setRequestHeader("Authorization", (<ILocalStorage>localStorage).FranForceAuthTokenType + " " + (<ILocalStorage>localStorage).FranForceAuthToken);
                        }

                        if (xhr.readyState === XMLHttpRequest.DONE) 
                        {
                            if (xhr.status >= 400)
                            {
                                console.log(xhr);
                                alert("Error In Upload");
                            }
                            this.createdId =xhr.response;
                        }
                    });
                }
            },
            complete: (e) =>
            {
                if (this.onUploadComplete) 
                {
                    this.onUploadComplete({event: {
                        e: e,
                        createdId: this.createdId
                    }});
                }
            }
        }
    }

    $postLink()
    {

    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
        if (onChanges[nameof(this.saveUrl)] && this.kendoUpload)
        {
            this.kendoUpload.setOptions(<kendo.ui.UploadOptions>{
                asyncOptions: {
                    saveUrl: this.saveUrl
                }
            });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("uploadImage", {
            bindings: {
                [nameof<UploadImageComponent>(o => o.saveUrl)]: "<",
                [nameof<UploadImageComponent>(o => o.onUploadComplete)]: "&"
            },
            controller: UploadImageComponent,
            template: `
<input name="FileContent"
        type="file"
        kendo-upload="$ctrl.${nameof<UploadImageComponent>(o => o.kendoUpload)}" 
       k-options="$ctrl.${nameof<UploadImageComponent>(o => o.defaultKendoUploadOptions)}"/>

<span class="allowed-extensions">
    Allowed Extensions: {{$ctrl.${nameof<UploadImageComponent>(o => o.allowedUploadExtensions)}.join(', ')}}
</span>
`
        });
    }
}