import { ApiConfig } from "AppConfig/ApiConfig";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { BillingResources, BillingEntities } from "Interfaces/FranForce/Billing/BillingResources";
import { BillingResourceApi } from "Interfaces/FranForce/Billing/BillingResourceApiInterfaces";
import { BillingSubscriptionImportResponseDataModel } from "../../Models/Billing/ZorWare/SubscriptionImport/BillingSubscriptionImportResponseDataModel";
import { BillingSubscriptionUpdateDataModel } from "../../Models/Billing/ZorWare/SubscriptionImport/BillingSubscriptionProcessDataModel";
import { BillingInvoiceTaxRequestRmDataModel } from "../../Models/Billing/ZorWare/BillingInvoices/BillingInvoiceTaxRequestRmDataModel";
import { BillingInvoiceTaxResultVmDataModel } from "../../Models/Billing/ZorWare/BillingInvoices/BillingInvoiceTaxResultVmDataModel";
import { GenerateCopyOfInvoiceEmailRequestDataModel } from "../../Models/Billing/ZorWare/BillingInvoices/GenerateCopyOfInvoiceEmailRequestDataModel";
import { BillingInvoiceTaxTrackerResponseDataModel } from "../../Models/Billing/ZorWare/BillingInvoices/BillingInvoiceTaxTrackerResponseDataModel";
import { LoanAmortizationSchedulesRequestModel } from "../../Models/LoanAmortization/LoanAmortizationSchedulesRequestModel";
import { LoanAmortizationSchedulesReponseModel } from "../../Models/LoanAmortization/LoanAmortizationSchedulesReponseModel";

export class BillingApiResources 
{
    BillingServiceApi: BillingResourceApi.IBillingServiceApi;
    BillingTypeApi: BillingResourceApi.IBillingTypeApi;
    BillingSubscriptionApi: BillingResourceApi.IBillingSubscriptionApi;

    static $inject = [
        "$resource",
        "$http",
        "odataHelper",
        "apiConfig"
    ];

    constructor(
        private $resource: ng.resource.IResourceService,
        private $http: ng.IHttpService,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig
    )
    {
        this.RefreshResources();
    }

    GenerateUUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    };

    RefreshResources()
    {
        this.BillingServiceApi = this.$resource<BillingResources.IBillingService>(this.apiConfig.AppSettings.CoreApiRootUrl + "/BillingServices/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.BillingTypeApi = this.$resource<BillingResources.IBillingType>(this.apiConfig.AppSettings.CoreApiRootUrl + "/BillingTypes/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());

        this.BillingSubscriptionApi = this.$resource<BillingResources.IBillingSubscription>(this.apiConfig.AppSettings.CoreApiRootUrl + "/BillingSubscriptions/:id",
            { id: "@id" }, this.odataHelper.GetStandardMethodExtensions());
    }

    GenerateInvoices(billingType: string, billingMonth: number, billingYear: number): ng.IHttpPromise<any>
    {
        return this.$http({
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/BillingBatches/GenerateInvoiceBatch",
            params: {
                billingType: billingType,
                billingMonth: billingMonth,
                billingYear: billingYear
            }
        });
    }

    CreateMollyMaidVideoBrochureSubscription(model: BillingEntities.MollyVideoBrochureSubscriptionParameters)
    {
        return this.$http({
            method: "POST",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + "/BillingSubscriptions/MLY/VideoBrochure",
            data: model
        });
    }

    GetActiveMonthlyBillingSubscriptionsByFranchise(franchiseId:number)
    {
        return this.$http<BillingEntities.usp_Billing_GetActiveBillingSubscriptionsForFranchise_Result[]>({
            method: "GET",
            url: this.apiConfig.AppSettings.CoreApiRootUrl + `/BillingSubscriptions/MonthlyByFranchise/${franchiseId}`,
        });
    }

    ImportBulkBillingSubscriptionUpdate(file: any) {
        let urlString = "/api/billing-subscriptions/upload";
        const formData = new FormData();
            formData.append('File', file);
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.BillingRootUrl + urlString,
            data: formData,
            headers: { 'Content-Type': undefined, 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<BillingSubscriptionImportResponseDataModel>(config);
    }

    ProcessBulkBillingSubscriptionUpdate(data: BillingSubscriptionUpdateDataModel[]) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.BillingRootUrl + "/api/billing-subscriptions/process",
            data: data,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<boolean>(config);
    }

    GetBillingInvoicesTaxReviewData(data: BillingInvoiceTaxRequestRmDataModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.BillingRootUrl + "/api/greater-plains/zorware-taxes",
            data: data,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<BillingInvoiceTaxResultVmDataModel[]>(config);
    }

    GenerateCopyOfInvoiceEmail(data: GenerateCopyOfInvoiceEmailRequestDataModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.BillingRootUrl + "/api/zorware-billing/generate-copy-of-invoice-email",
            data: data,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<boolean>(config);
    }

    GenerateBillingInvoiceTaxes(data: BillingInvoiceTaxRequestRmDataModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.BillingRootUrl + "/api/zorware-taxes/generate",
            data: data,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<BillingInvoiceTaxTrackerResponseDataModel[]>(config);
    }

    ProcessLoanAmortizationSchedule(loan_source_type: string, data: LoanAmortizationSchedulesRequestModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.BillingRootUrl + `/api/loan-source-types/${loan_source_type}/amortization-schedules/search`,
            data: data,
            headers: { 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<LoanAmortizationSchedulesReponseModel[]>(config);
    }
}