import { BillingApiResources } from "../../../Services/Resources/BillingApiResources";
import { CoreResources } from "../../../Interfaces/FranForce/Core/CoreResources";
import { AngularUtil } from "../../../Utility/AngularUtil";
import { BillingInvoiceTaxRequestRmDataModel } from "../../../Models/Billing/ZorWare/BillingInvoices/BillingInvoiceTaxRequestRmDataModel";
import { MonthSelectorOnSelectParams, YearSelectorOnSelectParams } from "../../Common";
import { GenerateCopyOfInvoiceEmailRequestDataModel } from "../../../Models/Billing/ZorWare/BillingInvoices/GenerateCopyOfInvoiceEmailRequestDataModel";
import { BillingInvoiceTaxTrackerResponseDataModel } from "../../../Models/Billing/ZorWare/BillingInvoices/BillingInvoiceTaxTrackerResponseDataModel";

export class BillingServicesComponentController implements ng.IController {

    isLoading: boolean;

    static $inject = [
        "$timeout",
        "$rootScope",
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private $rootScope: ng.IRootScopeService,
    ) {
    }

    $onInit() {

    }

    AddBillingService() {
        this.$rootScope.$emit('addBillingService', {});
    }

    static BindComponent(app: ng.IModule) {
        app.component("billingServices", {
            bindings: {
            },
            controller: BillingServicesComponentController,
            templateUrl: "/Templates/Zorware/BillingServices/BillingServices.html"
        });

    }
}