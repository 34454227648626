import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import * as _ from "underscore"
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";


import
{
    EducationTypeDto, GenderDto,
    LanguageDto, FranchiseProfileEmployeeBioEducationDetailDto,
    FranchiseProfileEmployeeBioDetailClient, FranchiseProfileEmployeeBioDetailDto,
    FranchiseProfileEmployeeBioDto as FranchiseProfileEmployeeBioGroupDto,
    FranchiseProfileEmployeeBioClient as FranchiseProfileEmployeeBioGroupClient,
    FranchiseProfileEmployeeBioXrefClient, FranchiseProfileEmployeeBioXrefDto,
    FranchiseProfileEmployeeBioLanguageXrefClient

} from '@nbly/franchise-employee-group-clients';
import
{
    FranchiseProfileEmployeeBiosVm,FranchiseEmployeeBioSearchResultVm,
    FranchiseProfileEmployeeBioSearchDto,FranchiseProfileEmployeeBioDescriptionDto,
    FranchiseProfileEmployeeBioCertificationClient, GetFranchiseProfileEmployeeBioCertificationDto,
    FranchiseProfileEmployeeBioDto, FranchiseProfileEmployeeBioClient, FranchiseProfileEmployeeBioDetailsDto,
    FranchiseProfileEmployeeBiosRm,FranchiseProfileEmployeeBioXrefRm,FranchiseProfileEmployeeBioDetailRm
    
} from '@nbly/franchise-employee-orchestrations-clients';
import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import
{
    FranchiseWebLocationDto
} from '@nbly/franchise-group-api-clients';

export class EmployeeBiosComponent implements ng.IController
{
    franchiseProfileEmployeeBioId: number;
    franchiseId: number;
    isLoading: boolean;
    conceptCode: string;
    employeeDropDown: kendo.ui.DropDownList;
    cetrificatesMultiSelect: kendo.ui.MultiSelect;
    cetrificatesMultiSelectOptions: kendo.ui.MultiSelectOptions;
    employeeDropDownOptions: kendo.ui.DropDownListOptions;
    allEmployeesMap: Map<string, IManagedUserViewModel>;
    genderOptions: kendo.ui.DropDownListOptions;
    maritialOptions: kendo.ui.DropDownListOptions;
    brandOptions: kendo.ui.DropDownListOptions;
    educationGrid: kendo.ui.Grid;
    languageData: any;
    filters: kendo.data.DataSourceFilter;
    genderList: GenderDto[];
    educationTypeList: EducationTypeDto[];
    languageList: LanguageDto[];
    onSave: () => void;
    onBioCreate: () => void;
    isFirstLoad:boolean;
    employeeBio: FranchiseProfileEmployeeBioSearchDto;
    employeeBioVm: FranchiseEmployeeBioSearchResultVm;
    employeeBioDetail: FranchiseProfileEmployeeBioDto;
    franchiseProfileEmployeeBioGroupDto: FranchiseProfileEmployeeBioGroupDto;
    franchiseProfileEmployeeBioXrefDto: FranchiseProfileEmployeeBioXrefDto;
    franchiseProfileEmployeeBioEducationDetail: FranchiseProfileEmployeeBioEducationDetailDto[];
    selectedCertificates: GetFranchiseProfileEmployeeBioCertificationDto[];
    employeeBiosDetailObject: FranchiseProfileEmployeeBioDetailsDto;
    franchiseProfileEmployeeBioDetailDto:FranchiseProfileEmployeeBioDetailDto;
    franchiseProfileEmployeeBioDescriptionDto:FranchiseProfileEmployeeBioDescriptionDto[];
    franchiseProfileEmployeeBiosRmDto:FranchiseProfileEmployeeBiosRm;
    franchiseEmployeeBioDetail:any;
    franchiseProfileEmployeeBiosVm:FranchiseProfileEmployeeBiosVm;
    selectedLangauges: [];
    selectedGender: {};
    certificateList:GetFranchiseProfileEmployeeBioCertificationDto[];
    setEducationGrid;

    description:string;
    bioImageUrl: string;
    saveBioImageUrl: string;
    franchiseWebLocationId:number;
    franchiseWebLocationDto: FranchiseWebLocationDto[];

    //Clients
    employeeOrchestrationsClient: AxiosInstance;
    employeeGroupClient: AxiosInstance;
    franchiseProfileEmployeeBioCertificationClient: FranchiseProfileEmployeeBioCertificationClient;
    franchiseProfileEmployeeBioClient: FranchiseProfileEmployeeBioClient;
    franchiseProfileEmployeeBioDetailClient: FranchiseProfileEmployeeBioDetailClient;
    franchiseProfileEmployeeBioGroupClient: FranchiseProfileEmployeeBioGroupClient;
    franchiseProfileEmployeeBioXrefClient: FranchiseProfileEmployeeBioXrefClient;
    franchiseProfileEmployeeBioLanguageXrefClient:FranchiseProfileEmployeeBioLanguageXrefClient;

   
    static $inject = [
        'coreApiResources',
        "odataHelper",
        "$scope",
    ];


    constructor(
        private coreApiResources: CoreApiResources,
        private odataHelper: ODataHelperService,
        private $scope: ng.IScope
    )
    {

    }

    async $onInit()
    {
        console.log('weblcoation dto',this.franchiseWebLocationDto)
        this.isLoading=true;
        this.employeeBiosDetailObject = <FranchiseProfileEmployeeBioDetailsDto>{};
        this.franchiseProfileEmployeeBioDetailDto=<FranchiseProfileEmployeeBioDetailDto>{};
        this.franchiseProfileEmployeeBiosRmDto=<FranchiseProfileEmployeeBiosRm>{};
        this.franchiseProfileEmployeeBioDescriptionDto=<FranchiseProfileEmployeeBioDescriptionDto[]>{};
        this.employeeGroupClient = FranForceAxiosClientBuilder.BuildEmployeeGroupBaseClient();
        this.employeeOrchestrationsClient = FranForceAxiosClientBuilder.BuildEmployeeOrchestrationsBaseClient();
        this.franchiseProfileEmployeeBioCertificationClient = new FranchiseProfileEmployeeBioCertificationClient("", this.employeeOrchestrationsClient);
        this.franchiseProfileEmployeeBioClient = new FranchiseProfileEmployeeBioClient("", this.employeeOrchestrationsClient);
        this.franchiseProfileEmployeeBioGroupClient = new FranchiseProfileEmployeeBioGroupClient("", this.employeeGroupClient);
        this.franchiseProfileEmployeeBioDetailClient = new FranchiseProfileEmployeeBioDetailClient("", this.employeeGroupClient);
        this.franchiseProfileEmployeeBioXrefClient = new FranchiseProfileEmployeeBioXrefClient("", this.employeeGroupClient);
        this.franchiseProfileEmployeeBioLanguageXrefClient = new FranchiseProfileEmployeeBioLanguageXrefClient("",this.employeeGroupClient);
        if(!this.franchiseId){
            await this.getFranchiseIdByLicense();
        }

        await this.LoadInfo();
    }



    async onApiSave(){
        this.onSave();
        await this.GetEmployeeBioDetail();
        await this.loadEmployeeDetailsDescription();
    }

    async loadEmployeeDetailsDescription(){
        let franchiseProfileEmployeeBioId;
        if(this.employeeBio){
            franchiseProfileEmployeeBioId=this.employeeBio.franchiseProfileEmployeeBioId
        }else if(this.employeeBioVm){
            franchiseProfileEmployeeBioId=this.employeeBioVm.franchiseProfileEmployeeBioId;
        }
        await this.franchiseProfileEmployeeBioClient.description(franchiseProfileEmployeeBioId)
        .then((response)=>{
            if(response.result.length){
                this.franchiseProfileEmployeeBioDescriptionDto=response.result;
                if(response.result && response.result[0]?.employeeBioDescription){
                    this.description=this.franchiseProfileEmployeeBioDescriptionDto[0].employeeBioDescription;
                    this.employeeBioDetail.description=this.description;
                    this.$scope.$digest();
                }
   
            }
        })
    }

    async getFranchiseIdByLicense()
    {
        let params: ODataQueryParameters = {};
        let filters: kendo.data.DataSourceFilters = {
            logic: "and",
            filters: []
        };
        let licenseNumberValue;
        if(this.employeeBio){
            licenseNumberValue=this.employeeBio.licenseNumber;
        }else if(this.employeeBioVm){
            licenseNumberValue=this.employeeBioVm.licenseNumber;
        }

        let licenseFilter: kendo.data.DataSourceFilterItem = {
            field: nameof<CoreResources.IFranchise>(o => o.LicenseNumber),
            operator: "eq",
            value: licenseNumberValue
        }
        filters.filters.push(licenseFilter);

        params.$select = [
            nameof<CoreResources.IFranchise>(o => o.FranchiseId),
            nameof<CoreResources.IFranchise>(o => o.ConceptCode),
            nameof<CoreResources.IFranchise>(o => o.ConceptId),
        ].join(",");
        params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersToString(filters);
        await this.coreApiResources.FranchiseApi.query(params)
            .$promise.then((franchises) =>
            {
                this.franchiseId = franchises.slice()[0].FranchiseId;
            })
            .catch((err) =>
            {

            });
    }

    async createEmployeeBioDetail(){
        let franchiseProfileEmployeeBioId;
        if(this.employeeBio){
            franchiseProfileEmployeeBioId=this.employeeBio.franchiseProfileEmployeeBioId;
        }else{
            franchiseProfileEmployeeBioId=this.employeeBioVm.franchiseProfileEmployeeBioId
        }
        this.franchiseProfileEmployeeBioDetailDto={
            franchiseProfileEmployeeBioDetailsId: 0,
            franchiseProfileEmployeeBioId: franchiseProfileEmployeeBioId,
            yearHired: null,
            yearsExpierenceDateOfHire:null,
            genderTypeId: null,
            maritalStatus:null,
            numberOfChildren: null,
            city: null,
            state: null,
            preferredPhotoType: null,
            squarePhotoUrl: null,
            circularPhotoUrl: null,
            photoExtension: null,
            altTag: null,
            title: null,
            lastName: null,
            firstName: null,
            createDateTime: new Date().toISOString(),
            updateDateTime: null,
            deletedDateTime: null,
            franchiseProfileEmployeeBioVariableId: 0,
        }
        await this.franchiseProfileEmployeeBioDetailClient.franchiseProfileEmployeeBioDetails(
            this.franchiseProfileEmployeeBioDetailDto
        ).then(async (response)=>{
            await this.GetEmployeeBioDetail();
        })
    }

    async GetEmployeeBioDetail(){
        
        if(this.employeeBio && this.employeeBio.franchiseProfileEmployeeBioId){
            //if bioId are present
            await this.franchiseProfileEmployeeBioClient.franchiseProfileEmployeeBioDetail(
                this.employeeBio.franchiseProfileEmployeeBioId
            ).then((response)=>{
                this.employeeBioDetail=response.result;
                this.description= this.employeeBioDetail.description;
                this.isLoading=false;
                this.$scope.$digest();
            })
        
        }else if(this.employeeBioVm && this.employeeBioVm.franchiseProfileEmployeeBioId){
            await this.franchiseProfileEmployeeBioClient.franchiseProfileEmployeeBioDetail(
                this.employeeBioVm.franchiseProfileEmployeeBioId
            ).then((response)=>{
                this.employeeBioDetail=response.result;
                this.description= this.employeeBioDetail.description;
                this.isLoading=false;
                this.$scope.$digest();
            })
        }
    }

    async LoadInfo()
    {
        if((this.employeeBio && this.employeeBio.franchiseProfileEmployeeBioId)||(this.employeeBioVm && this.employeeBioVm.franchiseProfileEmployeeBioId)){
            //if bioId are present
            await this.GetEmployeeBioDetail();
            if(!this.employeeBioDetail.franchiseProfileEmployeeBioDetail){
                await this.createEmployeeBioDetail();
            }
        }else{
            //bioId not present
            this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioId=0;
            this.franchiseProfileEmployeeBiosRmDto.franchiseId=this.franchiseId;

                if(this.employeeBio && this.employeeBio.syncTechnicianId){
                    this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm=<FranchiseProfileEmployeeBioXrefRm>{};
                    this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm.syncTechnicianEmployeeId=this.employeeBio.syncTechnicianId;
                    this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm.franchiseProfileEmployeeBioXrefId=0;
                    this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm.type=this.employeeBio.type;
                }else if(this.employeeBioVm && this.employeeBioVm.syncTechnicianEmployeeId){
                    this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm=<FranchiseProfileEmployeeBioXrefRm>{};
                    this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm.syncTechnicianEmployeeId=this.employeeBioVm.syncTechnicianEmployeeId;
                    this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm.franchiseProfileEmployeeBioXrefId=0;
                    this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm.type=this.employeeBioVm.type;
                }else{
                    this.franchiseProfileEmployeeBiosRmDto.franchiseProfileEmployeeBioXrefRm=null; 
                }

                if(this.franchiseWebLocationDto && this.franchiseWebLocationDto.length){
                    this.franchiseProfileEmployeeBiosRmDto.webLocationIds=[]
                    this.franchiseWebLocationDto.map((obj)=>{
                        this.franchiseProfileEmployeeBiosRmDto.webLocationIds.push(obj.franchiseWebLocationId)
                    })
                }
                
            
                
/*             this.franchiseProfileEmployeeBiosRmDto.webLocationIds=[this.franchiseWebLocationId];
 */            this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails=<FranchiseProfileEmployeeBioDetailRm>{};
            this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.franchiseProfileEmployeeBioDetailsId=0;
            this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.franchiseProfileEmployeeBioId=0;
            this.franchiseProfileEmployeeBiosRmDto.employeeBioDetails.genderTypeId=null;
            await this.franchiseProfileEmployeeBioClient.franchise_profile_employee_bios(
                this.franchiseProfileEmployeeBiosRmDto
                ).then((response)=>{
                    this.onBioCreate();
                    this.franchiseProfileEmployeeBiosVm=response.result;
                    this.franchiseEmployeeBioDetail=response.result;

                    if(this.employeeBio){
                        if(this.franchiseProfileEmployeeBiosVm?.franchiseProfileEmployeeBioXrefVm?.franchiseProfileEmployeeBioXrefId){
                            this.employeeBio.franchiseProfileEmployeeBioXrefId=this.franchiseProfileEmployeeBiosVm.franchiseProfileEmployeeBioXrefVm.franchiseProfileEmployeeBioXrefId;
                        }
                        this.employeeBio.franchiseProfileEmployeeBioId=this.franchiseProfileEmployeeBiosVm.franchiseProfileEmployeeBioId;
                        this.onSave();
                    }else if(this.employeeBioVm){
                        if(this.franchiseProfileEmployeeBiosVm?.franchiseProfileEmployeeBioXrefVm?.franchiseProfileEmployeeBioXrefId){
                            this.employeeBioVm.franchiseProfileEmployeeBioXrefId=this.franchiseProfileEmployeeBiosVm.franchiseProfileEmployeeBioXrefVm.franchiseProfileEmployeeBioXrefId;
                        }
                        this.employeeBioVm.franchiseProfileEmployeeBioId=this.franchiseProfileEmployeeBiosVm.franchiseProfileEmployeeBioId;
                    }
                  
                    this.franchiseProfileEmployeeBioClient.franchiseProfileEmployeeBioDetail(
                        this.franchiseEmployeeBioDetail.franchiseProfileEmployeeBioId
                    ).then((response)=>{
                        this.onBioCreate();
                        this.employeeBioDetail=response.result;
                        this.isLoading=false;
                        this.$scope.$digest();
                    })
                });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('employeeBiosComponent', {
            bindings: {
                [nameof<EmployeeBiosComponent>(o => o.genderList)]: "<",
                [nameof<EmployeeBiosComponent>(o => o.educationTypeList)]: "<",
                [nameof<EmployeeBiosComponent>(o => o.languageList)]: "<",
                [nameof<EmployeeBiosComponent>(o => o.employeeBio)]: "=",
                [nameof<EmployeeBiosComponent>(o => o.employeeBioVm)]: "=",
                [nameof<EmployeeBiosComponent>(o => o.conceptCode)]: "<",
                [nameof<EmployeeBiosComponent>(o => o.franchiseId)]: "<",
                [nameof<EmployeeBiosComponent>(o => o.franchiseWebLocationId)]: "<",
                [nameof<EmployeeBiosComponent>(o => o.certificateList)]: "<",
                [nameof<EmployeeBiosComponent>(o => o.isFirstLoad)]: "<",
                franchiseWebLocationDto: "<",
                onSave: "&",
                onBioCreate: "&",

            },
            controller: EmployeeBiosComponent,
            templateUrl: "/Templates/EmployeeBios/EmployeeBiosComponent.html"
        });
    }
}


