import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { MerchantApiResources } from "Services/Resources/MerchantApiResources";
import { UrlQueryParameters } from "Utility/UrlQueryParameters";
import * as _ from "underscore"
import { ApiConfig } from "AppConfig/ApiConfig";
import { MerchantResources } from "Interfaces/FranForce/Merchant/MerchantResources";

export class ReimbursementBatchGiftCertificatesController implements ng.IController {
    
    gridOptions: kendo.ui.GridOptions;
    batches: MerchantResources.IGiftCertificateReimbursementBatch[];
    

    static $inject = [
        "$scope",
        "coreApiResources",
        "merchantApiResources",
        "$q",
        "$log",
        "$window",
        "apiConfig"
    ];

    constructor(
        private $scope: ng.IScope,
        private coreApiResources: CoreApiResources,
        private merchantApiResources: MerchantApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $window: ng.IWindowService,
        private apiConfig: ApiConfig
    )
    {

    }

    $onInit()
    {
        this.InitBatches();
    }

    InitBatches()
    {
        return this.merchantApiResources.GiftCertificateReimbursementBatchApi.query({$orderby: "CreatedDateTime desc"}).$promise.then((batches) =>
        {
            this.batches = batches;

            let batchId = new UrlQueryParameters(window).GetValue("reimbursementBatchId");
            if (batchId)
            {
                let foundBatch = _.find(this.batches, b => b.GiftCertificateReimbursementBatchId == parseInt(batchId));
                if (foundBatch)
                {
                    return this.ToggleExpandBatch(foundBatch);
                }
            }
        });
    }

    ToggleExpandBatch(batch: MerchantResources.IGiftCertificateReimbursementBatch)
    {
        if (batch.GiftCertificates && batch.GiftCertificates.length)
        {
            batch.GiftCertificates = null;
        }
        else
        {
            let queryObj = {
                id: batch.GiftCertificateReimbursementBatchId,
                $select: "GiftCertificates",
                $expand: "GiftCertificates($select=GiftCertificateId,GiftCertificateSuffix,GiftCertificateSuffixString,Amount;$expand=RedeemedByFranchise($select=LicenseNumber))"
            }
            return this.merchantApiResources.GiftCertificateReimbursementBatchApi.get(queryObj)
                .$promise.then((batchWithGcs) => {
                    batch.GiftCertificates = batchWithGcs.GiftCertificates;
                });
        }
    }

    GetCsvLink(batch: MerchantResources.IGiftCertificateReimbursementBatch)
    {
        return `${this.apiConfig.AppSettings.CoreApiRootUrl}/GiftCertificateReimbursementBatches/${batch.GiftCertificateReimbursementBatchId}/RenderCsv?access_token=${(<ILocalStorage>localStorage).FranForceAuthToken}`; 
    }
}
