import { ServiceManagerApiResources } from "Services/Resources/ServiceManagerApiResources";

export interface propertywareTypes {
    propertywareTypeId: number;
    propertywareTypeName: string;
}

export class RpmDataSyncPropertywareController implements ng.IController {

    //rpmDataSyncPropertywareType DropDown
    rpmDataSyncPropertywareDropDownOptions: kendo.ui.DropDownListOptions;
    selectedRpmDataSyncPropertyware: number;
    propertywareTypes: propertywareTypes[];

    showOwnerIdsBlock: boolean;
    showOwnersBlock: boolean;
    showBuildingsBlock: boolean;
    showMetricsBlock: boolean;

    showPropertywareUploadFailureStatus: boolean;
    showPropertywareUploadSuccessStatus: boolean;

    //upload files
    kendoUploadPropertyWare: kendo.ui.Upload;
    defaultKendoUploadOptions: kendo.ui.UploadOptions;

    isLoadingPropertyware: boolean;

    static $inject = [
        'serviceManagerApiResources',
        '$timeout',
    ];

    constructor(
        private serviceManagerApiResources: ServiceManagerApiResources,
        private $timeout: ng.ITimeoutService,
    ) {
    }

    $onInit() {
        this.isLoadingPropertyware = false;
        this.showHideBlocksBasedOnPropertwareTypes(false, false, false, false);
        this.showHideAppfolioUploadStatusMessage(false, false);
        this.InitPropertywareTypes();
        this.initUploads();
        this.selectedRpmDataSyncPropertyware = null;
    }

    InitPropertywareTypes() {
        this.propertywareTypes = [
            {
                propertywareTypeId: 1,
                propertywareTypeName: "Owner Id's",
            },
            {
                propertywareTypeId: 2,
                propertywareTypeName: "Owners",
            },
            {
                propertywareTypeId: 3,
                propertywareTypeName: "Buildings",
            },
            {
                propertywareTypeId: 4,
                propertywareTypeName: "Metrics",
            }
        ];

        this.rpmDataSyncPropertywareDropDownOptions = {
            dataSource: this.propertywareTypes,
            dataValueField: nameof<propertywareTypes>(o => o.propertywareTypeId),
            dataTextField: nameof<propertywareTypes>(o => o.propertywareTypeName),
            optionLabel: "Select Type",
            change: () => {

            },
            select: (e) => {
                if (e.dataItem['propertywareTypeId'] == 1) {
                    this.showHideBlocksBasedOnPropertwareTypes(true, false, false, false);
                }
                else if (e.dataItem['propertywareTypeId'] == 2) {
                    this.showHideBlocksBasedOnPropertwareTypes(false, true, false, false);
                }
                else if (e.dataItem['propertywareTypeId'] == 3) {
                    this.showHideBlocksBasedOnPropertwareTypes(false, false, true, false);
                }
                else if (e.dataItem['propertywareTypeId'] == 4) {
                    this.showHideBlocksBasedOnPropertwareTypes(false, false, false, true);
                }
                else {
                    this.showHideBlocksBasedOnPropertwareTypes(false, false, false, false)
                }

                //remove all files
                this.kendoUploadPropertyWare.removeAllFiles();
            }
        }
    }

    //set file uploader
    initUploads() {
        this.defaultKendoUploadOptions = {
            multiple: false,
        }
    }

    showHideBlocksBasedOnPropertwareTypes(showOwnerIdsBlock: boolean, showOwnersBlock: boolean, showBuildingsBlock: boolean, showMetricsBlock: boolean) {
        this.showOwnerIdsBlock = showOwnerIdsBlock;
        this.showOwnersBlock = showOwnersBlock;
        this.showBuildingsBlock = showBuildingsBlock;
        this.showMetricsBlock = showMetricsBlock;
    }

    showHideAppfolioUploadStatusMessage(showSuccessMsg: boolean, showFailureMsg: boolean) {
        this.showPropertywareUploadSuccessStatus = showSuccessMsg;
        this.showPropertywareUploadFailureStatus = showFailureMsg;
    }

    onOwnerIdsUpload() {
        let uploadFilesArr = this.kendoUploadPropertyWare.getFiles().map(file => {
            return file.rawFile
        });

        if (!uploadFilesArr || (uploadFilesArr && uploadFilesArr.length < 1)) {
            alert("Please select file to upload.");
            return;
        }

        this.isLoadingPropertyware = true
        this.serviceManagerApiResources.UploadPropertywareOwnerIds(uploadFilesArr[0])
            .then(
                (response) => {
                    this.isLoadingPropertyware = false;
                    this.showHideAppfolioUploadStatusMessage(true, false);
                    //remove all files
                    this.kendoUploadPropertyWare.removeAllFiles();
                    
                }).catch(err => {
                    this.isLoadingPropertyware = false;
                    this.showHideAppfolioUploadStatusMessage(false, true);
                });
    }

    onOwnersUpload() {
        let uploadFilesArr = this.kendoUploadPropertyWare.getFiles().map(file => {
            return file.rawFile
        });

        if (!uploadFilesArr || (uploadFilesArr && uploadFilesArr.length < 1)) {
            alert("Please select file to upload.");
            return;
        }

        this.isLoadingPropertyware = true
        this.serviceManagerApiResources.UploadPropertywareOwners(uploadFilesArr[0])
            .then(
                (response) => {
                    this.isLoadingPropertyware = false;
                    this.showHideAppfolioUploadStatusMessage(true, false);
                    //remove all files
                    this.kendoUploadPropertyWare.removeAllFiles();

                }).catch(err => {
                    this.isLoadingPropertyware = false;
                    this.showHideAppfolioUploadStatusMessage(false, true);
                });
    }

    onBuildingsUpload() {
        let uploadFilesArr = this.kendoUploadPropertyWare.getFiles().map(file => {
            return file.rawFile
        });

        if (!uploadFilesArr || (uploadFilesArr && uploadFilesArr.length < 1)) {
            alert("Please select file to upload.");
            return;
        }

        this.isLoadingPropertyware = true
        this.serviceManagerApiResources.UploadPropertywareBuildings(uploadFilesArr[0])
            .then(
                (response) => {
                    this.isLoadingPropertyware = false;
                    this.showHideAppfolioUploadStatusMessage(true, false);
                    //remove all files
                    this.kendoUploadPropertyWare.removeAllFiles();

                }).catch(err => {
                    this.isLoadingPropertyware = false;
                    this.showHideAppfolioUploadStatusMessage(false, true);
                });
    }

    onMetricsUpload() {
        let uploadFilesArr = this.kendoUploadPropertyWare.getFiles().map(file => {
            return file.rawFile
        });

        if (!uploadFilesArr || (uploadFilesArr && uploadFilesArr.length < 1)) {
            alert("Please select file to upload.");
            return;
        }

        this.isLoadingPropertyware = true
        this.serviceManagerApiResources.UploadPropertywareMetrics(uploadFilesArr[0])
            .then(
                (response) => {
                    this.isLoadingPropertyware = false;
                    this.showHideAppfolioUploadStatusMessage(true, false);
                    //remove all files
                    this.kendoUploadPropertyWare.removeAllFiles();

                }).catch(err => {
                    this.isLoadingPropertyware = false;
                    this.showHideAppfolioUploadStatusMessage(false, true);
                });
    }


    static BindComponent(app: ng.IModule) {
        app.component("rpmDataSyncPropertyware", {
            bindings: {
            },
            controller: RpmDataSyncPropertywareController,
            templateUrl: "/Templates/ServiceManager/RpmDataSyncPropertyware.html",
        });
    }
}