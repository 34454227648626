import { IFranchiseeStateParams } from "Controllers/Rms/FranchiseeManagement/fmRouter";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { BooleanProperties } from "Types/BooleanProperties";

export class fmEmailController implements ng.IController 
{    
    readOnly: boolean;
    zorProcessTypeId: number;

    franchiseEmailsBindings: {
        additionalFilters: kendo.data.DataSourceFilter;
        restrictFields: Partial<BooleanProperties<CoreResources.IFranchiseEmail>>;
        defaultModel: Partial<CoreResources.IFranchiseEmail>;
        hideFields: string[];
        preDelete: (params: { grid: kendo.ui.Grid, franchiseEmail: Partial<CoreResources.IFranchiseEmail> }) => void;
    };

    static $inject = [
        "$scope",
        "$state",
        "$stateParams",
        "coreApiResources",
        "ngDialog",
        "rpmApiResources",
        "identityManager",
        "$q"
    ];

    constructor(
        private $scope: ng.IScope,
        private $state: any,
        private $stateParams: IFranchiseeStateParams,
        private coreApiResources: CoreApiResources,
        private ngDialog: any,
        private rpmApiResources: RpmApiResources,
        private identityManager: IdentityManager,
        private $q: ng.IQService
    ) {

    }

    $onInit()
    {
        this.identityManager.HasRole(["FranchiseeManagementAdmin", "Admin"]).then(isAdmin =>
        {
            this.readOnly = !isAdmin;
        });

        this.coreApiResources.FranchiseApi.get({ id: this.$stateParams.franchiseeID }).$promise.then((franchise) =>
        {
            this.franchiseEmailsBindings.defaultModel.Franchise.LicenseNumber = franchise.LicenseNumber
        });

        this.zorProcessTypeId = 1;

        this.franchiseEmailsBindings = {
            additionalFilters: <kendo.data.DataSourceFilters>{
                logic: "and",
                filters: <kendo.data.DataSourceFilterItem[]>[
                    {
                        field: nameof<CoreResources.IFranchiseEmail>(o => o.FranchiseId),
                        operator: "eq",
                        value: parseInt(this.$stateParams.franchiseeID)
                    }, {
                        field: nameof<CoreResources.IFranchiseEmail>(o => o.EmailProcessTypeID),
                        operator: "eq",
                        value: this.zorProcessTypeId
                    }
                ]
            },
            restrictFields: {
                FranchiseId: true,
                EmailProcessTypeID: true
            },
            defaultModel: {
                FranchiseId: parseInt(this.$stateParams.franchiseeID),
                Franchise: <any>{},
                EmailProcessTypeID: this.zorProcessTypeId
            },
            hideFields: [
                nameof<CoreResources.IFranchiseEmail>(o => o.FranchiseEmailId),
                nameof.full<CoreResources.IFranchiseEmail>(o => o.EmailProcessType.Name),
                nameof.full<CoreResources.IFranchiseEmail>(o => o.Franchise.Concept.ConceptCode),
                nameof.full<CoreResources.IFranchiseEmail>(o => o.Franchise.LicenseNumber),
            ],
            preDelete: (params) =>
            {
                if (params.grid.dataSource.total() <= 1)
                {
                    let message = "Deleting the final email record for a franchise is disallowed.";
                    alert(message);
                    throw message;
                }
            }
        };
    }
}