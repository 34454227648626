import { selectedInvoiceForRejectionModel } from "../../../../../Models/RmsApprovals/selectedInvoiceForRejectionModel";
import { AxiosInstance } from "axios";
import { RoyaltyApprovalClient, ApproveRoyaltyReportBatchRm, RejectedInvoiceDto, RejectRoyaltySyncInvoicesRm, RejectRoyaltySyncInvoicesDto } from "@nbly/royalty-orchestrations-clients";
import { FranForceAxiosClientBuilder } from "../../../../Clients/FranForceAxiosClientBuilder";

export class rmsPreApprovalRejectInvoicesPopupController implements ng.IController {

    show: boolean;
    isLoading: boolean;
    rejectInvoicesDialog: kendo.ui.Dialog;
    rmsPreApprovalInvoiceRejectGrid: kendo.ui.Grid;
    rmsPreApprovalInvoiceRejectGridOptions: kendo.ui.GridOptions;
    royaltyOrchestrationsClient: AxiosInstance;
    royaltyApprovalClient: RoyaltyApprovalClient

    selectedInvoicesForRejection: selectedInvoiceForRejectionModel[];
    royaltyReportBatchId: number;
    isApproveBatch: boolean;

    static $inject = [
        "$rootScope",
        "$scope",
        "$timeout",
        "$window",
    ];

    constructor(
        private $rootScope: ng.IRootScopeService,
        private $scope: ng.IScope,
        private $timeout: ng.ITimeoutService,
        private $window: ng.IWindowService,
    ) {
        this.royaltyOrchestrationsClient = FranForceAxiosClientBuilder.BuildRoyaltyOrchestrationsBaseClient();
        this.royaltyApprovalClient = new RoyaltyApprovalClient("", this.royaltyOrchestrationsClient);
    }

    $onInit() {
        this.isLoading = false;
        this.show = false;
        this.LoadRmsPreApprovalInvoiceRejectGrid();
        this.selectedInvoicesForRejection = [];

        this.$rootScope.$on('preApprovalRejectInvoices', async (event, args) => {
            if (args) {
                this.show = args.show;
                this.isApproveBatch = false;
                if (this.show) {
                    this.rejectInvoicesDialog.open();
                }
            }
        });

        this.$rootScope.$on('preApprovalRejectInvoiceChanged', async (event, args) => {
            if (args) {
                this.selectedInvoicesForRejection = args.selectedInvoicesForRejection;
            }

            this.rmsPreApprovalInvoiceRejectGrid?.dataSource?.read();
        });

        this.$rootScope.$on('preApprovalApproveBatch', async (event, args) => {
            if (args) {
                this.show = args.show;
                this.royaltyReportBatchId = args.royaltyReportBatchId;
                this.isApproveBatch = true;
                if (this.show) {
                    this.rejectInvoicesDialog.open();
                }
            }
        });

    }

    LoadRmsPreApprovalInvoiceRejectGrid() {
        let rmsPreApprovalInvoiceRejectGridDataSource = new kendo.data.DataSource({
            transport: {
                read: (options) => {
                    options.success(this.selectedInvoicesForRejection);
                }
            },
            pageSize: 25,
            schema: {
                model: {
                    id: "invoiceId",
                    fields: {
                        invoiceId: {
                            type: "number"
                        },
                        licenseNumber: {
                            type: "string"
                        },
                        franchiseeName: {
                            type: "string"
                        },
                        rejectNote: {
                            type: "string"
                        },
                    }
                }
            }
        });


        let rmsPreApprovalInvoiceRejectGridColumns: Array<kendo.ui.GridColumn> =
            [
                {
                    field: "invoiceId",
                    title: "Invoice Id",
                },
                {
                    field: "licenseNumber",
                    title: "License Number",
                },
                {
                    field: "franchiseeName",
                    title: "Franchisee Name",
                },
                {
                    field: "rejectNote",
                    title: "Reject Note",
                    template: `<textarea ng-model="dataItem.rejectNote" ng-change="$ctrl.${nameof(this.OnRejectNoteChange)}(dataItem)" class=reject_note id=reject_note_{{invoiceId}} style='width:100%;resize:none' rows='2'></textarea>`,
                },
            ];

        this.rmsPreApprovalInvoiceRejectGridOptions = {
            autoBind: false,
            dataSource: rmsPreApprovalInvoiceRejectGridDataSource,
            columns: rmsPreApprovalInvoiceRejectGridColumns,
            sortable: true,
            scrollable: true,
            pageable: {
                numeric: true,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true,
            },
        };
    }

    OnRejectNoteChange(dataItem: selectedInvoiceForRejectionModel) {
        let objIndex = this.selectedInvoicesForRejection.findIndex((x => x.invoiceId == dataItem.invoiceId));
        this.selectedInvoicesForRejection[objIndex].rejectNote = dataItem.rejectNote;
    }

    $postLink() {
        if (this.rejectInvoicesDialog) {
            this.rejectInvoicesDialog.setOptions(<kendo.ui.DialogOptions>{
                title: "Reject Invoices",
                modal: true,
                width: "50%",
                maxHeight: 500,
                animation: true,
                actions: null,
                open: (e) => {                  
                    if (this.selectedInvoicesForRejection.length > 0) {
                        this.selectedInvoicesForRejection.forEach(x => {
                            x.rejectNote = "";
                        })
                    }
                    this.rmsPreApprovalInvoiceRejectGrid?.dataSource?.read();
                }
            })
        }
    }

    OnCancel() {
        this.rejectInvoicesDialog.close();
    }

    async OnReject() {

        if (this.selectedInvoicesForRejection.some(x => x.rejectNote.trim() == '')) {
            this.ShowRmsNotification("error", "Please enter reject reason for invoices to be rejected.");
            return;
        }

        if (this.isApproveBatch) {

            let rejectedInvoices: RejectedInvoiceDto[] = [];
            this.selectedInvoicesForRejection.forEach(x => {
                let rejectedInvoice: RejectedInvoiceDto = {
                    invoiceId: x.invoiceId,
                    note: x.rejectNote
                }
                rejectedInvoices.push(rejectedInvoice);
            })

            let approveRoyaltyReportBatchRm: ApproveRoyaltyReportBatchRm = {
                isApproved: true,
                royaltyReportBatchId: this.royaltyReportBatchId,
                user: "",
                rejectedBatchNote: null,
                rejectedInvoices: rejectedInvoices
            }

            this.isLoading = true;
            await this.royaltyApprovalClient.approveRoyaltyReportBatch(approveRoyaltyReportBatchRm)
                .then(x => {
                    this.$rootScope.$emit('reassignBatchApprovalStatus', {
                        royaltyApprovalStatusTypeId: 3 //approved
                    });
                    this.rejectInvoicesDialog.close();
                    this.$rootScope.$emit('preApprovalAllInputsSelected', null);
                    this.ShowRmsNotification("success", "Batch Approved Successfully. Selected Invoices Rejected");
                })
                .catch(ex => {
                    this.ShowRmsNotification("error", "Error! Unable To Approve Batch");
                })
                .finally(() => {
                    this.isLoading = false;
                    this.$scope.$digest();
                });
        }
        else {

            let rejectRoyaltySyncInvoicesDtoList: RejectRoyaltySyncInvoicesDto[] = []
            this.selectedInvoicesForRejection.forEach(x => {

                let rejectRoyaltySyncInvoicesDto: RejectRoyaltySyncInvoicesDto = {
                    invoiceId: x.invoiceId,
                    note: x.rejectNote
                }

                rejectRoyaltySyncInvoicesDtoList.push(rejectRoyaltySyncInvoicesDto);
            })

            let rejectRoyaltySyncInvoicesRm: RejectRoyaltySyncInvoicesRm = {
                rejectRoyaltySyncInvoicesDto: rejectRoyaltySyncInvoicesDtoList
            }

            this.isLoading = true;
            await this.royaltyApprovalClient.rejectRoyaltySyncInvoices(rejectRoyaltySyncInvoicesRm)
                .then(response => {
                    this.rejectInvoicesDialog.close();
                    this.$rootScope.$emit('preApprovalAllInputsSelected', null);
                    this.ShowRmsNotification("success", "Invoices Rejected Successfully.");
                })
                .catch(ex => {
                    this.ShowRmsNotification("error", "Error! Unable To Reject Invoices");
                })
                .finally(() => {
                    this.isLoading = false;
                    this.$scope.$digest();
                })
        }
    }

    ShowRmsNotification(type: "error" | "success", message: string) {
        let elementId = "rms-notification-element";
        let notificationElement = this.$window.document.getElementById(elementId);
        if (!notificationElement) {
            notificationElement = this.$window.document.createElement("div");
            notificationElement.id = elementId;
            this.$window.document.body.appendChild(notificationElement);
        }

        let notification = angular.element(notificationElement).kendoNotification({
            autoHideAfter: 4000,
            position: {
                pinned: true,
                top: 60,
                right: 0,
            },
            animation: {
                open: {
                    effects: "slideIn:left"
                },
                close: {
                    effects: "slideIn:left",
                    reverse: true
                }
            },
            stacking: "down",
        }).data("kendoNotification");

        notification.show(message, type);
    }

    static BindComponent(app: ng.IModule) {
        app.component("rmsPreApprovalRejectInvoicesPopup", {
            bindings: {

            },
            templateUrl: '/Templates/RMSApproval/RmsPreApprovalQueue/rmsPreApprovalRejectInvoicesPopup.html',
            controller: rmsPreApprovalRejectInvoicesPopupController
        });
    }
}