import { ApiConfig } from "../../AppConfig/ApiConfig";

export class ServiceManagerApiResources {

    static $inject = [
        "$http",
        "apiConfig"
    ];

    constructor(
        private $http: ng.IHttpService,
        private apiConfig: ApiConfig
    ) {
    }

    GenerateUUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    };

    //upload appfolio
    UploadAppfolio(files: any) {
        let urlString = `/api/rpmdatasync/servicetriggers/adhoc/appfolio/upload`;

        const formData = new FormData();

        files.forEach(item => {
            formData.append('Files', item);
        })

        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.ServiceManagerUrl + urlString,
            data: formData,
            headers: { 'Content-Type': undefined, 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<any>(config);
    }

    //upload propertyware orderId's
    UploadPropertywareOwnerIds(file: any) {
        let urlString = `/api/rpmdatasync/servicetriggers/adhoc/propertyware/ownerids/upload`;

        const formData = new FormData();

        formData.append('File', file);

        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.ServiceManagerUrl + urlString,
            data: formData,
            headers: { 'Content-Type': undefined, 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<any>(config);
    }

    //upload propertyware orders
    UploadPropertywareOwners(file: any) {
        let urlString = `/api/rpmdatasync/servicetriggers/adhoc/propertyware/owners/upload`;

        const formData = new FormData();

        formData.append('File', file);

        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.ServiceManagerUrl + urlString,
            data: formData,
            headers: { 'Content-Type': undefined, 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<any>(config);
    }

    //upload propertyware buildings
    UploadPropertywareBuildings(file: any) {
        let urlString = `/api/rpmdatasync/servicetriggers/adhoc/propertyware/buildings/upload`;

        const formData = new FormData();

        formData.append('File', file);

        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.ServiceManagerUrl + urlString,
            data: formData,
            headers: { 'Content-Type': undefined, 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<any>(config);
    }

    //upload propertyware metrics
    UploadPropertywareMetrics(file: any) {
        let urlString = `/api/rpmdatasync/servicetriggers/adhoc/propertyware/metrics/upload`;

        const formData = new FormData();

        formData.append('File', file);

        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.apiConfig.AppSettings.ServiceManagerUrl + urlString,
            data: formData,
            headers: { 'Content-Type': undefined, 'X-Correlation-Id': this.GenerateUUID() }
        };

        return this.$http<any>(config);
    }
}