import { DppApiResources } from "Services/Resources/DisasterPrepProfileApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import { IEditDisasterPrepProfileSectionScope } from "@DppApp/EditDisasterPrepProfileSectionController";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";

interface IEditDisasterPrepProfileBuildingUtilitiesScope extends IEditDisasterPrepProfileSectionScope {
    AppSettings: IAppSettings;
    standardUtilityTypes: DppResources.IDisasterPrepProfileUtilityType[];
    profileUtilityTypes: DppResources.IDisasterPrepProfileUtilityType[];
    availableDppBuildingAssets: DppResources.IDisasterPrepProfileBuildingAsset[];
    isTypeExpanded: { [typeId: number]: boolean }; 

    SaveUtilities: () => ng.IPromise<any>;
    RefreshAssets: () => ng.IPromise<any>;
    RefreshUtilities: () => ng.IPromise<any>;

    newUtilityTypeName: string;
    RenameUtilityType: (serviceType: DppResources.IDisasterPrepProfileUtilityType, newName: string) => void;
    CreateUtilityType: (name: string) => void;
    DeleteUtilityType: (utilityType: DppResources.IDisasterPrepProfileUtilityType) => void;
    OnAssetSelectionForType: (asset: DppResources.IDisasterPrepProfileBuildingAsset, utilityType: DppResources.IDisasterPrepProfileUtilityType) => void;
    RemoveBuildingUtilityAsset: (assetRelation: DppResources.IDisasterPrepProfileBuildingUtilityAsset) => ng.IPromise<any>;
}
export class EditDisasterPrepProfileBuildingUtilitiesController
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.controller('EditDisasterPrepProfileBuildingUtilitiesController',
            [
                '$scope', '$q', "dppApiResources", 'odataHelper', 'apiConfig',
                ($scope: IEditDisasterPrepProfileBuildingUtilitiesScope, $q: ng.IQService, dppApiResources: DppApiResources, odataHelper: ODataHelperService, apiConfig: ApiConfig) =>
                {
                    if (!$scope.dppBuildingId) {
                        $scope.RedirectHome();
                        return;
                    }
                    console.log("AppSettings", apiConfig.AppSettings);
                    $scope.AppSettings = apiConfig.AppSettings;

                    $scope.RefreshAssets = () =>
                    {
                        let assetQueryParams = {
                            $select: dppApiResources.DppAssetSelectParametersWithoutFileContent.join(","),
                            $filter: "DppId eq " + $scope.dppId + " and (DppBuildingId eq " + $scope.dppBuildingId + " or DppBuildingId eq null)",
                            $orderby: "CreatedDateTime desc",
                            onlyImages: true
                        };
                        let assetPromise = dppApiResources.DisasterPrepProfileBuildingAssetApi.query(assetQueryParams).$promise.then(
                            (assets: DppResources.IDisasterPrepProfileBuildingAsset[]) =>
                            {
                                $scope.availableDppBuildingAssets = assets;
                            },
                            (err) => { console.error(err); }
                        );

                        $scope.QueueLoadingPromises(assetPromise);
                        return assetPromise;
                    }

                    $scope.RefreshUtilities = () =>
                    {
                        let paramsForUtilityTypes = {
                            $expand: "DisasterPrepProfileBuildingUtilities($filter=DppBuildingId eq " + $scope.dppBuildingId + ")," +
                                "DisasterPrepProfileBuildingUtilityAssets(" +
                                "$filter=DppBuildingId eq " + $scope.dppBuildingId + ";" +
                                "$expand=DisasterPrepProfileBuildingAsset($select=" + dppApiResources.DppAssetSelectParametersWithoutFileContent.join(",") + ")" +
                                ")",
                            $filter: "DppId eq " + $scope.dppId + " or DppId eq null"
                        };

                        let utilitiesPromise = dppApiResources.DisasterPrepProfileUtilityTypeApi.query(paramsForUtilityTypes).$promise.then(
                            (allUtilityTypes: DppResources.IDisasterPrepProfileUtilityType[]) =>
                            {
                                $scope.isTypeExpanded = {};
                                for (var utilType of allUtilityTypes) {
                                    if (utilType.DisasterPrepProfileBuildingUtilities.length > 1) {
                                        console.warn(`More than one utility brought back '${utilType.DisasterPrepProfileBuildingUtilities.length}' when there should be 0 to 1.` +
                                            `Changes will only happen to the first element.`, utilType.DisasterPrepProfileBuildingUtilities);
                                    }

                                    if (utilType.DisasterPrepProfileBuildingUtilities.length === 0) {
                                        let newUtility = new dppApiResources.DisasterPrepProfileBuildingUtilityApi();
                                        newUtility.DppBuildingId = $scope.dppBuildingId;
                                        newUtility.DppUtilityTypeId = utilType.DppUtilityTypeId;
                                        utilType.DisasterPrepProfileBuildingUtilities.push(newUtility);
                                        $scope.isTypeExpanded[utilType.DppUtilityTypeId] = false;
                                    }
                                    else {
                                        $scope.isTypeExpanded[utilType.DppUtilityTypeId] = (!!utilType.DisasterPrepProfileBuildingUtilities[0].ProviderName ||
                                            !!utilType.DisasterPrepProfileBuildingUtilities[0].AccountNumber ||
                                            !!utilType.DisasterPrepProfileBuildingUtilities[0].OtherDetails);
                                    }
                                }

                                $scope.standardUtilityTypes = allUtilityTypes.filter((value) => { return value.DppId === null });
                                $scope.profileUtilityTypes = allUtilityTypes.filter((value) => { return value.DppId === $scope.dppId });
                            },
                            (err: ng.IHttpPromiseCallbackArg<any>) =>
                            {
                                console.error(err);
                                alert("Could not get the utility types for this building.");
                            });

                        $scope.QueueLoadingPromises(utilitiesPromise);
                        return utilitiesPromise;
                    };

                    $scope.RefreshAssets().then(() =>
                    {
                        $scope.RefreshUtilities();
                    });


                    $scope.RenameUtilityType = (utilityType: DppResources.IDisasterPrepProfileUtilityType, newName: string) =>
                    {
                        utilityType.Name = newName;
                        utilityType.$update({ id: utilityType.DppUtilityTypeId },
                            (response) =>
                            {
                                console.log(response);
                            },
                            (err) =>
                            {
                                console.error(err);
                            });
                    };

                    $scope.CreateUtilityType = (name: string) =>
                    {
                        let newType = new dppApiResources.DisasterPrepProfileUtilityTypeApi();
                        newType.DppId = $scope.dppId;
                        newType.Name = name;

                        newType.$save(
                            (response: DppResources.IDisasterPrepProfileUtilityType) =>
                            {
                                $scope.newUtilityTypeName = "";
                                console.log(response);
                                let newUtility = new dppApiResources.DisasterPrepProfileBuildingUtilityApi();
                                newUtility.DppBuildingId = $scope.dppBuildingId;
                                newUtility.DppUtilityTypeId = response.DppUtilityTypeId;
                                newType.DisasterPrepProfileBuildingUtilities.push(newUtility);
                                $scope.profileUtilityTypes.push(newType);
                            },
                            (err) =>
                            {
                                console.error(err);
                            });
                    }

                    $scope.DeleteUtilityType = (utilityType: DppResources.IDisasterPrepProfileUtilityType) =>
                    {
                        utilityType.$delete({ id: utilityType.DppUtilityTypeId, allowHardDelete: true },
                            (response) =>
                            {
                                console.log(response);
                                $scope.profileUtilityTypes.splice($scope.profileUtilityTypes.indexOf(utilityType), 1);
                            },
                            (err) =>
                            {
                                console.error(err);
                            });
                    };


                    $scope.SaveUtilities = () =>
                    {
                        let savePromises: Array<ng.IPromise<any>> = [];

                        return dppApiResources.DisasterPrepProfileBuildingUtilityApi.query({ $filter: "DppBuildingId eq " + $scope.dppBuildingId }).$promise.then(
                            (existingUtilities: DppResources.IDisasterPrepProfileBuildingUtility[]) =>
                            {
                                let allUtilityTypes = $scope.standardUtilityTypes.slice().concat($scope.profileUtilityTypes.slice());
                                console.log("All utility types. ", allUtilityTypes);

                                let utilitiesExisting: DppResources.IDisasterPrepProfileBuildingUtility[] = [];
                                let providersMissing: DppResources.IDisasterPrepProfileBuildingUtility[] = [];
                                allUtilityTypes.forEach(
                                    (utValue) =>
                                    {
                                        let utilityExists = existingUtilities.some((xuValue) =>
                                        {
                                            return utValue.DppUtilityTypeId === xuValue.DppUtilityTypeId;
                                        });

                                        if (utilityExists)
                                            utilitiesExisting.push(<DppResources.IDisasterPrepProfileBuildingUtility>utValue.DisasterPrepProfileBuildingUtilities[0]);
                                        else
                                            providersMissing.push(<DppResources.IDisasterPrepProfileBuildingUtility>utValue.DisasterPrepProfileBuildingUtilities[0]);
                                    });


                                for (let updateProvider of utilitiesExisting) {
                                    console.log(updateProvider);
                                    let promise = dppApiResources.DisasterPrepProfileBuildingUtilityApi.update(
                                        {
                                            id: odataHelper.ConvertCompositeKeyToString({
                                                DppBuildingId: updateProvider.DppBuildingId,
                                                DppUtilityTypeId: updateProvider.DppUtilityTypeId
                                            })
                                        },
                                        updateProvider,
                                        (response) => { console.log(response); },
                                        (err) => { console.error(err); }).$promise;

                                    savePromises.push(promise);
                                }
                                for (let createProvider of providersMissing) {
                                    let promise = dppApiResources.DisasterPrepProfileBuildingUtilityApi.save(
                                        {},
                                        createProvider,
                                        (response) => { console.log(response); },
                                        (err) => { console.error(err); }).$promise;
                                    savePromises.push(promise);
                                }

                                return $q.all(savePromises).then(
                                    (responses) =>
                                    {
                                        console.log(responses);
                                        return $scope.RefreshUtilities();
                                    },
                                    (err) =>
                                    {
                                        console.error(err);
                                    });
                            },
                            (err: ng.IHttpPromiseCallbackArg<any>) =>
                            {
                                console.error(err);
                            });
                    }

                    $scope.OnAssetSelectionForType = (asset: DppResources.IDisasterPrepProfileBuildingAsset, utilityType: DppResources.IDisasterPrepProfileUtilityType) =>
                    {
                        let newAssetRelation = new dppApiResources.DisasterPrepProfileBuildingUtilityAssetApi();

                        newAssetRelation.DppUtilityTypeId = utilityType.DppUtilityTypeId;
                        newAssetRelation.DppBuildingAssetId = asset.DppBuildingAssetId;
                        newAssetRelation.DppBuildingId = $scope.dppBuildingId;

                        let newAssetRelationPromise = newAssetRelation.$save().then(
                            (assetRelation: DppResources.IDisasterPrepProfileBuildingUtilityAsset) =>
                            {
                                assetRelation.DisasterPrepProfileBuildingAsset = asset;
                                utilityType.DisasterPrepProfileBuildingUtilityAssets.push(assetRelation);
                            },
                            (err: ng.IHttpPromiseCallbackArg<any>) =>
                            {
                                if (err.status !== 409) //Conflict
                                {
                                    console.error(err);
                                }
                            }
                        );

                        $scope.QueueLoadingPromises(newAssetRelationPromise);
                    }

                    $scope.RemoveBuildingUtilityAsset = (assetRelation: DppResources.IDisasterPrepProfileBuildingUtilityAsset) =>
                    {
                        let removeAssetRelationPromise = dppApiResources.DisasterPrepProfileBuildingUtilityAssetApi.delete({
                            id: odataHelper.ConvertCompositeKeyToString({
                                DppBuildingAssetId: assetRelation.DppBuildingAssetId,
                                DppBuildingId: assetRelation.DppBuildingId,
                                DppUtilityTypeId: assetRelation.DppUtilityTypeId
                            }),
                            allowHardDelete: true
                        }).$promise.then(
                            (data) =>
                            {
                                let typeToRemoveFrom = $scope.profileUtilityTypes.filter(utilType => utilType.DppUtilityTypeId === assetRelation.DppUtilityTypeId)[0];
                                if (typeToRemoveFrom) {
                                    typeToRemoveFrom.DisasterPrepProfileBuildingUtilityAssets = typeToRemoveFrom.DisasterPrepProfileBuildingUtilityAssets.filter(
                                        utilAsset => utilAsset.DppBuildingAssetId !== assetRelation.DppBuildingAssetId
                                    );
                                }

                                typeToRemoveFrom = $scope.standardUtilityTypes.filter(utilType => utilType.DppUtilityTypeId === assetRelation.DppUtilityTypeId)[0];
                                if (typeToRemoveFrom) {
                                    typeToRemoveFrom.DisasterPrepProfileBuildingUtilityAssets = typeToRemoveFrom.DisasterPrepProfileBuildingUtilityAssets.filter(
                                        utilAsset => utilAsset.DppBuildingAssetId !== assetRelation.DppBuildingAssetId
                                    );
                                }
                            },
                            (err: ng.IHttpPromiseCallbackArg<any>) =>
                            {
                                if (err.status !== 404) {
                                    console.error(err);
                                    return err;
                                }
                                else {
                                    return $q.when(err.data);
                                }
                            }
                        );

                        $scope.QueueLoadingPromises(removeAssetRelationPromise);

                        return removeAssetRelationPromise;
                    }
                }
            ]);
    }
}

