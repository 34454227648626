import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RestAxiosClient } from "Clients/RestAxiosClient";
import { IRestAxiosClient } from "Clients/IRestAxiosClient";

const royaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationClient = new RestAxiosClient<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulation, number>(
    FranForceAxiosClientBuilder.BuildCoreBaseClient(),
    "/RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulations"
) as IRestAxiosClient<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulation, number>;

export { royaltyDisclosureDocumentRatePlanMinimumsTerritoryPopulationClient };