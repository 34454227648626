import { SyncResources } from "../../../Interfaces/FranForce/Sync/SyncResources";
import {
    FranchiseeManagementClient,
    UpdateFranchiseeManagementPosFieldsRm,
    FranchiseeManagementPosFieldsDto
} from '@nbly/royalty-orchestrations-clients';
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import { IdentityManager } from "../../../Services/Resources/IdentityManager";
import { SyncApiResources } from "../../../Services/Resources/SyncApiResources";
import { IFranchiseeStateParams } from "./fmRouter";
import { CoreApiResources } from "../../../Services/Resources/CoreApiResources";
import { FranchiseOrchestrationsApiResources } from "Services/Resources/FranchiseOrchestrationsApiResources";
import { FranchiseeManagementPosResult } from "../../../Models/FmPos/FranchiseeManagementPosResult";


export class fmPosController implements ng.IController {

    readOnly: boolean;
    franchisePos: FranchiseeManagementPosResult;
    franchiseRoyalty: IFranchiseRoyaltyFields;
    isLoading: boolean;
    saveFranchiseDataStatusCSSClass: string;
    showSaveFranchiseDataStatus: boolean;
    saveFranchiseDataStatusMessage: string;
    syncSourceTypes: SyncResources.ISyncSourceType[];
    royaltyOrchestrationsClient: AxiosInstance;
    franchiseeManagementClient: FranchiseeManagementClient;
    franchiseOrchestrationsApiResources: FranchiseOrchestrationsApiResources;
    

    //this will contain franchise royalty data on load
    initLoadFranchisePos: FranchiseeManagementPosFieldsDto;
    initLoadFranchiseRoyalty: IFranchiseRoyaltyFields;

    showClientSecretFields: boolean;
    showPosTenantId:boolean
    clientIdToggleClass: string;
    clientIdFieldType: string;
    clientSecretToggleClass: string;
    clientSecretFieldType: string;

    static $inject = [
        "$scope",
        "identityManager",
        "syncApiResources",
        "$stateParams",
        "coreApiResources",
        "$q",
        "franchiseOrchestrationsApiResources"
    ];
    constructor(
        private $scope: ng.IScope,
        private identityManager: IdentityManager,
        private syncApiResources: SyncApiResources,
        private $stateParams: IFranchiseeStateParams,
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        franchiseOrchestrationsApiResources: FranchiseOrchestrationsApiResources
    ) {
        this.franchiseOrchestrationsApiResources = franchiseOrchestrationsApiResources;
        this.royaltyOrchestrationsClient = FranForceAxiosClientBuilder.BuildRoyaltyOrchestrationsBaseClient();
        this.franchiseeManagementClient = new FranchiseeManagementClient("", this.royaltyOrchestrationsClient);
    }

    $onInit() {
        this.isLoading = false;
        this.SetClientFiledsToDefault();
        this.SetPosTeantIdToDefault();
        this.SetSaveFranchiseDataStatus("", false, "");
        this.CheckIfReadOnly();
        this.LoadSyncSourceTypes();
        this.RefreshData();

        this.$scope.$on('franchiseeSelected', (event, args) => {
            this.RefreshData();
        });
    }

    LoadSyncSourceTypes() {
        this.syncApiResources.SyncSourceTypesApi.query({}).$promise.then(syncSourceTypes => {
            if (syncSourceTypes && syncSourceTypes.length > 0) {
                let slectDefaultOption: SyncResources.ISyncSourceType = {
                    ...syncSourceTypes[0],
                    SyncSourceTypeID: 0,
                    Name: "Select a Sync Source Type"
                };
                this.syncSourceTypes = [slectDefaultOption].concat(syncSourceTypes)
            }
        });
    }

    SetClientFiledsToDefault() {
        this.showClientSecretFields = false;
        this.clientIdToggleClass = "fa fa-fw fa-eye-slash";
        this.clientIdFieldType = "password";
        this.clientSecretToggleClass = "fa fa-fw fa-eye-slash";
        this.clientSecretFieldType = "password";
    }
    SetPosTeantIdToDefault() {
        this.showPosTenantId = false;
    }

    CheckIfReadOnly() {
        this.identityManager.HasRole(["Admin", "POSAdmin", "BillingAdmin"]).then(hasRole => {
            this.readOnly = !hasRole;
        });
    }

    OnSyncSourceTypeChange() {
        this.showClientSecretFields = this.franchiseRoyalty.SyncSourceTypeID == 28 ? true : false;
        this.showPosTenantId = this.franchiseRoyalty.SyncSourceTypeID == 1 ? true : false;
    }

    OnToggeleView(field: string) {
        if (field == "clientId") {
            if (this.clientIdToggleClass == "fa fa-fw fa-eye-slash") {
                this.clientIdToggleClass = "fa fa-fw fa-eye";
                this.clientIdFieldType = "text";
                return;
            }

            if (this.clientIdToggleClass == "fa fa-fw fa-eye") {
                this.clientIdToggleClass = "fa fa-fw fa-eye-slash";
                this.clientIdFieldType = "password";
                return;
            }
        }

        if (field == "clientSecret") {
            if (this.clientSecretToggleClass == "fa fa-fw fa-eye-slash") {
                this.clientSecretToggleClass = "fa fa-fw fa-eye";
                this.clientSecretFieldType = "text";
                return;
            }

            if (this.clientSecretToggleClass == "fa fa-fw fa-eye") {
                this.clientSecretToggleClass = "fa fa-fw fa-eye-slash";
                this.clientSecretFieldType = "password";
                return;
            }
        }
    }

    RefreshData() {
        let franchiseId = parseInt(this.$stateParams.franchiseeID)

        let posPromise = this.franchiseOrchestrationsApiResources.GetFranchiseeManagementPosFields(franchiseId)
            .then(response => {
                if (response) {
                    this.franchisePos = response.data;
                    this.initLoadFranchisePos = { ...response.data };
                    this.showClientSecretFields = response.data.syncSourceTypeId == 28 ? true : false;
                    this.showPosTenantId = response.data.syncSourceTypeId == 1 ? true : false;
                }
            })

        let royaltyPromise = this.coreApiResources.GetFranchiseRoyaltyInfo(franchiseId).then((royalty) => {
            this.franchiseRoyalty = royalty.data;
            this.initLoadFranchiseRoyalty = { ...royalty.data };
        });

        return this.$q.all([posPromise, royaltyPromise]);
    }

    SetSaveFranchiseDataStatus(className: string, show: boolean, message: string) {
        this.saveFranchiseDataStatusCSSClass = className;
        this.showSaveFranchiseDataStatus = show;
        this.saveFranchiseDataStatusMessage = message;
    }

    convertDateToISOString(date: Date) {
        if (date == null) return date;
        var timestamp = date.getTime() - date.getTimezoneOffset() * 60000;
        var correctDate = new Date(timestamp);
        return correctDate.toISOString();
    }

    SaveChanges() {

        if (this.franchiseRoyalty.SyncSourceTypeID == 0) {
            this.SetSaveFranchiseDataStatus("saveFranchiseDataStatusError", true, "Please select Sync Source Type.");
            return;
        }
        else {
            this.SetSaveFranchiseDataStatus("", false, "");
        }

        let posLiveDate = "";
        if ((this.franchisePos == undefined)
            || (this.franchisePos && this.franchisePos.posLiveDate == null)
            || (this.franchisePos && this.franchisePos.posLiveDate == undefined)
            || (this.franchisePos && this.franchisePos.posLiveDate.trim() == "")) {
            posLiveDate = null;
        }
        else {
            posLiveDate = this.convertDateToISOString(new Date(this.franchisePos.posLiveDate)).toString();
        }

        let updateFranchiseeManagementPosFields: UpdateFranchiseeManagementPosFieldsRm = {
            syncSourceTypeId: this.franchiseRoyalty.SyncSourceTypeID,
            posKey: this.franchisePos?.posKey ?? null,
            posApiKey: this.franchisePos?.posApiKey ?? null,
            posLiveDate: posLiveDate,
            clientId: this.franchisePos?.clientId ?? null,
            clientSecret: this.franchisePos?.clientSecret ?? null,
            isQvinci: this.franchiseRoyalty?.IsQvinci ?? null
        }

        let franchiseId = parseInt(this.$stateParams.franchiseeID);

        this.isLoading = true;

        this.franchiseeManagementClient.merge2(franchiseId, updateFranchiseeManagementPosFields)
            .then(() => {
                this.initLoadFranchisePos = { ...this.franchisePos };
                this.$scope.$emit('showNotification', { saveSuccessful: true, text: "Successfully saved Franchise info" });
            })
            .catch(() => {
                this.$scope.$emit('showNotification', { saveSuccessful: false, text: "Failed to saved Franchise info" });
            })
            .finally(() => {
                this.isLoading = false;
                this.$scope.$digest();
            });
    }

    CancelChanges() {
        this.RefreshData();

        this.coreApiResources.UpdateFranchisePos
    }
}