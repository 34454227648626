import { BaseStateHandler } from "StateHandlers/BaseStateHandler";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyDisclosureDocumentStateHandler extends BaseStateHandler<RpmEntities.DisclosureDocumentViewModel, number> {

    constructor(
        private rpmUiApiResources: RpmUiApiResources
    )
    {
        super();
    }

    Get(disclosureDocumentId: number, isDynamicFees?: boolean): angular.IPromise<RpmEntities.DisclosureDocumentViewModel> {
        if (isDynamicFees) {
            return this.rpmUiApiResources.GetDisclosureDocumentByIdDynamic(disclosureDocumentId)
                .then((ddResponse) => {
                    return ddResponse.data;
                });
        } else {
            return this.rpmUiApiResources.GetDisclosureDocumentById(disclosureDocumentId)
                .then((ddResponse) => {
                    return ddResponse.data;
                });
        }
    }
}