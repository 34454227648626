import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { ApiConfig } from "AppConfig/ApiConfig";
import * as _ from "underscore"
import { CallCenterResources } from "Interfaces/FranForce/CallCenter/CallCenterResources";
import { AngularUtil } from "Utility/AngularUtil";
import { userStateObj, EditUserStateService } from "Services/State/EditUserStateService";
import { CoreResources, CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

export class EditUserCallCentersComponentController implements ng.IController
{
    managedUserId: string;
    refreshDate: Date;
    refresh: (params: { refreshedDate: Date }) => void;

    userState: userStateObj;
    isLoading: boolean;

    isViewable: boolean;

    assignedConceptIds: number[];
    canManageCallCenterUser: boolean;
    assignedCallCenterId: number;
    callCenterDropDown: kendo.ui.DropDownList;
    callCenterDropDownOptions: kendo.ui.DropDownListOptions;
    conceptFilters: kendo.data.DataSourceFilters;

    conceptSelect: string[];
    conceptTemplate: string;


    selectedConcepts: any[];
    managedConceptIds: number[];


    static $inject = [
        'editUserState',
        'coreApiResources',
        'callCenterApiResources',
        'identityManager',
        'odataHelper',
        'apiConfig',
        '$timeout',
        '$q',

    ];

    constructor(
        private editUserState: EditUserStateService,
        private coreApiResources: CoreApiResources,
        private callCenterApiResources: CallCenterApiResources,
        private identityManager: IdentityManager,
        private odataHelper: ODataHelperService,
        private apiConfig: ApiConfig,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
    )
    {
    }

    $onInit()
    {
        this.InitCallCenterDropDownOptions();

        this.conceptSelect = [
            nameof<CoreResources.IConcept>(o => o.ConceptId),
            nameof<CoreResources.IConcept>(o => o.ConceptCode),
            nameof<CoreResources.IConcept>(o => o.DisplayName),
        ];

        this.conceptTemplate = `
            <div class="ids">
                <strong>{{${nameof<CoreEntities.Concept>(o => o.ConceptCode)}}}</strong> - ({{${nameof<CoreEntities.Concept>(o => o.ConceptId)}}})
            </div>
            <div class="display-name">
                {{${nameof<CoreEntities.Concept>(o => o.DisplayName)}}}
            </div>`;
    }

    $postLink()
    {
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        this.Load();
    }

    Load()
    {
        let promise = this.editUserState.Load(this.managedUserId)
            .then((result) =>
            {
                this.userState = result;
            })
            .then(() =>
            {
                this.identityManager.CanManageCallCenterUser(this.userState.managedUser.Email)
                    .then((response) =>
                    {
                        this.canManageCallCenterUser = response.data.CanManage;

                        this.isViewable = (this.userState.managedUser ? true : false) &&
                            (this.canManageCallCenterUser || this.userState.isManagedUserReadOnly);

                    });

                this.callCenterDropDown.enable(!this.userState.isManagedUserReadOnly);

                this.assignedConceptIds = this.userState.managedUser.Claims
                    .filter((claim) =>
                    {
                        return claim.Type === this.apiConfig.FranForceConstants.ClaimConstants.ManagedCallCenterConceptIdClaimType;
                    })
                    .map(claim => parseInt(claim.Value));

                this.ReloadDropDown();
            });

        return AngularUtil.TrackLoadingPromise(promise, this);
    }

    InitCallCenterDropDownOptions()
    {
        this.assignedCallCenterId = null;
        this.callCenterDropDownOptions = {
            optionLabelTemplate: "(Unassigned)",
            optionLabel: "(Unassigned)",
            autoBind: false,
            dataTextField: nameof<CallCenterResources.IC4CallCenter>(o => o.Name),
            dataValueField: nameof<CallCenterResources.IC4CallCenter>(o => o.C4CallCenterId),
            select: (e) =>
            {
                let callCenter: CallCenterResources.IC4CallCenter = e.dataItem;
                let promise: ng.IPromise<any>;
                this.isLoading = true;

                if (callCenter.C4CallCenterId)
                {
                    if (callCenter.C4CallCenterId != this.assignedCallCenterId)
                    {
                        promise = this.identityManager.AddC4CallCenterPermission({
                            Email: this.userState.managedUser.Email,
                            C4CallCenterId: callCenter.C4CallCenterId
                        })
                            .then(() =>
                            {
                                this.assignedCallCenterId = callCenter.C4CallCenterId;
                                this.RefreshUser();

                                this.RefreshConceptFilters();
                            })
                    }
                    else
                    {
                        promise = this.$q.resolve();
                    }
                }
                else
                {
                    promise = this.identityManager.RemoveC4CallCenterPermission({
                        Email: this.userState.managedUser.Email,
                        C4CallCenterId: 0
                    })
                        .then(() =>
                        {
                            this.assignedCallCenterId = 0;

                            this.RefreshUser();
                        });
                }

                return promise
                    .finally(() =>
                    {
                        this.isLoading = false;
                    });
            },
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) =>
                    {
                        this.isLoading = true;

                        let isAdminUser = this.userState.selfUser.Roles.some((role) =>
                        {
                            return role.Name === this.apiConfig.FranForceConstants.RoleConstants.AdminRole ||
                                role.Name === this.apiConfig.FranForceConstants.RoleConstants.CallCenterAdmin
                                 || role.Name ==this.apiConfig.FranForceConstants.RoleConstants.CallCenterAccountManager;
                        });

                        let parameters: ODataQueryParameters = {};

                        if (!isAdminUser)
                        {
                            let assignableCallCenterIds = this.userState.selfUser.Claims
                                .filter(c => c.Type == this.apiConfig.FranForceConstants.ClaimConstants.ManagedCallCenterIdClaimType)
                                .map(claim => parseInt(claim.Value));
                            parameters.$filter = this.odataHelper.CreateInFilterStatement(nameof<CallCenterResources.IC4CallCenter>(o => o.C4CallCenterId), assignableCallCenterIds)
                        }

                        this.callCenterApiResources.C4CallCentersApi.query(parameters).$promise
                            .then((callCenters) =>
                            {
                                options.success(callCenters);
                            })
                            .catch((ex) =>
                            {
                                options.error(ex);
                            })
                            .finally(() => { this.isLoading = false; })
                    }
                },
                requestEnd: (e) =>
                {
                    let claim = _.find(this.userState.managedUser.Claims, c => c.Type === this.apiConfig.FranForceConstants.ClaimConstants.ManagedCallCenterIdClaimType);
                    this.assignedCallCenterId = claim ? parseInt(claim.Value) : null;

                    if (this.assignedCallCenterId)
                    {
                        this.RefreshConceptFilters();
                    }

                    this.$timeout(() =>
                    {
                        this.callCenterDropDown.select((callCenter: CallCenterResources.IC4CallCenter) =>
                        {
                            return callCenter.C4CallCenterId == this.assignedCallCenterId;
                        });
                    }, 0);
                }
            })
        };
    }

    RefreshConceptFilters()
    {
        let params: ODataQueryParameters = {
            $filter: `${nameof<CallCenterResources.IC4CallCentersServicingConcept>(o => o.C4CallCenterId)} eq ${this.assignedCallCenterId}`
        }

        this.callCenterApiResources.C4CallCentersServicingConceptsApi.query(params).$promise
            .then((servicedConcepts) =>
            {
                let conceptIds = servicedConcepts.map((sc) => { return sc.ConceptId });
                this.conceptFilters = {
                    logic: "or",
                    filters: conceptIds.map<kendo.data.DataSourceFilterItem>(cId =>
                    {
                        return {
                            field: nameof<CallCenterResources.IC4CallCentersServicingConcept>(o => o.ConceptId),
                            operator: "eq",
                            value: cId
                        };
                    })
                };
            });
    }

    ReloadDropDown()
    {
        return this.callCenterDropDown.dataSource.read().then(() =>
        {
            this.callCenterDropDown.refresh();
        });
    }

    async AddConceptRestriction()
    {
        if (this.selectedConcepts && this.selectedConcepts.length)
        {
            await Promise.all(
                this.selectedConcepts.map(async (concept) =>
                {

                    return this.identityManager.AddC4CallCenterConceptPermission({
                        ConceptId: concept.ConceptId,
                        Email: this.userState.managedUser.Email
                    })
                        .then(() =>
                        {
                            /*   if (this.assignedConceptIds.indexOf(concept.ConceptId) === -1)
                             {
                                 this.assignedConceptIds.push(concept.ConceptId);
 
                             } */

                        })
                        .catch(AngularUtil.GetJsonAlertCatch());
                })

            ).then(() =>
            {
                this.selectedConcepts = [];
                this.RefreshUser();
            })
        }
    }



    RemoveConceptRestriction(conceptId: number)
    {
        return this.identityManager.RemoveC4CallCenterConceptPermission({
            ConceptId: conceptId,
            Email: this.userState.managedUser.Email
        })
            .then(() =>
            {
                this.assignedConceptIds = _.filter(this.assignedConceptIds, (id) =>
                {
                    return id != conceptId;
                });

                this.RefreshUser();
            })
            .catch(AngularUtil.GetJsonAlertCatch());;
    }


    RefreshUser()
    {
        this.editUserState.Clear(this.userState.managedUser.Id);
        if (this.refresh)
        {
            this.refresh({
                refreshedDate: new Date()
            });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("editUserCallCenters", {
            bindings: {
                [nameof<EditUserCallCentersComponentController>(o => o.managedUserId)]: "<",
                [nameof<EditUserCallCentersComponentController>(o => o.refreshDate)]: "<",
                [nameof<EditUserCallCentersComponentController>(o => o.refresh)]: "&"
            },
            controller: EditUserCallCentersComponentController,
            templateUrl: "/Templates/Users/EditUser/EditUserCallCenters.html"
        });
    }
}


