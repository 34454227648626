import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RmsStateService } from "Services/State/RmsState";
import * as _ from "underscore"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { RoyaltyPeriodReportDetailViewModel } from "Interfaces/FranForce/Rpm/PeriodReport/RatePlanItemModel";

export class PeriodReportTafsFeesComponentController  implements ng.IController 
{
    royaltyPeriodReportId: number;
    franchise: Partial<CoreEntities.Franchise>;
    init: (params: { self: PeriodReportTafsFeesComponentController  }) => void;

    grid: kendo.ui.Grid;

    isLoading: boolean;

    calculatedTechnologyAmount: number;
    technologyMinimum: number;
    technologyFeeToBePaid: number;
    isDynamicFees: boolean;
    static $inject = [
        '$q',
        'rpmApiResources',
        'rpmUiApiResources',
        'rmsState'
    ]

    constructor(
        private $q: ng.IQService,
        private rpmApiResources: RpmApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private rmsState: RmsStateService,
    ) {
        
    }

    $onInit() 
    {
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledPm').val() === 'True';
        if (this.init) {
            this.init({ self: this });
        }
    }

    $postLink()
    {
        this.InitGrid();

        if (this.franchise && this.royaltyPeriodReportId) {
            this.RefreshGrid();
        }
        this.isDynamicFees = $('#hdnIsDynamicFeesEnabledPm').val() === 'True';
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (this.franchise && this.royaltyPeriodReportId) {
            let hasChanges = (onChanges[nameof(this.franchise)] || onChanges[nameof(this.royaltyPeriodReportId)]);
            let validBindings = this.franchise && this.royaltyPeriodReportId;
            if (hasChanges && validBindings) {
                this.RefreshGrid();
            }
        }
    }
    
    InitGrid()
    {
        let scColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RoyaltyPeriodReportDetailViewModel>(o => o.royaltyServiceCategoryId),
                hidden: true
            }, {
                field: `ServiceCategory.${nameof<RpmEntities.RoyaltyServiceCategoryViewModel>(o => o.Name)}`,
                title: "Service Category"
            },  {
                field: nameof<RoyaltyPeriodReportDetailViewModel>(o => o.calculatedTAFSFeeAmount),
                title: "TAFS Fee",
                format: "{0:c}",
                headerAttributes: {
                    style: "text-align:right"
                },
                attributes: {
                    style: "text-align:right"
                },
            }, {
                field: nameof<RoyaltyPeriodReportDetailViewModel>(o => o.tafsFeeRate),
                title: "TAFS Fee %",
                format: "{0:p}",
                headerAttributes: {
                    style: "text-align:right"
                },
                attributes: {
                    style: "text-align:right"
                },
            }, {
                field: "TotalFees",
                title: "Total Fees",
                format: "{0:c}",
                headerAttributes: {
                    style: "text-align:right"
                },
                attributes: {
                    style: "text-align:right"
                },
                aggregates: ["sum"],
                footerTemplate: "<div style='text-align:right'>Total Other Fee Amount: #= kendo.toString(sum, 'c') #</div>"
            }
        ];


        let scDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof<RoyaltyPeriodReportDetailViewModel>(o => o.royaltyServiceCategoryId),
                    fields: {
                        [nameof<RoyaltyPeriodReportDetailViewModel>(o => o.royaltyServiceCategoryId)]: { type: "string", validation: { required: true } },
                        [nameof<RoyaltyPeriodReportDetailViewModel>(o => o.calculatedTAFSFeeAmount)]: { type: "number", validation: { required: true } },
                        [nameof<RoyaltyPeriodReportDetailViewModel>(o => o.tafsFeeRate)]: { type: "number", validation: { required: true } },
                        TotalFees: { type: "number", validation: { required: true } }
                    }
                }
            },
            aggregate: [{ field: "TotalFees", aggregate: "sum" }],
            transport: {
                read: (options) =>
                {
                    this.isDynamicFees = $('#hdnIsDynamicFeesEnabledPm').val() === 'True';
                    this.isLoading = true;
                    if (this.isDynamicFees) {
                        //Dynamic Flow
                        console.log("Dynamic Flow");
                    
                        this.rpmUiApiResources.GetRoyaltyPeriodReportDetailsByPeriodReportDynamic(this.royaltyPeriodReportId)
                            .then((response) => {
                                // Map the response data to camelCase property names
                                const mappedDetails = response.data.map(detail => {
                                    // Create a new object with camelCase property names
                                    const mappedDetail: any = {};
                                    
                                    // Map each property to camelCase
                                    Object.keys(detail).forEach(key => {
                                        // Skip $id property
                                        if (key === '$id') return;
                                        
                                        // Convert PascalCase to camelCase (e.g., RoyaltyServiceCategoryId -> royaltyServiceCategoryId)
                                        const camelKey = key.charAt(0).toLowerCase() + key.slice(1);
                                        mappedDetail[camelKey] = detail[key];
                                    });
                                    
                                    return mappedDetail;
                                });
                                // Get period report data for technology fee calculations
                                this.rpmUiApiResources.GetRoyaltyPeriodReportDynamic(this.royaltyPeriodReportId)
                                    .then((periodreports) => {
                                        this.calculatedTechnologyAmount = periodreports.data.CalculatedTAFSFeeAmount;
                                        this.technologyMinimum = periodreports.data.CalculatedTechnologyFeeMinimumAmount;
                                        this.technologyFeeToBePaid = this.calculatedTechnologyAmount + this.technologyMinimum;
                                    });
                                // Get service categories if needed for dynamic flow
                                if (this.franchise && this.franchise.FranchisorId) {
                                    this.rmsState.royaltyServiceCategoriesByFranchisorStateHandler.Load(this.franchise.FranchisorId)
                                        .then((serviceCategories) => {
                                            mappedDetails.map(detail => {
                                                (<any>detail).ServiceCategory = _.find(serviceCategories, (sc) => { return sc.RoyaltyServiceCategoryId == detail.royaltyServiceCategoryId; }) || { };
                                                (<any>detail).TotalFees = detail.calculatedTAFSFeeAmount;
                                            });
                                            options.success(mappedDetails);
                                        })
                                        .finally(() => { this.isLoading = false; });
                                }
                            });
                    } else {
                        this.rmsState.GetPeriodReportDetails(this.royaltyPeriodReportId, this.franchise.FranchisorId)
                        .then((periodDetails) =>
                        {   
                            this.calculatedTechnologyAmount = (periodDetails.royaltyPeriodReport.CalculatedTAFSFeeAmount);
                            this.technologyMinimum = periodDetails.royaltyPeriodReport.CalculatedTechnologyFeeMinimumAmount;
                            this.technologyFeeToBePaid = this.calculatedTechnologyAmount + this.technologyMinimum;

                            periodDetails.royaltyPeriodReportDetails.forEach((detail) =>
                            {
                                (<any>detail).ServiceCategory = _.find(periodDetails.serviceCategories, (sc) => { return sc.RoyaltyServiceCategoryId == detail.royaltyServiceCategoryId; }) || { };
                                (<any>detail).TotalFees = detail.calculatedTAFSFeeAmount;
                            });

                            options.success(periodDetails.royaltyPeriodReportDetails);
                        })
                        .catch((err) => { options.error(err); })
                        .finally(() => { this.isLoading = false; });
                    }
                }
            }
        });

        this.grid.setOptions(<kendo.ui.GridOptions>{
            autoBind: false,
            columns: scColumns,
            dataSource: scDataSource,
            resizable: true
        });
    }

    RefreshGrid()
    {
        if (this.grid) {
            return this.$q.when(this.grid.dataSource.read())
                .then(() => {
                    this.grid.refresh();
                });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('prTafsFees', {
            bindings: {
                [nameof<PeriodReportTafsFeesComponentController >(o => o.royaltyPeriodReportId)]: "<",
                [nameof<PeriodReportTafsFeesComponentController >(o => o.franchise)]: "<",
                [nameof<PeriodReportTafsFeesComponentController >(o => o.init)]: "&"
            },
            controller: PeriodReportTafsFeesComponentController ,
            templateUrl: "/Templates/PeriodReport/prSections/TafsFees.html"
        });
    }
}
