import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

export class PeriodReportPosTransactionsComponentController implements ng.IController {
    royaltyPeriodReportId: number;
    royaltySummaryInvoiceDisplayTypeId: number;
    franchise: Partial<CoreEntities.Franchise>;
    init: (params: { self: PeriodReportPosTransactionsComponentController }) => void;

    grid: kendo.ui.Grid;

    isLoading: boolean;

    static $inject = [
        'rpmUiApiResources',
        '$q'
    ];

    constructor(
        private rpmUiApiResources: RpmUiApiResources,
        private $q: ng.IQService
    ) {
    }

    $onInit() {
        if (this.init) {
            this.init({ self: this });
        }
    }

    $postLink() {
        this.InitGrid();

        if (this.royaltyPeriodReportId) {
            this.RefreshGrid();
        }
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (this.royaltyPeriodReportId) {
            let hasChanges = (onChanges[nameof(this.royaltyPeriodReportId)]);
            let validBindings = this.royaltyPeriodReportId;
            if (hasChanges && validBindings) {
                this.RefreshGrid();
            }

            if (this.royaltySummaryInvoiceDisplayTypeId && this.royaltySummaryInvoiceDisplayTypeId == 5) {
                this.UpdateColumnHeaders();
            }
        }
    }

    InitGrid() {
        let dataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: "InvoiceNumber",
                    fields: {
                        [nameof<RpmEntities.RoyaltyPOSInvoiceViewModel>(o => o.InvoiceNumber)]: { type: "string", validation: { required: true } },
                        [nameof<RpmEntities.RoyaltyPOSInvoiceViewModel>(o => o.InvoicedOn)]: { type: "date", validation: { required: true } },
                        [nameof<RpmEntities.RoyaltyPOSInvoiceViewModel>(o => o.ResidentialSales)]: { type: "number", validation: { required: true } },
                        [nameof<RpmEntities.RoyaltyPOSInvoiceViewModel>(o => o.CommercialSales)]: { type: "number", validation: { required: true } },
                        [nameof<RpmEntities.RoyaltyPOSInvoiceViewModel>(o => o.TotalSales)]: { type: "number", validation: { required: true } },
                        [nameof<RpmEntities.RoyaltyPOSInvoiceViewModel>(o => o.IncludesTAFS)]: { type: "boolean", validaiton: { required: true } }
                    }
                }
            },
            transport: {
                read: (options) => {
                    this.isLoading = true;

                    return this.rpmUiApiResources.GetRoyaltyPeriodReport(this.royaltyPeriodReportId)
                        .then((periodResponse) => {
                            if (periodResponse.data.RoyaltySummaryInvoiceDisplayTypeId == 5) {
                                this.grid.hideColumn(5);
                                return this.rpmUiApiResources.GetRoyaltyPosPaymentsByRoyaltyPeriodReportId(this.royaltyPeriodReportId);
                            }

                            return this.rpmUiApiResources.GetRoyaltyPosInvoicesByFranchiseAndBatch(periodResponse.data.RoyaltyReportBatchId, periodResponse.data.FranchiseId);
                        })
                        .then((invoicesResponse) => {
                            options.success(invoicesResponse.data);
                        })
                        .catch((err) => { options.error(err); })
                        .finally(() => { this.isLoading = false; });
                }
            }
        });

        let columns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.RoyaltyPOSInvoiceViewModel>(o => o.InvoiceNumber),
                title: "Invoice Number"
            }, {
                field: nameof<RpmEntities.RoyaltyPOSInvoiceViewModel>(o => o.InvoicedOn),
                title: "Invoice Date",
                format: "{0:yyyy-MM-dd}"
            }, {
                field: nameof<RpmEntities.RoyaltyPOSInvoiceViewModel>(o => o.ResidentialSales),
                title: "Residential Sales",
                format: "{0:c}",
                headerAttributes: {
                    style: "text-align:right"
                },
                attributes: {
                    style: "text-align:right"
                },
            }, {
                field: nameof<RpmEntities.RoyaltyPOSInvoiceViewModel>(o => o.CommercialSales),
                title: "Commercial Sales",
                format: "{0:c}",
                headerAttributes: {
                    style: "text-align:right"
                },
                attributes: {
                    style: "text-align:right"
                },
            }, {
                field: nameof<RpmEntities.RoyaltyPOSInvoiceViewModel>(o => o.TotalSales),
                title: "Total Sales",
                format: "{0:c}",
                headerAttributes: {
                    style: "text-align:right"
                },
                attributes: {
                    style: "text-align:right"
                },
            }, {
                field: nameof<RpmEntities.RoyaltyPOSInvoiceViewModel>(o => o.IncludesTAFS),
                title: "Includes TAFS Sales",
                template: "#= IncludesTAFS == true ? 'Yes' : 'No' #"
            }
        ];

        this.grid.setOptions(<kendo.ui.GridOptions>{
            autoBind: false,
            columns: columns,
            dataSource: dataSource
        });
    }

    RefreshGrid() {
        if (this.grid) {
            return this.$q.when(this.grid.dataSource.read())
                .then(() => {
                    this.grid.refresh();
                });
        }
    }

    UpdateColumnHeaders() {
        if (this.grid) {
            this.grid.thead.find("[data-title='Invoice Number']").html("Quote / Payment Number / Customer");
            this.grid.thead.find("[data-title='Invoice Date']").html("Payment Date");
        }
    }

    static BindComponent(app: ng.IModule) {
        app.component('prPosTransactions', {
            bindings: {
                [nameof<PeriodReportPosTransactionsComponentController>(o => o.royaltyPeriodReportId)]: "<",
                [nameof<PeriodReportPosTransactionsComponentController>(o => o.franchise)]: "<",
                [nameof<PeriodReportPosTransactionsComponentController>(o => o.init)]: "&",
                [nameof<PeriodReportPosTransactionsComponentController>(o => o.royaltySummaryInvoiceDisplayTypeId)]: "<"
            },
            controller: PeriodReportPosTransactionsComponentController,
            templateUrl: "/Templates/PeriodReport/prSections/PosTransactions.html"
        });
    }
}

