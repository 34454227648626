import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class DisclosureDocumentKeyValuePairsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetDisclosureDocumentKeyValuePairsByDisclosureDocument(disclosureDocumentId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/DisclosureDocumentKeyValuePairs/InDocument/" + disclosureDocumentId
        };

        return this.$http<RpmEntities.RoyaltyDisclosureDocumentKeyValuePairViewModel[]>(config);
    }

    CreateDisclosureDocumentKeyValuePair(model: RpmEntities.RoyaltyDisclosureDocumentKeyValuePairViewModel) {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/DisclosureDocumentKeyValuePairs",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateDisclosureDocumentKeyValuePair(id: number, model: RpmEntities.RoyaltyDisclosureDocumentKeyValuePairViewModel) {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/DisclosureDocumentKeyValuePairs/" + id,
            data: model
        };

        return this.$http<number>(config);
    }
}