
export class OnOffSwitchTdgDirective
{
    static Init(dppApp: ng.IModule)
    {
        dppApp.directive('onOffSwitchTdg', ['$window', ($window: ng.IWindowService): ng.IDirective =>
        {
            return {
                scope: {
                    onOffSwitchTdg: '='
                },
                restrict: "A",
                replace: false,
                transclude: false,
                templateUrl: '/Templates/Dpp/Directives/OnOffSwitchTdg.html',
                //controller: 'EditDisasterPrepProfileSectionController',
                link: (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, controller) =>
                {

                }
            };
        }]);
    }
}
