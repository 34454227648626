import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyContractRatePlansRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyContractRatePlansByContract(contractId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContractRatePlans/InContract/" + contractId
        };

        return this.$http<RpmEntities.RoyaltyContractRatePlanViewModel[]>(config);
    }

    CreateRoyaltyContractRatePlan(model: RpmEntities.RoyaltyContractRatePlanViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyContractRatePlans",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyContractRatePlan(id: number, model: RpmEntities.RoyaltyContractRatePlanViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyContractRatePlans/" + id,
            data: model
        };

        return this.$http<number>(config);
    }
}