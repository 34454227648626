

let ifEmptyFilter = () =>
{
    return (input, defaultValue) =>
    {
        return (angular.isUndefined(input) || input === null || input === '') ? defaultValue : input;
    }
}

export { ifEmptyFilter };