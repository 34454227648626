import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

export class FranchiseProfileContactFinalAssignmentController implements ng.IController 
{
    franchiseId: number;
    onInit: (params: { self: FranchiseProfileContactFinalAssignmentController }) => void;

    isLoading: boolean;
    finalContactDropDownList: kendo.ui.DropDownList;
    finalContactDropDownListOptions: kendo.ui.DropDownListOptions;
    
    static $inject = [
        'coreApiResources',
        '$q',
        '$log',
        '$timeout'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService
    )
    {
    }

    $onInit()
    {
        if (this.onInit) {
            this.onInit({ self: this });
        }
        this.InitFinalContactDropDown();
    }

    $postLink()
    {
    }

    InitFinalContactDropDown()
    {
        this.finalContactDropDownListOptions = {
            optionLabel: "(None)",
            optionLabelTemplate: "(None)",
            autoWidth: true,
            dataTextField: nameof<CoreEntities.FranchiseProfileContact>(o => o.Name),
            dataValueField: nameof<CoreEntities.FranchiseProfileContact>(o => o.FranchiseProfileContactId),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (options) =>
                    {
                        this.isLoading = true;

                        let queryParams: ODataQueryParameters = {
                            $filter: `${nameof<CoreEntities.FranchiseProfileContact>(o => o.FranchiseId)} eq ${this.franchiseId}`
                        }
                        this.coreApiResources.FranchiseProfileContactApi.query(queryParams).$promise
                            .then((contacts) =>
                            {
                                options.success(contacts);
                                return this.coreApiResources.FranchiseProfileApi.get({ id: this.franchiseId }).$promise
                                    .then((profile) =>
                                    {
                                        this.finalContactDropDownList.select((contact) =>
                                        {
                                            return contact.FranchiseProfileContactId === profile.FinalNotificationFranchiseProfileContactId
                                        });
                                    })
                            })
                            .catch((err) =>
                            {
                                options.error(err);
                            })
                            .finally(() =>
                            {
                                this.isLoading = false;
                            });
                    }
                }
            }),
            select: (e) =>
            {
                let contact: CoreEntities.FranchiseProfileContact = e.dataItem;
                let contactId = contact && contact.FranchiseProfileContactId;

                this.isLoading = true;

                return this.coreApiResources.FranchiseProfileApi.get({ id: this.franchiseId }).$promise
                    .then((profile) =>
                    {
                        profile.FinalNotificationFranchiseProfileContactId = contactId;
                        return this.coreApiResources.FranchiseProfileApi.update({ id: this.franchiseId }, profile).$promise
                    })
                    .catch((err) =>
                    {
                        console.error(err);
                        alert("Couldn't save the final notification contact to your profile.");
                    })
                    .finally(() =>
                    {
                        this.isLoading = false;
                    });
            }
        }
    }

    ReloadDropDown()
    {
        return this.$q.when(this.finalContactDropDownList.dataSource.read());
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfileContactFinalAssignment', {
            bindings: {
                [nameof<FranchiseProfileContactFinalAssignmentController>(o => o.franchiseId)]: "<",
                [nameof<FranchiseProfileContactFinalAssignmentController>(o => o.onInit)]: "&?",
            },
            controller: FranchiseProfileContactFinalAssignmentController,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfileContactFinalAssignment.html"
        });
    }
}

