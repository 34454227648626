import { AngularUtil } from "Utility/AngularUtil";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { CallCenterResources } from "Interfaces/FranForce/CallCenter/CallCenterResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class ConceptLeadThrottleRulesComponentController implements ng.IController
{
    //---- Bindings ----
    conceptId: number;
    //------------------

    isLoading: boolean;
    throttle: CallCenterResources.IC4ConceptLeadThrottle;

    greenTimeUnitDropDownOptions: kendo.ui.DropDownListOptions;
    yellowTimeUnitDropDownOptions: kendo.ui.DropDownListOptions;
    timeSlotCamparerTypesDropDownOptions: kendo.ui.DropDownListOptions;
    showContent: boolean;
    
    static $inject = [
        "coreApiResources",
        "callCenterApiResources",
        "$q",
        "$log"
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private callCenterApiResources: CallCenterApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService)
    {

    }

    $onInit()
    {
        this.InitTimeUnitDropDownOptions();
        this.InitComparerTypeDropDownOptions();
        /* this.showContent = this.conceptId == 1 ? true : false; */
        this.showContent = true;
    }

    InitComparerTypeDropDownOptions() {
        let readTimeSlotsFunction = (options: kendo.data.DataSourceTransportOptions) => {
            this.callCenterApiResources.TimeSlotComparerTypeApi.query({}).$promise
                .then((timeSlotUnits) => {
                    options.success(timeSlotUnits);
                })
                .catch((err) => {
                    options.error(err);
                });
        }

        this.timeSlotCamparerTypesDropDownOptions = {
            valuePrimitive: true,
            dataTextField: nameof<CallCenterResources.ITimeSlotComparerType>(o => o.Name),
            dataValueField: nameof<CallCenterResources.ITimeSlotComparerType>(o => o.TimeSlotComparerTypeId),
            optionLabel: "Select Time Slot...",
            optionLabelTemplate: "Select Time Slot...",
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readTimeSlotsFunction
                }
            })
        }
    }

    InitTimeUnitDropDownOptions()
    {
        let readFunction = (options: kendo.data.DataSourceTransportOptions) =>
        {
            this.callCenterApiResources.C4ConceptLeadTimeUnitsApi.query({}).$promise
                .then((timeUnits) =>
                {
                    options.success(timeUnits);
                })
                .catch((err) =>
                {
                    options.error(err);
                });
        }

        this.greenTimeUnitDropDownOptions = {
            valuePrimitive: true,
            dataTextField: nameof<CallCenterResources.IC4ConceptLeadTimeUnit>(o => o.Name),
            dataValueField: nameof<CallCenterResources.IC4ConceptLeadTimeUnit>(o => o.C4ConceptLeadTimeUnitId),
            optionLabel: "Select Time...",
            optionLabelTemplate: "Select Time...",
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readFunction
                }
            })
        }

        this.yellowTimeUnitDropDownOptions = {
            valuePrimitive: true,
            dataTextField: nameof<CallCenterResources.IC4ConceptLeadTimeUnit>(o => o.Name),
            dataValueField: nameof<CallCenterResources.IC4ConceptLeadTimeUnit>(o => o.C4ConceptLeadTimeUnitId),
            optionLabel: "Select Time...",
            optionLabelTemplate: "Select Time...",
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readFunction
                }
            })
        }
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.conceptId)])
        {
            this.isLoading = true;
            this.coreApiResources.ConceptApi.get({ id: this.conceptId, $select: nameof<CoreResources.IConcept>(o => o.C4ConceptLeadThrottleId) }).$promise
                .then((concept: { C4ConceptLeadThrottleId: number }) =>
                {
                    if (!concept.C4ConceptLeadThrottleId)
                        return null;
                    return this.callCenterApiResources.C4ConceptLeadThrottlesApi.get({ id: concept.C4ConceptLeadThrottleId }).$promise
                })
                .then((conceptLeadThrottle) =>
                {
                    this.throttle = conceptLeadThrottle;
                })
                .finally(() =>
                {
                    this.isLoading = false;
                });
        }
    }

    Save()
    {
        let savePromise: ng.IPromise<any>;
        if (this.throttle.C4ConceptLeadThrottleId)
        {
            savePromise = this.callCenterApiResources.C4ConceptLeadThrottlesApi.update({ id: this.throttle.C4ConceptLeadThrottleId }, this.throttle).$promise
        }
        else
        {
            savePromise = this.callCenterApiResources.C4ConceptLeadThrottlesApi.save({}, this.throttle).$promise
                .then((createdThrottle) =>
                {
                    return this.callCenterApiResources.SetConceptLeadThrottleToConcept(createdThrottle.C4ConceptLeadThrottleId, this.conceptId)
                });
        }

        savePromise = savePromise.catch(AngularUtil.GetDefaultHttpErrorPromiseLogAlertCallback(this.$log, this.$q));

        return savePromise;
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("conceptLeadThrottleRules", {
            bindings: {
                conceptId: "<"
            },
            controller: ConceptLeadThrottleRulesComponentController,
            templateUrl: "/Templates/Concepts/ConceptLeadThrottleRules.html",
        });
    }
}

