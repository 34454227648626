import { IFRMStateParams } from "Controllers/Rms/FranchisorRoyaltyManagement/frmRouter";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import * as _ from "underscore"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { Helpers } from "Utility/Helpers";

interface IFRMReportingPeriodScope extends ng.IScope {
    // Kendo grid
    rpGridOptions: kendo.ui.GridOptions;
    rpDataSource: kendo.data.DataSource;
    rpGrid: kendo.ui.Grid;
    loadedEditPeriodGridOptions: kendo.ui.GridOptions;
    loadedEditPeriodDataSource: kendo.data.DataSource;

    // Kendo dialog
    confirmDeleteDialog: kendo.ui.Dialog;
    EditReportingPeriodDialog: kendo.ui.Dialog;

    //show/hide or enable/disable elements till data is loaded for saving to DB edit reporting period
    isloadEditPeriodsGridShow: boolean;
    disableEditSaveBtn: boolean;

    // params data of loaded grid for saving to DB
    saveEditReportingPeriodParams: any;

    //royalty period by franchisor data
    royaltyPeriodsData: any;

    //edit reporting period field values
    editSelectedPeriodEndDay: string;
    editSelectedStartDate: Date;
    editSelectedEndDate: Date;
    editRowYearVal: number;
    editRowFrequencyVal: string;
    selectedRoyaltyReportingPeriodId: number;
    selectedRoyaltyReportingPeriodIdOnEdit: number;

    //period Selected For Deletion
    periodSelectedForDeletion: number;

    //edit reporting period field validation
    invalidTextFieldPeriodEditEndDayCSSClass: string;
    invalidTextFieldPeriodEditEndDateCSSClass: string;
    invalidTextFieldPeriodEditStartDateCSSClass: string;
    isInValidEditLoadPeriodData: boolean;
    invalidEditLoadPeriodDataErrorMessage: string;

    //period end day field for edit reporting period
    periodEndDayDropDownOptions: kendo.ui.DropDownListOptions;
    periodEndDayTypes: { periodEndDayId: string, periodEndDayText: string }[];

    //logged in username
    userName: string;
}

export class FRMReportingPeriodController implements ng.IController {

    static $inject = [
        '$scope',
        '$stateParams',
        'rpmUiApiResources',
        'identityManager'
    ];

    constructor(
        private $scope: IFRMReportingPeriodScope,
        private $stateParams: IFRMStateParams,
        private rpmUiApiResources: RpmUiApiResources,
        private identityManager: IdentityManager
    ) {

    }

    $onInit() {
        //get logged in username
        this.identityManager.GetLoggedInUserInfo().then(
            (success) => {
                this.$scope.userName = success.data["FirstName"] ? success.data["FirstName"] : "unknown";
            },
            (error) => {
                console.log("error in getting userName", error);
            });

        //void all validations
        this.setAllValidationsToFalse()

        //set dropdown for period end day
        this.$scope.periodEndDayTypes = [];
        Helpers.DaysOfWeekObjects().forEach(item => {
            this.$scope.periodEndDayTypes.push({
                periodEndDayId: item.name, periodEndDayText: item.name
            })
        })
        this.$scope.periodEndDayDropDownOptions = {
            dataSource: this.$scope.periodEndDayTypes,
            dataValueField: "periodEndDayId",
            dataTextField: "periodEndDayText",
            optionLabel: "Select Period End Day"
        }

        //Datepickers for edit reporting period
        $("#editReportingPeriodStartDate").kendoDatePicker();
        $("#editReportingPeriodEndDate").kendoDatePicker();

        this.rpmUiApiResources.GetReportingPeriodYearListingByFranchisorId(parseInt(this.$stateParams.franchisorId)).then(
            (response) => {
                this.$scope.royaltyPeriodsData = response.data;
                let royaltyPeriods = response.data;
                //Sorted by Year desc primarily, PeriodEndDate asc secondarily
                royaltyPeriods = _.sortBy(royaltyPeriods, rp => rp.Year);
                royaltyPeriods = royaltyPeriods.reverse();
                this.$scope.rpDataSource.data(royaltyPeriods);
            });

        //********************************************************************
        //***************************** GRIDS ********************************
        //********************************************************************

        let rpColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.RoyaltyReportingPeriodYearListingViewModel>(o => o.Year),
                title: "Year"
            }, {
                field: nameof<RpmEntities.RoyaltyReportingPeriodYearListingViewModel>(o => o.Frequency),
                title: "Frequency"
            }, {
                field: nameof<RpmEntities.RoyaltyReportingPeriodYearListingViewModel>(o => o.WeekEndingDay),
                title: "Week Ending Day"
            }, {
                field: nameof<RpmEntities.RoyaltyReportingPeriodYearListingViewModel>(o => o.TotalReports),
                title: "Total Reports"
            },
            {
                field: nameof<RpmEntities.RoyaltyReportingPeriodYearListingViewModel>(o => o.StartDate),
                title: "Start Date",
                template: (dataItem) => {
                    return kendo.toString(kendo.parseDate(dataItem.StartDate), 'yyyy-MM-dd');
                }
            },
            {
                field: nameof<RpmEntities.RoyaltyReportingPeriodYearListingViewModel>(o => o.EndDate),
                title: "End Date",
                template: (dataItem) => {
                    return kendo.toString(kendo.parseDate(dataItem.EndDate), 'yyyy-MM-dd');
                }
            },
            {
                field: nameof<RpmEntities.RoyaltyReportingPeriodYearListingViewModel>(o => o.RoyaltyPeriodFrequencyId),
                hidden: true
            },
            {
                field: nameof<RpmEntities.RoyaltyReportingPeriodYearListingViewModel>(o => o.RoyaltyReportingPeriodId),
                hidden: true
            },
            {
                command: [
                    {
                        text: "Edit",
                        name: "Edit",
                        iconClass: "k-icon k-i-edit",
                        click: (e) => {
                            e.preventDefault();
                            this.$scope.isloadEditPeriodsGridShow = false;
                            this.$scope.disableEditSaveBtn = true;
                            this.$scope.isInValidEditLoadPeriodData = false;
                            this.$scope.invalidTextFieldPeriodEditEndDayCSSClass = "";
                            this.$scope.invalidEditLoadPeriodDataErrorMessage = "";
                            this.$scope.invalidTextFieldPeriodEditEndDateCSSClass = "";
                            this.$scope.invalidTextFieldPeriodEditStartDateCSSClass = "";

                            let dropdownEditPeriodEndDay = $("#reportingPeriodEditEndDaySelect").data("kendoDropDownList");
                            dropdownEditPeriodEndDay.value("");
                            dropdownEditPeriodEndDay.trigger("change");

                            var grid = $("#rp-table").data("kendoGrid");
                            var row = grid.dataItem($(e.currentTarget).closest("tr")).toJSON();
                            this.$scope.editRowYearVal = parseInt(row["Year"]);
                            this.$scope.selectedRoyaltyReportingPeriodIdOnEdit = parseInt(row["RoyaltyReportingPeriodId"]);
                            this.$scope.editRowFrequencyVal = row["Frequency"].toString();
                            var periodStartDateFromGridRow = new Date(row["StartDate"].toString());


                            //set invalid dates to disabled
                            let arrayPeriodStartDate = [];
                            let arrayAllYearDates = [];
                            let arrayInValidPeriodStartDates = [];
                            this.rpmUiApiResources.GetReportingPeriodInfo(this.$scope.selectedRoyaltyReportingPeriodIdOnEdit)
                                .then(
                                    (response) => {
                                        let royaltyPeriodInfo = response.data;
                                        royaltyPeriodInfo = _.sortBy(royaltyPeriodInfo, rp => rp.PeriodStartDate);

                                        //array of valid start dates
                                        royaltyPeriodInfo.forEach((item, index) => {
                                            var startDate = new Date(item.PeriodStartDate);
                                            var endDate = new Date(item.PeriodEndDate);
                                            var today = new Date();
                                            if (today < endDate) {
                                                arrayPeriodStartDate.push((((startDate.getMonth() > 8) ? (startDate.getMonth() + 1) : ('0' + (startDate.getMonth() + 1))) + '/' + ((startDate.getDate() > 9) ? startDate.getDate() : ('0' + startDate.getDate())) + '/' + startDate.getFullYear()).toString());
                                            }
                                        });

                                        //array of all dates in a year
                                        for (var d = new Date(this.$scope.editRowYearVal, 0, 1); d <= new Date(this.$scope.editRowYearVal, 11, 31); d.setDate(d.getDate() + 1)) {

                                            let date = new Date(d);
                                            let formattedDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
                                            arrayAllYearDates.push(formattedDate.toString());

                                        }

                                        //remove all valid dates from all dates in a year
                                        for (var i = 0; i < arrayPeriodStartDate.length; i++) {
                                            var arrlen = arrayAllYearDates.length;
                                            for (var j = 0; j < arrlen; j++) {
                                                if (arrayPeriodStartDate[i] == arrayAllYearDates[j]) {
                                                    arrayAllYearDates = arrayAllYearDates.slice(0, j).concat(arrayAllYearDates.slice(j + 1, arrlen));
                                                }
                                            }
                                        }

                                        //push invalid start dates to be disabled in array
                                        arrayAllYearDates.forEach((item, index) => {
                                            arrayInValidPeriodStartDates.push(new Date(item))
                                        })

                                        //set minimum date to one week before prev year if period has not started
                                        let minStartDate = new Date(arrayPeriodStartDate[0]);
                                        if ((periodStartDateFromGridRow.getTime() === minStartDate.getTime()) && (minStartDate.getFullYear() < this.$scope.editRowYearVal)) {
                                            minStartDate = new Date((this.$scope.editRowYearVal - 1), 11, 25);
                                        }

                                        this.$scope.editSelectedStartDate = new Date(arrayPeriodStartDate[0]);
                                        $("#editReportingPeriodStartDate").kendoDatePicker({
                                            min: minStartDate,
                                            max: new Date(this.$scope.editRowYearVal, 11, 31),
                                            value: new Date(arrayPeriodStartDate[0]),
                                            dates: arrayInValidPeriodStartDates,
                                            disableDates: (date) => {
                                                var dates = $("#editReportingPeriodStartDate").data("kendoDatePicker").options.dates;
                                                if (date && compareDates(date, dates)) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            },
                                            change: () => {
                                            }
                                        });
                                        function compareDates(date, dates) {
                                            for (var i = 0; i < dates.length; i++) {
                                                if (dates[i].getDate() == date.getDate() &&
                                                    dates[i].getMonth() == date.getMonth() &&
                                                    dates[i].getYear() == date.getYear()) {
                                                    return true
                                                }
                                            }
                                        }

                                        //set end date as last day of the year
                                        $("#editReportingPeriodEndDate").kendoDatePicker();
                                        var editReportingPeriodEndDatepicker = $("#editReportingPeriodEndDate").data("kendoDatePicker");
                                        editReportingPeriodEndDatepicker.value(null);
                                        editReportingPeriodEndDatepicker.trigger("change");

                                        //open dialog
                                        var dialog = $("#editReportingPeriodDialog").data("kendoDialog");
                                        dialog.open();
                                    });
                        },
                        visible: (row) => {
                            let showEdit = true;
                            if (new Date(row.EndDate.toString()).toISOString().slice(0, 10) <= new Date().toISOString().slice(0, 10)) {
                                showEdit = false;
                            }
                            return row.Frequency.toString().trim().toLowerCase() === "weekly" && row.Year >= new Date().getFullYear() && showEdit
                        }
                    },
                    {
                        text: "Delete",
                        name: "Delete",
                        iconClass: "k-icon k-i-delete",
                        click: (e) => {
                            e.preventDefault();
                            var grid = $("#rp-table").data("kendoGrid");
                            var row = grid.dataItem($(e.currentTarget).closest("tr")).toJSON();
                            this.$scope.periodSelectedForDeletion = parseInt(row["RoyaltyReportingPeriodId"]);

                            //open dialog
                            let dialog = $("#confirmDelete").data("kendoDialog");
                            dialog.open();
                        },
                        visible: (row) => {
                            return row.Year >= new Date().getFullYear()
                        }
                    }
                ], title: "Actions", width: "180px"
            }
        ];
        this.$scope.rpDataSource = new kendo.data.DataSource({
            pageSize: 5
        });
        this.$scope.rpGridOptions = {
            columns: rpColumns,
            pageable: {
                alwaysVisible: true,
                pageSizes: [5, 10, 15, 20],
            },
            dataSource: this.$scope.rpDataSource,
            selectable: true,
            sortable: true,
            scrollable: true,
            height: 300,
        }
    }

    //on select row from royalty periods table
    onSelection(data) {
        this.$scope.selectedRoyaltyReportingPeriodId = parseInt(data.RoyaltyReportingPeriodId);
    }

    //on Edit Period End Day Change
    onEditPeriodEndDayChange() {
        var datepicker = $("#editReportingPeriodEndDate").data("kendoDatePicker");
        if (!this.$scope.editSelectedPeriodEndDay) {
            datepicker.enable(false);
            return true;
        }
        else {
            let selectedEditPeriodEndDay = (Object.values(this.$scope.editSelectedPeriodEndDay)[1]).toString().toLowerCase();
            if (selectedEditPeriodEndDay.toString().trim() == "") {
                datepicker.enable(false);
                return true;
            }
            else {
                datepicker.enable(true);
                datepicker.max(new Date(this.$scope.editRowYearVal, 11, 31));

                //allow only selected end day days to be selected
                let allDaysOfWeekArr = ["mo", "tu", "we", "th", "fr", "sa", "su"];
                let selectedShortDayOfWeek = selectedEditPeriodEndDay.substring(0, 2).toLowerCase();
                let disabledDayNameArr = allDaysOfWeekArr.filter((item) => {
                    return item != selectedShortDayOfWeek;
                })

                $("#editReportingPeriodEndDate").kendoDatePicker({
                    max: new Date(this.$scope.editRowYearVal, 11, 31),
                    disableDates: disabledDayNameArr,
                });

                datepicker.value(null);
                datepicker.trigger("change");


                return false;
            }
        }
    }

    loadEditedPeriod() {
        this.$scope.isloadEditPeriodsGridShow = false
        //validation checks
        if (this.checkIfPeriodEditEndDayNotSelected()) {
            return;
        }
        if (this.CheckIfEditPeriodEndDateIsSelected()) {
            return;
        }
        if (this.CheckIfEndDateIsGreaterThanStartDate()) {
            return;
        }

        let selectedEditPeriodEndDay = (Object.values(this.$scope.editSelectedPeriodEndDay)[0]).toString().toLowerCase();
        let selectedEditPeriodStartDate = $("#editReportingPeriodStartDate").data("kendoDatePicker").value();
        let selectedEditPeriodEndDate = $("#editReportingPeriodEndDate").data("kendoDatePicker").value();
        let formattedEditPeriodStartDate = (((selectedEditPeriodStartDate.getDate() > 9) ? selectedEditPeriodStartDate.getDate() : ('0' + selectedEditPeriodStartDate.getDate())) + ' ' + (selectedEditPeriodStartDate.toLocaleString("en-us", { month: "short" })) + ' ' + selectedEditPeriodStartDate.getFullYear()).toString()
        let formattedEditPeriodEndDate = (((selectedEditPeriodEndDate.getDate() > 9) ? selectedEditPeriodEndDate.getDate() : ('0' + selectedEditPeriodEndDate.getDate())) + ' ' + (selectedEditPeriodEndDate.toLocaleString("en-us", { month: "short" })) + ' ' + selectedEditPeriodEndDate.getFullYear()).toString()
        let royaltyPeriodFrequencyId = 2;
        let year = this.$scope.editRowYearVal;
        let franchisorId = this.$stateParams.franchisorId;
        let userName = this.$scope.userName;
        this.rpmUiApiResources.LoadEditReportingPeriodInfo(year, royaltyPeriodFrequencyId, parseInt(franchisorId), userName, selectedEditPeriodEndDay, formattedEditPeriodStartDate, formattedEditPeriodEndDate)
            .then(
                (response) => {
                    let royaltyPeriodInfo = response.data;
                    royaltyPeriodInfo = _.sortBy(royaltyPeriodInfo, rp => rp.PeriodStartDate);
                    this.$scope.loadedEditPeriodDataSource.data(royaltyPeriodInfo);
                    this.$scope.isloadEditPeriodsGridShow = true;
                    this.$scope.disableEditSaveBtn = false;
                    this.$scope.saveEditReportingPeriodParams = {
                        year: year,
                        frequency: royaltyPeriodFrequencyId,
                        franchisorId: parseInt(this.$stateParams.franchisorId),
                        userName: this.$scope.userName,
                        periodEndDay: selectedEditPeriodEndDay,
                        startDate: formattedEditPeriodStartDate,
                        endDate: formattedEditPeriodEndDate
                    }
                });

        //********************************************************************
        //***************************** GRIDS ********************************
        //********************************************************************

        let loadedEditPeriodColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.PeriodStartDate),
                title: "Period Start Date",
                template: (dataItem) => {
                    return kendo.toString(kendo.parseDate(dataItem.PeriodStartDate), 'yyyy-MM-dd');
                }
            },
            {
                field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.PeriodEndDate),
                title: "Period End Date",
                template: (dataItem) => {
                    return kendo.toString(kendo.parseDate(dataItem.PeriodEndDate), 'yyyy-MM-dd');
                }
            },
            {
                field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.Year),
                title: "Year"
            },
            {
                field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.MonthNumber),
                title: "Month Number"
            },
            {
                field: nameof<RpmEntities.RoyaltyPeriodViewModel>(o => o.WeekNumber),
                title: "Week Number"
            }
        ];
        this.$scope.loadedEditPeriodDataSource = new kendo.data.DataSource({
            transport: {
                parameterMap: function (options, operation) {
                    if (operation !== "read" && options.models) {
                        return { models: kendo.stringify(options.models) };
                    }
                }
            },
            schema: {
                model: {
                    fields: {
                        PeriodStartDate: { type: "string" },
                        PeriodEndDate: { type: "string" },
                        Year: { type: "number" },
                        MonthNumber: { type: "number" },
                        WeekNumber: { type: "number" },
                    }
                }
            }
        });
        this.$scope.loadedEditPeriodGridOptions = {
            columns: loadedEditPeriodColumns,
            dataSource: this.$scope.loadedEditPeriodDataSource,
            sortable: true,
            scrollable: true,
            resizable: true,
        }

        let grid = $("#loadedEditPeriodGrid").data("kendoGrid");
        grid.setDataSource(this.$scope.loadedEditPeriodDataSource);
        grid.refresh();

    }

    //on cancel edited period
    CancelEditedPeriod() {
        let dropdownEditPeriodEndDay = $("#reportingPeriodEditEndDaySelect").data("kendoDropDownList");
        dropdownEditPeriodEndDay.value("");
        dropdownEditPeriodEndDay.trigger("change");

        this.$scope.isloadEditPeriodsGridShow = false;
        this.$scope.disableEditSaveBtn = true;

        this.$scope.EditReportingPeriodDialog.close();
    }

    //on cancel delete
    onCancelDelete() {
        this.$scope.periodSelectedForDeletion = 0;
        this.$scope.confirmDeleteDialog.close();
    }

    //on confirm delete to delete reporting period
    onConfirmDelete() {
        this.rpmUiApiResources.DeleteReportingPeriod(this.$scope.periodSelectedForDeletion, this.$scope.userName)
            .then(
                (response) => {
                    this.$scope.periodSelectedForDeletion = 0;
                    this.$scope.confirmDeleteDialog.close();
                    location.reload();
                });
    }

    setErrorEditReportingPeriods(errorMsg: string) {
        this.$scope.isInValidEditLoadPeriodData = true;
        this.$scope.invalidEditLoadPeriodDataErrorMessage = errorMsg;
    }

    unSetErrorEditReportingPeriods() {
        this.$scope.isInValidEditLoadPeriodData = false;
        this.$scope.invalidEditLoadPeriodDataErrorMessage = "";
    }

    //Check If EndDate Is Greater Than StartDate for edit reporting period
    CheckIfEndDateIsGreaterThanStartDate() {
        let selectedEditPeriodStartDate = $("#editReportingPeriodStartDate").data("kendoDatePicker").value();
        let selectedEditPeriodEndDate = $("#editReportingPeriodEndDate").data("kendoDatePicker").value();

        if (selectedEditPeriodEndDate <= selectedEditPeriodStartDate) {
            this.setErrorEditReportingPeriods("End date should be greater than start date");
            this.$scope.invalidTextFieldPeriodEditEndDateCSSClass = "invalidTextFieldPeriodEditEndDateCSSClass";
            this.$scope.invalidTextFieldPeriodEditStartDateCSSClass = "invalidTextFieldPeriodEditStartDateCSSClass";
            return true;
        } else {
            this.unSetErrorEditReportingPeriods()
            this.$scope.invalidTextFieldPeriodEditEndDateCSSClass = "";
            this.$scope.invalidTextFieldPeriodEditStartDateCSSClass = "";
            return false;
        }
    }

    //Check If EndDate Is selected for edit reporting period
    CheckIfEditPeriodEndDateIsSelected() {
        if (!($("#editReportingPeriodEndDate").data("kendoDatePicker").value())) {
            this.setErrorEditReportingPeriods("Please select period end date");
            this.$scope.invalidTextFieldPeriodEditEndDateCSSClass = "invalidTextFieldPeriodEditEndDateCSSClass";
            return true;
        }
        else {
            this.unSetErrorEditReportingPeriods()
            this.$scope.invalidTextFieldPeriodEditEndDateCSSClass = "";
            return false;
        }
    }

    //check if period edit end day is not selected
    checkIfPeriodEditEndDayNotSelected() {
        if (!this.$scope.editSelectedPeriodEndDay) {
            this.setErrorEditReportingPeriods("Please select period end day");
            this.$scope.invalidTextFieldPeriodEditEndDayCSSClass = "invalidTextFieldPeriodEditEndDayCSSClass";
            return true;
        }
        else {
            let selectedEditPeriodEndDay = (Object.values(this.$scope.editSelectedPeriodEndDay)[1]).toString().toLowerCase();
            if (selectedEditPeriodEndDay.toString().trim() == "") {
                this.setErrorEditReportingPeriods("Please select period end day");
                this.$scope.invalidTextFieldPeriodEditEndDayCSSClass = "invalidTextFieldPeriodEditEndDayCSSClass";
                return true;
            }
            else {
                this.unSetErrorEditReportingPeriods()
                this.$scope.invalidTextFieldPeriodEditEndDayCSSClass = "";
                return false;
            }
        }
    }

    //void all validations
    setAllValidationsToFalse() {
        this.$scope.disableEditSaveBtn = true;
        this.$scope.isInValidEditLoadPeriodData = false;
        this.$scope.invalidTextFieldPeriodEditEndDayCSSClass = "";
        this.$scope.invalidEditLoadPeriodDataErrorMessage = "";
        this.$scope.invalidTextFieldPeriodEditEndDateCSSClass = "";
        this.$scope.invalidTextFieldPeriodEditStartDateCSSClass = "";
    }

    //save edited reporting period to DB
    SaveEditedPeriod() {
        this.rpmUiApiResources.EditReportingPeriodInfo(this.$scope.saveEditReportingPeriodParams.year, this.$scope.saveEditReportingPeriodParams.frequency, this.$scope.saveEditReportingPeriodParams.franchisorId, this.$scope.saveEditReportingPeriodParams.userName, this.$scope.saveEditReportingPeriodParams.periodEndDay, this.$scope.saveEditReportingPeriodParams.startDate, this.$scope.saveEditReportingPeriodParams.endDate)
            .then(
                (response) => {
                    if (response.statusText === "OK") {
                        this.CancelEditedPeriod();
                        location.reload();
                    }
                });
    }

}






