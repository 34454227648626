import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmEntities, RpmResources } from "Interfaces/FranForce/Rpm/RpmResources";

interface IBMPeriodStateParams extends ng.ui.IStateParamsService
{
    period?: string;
}

export class bmPeriodController implements ng.IController
{
    periods: RpmEntities.RoyaltyPeriodDropDownViewModel[];

    batchFilters: kendo.data.DataSourceFilter;
    periodFilters: kendo.data.DataSourceFilter;

    selectedPeriod: string;
    selectedBatches: RpmResources.IRoyaltyReportBatch[];

    isLoading: boolean;
    readOnly: boolean;

    static $inject = [
        '$scope',
        '$stateParams',
        'coreApiResources',
        'rpmUiApiResources',
        'identityManager',
        '$state',
        "$q",
        "$timeout"
    ]

    constructor(
        private $scope: ng.IScope,
        private $stateParams: IBMPeriodStateParams,
        private coreApiResources: CoreApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private identityManager: IdentityManager,
        private $state: ng.ui.IStateService,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService
    ) {
        
    }

    $onInit() 
    {
        this.selectedBatches = [];
        this.isLoading = true;

        let adminPromise = this.identityManager.HasRole(["BatchManagementAdmin", "Admin"])
            .then(isAdmin =>
            {
                this.readOnly = !isAdmin;
            });

        this.rpmUiApiResources.GetRoyaltyPeriodDatesByBeforeDate(kendo.toString(new Date(), 'yyyy-MM-dd'))
            .then((response) =>
            {
                this.periods = response.data;
                this.periods.forEach(value =>
                {
                    (<any>value).DisplayDate = kendo.toString(kendo.parseDate(value.PeriodEndDate), 'dddd MM/dd/yyyy');
                });
            })
            .finally(() => { this.isLoading = false; });

        this.selectedPeriod = this.$stateParams.period;
        if (this.selectedPeriod)
        {
            this.SetFilters();
        }
    }

    OnPeriodSelect()
    {
        this.$stateParams.period = this.selectedPeriod;
        this.$state.go("bm.period", this.$stateParams);
    }

    OnSelectedBatch(batches: RpmResources.IRoyaltyReportBatch[])
    {
        this.$timeout(() =>
        {
            this.selectedBatches = batches;
            this.SetPeriodFilters();
        });
    }

    SetFilters()
    {
        this.SetBatchFilters();
        this.SetPeriodFilters();
    }

    SetBatchFilters()
    {
        if (!this.selectedPeriod) {
            this.batchFilters = null;
            return;
        }

        let selectedDate = new Date(this.selectedPeriod + "Z");
        let nextDay = new Date(this.selectedPeriod + "Z");
        nextDay.setDate(nextDay.getDate() + 1);

        this.batchFilters = <kendo.data.DataSourceFilters>{
            logic: "and",
            filters: [{
                operator: "gte",
                field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyPeriod.PeriodEndDate),
                value: selectedDate
            }, {
                operator: "lt",
                field: nameof.full<RpmResources.IRoyaltyReportBatch>(o => o.RoyaltyPeriod.PeriodEndDate),
                value: nextDay
            }]
        };
    }

    SetPeriodFilters()
    {
        if (!this.selectedBatches || !this.selectedBatches.length) {
            this.periodFilters = null;
            return;
        }

        let filtersArray = <kendo.data.DataSourceFilterItem[]>[]
        this.periodFilters = <kendo.data.DataSourceFilters>{
            filters: filtersArray,
            logic: "or"
        };

        for (let batchId of this.selectedBatches.map(b => b.RoyaltyReportBatchId)) {
            filtersArray.push({
                field: nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatchId),
                operator: "eq",
                value: batchId,
            });
        }
    }
}
