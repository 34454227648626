import { ifEmptyFilter } from "Filters/IfEmptyFilter";


export function Init()
{
    let app = angular.module('portal');

    app.filter('ifEmpty', ifEmptyFilter);

    return app;
}
