import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { onUpdateEmployeeBioEvent, onDeleteEmployeeBioEvent } from "Directives/FranchiseProfiles/FranchiseProfileEmployeeBioEditComponent";
import * as _ from "underscore"
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";
import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import
{
    EducationTypeClient, EducationTypeDto,
    GenderClient, GenderDto,
    LanguageClient, LanguageDto,
    FranchiseProfileEmployeeBioClient as FranchiseProfileEmployeeBioGroupClient,
} from '@nbly/franchise-employee-group-clients';


import
{
    FranchiseEmployeeProfileSearchClient,
    FranchiseProfileEmployeeBioSearchDto,
    FranchiseProfileEmployeeBioCertificationClient,
    GetFranchiseProfileEmployeeBioCertificationDto,
    FranchiseEmployeeBioSearchResultVm,
    FranchiseProfileEmployeeBioClient,
    FranchiseProfileEmployeeBioDto,


} from '@nbly/franchise-employee-orchestrations-clients';

import
{
    FranchiseWebLocationClient,
    FranchiseWebLocationDto
} from '@nbly/franchise-group-api-clients';


export class FranchiseProfileEmployeeBiosComponent implements ng.IController
{
    franchiseId: number;
    conceptId: number;

    isLoading: boolean;
    bios: Array<CoreEntities.FranchiseProfileEmployeeBio>;
    allEmployeesMap: Map<string, IManagedUserViewModel>;

    employeeGroupClient: AxiosInstance;
    employeeOrchestrationsClient: AxiosInstance;
    educationTypeClient: EducationTypeClient;
    genderClient: GenderClient;
    languageClient: LanguageClient;
    franchiseEmployeeProfileSearchClient: FranchiseEmployeeProfileSearchClient;
    franchiseProfileEmployeeBioCertificationClient: FranchiseProfileEmployeeBioCertificationClient;
    isFirstLoad: boolean;
    genderList: GenderDto[];
    educationTypeList: EducationTypeDto[];
    languageList: LanguageDto[];
    conceptCode: string;
    franchiseEmployeeBioSearchResultVm: FranchiseEmployeeBioSearchResultVm[];
    franchiseProfileEmployeeBioEmployeeBio: FranchiseEmployeeBioSearchResultVm[];
    employeeBioWindow: kendo.ui.Window;
    employeeBioWindowOptions: kendo.ui.WindowOptions;
    selectedEmployeeBio: FranchiseEmployeeBioSearchResultVm;
    certificateList: GetFranchiseProfileEmployeeBioCertificationDto[];
    franchiseProfileEmployeeBioClient: FranchiseProfileEmployeeBioClient;
    franchiseProfileEmployeeBioGroupClient: FranchiseProfileEmployeeBioGroupClient;

    franchiseWebLocationId: number;
    franchiseWebLocationListClient: FranchiseWebLocationClient;
    franchiseWebLocationDtoArray: FranchiseWebLocationDto[];
    franchiseWebLocationDto: FranchiseWebLocationDto;
    franchiseWebLocationClient: AxiosInstance;

    static $inject = [
        'coreApiResources',
        "$scope",
        "$document"
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $scope: ng.IScope,
        private $document: ng.IDocumentService
    )
    {

    }

    async $onInit()
    {
        this.employeeGroupClient = FranForceAxiosClientBuilder.BuildEmployeeGroupBaseClient();
        this.employeeOrchestrationsClient = FranForceAxiosClientBuilder.BuildEmployeeOrchestrationsBaseClient();
        this.employeeGroupClient = FranForceAxiosClientBuilder.BuildEmployeeGroupBaseClient();
        this.employeeOrchestrationsClient = FranForceAxiosClientBuilder.BuildEmployeeOrchestrationsBaseClient();
        this.franchiseProfileEmployeeBioCertificationClient = new FranchiseProfileEmployeeBioCertificationClient("", this.employeeOrchestrationsClient);
        this.franchiseEmployeeProfileSearchClient = new FranchiseEmployeeProfileSearchClient("", this.employeeOrchestrationsClient);
        this.educationTypeClient = new EducationTypeClient("", this.employeeGroupClient);
        this.genderClient = new GenderClient("", this.employeeGroupClient);
        this.languageClient = new LanguageClient("", this.employeeGroupClient);
        this.franchiseProfileEmployeeBioClient = new FranchiseProfileEmployeeBioClient("", this.employeeOrchestrationsClient);
        this.franchiseProfileEmployeeBioGroupClient = new FranchiseProfileEmployeeBioGroupClient("", this.employeeGroupClient);
        this.franchiseWebLocationClient = FranForceAxiosClientBuilder.BuildFranchiseGroupBaseClient();
        this.franchiseWebLocationListClient = new FranchiseWebLocationClient("", this.franchiseWebLocationClient);

        await this.LoadStaticInfo();
    }

    async refreshBios()
    {
        this.franchiseEmployeeProfileSearchClient.search3(this.franchiseWebLocationId)
            .then((response) =>
            {
                this.franchiseEmployeeBioSearchResultVm = response.result;
                this.franchiseProfileEmployeeBioEmployeeBio = this.franchiseEmployeeBioSearchResultVm.filter(obj => obj.franchiseProfileEmployeeBioId);
                this.$scope.$digest();

            }).catch((err) =>
            {
                console.log('Error occured refreshing bios', err)
            });

    }

    async loadBios()
    {
        this.franchiseEmployeeProfileSearchClient.search3(this.franchiseWebLocationId)
            .then((response) =>
            {
                this.franchiseEmployeeBioSearchResultVm = response.result;
                this.franchiseProfileEmployeeBioEmployeeBio = this.franchiseEmployeeBioSearchResultVm.filter(obj => obj.franchiseProfileEmployeeBioId);
                this.$scope.$digest();

            }).catch((err) =>
            {

            });

    }

    async $postLink()
    {
        this.$document.on('franchiseWebLocationSelector-webLocationSelect', async (ev: any) =>
        {
            this.franchiseWebLocationDto = ev.originalEvent.detail;
            this.conceptId = this.franchiseWebLocationDto.conceptId;
            this.franchiseWebLocationId = this.franchiseWebLocationDto.franchiseWebLocationId;
            await this.loadBios();
            await this.loadFranchiseWeblocations();
        });

    }

    async loadFranchiseWeblocations()
    {
        if (this.franchiseId)
        {
            await this.franchiseWebLocationListClient.get_protected_franchiseweblocations_franchises_franchiseid(this.franchiseId)
                .then((res) =>
                {
                    this.franchiseWebLocationDtoArray = res.result;
                }).catch((err) =>
                {
                    console.log('Error Occured while fetching franchiseWebLocationDtos', this.franchiseWebLocationDtoArray)
                })
        }
    }

    async LoadStaticInfo()
    {
        this.educationTypeClient.educationTypes().then((response) =>
        {
            this.educationTypeList = response.result;
        })
        this.genderClient.genders().then((response) =>
        {
            this.genderList = response.result;
        })
        this.languageClient.languages().then((response) =>
        {
            this.languageList = response.result;
        })

        await this.coreApiResources.FranchiseApi.get({ id: this.franchiseId }).$promise.then((franchise) =>
        {
            this.conceptCode = franchise.ConceptCode;
        });
        if (this.conceptCode)
        {
            this.franchiseProfileEmployeeBioCertificationClient.certifications(this.conceptCode).then((certificates) =>
            {
                this.certificateList = certificates.result;

            }).catch((err) =>
            {
                this.certificateList = null;
            })
        } else
        {
            this.certificateList = null;
        }

    }

    onSave()
    {
        if (this.isFirstLoad)
        {
            let obj = this.franchiseProfileEmployeeBioEmployeeBio.filter(bio => bio.franchiseProfileEmployeeBioId == this.selectedEmployeeBio.franchiseProfileEmployeeBioId);
            obj[0].displayName = this.selectedEmployeeBio.displayName;

        }
        this.employeeBioWindow.title(this.selectedEmployeeBio.displayName);
        this.$scope.$digest();
    }

    OpenWindow()
    {
        let template =
            `<div style="width:80vw;height:80vh">
            <employee-bios-component
                gender-list="$ctrl.genderList"
                education-type-list="$ctrl.educationTypeList"
                language-list="$ctrl.languageList"
                employee-bio-vm="$ctrl.selectedEmployeeBio"
                concept-code="$ctrl.conceptCode"
                certificate-list="$ctrl.certificateList"
                on-save="$ctrl.onSave();"
                on-bio-create="$ctrl.refreshBios();"
                franchise-id="$ctrl.franchiseId"
                is-first-load="$ctrl.isFirstLoad"
                franchise-web-location-id="$ctrl.franchiseWebLocationId"
                franchise-Web-Location-Dto="$ctrl.franchiseWebLocationDtoArray"
                >
            </employee-bios-component> 
        </div>`;

        let options: kendo.ui.WindowOptions = {
            modal: true,
            position: { top: 0 }
        };

        this.employeeBioWindow.open();
        this.employeeBioWindow.title(this.selectedEmployeeBio.displayName);
        this.employeeBioWindow.content(template)
        this.employeeBioWindow.center();
        this.employeeBioWindow.setOptions(options);

    }

    EditEmployeeBio(bio: FranchiseEmployeeBioSearchResultVm)
    {
        this.selectedEmployeeBio = bio;
        this.isFirstLoad = false;
        this.OpenWindow();

    }

    async DeleteEmployeeBio(employeeBio: FranchiseEmployeeBioSearchResultVm)
    {
        if (confirm(`Do you want to delete the bio?`))
        {
            await this.franchiseProfileEmployeeBioClient.franchise_profile_employee_bios3(
                employeeBio.franchiseProfileEmployeeBioId
            ).then(async (response) =>
            {
                await this.refreshBios();

            }).catch((err) =>
            {
                console.log("Error Occured")
            })

        }
    }

    CreateNewEmployeeBio(selectedEmployee: FranchiseEmployeeBioSearchResultVm)
    {
        let emptyBioSearchDTO: FranchiseEmployeeBioSearchResultVm = {
            conceptCode: this.conceptCode,
            franchiseName: null,
            licenseNumber: null,
            doingBusinessAs: null,
            displayName: null,
            profilePic: false,
            bioDescription: false,
            syncTechnicianEmployeeId: null,
            franchiseProfileEmployeeBioId: null,
            franchiseProfileEmployeeBioXrefId: null,
            type: null
        }

        this.selectedEmployeeBio = emptyBioSearchDTO;
        this.isFirstLoad = true;
        this.OpenWindow();
    }

    OnUpdateBio(event: onUpdateEmployeeBioEvent)
    {
        let foundIndex = this.bios.findIndex(bio => bio.FranchiseProfileEmployeeBioId == event.bio.FranchiseProfileEmployeeBioId);

        if (foundIndex !== -1)
        {
            this.bios[foundIndex] = event.bio;
        }
    }

    OnDeleteBio(event: onDeleteEmployeeBioEvent)
    {
        this.bios = this.bios.filter(bio => bio.FranchiseProfileEmployeeBioId !== event.bio.FranchiseProfileEmployeeBioId);

    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfileEmployeeBios', {
            bindings: {
                franchiseId: "<"
            },
            controller: FranchiseProfileEmployeeBiosComponent,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfileEmployeeBios.html"
        });
    }
}


