import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ZeeMailApiResources } from "Services/Resources/ZeeMailApiResources";
import { KendoHelperService } from "Services/Utility/KendoHelperService";
import { AngularUtil } from "Utility/AngularUtil";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";


export class FranchiseProfileEditComponent implements ng.IController
{
    franchiseId: number;

    franchiseProfileTabs: kendo.ui.TabStrip;
    franchiseProfile: CoreResources.IFranchiseProfile;

    tooltips: {
        hoursOfOperation: string;
        servicesOffered: string;
        callCenterInfo: string;
        businessInfo: string;
    }
    lastModified: string;
    canUserViewOptInOptions: boolean = false;

    isLoading: boolean;
    isZeeMailEnabled: boolean;
    showEmployeeBios: boolean;
    isLeadStateEnabled: boolean = false;

    static $inject = [
        'coreApiResources',
        'zeeMailApiResources',
        'identityManager',
        '$window',
        '$q',
        'kendoHelper',
        'apiConfig'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private zeeMailApiResources: ZeeMailApiResources,
        private identityManager: IdentityManager,
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private kendoHelper: KendoHelperService,
        private apiConfig: ApiConfig
    )
    {

    }

    $onInit()
    {
        if (!this.franchiseId)
        {
            var urlParts = this.$window.location.pathname.split("/");
            if (!urlParts[urlParts.length - 1])
            {
                urlParts.pop();
            }
            this.franchiseId = parseInt(urlParts[urlParts.length - 1]);
        }

        this.InitTooltips();

        let params: ODataQueryParameters & {id: number} = {
            id: this.franchiseId,
            $select: [
                nameof<CoreResources.IFranchiseProfile>(o => o.FranchiseId),
                nameof<CoreResources.IFranchiseProfile>(o => o.Franchise),
                nameof<CoreResources.IFranchiseProfile>(o => o.OptInForReferANeighbor),
                nameof<CoreResources.IFranchiseProfile>(o => o.OptInForTextANeighbor),
            ].join(","),
            $expand: [
                nameof<CoreResources.IFranchiseProfile>(o => o.Franchise)
            ].join(","),
        };

        let updatedPromise = this.coreApiResources.GetFranchiseProfileLastUpdatedDateTime(this.franchiseId)
            .then((response) =>
            {
                this.lastModified = kendo.toString(new Date(response.data), "g");
            });

        let profilePromise = this.coreApiResources.FranchiseProfileApi.get(params).$promise
            .then((franchiseProfile) =>
            {
                this.franchiseProfile = franchiseProfile;

                let zeeMailEnabledPromise = this.zeeMailApiResources.GetFranchisorIsZeeMailEnabled(this.franchiseProfile.Franchise.FranchisorId)
                    .then((enabledResponse) =>
                    {
                        this.isZeeMailEnabled = enabledResponse.data;
                    });

                let conceptPromise = this.coreApiResources.ConceptApi.get({
                    id: this.franchiseProfile.Franchise.ConceptId,
                    $select: [
                        nameof<CoreResources.IConcept>(o => o.ConceptId),
                        nameof<CoreResources.IConcept>(o => o.LeadStateEnabled)
                    ].join(",")
                }).$promise
                    .then((concept) =>
                    {
                        this.isLeadStateEnabled = concept.LeadStateEnabled;
                    });

                return this.$q.all([
                    updatedPromise,
                    zeeMailEnabledPromise,
                    conceptPromise
                ]);
            })
            .catch((err: ng.IHttpPromiseCallbackArg<string>) =>
            {
                if (err.status === 404)
                {
                    console.log("Generating default profile...");
                    let newFranchiseProfile = new this.coreApiResources.FranchiseProfileApi();
                    newFranchiseProfile.FranchiseId = this.franchiseId;
                    return newFranchiseProfile.$save(() =>
                    {
                        this.$window.location.href = `/FranchiseProfiles/Edit/${newFranchiseProfile.FranchiseId}`;
                    })
                        .catch((err) =>
                        {
                            console.error(err);
                            alert("Could not generate profile's default settings. Please try again later. If the issue continues to persist, please contact your brand manager.");
                        });
                }
            })
            .then(() =>
            {
                return this.identityManager.GetLoggedInUserInfo()
                    .then((user) =>
                    {
                        let roles = this.apiConfig.FranForceConstants.RoleConstants;

                        this.showEmployeeBios = (
                            this.identityManager.UserHasRole(user.data, roles.AllConceptManagementRoles.split(",")) ||
                            this.franchiseProfile.OptInForTextANeighbor || 
                            this.franchiseProfile.OptInForReferANeighbor
                        );
                    });
            });

        AngularUtil.TrackLoadingPromise(profilePromise, this);
    }

    $postLink()
    {
        this.franchiseProfileTabs.bind(
            nameof<kendo.ui.TabStripOptions>(o => o.activate),
            (e: kendo.ui.TabStripActivateEvent) =>
            {
                if (this.RefreshEditors)
                {
                    this.RefreshEditors();
                }
            });

        this.kendoHelper.BindTabStripStateHandlers(this.franchiseProfileTabs);
    }

    InitTooltips()
    {
        this.tooltips = {
            hoursOfOperation: `
                <p>
                    <strong>After Hours (AM) - </strong>
                    <span>Are defined as any hours from midnight until noon or midnight until the start of Normal Hours that appointments can be scheduled.</span>
                </p>
                <p>
                    <strong>Normal Hours - </strong>
                    <span>Are defined as any hours in which your franchise is open for normal business.</span>
                </p>
                <p>
                    <strong>After Hours (PM) - </strong>
                    <span>Are defined as any hours from noon until midnight or the end of Normal Hours until midnight (12:00 AM EOD) that appointments can be scheduled.</span> 
                </p>
            `,
            servicesOffered: `This section allows you to specify the high priority and regular services that your business offers.
                    This help the call center better understand which services they can offer to your customer and in a later section –
                    how you want to be notified when a specific services is booked. The system has preconfigured default services for you which you can change at any time.
                    To select or deselect a service, simply click it and wait for the color and icon to change. Priority services have priority scheduling rules associated with them.
                    If you offer services that do not appear here, please contact your franchise consultant for consideration of having them added to the system.`,
            callCenterInfo: `Enter any information that you want the call center to be aware of about your business or promotions that you are currently running.
                        Information such as this will help the call center be more like you when they are speaking with the customer.`,
            businessInfo: `The business information below in this section is not editable here and is retrieved from our information in FranConnect.
                       If you see any information in this section that is not accurate, you should contact your franchise consultant to have this information updated.
                        <br><br><strong> Please Note</strong>: Any changes to the physical business information may take <i>up to 24-hours to update</i>.
                        This is separate from the information you provide within the CES Franchise Profile, as the physical business information is provided by Franconnect and must be updated in this system.
                        All other changes in the CES will update in real time.`
        }
    }

    SetPermitted = (isPermitted: boolean) =>
    {
        this.canUserViewOptInOptions = isPermitted;
    }

    RefreshEditors?: () => ng.IPromise<void>;

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfileEdit', {
            bindings: {
                [nameof<FranchiseProfileEditComponent>(o => o.franchiseId)]: "<"
            },
            controller: FranchiseProfileEditComponent,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseProfileEdit.html"
        });
    }
}