import { ApiConfig } from "AppConfig/ApiConfig";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { AngularUtil } from "Utility/AngularUtil";

export class LoginPageController implements ng.IController
{
    username: string;
    password: string;
    grantType: string;
    isLoginError: boolean = false;
    errorMessage: string;

    static $inject = [
        '$http',
        '$timeout',
        '$q',
        '$log',
        "apiConfig",
        "identityManager",
        "$window",
    ]

    constructor(
        private $http: ng.IHttpService,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private apiConfig: ApiConfig,
        private identityManager: IdentityManager,
        private $window: ng.IWindowService,
    )
    {

    }

    $onInit()
    {
        this.grantType = "password";
    }

    OnPastePassword(event: ng.IAngularEvent)
    {
        this.$timeout(() =>
        {
            if (this.password)
            {
                this.password = this.password.replace(/\s/g, "");
            }
        }, 0);
    }

    handleExpirePasswordFlow()
    {
        this.$window.location.href = `/Account/PasswordUpdate?username=${this.username}`; 
    }

    OnLoginClick()
    {
        let redirectUrl: string = "";
        this.isLoginError = false;

        return this.identityManager.PostLoginToken({
            username: this.username,
            password: this.password,
            grantType: this.grantType
        })
            .then((tokenResponse) =>
            {
                (<ILocalStorage>localStorage).FranForceAuthToken = tokenResponse.data.access_token;
                (<ILocalStorage>localStorage).FranForceAuthTokenType = tokenResponse.data.token_type;


                return this.$q.all([
                    this.$http.post<{ redirect: string }>(window.location.href, tokenResponse.data)
                        .then((response) =>
                        {
                            if (response.data.redirect)
                            {
                                redirectUrl = response.data.redirect + window.location.hash;
                            }
                            else
                            {
                                redirectUrl = "/";

                            }
                        }),

                    this.identityManager.GetLoggedInUserInfo()
                        .then((userResponse) =>
                        {
                            (<ILocalStorage>localStorage).AccountEmail = userResponse.data.Email;
                            (<ILocalStorage>localStorage).PassWordExpirationDate = userResponse.data.PassWordExpirationDate;
                        })
                ]);
            })
            .then((response) =>
            {
                window.location.href = redirectUrl;
            })
            .catch((tokenResponse) =>
            {
                this.isLoginError = true;
                if (tokenResponse?.data?.error_description) {
                    if (tokenResponse.data.error_description === 'Password has expired.') {
                        this.handleExpirePasswordFlow();
                        this.errorMessage = 'Password has expired. Please reset your password.'
                    } else {
                        this.errorMessage = tokenResponse.data.error_description;
                    }
                }else{
                    this.errorMessage = 'Please try again!'
                }

                console.log('Error: ', tokenResponse.data.error_description);
            })
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("loginPage", {
            bindings: {

            },
            controller: LoginPageController,
            templateUrl: "/Templates/Account/Login.html"
        })
    }
}