import * as elements from 'typed-html';
import { StringProperties } from "Types/StringProperties";
import { RpmEntities } from 'Interfaces/FranForce/Rpm/RpmResources';
import { FranchiseEntityRelationshipEditorOnAction, FranchiseEntityRelationshipEditorOnInit } from 'Directives/RPM/FranchiseEntity';
import { KendoUtil } from 'Utility/KendoUtil';
import { rmsFranchiseEntityRelationshipClient, RmsFranchiseEntityRelationshipApplicationClient, RmsFranchiseEntityRelationshipTypeClient } from 'Clients/Rms';
import { franchiseEntityRelationshipDetailGridDataModel } from "../../../Models/Royalty/FranchiseEntities/FranchiseEntityRelationshipDetailGridDataModel";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            rmsFranchiseEntityRelationshipsGrid: Partial<StringProperties<FranchiseEntityRelationshipsGrid>>;
        }
    }
}

export interface FranchiseEntityRelationshipsGridItem extends RpmEntities.FranchiseEntityRelationship
{
    RelationshipType: string;
    Application: string;
}

export class FranchiseEntityRelationshipsGrid implements ng.IController 
{
    isLoading: boolean;
    franchiseEntityId: number;
    grid: kendo.ui.Grid;
    editorWindow: kendo.ui.Window;

    LoadEditor: FranchiseEntityRelationshipEditorOnInit["loadEditorFunc"];

    static $inject = [
        "$timeout",
        "royaltyApiResources"
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private royaltyApiResources: RoyaltyApiResources,
    )
    {
    }

    $postLink()
    {
        this.InitGrid();
        this.editorWindow.setOptions({
            modal: true
        } as kendo.ui.WindowOptions);
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.franchiseEntityId)])
        {
            if (this.franchiseEntityId) {
                this.InitGrid();
                this.grid.dataSource.read();
                this.grid.refresh();
            } 
        }
    }

    InitGrid()
    {
        if (this.franchiseEntityId) {
            let toolbarItems = [
                {
                    template: (
                        <div>
                            <button
                                style="width:50px; min-width:50px"
                                type='button'
                                ng-click={`$ctrl.${nameof(this.OpenPopup)}(0)`}>
                                Add...
                                        </button>
                        </div>
                    )
                }
            ] as kendo.ui.GridToolbarItem[];

            let columns: Array<kendo.ui.GridColumn> = [
                {
                    field: nameof<franchiseEntityRelationshipDetailGridDataModel>(o => o.franchiseEntityRelationshipId),
                    title: "Id",
                    width: 60,
                }, {
                    field: nameof<franchiseEntityRelationshipDetailGridDataModel>(o => o.franchiseEntityRelationshipApplicationName),
                    title: "Application",
                    width: 120,
                }, {
                    field: nameof<franchiseEntityRelationshipDetailGridDataModel>(o => o.franchiseEntityRelationshipTypesAggr),
                    title: "Relationship Type",
                }, {
                    field: nameof<franchiseEntityRelationshipDetailGridDataModel>(o => o.licenseNumbers),
                    title: "License Number",
                },{
                    field: nameof<franchiseEntityRelationshipDetailGridDataModel>(o => o.effectiveDate),
                    title: "Effective Date",
                    width: 140,
                    template: (dataItem) => {
                        return kendo.toString(kendo.parseDate(dataItem.effectiveDate), 'yyyy-MM-dd');
                    }
                }, {
                    field: nameof<franchiseEntityRelationshipDetailGridDataModel>(o => o.expirationDate),
                    title: "Expiration Date",
                    width: 140,
                    template: (dataItem) => {
                        return kendo.toString(kendo.parseDate(dataItem.expirationDate), 'yyyy-MM-dd');
                    }
                }, {
                    title: "",
                    width: 140,
                    template: (dataItem) => {
                        return (
                            <button
                                style="width:50px; min-width:50px"
                                type='button'
                                ng-click={`$ctrl.${nameof(this.OpenPopup)}(${dataItem.franchiseEntityRelationshipId})`}>
                                Edit...
                                            </button>
                        );
                    }
                }
            ];
            this.grid.setOptions({
                toolbar: toolbarItems,
                dataSource: [],
                autoBind: false,
                columns: columns,
                height: 400,
                scrollable: true,
                resizable: true
            } as kendo.ui.GridOptions);
            this.isLoading = true;
            this.royaltyApiResources.GetFranchiseEntityRelationshipDetails(this.franchiseEntityId, true)
                .then(
                    (response) => {
                        this.isLoading = false;
                        if (response && response.data && response.data.length>0) {
                            let franchiseEntityRelationshipDetails = [];
                            response.data.forEach((item) => {
                                let franchiseEntityRelationshipObj = {
                                    franchiseEntityRelationshipId: item.franchiseEntityRelationshipId,
                                    franchiseEntityId: item.franchiseEntityId,
                                    franchiseEntityRelationshipApplicationName: item.franchiseEntityRelationshipApplicationName,
                                    franchiseEntityRelationshipTypesAggr: item.franchiseEntityRelationshipTypesAggr,
                                    effectiveDate: item.effectiveDate,
                                    expirationDate: item.expirationDate,
                                    licenseNumber: item.licenseNumbers
                                }
                                franchiseEntityRelationshipDetails.push(franchiseEntityRelationshipObj);
                            })
                            this.grid.dataSource.data(response.data)
                        }
                    })
                .catch(error => {
                    this.isLoading = false;
                    alert("error in fetching data for franchise entity relationship");
                });
        }
    }

    OnEditorInit(
        loadEditorFunc: FranchiseEntityRelationshipEditorOnInit["loadEditorFunc"]
    )
    {
        this.LoadEditor = loadEditorFunc;
    }

    async OnEditorAction(
        action: FranchiseEntityRelationshipEditorOnAction["actionType"]
    )
    {
        if (action != "Cancel")
        {
            await this.LoadGrid();
        }

        this.editorWindow.close();
    }

    async OpenPopup(franchiseEntityRelationshipId: number)
    {
        this.$timeout(() =>
        {
            this.LoadEditor(this.franchiseEntityId, franchiseEntityRelationshipId);
            this.editorWindow.open();
        })
        this.editorWindow.center();
    }

    async LoadGrid()
    {
        this.isLoading = true;

        try
        {
            await this.InitGrid();
            await Promise.resolve(this.grid.dataSource.read());
            await Promise.resolve(this.grid.refresh());
        }
        finally
        {
            this.$timeout(() =>
            {
                this.isLoading = false;
            });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.rmsFranchiseEntityRelationshipsGrid);

        let loadingTemplate = (
            <loadingGearsOverlay isLoading={`$ctrl.${nameof<FranchiseEntityRelationshipsGrid>(o => o.isLoading)}`} ></loadingGearsOverlay>
        );

        let relationshipEditorTemplate = (
            <div kendo-window={`$ctrl.${nameof<FranchiseEntityRelationshipsGrid>(o => o.editorWindow)}`}
                k-visible="false"
                >
                <rmsFranchiseEntityRelationshipEditor
                    onInit={`$ctrl.${nameof<FranchiseEntityRelationshipsGrid>(o => o.OnEditorInit)}(${nameof<FranchiseEntityRelationshipEditorOnInit>(o => o.loadEditorFunc)})`}
                    onAction={`$ctrl.${nameof<FranchiseEntityRelationshipsGrid>(o => o.OnEditorAction)}(${nameof<FranchiseEntityRelationshipEditorOnAction>(o => o.actionType)})`}
                    >

                </rmsFranchiseEntityRelationshipEditor>
            </div>
        );

        let gridTemplate = (
            <div ng-show={`$ctrl.${nameof<FranchiseEntityRelationshipsGrid>(o => o.franchiseEntityId)}`}>
                <h1>Franchise Entity Relationship</h1>
                <div id="franchiseEntityRelationshipGrid" kendo-grid={`$ctrl.${nameof<FranchiseEntityRelationshipsGrid>(o => o.grid)}`}
                ng-show={`$ctrl.${nameof<FranchiseEntityRelationshipsGrid>(o => o.franchiseEntityId)}`}>
                </div>
            </div>
        );

        let template = `${loadingTemplate} ${relationshipEditorTemplate} ${gridTemplate}`;

        app.component(componentName, {
            bindings: {
                [nameof<FranchiseEntityRelationshipsGrid>(o => o.franchiseEntityId)]: "<",
            },
            controller: FranchiseEntityRelationshipsGrid,
            template: template
        });
    }
}

