import { IdentityManager } from "Services/Resources/IdentityManager";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { RmsStateService } from "Services/State/RmsState";
import * as _ from "underscore"
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

export class PeriodReportAdjustmentsComponentController implements ng.IController 
{    
    royaltyPeriodReportId: number;
    franchise: Partial<CoreEntities.Franchise>;
    canEdit: boolean;
    init: (params: { self: PeriodReportAdjustmentsComponentController }) => void;
    
    isLoading: boolean;
    adjustmentTotal: number;
    newAdjustment: RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult;

    grid: kendo.ui.Grid;
    newAdjustmentWindow: kendo.ui.Window;

    static $inject = [
        'identityManager',
        '$timeout',
        '$q',
        'rpmApiResources',
        'rpmUiApiResources',
        'rmsState'
    ];

    constructor (
        private identityManager: IdentityManager,
        private $timeout: ng.ITimeoutService,
        private $q: ng.IQService,
        private rpmApiResources: RpmApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private rmsState: RmsStateService
    )
    {
    }

    $onInit()
    {
        if (this.init) {
            this.init({ self: this });
        }
    }

    $postLink()
    {
        this.newAdjustmentWindow.setOptions(<kendo.ui.WindowOptions>{
            modal: true
        });
        this.SetGridOptions();
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (this.royaltyPeriodReportId) {
            let hasChanges = (onChanges[nameof(this.royaltyPeriodReportId)]);
            let validBindings = this.royaltyPeriodReportId;
            if (hasChanges && validBindings) {
                this.RefreshGrid();
            }
        }
    }

    SetGridOptions()
    {
        let adjDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.RoyaltyPeriodReportAdjustmentId),
                    fields: {
                        [nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.RoyaltyPeriodReportAdjustmentId)]: { type: "number", validation: { required: true } },
                        [nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.CreatedDateTime)]: { type: "date", validation: { required: true } },
                        [nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.PostedDateTime)]: { type: "date", validation: { required: true } },
                        [nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.RoyaltyFeeAdjustment)]: { type: "number", validaiton: { required: true } },
                        [nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.AdFeeAdjustment)]: { type: "number", validaiton: { required: true } },
                        [nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.TechnologyFeeAdjustment)]: { type: "number", validaiton: { required: true } }, 
                        [nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.TAFSFeeAdjustment)]: { type: "number", validaiton: { required: true } },
                        [nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.TotalAdjustment)]: { type: "number", validaiton: { required: true } },
                        [nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.Description)]: { type: "string", validation: { required: true } },
                        [nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.RoyaltyPeriodReportId)]: { type: "string", validation: { required: true } }
                    }
                }
            },
            transport: {
                read: (options) =>
                {
                    this.isLoading = true;
                    this.rpmUiApiResources.GetRoyaltyPeriodReportAdjustmentsByRoyaltyPeriodReport(this.royaltyPeriodReportId)
                        .then(adjustmentsResponse =>
                        {
                            this.adjustmentTotal = _.reduce(adjustmentsResponse.data, (runningTotal, adjustment) =>
                            {
                                return runningTotal + adjustment.TotalAdjustment;
                            }, 0);
                            options.success(adjustmentsResponse.data);
                        })
                        .catch((err) => { options.error(err); })
                        .finally(() => { this.isLoading = false; });
                }
            }
        });

        let adjColumns: Array<kendo.ui.GridColumn> = [
            {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.PeriodReportAdjustmentID),
                title: "Report Adjustment Id"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.PostedDateTime),
                title: "Posted Date",
                format: "{0:d}"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.CreatedDateTime),
                title: "Adjustment Date",
                format: "{0:d}"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.RoyaltyFeeAdjustment),
                title: "Royalty Fee Adjustment",
                format: "{0:c2}"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.AdFeeAdjustment),
                title: "Ad Fee Adjustment",
                format: "{0:c2}"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.TechnologyFeeAdjustment),
                title: "Tech Fee Adjustment",
                format: "{0:c2}"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.TAFSFeeAdjustment),
                title: "TAFS Fee Adjustment",
                format: "{0:c2}"
            }, {
                field: nameof<RpmEntities.usp_RoyaltyPeriodReportAdjustmentResult>(o => o.Description),
                title: "Description"
            }
        ];

        this.grid.setOptions(<kendo.ui.GridOptions>{
            toolbar: [
                {
                    template: `<button class='pure-button' ng-disabled='!$ctrl.canEdit' promise-button ng-click="$ctrl.${nameof(this.NewAdjustmentPopup)}()">
                                    New Adjustment...
                                </button>`
                }
            ],
            columns: adjColumns,
            dataSource: adjDataSource,
            pageable: true,
            resizable: true
        });
    }

    RefreshGrid()
    {
        if (this.grid) {
            return this.$q.when(this.grid.dataSource.read())
                .then(() => {
                    this.grid.refresh();
                });
        }
    }

    NewAdjustmentPopup()
    {
        return this.rmsState.royaltyPeriodReportStateHandler.Load(this.royaltyPeriodReportId)
            .then((periodReport) =>
            {
                this.newAdjustment = {
                    RoyaltyPeriodReportAdjustmentId: 0,
                    RoyaltyPeriodReportId: this.royaltyPeriodReportId,
                    FranchiseId: periodReport.FranchiseId,
                    AdFeeAdjustment: 0,
                    RoyaltyFeeAdjustment: 0,
                    TAFSFeeAdjustment: 0,
                    TechnologyFeeAdjustment: 0,
                    TotalAdjustment: 0,
                    Description: "",
                    FranCISReportWeekID: periodReport.FranCISReportWeekID,
                    PeriodReportAdjustmentID: null,
                    PostedDateTime: null,
                    CreatedDateTime: null,
                    CreatedUser: "",
                    UpdatedDateTime: null,
                    UpdatedUser: ""
                };
                this.newAdjustmentWindow.open();
                return this.$timeout(() =>
                {
                    this.newAdjustmentWindow.center();
                }, 0);
            });
    }

    CreateNewAdjustment()
    {
        this.newAdjustment.TotalAdjustment = this.newAdjustment.AdFeeAdjustment
            + this.newAdjustment.RoyaltyFeeAdjustment
            + this.newAdjustment.TechnologyFeeAdjustment
            + this.newAdjustment.TAFSFeeAdjustment;

        return this.rpmUiApiResources.CreateRoyaltyPeriodReportAdjustment(this.newAdjustment)
            .then(() =>
            {
                return this.RefreshGrid()
                    .finally(() =>
                    {
                        this.newAdjustmentWindow.close();
                    });
            });
    }

    CancelNewAdjustment()
    {
        this.newAdjustmentWindow.close();
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('prAdjustments', {
            bindings: {
                [nameof<PeriodReportAdjustmentsComponentController>(o => o.royaltyPeriodReportId)]: "<",
                [nameof<PeriodReportAdjustmentsComponentController>(o => o.franchise)]: "<",
                [nameof<PeriodReportAdjustmentsComponentController>(o => o.canEdit)]: "<",
                [nameof<PeriodReportAdjustmentsComponentController>(o => o.init)]: "&"
            },
            controller: PeriodReportAdjustmentsComponentController,
            templateUrl: "/Templates/PeriodReport/prSections/Adjustments.html"
        });
    }
}