import { AxiosInstance } from "axios";
import { InvoiceFileUploadsClient, InvoiceFileStagingDataVm, BillingServiceClient, IntegrationFinanceClient, IntegrationFinanceInvoiceSourceTypeClient, IntegrationFinanceInvoiceSourceTypeVm, IntegrationFinanceDashboardCompanyCodeVm, IntegrationFinanceDashboardResultRm } from "@nbly/billing-orchestrations-clients";
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";
import { IdentityManager } from "../../../Services/Resources/IdentityManager";
import { ApplicationSourceVm } from "./financeIntegrationDashboardInput";
import { FinanceSyncWorkerClient } from "@nbly/service-manager-clients";


export class financeIntegrationDashboardFileUploadGridController implements ng.IController {

    //bindings
    displaySnackbar: (params: { type: string, message: string }) => void;
    //end bindings
 
    selectedBatchIdsMap: Map<number, Array<number>>;
    selectedBatchIds: Set<number>;
    userEmail: string;
    integrationFinanceDashboardResultRm: IntegrationFinanceDashboardResultRm;
    
    financeIntegrationImportEntryGrid:  kendo.ui.Grid ;
    financeIntegrationImportEntryGridOptions: kendo.ui.GridOptions;

    billingOrchestrationsClient: AxiosInstance;
    billingServicesClient: BillingServiceClient
    integrationFinanceClient: IntegrationFinanceClient
    invoiceFileUploadsClient : InvoiceFileUploadsClient
    integrationFinanceInvoiceSourceTypeClient: IntegrationFinanceInvoiceSourceTypeClient;
    localStorage: ILocalStorage;
    serviceManagerClient: AxiosInstance;

    integrationFinanceInvoiceSourceTypeClients:IntegrationFinanceInvoiceSourceTypeVm[];
    isLoading: boolean;
    
    static $inject = [
        '$scope',
        'identityManager',
        '$location',
        '$rootScope'
    ];

    selectedRows: Array<any>; // To track selected rows

    financeSyncWorkerClient: FinanceSyncWorkerClient;

    constructor(
        private $scope: ng.IScope,
        private identityManager: IdentityManager,
        private $location: ng.ILocationService,
        private $rootScope: ng.IRootScopeService,
    ) {
        this.billingOrchestrationsClient = FranForceAxiosClientBuilder.BuildBillingOrchestrationsBaseClient();
        this.billingServicesClient = new BillingServiceClient("", this.billingOrchestrationsClient);
        this.integrationFinanceClient = new IntegrationFinanceClient("", this.billingOrchestrationsClient);
        this.integrationFinanceInvoiceSourceTypeClient = new IntegrationFinanceInvoiceSourceTypeClient("", this.billingOrchestrationsClient);
        this.invoiceFileUploadsClient = new InvoiceFileUploadsClient("",this.billingOrchestrationsClient)

        this.serviceManagerClient = FranForceAxiosClientBuilder.BuildServiceManagerBaseClient();
        this.financeSyncWorkerClient = new FinanceSyncWorkerClient("", this.serviceManagerClient);

        this.selectedRows = [];
    }

    // Check if any row is selected
    IsRowSelected() {
        return this.selectedRows.length > 0;
    }
 
    $onInit() {
        this.localStorage = <ILocalStorage>localStorage;
        this.LoadUser();
        this.LoadImportEntrtyGridOptions();
    }
   

    LoadUser() {
        this.identityManager.GetLoggedInUserInfo().then(
            (success) => {
               this.isLoading = true;
               this.userEmail = success.data.Email;
               this.GetImportEntryGridData(this.userEmail);

            },
            (error) => {
                console.log(error);
            });
    }

    OnRefresh(){
        this.GetImportEntryGridData(this.userEmail);
    }

    async OnSubmit() {
        // Track the results
        const successIds = [];
        const failureIds = [];

        const selectedUploadRowsLength = this.selectedRows.length
    
        // Iterate over selected rows
        for (const row of this.selectedRows) {
            try {
                // Await each async API call
                await this.financeSyncWorkerClient.execute7(row.invoiceFileUploadId);
                successIds.push(row.invoiceFileUploadId); // Track successful IDs
                this.selectedRows = this.selectedRows.filter(item => item.invoiceFileUploadId !== row.invoiceFileUploadId);
            } catch ({ status }) {
                // Track failures with status
                failureIds.push({ id: row.invoiceFileUploadId, status });
            }
        }
    
        // Handle the result after all promises are resolved
        if (successIds.length === selectedUploadRowsLength) {
            this.DisplaySnackbar("success", "All selected invoice file uploads sync runs have been queued successfully!");
        } else if (successIds.length > 0 && failureIds.length > 0) {
            const failedIds = failureIds.map(item => item.id).join(", ");
            this.DisplaySnackbar("warning", `Some invoice file upload sync runs failed. Successful: ${successIds.join(", ")}; Failed: ${failedIds}`);
        } else if (failureIds.length === selectedUploadRowsLength) {
            this.DisplaySnackbar("error", "All selected invoice file uploads sync runs failed.");
        }
    
        // Refresh the grid after performing the operation
        this.OnRefresh();
    
        // Reset the loading state
        this.isLoading = false;
    
        // Apply scope changes
        // Check if a digest cycle is already in progress
        this.$scope.$evalAsync()
    }    

    showSnackbar: boolean;
    snackbarType: string;
    snackbarMessage: string;

    DisplaySnackbar(type: string, message: string) {
        this.showSnackbar = !this.showSnackbar;
        this.snackbarType = type;
        this.snackbarMessage = message;
        this.$scope.$evalAsync();
    }

    // Function to handle row selection
    OnRowSelect(dataItem: any, $event: any) {
        // Update the selected property manually based on checkbox state
        const isChecked = $event.target.checked;

        if (isChecked) {
            // Add selected property to dataItem
            dataItem.selected = true;

            // If the row is selected, add it to selectedRows
            this.selectedRows.push(dataItem);
        } else {
            // Unselect the row and remove it from selectedRows
            dataItem.selected = false;
            this.selectedRows = this.selectedRows.filter(item => item.invoiceFileUploadId !== dataItem.invoiceFileUploadId);
        }

        // Check if a digest cycle is already in progress
        this.$scope.$evalAsync()
    }   

    LoadImportEntrtyGridOptions() {
        let financeIntegrationImportEntryDataSource = new kendo.data.DataSource({
            data: [],
            pageSize: 25,
            schema: {
                model: {
                    id: "invoiceFileUploadId",
                    type:"number",
                    fields: {
                        invoiceFileUploadId: {
                            type: "number"
                        },
                        integrationFinanceInvoiceSourceTypeId: {
                            type: "string"
                        },
                        uploadedOn: {
                            type: "date"
                        },
                        createdByUser: {
                            type: "string"
                        }
                    }
                }
            }
        });

        const financeIntegrationImportEntryGridColumns: Array<kendo.ui.GridColumn> = [
            {
                template: `<input type='checkbox' ng-click='$ctrl.OnRowSelect(dataItem, $event)' ng-disabled='dataItem.invoiceFileUploadStatus !== 1' />`,
                width: "50px"
            },
            {
                field: "invoiceFileUploadId",
                title: "Upload Id",
            },
            {
                field: "integrationFinanceInvoiceSourceTypeId",
                title: "Source Type",
                template: function (dataItem) {
                    if (dataItem.integrationFinanceInvoiceSourceTypeId === 1) {
                        return "RMS";
                    }
                    else if (dataItem.integrationFinanceInvoiceSourceTypeId === 2) {
                        return "ZOR Billing";
                    }
                    else if (dataItem.integrationFinanceInvoiceSourceTypeId === 3) {
                        return "RBW Fusion";
                    }
                    else if (dataItem.integrationFinanceInvoiceSourceTypeId === 4) {
                        return "NSS";
                    }
                    else if (dataItem.integrationFinanceInvoiceSourceTypeId === 5) {
                        return "GOM";
                    }
                    else if (dataItem.integrationFinanceInvoiceSourceTypeId === 6) {
                        return "JUK";
                    }
                    else if (dataItem.integrationFinanceInvoiceSourceTypeId === 7) {
                        return "PDS";
                    }
                    else if (dataItem.integrationFinanceInvoiceSourceTypeId === 8) {
                        return "Online Res";
                    }
                    else if (dataItem.integrationFinanceInvoiceSourceTypeId === 9) {
                        return "BOI";
                    }
                    else if (dataItem.integrationFinanceInvoiceSourceTypeId === 10) {
                        return "SHG";
                    }
                }
            }, 
            {
                field: "invoiceFileUploadStatus",
                title: "Invoice File Upload Status",
                template: function (dataItem) {
                    switch (dataItem.invoiceFileUploadStatus) {
                        case 1:
                            return "Received";
                        case 2:
                            return "Schema Validation Failed";
                        case 3:
                            return "Queued";
                        case 4:
                            return "Validation Failed";
                        case 5:
                            return "In Progress";
                        case 6:
                            return "Completed";
                        case 7:
                            return "Failed";
                        default:
                            return "Unknown";
                    }
                }
            },
            {
                field: "createdUser",
                title: "Created User"
            }, 
            {
                field: "uploadedOn",
                title: "Upload Date",
                template: "#= kendo.toString(kendo.parseDate(uploadedOn), 'MM/dd/yyyy hh:mm:ss tt') #",
            }            
        ];
        

        this.financeIntegrationImportEntryGridOptions = {
            toolbar: [
                {
                    template: `<button class='financeIntegrationDashboard_refreshBtn' ng-click='$ctrl.OnRefresh()'>Refresh</button>`
                },],
            autoBind: false,
            dataSource: financeIntegrationImportEntryDataSource,
            columns: financeIntegrationImportEntryGridColumns,  
            pageable: {
                numeric: true,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
        };
    }

    async GetImportEntryGridData(userEmail: string) {
        await this.invoiceFileUploadsClient.invoiceFileUploadsAll(userEmail).then(
            (response) => {
                this.financeIntegrationImportEntryGrid.dataSource.data(response?.result)
                this.financeIntegrationImportEntryGrid.refresh();
            }
        )
            .catch(({ status }) => {
                switch (status) {
                    case 403: //Unauthorized
                        this.displaySnackbar({ type: "warning", message: "You are unauthorized to view invoice file imports!" });
                        break;
                    default:
                        this.displaySnackbar({ type: "error", message: "An error occured when trying to load invoice file import data!" })
                }
            })
            .finally(() => {
                this.isLoading = false;
                // Check if a digest cycle is already in progress
                this.$scope.$evalAsync()
            })
    }
    
    static BindComponent(app: ng.IModule) {
        app.component("financeIntegrationDashboardFileUploadGrid", {
            bindings: {
                [nameof<financeIntegrationDashboardFileUploadGridController>(o => o.displaySnackbar)]: "&"
            },
            templateUrl: '/Templates/FinanceIntegrationDashboard/financeIntegrationDashboardFileUploadGrid.html',
            controller: financeIntegrationDashboardFileUploadGridController
        });
    }
}