import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
export class RoyaltyDisclosureDocumentRatePlanMinimumItemDetailsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;
        
    GetRoyaltyDisclosureDocumentRatePlanMinimumItemDetailsByRatePlanMinimumItem(ratePlanMinimumItemId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlanMinimumItemDetails/InRatePlanMinimumItem/" + ratePlanMinimumItemId
        };

        return this.$http<RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel[]>(config);
    }

    CreateRoyaltyDisclosureDocumentRatePlanMinimumItemDetail(model: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlanMinimumItemDetails",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyDisclosureDocumentRatePlanMinimumItemDetail(id: number, model: RpmEntities.RoyaltyDisclosureDocumentRatePlanMinimumItemDetailViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyDisclosureDocumentRatePlanMinimumItemDetails/" + id,
            data: model
        };

        return this.$http<number>(config);
    }
}