import * as _ from "underscore"
import { FranForceAxiosClientBuilder, } from "Clients/FranForceAxiosClientBuilder";
import { AxiosInstance } from "axios";
import
{
    MeetTheTeamClient, MeetTheTeamInfoWithEmpBioDto, MeetTheTeamInfoDto
} from '@nbly/franchise-employee-orchestrations-clients';
import { Helpers } from "Utility/Helpers"
import
{
    FranchiseWebLocationDto
} from '@nbly/franchise-group-api-clients';
import * as CropperTypes from 'cropperjs';
import Cropper from 'cropperjs/dist/cropper.esm';
const Compressor = require('compressorjs');


export class MeetTheTeamPopupComponent implements ng.IController
{
    franchiseId: number;
    conceptId: number;
    isLoading: boolean;
    onSave: () => void;


    //Clients
    employeeOrchestrationsClient: AxiosInstance;
    meetTheTeamClient: MeetTheTeamClient;

    //DTOS
    meetTheTeamInfoWithEmpBioDto: MeetTheTeamInfoWithEmpBioDto;
    meetTheTeamInfoDto: MeetTheTeamInfoDto;
    franchiseWebLocationId:number;
    franchiseWebLocationDto: FranchiseWebLocationDto[];

    //Upload
    defaultKendoUploadOptions: kendo.ui.UploadOptions;
    allowedUploadExtensions: string[];
    rawFile: any;

    //Images
    squareImageURL: any;
    circleImageURL: any;
    inSquareImageEditMode: boolean;
    inCircleImageEditMode: boolean;
    squareImageFile: any;
    circleImageFile: any;

    //Cropper Tool
    squareCropperOption: CropperTypes.default.Options
    squareCropper: CropperTypes.default;
    circleCropper: CropperTypes.default;
    cricleCropperOptions: CropperTypes.default.Options;
    imageFile: any;

    //Compressor
    Compressor: any;

    //Kendo
    typeOptions: kendo.ui.DropDownListOptions;
    teamPhotoExsists: boolean;
    weblocationMultiSelect: kendo.ui.MultiSelect;
    weblocationMultiSelectOptions: kendo.ui.MultiSelectOptions;

    isImageUploadCircle: boolean = false;
    isImageUploadSquare: boolean = false;


    static $inject = [
        "$scope",
        '$timeout',
    ];

    constructor(
        private $scope: ng.IScope,
        private $timeout: ng.ITimeoutService,
    )
    {
        this.employeeOrchestrationsClient = FranForceAxiosClientBuilder.BuildEmployeeOrchestrationsBaseClient();
        this.meetTheTeamClient = new MeetTheTeamClient("", this.employeeOrchestrationsClient);

    }

    async $onInit()
    {
        if (this.meetTheTeamInfoDto.meetTheTeamInfoId)
        {
            this.squareImageURL = this.meetTheTeamInfoDto.squarePhotoUrl;
            this.circleImageURL = this.meetTheTeamInfoDto.circularPhotoUrl;
        }

        this.InitUploadComponent();
    

    }

    async $postLink()
    {
        this.SetDropDownOptions();
        this.SetDropDownOptionsForMultiSelect();
    }

    SetDropDownOptionsForMultiSelect(){
        this.weblocationMultiSelectOptions =
        {
            placeholder: "Select Weblocations...",
            dataTextField: nameof.full<FranchiseWebLocationDto>(o => o.doingBusinessAs),
            dataValueField: nameof.full<FranchiseWebLocationDto>(o => o.franchiseWebLocationId),
            valuePrimitive: true,
            autoBind: true,
            dataSource: this.franchiseWebLocationDto,
            dataBound: (e: kendo.ui.MultiSelectDataBoundEvent) =>
            {
                this.weblocationMultiSelect = e.sender;
            },
        };
    }
    SetDropDownOptions()
    {
        let dropDownDataSource:kendo.data.DataSource;
        dropDownDataSource = new kendo.data.DataSource({
            data: [
                { name: "Team", value: 'Team' },
                { name: "Staff", value: 'Staff' },
            ],
        });

        if (this.teamPhotoExsists)
        {
            if (this.meetTheTeamInfoDto && this.meetTheTeamInfoDto.meetTheTeamInfoId == 0)
            {
                dropDownDataSource = new kendo.data.DataSource({
                    data: [

                        { name: "Staff", value: 'Staff' },
                    ],
                });
            }
            if (this.meetTheTeamInfoDto && this.meetTheTeamInfoDto.type == 'Team')
            {
                dropDownDataSource = new kendo.data.DataSource({
                    data: [
                        { name: "Team", value: 'Team' },
                    ],
                });
            } else if (this.meetTheTeamInfoDto && this.meetTheTeamInfoDto.type == 'Staff')
            {
                dropDownDataSource = new kendo.data.DataSource({
                    data: [
                        { name: "Staff", value: 'Staff' },
                    ],
                });
            }


        }

        this.typeOptions = {
            valuePrimitive: false,
            dataValueField: "value",
            dataTextField: "name",
            autoBind: true,
            optionLabel: 'None',
            dataSource: dropDownDataSource,
            select: (e: kendo.ui.DropDownListSelectEvent) => {
                if(e.dataItem.value == 'Team'){
                    //If selected Type is team, assign all weblocationIds to the record
                   this.meetTheTeamInfoDto.webLocationIds = this.franchiseWebLocationDto.map(a => a.franchiseWebLocationId);
                }
            
            }
        };
    }

    async masterSave()
    {
        if (this.meetTheTeamInfoDto && this.meetTheTeamInfoDto.type == 'Team')
        {
            this.meetTheTeamInfoDto.title = null;
        }
        if (this.meetTheTeamInfoDto.meetTheTeamInfoId)
        {
            //Put Call
            if (!Helpers.isUrl(this.meetTheTeamInfoDto.circularPhotoUrl))
            {
                this.meetTheTeamInfoDto.photoExtension = this.meetTheTeamInfoDto.photoExtension.replace('data:image/', '').replace(';base64', '');
            }

            if (!Helpers.isUrl(this.meetTheTeamInfoDto.squarePhotoUrl))
            {
                this.meetTheTeamInfoDto.photoExtension = this.meetTheTeamInfoDto.photoExtension.replace('data:image/', '').replace(';base64', '');
            }
            await this.meetTheTeamClient.meetTheTeam4(this.meetTheTeamInfoDto).then((res) =>
            {

                this.meetTheTeamInfoDto = res.result;
                this.onSave();
            }).catch((err) =>
            {
                console.log('Error while saving', err)
            })
        } else
        {
            this.meetTheTeamInfoDto.photoExtension = this.meetTheTeamInfoDto.photoExtension.replace('data:image/', '').replace(';base64', '');
            await this.meetTheTeamClient.meetTheTeam3(this.meetTheTeamInfoDto).then((res) =>
            {

                this.meetTheTeamInfoDto = res.result;
                this.SetDropDownOptions();
                this.onSave();
            }).catch((err) =>
            {
                console.log('error occured while saving', err)
            })
        }

    }


    InitUploadComponent()
    {
        this.allowedUploadExtensions = [".jpg", ".png", ".webp", ".jpeg"];
        this.defaultKendoUploadOptions = {
            showFileList: true,
            multiple: false,
            async: {
                autoUpload: false,
            },
            validation: {
                allowedExtensions: this.allowedUploadExtensions
            },
            localization: {
                select: 'Select Photo',

            },
            select: this.onSelect
        };
    }

    onSelect = (e) =>
    {
        if (e.files[0].validationErrors?.length)
        {
            console.log('error occured')
        } else
        {
            this.setImage(e);
        }
    }

    async FindOptimalQuality(file)
    {
        let result;
        for (let i = 10; i > 0; i--)
        {
            result = await new Promise((resolve, reject) =>
            {
                new Compressor(file, {
                    quality: i / 10,
                    maxWidth: 1000,
                    convertSize: 500000,
                    success: resolve,
                    error: reject,
                });
            });

            if (result.size < 500000)
            {
                console.log('break from the loop');
                break;
            }
        }
        return result;
    }

    async compress(file)
    {
        try
        {
            let response = await this.FindOptimalQuality(file);
            return response;
        } catch (err)
        {

        }

    }

    async setImage(e: kendo.ui.UploadSelectEvent)
    {

        const file = e.files[0].rawFile;
        await this.compress(file).then(res =>
        {
            this.rawFile = e.files[0].rawFile;
            this.imageFile = res;
            let reader = new FileReader();
            reader.onload = (event) =>
            {

                this.isImageUploadCircle = true;
                this.isImageUploadSquare = true;
                this.squareImageURL = event.target.result;
                this.circleImageURL = angular.copy(this.squareImageURL);

                this.$scope.$digest();
                this.$timeout(() =>
                {

                    this.InitCircularEditTool();
                    this.InitSquareEditTool();
                });
            };
            reader.readAsDataURL(res);


        })

    }

    InitCircularEditTool()
    {

        this.inCircleImageEditMode = !this.inCircleImageEditMode;
        if (this.inCircleImageEditMode)
        {
            let contents = $('#circleImageContainerMeetTheTeam')[0] as HTMLImageElement;
            this.cricleCropperOptions = {
                dragMode: 'move',
                /*     scalable: true,
                    rotatable: true, */
                aspectRatio: 1,
                /*   center: true,
                  autoCrop: true, */
                checkCrossOrigin: false,
                checkOrientation: false,
                viewMode: 2,

                crop(event)
                {

                },
            }
            this.circleCropper = new Cropper(contents, this.cricleCropperOptions);
        } else
        {
            this.circleCropper.destroy();
            this.circleCropper = null;
        }
    }

    InitSquareEditTool()
    {
        this.inSquareImageEditMode = !this.inSquareImageEditMode;
        if (this.inSquareImageEditMode)
        {
            if (this.squareCropper)
            {
                this.squareCropper.destroy();
                this.squareCropper = null;
            }

            let contents = $('#squareImageContainerMeetTheTeam')[0] as HTMLImageElement;
            this.squareCropperOption = {
                dragMode: 'move',
                checkCrossOrigin: false,
                checkOrientation: false,
                aspectRatio: 1,
                scalable: true,
                rotatable: true,
                center: true,
                autoCrop: true,
                viewMode: 2,
                crop(event)
                {

                },
            }
            this.squareCropper = new Cropper(contents, this.squareCropperOption);
        } else
        {
            this.squareCropper.destroy();
            this.squareCropper = null;
        }

    }

    saveCircularImage()
    {
        try
        {
            let croppedCanvas = this.circleCropper.getCroppedCanvas({ imageSmoothingEnabled: false, imageSmoothingQuality: "high" });
            let roundedCanvas = this.getRoundedCanvas(croppedCanvas);
            this.circleImageURL = roundedCanvas.toDataURL(/* imageType */);
            [this.meetTheTeamInfoDto.photoExtension, this.meetTheTeamInfoDto.circularPhotoUrl] = this.GenerateBase64WithoutMetaInfo(this.circleImageURL);

            this.circleCropper.destroy();
            this.inCircleImageEditMode = false;
            if (this.isImageUploadCircle)
            {
                this.isImageUploadCircle = false;
            }

        } catch (err)
        {
            console.log('error occured while saving image', err);
        }
    }

    saveSquareImage()
    {
        try
        {
            this.squareImageURL = this.squareCropper.getCroppedCanvas({ imageSmoothingEnabled: false, imageSmoothingQuality: "high" }).toDataURL();
            [this.meetTheTeamInfoDto.photoExtension, this.meetTheTeamInfoDto.squarePhotoUrl] = this.GenerateBase64WithoutMetaInfo(this.squareImageURL);
            this.squareCropper.destroy();
            this.inSquareImageEditMode = false;
            if (this.isImageUploadSquare)
            {
                this.isImageUploadSquare = false;
            }

        } catch (err)
        {
            console.log('error occured while saving image', err);
        }
    }

    GenerateBase64WithoutMetaInfo(base64URL: string)
    {
        return base64URL.split(',');
    }

    getRoundedCanvas(sourceCanvas)
    {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        var width = sourceCanvas.width;
        var height = sourceCanvas.height;

        canvas.width = width;
        canvas.height = height;
        context.imageSmoothingEnabled = true;
        context.drawImage(sourceCanvas, 0, 0, width, height);
        context.globalCompositeOperation = 'destination-in';
        context.beginPath();
        context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
        context.fill();
        return canvas;
    }


    static BindComponent(app: ng.IModule)
    {
        app.component('meetTheTeamPopupComponent', {
            bindings: {
                franchiseId: "<",
                meetTheTeamInfoDto: "=",
                onSave: "&",
                teamPhotoExsists: "<",
                [nameof<MeetTheTeamPopupComponent>(o => o.franchiseWebLocationId)]: "<",
                franchiseWebLocationDto: "<",
            },
            controller: MeetTheTeamPopupComponent,
            templateUrl: "/Templates/MeetTheTeam/MeetTheTeamPopupComponent.html"
        });
    }
}


