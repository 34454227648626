export class FeeTypesComponentController implements ng.IController
{
    //---- Bindings ----
    feeTypeGrid: kendo.ui.Grid;
    feeTypeDataSource: kendo.data.DataSource;
    //------------------

    feeOptions: kendo.ui.NumericTextBoxOptions;

    static $inject = [
    ];
    
    constructor()
    {

    }

    $onInit()
    {

    }

    $postLink()
    {
        
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.feeTypeDataSource)] && this.feeTypeGrid) {
            this.feeTypeGrid.setOptions(<kendo.ui.GridOptions>{
                dataSource: this.feeTypeDataSource
            });
        }
    }

    InitGridOptions()
    {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof<IFeeType>(o => o.Name),
                title: "Name"
            },
            {
                field: nameof<IFeeType>(o => o.DefaultFee),
                title: "Fee"
            },
            {
                field: nameof<IFeeType>(o => o.DefaultIsWaived),
                title: "Is Waived",
                template: `<button class="pure-button mr-1" ng-click="dataItem.${nameof<IFeeType>(o => o.DefaultIsWaived)} = !dataItem.${nameof<IFeeType>(o => o.DefaultIsWaived)}">
                            <i ng-class="dataItem.${nameof<IFeeType>(o => o.DefaultIsWaived)} ? 'fa fa-check-square-o mr-1' : 'fa fa-square-o mr-1'" aria-hidden="true"></i>
                        </button>`,
                width: 60
            },
            {
                field: nameof<IFeeType>(o => o.DefaultAfterHoursFee),
                title: "Fee (After Hours)"
            },
            {
                field: nameof<IFeeType>(o => o.DefaultAfterHoursIsWaived),
                title: "Is Waived (After Hours)",
                template: `<button class="pure-button mr-1" ng-click="dataItem.${nameof<IFeeType>(o => o.DefaultAfterHoursIsWaived)} = !dataItem.${nameof<IFeeType>(o => o.DefaultAfterHoursIsWaived)}">
                            <i ng-class="dataItem.${nameof<IFeeType>(o => o.DefaultAfterHoursIsWaived)} ? 'fa fa-check-square-o mr-1' : 'fa fa-square-o mr-1'" aria-hidden="true"></i>
                        </button>`,
                width: 60
            }
        ];

        this.feeTypeGrid.setOptions(<kendo.ui.GridOptions>{
            columns: columns,
            dataSource: this.feeTypeDataSource,
            editable: true,
        });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("feeTypes", {
            bindings: {
                feeTypeDataSource: "<"
            },
            controller: FeeTypesComponentController,
            templateUrl: "/Templates/Common/FeeTypes.html",
        });
    }
}


