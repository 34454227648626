import * as moment from "moment"


export class RmsWeeksInBusinessDisplayComponentController implements ng.IController 
{
    conceptId: number;
    calculationDate: string | Date;
    contractSignedDate: string | Date;
    royaltyBeginDate: string | Date;
    onCalc: (params: {
        weeksInBusiness: number,
        daysInBusiness: number,
        duration: moment.Duration,
        pivotField: string
    }) => void;

    weeksInBusiness: number;
    daysInBusiness: number;

    static $inject = [

    ]

    constructor(
    )
    {

    }

    $onInit()
    {

    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (!this.calculationDate) {
            let today = new Date();
            this.calculationDate = moment({ year: today.getFullYear(), month: today.getMonth(), date: today.getDate() }).toDate();
        }

        if (this.calculationDate && this.contractSignedDate && this.royaltyBeginDate && this.conceptId) {
            this.CalculateWeeksInBusiness()
        }
    }

    CalculateWeeksInBusiness()
    {
        let pivotField = this.PickPivotField(this.conceptId, this.contractSignedDate, this.royaltyBeginDate);
        let start = moment(this[pivotField]);
        let end = moment(this.calculationDate); 
        start.utcOffset("day");
        end.startOf("day");
        start.utcOffset(0, true);
        end.utcOffset(0, true);
        let duration = moment.duration(end.diff(start));
        let totalDaysInBusiness = duration.asDays();

        this.weeksInBusiness = Math.trunc(totalDaysInBusiness / 7);
        this.daysInBusiness = (totalDaysInBusiness >= 0 ? 1 : -1) * (Math.abs(totalDaysInBusiness) % 7);

        if (this.onCalc) {
            this.onCalc({
                weeksInBusiness: this.weeksInBusiness,
                daysInBusiness: this.daysInBusiness,
                duration: duration,
                pivotField: pivotField
            });
        }
    }

    PickPivotField(conceptId: number, contractSignedDate: string | Date, royaltyBeginDate: string | Date)
    {
        if ([1, 2, 13].every(cid => cid != conceptId))
        {
            let pivotDate = moment("2016-04-01");
            let pivotField = pivotDate.isBefore(contractSignedDate, "day") ? nameof(contractSignedDate) : nameof(royaltyBeginDate);
            return pivotField;
        }
        else if ([2, 13].some(cid => cid == conceptId))
        {
            let pivotDate = moment("2018-09-25");
            let pivotField = pivotDate.isBefore(contractSignedDate, "day") ? nameof(contractSignedDate) : nameof(royaltyBeginDate);
            return pivotField;
        }
        else if (1 == conceptId) {
            let pivotDate = moment("2020-10-07");
            let pivotField = pivotDate.isBefore(contractSignedDate, "day") ? nameof(contractSignedDate) : nameof(royaltyBeginDate);
            return pivotField;
        }
        else {
            return nameof(contractSignedDate);
        }
    }

    static BindComponent(app: ng.IModule)
    {
        app
            .component("rmsWeeksInBusinessDisplay", {
                bindings: {
                    [nameof<RmsWeeksInBusinessDisplayComponentController>(o => o.conceptId)]: "<",
                    [nameof<RmsWeeksInBusinessDisplayComponentController>(o => o.calculationDate)]: "<",
                    [nameof<RmsWeeksInBusinessDisplayComponentController>(o => o.contractSignedDate)]: "<",
                    [nameof<RmsWeeksInBusinessDisplayComponentController>(o => o.royaltyBeginDate)]: "<",
                    [nameof<RmsWeeksInBusinessDisplayComponentController>(o => o.onCalc)]: "&?"
                },
                template: `<div class='wib-weeks'>
                    <span class='wib-label'>Weeks:</span>
                    <span class='wib-value' ng-bind='$ctrl.${nameof<RmsWeeksInBusinessDisplayComponentController>(o => o.weeksInBusiness)}'></span>
                    </div>
                    <div class='wib-days'>
                    <span class='wib-label'>Days:</span>
                    <span class='wib-value' ng-bind='$ctrl.${nameof<RmsWeeksInBusinessDisplayComponentController>(o => o.daysInBusiness)}'></span>
                    </div>`,
                controller: RmsWeeksInBusinessDisplayComponentController
            });
    }
}

