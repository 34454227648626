
import { UriHelperService } from 'Services/Utility/UriHelperService';
import { BillingApiResources } from 'Services/Resources/BillingApiResources';
import { FranchiseOrchestrationsApiResources } from 'Services/Resources/FranchiseOrchestrationsApiResources';
import { ODataHelperService } from 'Services/Utility/ODataHelperService';
import { GeoService } from 'Services/Utility/GeoService';
import { KendoHelperService } from 'Services/Utility/KendoHelperService';
import { CallCenterApiResources } from 'Services/Resources/CallCenterApiResources';
import { CoreApiResources } from 'Services/Resources/CoreApiResources';
import { DppApiResources } from 'Services/Resources/DisasterPrepProfileApiResources';
import { IdentityManager } from 'Services/Resources/IdentityManager';
import { LegalApiResources } from 'Services/Resources/LegalApiResources';
import { MerchantApiResources } from 'Services/Resources/MerchantApiResources';
import { RpmApiResources } from 'Services/Resources/RpmApiResources';
import { RpmHelperService } from 'Services/Utility/RpmHelperService';
import { RpmUiApiResources } from 'Services/Resources/RpmUiApiResources';
import { SyncApiResources } from 'Services/Resources/SyncApiResources';
import { ZeeMailApiResources } from 'Services/Resources/ZeeMailApiResources';
import { MollyDonationsShelterApiResources } from 'Services/Resources/MollyDonationsShelterResources';
import { CallCenterStateService } from 'Services/State/CallCenterState';
import { RmsContractManagementStateService } from 'Services/State/RmsContractManagementStateService';
import { RmsStateService } from 'Services/State/RmsState';
import { EditUserStateService } from 'Services/State/EditUserStateService';
import { StorageService } from 'Services/StorageService';
import { WebImageApiResources } from 'Services/Resources/WebImageApiResources';
import { EmployeeBiosSearchResources } from 'Services/Resources/EmployeeBiosSearchResources';
import { ServiceOrchestrationApiResources } from 'Services/Resources/ServiceOrchestrationApiResources';
import { KnowledgeApiResources } from '../Services/Resources/KnowledgeApiResources';
import { RoyaltyApiResources } from '../Services/Resources/RoyaltyApiResources';
import { ServiceManagerApiResources } from '../Services/Resources/ServiceManagerApiResources';
import { NotificationGroupApiResources } from 'Services/Resources/NotificationGroupApiResources';
import { FranchiseeStateService } from '../Services/State/FranchiseeStateService';

export function Init()
{   
    let app = angular.module('portal');
    app.service("odataHelper", ODataHelperService);
    app.service("uriHelper", UriHelperService);
    app.service("geo", GeoService);
    app.service("kendoHelper", KendoHelperService);

    app.service("callCenterState", CallCenterStateService);
    app.service("editUserState", EditUserStateService);
    app.service('rmsContractManagementState', RmsContractManagementStateService);
    app.service("rmsState", RmsStateService);

    app.service("identityManager", IdentityManager);
    app.service("billingApiResources", BillingApiResources);
    app.service("franchiseOrchestrationsApiResources", FranchiseOrchestrationsApiResources);
    app.service("callCenterApiResources", CallCenterApiResources);
    app.service("coreApiResources", CoreApiResources);
    app.service("dppApiResources", DppApiResources);
    app.service("legalApiResources", LegalApiResources);
    app.service("merchantApiResources", MerchantApiResources);
    app.service("rpmApiResources", RpmApiResources);
    app.service("rpmHelper", RpmHelperService);
    app.service("rpmUiApiResources", RpmUiApiResources);
    app.service("syncApiResources", SyncApiResources);
    app.service("webImageApiResources", WebImageApiResources);
    app.service("zeeMailApiResources", ZeeMailApiResources);
    app.service("MollyDonationsShelterApiResources", MollyDonationsShelterApiResources);
    app.service("employeeBiosSearchResources", EmployeeBiosSearchResources);
    app.service("serviceOrchestrationApiResources", ServiceOrchestrationApiResources);
    app.service("notificationGroupApiResources", NotificationGroupApiResources);
    app.service("franchiseeStateService", FranchiseeStateService);
    StorageService.BindService(app)

    app.service("knowledgeApiResources", KnowledgeApiResources);
    app.service("royaltyApiResources", RoyaltyApiResources);
    app.service("serviceManagerApiResources", ServiceManagerApiResources);
}