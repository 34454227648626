import { StringProperties } from "Types/StringProperties";
import * as elements from 'typed-html';
import { ExpressionBinding } from "Utility/AngularUtil";

export type ToggleCheckboxOnCheck = { newValue: boolean };

declare global
{
    namespace JSX
    {
        interface IntrinsicElements
        {
            toggleCheckbox: Partial<StringProperties<ToggleCheckboxComponent>>;
            toggleLabel: {};
        }
    }
}

export class ToggleCheckboxComponent implements ng.IController
{
    isChecked: boolean;
    onCheck: ExpressionBinding<ToggleCheckboxOnCheck>;
    ngDisabled: boolean = false;

    static $inject = [
    ];

    constructor(
    )
    {
    }

    $onInit()
    {

    }

    OnChange()
    {
        if (this.ngDisabled)
        {
            this.isChecked = !this.isChecked;
            return;
        }

        if (this.onCheck)
        {
            this.onCheck({ newValue: this.isChecked });
        }
    }

    static BindComponent(app: ng.IModule)
    {
        let componentName = nameof<JSX.IntrinsicElements>(o => o.toggleCheckbox);

        app.component(componentName, {
            bindings: {
                [nameof<ToggleCheckboxComponent>(o => o.ngDisabled)]: "<",
                [nameof<ToggleCheckboxComponent>(o => o.isChecked)]: "<",
                [nameof<ToggleCheckboxComponent>(o => o.onCheck)]: "&?",
            },
            controller: ToggleCheckboxComponent,
            transclude: {
                label: "toggleLabel"
            },
            template: (
                <label class="toggle">
                    <input class="toggle-checkbox"
                        type="checkbox"
                            ng-model={`$ctrl.${nameof<ToggleCheckboxComponent>(o => o.isChecked)}`}
                            ng-change={`$ctrl.${nameof<ToggleCheckboxComponent>(o => o.OnChange)}()`}
                        />
                    <div class="toggle-switch"></div>
                    <span class="toggle-label" ng-transclude="label"></span>
                </label>
            ),
        });
    }
}

