import { Helpers } from "Utility/Helpers";

export class OpenTimeEditorComponentController implements ng.IController
{
    startMin: number;
    endMin: number;
    isOpen: boolean;
    openType: string;
    onChange: (params: { startMin: number, endMin: number, isOpen: boolean }) => void;
    onFocusChange: (params: { isFocused: boolean }) => void;
    
    startOptions: kendo.ui.ComboBoxOptions;
    endOptions: kendo.ui.ComboBoxOptions;

    showError: { start: boolean, end: boolean };

    static $inject = [
        "$timeout"
    ];

    constructor(
        private $timeout: ng.ITimeoutService
    )
    {
    }

    $onInit()
    {
        this.showError = { start: false, end: false };
        this.InitTimePickers();
    }

    $onChanges(changes: ng.IOnChangesObject)
    {

    }

    InitTimePickers()
    {
        let dropDownMinutes: { minute: number, display: string } [] = [];
        let interval = 15
        for (let min = 0; min < 1440; min += interval) {
            dropDownMinutes.push({
                minute: min,
                display: kendo.toString(this.MinutesToDate(min), 't')
            });
        }
        dropDownMinutes.push({
            minute: 1440,
            display: kendo.toString(this.MinutesToDate(1440), 't') + " EOD"
        });

        this.startOptions = {
            valuePrimitive: true,
            dataTextField: "display",
            dataValueField: "minute",
            filter: "contains",
            autoWidth: true,
            dataSource: new kendo.data.DataSource({ data: dropDownMinutes }),
            dataBound: (e) =>
            {
                e.sender.input.on("focus", () => { this.ChangeFocus(true); })
                e.sender.input.on("blur", () => { this.ChangeFocus(false); })
            },
            select: (e) =>
            {
                let selectedTime: { minute: number, display: string } = e.dataItem;

                if (selectedTime.minute > this.endMin) {
                    this.$timeout(() =>
                    {
                        this.showError.start = true;
                        this.$timeout(() =>
                        {
                            this.showError.start = false;
                        }, 5000);
                    });
                    e.preventDefault();
                    return;
                }

                this.startMin = selectedTime.minute;
                this.TriggerOnChange();
            }
        }

        this.endOptions = {
            valuePrimitive: true,
            dataTextField: "display",
            dataValueField: "minute",
            filter: "contains",
            autoWidth: true,
            dataSource: new kendo.data.DataSource({ data: dropDownMinutes }),
            dataBound: (e) =>
            {
                e.sender.input.on("focus", () => { this.ChangeFocus(true); })
                e.sender.input.on("blur", () => { this.ChangeFocus(false); })
            },
            select: (e) =>
            {
                let selectedTime: { minute: number, display: string } = e.dataItem;

                if (this.startMin > selectedTime.minute)
                {
                    this.$timeout(() =>
                    {
                        this.showError.end = true;
                        this.$timeout(() =>
                        {
                            this.showError.end = false;
                        }, 5000);
                    });
                    e.preventDefault();
                    return;
                }

                this.endMin = selectedTime.minute;
                this.TriggerOnChange();
            }
        }
    }

    ToggleIsOpen()
    {
        this.isOpen = !this.isOpen
        this.TriggerOnChange();
    }

    TriggerOnChange()
    {
        if (this.onChange)
        {
            this.onChange({
                startMin: this.startMin,
                endMin: this.endMin,
                isOpen: this.isOpen
            });
        }
    }

    ChangeFocus(isFocused: boolean)
    {
        if (this.onFocusChange) {
            this.onFocusChange({ isFocused: isFocused });
        }
    }

    MinutesToDate(min: number): Date
    {
        return Helpers.MinutesToDate(min);
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("openTimeEditor", {
            bindings: {
                startMin: "<",
                endMin: "<",
                isOpen: "<",
                openType: "<",
                onChange: "&",
                onFocusChange: "&"
            },
            controller: OpenTimeEditorComponentController,
            templateUrl: "/Templates/Common/OpenTimeEditor.html"
        });
    }
}

