import { ApiConfig } from "AppConfig/ApiConfig";
import { DppResources } from "Interfaces/FranForce/Dpp/DppResources";

interface IDppImageSelectorDirectiveScope extends ng.IScope 
{
    //External Scope
    dppId: number;
    dppBuildingId: number;
    dppBuildingAssetId: number;
    dppBuildingAssets: DppResources.IDisasterPrepProfileBuildingAsset[];
    dppMaxImageWidth: number;
    dppMaxImageHeight: number;
    dppRefreshAssets: () => () => void;
    dppImageSelectorModel: any;
    dppOnAssetSelect: () => (selectedAsset: DppResources.IDisasterPrepProfileBuildingAsset, model: any) => void;

    //Internal Scope
    selectedDppBuildingAsset: DppResources.IDisasterPrepProfileBuildingAsset;
    asyncOptions: kendo.ui.UploadAsync;
    imageQueryString: string;
    AppSettings: IAppSettings;
    OpenDialog: () => void;
    SelectImage: (asset: DppResources.IDisasterPrepProfileBuildingAsset) => void;

    OnSelect: (e: kendo.ui.UploadSelectEvent) => void;
    OnUpload: (e: kendo.ui.UploadUploadEvent) => void;
    OnError: (e: kendo.ui.UploadErrorEvent) => void;
    OnSuccess: (e: kendo.ui.UploadSuccessEvent) => void;
}

export class ImageSelectorDirective implements ng.IDirective {

    compile: ng.IDirectiveCompileFn;
    controller?: string | ng.Injectable<ng.IControllerConstructor>;
    controllerAs?: string;
    bindToController?: boolean | { [boundProperty: string]: string };
    link?: ng.IDirectiveLinkFn | ng.IDirectivePrePost;
    multiElement?: boolean;
    priority?: number;
    replace?: boolean;
    require?: string | string[] | { [controller: string]: string };
    restrict?: string;
    scope?: boolean | { [boundProperty: string]: string };
    template?: string | ((tElement: JQuery, tAttrs: ng.IAttributes) => string);
    templateNamespace?: string;
    templateUrl?: string | ((tElement: JQuery, tAttrs: ng.IAttributes) => string);
    terminal?: boolean;
    transclude?: boolean | 'element' | { [slot: string]: string };

    static Init(dppApp: ng.IModule)
    {
        dppApp.directive('dppImageSelector', ['$window', "ngDialog", "$httpParamSerializer", "apiConfig",
            ($window: ng.IWindowService, ngDialog: any, $httpParamSerializer: ng.IHttpParamSerializer, apiConfig: ApiConfig): ng.IDirective =>
            {
                return new ImageSelectorDirective($window, ngDialog, $httpParamSerializer, apiConfig, false);
            }]);

        dppApp.directive('dppImageSelectorButton', ['$window', "ngDialog", "$httpParamSerializer", "apiConfig",
            ($window: ng.IWindowService, ngDialog: any, $httpParamSerializer: ng.IHttpParamSerializer, apiConfig: ApiConfig): ng.IDirective =>
            {
                return new ImageSelectorDirective($window, ngDialog, $httpParamSerializer, apiConfig, true);
            }]);
    }

    constructor($window: ng.IWindowService, ngDialog: any, $httpParamSerializer: ng.IHttpParamSerializer, apiConfig: ApiConfig, isButton: boolean)
    {
        this.scope = {
            dppId: '@',
            dppBuildingId: '@?',
            dppBuildingAssets: '=',
            dppBuildingAssetId: '=',
            dppMaxImageWidth: '<?',
            dppMaxImageHeight: '<?',
            dppRefreshAssets: '&',
            dppImageSelectorModel: '=',
            dppOnAssetSelect: '&'
        };
        this.restrict = "AE";
        this.replace = false;
        this.transclude = isButton;
        this.templateUrl = isButton ? '/Templates/Dpp/Directives/ImageSelectorButton.html' : '/Templates/Dpp/Directives/ImageSelector.html';
        this.link = (scope: IDppImageSelectorDirectiveScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, controller) =>
        {
            let onWatch = () => {
                if (scope.dppBuildingAssets && scope.dppBuildingAssetId) {
                    scope.selectedDppBuildingAsset = scope.dppBuildingAssets.filter(c => c.DppBuildingAssetId === scope.dppBuildingAssetId)[0];
                }
                else {
                    scope.selectedDppBuildingAsset = null;
                }
            }

            scope.AppSettings = apiConfig.AppSettings; 

            scope.asyncOptions = {
                saveUrl: `${apiConfig.AppSettings.CoreApiRootUrl}/DisasterPrepProfileBuildingAssets/Upload?dppId=${scope.dppId}`,
                autoUpload: true,
                withCredentials: false
            }
            if (scope.dppBuildingId) {
                scope.asyncOptions.saveUrl += `&dppBuildingId=${scope.dppBuildingId}`; 
            }

            if (scope.dppMaxImageWidth > 0 || scope.dppMaxImageHeight > 0)
            {
                let queryObj: {maxWidth?: number, maxHeight?: number} = {};
                if (scope.dppMaxImageWidth) {
                    queryObj.maxWidth = scope.dppMaxImageWidth;
                }
                if (scope.dppMaxImageHeight) {
                    queryObj.maxHeight = scope.dppMaxImageHeight;
                }
                scope.imageQueryString = "?" + $httpParamSerializer(queryObj);
            }

            scope.$watch("dppBuildingAssetId", onWatch);
            scope.$watch("dppBuildingAssets", onWatch);


            scope.SelectImage = (image: DppResources.IDisasterPrepProfileBuildingAsset) => {
                if (scope.dppOnAssetSelect) {
                    let assetSelectFunction = scope.dppOnAssetSelect();
                    if (assetSelectFunction && typeof assetSelectFunction === "function") {
                        assetSelectFunction(image, scope.dppImageSelectorModel);
                    };
                }
                scope.dppBuildingAssetId = image.DppBuildingAssetId;
                ngDialog.close();
            };
            scope.OnSelect = (e) => {
                console.log("OnSelect", e);
            };
            scope.OnUpload = (e) => {
                console.log("OnUpload", e);

                var xhr: XMLHttpRequest = e.XMLHttpRequest;
                if (xhr) {
                    xhr.addEventListener("readystatechange", (e: ProgressEvent) => {
                        if (xhr.readyState === XMLHttpRequest.OPENED) {
                            xhr.setRequestHeader("Authorization", (<ILocalStorage>localStorage).FranForceAuthTokenType + " " + (<ILocalStorage>localStorage).FranForceAuthToken);
                        }

                        if (xhr.readyState === XMLHttpRequest.DONE) {
                            if (xhr.status >= 400) {
                                alert("Error In Upload");
                            }
                            scope.dppRefreshAssets()();
                        }
                    });
                }
            };
            scope.OnError = (e) => {
                console.error("Failed Files Event", e);
            };
            scope.OnSuccess = (e) => {
                console.log("Files Created Event", e);
            };

            scope.OpenDialog = () => {
                ngDialog.open({
                    template: 'imageSelectPopup',
                    className: 'ngdialog-theme-default image-selection-dialog',
                    scope: scope,
                    controller: ['$scope', ($scope) => { }] 
                });
            };
        }
    }
}