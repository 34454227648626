import { CoreApiResources } from "Services/Resources/CoreApiResources";
import * as _ from "underscore"
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";

export class ConceptSelectorOldComponentController implements ng.IController {
    
    concepts: CoreResources.IConcept[];
    selectedConcept: CoreResources.IConcept;
    //conceptDropDownList: kendo.ui.DropDownList;
    conceptDropDownOptions: kendo.ui.DropDownListOptions;

    //---- Bindings ----
    conceptId: number;
    //------------------

    static $inject = ["coreApiResources"];

    constructor(
        private coreApiResources: CoreApiResources
    )
    {

    }

    $onInit()
    {
        let conceptPromise = this.coreApiResources.ConceptApi.query({ $select: "ConceptId,ConceptCode,DisplayName" })
            .$promise.then((concepts) => {
                this.concepts = concepts;

                if (this.conceptId)
                {
                    this.selectedConcept = _.find(this.concepts, c => c.ConceptId === this.conceptId);
                }

                this.conceptDropDownOptions = {
                    dataSource: this.concepts,
                    dataTextField: "DisplayName",
                    dataValueField: "ConceptId",
                    height: 450,
                    optionLabel: "Select Concept...",
                    select: (e) => {
                        this.conceptId = (<CoreResources.IConcept>e.dataItem).ConceptId;
                    }
                };
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("conceptSelectorOld", {
            bindings: {
                conceptId: "="
            },
            controller: ConceptSelectorOldComponentController,
            templateUrl: "/Templates/Common/ConceptSelectorOld.html",
        });
    }
}

