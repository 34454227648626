import { CallCenterApiResources } from "Services/Resources/CallCenterApiResources";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { CallCenterStateService } from "Services/State/CallCenterState";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";
import { CallCenterResources } from "Interfaces/FranForce/CallCenter/CallCenterResources";


export class FranchiseLeadStateComponentController implements ng.IController
{
    //---- Bindings ----
    franchiseId: number;
    //------------------

    conceptId: number;
    canRedThrottle: boolean;
    isLoading: boolean;
    throttle: Partial<CallCenterResources.IC4FranchiseLeadThrottle> & { StartDateObj?: Date, EndDateObj?: Date };
    conceptThrottle: Partial<CallCenterResources.IC4ConceptLeadThrottle>;
    
    static $inject = [
        "coreApiResources",
        "callCenterApiResources",
        "callCenterState",
        "identityManager",
        "apiConfig",
        "$timeout"
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private callCenterApiResources: CallCenterApiResources,
        private callCenterState: CallCenterStateService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig,
        private $timeout: ng.ITimeoutService
    )
    {

    }

    $onInit()
    {
        this.identityManager.HasRole([
            this.apiConfig.FranForceConstants.RoleConstants.AdminRole,
            this.apiConfig.FranForceConstants.RoleConstants.CallCenterAdmin,
            this.apiConfig.FranForceConstants.RoleConstants.SupportAdminRole,
            this.apiConfig.FranForceConstants.RoleConstants.ConceptManagerRole,
            this.apiConfig.FranForceConstants.RoleConstants.FranchiseConsultantRole,
        ])
            .then((canRedThrottle) =>
            {
                this.canRedThrottle = canRedThrottle;
            });
    }

    $onChanges(changes: ng.IOnChangesObject)
    {
        if (changes[nameof(this.franchiseId)] && this.franchiseId)
        {
            this.callCenterState.LoadLeadStateForFranchise(this.franchiseId)
                .then((throttle) =>
                {
                    this.throttle = angular.copy(throttle);
                });
        }
    }
    
    SaveLeadStateTimes()
    {
        if ((this.throttle.Status == "Y" || this.throttle.Status == "R") && !this.throttle.EndDateObj)
        {
            alert("Specify an end date for yellow or red status.");
            return;
        }

        if (!this.canRedThrottle && this.throttle.Status == "R")
        {
            alert("You aren't allowed to set a red status. Select green or yellow.");
            return;
        }

        if (this.throttle.Status == "G")
        {
            this.throttle.StartDate = new Date().toISOString();
            this.throttle.EndDate = null;
        }
        
        if ((this.throttle.Status == "Y" || this.throttle.Status == "R"))
        {
            this.throttle.StartDate = (this.throttle.StartDateObj || new Date()).toISOString();
            this.throttle.EndDate = this.throttle.EndDateObj.toISOString()
        }
        
        return this.callCenterApiResources.SetFranchiseLeadState(this.throttle)
            .then(() =>
            {
                return this.callCenterState.RefreshLeadStateForFranchise(this.franchiseId);
            });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("franchiseLeadState", {
            bindings: {
                franchiseId: "<"
            },
            controller: FranchiseLeadStateComponentController,
            templateUrl: "/Templates/FranchiseProfiles/FranchiseLeadState.html",
        });
    }
}

