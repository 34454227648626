import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { KendoUtil } from "Utility/KendoUtil";
import * as _ from "underscore"
import { RpmResources, RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { RoyaltyApiResources } from "Services/Resources/RoyaltyApiResources";

interface ReportGroup extends RpmResources.IRetriggerManualSalesGroup {
}

interface RetriggerReportData extends RpmResources.IRetriggerManualSalesRequest {
}

export class ManualSalesPeriodReportsController implements ng.IController {
    // -- Bindings --
    additionalFilters: kendo.data.DataSourceFilter;
    conceptId: number;
    franchiseId: number;
    franchisorId: number;
    showSnackbar: boolean;
    snackbarType: string;
    snackbarMessage: string;
    // --

    periodReportGrid: kendo.ui.Grid;
    gridQueryResponseHeaders: ODataQueryResponseHeaders;
    gridLoadingPromise: ng.IPromise<any>;
    isLoading: boolean;

    selectedPeriodReports: RpmResources.IRoyaltyPeriodReport[] & { Selected?: boolean }[];
    selectedPeriodReportIds: number[];
    selectedBatchIds: number[];
    selectedPeriodReportIdCommaSeperated: string;

    private readonly MAX_SELECTABLE_REPORTS = 5;

    static $inject = [
        '$q',
        '$timeout',
        'rpmApiResources',
        'rpmHelper',
        'odataHelper',
        '$scope',
        'royaltyApiResources',
        '$state',
        '$window'
    ];

    constructor(
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService,
        private rpmApiResources: RpmApiResources,
        private rpmHelper: RpmHelperService,
        private odataHelper: ODataHelperService,
        private $scope: ng.IScope,
        private royaltyApiResources: RoyaltyApiResources,
        private $state: ng.ui.IStateService,
        private $window: ng.IWindowService,
    ) {
    }

    $onInit() {
        // Initialize properties
        this.isLoading = false;
        this.selectedPeriodReports = [];
        this.selectedPeriodReportIds = [];
        this.selectedBatchIds = [];
        this.showSnackbar = false;
        this.snackbarType = '';
        this.snackbarMessage = '';

        // Use watchGroup to watch both conceptId and franchisorId
        // This prevents multiple grid reloads when both values change
        // this.$scope.$watchGroup(
        //     [
        //         () => this.conceptId,
        //         () => this.franchisorId
        //     ],
        //     (newValues, oldValues) => {
        //         // Only reload if both values are defined and at least one has changed
        //         const [newConceptId, newFranchisorId] = newValues;
        //         const [oldConceptId, oldFranchisorId] = oldValues;
                
        //         if (newConceptId !== undefined && newFranchisorId !== undefined && 
        //             (newConceptId !== oldConceptId || newFranchisorId !== oldFranchisorId) &&
        //             !this.isLoading) { // Prevent reload if already loading
        //             this.ClearSelections();
        //             this.LoadGrid();
        //         }
        //     }
        // );
    }

    $postLink() {
        this.InitPeriodGrid();
        if (this.additionalFilters) {
            this.LoadGrid();
        }
    }

    $onChanges(onChanges: ng.IOnChangesObject) {
        if (onChanges[nameof<ManualSalesPeriodReportsController>(o => o.additionalFilters)]) {
            this.ClearSelections();
            this.LoadGrid();
        }
    }

    InitPeriodGrid()
    {
        let periodReportColumns: kendo.ui.GridColumn[] = [{
                field: nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportId),
                title: "ID",
                filterable: true,
                template: `<button 
                        ng-show="dataItem.RoyaltyPeriodReportId"
                        ng-class="'pure-button grid-edit-button ' + ($ctrl.${nameof(this.IsSelectedPeriodReport)}(dataItem.${nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportId)}) ? 'rpm-selected-row-btn' : '')"
                        ng-click="$ctrl.${nameof(this.ToggleSelect)}(dataItem) ; $event.preventDefault()">
                        <i ng-class="'fa fa-' + ($ctrl.${nameof(this.IsSelectedPeriodReport)}(dataItem.${nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportId)}) ? 'check-circle-o' : 'circle-o')" aria-hidden="true"></i>
                        <span ng-bind="dataItem.${nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportId)}"></span>
                        </button>
                        <span ng-show="!dataItem.RoyaltyPeriodReportId" ng-bind="dataItem.RoyaltyPeriodReportId"></span>`
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.Franchise.Franchisor.DisplayName),
                title: "Franchisor",
                filterable: true
            }, {
                field: nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatchId),
                title: "Batch ID",
                filterable: true
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.Franchise.FranchiseeName),
                title: "Franchisee Name",
                width: 400,
                filterable: true
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.Franchise.LicenseNumber),
                title: "Account Code",
                filterable: true
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatch.RoyaltyPeriod.WeekNumber),
                title: "Week",
                filterable: true
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportStatus.Description),
                title: "Status Desc",
                filterable: true
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.ErrorDescription),
                title: "Error Desc",
                filterable: true,
                attributes: {
                    style: "color: \\#E32636"
                }
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatch.RoyaltyPeriod.PeriodEndDate),
                title: "Period End Date",
                filterable: {
                    ui: (element) => {
                        element.kendoDatePicker({
                            format: "MM/dd/yyyy",
                            parseFormats: ["MM/dd/yyyy"]
                        });
                    },
                    operators: {
                        date: {
                            eq: "Equal to",
                            gte: "After or equal to",
                            gt: "After",
                            lte: "Before or equal to",
                            lt: "Before"
                        }
                    }
                },
                template: (dataItem) => {
                    const date = dataItem.RoyaltyReportBatch?.RoyaltyPeriod?.PeriodEndDate;
                    return date ? kendo.toString(new Date(date), 'MM/dd/yyyy') : '';
                }
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.SourceTotalGrossSalesAmount),
                title: "Total Sales",
                filterable: true,
                template: `#= kendo.toString(${nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.SourceTotalGrossSalesAmount)}, 'c') #`
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.TotalFeeAmount),
                title: "Total Fee",
                filterable: true,
                template: `#= kendo.toString(${nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.TotalFeeAmount)}, 'c') #`
            }];

        let periodReportDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: "RoyaltyPeriodReportId",
                    fields: {
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportId)]: {
                            type: "number",
                            parse: (value: any) => parseInt(value, 10)
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.Franchise.Franchisor.DisplayName)]: {
                            type: "string"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.Franchise.LicenseNumber)]: {
                            type: "string"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.FranchiseId)]: {
                            type: "number"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatchId)]: {
                            type: "number",
                            parse: (value: any) => parseInt(value, 10)
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.SourceTotalGrossSalesAmount)]: {
                            type: "number"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.TotalFeeAmount)]: {
                            type: "number"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatch)]: {
                            type: "object"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatch.RoyaltyPeriod.PeriodEndDate)]: {
                            type: "date"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatch.RoyaltyPeriod.WeekNumber)]: {
                            type: "number",
                            parse: (value: any) => parseInt(value, 10)
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportStatus.Description)]: {
                            type: "string"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.ErrorDescription)]: {
                            type: "string"
                        }
                    }
                },
                total: (response) => {
                    return this.gridQueryResponseHeaders["odata-count"];
                }
            },
            pageSize: 10,
            serverPaging: true,
            serverSorting: true,
            serverFiltering: true,
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) =>
                {
                    this.isLoading = true;

                    if (!this.additionalFilters) {
                        options.success([]);
                        return;
                    }

                    let filters = this.additionalFilters ?
                        {
                            logic: "and",
                            filters: [
                                this.additionalFilters,
                                this.formatDateFilters(options.data.filter),
                                {
                                    field: nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportStatusID),
                                    operator: "eq",
                                    value: 4
                                }
                            ]
                        } :
                        {
                            logic: "and",
                            filters: [
                                this.formatDateFilters(options.data.filter),
                                {
                                    field: nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportStatusID),
                                    operator: "eq",
                                    value: 4
                                }
                            ]
                        };

                    let params: ODataQueryParameters = this.odataHelper.ConvertKendoDataSourceTransportReadOptionsDataToParameterObject(options.data);
                    params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersToString(filters);
                    params.$expand = this.odataHelper.ConvertKendoGridColumnsToExpansionString(
                        [
                            ...periodReportColumns,
                            { field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportStatus.Name)}
                        ]);
                    params.$count = true;
                    params.$select = [
                        nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatchId).replace("[0]", ""),
                        nameof<RpmResources.IRoyaltyPeriodReport>(o => o.SourceTotalGrossSalesAmount).replace("[0]", ""),
                        nameof<RpmResources.IRoyaltyPeriodReport>(o => o.TotalFeeAmount).replace("[0]", ""),
                        nameof<RpmResources.IRoyaltyPeriodReport>(o => o.FranchiseId).replace("[0]", ""),
                        nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportId).replace("[0]", ""),
                        nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportStatusID).replace("[0]", ""),
                        nameof<RpmResources.IRoyaltyPeriodReport>(o => o.ErrorDescription)
                    ].join(",");

                    this.rpmApiResources.RoyaltyPeriodReportApi.query(params, (data, headersCallback) => { this.gridQueryResponseHeaders = headersCallback(); }).$promise
                        .then((periodReports) =>  
                        {
                            options.success(periodReports);
                        })
                        .catch((err) => { options.error(err); })
                        .finally(() => { this.isLoading = false; });
                }
            }
        });

        let periodReportGridOptions: kendo.ui.GridOptions = {
            autoBind: false,
            columns: periodReportColumns,
            dataSource: periodReportDataSource,
            toolbar: [
                {
                    template: `<label style="margin: 0px 8px; width: 300px;">
                                    <span style="display: block">File Name</span>
                                    <input type="text" style="height: auto; width: 100%; box-sizing: border-box;" ng-model="$ctrl.${nameof(this.periodReportGrid)}.${nameof.full<kendo.ui.Grid>(o => o.options.excel.fileName)}" /> 
                                </label>`
                }, {
                    template: `<label style="margin: 0px 8px; text-align: center;">
                                        <span style="display: block">All Pages</span>
                                        <input type="checkbox" style="height: auto" ng-model="$ctrl.${nameof(this.periodReportGrid)}.${nameof.full<kendo.ui.Grid>(o => o.options.excel.allPages)}" /> 
                                    </label>`
                }, 
                "excel",
                {
                    template: `<button class='pure-button batchManagement-period-report-clearSections-btn' ng-click='$ctrl.${nameof(this.ClearSelections)}()'>Clear Selections</button>`
                }
            ],
            excel: {
                fileName: `PeriodReports-` +
                    `${new Date().getFullYear()}_` +
                    `${new Date().getMonth() + 1 < 10 ? "0" : ""}${new Date().getMonth() + 1}_` +
                    `${new Date().getDate() < 10 ? "0" : ""}${new Date().getDate()}.xlsx`,
                filterable: true,
                allPages: false
            },
            excelExport: (e) =>
            {
                //let date = new Date();
                //(<any>e.workbook).fileName = `PeriodReportsExport-${date.getUTCFullYear()}_${date.getUTCMonth()}_${date.getUTCDay()}.xlsx`;
            },
            selectable: "multiple, row",
            editable: false,
            scrollable: true,
            sortable: true,
            resizable: true,
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true
            },
        };

        this.periodReportGrid.setOptions(periodReportGridOptions);
    }

    LoadGrid() {
        if (!this.periodReportGrid)
            return this.$q.resolve();

        if (!this.gridLoadingPromise)
            this.gridLoadingPromise = this.$q.resolve();

        this.gridLoadingPromise = this.gridLoadingPromise.then(() => {
            this.isLoading = true;
            return this.$q.when(this.periodReportGrid.dataSource.read())
                .then(() => {
                    this.periodReportGrid.refresh();
                })
                .finally(() => { this.isLoading = false; });
        });
        return this.gridLoadingPromise;
    }

    ClearSelections() {
        this.selectedPeriodReports = [];
        this.selectedPeriodReportIds = [];
        this.selectedBatchIds = [];
        if (this.periodReportGrid) {
            this.periodReportGrid.clearSelection();
        }
    }

    ToggleSelect(selectedItem: RpmResources.IRoyaltyPeriodReport & { Selected?: boolean }) {
        const index = this.selectedPeriodReports.findIndex(o => o.RoyaltyPeriodReportId === selectedItem.RoyaltyPeriodReportId);
        if (index >= 0) {
            this.selectedPeriodReports.splice(index, 1);
        }
        else {
            // Proactively check if adding another selection would exceed the limit
            if (this.selectedPeriodReports.length >= this.MAX_SELECTABLE_REPORTS) {
                this.DisplaySnackbar('warning', `Cannot select more than ${this.MAX_SELECTABLE_REPORTS} reports at a time.`);
                // Prevent the selection in the grid
                if (this.periodReportGrid) {
                    // Get the Kendo data item which has the uid property
                    const dataItem = this.periodReportGrid.dataSource.get(selectedItem.RoyaltyPeriodReportId);
                    if (dataItem) {
                        const row = this.periodReportGrid.tbody.find('tr[data-uid="' + dataItem.uid + '"]');
                        if (row.length) {
                            row.removeClass('k-state-selected');
                        }
                    }
                }
                return;
            }
            this.selectedPeriodReports.push(selectedItem);
        }

        this.selectedPeriodReportIds = this.selectedPeriodReports
            .map(o => o.RoyaltyPeriodReportId);
        this.selectedPeriodReportIdCommaSeperated = this.selectedPeriodReportIds.join(",");
        this.selectedBatchIds = _.uniq(this.selectedPeriodReports
            .map(o => o.RoyaltyReportBatchId));
    }

    IsSelectedPeriodReport(royaltyPeriodReportId: number) {
        return this.selectedPeriodReports.some((b) => {
            return b.RoyaltyPeriodReportId === royaltyPeriodReportId;
        })
    }

    RetriggerSelectedReports() {
        if (!this.selectedPeriodReports || this.selectedPeriodReports.length === 0) {
            this.DisplaySnackbar('error', 'Please select at least one report to retrigger.');
            return;
        }

        // Group reports by batch ID and period end date
        const groupedReports = _.groupBy(this.selectedPeriodReports, report => 
            `${report.RoyaltyReportBatchId}_${report.RoyaltyReportBatch.RoyaltyPeriod.PeriodEndDate}`
        );

        const groups: ReportGroup[] = Object.values(groupedReports).map(reports => ({
            royaltyReportBatchId: reports[0].RoyaltyReportBatchId,
            periodEndDate: reports[0].RoyaltyReportBatch.RoyaltyPeriod.PeriodEndDate,
            royaltyPeriodReportIds: reports.map(r => r.RoyaltyPeriodReportId)
        }));

        if (groups.length === 0) {
            this.DisplaySnackbar('error', 'Failed to process selected reports. Please try again.');
            return;
        }

        const request: RetriggerReportData = {
            franchisorId: this.franchisorId,
            conceptId: this.conceptId,
            licenseNumber: this.selectedPeriodReports[0].Franchise?.LicenseNumber,
            groups: groups
        };

        this.isLoading = true;
        this.royaltyApiResources.RetriggerManualSalesEntries(request)
            .then(response => {
                if (response && response.results && response.results.length > 0) {
                    const successCount = response.results.filter(r => r.reportsCreated).length;
                    const failCount = response.results.length - successCount;
                    
                    // Get all batch IDs
                    const allBatchIds = response.results
                        .map(r => r.batchId)
                        .filter((id, index, self) => self.indexOf(id) === index);
                        
                    // Get successful batch IDs for status display
                    const successBatchIds = response.results
                        .filter(r => r.reportsCreated)
                        .map(r => r.batchId)
                        .filter((id, index, self) => self.indexOf(id) === index);
                    
                    // Convert all batch IDs to query string format for URL
                    const batchIdParams = allBatchIds.map(id => `batchId=${id}`).join('&');
                    
                    // Format batch IDs with status
                    const batchIdsWithStatus = allBatchIds.map(id => {
                        const wasSuccessful = successBatchIds.includes(id);
                        return wasSuccessful ? id.toString() : `${id} (no reports)`;
                    }).join(', ');

                    if (failCount === 0) {
                        // Show success message with batch information
                        const batchesText = allBatchIds.length === 1 ? 'batch' : 'batches';
                        this.DisplaySnackbar('success', 
                            `Successfully created ${allBatchIds.length} ${batchesText} (${batchIdsWithStatus}). Opening in a new tab...`);
                    } else if (successCount === 0) {
                        // Show error message with batch information
                        const batchesText = allBatchIds.length === 1 ? 'batch' : 'batches';
                        this.DisplaySnackbar('error', 
                            `Created ${allBatchIds.length} ${batchesText} but no reports were generated (${batchIdsWithStatus}). Opening in a new tab...`);
                    } else {
                        // Show partial success message with batch information
                        const batchesText = allBatchIds.length === 1 ? 'batch' : 'batches';
                        this.DisplaySnackbar('warning', 
                            `Partially successful: Created ${allBatchIds.length} ${batchesText} (${batchIdsWithStatus}). Opening in a new tab...`);
                    }

                    // Open batch management page in new tab with all batches
                    if (allBatchIds.length > 0) {
                        this.$timeout(() => {
                            const baseUrl = this.$window.location.origin;
                            const url = `${baseUrl}/BatchManagement#/bm/batchId/${batchIdParams}`;
                            this.$window.open(url, '_blank');
                        }, 3000); // Small delay to ensure snackbar is shown
                    }
                } else {
                    this.DisplaySnackbar('error', 'No response received from the server. Please try again.');
                }
            })
            .catch(error => {
                console.error('Error retriggering reports:', error);
                this.DisplaySnackbar('error', 
                    'An error occurred while retriggering reports. Please try again or contact support.');
            })
            .finally(() => {
                this.isLoading = false;
                this.ClearSelections(); // Clear grid selections
                this.LoadGrid(); // Refresh the grid to show updated data
            });
    }

    DisplaySnackbar(type: string, message: string) {
        this.showSnackbar = !this.showSnackbar;
        this.snackbarType = type;
        this.snackbarMessage = message;
        this.$scope.$evalAsync();
    }

    private formatDateFilters(filter: any): any {
        if (!filter) return filter;

        // If it's a single filter
        if (filter.field === nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatch.RoyaltyPeriod.PeriodEndDate) && filter.value) {
            // Parse the input date
            const localDate = new Date(filter.value);
            // Create UTC date at midnight
            filter.value = new Date(Date.UTC(
                localDate.getFullYear(),
                localDate.getMonth(),
                localDate.getDate(),
                0, 0, 0, 0
            ));
        }
        // If it's a composite filter
        else if (filter.filters) {
            filter.filters = filter.filters.map(f => this.formatDateFilters(f));
        }
        return filter;
    }

    static BindComponent(app: ng.IModule) {
        app.component('manualSalesPeriodReports', {
            bindings: {
                [nameof<ManualSalesPeriodReportsController>(o => o.additionalFilters)]: "<",
                [nameof<ManualSalesPeriodReportsController>(o => o.conceptId)]: "<",
                [nameof<ManualSalesPeriodReportsController>(o => o.franchiseId)]: "<",
                [nameof<ManualSalesPeriodReportsController>(o => o.franchisorId)]: "<"
            },
            controller: ManualSalesPeriodReportsController,
            templateUrl: "/Templates/RPM/manualSalesPeriodReports.html"
        });
    }
}