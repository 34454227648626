import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RatePlanItemDetailFeeXrefDataModel } from "../../Models/Royalty/RoyaltyContractRatePlanItemDetailFeeXref/RatePlanItemDetailFeeXrefDataModel";

export class RoyaltyContractRatePlanItemDetailsRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;

    GetRoyaltyContractRatePlanItemDetailsByContractPlanItem(contractRatePlanItemId: number)
    {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContractRatePlanItemDetails/InContractRatePlanItem/" + contractRatePlanItemId
        };

        return this.$http<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel[]>(config);
    }

    GetRoyaltyContractRatePlanItemDetailsByContractPlanItemDynamic(contractRatePlanItemId: number) {
        let config: ng.IRequestConfig = {
            method: "GET",
            url: this.endpointPrefix + "/RoyaltyContractRatePlanItemDetails/InContractRatePlanItem/" + contractRatePlanItemId + "/Dynamic"
        };

        return this.$http<RpmEntities.RoyaltyContractRatePlanItemDetailViewModel[]>(config);
    }

    CreateRoyaltyContractRatePlanItemDetail(model: RpmEntities.RoyaltyContractRatePlanItemDetailViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyContractRatePlanItemDetails",
            data: model
        };

        return this.$http<number>(config);
    }

    UpdateRoyaltyContractRatePlanItemDetail(id: number, model: RpmEntities.RoyaltyContractRatePlanItemDetailViewModel)
    {
        let config: ng.IRequestConfig = {
            method: "PUT",
            url: this.endpointPrefix + "/RoyaltyContractRatePlanItemDetails/" + id,
            data: model
        };

        return this.$http<number>(config);
    }
}