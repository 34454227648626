import { KendoUtil } from "Utility/KendoUtil";
import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { CoreEntities } from "Interfaces/FranForce/Core/CoreResources";

export class FranchiseProfilesGridComponentController implements ng.IController 
{
    grid: kendo.ui.Grid;
    gridQueryResponseHeaders: ODataQueryResponseHeaders;

    static $inject = [
        'coreApiResources',
        '$q',
        '$log',
        '$timeout',
        '$window',
        'odataHelper'
    ];

    constructor(
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private $timeout: ng.ITimeoutService,
        private $window: ng.IWindowService,
        private odataHelper: ODataHelperService
    )
    {
    }

    $onInit()
    {
    }

    $postLink()
    {
        this.InitGridOptions();
    }

    InitGridOptions()
    {
        let columns: kendo.ui.GridColumn[] = [
            {
                field: nameof.full<CoreEntities.FranchiseProfile>(o => o.Franchise.Concept.ConceptCode),
                title: "Concept"
            }, {
                field: nameof.full<CoreEntities.FranchiseProfile>(o => o.Franchise.LicenseNumber),
                title: "License Number",
                minScreenWidth: 1000
            }, {
                field: nameof.full<CoreEntities.FranchiseProfile>(o => o.Franchise.DoingBusinessAs),
                title: "DBA"
            }, {
                field: nameof.full<CoreEntities.FranchiseProfile>(o => o.Franchise.ContactFirstName),
                title: "First Name"
            }, {
                field: nameof.full<CoreEntities.FranchiseProfile>(o => o.Franchise.ContactLastName),
                title: "Last Name"
            }, {
                field: nameof.full<CoreEntities.FranchiseProfile>(o => o.Franchise.StateAbbr),
                title: "State",
                minScreenWidth: 1200
            }, {
                field: nameof.full<CoreEntities.FranchiseProfile>(o => o.Franchise.Country),
                title: "Country",
                minScreenWidth: 1300
            }, 
            //{
            //    title: "Profile Link",
            //   template: `<div style='text-align: center;'><a class='pure-button' href='/FranchiseProfiles/Edit/#= ${nameof.full<CoreEntities.FranchiseProfile>(o => o.FranchiseId)} #'>
            //                   Edit: #= ${nameof.full<CoreEntities.FranchiseProfile>(o => o.Franchise.LicenseNumber)} #
            //                </a></div>`,
            //    sortable: false
            //},  
            {
                title: "Website Id",
                width: 120,
                template: (dataItem: kendo.data.Model & CoreEntities.FranchiseProfile) => {
                     if(dataItem.Franchise.FranchiseWebLocationXRefs.length){
                        let returnVal= Array.from(new Set(dataItem.Franchise.FranchiseWebLocationXRefs.map(o=>o.FranchiseWebLocation.FranchiseWebLocationMarketingId).filter(FranchiseWebLocationMarketingId =>FranchiseWebLocationMarketingId))).join(', ');
                        return(
                            returnVal
                        )
                       
                    } 
                    return('')

                }
            },{
                 title: "WDI Link",
                 template: (dataItem: CoreEntities.FranchiseProfile) => 
                             `<div style='text-align: center;'><a class='pure-button' href='/FranchiseWebLocationProfiles/Edit?franchiseId=${dataItem.FranchiseId}'>
                                 Web Locations: 
                                 ${dataItem.Franchise.LicenseNumber}
                             </a></div>`,
                 sortable: false
             }
        ];

        let dataSource = new kendo.data.DataSource({
            schema: {
                total: (data) =>
                {
                    return this.gridQueryResponseHeaders["odata-count"];
                }
            },
            serverSorting: true,
            serverFiltering: true,
            serverPaging: true,
            pageSize: 25,

            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) =>
                {

                        options.data.filter = {
                            logic: "and",
                            filters: [
                                <kendo.data.DataSourceFilterItem>{
                                    field: nameof.full<CoreEntities.FranchiseProfile>(o => o.Franchise.Status),
                                    operator: "ne",
                                    value: "Inactive"
                                },
                                options.data.filter
                            ]
                        }; 
    

                    let params = this.odataHelper.ConvertKendoDataSourceTransportReadOptionsDataToParameterObject(options.data);

               /*      params.$expand = this.odataHelper.ConvertKendoGridColumnsToExpansionString([...columns, {field: "Franchise.FranchiseWebLocationXrefs.FranchiseWebLocation.FranchiseWebLocationMarketingId"}]);
       */
                    params.$expand = nameof<CoreEntities.FranchiseProfile>(o => o.Franchise) +
                    `($select=LicenseNumber,DoingBusinessAs,ContactFirstName,ContactLastName,StateAbbr,Country;` +
                    `$expand=Concept($select=ConceptCode),` +
                    `FranchiseWebLocationXrefs($expand=FranchiseWebLocation($select=FranchiseWebLocationMarketingId);` + 
                    `$filter=FranchiseWebLocation/status ne 'Inactive'))`;

                    params.$count = true;

                    this.coreApiResources.FranchiseProfileApi.query(params, (data, responseHeaders) => { this.gridQueryResponseHeaders = responseHeaders(); }).$promise
                        .then((franchiseProfiles) =>
                        {
                            options.success(franchiseProfiles);
                        })
                        .catch((err) =>
                        {
                            options.error(err);
                        })
                }
            }
        });

        let gridHeight = angular.element(this.$window).height() - this.grid.element.offset().top - 15;

        if (gridHeight < 250) {
            gridHeight = 250;
        }

        this.grid.setOptions(<kendo.ui.GridOptions>{
            dataSource: dataSource,
            columns: columns,
            sortable: true,
            resizable: true,
            pageable: {
                numeric: false,
                pageSize: 25,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true
            },
            height: gridHeight
        });
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('franchiseProfilesGrid', {
            bindings: {

            },
            controller: FranchiseProfilesGridComponentController,
            template: `<div kendo-grid="$ctrl.${nameof<FranchiseProfilesGridComponentController>(o => o.grid)}" id="mainGrid"></div>`
        });

    }
}


