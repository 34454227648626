import { UrlQueryParameters } from "Utility/UrlQueryParameters";
import { IdentityManager } from "Services/Resources/IdentityManager";
import { ApiConfig } from "AppConfig/ApiConfig";

export class ReferANeighborActivityReport implements ng.IController
{
    conceptId: number;
    franchiseId: number;
    startDateTime: Date;
    endDateTime: Date;

    static $inject = [
        "$window",
        "identityManager",
        "apiConfig"
    ];

    constructor(
        private $window: ng.IWindowService,
        private identityManager: IdentityManager,
        private apiConfig: ApiConfig
    )
    {
    }

    $onInit()
    {
        let params = new UrlQueryParameters(this.$window.window); 
        this.conceptId = parseInt(params.GetValue("ConceptID"));
        this.franchiseId = parseInt(params.GetValue("FranchiseId"));
        this.startDateTime =  new Date(params.GetValue("StartDate"));
        this.endDateTime = new Date(params.GetValue("EndDate"));

        if (!this.franchiseId)
        {
            this.SelectFranchiseIfSingleOwnership();
        }
    }

    async SelectFranchiseIfSingleOwnership()
    {
        let userInfo = (await this.identityManager.GetLoggedInUserInfo()).data;
        let franchiseIds = userInfo.Claims
            .filter(c => c.Type == this.apiConfig.FranForceConstants.ClaimConstants.OwnedFranchiseIdClaimType)
            .map(c => parseInt(c.Value));

        if (franchiseIds.length === 1)
            this.franchiseId = franchiseIds[0];
    }

    OnFranchiseChange(franchise)
    {
        this.franchiseId = franchise.FranchiseId;
        this.conceptId = franchise.ConceptId;
    }

    GetReportUrl()
    {
        return `/Reports/ReferANeighborActivityReport/?ConceptID=${this.conceptId}&FranchiseId=${this.franchiseId}&StartDate=${kendo.toString(this.startDateTime, "d", "en-US")}&EndDate=${kendo.toString(this.endDateTime, "d", "en-US")}`
    }

    static BindComponent(app: ng.IModule)
    {
        app.component("referANeighborActivityReport", {
            controller: ReferANeighborActivityReport,
            transclude: {
                'report': 'report'
            },
            templateUrl: "/Templates/Reports/ReferANeighborActivityReport.html"
        });
    }
}

