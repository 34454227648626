import { CoreApiResources } from "Services/Resources/CoreApiResources";
import { RpmUiApiResources } from "Services/Resources/RpmUiApiResources";
import { RpmApiResources } from "Services/Resources/RpmApiResources";
import { ODataHelperService } from "Services/Utility/ODataHelperService";
import { RpmHelperService } from "Services/Utility/RpmHelperService";
import { KendoUtil } from "Utility/KendoUtil";
import * as _ from "underscore"
import { RpmResources, RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { CoreResources } from "Interfaces/FranForce/Core/CoreResources";
import { RoyaltyApiResources } from "../../../Services/Resources/RoyaltyApiResources";
import moment = require("moment");
import { royaltyInvoiceReversalFileDataModel } from "../../../Models/Royalty/RoyaltyBatchManagement/RoyaltyInvoiceReversalFileDataModel";

interface RoyaltyReportBackedOutParams {
    status: RpmEntities.usp_RoyaltyPeriodReportStatus_GetBySetViaBatchManagement_Result,
    reason: string;
    selectedPeriodReports: RpmResources.IRoyaltyPeriodReport[] & { Selected?: boolean }[];
}

export class BatchManagementPeriodReportsComponentController implements ng.IController 
{
    // -- Bindings --
    additionalFilters: kendo.data.DataSourceFilter;
    onSelectedPeriodReports: (params: { periodReportIds: number[] }) => void;
    selectedBatches: RpmResources.IRoyaltyReportBatch[];
    // --

    franchisors: CoreResources.IFranchisor[];
    reportFranchises: CoreResources.IFranchise[];
    periodReports: RpmResources.IRoyaltyPeriodReport[] & { Selected?: boolean }[];
    selectedPeriodReports: RpmResources.IRoyaltyPeriodReport[] & { Selected?: boolean }[];
    selectedPeriodReportIds: number[];
    selectedBatchIds: number[];
    selectedPeriodReportIdCommaSeperated: string;

    periodReportGrid: kendo.ui.Grid;
    gridQueryResponseHeaders: ODataQueryResponseHeaders;
    gridLoadingPromise: ng.IPromise<any>;

    sendEmailWindow: kendo.ui.Window;
    emailDraftDate: Date;

    isLoading: boolean;

    isSelectedPeriodReportStatusDifferent: boolean;
    selectedStatusForBackout?: number;

    //invoice reversal 
    invoiceReversalWindow: kendo.ui.Window;
    invoicesReversalDataSource: kendo.data.DataSource;

    backoutPeriodReportErrorDesc: string;


    static $inject = [
        '$rootScope',
        'coreApiResources',
        '$q',
        '$timeout',
        'rpmApiResources',
        'rpmUiApiResources',
        'rpmHelper',
        'odataHelper',
        'ngDialog',
        '$sce',
        "royaltyApiResources",
        '$scope',
    ];

    constructor(
        private $rootScope: ng.IRootScopeService,
        private coreApiResources: CoreApiResources,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService,
        private rpmApiResources: RpmApiResources,
        private rpmUiApiResources: RpmUiApiResources,
        private rpmHelper: RpmHelperService,
        private odataHelper: ODataHelperService,
        private ngDialog: ng.dialog.IDialogService,
        private $sce: ng.ISCEService,
        private royaltyApiResources: RoyaltyApiResources,
        private $scope: ng.IScope,
    )
    {
    }

    $onInit()
    {
        this.selectedPeriodReports = [];
        this.$scope.$on('refreshBatchManagementData', (event, args) => {
            if (this.periodReportGrid && this.periodReportGrid.dataSource) {
                if (this.periodReportGrid.dataSource.total() > 0) {
                    this.periodReportGrid.dataSource.read();
                    this.periodReportGrid.refresh();
                    this.ClearSelections();
                }
            }
        });
    }

    $postLink()
    {
        this.InitPeriodGrid();
        if (this.additionalFilters) {
            this.LoadGrid();
        }
    }

    $onChanges(onChanges: ng.IOnChangesObject)
    {
        if (onChanges[nameof<BatchManagementPeriodReportsComponentController>(o => o.additionalFilters)]) {
            this.LoadGrid();
        }
    }

    InitPeriodGrid()
    {
        let periodReportColumns: kendo.ui.GridColumn[] = [{
                field: nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportId),
                title: "ID",
                template: `<button 
                        ng-show="dataItem.RoyaltyPeriodReportId"
                        ng-class="'pure-button grid-edit-button ' + ($ctrl.${nameof(this.IsSelectedPeriodReport)}(dataItem.${nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportId)}) ? 'rpm-selected-row-btn' : '')"
                        ng-click="$ctrl.ToggleSelect(dataItem) ; $event.preventDefault()">
                            <i ng-class="'fa fa-' + (dataItem.Selected  ? 'check-circle-o' : 'circle-o')" aria-hidden="true"></i>
                            <span ng-bind="dataItem.RoyaltyPeriodReportId"></span>
                        </button>
                        <span ng-show="!dataItem.RoyaltyPeriodReportId" ng-bind="dataItem.RoyaltyPeriodReportId"></span>`
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.Franchise.Franchisor.DisplayName),
                title: "Franchisor"
            }, {
                field: nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatchId),
                title: "Batch ID"
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.Franchise.FranchiseeName),
                title: "Franchisee Name",
                width: 400
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.Franchise.LicenseNumber),
                title: "Account Code"
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatch.RoyaltyPeriod.WeekNumber),
                title: "Week"
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportStatus.Description),
                title: "Status Desc"
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.ErrorDescription),
                title: "Error Desc",
                attributes: {
                    style: "color: \\#E32636"
                }
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatch.RoyaltyPeriod.PeriodEndDate),
                title: "Period End Date",
                template: `#= kendo.toString(kendo.parseDate(${nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatch.RoyaltyPeriod.PeriodEndDate)}), 'd') #`
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.SourceTotalGrossSalesAmount),
                title: "Total Gross Sales",
                template: `#= kendo.toString(${nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.SourceTotalGrossSalesAmount)}, 'c') #`
            }, {
                field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.TotalFeeAmount),
                title: "Total Fees Due",
                template: `#= kendo.toString(${nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.TotalFeeAmount)}, 'c') #`
            }, {
                title: "View Report",
                template: `<a href='\\#' ng-click='$ctrl.${nameof(this.OpenTab)}(#= ${nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportId)} #) ; $event.preventDefault()'>View Report</a>`
            }, {
                title: "View Email",
                template: `<a href='\\#' ng-click='$ctrl.${nameof(this.ViewEmail)}(#= ${nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportId)} #)'>View Email</a>`
        }];

        let periodReportDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: "RoyaltyPeriodReportId",
                    fields: {
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportId)] : {
                            type: "number"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.Franchise.Franchisor.DisplayName)]: {
                            type: "string"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.Franchise.FranchiseeName)]: {
                            type: "string"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.Franchise.LicenseNumber)]: {
                            type: "string"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.FranchiseId)]: {
                            type: "number"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatchId)]: {
                            type: "number"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.SourceTotalGrossSalesAmount)]: {
                            type: "number"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.TotalFeeAmount)]: {
                            type: "number"
                        },
                        [nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatch)]: {
                            type: "object"
                        }
                    }
                },
                total: (response) =>
                {
                    return this.gridQueryResponseHeaders ? this.gridQueryResponseHeaders["odata-count"] : 0;
                }
            },
            pageSize: 10,
            serverPaging: true,
            serverSorting: true,
            serverFiltering: true,
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) =>
                {
                    this.isLoading = true;

                    if (!this.additionalFilters) {
                        options.success([]);
                        return;
                    }

                    let filters = this.additionalFilters ?
                        {
                            logic: "and",
                            filters: [this.additionalFilters, options.data.filter]
                        } :
                        options.data.filter;

                    let params: ODataQueryParameters = this.odataHelper.ConvertKendoDataSourceTransportReadOptionsDataToParameterObject(options.data);
                    params.$filter = this.odataHelper.ConvertKendoDataSourceFiltersToString(filters);
                    params.$expand = this.odataHelper.ConvertKendoGridColumnsToExpansionString(
                        [
                            ...periodReportColumns,
                            { field: nameof.full<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportStatus.Name)}
                        ]);
                    params.$count = true;
                    params.$select = [
                        nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyReportBatchId),
                        nameof<RpmResources.IRoyaltyPeriodReport>(o => o.SourceTotalGrossSalesAmount),
                        nameof<RpmResources.IRoyaltyPeriodReport>(o => o.TotalFeeAmount),
                        nameof<RpmResources.IRoyaltyPeriodReport>(o => o.FranchiseId),
                        nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportId),
                        nameof<RpmResources.IRoyaltyPeriodReport>(o => o.RoyaltyPeriodReportStatusID),
                        nameof<RpmResources.IRoyaltyPeriodReport>(o => o.ErrorDescription)
                    ].join(",");

                    this.rpmApiResources.RoyaltyPeriodReportApi.query(params, (data, headersCallback) => { this.gridQueryResponseHeaders = headersCallback(); }).$promise
                        .then((periodReports) =>  
                        {
                            options.success(periodReports);
                        })
                        .catch((err) => { options.error(err); })
                        .finally(() => { this.isLoading = false; });
                }
            }
        });

        let periodReportGridOptions: kendo.ui.GridOptions = {
            autoBind: false,
            columns: periodReportColumns,
            dataSource: periodReportDataSource,
            toolbar: [
                {
                    template: `<label style="margin: 0px 8px; width: 300px;">
                                    <span style="display: block">File Name</span>
                                    <input type="text" style="height: auto; width: 100%; box-sizing: border-box;" ng-model="$ctrl.${nameof(this.periodReportGrid)}.${nameof.full<kendo.ui.Grid>(o => o.options.excel.fileName)}" /> 
                                </label>`
                }, {
                    template: `<label style="margin: 0px 8px; text-align: center;">
                                        <span style="display: block">All Pages</span>
                                        <input type="checkbox" style="height: auto" ng-model="$ctrl.${nameof(this.periodReportGrid)}.${nameof.full<kendo.ui.Grid>(o => o.options.excel.allPages)}" /> 
                                    </label>`
                }, 
                "excel",
                {
                    template: `<button class='pure-button batchManagement-period-report-clearSections-btn' ng-click='$ctrl.${nameof(this.ClearSelections)}()'>Clear Selections</button>`
                }
            ],
            excel: {
                fileName: `PeriodReports-` +
                    `${new Date().getFullYear()}_` +
                    `${new Date().getMonth() + 1 < 10 ? "0" : ""}${new Date().getMonth() + 1}_` +
                    `${new Date().getDate() < 10 ? "0" : ""}${new Date().getDate()}.xlsx`,
                filterable: true,
                allPages: false
            },
            excelExport: (e) =>
            {
                //let date = new Date();
                //(<any>e.workbook).fileName = `PeriodReportsExport-${date.getUTCFullYear()}_${date.getUTCMonth()}_${date.getUTCDay()}.xlsx`;
            },
            selectable: "multiple, row",
            editable: false,
            scrollable: true,
            sortable: true,
            resizable: true,
            pageable: {
                numeric: false,
                pageSize: 10,
                pageSizes: [10, 25, 50, 100, 250, 500, "all"],
                input: true
            },
            filterable: {
                mode: "menu",
                operators: KendoUtil.GetDefaultKendoGridFilterOperators(),
                extra: true
            },
        };

        this.periodReportGrid.setOptions(periodReportGridOptions);
    }

    ViewEmail(rprID: number)
    {
        this.rpmUiApiResources.GetRoyaltyPeriodReportEmail(rprID).then(
            (response) =>
            {
                let emailScope = <ng.IScope & { emailModel: RpmEntities.EmailServiceViewModel }>this.$rootScope.$new(true);
                emailScope.emailModel = response.data;
                emailScope.emailModel.ToAddress = (emailScope.emailModel.ToAddress || "").replace(";", " ");
                emailScope.emailModel.CCAddress = (emailScope.emailModel.CCAddress || "").replace(";", " ");
                emailScope.emailModel.BCCAddress = (emailScope.emailModel.BCCAddress || "").replace(";", " ");
                emailScope.emailModel.Body = this.$sce.trustAsHtml(emailScope.emailModel.Body);
                emailScope.emailModel.DateToSend = kendo.toString(kendo.parseDate(response.data.DateToSend), 'd');
                emailScope.emailModel.DateSent = kendo.toString(kendo.parseDate(response.data.DateSent), 'd');
                emailScope.emailModel.LastSendAttemptDate = kendo.toString(kendo.parseDate(response.data.LastSendAttemptDate), 'd');

                let dialog = this.ngDialog.open({
                    template: '/Templates/BatchManagement/bmEmailModal.html',
                    className: 'ngdialog-theme-default ngdialog-email-dialog',
                    scope: <any>emailScope,
                    controller: ['$scope', ($scope: ng.IScope & { emailModel: RpmEntities.EmailServiceViewModel }) =>
                    {
                    }],
                    showClose: false
                });
            });
    }

    OpenTab(id: number)
    {
        let win = window.open(`/PeriodReport/Edit#?royaltyPeriodReportId=${id}`);
        win.focus();
    }

    GetFranchisorById(id: number)
    {
        return _.find(this.franchisors, (f) => { return f.FranchisorId == id });
    }

    GetReportFranchiseById(id: number)
    {
        return _.find(this.reportFranchises, (f) => { return f.FranchiseId == id });
    }

    ExcelExport(grid: kendo.ui.Grid, allPages: boolean)
    {
        grid.options.excel.allPages = allPages;
        grid.saveAsExcel();
    }

    LoadGrid()
    {
        if (!this.periodReportGrid)
            return this.$q.resolve();

        if (!this.gridLoadingPromise)
            this.gridLoadingPromise = this.$q.resolve();

        this.gridLoadingPromise = this.gridLoadingPromise.then(() =>
        {
            this.isLoading = true;
            return this.$q.when(this.periodReportGrid.dataSource.read())
                .then(() =>
                {
                    this.periodReportGrid.refresh();
                })
                .finally(() => { this.isLoading = false; });
        });
        return this.gridLoadingPromise;
    }

    areAllElementsOfArrayEqual(arr) {
        return new Set(arr).size == 1;
    }

    backoutParams: RoyaltyReportBackedOutParams

    onReportsBackedOut(backout: RoyaltyReportBackedOutParams){
        this.backoutParams = backout;
    }

    ToggleSelect(selectedItem: RpmResources.IRoyaltyPeriodReport & { Selected?: boolean })
    {
        //selectedItem.Selected = !selectedItem.Selected;
        //this.selectedPeriodReports = _.filter<RpmResources.IRoyaltyPeriodReport & { Selected?: boolean }>(<any>this.periodReportGrid.dataSource.data(), o => o.Selected);

        let foundPeriodReport = _.find(this.selectedPeriodReports , (b) => { return b.RoyaltyPeriodReportId == selectedItem.RoyaltyPeriodReportId; });
        if (foundPeriodReport) {
            this.selectedPeriodReports = _.without(this.selectedPeriodReports, foundPeriodReport);
        }
        else {
            this.selectedPeriodReports.push(selectedItem);
        }

        // remove period reports if batch is unchecked at batch level
        if (this.selectedBatches && this.selectedBatches.length >= 0) {
            let batchIdsArr = this.selectedBatches.map(x => {
                return x.RoyaltyReportBatchId
            });

            this.selectedPeriodReports = this.selectedPeriodReports.filter(x => {
                let selectedPeriodReportBatchId = Number(x.RoyaltyReportBatchId);
                return batchIdsArr.includes(selectedPeriodReportBatchId)
            })
        }


        // check if all period reports selected are of same status
        let isSelectedPeriodReportStatusSame = false;
        let selectedStatusIdArr = this.selectedPeriodReports.map(x => {
            return x.RoyaltyPeriodReportStatusID
        })
        if (selectedStatusIdArr && selectedStatusIdArr.length > 0) {
            isSelectedPeriodReportStatusSame = this.areAllElementsOfArrayEqual(selectedStatusIdArr);
        }
        this.isSelectedPeriodReportStatusDifferent = isSelectedPeriodReportStatusSame == true ? false : true;

        if (!this.isSelectedPeriodReportStatusDifferent && selectedStatusIdArr && selectedStatusIdArr.length > 0) {
            this.selectedStatusForBackout = selectedStatusIdArr[0];
        }

        this.selectedPeriodReportIds = this.selectedPeriodReports
            .map(o => o.RoyaltyPeriodReportId);
        this.selectedPeriodReportIdCommaSeperated = this.selectedPeriodReportIds.join(",");
        this.selectedBatchIds =  _.uniq(this.selectedPeriodReports
            .map(o => o.RoyaltyReportBatchId));

        if (this.onSelectedPeriodReports) {
            this.onSelectedPeriodReports({
                periodReportIds: this.selectedPeriodReportIds
            });
        }
    }

    ClearSelections()
    {
        if (!this.selectedPeriodReports)
            return;

        if (this.selectedPeriodReports) {
            this.selectedPeriodReports.forEach(r => (<{ Selected?: boolean }>r).Selected = false);
            this.selectedPeriodReports.splice(0, this.selectedPeriodReports.length);
        }

        if (this.selectedPeriodReportIds) {
            this.selectedPeriodReportIds.splice(0, this.selectedPeriodReportIds.length);
        }

        if (this.selectedBatchIds) {
            this.selectedBatchIds.splice(0, this.selectedBatchIds.length);
        }

        if (this.onSelectedPeriodReports) {
            this.onSelectedPeriodReports({
                periodReportIds: this.selectedPeriodReportIds
            });
        }
    }

    OpenInvoiceReversalModal() {
        this.isLoading = true;
        this.invoicesReversalDataSource = new kendo.data.DataSource({
            pageSize: 10,
            transport: {
                read: (options: kendo.data.DataSourceTransportReadOptions) => {
                    this.isLoading = true;
                    this.royaltyApiResources.GetRoyaltyReportPeriodReportReverseInvoices(this.selectedPeriodReportIds)
                        .then((results) => {
                            for (let invoice of results.data) {
                                invoice.date = moment(new Date(invoice.date)).format("M/D/Y");
                            }
                            options.success(results.data);
                        })
                        .catch((err) => {
                            options.error(err);
                        })
                        .finally(() => { this.isLoading = false; });
                }
            },
            sort: {
                field: nameof<royaltyInvoiceReversalFileDataModel>(o => o.extAmount),
                dir: "asc"
            }
        });

        this.invoiceReversalWindow.open();
        this.$timeout(() => {
            this.invoiceReversalWindow.center();
        })
    }

    OnInvoiceVoided() {
        this.invoiceReversalWindow.close();

        const {selectedPeriodReports, reason, status} = this.backoutParams

        const promises: ng.IPromise<any>[] = selectedPeriodReports.map(periodReport => {
            return this.rpmUiApiResources.BackoutRoyaltyPeriodReports({
                RoyaltyPeriodReportId: periodReport.RoyaltyPeriodReportId,
                RoyaltyPeriodReportStatusID: status.RoyaltyPeriodReportStatusID,
                ErrorDescription: reason,
                RetryCount: null,
                UserName: null
            });
        });
        
        this.$q.all(promises);

        return this.LoadGrid();
    }

    SetPeriodReportBackoutErrorDesc(errorDesc: string) {
        this.backoutPeriodReportErrorDesc = errorDesc;
    }

    IsSelectedPeriodReport(royaltyPeriodReportId: number) {
        return this.selectedPeriodReports.some((b) => {
            return b.RoyaltyPeriodReportId === royaltyPeriodReportId;
        })
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('bmPeriodReports', {
            bindings: {
                additionalFilters: "<",
                onSelectedPeriodReports: "&?",
                selectedBatches: "<"
            },
            controller: BatchManagementPeriodReportsComponentController,
            templateUrl: "/Templates/BatchManagement/bmPeriodReports.html" 
        });
    }
}
