import { Constants } from 'Constants';
export class AccountResourceLinksController implements ng.IController
{
    token: string;
    email: string;
    localStorage: ILocalStorage;
    
    static $inject = [
        "$rootScope"
    ];

    constructor(
        private $rootScope: ng.IRootScopeService
    )
    {

    }

    $onInit()
    {
        this.localStorage = <ILocalStorage>localStorage;
        this.token = this.localStorage.FranForceAuthToken;
        this.email = this.localStorage.AccountEmail;
    }

    ChangePasswordClick(){
        this.$rootScope.$emit(Constants.PopupEvents.OpenPasswordPopupEvent, {});
    }

    static BindComponent(app: ng.IModule)
    {
        app.component('accountResourceLinks', {
            bindings: {

            },
            controller: AccountResourceLinksController,
            templateUrl: "/Templates/Account/AccountResourceLinks.html"
        });
    }
}
