import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { RestAxiosClient } from "Clients/RestAxiosClient";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { IRestAxiosQueryClient, IRestAxiosGetClient } from "Clients/IRestAxiosClient";
import { AxiosRequestConfig } from "axios";

export class RmsFranchiseEntityRelationshipApplicationClient implements
    IRestAxiosQueryClient<RpmEntities.FranchiseEntityRelationshipApplication>,
    IRestAxiosGetClient<RpmEntities.FranchiseEntityRelationshipApplication, number>
{
    private client: RestAxiosClient<RpmEntities.FranchiseEntityRelationshipApplication, number>;

    constructor()
    {
        this.client = new RestAxiosClient(
            FranForceAxiosClientBuilder.BuildCoreBaseClient(),
            "/RmsFranchiseEntityRelationshipApplications"
        );
    }

    async Query(parameters: ODataQueryParameters, config?: AxiosRequestConfig): Promise<RpmEntities.FranchiseEntityRelationshipApplication[]>
    {
        return this.client.Query(parameters, config);
    }

    async Get(id: number, parameters: ODataQueryParameters, config?: AxiosRequestConfig): Promise<RpmEntities.FranchiseEntityRelationshipApplication>
    {
        return this.client.Get(id, parameters, config);
    }
}

