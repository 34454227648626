import { FranForceAxiosClientBuilder } from "Clients/FranForceAxiosClientBuilder";
import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";
import { RestAxiosClient } from "Clients/RestAxiosClient";
import { IRestAxiosClient } from "Clients/IRestAxiosClient";

const rmsFranchiseEntityRelationshipDetailClient = new RestAxiosClient<RpmEntities.FranchiseEntityRelationshipDetail, number>(
    FranForceAxiosClientBuilder.BuildCoreBaseClient(),
    "/RmsFranchiseEntityRelationshipDetails"
) as IRestAxiosClient<RpmEntities.FranchiseEntityRelationshipDetail, number>;

export { rmsFranchiseEntityRelationshipDetailClient };