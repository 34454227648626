import { RpmEntities } from "Interfaces/FranForce/Rpm/RpmResources";

export class RoyaltyInvoiceExportBatchesRpmUiPartialService implements IRpmUiPartialService
{
    endpointPrefix: string;
    $http: angular.IHttpService;
    

    SetRoyaltyInvoiceExportBatchPosted(id: number)
    {
        let config: ng.IRequestConfig = {
            method: "POST",
            url: this.endpointPrefix + "/RoyaltyInvoiceExportBatches/" + id + "/SetPosted"
        };

        return this.$http<void>(config);
    }
}
    
