import { BillingApiResources } from "../../../Services/Resources/BillingApiResources";
import { CoreResources } from "../../../Interfaces/FranForce/Core/CoreResources";
import { AngularUtil } from "../../../Utility/AngularUtil";
import { BillingInvoiceTaxRequestRmDataModel } from "../../../Models/Billing/ZorWare/BillingInvoices/BillingInvoiceTaxRequestRmDataModel";
import { MonthSelectorOnSelectParams, YearSelectorOnSelectParams } from "../../Common";
import { GenerateCopyOfInvoiceEmailRequestDataModel } from "../../../Models/Billing/ZorWare/BillingInvoices/GenerateCopyOfInvoiceEmailRequestDataModel";
import { BillingInvoiceTaxTrackerResponseDataModel } from "../../../Models/Billing/ZorWare/BillingInvoices/BillingInvoiceTaxTrackerResponseDataModel";
import { AxiosInstance } from "axios";
import { FranForceAxiosClientBuilder } from "../../../Clients/FranForceAxiosClientBuilder";
import {
    BillingServiceClient,
    BillingGreatPlainsCodeClient,
    BillingGreatPlainsCodesVm,
    CreateBillingServiceRm
} from '@nbly/billing-orchestrations-clients';

export class AddBillingServicePopupComponentController implements ng.IController {

    isLoading: boolean;
    addBillingServiceDialog: kendo.ui.Dialog;

    greatPlainsCodesDropdown: kendo.ui.DropDownList;

    billingOrchestrationsClient: AxiosInstance;
    billingServicesClient: BillingServiceClient
    billingGreatPlainsCodeClient: BillingGreatPlainsCodeClient

    showSnackbar: boolean;
    snackbarType: string;
    snackbarMessage: string;

    greatPlainsCodeId: number;
    serviceCode: string;
    servicePrice: number;
    serviceDescription: string;
    internalDescription: string;

    static $inject = [
        "$timeout",
        "$rootScope",
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private $rootScope: ng.IRootScopeService,
    ) {
        this.billingOrchestrationsClient = FranForceAxiosClientBuilder.BuildBillingOrchestrationsBaseClient();
        this.billingServicesClient = new BillingServiceClient("", this.billingOrchestrationsClient);
        this.billingGreatPlainsCodeClient = new BillingGreatPlainsCodeClient("", this.billingOrchestrationsClient);
    }

    $onInit() {
        this.$rootScope.$on('addBillingService', async (event, args) => {
            this.addBillingServiceDialog.open();
        });
    }

    $postLink() {
        if (this.addBillingServiceDialog) {
            this.addBillingServiceDialog.setOptions(<kendo.ui.DialogOptions>{
                title: "Add Billing Service",
                modal: true,
                width: "50%",
                maxHeight: 300,
                animation: true,
                actions: null,
                open: (e) => {
                    this.InitGreatPlainsCodesDropdown();
                    this.greatPlainsCodeId = 0;
                    this.serviceCode = null;
                    this.servicePrice = null;
                    this.serviceDescription = null;
                    this.internalDescription = null;
                },
                close: (e) => {
                    
                }
            })
        }
    }

    async InitGreatPlainsCodesDropdown() {
        this.greatPlainsCodesDropdown.setOptions(
            {
                autoBind: true,
                valuePrimitive: true,
                dataValueField: nameof<BillingGreatPlainsCodesVm>(o => o.billingGreatPlainsCodeId),
                dataTextField: nameof<BillingGreatPlainsCodesVm>(o => o.greatPlainsCode),
                optionLabel: "Select Great Plains Code",
                optionLabelTemplate: "Select Great Plains Code",
                autoWidth: true,
                filter: "contains",
                select: async (e) => {

                }
            } as kendo.ui.DropDownListOptions
        );

        let greatPlainsCodesDropdownDataSource = new kendo.data.DataSource({
            transport: {
                read: async (options) => {
                    options.success([]);                
                }
            }
        });

        this.greatPlainsCodesDropdown.setDataSource(greatPlainsCodesDropdownDataSource);

        this.isLoading = true;
        await this.billingGreatPlainsCodeClient.billingGreatPlainsCodes()
            .then(response => {
                if (response && response.result) {
                    this.greatPlainsCodesDropdown.dataSource.data(response.result);
                }
            })
            .catch(ex => {
                console.log("Error loading GreatPlainsCodes", ex);
                this.DisplaySanackbar("error", "Error! Failed to fetch billing GreatPlainsCodes");
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    DisplaySanackbar(type: string, message: string) {
        this.showSnackbar = !this.showSnackbar;
        this.snackbarType = type;
        this.snackbarMessage = message;
    }

    async AddBillingService() {
        if (this.greatPlainsCodeId == null || this.greatPlainsCodeId == undefined || this.greatPlainsCodeId == 0) {
            this.DisplaySanackbar("error", "Please select Great Plains Code.");
            return;
        }
        if (this.serviceCode == undefined || this.serviceCode == null || this.serviceCode.trim() == "")
            this.serviceCode = null;
        if (this.servicePrice == undefined || this.servicePrice == null)
            this.servicePrice = null;
        if (this.serviceDescription == undefined || this.serviceDescription == null || this.serviceDescription.trim() == "")
            this.serviceDescription = null;
        if (this.internalDescription == undefined || this.internalDescription == null || this.internalDescription.trim() == "")
            this.internalDescription = null;

        let createBillingServiceRm: CreateBillingServiceRm = {
            billingServiceId: null,
            billingGreatPlainsCodeId: +this.greatPlainsCodeId,
            active: true,
            createdDateTime: null,
            serviceCode: this.serviceCode,
            servicePrice: this.servicePrice == null ? null : +(Math.trunc(this.servicePrice * Math.pow(10, 2)) / Math.pow(10, 2)),
            serviceDescription: this.serviceDescription,
            internalDescription: this.internalDescription
        }

        this.isLoading = true;
        await this.billingServicesClient.billingServices(createBillingServiceRm)
            .then(response => {
                if (response && response.result) {
                    this.DisplaySanackbar("success", "Billing service added successfully!");
                    this.addBillingServiceDialog.close();
                    this.$rootScope.$emit('refreshBillingServiceGrid', {});
                }
            })
            .catch(ex => {
                console.log("Error adding billing service", ex);
                this.DisplaySanackbar("error", "Error! Failed to add billing service");
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    CancelAddBillingService() {
        this.addBillingServiceDialog.close();
    }

    static BindComponent(app: ng.IModule) {
        app.component("addBillingServicePopup", {
            bindings: {
            },
            controller: AddBillingServicePopupComponentController,
            templateUrl: "/Templates/Zorware/BillingServices/AddBillingServicePopup.html"
        });

    }
}